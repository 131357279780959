import { MODULES_FETCHED, MODULE_SET_PRISTINE } from '../actions/module'

const INITIAL_STATE = {
  modules: { list: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODULES_FETCHED:
      return { ...state, ...handleModulesFetched(action) }
    case MODULE_SET_PRISTINE:
      return { ...state, ...handleModuleSetPristine(action) }
    default:
      return state
  }
}

function handleModulesFetched (action) {
  const { modules } = action
  return { modules, pristine: false }
}

function handleModuleSetPristine (action) {
  const { pristine } = action
  return { pristine }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jobService, employeeLeaveService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import { exportFile, formatter, log } from '../../../util'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { apiHostname } from '../../../config'
import { ExportType } from '../../../constants'

// UI
import { Loading, List, Page } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
export class EmployeeImpactedJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      item: {},
      dayTime: '',
      taskDetail: '',
      jobs: { list: [], total: 0 },
      loading: false,
      clientId: '',
      employeeInfo: '',
      startDay: '',
      endDay: '',
      memoInfo: '',
      payrolls: [],
      languages: [],
      skills: [],
      modal: {
        item: { link: {} },
        show: false
      },
      editLeave: {
        isEdit: false
      },
      modalShow: false,
      totalReview: 0,
      impacted: false
    }
  }

  componentDidMount () {
    this.fetchLeaveJobs()
    this.checkRef()
  }

  render () {
    const { form, match } = this.props
    const { jobs, loading, totalReview, impacted } = this.state
    const { params } = match
    const { id } = params
    const info = jobs.list.length > 0 ? {
      firstName: jobs.list[0].first_name,
      lastName: jobs.list[0].last_name,
      gender: jobs.list[0].gender,
      leaveStart: jobs.list[0].leave_start,
      leaveEnd: jobs.list[0].leave_end
    } : {}

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency, cancellation_penalty }) => (<div className='indicator-list'> { emergency ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>E</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
          { cancellation_penalty > 0 ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
        </div>)
      },
      {
        title: 'Client',
        width: 5,
        render: ({ client_id: clientId, client }) => <div><a href={`/clients/${clientId}`} rel='noopener noreferrer' target='_blank'>{client}</a></div>
      },
      {
        title: 'Day',
        width: 2,
        render: ({ job_start_date: startDate }) => startDate ? moment(startDate).format('dddd') : '-'
      },

      {
        title: 'Start Date',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortDate(job_start_date)
      },

      {
        title: 'End Date',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortDate(job_end_date)
      },

      {
        title: 'Start Time',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortTime(job_start_date)
      },

      {
        title: 'End Time',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortTime(job_end_date)
      },
      {
        title: 'Status',
        width: 2,
        render: ({ job_id, job_leave_status, is_cancel }) => <div style={{ color: '#ccc', fontSize: '12pt' }}>
          { is_cancel ? <div><Icon type='check-circle' /> &nbsp;<Tooltip mouseLeaveDelay={0} title='Cancelled'><span style={{ color: '#ff5b5b' }}><Icon type='close-circle' theme='filled' /></span></Tooltip></div> : <div>
          <Tooltip mouseLeaveDelay={0} title='Keep?'>{ job_leave_status === 'approved' ? <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span> : <Icon type='check-circle' onClick={() => this.saveStatus(job_id, 'approved')} /> }</Tooltip> &nbsp;
          <Tooltip mouseLeaveDelay={0} title='To review?'>{ job_leave_status !== 'approved' ? <span style={{ color: '#f2b018' }}><Icon type='exclamation-circle' theme='filled' /></span> : <Icon type='exclamation-circle' onClick={() => this.saveStatus(job_id, 'review')} /> }</Tooltip></div> }
        </div>
      },
      {
        title: '',
        width: 1,
        render: ({ job_id }) => <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
          <Tooltip mouseLeaveDelay={0} title='View'> <a href={`/jobs/single/${job_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></Tooltip>
        </div>
      }
    ]

    // backLink={impacted ? '/notifications' : `/employees/${id}`}

    return (
      <Page.Body className='impacted'>
        <Page.Left>
          <Page.Menu title='Employee' backLink={impacted ? '/reports/employee-leave' : `/employees/${id}`} panel={<div style={{ textAlign: 'center', padding: '20px 0' }}>
            <img alt='' src={process.env.PUBLIC_URL + '/img/' + (info.gender && info.gender.toLowerCase() === 'male' ? 'man.png' : 'woman.png')} className='avatar' />
            <div className='employee'>
              {
                info.firstName
              }
              &nbsp;
              {
                info.lastName
              }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
        <Page.Header title={`${totalReview} Jobs To Action (Leave From ${formatter.toShortDate(info.leaveStart)} to ${formatter.toShortDate(info.leaveEnd)})`}>
          <div className='btn' onClick={() => this.handleExport()}>
            Export
          </div>
        </Page.Header>
          <Loading loading={loading} blur>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={jobs.list} />
            </Skeleton>
          </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  checkRef () {
    const { location } = this.props
    if (location.search === '?notify') {
      // redirected from notification page
      this.setState({ impacted: true })
    } else {
      // redirected from employee page
      console.log('From Employee Page')
    }
  }

  fetchLeaveJobs = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    const jobs = await employeeLeaveService.listImpactedJobsByPage(1, 200, { id: lid })
    this.fetchTotalReview()
    this.setState({ jobs })
  }

  fetchTotalReview = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params
    let filter = {}
    filter.job_leave_status = 'review'
    filter.is_cancel = false
    filter.id = lid
    const { total } = await employeeLeaveService.listImpactedJobsByPage(1, 1, filter)
    this.setState({ totalReview: total })
  }

  async saveStatus (id, value) {
    const { jobs } = this.state
    const result = await jobService.save(id, { job_leave_status: value })

    if (result.id) {
      this.fetchTotalReview()
      for (let i = 0; i < jobs.list.length; i++) {
        const job = jobs.list[i]
        if (job.job_id === id) {
          job.job_leave_status = value
          break
        }
      }
    }

    this.setState({ jobs })
  }

  handleExport = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    try {
      this.setState({ loading: true })
      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_EMPLOYEE_JOB_TO_ACTION, {}, lid)

      setTimeout(() => {
        this.setState({ loading: false })
      }, 3000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get jobs to action export successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeImpactedJob))

import React, { Component } from 'react'
import { ExportType, ReportMenu, YesNoOption } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter } from '../../../util'

// UI
import { Button, ControlLabel, List, Page } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
const VIEW_REPORTABLE = 'reportable'
const VIEW_REPORTABLE_LABEL = 'Reportable Incident'
const VIEW_ACTIONS = 'actions'
const VIEW_ACTIONS_LABEL = 'Feedback Actions'

export class ReportFeedback extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentTitle: VIEW_REPORTABLE_LABEL,
      currentView: VIEW_REPORTABLE,
      expiredList: { list: [], total: 0 },
      expiringList: { list: [], total: 0 },
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchList()
    this.fetchOtherSettings()
  }

  render () {
    const { currentTitle, currentView, expiredList, expiringList, loading, loadingExport, loadingList, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Feedback Report'>
            {!loading
              ? <Button key={'export'} feedback={loadingExport} onClick={this.handleExport}>
                Export
              </Button>
              : null}
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }

          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('feedback_report_email', {
                          initialValue: settings.feedback_report_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group defaultValue={currentView} disabled={loading || loadingList} onChange={this.onChangeView}>
                  <Radio.Button value={VIEW_REPORTABLE}>{VIEW_REPORTABLE_LABEL}</Radio.Button>
                  <Radio.Button value={VIEW_ACTIONS}>{VIEW_ACTIONS_LABEL}</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div className='admin-list' style={{marginTop: '30px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div>
                  <div className='report-title'>{currentTitle} OVERDUE <span className='report-tag'>{expiredList.total}</span></div>
                  <List cols={this.getColumns(currentView, true)} rows={expiredList.list} />
                </div>

                <div>
                  <div className='report-title'>{currentTitle} due soon <span className='report-tag'>{expiringList.total}</span></div>
                  <List cols={this.getColumns(currentView, false)} rows={expiringList.list} />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList = () => {
    const { currentView, isLoaded, loading } = this.state
    this.fetchDayCount({currentView, isLoaded, loading})
  }

  fetchDayCount = async ({ currentView, isLoaded = true }) => {
    if (this.hasAccess('readFeedbackReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const { expiring, expired } = await reportService.getListByAll(ExportType.REPORT.FEEDBACK, currentView)
        this.setState({
          expiringList: expiring, expiredList: expired, loading: false, loadingList: false, isLoaded: true
        }, this.toTop)
      } catch (e) {
        notify.error('Unable to get Feedback Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  getColumns = (type, isOverdue) => {
    const COLUMN = {
      [VIEW_REPORTABLE]: [
        {
          title: 'FID',
          width: 1,
          render: ({ id, case_no: caseNo }) => <a href={`/feedbacks/${id}`} rel='noopener noreferrer' target='_blank'>#{caseNo}</a>
        },
        {
          title: 'Category',
          width: 1,
          render: ({ category_name: categoryName, sub_category_name: subCategoryName }) => `${categoryName} (${subCategoryName})`
        },
        {
          title: 'Employee',
          width: 1,
          render: ({ 
            employee_id: id, rb_employee_first_name: firstName, rb_employee_last_name: lastName, rb_employee_acc_ref: accRef
          }) => id ? <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{firstName} {lastName} ({accRef})</a> : null
        },
        {
          title: 'Client',
          width: 1,
          render: ({ 
            client_id: id, rb_client_first_name: firstName, rb_client_last_name: lastName, rb_client_acc_ref: accRef
          }) => id ? <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{firstName} {lastName} ({accRef})</a> : null
        },
        {
          title: 'Job',
          width: 1,
          render: ({ job_id: id }) => id ? <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>#{id}</a> : null
        },
        {
          title: 'Report Date 1',
          width: 1,
          render: ({ reportable_date1: date }) => formatter.toShortDate(date)
        },
        {
          title: isOverdue ? 'As of today' : 'Remaining',
          width: 1,
          render: ({ reportable_done1: done, num_of_days1: numOfDays, date_remainder1: dateRemainder }) => (
            done === YesNoOption.yes.value 
              ? 'Done'
              : `${parseInt(numOfDays, 10) === 0 && parseFloat(dateRemainder) !== 0
                ? '< 24 hours'
                : numOfDays}`
          )
        },
        {
          title: 'Report Date 2',
          width: 1,
          render: ({ reportable_date2: date }) => formatter.toShortDate(date)
        },
        {
          title: isOverdue ? 'As of today' : 'Remaining',
          width: 1,
          render: ({ reportable_done2: done, num_of_days2: numOfDays, date_remainder2: dateRemainder }) => (
            done === YesNoOption.yes.value 
              ? 'Done'
              : `${parseInt(numOfDays, 10) === 0 && parseFloat(dateRemainder) !== 0
                ? '< 24 hours'
                : numOfDays}`
          )
        }
      ],
      [VIEW_ACTIONS]: [
        {
          title: 'FID',
          width: 1,
          render: ({ id, case_no: caseNo }) => <a href={`/feedbacks/${id}`} rel='noopener noreferrer' target='_blank'>#{caseNo}</a>
        },
        {
          title: 'Category',
          width: 1,
          render: ({ category_name: categoryName, sub_category_name: subCategoryName }) => `${categoryName} (${subCategoryName})`
        },
        {
          title: 'Employee',
          width: 1,
          render: ({ 
            employee_id: id, rb_employee_first_name: firstName, rb_employee_last_name: lastName, rb_employee_acc_ref: accRef
          }) => id ? <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{firstName} {lastName} ({accRef})</a> : null
        },
        {
          title: 'Client',
          width: 1,
          render: ({ 
            client_id: id, rb_client_first_name: firstName, rb_client_last_name: lastName, rb_client_acc_ref: accRef
          }) => id ? <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{firstName} {lastName} ({accRef})</a> : null
        },
        {
          title: 'Job',
          width: 1,
          render: ({ job_id: id }) => id ? <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>#{id}</a> : null
        },
        {
          title: 'Description',
          width: 1,
          render: ({ fa_description: description }) => description
        },
        {
          title: 'Due Date',
          width: 1,
          render: ({ fa_due_at: date }) => formatter.toShortDate(date)
        },
        {
          title: isOverdue ? 'As of today' : 'Remaining',
          width: 1,
          render: ({ fa_done: done, fa_num_of_days: numOfDays, fa_date_remainder: dateRemainder }) => (
            done === YesNoOption.yes.value 
              ? 'Done'
              : `${parseInt(numOfDays, 10) === 0 && parseFloat(dateRemainder) !== 0
                ? '< 24 hours'
                : numOfDays}`
          )
        }
      ]
    }
    return COLUMN[type]
  }

  onChangeView = (e) => {
    const currentView = e.target.value
    const currentTitle = currentView === VIEW_REPORTABLE ? VIEW_REPORTABLE_LABEL : currentView === VIEW_ACTIONS ? VIEW_ACTIONS_LABEL : 'Feedback Report'
    this.setState({ currentTitle, currentView, isLoaded: false }, this.fetchList)
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readFeedbackReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.FEEDBACK)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportFeedback))

import React, { Component } from 'react'
import './styles.css'

export default class NotificationBox extends Component {
  render () {
    const { type, title, message } = this.props
    const classSelection = ((type && type === 'error') ? 'notification-box-error' : 'notification-box-success')

    return (
      <div className={`notification-box ${classSelection}`}>
        <div className='icon'>
          <i className='iconfont icon-52 icon' />
        </div>
        <div className='content'>
          <div className='title'>{title}</div>
          <div className='message'>{ message }</div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, fvpCategoriesService, fvpRatesService } from '../../../../../services'
import moment from 'moment'
// import { FieldList } from '../../../../../constants'
import { Link } from 'react-router-dom'
import Subset from './subset'
import uid from 'uid-safe'

// UI
import { Button, FullModal, Loading, Page, SectionTitle } from '../../../../../components'
import { validator } from '../../../../../util'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Notification from 'antd/lib/notification'

import './styles.css'
import notify from '../../../../../components/Notification'
import BillingRateRow from './row'
import { cloneDeep, truncate } from 'lodash'

const { error } = Modal

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

const settingTitle = 'Rate Set'
const settingType = 'rate set'

const DefaultRateSet = {
  name: 'New Set',
  categories: [],
  active: true,
  start_date: null,
  end_date: null,
  normal_hours_start: null,
  normal_hours_end: null,
  after_hours_start: null,
  after_hours_end: null,
  emg_rate: 0.0,
  km_rate: 0.0,
  so_hours_start: null,
  so_hours_end: null,
  so_free_getup: null,
  km_label: null
}

const DefaultCategorySet = {

}

export class SettingRateSetPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: [],
      currentCategories: [],
      soCategories: [],
      currentPage: 1,
      rateSet: {},
      rates: [],
      loading: false,
      loadingForm: false,
      validatingForm: false
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const { currentPage } = this.state

    this.fetchCategories()
    this.fetchRateSet()
  }

  fetchCategories = async () => {
    this.setState({ loading: true })
    const categories = await fvpCategoriesService.listByPage(1, 0, { active: true, is_delete: false })

    if (categories && validator.isNotEmptyArray(categories.list)) {
      const filteredCategories = categories.list.filter(e => e.is_sleepover === false)
      const soCategories = categories.list.filter(e => e.is_sleepover === true)
      this.setState({ categories, currentCategories: filteredCategories, soCategories })
    }
  }

  fetchRateSet = async () => {
    if (this.hasAccess('readFvpFundingRateSet')) {
      this.setState({ loading: true })
      const id = this.getId()
      if (id !== 'add') {
        const r = await fvpRatesService.get(id)

        if (r && r.id) {
          this.setState({ rateSet: r, rates: validator.isNotEmptyArray(r.rates) ? r.rates : [] })
        }
      }
    }

    this.setState({ loading: false })
  }

  handleAddSubset = () => {
    const { currentCategories = [], soCategories = [], rates = [] } = this.state

    const newSet = cloneDeep(DefaultRateSet)
    newSet.id = uid.sync(8)
    // newSet.selectCategories = cloneDeep(currentCategories)

    if (!validator.isArray(newSet.categories)) {
      newSet.categories = []
    }

    for (let i = 0; i < soCategories.length; i++) {
      const s = soCategories[i]
      const rates = []
      for (let j = 0; j < 8; j++) {
        // populate normal hours
        rates.push({category_id: s.id, day: `n${j + 1}`, value: 0, label: null})
        // populate after hours
        rates.push({category_id: s.id, day: `a${j + 1}`, value: 0, label: null})
      }

      newSet.categories.push({ id: s.id, name: s.name, is_sleepover: s.is_sleepover, rates })
    }

    rates.unshift(newSet)

    this.setState({ rates })
  }

  handleUpdate = (set) => {
    const { rates = [] } = this.state

    const idx = rates.findIndex(e => e.id === set.id)

    if (idx > -1) {
      rates.splice(idx, set)
      this.setState({rates})
    }
  }

  handleDuplicate = (set) => {
    const { rates = [] } = this.state

    const idx = rates.findIndex(e => e.id === set.id)

    if (idx > -1) {
      const newSet = cloneDeep(set)
      newSet.id = uid.sync(8)
      newSet.name = 'New Duplicate'
      newSet.start_date = null
      newSet.end_date = null
      rates.unshift(newSet)
      this.setState({rates})
    }
  }

  handleDelete = (id) => {
    const { rates = [] } = this.state

    const idx = rates.findIndex(e => e.id === id)

    if (idx > -1) {
      if(validator.isDigit(id)) {
        rates[idx] = {
          id: id,
          is_delete: true
        }
      } else {
        rates.splice(idx)
      }

      this.setState({rates})
    }
  }

  handlePreSaveCheck = () => {
    const { loadingForm, validatingForm } = this.state
    if (loadingForm || validatingForm) return

    this.setState({ shouldValidate: true, hasError: false, validatingForm: true })

    const isOverlap = this.validateRateSetPeriod()

    setTimeout(() => {
      this.setState({ validatingForm: false })
      this.handleSave()
    }, 1100)
  }

  handleSubsetValidation = (res) => {
    this.setState({ hasError: true, shouldValidate: false })
    this.showErrorNotification()
  }

  showErrorNotification = () => {
    Notification.error({
      message: 'Incomplete Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { hasError, loadingForm, rates, rateSet } = this.state

    if (loadingForm || hasError) return

    validateFields(['rate_name', 'rate_active'], async (errors, values) => {
      if (!errors) {
        const id = this.getId()
        this.setState({ loadingForm: true })

        try {
          let response
          const rsData = {
            name: values.rate_name,
            active: values.rate_active || false
          }

          if (this.isEdit()) {
            rsData.rates = rates
            response = await fvpRatesService.save(id, rsData)
          } else {
            response = await fvpRatesService.add(rsData)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)

            if (!this.isEdit()) {
              window.location.replace(`/settings/funders/rate-set/${response.id}`)
            } else {
              this.fetchRateSet()
            }
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  validateRateSetPeriod () {
    const { rates } = this.state

    let isOverlap = false
    let rs1 = {}
    let rs2 = {}

    for (let i = 0; i < rates.length; i++) {
      const r1 = rates[i]
      rs1 = r1
      const csd = moment(r1.start_date)
      const ced = moment(r1.end_date)

      for (let j = i + 1; j < rates.length; j++) {
        const r2 = rates[j]
        rs2 = r2
        const rsd = moment(r2.start_date)
        const red = moment(r2.end_date)

        const isBetween1 = moment(csd).isBetween(rsd, red)
        const isBetween2 = moment(ced).isBetween(rsd, red)
        if (isBetween1 || isBetween2) {
          isOverlap = true
          break
        }
      }

      if (isOverlap === true){
        break
      }
    }

    if (isOverlap) {
      error({
        title: `Rate Set Period Overlapped`,
        content: <div>
          <p>The following periods are overlapped:</p>
          <ul>
            <li>{rs1.name}</li>
            <li>{rs2.name}</li>
          </ul>
        </div>
      })
    }

    return isOverlap
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { categories, currentCategories, loading, loadingForm, validatingForm, rates, rateSet, shouldValidate } = this.state
    const { form, history } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 7 },
      wrapperCol: { sm: 14, md: 14, lg: 16 }
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={settingTitle}>
            { (this.hasAccess('updateFvpFundingRateSet') || this.hasAccess('createFvpFundingRateSet')) && !loading
              ? <div class='btn' onClick={() => this.handlePreSaveCheck()}>
                {loadingForm ? <div><Icon type='loading' /> &nbsp;Saving...</div>
                  : validatingForm ? <div><Icon type='loading' /> &nbsp;Validating...</div> : 'Save'}
              </div> : null}
            <div class='btn btn-ghost' onClick={() => history.goBack()}>
              Back
            </div>
          </Page.Header>
            <Skeleton loading={loading}>
              <Loading loading={loadingForm} blur>
                <Form>
                  <div className='billing-rate-info'>
                    <Row>
                      <Col lg={10}>
                        <FormItem label='Name' {...formItemLayout}>
                          {getFieldDecorator('rate_name', {
                            initialValue: rateSet.name || '',
                            rules: [
                              { min: 2, message: 'Name must be between 2 and 128 characters' },
                              { max: 128, message: 'Name must be between 2 and 128 characters' },
                              { required: true, message: 'Please enter name' },
                              { whitespace: true, message: 'Please enter name' }
                            ]
                          })(
                            <Input />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={2}>
                        <FormItem label='' {...formItemLayout}>
                          {getFieldDecorator('rate_active', {
                            initialValue: rateSet.active === undefined ? true : rateSet.active,
                            valuePropName: 'checked'
                          })(
                            <Switch
                              checkedChildren='Enable' unCheckedChildren='Disable'
                            />
                          )}
                        </FormItem>
                      </Col>
                      { this.isEdit()
                        ? <Col lg={12} style={{ textAlign: 'right', padding: '6px 15px 0 0' }}>
                          <Button onClick={() => this.handleAddSubset()}>
                          Add Subset
                          </Button>
                        </Col>
                        : null }
                    </Row>
                  </div>
                </Form>

              { rates.map((set, index) => (
                !set.is_delete
                  ? <Subset
                    index={index}
                    key={`rsk${index}`}
                    data={set}
                    subsets={rateSet.rates}
                    validate={shouldValidate}
                    categories={currentCategories}
                    onError={(pass) => this.handleSubsetValidation(pass)}
                    onUpdate={(set) => this.handleUpdate(set)}
                    onDuplicate={(set) => this.handleDuplicate(set)}
                    onDelete={(id) => this.handleDelete(id)}
                  />
                  : null
              ))}
            </Loading>
          </Skeleton>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSetPage))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import FileCategory from './Category'
import FileSubCategory from './SubCategory'
import './styles.css'

const TabPane = Tabs.TabPane
const settingTitle = 'File'

export class SettingFile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addFileCategory: false,
      addFileType: false,
      currentPage: 1,
      activeTab: '1',
      classificationKey: 'client'
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    // const tab = (new URLSearchParams(window.location.search)).get("t")
    // this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  addFileCategory = () => {
    this.setState({ addFileCategory: true })
  }

  addFileType = () => {
    this.setState({ addFileType: true })
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  onClassificationChange = (key) => {
    this.setState({ classificationKey: key })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addFileCategory, addFileType, activeTab, classificationKey } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs type='line' activeKey={classificationKey} onChange={this.onClassificationChange}>
            { /* Client Tab */ }
            <TabPane tab='Client' key='client'>
              <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
                <TabPane tab='Categories' key='1'>
                  <div className='header-action'>
                    { this.hasAccess('createMainCategory') ? <div className='btn' onClick={() => this.addFileCategory()}>
                      Add Category
                    </div> : null }
                  </div>
                  <FileCategory classification='client' onAdd={addFileCategory} onComplete={() => this.setState({ addFileCategory: false })} />
                </TabPane>

                <TabPane tab='Sub-Categories' key='2'>
                  <div className='header-action'>
                    { this.hasAccess('createSubCategory') ? <div className='btn' onClick={() => this.addFileType()}>
                      Add Sub-Category
                    </div> : null }
                  </div>
                  <FileSubCategory classification='client' onAdd={addFileType} onComplete={() => this.setState({ addFileType: false })} />
                </TabPane>
              </Tabs>
            </TabPane>
            { /* Employee Tab */ }
            <TabPane tab='Employee' key='employee'>
              <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
                <TabPane tab='Categories' key='1'>
                  <div className='header-action'>
                    { this.hasAccess('createMainCategory') ? <div className='btn' onClick={() => this.addFileCategory()}>
                      Add Category
                    </div> : null }
                  </div>
                  <FileCategory classification='employee' onAdd={addFileCategory} onComplete={() => this.setState({ addFileCategory: false })} />
                </TabPane>

                <TabPane tab='Sub-Categories' key='2'>
                  <div className='header-action'>
                    { this.hasAccess('createSubCategory') ? <div className='btn' onClick={() => this.addFileType()}>
                      Add Sub-Category
                    </div> : null }
                  </div>
                  <FileSubCategory classification='employee' onAdd={addFileType} onComplete={() => this.setState({ addFileType: false })} />
                </TabPane>
              </Tabs>
            </TabPane>
            { /* Funder Tab */ }
            <TabPane tab='Funder' key='funder'>
              <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
                <TabPane tab='Categories' key='1'>
                  <div className='header-action'>
                    { this.hasAccess('createMainCategory') ? <div className='btn' onClick={() => this.addFileCategory()}>
                      Add Category
                    </div> : null }
                  </div>
                  <FileCategory classification='funder' onAdd={addFileCategory} onComplete={() => this.setState({ addFileCategory: false })} />
                </TabPane>

                <TabPane tab='Sub-Categories' key='2'>
                  <div className='header-action'>
                    { this.hasAccess('createSubCategory') ? <div className='btn' onClick={() => this.addFileType()}>
                      Add Sub-Category
                    </div> : null }
                  </div>
                  <FileSubCategory classification='funder' onAdd={addFileType} onComplete={() => this.setState({ addFileType: false })} />
                </TabPane>
              </Tabs>
            </TabPane>
            { /* Job Tab */ }
            <TabPane tab='Job' key='job'>
              <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
                <TabPane tab='Categories' key='1'>
                  <div className='header-action'>
                    { this.hasAccess('createMainCategory') ? <div className='btn' onClick={() => this.addFileCategory()}>
                      Add Category
                    </div> : null }
                  </div>
                  <FileCategory classification='job' onAdd={addFileCategory} onComplete={() => this.setState({ addFileCategory: false })} />
                </TabPane>

                <TabPane tab='Sub-Categories' key='2'>
                  <div className='header-action'>
                    { this.hasAccess('createSubCategory') ? <div className='btn' onClick={() => this.addFileType()}>
                      Add Sub-Category
                    </div> : null }
                  </div>
                  <FileSubCategory classification='job' onAdd={addFileType} onComplete={() => this.setState({ addFileType: false })} />
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFile))

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_STORES, FETCHING_STORES, STORES_FETCHED } from '../../actions/setting/store'
import settingStoreService from '../../../services/setting/store'

function * fetchStores ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_STORES, loading })
    const stores = yield settingStoreService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: STORES_FETCHED, loading: false, stores })
  } catch (e) {
    yield put({ type: FETCHING_STORES, loading: false })
    console.error(e)
  }
}

function * watchSettingStore () {
  yield takeEvery(FETCH_STORES, fetchStores)
}

export default watchSettingStore

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchFutureJobs } from '../../../states/actions/futureJob'
import { fetchTotalPending } from '../../../states/actions'
import { ExportType, JobFvpMenu } from '../../../constants'
import { exportFile, formatter, validator } from '../../../util'
import moment from 'moment'
import AddJobModal from '../AddJobModal'
import { authService } from '../../../services'
import { jobLegacyURL, apiHostname } from '../../../config'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 20

const { RangePicker } = DatePicker

export class IndividualFuture extends Component {
  constructor (props) {
    super(props)
    const { jobs: { list, total }, loading } = props
    this.state = {
      jobIds: [],
      currentPage: 1,
      filter: {},
      list,
      loading,
      loadingExport: false,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      showExportDate: false,
      sort: {},
      jobStatus: '',
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobs: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const currUrl = this.props.match.url
    const { currentPage, filter, loading, searchText, sort } = this.state
    if (currUrl === '/jobs/future') {
      this.fetchFutureJobs({ currentPage, filter, loading: true, searchText, sort, jobStatus: 'future' })
      this.setState({ jobStatus: 'future' })
    } else if (currUrl === '/jobs/past') {
      this.fetchFutureJobs({ currentPage, filter, loading: true, searchText, sort, jobStatus: 'past' })
      this.setState({ jobStatus: 'past' })
    } else {
      this.fetchFutureJobs({ currentPage, filter, loading: true, searchText, sort, jobStatus: 'cancel' })
      this.setState({ jobStatus: 'cancel' })
    }

    this.props.fetchTotalPending()
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, loading, sort, jobStatus } = this.state
    this.setState({ searching: true })

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          const w = words[i]

          if (w) {
            filter.$and.push({
              $or: [
                { first_name: { condition: 'ilike', value: `%${w}%` } },
                { last_name: { condition: 'ilike', value: `%${w}%` } },
                { emp_firstname: { condition: 'ilike', value: `%${w}%` } },
                { emp_lastname: { condition: 'ilike', value: `%${w}%` } },
                { fund_fullname: { condition: 'ilike', value: `%${w}%` } },
                { suburb: { condition: 'ilike', value: `%${w}%` } },
                { job_start_day: { condition: 'ilike', value: `%${w}%` } },
                { tasks: { condition: 'ilike', value: `%${w}%` } },
                { string_start_date: { condition: 'ilike', value: `%${w}%` } }
              ]
            })
          }
        }

        if (value && value.trim() !== '') {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${value}%` } },
              { last_name: { condition: 'ilike', value: `%${value}%` } },
              { client_name: { condition: 'ilike', value: `%${value}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${value}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${value}%` } },
              { emp_name: { condition: 'ilike', value: `%${value}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${value}%` } },
              { suburb: { condition: 'ilike', value: `%${value}%` } },
              { job_start_day: { condition: 'ilike', value: `%${value}%` } },
              { tasks: { condition: 'ilike', value: `%${value}%` } },
              { string_start_date: { condition: 'ilike', value: `%${value}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }

    this.fetchFutureJobs({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort, jobStatus })
    this.setState({ searchText: value, currentPage: 1 })
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  onClickRecurring = () => {
    this.setState({ showEndDate: false })
  }

  onClickSingle = () => {
    this.setState({ showEndDate: true })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async exportJobList (d1, d2) {
    this.setState({ showExportDate: false })

    if (validator.isNotEmptyArray(d1)) {
      this.setState({ loadingExport: true })
      const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_LIST, {from: d1[0], to: d1[1]})

      setTimeout(() => {
        this.setState({ loadingExport: false })
      }, 2000)
    }
  }

  render () {
    const { currentPage, list, showJobModal, searching, total, jobStatus, showExportDate, loadingExport } = this.state
    const { loading } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobFvpMenu} backLink='/' countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={jobStatus === 'future' ? 'Future Jobs' : jobStatus === 'past' ? 'Past Jobs' : 'Cancelled Jobs'}>
            <Row>
              <Col lg={24} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
                { this.hasAccess('createJob')
                  ? <div className='btn' onClick={() => this.addJob()}>
                  Add Job
                  </div>
                  : null }
                <div className='btn btn-ghost'  onClick={() => this.openDownloadJobList()}>
                  { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `Export` }
                </div>
              </Col>
            </Row>
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
                <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                  <RangePicker
                    format='YYYY-MM-DD'
                    open={showExportDate}
                    onChange={(d1, d2) => this.exportJobList(d1, d2)}
                  />
                </div>
              </Col>
            </Row>
          </Page.Filter>

          <div className='jobs'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, base_job_id, job_start_date, job_end_date, first_name, last_name, emp_firstname,
                  emp_lastname, suburb, tasks, fund_fullname, client_id, employee_id, funder_id, emergency, cancellation_penalty, sleepover,
                  client_leave_id, client_leave_start, client_leave_end, employee_leave_id, employee_leave_start, employee_leave_end, holiday_date, holiday_name,
                  emergency_invoice, emergency_pay, client_leave_until_further_notice, is_sleepover_job, cf_id, contact_client_for_jobs, clashed_client_names, clashed_employee_names,
                  jcs_id, jcs_is_forced_update, jps_id, job_survey_feedback, job_survey_feedback_text } = item

                const isCostingUpdated = (jcs_id && jcs_is_forced_update) || !!jps_id
                const costingMsg = (jcs_id && jcs_is_forced_update) && !!jps_id
                  ? `Costing - Invoice / Payroll updated`
                  : (jcs_id && jcs_is_forced_update)
                    ? `Costing - Invoice updated`
                    : !!jps_id
                      ? `Costing - Payroll updated`
                      : ''

                return (
                  <div className={`list-item ${holiday_date ? 'list-item-highlight' : ''}`} key={id}>
                    <Row>
                      <Col lg={1}>
                        { emergency_invoice && emergency_pay
                          ? <div className='list-indicator emergency'><span>EE</span></div>
                          : emergency_invoice
                            ? <div className='list-indicator emergency-invoice'><span>EI</span></div>
                            : emergency_pay
                              ? <div className='list-indicator emergency-pay'><span>EP</span></div>
                              : null }
                        { cancellation_penalty ? <div className='list-indicator late'><span>LC</span></div> : null }
                        { base_job_id > 0 ? <div className='list-indicator recur'><span>R</span></div> : null }
                        { job_survey_feedback ? <Tooltip title='Job Feedback'><div className='list-indicator fback'><span>F</span></div></Tooltip> : null }
                      </Col>
                      <Col lg={22}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Client</div>
                              </Col>
                              <Col lg={14}>
                                {/* <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { client_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                                <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a></div>
                                { client_leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(client_leave_start)} - ${client_leave_until_further_notice ? 'UFN' : formatter.toShortDate(client_leave_end)}`}</div> : null }
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Employee</div>
                              </Col>
                              <Col lg={14}>
                                {/* <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a> { employee_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                                <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a></div>
                                { employee_leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div> : null }
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Funder</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a>&nbsp;{!cf_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Outside of client's funding period`}</div> : null}{contact_client_for_jobs ? <Tooltip title='Contact client for jobs' mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='phone' style={{ color: '#1890ffbb'}} /></Tooltip> : null}</div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='calendar' /> { formatter.toShortDate(job_start_date)} - { formatter.toShortDate(job_end_date)} { holiday_date ? <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } <span className='time-sum'>{moment(job_start_date).format('dddd')}</span>
                            </div>
                            {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {moment(created_at).fromNow()}</div> */}
                          </Col>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='clock-circle' /> { formatter.toShortTime(job_start_date)} - { formatter.toShortTime(job_end_date)}
                              { is_sleepover_job ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(moment.duration(moment(job_end_date).diff(moment(job_start_date))).asHours())} hours</span> }
                              &nbsp;&nbsp;
                              { isCostingUpdated
                                ? <Tooltip
                                  title={<div>{costingMsg}</div>}
                                  mouseEnterDelay={0}
                                  mouseLeaveDelay={0}
                                >
                                  <Icon style={{fontSize: '11pt'}} type='dollar' theme='twoTone' twoToneColor='#d60b29' />
                                </Tooltip>
                                : null }
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='detail'><Icon type='environment' /> {suburb}&nbsp;
                            {clashed_client_names || clashed_employee_names
                              ? <span>
                                  &nbsp;
                                  <Tooltip
                                    title={(
                                      <div>
                                        {clashed_client_names ? <div>Same address with client: {clashed_client_names}</div> : ''}
                                        {clashed_employee_names ? <div>Same address with employee: {clashed_employee_names}</div> : ''}
                                      </div>
                                    )}
                                    mouseEnterDelay={0}
                                    mouseLeaveDelay={0}
                                  >
                                    <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                                  </Tooltip>
                                </span>
                              : null}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                          <Col>
                            <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : null }</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className='action-icon'>

                        <Link to={jobStatus === 'future' ? `/jobs/future/${id}` : jobStatus === 'past' ? `/jobs/past/${id}` : `/jobs/cancelled/${id}`}>

                          { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                            <Tooltip mouseLeaveDelay={0}  title='Manage job'>
                              <Icon type='form' />
                            </Tooltip>
                          </div> : null
                          }

                        </Link>
                      </Col>
                    </Row>
                  </div>

                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <AddJobModal visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, jobStatus } = this.state
    this.fetchFutureJobs({ currentPage, filter, searchText, sort, jobStatus })
  }

  fetchFutureJobs = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, jobStatus }) => {
    try {
      if (Array.isArray(filter.$and) && filter.$and.length) {
        searchText = ''
      }
      const { fetchFutureJobs } = this.props
      this.setState({ currentPage })
      fetchFutureJobs({ loading, currentPage, pageSize, filter, sort, searchText, jobStatus })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
    }
  }

  openDownloadJobList = () => {
    const { showExportDate, loadingExport } = this.state
    if (loadingExport) return

    this.setState({ showExportDate: !showExportDate })
  }
}

const mapDispatchToProps = {
  fetchFutureJobs,
  fetchTotalPending
}

const mapStateToProps = (state) => {
  return { ...state.FutureJob, ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(IndividualFuture))

import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/jvp/basejobs'

export default {
  add (values) {
    return request(`${url}/info`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/info/${id}`)
  },
  save (id, values) {
    return request(`${url}/info/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  // validateRecurring (values) {
  //   return request(`${url}/validate/recurring`, {
  //     method: 'POST',
  //     headers,
  //     body: JSON.stringify(values)
  //   })
  // },
  validateClientLeave (values) {
    return request(`${url}/validate/leave/client`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  validateEmployeeLeave (values) {
    return request(`${url}/validate/leave/employee`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listViewByPage (view, page, size, filter, sorting, text) {
    return request(`${url}/list/${view}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listViewMainByPage (view, page, size, filter, sorting, text) {
    return request(`${url}/list/${view}/main/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  checkUnsentComm (id) {
    return request(`${url}/check/comm-unsent/${id}`)
  },
  checkRecurringConflictJobs (values) {
    return request(`${url}/check/conflict`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  checkAffectedSingleJobs (values) {
    return request(`${url}/check/affected-jobs`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listSingleJobsByPage (view, page, size, filter, sorting, text) {
    return request(`${url}/list-single/${view}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Panel extends Component {
  render () {
    const { children, className, disabled = false, panelStyle = {}, color, subtitle, title, titleColor, type } = this.props

    const style = { ...panelStyle, border: type === 'warn' ? '1.5px solid rgba(255,0,0,0.4)' : '0' }

    return (
      <div className={formatter.toClassName(['wd-panel', disabled ? 'disallowed' : '', className])} style={style}>
        <div className={`wd-panel-title ${title ? '' : 'no-title'}`} style={{ display: title || subtitle ? '' : 'none', color: titleColor || undefined }}>
          { title }

          <div style={{float: 'right', color: undefined}}>{subtitle}</div>
        </div>

        <div className='wd-panel-body'>
          { children }
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.node
}

export const FETCH_CANCELLED_RECURRING_JOBS = 'FETCH_CANCELLED_RECURRING_JOBS'
export const FETCHING_CANCELLED_RECURRING_JOBS = 'FETCHING_CANCELLED_RECURRING_JOBS'
export const CANCELLED_RECURRING_JOBS_FETCHED = 'CANCELLED_RECURRING_JOBS_FETCHED'

export const fetchCancelledRecurringJobs = (params) => {
  return Object.assign({
    type: FETCH_CANCELLED_RECURRING_JOBS
  }, params)
}

export const fetchingCancelledRecurringJobs = (loading) => {
  return {
    type: FETCHING_CANCELLED_RECURRING_JOBS,
    loading
  }
}

export const cancelledRecurringJobsFetched = (jobs) => {
  return {
    type: CANCELLED_RECURRING_JOBS_FETCHED,
    jobs
  }
}

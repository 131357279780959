import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

export class SettingGeneral extends Component {
  constructor (props) {
    super(props)
    const { settingInfo = {} } = props
    this.state = {
      currentPage: 1,
      filter: {},
      isShowModal: false,
      list: [],
      loading: false,
      loadingForm: false,
      searchText: '',
      selectedItem: {},
      sort: {},
      settingInfo,
      settingType: settingInfo.type,
      settingTypeTitle: settingInfo.title,
      settingTypeTitleSingular: settingInfo.title_singular,
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })
  }

  fetchSettings = async ({ currentPage = 1 }) => {
    const { settingType } = this.state
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingGeneralService.listByPage(currentPage, pageSize, { type: settingType })
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  changePage = (currentPage) => {
    this.fetchSettings({ currentPage })
  }

  handleModal = (isShowModal, item = {}) => {
    this.setState({ isShowModal, selectedItem: item }, () => {
      const { form, onComplete } = this.props
      const { resetFields } = form
      if (isShowModal) {

      } else {
        resetFields()
        onComplete && onComplete()
      }
    })
  }

  hideModal = () => {
    const { form, onComplete } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
    onComplete()
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem, settingType, settingTypeTitle, settingTypeTitleSingular } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id, active } = selectedItem
        values.type = settingType
        values.value = values.name.toLowerCase()
        this.setState({ loadingForm: true })

        try {
          let r

          if (id) {
            r = await settingGeneralService.save(id, values)
          } else {
            r = await settingGeneralService.add(values)
          }

          this.setState({ loadingForm: false })

          if (r && r.id) {
            notify.success('Saved successfully', `${settingTypeTitleSingular} saved successfully.`)
            this.fetchSettings({ currentPage: 1 })
            this.handleModal(false)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTypeTitleSingular} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTypeTitleSingular} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  async handleDelete (id) {
    const { settingTypeTitleSingular } = this.state
    this.setState({ loadingForm: true })
    const r = await settingGeneralService.remove(id)

    if (r && r.id) {
      notify.success('Deleted successfully', `${settingTypeTitleSingular} deleted successfully.`)
      this.fetchSettings({ currentPage: 1 })
    }

    this.setState({ loadingForm: false })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, isShowModal, selectedItem, settingInfo, settingType, settingTypeTitle, settingTypeTitleSingular, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 16,
        key: 'name'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, name, value, active, is_editable }) => settingInfo.PmsUpdate && this.hasAccess(settingInfo.PmsUpdate)
          ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleModal(true, { id, name, value, active, is_editable })}>
            <Icon type='form' />
          </div>
          : null
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => settingInfo.PmsDelete && this.hasAccess(settingInfo.PmsDelete) && is_editable === true
        ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        >
          <Icon type='delete' />
        </Popconfirm>
        : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <div className='header-action'>
            { settingInfo.PmsCreate && this.hasAccess(settingInfo.PmsCreate)
              ? <div className='btn' onClick={() => this.handleModal(true)}>
                Add { settingTypeTitleSingular }
              </div>
              : null }
          </div>

          <Skeleton loading={loading} active>
            <Spin spinning={loadingForm}>
              <List cols={columns} rows={list} />

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} ${total === 1 ? `${settingTypeTitleSingular}` : `${settingTypeTitle}`}`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Spin>
          </Skeleton>
        </div>

        <SideModal
          title={`${selectedItem.id ? 'Update' : 'Add'} ${settingTypeTitleSingular}`}
          showModal={isShowModal}
          onClose={() => this.handleModal(false)}
          buttons={[
            <Button key='0' disabled={selectedItem.is_editable === false} onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Name'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Name must be between 2 and 128 characters' },
                  { max: 128, message: 'Name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input />
              )}
            </FormItem>
            {/* <FormItem label='Value'>
              {getFieldDecorator('value', {
                initialValue: selectedItem.value,
                rules: [
                  { min: 2, message: 'Value must be between 2 and 128 characters' },
                  { max: 128, message: 'Value must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter value' },
                  { whitespace: true, message: 'Please enter value' }
                ]
              })(
                <Input />
              )}
            </FormItem> */}
            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: selectedItem.active === undefined ? true : selectedItem.active || false,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Enable' unCheckedChildren='Disable'
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingGeneral))

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_ENDED_BASEJOBS, FETCHING_ENDED_BASEJOBS, ENDED_BASEJOBS_FETCHED } from '../actions/endedBaseJob'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_ENDED_BASEJOBS, loading })
    const jobs = yield baseJobService.getEndedJob(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: ENDED_BASEJOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_ENDED_BASEJOBS, loading: false })
    console.error(e)
  }
}

function * watchEndedBaseJobs () {
  yield takeEvery(FETCH_ENDED_BASEJOBS, fetchJobs)
}

export default watchEndedBaseJobs

export default [
  // {
  //   name: 'Billing Rates',
  //   link: '/settings/billing-rates',
  //   icon: 'calculator',
  //   desc: 'Configure billing rates & categories for funders',
  //   enable: true,
  //   permission: 'listBillingRates'
  // },
  {
    name: 'Contacts',
    icon: 'contacts',
    link: '/settings/contacts',
    desc: 'Download Contacts',
    enable: true,
    permission: 'listContacts'
  },
  {
    name: 'Feedback',
    icon: 'star',
    link: '/settings/feedbacks',
    desc: 'Configure feedback settings',
    enable: true,
    permission: 'listFeedbackSettings'
  },
  {
    name: 'File',
    icon: 'file',
    link: '/settings/files',
    desc: 'Configure file settings',
    enable: true,
    permission: 'listFiles'
  },
  {
    name: 'Funders',
    icon: 'block',
    link: '/settings/funders',
    desc: 'Configure Funders',
    enable: true,
    permission: 'listFvpFundingTypes'
  },
  // {
  //   name: 'Funding Types',
  //   icon: 'block',
  //   link: '/settings/funding-types',
  //   desc: 'Configure funding types for Funders',
  //   enable: true,
  //   permission: 'listFundingTypes'
  // },
  {
    name: 'Public Holidays',
    icon: 'calendar',
    link: '/settings/holidays',
    permission: 'listPublicHolidays',
    enable: true,
    desc: 'Configure public holidays for each year'
  },
  {
    name: 'Profile',
    icon: 'idcard',
    link: '/settings/profile',
    permission: 'listProfile',
    enable: true,
    desc: 'Configure profile settings'
  },
  {
    name: 'Reasons',
    link: '/settings/reasons',
    icon: 'exception',
    desc: 'Configure reasons of cancellation',
    enable: true,
    permission: 'listCancellations'
  },
  // {
  //   name: 'Tax Codes',
  //   link: '/settings/tax-codes',
  //   icon: 'number',
  //   desc: 'Configure tax code for under',
  //   enable: true,
  //   permission: 'listTaxCodes'
  // },
  {
    name: 'Others',
    link: '/settings/others',
    icon: 'setting',
    desc: 'Configure other settings',
    enable: true,
    permission: 'listOthers'
  }
]

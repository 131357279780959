import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_JOB_RECURRING_LIST,
  FETCHING_JOB_RECURRING_LIST,
  JOB_RECURRING_LIST_FETCHED
} from '../actions/jvpJobRecurring'
import jvpJobRecurringService from '../../services/jvp/jobRecurring'

function * fetchJobList ({ loading, isForceReload = false, currentPage, pageSize, filter, filterType, sort, searchText }) {
  try {
    yield put({ type: FETCHING_JOB_RECURRING_LIST, loadingList: loading, isLoaded: !isForceReload })

    const jobs = yield jvpJobRecurringService.listViewMainByPage(filterType, currentPage, pageSize, filter, sort, searchText)

    yield put({ type: JOB_RECURRING_LIST_FETCHED, jobs, loadingList: false })
  } catch (e) {
    yield put({ type: JOB_RECURRING_LIST_FETCHED, loadingList: false })
    console.error(e)
  }
}

function * watchJvpJobRecurring () {
  yield takeEvery(FETCH_JOB_RECURRING_LIST, fetchJobList)
}

export default watchJvpJobRecurring

export const FeedbackGenreOptions = Object.freeze([
  // { label: 'Employee', value: 'employee' },
  // { label: 'Client', value: 'client' },
  { label: 'Other People', value: 'people' },
  // { label: 'Job', value: 'job' },
  { label: 'Previous Feedback', value: 'feedback' },
  { label: 'Other Matters', value: 'matters' }
])
export const FeedbackGenreOption = Object.freeze(Object.fromEntries(FeedbackGenreOptions.map((option) => [option.value, option])))
export const FeedbackReportedByOptions = Object.freeze([
  { label: 'Employee', value: 'employee' },
  { label: 'Client', value: 'client' },
  { label: 'Others', value: 'others' }
])
export const FeedbackReportedByOption = Object.freeze(Object.fromEntries(FeedbackReportedByOptions.map((option) => [option.value, option])))
export const FeedbackStatusOptions = Object.freeze([
  { label: 'New', value: 'new' },
  { label: 'Pending Investigation', value: 'pending investigation' },
  { label: 'In Progress', value: 'in progress' },
  { label: 'Completed', value: 'completed' }
])
export const FeedbackStatusOption = Object.freeze(Object.fromEntries(FeedbackStatusOptions.map((option) => [option.value, option])))

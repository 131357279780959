/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { authService, funderService, jobService, settingGeneralService, settingBillingRateService, logService } from '../../../services'
import { fetchingFunders, setRefreshActivityLog } from '../../../states/actions'
import { HelpdeskMenu } from '../../../constants'
import moment from 'moment'
import { accRef, formatter, log, trigger } from '../../../util'
import Moment from 'moment-timezone'

// UI
import { Loading, List, Page, Pager, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Tabs from 'antd/lib/tabs'

//import ClientList from '../Client'

import './styles.css'

const { confirm } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
const pageSize = 30
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'

/**
 * mock data
 */

const mockData = [
  {
    id: 1,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 63912,
    recipient: 'employee',
    recipient_id: 832,
    recipient_name: 'Su Su',
    employee_id: 832,
    employee_name: 'Su Su',
    client_id: 802, // join from job
    client_name: 'Anderson', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-04 03:19:42',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_update',
    subject: 'Your job for Anderson',
    body: 'Hi Su Su, Your job for Anderson (High) ON Mon 08/06/2020 11:00 AM is CANCELLED. Thanks, Anson, Flexi Support',
    created_by: 'Anson',
    created_by_id: 34,
    is_pending_available: true
  },
  {
    id: 2,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46952,
    recipient: 'client',
    recipient_id: 856, // client id
    recipient_name: 'Jefferson',
    employee_id: 202,
    employee_name: 'Sabrina',
    client_id: 856, // join from job
    client_name: 'Jefferson', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-12 09:22:42',
    bound: 'inbound',
    channel_used: 'sms',
    type: 'complaint',
    subject: 'Issues with worker',
    body: 'I would like to make the complain on my worker.',
    created_by: 'Jenny',
    created_by_id: 36,
    is_pending_available: true
  },
  {
    id: 3,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46874,
    recipient: 'employee',
    recipient_id: 832,
    recipient_name: 'Cui',
    employee_id: 832,
    employee_name: 'Cui',
    client_id: 954, // join from job
    client_name: 'Vil (Vilnis)  Dilderats', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-07 15:30:20',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_create',
    subject: 'Your shift for Diidarets',
    body: 'Hi Cui: Your job for Vil (Vilnis)  Dilderats (Standard) When: Tue 09/06/2020 09:00 AM - 11:00 AM At: Scope Australia SRS, 97 Lusher Rd, Croydon Task: Respite and community access. E.g. taking for lunch, going for a walk, going to the shops, playing games and general company. Reply OK to confirm. Thanks, Ayla, Flexi Support',
    created_by: 'system',
    created_by_id: 0,
    is_pending_available: false
  },
  {
    id: 4,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 90344,
    recipient: 'employee',
    recipient_id: 503,
    recipient_name: 'Fiona (Shao Fang)',
    employee_id: 503,
    employee_name: 'Fiona (Shao Fang)',
    client_id: 201, // join from job
    client_name: 'Bi Ying Wu', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-08 19:44:23',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_cancel',
    subject: 'Your job for Bi Ying  Wu',
    body: 'Hi Fiona (Shao Fang), Your job for Bi Ying  Wu  (Standard) ON Thu 04/06/2020 01:00 PM is CANCELLED Please write 3 hour(s) and “Late Cancellation” on your timesheet Please note all services have been put on HOLD until further notice Thanks, Saliha, Flexi Support',
    created_by: 'Saliha',
    created_by_id: 46,
    is_pending_available: true
  },
  {
    id: 5,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46262,
    recipient: 'individual',
    recipient_id: 0,
    recipient_name: 'Alicia Bosch',
    employee_id: 599,
    employee_name: 'Mariam',
    client_id: 355, // join from job
    client_name: 'Bosch', // join from job
    recipient_additional_info: 'Daughter',
    recipient_additional_email: 'jasminebosch@gmail.com',
    recipient_additional_phone_no: '0428 092 207',
    created_at: '2020-06-12 11:55:10',
    bound: 'inbound',
    channel_used: 'call',
    type: 'incident',
    subject: 'Incident happened during serving',
    body: 'Washing machine is damaged while serving',
    created_by: 'Saliha',
    created_by_id: 46,
    is_pending_available: true
  },
  {
    id: 6,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 64624,
    recipient: 'client',
    recipient_id: 251,
    recipient_name: 'Caren',
    employee_id: 515,
    employee_name: 'Sarah',
    client_id: 251, // join from job
    client_name: 'Caren', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-15 01:11:32',
    bound: 'outbound',
    channel_used: 'call',
    type: 'client_contact',
    subject: 'Sarah is sick',
    body: 'Inform Caren that Sarah is sick.',
    created_by: 'Winnie',
    created_by_id: 37,
    is_pending_available: false,
    actions: [
      {
        id: 1,
        created_at: '2020-06-15 01:11:32',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Inform Caren that Sarah is sick.',
        is_follow_up: true
      },
      {
        id: 2,
        created_at: '2020-06-15 13:04:22',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Caren is being informed. The job is cancelled',
        is_follow_up: false
      }
    ]
  },
  {
    id: 7,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 88212,
    recipient: 'individual',
    recipient_id: 0,
    recipient_name: 'Arven Moses',
    employee_id: 708,
    employee_name: 'Yi Heng',
    client_id: 331, // join from job
    client_name: 'Moses', // join from job
    recipient_additional_info: 'Dad',
    recipient_additional_email: '',
    recipient_additional_phone_no: '0412 783 210',
    created_at: '2020-06-16 17:30:21',
    bound: 'inbound',
    channel_used: 'letter',
    type: 'feedback_negative',
    subject: 'Bad atitude to dad',
    body: 'Complain employee giving bad attitude to client',
    created_by: 'Tiffany',
    created_by_id: 38,
    is_pending_available: true,
    actions: [
      {
        id: 3,
        created_at: '2020-06-16 17:30:21',
        created_by: 'Tiffany',
        created_by_id: 38,
        description: 'Complain employee giving bad attitude to client',
        is_follow_up: true
      },
      {
        id: 4,
        created_at: '2020-06-16 20:33:34',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Have been talked to Yi Heng and the case is not as described as Moses.',
        is_follow_up: true
      },
      {
        id: 5,
        created_at: '2020-06-17 07:05:34',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Confirmed with Moses and the cases confirmed. Will further investigate',
        is_follow_up: true
      }
    ]
  },
  {
    id: 8,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 90271,
    recipient: 'client',
    recipient_id: 390,
    recipient_name: 'Johnny',
    employee_id: 762,
    employee_name: 'Ashton',
    client_id: 390, // join from job
    client_name: 'Johnny', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-17 02:22:14',
    bound: 'inbound',
    channel_used: 'email',
    type: 'feedback_positive',
    subject: 'Help alot on client',
    body: 'Good help',
    created_by: 'Janice',
    created_by_id: 27,
    is_pending_available: false
  }
]

const mockJobs = [
  {
    acc_ref: "R00245",
    base_job_id: 9844,
    billing_category_id: 9,
    billing_rate_id: 21,
    cancellation_reason: null,
    cancellation_time: null,
    charge_hours: null,
    client_id: 245,
    client_leave_end: null,
    client_leave_id: null,
    client_leave_start: null,
    created_at: "2020-04-20T16:00:09.056Z",
    day_number: null,
    emergency: false,
    emergency_invoice: false,
    emergency_pay: false,
    emp_accref: "E00523",
    emp_firstname: "Vivienne (Ying)",
    emp_gender: "female",
    emp_id: 513,
    emp_lastname: "Shaw",
    emp_suburb: "Glen Waverley",
    employee_email: "vivienne.shawying@gmail.com",
    employee_id: 513,
    employee_leave_end: null,
    employee_leave_id: null,
    employee_leave_start: null,
    first_name: "Karen",
    fund_fullname: "NDIS",
    funder_id: 38,
    gender: "female",
    get_up_hours: null,
    holiday_date: null,
    holiday_name: null,
    id: 55408,
    is_after_hours_job: null,
    is_cancel: false,
    is_delete: false,
    job_after_hours: null,
    job_after_hours_weekend: null,
    job_end_date: "2020-07-19T22:15:00.000Z",
    job_hours: 14,
    job_normal_hours: null,
    job_normal_hours_weekend: null,
    job_start_date: "2020-07-19T20:15:00.000Z",
    job_start_day: "Monday",
    job_type: "single",
    kms: 0,
    last_name: "Hiam",
    month_setting: null,
    notes: "null",
    payroll: "high",
    payroll_category: "high",
    sleepover: 0,
    status: "pending",
    string_end_date: "20/07/2020",
    string_start_date: "20/07/2020",
    suburb: "Clayton",
    tasks: "Assist client with showering and personal grooming , changing pads, toileting and use of hoist",
    updated_at: "2020-04-20T16:00:09.056Z"
  }
]

const typeList = [
  { name: 'Job Create', value: 'job_create', isEdit: false },
  { name: 'Job Update', value: 'job_update', isEdit: false },
  { name: 'Job Cancel', value: 'job_cancel', isEdit: false },
  { name: 'Complaint', value: 'complaint', isEdit: true },
  { name: 'Client Contact', value: 'client_contact', isEdit: true },
  { name: 'Negative Feedback', value: 'feedback_negative', isEdit: true },
  { name: 'Positive Feedback', value: 'feedback_positive', isEdit: true },
  { name: 'Incident', value: 'incident', isEdit: true }
]

const contactTypeList = [
  { name: 'Employee', value: 'employee' },
  { name: 'Client', value: 'client' },
  { name: 'Others', value: 'individual' }
]

const boundList = [
  { name: 'Inbound', value: 'inbound' },
  { name: 'Outbound', value: 'outbound' }
]

const channelsList = [
  { id: 1, name: 'Call', value: 'call', isEdit: true },
  { id: 2, name: 'Email', value: 'email', isEdit: true },
  { id: 3, name: 'Letter', value: 'letter', isEdit: true },
  { id: 4, name: 'SMS', value: 'sms', isEdit: true },
  { id: 5, name: 'Others', value: 'others', isEdit: true },
  { id: 6, name: 'System', value: 'system', isEdit: false }
]


export class HelpdeskPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      contactType: '',
      currentPageAction: 1,
      searchText: '',
      jobList: [],
      selectedJob: {},
      loading: false,
      showSave: false,
      showEdit: true,
      showSearchLoading: false
    }
    this.fetchTimeout = null
  }

  componentDidMount () {
    this.fetchHelpdesk()
  }

  fetchHelpdesk = async () => {
    try {
      console.log('fetch help', this.isEdit(), this.isEdit(true))
      if (this.isEdit()) {
        this.setState({ loading: true })
        const id = this.isEdit(true)
        const item = mockData.find(e => {console.log('find', e.id === parseInt(id), e); return e.id === parseInt(id);}) || {}
        const job = await this.fetchJob(item)
        console.log('fetch help 2', job)
        this.setState({ item, loading: false, selectedJob: job, contactType: item.recipient || '' })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load message successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchJob = async (item) => {
    try {
      console.log('job 1', item.module, item.module_id, item.module === 'job')
      if (item.module === 'job' && item.module_id) {
        const job = await jobService.getDetail(item.module_id)
        console.log('job 2', job, item.module_id)
        if (job && job.item) {
          return job.item
        }
      }
    } catch (e) {
      notify.error('Unable to load job successfully', 'Unable to load job successfully. Please try again later.')
    }

    return {}
  }

  render () {
    const { match, history } = this.props
    const { item, loading, showSave, showEdit } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Communication' menu={HelpdeskMenu} backLink='/helpdesk' />
        </Page.Left>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Communication (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.bound} - ${item.subject} (View Only)` : showSave ? `${item.bound} - ${item.subject} (Edit Mode)` : 'Communication'}>
            {
              this.isEdit() && this.hasAccess('deleteFunder') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null}
            {
              showEdit && this.isEdit() && this.hasAccess('updateFunder')
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              showSave || !this.isEdit()
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  Save
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            {/* { this.isEdit()
              ? <div className='funder-panel-header'>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.recipient_name}<div className='funder-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Phone Number</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.recipient_additional_phone_no || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Suburb</div>
                      <div className='funder-panel-header-value'>{item.suburb}</div>
                    </Col>
                    <Col lg={4} />
                  </Row> }
              </div> : null
            } */}
            <div className='funder-panel-body'>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Info' key='1'>
                  { this.infoTab() }
                </TabPane>
                { this.isEdit()
                  ? <TabPane tab='Actions' key='2'>
                    { this.actionTab() }
                  </TabPane>
                  : null }
                {/* <TabPane tab='Communications' key='3'>

                </TabPane> */}
                {/* <TabPane tab='Activity Log' key='4'> */}
                  {/* <ActivityLog funderId={match.params.id} history={this.props.history} /> */}
                {/* </TabPane> */}
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { item, contactType, jobList, loading, selectedJob, showSearchLoading } = this.state
    const { getFieldDecorator } = form
console.log('item', selectedJob)
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }
console.log('type', typeList.find(e => e.value === item.type, item.type))
    const isTypeUpdate = typeList.find(e => e.value === item.type) ? !typeList.find(e => e.value === item.type).isEdit : false
    const isChannelUpdate = channelsList.find(e => e.value === item.channel_used) ? !channelsList.find(e => e.value === item.channel_used).isEdit : false

    /**
     * {
      id: 2,
      co_id: 1,
      target: 'private',
      module: 'job',
      module_id: 46952,
      recipient: 'client',
      recipient_id: 856, // client id
      recipient_name: 'Jefferson',
      employee_id: 202,
      employee_name: 'Sabrina',
      client_id: 856, // join from job
      client_name: 'Jefferson', // join from job
      recipient_additional_info: '',
      recipient_additional_email: '',
      recipient_additional_phone_no: '',
      created_at: '2020-06-12 09:22:42',
      bound: 'inbound',
      channel_used: 'sms',
      type: 'complaint',
      subject: 'Issues with worker',
      body: 'I would like to make the complain on my worker.',
      created_by: 'Jenny',
      created_by_id: 36,
      is_pending_available: true
    }
     */

    return <Form>
      <Panel>
        <Row gutter={12}>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Bound'>
              {getFieldDecorator('bound', {
                initialValue: item.bound || undefined,
                rules: [
                  { required: true, message: 'Please select bound.' }
                ]
              })(
                <Select
                  placeholder='Select bound'
                  showSearch
                  disabled={this.isEdit()}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    boundList.map((type) => (
                      <Option key={`boundType${type.value}`} value={type.value}>{type.name}</Option>
                    ))
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Ticket Type'>
              {getFieldDecorator('type', {
                initialValue: item.type || undefined,
                rules: [
                  { required: true, message: 'Please select a type.' }
                ]
              })(
                <Select
                  placeholder='Select a type'
                  showSearch
                  disabled={isTypeUpdate}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    this.isEdit() && isTypeUpdate
                    ? typeList.map((type) => (
                      <Option key={`ticType${type.value}`} value={type.value}>{type.name}</Option>
                    ))
                    : typeList.filter(e => e.isEdit === true).map((type) => (
                      <Option key={`ticType${type.value}`} value={type.value}>{type.name}</Option>
                    ))
                  }
                </Select>
              )}
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Channel'>
              {getFieldDecorator('channel', {
                initialValue: item.channel_used || undefined,
                rules: [
                  { required: true, message: 'Please select a channel' }
                ]
              })(
                <Select placeholder='Select a channel'
                  showSearch
                  disabled={isChannelUpdate}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    this.isEdit() && isChannelUpdate
                    ? channelsList.map((channel, idx) => {
                      return <Option key={`chType${channel.value}`} value={channel.value}>{channel.name}</Option>
                    })
                    : channelsList.filter(e => e.isEdit === true).map((channel, idx) => {
                      return <Option key={`chType${channel.value}`} value={channel.value}>{channel.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>

        </Row>

        <Row gutter={24}>
          <Col lg={24}>
            <FormItem {...formItemLayout} label='Job'>
              {getFieldDecorator('job', {
                initialValue: selectedJob && selectedJob.id ? `${selectedJob.id} - ${selectedJob.first_name || selectedJob.client_first_name} ${selectedJob.last_name || selectedJob.client_last_name} by ${selectedJob.emp_firstname} ${selectedJob.emp_lastname}`: undefined
              })(
                <Select placeholder='Type job info to find desired jobs or leave blank.'
                  showSearch
                  loading={showSearchLoading}
                  onSearch={e => this.searchJobs(e)}
                  filterOption={false}
                  notFoundContent={showSearchLoading ? <div>Getting Jobs...</div> : <div>No jobs available.</div>}
                  onSelect={(e) => this.onSelectJob(e)}
                  onDeselect={() => this.onSelectJob('')}
                  >
                  {
                    jobList.map((job, idx) => {
                      // console.log('job', job, job.id)
                      return <Option key={`job${job.id}`} value={job.id}>{`${job.id} - ${job.first_name} ${job.last_name} by ${job.emp_firstname} ${job.emp_lastname}`}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Contact Type'>
              {getFieldDecorator('recipient', {
                initialValue: item.recipient || undefined,
                rules: [
                  { required: true, message: 'Please select a contact type' }
                ]
              })(
                <Select placeholder='Select contact type'
                  showSearch
                  onSelect={(e) => this.updateContactType(e)}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    contactTypeList.map((c, idx) => {
                      return <Option key={`contactType${c.value}`} value={c.value}>{c.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          { contactType === 'individual'
          ? <div>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Name'>
                {getFieldDecorator('recipient_additional_info', {
                  initialValue: item.recipient_additional_info || undefined,
                  rules: [
                    { required: true, message: 'Please enter the contact name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Relationship'>
                {getFieldDecorator('recipient_additional_rs', {
                  initialValue: item.recipient_additional_rs || undefined,
                  rules: [
                    { required: true, message: 'Please enter the contact relationship' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Email'>
                {getFieldDecorator('recipient_additional_email', {
                  initialValue: item.recipient_additional_email || undefined,
                  rules: [
                    { required: true, message: 'Please enter the contact email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Phone No'>
                {getFieldDecorator('recipient_additional_phone_no', {
                  initialValue: item.recipient_additional_phone_no || undefined,
                  rules: [
                    { required: true, message: 'Please enter the contact phone no' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </div>
          : null }
        </Row>

        <Row gutter={24}>
          <Col lg={24}>
            <FormItem {...formItemLayout} label='Subject'>
              {getFieldDecorator('subject', {
                initialValue: item.subject ? item.subject : undefined,
                rules: [
                  { required: true, message: 'Please enter subject of the ticket' }
                ]
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>

        { !this.isEdit()
          ? <div>
            <Row gutter={24}>
            <Col lg={24}>
              <FormItem {...formItemLayout} label='Message'>
                {getFieldDecorator('body', {
                  initialValue: item.body ? item.body : undefined,
                  rules: [
                    { required: true, message: 'Please enter message of the ticket' }
                  ]
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
          </Row>
          </div>
          : null }



        {/* <Row gutter={12}>
          <Col lg={12}>
          <FormItem {...sideBySideFormItemLayout} label='Contact Type'>
              {getFieldDecorator('recipient', {
                initialValue: item.recipient || '',
                rules: [
                  { required: true, message: 'Please select a contact type' }
                ]
              })(
                <Select placeholder='Select a recipient type'
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    contactTypeList.map((c, idx) => {
                      return <Option key={`contactType${c.value}`} value={c.value}>{c.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row> */}

        {/* <Row gutter={12}>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Contact Person'>
              {getFieldDecorator('contact', {
                initialValue: item.contact || ''
              })(
                <Input />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>

          </Col>
        </Row> */}
        {/* <FormItem {...formItemLayout} label='Description' hasFeedback>
          {getFieldDecorator('description', {
            initialValue: item.description || ''
          })(
            <TextArea rows={4} />
          )}
        </FormItem> */}
      </Panel>
    </Form>
  }

  actionTab = () => {
    const { form } = this.props
    const { item, loading, currentPageAction } = this.state
    const { getFieldDecorator } = form
    const { actions } = item
    // const actionList = Object.assign({}, actions)
    // const reversed = actionList.reverse()
    const actionColumns = [
      {
        title: 'User',
        width: 4,
        render: ({ created_by: createdBy }) => createdBy
      },
      {
        title: 'Date',
        width: 3 ,
        render: ({ created_at: createdAt }) => formatter.toStandardDate(createdAt)
      },
      {
        title: 'Description',
        width: 5,
        key: 'description'
      },
      {
        title: '',
        width: 1,
        // render: ({ id }) => <div className='action-buttons'><Link to={`/helpdesk/${id}`}><Tooltip mouseLeaveDelay={0} title='Edit Ticket'> <Icon type='form' style={{ color: 'orange' }} /></Tooltip></Link></div>
        render: ({ is_follow_up }) => is_follow_up
          ? <div style={{ color: '#3273ff', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#ccc', fontSize: '11.5pt', cursor: 'pointer' }}><Tooltip mouseLeaveDelay={0} title='Submit Completion'><Icon type='check-circle' /></Tooltip></div>
      }
    ]

    return (
      <div>
        <Page.Filter>
          <Row gutter={8}>
          </Row>
        </Page.Filter>
        <div className='helpdesk-list'>
          <Skeleton loading={loading} active>
            <List cols={actionColumns} rows={actions} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={item.length}
          totalText={`Total ${item.length} records`}
          current={currentPageAction}
          onChange={(page) => this.refreshList('confirmation', page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = (isReturnId = false) => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return isReturnId ? id : id !== 'add'
  }

  updateContactType (type) {
    this.setState({contactType: type})
  }

  searchJobs (value) {
    console.log('search jobs', value)
    let filter = {}
    if (this.fetchTimeout) clearTimeout(this.fetchTimeout)
    if (value) {
      if (value.indexOf(' ') >= 0) {
        const words = value.split(' ')
        console.log('search jobs 1.1', words)
        if (Array.isArray(words)) {
          filter.$and = []

          for (let i = 0; i < words.length; i++) {
            filter.$and.push({
              $or: [
                { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
                { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
                { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
                { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
                { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
                { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
                { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
              ]
            })
          }
          console.log('filters:', filter)
        }
      } else {
        if (Array.isArray(filter.$and)) {
          delete filter.$and
        }
      }
      console.log('search jobs 2', filter)
      this.fetchTimeout = setTimeout(() => this.fetchClientJob({ searchText: (filter.$and ? '' : value) }), 1200)
    } else {
      this.setState({ jobList: [] })
    }
    this.setState({ filter, searchText: value })
  }

  fetchClientJob = async ({ filter, searchText }) => {
    this.setState({ showSearchLoading: true })
    const { clientId } = this.props
    const sorting = { job_start_date: -1 }
    const nowDate = Moment().startOf('day')
    console.log('search jobs 3.1', searchText)
    const { list: jobList, total } = await jobService.listByPage(1, 20, filter, sorting, searchText)
    console.log('search jobs 3.2', jobList)

    this.setState({ jobList, showSearchLoading: false, searchText })
  }

  onSelectJob = (jobId) => {
    const { jobList = [] } = this.state
    const job = jobId ? jobList.find(e => e.id === jobId) : null
    console.log('on select job', jobId, job)
    if (job) {
      this.setState({selectedJob: job})
    } else {
      this.setState({selectedJob: {}})
    }
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(HelpdeskPage))

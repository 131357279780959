import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchTimesheetsFvp, fetchTimesheetSummaryFvp } from '../../../states/actions'
import { PayrollFvpMenu } from '../../../constants'
import { formatter, localStorage } from '../../../util'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, Page, Pager, SearchInput, List } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 30

export class Payroll extends Component {
  constructor (props) {
    super(props)
    const { timesheets: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: {},
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      total
    }
    this.onSearch = debounce(this.onSearch, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { timesheets: { list, total }, timesheetSummary: { date_from, date_to, payroll_id, employees, clients, kms, sleepovers, getups, hours, jobs, review, signed, approved, rejected }, loading } = nextProps
    const state = { ...prevState, employees, clients, loading, kms, sleepovers, getups, hours, jobs, review, signed, approved, rejected, date_from, date_to, payroll_id }
    if (list !== prevState.list) {
      state.searching = false
    }
    state.list = list
    state.total = total
    return state
  }

  componentDidMount () {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = { id }
    const { currentPage, loading, searchText, sort } = this.state

    this.fetchTimesheetSummaryFvp()
    this.fetchTimesheetsFvp({ loading: true, currentPage, filter, searchText, sort })
    this.setState({ filter })
  }

  onSearch (value) {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true })
    this.fetchTimesheetsFvp({ currentPage: 1, filter, loading, searchText: value, sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onChangeView (event) {
    const value = event.target.value
    localStorage.setItem('timesheetView', value)

    this.setState({ list: [], total: 0 })
    const { filter, sort, searchText } = this.state

    this.fetchTimesheetSummaryFvp()
    this.fetchTimesheetsFvp({ currentPage: 1, filter, loading: true, searchText, sort })
  }

  onFilter (event) {
    const { filter, loading, sort, searchText } = this.state
    const value = event.target.value

    delete filter.approved
    delete filter.review
    delete filter.signed
    delete filter.rejected

    if (value !== 'all') {
      if (value === 'approved') {
        filter.review = { condition: '=', value: '0' }
        filter.approved = { condition: '>', value: '0' }
      } else if (value === 'signed') {
        filter.signed = { condition: '>', value: '0' }
      } else if (value === 'rejected') {
        filter.rejected = { condition: '>', value: '0' }
      } else {
        filter.review = { condition: '>', value: '0' }
      }
    }

    this.fetchTimesheetsFvp({ currentPage: 1, filter, loading: true, searchText, sort })
    this.setState({ filter })
  }

  getTimesheetView () {
    return localStorage.getItem('timesheetView') || 'employee'
  }

  isEmployeeView () {
    return this.getTimesheetView() === 'employee'
  }

  render () {
    const { filter, currentPage, list, date_from, date_to, payroll_id, employees = 0, clients = 0, jobs = 0, total = 0, kms = 0, hours = 0, sleepovers = 0, getups = 0, approved = 0, review = 0, rejected = 0, signed = 0, searching } = this.state
    const { loading } = this.props

    const columns = [

      this.isEmployeeView() ? {
        title: 'Employees',
        width: 5,
        render: ({ employee, employee_id, employee_leave_id, employee_leave_start, employee_leave_end, emp_active }) => (employee_leave_id ?
          <div className={`${!emp_active ? 'timesheet-label-dim' : ''}`}><a href={`/employees/${employee_id}`} target='_blank'><span style={{ fontWeight: 'bold' }}><Icon type='user' /> {employee}</span></a>&nbsp;
            <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div>
          </div> : (
            <div>
              <a href={`/employees/${employee_id}`} target='_blank'>
                <span className={`timesheet-label ${!emp_active ? 'timesheet-label-dim' : ''}`}><Icon type='user' /> {employee}</span>
              </a>
            </div>)
        ) } : {
        title: 'Clients',
        width: 5,
        render: ({ client, client_id, client_leave_id, client_leave_start, client_leave_end, client_active, client_leave_is_ufn }) => (client_leave_id ?
          <div className={`${!client_active ? 'timesheet-label-dim' : ''}`}><a href={`/clients/${client_id}`} target='_blank'><span style={{ fontWeight: 'bold' }}><Icon type='user' /> {client}</span></a>&nbsp;
            <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(client_leave_start)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end)}`}</div>
          </div> : (
            <div>
              <a href={`/clients/${client_id}`} target='_blank'>
                <span className={`timesheet-label ${!client_active ? 'timesheet-label-dim' : ''}`}><Icon type='user' /> {client}</span>
              </a>
            </div>
          )
        ) },
      {
        title: 'Acc Ref',
        width: 2,
        render: ({ acc_ref }) => <div className='timesheet-label-second'>{acc_ref}</div>
      },
      this.isEmployeeView()
      ? {
          title: 'Clients',
          width: 2,
          render: ({ clients }) => <div style={{ color: '#fc6a03', fontWeight: 'bold' }}>{clients}</div>
        }
      : {
          title: 'Employees',
          width: 2,
          render: ({ employees }) => <div style={{ color: '#fc6a03', fontWeight: 'bold' }}>{employees}</div>
        },
      {
        title: 'Jobs',
        width: 2,
        render: ({ jobs, outside_funding_period_count }) => parseFloat(outside_funding_period_count) > 0
        ? <div style={{ color: '#1a63b0', fontWeight: 'bold', textTransform: 'none' }}>{jobs}&nbsp;<Tooltip title={`Some job(s) is/are Outside of client's funding period`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div>
        : <div style={{ color: '#1a63b0', fontWeight: 'bold' }}>{jobs}</div>
      },
      {
        title: 'Approved',
        width: 2,
        render: ({ approved }) => <div style={{ color: parseFloat(approved) > 0 ? '#2ec77a' : '#aaa' }}>{approved}</div>
      },
      {
        title: 'To Review / Signed',
        width: 3,
        render: ({ review, signed }) => <div>
          <span style={{ color: parseFloat(review) > 0 ? '#f50' : '#aaa' }}>{review}</span> / <span style={{ color: parseFloat(signed) > 0 ? '#e8419d' : '#aaa' }}>{signed}</span>
        </div>
      },
      {
        title: 'Rejected',
        width: 2,
        render: ({ rejected }) => <div style={{ color: parseFloat(rejected) > 0 ? '#ff0000' : '#aaa' }}>{rejected}</div>
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ hours }) => formatter.toDecimalS(hours) || 0
      },
      {
        title: 'Sleepovers',
        width: 2,
        render: ({ sleepovers }) => sleepovers || 0
      },
      {
        title: 'Getups',
        width: 1,
        render: ({ getups }) => getups || 0
      },
      {
        title: 'KMs',
        width: 1,
        render: ({ kms }) => formatter.toDecimalS(kms) || 0
      },
      this.isEmployeeView()
      ? {
          title: '',
          width: 1,
          render: ({ id, employee_id }) => <Link to={`/payrolls/${id}/employee/${employee_id}`} className='button'><Icon type='form' /></Link>
        }
      : {
          title: '',
          width: 1,
          render: ({ id, client_id }) => <Link to={`/payrolls/${id}/client/${client_id}`} className='button'><Icon type='form' /></Link>
        }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Payrolls' menu={PayrollFvpMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header icon={<Icon type='table' />} title='Timesheet' total={payroll_id ? `${formatter.toShortDate(date_from)} - ${formatter.toShortDate(date_to)}` : null}>
            <div className='btn' onClick={() => window.open(`/print/payroll/${filter.id}`)}>Print</div>
          </Page.Header>

          <div className='page-summary'>
            <Row>
              {
                this.isEmployeeView() ? (
                  <Col lg={3} className='col'>
                    <div className='number'>{employees}</div>
                  Employees
                  </Col>)
                  : (
                    <Col lg={3} className='col'>
                      <div className='number'>{clients}</div>
                    Clients
                    </Col>
                  )
              }
              <Col lg={3} className='col'>
                <div className='number'>{jobs}</div>
                Jobs
              </Col>
              <Col lg={3} className='col'>
                <div className='number'>{approved}</div>
                Approved
              </Col>
              <Col lg={3} className='col'>
                { payroll_id
                  ? <div className='number'>
                    <Link to={`/payrolls/${payroll_id}/review`}>
                      {review}
                    </Link>&nbsp;/&nbsp;
                    <Link to={`/payrolls/${payroll_id}/signed`}>
                      {signed}
                    </Link>
                  </div>
                  : <div className='number'>
                    {review} / {signed}
                  </div> }
                To Review / Signed
              </Col>
              <Col lg={2} className='col'>
                <div className='number'>{rejected}</div>
                Rejected
              </Col>
              <Col lg={3} className='col'>
                <div className='number'>{hours}</div>
                Hours
              </Col>
              <Col lg={2} className='col'>
                <div className='number'>{sleepovers || 0}</div>
                Sleepovers
              </Col>
              <Col lg={2} className='col'>
                <div className='number'>{getups || 0}</div>
                Getups
              </Col>
              <Col lg={3} className='col'>
                <div className='number'>{kms}</div>
                KMs
              </Col>
            </Row>
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Search</ControlLabel>
                <SearchInput placeholder='Name, Acc Ref' onChange={(v) => this.onSearch(v)} isSearching={searching} />
              </Col>
              <Col lg={4}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group defaultValue={this.getTimesheetView()} onChange={(v) => this.onChangeView(v)}>
                  <Radio.Button value='employee'> Employee </Radio.Button>
                  <Radio.Button value='client'> Client </Radio.Button>
                </Radio.Group>
              </Col>
              <Col lg={12}>
                <ControlLabel>Status</ControlLabel>
                <Radio.Group defaultValue='all' onChange={(v) => this.onFilter(v)}>
                  <Radio.Button value='all'> All </Radio.Button>
                  <Radio.Button value='approved'>Complete</Radio.Button>
                  <Radio.Button value='review'>Incomplete</Radio.Button>
                  <Radio.Button value='rejected'>Rejected</Radio.Button>
                  <Radio.Button value='signed'>Signed</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div className='payroll'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} ${this.isEmployeeView() ? 'employee' : 'client'}${total > 1 ? 's' : ''}`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchTimesheetsFvp({ currentPage, filter, searchText, sort })
  }

  fetchTimesheetsFvp = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchTimesheetsFvp } = this.props
      this.setState({ currentPage, loading: true })
      fetchTimesheetsFvp({ loading, currentPage, pageSize, filter, sort, searchText, view: this.getTimesheetView() })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchTimesheetSummaryFvp = async () => {
    const { match, fetchTimesheetSummaryFvp } = this.props
    const { params } = match
    const { id } = params

    try {
      fetchTimesheetSummaryFvp({ id, view: this.getTimesheetView() })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchTimesheetsFvp,
  fetchTimesheetSummaryFvp
}

const mapStateToProps = (state) => {
  return { ...state.PayrollFvp }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payroll)

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_RECURRING_JOBS_LIST, FETCHING_RECURRING_JOBS_LIST, RECURRING_JOBS_LIST_FETCHED } from '../actions/recurringJobList'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, baseJobsId }) {
  try {
    yield put({ type: FETCHING_RECURRING_JOBS_LIST, loading, baseJobsId })
    const jobs = yield baseJobService.getJobList(baseJobsId)
    yield put({ type: RECURRING_JOBS_LIST_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_RECURRING_JOBS_LIST, loading: false })
    console.error(e)
  }
}

function * watchRecurringJobList () {
  yield takeEvery(FETCH_RECURRING_JOBS_LIST, fetchJobs)
}

export default watchRecurringJobList

import { localStorage, notification, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/api'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  getCurrentUser () {
    return localStorage.getObject('user')
  },
  hasAccess (permission) {
    try {
      if (permission) {
        const permissions = localStorage.getObject('permissions', [])
        return permissions.indexOf(permission) > -1
      }

      return false
    } catch (e) {
      return false
    }
  },
  isSignedIn () {
    const token = localStorage.getItem('token')
    return token && token !== 'undefined' && token.length > 0
  },
  resetPassword (email) {
    try {
      const result = request(`${url}/reset-password`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signIn (username, password, newPassword, rememberMe) {
    try {
      const { location } = window
      const { search } = location
      const result = request(`${url}/login${search}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username, password, newPassword, rememberMe })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signOut () {
    try {
      const token = this.getCurrentToken()
      const user = this.getCurrentUser()
      const result = request(`${url}/logout`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ token, user })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  unauthorizeAction (redirectPath, duration, callback) {
    if (typeof callback === 'function') {
      callback()
    } else {
      redirectPath = typeof redirectPath === 'string' ? redirectPath : '/'
      duration = typeof duration === 'number' ? duration : 3000
      notification.show('error', 'You do not have permission to perform this action.')
      setTimeout(() => window.location.replace(redirectPath), duration)
    }
  }
}

import { ADMIN_ROLES_FETCHED, ADMIN_ROLE_SET_PRISTINE } from '../actions/adminRole'

const INITIAL_STATE = {
  adminRoles: { list: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_ROLES_FETCHED:
      return { ...state, ...handleAdminRolesFetched(action) }
    case ADMIN_ROLE_SET_PRISTINE:
      return { ...state, ...handleAdminRoleSetPristine(action) }
    default:
      return state
  }
}

function handleAdminRolesFetched (action) {
  const { adminRoles } = action
  return { adminRoles, pristine: false }
}

function handleAdminRoleSetPristine (action) {
  const { pristine } = action
  return { pristine }
}

export const FETCH_RECURRING_JOBS_LIST = 'FETCH_RECURRING_JOBS_LIST'
export const FETCHING_RECURRING_JOBS_LIST = 'FETCHING_RECURRING_JOBS_LIST'
export const RECURRING_JOBS_LIST_FETCHED = 'RECURRING_JOBS_LIST_FETCHED'

export const fetchRecurringJobsList = (params) => {
  return Object.assign({
    type: FETCH_RECURRING_JOBS_LIST
  }, params)
}

export const fetchingRecurringJobsList = (loading) => {
  return {
    type: FETCHING_RECURRING_JOBS_LIST,
    loading
  }
}

export const recurringJobsListFetched = (jobs) => {
  return {
    type: RECURRING_JOBS_LIST_FETCHED,
    jobs
  }
}

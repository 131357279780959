import React, { Component } from 'react'
import { connect } from 'react-redux'
import { apiHostname } from '../../../../config'
import { authService, funderFileService, jobService, settingFileCategoryService, settingFileTypeService } from '../../../../services'
import { setRefreshActivityLog, setRefreshFiles } from '../../../../states/actions'
import { formatter, log, uploader, validator } from '../../../../util'
import Moment from 'moment-timezone'
import moment from 'moment'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager, FileUpload, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 20

const dateFormat = 'DD/MM/YYYY'
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class JobFile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      loadingFile: false,
      loadingForm: false,
      isShowFileRule: false,
      list: [],
      filter: {},
      filterParam: '',
      filteredSubCategories: [],
      mainCategories: [],
      searchText: '',
      sort: {},
      subCategories: [],
      total: 0,
      modal: {
        edit: false,
        item: {},
        show: false
      },
      currentFileName: '',
      currentSubCategory: {},
      currentMainCategory: {}
    }
  }

  componentWillReceiveProps (nextProps) {
    const { onRefreshFiles } = this.props
    const { currentPage, filter, sort, searchText, filterParam } = this.state

    if (nextProps.shouldRefreshFiles !== this.props.shouldRefreshFiles) {
      if (nextProps.shouldRefreshFiles) {
        this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
        onRefreshFiles(false)
      }
    }
  }

  componentDidMount () {
    const { currentPage, filter, sort, searchText, filterParam } = this.state

    this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
    this.fetchMainCategories()
    this.fetchSubCategories()
  }

  filterFileActive (e) {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.active = filterValue
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort, filterParam: filterValue })
  }

  filterFileMandatory (e) {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.has_mandatory = filterValue
    } else {
      delete filter.has_mandatory
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort })
    // this.fetchFiles({ currentPage, filter, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, sort } = this.state
    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { label: { condition: 'ilike', value: `%${words[i]}%` } },
              { main_category: { condition: 'ilike', value: `%${words[i]}%` } },
              { sub_category: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchFiles({ currentPage, filter, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  render () {
    const { form, jobId } = this.props
    const { getFieldDecorator } = form
    const { currentPage, loading, loadingFile, loadingForm, list, total, modal, isShowFileRule,
      mainCategories, subCategories, filteredSubCategories, currentSubCategory } = this.state
    const { edit, item: modalItem, show: modalShow } = modal

    const columns = [

      {
        title: 'Main Category',
        width: 4,
        key: 'main_category'
      },

      {
        title: 'Sub Category',
        width: 4,
        key: 'sub_category'
      },

      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },

      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },

      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },

      {
        title: 'Enabled',
        width: 2,
        render: ({ active }) => active ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <div style={{ color: '#ccc' }}><Icon type='check-circle' /></div>
      },

      {
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {/* {this.hasAccess('createFunderFile') ? ( */}
            <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.showModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          {/* ) : null} */}
          <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.file_name)}`}><div onClick={this.handleDownload(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div></Tooltip>
          {/* {this.hasAccess('deleteFunderFile') ? ( */}
            <div>
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
                  onConfirm={() => this.handleDelete(item.id, item)}
                  okText='Yes'
                  cancelText='No'
                ><Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            </div>
          {/* ) : null} */}
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Label, Main Category, Sub Category</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{ display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end' }}>
              <div className='task-header'>
                {/* {this.hasAccess('createFunderFile') ? ( */}
                  <div className='btn' onClick={() => this.showModal(false)}>
                    Add File
                  </div>
                {/* ) : null} */}
              </div>
            </Col>
          </Row>

          <Row gutter={8} style={{ marginTop: 13 }}>
            {/* <Col lg={6}>
              <ControlLabel>Mandatory</ControlLabel>
              <Radio.Group onChange={(e) => this.filterFileMandatory(e)} defaultValue=''>
                <Radio.Button value=''>All</Radio.Button>
                <Radio.Button value='true'>Mandatory</Radio.Button>
                <Radio.Button value='false'>Non-Mandatory</Radio.Button>
              </Radio.Group>
            </Col> */}

            <Col lg={8}>
              <ControlLabel>Enabled</ControlLabel>
              <Radio.Group onChange={(e) => this.filterFileActive(e)} defaultValue=''>
                <Radio.Button value=''>All</Radio.Button>
                <Radio.Button value='true'>Active</Radio.Button>
                <Radio.Button value='false'>Inactive</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <SideModal
              title='File (V1)'
              showModal={modalShow}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Main Category'>
                  {getFieldDecorator('main_category_id', {
                    initialValue: modalItem.main_category_id || '',
                    rules: [
                      { required: true, message: 'Please select main category' }
                    ]
                  })(
                    <Select placeholder='Please select main category'
                      onChange={(mainId) => this.handleMainCategory(mainId)}
                      disabled={edit}>
                      {
                        mainCategories.map((main) => (
                          <Option key={main.name} value={main.id}>{main.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Sub Category'>
                  {getFieldDecorator('sub_category_id', {
                    initialValue: modalItem.sub_category_id || '',
                    rules: [
                      { required: true, message: 'Please select sub category' }
                    ]
                  })(
                    <Select placeholder='Please select sub category'
                      onChange={(subId) => this.handleSubCategory(subId)}
                      disabled={edit}>
                      {edit
                        ? subCategories.map((sub) => (
                          <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                        ))
                        : filteredSubCategories.map((sub) => (
                          <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Issuance Date'>
                  {getFieldDecorator('issuance_date', {
                    rules: [
                      { required: true, message: 'Please enter issuance date' }
                    ],
                    initialValue: modalItem.issuance_date ? Moment(modalItem.issuance_date) : null
                  })(
                    <DatePicker onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} />
                  )}
                </FormItem>

                {currentSubCategory.has_expiry || modalItem.expiry_date
                  ? <FormItem label='Expiry Date'>
                    {getFieldDecorator('expiry_date', modalItem.expiry_date ? {
                      rules: [
                        { required: true, message: 'Please enter expiry date' }
                      ],
                      initialValue: Moment(modalItem.expiry_date).isValid() ? Moment(modalItem.expiry_date) : null
                    } : {
                      rules: [
                        { required: true, message: 'Please enter expiry_date' }
                      ]
                    })(
                      <DatePicker format={dateFormat} />
                    )}
                  </FormItem>
                  : null}

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: modalItem.label,
                    rules: [
                      { min: 2, message: 'Label must be between 2 and 128 characters' },
                      { max: 128, message: 'Label must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FormItem>
                  <FileUpload
                    file={modalItem.file_url}
                    fileName={modalItem.file_name}
                    loading={loadingFile}
                    readOnly={false}
                    showError={isShowFileRule}
                    upload={{
                      action: `${apiHostname}/private/api/jobs/files/upload/files`,
                      beforeUpload: this.checkFile,
                      data: { id: modalItem.id, moduleId: jobId },
                      disabled: loading,
                      headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                      name: 'file',
                      onChange: this.handleUploadFile,
                      showUploadList: false
                    }}
                  />

                  {isShowFileRule
                    ? <div style={{ marginTop: '5px', color: 'red' }}>Please upload file</div>
                    : null }
                </FormItem>

                {edit
                  ? (
                    <FormItem label='Uploaded By'>
                      {modalItem.created_by}
                    </FormItem>)
                  : null}

                {edit
                  ? (
                    <FormItem label='Uploaded On'>
                      {formatter.toStandardDate(modalItem.created_at)}
                    </FormItem>
                  )
                  : null}

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} files`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  changePage = async (currentPage) => {
    this.fetchFiles(currentPage)
  }

  fetchFiles = async ({ currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { jobId } = this.props

    filter.module = { condition: '=', value: 'job' }
    filter.module_id = { condition: '=', value: jobId }

    const { list, total } = await jobService.listFileByPage(currentPage, pageSize, filter, sort, searchText)
    this.setState({ list, total, loading: false, currentPage, filter })
  }

  fetchMainCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })

      filter.active = { condition: '=', value: true }
      filter.classification = { condition: '=', value: 'job' }

      const mainCategories = await settingFileCategoryService.listByPage(1, 0, filter)

      this.setState({ mainCategories: mainCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load main categories successfully. Please try again later.')
    }
  }

  fetchSubCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })
      const subCategories = await settingFileTypeService.listByPage(1, 0, filter)

      this.setState({ subCategories: subCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load sub categories successfully. Please try again later.')
    }
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ loadingForm: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCategory } = this.state

    if (value) {
      if (currentSubCategory.has_expiry && currentSubCategory.expiry_month) {
        const expiryMonth = parseInt(currentSubCategory.expiry_month)
        const expiryDate = moment(value).add(expiryMonth, 'months')

        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ loadingForm: false })
  }

  handleMainCategory = (mainId) => {
    this.setState({ loadingForm: true })
    const { subCategories, mainCategories } = this.state
    const subs = subCategories.filter(item => mainId === item.file_category_id)
    const selectedMainCat = mainCategories.find(item => mainId === item.id)
    this.setState({
      loadingForm: false,
      isShowSubCategory: true,
      filteredSubCategories: subs,
      currentMainCategory: selectedMainCat
    })
  }

  handleSubCategory = (subId) => {
    this.setState({ loadingForm: true })
    const { subCategories } = this.state
    const selectedSubCat = subCategories.find(item => subId === item.id)
    this.setState({ currentSubCategory: selectedSubCat })
  }

  handleUploadFile = async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loadingForm: true })
    }

    if (status === 'done') {
      const { modal } = this.state
      const { edit, item } = modal
      const { fileUrl } = response
      const lastIndex = fileUrl.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

      if (edit) {
        const currentFileName = formatter.toStandardFileName(item.file_name)
        this.setState({ currentFileName })
      }

      item.file_url = fileUrl
      item.file_name = fileName
      this.setState({ modal, loadingFile: false, loadingForm: false, isShowFileRule: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { modal } = this.state
        const { edit, item } = modal
        const { UploadType } = uploader
        const response = await uploader.upload(item.id, info, token, UploadType.FILE)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = fileUrl.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

          if (edit) {
            const currentFileName = formatter.toStandardFileName(item.file_name)
            this.setState({ currentFileName })
          }

          item.file_name = fileName
          item.file_url = fileUrl
          this.setState({ modal })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleSave = () => {
    const { form, jobId, onUpdateInfo, onRefreshPage } = this.props
    const { modal, currentSubCategory, currentMainCategory, currentFileName, currentPage, filter, sort, searchText } = this.state
    const { edit, item } = modal
    const { file_url: fileUrl, file_name: fileName } = item
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors && fileUrl) {
        this.setState({ loadingForm: true, isShowFileRule: false })

        values.module = 'job'
        values.module_id = jobId
        values.file_url = fileUrl
        values.file_name = fileName
        values.label = values.label || ''
        values.type = currentSubCategory.name

        let logItemBefore = {
          label: item.label || '',
          file_name: currentFileName || '',
          active: item.active,
          issuance_date: item.issuance_date
        }

        let logItemAfter = {
          label: values.label || '',
          file_name: formatter.toStandardFileName(fileName),
          active: values.active,
          issuance_date: values.issuance_date
        }

        if (currentSubCategory.has_expiry && values.expiry_date) {
          logItemBefore.expiry_date = item.expiry_date || ''
          logItemAfter.expiry_date = values.expiry_date || ''
        }

        try {
          if (edit) {
            const response = await jobService.saveFile(item.id, values)

            if (response.id) {
              const extraLog = []

              extraLog.push(`${currentMainCategory.name} - ${currentSubCategory.name}${values.label ? ` - ${values.label}` : ''}${fileName ? `(${formatter.toStandardFileName(fileName)})` : ''}`)

              log.updateJobFile(jobId, logItemBefore, logItemAfter, [], extraLog.join())

              this.setState({ loadingForm: false })
              this.fetchFiles({ currentPage, filter, sort, searchText })
              this.hideModal()
              // onUpdateInfo()
              onRefreshPage()
              notify.success('Saved successfully', 'File saved successfully')
            }
          } else {
            const response = await jobService.addFile(values)

            if (response.id) {
              const extraLog = []
              extraLog.push(`${currentMainCategory.name} - ${currentSubCategory.name}${values.label ? ` - ${values.label}` : ''}${fileName ? `(${formatter.toStandardFileName(fileName)})` : ''}`)

              log.addJobFile(jobId, logItemAfter, [], extraLog.join())

              this.setState({ loadingForm: false })
              this.fetchFiles({ currentPage, filter, sort, searchText })
              this.hideModal()
              // onUpdateInfo()
              onRefreshPage()
              notify.success('Saved successfully', 'File saved successfully')
            }
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save file successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }

      } else {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  async handleDelete (id, info) {
    const { jobId, onUpdateInfo, onRefreshPage } = this.props
    const { currentPage, filter, sort, searchText } = this.state
    this.setState({ loading: true })

    const response = await jobService.removeFile(id)
    this.setState({ loading: false })

    if (response.id) {
      let changes = `Delete file${info.main_category ? ` ${info.main_category}` : ''}${info.sub_category ? ` - ${info.sub_category}` : ''}`
      changes += `, Label "${info.label ? info.label : ''}"`
      changes += `, File Name "${info.file_name ? formatter.toStandardFileName(info.file_name) : ''}"`
      changes += `, Issuance Date "${info.issuance_date ? formatter.toShortDate(info.issuance_date) : ''}"`
      if (info.expiry_date) {
        changes += `, Expiry Date "${formatter.toShortDate(info.expiry_date)}"`
      }

      log.deleteJobFile(jobId, changes)
      notify.success('Deleted successfully', 'File deleted successfully')
      this.fetchFiles({ currentPage, filter, sort, searchText })
      // onUpdateInfo()
      onRefreshPage()
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({
      modal,
      isShowFileRule: false,
      isShowSubCategory: false,
      filteredSubCategories: [],
      currentFileName: '',
      currentSubCategory: {},
      currentMainCategory: {}
    })
  }

  showModal = (isEdit, modalItem) => {
    const { modal, subCategories, mainCategories } = this.state
    modal.edit = isEdit || false
    modal.item = modalItem || {}
    modal.show = true

    if (isEdit) {
      const editingSub = subCategories.find(item => item.id === modalItem.sub_category_id)
      const editingMain = mainCategories.find(item => item.id === modalItem.main_category_id)
      this.setState({
        currentFileName: formatter.toStandardFileName(modalItem.file_name),
        currentSubCategory: editingSub,
        currentMainCategory: editingMain
      })
    }
    this.setState({ modal })
  }

}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobFile))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jvpJobService, jvpLeaveService } from '../../../services'
import { fetchEmployees } from '../../../states/actions/employee'
import { common, exportFile, formatter, validator } from '../../../util'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { jobURL, timezone } from '../../../config'
import { ExportType } from '../../../constants'

// UI
import { Checkbox, Loading, List, Page } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

moment.tz.setDefault(timezone)

const view = 'Client Leave'

class ClientImpactedJobJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      leaveInfo: {},
      list: [],
      total: 0,
      isNotify: null,
      jobCheckList: [],
      loading: false,
      loadingInfo: false,
      loadingSave: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    if (prevState.isNotify === null) {
      let isNotify = prevState.isNotify

      if (location && location.search) {
        const queries = common.getQueryStringSearchParams(location.search)

        if (queries && queries['notify'] !== undefined) {
          isNotify = true
        } else {
          isNotify = false
        }
      }

      return { ...prevState, isNotify }
    }

    return { ...prevState }
  }

  componentDidMount () {
    this.fetchLeaveInfo()
    this.fetchLeaveJobs()
  }

  render () {
    const { form } = this.props
    const { isNotify, jobCheckList, list, leaveInfo, loading, loadingInfo, loadingSave } = this.state


    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >SO</div> }
        </div>
      },
      {
        title: 'Employee',
        width: 5,
        render: ({ client_leave_id, is_frontend_unhide, employee_active, employee_id, employee_fullname, employee_leave_id, employee_leave_start_date, employee_leave_end_date }) => {
          const isEmployeeLeave = !!employee_leave_id
          const employeeLeaveText = isEmployeeLeave ? `Leave ${formatter.toShortDate(employee_leave_start_date)} - ${formatter.toShortDate(employee_leave_end_date)}` : null
          const isHide = client_leave_id && !is_frontend_unhide

          return isEmployeeLeave
            ? (<div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                <a className={`${!employee_active || isHide ? 'emp-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
                <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />&nbsp;{employeeLeaveText}</div>
              </div>)
            : (<div>
                <a className={`${!employee_active || isHide ? 'emp-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
              </div>)
        }
      },
      {
        title: 'Day',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, string_job_start_day }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'emp-label-dim-unhover' : ''}`}>{formatter.capitalize(string_job_start_day)}</div>
        }
      },
      {
        title: 'Start Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'emp-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_start_date)}</div>
        }
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'emp-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_end_date)}</div>
        }
      },
      {
        title: 'Start Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'emp-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_start_date)}</div>
        }
      },
      {
        title: 'End Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'emp-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_end_date)}</div>
        }
      },
      {
        title: 'Status',
        width: 1,
        render: ({ client_leave_id, is_frontend_unhide, id, is_cancel }) => {
          const isCancel = !!is_cancel
          const isUnhide = !!is_frontend_unhide

          return (
            <div style={{ color: '#ccc', fontSize: '12pt' }}>
              { isCancel
                ? <div>
                  <Tooltip mouseLeaveDelay={0} title='Cancelled'>
                    <span style={{ color: '#ff5b5b' }}>
                      <Icon type='close-circle' theme='filled' />
                    </span>
                  </Tooltip>
                </div>
                : isUnhide
                  ? <span>
                    <Tooltip mouseLeaveDelay={0} title='Displayed to Employee'>
                      <span style={{ color: '#4fbc85' }}>
                        <Icon type='play-circle' theme='filled' />
                      </span>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='Toggle to hide from Employee'>
                      <Popconfirm
                        title='Confirm to toggle to hide this job from Employee?'
                        onConfirm={() => this.updateJobUnhide(id, false)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <span style={{ color: '#ccc', cursor: 'pointer' }}>
                          <Icon type='exclamation-circle' theme='filled' />
                        </span>
                      </Popconfirm>
                    </Tooltip>
                  </span>
                  : <span>
                    <Tooltip mouseLeaveDelay={0} title='Toggle to display to Employee'>
                      <Popconfirm
                        title='Confirm to toggle to show this job to Employee?'
                        onConfirm={() => this.updateJobUnhide(id, true)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <span style={{ color: '#ccc', cursor: 'pointer' }}>
                        <Icon type='play-circle' theme='filled' />
                        </span>
                      </Popconfirm>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='Hidden from Employee'>
                      <span style={{ color: '#f2b018' }}>
                        <Icon type='exclamation-circle' theme='filled' />
                      </span>
                    </Tooltip>
                  </span>}
            </div>
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_cancel }) => {
          const isCancel = !!is_cancel
          const isChecked = jobCheckList.find(e => e.id === id)
          return (
            !isCancel
            ? <Tooltip mouseLeaveDelay={0} title={'Cancel?'}>
              <Checkbox
                disabled={isCancel}
                checked={isChecked}
                onClick={f => this.handleCheckboxClick(f, id)}
              />
            </Tooltip>
            : null
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => (
          <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
            <Tooltip mouseLeaveDelay={0} title='View'>
              <a href={`${jobURL}/single/${id}/info`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
          </div>
        )
      }
    ]

    return (
      <Page.Body className='client-impacted'>
        <Page.Left>
          <Page.Menu
            title='Client'
            backLink={loadingInfo ? null : isNotify ? '/reports/client-leave' : `/clients/${leaveInfo.member_id}/leaves`}
            panel={<div style={{ textAlign: 'center', padding: '20px 0' }}
          >
            <img alt='' src={process.env.PUBLIC_URL + '/img/' + (leaveInfo.member_gender && leaveInfo.member_gender.toLowerCase() === 'male' ? 'man.png' : 'woman.png')} className='avatar' />
            <div className='employee'>
              { leaveInfo.member_fullname }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`Jobs Affected Under the Leave ${loadingInfo ? '' : `(From ${formatter.toShortDate(leaveInfo.start_date)} to ${leaveInfo.is_ufn ? `UFN` : formatter.toShortDate(leaveInfo.end_date)})`}`}>
            <div className='btn' onClick={() => this.handleExport()}>
              Export
            </div>
            <div className='btn' onClick={() => this.updateJobCancel()}>
              { loadingSave ? <span style={{ marginRight: '5px' }}><Icon type='sync' spin /></span> : null }Cancel Job
            </div>
          </Page.Header>
            <Loading loading={loadingSave} blur>
              <Skeleton loading={loading} active>
                <List cols={columns} rows={list} />
              </Skeleton>
            </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchLeaveInfo = async () => {
    const leaveId = this.getLeaveId()
    const typeId = this.getClientId()

    this.setState({ loadingInfo: true })

    const info = await jvpLeaveService.getInfo(leaveId, 'client', typeId)

    this.setState({ leaveInfo: info && info.id ? info : {}, loadingInfo: false })
  }

  fetchLeaveJobs = async () => {
    const leaveId = this.getLeaveId()
    const typeId = this.getClientId()

    this.setState({ loading: true })

    const jobs = await jvpLeaveService.listImpactedJobs(leaveId, 'client', typeId)

    this.setState({ list: validator.isArray(jobs) ? jobs : [], total: validator.isArray(jobs) ? jobs.length : 0, loading: false })
  }

  handleCheckboxClick = (e, id) => {
    const check = e.target.checked
    const { jobCheckList } = this.state

    const r = jobCheckList.findIndex(f => f === id)

    if (check) {
      if (!(r > -1)) {
        jobCheckList.push(id)
      }
    } else {
      if (r > -1) {
        jobCheckList.splice(r, 1)
      }
    }

    this.setState({ jobCheckList })
  }

  updateJobCancel = async (id, value) => {
    const { loading, loadingSave, jobCheckList } = this.state
    if (loading || loadingSave) return

    if (!validator.isNotEmptyArray(jobCheckList)) {
      this.showCancelJobError()
      return
    }

    const body = {
      ids: jobCheckList,
      is_cancel: true,
      view
    }

    this.setState({ loadingSave: true }, async () => {
      const { list } = this.state
      try {
        const r = await jvpJobService.saveList(body)

        if (r && (r.id || r.ts)) {
          this.showUpdateJobSuccess()

          this.fetchLeaveJobs()
        } else {
          this.showUpdateJobError()
        }
      } catch (e) {
        this.showUpdateJobError()
      }

      this.setState({ loadingSave: false, list })
    })
  }

  updateJobUnhide = async (id, value) => {
    const { loading, loadingSave } = this.state
    if (loading || loadingSave) return

    const body = {
      id: id,
      is_frontend_unhide: value,
      view
    }

    this.setState({ loadingSave: true }, async () => {
      const { list } = this.state
      try {
        const r = await jvpJobService.saveList(body)

        if (r && (r.id || r.ts)) {
          // this.showUpdateJobSuccess()
          const jobIndex = list.findIndex(e => e.id === id)

          if (jobIndex > -1) {
            list[jobIndex].is_frontend_unhide = value
          }
        } else {
          this.showUpdateJobError()
        }
      } catch (e) {
        this.showUpdateJobError()
      }

      this.setState({ loadingSave: false, list })
    })
  }

  showUpdateJobSuccess = () => {
    notify.success('Updated successfully', 'Job status updated successfully.')
  }

  showUpdateJobError = () => {
    notify.error('Unable to Update', 'Unable to update the job. Please try again later.')
  }

  showExportJobsError = () => {
    notify.error('Unable to export', 'Unable to get affected jobs export successfully. Please try again later.')
  }

  showCancelJobError = () => {
    notify.error('Unable to Cancel Jobs', 'Please select at least one job.')
  }

  handleExport = async () => {
    const { loading, loadingSave } = this.state
    if (loading || loadingSave) return

    const leaveId = this.getLeaveId()
    const typeId = this.getClientId()

    try {
      this.setState({ loadingSave: true })
      const r = await exportFile.fetchListExport(ExportType.GENERAL.EXPORT_CLIENT_JOB_TO_ACTION, { leave_id: leaveId, client_id: typeId })

      setTimeout(() => {
        this.setState({ loadingSave: false })
      }, 3000)
    } catch (e) {
      this.showExportJobsError()
      this.setState({ loadingSave: false })
    }
  }

  getLeaveId = () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    return lid
  }

  getClientId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    return id
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientImpactedJobJvp))

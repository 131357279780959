import {
  CUSTOMERS_FETCHED, CUSTOMERS_FETCHING
} from '../actions/customer'

const INITIAL_STATE = {
  customers: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMERS_FETCHED:
      return handleCustomersFetched(state, action)
    case CUSTOMERS_FETCHING:
      return handleCustomersFetching(state, action)
    default:
      return state
  }
}

function handleCustomersFetched (state, action) {
  const { customers, loading } = action
  return { ...state, customers, loading }
}

function handleCustomersFetching (state, action) {
  const { loading } = action
  return { ...state, loading }
}

import { diff } from 'json-diff'
import { formatter } from '.'
import { authService, notifyService } from '../services'
import moment from 'moment'

export default {
  addFunder (id, name, values, fields, additional) {
    const content = generateContent(values, fields)

    sendNotification('Funder', id, name, 'new', (additional ? additional + content : content))
  },
  updateFunder (id, name, before, after, fields, additional) {
    const changes = generateChanges(before, after, fields)

    if (changes !== '' || additional !== '') {
      sendNotification('Funder', id, name, changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  addClient (id, name, values, fields, additional) {
    const content = generateContent(values, fields)

    sendNotification('Client', id, name, 'new', (additional ? additional + content : content))
  },
  updateClient (id, name, before, after, fields) {
    const changes = generateChanges(before, after, fields)

    if (changes !== '') {
      sendNotification('Client', id, name, changes)
    }
  },
  addEmployee (id, name, values, fields, additional) {
    const content = generateContent(values, fields)

    sendNotification('Employee', id, name, 'new', (additional ? additional + content : content))
  },
  updateEmployee (id, name, before, after, fields) {
    const changes = generateChanges(before, after, fields)

    if (changes !== '') {
      sendNotification('Employee', id, name, changes)
    }
  },
  // addClientFunder (id, name, funderName) {
  //   sendNotification('Client Funder', id, name, 'new')
  // },
  addClientFunder (id, name, funderName, values, fields, additional) {
    const content = generateContent(values, fields)

    sendNotification('Client Funder', id, name, 'new', (additional ? additional + content : content))
  },
  updateClientFunder (id, name, before, after, fields, additional) {
    const changes = generateChanges(before, after, fields)

    if (changes !== '' || additional !== '') {
      sendNotification('Client Funder', id, name, (additional ? additional + ', ' : '') + changes)
    }
  },
  updateClientFundingPeriod (id, name, changes) {
    if (changes) {
      sendNotification('Client Funding Period', id, name, changes)
    }
  },
  updateJobTimesheetUpdate (id, name, changes = '', content = '') {
    if (changes !== '' || content !== '') {
      sendNotification('Single Job Timesheet Update', id, name, changes, content)
    }
  }
}

function sendNotification (type, id, name, changes, content) {
  notifyService.send({ type, type_id: id, name, changes, content })
}

function generateChanges (item, values, fields = []) {
  const result = diff(item, values)
  let changes = ''

  for (const key of Object.keys(result)) {
    const value = result[key]
    const isFound = fields.findIndex(item => item.key === key)

    if (isFound >= 0) {
      if (value !== null && value.hasOwnProperty('__new')) {
        // check if the value is date
        const label = fields[isFound].label || formatter.capitalize(key.replace(/_/g, ' '))
        if (value.__old !== null && isDate(value.__old)) {
          // skip if no changes on date
          if (showAsDateTime(key)) {
            if (!moment(value.__old).isSame(moment(value.__new))) {
              changes += `${label} from "${formatDateTime(value.__old)}" to "${formatDateTime(value.__new)}", `
            }
          } else {
            if (!moment(value.__old).isSame(moment(value.__new), 'day')) {
              changes += `${label} from "${formatDate(value.__old)}" to "${formatDate(value.__new)}", `
            }
          }
        } else {
          if (value.__old !== null && value.__new !== null && (value.__old.toString() !== value.__new.toString())) {
            changes += `${label} from "${value.__old}" to "${value.__new}", `
          } else if (value.__old === null && value.__new !== null) {
            changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
          }
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}

function generateContent (values, fields = []) {
  let content = ''

  for (const key of Object.keys(values)) {
    const value = values[key]
    const isFound = fields.findIndex(item => item.key === key)

    if (isFound >= 0) {
      const label = fields[isFound].label || formatter.capitalize(key.replace(/_/g, ' '))

      if (isGMTDate(value)) {
        content += `${label}: ${formatDate(value)}<br />`
      } else if (value === undefined && key === 'no_km_restriction') {
        content += `${label}: false<br />`
      } else {
        content += `${label}: ${(value === null || value === undefined || value === '') ? '-' : value}<br />`
      }

    }
  }

  return content.length > 0 ? content.substr(0, content.length - 6) : ''
}

function isDate (date) {
  if (date && isNaN(date) && date.length >= 10) {
    const dateCheck = new RegExp(/^\d{4}-\d{2}-\d{2}$/)
    return dateCheck.exec(date.substr(0, 10)) !== null ? true : false
  }
  return false
}

function isGMTDate (date) {
  return date ? (date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}

function formatDate (value) {
  const formatted = moment(value).format('DD/MM/YYYY')

  return formatted !== 'Invalid date' ? formatted : ''
}

function formatDateTime (value) {
  const formatted = moment(value).format('DD/MM/YYYY hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsDateTime (key) {
  return key === 'job_start_date' || key === 'job_end_date'
}

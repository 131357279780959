export const REFRESH_ACTIVITY_LOG = 'REFRESH_ACTIVITY_LOG'
export const REFRESH_COMM_LOG = 'REFRESH_COMM_LOG'
export const REFRESH_FILES = 'REFRESH_FILES'

export const setRefreshActivityLog = (done) => {
  return {
    type: REFRESH_ACTIVITY_LOG,
    done
  }
}

export const setRefreshCommLog = (done) => {
  return {
    type: REFRESH_COMM_LOG,
    done
  }
}

export const setRefreshFiles = (done) => {
  return {
    type: REFRESH_FILES,
    done
  }
}

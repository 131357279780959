import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { authService, fvpCategoriesService, fvpRateTaxCodeService } from '../../../services'

// UI
import { Button, Loading, Page, Pager, Panel, SideModal, List } from '../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'
import { ExportType } from '../../../constants'
import { exportFile, formatter, validator } from '../../../util'
const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

export class SettingContacts extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isGenerating: false
    }
  }

  componentDidMount () {

  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { isGenerating } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const isAllowed = this.hasAccess('listContacts')

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Contacts' />

          <div className='setting-list'>
            <Panel title='Microsip'>
              <Row gutter={12}>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='msp-csv'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.MICROSIP_CSV)}
                      >
                        Download CSV
                      </Button>
                    : null }
                </Col>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='msp-xml'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.MICROSIP_XML)}
                      >
                        Download XML
                      </Button>
                    : null }
                </Col>
              </Row>
            </Panel>
            <Panel title='Acrobits'>
              <Row gutter={12}>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='acp-csv'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.ACROBITS_CSV)}
                      >
                        Download CSV
                      </Button>
                    : null }
                </Col>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='acp-json'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.ACROBITS_JSON)}
                      >
                        Download JSON
                      </Button>
                    : null }
                </Col>
              </Row>
            </Panel>
            <Panel title={`General`}>
              <Row gutter={12}>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='gcp-csv'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.GENERAL_CSV)}
                      >
                        Download CSV
                      </Button>
                    : null }
                </Col>
                <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='gcp-xml'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.GENERAL_XML)}
                      >
                        Download XML
                      </Button>
                    : null }
                </Col>
              </Row>
            </Panel>
            <Panel title={`Simple`}>
              <Row gutter={12}>
                <Col lg={6}>
                  { isAllowed
                    ? <Button
                      key='gcpv2-zip-csv'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.GENERAL_V2_ZIP_CSV)}
                      >
                        Download Zipped CSV
                      </Button>
                    : null }
                </Col>
                <Col lg={6}>
                  { isAllowed
                    ? <Button
                      key='gcpv2-zip-email'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.GENERAL_V2_ZIP_EMAIL)}
                      >
                        Email Zipped CSV
                      </Button>
                    : null }
                </Col>
                {/* <Col lg={4}>
                  { isAllowed
                    ? <Button
                      key='gcpv2-xml'
                      feedback={isGenerating}
                      onClick={() => this.getExport(ExportType.CONTACT.GENERAL_V2_XML)}
                      >
                        Download XML
                      </Button>
                    : null }
                </Col> */}
              </Row>
            </Panel>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCategories({ loading: true, currentPage, filter, sort, searchText })
  }

  getExport = async (type) => {
    if (type) {
      try {
        this.setState({ isGenerating: true })
        const r = await exportFile.fetchExport(type, {})

        setTimeout(() => {
          this.setState({ isGenerating: false }, () => {
            if (type === ExportType.CONTACT.GENERAL_V2_ZIP_EMAIL) {
              if (r && r.ts) {
                notify.success('Send Email Successfully', 'The email is sent successfully.')
              } else {
                notify.error('Send Email Failed', 'The email is not sent. Please try again later.')
              }
            }
          })
        }, 4000)
      } catch (e) {
        notify.error('Unable to export', 'Unable to get export successfully. Please try again later.')
        this.setState({ isGenerating: false })
      }
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingContacts))

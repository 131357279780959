import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/jobs/timesheet'

export default {
  get (id) {
    return request(`${url}/${id}`)
  },
  remove (tsid, jobid) {
    return request(`${url}/${tsid}/job/${jobid}`, {
      method: 'DELETE',
      headers
    })
  },
  listJobByPage (page, size, filter, sorting, text) {
    return request(`${url}/jobs/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  exportTimesheetReport (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
}

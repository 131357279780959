import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jobService, employeeService, clientService, funderService, logService, settingGeneralService, settingBillingRateService,
  settingCancellationService, settingHolidayService, employeeLeaveService, clientLeaveService, baseJobService, authService,
  employeeFileService, settingFileCategoryService, settingFileTypeService } from '../../../services'

import { fetchingJobs } from '../../../states/actions/job'
import { fetchTotalPending, setRefreshCommLog } from '../../../states/actions'
import { JobMenu } from '../../../constants'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import formatter from '../../../util/formatter'
import { Link } from 'react-router-dom'
// import moment from 'moment'
import { common, log, validator } from '../../../util'

import Communication from './Communication'
import File from './File'
import AddFileModal from './AddFileModal'

// UI
import { List, Loading, Page, Panel, DateTimePicker, Pager, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'
import Checkbox from 'antd/lib/checkbox'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const Option = Select.Option
const momentWeek = extendMoment(Moment)
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const pageSize = 10

export class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clients: [],
      clientInfo: '',
      clientId: '',
      currentEmployee: { id: '', name: '' },
      currentFunder: { id: '', name: '' },
      currentBillingCategory: { name: '' },
      funderInfo: '',
      funderDetail: '',
      funders: [],
      employees: [],
      payrolls: [],
      isRecurring: false,
      billingRate: [],
      billingRateId: null,
      isEmergencyTime: false,
      isHoliday: false,
      isTimeConflict: false,
      isTimeConflictEnd: false,
      isOnLeave: false,
      isNeverEnd: false,
      isMatchLanguage: true,
      isMatchSkill: true,
      billingCategory: [],
      emergencyField: false,
      selectedEmpInfo: '',
      singleJobList: [],
      item: { client: {}, employee: {} },
      modalShow: false,
      loading: false,
      showGetupHours: false,
      dayNumber: '',
      currentWeek: '',
      optionDate: '',
      showCancelAlert: false,
      clientLanguage: [],
      clientSkill: [],
      prevEmployee: [],
      willShowOnlyFreq: true,
      willShowFreqAndMonth: false,
      willShowMonthOption: false,
      logList: [],
      showSleepoverAlert: false,
      employeePending: false,
      cancelType: [],
      cancelReason: [],
      showOtherField: false,
      firstFunder: '',
      firstFunderBillingRate: '',
      startD: Moment(),
      endD: '',
      isSleepover: false,
      showFunderModal: false,
      funderList: [],
      showFunderAlert: false,
      showClientConflictAlert: false,
      clientConflictInfo: '',
      clientIsOnLeave: false,
      clientLeaveInfo: '',
      emergencyPay: true,
      getupList: [],
      notAssignedTime: false,
      currentEnd: null,
      currentTotalHours: 0,
      enableEndTime: false,
      showNewDayModal: false,
      isNextDay: false,
      enableFromTime: true,
      allJobChecked: false,
      showEdit: true,
      showSave: false,
      hasStartDate: false,
      jobDuration: '',
      newJobEndTime: null,
      currentPage: 1,
      type: 'future',
      isCertificateExpired: false,
      isLicenseExpired: false,
      isVehicleRegistrationExpired: false,
      isVehicleInsuranceExpired: false,
      isDwesExpired: false,
      isPoliceExpired: false,
      isFirstAidExpired: false,
      isWWCCExpired: false,
      unsentCommTotal: 0,
      clientFunderId: 0,
      tabActiveKey: '1',
      changeEmployeeReason: '',
      changeEmployeeReasonType: '',
      changeEmployeeOtherReason: '',
      changeEmployeeNote: '',
      showEmployeeReasonModal: false,
      shouldRefreshComm: false,
      showClientPublicAlert: false,
      showClientPrivateAlert: false,
      showEmployeePublicAlert: false,
      showEmployeePrivateAlert: false,
      loadingJob: false,
      // MS0021 recurring enhancement
      conflictJobErrorMsg: '',
      conflictJobList: [],
      conflictJobListClient: [],
      conflictJobListEmp: [],
      updateAffectJobList: [],
      updateAffectErrorMsg: '',
      isLoadingConflictJobList: false,
      isLoadingAffectJobList: false,
      expiredFiles: [],
      categoriesList: [],
      subCategoriesList: [],
      fileList: [],
      fileInfo: {},
      showAddFileModal: false,
      employeeJobHoursInfo: {}
    }
    this.filterJob = this.filterJob.bind(this)
  }

  componentWillMount () {
    const { match } = this.props
    const { id } = match.params

    this.setState({ jobId: id })
  }

  async componentDidMount () {
    const { currentPage, jobId, type } = this.state
    let clientId = ''
    let funderId = ''
    let employeeId

    this.fetchSettings()

    if (this.isEdit()) {
      const item = await this.fetchRecurringJob()

      if (item) {
        clientId = item.client_id
        funderId = item.client_funder_id
        employeeId = item.employee_id
        this.showDuration(Moment(item.job_start_time), Moment(item.job_end_time))
      }

      this.fetchCancel()
      this.fetchSingleJobList(type, currentPage)
      this.fetchLogs()
      this.fetchUnsentEmail(jobId)

      this.handleJobAffectedChecking(true)
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)
      clientId = client
      funderId = funder
    }

    this.fetchClient(clientId, employeeId)
    this.fetchAllEmployees(clientId, employeeId) // review this
    this.fetchFileCategories()

    // this.fetchBillingRate(funderId)
    this.fetchFunders(clientId, funderId)

    this.fetchPreviousEmployees(clientId)
    this.props.fetchTotalPending()

    this.setState({ clientId, funderId })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.isEdit()) {
        this.fetchSingleJobList(this.state.type, 1)
        this.fetchLogs()
      }
    }
  }

  changePage = async (currentPage) => {
    const { type } = this.state
    this.fetchSingleJobList(type, currentPage)
  }

  handleChangeTab (tabNumber) {
    this.setState({ tabActiveKey: tabNumber })
  }

  handleChangeEmployee= async () => {
    const { form } = this.props
    this.setState({ showEmployeeReasonModal: false, showOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({ change_employee_reason: '', change_employee_reason_type: '', change_employee_other_reason: '', change_employee_note: '' })
    }, 1000)
  }

  handleEmployeeReasonTypeChange = async (id, value) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReason: cancelReason.list, changeEmployeeReasonType: value.props.children })
  }

  handleEmployeeCancelReasonChange = async (value) => {
    if (value === 'Other') {
      this.setState({ showOtherField: true })
    } else {
      this.setState({ showOtherField: false })
    }
  }

  handleEmployeeChangeReason = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll([ 'change_employee_reason', 'change_employee_other_reason', 'change_employee_note' ],
      async (errors, values) => {
        if (!errors) {
          this.setState({
            showEmployeeReasonModal: false,
            changeEmployeeReason: values.change_employee_reason,
            changeEmployeeOtherReason: values.change_employee_other_reason,
            changeEmployeeNote: values.change_employee_note
          })
          this.setState({ loading: true })

          that.handleChangeEmployee()
          //Proceed to save
          setTimeout(() => {
            this.handleSave()
          }, 500)
        }
      })
  }

  handleStartDateChange = async (date) => {
    const { form } = this.props
    const { clientId } = this.state
    const firstJobDate = Moment(date)
    const selectedEmployeeId = form.getFieldValue('employee_id')
    const hourValueStart = Moment().startOf('hour').hour()
    const optionDate = firstJobDate.format('DD')
    this.setState({ optionDate, hasStartDate: true })

    const jobStartTime = firstJobDate.clone().hour(hourValueStart).minute(0)
    const jobEndTime = jobStartTime.clone().add(1, 'hour')
    form.setFieldsValue({ job_start_time: jobStartTime })
    form.setFieldsValue({ job_end_time: jobEndTime })

    this.showDuration(jobStartTime, jobEndTime)
    this.checkClient(clientId, jobStartTime, jobEndTime)

    this.checkFunderAvailability(jobStartTime)

    // get day

    const chosenDayNumber = firstJobDate.day()
    const chosenDate = firstJobDate.format('DD-MM-YYYY')

    const firstDayOfMonth = Moment(firstJobDate).startOf('month')
    const firstDayOfMonthNum = Moment(firstJobDate).startOf('month').day()

    if (chosenDayNumber < firstDayOfMonthNum) {
      const checkWeek = firstJobDate.format('w')
      const startDateWeek = Moment(firstDayOfMonth).format('w')
      const currentWeek = parseInt(checkWeek - startDateWeek + 1, 10)
      const dayNumber = parseInt(currentWeek - 1, 10)
      // console.log('week', checkWeek, startDateWeek, currentWeek, firstJobDate)
      this.setState({ dayNumber: dayNumber })
    } else {
      const numberLeftInWeek = 6 - firstDayOfMonthNum
      const endDateForFirstWeek = firstDayOfMonth.add(numberLeftInWeek, 'days').format('DD-MM-YYYY')
      if (chosenDate > endDateForFirstWeek) {
        const checkWeek = firstJobDate.format('w')
        const startDateWeek = Moment(firstDayOfMonth).format('w')
        const currentWeek = checkWeek - startDateWeek + 1
        this.setState({ dayNumber: currentWeek })
      }
    }

    if (typeof selectedEmployeeId === 'number' && firstJobDate !== null && Moment(firstJobDate).isValid()) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, firstJobDate)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstJobDate, jobStartTime, jobEndTime)
    }

    this.handleConflictChecking()
  }

  handleEndDateChange = async (date) => {
    const { form } = this.props
    const firstJobDate = form.getFieldValue('first_job_date')
    const jobStartTime = form.getFieldValue('job_start_time')
    const jobEndTime = form.getFieldValue('job_end_time')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    if (typeof selectedEmployeeId === 'number' && firstJobDate !== null && Moment(firstJobDate).isValid()) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, firstJobDate)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstJobDate, jobStartTime, jobEndTime)
    }
  }

  handleStartTimeChange = async (date) => {
    const { clientId } = this.state
    const { form } = this.props

    const startTime = Moment(date)
    const firstDate = form.getFieldValue('first_job_date')
    const endTime = form.getFieldValue('job_end_time')
    const newEndTime = Moment(startTime).add(1, 'hours')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    // Update job end time
    if (!endTime) {
      form.setFieldsValue({ job_end_time: newEndTime })
    }

    form.setFieldsValue({ job_start_time: firstDate.set({ hour: startTime.get('hour'), minute: startTime.get('minute'), second: 0, millisecond: 0 }) })

    this.showDuration(startTime, endTime || newEndTime)

    this.checkClient(clientId, startTime, newEndTime)

    this.setState({ enableEndTime: true })

    if (!this.isEdit() && typeof selectedEmployeeId === 'number' && firstDate !== null && Moment(firstDate).isValid()) {
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstDate, startTime, endTime || newEndTime)
    }

    this.handleConflictChecking()
  }

  handleEndTimeChange = async (time) => {
    const { clientId } = this.state
    const { form } = this.props

    const firstDate = form.getFieldValue('first_job_date')
    const jobStartTime = Moment(form.getFieldValue('job_start_time'))
    const jobEndTime = Moment(time)
    const selectedEmployeeId = form.getFieldValue('employee_id')
    let duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()

    // Time correction
    if (duration <= 0) {
      jobEndTime.add('day', 1)
      duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()
      form.setFieldsValue({ job_end_time: jobEndTime })
      this.setState({ newJobEndTime: jobEndTime })
    } else if (jobStartTime.format('YYYY-MM-DD') !== jobEndTime.format('YYYY-MM-DD')) {
      if (duration > 24) {
        jobEndTime.add('day', -1)
        duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()
        form.setFieldsValue({ job_end_time: jobEndTime })
        this.setState({ newJobEndTime: jobEndTime })
      }
    }

    this.showDuration(jobStartTime, jobEndTime)
    this.checkClient(clientId, jobStartTime, jobEndTime)

    if (!this.isEdit() && typeof selectedEmployeeId === 'number' && firstDate !== null && Moment(firstDate).isValid()) {
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstDate, jobStartTime, jobEndTime)
    }

    this.handleConflictChecking()
  }

  handleBadgeCount = (value) => {
    this.setState({ unsentCommTotal: value })
    this.fetchLogs()
  }

  // MS0021 recurring enhancement
  handleConflictChecking = async () => {
    setTimeout(async () => {
      const { clientId = '' } = this.state
      const { form } = this.props

      const isNeverEnd = form.getFieldValue('is_never_end')
      const fd = form.getFieldValue('first_job_date')
      const ld = form.getFieldValue('last_job_date')
      const ed = form.getFieldValue('last_job_date')
      const jst = form.getFieldValue('job_start_time')
      const jet = form.getFieldValue('job_end_time')
      const jobBaseId = this.getBaseJobId()

      const firstDate = fd && fd.isValid() ? fd.format('YYYY-MM-DD') : fd
      const lastDate = ld && ld.isValid() ? ld.format('YYYY-MM-DD') : ld

      const jobStartTime = jst && jst.isValid() ? jst.format('HH:mm:ss') : jst
      const jobEndTime = jet && jet.isValid() ? jet.format('HH:mm:ss') : jet
      const frequency = form.getFieldValue('interval')
      const employeeId = form.getFieldValue('employee_id') || ''

      // console.log('conflict', firstDate, lastDate, isNeverEnd, jobStartTime, jobEndTime, frequency, employeeId)
      if (clientId && firstDate && jobStartTime && jobEndTime && frequency) {
        const body = {
          clientId: clientId,
          employeeId: employeeId,
          startDate: firstDate,
          endDate: lastDate,
          startTime: jobStartTime,
          endTime: jobEndTime,
          frequency,
          jobBaseId: jobBaseId === 'add' ? '' : jobBaseId
        }

        const r = await jobService.getRecurringConflictJobs(body)
        // console.log('conflict jobs', r)
        if (r && r.job_list && Array.isArray(r.job_list)) {
          const conflictJobList = r.job_list
          const conflictJobListClient = conflictJobList.filter(e => parseInt(e.client_id) === parseInt(clientId))
          const conflictJobListEmp = employeeId ? conflictJobList.filter(e => parseInt(e.employee_id) === parseInt(employeeId)) : []
          this.setState({ conflictJobList, conflictJobListClient, conflictJobListEmp, conflictJobErrorMsg: '' })
        } else {
          this.setState({ conflictJobErrorMsg: 'Unable to validate conflict jobs.' })
        }
      }
    }, 500)
  }

  // MS0021 recurring enhancement
  handleJobAffectedChecking = async (applyAll, dateFrom) => {
    if (this.isEdit()) {
      if (applyAll) {
        this.setState({isLoadingAffectJobList: true})
        const { clientId = '' } = this.state
        const jobBaseId = this.getBaseJobId()
        const body = {
          clientId,
          jobBaseId,
          dateFrom
        }

        const r = await jobService.getAffectedUpdateJobs(body)

        if (r && r.job_list && Array.isArray(r.job_list)) {
          this.setState({ updateAffectJobList: r.job_list, updateAffectErrorMsg: '', isLoadingAffectJobList: false })
        } else {
          this.setState({ updateAffectErrorMsg: 'Unable to look for affected jobs.', isLoadingAffectJobList: false })
        }
      } else {
        this.setState({ updateAffectJobList: [], updateAffectErrorMsg: '', isLoadingAffectJobList: false })
      }
    }
  }

  // MS0021 recurring enhancement
  handleCheckJobAffectedStatus = (job) => {
    const { is_updated: isUpdated } = job
    const { type } = this.state

    if (type !== 'previous') {
      if (isUpdated) {
        return 1
      }
    }

    return 0
  }

  replacer = (value) => {
    let str = value
    // Replace special characters
    str = str.replace(/[•·]/g, '-')

    // Remove extra blank space or tab
    str = str.replace(/([ ]{2,})|(\t)/g, ' ')

    return str
  }

  render () {
    const { history, match } = this.props
    const { item, clientInfo, showEdit, showSave, loading, unsentCommTotal, tabActiveKey, shouldRefreshComm, showClientPrivateAlert, showClientPublicAlert } = this.state

    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''
    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Jobs' menu={JobMenu} countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          {
            !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Page.Header title={`${!this.isEdit() ? 'New ' : ''}Recurring Job For ${clientInfo.first_name} ${clientInfo.last_name} ${this.isEdit() && showEdit ? '(View Only)' : this.isEdit() && showSave ? '(Edit Mode)' : ''}`}>
                {
                  showEdit && this.isEdit() && this.hasAccess('updateJob') ? <div className='btn' onClick={this.handleEditButton}>Edit</div> : null
                }
                {
                  (showSave && this.hasAccess('updateJob')) || (!this.isEdit() && this.hasAccess('createJob'))
                    ? <div className='btn' onClick={() => this.checkBeforeSave()}>{ loading ? <Icon type='loading' style={{ fontSize: 13 }} /> : 'Save' }</div> : null
                }
                <div className='btn' onClick={history.goBack}>Back</div>
              </Page.Header>
          }

         {/* { showClientPrivateAlert && clientInfo.private_alert && showClientPublicAlert && clientInfo.public_alert
            ? <div>
                <Alert message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPublicAlertMsg}<br />${clientPrivateAlertMsg}`
                  }} />
                } type='warning' showIcon /><br />
              </div>
            : showClientPublicAlert && clientInfo.public_alert
              ? <div>
                <Alert message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPublicAlertMsg}`
                  }} />
                } type='warning' showIcon /><br />
              </div>
              : showClientPrivateAlert && clientInfo.private_alert
                ? <div>
                  <Alert message={
                    <div dangerouslySetInnerHTML={{
                      __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPrivateAlertMsg}`
                    }} />
                  } type='warning' showIcon /><br />
                </div>
                : null } */}
          { showClientPrivateAlert || showClientPublicAlert
            ? <div>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                    `${clientPublicAlertMsg ? `${clientPublicAlertMsg}<br />` : ''}` +
                    `${clientPrivateAlertMsg ? `${clientPrivateAlertMsg}<br />` : ''}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null }

          { this.isEdit()
            ? <div className='job-remark' style={{ backgroundColor: '#ea2a2acc' }}><Icon type='exclamation-circle' /> You are editing Base Job. Any changes you make will affect future jobs.</div>
            : null }

          { item.emergency
            ? <div className='job-remark' style={{ backgroundColor: '#ff5b5bbb' }}><Icon type='exclamation-circle' /> This is Emergency job</div>
            : null }

          { item.cancellation_penalty > 0
            ? <div className='job-remark' style={{ backgroundColor: '#b17bcd' }}><Icon type='info-circle' /> This job has Late Cancellation charge ({this.getChargeHours()} hours)</div>
            : null }

          { item.base_job_id > 0
            ? <div className='job-remark' style={{ backgroundColor: '#1890ffbb' }}><Icon type='info-circle' /> This is Recurring job</div>
            : null }

          <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)}>
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.infoTab() }
            </TabPane>
            { this.isEdit() ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2' forceRender>
              <Communication jobId={match.params.id} onUpdate={(value) => this.handleBadgeCount(value)} onUpdateRefresh={() => this.setState({ shouldRefreshComm: false })} shouldRefreshComm={shouldRefreshComm} />
            </TabPane> : null }
            { this.isEdit() ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
              <File jobId={match.params.id} history={this.props.history} onUpdateInfo={() => this.handleFilesUpdateAction()} onRefreshPage={() => this.fetchLogs()} />
            </TabPane> : null }
            { this.isEdit() ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='4'>
              { this.logTab() }
            </TabPane> : null }
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { employees, item, loading, clientInfo, clientId, payrolls, isHoliday, holidayInfo = [], billingCategory,
      isRecurring, knownLanguage = [], missingSkill = [], isTimeConflict, hasStartDate, funders,
      isOnLeave, singleJobList, selectedEmpInfo, clientLanguage, prevEmployee, clientSkill, willShowMonthOption, showSleepoverAlert,
      employeePending, funderInfo, funderDetail, showFunderModal, showFunderAlert,
      showClientConflictAlert, clientConflictInfo, clientLeaveInfo, clientIsOnLeave, isNeverEnd, jobDuration, isMatchLanguage, isMatchSkill, conflictJobInfo, optionDate,
      dayNumber, currentPage, total, isLicenseExpired, isDwesExpired, isPoliceExpired, isFirstAidExpired, isVehicleInsuranceExpired,
      isVehicleRegistrationExpired, isWWCCExpired , showEmployeeReasonModal, cancelType, cancelReason, showOtherField,
      showEmployeePrivateAlert, showEmployeePublicAlert, loadingJob, conflictJobErrorMsg, conflictJobList, conflictJobListClient, conflictJobListEmp, updateAffectJobList,
      isLoadingConflictJobList, isLoadingAffectJobList, expiredFiles, categoriesList, subCategoriesList, fileList, fileInfo, showAddFileModal,
      employeeJobHoursInfo } = this.state

    const employeeId = form.getFieldValue('employee_id') || ''
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    let itemTasks = item.tasks || ''
    let itemNotes = item.notes || ''

    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 17 }
    }
    const shortFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const specialFormItemLayoutToggle = {
      labelCol: { sm: 6, md: 6, lg: 0 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    const sideBySideExtraFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 10 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    if (selectedEmpInfo && selectedEmpInfo.private_alert) {
      const pAlert = selectedEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.public_alert) {
      const uAlert = selectedEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const fileColumns = [

      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = categoriesList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },

      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subCategoriesList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.name : ''}</div>
        }
      },

      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },

      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },

      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },

      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleDeleteFile(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

  return <Loading loading={loading} blur>
      <Row gutter={16}>
        <Col lg={12}>
          <Panel>
            { !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col style={{ height: 105 }}>
                  {/* <span className='client-name'>{clientInfo.first_name } { clientInfo.last_name } { clientInfo.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${formatter.toShortDate(clientInfo.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </span> */}
                  <span className='personnel-name'><a href={`/clients/${clientInfo.id}`} rel='noopener noreferrer' target='_blank'>{clientInfo.first_name } { clientInfo.last_name }</a></span>
                  <span className='personnel-accref'>{ clientInfo.acc_ref }</span>
                  { clientInfo.leave_id ? <span style={{ color: '#ff0000', fontSize: '9.5pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${clientInfo.until_further_notice ? 'UFN' : formatter.toShortDate(clientInfo.leave_end)}`}</span> : null }
                  <div className='personnel-details'>
                    { clientInfo.phone ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { clientInfo.phone }</div> : null }
                    <div style={{ fontSize: 12, marginRight: 10 }}>{ clientInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' } { clientInfo.address }</div>
                  </div>
                  <div className='personnel-details' style={{ marginTop: 15 }}>
                    <b>Preference</b>
                    <div style={{ fontSize: 12, marginRight: 10, marginLeft: 10, textTransform: 'capitalize' }}>{ clientInfo.preferred_gender ? <span style={{ color: '#ed6d1e' }}><Icon type='user-add' /></span> : null } { clientInfo.preferred_gender }</div>

                    <div style={{ fontSize: 12, marginRight: 10 }}>{ clientLanguage ? <span style={{ color: '#ed6d1e' }}><Icon type='font-size' /></span> : null } {
                      clientLanguage.map((language, idx) => (
                        <span key={idx} className='preferrence-item'>{language}{idx === clientLanguage.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                  <div className='personnel-sub-details'>
                    <b>Skills</b>
                    <div style={{ fontSize: 12, marginLeft: 10 }}>{ clientSkill ? <Icon type='check-circle' theme='twoTone' twoToneColor='#ed6d1e' /> : null } {
                      clientSkill.map((skill, idx) => (
                        <span key={idx} className='preferrence-item'>{skill}{idx === clientSkill.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                </Col>
              </Row>
            }
          </Panel>
        </Col>
        <Col lg={12}>
          <Panel>
            { !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col lg={24} style={{ height: 105 }}>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        { funderInfo.fullname
                          ? <span className='personnel-name'><a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>{ funderInfo.fullname }</a></span>
                          : <span className='personnel-name' style={{ color: '#ff0000' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>}
                        { funderInfo.acc_ref ? <span className='personnel-accref'>{ funderInfo.acc_ref }</span> : null }
                      </div>
                      <span className='changeFunder'
                        style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                        onClick={this.handleChangeFunder}><Icon type='form' style={{fontSize: '12pt'}} /></span>
                    </div>

                    <div className='personnel-details'>
                      { funderInfo.phone_number ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { funderInfo.phone_number }</div> : null }
                      <div style={{ fontSize: 12 }}>{ funderInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { funderInfo.address }</div>
                    </div>

                    { funderDetail ? <div>
                      <div className='personnel-sub-details' style={{ marginTop: 15 }}>
                        <b>Fund Period</b>
                        <div style={{ fontSize: 12, marginLeft: 10 }}>
                          <span style={{ color: '#ed6d1e' }}><Icon type='calendar' /></span> { funderDetail ? Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' - ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') : '' }
                        </div>
                      </div>

                      <div className='personnel-sub-details' style={{ marginTop: 5 }}>
                        <b>Fund Manager</b>
                        <div style={{ fontSize: 12, marginLeft: 10 }}>
                          <span style={{ color: '#ed6d1e' }}><Icon type='user' /></span> { funderDetail && funderDetail.manager !== '' ? funderDetail.manager : '-' }
                        </div>
                      </div>
                    </div> : null }
                  </div>
                </Col>
              </Row>
            }
          </Panel>
        </Col>
      </Row>

      <Panel
        title={`Recurring Job Schedule ${form.getFieldValue('first_job_date') ? `- Starting from ${Moment(form.getFieldValue('first_job_date')).format('DD/MM/YYYY')}, ${Moment(form.getFieldValue('first_job_date')).format('dddd')}` : ''}`}
        subtitle={<Row>
          <Col lg={24}>
            <div style={{ fontSize: 14 }}>
              { item.frequency ? <Icon type='clock-circle' /> : null } { jobDuration }
              {
                item.week_month === 'months'
                  ? <span>, { item.frequency_number ? <Icon type='sync' /> : null } For { item.frequency_number } months ( {item.frequency} weeks)</span>
                  : isRecurring
                    ? <span>, { item.frequency ? <Icon type='sync' /> : null } For { item.frequency } weeks</span>
                    : ''
              }
            </div>
          </Col>
        </Row>}>

        { /** -------------------------------------------------------------------- */}
        { Array.isArray(conflictJobListClient) && conflictJobListClient.length > 0
          ? <div style={{marginBottom: '20px'}}>
            {
              conflictJobListClient.map((c) => {
                const { id, job_start_date: jobStartDate, job_end_date: jobEndDate, employee_id: eid, emp_firstname: empFirstName, emp_lastname: empLastName, client_id: cid, first_name: clientFirstName, last_name: clientLastName, fund_fullName: fundName } = c
                const clientName = `${clientFirstName} ${clientLastName}`

                const clientText = `that is serving ${clientName}`
                return (
                  <div style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                    <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`/v1/jobs/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>A job {clientText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.</span>
                  </div>
                )
              })
            }
            </div>
            : null }

        <Row>
          <Col lg={24}>
            {
              clientIsOnLeave ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {
              showClientConflictAlert ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Client Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}<a href={`/v1/jobs/single/${clientConflictInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {
              showFunderAlert ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Funder Not Available</span> { funderDetail.funder_name } not available for selected date</div>}
                  type='error'
                  showIcon
                />
              </div> : null
            }
            {
              isHoliday ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {`${holidayInfo.name} (${Moment(holidayInfo.date).format('DD/MM/YYYY')})`}</div>}
                  type='info'
                  showIcon
                />
              </div> : null
            }
            {
              showSleepoverAlert ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                  type='info'
                  showIcon
                />
              </div> : null
            }
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Recurring Start Date'>
              {getFieldDecorator('first_job_date', {
                initialValue: this.isEdit() ? Moment(item.first_job_date) : null,
                rules: [
                  { required: true, message: 'Please enter first shift date' }
                ]
              })(
                <DateTimePicker showTime={false} onChange={this.handleStartDateChange} disabled={this.isEdit()} />
              )}
            </FormItem>
          </Col>

          <Col lg={12}>
            <Row>
              <Col lg={19}>
                <FormItem {...sideBySideExtraFormItemLayout} label='Recurring End Date'>
                  {getFieldDecorator('last_job_date', {
                    initialValue: this.isEdit() && !isNeverEnd ? (Moment(item.last_job_date) || null) : null,
                    rules: [
                      !isNeverEnd ? { required: true, message: 'Please enter last shift date' } : {}
                    ]
                  })(
                    <DateTimePicker showTime={false} onChange={this.handleEndDateChange} disabled={isNeverEnd ? true : (this.isEdit() ? false : !hasStartDate)} />
                  )}
                </FormItem>
              </Col>
              <Col lg={5}>
                <FormItem {...specialFormItemLayoutToggle}>
                  {getFieldDecorator('is_never_end', {
                    initialValue: item.is_never_end || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Never&nbsp;End'
                      unCheckedChildren='Never&nbsp;End'
                      onChange={(e) => this.handleNeverEndClick(e)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Job Start Time'>
              {getFieldDecorator('job_start_time', {
                initialValue: this.isEdit() ? Moment(item.job_start_time) : null,
                rules: [
                  { required: true, message: 'Please enter job start time' }
                ]
              })(
                <DateTimePicker showTime showDate={false} onChange={this.handleStartTimeChange} disabled={this.isEdit() ? false : !hasStartDate} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Job End Time'>
              {getFieldDecorator('job_end_time', {
                initialValue: this.isEdit() ? Moment(item.job_end_time) : null,
                rules: [
                  { required: true, message: 'Please enter job end time' }
                ]
              })(
                <DateTimePicker showTime showDate={false} onChange={this.handleEndTimeChange} disabled={this.isEdit() ? false : !hasStartDate} />
              )}
            </FormItem>

          </Col>

        </Row>

        <Row>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Frequency'>
              {getFieldDecorator('interval', {
                initialValue: item.interval,
                rules: [
                  { required: true, message: 'Please select interval' }
                ]
              })(
                <Select
                  disabled={this.isEdit()}
                  style={{ width: 150 }}
                  onChange={(value) => this.handleIntervalClick(value)}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {/* <Option value='daily'>Daily</Option> */}
                  <Option value='week'>Weekly</Option>
                  <Option value='fortnight'>Fortnightly</Option>
                  {/* <Option value='monthly'>Monthly</Option> */}
                </Select>
              )}
            </FormItem>
          </Col>

          {
            willShowMonthOption

              ? <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Monthly Option' hasFeedback>
                  {getFieldDecorator('month_setting', {
                    initialValue: item.month_setting || 'fixed-date',
                    rules: [
                      { required: true, message: 'Please select option' }
                    ]
                  })(
                    <Select
                      disabled={this.isEdit()}
                      style={{ width: 300 }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      <Option value='fixed-date'> Every Month On {common.getOptionDate(optionDate)}</Option>
                      <Option value='fixed-day'>Every Month On {common.getDayNumber(dayNumber)} {Moment(form.getFieldValue('first_job_date')).format('dddd')}</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>

              : null
          }

        </Row>

        {/*
          showSleepoverAlert

            ? <Row>
              <Col lg={16}>

                <Row>
                  <Col lg={24}>
                    <FormItem {...formItemLayout} label='Overnight'>
                      {getFieldDecorator('overnight_type', {
                        initialValue: item.overnight_type || 'sleepover'
                      })(
                        <RadioGroup buttonStyle='solid' onChange={this.handleOvernightChange}>
                          <RadioButton value='sleepover'>Sleepover (Inactive)</RadioButton>
                          <RadioButton value='nightowl'>Night Owl Shift (Active)</RadioButton>
                        </RadioGroup>
                      )}
                    </FormItem>

                  </Col>

                </Row>
              </Col>
            </Row>

            : null
                      */ }

      </Panel>

      <Panel title='Shift Work' type={item.employee_id === null ? 'warn' : ''}>
        <Row gutter={16}>
          {/* Start ALert */}

          <Col lg={24}>
            <div style={{ marginBottom: 10 }}>
              { /** MS0021 pt2 : list employee conflicted job  */}
              { Array.isArray(conflictJobListEmp) && conflictJobListEmp.length > 0
                ? <div style={{marginBottom: '20px'}}>
                  {
                    conflictJobListEmp.map((c) => {
                      const { id, job_start_date: jobStartDate, job_end_date: jobEndDate, employee_id: eid, emp_firstname: empFirstName, emp_lastname: empLastName, client_id: cid, first_name: clientFirstName, last_name: clientLastName, fund_fullName: fundName } = c

                      const empName = `${empFirstName} ${empLastName}`
                      // console.log('array', isClient, isEmployee, clientName, empName)

                      const employeeText = `served by ${empName}`
                      return (
                        <div style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                          <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`/v1/jobs/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>A job {employeeText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.</span>
                        </div>
                      )
                    })
                  }
                  </div>
                  : null }
              {/* {
                showEmployeePrivateAlert && selectedEmpInfo.private_alert && showEmployeePublicAlert && selectedEmpInfo.public_alert
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div dangerouslySetInnerHTML={{
                          __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePublicAlertMsg}<br />${employeePrivateAlertMsg}`
                        }} />}
                      type='warning'
                      showIcon
                    />
                  </div>
                  : showEmployeePublicAlert && selectedEmpInfo.public_alert
                    ? <div className='alert-info'>
                      <Alert
                        message={
                          <div dangerouslySetInnerHTML={{
                            __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePublicAlertMsg}`
                          }} />}
                        type='warning'
                        showIcon
                      />
                    </div>
                    : showEmployeePrivateAlert && selectedEmpInfo.private_alert
                      ? <div className='alert-info'>
                        <Alert
                          message={
                            <div dangerouslySetInnerHTML={{
                              __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePrivateAlertMsg}`
                            }} />}
                          type='warning'
                          showIcon
                        />
                      </div>
                      : null
              } */}
              {
                showEmployeePrivateAlert || showEmployeePublicAlert /* || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) */
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div>
                          <span style={{ fontWeight: 'bold' }}>{`Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}`}</span>
                          {employeePublicAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                            : null
                          }
                          {employeePrivateAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                            : null
                          }
                          {/* {!this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                            ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                            `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week, including this job will be <span style="color: #f5222d; font-weight: bold;">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                            }} />
                            : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                              ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                              `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week, including this job will be <span style="font-weight: bold">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours this week.`
                              }} />
                              : null
                          }
                          {this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                            ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                            `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                            }} />
                            : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                              ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                              `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                              }} />
                              : null
                          } */}
                        </div>
                      }
                      type='warning'
                      showIcon
                    />
                  </div>
                  : null
              }
              {
                isOnLeave ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }

              {/* Employee max hour warning */}
              {
                !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>}
                      type='error'
                      showIcon
                    />
                  </div>
                  : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>}
                      type='info'
                      showIcon
                    />
                  </div> : null
              }
              {
                this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />}
                      type='error'
                      showIcon
                    />
                  </div>
                  : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                    ? <div className='alert-info'>
                      <Alert
                        banner
                        message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                        }} />}
                        type='info'
                        showIcon
                      />
                    </div>
                    : null
              }

              {
                isTimeConflict ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Schedule Conflict</span> {`${conflictJobInfo.emp_firstname} ${conflictJobInfo.emp_lastname}  has conflict shift with ${conflictJobInfo.first_name} ${conflictJobInfo.last_name}
                      at ${formatter.toShortDate(conflictJobInfo.job_start_date)}, ${formatter.toShortTime(conflictJobInfo.job_start_date)} - ${formatter.toShortTime(conflictJobInfo.job_end_date)}`} <a href={`/v1/jobs/single/${conflictJobInfo.id}`}  target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                !isMatchLanguage ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${knownLanguage.join(', ')},
                      but ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} does not. `} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                !isMatchSkill ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}  does not possess following skill(s): ${missingSkill.join(', ')}`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                validator.isNotEmptyArray(expiredFiles)
                  ? (expiredFiles.map((file, idx) => {
                    return (
                      <div key={`expired file ${idx}`} className='alert-info'>
                        <Alert
                          message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                          type='error'
                          banner
                          showIcon
                        />
                      </div>
                    )
                  }))
                  : null
              }
            </div>
          </Col>
          {/* End Alert */}
        </Row>
        <Form layout='vertical'>
          <Row gutter={16}>
            <Col lg={8} style={{ marginTop: '8.5px' }}>
              <FormItem label='Shift Type'>
                {getFieldDecorator('billing_category_id', {
                  initialValue: item.billing_category_id,
                  rules: [
                    { required: true, message: 'Please Select Billing Rate' }
                  ]
                })(
                  <Select
                    onSelect={(v, o) => this.onSelectBillingRate(o)}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      billingCategory && billingCategory.map((bill, idx) => {
                        return <Option key={idx} value={bill.id}>{bill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8} style={{ marginTop: '8.5px' }}>
              <FormItem label='Carer Skill Level Required'>
                {getFieldDecorator('payroll', this.isEdit() ? {
                  initialValue: item.payroll,
                  rules: [
                    { required: true, message: 'Please Select Carer Skill Level' }
                  ]
                } : {
                  initialValue: clientInfo.payroll_category,
                  rules: [
                    { required: true, message: 'Please Select Carer Skill Level' }
                  ]
                })(
                  <Select
                    onChange={this.handleChangePayroll}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      payrolls.map((pay) => {
                        return <Option key={pay.value}>{pay.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label={
                <span>Employee <Switch
                  checkedChildren='Pending'
                  unCheckedChildren='Pending'
                  style={{ marginLeft: 10 }}
                  checked={employeePending}
                  onChange={this.handlePendingEmployee}
                /></span>
              } hasFeedback>
                {getFieldDecorator('employee_id', {
                  initialValue: item.employee_id,
                  rules: [
                    !employeePending ? { required: true, message: 'Please select an employee OR turn on Pending' } : {}
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Employee'
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    filterOption={(input, option) => this.findEmployees(input, option)}
                    onChange={this.handleEmployeeChange} disabled={employeePending}>
                    {
                      employees.map((items, idx) => {
                        return <Option key={idx} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <span style={{ color: '#ff0000', fontSize: '9pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start)} - ${formatter.toShortDate(items.leave_end)}`}</span> : null }</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>
            <Col lg={8}>
              <FormItem label='Tasks' hasFeedback>
                {getFieldDecorator('tasks', {
                  initialValue: this.replacer(itemTasks),
                  rules: [
                    { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter tasks' }
                  ]
                })(
                  <TextArea row={4} />
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Notes'>
                {getFieldDecorator('notes', {
                  initialValue: this.replacer(itemNotes),
                })(
                  <TextArea row={4} />
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Max KM'>
                {getFieldDecorator('job_kms', {
                  initialValue: item.job_kms,
                  rules: [
                    { validator: this.checkKMSInput }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>
            <Col lg={8}>

            </Col>

          </Row>

        </Form>
      </Panel>

      {
        prevEmployee.length > 0 ? <Panel title='Previous Carers'>
          <Row>
            <Col lg={24}>
              {
                prevEmployee.map((employee) => (
                  // <Link to={`/employees/${employee.employee_id}`} key={employee.employee_id}>
                  //   <span className='employeeList' style={{ color: '#242b49' }}><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</span>
                  // </Link>
                  <span className='employeeList' style={{ color: '#242b49' }}><a href={`/employees/${employee.employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</a></span>
                ))
              }
            </Col>
          </Row>
        </Panel> : null
      }

      { !this.isEdit()
        ? <Panel
            title='Files'
            subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
          >
            <List cols={fileColumns} rows={fileList} />
        </Panel>
        : null }

      { /** File Modal */}
      <AddFileModal
        jobId={'add'}
        key={`addfilemodaljob_new`}
        item={fileInfo}
        categoriesList={categoriesList}
        subCategoriesList={subCategoriesList}
        onClose={() => this.handleAddFileModal(false)}
        onSetFile={(values) => this.updateFileAdded(values)}
        visible={showAddFileModal}
      />

      { this.isEdit() ? <Panel title='Effective Date of Changes'>
        <Row>
          <Col lg={24}>
            <div className='specific-date'>
              <p><Icon type='exclamation-circle' /> To apply these updates after a specific date, add that date below.</p>
              <Row>
                <Col lg={12}>
                  <FormItem label='Effective From' {...shortFormItemLayout}>
                    {getFieldDecorator('from_date', {
                      initialValue: null,
                      rules: [
                        { required: true, message: 'Please enter date' }
                      ]
                    })(
                      <DateTimePicker showTime={false} onChange={this.handleFromDateChange} />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </div>
            {/* <div className='apply-all-jobs'>
              <Checkbox checked={this.state.allJobChecked} onChange={this.handleApplyAllJobs}>Apply these changes to all future jobs, even if they have been updated with different details to the base jobs. </Checkbox>
              {isLoadingAffectJobList ? <Icon type='loading' style={{ marginLeft: '8px', fontSize: '15pt' }} /> : null}
            </div> */}

            { /** -------------------------------------------------------------------- */}
            { Array.isArray(updateAffectJobList) && updateAffectJobList.length > 0
              ? <div style={{marginTop: '8pt'}}>
                { updateAffectJobList.map((c) => {
                  const { id, job_start_date: jobStartDate } = c

                  return (
                    <div style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                      <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`/v1/jobs/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>The job on {formatter.toStandardLongDate(jobStartDate)}</a> is individually updated before.</span>
                    </div>
                  )
                })}
              </div>
              : null }
          </Col>
        </Row>
      </Panel> : null }

      {
        this.isEdit() ? <Panel title='All Recurring Jobs'>
          <Radio.Group onChange={this.filterJob} defaultValue='future' style={{ marginLeft: 20 }}>
            <Radio.Button value='future'>Future</Radio.Button>
            <Radio.Button value='previous'>Past</Radio.Button>
          </Radio.Group>

          <div className='jobs'>
            <Skeleton loading={loadingJob} active>
              {
                singleJobList.length === 0 ? <img src={`${process.env.PUBLIC_URL}/img/table-nodata.png`} style={{ width: '200px', margin: '10px auto' }} /> : ''
              }
              {singleJobList.map((item) => {
                const { id, job_start_date, job_end_date, first_name, last_name, emp_firstname, sleepover,
                  emp_lastname, suburb, tasks, fund_fullname, is_delete: jobDelete, is_cancel: jobCancel, client_id, employee_id, funder_id,
                  client_leave_id, client_leave_start, client_leave_end, employee_leave_id, employee_leave_start, employee_leave_end,
                  holiday_date, holiday_name, client_leave_until_further_notice } = item

                const isUpdated = this.handleCheckJobAffectedStatus(item)

                return (
                  <div className={`list-item ${isUpdated === 1 ? 'list-item-warning' : holiday_date ? 'list-item-highlight' : ''}`} key={id}>
                    <Row>
                      <Col lg={1}>
                        {
                          !jobCancel && !jobDelete

                            ? <div className='normalIcon'>
                              <Icon type='check-circle' />
                            </div>

                            : !jobCancel && jobDelete

                              ? <div className='cancelIcon'>
                                <Icon type='delete' theme='twoTone' twoToneColor='#17202A' />
                              </div>

                              : jobCancel && !jobDelete

                                ? <div className='cancelIcon'>
                                  <Icon type='close-circle' theme='twoTone' twoToneColor='#eb2f96' />
                                </div>

                                : <div className='cancelIcon'>
                                  <Icon type='close-circle' theme='twoTone' twoToneColor='#eb2f96' />
                                  <Icon type='delete' theme='twoTone' twoToneColor='#17202A' />
                                </div>

                        }

                      </Col>
                      <Col lg={22}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Client</div>
                              </Col>
                              <Col lg={14}>
                                {/* <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { client_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                                <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
                                  { client_leave_id ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(client_leave_start)} - ${client_leave_until_further_notice ? 'UFN' : formatter.toShortDate(client_leave_end)}`}</div> : null }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Employee</div>
                              </Col>
                              <Col lg={14}>
                                {/* <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a> { employee_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                                <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a></div>
                                { employee_leave_id ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div> : null }
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Funder</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a></div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col md={8}>
                            <div className='detail'><Icon type='calendar' /> { formatter.toShortDate(job_start_date)} - { formatter.toShortDate(job_end_date)} { holiday_date ? <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' /></Tooltip> : null } <span className='time-sum'>{Moment(job_start_date).format('dddd')}</span></div>
                            {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {moment(created_at).fromNow()}</div> */}
                          </Col>
                          <Col md={8}>
                            <div className='detail'><Icon type='clock-circle' /> { formatter.toShortTime(job_start_date)} - { formatter.toShortTime(job_end_date)} { sleepover ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(Moment.duration(Moment(job_end_date).diff(Moment(job_start_date))).asHours())} hours</span> }</div>
                          </Col>
                          <Col md={6}>
                            <div className='detail'><Icon type='environment' /> {suburb}</div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                          <Col>
                            <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : tasks }</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className='action-icon'>

                        <Link to={`/v1/jobs/single/${id}`}>

                          { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                            <Tooltip mouseLeaveDelay={0} title='Manage job'>
                              <Icon type='form' />
                            </Tooltip>
                          </div> : null
                          }

                        </Link>
                      </Col>
                    </Row>
                  </div>
                )
              })
              }
            </Skeleton>
          </div>
          <Pager
            size={pageSize}
            total={total ? parseInt(total) : 0}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={(e) => this.changePage(e)}
            style={{ marginTop: '15px' }}
          />
        </Panel>
          : null }

      {/* --------------------------------------FUNDER MODAL START---------------------------------------- */}
      <Modal
        width={600}
        title='Change Funder'
        visible={showFunderModal}
        onOk={this.handleFunderSubmit}
        onCancel={this.handleFunderCancel}
        footer={[
          <Row>
            <Button key='ok' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>
            <Button key='cancel' onClick={this.handleFunderCancel}>Cancel</Button>

          </Row>

        ]}
      >
        <FormItem {...formItemLayout} label='Funder' hasFeedback>
          {getFieldDecorator('client_funder_id', {
            initialValue: item.client_funder_id
          })(
            <Select
              style={{ width: '100%' }}
              placeholder='Funders'
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {
                funders.map((funder, idx) => (<Option key={idx} value={funder.id}>
                  {funder.funder_name}
                  <div className='clientId'>{funder.manager} ({formatter.toShortDate(funder.start_date)} - {formatter.toShortDate(funder.end_date)})</div></Option>))
              }
            </Select>
          )}
        </FormItem>
      </Modal>
      {/* --------------------------------------FUNDER MODAL END---------------------------------------- */}

      {/* --------------------------------------CHANGE EMPLOYEE MODAL START---------------------------------------- */}

      <SideModal
        title='You Have Changed Employee'
        showModal={showEmployeeReasonModal}
        onClose={() => this.setState({showEmployeeReasonModal: false})}
        buttons={[
          <Button key='0' type='primary' onClick={() => this.handleEmployeeChangeReason()}>Submit</Button>
        ]}
      >
        <FormItem label='Reason Type'>
          {getFieldDecorator('change_employee_reason_type', {
            rules: [
              { required: true, message: 'Please select change type' }
            ]
          })(
            <Select style={{ width: '100%' }} onChange={this.handleEmployeeReasonTypeChange}>
              {
                cancelType.map((items, idx) => {
                  return <Option key={idx} value={items.id}>{items.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        <FormItem label='Reason To Change'>
          {getFieldDecorator('change_employee_reason', {
            initialValue: item.cancellation_reason,
            rules: [
              { required: true, message: 'Please select reason' }
            ]
          })(
            <Select style={{ width: '100%' }} onChange={this.handleEmployeeCancelReasonChange}>
              {
                cancelReason.map((items, idx) => {
                  return <Option key={idx} value={items.name}>{items.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        {
          showOtherField

            ? (
              <FormItem label='Other Reason To Change'>
                {getFieldDecorator('change_employee_other_reason', {
                })(
                  <TextArea row={2} />
                )}
              </FormItem>
            )

            : null
        }
        <FormItem label='Notes (Optional)'>
          {getFieldDecorator('change_employee_note', {
          })(
            <TextArea rows={2} />
          )}
        </FormItem>

      </SideModal>
      {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

    </Loading>
  }

  logTab = () => {
    const { loading, logList } = this.state
    const columns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at: createdAt }) => Moment(createdAt).format('DD/MM/YYYY hh:mm A')
      },
      {
        title: 'Updated By',
        width: 4,
        render: ({ updated_by: updatedBy }) => <div style={{ textTransform: 'capitalize' }}>{updatedBy}</div>
      },
      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div style={{ textTransform: 'uppercase' }}>{action}</div>
      },
      {
        title: 'Changes',
        width: 12,
        render: ({ changes }) => <div dangerouslySetInnerHTML={{
          __html: `${changes}`
        }} />
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={logList} />
          </Skeleton>
        </div>
      </Loading>
    )
  }

  fetchCancel = async () => {
    const cancelType = await settingCancellationService.getAll()
    this.setState({ cancelType: cancelType.list })
  }

  fetchRecurringJob = async () => {
    try {
      this.setState({ loading: true })
      const { item } = await baseJobService.get(this.getBaseJobId())

      const emerFlag = item.emergency
      const monthlyOption = item.month_setting
      const startDate = item.first_job_date
      const isNeverEnd = item.is_never_end
      const date = Moment(startDate)
      const cloneDate = Moment(date)

      const startTime = item.job_start_time
      const endTime = item.job_end_time
      const totalDiff = Moment(endTime).diff(startTime, 'hours')

      // if employee is pending
      if (item.employee_id === null) {
        this.setState({ employeePending: true })
      }

      const optionDate = Moment(date).format('DD')
      this.setState({ optionDate: optionDate })

      const chosenDay = date.format('dddd')
      const chosenDayNumber = date.day()
      const chosenDate = date.format('DD-MM-YYYY')

      const firstDayOfMonth = Moment(date).startOf('month')
      const firstDayOfMonthNum = Moment(date).startOf('month').day()

      if (chosenDayNumber < firstDayOfMonthNum) {
        // console.log('Chosen Day Not On First Week')
        const checkWeek = cloneDate.format('w')
        const startDateWeek = Moment(firstDayOfMonth).format('w')
        const currentWeek = parseInt(checkWeek - startDateWeek + 1, 10)
        const dayNumber = parseInt(currentWeek - 1, 10)

        this.setState({ dayNumber: dayNumber })

        // console.log('week', checkWeek, startDateWeek, currentWeek)
        // console.log(`You Selected The ${dayNumber} ${chosenDay} for Every Month`)
      } else {
        // console.log('Chosen Day Is On First Week')
        const numberLeftInWeek = 6 - firstDayOfMonthNum
        const endDateForFirstWeek = firstDayOfMonth.add(numberLeftInWeek, 'days').format('DD-MM-YYYY')
        if (chosenDate > endDateForFirstWeek) {
          const checkWeek = cloneDate.format('w')
          const startDateWeek = Moment(firstDayOfMonth).format('w')
          const currentWeek = checkWeek - startDateWeek + 1

          this.setState({ dayNumber: currentWeek })
        } else {
          // console.log('Yes, First Week')
          // console.log({ dayNumber: 1 })
          // console.log(`You Selected The First ${chosenDay} for Every Month`)
        }
      }

      if (monthlyOption) {
        if (isNeverEnd) {
          this.setState({ willShowOnlyFreq: false, willShowFreqAndMonth: false })
        } else {
          this.setState({ willShowOnlyFreq: false, willShowFreqAndMonth: true, willShowMonthOption: true })
        }
      } else {
        if (isNeverEnd) {
          this.setState({ willShowOnlyFreq: false, willShowFreqAndMonth: false })
        } else {
          this.setState({ willShowOnlyFreq: true, willShowFreqAndMonth: false })
        }
      }

      this.setState({ item, isNeverEnd, loading: false, emergencyField: emerFlag, startD: startTime, currentEnd: endTime, currentTotalHours: totalDiff, clientId: item.client_id, funderId: item.funder_id })

      return item
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load base job successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  /** Add new File - File Upload Modal */
  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  handleDeleteFile = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  uploadFiles = async (jobId) => {
    const { fileList, categoriesList, subCategoriesList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = categoriesList.find(e => e.id === file.main_category_id)
      const subcat = subCategoriesList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jobService.addFile(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addBaseJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  handleSave = () => {
    const { form, history } = this.props
    const { clientInfo, changeEmployeeReason, changeEmployeeReasonType, changeEmployeeOtherReason, changeEmployeeNote, fileList } = this.state
    const { validateFieldsAndScroll, setFieldsValue } = form

    validateFieldsAndScroll(['first_job_date', 'last_job_date', 'is_never_end', 'job_start_time', 'job_end_time', 'interval',
      'billing_category_id', 'payroll', 'employee_id', 'tasks', 'notes', 'from_date',
      'job_kms'], async (errors, values) => {
      if (!errors) {
        const { billingRateId, item, dayNumber, isNeverEnd, clientId, clientFunderId, newJobEndTime, funderInfo, currentBillingCategory, currentEmployee, currentFunder } = this.state
        const jobBaseId = this.getBaseJobId()

        // Check if employee has changed
        if (currentEmployee.id !== '' && currentEmployee.id !== values.employee_id) {
          if (changeEmployeeReason === '') {
            this.setState({ showEmployeeReasonModal: true })
            return
          }
        }

        this.setState({ loading: true })

        if (newJobEndTime) {
          values.job_end_time = newJobEndTime
        }

        const isStartOnSameDay = Moment(values.first_job_date).isSame(values.job_start_time, 'day')

        if (!isStartOnSameDay) {
          const date = values.first_job_date.clone()
          const time = values.job_start_time
          values.job_start_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 })
        }

        const isEndOnSameDay = Moment(values.first_job_date).isSame(values.job_end_time, 'day')

        if (!isEndOnSameDay) {
          const date = values.first_job_date.clone()
          const time = values.job_end_time

          if (values.job_end_time.format('a') === 'am' && values.job_start_time.format('a') === 'pm') {
            values.job_end_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 }).add('day', 1)
          } else {
            values.job_end_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 })
          }
        }

        // calculate Job Hours
        const startTime = values.job_start_time.seconds(0).milliseconds(0)
        const endTime = values.job_end_time.seconds(0).milliseconds(0)
        const durations = Moment.duration(Math.abs(endTime.diff(startTime)))
        const diffHours = durations.asHours()

        values.client_id = clientId
        values.funder_id = funderInfo.id
        values.client_funder_id = clientFunderId
        values.job_hours = Math.round(diffHours * 100) / 100
        values.billing_rate_id = billingRateId
        values.job_start_day = Moment(values.first_job_date).format('dddd')
        values.is_never_end = isNeverEnd

        // Replace and remove if tasks has special character, tab or extra blank spaces
        if (values.tasks.length > 0) {
          values.tasks = this.replacer(values.tasks).trim()
          setFieldsValue({ tasks: values.tasks })
        }

        // Replace and remove if notes has special character, tab or extra blank spaces
        if (values.notes.length > 0) {
          values.notes = this.replacer(values.notes).trim()
          setFieldsValue({ notes: values.notes })
        }

        // Check if part of this job is sleepover
        if (values.billing_category_id === 4) {
          values.sleepover = 1
          values.sleepover_category_id = values.billing_category_id
          values.job_hours = 0
        } else {
          values.sleepover = 0
          values.sleepover_category_id = null
        }

        // Check if job is not emergency then clear invoice and pay
        if (!values.emergency) {
          values.emergency_invoice = false
          values.emergency_pay = false
        }

        // Check if job is monthly job that got indicator
        if (values.month_setting) {
          values.day_number = dayNumber
        } else {
          delete values.month_setting
        }

        // Remove job_kms if job_kms empty string
        if (values.job_kms === '') {
          values.job_kms = null
        }

        // delete values.cancellation_time
        // delete values.is_never_end

        // check if job is never ending
        const intervalVal = values.interval

        const jobEndDate = Moment(values.last_job_date).endOf('day')
        const legacyStart = values.cache_start_time
        const legacyEnd = values.cache_end_time

        // generate frequency

        if (legacyStart && legacyEnd) {
          const startDate = Moment(legacyStart)

          const endDate = Moment(startDate).add(3, 'months')
          const weekNum = endDate.diff(legacyStart, 'week')
          const dayNum = endDate.diff(legacyStart, 'days')
          if (isNeverEnd) {
            values.is_never_end = true
            if (intervalVal === 'monthly') {
              values.frequency = 3
              const lastDate = Moment(legacyStart).add(2, 'months')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'week') {
              values.frequency = weekNum
              const lastDate = Moment(legacyStart).add((weekNum - 1), 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'fortnight') {
              values.frequency = 6
              const lastDate = Moment(legacyStart).add(10, 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else {
              values.frequency = dayNum
              const lastDate = Moment(legacyStart).add((dayNum - 1), 'days')
              values.recurring_end = Moment(lastDate).startOf('day')
            }
          } else {
            const limitJobEndDate = Moment(legacyStart).add(3, 'months')

            if (Moment(legacyEnd).isAfter(Moment(limitJobEndDate))) {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'weeks')
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                // console.log('lastdate', Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks'))
                values.frequency = totalFreq
                values.recurring_end = Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(limitJobEndDate).startOf('day')
              }
            } else {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'weeks')
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(jobEndDate).startOf('day')
              }
            }
          }
        } else {
          const startDate = Moment(form.getFieldValue('first_job_date')).startOf('day')
          const endDate = Moment(startDate).add(3, 'months')
          const weekNum = endDate.diff(startDate, 'week')
          const dayNum = endDate.diff(startDate, 'days')
          if (isNeverEnd) {
            values.is_never_end = true
            if (intervalVal === 'monthly') {
              values.frequency = 3
              const lastDate = Moment(startDate).add(2, 'months')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'week') {
              values.frequency = weekNum + 1
              const lastDate = Moment(startDate).add((weekNum), 'weeks')
              values.recurring_end = Moment(lastDate).add(1, 'day').startOf('day')
            } else if (intervalVal === 'fortnight') {
              values.frequency = 6
              const lastDate = Moment(startDate).add(10, 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else {
              values.frequency = dayNum
              const lastDate = Moment(startDate).add((dayNum - 1), 'days')
              values.recurring_end = Moment(lastDate).startOf('day')
            }
          } else {
            let limitJobEndDate = Moment(startDate).add(3, 'months')

            if (limitJobEndDate < Moment()) {
              limitJobEndDate = Moment().add(3, 'months')
            }

            if (Moment(jobEndDate).isAfter(Moment(limitJobEndDate))) {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'weeks')
                // console.log(`I will calculate from ${limitJobEndDate} to ${startDate}`)
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(startDate).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(limitJobEndDate).startOf('day')
              }
            } else {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(startDate).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'weeks')
                // console.log('Number Of Week:', startEnd)
                // console.log(`I will calculate from ${jobEndDate} to ${startDate}`)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(startDate).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(jobEndDate).diff(startDate, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(jobEndDate).startOf('day')
              }
            }
          }
        }
        // Check if recurring date is changed to greater Date.
        const initialRecurringEnd = Moment(item.recurring_end)
        const newRecurringEnd = Moment(form.getFieldValue('last_job_date')).isValid() ? form.getFieldValue('last_job_date').endOf('day') : ''

        // check if job is ended before start date

        // kiv first

        // if (Moment(form.getFieldValue('last_job_date').isBefore(Moment(form.getFieldValue('first_job_date'))))) {
        //   values.is_cancel = true
        //   const initialNotes = form.getFieldValue('notes')
        //   values.notes = `${initialNotes}` + ` [Cancelled by shorten the end date to before start date]`
        // } else {
        //   values.is_cancel = false
        // }

        if (Moment(newRecurringEnd).isAfter(initialRecurringEnd)) {
          values.is_extended = true
        } else {
          values.is_extended = false
        }

        try {
          const { allJobChecked, type } = this.state
          const checkFromDateField = form.getFieldValue('from_date')

          if (this.isEdit()) {
            let response

            if (checkFromDateField !== null) {
              const fromDatePrep = values.from_date
              const fromDate = fromDatePrep.toISOString()
              delete values.from_date

              if (allJobChecked) {
                response = await baseJobService.saveFromUpdated(jobBaseId, values, fromDate)
              } else {
                response = await baseJobService.saveFrom(jobBaseId, values, fromDate)
              }
            } else {
              const fromDatePrep = Moment()
              const fromDate = fromDatePrep.toISOString()
              delete values.from_date

              if (allJobChecked) {
                response = await baseJobService.saveFromUpdated(jobBaseId, values, fromDate)
              } else {
                response = await baseJobService.saveFrom(jobBaseId, values, fromDate)
              }
            }

            if (response.id) {
              const { id } = response
              notify.success('Saved successfully', 'Job saved successfully.')

              let extraLogs = []

              if (validator.isNotEmptyArray(response.logJobsInfo)) {
                let logParams = []

                logParams.push(`Recurring End Date has been updated to ${formatter.toShortDate(values.last_job_date)} ${formatter.toShortTime(values.job_end_time)}. The following jobs are added/removed:<br />`)

                for (let x = 0; x < response.logJobsInfo.length; x++) {
                  const job = response.logJobsInfo[x]
                  logParams.push(`- ${job.isRemoveJob ? 'Removed' : 'Added'} ${formatter.toShortDate(job.job_start_date)} ${formatter.toShortTime(job.job_start_date)} - ${formatter.toShortTime(job.job_end_date)} (ID: ${job.job_id})<br />`)
                }

                log.updateBaseJobExtra(response.id, 'update', logParams.join(''))
              }

              if (currentEmployee.id !== '' && currentEmployee.id !== values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from "${currentEmployee.name}" to "${newEmployeeName}"`)

                this.setState({
                  currentEmployee: {
                    name: newEmployeeName,
                    id: values.employee_id
                  }
                })
                log.updateBaseJobExtra(response.id, 'employee change reason', `Employee changed to "${newEmployeeName}" with reason: ${changeEmployeeReasonType} - ${changeEmployeeReason} ${changeEmployeeOtherReason ? ` - ${changeEmployeeOtherReason}` : ''} ${changeEmployeeNote ? ` (${changeEmployeeNote})` : ''}`)

              } else if (currentEmployee.id === '' && values.employee_id !== '') {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from Pending to "${newEmployeeName}"`)

                this.setState({
                  currentEmployee: {
                    name: newEmployeeName,
                    id: values.employee_id
                  }
                })
              }

              if (values.funder_id !== item.funder_id) {
                extraLogs.push(`Funder changed from "${currentFunder.fullname}" to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== item.billing_category_id) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(`Shift Type changed from "${currentBillingCategory.name}" to "${name}"`)
              }

              if (values.payroll !== item.payroll) {
                const oldPayroll = await this.getPayrollCategoryName(item.payroll)
                const newPayroll = await this.getPayrollCategoryName(values.payroll)
                extraLogs.push(`Carer Skill Level changed from "${oldPayroll}" to "${newPayroll}"`)
              }

              await log.updateBaseJob(id, item, values, ['recurring_end', 'is_extended', 'employee_id', 'frequency', 'funder_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'sleepover', 'sleepover_category_id', 'client_funder_id'], extraLogs.join())

              this.setState({ changeEmployeeReason: '', changeEmployeeReasonType: '', changeEmployeeOtherReason: '', changeEmployeeNote: '' })

              fetchingJobs(true)
              this.fetchSingleJobList(type)
              await this.fetchUnsentEmail(id)
              this.checkEmail()
            }
          } else {
            const response = await baseJobService.add(values)

            if (response.id) {
              const { id } = response
              // log.addJob(id, '', [], `New base job`)

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList)) {
                await this.uploadFiles(response.id)
              }
              // ------ append file end -------

              let extraLogs = []

              extraLogs.push(`Client to "${clientInfo.first_name} ${clientInfo.last_name}"`)

              if (values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(` Employee to "${newEmployeeName}"`)
              }

              if (values.funder_id !== undefined) {
                extraLogs.push(` Funder to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== undefined) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(` Shift Type to "${name}"`)
              }

              if (values.payroll !== undefined) {
                const newPayroll = await this.getPayrollCategoryName(values.payroll)
                extraLogs.push(` Carer Skill Level to "${newPayroll}"`)
              }

              if (values.interval !== undefined) {
                extraLogs.push(` Frequency to "${values.interval}"`)
              }

              log.addBaseJob(id, values, ['is_extended', 'is_updated', 'interval', 'job_hours', 'employee_id', 'frequency', 'funder_id', 'client_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'sleepover', 'job_type', 'original_end_date', 'client_funder_id'], extraLogs.join())

              notify.success('Saved successfully', 'Job saved successfully.')
              history.replace(`/v1/jobs/recurring/${id}`)

              this.cacheEmployeeName(values.employee_id)

              await this.fetchUnsentEmail(id)

              setTimeout(() => {
                this.checkEmail()
              }, 800)
            }
          }
          this.setState({ item: { ...item, ...values }, loading: false })
        } catch (e) {
          console.log(e)
          notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.fetchLogs()
        this.props.setRefreshCommLog(true)
      } else {
        this.showErrorNotification('error')
      }
    })
  }

  // 1. Fetch Data ////////////////////////////

  fetchAllEmployees = async (clientId, employeeId) => {
    try {
      const employeeActive = await clientService.getClientEmployees(clientId)
      this.setState({ employees: employeeActive })

      setTimeout(() => {
        this.cacheEmployeeName(employeeId)
      }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchBillingRate = async (funderId) => {
    const funderInfo = await funderService.get(funderId)
    const funderInfoRes = funderInfo.item
    const funderRateSet = funderInfoRes ? funderInfoRes.rate_set : null
    const billRateInfo = await settingBillingRateService.get(funderRateSet)
    const billRateCategory = billRateInfo.categories

    this.setState({ billingCategory: billRateCategory, billingRateId: funderRateSet, billRateInfo })

    setTimeout(() => {
      this.cacaheBillingCategory()
    }, 500)
  }

  fetchClient = async (clientId, empId) => {
    try {
      const clientInfoRes = await clientService.get(clientId)
      const clientInfo = clientInfoRes.item
      const clientLanguages = clientInfo.languages
      const clientSkills = clientInfo.skills
      const clientHasPrivateAlert = clientInfo.private_alert !== null && !validator.isEmptyString(clientInfo.private_alert)
      const clientHasPublicAlert = clientInfo.public_alert !== null && !validator.isEmptyString(clientInfo.public_alert)

      // Get skills info
      if (clientSkills && clientSkills.length > 0 && clientSkills[0] !== null) {
        let preferredSkill = []
        const skills = await Promise.all(clientSkills.map(item => settingGeneralService.get(item)))

        for (var j = 0; j < skills.length; j++) {
          const item = skills[j].item.name
          preferredSkill.push(item)
        }

        this.setState({ clientSkill: preferredSkill })
      }

      // Get languages info
      if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
        let preferredLanguage = []
        const languages = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))

        for (var i = 0; i < languages.length; i++) {
          const item = languages[i].item.name
          preferredLanguage.push(item)
        }

        this.setState({ clientLanguage: preferredLanguage })
      }

      this.setState({ clientInfo, showClientPrivateAlert: clientHasPrivateAlert, showClientPublicAlert: clientHasPublicAlert })

      setTimeout(() => {
        this.checkClient(clientId)
      }, 1000)

      if (empId) {
        setTimeout(() => {
          this.checkEmployee(empId)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later')
    }
  }

  fetchEmployees = async (empId) => {
    try {
      const employeeInfoRes = await employeeService.get(empId)
      const employees = await employeeService.listByPage(1, 0)
      this.setState({ employeeInfo: employeeInfoRes.item, employees: employees.list, employeeId: employeeInfoRes.item.id })

      this.checkEmployee(empId)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId, funderSettingId) => {
    const { form } = this.props
    const funders = await clientService.getClientFunders(clientId)
    const clientFunderSetting = await funderService.getClientFunderSetting(funderSettingId)

    const funderId = clientFunderSetting ? clientFunderSetting.funder_id : null

    if (funderId) {
      const funderInfo = await funderService.get(funderId)
      // console.log('funderInfo', validator.isNotEmptyObject(funderInfo), funderInfo)
      this.setState({
        funderInfo: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
        currentFunder: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
      })
    }

    this.setState({ funderId, funders, funderDetail: clientFunderSetting, clientFunderId: funderSettingId })

    const fundClient = await clientService.getClientFundersInfo(clientId, funderId)

    if (fundClient.item && fundClient.item.end_date === null) {
      this.setState({ notAssignedTime: true })
      this.alertUnassignedTime()
    } else {
      this.setState({ notAssignedTime: false })

      const startDate = form.getFieldValue('job_start_date')
      this.checkFunderAvailability(startDate)
      this.fetchBillingRate(funderId)
    }
  }

  fetchUnsentEmail = async (jobId) => {
    const {unsentCommTotal} = this.state
    const { unsentTotal } = await jobService.getUnsentEmailTotalRecurring(jobId)

    this.setState({ unsentCommTotal: unsentTotal })
  }

  fetchLogs = async () => {
    const jobId = this.getBaseJobId()

    const filter = {
      type: 'basejob',
      type_id: jobId
    }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: settings.list.filter(item => item.type === 'language'),
      skills: settings.list.filter(item => item.type === 'skill'),
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding')
    })
  }

  fetchSingleJobList = async (filterParam, startPage = null) => {
    const { currentPage } = this.state
    const currentDate = Moment()
    const filter = { }

    this.setState({ loadingJob: true })
    const page = startPage || currentPage

    if (filterParam === 'future') {
      filter.job_start_date = { condition: '>=', value: currentDate }
    } else if (filterParam === 'previous') {
      filter.job_start_date = { condition: '<=', value: currentDate }
    } else {
      filter.job_start_date = { condition: '>=', value: currentDate }
    }

    filter.base_job_id = { condition: '=', value: this.getBaseJobId() }
    try {
      if (filterParam === 'previous') {
        const { list: singleJobList, total } = await baseJobService.getJobListDesc(page, pageSize, filter)
        this.setState({ singleJobList, total, currentPage: page, filter, type: filterParam, loadingJob: false })
      } else {
        const { list: singleJobList, total } = await baseJobService.getJobList(page, pageSize, filter)
        this.setState({ singleJobList, total, currentPage: page, filter, type: filterParam, loadingJob: false })
      }
      const { list: futureJobList } = await baseJobService.getJobList(1, 0, filter)
      this.setState({ futureJobList })
    } catch (e) {
      notify.error('Unable to load successfully, Unable to load single jobs list successfully. Please try again later')
      this.setState({ loadingJob: false })
    }
  }

  filterJob (e) {
    this.setState({ currentPage: 1 })
    this.fetchSingleJobList(e.target.value, 1)
  }

  fetchPreviousEmployees = async (clientId) => {
    const employeeList = await clientService.getClientPrevEmployeesActive(clientId)
    this.setState({ prevEmployee: employeeList })
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        categoriesList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subCategoriesList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loading: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  // 2. Logic  ////////////////////////////////

  checkSingleJobsDelete = () => {
    const { item, futureJobList } = this.state
    const { form } = this.props
    let show = null

    if (this.isEdit()) {
      if (item.is_never_end && !form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date')) {
        const delJobs = futureJobList.filter(item => Moment(item.job_end_date).isAfter(form.getFieldValue('last_job_date')))
        return delJobs
      } else if (!form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date') &&
        (form.getFieldValue('last_job_date').isBefore(Moment(item.last_job_date)))) {
        const delJobs = futureJobList.filter(item => Moment(item.job_end_date).isAfter(form.getFieldValue('last_job_date')))
        return delJobs
      }
    }

    return show
  }

  checkBeforeSave = () => {
    const { isDwesExpired, isPoliceExpired, isLicenseExpired, isFirstAidExpired, isCertificateExpired,
      isVehicleInsuranceExpired, isVehicleRegistrationExpired, isWWCCExpired , showFunderAlert, isOnLeave,
      selectedEmpInfo, isMatchSkill, missingSkill, clientInfo, clientLeaveInfo, clientIsOnLeave, funderDetail,
      showClientPrivateAlert, showEmployeePrivateAlert, showClientPublicAlert, showEmployeePublicAlert, loading,
      conflictJobList, updateAffectJobList, expiredFiles, item, employeeJobHoursInfo } = this.state
    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isAnyJobConflicted = Array.isArray(conflictJobList) && conflictJobList.length > 0
    const isAnyJobAffected = Array.isArray(updateAffectJobList) && updateAffectJobList.length > 0
    const isFileExpired = validator.isNotEmptyArray(expiredFiles)
    const { form } = this.props
    const { handleSave } = this

    if (loading) return

    const isShowJobWarning = this.checkSingleJobsDelete()

    if (!form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date') &&
    (form.getFieldValue('last_job_date') < form.getFieldValue('first_job_date'))) {
      this.showDateNotification()
      return
    }

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.private_alert) {
      const pAlert = selectedEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.public_alert) {
      const uAlert = selectedEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }


    if (showClientPrivateAlert || showEmployeePrivateAlert || showClientPublicAlert || showEmployeePublicAlert ||
      showFunderAlert || isOnLeave || !isMatchSkill || isAnyJobConflicted || isAnyJobAffected) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>
            <br />

            {/* Private Alert */}

            {
              // showClientPrivateAlert && showClientPublicAlert
              //   ? <div>
              //     <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //       <span dangerouslySetInnerHTML={{
              //         __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}<br />${clientPrivateAlertMsg}`
              //       }} />
              //     </p>
              //   </div>
              //   : showClientPublicAlert
              //     ? <div>
              //       <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //         <span dangerouslySetInnerHTML={{
              //           __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}`
              //         }} />
              //       </p>
              //     </div>
              //     : showClientPrivateAlert
              //       ? <div>
              //         <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //           <span dangerouslySetInnerHTML={{
              //             __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPrivateAlertMsg}`
              //           }} />
              //         </p>
              //       </div>
              //       : null
            }
            {
              showEmployeePrivateAlert || showEmployeePublicAlert /* || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) */
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}>{` Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}`}</span>
                    {employeePublicAlertMsg.length > 0
                      ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                      : null
                    }
                    {employeePrivateAlertMsg.length > 0
                      ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                      : null
                    }
                    {/* {!this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                      ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week, including this job will be <span style="color: #f5222d; font-weight: bold;">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />
                      : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                        ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week, including this job will be <span style="font-weight: bold">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours this week.`
                        }} />
                        : null
                    }
                    {this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                      ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />
                      : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                        ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                        }} />
                        : null
                    } */}
                  </p>
                </div>
                : null
            }
            {

            }

            {/* Files Expiry */}

            {
              isFileExpired ? (
                <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Expired Files:</strong></p>
                  <li>
                    <ul>{expiredFiles.map((file, idx) => { return (<li key={`expired file warnings ${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                  </li>
                  <br />
                </div>
              ) : null
            }

            {/* Funder */}

            {
              showFunderAlert ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Fund Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>

                <br /></div> : null
            }

            {/* Employee Max Hour Warning */}

            {// Create job max hour warning
              !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                    <div> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>
                  </p>
                </div>
                : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                      <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                      <div>{`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>
                    </p>
                  </div>
                  : null
            }

            {// Edit job max hour warning
              this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                    }} />
                  </p>
                </div>
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                      }} />
                    </p>
                  </div>
                  : null
            }

            {/* Client On Leave */}

            {
              clientIsOnLeave ? <div>
                <div><p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Employee On Leave */}

            {
              isOnLeave ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} is on leave from {Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Skill Mismatch */}

            {
              isMatchSkill ? null
                : <div>
                  <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess following skill(s):</strong></p>
                  <ul>
                    {
                      missingSkill.map((skills, index) => (
                        <li key={index}>{skills}</li>
                      ))
                    }
                  </ul>
                  <br />
                </div>
            }

            {/* Conflicted Jobs exists */}

            {
              isAnyJobConflicted ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Jobs are conflicting </strong></p>
                <p>{conflictJobList.length} job{conflictJobList.length === 1 ? '' : 's'} in total are conflicting with Base's series. Please check those jobs before you continue.</p>
                <br />
              </div> : null
            }

            {/* Individual Edited Jobs exists */}

            {
              isAnyJobAffected ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Jobs are different from base </strong></p>
                <p>{updateAffectJobList.length} job{updateAffectJobList.length === 1 ? '' : 's'} that have been individually updated before are different from Recurring Base. Please check those Jobs and apply update individually.</p>
                <br />
              </div> : null
            }

            {/* Warning Recurring Single Jobs to be remove */}

            {
              isShowJobWarning && Array.isArray(isShowJobWarning) && isShowJobWarning.length > 0
                ? <div>
                  <p style={{ color: 'red', fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>(Remove Job) Recurring single job(s) below are unable to revert after save:</strong></p>
                  <ul>
                    {
                      isShowJobWarning.map((job, index) => (
                        <li key={`jtd_${index}`} style={{ color: 'red' }}>{`${formatter.toStandardDate(job.job_start_date)} - ${formatter.toShortTime(job.job_end_date)} (ID: ${job.id})`}</li>
                      ))
                    }
                  </ul>
                  <br />
                </div>
                : null
            }

            <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          handleSave()
        },
        onCancel () {
        }
      })
    } else {
      handleSave()
    }
  }

  checkClient = (clientId, start, end) => {
    const { form } = this.props

    const jobStartDateTime = start || Moment(form.getFieldValue('job_start_date'))
    const jobEndDateTime = end || Moment(form.getFieldValue('job_end_date'))

    if (jobStartDateTime.isValid() && jobEndDateTime.isValid()) {
      // 1. Check if client is onleave
      this.checkClientLeave(clientId, jobStartDateTime, jobEndDateTime)

      // 2. Check if it has client conflict shift
      // this.checkClientJobConflict(clientId, jobStartDateTime, jobEndDateTime)

      // 3. Check if it is public holiday
      this.checkHoliday(jobStartDateTime)

      // 4. Check if it is sleepover
      this.checkSleepover(jobStartDateTime, jobEndDateTime)

      // if (!this.isEdit()) {
      //   setTimeout(() => {
      //     this.showPreWarning()
      //   }, 2000)
      // }
    }
  }

  // Refactor: Get client conflict jobs
  checkClientJobConflict = async (client, start, end) => {
    const jobId = this.getBaseJobId()
    const conflictJobs = await jobService.getClientConflictJobs(jobId, client, start.format(), end.format())

    if (conflictJobs.items.length > 0) {
      this.setState({ showClientConflictAlert: true, clientConflictInfo: conflictJobs.items[0] })
    } else {
      this.setState({ showClientConflictAlert: false })
    }

    // Further check for employee's conflict job if employee is set
    /*  const { form } = this.props
    const employeeId = form.getFieldValue('employee_id')

    if (employeeId) {
      this.checkEmployeeJobConflict(employeeId, start, end)
    } */
  }

  checkClientLeave = async (clientId, start, end) => {
    const { item: allLeaves } = await clientLeaveService.getByStartEnd(clientId, start.format(), end.format())

    if (allLeaves.length > 0) {
      this.setState({ clientIsOnLeave: true, clientLeaveInfo: allLeaves[0] })
      // this.showClientLeaveConfirm()
    } else {
      this.setState({ clientIsOnLeave: false })
    }
  }

  checkEmail = () => {
    const that = this
    const { unsentCommTotal } = this.state

    if (unsentCommTotal > 0) {
      that.setState({ shouldRefreshComm: true })
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.setState({ tabActiveKey: '2' })
        },
        onCancel () {

        }
      })
    } else {}
  }

  // Refactor:  Get employee conflict jobs
  checkEmployeeJobConflict = async (employee, start, end) => {
    const jobId = this.getBaseJobId()
    const conflictJobs = await jobService.getEmployeeConflictJobs(jobId, employee, start.format(), end.format())

    if (conflictJobs.items.length > 0) {
      this.setState({ isTimeConflict: true, conflictJobInfo: conflictJobs.items[0] })
    } else {
      this.setState({ isTimeConflict: false })
    }
  }

  checkEmergencyJob = async (date) => {
    const { settingOthers } = this.state
    const emergencyDuration = settingOthers.emergency_notice
    const durationFromNow = Moment.duration(Math.abs(date.diff(Moment())))
    const differentHours = durationFromNow.asHours()

    if (differentHours >= 0 && differentHours <= emergencyDuration) {
      this.setState({ isEmergencyTime: true })
      this.showEmergencyConfirm()
    } else {
      this.setState({ isEmergencyTime: false })
    }
  }

  checkEmployee = async (empId, changeEmployee = false) => {
    const { clientInfo } = this.state
    const { form } = this.props
    const formStartDate = Moment(form.getFieldValue('first_job_date')).startOf('day')
    const formEndDate = Moment(form.getFieldValue('first_job_date')).endOf('day')
    const startTime = Moment(form.getFieldValue('job_start_time'))
    const endTime = Moment(form.getFieldValue('job_end_time'))
    let employeeJobHoursInfo = {}

    if (typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null)) {
      if (endTime > startTime) {
        let body = {}
        const startingWeek = Moment(formStartDate).startOf('week')
        const endingWeek = Moment(formStartDate).endOf('week')
        const s = Moment(startingWeek).startOf('day')
        const e = Moment(endingWeek).endOf('day')
        const durationFromNewJob = Moment.duration(Math.abs(startTime.diff(endTime)))
        const differentHours = durationFromNewJob.asHours()
        const baseJobId = this.getBaseJobId()
        // console.log('sss', s.format())
        // console.log('eee', e.format())
        // console.log('differentHours', differentHours)
        body.empId = empId
        body.start = s
        body.end = e
        body.newJobHours = differentHours
        body.baseJobId = baseJobId === 'add' ? null : baseJobId
        const r = await baseJobService.getEmployeeTotalMaxHour(body)

        if (r && r.isEmpHasMaxHour !== undefined) {
          employeeJobHoursInfo = r
          this.setState({ employeeJobHoursInfo: r })
        } else {
          this.setState({ employeeJobHoursInfo: {} })
        }
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    }

    const employeeInfoRes = await employeeService.get(empId)
    const employeeInfo = employeeInfoRes.item
    const employeeHasPrivateAlert = employeeInfo.private_alert !== null && !validator.isEmptyString(employeeInfo.private_alert)
    const employeeHasPublicAlert = employeeInfo.public_alert !== null && !validator.isEmptyString(employeeInfo.public_alert)
    const clientLanguages = clientInfo.languages
    const clientSkills = clientInfo.skills

    setTimeout(() => {
      // Show popup alert if change new employee & employee have private alert or public alert
      if ((changeEmployee && employeeHasPrivateAlert) || (changeEmployee && employeeHasPublicAlert) || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)) {
        const pAlert = employeeInfo.private_alert || ''
        const uAlert = employeeInfo.public_alert || ''
        const privateAlert = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        const publicAlert = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        warning({
          title: `Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`,
          content: (
            <div>
              { publicAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null }
              { privateAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null }
              { !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpHasMaxHour
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
            </div>
          ),
          okText: 'OK',
          onOk () { },
        })
      }
    }, 400)

    this.setState({
      selectedEmpInfo: employeeInfo,
      showEmployeePrivateAlert: employeeHasPrivateAlert,
      showEmployeePublicAlert: employeeHasPublicAlert
    })

    if (clientSkills && clientSkills.length > 0 && clientSkills[0] !== null) {
      const finalSkills = []
      const employeeSkills = employeeInfo.skills

      const diffSkills = clientSkills.filter(x => !employeeSkills.includes(x))
      const isMatchSkill = !(diffSkills.length > 0)

      const skills = await Promise.all(diffSkills.map(item => settingGeneralService.get(item)))

      skills.map(skill => (
        finalSkills.push(skill.item.name)
      ))

      this.setState({ missingSkill: finalSkills, isMatchSkill })
      // this.showEmployeeSkillConfirm()
    }

    if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
      const languages = []
      const finalLang = []
      const employeeLanguage = employeeInfo.languages

      clientLanguages.forEach((e1) => employeeLanguage.forEach((e2) => {
        if (e1 === e2) {
          languages.push(e2)
        }
      }))

      const languageNames = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))
      languageNames.map(lang => (
        finalLang.push(lang.item.name)
      ))

      this.setState({ knownLanguage: finalLang, isMatchLanguage: languages.length > 0 })
    }

    // 1. Check schedule conflict
    // this.checkEmployeeJobConflict(empId, formStartDate, formEndDate)

    // 2. Check if employee is on leave on the selected date
    this.checkEmployeeLeave(empId, formStartDate, formEndDate)

    // 3. Check employee files expiry
    if (typeof empId === 'number') {
      this.checkEmployeeFilesExpiry(empId, formStartDate, formEndDate)
    }

    // 4. Check employee weekly max hours
    // if (typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null)) {
    //   this.checkEmployeeWeeklyMaxHour(empId, formStartDate, startTime, endTime)
    // }

    // setTimeout(() => {
    //   this.showPreWarning()
    // }, 2000)
  }

  checkEmployeeLeave = async (empId, start, end) => {
    const allLeaves = await employeeLeaveService.getByStartEnd(empId, start, end)
    const allLeavesCheck = allLeaves.item

    if (allLeavesCheck.length > 0) {
      this.setState({ isOnLeave: true })
      // this.showEmployeeLeaveConfirm()
    } else {
      this.setState({ isOnLeave: false })
    }
  }

  checkEmployeeWeeklyMaxHour = async (employeeId, start, startTime, endTime) => {
    if (endTime > startTime) {
      let body = {}
      const startingWeek = Moment(start).startOf('week')
      const endingWeek = Moment(start).endOf('week')
      const s = Moment(startingWeek).startOf('day')
      const e = Moment(endingWeek).endOf('day')
      const durationFromNewJob = Moment.duration(Math.abs(startTime.diff(endTime)))
      const differentHours = durationFromNewJob.asHours()
      const baseJobId = this.getBaseJobId()
      // console.log('sss', s.format())
      // console.log('eee', e.format())
      // console.log('differentHours', differentHours)
      body.empId = employeeId
      body.start = s
      body.end = e
      body.newJobHours = differentHours
      body.baseJobId = baseJobId === 'add' ? null : baseJobId
      const r = await baseJobService.getEmployeeTotalMaxHour(body)

      if (r && r.isEmpHasMaxHour !== undefined) {
        this.setState({ employeeJobHoursInfo: r })
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    } else {
      this.setState({ employeeJobHoursInfo: {} })
    }
  }

  checkEmployeeCertificate = async (employee, jobStartDate, jobEndDate) => {
    let { isLicenseExpired: license, isDwesExpired: dwes, isFirstAidExpired: firstAid, isPoliceExpired: police,
      isVehicleRegistrationExpired: vehicleRegistration, isVehicleInsuranceExpired: vehicleInsurance, isWWCCExpired: wwcc } = this.state


    const { item: employeeInfo } = await employeeService.get(employee)
    const { driver_license_expiry: licenseExpiry, dwes_expiry: dwesExpiry, first_aid_expiry: firstAidExpiry, police_expire_date: policeCheck,
      vehicle_registration_expiry: vRegistrationExpiry, vehicle_insurance_expiry: vInsuranceExpiry, work_with_child_expiry: wwccExpiry } = employeeInfo

    // 1. Driver Licence

    if (jobStartDate.isAfter(licenseExpiry) || jobEndDate.isAfter(licenseExpiry)) {
      license = true
    } else {
      license = false
    }

    // 2. DWES

    if (jobStartDate.isAfter(dwesExpiry) || jobEndDate.isAfter(dwesExpiry)) {
      dwes = true
    } else {
      dwes = false
    }

    // 3. First Aid

    if (jobStartDate.isAfter(firstAidExpiry) || jobEndDate.isAfter(firstAidExpiry)) {
      firstAid = true
    } else {
      firstAid = false
    }

    // 4. Police Check

    if (jobStartDate.isAfter(policeCheck) || jobEndDate.isAfter(policeCheck)) {
      police = true
    } else {
      police = false
    }

    // 5. Vehicle Registration

    if (jobStartDate.isAfter(vRegistrationExpiry) || jobEndDate.isAfter(vRegistrationExpiry)) {
      vehicleRegistration = true
    } else {
      vehicleRegistration = false
    }

    // 6. Vechicle Insurance

    if (jobStartDate.isAfter(vInsuranceExpiry) || jobEndDate.isAfter(vInsuranceExpiry)) {
      vehicleInsurance = true
    } else {
      vehicleInsurance = false
    }

    // 7. WWCC

    if (jobStartDate.isAfter(wwccExpiry) || jobEndDate.isAfter(wwccExpiry)) {
      wwcc = true
    } else {
      wwcc = false
    }

    this.setState({
      isLicenseExpired: license,
      isDwesExpired: dwes,
      isFirstAidExpired: firstAid,
      isPoliceExpired: police,
      isVehicleRegistrationExpired: vehicleRegistration,
      isVehicleInsuranceExpired: vehicleInsurance,
      isWWCCExpired: wwcc,
      isCertificateExpired: license || dwes || firstAid || police || vehicleRegistration || vehicleInsurance || wwcc
    })


    // // 5. Trigger alert

    // if (jobStartDate.isAfter(licenseExpiry) || jobEndDate.isAfter(licenseExpiry) || jobStartDate.isAfter(dwesExpiry) || jobEndDate.isAfter(dwesExpiry) ||
    //     jobStartDate.isAfter(firstAidExpiry) || jobEndDate.isAfter(firstAidExpiry) || jobStartDate.isAfter(policeCheck) || jobEndDate.isAfter(policeCheck)) {
    //   this.showEmployeeCertConfirm(employee)
    // }
  }

  checkEmployeeFilesExpiry = async (empId, jobStartDate) => {
    const filter = {}
    const expiredFiles = []

    filter.has_expiry = { condition: '=', value: 'true' }
    filter.active = { condition: '=', value: 'true' }
    filter.module = { condition: '=', value: 'employee' }
    filter.module_id = { condition: '=', value: empId }

    const { list: files } = await employeeFileService.listByPage(1, 0, filter)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.expiry_date && Moment(jobStartDate).isValid()) {
        if (jobStartDate.isAfter(file.expiry_date)) {
          expiredFiles.push(file)
        }
      }
    }

    this.setState({ expiredFiles })
  }

  checkFunderAvailability = (jobStartDate) => {
    const { funderDetail } = this.state

    if (funderDetail) {
      const funderStartDate = Moment(funderDetail.start_date)
      const funderEndDate = Moment(funderDetail.end_date)

      if (jobStartDate) {
        if (jobStartDate.isBetween(funderStartDate, funderEndDate)) {
          this.setState({ showFunderAlert: false })
        } else {
          this.setState({ showFunderAlert: true })
          // this.showFunderUnavailableConfirm()
        }
      }
    }
  }

  checkHoliday = async (date) => {
    const holiday = await settingHolidayService.getTodayHoliday(date)

    if (holiday.list && holiday.list.length > 0) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list[0] })
    } else {
      this.setState({ isHoliday: false })
    }
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSleepover = (start, end) => {
    const isSameDay = Moment(start).isSame(end, 'day')
    const durations = Moment.duration(Math.abs(end.diff(start)))
    const diffHours = durations.asHours()

    if (diffHours > 4) {
      /*   const { billRateInfo } = this.state
      const afterStart = billRateInfo.after_hours_start
      const afterEnd = billRateInfo.after_hours_end
      const normalStart = billRateInfo.normal_hours_start
      const normalEnd = billRateInfo.normal_hours_end

      const afterStartTime = Moment(Moment(afterStart).format('hh:mm a'), 'hh:mm a')
      const afterEndTime = Moment(Moment(afterEnd).format('hh:mm a'), 'hh:mm a')
      const normalStartTime = Moment(Moment(normalStart).format('hh:mm a'), 'hh:mm a')
      const normalEndTime = Moment(Moment(normalEnd).format('hh:mm a'), 'hh:mm a')
*/
      // Need to enhance further
      if (isSameDay) {
        // Same day
        const isStartInAM = start.format('A') === 'AM'
        const isEndInPM = end.format('A') === 'PM'

        if (isStartInAM && !isEndInPM) {
          const { billRateInfo } = this.state

          if (billRateInfo) {
            const midnightStart = Moment(Moment().startOf('day').format('hh:mm a'), 'hh:mm a')
            const afterEndTime = Moment(Moment(billRateInfo.after_hours_end).format('hh:mm a'), 'hh:mm a')
            const startTime = Moment(Moment(start).format('hh:mm a'), 'hh:mm a')
            const endTime = Moment(Moment(end).format('hh:mm a'), 'hh:mm a')

            // console.log(midnightStart, afterEndTime, startTime, endTime, startTime.isBetween(midnightStart, afterEndTime), endTime.isBetween(midnightStart, afterEndTime), endTime.isSame(afterEndTime))

            if ((startTime.isBetween(midnightStart, afterEndTime) || startTime.isSame(midnightStart)) && (endTime.isBetween(midnightStart, afterEndTime) || endTime.isSame(afterEndTime))) {
              this.setState({ isSleepover: true, showSleepoverAlert: true })
            }
          }
          // console.log(afterStartTime, afterEndTime)
        } else {
          this.setState({ isSleepover: false, showSleepoverAlert: false })
        }
      } else {
        // Different Day
        const isStartInPM = start.format('A') === 'PM'
        const isEndInAM = end.format('A') === 'AM'

        if (isStartInPM && isEndInAM) {
          this.setState({ isSleepover: true, showSleepoverAlert: true })
        } else {
          this.setState({ isSleepover: false, showSleepoverAlert: false })
        }
      }
    } else {
      this.setState({ isSleepover: false, showSleepoverAlert: false })
    }

    // this.setState({ isSleepover: false, showSleepoverAlert: false })
  }

  cacheEmployeeName = (employeeId) => {
    if (employeeId) {
      const employeeName = this.getEmployeeName(employeeId)
      this.setState({
        currentEmployee: {
          name: employeeName,
          id: employeeId
        }
      })
    }
  }

  cacaheBillingCategory = () => {
    const { item, currentBillingCategory } = this.state
    if (item.billing_category_id && currentBillingCategory.name === '') {
      const name = this.getBillingCategoryName(item.billing_category_id)
      this.setState({
        currentBillingCategory: {
          name
        }
      })
    }
  }

  getEmployeeName = (employeeId) => {
    const { employees } = this.state
    const employee = employees.filter(item => item.id === employeeId)
    return employee.length > 0 ? `${employee[0].first_name} ${employee[0].last_name}` : ''
  }

  getBillingCategoryName = (billingId) => {
    const { billingCategory } = this.state
    const category = billingCategory.filter(item => item.id === billingId)
    return category.length > 0 ? category[0].name : ''
  }

  getPayrollCategoryName = (payrollValue) => {
    const { payrolls } = this.state
    const payroll = payrolls.filter(item => item.value === payrollValue)
    return payroll.length > 0 ? payroll[0].name : ''
  }

  getChargeHours = () => {
    const { item, settingOthers = {} } = this.state

    if (item.cancellation_penalty > 0) {
      return item.job_hours > settingOthers.cancellation_charge_hour ? settingOthers.cancellation_charge_hour : item.job_hours
    }
    return 0
  }

  getNumberOfWeeks = (date) => {
    const firstDay = Moment(date).startOf('month')
    const endDay = Moment(date).endOf('month')
    const monthRange = momentWeek.range(firstDay, endDay)
    const weeks = []
    const calendar = []

    for (let mday of monthRange.by('days')) {
      if (weeks.indexOf(mday.week()) === -1) {
        weeks.push(mday.week())
      }
    }

    for (let index = 0; index < weeks.length; index++) {
      var weeknumber = weeks[index]
      let firstWeekDay = Moment(firstDay).week(weeknumber).day(0)
      if (firstWeekDay.isBefore(firstDay)) {
        firstWeekDay = firstDay
      }
      let lastWeekDay = Moment(endDay).week(weeknumber).day(6)
      if (lastWeekDay.isAfter(endDay)) {
        lastWeekDay = endDay
      }
      calendar.push(momentWeek.range(firstWeekDay, lastWeekDay))
    }

    return calendar.length
  }

  // 3. UI Control ////////////////////////////

  findEmployees = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleApplyAllJobs = (value) => {
    const { form } = this.props
    const applyAll = value.target.checked

    if (applyAll) {
      this.setState({ allJobChecked: true })
    } else {
      this.setState({ allJobChecked: false })
    }

    this.handleJobAffectedChecking(applyAll)
  }

  handleFromDateChange = (e) => {
    this.setState({ allJobChecked: false })
    const date = Moment.isMoment(e) ? e.clone().format('YYYY-MM-DD') : e
    this.handleJobAffectedChecking(true, e)
  }

  handleChangeFunder = async () => {
    this.setState({ showFunderModal: true })
  }

  handleChangePayroll = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleEmployeeChange = async (value) => {
    // Check if it is emergency
    /*  const { form } = this.props
    const { settingOthers } = this.state
    const formStartDate = Moment(form.getFieldValue('first_job_date'))
    const emergencyParam = settingOthers.emergency_notice
    const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
    const differentHours = durationFromNow.asHours()

    if (differentHours < emergencyParam) {
      this.setState({ emergencyField: true, showEmergencyModal: true, newEmployeeId: value })
      return
    } */

    this.checkEmployee(value, true)
    this.handleConflictChecking()
  }

  handleFunderSubmit = async () => {
    const { clientId, funderId: currrentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['client_funder_id'], async (errors, values) => {
      const funderSettingId = values.client_funder_id
      const clientFunderSetting = await funderService.getClientFunderSetting(funderSettingId)
      const funderId = clientFunderSetting.funder_id
      const funders = await funderService.get(funderId)
      const fundClient = await clientService.getClientFundersInfo(clientId, funderId)
      const funderList = await clientService.getClientFunders(clientId)

      if (currrentFunderId !== funderId) {
        this.fetchBillingRate(funderId)
        form.setFieldsValue({ billing_category_id: null })
      }

      this.setState({ funderInfo: funders.item, funderDetail: clientFunderSetting, funderList, showFunderModal: false, funderId, clientFunderId: funderSettingId })

      if (fundClient.item.end_date === null) {
        this.setState({ notAssignedTime: true })
        this.alertUnassignedTime()
      } else {
        this.setState({ notAssignedTime: false })
      }

      const startDate = form.getFieldValue('job_start_date')
      this.checkFunderAvailability(startDate)
    })
  }

  handleFunderCancel = async () => {
    this.setState({ showFunderModal: false })
  }

  handleIntervalClick = (value) => {
    const { item } = this.state
    const { form } = this.props
    const isNever = item.is_never_end

    if (isNever) {
      form.setFieldsValue({
        is_never_end: false
      })
    }

    if (value === 'monthly') {
      this.setState({ willShowMonthOption: true })
    } else {
      this.setState({ willShowMonthOption: false })
    }

    this.handleConflictChecking()
  }

  handleNeverEndClick = (value) => {
    const { form } = this.props
    const intervalVal = form.getFieldValue('interval')

    if (value) {
      if (intervalVal === 'monthly') {
        this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: false, willShowOnlyMonth: true, isNeverEnd: true })
      } else {
        this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: false, willShowOnlyMonth: false, isNeverEnd: true })
      }
    } else {
      if (intervalVal === 'monthly') {
        this.setState({ willShowFreqAndMonth: true, willShowOnlyFreq: false, willShowOnlyMonth: false, isNeverEnd: false })
      } else {
        this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: true, willShowOnlyMonth: false, isNeverEnd: false })
      }
    }

    form.setFieldsValue({ last_job_date: null })
    this.handleConflictChecking()
  }

  handlePendingEmployee = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({ employeePending: true, expiredFiles: [], employeeJobHoursInfo: {} })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ employeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  hasAccess = (accessLevel) => {
    return authService.hasAccess(accessLevel)
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  onSelectBillingRate = (option) => {
    this.setState({ showGetupHours: option.props.children.indexOf('Sleepover') > -1 })
  }

  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Funding Period Not Defined!',
      content: 'Please assign a funding period for this funder before proceeding. Click Ok to be redirected to the client page or Cancel to choose another Funder',
      onOk () {
        history.replace(`/clients/${clientId}`)
      },
      onCancel () {
      }
    })
  }

  showClientLeaveConfirm = () => {
    const { clientLeaveInfo, clientInfo } = this.state
    warning({
      title: 'Client On Leave',
      content: `${clientInfo.first_name} ${clientInfo.last_name} is on leave from ${Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to ${Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}`
    })
  }

  showDuration = (start, end) => {
    const duration = Moment.duration(Math.abs(Moment(end).diff(start))).asHours()
    this.setState({ jobDuration: `${start.format('hh:mm A')} - ${end.format('hh:mm A')} (${duration} hours)` })
  }

  showEmployeeLeaveConfirm = () => {
    const { selectedEmpInfo } = this.state
    warning({
      title: 'Employee On Leave',
      content: `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} is on leave from ${Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to ${Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}`
    })
  }

  showEmployeeSkillConfirm = () => {
    const { isMatchSkill, missingSkill, selectedEmpInfo } = this.state

    if (!isMatchSkill) {
      warning({
        title: 'Skill Mismatch!',
        content: (<div>
          <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess (following skill(s):</p>
          <ul>
            {
              missingSkill.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))
            }
          </ul>

        </div>)
      })
    }
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Recurring Date Error',
      description: 'End Date should not be earlier than Start Date',
      top: 130
    })
  }

  showFunderUnavailableConfirm = () => {
    warning({
      title: 'Funder Not Available.',
      content: 'This funder is not available on the selected date.'

    })
  }

  showEmployeeCertConfirm = async (employee) => {
    const { item: employeeInfo } = await employeeService.get(employee)
    const { isDwesExpired, isFirstAidExpired, isLicenseExpired, isPoliceExpired } = this.state
    warning({
      title: `Expired Certificate!`,
      content: (<div>
        <p>{employeeInfo.first_name} {employeeInfo.last_name} got one or more expired certificate:</p>
        <ul>
          {isDwesExpired ? <li>DWES Expired</li> : null}
          {isPoliceExpired ? <li>Police Check Expired</li> : null}
          {isLicenseExpired ? <li>Driver License Expired</li> : null}
          {isFirstAidExpired ? <li>First Aid Certificate Expired</li> : null}
        </ul>

      </div>)

    })
  }

  showPreWarning = () => {
    const { isDwesExpired, isPoliceExpired, isLicenseExpired, isFirstAidExpired, isCertificateExpired, showFunderAlert, clientIsOnLeave,
      isOnLeave, isMatchSkill, missingSkill, clientInfo, clientLeaveInfo, selectedEmpInfo, funderDetail } = this.state

    if (isDwesExpired || isPoliceExpired || isLicenseExpired || isFirstAidExpired || showFunderAlert || clientIsOnLeave ||
       isOnLeave || !isMatchSkill) {
      warning({
        title: (<strong>Proceed To {this.isEdit() ? 'Edit' : 'Add'} ?</strong>),
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            {/* certificate */}

            {
              isCertificateExpired ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Expired Certificate:</strong></p>
                <li>
                  <ul>
                    {isDwesExpired ? <li>DWES Expired</li> : null}
                    {isPoliceExpired ? <li>Police Check Expired</li> : null}
                    {isLicenseExpired ? <li>Driver License Expired</li> : null}
                    {isFirstAidExpired ? <li>First Aid Certificate Expired</li> : null}

                  </ul>
                </li>
                <br />
              </div> : null
            }

            {/* Funder */}

            {
              showFunderAlert ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Fund Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>
                <br />
              </div> : null
            }

            {/* Client On Leave */}

            {
              clientIsOnLeave ? <div>
                <div><p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Employee On Leave */}

            {
              isOnLeave ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} is on leave from {Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Skill Mismatch */}

            {
              isMatchSkill ? null
                : <div>
                  <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess following skill(s):</strong></p>
                  <ul>
                    {
                      missingSkill.map((skills, index) => (
                        <li key={index}>{skills}</li>
                      ))
                    }
                  </ul>
                  <br />
                </div>
            }

            {/* <mark><strong>Click OK to go back and made changes or proceed to save.</strong></mark> */}

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks

        },
        onCancel () {
        }
      })
    }
  }

  // 4. General ////////////////////////////

  getBaseJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  isEdit = () => {
    return this.getBaseJobId() !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingJobs,
  fetchTotalPending,
  setRefreshCommLog
}

const mapStateToProps = (state) => {
  return { ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Job))

import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/fvp/job-cost'
const costingUrl = `${url}/costing`
const payrollUrl = `${url}/payroll`

export default {
  getCostingDetail (jobId) {
    return request(`${costingUrl}/detail/${jobId}`)
  },
  updateCostingDetail (jobId, values) {
    return request(`${costingUrl}/detail/${jobId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  refreshCostingDetail (jobId, values) {
    return request(`${costingUrl}/refresh/${jobId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getPayrollDetail (jobId) {
    return request(`${payrollUrl}/detail/${jobId}`)
  },
  updatePayrollDetail (jobId, values) {
    return request(`${payrollUrl}/detail/${jobId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  refreshPayrollDetail (jobId, values) {
    return request(`${payrollUrl}/refresh/${jobId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
}

import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import TimePickerPanel from 'rc-time-picker/lib/Panel'

import 'rc-calendar/assets/index.css'
import 'rc-time-picker/assets/index.css'
import './styles.css'

const timePickerElement = <TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} showSecond={false} />

export default class DateInput extends Component {
  constructor (props) {
    super(props)
    const value = props.value || props.defaultValue
    this.state = {
      value
    }
  }

  render () {
    const { label, placeholder } = this.props
    const calendar = (
      <Calendar
        style={{ zIndex: 1000 }}
        dateInputPlaceholder='Select a date'
        format='YYYY-MM-DD HH:mm:ss'
        defaultValue={moment()}
        timePicker={timePickerElement}
        showDateInput
        showToday
      />
    )

    return (
      <div>
        <DatePicker
          calendar={calendar}
          {...this.props}
        >
          {this.renderText(label, placeholder)}
        </DatePicker>
      </div>
    )
  }

  renderText (label, placeholder) {
    return ({ value }) => label ? (
      <div className='witty-text-container'>
        <div className='witty-label'>{label}</div>

        <div className='witty-text'>
          <input
            className='control'
            placeholder={placeholder}
            {...this.props}
            value={value ? moment(value).format('YYYY-MM-DD hh:mm:ss A') : ''}
          />
        </div>
      </div>
    ) : (
      <div className='witty-text'>
        <div className={'label' + (value ? ' label-visible' : '')}>{placeholder}</div>

        <input
          className='control'
          placeholder={placeholder}
          {...this.props}
          value={value ? moment(value).format('YYYY-MM-DD hh:mm:ss A') : ''}
        />
      </div>
    )
  }
}

DateInput.propTypes = {
  label: PropTypes.node,
  placeholder: PropTypes.string.isRequired
}

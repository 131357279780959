import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { settingOtherService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
import notify from '../../../components/Notification'

const { Item: FormItem } = Form

const settingTitle = 'Settings'

export class ReportSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { loading, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Recipient Settings'>
            {
              this.hasAccess('updateRecipient') ? <div className='btn' onClick={() => this.handleSave()}>
              Save
              </div> : null
            }
          </Page.Header>

          <div className='setting-list'>
            <Skeleton loading={loading} active>
              <Form>
                {/* <Panel title='Email Report Recipients'>
                  <FormItem label='Client Care Plan Review' {...longFormItemLayout}>
                    {getFieldDecorator('client_careplan_email', {
                      initialValue: settings.client_careplan_email,
                      rules: [
                        { required: true, message: 'Please enter client care plan review report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Client Budget' {...longFormItemLayout}>
                    {getFieldDecorator('client_budget_email', {
                      initialValue: settings.client_budget_email,
                      rules: [
                        { required: true, message: 'Please enter client budget report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Client Funding' {...longFormItemLayout}>
                    {getFieldDecorator('client_fund_email', {
                      initialValue: settings.client_fund_email,
                      rules: [
                        { required: true, message: 'Please enter client funding report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Client Leave' {...longFormItemLayout}>
                    {getFieldDecorator('client_leave_email', {
                      initialValue: settings.client_leave_email,
                      rules: [
                        { required: true, message: 'Please enter client leave report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Client Birthday' {...longFormItemLayout}>
                    {getFieldDecorator('client_birthday_email', {
                      initialValue: settings.client_birthday_email,
                      rules: [
                        { required: true, message: 'Please enter client birthday report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Client Files' {...longFormItemLayout}>
                    {getFieldDecorator('client_file_email', {
                      initialValue: settings.client_file_email,
                      rules: [
                        { required: true, message: 'Please enter client file report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Employee Files' {...longFormItemLayout}>
                    {getFieldDecorator('employee_file_email', {
                      initialValue: settings.employee_file_email,
                      rules: [
                        { required: true, message: 'Please enter employee files report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label={`Employee Leave`} {...longFormItemLayout}>
                    {getFieldDecorator('employee_leave_email', {
                      initialValue: settings.employee_leave_email,
                      rules: [
                        { required: true, message: 'Please enter employee leave report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label={`Employee Birthday`} {...longFormItemLayout}>
                    {getFieldDecorator('employee_birthday_email', {
                      initialValue: settings.employee_birthday_email,
                      rules: [
                        { required: true, message: 'Please enter employee birthday report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  {/** MS0130 - FUNDER>INFO: SA Dates */}
                  {/* <FormItem label='Funder Agreement Expiry' {...longFormItemLayout}>
                    {getFieldDecorator('funder_agreement_expiry_email', {
                      initialValue: settings.funder_agreement_expiry_email,
                      rules: [
                        { required: true, message: 'Please enter funder agreement expiry report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem> */}
                  {/* <FormItem label='Feedback' {...longFormItemLayout}>
                    {getFieldDecorator('feedback_report_email', {
                      initialValue: settings.feedback_report_email,
                      rules: [
                        { required: true, message: 'Please enter feedback report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Funder Files' {...longFormItemLayout}>
                    {getFieldDecorator('funder_file_email', {
                      initialValue: settings.funder_file_email,
                      rules: [
                        { required: true, message: 'Please enter funder file report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='User Action' {...longFormItemLayout}>
                    {getFieldDecorator('user_action_email', {
                      initialValue: settings.user_action_email,
                      rules: [
                        { required: true, message: 'Please enter user action report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Back-To-Back Shift' {...longFormItemLayout}>
                    {getFieldDecorator('back_to_back_email', {
                      initialValue: settings.back_to_back_email,
                      rules: [
                        { required: true, message: 'Please enter back-to-back report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Clashed Shift' {...longFormItemLayout}>
                    {getFieldDecorator('clash_back_email', {
                      initialValue: settings.clash_back_email,
                      rules: [
                        { required: true, message: 'Please enter clashed report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Last Job Count' {...longFormItemLayout}>
                    {getFieldDecorator('last_job_count_email', {
                      initialValue: settings.last_job_count_email,
                      rules: [
                        { required: true, message: 'Please enter last job count report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Multi Client Shift' {...longFormItemLayout}>
                    {getFieldDecorator('multi_client_email', {
                      initialValue: settings.multi_client_email,
                      rules: [
                        { required: true, message: 'Please enter multi client shift report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Job Timesheet' {...longFormItemLayout}>
                    {getFieldDecorator('job_timesheet_report_email', {
                      initialValue: settings.job_timesheet_report_email,
                      rules: [
                        { required: true, message: 'Please enter job timesheet report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel> */} 

                <Panel title='Changes Notification Recipients'>
                  <FormItem label='Client Changes' {...longFormItemLayout}>
                    {getFieldDecorator('client_changes_email', {
                      initialValue: settings.client_changes_email,
                      rules: [
                        { required: true, message: 'Please enter client changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Employee Changes' {...longFormItemLayout}>
                    {getFieldDecorator('employee_changes_email', {
                      initialValue: settings.employee_changes_email,
                      rules: [
                        { required: true, message: 'Please enter employee changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Funder Changes' {...longFormItemLayout}>
                    {getFieldDecorator('funder_changes_email', {
                      initialValue: settings.funder_changes_email,
                      rules: [
                        { required: true, message: 'Please enter funder changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Timesheet Changes' {...longFormItemLayout}>
                    {getFieldDecorator('timesheet_changes_email', {
                      initialValue: settings.timesheet_changes_email,
                      rules: [
                        { required: true, message: 'Please enter timesheet changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>
                <Panel title='Questionnaire Feedback'>
                  <FormItem label='Health Feedback' {...longFormItemLayout}>
                    {getFieldDecorator('questionnaire_health_email', {
                      initialValue: settings.questionnaire_health_email,
                      rules: [
                        { required: true, message: 'Please enter questionnaire health feedback email recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Job Feedback' {...longFormItemLayout}>
                    {getFieldDecorator('questionnaire_job_feedback_email', {
                      initialValue: settings.questionnaire_job_feedback_email,
                      rules: [
                        { required: true, message: 'Please enter questionnaire job feedback email recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>
                <Panel title='Feedback'>
                  <FormItem label='Feedback Added' {...longFormItemLayout}>
                    {getFieldDecorator('feedback_added_email', {
                      initialValue: settings.feedback_added_email,
                      rules: [
                        { required: true, message: 'Please enter feedback added email recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>
                <Panel title='Profile Export Recipients'>
                  <FormItem label='Profile Export Recipients' {...longFormItemLayout}>
                    {getFieldDecorator('setting_contact_export_email', {
                      initialValue: settings.setting_contact_export_email,
                      rules: [
                        { required: true, message: 'Please enter feedback added email recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>
              </Form>

            </Skeleton>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportSetting))

import { settingOtherService } from '../services'

export default {
  async client () {
    return getNumber('R', 'client_acc_ref')
  },
  async employee () {
    return getNumber('E', 'employee_acc_ref')
  },
  async funder () {
    return getNumber('F', 'funder_acc_ref')
  }
}

async function getNumber (prefix, field) {
  const { item } = await settingOtherService.get(1)
  const number = parseInt(item[field]) + 1
  const formattedNumber = padNumber(number)

  const values = {}
  values[field] = formattedNumber

  await settingOtherService.save(1, values)
  return prefix + formattedNumber
}

function padNumber (number) {
  return ('00000' + number).slice(-5)
}

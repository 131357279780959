import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import CancellationReason from './Cancellation'
import ReasonDetails from './ReasonDetails'
import './styles.css'

const TabPane = Tabs.TabPane
const settingTitle = 'Reason'

export class SettingReason extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addCancellation: false,
      addReason: false,
      currentPage: 1,
      activeTab: '1'
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    const tab = (new URLSearchParams(window.location.search)).get('t')
    this.setState({ activeTab: tab === 'resigned' ? '2' : '1' })
  }

  addCancellation = () => {
    // this.setState({ addCancellation: true })
    this.props.history.replace('/settings/cancellations/add')
  }

  addReason = () => {
    this.props.history.replace('/settings/resigned-or-exited/add')
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addCancellation, addReason, activeTab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
            <TabPane tab='Cancellations' key='1'>
              <div className='header-action'>
                { this.hasAccess('createCancellation') ? <div className='btn' onClick={() => this.addCancellation()}>
                  Add Cancellation
                </div> : null }
              </div>
              <CancellationReason onAdd={addCancellation} onComplete={() => this.setState({ addCancellation: false })} />
            </TabPane>

            <TabPane tab='Inactive (Employee)' key='2'>
              <ReasonDetails name={'Inactive (Employee)'} code={'inactive-employee'} />
            </TabPane>

            <TabPane tab='Inactive (Client)' key='3'>
              <ReasonDetails name={'Inactive (Client)'} code={'inactive-client'} />
            </TabPane>

            <TabPane tab='Timesheet Reject' key='4'>
              <ReasonDetails name={'Timesheet Reject'} code={'job-ts-reject'} />
            </TabPane>
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingReason))

export default [
  {
    name: 'Employee List',
    desc: 'Manage all customers from different channels',
    icon: 'team',
    link: '/employees',
    enable: true,
    permission: 'listEmployees'
  }/* ,
  {
    name: 'Add Client',
    desc: 'Membership',
    icon: 'usergroup-add',
    link: '/clients/add'
  } */
]

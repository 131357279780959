export default [
  {
    name: 'Inbound',
    desc: 'List inbound issues',
    icon: 'login',
    permission: 'listJobs',
    submenu: [
      {
        name: 'Pending Issues',
        link: '/helpdesk',
        count: 'individual'
      },
      {
        name: 'All Issues',
        link: '/helpdesk',
        count: 'recurring'
      }
    ]
  },
  {
    name: 'Outbound',
    desc: 'List outbound issues',
    icon: 'logout',
    permission: 'listJobs',
    submenu: [
      {
        name: 'Pending Issues',
        link: '/helpdesk',
        count: 'individual'
      },
      {
        name: 'All Issues',
        link: '/helpdesk',
        count: 'recurring'
      }
    ]
  },
]

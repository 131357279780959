import React, { Component } from 'react'
import moment from 'moment-timezone'
import ReportMenu from '../../../constants/menu/report'
import { authService, jobTimesheetService } from '../../../services'
import { exportFile, formatter, log, validator } from '../../../util'
import { timezone } from '../../../config'
// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import TimesheetTemplate from '../../JobJvp/Timesheet'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
moment.tz.setDefault('Australia/Melbourne')

const pageSize = 20
const dateFormat = 'DD/MM/YYYY hh:mmA'

const DefaultSort = { job_start_date: -1 }

const VIEW_PENDING = 'pending'
const VIEW_REVIEW = 'review'
const VIEW_REJECTED = 'rejected'
const TITLE_REVIEW = 'In Review'
const TITLE_REJECTED = 'Rejected'

export class ReportJobTimesheet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      jobList: [],
      currentPage: 1,
      filter: {},
      isLoaded: false,
      isModal: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort),
      signoffInfo: {},
      total: 0,
      view: VIEW_REVIEW,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchReport()
    this.fetchOtherSettings()
  }

  onShowSignoff (item) {
    console.log('on show signoff', item)
    // json parse the object of signed location
    let signoffCoord = { latitude: NaN, longitude: NaN }
    try {
      signoffCoord = JSON.parse(item.job_jts_signed_location)
    } catch (e) {
      console.log('sign off coords e', e)
    }

    let clientCoord = { latitude: NaN, longitude: NaN }
    try {
      clientCoord = JSON.parse(item.job_jts_signed_client_location)
    } catch (e) {
      console.log('client coords e', e)
    }

    const clientCoords = {latitude: parseFloat(clientCoord.latitude), longitude: parseFloat(clientCoord.longitude)}
    const signoffCoords = {latitude: parseFloat(signoffCoord.latitude), longitude: parseFloat(signoffCoord.longitude)}

    // appened some additional data for TimesheetTemplate and handleDeleteTimesheet function
    item.kms = item.job_jts_recorded_kms
    item.job_jts_signed_location = signoffCoords
    item.job_jts_signed_client_location = clientCoords

    this.setState({ signoffInfo: item, isModal: true })
  }

  onCloseSignoff () {
    this.setState({ signoffInfo: {}, isModal: false })
  }

  render () {
    const { jobList, currentPage, filter, isModal, loading, loadingExport, loadingList, sort, signoffInfo, total, view, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    const title = view === VIEW_REVIEW ? TITLE_REVIEW : view === VIEW_REJECTED ? TITLE_REJECTED : ''

    const columns = [
      {
        title: 'Job Start Date',
        width: 4,
        render: ({ job_start_date }) => {
          const std = moment(job_start_date).format(dateFormat)
          return <div>{std}</div>
        },
        key: 'job_start_date',
        onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Job End Date',
        width: 4,
        render: ({ job_end_date }) => {
          const etd = moment(job_end_date).format(dateFormat)
          return <div>{etd}</div>
        },
        key: 'job_end_date',
        onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Client',
        width: 4,
        render: ({ client_fullname }) => {
          return <div>{client_fullname}</div>
        },
        key: 'client_fullname',
        onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Employee',
        width: 4,
        render: ({ employee_fullname }) => {
          return <div>{employee_fullname}</div>
        },
        key: 'employee_fullname',
        onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Funder',
        width: 4,
        render: ({ funder_fullname }) => {
          return <div>{funder_fullname}</div>
        },
        key: 'funder_fullname',
        onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Outside Duration?',
        width: 1,
        render: ({ job_jts_is_signed_late, job_jts_job_end_time_diff, job_jts_signed_config_value_intime }) => {
          const durationText = formatter.toTimeCountText(job_jts_job_end_time_diff, 'After Job End Date', 'Before Job End Date')
          const title = job_jts_is_signed_late && job_jts_job_end_time_diff !== null
            ? <div>{durationText}<br/>{`In-time Submit Timeframe: +/- ${job_jts_signed_config_value_intime} min(s)`}</div>
            : <div>{durationText}</div>
          return <div>
            <Tooltip mouseLeaveDelay={0} title={title}>
              { job_jts_is_signed_late
                ? <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
                : <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>}
            </Tooltip>
          </div>
        },
        key: ['job_jts_is_signed_late', {key: 'job_jts_job_end_time_diff_abs', sort: -1}],
        onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Out of Max KM?',
        width: 1,
        render: ({ job_jts_is_outside_kms, job_jts_kms_diff, job_jts_recorded_kms, job_jts_job_kms }) => {
          const jobKmsText = (job_jts_job_kms === null || job_jts_job_kms === '' || job_jts_job_kms === undefined) ? 'No Max KM' : (!parseFloat(job_jts_job_kms)) ? 'KMS Not Allowed' : `${job_jts_job_kms} KM Allowed`

          const title = job_jts_is_outside_kms && job_jts_recorded_kms !== null
            ? `${job_jts_recorded_kms} KM recorded (${jobKmsText})`
            : 'No submitted KMS recorded'

          return <div>
            <Tooltip mouseLeaveDelay={0} title={title}>
              { job_jts_is_outside_kms
                ? <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
                : <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>}
            </Tooltip>
          </div>
        },
        key: ['job_jts_is_outside_kms', {key: 'job_jts_kms_diff', sort: -1}],
        onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Out of Location?',
        width: 1,
        render: ({ job_jts_is_outside_loc, job_jts_signed_distance, job_jts_signed_config_value_radius }) => {
          const distanceText = `${formatter.toDecimal(job_jts_signed_distance)}m`
          const title = job_jts_is_outside_loc && job_jts_signed_distance !== null
            ? <div>{distanceText}<br/>{`Location Radius: +/- ${job_jts_signed_config_value_radius} meter(s)`}</div>
            : <div>{distanceText}</div>

          return <div>
            <Tooltip mouseLeaveDelay={0} title={title}>
              { job_jts_is_outside_loc
                ? <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
                : <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>}
            </Tooltip>
          </div>
        },
        key: ['job_jts_is_outside_loc', {key: 'job_jts_signed_distance', sort: -1}],
        onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Out of Accuracy?',
        width: 1,
        render: ({ job_jts_is_outside_acc, job_jts_signed_accuracy, job_jts_signed_config_value_radius }) => {
          const distanceText = `${formatter.toDecimal(job_jts_signed_accuracy)}m`
          const title = job_jts_is_outside_acc && job_jts_signed_accuracy !== null
            ? <div>{distanceText}<br/>{`Location Radius: +/- ${job_jts_signed_config_value_radius} meter(s)`}</div>
            : <div>{distanceText}</div>
          return <div>
            <Tooltip mouseLeaveDelay={0} title={title}>
              { job_jts_is_outside_acc
                ? <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
                : <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>}
            </Tooltip>
          </div>
        },
        key: ['job_jts_is_outside_acc', {key: 'job_jts_signed_accuracy', sort: -1}],
        onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: '',
        width: 1,
        render: (item) => {
          const { id, job_jts_id, job_jts_is_signed_late, job_jts_is_outside_kms, job_jts_is_outside_loc, job_jts_is_outside_acc } = item
          const isOut = job_jts_is_signed_late || job_jts_is_outside_kms || job_jts_is_outside_loc || job_jts_is_outside_acc

          return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {job_jts_id
              ? <Tooltip mouseLeaveDelay={0} title={`${isOut ? 'Signed with issue(s)' : 'Signed with no issue'}`}>
                <Icon type='edit' onClick={() => this.onShowSignoff(item)} style={{fontSize: '12pt', color: isOut ? 'red' : 'green', marginRight: '15px'}} theme='filled' />
              </Tooltip>
              : <Tooltip mouseLeaveDelay={0} title={`Not Signed`}>
                <Icon type='edit' style={{fontSize: '12pt', color: '#888', marginRight: '15px'}} theme='filled' />
              </Tooltip> }
            <Tooltip mouseLeaveDelay={0} title='View Job'>
              <a href={`/jobs/single/${id}`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
          </div>)
        }
      }
    ]


    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Job Timesheet Report`}>
            { this.hasAccess('readJobTimesheetReport')
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }
          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('job_timesheet_report_email', {
                          initialValue: settings.job_timesheet_report_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form>: null
              : null }   
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={12}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group disabled={loading || loadingList} defaultValue={view} onChange={(v) => this.handleChangeView(v)}>
                  <Radio.Button value={VIEW_REVIEW}> In Review </Radio.Button>
                  <Radio.Button value={VIEW_REJECTED}> Rejected </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div className='report-ts'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div>
                  <div className='report-title'>{`Job Timesheet Report ${title ? `- ${title}` : ''}`} <span className='report-tag'>{total}</span></div>
                  <List cols={columns} rows={jobList} />

                  <Pager
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} job${total === 1 ? '' : 's'}`}
                    current={currentPage}
                    onChange={(e) => this.changePage(e)}
                    style={{ marginTop: '15px' }}
                  />

                  <TimesheetTemplate
                    info={signoffInfo}
                    visible={isModal}
                    onCloseSignoffModal={() => this.onCloseSignoff()}
                    onDeleteTimesheet={() => this.handleDeleteTimesheet()}
                    mode={'modal-delete'}
                  />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchReport () {
    const { currentPage, filter, isLoaded, loading, sort, view } = this.state
    this.fetchJobTimesheetReport({currentPage, filter, isLoaded, loading, sort, view})
  }

  async fetchJobTimesheetReport ({ currentPage, filter = {}, sort = {}, isLoaded = true, loading, view }) {
    if (this.hasAccess('readJobTimesheetReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      let newFilter = {}

      if (view === VIEW_REVIEW) {
        newFilter.status = {$or: [{ condition: '=', value: VIEW_REVIEW }, { condition: '=', value: VIEW_PENDING }]}
        newFilter.job_jts_is_ts_mismatch = { condition: '=', value: true }
        newFilter.job_jts_id = { condition: 'is not null' }
      } else if (view === VIEW_REJECTED) {
        newFilter.status = { condition: '=', value: VIEW_REJECTED }
      } else {
        newFilter = Object.assign({}, filter)
      }

      const { list: jobList, total } = await jobTimesheetService.listJobByPage(currentPage, pageSize, newFilter, sort)
      this.setState({ jobList, loading: false, loadingList: false, filter: newFilter, sort, isLoaded: true, total }, () => this.toTop())
    }
  }

  async handleDeleteTimesheet () {
    const { signoffInfo } = this.state

    try {
      this.onCloseSignoff()
      this.setState({ loadingList: true })
      const r = await jobTimesheetService.remove(signoffInfo.signed_timesheet_id, signoffInfo.id)

      if (r && r.id) {
        if (r.errors) {
          notify.error('Unable to delete timesheet', r.message || 'Unable to remove the timesheet. Please try again later')
        } else {
          await this.fetchReport()
          log.updateJobExtra(signoffInfo.id, 'update timesheet', `Timesheet with raw ID ${signoffInfo.signed_timesheet_id} is removed.`)
          notify.success('Delete Timesheet Successfully', 'The timesheet is removed successfully.')
        }
      } else {
        notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')
        this.setState({ loadingList: false })
      }
    } catch (e) {
      notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')

      this.setState({ loadingList: false })
    }
  }

  handleChangeView (e) {
    this.setState({ view: e.target.value, isLoaded: false, loading: true, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  handleSort (key, order) {
    const { filter } = this.state
    let newSort = {}

    if (order === 0) {
      newSort = Object.assign({}, DefaultSort)
    } else {
      newSort = Object.assign({}, {[key]: order})
    }

    this.setState({ currentPage: 1, filter, isLoaded: true, sort: newSort }, () => {
      this.fetchReport()
    })
  }

  handleSortItem (sort) {
    const { filter } = this.state

    let newSort = Object.assign({}, sort)
    for (let s in newSort) {
      if (newSort[s] === 0) {
        // a special handling on new sort when main key is back to 0
        // do not copy this function to other page unless other page has similar treatment
        newSort = {}
      }
    }

    if (!validator.isNotEmptyObject(newSort)) {
      newSort = Object({}, DefaultSort)
    }

    this.setState({ currentPage: 1, filter, isLoaded: true, sort: newSort }, () => {
      this.fetchReport()
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readJobTimesheetReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchExport('job-timesheet-export')
      this.setState({ loadingExport: false })
    }, 4000)
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  changePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportJobTimesheet))

import {
  FETCHING_JOB_LIST,
  JOB_LIST_FETCHED
} from '../actions/jvpJob'

const INITIAL_STATE = {
  jobList: { list: [], total: 0, ts: Date.now() },
  loadingList: true,
  loadingSummary: true,
  isLoaded: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_JOB_LIST:
      return handleFetchingJobList(state, action)
    case JOB_LIST_FETCHED:
      return handleJobListFetched(state, action)
    default:
      return state
  }
}

function handleFetchingJobList (state, action) {
  const { loadingList, isLoaded } = action
  return { ...state, loadingList, isLoaded: isLoaded === null || isLoaded === undefined ? state.isLoaded : isLoaded }
}

function handleJobListFetched (state, action) {
  const { jobs, loadingList } = action
  return { ...state, jobList: jobs, loadingList, isLoaded: true }
}


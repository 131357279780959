export const FETCH_JOB_LIST = 'FETCH_JOB_LIST'
export const FETCHING_JOB_LIST = 'FETCHING_JOB_LIST'
export const JOB_LIST_FETCHED = 'JOB_LIST_FETCHED'

export const fetchJobList = (params) => {
  return Object.assign({
    type: FETCH_JOB_LIST
  }, params)
}

export const fetchingJobList = (loading) => {
  return {
    type: FETCHING_JOB_LIST,
    loading
  }
}

export const jobListFetched = (jobs) => {
  return {
    type: JOB_LIST_FETCHED,
    jobs
  }
}

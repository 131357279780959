import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { authService, reportService } from '../../../services'
import { ExportType } from '../../../constants'
import { common, exportFile, formatter, log, validator } from '../../../util'
import { Link } from 'react-router-dom'
import { apiHostname } from '../../../config'

// UI
import { Button, ControlLabel, Loading, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
const { RangePicker } = DatePicker

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const pageSize = 30
const dateFormat = 'YYYY-MM-DD'

const ActionAdd = 'add'
const ActionCancel = 'cancel'
const ActionUncancel = 'uncancel'
const ActionUpdate = 'update'
const ActionTotal = 'total'
const ActionPercentageTotal = '-per-total'
const ActionGrandTotal = '-per-grand-total'
const ActionPercentageAction = '-per-action'

export class ReportUserJobActionList extends Component {
  constructor (props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    const { dateFrom, dateTo } = common.getQueryStringSearchParams(location.search)

    const df = dateFrom ? moment(dateFrom) : moment().add(-1, 'day')
    const dt = dateTo ? moment(dateTo) : moment().add(-1, 'day')

    this.state = {
      currentPage: 1,
      dateFrom: df,
      dateTo: dt,
      jobs: { list: [], total: 0 },
      info: {
        total_summary: { [ActionAdd]: 0, [ActionUpdate]: 0, [ActionCancel]: 0, [ActionUncancel]: 0 },
        actions: [],
        summary: {},
        admin: '',
        role: ''
      },
      isLoaded: false,
      isLoadedSummary: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      loadingSummary: false,
      pageKey: key,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.type !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, pageKey: location.key }
  }

  componentDidMount () {
    this.fetchSummary()
    this.fetchList()
  }

  render () {
    const { history, match } = this.props
    const { currentPage, dateFrom, dateTo, info, jobs, isLoaded, isLoadedSummary, loading, loadingList, loadingExport, loadingSummary } = this.state
    const { form } = this.props
    const code = this.getUserCode()
    const isMe = code === 'me'

    const columns = [
      {
        title: 'Date',
        width: 3.5,
        render: ({ created_at }) => formatter.toStandardLongDate(created_at)
      },
      {
        title: 'Action',
        width: 1.5,
        key: 'action',
        render: ({ action }) => <div className='user-action-type' style={action === 'add' ? { background: '#52d9a733', color: '#2ed394' } : action === 'cancel' ? { background: '#e9235a22', color: '#e9235a' } : action === 'uncancel' ? { background: '#ffa50122', color: '#ffa501' } : {}}>{action.toUpperCase()}</div>
      },
      {
        title: 'Job Type',
        width: 1.5,
        key: 'type',
        render: ({ job_type }) => <div>{formatter.capitalize(job_type)}</div>
      },
      {
        title: 'Job Date',
        width: 5,
        render: ({ job_type, job_start_date, job_end_date, first_job_date, interval }) => job_type === 'recurring'
          ? `Every ${interval === 'fortnight' ? 'Fortnight ' : ''}${formatter.toDurationText(job_start_date, job_end_date, 'hh:mm A', 'ddd')}`
          : formatter.toDurationText(job_start_date, job_end_date)
      },
      {
        title: 'Client',
        width: 3.5,
        render: ({ client_id, client_fullname }) => <div><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
      },
      {
        title: 'Employee',
        width: 3.5,
        render: ({ employee_id, employee_fullname }) => <div><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
      },
      {
        title: 'Funder',
        width: 4,
        render: ({ funder_id, funder_fullname }) => <div><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
      },
      {
        title: 'Single/Recurring',
        width: 2,
        render: ({ job_type, job_id, job_base_id }) => <div className='action-buttons-s2'>
        { job_id
          ? <Tooltip mouseLeaveDelay={0} title='View Job'>
            <a href={`/jobs/single/${job_id}`} rel='noopener noreferrer' target='_blank'>
              <Icon type='eye' />
            </a>
          </Tooltip>
          : <div style={{width: '12px'}} /> }
        { job_base_id
          ? <Tooltip mouseLeaveDelay={0} title='View Base Job'>
            <a href={`/jobs/recurring/${job_base_id}`} rel='noopener noreferrer' target='_blank'>
              <Icon type='eye' />
            </a>
          </Tooltip>
          : <div style={{width: '12px'}} /> }
      </div>
      }
    ]

    const title = loadingSummary && !isLoadedSummary
      ? `System User Action`
      : `System User Action${info.admin ? ` - ${info.admin} (${info.role})` : ''}`

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={`${title}`}>
            { (this.hasAccess('readActionReport') || isMe) && !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
          </Page.Header>

          <ControlLabel>Date Range</ControlLabel>
          <RangePicker
            disabled={loading || loadingExport || loadingList || loadingSummary}
            format="DD/MM/YYYY"
            onChange={(d1, d2) => this.onUpdateRange(d1, d2)}
            defaultValue={[dateFrom, dateTo]}
          />

          <div className='action-job-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div className='summary-list'>
                  <table width='70%' style={{ marginTop: '20px' }} cellPadding='2' cellSpacing='2'>
                    <tbody>
                      <tr>
                        <td width='25%' style={{ textAlign: 'center' }}>
                          <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionAdd}`]}</div> New Job
                        </td>
                        <td width='25%' style={{ textAlign: 'center' }}>
                          <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionUpdate}`]}</div> Amend Job
                        </td>
                        <td width='25%' style={{ textAlign: 'center' }}>
                          <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionCancel}`]}</div> Cancel Job
                        </td>
                        <td width='25%' style={{ textAlign: 'center' }}>
                          <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionUncancel}`]}</div> Uncancel Job
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='action-job-list'>
                  <List cols={columns} rows={jobs.list} />

                  <Pager
                    size={pageSize}
                    total={jobs.total}
                    totalText={`Total ${jobs.total} action${jobs.total === 1 ? '' : 's'}`}
                    current={currentPage}
                    onChange={(e) => this.onChangePage(e)}
                    style={{ marginTop: '15px' }}
                  />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList = () => {
    const { match } = this.props
    const { params } = match
    const { user, from, to } = params
    const { currentPage, isLoaded } = this.state
    this.fetchJobs({ currentPage, user, isLoaded, from, to })
  }

  fetchSummary = async () => {
    const { dateFrom, dateTo } = this.state
    const code = this.getUserCode()
    this.toTop()

    if (code) {
      if (this.hasAccess('readActionReport') || code === 'me') {
        this.setState({ loadingSummary: true })

        const from = dateFrom.format(dateFormat)
        const to = dateTo.format(dateFormat)

        const smr = await reportService.getListByDate(ExportType.REPORT.USER_ACTION, from, to, code)

        this.setState({ info: smr, loadingSummary: false, isLoadedSummary: true })
      }
    }
  }

  fetchJobs = async ({ currentPage, user, isLoaded }) => {
    const { dateFrom, dateTo } = this.state
    const code = this.getUserCode()

    if (code) {
      if (this.hasAccess('readActionReport') || code === 'me') {
        if (isLoaded) {
          this.setState({ loadingList: true })
        } else {
          this.setState({ loading: true })
        }

        const from = dateFrom.format(dateFormat)
        const to = dateTo.format(dateFormat)

        const jobs = await reportService.getListByDateViewPage(ExportType.REPORT.USER_ACTION, code, from, to, currentPage, pageSize)

        this.setState({ jobs, isLoaded: true, loading: false, loadingList: false })
      }
    }

    this.toTop()
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  onUpdateRange (d1, d2) {
    const { loading, loadingList, loadingExport } = this.state

    if (loading || loadingList || loadingExport) return

    if (validator.isNotEmptyArray(d1)) {
      this.setState({ dateFrom: d1[0], dateTo: d1[1], isLoaded: false }, () => {
        this.fetchSummary()
        this.fetchList()
      })
    }
  }

  handleExport = async () => {
    const { dateFrom, dateTo, loadingExport, loading, loadingList, loadingSummary } = this.state
    const code = this.getUserCode()
    const isMe = code === 'me'

    if (loadingExport || loading || loadingList || loadingSummary || !(this.hasAccess('readActionReport') || isMe)) return

    this.setState({ loadingExport: true })

    try {
      setTimeout(async () => {
        const code = this.getUserCode()

        const r = await exportFile.fetchReportExport(ExportType.REPORT.USER_ACTION, { user: code, from: dateFrom, to: dateTo })
        this.setState({ loadingExport: false })
      }, 1000)
    } catch (e) {
      notify.error('Unable to Export the Report', 'Please try again later.')
    }
  }

  getUserCode = () => {
    const { match } = this.props
    const { params } = match
    const { usercode } = params

    return usercode
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportUserJobActionList))

import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/jvp/leaves'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getInfo (id, view, viewId) {
    return request(`${url}/info/${id}/${view}/${viewId}`)
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAll (view, id) {
    return request(`${url}/list/all/${view}/${id}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listImpactedJobs (id, view, viewId) {
    return request(`${url}/jobs/${id}/${view}/${viewId}`)
  },
  validateImpactedJobs(values) {
    return request(`${url}/validate/impacted-jobs`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}

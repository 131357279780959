export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES'
export const FETCHING_EMPLOYEES = 'FETCHING_EMPLOYEES'
export const EMPLOYEES_FETCHED = 'EMPLOYEES_FETCHED'
export const FETCH_MANDATORY_FILES = 'FETCH_MANDATORY_FILES'
export const FETCHING_MANDATORY_FILES = 'FETCHING_MANDATORY_FILES'
export const MANDATORY_FILES_FETCHED = 'MANDATORY_FILES_FETCHED'

export const fetchEmployees = (params) => {
  return Object.assign({
    type: FETCH_EMPLOYEES
  }, params)
}

export const fetchingEmployees = (loading) => {
  return {
    type: FETCHING_EMPLOYEES,
    loading
  }
}

export const employeesFetched = (employees) => {
  return {
    type: EMPLOYEES_FETCHED,
    employees
  }
}

export const fetchMandatoryFiles = (params) => {
  return Object.assign({
    type: FETCH_MANDATORY_FILES
  }, params)
}

export const fetchingMandatoryFiles = (loading) => {
  return {
    type: FETCHING_MANDATORY_FILES,
    loading
  }
}

export const employeesMandatoryFiles = (saveMandatoryList) => {
  return {
    type: MANDATORY_FILES_FETCHED,
    saveMandatoryList
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jvpJobRecurringService, employeeService, authService } from '../../../../services'
import { ExportType, JobListType, JobFilterType } from '../../../../constants'
import moment from 'moment-timezone'
import { exportFile, formatter, log, validator } from '../../../../util'
import { apiHostname, jobURL, timezone } from '../../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import JobRecurringView from '../../../JobJvp/Recurring/View'

const pageSize = 20

moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker

export class EmployeeJobsRecurringListJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: JobFilterType.JOB_TYPE_ACTIVE,
      searchText: '',
      list: [],
      total: 0,
      loading: false,
      showJobModal: false,
      showReminderDate: false,
      showExportDate: false
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.refreshJob()
  }

  filterJob (e) {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchEmployeeJob({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state
    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          const w = words[i]

          if (w) {
            filter.$and.push({
              $or: [
                { client_fullname: { condition: 'ilike', value: `%${w}%` } },
                { employee_fullname: { condition: 'ilike', value: `%${w}%` } },
                { funder_fullname: { condition: 'ilike', value: `%${w}%` } },
                { client_suburb: { condition: 'ilike', value: `%${w}%` } },
                { string_recurring_start_day: { condition: 'ilike', value: `%${w}%` } },
                { tasks: { condition: 'ilike', value: `%${w}%` } },
                { string_recurring_start_date: { condition: 'ilike', value: `%${w}%` } }
              ]
            })
          }
        }

        if (value && value.trim() !== '') {
          filter.$and.push({
            $or: [
              { client_fullname: { condition: 'ilike', value: `%${value}%` } },
              { employee_fullname: { condition: 'ilike', value: `%${value}%` } },
              { funder_fullname: { condition: 'ilike', value: `%${value}%` } },
              { client_suburb: { condition: 'ilike', value: `%${value}%` } },
              { string_recurring_start_day: { condition: 'ilike', value: `%${value}%` } },
              { tasks: { condition: 'ilike', value: `%${value}%` } },
              { string_recurring_start_date: { condition: 'ilike', value: `%${value}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchEmployeeJob({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { employeeId, data, form } = this.props
    const { currentPage, loading, list, total, showJobModal, showReminderDate, showExportDate } = this.state

    const { getFieldDecorator } = form

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              <div className='btn' onClick={() => this.refreshJob()}>
                Refresh
              </div>
            </Col>
          </Row>

        </div>

        <Radio.Group onChange={this.filterJob} defaultValue={JobFilterType.JOB_TYPE_ACTIVE} style={{ marginLeft: 20 }}>
          <Radio.Button value={JobFilterType.JOB_TYPE_ACTIVE}>Active</Radio.Button>
          <Radio.Button value={JobFilterType.JOB_TYPE_ENDED}>Ended</Radio.Button>
        </Radio.Group>

        <div className='jobs-jvp extra-padding'>
          <Skeleton loading={loading} active>
            {list.map((item) => {
              return (
                <JobRecurringView
                  item={item}
                  listType={JobListType.JOB_LIST_RECURRING}
                />
              )
            })}
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} job${total === 1 ? '' : 's'}`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  refreshJob = async () => {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchEmployeeJob({ currentPage, filter, loading, searchText, sort, filterParam: filterParam })
  }

  fetchEmployeeJob = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { employeeId } = this.props

    try {
      const page = startPage || currentPage
      const newFilter = { employee_id: { condition: '=', value: employeeId } }

      const { list, total } = await jvpJobRecurringService.listViewByPage(filterParam, currentPage, pageSize, newFilter, sort, searchText)

      this.setState({ list, loading: false, filterParam, total, currentPage: page, filter: newFilter, searchText })
    } catch (e) {
      notify.error('Unable to fetch Jobs', 'Please try again later')
    }
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, type } = this.state
    this.fetchEmployeeJob({ currentPage, filter, searchText, sort, filterParam: type })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeJobsRecurringListJvp))

import React, { Component } from 'react'
import moment from 'moment-timezone'
import { clientService, fvpClientFundingService } from '../../../services'
import { formatter, validator } from '../../../util'
import { JobFilterType, JobListType } from '../../../constants'
import { jobURL, timezone } from '../../../config'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

moment.tz.setDefault(timezone)

class AddJobModal extends Component {
  state = {
    clientList: [],
    funderList: [],
    isLoadingFunder: false,
    isFunderDisabled: true
  }

  componentDidMount () {
    const { clientId, form } = this.props
    if (clientId) {
      this.fetchFunders(clientId)
    }
    this.fetchClients()
  }

  fetchClients = async () => {
    try {
      const clientList = await clientService.listByPageActive(1, 0)
      this.setState({ clientList: clientList.list })
    } catch (e) {
      // notify.error('Unable to load successfully', 'Unable to load clientList successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId) => {
    const funderList = await fvpClientFundingService.listClientFunders(clientId)

    if (validator.isNotEmptyArray(funderList)) {
      this.setState({ funderList, isFunderDisabled: false })
    }
  }

  onSelectClient = async (value, option) => {
    const { clientList } = this.state
    const { form } = this.props
    this.setState({ isLoadingFunder: true })

    const c = clientList.find(e => e.id === value)

    if (c && c.id && (c.public_alert || c.private_alert)) {
      const privateAlert = this.getAlertMsgHtml(c, 'private_alert')
      const publicAlert = this.getAlertMsgHtml(c, 'public_alert')
      warning({
        title: `Client: ${c.first_name} ${c.last_name}`,
        content: (
          <div>
            { publicAlert.length > 0
              ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
              : null }
            { privateAlert.length > 0
              ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
              : null }
          </div>
        ),
        okText: 'OK',
        onOk () { },
      })
    }

    const funderList = await fvpClientFundingService.listClientFunders(value)

    this.setState({
      funderList: validator.isNotEmptyArray(funderList) ? funderList : [],
      isLoadingFunder: false,
      isFunderDisabled: false
    })
    form.setFieldsValue({ funder_id: null })
  }

  getAlertMsgHtml = (info = {}, key) => {
    if (validator.isNotEmptyObject(info) && info[key] !== undefined) {
      const alert = info[key] !== null && !validator.isEmptyString(info[key]) ? info[key] : ''
      let alertHtml = alert.replace(/(?:\r\n|\r|\n)/g, '<br />')

      if (alertHtml) {
        if (key === 'public_alert') {
          alertHtml = `<strong><ins>${alertHtml}</ins></strong>`
        } else if (key === 'private_alert') {
          alertHtml = `<i>${alertHtml}</i>`
        }
      }

      return alertHtml
    }

    return ''
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history } = this.props
        const clientId = values.client_id
        const funderId = values.funder_id
        const jobType = values.job_type
        this.setState({ loading: true })

        if (jobType === JobListType.JOB_LIST_SINGLE) {
          history.push(`${jobURL}/${JobListType.JOB_LIST_SINGLE}/add?client=${clientId}&funder=${funderId}`)
        } else if (jobType === JobListType.JOB_LIST_RECURRING) {
          history.push(`${jobURL}/${JobListType.JOB_LIST_RECURRING}/add?client=${clientId}&funder=${funderId}`)
        }

        this.setState({ loading: false })
      }
    })
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }


  closeModal = () => {
    const { onClose, form } = this.props
    const { resetFields } = form

    resetFields()
    onClose()
  }

  render () {
    const { form, visible, onClose, clientId = null } = this.props
    const { clientList, currentStep, funderList, isLoadingFunder, isFunderDisabled } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return (
      <Modal visible={visible}
        width={700}
        title='New Job'
        onOk={this.handleSubmit}
        okText='Next'
        onCancel={this.closeModal}
      >
        {/* <Steps size='small' current={currentStep}>
          <Step title='Select Client & Funder' />
          <Step title='Click Next to continue' />
        </Steps> */}
        <div className='add-job-form'>
          <Form>
            <FormItem {...formItemLayout} label='Job Type'>
              {getFieldDecorator('job_type', {
                initialValue: JobListType.JOB_LIST_SINGLE
              })(
                <RadioGroup buttonStyle='solid'>
                  <RadioButton value={JobListType.JOB_LIST_SINGLE}>{`One-off`}</RadioButton>
                  <RadioButton value={JobListType.JOB_LIST_RECURRING}>Recurring</RadioButton>
                </RadioGroup>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label='Client' hasFeedback>
              {getFieldDecorator('client_id', {
                initialValue: clientId,
                rules: [
                  { required: true, message: 'Please select a client' }
                ]
              })(
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder='Client'
                  optionFilterProp='children'
                  notFoundContent='Not found'
                  filterOption={(input, option) => this.findClients(input, option)}
                  onChange={(e, c) => this.onSelectClient(e, c)}>
                  { clientList.map((client, idx) => {
                      const { id, first_name, last_name, acc_ref, leave_id, leave_start_date, leave_end_date, leave_is_ufn } = client
                      const leaveText = !leave_id
                        ? ''
                        : `Leave ${formatter.toShortDate(leave_start_date)} - ${leave_is_ufn ? `UFN` : `${formatter.toShortDate(leave_end_date)}`}`

                      return <Option key={`client-${idx}`} value={`${id}`}>
                        {first_name} {last_name} { leave_id
                          ? <span className='ajm-leave-text'>
                            <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                            &nbsp;{ leaveText }
                          </span>
                          : null }
                        <div className='ajm-appendix'>{acc_ref}</div>
                      </Option>
                    }) }
                </Select>
              )}
            </FormItem>

            <Spin spinning={isLoadingFunder}>
              <FormItem {...formItemLayout} label='Funder' hasFeedback>
                {getFieldDecorator('funder_id', {
                  rules: [
                    { required: true, message: 'Please select a funder' }
                  ]
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Funders'
                    optionFilterProp='children'
                    notFoundContent='No Funders Available'
                    disabled={isFunderDisabled}
                    filterOption={(input, option) => this.findFunders(input, option)}>
                    { funderList.map((funder, idx) => {
                      const { funder_id, funder_fullname } = funder
                      return <Option key={`funder-fs-${funder_id}`} value={funder_id}>
                        {funder_fullname}
                      </Option>
                    })}
                  </Select>
                )}
              </FormItem>
            </Spin>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddJobModal)

export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA'
export const FETCHING_DASHBOARD_DATA = 'FETCHING_DASHBOARD_DATA'
export const DASHBOARD_DATA_FETCHED = 'DASHBOARD_DATA_FETCHED'

export const fetchDashboardData = (params) => {
  return Object.assign({
    type: FETCH_DASHBOARD_DATA
  }, params)
}

export const fetchingDashboardData = (loading) => {
  return {
    type: FETCHING_DASHBOARD_DATA,
    loading
  }
}

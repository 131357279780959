import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { authService } from '../../../services'
import moment from 'moment-timezone'
import { log, formatter, validator } from '../../../util'
import { apiHostname, timezone } from '../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import SingleListPage from './SinglePage'
import RecurringListPage from './RecurringPage'

const pageSize = 20

moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker
const TabPane = Tabs.TabPane

export class FunderJobsJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { data, funderId, form } = this.props

    return (
      <div>
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
          <TabPane tab="Single Jobs" key="1">
            <SingleListPage
              funderId={funderId}
              data={data}
              history={this.props.history}
            />
          </TabPane>
          <TabPane tab="Recurring Base" key="2">
            <RecurringListPage
              funderId={funderId}
              data={data}
              history={this.props.history}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FunderJobsJvp))

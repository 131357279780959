import React, { Component } from 'react'
import moment from 'moment'
import Moment from 'moment-timezone'
import { authService } from '../../../../services'
import { formatter, log, validator } from '../../../../util'
import { cloneDeep } from 'lodash'

import { Button, FileUpload, Loading, SideModal } from '../../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../../config'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'
Moment.tz.setDefault('Australia/Melbourne')

const UploadMsgWrongFormat = 'Wrong format of file selected.'
const UploadMsgNoFile = 'No file selected.'

export class AddFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientId: '',
      currentSubCatList: [],
      currentSubCat: {},
      fileList: [],
      fileUploadedList: {},
      fileType: '',
      item: {},
      itemPrev: {},
      isEdit: false,
      loading: false,
      spinLoading: false,
      visible: false,
      uploadErrorMsg: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientId = '', fileType = '', item = {}, visible } = nextProps
    const isEdit = !!item.id
    const itemPrev = cloneDeep(item)

    if (visible === true && prevState.visible === false) {
      return { ...prevState, clientId, fileType, item, itemPrev, isEdit, visible }
    } else {
      return { ...prevState, clientId, fileType, item, itemPrev, isEdit, visible }
    }
  }

  handleSubmit = () => {
    const { clientId, form, onUpdate, categoriesList = [], subCategoriesList = [], onSetFile = () => {} } = this.props
    const { item, itemPrev, isEdit = false, fileUploadedList } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (!isEdit && !fileUploadedList.uid && !item.file_url) {
          this.setState({ uploadErrorMsg: UploadMsgNoFile })
          return
        } else {
          const adminUser = await authService.getCurrentUser() || {}

          if (fileUploadedList.uid) {
            values.file_name = fileUploadedList.fileName
            // values.file_path = fileUploadedList.filePath
            values.file_url = fileUploadedList.fileUrl
            values.created_by_id = adminUser.id || ''
            values.created_by = adminUser.name || ''
            values.type = item.type
            values.module = 'client'
            values.module_id = clientId
            values.compId = 1

            onSetFile(values)
            this.fileClear()
            resetFields()
          } else if (item.file_url) {
            values.file_name = item.file_name
            // values.file_path = item.file_path
            values.file_url = item.file_url
            values.created_by_id = adminUser.id || ''
            values.created_by = adminUser.name || ''
            values.type = item.type
            values.module = 'client'
            values.module_id = clientId
            values.compId = 1

            onSetFile(values)
            this.fileClear()
            resetFields()
          }
        }
      } else if (!fileUploadedList.uid) {
        this.setState({ uploadErrorMsg: UploadMsgNoFile })
      }
    })
  }

  handleMainCatChange = (id) => {
    const { form, subCategoriesList } = this.props
    const { setFieldsValue } = form
    const list = subCategoriesList.filter(e => e.id === id)
    this.setState({ currentSubCatList: list, currentSubCat: {} })
    setFieldsValue({ sub_cat_id: undefined })
  }

  handleSubCatChange = (id) => {
    const { currentSubCatList } = this.state
    const cat = currentSubCatList.find(e => e.cat_sub_id === id)
    if (cat) {
      this.setState({ currentSubCat: cat })
    }
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ spinLoading: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCat } = this.state

    if (value) {
      if (currentSubCat.is_expiry && currentSubCat.expiry_value) {
        const expiryMonth = parseInt(currentSubCat.expiry_value)
        let expDate = formatter.toMomentClone(value)
        const expiryDate = expDate.add(expiryMonth, currentSubCat.expiry_unit || 'month')
        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ spinLoading: false })
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileClear = () => {
    this.setState({fileList: [], fileUploadedList: {}})
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && (
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      )) {
      this.setState({fileList: [file], uploadErrorMsg: ''})
      return true
    } else {
      this.setState({ uploadErrorMsg: UploadMsgWrongFormat })
      return false
    }

    // return false
  }

  render () {
    const { visible, categoriesList, subCategoriesList, invoiceId, form, onClose } = this.props
    const { clientId, currentSubCatList, fileList, isEdit, item, loading, spinLoading, currentSubCat, uploadErrorMsg } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit File' : 'Add File'

    return (
      <SideModal
        key={`sidebudget_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          this.fileClear()
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Main Category'>
                {getFieldDecorator('main_category_id', {
                  initialValue: item.main_category_id || '',
                  rules: [
                    { required: true, message: 'Please select main category' }
                  ]
                })(
                  <Select placeholder='Please select main category'
                    onChange={(mainId) => this.handleMainCatChange(mainId)}
                    disabled
                  >
                    {
                      categoriesList.map((main) => (
                        <Option key={main.name} value={main.id}>{main.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Sub Category'>
                {getFieldDecorator('sub_category_id', {
                  initialValue: item.sub_category_id || '',
                  rules: [
                    { required: true, message: 'Please select sub category' }
                  ]
                })(
                  <Select placeholder='Please select sub category'
                    onChange={(subId) => this.handleSubCatChange(subId)}
                    disabled
                  >
                    {subCategoriesList.map((sub) => (
                        <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Issuance Date'>
                {getFieldDecorator('issuance_date', {
                  rules: [
                    { required: true, message: 'Please enter issuance date' }
                  ],
                  initialValue: item.issuance_date ? formatter.toMoment(item.issuance_date) : null
                })(
                  <DatePicker onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} defaultPickerValue={Moment(new Date())} />
                )}
              </FormItem>

              {currentSubCat.is_expiry || item.expiry_date
                ? <FormItem label='Expiry Date'>
                  {getFieldDecorator('expiry_date', item.expiry_date ? {
                    rules: [
                      { required: true, message: 'Please enter expiry date' }
                    ],
                    initialValue: item.expiry_date ? formatter.toMoment(item.expiry_date) : null
                  } : {
                    rules: [
                      { required: true, message: 'Please enter expiry_date' }
                    ]
                  })(
                    <DatePicker format={dateFormat} defaultPickerValue={Moment(new Date())} />
                  )}
                </FormItem>
                : null }

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: item.label,
                    rules: [
                      { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof item.active === 'boolean' ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FileUpload
                  file={item.file_url}
                  fileName={item.file_name}
                  loading={spinLoading}
                  readOnly={false}
                  showError={!!uploadErrorMsg}
                  upload={{
                    method: 'POST',
                    action: `${apiHostname}/private/api/careplans-v2/file/${clientId}`,
                    data: {clientId: clientId, type: item.type},
                    name: 'file',
                    onRemove: this.fileRemove,
                    onChange: this.fileChange,
                    beforeUpload: this.fileSet,
                    headers: {Authorization: `Bearer ${authService.getCurrentToken()}` },
                    fileList: fileList,
                    multiple: false
                  }}
                />

                { uploadErrorMsg ? <div style={{color: 'red', fontSize: '13px', marginTop: '5px'}}>{uploadErrorMsg}</div> : null }
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileModal)

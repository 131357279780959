/* global google */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import Communication from './Communication'
import Cost from './Cost'
import File from './File'
import AddFileModal from './AddFileModal'

import {
  authService,
  clientService,
  clientLeaveService,
  communicationService,
  employeeService,
  employeeLeaveService,
  employeeFileService,
  funderService,
  fvpCategoriesService,
  fvpClientFundingService,
  fvpClientFundingPeriodService,
  fvpRatesService,
  jobService,
  jobActionLogService,
  jobTimesheetService,
  logService,
  settingCancellationService,
  settingFileCategoryService,
  settingGeneralService,
  settingBillingRateService,
  settingHolidayService,
  settingOtherService,
  settingFileTypeService
} from '../../../services'

import { fetchingJobs, fetchTotalPending } from '../../../states/actions/job'
import { setRefreshCommLog, setRefreshActivityLog } from '../../../states/actions'
import { JobFvpMenu } from '../../../constants'
import { common, formatter, log, trigger, validator } from '../../../util'
import DurationExtendPeriod from '../../../util/extendPeriod'
// import DurationBreakdown from '../../../util/duration'
import DurationBreakdown from '../../../util/durationFvp'
import { jobLegacyURL, googleMapApi } from '../../../config'

// S1.5
// import GetUpTask from './getup'

// UI
import { DateTimePicker, Loading, List, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'
import TimePicker from 'antd/lib/time-picker'

import './styles.css'
import ActivityLog from './ActivityLog'
import SignoffMap from './map'
import TimesheetTemplate from '../Timesheet'

const { Item: FormItem } = Form
const { confirm, error, info, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const { Option, OptGroup } = Select

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const sqlFormat = 'YYYY-MM-DDTHH:mm:ss+00'

const SleepOver = 'Sleepover'

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const threeFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 14 },
  wrapperCol: { sm: 14, md: 14, lg: 8 }
}

export class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // ids
      clientId: null,
      employeeId: null,
      funderId: null,
      clientFunderLegacyId: null,
      newEmployeeId: null,
      // info
      cachedBillingCategoryId: null,
      clientInfo: {},
      clientLanguages: [],
      clientSkills: [],
      clientLanguagesName: [],
      clientSkillsName: [],
      clientFunderSettingLegacyInfo: {},
      currentEmpInfo: {},
      currentFunderInfo: {},
      currentPeriodInfo: {},
      empLanguages: [],
      empSkills: [],
      empLanguagesName: [],
      empSkillsName: [],
      employeeJobHoursInfo: {},
      extBreakdownInfo: new DurationExtendPeriod({}),
      extHoursList: [],
      extHoursOriList: [],
      extSleepoverList: [],
      extSleepoverOriList: [],
      funderInfo: {},
      fileInfo: {},
      fileList: [],
      prevEmpInfo: {},
      prevFunderInfo: {},
      rateSetInfo: {},
      clashClient: '',
      clashEmp: '',
      // job item
      item: {},
      itemOri: {},
      // job related fields
      itemLegacyBillingRateId: null,
      itemCurrentTotalHours: null,
      // job related flags
      isCustomSO: false,
      isDisableCategoriesSelect: false,
      isEmergency: false,
      isEmergencyTime: false, // required?
      isEmployeePending: false,
      isExtExtended: false,
      isExtSleepover: false,
      isLastJobSeries: false,
      isOvernightSleepover: false,
      isSignoff: false,
      isShowClientPublicAlert: false,
      isShowClientPrivateAlert: false,
      isShowEmployeePublicAlert: false,
      isShowEmployeePrivateAlert: false,
      isShowSleepoverAlert: false,
      // loading flags
      loading: false,
      loadingCancel: false,
      loadingLateCancel: false,
      // modal flags
      showAddFileModal: false,
      showCancelModal: false,
      showCancelConfirmModal: false,
      showEmergencyModal: false,
      showEmployeeReasonModal: false,
      showFunderModal: false,
      // listings
      billingCategoryList: [],
      cancelReasonList: [],
      cancelTypeList: [],
      employeeAllList: [],
      funderLegacyList: [],
      funderList: [],
      funderPeriodList: [],
      previousEmployeeList: [],
      // client leave info
      isClientOnLeave: false,
      clientLeaveInfo: null,
      // client conflict info
      isShowClientConflictAlert: false,
      clientConflictInfo: null,
      // employee leave info
      isEmployeeOnLeave: false,
      employeeLeaveInfo: null,
      // employee job hours info
      employeeJobHoursInfo: {},
      // employee conflict info,
      isEmployeeJobConflict: false,
      employeeJobConflictInfo: {},
      // employee change reason
      changeEmployeeReason: null,
      changeEmployeeReasonType: null,
      changeEmployeeOtherReason: null,
      changeEmployeeNote: null,
      // holiday info
      isHoliday: false,
      holidayInfo: [],
      // preferences mismatch lists
      isMatchLanguage: true,
      isMatchSkill: true,
      mismatchedLanguageList: [],
      mismatchedSkillList: [],
      // files
      employeeExpiredFiles: [],
      mainFileCategoryList: [],
      subFileCategoryList: [],
      // setting lists
      settingsAll: [],
      settingsPayroll: [],
      settingsLanguage: [],
      settingsSkill: [],
      settingsGender: [],
      settingsFunding: [],
      settingsOthers: {},
      // UI
      isShowSave: false,
      isShowCancelOtherField: false,
      tabActiveKey: '1',
      textDurationBreakdown: '',
      textDurationHours: '',
      textSleepoverMismatch: '',
      prevUnsentCommTotal: null,
      unsentCommTotal: 0
    }
  }

  async componentDidMount () {
    this.initialize()
  }

  render () {
    const { history, match, form, totalPending } = this.props
    const { getFieldDecorator } = form
    const {
      cancelReasonList,
      cancelTypeList,
      clientInfo,
      funderInfo,
      funderList,
      isShowSave,
      isShowCancelOtherField,
      isShowClientPrivateAlert,
      isShowClientPublicAlert,
      isSignoff,
      loadingCancel,
      loadingLateCancel,
      showCancelModal,
      showCancelConfirmModal,
      showEmergencyModal,
      showEmployeeReasonModal,
      showFunderModal,
      item,
      settingsOthers,
      tabActiveKey,
      textDurationHours,
      unsentCommTotal,
      clashClient,
      clashEmp
    } = this.state
    const jobId = this.getJobId()
    const isInfoTab = tabActiveKey === '1'

    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Jobs' menu={JobFvpMenu} countData={totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={
            !this.isEdit()
            ? `New Job ${clientInfo && clientInfo.id ? `For ${clientInfo.first_name} ${clientInfo.last_name}` : ''}`
            : item.is_cancel && item.base_job_id && !item.is_delete
              ? 'Single Job (Recurring Job) (Cancelled)'
              : !item.is_cancel && item.base_job_id && !item.is_delete
                ? 'Single Job (Recurring Job)'
                : item.is_cancel && !item.base_job_id && !item.is_delete
                  ? 'Single Job (Cancelled)'
                  : !item.is_cancel && !item.base_job_id && !item.is_delete
                    ? 'Single Job'
                    : ''
          }>
            { this.isEdit() && item.base_job_id && isInfoTab
              ? (
                <Link to={`${jobLegacyURL}/recurring/${item.base_job_id}`}>
                  <div className='btn btn-ghost'>
                    { !item.is_cancel ? 'Edit Base Job' : 'Show Base Job' }
                  </div>
                </Link>
              ) : null }

            { isShowSave && this.isEdit() && !item.is_cancel && this.hasAccess('updateJob') && isInfoTab
              ? (
                <div className='btn' onClick={() => this.triggerCancelModal(true)} style={{ marginRight: 15 }}>Cancel Job</div>
              ) : null }

            { isShowSave && this.isEdit() && item.is_cancel && this.hasAccess('updateJob') && isInfoTab
              ? (
                <div className='btn' onClick={this.onSaveUncancelJob} style={{ marginRight: 15 }}>Uncancel Job</div>
              ) : null }

            { !isShowSave && this.isEdit() && this.hasAccess('updateJob') && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>Edit</div>
              ) : null }

            { (isShowSave && (this.hasAccess('updateJob')) && !item.is_cancel) || (!this.isEdit() && this.hasAccess('createJob') && isInfoTab)
              ? (
                <div className='btn' onClick={this.checkBeforeSave}>Save</div>
              ) : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          { isShowClientPrivateAlert || isShowClientPublicAlert
              ? <div>
                <Alert message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                      `${clientPublicAlertMsg ? `${clientPublicAlertMsg}<br />` : ''}` +
                      `${clientPrivateAlertMsg ? `${clientPrivateAlertMsg}<br />` : ''}`
                  }} />
                } type='warning' showIcon /><br />
              </div>
              : null }

          { clashClient || clashEmp
                ? <div>
                  <Alert
                    message={
                      <div dangerouslySetInnerHTML={{
                        __html: `<span style="font-weight: bold;">Address</span><br />` +
                          `${clashClient ? `Same address with client: ${clashClient}<br />` : ''}` +
                          `${clashEmp ? `Same address with employee: ${clashEmp}<br />` : ''}`
                      }} />
                    }
                    type='warning'
                    showIcon
                  /><br />
                </div>
                : null }

          { item.emergency_pay && item.emergency_invoice
            ? <div className='job-remark' style={{ backgroundColor: '#ff526ebb' }}><Icon type='exclamation-circle' /> This is Emergency job</div>
            : item.emergency_pay
              ? <div className='job-remark' style={{ backgroundColor: '#ea3471bb' }}><Icon type='exclamation-circle' /> This is Emergency job with emergency pay</div>
              : item.emergency_invoice
                ? <div className='job-remark' style={{ backgroundColor: '#ff5b5bbb' }}><Icon type='exclamation-circle' /> This is Emergency job with emergency invoice</div>
                : null }

          { item.cancellation_penalty > 0
            ? <div className='job-remark' style={{ backgroundColor: '#b17bcd' }}><Icon type='info-circle' /> This job has Late Cancellation {this.getChargeHours()} hours charge (original {this.getOriginalHours()} hours).</div>
            : null }

          { item.base_job_id > 0
            ? <div className='job-remark' style={{ backgroundColor: '#1890ffbb' }}><Icon type='info-circle' /> This is Recurring job</div>
            : null }

          {/* { item.billing_rate_id !== null && this.isEdit()
            ? <div className='job-remark' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' />
              { item.base_job_id > 0
                ? (item.job_start_date && Moment(item.job_start_date).isBefore(Moment(new Date())) || item.is_updated
                  ? ' This job is still having previous funder rate configuration. Please save the job to apply the patch.'
                  : ' This job is still having previous funder rate configuration. Please update the base job to apply the patch.'
                )
                : ' This job is still having previous funder rate configuration. Please update the base job to apply the patch.' }
              </div>
            : null } */}

          { funderInfo && funderInfo.id === null
            ? <div className='job-remark' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' /> The client does not have active funding period so unable to show the funder properly.</div>
            : null }

          <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)} >
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.infoTab() }
            </TabPane>

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2' forceRender>
                <Communication jobId={jobId} onUpdate={(value) => this.handleBadgeCount(value)} />
              </TabPane>
              : null }

            {/** ** CR0011 - disable certain tab for legacy job **/}
            {/* { this.isEdit()
              ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
                <File jobId={jobId} history={history} onRefreshPage={() => this.refreshPage()} />
              </TabPane>
              : null }

            { this.isEdit() && isSignoff
              ? <TabPane tab={<div><Icon type='edit' /> Timesheet </div>} key='4'>
                { this.signOffTab() }
              </TabPane>
              : null }

            { this.isEdit() && !!item.id && this.hasAccess('readJobCosting')
              ? <TabPane tab={<div><Icon type='dollar' /> Costing </div>} key='5'>
                <Cost jobId={jobId} job={item} breakdown={textDurationHours}/>
              </TabPane>
              : null } */}

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='6'>
                <ActivityLog jobId={jobId} />
              </TabPane>
              : null }
          </Tabs>

          {/* --------------------------------------CANCEL CONFIRM MODAL START---------------------------------------- */}
          <Modal
            width={550}
            title='Are you sure you want to cancel this job?'
            visible={showCancelConfirmModal}
            onOk={this.onSaveLateCancel}
            onCancel={() => this.setState({ showCancelConfirmModal: false })}
            footer={[
              <Button key='1' type='primary' loading={loadingLateCancel} onClick={() => this.onSaveLateCancel(true)}>Late Cancel</Button>,
              <Button key='2' loading={loadingCancel} onClick={() => this.onSaveLateCancel()}>Normal Cancel</Button>
            ]}
          >
            <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ fontSize: 50, color: 'orange', marginRight: '20px' }}>
                <Icon type='question-circle' />
              </div>

              <div>You are cancelling this job within {settingsOthers.cancellation_notice} hours of start time making it a Late Cancellation.<br /><br />
            Please confirm if Late Cancellation charges should be applied.<br /><br />
                <div>Late Charge: <b>{this.getChargeHours()} hours</b></div>
              </div>

            </div>
          </Modal>
          {/* --------------------------------------CANCEL CONFIRM MODAL END---------------------------------------- */}

          {/* --------------------------------------EMG CONFIRM MODAL START---------------------------------------- */}
          <Modal
            width={550}
            title='Do you want to change Employee?'
            visible={showEmergencyModal}
            onOk={() => this.handleEmployeeEmergency()}
            onCancel={() => this.handleEmployeeCancel()}
            footer={[
              <Button key='1' type='primary' onClick={() => this.handleEmployeeEmergency(true)}>Emergency</Button>,
              <Button key='2' onClick={() => this.handleEmployeeEmergency()}>Not Emergency</Button>
            ]}
          >
            <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 50, color: 'orange', marginRight: '20px' }}>
                <Icon type='question-circle' />
              </div>
              <div>Changing the employee now will incur emergency charge. Are you sure to proceed?</div>
            </div>
          </Modal>
          {/* --------------------------------------EMG CONFIRM MODAL END---------------------------------------- */}

          {/* --------------------------------------CHANGE FUNDER MODAL START---------------------------------------- */}
          <Modal
            width={600}
            title='Change Funder'
            visible={showFunderModal}
            onOk={this.handleFunderSubmit}
            onCancel={() => this.triggerFunderModal(false)}
            footer={[
              <Button key='ok' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>,
              <Button key='cancel' onClick={() => this.triggerFunderModal(false)}>Cancel</Button>
            ]}
          >
            { showFunderModal
              ? <FormItem {...formItemLayout} label='Funder' hasFeedback>
                {getFieldDecorator('funder_id', {
                  initialValue: funderInfo.id
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Funders'>
                    { funderList.map((funder, idx) => (<Option key={idx} value={funder.funder_id}>
                        {funder.funder_fullname}
                        {/* <div className='clientId'>{funder.manager} ({formatter.toShortDate(funder.start_date)} - {formatter.toShortDate(funder.end_date)})</div> */}
                      </Option>
                    )) }
                  </Select>
                )}
              </FormItem>
              : null }
          </Modal>
          {/* --------------------------------------CHANGE FUNDER MODAL END---------------------------------------- */}

          {/* --------------------------------------CANCEL JOB SIDE MODAL START---------------------------------------- */}
          <SideModal
            title='Cancel Job'
            showModal={showCancelModal}
            onClose={() => this.triggerCancelModal(false)}
            buttons={[
              <Button key='0' type='primary' onClick={this.onSaveCancelJob}>Cancel Job</Button>
            ]}
          >
            { showCancelModal
              ? <div>
                <FormItem label='Cancellation Type'>
                {getFieldDecorator('cancellation_type', {
                  rules: [
                    { required: true, message: 'Please select cancellation type' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleCancelTypeChange}>
                    {
                      cancelTypeList.map((items, idx) => {
                        return <Option key={idx} value={items.id}>{items.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
              <FormItem label='Cancellation Reason'>
                {getFieldDecorator('cancellation_reason', {
                  rules: [
                    { required: true, message: 'Please select reason' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                    { cancelReasonList.map((items, idx) => {
                        return <Option key={idx} value={items.name}>{items.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>
              { isShowCancelOtherField
                ? (
                  <FormItem label='Other Reason For Cancellation'>
                    {getFieldDecorator('cancellation_other_reason', {
                    })(
                      <TextArea row={2} />
                    )}
                  </FormItem>
                )
                : null }
              <FormItem label='Notes (Optional)'>
                {getFieldDecorator('cancellation_note', {
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>
            </div>
            : null }
          </SideModal>
          {/* --------------------------------------CANCEL JOB SIDE MODAL END---------------------------------------- */}

          {/* --------------------------------------CHANGE EMPLOYEE MODAL START---------------------------------------- */}
          <SideModal
            title='You Have Changed Employee'
            showModal={showEmployeeReasonModal}
            onClose={() => this.setState({showEmployeeReasonModal: false})}
            buttons={[
              <Button key='0' type='primary' onClick={() => this.handleEmployeeChangeReason()}>Submit</Button>
            ]}
          >
            { showEmployeeReasonModal
              ? <div>
                <FormItem label='Reason Type'>
                  {getFieldDecorator('change_employee_reason_type', {
                    rules: [
                      { required: true, message: 'Please select reason type' }
                    ]
                  })(
                    <Select style={{ width: '100%' }} onChange={this.handleEmployeeCancelTypeChange}>
                      {
                        cancelTypeList.map((items, idx) => {
                          return <Option key={idx} value={items.id}>{items.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
                <FormItem label='Reason To Change'>
                  {getFieldDecorator('change_employee_reason', {
                    rules: [
                      { required: true, message: 'Please select reason' }
                    ]
                  })(
                    <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                      {
                        cancelReasonList.map((items, idx) => {
                          return <Option key={idx} value={items.name}>{items.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
                { isShowCancelOtherField
                  ? (
                    <FormItem label='Other Reason To Change'>
                      {getFieldDecorator('change_employee_other_reason', {
                      })(
                        <TextArea row={2} />
                      )}
                    </FormItem>
                  )
                  : null }
                <FormItem label='Notes (Optional)'>
                  {getFieldDecorator('change_employee_note', {
                  })(
                    <TextArea rows={2} />
                  )}
                </FormItem>
              </div>
              : null }
          </SideModal>
          {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      billingCategoryList,
      clientConflictInfo,
      clientInfo,
      clientLeaveInfo,
      clientLanguages,
      clientSkills,
      clientLanguagesName,
      clientSkillsName,
      currentEmpInfo,
      currentPeriodInfo,
      employeeAllList,
      employeeExpiredFiles,
      employeeJobConflictInfo,
      employeeJobHoursInfo,
      extHoursList,
      extSleepoverList,
      fileInfo,
      fileList,
      funderInfo,
      holidayInfo,
      isClientOnLeave,
      isCustomSO,
      isDisableCategoriesSelect,
      isEmployeeJobConflict,
      isEmployeeOnLeave,
      isEmployeePending,
      isEmergency,
      isHoliday,
      isLastJobSeries,
      isMatchLanguage,
      isMatchSkill,
      isExtSleepover,
      isShowClientConflictAlert,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert,
      isShowSleepoverAlert,
      item,
      loading,
      mainFileCategoryList,
      mismatchedLanguageList,
      mismatchedSkillList,
      previousEmployeeList,
      rateSetInfo,
      settingsPayroll,
      showAddFileModal,
      subFileCategoryList,
      textDurationBreakdown,
      textDurationHours,
      textSleepoverMismatch
    } = this.state

    const billingCategoryListPreferred = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === true) : []
    const billingCategoryListOther = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === false) : []

    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isSleepoverToggleEnabled = !!form.getFieldValue('job_start_date') && funderInfo && funderInfo.id && validator.isNotEmptyArray(billingCategoryList) && billingCategoryList.findIndex(e => e.is_sleepover === true) > -1

    const isSleepoverListEnabled = !form.getFieldValue('job_start_date')
      ? -1 // no job start date, the list not showing up
      : form.getFieldValue('job_start_date') > Moment(new Date())
      ? 0 // job start date before current date, the list not showing up
      : !!form.getFieldValue('job_start_date') && funderInfo && funderInfo.id && form.getFieldValue('job_start_date') && isSleepoverToggleEnabled
        ? 3 // allow to edit get ups
        : funderInfo && funderInfo.id === null
          ? 2 // unable to edit get ups, mostly due to no funder info
          : 1 // waiting funder info to get loaded

    if (currentEmpInfo && currentEmpInfo.private_alert) {
      const pAlert = currentEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.public_alert) {
      const uAlert = currentEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const fileColumns = [
      { title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = mainFileCategoryList.find(e => e.id === main_category_id)
          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      { title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subFileCategoryList.find(e => e.id === sub_category_id)
          return <div>{subCat ? subCat.name : ''}</div>
        }
      },
      { title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      { title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      { title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      { title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleFileDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    const isJobCostingUpdated = this.isEdit() && !!item.is_costing_updated

    return (
      <Loading loading={loading} blur>
        <Row>
          <Col lg={24}>
            { isJobCostingUpdated
              ? <div className='alert-info' style={{marginBottom: '10px'}}>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Job Costing is Updated</span> This Job cannot be updated because its costing has been manually overridden. Please revert to the original costing in order to update any changes.</div>}
                  type='error'
                  banner
                  showIcon
                />
              </div>
              : null }
          </Col>
        </Row>

        <Row gutter={16}>
          <Col lg={12}>
            <Panel>
              { !(clientInfo && clientInfo.id)
                ? <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </Col>
                </Row>
                : <Row>
                  <Col style={{ minHeight: 105 }}>
                    <span className='personnel-name'><a href={`/clients/${clientInfo.id}`} rel='noopener noreferrer' target='_blank'>{clientInfo.first_name } { clientInfo.last_name }</a></span>
                    <span className='personnel-accref'>{ clientInfo.acc_ref }</span>
                    { clientInfo.leave_id ? <span style={{ color: '#ff0000', fontSize: '9.5pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${clientInfo.until_further_notice ? 'UFN' : formatter.toShortDate(clientInfo.leave_end)}`}</span> : null }
                    <div className='personnel-details'>
                      { clientInfo.phone ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { clientInfo.phone }</div> : null }
                      <div style={{ fontSize: 12, marginRight: 10 }}>{ clientInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' } {clientInfo.address }</div>
                    </div>
                    <div className='personnel-details' style={{ marginTop: 15 }}>
                      <b>Preference</b>
                      <div style={{ fontSize: 12, marginRight: 10, marginLeft: 10, textTransform: 'capitalize' }}>{ clientInfo.preferred_gender ? <span style={{ color: '#ed6d1e' }}><Icon type='user-add' /></span> : null } { clientInfo.preferred_gender }</div>

                      <div style={{ fontSize: 12, marginRight: 10 }}>{ validator.isNotEmptyArray(clientLanguagesName) ? <span style={{ color: '#ed6d1e' }}><Icon type='font-size' /></span> : null } {
                        clientLanguagesName.map((language, idx) => (
                          <span key={idx} className='preferrence-item'>{language}{idx === clientLanguagesName.length - 1 ? '' : ','}</span>
                        ))
                      }</div>
                    </div>
                    <div className='personnel-sub-details'>
                      <b>Skills</b>
                      <div style={{ fontSize: 12, marginLeft: 10 }}>{ validator.isNotEmptyArray(clientSkillsName) ? <Icon type='check-circle' theme='twoTone' twoToneColor='#ed6d1e' /> : null } {
                        clientSkillsName.map((skill, idx) => (
                          <span key={idx} className='preferrence-item'>{skill}{idx === clientSkillsName.length - 1 ? '' : ','}</span>
                        ))
                      }</div>
                    </div>
                  </Col>
                </Row> }
            </Panel>
          </Col>
          <Col lg={12}>
            <Panel panelStyle={{ backgroundColor: funderInfo && funderInfo.isInvalidFunder ? 'red' : undefined }}>
              { !(funderInfo && funderInfo.id)
                ? <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </Col>
                </Row>
                : <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          { funderInfo.fullname
                            ? <span className='personnel-name'><a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>{ funderInfo.fullname }</a></span>
                            : <span className='personnel-name' style={{ color: '#ff0000' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>}
                          { funderInfo.acc_ref ? <span className='personnel-accref'>{ funderInfo.acc_ref }</span> : null }
                        </div>
                        { isJobCostingUpdated
                          ? null
                          : <span className='changeFunder'
                          style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => this.triggerFunderModal(true)}><Icon type='form' style={{fontSize: '12pt'}} /></span> }
                      </div>

                      <div className='personnel-details'>
                        { funderInfo.phone_number ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { funderInfo.phone_number }</div> : null }
                        <div style={{ fontSize: 12 }}>{ funderInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { funderInfo.address }</div>
                      </div>

                      { funderInfo.isInvalidFunder
                        ? <div>
                          <span className='personnel-sub-details' style={{ marginTop: 15 }}>
                          <span className='personnel-name' style={{ color: '#FFF' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>
                          </span>
                        </div>
                        : null }

                      { currentPeriodInfo && currentPeriodInfo.id
                        ? <div>
                          <div className='personnel-sub-details' style={{ marginTop: 15 }}>
                            <b>Fund Period</b>
                            <div style={{ fontSize: 12, marginLeft: 10 }}>
                              <span style={{ color: '#ed6d1e' }}><Icon type='calendar' /></span> { Moment(currentPeriodInfo.start_date).format('DD/MM/YYYY') + ' - ' + Moment(currentPeriodInfo.end_date).format('DD/MM/YYYY') }
                            </div>
                          </div>
                          <div className='personnel-sub-details' style={{ marginTop: 5 }}>
                            <b>Invoice Notes</b>
                            <div style={{ fontSize: 12, marginLeft: 10 }}>
                              <span style={{ color: '#ed6d1e' }}><Icon type='highlight' /></span> { currentPeriodInfo.invoice_note ? currentPeriodInfo.invoice_note : '-' }
                            </div>
                          </div>
                        </div>
                        : currentPeriodInfo && currentPeriodInfo.id === null
                          ? <span className='personnel-sub-details' style={{ marginTop: 15 }}>
                            <span className='personnel-name'><Icon type='exclamation-circle' style={{ color: '#ff0000' }} /> Client has no funding period<span style={{fontWeight: 'normal', fontSize: '11px'}}>&nbsp;&nbsp;The job date does not fall on any available funding period.</span></span>
                          </span>
                          : null
                       }
                    </div>
                  </Col>
                </Row>
              }
            </Panel>
          </Col>
        </Row>

        <Panel
          disabled={isJobCostingUpdated}
          title={`Job Schedule ${this.getScheduleDay()}`}
          subtitle={(
          <Row>
            <Col lg={24}>
              <div style={{ fontSize: 14 }}>
                <span>{ item.frequency ? <Icon type='clock-circle' /> : null } { textDurationBreakdown }</span>
                { item.week_month === 'months'
                  ? <span>, { item.frequency_number ? <Icon type='sync' /> : null } For { item.frequency_number } months ( {item.frequency} weeks)</span>
                  // : isRecurring
                  //   ? <span>, { item.frequency ? <Icon type='sync' /> : null } For { item.frequency } weeks</span>
                    : '' }
              </div>
            </Col>
          </Row>)}
        >
          <Row>
          {/* Start Alert */}
            <Col lg={24}>
              { isClientOnLeave && clientLeaveInfo
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { isShowClientConflictAlert && clientConflictInfo
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}<a href={`${jobLegacyURL}/single/${clientConflictInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date')
                ? <div className='alert-container'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Job Time Error</span> End Time should not be earlier than Start Time </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { isHoliday && validator.isNotEmptyArray(holidayInfo)
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {holidayInfo.map(e => `${e.name} (${Moment(e.date).format('DD/MM/YYYY')})`).join(', ')}</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }

              { isShowSleepoverAlert
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }

              { textSleepoverMismatch
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Sleepover Duration Mismatch</span> {textSleepoverMismatch}</div>}
                    type='warning'
                    showIcon
                  />
                </div>
                : null }

              { isDisableCategoriesSelect
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client: No Available Funding Period</span> {`${clientInfo.first_name} ${clientInfo.last_name} does not have available funding period for selected job date time.`} </div>}
                    type='warning'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { isLastJobSeries
                ? <div className='alert-info'>
                  <Alert
                    message={<div>This is the last single job from the recurring job.</div>}
                    type='warning'
                    banner
                    showIcon
                  />
                </div>
                : null }
            </Col>
          {/* End Alert */}
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Start Time'>
                {getFieldDecorator('job_start_date', {
                  initialValue: this.isEdit() && item.job_start_date ? Moment(item.job_start_date) : null,
                  rules: [
                    { required: true, message: 'Please enter job start time' }
                  ]
                })(
                  <DateTimePicker onChange={this.handleDateStartChange} disabled={isLastJobSeries} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='End Time'>
                {getFieldDecorator('job_end_date', {
                  initialValue: this.isEdit() && item.job_end_date ? Moment(item.job_end_date) : null,
                  rules: [
                    { required: true, message: 'Please enter job end time' }
                  ]
                })(
                  <DateTimePicker onChange={this.handleDateEndChange} disabled={isLastJobSeries} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={7}>
              <FormItem {...threeFormItemLayout} label='Emergency Job'>
                {getFieldDecorator('emergency', {
                  initialValue: item.emergency,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleEmergencyClick}
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={16}>
              { isEmergency
                ? <div className='additionalEmergency'>
                  <Row>
                    <Col lg={12}>
                      <FormItem {...threeFormItemLayout} label='Emergency Pay'>
                        {getFieldDecorator('emergency_pay', {
                          initialValue: item.emergency_pay,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            onChange={this.handleEmergencyPayClick}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem {...threeFormItemLayout} label='Emergency Invoice'>
                        {getFieldDecorator('emergency_invoice', {
                          initialValue: item.emergency_invoice,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            onChange={this.handleEmergencyInvoiceClick}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
                : null }
            </Col>
          </Row>
          <Row style={{ display: item.is_cancel ? '' : 'none' }}>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Late Cancellation?'>
                {getFieldDecorator('cancellation_penalty', {
                  initialValue: item.cancellation_penalty || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.onSaveLateCancellation}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel
          disabled={isJobCostingUpdated}
          title='Shift Work'
          type={item.employee_id === null ? 'warn' : ''}
        >
          <Row gutter={16}>
            {/* Start Alert */}

          <Col lg={24}>
            <div style={{ marginBottom: 10 }}>
              { isShowEmployeePrivateAlert || isShowEmployeePublicAlert
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div>
                          <span style={{ fontWeight: 'bold' }}>{`Employee: ${currentEmpInfo.first_name} ${currentEmpInfo.last_name}`}</span>
                          { employeePublicAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                            : null }
                          { employeePrivateAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                            : null }
                        </div>
                      }
                      type='warning'
                      showIcon
                    />
                  </div>
                  : null }

              { isEmployeeOnLeave
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              {/* Employee max hour warning */}
              { !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>}
                      type='error'
                      showIcon
                    />
                  </div>
                  : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                    ? <div className='alert-info'>
                      <Alert
                        banner
                        message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>}
                        type='info'
                        showIcon
                      />
                    </div>
                    : null }

              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />}
                      type='error'
                      showIcon
                    />
                  </div>
                  : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                    ? <div className='alert-info'>
                      <Alert
                        banner
                        message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                        }} />}
                        type='info'
                        showIcon
                      />
                    </div>
                    : null }

              { isEmployeeJobConflict
                ? <div className='alert-info'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Schedule Conflict</span> {`${employeeJobConflictInfo.emp_firstname} ${employeeJobConflictInfo.emp_lastname}  has conflict shift with ${employeeJobConflictInfo.first_name} ${employeeJobConflictInfo.last_name}
                        at ${formatter.toShortDate(employeeJobConflictInfo.job_start_date)}, ${formatter.toShortTime(employeeJobConflictInfo.job_start_date)} - ${formatter.toShortTime(employeeJobConflictInfo.job_end_date)}`} <a href={`${jobLegacyURL}/single/${employeeJobConflictInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                : null }
              { !isMatchLanguage
                ? <div className='alert-info'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${mismatchedLanguageList.map(e => e).join(', ')},
                        but ${currentEmpInfo.first_name} ${currentEmpInfo.last_name} does not. `} </div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                : null }
              { !isMatchSkill
                ? <div className='alert-info'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}  does not possess following skill(s): ${mismatchedSkillList.map(e => e).join(', ')}`} </div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                : null }
              { validator.isNotEmptyArray(employeeExpiredFiles)
                ? (employeeExpiredFiles.map((file, idx) => {
                  return (
                    <div key={`expired file ${idx}`} className='alert-info'>
                      <Alert
                        message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                        type='error'
                        banner
                        showIcon
                      />
                    </div>
                  )
                }))
                : null }
            </div>
            </Col>
          </Row>
          <Form layout='vertical'>
            <Row gutter={16}>
              <Col lg={8} style={{ marginTop: '0px' }}>
                <FormItem label={<span>Shift Type
                  <Switch
                    disabled={!isSleepoverToggleEnabled}
                    checkedChildren='Sleepover'
                    unCheckedChildren='Sleepover?'
                    style={{ marginLeft: 10 }}
                    checked={isExtSleepover}
                    onChange={(value) => this.handleSleepover(value, false, true)}
                  />
                </span>}>
                  {getFieldDecorator('billing_category_id', {
                    initialValue: item.billing_category_id,
                    rules: [
                      { required: true, message: 'Please Select Billing Rate' }
                    ]
                  })(
                    <Select
                      onSelect={(v, o) => this.handleBillingSelectCategory(v, o)}
                      disabled={isExtSleepover || isDisableCategoriesSelect}
                      showSearch
                      filterOption={(input, option) =>
                        this.filterBillingCategory(input, option)
                      }
                    >
                      { validator.isNotEmptyArray(billingCategoryListPreferred)
                        ? <OptGroup label={<div className='label-title'>----- PREFERRED -----</div>}>
                          { billingCategoryListPreferred.map((bill, idx) => {
                            return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcfa-${idx}`} value={bill.id}>{bill.name}</Option> : null
                          }) }
                        </OptGroup>
                        : null }
                      { validator.isNotEmptyArray(billingCategoryListOther)
                        ? <OptGroup label={<div className='label-title'>{validator.isNotEmptyArray(billingCategoryListPreferred) ? <p></p> : null}<p>----- OTHER -----</p></div>}>
                          { billingCategoryListOther.map((bill, idx) => {
                            return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcfb-${idx}`} value={bill.id}>{bill.name}</Option> : null
                          }) }
                        </OptGroup>
                        : null }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={8} style={{ marginTop: '8.5px' }}>
                <FormItem label='Carer Skill Level Required'>
                  {getFieldDecorator('payroll', this.isEdit()
                    ? { initialValue: item.payroll,
                        rules: [
                          { required: true, message: 'Please Select Payroll Category' }
                        ]}
                    : { initialValue: clientInfo.payroll_category,
                        rules: [
                          { required: true, message: 'Please Select Payroll Category' }
                        ]})(
                      <Select onChange={this.handleChangePayroll}>
                        { settingsPayroll.map((pay) => {
                            return <Option key={pay.value}>{pay.name}</Option>
                          }) }
                      </Select>
                    )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem label={
                  <span>Employee <Switch
                    checkedChildren='Pending'
                    unCheckedChildren='Pending'
                    style={{ marginLeft: 10 }}
                    checked={isEmployeePending}
                    onChange={this.handlePendingEmployee}
                  /></span>
                } hasFeedback>
                  {getFieldDecorator('employee_id', {
                    initialValue: item.employee_id,
                    rules: [
                      !isEmployeePending ? { required: true, message: 'Please select an employee OR turn on Pending' } : {}
                    ]
                  })(
                    <Select showSearch
                      style={{ width: '100%' }}
                      placeholder='Employee'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      filterOption={(input, option) => this.filterEmployees(input, option)}
                      onChange={this.handleEmployeeChange} disabled={isEmployeePending}>
                      {
                        employeeAllList.map((items, idx) => {
                          return <Option key={idx} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <span style={{ color: '#ff0000', fontSize: '9pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start)} - ${formatter.toShortDate(items.leave_end)}`}</span> : null }</Option>
                        })
                      }

                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={8} style={{ marginTop: '8.5px' }}>
                <FormItem label='Tasks' hasFeedback>
                  {getFieldDecorator('tasks', {
                    initialValue: this.replacer(item.tasks),
                    rules: [
                      { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter tasks' }
                    ]
                  })(
                    <TextArea row={4} />
                  )}
                </FormItem>
              </Col>
              <Col lg={8} style={{ marginTop: '8.5px' }}>
                <FormItem label='Notes'>
                  {getFieldDecorator('notes', {
                    initialValue: this.replacer(item.notes),
                  })(
                    <TextArea row={4} />
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                { this.isEdit()
                  ? <FormItem label={<span>Feedback
                      {getFieldDecorator('job_survey_feedback', {
                        initialValue: item.job_survey_feedback ? item.job_survey_feedback : false,
                        valuePropName: 'checked'
                      })(
                      <Switch
                        onChange={this.handleFeedbackChange}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={!this.hasAccess('updateJobFeedback')}
                        style={{ marginLeft: 25 }}
                      />
                      )}
                  </span>}>
                    {getFieldDecorator(`job_survey_feedback_text`, {
                        initialValue: this.replacer(item.job_survey_feedback_text)
                      })(
                        <TextArea rows={2} disabled={!this.hasAccess('updateJobFeedback') || !getFieldValue(`job_survey_feedback`)} /*readOnly={mode !== 'view'}*/ />
                      )}
                  </FormItem>
                  : null }
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={8}>
                <FormItem label='Max KM'>
                  {getFieldDecorator('job_kms', {
                    initialValue: this.isEdit() ? item.job_kms : 0,
                    rules: [
                      { validator: this.checkKMSInput }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem label='Recorded KM'>
                  {getFieldDecorator('kms', {
                    initialValue: item.kms,
                    rules: [
                      { validator: this.checkKMSInput }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Panel>

        { isExtSleepover && this.isEdit() && isSleepoverListEnabled > 0
          ? <Panel title='Sleepover Getups'>
            <Form className='getup-panel'>
              <Row>
                <Col lg={24}>
                  {/* <Row className='getup'>
                    <Col offset={4} xs={{ offset: 1 }} lg={22}>
                      <Row>
                        <Col xs={24} lg={3}><div className='getup-label-2'>Custom S/O</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_start_time', {
                              initialValue: item.custom_so_start_time ? Moment(item.custom_so_start_time) : rateSetInfo.so_hours_start ? Moment(rateSetInfo.so_hours_start) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={2} lg={1}><div className='getup-label-2'>to</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_end_time', {
                              initialValue: item.custom_so_end_time ? Moment(item.custom_so_end_time) : rateSetInfo.so_hours_end ? Moment(rateSetInfo.so_hours_end) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={9} lg={9}>
                          <FormItem>
                            {getFieldDecorator('is_custom_so_time', {
                              initialValue: isCustomSO || false
                            })(
                              <Switch
                                checkedChildren='Enabled Custom SO Time'
                                unCheckedChildren='Enable Custom SO Time?'
                                style={{ marginLeft: 10 }}
                                checked={isCustomSO}
                                onChange={this.handleSleepoverCustomSO}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  { isSleepoverListEnabled === 3
                    ? <Row style={{ paddingTop: '5px', marginTop: '0px', paddingTop: '15px', paddingBottom: '20px' }}>
                      <Col lg={11} />
                      <Col lg={2}>
                        Get Up Hours
                      </Col>
                      <Col lg={1}>
                        <Tooltip title='Add Get Up Hour' mouseEnterDelay={0} mouseLeaveDelay={0}>
                          <div className='btnAddGetup' onClick={() => this.handleAddGetup()}>
                            <Icon type='plus'/>
                          </div>
                        </Tooltip>
                      </Col>
                      <Col lg={10} />
                    </Row>
                    : isSleepoverListEnabled === 2
                      ? <div className='section'>Unable to edit Sleepover Getups. Please check on Client's Funding Period again.</div>
                      : null }

                  { validator.isNotEmptyArray(extSleepoverList) && extSleepoverList.map((g, index) => {
                      const {
                        id,
                        start_datetime: std,
                        end_datetime: etd,
                        category_id: catId,
                        is_delete: isDelete,
                        notes,
                      } = g
                      return (
                        <Row key={`soitem${index}`} gutter={6} style={{ marginTop: isSleepoverListEnabled < 2 && index === 0 ? '15px' : '0px', marginBottom: '5px' }} className='getup-2'>
                          <Col lg={3}>
                            <div className='getup-label'>Get Up {index + 1}</div>
                          </Col>
                          <Col lg={3}>
                            {getFieldDecorator(`start_datetime${index}`, {
                              initialValue: std ? Moment(std).seconds(0).milliseconds(0) : null,
                              rules: [
                                { required: true, message: 'Please enter Getup Start Time' }
                              ]
                            })(
                              <TimePicker
                                disabled={isDelete}
                                use12Hours
                                format='h:mm A'
                                minuteStep={15}
                                style={{ width: 120 }}
                                defaultOpenValue={Moment('00:00:00', 'HH:mm:ss')}
                                disabledMinutes={() => this.handleSleepoverItemDisableMinutes('end_datetime', index)}
                              />
                            )}
                          </Col>
                          <Col lg={1}>
                            <div className='getup-label'>to</div>
                          </Col>
                          <Col lg={3}>
                            {getFieldDecorator(`end_datetime${index}`, {
                              initialValue: etd ? Moment(etd).seconds(0).milliseconds(0) : null,
                              rules: [
                                { required: true, message: 'Please enter Getup End Time' }
                              ]
                            })(
                              <TimePicker
                                disabled={isDelete}
                                use12Hours
                                format='h:mm A'
                                minuteStep={15}
                                style={{ width: 120 }}
                                defaultOpenValue={Moment('00:00:00', 'HH:mm:ss')}
                                disabledMinutes={() => this.handleSleepoverItemDisableMinutes('start_datetime', index)}
                              />
                            )}
                          </Col>
                          <Col lg={5}>
                            {getFieldDecorator(`category_id${index}`, {
                              initialValue: catId || null,
                            })(
                              <Select disabled={isDelete}  placeholder='Shift Type'>
                                { validator.isNotEmptyArray(billingCategoryListPreferred)
                                  ? <OptGroup label={'Preferred'}>
                                    { billingCategoryListPreferred.map((bill, idx) => {
                                      return (!bill.is_sleepover) ? <Option key={idx} value={bill.id}>{bill.name}</Option> : null
                                    }) }
                                  </OptGroup>
                                  : null }
                                { validator.isNotEmptyArray(billingCategoryListOther)
                                  ? <OptGroup label={'Other'}>
                                    { billingCategoryListOther.map((bill, idx) => {
                                      return (!bill.is_sleepover) ? <Option key={idx} value={bill.id}>{bill.name}</Option> : null
                                    }) }
                                  </OptGroup>
                                  : null }
                              </Select>
                            )}
                          </Col>
                          <Col lg={7}>
                            {getFieldDecorator(`notes${index}`, {
                              initialValue: notes || null,
                            })(
                              <Input disabled={isDelete} placeholder='Notes' />
                            )}
                          </Col>
                          <Col lg={1}>
                            { isDelete
                              ? <Popconfirm
                                title='Confirm to undo delete on this entry?'
                                onConfirm={(e) => this.handleSleepoverItemUndoDelete(index)}
                                okText='Yes'
                                cancelText='No'
                                placement='left'
                              ><div className='getup-remove-row'><Icon type='undo' /></div>
                              </Popconfirm>
                              : <Popconfirm
                                title='Confirm to remove this entry?'
                                onConfirm={(e) => this.handleSleepoverItemDelete(index)}
                                okText='Yes'
                                cancelText='No'
                                placement='left'
                              ><div className='getup-remove-row'><Icon type='delete' /></div>
                              </Popconfirm> }
                          </Col>
                        </Row>
                      )
                    }) }
                </Col>
              </Row>

            </Form>
          </Panel>
          : null }

        { validator.isNotEmptyArray(previousEmployeeList)
          ? <Panel title='Previous Carers'>
            <Row>
              <Col lg={24}>
                { previousEmployeeList.map((employee, index) => (
                    <span key={`emp_prev${index}`} className='employeeList' style={{ color: '#242b49' }}><a href={`/employees/${employee.employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</a></span>
                  )) }
              </Col>
            </Row>
          </Panel>
          : null }

        { !this.isEdit()
          ? <Panel
              title='Files'
              subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
            >
              <List cols={fileColumns} rows={fileList} />
          </Panel>
          : null }

        { /** File Modal */}
        <AddFileModal
          jobId={'add'}
          key={`addfilemodaljob_new`}
          item={fileInfo}
          categoriesList={mainFileCategoryList}
          subCategoriesList={subFileCategoryList}
          onClose={() => this.handleAddFileModal(false)}
          onSetFile={(values) => this.updateFileAdded(values)}
          visible={showAddFileModal}
        />
      </Loading>
    )
  }

  signOffTab = () => {
    const { clientInfo = {}, item = {}, loading } = this.state

    return (
      <Loading loading={loading} blur>
        <TimesheetTemplate
          info={item}
          clientInfo={clientInfo}
          mode={'view'}
          onDeleteTimesheet={() => this.handleDeleteTimesheet(item)}
          onSavedJob={() => this.refreshPage()}
        />
      </Loading>
    )
  }


  initialize = async () => {
    let clientId = null
    let funderId = null
    let clientFunderLegacyId = null
    let employeeId = null

    this.fetchSettings()
    if (this.isEdit()) {
      const item = await this.fetchJob()
      const jobId = this.getJobId()

      if (item && item.id) {
        clientId = item.client_id
        funderId = item.funder_id
        clientFunderLegacyId = item.client_funder_id
        employeeId = item.employee_id

        this.showBreakdown(Moment(item.job_start_date), Moment(item.job_end_date))
      }

      this.fetchCancel()
      // this.fetchLogs()
      this.fetchUnsentEmail(jobId)
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)

      clientId = client
      funderId = funder
    }

    this.fetchFunders(clientId, funderId, true)
    this.fetchClient(clientId, employeeId)
    this.fetchAllEmployees(clientId, employeeId)
    this.fetchFileCategories()

    this.fetchPreviousEmployee(clientId)
    this.props.fetchTotalPending()

    this.setState({ clientId, clientFunderLegacyId, funderId, employeeId })
  }

  /*********************************************************************************
    Keep all refactored/clean codes below here
    Please arrange by alphabet A-Z
  *********************************************************************************/

  /** ========== SECTION 1: fetch functions ========== */
  fetchAllEmployees = async (clientId, employeeId) => {
    try {
      const employeeAllList = await clientService.getClientEmployees(clientId)
      this.setState({ employeeAllList })

      setTimeout(() => {
        this.cacheEmployee(employeeId)
      }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchCancel = async () => {
    const cancelType = await settingCancellationService.getAll()
    this.setState({ cancelTypeList: cancelType.list || [] })
  }

  fetchJob = async () => {
    try {
      this.setState({ loading: true })

      const jobId = this.getJobId()
      const { item } = await jobService.get(jobId)

      if (!item.job_start_date) {
        item.job_start_date = Moment().startOf('hour')
      }

      if (!item.job_end_date) {
        item.job_end_date = Moment(item.job_start_date).startOf('hour').add(1, 'hour')
      }

      if (item.signed_location) {
        try {
          const loc = JSON.parse(item.signed_location)
          item.signed_location = loc
        } catch (e) {
          item.signed_location = ''
        }
      }

      if (item.signed_client_location) {
        try {
          const loc = JSON.parse(item.signed_client_location)
          item.signed_client_location = loc
        } catch (e) {
          item.signed_client_location = ''
        }
      }

      const isCustomSO = !!item.is_custom_so_time
      const isEmergency = !!item.emergency
      const isEmployeePending = !item.employee_id
      const isLastJobSeries = !!item.is_last_job_series
      const isOvernightSleepover = (item.sleepover_type === 'sleepover')
      const isExtSleepover = !!item.is_sleepover_job
      const isSignoff = !!item.signed_timesheet_id
      const extSleepoverList = item.sleepover_list ? cloneDeep(item.sleepover_list) : []

      this.setState({
        item,
        itemOri: cloneDeep(item),
        loading: false,
        itemLegacyBillingRateId: item.billing_rate_id,
        itemCurrentTotalHours: item.job_hours,
        isCustomSO,
        isEmergency,
        isEmployeePending,
        isLastJobSeries,
        isOvernightSleepover,
        isExtSleepover,
        isSignoff,
        extSleepoverList,
        sleepoverOriList: cloneDeep(extSleepoverList),
        clientId: item.client_id,
        clientFunderLegacyId: item.client_funder_id,
        employeeId: item.employee_id,
        funderId: item.funder_id,
        cachedBillingCategoryId: item.billing_category_id
      })

      this.setState({ loading: false })

      return item
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load job successfully. Please try again later.')
    }

    return null
  }

  fetchClient = async (clientId, empId) => {
    try {
      const clientInfoRes = await clientService.get(clientId)
      const clientInfo = clientInfoRes.item
      const clientLanguages = clientInfo.preferences.languages || []
      const clientSkills = clientInfo.preferences.skills || []
      const clientLanguagesName = clientInfo.preferences.languages_name || []
      const clientSkillsName = clientInfo.preferences.skills_name || []
      const isShowClientPrivateAlert = clientInfo.private_alert !== null && !validator.isEmptyString(clientInfo.private_alert)
      const isShowClientPublicAlert = clientInfo.public_alert !== null && !validator.isEmptyString(clientInfo.public_alert)
      const clashClient = clientInfoRes.clashClient
      const clashEmp = clientInfoRes.clashEmp

      this.setState({
        clientInfo,
        clientLanguages,
        clientSkills,
        clientLanguagesName,
        clientSkillsName,
        isShowClientPrivateAlert,
        isShowClientPublicAlert,
        clashClient,
        clashEmp
      })

      setTimeout(() => {
        this.checkClient(clientId)
      }, 1000)

      if (empId) {
        setTimeout(() => {
          this.checkEmployee(empId)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later')
    }
  }

  fetchFunders = async (clientId, funderId, isUpdateFunderList = false) => {
    const { history } = this.props
    let clientFunderSettingLegacyInfo = {}
    let funderInfo = { id: null }
    let funderPeriods = []
    let legacyFunders = []
    let funders = []

    if (isUpdateFunderList) {
      funders = await fvpClientFundingService.listClientFunders(clientId)
    } else {
      funders = this.state.funderList
    }

    // if no funders list, it means that the new client fundings are not available or not configured yet.
    // then need to load the legacy client funder settings to continue
    if (validator.isNotEmptyArray(funders)) {
      if (funderId) {
        const f = funders.find(e => e.funder_id === parseInt(funderId))
        if (f && f.id) {
          const info = await funderService.get(funderId)
          if (info && info.item && info.item.id) {
            funderInfo = info.item
          }
          const periods = await fvpClientFundingService.listClientFundingPeriod(clientId, funderId)

          if (validator.isNotEmptyArray(periods)) {
            funderPeriods = periods
          }
        } else {
          this.showInvalidFunderNotification()

          if (funderId) {
            const info = await funderService.get(funderId)
            if (info && info.item && info.item.id) {
              funderInfo = info.item
              funderInfo.isInvalidFunder = true
            }
          }
          // confirm({
          //   icon: (<Icon type='close-circle' style={{color: 'red'}} />),
          //   title: `Invalid Funder`,
          //   content: (
          //     <div>
          //       The funder is not set for this client. Please press on <b>Go To Client Page</b> to add the funder or <b>Cancel</b> to back to the listing.
          //     </div>
          //   ),
          //   okText: 'Go To Client Page',
          //   cancelText: 'Cancel',
          //   onOk () {
          //     history.replace(`/clients/${clientId}`)
          //   },
          //   onCancel () {
          //     history.replace(`${jobLegacyURL}/past`)
          //   }
          // })
        }
      }
    }

    // if (clientFunderLegacyId) {
    //   legacyFunders = await clientService.getClientFunders(clientId)
    //   const clientFunderSetting = await funderService.getClientFunderSetting(clientFunderLegacyId)
    //   const legacyFunderId = clientFunderSetting ? clientFunderSetting.funder_id : null

    //   if (legacyFunderId && !funderInfo.id) {
    //     const info = await funderService.get(legacyFunderId)
    //     if (info && info.item && info.item.id) {
    //       funderInfo = info
    //     }

    //     clientFunderSettingLegacyInfo = clientFunderSetting
    //   }
    // }

    this.setState({
      funderList: funders,
      funderLegacyList: legacyFunders,
      funderId,
      funderInfo,
      funderPeriodList: funderPeriods,
      currentFunderInfo: Object.assign({}, funderInfo),
      prevFunderInfo: this.state.prevFunderInfo && this.state.prevFunderInfo.id ? this.state.prevFunderInfo : Object.assign({}, funderInfo),
      clientFunderSettingLegacyInfo
    }, () => {
      const { item } = this.state
      const { form } = this.props
      const startDate = form.getFieldValue('job_start_date')
        ? form.getFieldValue('job_start_date')
        : (item && item.id)
          ? item.job_start_date
          : null

      if (startDate) {
        this.fetchRateSetInfo(startDate)
        this.checkCategoriesAndUpdate(startDate)
        this.checkClientCurrentFunding(startDate)
      }
    })

    if (!validator.isNotEmptyArray(funders) && clientFunderSettingLegacyInfo && clientFunderSettingLegacyInfo.end_date === null) {
      this.alertUnassignedTime()
    } else if (funderInfo && funderInfo.id && !validator.isNotEmptyArray(funderPeriods)) {
      this.alertUnassignedTime()
    }
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        mainFileCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subFileCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loading: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  fetchPreviousEmployee = async (clientId) => {
    try {
      const previousEmployeeList = await clientService.getClientPrevEmployeesActive(clientId)
      this.setState({ previousEmployeeList })
    } catch (e) {
      notify.error('Unable to load successfully, Unable to load previous employee list successfully. Please try again later')
    }
  }

  fetchRateSetInfo = async (jobStartDateTime) => {
    const { funderInfo } = this.state

    if (!funderInfo.isInvalidFunder) {
      try {
        const body = {
          date: jobStartDateTime,
          rate_id: funderInfo.fvp_rate_id
        }

        const r = await fvpRatesService.getDetails(body)
        if (r && r.id) {
          this.setState({ rateSetInfo: r })
        } else {
          this.setState({ rateSetInfo: {} })
        }
      } catch (e) {
        this.setState({ rateSetInfo: {} })
      }

      this.checkSleepoverMismatch ()
      // this.checkExtendedDuration()
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)

    this.setState({
      settingsAll: settings.list,
      settingsPayroll: settings.list.filter(item => item.type === 'payroll'),
      settingsLanguage: settings.list.filter(item => item.type === 'language'),
      settingsSkill: settings.list.filter(item => item.type === 'skill'),
      settingsGender: settings.list.filter(item => item.type === 'gender'),
      settingsFunding: settings.list.filter(item => item.type === 'funding'),
      settingsOthers: settingOthers.item
    })
  }

  fetchUnsentEmail = async (jobId, isRedirectedFromNewJob = false) => {
    const { unsentTotal } = await jobService.getUnsentEmailTotal(jobId)

    // if it is redirected fron new job, prevUnsentCommTotal should be null, so set the unsentCommTotal and go to checkEmail
    // else if load from id, just update both values; or if after edit save, just update unsentCommTotal and prevUnsentCommTotal will be updated at checkEmail().
    if (this.state.prevUnsentCommTotal !== null) {
      this.setState({ unsentCommTotal: unsentTotal })
    } else {
      if (isRedirectedFromNewJob) {
        this.setState({ unsentCommTotal: unsentTotal }, () => {
          this.checkEmail()
        })
      } else {
        this.setState({ unsentCommTotal: unsentTotal, prevUnsentCommTotal: unsentTotal })
      }
    }
  }

  /** ========== SECTION 2: check functions ========== */
  /** check before save function */
  checkBeforeSave = () => {
    const {
      clientConflictInfo,
      clientInfo,
      clientLeaveInfo,
      currentEmpInfo,
      currentPeriodInfo,
      employeeExpiredFiles,
      employeeJobConflictInfo,
      employeeJobHoursInfo,
      funderDetail,
      funderInfo,
      isClientOnLeave,
      isEmployeeJobConflict,
      isEmployeeOnLeave,
      isExtSleepover,
      isMatchSkill,
      isShowClientConflictAlert,
      isShowClientPrivateAlert,
      isShowClientPublicAlert,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert,
      item,
      loading,
      mismatchedSkillList,
      showFunderAlert,
      clashClient,
      clashEmp
    } = this.state
    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isFileExpired = validator.isNotEmptyArray(employeeExpiredFiles)
    const isSleepoverTurnOff = item.is_sleepover_job === true && isExtSleepover === false
    let isClientNoFundingPeriod = false

    const { form } = this.props
    const { onSaveJob } = this

    if (form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date')) {
      this.showDateNotification()
      return
    }

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    if (loading) return

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.private_alert) {
      const pAlert = currentEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.public_alert) {
      const uAlert = currentEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentPeriodInfo && currentPeriodInfo.id === null) {
      isClientNoFundingPeriod = true
    }

    if (isShowClientPrivateAlert || isShowEmployeePrivateAlert || isShowClientPublicAlert || isShowEmployeePublicAlert || isClientNoFundingPeriod ||
      isClientOnLeave || isEmployeeOnLeave || isEmployeeJobConflict || isFileExpired ||
      isShowClientConflictAlert || !isMatchSkill || isSleepoverTurnOff || showFunderAlert) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            { isClientNoFundingPeriod
              ? <div>
                <p style={{ fontSize: '14px', margin: '0px 0px 5px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client Has No Funding Period</strong></p>
                <p>The job date does not fall on any available funding period.</p>
              </div>
              : null }

            {/* Private Alert */}
            { isShowClientPrivateAlert && isShowClientPublicAlert
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <span dangerouslySetInnerHTML={{
                    __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}<br />${clientPrivateAlertMsg}`
                  }} />
                </p>
              </div>
              : isShowClientPublicAlert
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{
                      __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}`
                    }} />
                  </p>
                </div>
                : isShowClientPrivateAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPrivateAlertMsg}`
                      }} />
                    </p>
                  </div>
                  : null }

            { isShowEmployeePrivateAlert || isShowEmployeePublicAlert
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <span style={{ fontWeight: 'bold' }}>{` Employee: ${currentEmpInfo.first_name} ${currentEmpInfo.last_name}`}</span>
                  { employeePublicAlertMsg.length > 0
                    ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                    : null }
                  { employeePrivateAlertMsg.length > 0
                    ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                    : null }
                </p>
              </div>
              : null }

            {/* clashed address detail */}
            { clashClient || clashEmp
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Address</strong> ${clashClient
                            ? `<div>Same address with client: ${clashClient}</div>` : ''}
                            ${clashEmp
                              ? `<div>Same address with employee: ${clashEmp}</div>` : ''}`
                      }} />
                  </p>
                </div>
                : null }

            {/* certificate */}

            { isFileExpired
              ? ( <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Expired Files:</strong></p>
                  <li>
                    <ul>{employeeExpiredFiles.map((file, idx) => { return (<li key={`expired file warnings ${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                  </li>
                  <br />
                </div> )
              : null }

            {/* Funder */}
            {/** TODO: fvp revamp check */}
            { showFunderAlert
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Fund Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>
                <br /></div>
              : null }

            {/* Employee Max Hour Warning */}

            {// Create job max hour warning
              !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                    <div> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>
                  </p>
                </div>
                : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                      <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                      <div>{`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>
                    </p>
                  </div>
                  : null }

            {/** Edit job max hour warning */}

            { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                  <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                    `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                  }} />
                </p>
              </div>
              : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                      `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                    }} />
                  </p>
                </div>
                : null }

            {/* Client On Leave */}

            { isClientOnLeave
              ? <div>
                <div><p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div>
              : null }

            {/* Client Shift Conflict */}

            { isShowClientConflictAlert
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client Has Another Shift</strong></p>
                <p> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}</p>
                <br />
              </div>
              : null }

            {/* Employee On Leave */}

            { isEmployeeOnLeave
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{currentEmpInfo.first_name} {currentEmpInfo.last_name} is on leave from {Moment(currentEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(currentEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div>
              : null }

            {/* Employee Shift Conflict */}

            { isEmployeeJobConflict
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee Has Another Shift</strong></p>
                <p> {currentEmpInfo.first_name} {currentEmpInfo.last_name} has shift on {Moment(employeeJobConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(employeeJobConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(employeeJobConflictInfo.job_end_date))}</p>
                <br />
              </div>
              : null }

            {/* Skill Mismatch */}

            { isMatchSkill
              ? null
              : <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>{currentEmpInfo.first_name} {currentEmpInfo.last_name} does not possess following skill(s):</strong></p>
                <ul>
                  { mismatchedSkillList.map((skills, index) => (
                      <li key={index}>{skills}</li>
                    )) }
                </ul>
                <br />
              </div> }

            {/* Sleepover revoke */}
            { isSleepoverTurnOff
              ? ( <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Sleepover Is Removed:</strong></p>
                  <p>Sleepover is about to be updated to normal shift type.</p>
                  <p><strong>ALL RECORDED GET UP LIST WILL BE REMOVED IF UPDATED TO NORMAL SHIFT TYPE.</strong></p>
                  <br />
                </div> )
              : null }

            <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          onSaveJob()
        },
        onCancel () {
        }
      })
    } else {
      onSaveJob()
    }
  }

  /** Timesheet delete check function */
  checkBeforeDeleteTimesheet = () => {
    const that = this

    confirm({
      title: 'Are you sure you want to delete this timesheet?',
      content: <div><p>Press Ok to continue, No to return</p><p><strong>This action CANNOT BE UNDONE!</strong></p></div>,
      okText: 'Yes',
      cancelText: 'No',
      onOk () {
        that.handleDeleteTimesheet()
      },
      onCancel () {

      }
    })
  }

  /** client check functions */
  checkCategoriesAndUpdate = (jobStartDateTime) => {
    const { form } = this.props
    const { isExtSleepover, funderPeriodList, isDisableCategoriesSelect: isl } = this.state
    let billingCategoryList = []
    let isDisableCategoriesSelect = isl

    if (jobStartDateTime) {
      /**
       * Update on 20220624: funderPeriodList will add new periods which has no period ID but having funder_id and valid category list.
       * this list is to indicate the admin select a job date that beyond of current client's plan but rate set did include those categories which are open to select
       * when this plan is selected, all categories will be in Other list, but no locking on shift type select.
       */
      /**
       * two jsdt set available. first one is following the job start date time and find the period,
       * second one is following current date time. it is possible that the created job (by recurring)
       * is beyond of any current funder period.
       * when second case is met, the shift type (billing category) select should be disabled and show alert
       * to indicate the job start date time is not falling into any availble funding period
       * */
      const jsdt = Moment.isMoment(jobStartDateTime) ? jobStartDateTime : Moment(jobStartDateTime)
      const pd = funderPeriodList.find(e => {
        const std = Moment(e.start_date)
        const etd = Moment(e.end_date)
        return std.isBefore(jsdt) && etd.isAfter(jsdt)
      })

      const jsdt2 = Moment(new Date())
      const pd2 = funderPeriodList.find(e => {
        const std = Moment(e.start_date)
        const etd = Moment(e.end_date)
        return std.isBefore(jsdt2) && etd.isAfter(jsdt2)
      })

      // check on pd.id (valid client funding) or pd.funder_id (category list out of client's funding periods) to enter the sequence
      if (pd && (pd.id || pd.funder_id) && validator.isNotEmptyArray(pd.category)) {
        billingCategoryList = pd.category
        isDisableCategoriesSelect = false
        if (!pd.id) this.showNoFundingPeriodNotification()
      } else if (pd2 && (pd2.id || pd2.funder_id) && validator.isNotEmptyArray(pd2.category)) {
        billingCategoryList = pd2.category
        isDisableCategoriesSelect = false
        this.showNoFundingPeriodNotification()
      } else {
        isDisableCategoriesSelect = true
        this.showNoFundingPeriodNotification()
      }
    }

    this.setState({
      isDisableCategoriesSelect,
      isExtSleepover: jobStartDateTime ? isExtSleepover : false,
      billingCategoryList
    }, () => {
      this.fetchRateSetInfo(jobStartDateTime)
    })
  }

  checkClient = (clientId, start, end, isUpdate = false) => {
    const { form } = this.props

    const jobStartDateTime = start || Moment(form.getFieldValue('job_start_date'))
    const jobEndDateTime = end || Moment(form.getFieldValue('job_end_date'))

    if (jobStartDateTime.isValid() && jobEndDateTime.isValid()) {
      // 1. Check if client is onleave
      this.checkClientLeave(clientId, jobStartDateTime, jobEndDateTime)

      // 2. Check if it has client conflict shift
      this.checkClientJobConflict(clientId, jobStartDateTime, jobEndDateTime)

      // 3. Check if it is public holiday
      this.checkHolidayDouble(jobStartDateTime, jobEndDateTime)

      // 4. Check if it is sleepover
      this.checkSleepover(jobStartDateTime, jobEndDateTime, isUpdate)

      // 5. Check job sleepover extended period
      // setTimeout(() => {
      //   this.checkExtendedDuration()
      // }, 200)
    }
  }

  checkClientLeave = async (clientId, start, end) => {
    const std = !start  // step 1: check whether is empty date
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(start) // step 2: check whether start is a moment object
        ? Moment(start).utc().format(sqlFormat)
        : start.isValid() // step 3: check whether start has a valid date
          ? start.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)
    const etd = !end
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(end)
        ? Moment(end).utc().format(sqlFormat)
        : end.isValid()
          ? end.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)

    const allLeaves = await clientLeaveService.getByStartEnd(clientId, std, etd)

    if (allLeaves && validator.isNotEmptyArray(allLeaves.item)) {
      this.setState({ isClientOnLeave: true, clientLeaveInfo: allLeaves.item[0] })
      // this.showClientLeaveConfirm()
    } else {
      this.setState({ isClientOnLeave: false, clientLeaveInfo: null })
    }
  }

  checkClientJobConflict = async (client, start, end) => {
    const jobId = this.getJobId()
    const conflictJobs = await jobService.getClientConflictJobs(jobId, client, start.format(), end.format())

    if (conflictJobs.items.length > 0) {
      this.setState({ isShowClientConflictAlert: true, clientConflictInfo: conflictJobs.items[0] })
      // this.showClientConflictConfirm()
    } else {
      this.setState({ isShowClientConflictAlert: false, clientConflictInfo: null })
    }

    // Further check for employee's conflict job if employee is set
    const { form } = this.props
    const employeeId = form.getFieldValue('employee_id')

    if (employeeId) {
      this.checkEmployeeJobConflict(employeeId, start, end)
    }
  }

  checkClientCurrentFunding = (jobStartDate) => {
    let period = {}
    const { funderPeriodList } = this.state
    const p = funderPeriodList.find(e => {
      const std = Moment(e.start_date)
      const etd = Moment(e.end_date)
      const jsd = Moment.isMoment(jobStartDate) ? jobStartDate : Moment(jobStartDate)

      return jsd.isSameOrAfter(std) && jsd.isSameOrBefore(etd)
    })

    if (p && p.id) {
      period = p
    } else {
      period = { id: null }
    }

    this.setState({ currentPeriodInfo: period })
  }

  /**
   * this function is used for check extended duration if S/O job is different with rate set / custom S/O period
   * it shall be have more extended entries if the job period is wider than S/O period
   * on hold for this update currently.
   */
   checkExtendedDuration = () => {
    const { form } = this.props
    const { isCustomSO, isExtSleepover, extBreakdownInfo, rateSetInfo, item } = this.state
    const jobStartDate = form.getFieldValue('job_start_date') || Moment(item.job_start_date)
    const jobEndDate = form.getFieldValue('job_end_date') || Moment(item.job_end_date)
    const soStartDate = form.getFieldValue('custom_so_start_time')
    const soEndDate = form.getFieldValue('custom_so_end_time')

    if (jobStartDate && jobEndDate && rateSetInfo && rateSetInfo.id && isExtSleepover) {
      let finalSoStartDate = null
      let finalSoEndDate = null
      if (isCustomSO) {
        finalSoStartDate = soStartDate
        finalSoEndDate = soEndDate
      } else {
        finalSoStartDate = rateSetInfo.so_hours_start
        finalSoEndDate = rateSetInfo.so_hours_end
      }

      if (finalSoStartDate && finalSoEndDate) {
        extBreakdownInfo.updateValue({soHourStart: finalSoStartDate, soHourEnd: finalSoEndDate})
        extBreakdownInfo.getBreakdown(jobStartDate, jobEndDate)
      }
    }
  }

  checkHolidayDouble = async (date1, date2) => {
    const d1 = Moment.isMoment(date1) ? date1.format('YYYY-MM-DD') : Moment(new Date()).format('YYYY-MM-DD')
    const d2 = Moment.isMoment(date2) ? date2.format('YYYY-MM-DD') : null
    const holiday = d2 ? await settingHolidayService.getApproachHoliday(d1, d2) : await settingHolidayService.getTodayHoliday(d1)

    if (holiday && validator.isNotEmptyArray(holiday.list)) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list })
      return true
    } else {
      this.setState({ isHoliday: false, holidayInfo: [] })
      return false
    }

    return false
  }

  checkHoliday = async (date) => {
    const newDate = Moment.isMoment(date) ? date.format('YYYY-MM-DD') : Moment(new Date()).format('YYYY-MM-DD')
    const holiday = await settingHolidayService.getTodayHoliday(newDate)

    if (holiday && validator.isNotEmptyArray(holiday.list)) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list })
      return true
    } else {
      this.setState({ isHoliday: false, holidayInfo: [] })
      return false
    }

    return false
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSleepover = (start, end, isUpdate) => {
    // check whether the current job start/end time able to auto trigger sleepover flag or not
    // condition: job duration must be MORE THAN 4 HOURS OF AH.
    // so if job duration is beyond of AH, sleepover toggle shall not be triggered anyhow.
    const { isExtSleepover, rateSetInfo } = this.state

    // only process if rateSetInfo is available, because need to get AH Hours
    if (rateSetInfo && rateSetInfo.id && isUpdate) {
      const std = Moment.isMoment(start) ? start : Moment(start)
      const etd = Moment.isMoment(end) ? end : Moment(end)

      const isSameDay = std.isSame(etd, 'day')
      const durations = Moment.duration(Math.abs(end.diff(start))).asMinutes()

      // get after hours moment obj
      const saah = rateSetInfo.after_hours_start ? Moment(rateSetInfo.after_hours_start) : null
      const eaah = rateSetInfo.after_hours_end ? Moment(rateSetInfo.after_hours_end) : null

      // set valid AH hours based on the setting. for AH end time, if the start&end dates are same day, the AH end time shall be on the next day.
      const sah = saah ? std.clone().set('hours', saah.get('hours')).set('minutes', saah.get('minutes')) : null
      const eah = eaah ? (isSameDay ? etd.clone().add(1, 'day').set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes')) : etd.clone().set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes'))) : null

      // get the 12:00AM moment object of the day, if same day, then it should be end of the day (next day 12.00AM), else it would be end date's start of the day.
      const eth = isSameDay ? etd.clone().endOf('day').add(1, 'second') : etd.clone().startOf('day')

      let ovlDuration = 0

      if (sah && eah) {
        // case for same day:
        // if job end time is before start of AH: no possibilities for auto sleepover detection since it is out of AH duration
        // if job end time is after start of AH: check on job start time is whether before or after start of AH.
        // if before, get the duration between start of AH and job end time
        // if after, get the duration between job start time and job end time (i.e. job duration is within the period of AH but before the day ends)
        if (isSameDay) {
          if (!etd.isBefore(sah)) {
            if (std.isBefore(sah)) {
              ovlDuration = Moment.duration(Math.abs(etd.diff(sah))).asMinutes()
            } else {
              ovlDuration = Moment.duration(Math.abs(etd.diff(std))).asMinutes()
            }
          }
        } else {
          // case for diff day:
          // for day 1, check job start time is before/after start of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          // for day 2, check job end time is before/after end of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          let d1c = 0
          let d2c = 0
          if (std.isBefore(sah)) {
            d1c = Moment.duration(Math.abs(sah.diff(eth))).asMinutes()
          } else {
            d1c = Moment.duration(Math.abs(std.diff(eth))).asMinutes()
          }

          if (etd.isAfter(eah)) {
            d2c = Moment.duration(Math.abs(eth.diff(eah))).asMinutes()
          } else {
            d2c = Moment.duration(Math.abs(eth.diff(etd))).asMinutes()
          }

          ovlDuration = d1c + d2c
        }
      }

      if (durations > 240 && ovlDuration > 240) {
        if (std.isBefore(etd)) {
          this.handleSleepover(isUpdate ? true : isExtSleepover, true)
        }
      } else {
        this.handleSleepover(false, false, isUpdate)
      }
    }
  }

  checkSleepoverMismatch = () => {
    const { form } = this.props
    const { isCustomSO, isExtSleepover, extBreakdownInfo, rateSetInfo } = this.state
    const jobStartDate = form.getFieldValue('job_start_date')
    const jobEndDate = form.getFieldValue('job_end_date')
    const soStartDate = form.getFieldValue('custom_so_start_time')
    const soEndDate = form.getFieldValue('custom_so_end_time')
    let soType = ''

    if (jobStartDate && jobEndDate && rateSetInfo && rateSetInfo.id && isExtSleepover) {
      let finalSoStartDate = null
      let finalSoEndDate = null
      if (isCustomSO) {
        finalSoStartDate = soStartDate
        finalSoEndDate = soEndDate
        soType = 'custom'
      } else {
        finalSoStartDate = rateSetInfo.so_hours_start
        finalSoEndDate = rateSetInfo.so_hours_end
        soType = 'rateset'
      }

      if (finalSoStartDate && finalSoEndDate) {
        const std = Moment.isMoment(jobStartDate) ? jobStartDate.format('HH:mm') : Moment(jobStartDate).format('HH:mm')
        const etd = Moment.isMoment(jobEndDate) ? jobEndDate.format('HH:mm') : Moment(jobEndDate).format('HH:mm')
        const sod = Moment.isMoment(finalSoStartDate) ? finalSoStartDate.format('HH:mm') : Moment(finalSoStartDate).format('HH:mm')
        const eod = Moment.isMoment(finalSoEndDate) ? finalSoEndDate.format('HH:mm') : Moment(finalSoEndDate).format('HH:mm')

        let text = ''

        if (std !== sod || etd !== eod) {
          if (soType === 'custom') {
            text = 'The job start end time does not match with Custom S/O duration.'
          } else if (soType === 'rateset') {
            text = 'The job start end time does not match with Rate Set S/O duration.'
          }
        }

        this.setState({textSleepoverMismatch: text})
      } else {
        this.setState({textSleepoverMismatch: ''})
      }
    }
  }

  /** employee check functions */
  checkEmployee = async (empId, changeEmployee = false) => {
    const { form } = this.props
    const formStartDate = Moment(form.getFieldValue('job_start_date'))
    const formEndDate = Moment(form.getFieldValue('job_end_date'))
    let employeeJobHoursInfo = {}

    if (typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null) && (Moment(formEndDate).isValid() && formEndDate !== null)) {
      employeeJobHoursInfo = await this.checkEmployeeWeeklyMaxHour(empId, formStartDate, formEndDate)
    }

    const employeeInfoRes = await employeeService.get(empId)
    const employeeInfo = employeeInfoRes.item
    const empLanguages = employeeInfo.preferences.languages || []
    const empSkills = employeeInfo.preferences.skills || []
    const empLanguagesName = employeeInfo.preferences.languages_name || []
    const empSkillsName = employeeInfo.preferences.skills_name || []
    const isShowEmployeePrivateAlert = employeeInfo.private_alert !== null && !validator.isEmptyString(employeeInfo.private_alert)
    const isShowEmployeePublicAlert = employeeInfo.public_alert !== null && !validator.isEmptyString(employeeInfo.public_alert)

    setTimeout(() => {
      // Show popup alert if change new employee & employee have private alert or public alert
      if ((changeEmployee && isShowEmployeePrivateAlert) || (changeEmployee && isShowEmployeePublicAlert) || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)) {
        const pAlert = employeeInfo.private_alert || ''
        const uAlert = employeeInfo.public_alert || ''
        const privateAlert = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        const publicAlert = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        warning({
          title: `Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`,
          content: (
            <div>
              { publicAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null }
              { privateAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null }
              { !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpHasMaxHour
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
            </div>
          ),
          okText: 'OK',
          onOk () { },
        })
      }
    }, 400)

    this.setState({
      currentEmpInfo: employeeInfo,
      empLanguages,
      empSkills,
      empLanguagesName,
      empSkillsName,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert
    })

    const { clientLanguagesName, clientSkillsName } = this.state

    // check skills and languages mismatch between client and employee
    let mismatchedLanguageList = []
    let mismatchedSkillList = []

    if (validator.isNotEmptyArray(clientLanguagesName)) {
      mismatchedLanguageList = cloneDeep(clientLanguagesName).filter(e => empLanguagesName.findIndex(f => f === e) < 0)
    }

    if (validator.isNotEmptyArray(clientSkillsName)) {
      mismatchedSkillList = cloneDeep(clientSkillsName).filter(e => empSkillsName.findIndex(f => f === e) < 0)
    }

    this.setState({
      isMatchLanguage: !validator.isNotEmptyArray(mismatchedLanguageList),
      isMatchSkill: !validator.isNotEmptyArray(mismatchedSkillList),
      mismatchedLanguageList,
      mismatchedSkillList
    })

    // 1. Check schedule conflict
    this.checkEmployeeJobConflict(empId, formStartDate, formEndDate)

    // 2. Check if employee is on leave on the selected date
    this.checkEmployeeLeave(empId, formStartDate, formEndDate)

    // 3. Check employee files expiry
    if (validator.isDigit(empId)) {
      this.checkEmployeeFilesExpiry(empId, formStartDate, formEndDate)
    }
  }

  checkEmployeeJobConflict = async (employee, start, end) => {
    const std = !start  // step 1: check whether is empty date
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(start) // step 2: check whether start is a moment object
        ? Moment(start).utc().format(sqlFormat)
        : start.isValid() // step 3: check whether start has a valid date
          ? start.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)
    const etd = !end
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(end)
        ? Moment(end).utc().format(sqlFormat)
        : end.isValid()
          ? end.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)

    const jobId = this.getJobId()
    const conflictJobs = await jobService.getEmployeeConflictJobs(jobId, employee, std, etd)

    if (conflictJobs && validator.isNotEmptyArray(conflictJobs.items)) {
      this.setState({ isEmployeeJobConflict: true, employeeJobConflictInfo: conflictJobs.items[0] })
      // this.showEmployeeConflictConfirm()
    } else {
      this.setState({ isEmployeeJobConflict: false })
    }
  }

  checkEmployeeLeave = async (empId, start, end) => {
    const std = !start  // step 1: check whether is empty date
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(start) // step 2: check whether start is a moment object
        ? Moment(start).utc().format(sqlFormat)
        : start.isValid() // step 3: check whether start has a valid date
          ? start.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)
    const etd = !end
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(end)
        ? Moment(end).utc().format(sqlFormat)
        : end.isValid()
          ? end.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)

    const allLeaves = await employeeLeaveService.getByStartEnd(empId, std, etd)

    if (allLeaves && validator.isNotEmptyArray(allLeaves.item)) {
      this.setState({ isEmployeeOnLeave: true, employeeLeaveInfo: allLeaves.item[0] })
      // this.showEmployeeLeaveConfirm()
    } else {
      this.setState({ isEmployeeOnLeave: false, employeeLeaveInfo: {} })
    }
  }

  checkEmployeeFilesExpiry = async (empId, jobStartDate, jobEndDate) => {
    const filter = {}
    const employeeExpiredFiles = []

    filter.has_expiry = { condition: '=', value: 'true' }
    filter.active = { condition: '=', value: 'true' }
    filter.module = { condition: '=', value: 'employee' }
    filter.module_id = { condition: '=', value: empId }

    const list = await employeeFileService.listByPage(1, 0, filter)

    if (list && validator.isNotEmptyArray(list.list)) {
      const files = list.list
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.expiry_date) {
          if (jobStartDate.isAfter(file.expiry_date) || jobEndDate.isAfter(file.expiry_date)) {
            employeeExpiredFiles.push(file)
          }
        }
      }
    }

    this.setState({ employeeExpiredFiles })
  }

  checkEmployeeWeeklyMaxHour = async (employeeId, start, end) => {
    let employeeJobHoursInfo = {}
    if (end > start) {
      let body = {}
      const startingWeek = Moment(start).startOf('week')
      const endingWeek = Moment(start).endOf('week')
      const s = Moment(startingWeek).startOf('day')
      const e = Moment(endingWeek).endOf('day')
      const durationFromNewJob = Moment.duration(Math.abs(start.diff(end)))
      const differentHours = durationFromNewJob.asHours()
      const jobId = this.getJobId()

      body.empId = employeeId
      body.start = s
      body.end = e
      body.newJobHours = differentHours
      body.jobId = jobId === 'add' ? null : jobId
      const r = await jobService.getEmployeeTotalMaxHour(body)

      if (r && r.isEmpHasMaxHour !== undefined) {
        employeeJobHoursInfo = r
        this.setState({ employeeJobHoursInfo: r })
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    } else {
      this.setState({ employeeJobHoursInfo: {} })
    }

    return employeeJobHoursInfo
  }

  checkEmergencyJob = async (date) => {
    const { settingsOthers } = this.state
    const emergencyDuration = settingsOthers.emergency_notice
    const emergencyEmpDuration = settingsOthers.emergency_notice_emp
    const durationFromNow = Moment.duration(Math.abs(date.diff(Moment())))
    const differentHours = durationFromNow.asHours()
    const isPastDate = Moment().isAfter(date)

    if ((differentHours >= 0 && differentHours <= emergencyDuration) || isPastDate) {
      // this.setState({ isEmergencyTime: true })

      if (emergencyEmpDuration) {
        this.showEmergencyConfirm({
          emgPay: differentHours >= 0 && differentHours <= emergencyEmpDuration,
          emgInv: differentHours >= 0 && differentHours <= emergencyDuration,
          emgHours: emergencyDuration
        })
      } else {
        this.showEmergencyConfirm({
          emgPay: true,
          emgInv: true,
          emgHours: emergencyDuration
        })
      }
    } else {
      // this.setState({ isEmergencyTime: false })
    }
  }

  checkJobLessThan2Hrs = async (startDate, endDate) => {
    const differentHours = Moment.duration(Math.abs(startDate.diff(endDate))).asHours()

    if (differentHours < 2) {
      warning({
        title: 'This job\'s duration is less than 2 hours.'
      })
    }
  }

  checkEmail = () => {
    const that = this
    const { prevUnsentCommTotal, unsentCommTotal } = this.state

    if ((prevUnsentCommTotal === null && unsentCommTotal > 0) ||
      (prevUnsentCommTotal !== null && unsentCommTotal > 0 && prevUnsentCommTotal < unsentCommTotal)) {
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.setState({ tabActiveKey: '2' })
        },
        onCancel () {

        }
      })

      // update on prevUnsentCommTotal after confirm pop up appears
      this.setState({ prevUnsentCommTotal: unsentCommTotal })
    }
  }

  /** ========== SECTION 3: SAVE Related Controls and functions ========== */
  /** Cancel handles functions */
  onSaveCancelJob = async () => {
    const { form } = this.props
    const { employeeId, funderInfo, settingsOthers, clientFunderLegacyId, item, cancelReasonList, cancelTypeList } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const that = this

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    validateFieldsAndScroll(['job_start_date', 'cancellation_reason', 'cancellation_type', 'cancellation_note', 'cancellation_other_reason'], async (errors, values) => {
      const valuesStart = Moment(values.job_start_date)
      const nowTime = Moment()

      const durations = Moment.duration(Math.abs(valuesStart.diff(nowTime)))
      const diffHours = durations.asHours()
      const cancelParam = settingsOthers.cancellation_notice
      const isPastDate = Moment().isAfter(valuesStart)
      const { name: adminName } = authService.getCurrentUser()

      if (!errors) {
        if (diffHours < cancelParam || isPastDate) {
          // Late cancellation wiht option to Normal Cancel
          this.setState({ showCancelConfirmModal: true })
        } else {
          // Normal cancellation
          values.is_cancel = true
          values.cancellation_time = Moment()
          values.cancellation_penalty = false
          values.funder_id = funderInfo.id || item.funder_id
          values.client_funder_id = clientFunderLegacyId
          values.employee_id = employeeId || null
          values.pending_employee = !employeeId
          values.staff = adminName
          values.original_end_date = item.job_end_date

          item.original_end_date = item.job_end_date

          this.setState({ item })


          // Generate & save log_multi_action
          const cancelTypeName = cancelTypeList.find(e => e.id === values.cancellation_type) || {}
          const cancelReasonId = cancelReasonList.find(e => e.name === values.cancellation_reason) || {}
          const actions = {}
          actions.module = 'job'
          actions.module_id = jobId
          actions.previous_id = employeeId
          actions.current_id = employeeId
          actions.action = 'job-cancel'
          actions.action_lvl1_id = values.cancellation_type
          actions.action_lvl1_name = cancelTypeName.name
          actions.action_lvl1_text = values.cancellation_other_reason
          actions.action_lvl1_other = values.cancellation_note
          actions.action_lvl2_id = cancelReasonId.id
          actions.action_lvl2_name = values.cancellation_reason

          confirm({
            title: 'Are you sure you want to cancel this job?',
            content: 'Press Ok to continue, No to return',
            okText: 'Yes',
            cancelText: 'No',
            async onOk () {
              const response = await jobService.save(jobId, values)
              if (response.id) {
                log.cancelJob(response.id, `Reason: ${that.getCancelTypeName(values.cancellation_type)} - ${values.cancellation_reason} ${values.cancellation_other_reason ? ` - ${values.cancellation_other_reason}` : ''} ${values.cancellation_note ? ` (${values.cancellation_note})` : ''}`)
                await that.logJobUpdate(response, item, values)
                const actionLog = await jobActionLogService.add(actions)

                notify.success('Success', 'Job Cancelled.')

                that.resetCancelForm()
                that.refreshPage()
              }
            }
          })
        }
      }
    })
  }

  onSaveUncancelJob = async () => {
    const { form } = this.props
    const { funderInfo, clientFunderLegacyId, item, clientId, employeeId } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const { name: adminName } = authService.getCurrentUser()
    const that = this

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      // Generate & save log_multi_action
      const actions = {}

      actions.module = 'job'
      actions.module_id = jobId
      actions.previous_id = employeeId
      actions.current_id = employeeId
      actions.action = 'job-uncancel'

      values.is_cancel = false
      values.cancellation_reason = null
      values.cancellation_time = null
      values.cancellation_other_reason = null
      values.cancellation_type = null
      values.cancellation_penalty = false
      values.funder_id = funderInfo.id || item.funder_id
      values.client_funder_id = clientFunderLegacyId
      values.staff = adminName
      values.job_end_date = item.original_end_date

      delete values.change_employee_reason
      delete values.change_employee_reason_type
      delete values.change_employee_note

      confirm({
        title: 'Are you sure to uncancel this job?',
        content: 'Click Yes to uncancel, No to leave the job cancelled.',
        okText: 'Yes',
        cancelText: 'No',
        async onOk () {
          values.notes = ''

          const response = await jobService.save(jobId, values)
          if (response.id) {
            notify.success('Success', 'Job Uncancelled.')
            log.uncancelJob(response.id, 'Job is uncancelled')
            await that.logJobUpdate(response, item, values)
            const actionLog = await jobActionLogService.add(actions)
            that.refreshPage()
          }
        }
      })
    })
  }

  onSaveLateCancel = async (isLate = false) => {
    const { form, fetchingJobs } = this.props
    const { clientFunderLegacyId, employeeId, funderInfo, item, clientId, cancelReasonList, cancelTypeList } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const { name: adminName } = authService.getCurrentUser()

    validateFieldsAndScroll(['cancellation_reason', 'cancellation_type', 'cancellation_other_reason', 'cancellation_note', 'job_end_date'],
      async (errors, values) => {
        values.is_cancel = true
        values.cancellation_time = Moment()
        values.funder_id = funderInfo.id || item.funder_id
        values.client_funder_id = clientFunderLegacyId
        values.employee_id = employeeId || null
        values.pending_employee = !employeeId
        values.staff = adminName
        values.original_end_date = item.job_end_date

        if (isLate) {
          this.setState({ loadingLateCancel: true })

          const initialNotes = values.notes
          const remarkStr = '(LC)'
          const finalStr = `${initialNotes} ${remarkStr}`
          const currStartTime = form.getFieldValue('job_start_date')
          const newEndTime = Moment(currStartTime).add(this.getChargeHours(), 'hours')

          values.job_end_date = newEndTime
          values.original_end_date = item.job_end_date

          values.notes = finalStr
          values.cancellation_penalty = true
          values.job_hours = this.getChargeHours()
        } else {
          this.setState({ loadingCancel: true })
        }

        const response = await jobService.save(jobId, values)

        if (response.id) {
          // Generate & save log_multi_action
          const cancelTypeName = cancelTypeList.find(e => e.id === values.cancellation_type) || {}
          const cancelReasonId = cancelReasonList.find(e => e.name === values.cancellation_reason) || {}
          const actions = {}

          actions.module = 'job'
          actions.module_id = jobId
          actions.previous_id = employeeId
          actions.current_id = employeeId
          actions.action = 'job-cancel'
          actions.action_lvl1_id = values.cancellation_type
          actions.action_lvl1_name = cancelTypeName.name
          actions.action_lvl1_text = values.cancellation_other_reason
          actions.action_lvl1_other = values.cancellation_note
          actions.action_lvl2_id = cancelReasonId.id
          actions.action_lvl2_name = values.cancellation_reason

          const actionLog = await jobActionLogService.add(actions)

          log.cancelJob(response.id, `${isLate ? 'Late Cancellation.' : ''} Reason: ${this.getCancelTypeName(values.cancellation_type)} - ${values.cancellation_reason} ${values.cancellation_other_reason ? ` - ${values.cancellation_other_reason}` : ''} ${values.cancellation_note ? ` (${values.cancellation_note})` : ''}`)

          await this.logJobUpdate(response, item, values)
          notify.success('Success', 'Job Cancelled.')

          this.setState({ showCancelConfirmModal: false, showCancelModal: false, loadingCancel: false, loadingLateCancel: false })

          this.resetCancelForm()
          this.refreshPage()
        }
      }
    )
  }

  onSaveLateCancellation = async(value) => {
    const { item } = this.state
    const { form } = this.props
    const that = this

    confirm({
      title: 'Late Cancelllation',
      content: `${value ? 'Add' : 'Remove'} late cancellation charge?`,
      async onOk () {
        const params = { cancellation_penalty: value }

        if (value) {
          params.job_end_date = Moment(item.job_start_date).add(that.getChargeHours(), 'hours')

          if (!item.original_end_date) {
            params.original_end_date = item.job_end_date
          }
        } else {
          params.job_end_date = item.original_end_date
        }

        const response = await jobService.saveQuick(item.id, params)

        form.setFieldsValue({ cancellation_penalty: value, job_end_date: Moment(params.job_end_date) })
        that.showBreakdown(Moment(item.job_start_date), Moment(params.job_end_date))
        item.cancellation_penalty = value

        that.setState({ item })

        if (response) {
          notify.success('Updated successfully', 'Job updated successfully.')
          await that.logJobUpdate(response, item, params)
          log.updateJobExtra(response.id, 'update', `Late cancellation from '${!value}' to '${value}'`)
        }

        that.refreshPage()
      },
      onCancel () {
        form.setFieldsValue({ cancellation_penalty: !value })
      }
    })
  }

  onSaveJob = async (isRedirected = false) => {
    const { form, fetchingJobs, history } = this.props
    const { clientInfo, changeEmployeeReason, changeEmployeeReasonType, changeEmployeeOtherReason, changeEmployeeNote, fileList } = this.state
    const { validateFieldsAndScroll, setFieldsValue } = form

    const { name: adminName } = authService.getCurrentUser()

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const {
          cancelTypeList,
          cancelReasonList,
          clientId,
          currentEmpInfo,
          currentFunderInfo,
          extSleepoverList,
          employeeId,
          funderInfo,
          item,
          itemOri,
          isCustomSO,
          isEmployeePending,
          isExtSleepover,
          loading,
          newEmployeeId,
          rateSetInfo
        } = this.state

        if (itemOri.employee_id && itemOri.employee_id !== values.employee_id) {
          if (!changeEmployeeReason){
            this.setState({ showEmployeeReasonModal: true })
            return
          }
        }

        if (loading && !isRedirected) return

        this.setState({ loading: true })

        // calculate Job Hours
        const startDate = values.job_start_date.seconds(0).milliseconds(0)
        const endDate = values.job_end_date.seconds(0).milliseconds(0)
        const durations = Moment.duration(Math.abs(endDate.diff(startDate)))
        const diffHours = durations.asHours()

        values.job_hours = Math.round(diffHours * 100) / 100
        // values.billing_rate_id = funderInfo.rate_set // for legacy value only, no actual use for fvp
        values.billing_rate_id = null // fvp update to configure all jobs saved under v2 empty the billing_rate_id to separate with legacy case
        values.job_start_day = Moment(startDate).format('dddd')

        values.client_id = clientId
        values.funder_id = funderInfo.id || item.funder_id
        values.client_funder_id = rateSetInfo.id // updated with fvp rate set id but still no actual use for fvp

        values.pending_employee = isEmployeePending

        values.staff = adminName

        // round up recorded kms
        const newKms = formatter.roundKMS(values.kms)
        values.kms = newKms
        setFieldsValue({ kms: newKms })

        // Replace and remove if tasks has special character, tab or extra blank spaces
        if (values.tasks && values.tasks.length > 0) {
          values.tasks = this.replacer(values.tasks).trim()
          setFieldsValue({ tasks: values.tasks })
        }

        // Replace and remove if notes has special character, tab or extra blank spaces
        if (values.notes && values.notes.length > 0) {
          values.notes = this.replacer(values.notes).trim()
          setFieldsValue({ notes: values.notes })
        }

        // Replace and remove if job_survey_feedback_text has special character, tab or extra blank spaces
        if (values.job_survey_feedback_text && values.job_survey_feedback_text.length > 0) {
          values.job_survey_feedback_text = this.replacer(values.job_survey_feedback_text).trim()
          setFieldsValue({ job_survey_feedback_text: values.job_survey_feedback_text })
        }

        // Check if job is not emergency then clear invoice and pay
        if (!values.emergency) {
          values.emergency_invoice = false
          values.emergency_pay = false
        }

        delete values.cancellation_time

        if (item.base_job_id !== null) {
          values.is_updated = true
        }

        // Remove job_kms if job_kms empty string
        if (values.job_kms === '') {
          values.job_kms = null
        }

        // Remove kms if kms empty string
        if (values.kms === '') {
          values.kms = null
        }

        // fvp sleepover
        values.is_sleepover_job = isExtSleepover
        values.is_custom_so_time = isCustomSO
        values.sleepover_type = isExtSleepover ? 'sleepover' : null

        if (!isCustomSO) {
          values.custom_so_start_time = null
          values.custom_so_end_time = null
        }

        // fvp update to configure all jobs saved under v2 empty the billing_rate_id to separate with legacy case
        values.billing_rate_id = null

        // fvp sleepover list implementation
        const soList = []
        if (validator.isNotEmptyArray(extSleepoverList)) {
          for (let i = 0; i < extSleepoverList.length; i++) {
            const so = extSleepoverList[i]
            if (so.id) {
              if (so.is_delete) {
                soList.push({
                  id: so.id,
                  is_delete: so.is_delete
                })
              } else {
                soList.push({
                  id: so.id,
                  start_datetime: values[`start_datetime${i}`],
                  end_datetime: values[`end_datetime${i}`],
                  category_id: values[`category_id${i}`],
                  notes: values[`notes${i}`] || null,
                  type: 'getup'
                })
              }
              delete values[`start_datetime${i}`]
              delete values[`end_datetime${i}`]
              delete values[`category_id${i}`]
              delete values[`notes${i}`]
            } else {
              soList.push({
                id: null,
                start_datetime: values[`start_datetime${i}`],
                end_datetime: values[`end_datetime${i}`],
                category_id: values[`category_id${i}`],
                notes: values[`notes${i}`] || null,
                type: 'getup'
              })

              delete values[`start_datetime${i}`]
              delete values[`end_datetime${i}`]
              delete values[`category_id${i}`]
              delete values[`notes${i}`]
            }
          }
        }

        values.sleepover_list = soList

        try {
          if (this.isEdit()) {
            values.original_end_date = values.job_end_date

            const isUpdateAlert = this.logValidateStatusUpdate(item, values)
            if (isUpdateAlert) {
              values.status = 'review'
            } else {
              values.status = item.status
            }

            const response = await jobService.save(item.id, values)

            if (response.id) {
              const { id } = response
              // Generate & save log_multi_action
              if (changeEmployeeReason !== null && changeEmployeeReasonType !== null) {
                const oldEmployeeName = await this.getEmployeeName(employeeId)
                const newEmployeeName = await this.getEmployeeName(newEmployeeId)
                const reasonType = await cancelTypeList.find(e => e.id === parseInt(changeEmployeeReasonType)) || {}
                const reasonId = await cancelReasonList.find(e => e.name === changeEmployeeReason) || {}
                const actions = {}

                actions.module = 'job'
                actions.module_id = id
                actions.action = 'job-employee-change'
                actions.action_detail = `job change employee from "${oldEmployeeName}" to "${newEmployeeName}"`
                actions.previous_id = employeeId
                actions.current_id = newEmployeeId
                actions.action_lvl1_id = changeEmployeeReasonType
                actions.action_lvl1_name = reasonType.name
                actions.action_lvl1_other = changeEmployeeOtherReason
                actions.action_lvl1_text = changeEmployeeNote
                actions.action_lvl2_id = reasonId.id
                actions.action_lvl2_name = changeEmployeeReason

                const actionLog = await jobActionLogService.add(actions)

                const actions2 = {}
                actions2.module = 'job'
                actions2.module_id = id
                actions2.action = 'job-cancel-by-employee-change'
                actions2.previous_id = employeeId
                actions2.current_id = newEmployeeId
                const actionLog2 = await jobActionLogService.add(actions2)
              }

              let extraLogs = []

              await this.logJobUpdate(response, item, values, undefined, isUpdateAlert)

              notify.success('Saved successfully', 'Job saved successfully.')

              this.refreshPage()
              this.setState({
                changeEmployeeReason: null,
                changeEmployeeReasonType: null,
                changeEmployeeOtherReason: null,
                changeEmployeeNote: null
              })
            }
          } else {
            values.original_end_date = values.job_end_date
            values.job_type = 'single'
            const response = await jobService.add(values)

            if (response.id) {
              let extraLogs = []

              extraLogs.push(`Client to "${clientInfo.id ? `ID ${clientInfo.id} - ` : ''}${clientInfo.first_name} ${clientInfo.last_name}"`)

              if (values.employee_id) {
                const newEmployeeName = await this.getEmployeeName(values.employee_id)
                extraLogs.push(` Employee to "${values.employee_id ? `ID ${values.employee_id} - ` : ''}${newEmployeeName}"`)
              }

              if (values.funder_id !== undefined) {
                extraLogs.push(` Funder to "${values.funder_id ? `ID ${values.funder_id} - ` : ''}${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== undefined) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(` Shift Type to "${name}"`)
              }

              if (values.payroll !== undefined) {
                const newPayroll = this.getPayrollName(values.payroll)
                extraLogs.push(` Carer Skill Level to "${newPayroll}"`)
              }

              if (validator.isNotEmptyArray(values.sleepover_list)) {
                const newSOList = await this.getSleepoverDifferenceText([], values.sleepover_list)
                extraLogs.push(newSOList)
              }

              log.addJob(
                response.id,
                values,
                ['is_updated', 'job_hours', 'employee_id', 'funder_id', 'client_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'sleepover', 'job_type', 'original_end_date', 'client_funder_id', 'sleepover_list', 'is_sleepover_job'],
                extraLogs.join(),
                [
                  { key: 'custom_so_start_time', label: 'Custom S/O Start Time' },
                  { key: 'custom_so_end_time', label: 'Custom S/O End Time' },
                  { key: 'job_kms', label: 'Max KM', isNullRecorded: true },
                  { key: 'kms', label: 'Recorded KM', isNullRecorded: true }
                ]
              )

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList)) {
                await this.onSaveUploadFiles(response.id)
              }
              // ------ append file end -------

              notify.success('Saved successfully', 'Job saved successfully.')
              history.replace(`/jobs/single/${response.id}`)

              this.cacheEmployee(values.employee_id)

              // add a new flag to indicate the refresh is redirected from add job page
              this.refreshPage(true)
            }
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.props.setRefreshActivityLog(true)
      } else {
        this.showErrorNotification('error')
      }
    })
  }

  onSaveUploadFiles = async (jobId) => {
    const { fileList, mainFileCategoryList, subFileCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = mainFileCategoryList.find(e => e.id === file.main_category_id)
      const subcat = subFileCategoryList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jobService.addFile(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  logJobUpdate = async (response, item, values, extraExclude = [], isUpdateAlert = false) => {
    const {
      changeEmployeeReason,
      changeEmployeeReasonType,
      changeEmployeeOtherReason,
      changeEmployeeNote,
      clientId,
      currentEmpInfo,
      currentFunderInfo,
      extSleepoverList,
      funderInfo,
      itemOri,
      isCustomSO,
      isEmployeePending,
      isExtSleepover,
      prevEmpInfo,
      prevFunderInfo,
      rateSetInfo
    } = this.state

    let extraLogs = []

    const defaultExclude = [
      'is_updated', 'job_hours', 'employee_id', 'funder_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'client_funder_id', 'sleepover_list', 'is_sleepover_job', 'custom_so_start_time', 'custom_so_end_time', 'is_custom_so_time', 'sleepover_type', 'is_extending_job',
      'cancellation_reason', 'cancellation_time', 'cancellation_other_reason', 'cancellation_type', 'cancellation_penalty', 'change_employee_reason', 'change_employee_other_reason', 'change_employee_reason_type', 'change_employee_note', 'is_cancel'
    ]
    const exclude = validator.isNotEmptyArray(extraExclude) ? defaultExclude.concat(extraExclude) : defaultExclude

    if (prevEmpInfo.id && values.employee_id !== undefined && prevEmpInfo.id !== values.employee_id) {
      const oldEmployeeName = await this.getEmployeeName(prevEmpInfo.id)
      const newEmployeeName = await this.getEmployeeName(values.employee_id)
      extraLogs.push(`Employee changed from "${prevEmpInfo.id ? `ID ${prevEmpInfo.id} - ` : ''}${oldEmployeeName}" to "${values.employee_id ? `ID ${values.employee_id} - ` : ''}${newEmployeeName}"`)

      this.cacheEmployee(values.employee_id)

      log.updateJobExtra(response.id, 'employee change reason', `Employee changed to "${newEmployeeName}" with reason: ${changeEmployeeReasonType} - ${changeEmployeeReason} ${changeEmployeeOtherReason ? ` - ${changeEmployeeOtherReason}` : ''} ${changeEmployeeNote ? ` (${changeEmployeeNote})` : ''}`)
    } else if (!prevEmpInfo.id && values.employee_id !== undefined) {
      const newEmployeeName = await this.getEmployeeName(values.employee_id)
      extraLogs.push(`Employee changed from Pending to "${values.employee_id ? `ID ${values.employee_id} - ` : ''}${newEmployeeName}"`)

      this.cacheEmployee(values.employee_id)
    }

    if (values.funder_id !== itemOri.funder_id && values.funder_id !== undefined) {
      extraLogs.push(`Funder changed from "${itemOri.funder_id ? `ID ${itemOri.funder_id} - ` : ''}${prevFunderInfo.fullname}" to "${values.funder_id ? `ID ${values.funder_id} - ` : ''}${funderInfo.fullname}"`)

      this.cacheFunder(values.funder_id)
    }

    if (values.billing_category_id !== itemOri.billing_category_id && values.billing_category_id !== undefined) {
      const oldName = await this.getBillingCategoryName(itemOri.billing_category_id)
      const newName = await this.getBillingCategoryName(values.billing_category_id)

      if (oldName || newName) {
        extraLogs.push(`Shift Type changed from "${oldName}" to "${newName}"`)
      }
    }

    if (values.payroll !== itemOri.payroll && values.payroll !== undefined) {
      const oldPayroll = this.getPayrollName(itemOri.payroll)
      const newPayroll = this.getPayrollName(values.payroll)

      if (oldPayroll || newPayroll) {
        extraLogs.push(`Carer Skill Level changed from "${oldPayroll}" to "${newPayroll}"`)
      }
    }

    const SOListUpdates = await this.getSleepoverDifferenceText(itemOri.sleepover_list, values.sleepover_list)
    if (SOListUpdates) {
      extraLogs.push(`${SOListUpdates}`)
    }

    const logText = log.updateJob(
      response.id,
      item,
      values,
      exclude,
      extraLogs.join(),
      [
        { key: 'custom_so_start_time', label: 'Custom S/O Start Time' },
        { key: 'custom_so_end_time', label: 'Custom S/O End Time' },
        { key: 'is_cancel', label: 'Job Is Cancelled?' },
        { key: 'job_kms', label: 'Max KM' },
        { key: 'kms', label: 'Recorded KM' },
        { key: 'job_survey_feedback', label: 'Job Feedback' },
        { key: 'job_survey_feedback_text', label: 'Job Feedback Notes' }
      ]
    )

    if (isUpdateAlert) {
      let action = {
        module: 'job',
        module_id: response.id,
        action: 'job-status-update',
        action_detail: logText,
        previous_text: item.status,
        current_text: values.status
      }
      const actionLog = await jobActionLogService.add(action)

      if (values.status !== 'approved') {
        trigger.updateJobTimesheetUpdate(response.id, '', logText)
      }
    }
  }

  logValidateStatusUpdate = (prev = {}, curr = {}) => {
    const compare = log.generateItemChanges(prev, curr, undefined, undefined, [
      'client_id', 'emergency', 'emergency_invoice', 'emergency_pay', 'employee_id', 'funder_id',
      'is_cancel', 'job_end_date', 'job_kms', 'job_start_day', 'kms', 'payroll'
    ])

    return compare ? (prev.status === 'approved' && compare.length > 0) : false
  }

  /** ========== SECTION 4: modal functions ========== */
  /** Cancel confirm modal */
  getChargeHours = () => {
    const { settingsOthers = {} } = this.state
    const { form } = this.props
    const startDateTime = Moment(form.getFieldValue('job_start_date'))
    const endDateTime = Moment(form.getFieldValue('job_end_date'))

    const startDate = startDateTime.seconds(0).milliseconds(0)
    const endDate = endDateTime.seconds(0).milliseconds(0)
    const durations = Moment.duration(Math.abs(endDate.diff(startDate)))
    const diffHours = durations.asHours()

    var job_hours = Math.round(diffHours * 100) / 100

    return job_hours > settingsOthers.cancellation_charge_hour ? settingsOthers.cancellation_charge_hour : job_hours
  }

  getOriginalHours = () => {
    const { item } = this.state

    const start = Moment(item.job_start_date)
    const end = item.original_end_date ? Moment(item.original_end_date) : Moment(item.job_end_date)
    const durations = Moment.duration(Math.abs(start.diff(end)))
    const diffHours = durations.asHours()

    return diffHours
  }

  /** handle timesheet delete */
  handleDeleteTimesheet = async () => {
    const { item } = this.state

    try {
      this.setState({ loading: true })
      const r = await jobTimesheetService.remove(item.signed_timesheet_id, item.id)

      if (r && r.id) {
        if (r.errors) {
          notify.error('Unable to delete timesheet', r.message || 'Unable to remove the timesheet. Please try again later')
        } else {
          await this.fetchJob()
          log.updateJobExtra(item.id, 'update timesheet', `Timesheet with raw ID ${item.signed_timesheet_id} is removed.`)
          this.handleChangeTab('1')
          notify.success('Delete Timesheet Successfully', 'The timesheet is removed successfully.')
        }
      } else {
        notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')
      }
    } catch (e) {
      notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')
    }

    this.setState({ loading: false })
  }


  /** Employee Change modal */
  handleEmployeeChangeReason = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll([ 'change_employee_reason', 'change_employee_other_reason', 'change_employee_note', 'change_employee_reason_type' ],
      async (errors, values) => {

        if (!errors) {
          this.setState({
            showEmployeeReasonModal: false,
            changeEmployeeReason: values.change_employee_reason,
            changeEmployeeReasonType: values.change_employee_reason_type,
            changeEmployeeOtherReason: values.change_employee_other_reason,
            changeEmployeeNote: values.change_employee_note
          })
          this.setState({ loading: true })

          that.resetChangeEmployeeForm()
          //Proceed to save
          setTimeout(() => {
            this.onSaveJob(true)
          }, 500)
        }
      })
  }

  handleEmployeeCancelTypeChange = async (id, value) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReasonList: cancelReason.list || [], changeEmployeeReasonType: value.props.children })
  }

  /** EMG Employee confirm modal */
  handleEmployeeEmergency = async (isEmergency = false) => {
    const { form } = this.props
    const { employeeId, newEmployeeId, settingsOthers } = this.state

    if (isEmergency) {
      const isEmergency = form.getFieldValue('emergency')
      const isInvoiceEmergency = form.getFieldValue('emergency_invoice')
      const formStartDate = Moment(form.getFieldValue('job_start_date'))
      const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
      const differentHours = durationFromNow.asHours()
      const isPastDate = Moment().isAfter(formStartDate)
      const emergencyEmpDuration = settingsOthers.emergency_notice_emp

      form.setFieldsValue({
        emergency: true,
        emergency_pay: ((emergencyEmpDuration ? differentHours >= 0 && differentHours <= emergencyEmpDuration : true) || isPastDate),
        emergency_invoice: !isEmergency ? false : (isInvoiceEmergency || false),
        employee_id: newEmployeeId
      })

      this.setState({ employeeId: newEmployeeId })
      this.checkEmployee(newEmployeeId)
    } else {
      form.setFieldsValue({ newEmployeeId: employeeId, emergency_pay: false })
    }

    this.setState({ showEmergencyModal: false })
  }

  handleEmployeeCancel = async () => {
    const { form } = this.props
    const { employeeId } = this.state
    form.setFieldsValue({ employee_id: employeeId })
    this.checkEmployee(employeeId)
    this.setState({ showEmergencyModal: false })
  }

  /** Funder change modal */
  handleFunderSubmit = async () => {
    const { clientId, funderInfo, funderId: currrentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    /** TODO: review on the function for fvp */
    validateFieldsAndScroll(['funder_id'], async (errors, values) => {
      if (currrentFunderId !== parseInt(values.funder_id)) {
        this.setState({ funderInfo: {}, funderId: null, showFunderModal: false })
        form.setFieldsValue({ billing_category_id: undefined })
        this.fetchFunders(clientId, values.funder_id)
      }
    })
  }

  handleFeedbackChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form

    if (!value) {
      setFieldsValue({ job_survey_feedback_text: null })
    }
  }

  triggerFunderModal = (showFunderModal) => {
    this.setState({ showFunderModal })
  }

  /** Job side modal */
  handleCancelTypeChange = async (id) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReasonList: cancelReason.list || [] })
  }

  handleCancelReasonChange = async (value) => {
    if (value === 'Other') {
      this.setState({ isShowCancelOtherField: true })
    } else {
      this.setState({ isShowCancelOtherField: false })
    }
  }

  triggerCancelModal (showCancelModal) {
    if (this.state.loading) return
    this.setState({ showCancelModal })
  }

  /** Upload File Modal */
  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  /** Communication */
  handleBadgeCount = (value) => {
    this.setState({ unsentCommTotal: value })
    this.props.setRefreshActivityLog(true)
  }

  /** ========== SECTION 5: UI Control functions ========== */
  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state

    confirm({
      title: 'Funding Period Not Defined!',
      content: <div><p>Please assign a funding period for this funder before proceeding.</p> <p>Click <b>Go to Client Page</b> to be redirected to the client page or <b>Continue</b> to choose another Funder.</p></div>,
      okText: 'Go To Client Page',
      cancelText: 'Continue',
      onOk () {
        history.replace(`/clients/${clientId}`)
      },
      onCancel () {
        // this.setState({ showFunderModal: true })
      }
    })
  }

  getScheduleDay = () => {
    let jobStartDate
    if (this.isEdit()) {
      const { item } = this.state
      jobStartDate = item.job_start_date
    } else {
      const { form } = this.props
      jobStartDate = form.getFieldValue('job_start_date')
    }
    return jobStartDate && Moment(jobStartDate).isValid() ? `on ${Moment(jobStartDate).format('DD/MM/YYYY')}, ${Moment(jobStartDate).format('dddd')}` : ''
  }

  handleAddGetup = () => {
    const { extSleepoverList } = this.state
    extSleepoverList.push({ id: null, type: 'getup' })

    this.setState({ extSleepoverList })
  }

  handleBillingSelectCategory (value, option) {
    /**TODO: fvp revamp */
    // this.setState({ showGetupHours: option.props.children.indexOf('Sleepover') > -1 })
    if (typeof value === 'number') {
      this.setState({ cachedBillingCategoryId: value })
    }
  }

  handleChangePayroll = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleChangeTab (tabActiveKey) {
    this.setState({ tabActiveKey })
  }

  handleDateStartChange = async (date) => {
    const { form } = this.props
    const { clientId, item: job } = this.state
    const jobHours = job.job_hours || 2

    const jobStartDateTime = date
    const jobEndDateTime = form.getFieldValue('job_end_date')
    const newJobEndDateTime = Moment(jobStartDateTime).add(jobHours, 'hours')
    const durationsHours = jobEndDateTime ? jobEndDateTime.diff(jobStartDateTime, 'hours') : newJobEndDateTime.diff(jobStartDateTime, 'hours')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    this.setState({ itemCurrentTotalHours: durationsHours })

    // Update job end time to field
    if (!jobEndDateTime) {
      form.setFieldsValue({ job_end_date: newJobEndDateTime })
    }

    // Display the shift time breakdown
    this.showBreakdown(jobStartDateTime, jobEndDateTime || newJobEndDateTime)

    this.checkEmergencyJob(jobStartDateTime)
    this.checkJobLessThan2Hrs(jobStartDateTime, jobEndDateTime || newJobEndDateTime)

    /* fvp added functions */
    this.checkCategoriesAndUpdate(jobStartDateTime)
    this.checkSleepoverMismatch()
    this.checkClientCurrentFunding(date)

    this.checkClient(clientId, jobStartDateTime, jobEndDateTime || newJobEndDateTime, true)

    if (typeof selectedEmployeeId === 'number' && jobStartDateTime !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jobStartDateTime, jobEndDateTime || newJobEndDateTime)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, jobStartDateTime, jobEndDateTime || newJobEndDateTime)
    }
  }

  handleDateEndChange = async (date) => {
    const { form } = this.props
    const { clientId } = this.state
    const startDateTime = form.getFieldValue('job_start_date')

    const jobStartDateTime = Moment(startDateTime)
    const jobEndDateTime = date
    const selectedEmployeeId = form.getFieldValue('employee_id')

    const durationsHours = jobEndDateTime.diff(jobStartDateTime, 'hours')

    this.setState({ itemCurrentTotalHours: durationsHours })

    // Display the shift time breakdown
    this.showBreakdown(jobStartDateTime, jobEndDateTime)

    // this.checkEmergencyJob(jobStartDateTime)
    this.checkJobLessThan2Hrs(jobStartDateTime, jobEndDateTime)

    this.checkClient(clientId, jobStartDateTime, jobEndDateTime, true)

    this.checkSleepoverMismatch()

    if (typeof selectedEmployeeId === 'number' && jobStartDateTime !== null && jobEndDateTime !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jobStartDateTime, jobEndDateTime)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, jobStartDateTime, jobEndDateTime)
    }
  }

  handleEditButton = () => {
    this.setState({ isShowSave: true })
  }

  handleEmergencyClick = (value) => {
    const { form } = this.props
    const { settingsOthers } = this.state
    const formStartDate = Moment(form.getFieldValue('job_start_date'))

    this.setState({ isEmergency: !!value })

    if (value === false) {
      setTimeout(() => {
        form.setFieldsValue({ emergency_pay: value, emergency_invoice: value })
      }, 200)
    } else {
      // Check if is emergency pay or emergency invoice
      const emergencyDuration = settingsOthers.emergency_notice
      const emergencyEmpDuration = settingsOthers.emergency_notice_emp
      const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
      const differentHours = durationFromNow.asHours()

      setTimeout(() => {
        if (emergencyEmpDuration) {
          form.setFieldsValue({
            emergency_pay: differentHours >= 0 && differentHours <= emergencyEmpDuration,
            emergency_invoice: differentHours >= 0 && differentHours <= emergencyDuration
          })
        } else {
          form.setFieldsValue({
            emergency_pay: value,
            emergency_invoice: value
          })
        }
      }, 200)
    }
  }

  handleEmergencyInvoiceClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Invoice?',
        content: 'Not Charging Client Emergency Loading?',
        onOk () {
          form.setFieldsValue({ emergency_invoice: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_invoice: true })
        }
      })
    }
  }

  handleEmergencyPayClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Pay?',
        content: 'Not Paying Employee Emergency Rate?',
        onOk () {
          form.setFieldsValue({ emergency_pay: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_pay: true })
        }
      })
    }
  }

  handleEmergencyYes = ({ emgPay = false, emgInv = false }) => {
    const { form } = this.props
    const { settingsOthers } = this.state
    form.setFieldsValue({ emergency: true })
    this.setState({ isEmergency: true, isEmergencyTime: false })

    setTimeout(() => {
      form.setFieldsValue({ emergency_pay: emgPay, emergency_invoice: emgInv })
    }, 200)
  }

  handleEmergencyNo = () => {
    const { form } = this.props
    form.setFieldsValue({ emergency: false, emergency_pay: false, emergency_invoice: false })
    this.setState({ isEmergency: false, isEmergencyTime: false })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item && item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  handleEmployeeChange = async (value) => {
    const { form } = this.props
    const { clientId, settingsOthers } = this.state
    const formStartDate = Moment(form.getFieldValue('job_start_date'))
    // Check if it is emergency
    const emergencyDuration = settingsOthers.emergency_notice
    const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
    const differentHours = durationFromNow.asHours()
    const isPastDate = Moment().isAfter(formStartDate)

    this.checkEmployee(value, true)
    this.setState({ newEmployeeId: value })

    if (!this.isEdit()) {
      return
    }

    if ((differentHours >= 0 && differentHours <= emergencyDuration) || isPastDate) {
      setTimeout(() => {
        this.setState({ isEmergency: true, showEmergencyModal: true, newEmployeeId: value })
      }, 1000)
    }
  }

  handlePendingEmployee = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({
        isEmployeePending: true,
        isEmployeeOnLeave: false,
        isEmployeeJobConflict: false,
        isMatchLanguage: true,
        isMatchSkill: true,
        employeeExpiredFiles: [],
        employeeJobHoursInfo: {}
      })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ isEmployeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleSleepoverCustomSO = (value) => {
    const { item, rateSetInfo } = this.state
    const { form } = this.props
    if (value) {
      if (item.custom_so_start_time) {
        form.setFieldsValue({custom_so_start_time: Moment(item.custom_so_start_time)})
      }

      if (item.custom_so_end_time) {
        form.setFieldsValue({custom_so_end_time: Moment(item.custom_so_end_time)})
      }
    } else {
      if (rateSetInfo && rateSetInfo.id) {
        form.setFieldsValue({custom_so_start_time: Moment(rateSetInfo.so_hours_start), custom_so_end_time: Moment(rateSetInfo.so_hours_end) })
      }
    }

    this.setState({ isCustomSO: value })
    this.checkSleepoverMismatch()
  }

  handleSleepover = (value, isShowSleepoverAlert = false, isUpdate = false) => {
    const { form } = this.props
    const { item, billingCategoryList, cachedBillingCategoryId, isExtSleepover } = this.state
    const that = this

    const ct = billingCategoryList.find(e => e.is_sleepover === true)
    const catId = item.billing_category_id || cachedBillingCategoryId
    const isPreviouslySOCat = ct && ct.id && ct.id === catId

    if (isShowSleepoverAlert && !isExtSleepover) {
      confirm({
        title: 'Is This A Sleepover Job?',
        content: `This job has more than 4 After Hours.`,
        okText: 'Yes',
        cancelText: 'Not Sleepover',
        onOk () {
          if (ct && ct.id) {
            form.setFieldsValue({ billing_category_id: ct.id })

            that.setState({ isExtSleepover: true, isShowSleepoverAlert, textSleepoverMismatch: '' })
          }
        },
        onCancel () {
          /**
           * to revert the previous billing category if cancel the selection on sleepover, two ways to do so:
           * 1. for saved jobs, assign back the previous billing category id
           * 2. for new jobs, new value "cachedBillingCategoryId" applied. this will be set when shift type is selected
           *
           * the shift type will only be empty when
           * 1. change funder
           * 2. new jobs - no shift type is previously selected
           * 3. previously job shift type is sleepover
           * */
          form.setFieldsValue({ billing_category_id: isPreviouslySOCat ? undefined : item.billing_category_id ? item.billing_category_id : cachedBillingCategoryId ? cachedBillingCategoryId : undefined })
          that.setState({ isExtSleepover: false,  isShowSleepoverAlert, textSleepoverMismatch: '' })
        }
      })
    } else {
      if (value === false) {
        if (isUpdate) {
          form.setFieldsValue({ billing_category_id: isPreviouslySOCat ? undefined : item.billing_category_id ? item.billing_category_id : cachedBillingCategoryId ? cachedBillingCategoryId : undefined })
        }
      } else {
        if (ct && ct.id) {
          form.setFieldsValue({ billing_category_id: ct.id })
        }
      }

      this.setState({ isExtSleepover: value, isShowSleepoverAlert, textSleepoverMismatch: '' })
    }

    setTimeout(() => {
      this.showBreakdown(form.getFieldValue('job_start_date'), form.getFieldValue('job_end_date'))
      this.checkSleepoverMismatch()
    }, 100)


    // setTimeout(() => {
    //   this.checkExtendedDuration()
    // }, 200)
  }

  handleSleepoverItemDelete = (index) => {
    const { extSleepoverList } = this.state

    if (extSleepoverList.length > index) {
      const so = extSleepoverList[index]
      if (so && so.id) {
        so.is_delete = true
      } else {
        extSleepoverList.splice(index, 1)
      }
    }

    this.setState({ extSleepoverList })
  }

  handleSleepoverItemUndoDelete = (index) => {
    const { extSleepoverList } = this.state

    if (extSleepoverList.length > index) {
      const so = extSleepoverList[index]
      if (so && so.id && so.is_delete) {
        so.is_delete = false
      }
    }

    this.setState({ extSleepoverList })
  }

  handleSleepoverItemDisableMinutes = (type, index) => {
    const { form } = this.props
    const std = form.getFieldValue(`${type}${index}`)

    if (std) {
      if (std.format('mm') === '00' || std.format('mm') === '30') {
        return [15, 45]
      } else {
        return [0, 30]
      }
    } else {
      return []
    }
  }

  resetCancelForm = () => {
    const { form } = this.props
    this.setState({ showCancelModal: false, isShowCancelOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({
        cancellation_reason: undefined,
        cancellation_type: undefined,
        cancellation_other_reason: undefined,
        cancellation_note: undefined
      })
    }, 1000)
  }

  resetChangeEmployeeForm = async () => {
    const { form } = this.props
    this.setState({ showEmployeeReasonModal: false, isShowCancelOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({
        change_employee_reason: undefined,
        change_employee_reason_type: undefined,
        change_employee_other_reason: undefined,
        change_employee_note: undefined
      })
    }, 1000)
  }

  showBreakdown = async (start, end) => {
    const { isExtSleepover, item, funderId } = this.state
    const isHoliday = await this.checkHoliday(start)
    const isNextHoliday = await this.checkHoliday(end)
    const breakdown = new DurationBreakdown(item.funder_id || funderId, isHoliday, isNextHoliday, isExtSleepover)
    const { breakdown: textDurationBreakdown, hours: textDurationHours } = await breakdown.get(start, end)

    this.setState({ textDurationBreakdown, textDurationHours })
  }

  showEmergencyConfirm = ({ emgPay, emgInv, emgHours }) => {
    const { handleEmergencyNo, handleEmergencyYes } = this
    confirm({
      title: 'Emergency Job?',
      content: `Shift Start In Less Than ${emgHours} Hours`,
      okText: 'Yes',
      cancelText: 'Not EMG',
      onOk () {
        handleEmergencyYes({ emgPay, emgInv })
      },
      onCancel () {
        handleEmergencyNo()
      }
    })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Job Time Error',
      description: 'End Time should not be earlier than Start Time',
      top: 130
    })
  }

  showInvalidFunderNotification = () => {
    error({
      title: `Invalid Funder`,
      content: <div><p>The funder is not set for this client.</p> <p>Please go to Client Page to update funder or update this job's funder in order to proceed to edit and save.</p></div>
    })
  }

  showNoFundingPeriodNotification = () => {
    warning({
      title: `Client has no Funding Period`,
      content: `The job date does not fall on any available funding period.`
    })
  }

  /** ========== SECTION 6: Misc functions ========== */
  cacheEmployee = async (employeeId) => {
    if (employeeId) {
      const emp = await this.getEmployee(employeeId)
      this.setState({ prevEmpInfo: Object.assign({}, emp), currentEmpInfo: Object.assign({}, emp), employeeId })
    }
  }

  cacheFunder = (funderId) => {
    if (funderId) {
      const funder = this.getFunder(funderId)
      this.setState({ prevFunderInfo: Object.assign({}, funder), currentFunderInfo: Object.assign({}, funder), funderId })
    }
  }

  filterEmployees = (input, option) => {
    const emp = `${option.props.children[0]} ${option.props.children[2]}`
    return emp.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterBillingCategory = (input, option) => {
    const { children } = option.props

    if (option.type.isSelectOptGroup) {
      return children.includes(child => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getBillingCategoryName = async (catId) => {
    const { billingCategoryList } = this.state
    const cat = billingCategoryList.find(e => e.id === parseInt(catId)) || {}

    if (cat && cat.id) {
      return `${cat.name}`
    } else {
      const cat2 = await fvpCategoriesService.get(catId)

      if (cat2 && cat2.item && cat2.item.id) {
        return `${cat2.item.name}`
      }
    }

    return ''
  }

  getCancelTypeName = (id) => {
    const { cancelTypeList } = this.state
    const type = cancelTypeList.filter((item) => item.id === id)
    return type ? type[0].name : ''
  }

  getEmployee = async (empId) => {
    const { employeeAllList } = this.state
    const emp = employeeAllList.find(e => e.id === parseInt(empId)) || {}

    if (emp && emp.id) {
      return emp
    } else {
      const emp2 = await employeeService.get(empId)
      if (emp2 && emp2.id) {
        return emp2
      }
    }
    return null
  }

  getEmployeeName = async (empId) => {
    const emp = await this.getEmployee(empId)
    return emp && emp.id ? `${emp.first_name} ${emp.last_name}` : ''
  }

  getFunder = (funderId) => {
    const { funderList } = this.state
    const funder = funderList.find(e => e.id === parseInt(funderId)) || {}
    return funder
  }

  getPayrollName = (value) => {
    const { settingsPayroll } = this.state
    const p = settingsPayroll.find(e => e.value === value) || {}
    return p && p.id ? `${p.name}` : ''
  }

  getSleepoverDifferenceText = async(oriSO = [], newSO = []) => {
    let newMsg = ''
    let updateMsg = ''
    let deleteMsg = ''
    for (let i = 0; i < oriSO.length; i++) {
      const s = oriSO[i]
      const ns = newSO.find(e => e.id === s.id)

      const oldCatName = await this.getBillingCategoryName(s.category_id)
      const newCatName = await this.getBillingCategoryName(ns.category_id)
      if (ns && ns.id) {
        if (ns.is_delete) {
          deleteMsg += `${deleteMsg ? ', ' : ''}${`Getup between ${Moment(ns.start_datetime).format('hh:mm A')} to ${Moment(ns.end_datetime).format('hh:mm A')} with ${oldCatName}.`}`
        } else {
          const catItemChange = parseInt(s.category_id) === parseInt(ns.category_id) ? '' : `getup category from ${oldCatName} to ${newCatName}`
          const logChange = log.generateItemChanges(s, ns, ['category_id'])
          if (logChange || catItemChange) {
            updateMsg += `${updateMsg && (logChange || catItemChange) ? ', ' : ''}Getup Item No. ${i+1}: ${logChange}${logChange && catItemChange ? ', ': ''}${catItemChange}`
          }
        }
      }
    }

    const newSO2 = newSO.filter(e => !e.id)

    for (let i = 0; i < newSO2.length; i++) {
      const s = newSO2[i]
      const newCatName = await this.getBillingCategoryName(s.category_id)

      const logChange = `${i + 1}. Getup between ${Moment(s.start_datetime).format('hh:mm A')} to ${Moment(s.end_datetime).format('hh:mm A')} with ${newCatName}${s.notes ? ` and notes "${s.notes}"` : ''}`

      newMsg += `${newMsg && (logChange) ? ', ' : ''}${logChange}`
    }

    return newMsg || updateMsg || deleteMsg ? `Getup List: ${newMsg ? `New getup items: ${newMsg}` : ''}${newMsg && (updateMsg || deleteMsg) ? ', ' : ''}${updateMsg ? `Updated getup items: ${updateMsg}` : ''} ${(newMsg || updateMsg) && deleteMsg ? `, ` : ''}${deleteMsg ? `Deleted getup items: ${deleteMsg}` : ''}` : ''
  }

  async refreshPage (isRedirectedFromNewJob = false) {
    this.fetchJob()
    const jobId = this.getJobId()
    await this.fetchUnsentEmail(jobId, isRedirectedFromNewJob)

    setTimeout(() => {
      this.checkEmail()
    }, 600)

    this.fetchCancel()

    this.props.setRefreshActivityLog(true)
    this.props.setRefreshCommLog(true)
  }

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }

  replacer = (value) => {
    if (value) {
      let str = value
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return null
  }
}


const mapDispatchToProps = {
  fetchingJobs,
  fetchTotalPending,
  setRefreshCommLog,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Job))

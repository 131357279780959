import React, { Component } from 'react'

// UI
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Popconfirm from 'antd/lib/popconfirm'

import './styles.css'

export class SettingBillingRateRow extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  onChangeValue (day, value) {
    const { item } = this.props

    this.props.onChange(item.id, day, value)
    return value
  }

  render () {
    const { form, item, onDelete } = this.props
    const { getFieldDecorator } = form

    const rateProps = (field, value = '') => (getFieldDecorator(field, {
      getValueFromEvent: (e) => this.onChangeValue(field, e.target.value),
      initialValue: value
    })(
      <Input className='billing-rate-set-field' placeholder='0.00' />
    ))

    const normalHours = item.rates.filter(r => r.day.indexOf('n') > -1).sort((x, y) => x.day - y.day)
    const afterHours = item.rates.filter(r => r.day.indexOf('a') > -1).sort((x, y) => x.day - y.day)

    return (
      <div style={{ margin: '5px 0' }}>
        <Row gutter={10}>
          <Col lg={3}>
            <div className='billing-rate-category-name'>{ item.name }</div>
          </Col>
          <Col lg={10}>
            <Row gutter={4}>
              {
                normalHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {rateProps(rate.day, rate.value)}
                  </Col>
                })
              }
            </Row>
          </Col>
          <Col lg={10}>
            <Row gutter={4}>
              {
                afterHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {rateProps(rate.day, rate.value)}
                  </Col>
                })
              }
            </Row>
          </Col>
          <Col lg={1}>
            <Popconfirm
              title='Confirm to remove this?'
              onConfirm={(e) => onDelete(item.id)}
              okText='Yes'
              cancelText='No'
              placement='left'
            ><div className='billing-rate-remove-row'><Icon type='delete' /></div>
            </Popconfirm>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (Form.create()(SettingBillingRateRow))

import React, { Component } from 'react'
import moment from 'moment-timezone'
import ReportMenu from '../../../constants/menu/report'
import { authService, fvpClientBudgetService } from '../../../services'
import { exportFile, formatter, validator } from '../../../util'
import { timezone } from '../../../config'
// UI
import { Button, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
moment.tz.setDefault(timezone)

const pageSize = 20
const dateFormat = 'DD/MM/YYYY'

const DefaultSort = { client_name: 1 }

export class ReportClientBudget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      budgetList: [],
      currentPage: 1,
      filter: { active: { condition: '=', value: true } },
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort),
      total: 0,
      settings: {},
    }
  }

  componentDidMount () {
    const { currentPage, filter, isLoaded, loading, sort } = this.state
    this.fetchClientBudgetReport({currentPage, filter, isLoaded, loading, sort})
    this.fetchOtherSettings()
  }

  render () {
    const { budgetList, currentPage, filter, loading, loadingExport, loadingList, sort, total, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    const columns = [
      {
        title: 'Clients',
        width: 4,
        render: ({ client_id, client_name, client_active }) => (
          <div className='font-small'>
            <a href={`/clients/${client_id}`} target='_blank'>
              <span style={{color: client_active ? '#222' : '#ccc'}}><Icon type='user' /> {client_name}</span>
            </a>
          </div>
        ),
        key: 'client_name',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Budget Period',
        width: 10,
        render: ({ active, start_date, end_date, period_day_to_now, upcoming_day_to_now, period_day_interval, period_progress, upcoming_progress }) => {
          const isBudgetActive = !!active
          const std = moment(start_date).format(dateFormat)
          const etd = moment(end_date).format(dateFormat)
          const isExpired = period_day_to_now > period_day_interval
          const isExpiredUpcoming = upcoming_day_to_now > period_day_interval
          const isExpiring = parseFloat(period_progress) >= 80

          const periodText = `${isExpired ? `Expired` : `${period_progress}%`}`
          const upcomingText = `${isExpired ? `Expired` : `${upcoming_progress}%`}`

          return (
            <div className='font-small' style={{color: isExpiring ? 'red' : '#222', fontSize: '11px', fontWeight: isExpiring ? 'bold' : undefined}}>
              <div>{`${std} - ${etd}`}</div>
              <div>
                <Tooltip mouseLeaveDelay={0} title={<div><div>Current Progress: {periodText}</div><div>Upcoming Progress: {upcomingText}</div></div>}>
                  <Icon type='question-circle' style={{color: '#bbb'}} />
                </Tooltip>
                &nbsp;{`(${periodText})`}
              </div>
            </div>
          )
        }
      },
      {
        title: 'Funders',
        width: 2,
        render: ({ funder_id, funder_fullname }) => {
          return (
            <div className='font-small'>
              <a href={`/funders/${funder_id}`} target='_blank'>
                <div>{ funder_fullname }</div>
              </a>
            </div>
          )
        },
        key: 'funder_fullname',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Total Budget',
        width: 2,
        render: ({ budget_value }) => {
          return (
            <div className='font-small'>{formatter.toPrice(budget_value)}</div>
          )
        },
        key: 'budget_value',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Current Job Spending',
        width: 2,
        render: ({ remaining_upcoming_percentage, subtotal_up_to_now_job, subtotal_up_to_now_job_percentage }) => {
          const isExceding = parseFloat(remaining_upcoming_percentage) <= 25

          return (
            <div className='font-small' style={{color: isExceding ? 'red' : '#222', fontWeight: isExceding ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_up_to_now_job)}</div>
              <div>{`(${subtotal_up_to_now_job_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_up_to_now_job_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Current KM Spending',
        width: 2,
        render: ({ remaining_upcoming_percentage, subtotal_up_to_now_kms, subtotal_up_to_now_kms_percentage }) => {
          const isExceding = parseFloat(remaining_upcoming_percentage) <= 25

          return (
            <div className='font-small' style={{color: isExceding ? 'red' : '#222', fontWeight: isExceding ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_up_to_now_kms)}</div>
              <div>{`(${subtotal_up_to_now_kms_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_up_to_now_kms_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Upcoming Job Spending',
        width: 2,
        render: ({ remaining_upcoming_percentage, subtotal_upcoming_job, subtotal_upcoming_job_percentage }) => {
          const isExceding = parseFloat(remaining_upcoming_percentage) <= 25

          return (
            <div className='font-small' style={{color: isExceding ? 'red' : '#222', fontWeight: isExceding ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_upcoming_job)}</div>
              <div>{`(${subtotal_upcoming_job_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_upcoming_job_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Projected KM Spending',
        width: 2,
        render: ({ subtotal_projected_upcoming_kms, subtotal_projected_upcoming_kms_percentage, remaining_projected_percentage }) => {
          const p = parseFloat(remaining_projected_percentage)
          const isOverProject = p <= 0 ? 2 : p <= 10 ? 1 : 0

          return (
            <div className='font-small' style={{color: isOverProject === 2 ? 'red' : isOverProject === 1 ? 'orange' : '#222', fontWeight: isOverProject > 0 ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_projected_upcoming_kms)}</div>
              <div>{`(${subtotal_projected_upcoming_kms_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_projected_upcoming_kms_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Upcoming Balance',
        width: 2,
        render: ({ remaining_upcoming_percentage, remaining_upcoming }) => {
          const isExceding = parseFloat(remaining_upcoming_percentage) <= 25

          return (
            <div className='font-small' style={{color: isExceding ? 'red' : '#222', fontWeight: isExceding ? 'bold' : undefined}}>
              <div>{formatter.toPrice(remaining_upcoming)}</div>
              <div>{`(${remaining_upcoming_percentage}%)`}</div>
            </div>
          )
        },
        key: 'remaining_upcoming_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Projected Total Job Spending',
        width: 2,
        render: ({ subtotal_projected_job, subtotal_projected_job_percentage, remaining_projected_percentage }) => {
          const p = parseFloat(remaining_projected_percentage)
          const isOverProject = p <= 0 ? 2 : p <= 10 ? 1 : 0

          return (
            <div className='font-small' style={{color: isOverProject === 2 ? 'red' : isOverProject === 1 ? 'orange' : '#222', fontWeight: isOverProject > 0 ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_projected_job)}</div>
              <div>{`(${subtotal_projected_job_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_projected_job_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Projected Total KM Spending',
        width: 2,
        render: ({ subtotal_projected_kms, subtotal_projected_kms_percentage, remaining_projected_percentage }) => {
          const p = parseFloat(remaining_projected_percentage)
          const isOverProject = p <= 0 ? 2 : p <= 10 ? 1 : 0

          return (
            <div className='font-small' style={{color: isOverProject === 2 ? 'red' : isOverProject === 1 ? 'orange' : '#222', fontWeight: isOverProject > 0 ? 'bold' : undefined}}>
              <div>{formatter.toPrice(subtotal_projected_kms)}</div>
              <div>{`(${subtotal_projected_kms_percentage}%)`}</div>
            </div>
          )
        },
        key: 'subtotal_projected_kms_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
      {
        title: 'Projected Balance',
        width: 2,
        render: ({ remaining_projected, remaining_projected_percentage }) => {
          const p = parseFloat(remaining_projected_percentage)
          const isOverProject = p <= 0 ? 2 : p <= 10 ? 1 : 0

          return (
            <div className='font-small' style={{color: isOverProject === 2 ? 'red' : isOverProject === 1 ? 'orange' : '#222', fontWeight: isOverProject > 0 ? 'bold' : undefined}}>
              <div>{formatter.toPrice(remaining_projected)}</div>
              <div>{`(${remaining_projected_percentage}%)`}</div>
            </div>
          )
        },
        key: 'remaining_projected_percentage',
        onSort: (key, order) => this.handleSort(key, order)
        // onSortItem: (sort) => this.handleSortItem(sort)
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Client's Budget Report`}>
            { this.hasAccess('readClientBudgetReport')
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }
          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('client_budget_email', {
                          initialValue: settings.client_budget_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <div className='report'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div>
                  <div className='report-title'>{`Client's Budget `} <span className='report-tag'>{total}</span></div>
                  <List cols={columns} rows={budgetList} />

                  <Pager
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} budget`}
                    current={currentPage}
                    onChange={(e) => this.changePage(e)}
                    style={{ marginTop: '15px' }}
                  />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchReport () {
    const { currentPage, filter, isLoaded, loading, sort } = this.state
    this.fetchClientBudgetReport({currentPage, filter, isLoaded, loading, sort})
  }

  async fetchClientBudgetReport ({ currentPage, filter = {}, sort = {}, isLoaded = true, loading }) {
    if (this.hasAccess('readClientBudgetReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const { list: budgetList, total } = await fvpClientBudgetService.listByPage(currentPage, pageSize, filter, sort)

        this.setState({ budgetList, loading: false, loadingList: false, filter, sort, isLoaded: true, total })
      } catch (e) {
        notify.error('Unable to get Client Budget Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleSort (key, order) {
    const { filter } = this.state
    let newSort = {}

    if (order === 0) {
      newSort = Object.assign({}, DefaultSort)
    } else {
      newSort = Object.assign({}, {[key]: order})
    }

    this.fetchClientBudgetReport({ currentPage: 1, filter, isLoaded: true, sort: newSort })
  }

  handleSortItem (sort) {
    const { filter } = this.state

    let newSort = Object.assign({}, sort)
    for (let s in newSort) {
      if (newSort[s] === 0) {
        delete newSort[s]
      }
    }

    if (!validator.isNotEmptyObject(newSort)) {
      newSort = Object({}, DefaultSort)
    }

    this.fetchClientBudgetReport({ currentPage: 1, filter, isLoaded: true, sort: newSort })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readClientBudgetReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchExport('client-budget-export')
      this.setState({ loadingExport: false })
    }, 4000)
  }

  changePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportClientBudget))

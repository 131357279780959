export const FETCH_FEEDBACKS = 'FETCH_FEEDBACKS'
export const FETCHING_FEEDBACKS = 'FETCHING_FEEDBACKS'
export const FEEDBACKS_FETCHED = 'FEEDBACKS_FETCHED'
export const REFRESH_FEEDBACK_ACTIONS = 'REFRESH_FEEDBACK_ACTIONS'

export const fetchFeedbacks = (params) => {
  return Object.assign({
    type: FETCH_FEEDBACKS
  }, params)
}

export const fetchingFeedbacks = (loading) => {
  return {
    type: FETCHING_FEEDBACKS,
    loading
  }
}

export const feedbacksFetched = (feedbacks) => {
  return {
    type: FEEDBACKS_FETCHED,
    feedbacks
  }
}

export const setRefreshFeedbackActions = (done) => {
  return {
    type: REFRESH_FEEDBACK_ACTIONS,
    done
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { fetchJobRecurringList, fetchJobSummary } from '../../../../states/actions'
import { JobJvpMenu, JobListType, JobFilterType } from '../../../../constants'
import { authService } from '../../../../services'
import { common, validator } from '../../../../util'
import { timezone } from '../../../../config'
import moment from 'moment-timezone'
import AddJobModal from '../../AddJobModal'
import RecurringJobView from '../View'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'

import '../../styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 20

moment.tz.setDefault(timezone)

const getFilterObject = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  value = value ? value.trim() : value

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          filter.$and.push({
            $or: [
              { client_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { employee_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { funder_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_recurring_start_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_recurring_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { status_sleepover: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }

      if (value && value.trim() !== '') {
        filter.$and.push({
          $or: [
            { client_fullname: { condition: 'ilike', value: `%${value}%` } },
            { employee_fullname: { condition: 'ilike', value: `%${value}%` } },
            { funder_fullname: { condition: 'ilike', value: `%${value}%` } },
            { string_recurring_start_date: { condition: 'ilike', value: `%${value}%` } },
            { string_recurring_start_day: { condition: 'ilike', value: `%${value}%` } },
            { tasks: { condition: 'ilike', value: `%${value}%` } },
            { client_suburb: { condition: 'ilike', value: `%${value}%` } },
            { status_sleepover: { condition: 'ilike', value: `%${value}%` } }
          ]
        })
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

const redirectUrl = (props, query) => {
  const { history, location } = props
  const params = new URLSearchParams(query)

  history.replace({ pathname: location.pathname, search: params.toString() })
}

class JobRecurringList extends Component {
  constructor (props) {
    super(props)
    const { location } = this.props
    const { page = 1, q = '' } = common.getQueryStringSearchParams(location.search)
    const { key = undefined } = location

    this.state = {
      currentPage: page ? Number(page) : 1,
      filter: {},
      isAddJobModal: false,
      isLoaded: false,
      isSearching: false,
      jobFilterType: null,
      jobSummary: {},
      list: [],
      loadingList: false,
      loadingSummary: false,
      searchText: q || '' ,
      total: 0,
      currentTS: Date.now(),
      pageKey: key,
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobList: { list, total }, jobSummary = {}, loadingList, loadingSummary, isLoaded, fetchJobRecurringList, fetchJobSummary, match, location } = nextProps
    const { type = JobFilterType.JOB_TYPE_ACTIVE } = common.getPath(match)
    const { page = 1, q } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterObject(prevState.filter, q)

    const currentPage = page ? Number(page) : prevState.currentPage

    if (prevState.jobFilterType !== null && prevState.jobFilterType !== type) {
      redirectUrl(nextProps, { page: 1, q: '' })
      fetchJobRecurringList({ loading: true, isForceReload: true, currentPage: 1, pageSize, filter, sort: {}, searchText: filter.$and ? '' : prevState.searchText, filterType: type })
      fetchJobSummary({ loadingSummary: true })
    }

    const state = {
      ...prevState,
      currentPage,
      filter,
      isLoaded,
      isSearching: prevState.loadingList === true && loadingList === false ? false : prevState.isSearching,
      jobFilterType: type,
      jobSummary,
      list,
      loadingList,
      loadingSummary,
      searchText: q ? q : prevState.searchText,
      total
    }

    return state
  }

  componentDidMount () {
    this.fetchDataList()
    this.fetchJobSummary()
  }

  getTitle () {
    const { jobFilterType } = this.state

    if (jobFilterType === JobFilterType.JOB_TYPE_ACTIVE) {
      return 'Active Recurring Jobs'
    } else if (jobFilterType === JobFilterType.JOB_TYPE_ENDED) {
      return 'Ended Recurring Jobs'
    } else if (jobFilterType === JobFilterType.JOB_TYPE_PENDING) {
      return 'Pending Recurring Jobs'
    }

    return 'Recurring Jobs'
  }

  onSearchName (value) {
    const { filter: prevFilter, sort } = this.state
    this.setState({ isSearching: true })

    const filter = getFilterObject(prevFilter, value)

    this.setState({ filter, searchText: value, currentPage: 1 }, () => {
      redirectUrl(this.props, { page: 1, q: value })
      this.fetchRecurringJobs({ currentPage: 1, filter, loading: true, searchText: (filter.$and ? '' : value), sort })
    })
  }

  render () {
    const { history } = this.props
    const { currentPage, jobFilterType, jobSummary, list, total, searchText, loadingList, loadingSummary, isAddJobModal, isLoaded, isSearching } = this.state

    const title = this.getTitle(jobFilterType)

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobJvpMenu} backLink='/' countData={jobSummary} dataCheckRequired />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess('createJob')
              ? <div className='btn' onClick={() => this.showAddJobModal(true)}>
                Add Job
              </div>
              : null }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput
                  placeholder='Search'
                  onChange={(v) => this.onSearchName(v)}
                  isSearching={isSearching}
                  value={searchText}
                />
              </Col>
            </Row>
          </Page.Filter>

          <div className='jobs-jvp'>
            <Skeleton loading={loadingList && !isLoaded} active>
              <Spin spinning={loadingList && isLoaded}>
                { validator.isArray(list) && list.map((item) => {
                  return <RecurringJobView key={`rcrjs-${item.id}`} item={item} listType={JobListType.JOB_LIST_RECURRING} />
                })}
              </Spin>
            </Skeleton>
          </div>

          { !(loadingList && !isLoaded) // inverse of Skeleton activate condition
            ? <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} job${total === 1 ? '' : 's'}`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
            : null }

          <AddJobModal
            visible={isAddJobModal}
            history={history}
            onClose={() => this.showAddJobModal(false)}
          />

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    redirectUrl(this.props, { page: currentPage, q: searchText })
    this.fetchRecurringJobs({ currentPage, filter, searchText, sort })
  }

  fetchDataList = async () => {
    const { currentPage, searchText } = this.state
    this.fetchList({ page: currentPage, searchText }, true)
  }

  async fetchList ({page = 1, searchText}, isRedirect = true) {
    const { filter, loading, searchText: prevSearchText, sort } = this.state

    if (isRedirect) {
      redirectUrl(this.props, { page, q: searchText !== undefined ? searchText : prevSearchText || '' })
    }

    this.fetchRecurringJobs({ loading, isForceReload: isRedirect, currentPage: page, filter, searchText: searchText !== undefined ? searchText : prevSearchText, sort })
  }

  fetchRecurringJobs = async ({ loading = true, isForceReload = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchJobRecurringList } = this.props
      const { jobFilterType } = this.state
      fetchJobRecurringList({ loading, isForceReload, currentPage, pageSize, filter, sort, searchText, filterType: jobFilterType })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load recurring jobs successfully. Please try again later.')
    }
  }

  fetchJobSummary = async () => {
    const { fetchJobSummary } = this.props

    fetchJobSummary()
  }

  // handleFunderChange = async (value) => {
  //   const { item } = await funderService.get(value)
  //   const funderRateSet = item.rate_set
  //   const billRateInfo = await settingBillingRateService.get(funderRateSet)
  //   const billRateCategory = billRateInfo.categories
  //   this.setState({ billingCategory: billRateCategory, billingRateId: item.rate_set, billRateInfo })
  // }

  // handleSubmit = () => {
  //   const { form } = this.props
  //   const { validateFieldsAndScroll } = form

  //   validateFieldsAndScroll(async (errors, values) => {
  //     if (!errors) {
  //       const { history } = this.props
  //       const clientId = values.client_id
  //       const funderId = values.funder_id
  //       const jobType = values.job_type
  //       this.setState({ loading: true })

  //       if (jobType === 'single') {
  //         history.replace(`/jobs/new/client/${clientId}/funder/${funderId}`)
  //       } else {
  //         history.replace(`/jobs/new/client/${clientId}/funder/${funderId}/recurring`)
  //       }

  //       this.setState({ loading: false })
  //     }
  //   })
  // }

  // add job modal
  showAddJobModal = (isAddJobModal) => {
    this.setState({ isAddJobModal })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchJobRecurringList,
  fetchJobSummary
}

const mapStateToProps = (state) => {
  return { ...state.JvpJobRecurring, ...state.JvpJobSummary }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobRecurringList))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uploader, validator, formatter } from '../../util'
import { authService } from '../../services'
import { employeesMandatoryFiles } from '../../states/actions'
import Moment from 'moment-timezone'

// UI
import { FileTypeUpload } from '../index'
import notify from '../Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { apiHostname } from '../../config'

const dateFormat = 'DD/MM/YYYY'
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm, error, warning } = Modal

export class MandatoryFiles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadingFile: false
    }
  }

  render () {
    const sideBySideFirstFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 14 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 10 },
      wrapperCol: { sm: 14, md: 14, lg: 11 }
    }

    const sideBySideFormItemLayout2 = {
      labelCol: { sm: 6, md: 6, lg: 13 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const { form, moduleType, moduleId, settingsMandatory, items } = this.props
    const { loadingFile } = this.state
    const { getFieldDecorator, getFieldValue } = form

    return settingsMandatory.map((file, index) => {
      const _item = items.filter(a => a.main_category_id === file.main_cat_id && a.sub_category_id === file.id)
      const item = _item[0] || {}
      const tempFileName = getFieldValue(`mandatory[${index}].file_name`)
      const tempFileUrl = getFieldValue(`mandatory[${index}].file_url`)

      return (
        <div key={file.id}>
          <Row>
            <Col lg={11}>
              <FormItem {...sideBySideFormItemLayout} label={file.sub_cat_name}>
                {getFieldDecorator(`mandatory[${index}].issuance_date`, {
                  rules: [
                    // { required: true, message: `Please enter ${file.name} Date` }
                  ],
                  initialValue: item.issuance_date ? Moment(item.issuance_date) : null
                })(
                  <DatePicker onChange={(e) => this.handleIssuanceDateChange(e, file, index)} format={dateFormat} defaultPickerValue={Moment(new Date())} />
                )}
              </FormItem>
            </Col>

            <Col lg={9}>
              <FormItem {...sideBySideFormItemLayout2} label='Expires'>
                {getFieldDecorator(`mandatory[${index}].expiry_date`,
                  file.has_expiry ? {
                    rules: [
                      // { required: true, message: `Please enter expiry date` }
                    ],
                    initialValue: item.expiry_date ? Moment(item.expiry_date) : null
                  } : {
                    initialValue: item.expiry_date ? Moment(item.expiry_date) : null
                  }
                )(
                  <DatePicker format={dateFormat} disabled={(file.has_expiry) ? false : true} defaultPickerValue={Moment(new Date())} />
                )}
              </FormItem>
            </Col>

            <Col lg={2}>
              <FormItem {...sideBySideFormItemLayout} label=''>
                <div className='file-action-buttons mandatory-document'>
                  {this.hasAccess(`create${formatter.capitalize(moduleType)}File`) && !item.file_url && !item.file_name
                    ? <FileTypeUpload
                      loading={loadingFile}
                      readOnly={false}
                      upload={{
                        action: `${apiHostname}/private/api/clients/files/upload/files`,
                        beforeUpload: this.checkFile,
                        data: { id: undefined, moduleId: moduleId, compId: 1 },
                        headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                        name: 'file',
                        onChange: this.handleUploadFile(index, item, file),
                        showUploadList: false
                      }}
                    />
                    : null}
                  {/* {this.isEdit() && policeCheck
                    ? <div>
                    <Tooltip mouseLeaveDelay={0} title='Delete'>
                      <Popconfirm
                        title='Confirm to delete this?'
                        onConfirm={() => this.handleDeleteFile(policeCheck.id, policeCheck)}
                        okText='Yes'
                        cancelText='No'
                      ><Icon type='delete' />
                      </Popconfirm>
                    </Tooltip>
                  </div>
                    : null} */}

                  {this.hasAccess(`read${formatter.capitalize(moduleType)}File`) && tempFileName && tempFileUrl
                    ? <Tooltip
                      mouseLeaveDelay={0}
                      title={`Download ${formatter.toStandardFileName(tempFileName)}`}
                    >
                      <div onClick={this.handleDownloadFile(tempFileUrl)} style={{ cursor: 'pointer', paddingTop: 2 }}>
                        <Icon type='file-text' /></div>
                    </Tooltip>
                    : (this.hasAccess(`read${formatter.capitalize(moduleType)}File`) && item && item.file_url && item.file_name
                      ? <Tooltip
                        mouseLeaveDelay={0}
                        title={`Download ${formatter.toStandardFileName(item.file_name)}`}
                      >
                        <div onClick={this.handleDownloadFile(item.file_url)} style={{ cursor: 'pointer', paddingTop: 2 }}>
                          <Icon type='file-text' /></div>
                      </Tooltip>
                      : null)}

                </div>
              </FormItem>
            </Col>
          </Row>

          <Row style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'File ID'}>
                {getFieldDecorator(`mandatory[${index}].id`, {
                  initialValue: item.id ? item.id : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Module'}>
                {getFieldDecorator(`mandatory[${index}].module`, {
                  initialValue: item.module ? item.module : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Module ID'}>
                {getFieldDecorator(`mandatory[${index}].module_id`, {
                  initialValue: item.module_id ? item.module_id : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'File Name'}>
                {getFieldDecorator(`mandatory[${index}].file_name`, {
                  initialValue: item.file_name ? item.file_name : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'File URL'}>
                {getFieldDecorator(`mandatory[${index}].file_url`, {
                  initialValue: item.file_url ? item.file_url : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Active'}>
                {getFieldDecorator(`mandatory[${index}].active`, {
                  initialValue: item.active ? item.active : null,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={file.macin_cat_name}>
                {getFieldDecorator(`mandatory[${index}].main_category_id`, {
                  initialValue: file.main_cat_id ? file.main_cat_id : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={file.sub_cat_name}>
                {getFieldDecorator(`mandatory[${index}].sub_category_id`, {
                  initialValue: file.id ? file.id : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Main Category Name'}>
                {getFieldDecorator(`mandatory[${index}].main_category`, {
                  initialValue: file.main_cat_name ? file.main_cat_name : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Sub Category Name'}>
                {getFieldDecorator(`mandatory[${index}].sub_category`, {
                  initialValue: file.sub_cat_name ? file.sub_cat_name : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Label'}>
                {getFieldDecorator(`mandatory[${index}].label`, {
                  initialValue: item.label ? item.label : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Has Apply Skill'}>
                {getFieldDecorator(`mandatory[${index}].is_apply_skill`, {
                  initialValue: file.sub_cat_is_apply_skill ? file.sub_cat_is_apply_skill : null
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFirstFormItemLayout} label={'Skills'}>
                {getFieldDecorator(`mandatory[${index}].automate_skills`, {
                  initialValue: file.sub_cat_automate_skills ? file.sub_cat_automate_skills : null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>
        </div>

      )
    })
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024
    // console.log('checkFile', file)
    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  async handleIssuanceDateChange (value, setting, index) {
    const { form } = this.props

    if (validator.isDigit(setting.sub_cat_expiry_month)) {
      const mandatory = form.getFieldValue('mandatory')
      const addMonths = setting.sub_cat_expiry_month
      const expiryDate = Moment(value).add(addMonths, 'months')
      mandatory[index].expiry_date = expiryDate

      form.setFieldsValue({ mandatory })
    }
  }

  handleDownloadFile = (url) => (e) => {
    if (url) {
      window.location.href = url
    } else {
      error({
        title: 'Error',
        content: (
          <div>No file has been uploaded!</div>
        ),
        okText: 'OK',
        onOk () { }
      })
    }
  }

  handleUploadFile = (selectedIndex, selectedFile, currentCategory) => async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true })
    }

    if (status === 'done') {
      const { employeesMandatoryFiles, settingsMandatory, form, moduleType, moduleId, onSkillApply } = this.props
      const { getFieldValue, setFieldsValue } = form
      const mandatory = getFieldValue('mandatory')

      const { fileUrl } = response
      const lastIndex = fileUrl.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
      const { id: userId, name: userName } = authService.getCurrentUser()

      if (settingsMandatory[selectedIndex].id === currentCategory.id) {
        mandatory[selectedIndex].module = moduleType
        mandatory[selectedIndex].module_id = moduleId || null
        mandatory[selectedIndex].file_name = fileName
        mandatory[selectedIndex].file_url = fileUrl
        mandatory[selectedIndex].active = true
        mandatory[selectedIndex].created_at = Moment()
        mandatory[selectedIndex].created_by = userName
        mandatory[selectedIndex].created_by_id = userId

        if (settingsMandatory[selectedIndex].sub_cat_is_apply_skill && validator.isNotEmptyArray(settingsMandatory[selectedIndex].sub_cat_automate_skills)) {
          onSkillApply(settingsMandatory[selectedIndex].sub_cat_automate_skills)
        }
      }

      this.setState({ loadingFile: false })
      setFieldsValue({ mandatory: mandatory })
    }

    if (status === 'error') {
      // const { token, tokenRefreshed } = response
      console.log('Upload Error')
      // if (tokenRefreshed) {
      //   const { UploadType } = uploader
      //   const response = await uploader.upload(fileId, info, token, UploadType.FILE)
      //   console.log('token refreshed & upload file done', response)
      //   if (response.fileUrl) {
      //     const { fileUrl } = response
      //     const lastIndex = fileUrl.lastIndexOf('/')
      //     const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
      //     const fileType = FileTypeList.filter(item => item.value === type)
      //     const values = {}

      //     values.module = clientModule
      //     values.module_id = moduleId
      //     values.type = type
      //     values.file_name = fileName
      //     values.file_url = fileUrl
      //     values.label = `${type}_${formatter.toShortDate(moment())}`
      //     values.active = true

      //     const addFileResult = await employeeFileService.add(values)

      //     if (addFileResult.id) {
      //       this.setState({ shouldRefreshFiles: true })
      //       log.addEmployeeFile(moduleId, `New ${fileType[0].name} ${values.label}`)
      //       this.fetchFiles()
      //       notify.success('Upload successfully', 'File upload successfully')
      //       this.props.setRefreshActivityLog(true)
      //     } else {
      //       notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      //     }
      //   } else {
      //     notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      //   }
      // } else {
      //   notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      // }

      this.setState({ loadingFile: false })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  employeesMandatoryFiles
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MandatoryFiles)

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_PENDING_EMPLOYEE_BASEJOBS, FETCHING_PENDING_EMPLOYEE_BASEJOBS, PENDING_EMPLOYEE_BASEJOBS_FETCHED } from '../actions/pendingEmployeeBaseJob'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_PENDING_EMPLOYEE_BASEJOBS, loading })
    const jobs = yield baseJobService.getPendingEmployeeJob(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: PENDING_EMPLOYEE_BASEJOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PENDING_EMPLOYEE_BASEJOBS, loading: false })
    console.error(e)
  }
}

function * watchPendingEmployeeBaseJobs () {
  yield takeEvery(FETCH_PENDING_EMPLOYEE_BASEJOBS, fetchJobs)
}

export default watchPendingEmployeeBaseJobs

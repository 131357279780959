export const FETCH_PENDING_EMPLOYEE_BASEJOBS = 'FETCH_PENDING_EMPLOYEE_BASEJOBS'
export const FETCHING_PENDING_EMPLOYEE_BASEJOBS = 'FETCHING_PENDING_EMPLOYEE_BASEJOBS'
export const PENDING_EMPLOYEE_BASEJOBS_FETCHED = 'PENDING_EMPLOYEE_BASEJOBS_FETCHED'

export const fetchPendingEmployeeBaseJobs = (params) => {
  return Object.assign({
    type: FETCH_PENDING_EMPLOYEE_BASEJOBS
  }, params)
}

export const fetchingPendingEmployeeBaseJobs = (loading) => {
  return {
    type: FETCHING_PENDING_EMPLOYEE_BASEJOBS,
    loading
  }
}

export const pendingEmployeeBaseJobsFetched = (jobs) => {
  return {
    type: PENDING_EMPLOYEE_BASEJOBS_FETCHED,
    jobs
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingReasonService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Page, Panel, SideModal } from '../../../../components'
import Empty from 'antd/lib/empty'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Inactive Reasons'
const settingType = 'Inactive Reasons'

export class SettingResignedExitedPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modalShow: false,
      loading: false,
      loadingForm: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    this.fetchReasonDetails()
  }

  fetchReasonDetails = async () => {
    try {
      const { code } = this.props
      this.setState({ loading: true })
      const settings = await settingReasonService.getCode(code)
      this.setState({ list: settings.list, loading: false, name: settings.name })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleModal = (modalShow = false, selectedItem = {}) => {
    const { form } = this.props
    const { resetFields } = form

    resetFields()

    if (modalShow) {
      this.setState({ modalShow, selectedItem })
    } else {
      this.setState({ modalShow, selectedItem: {} })
    }
  }

  handleSave = () => {
    const { code, name, form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        this.setState({ loadingForm: true })

        delete values.setName

        try {
          let response

          if (id) {
            response = await settingReasonService.save(id, values)
          } else {
            values.reason_code = code
            response = await settingReasonService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${name} reason saved successfully.`)
            this.fetchReasonDetails()
            this.handleModal(false)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${name} reason successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${name} reason successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  async handleDelete (id) {
    const { name } = this.props
    const values = {}
    values.is_delete = true

    const res = await settingReasonService.save(id, values)

    if (res) {
      notify.success('Deleted successfully', `${name} reason deleted successfully`)
      this.fetchReasonDetails({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modalShow, name, selectedItem } = this.state
    const { name: typeName, code, form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <div className='setting-list'>

            <Skeleton loading={loading} active>
              <div>
                  <Row>
                    <Col lg={16}>
                    </Col>
                    <Col lg={8} className='holiday-title-action'>
                      { this.hasAccess('createCancellation') ? <div className='btn' style={{ width: '120px', float: 'right' }} onClick={() => this.handleModal(true)}>
                        Add Reason
                      </div> : null }
                    </Col>
                  </Row>
                  <Row className='list-header'>
                    <Col md={20}>Reason</Col>
                  </Row>
                  {list.map((item) => {
                    const { id, name, date } = item

                    return (name
                      ? <div className='list-item' key={`${code}-${id}`}>
                        <Row className='list-content'>
                          <Col md={22}>{ name }</Col>
                          <Col md={1}>
                            { this.hasAccess('updateCancellation') ? <div onClick={() => this.handleModal(true, item)}><Icon type='form' /></div> : null }
                          </Col>
                          <Col md={1}>
                            { this.hasAccess('deleteCancellation') ? <Popconfirm
                              title='Confirm to delete this?'
                              onConfirm={(e) => this.handleDelete(id)}
                              okText='Yes'
                              cancelText='No'
                            ><Icon type='delete' />
                            </Popconfirm> : null }
                          </Col>
                        </Row>
                      </div> : null
                    )
                  })}
                  { !validator.isNotEmptyArray(list)
                    ? <Empty description={
                      <span>
                        No reason yet, <a onClick={() => this.handleModal(true)}>create one</a>?
                      </span>
                    } /> : null}
                </div>
            </Skeleton>
          </div>

        </Page.Content>

        <SideModal
          title={`${typeName} Reason`}
          showModal={modalShow}
          onClose={() => this.handleModal(false)}
          buttons={[
            <Button key={`rsc-save`} onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Reason'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Reason must be between 2 and 128 characters' },
                  { max: 128, message: 'Reason must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter reason' },
                  { whitespace: true, message: 'Please enter reason' }
                ]
              })(
                <Input disabled={loadingForm} />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingResignedExitedPage))

import { FETCHING_STORES, STORES_FETCHED } from '../../actions/setting/store'

const INITIAL_STATE = {
  loading: true,
  stores: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_STORES:
      return handleFetchingStores(state, action)
    case STORES_FETCHED:
      return handleStoresFetched(state, action)
    default:
      return state
  }
}

function handleFetchingStores (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleStoresFetched (state, action) {
  const { loading, stores } = action
  return { ...state, loading, stores }
}

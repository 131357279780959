import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_JOB_SUMMARY,
  FETCHING_JOB_SUMMARY,
  JOB_SUMMARY_FETCHED
} from '../actions//jvpJobSummary'
import jobService from '../../services/jvp/job'

function * fetchJobSummary ({ loading = true }) {
  try {
    yield put({ type: FETCHING_JOB_SUMMARY, loadingSummary: loading })

    const summary = yield jobService.getSummary()

    yield put({ type: JOB_SUMMARY_FETCHED, summary, loadingSummary: false })
  } catch (e) {
    yield put({ type: JOB_SUMMARY_FETCHED, loadingSummary: false })
    console.error(e)
  }
}

function * watchJvpSummary () {
  yield takeEvery(FETCH_JOB_SUMMARY, fetchJobSummary)
}

export default watchJvpSummary

import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/export/contacts'

export default {
  getMicrosipCsv (values) {
    return requestFile(`${url}/microsip/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getMicrosipXML (values) {
    return requestFile(`${url}/microsip/xml`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAcrobitsCsv (values) {
    return requestFile(`${url}/acrobits/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAcrobitsJSON (values) {
    return requestFile(`${url}/acrobits/json`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getGeneralCsv (values) {
    return requestFile(`${url}/general/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getGeneralXML (values) {
    return requestFile(`${url}/general/xml`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getSimpleCsv (values) {
    return requestFile(`${url}/simple/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getSimpleZipCsv (values) {
    return requestFile(`${url}/simple/zip`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getSimpleZipEmail (values) {
    return request(`${url}/simple/email`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getSimpleXML (values) {
    return requestFile(`${url}/simple/xml`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
}

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Pending Jobs',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'Individual',
                link: '/jobs/list/single/pending',
                permission: 'listJobs'
              },
              {
                name: 'Recurring Base',
                link: '/jobs/list/recurring/pending',
                permission: 'listJobs'
              }
            ],
            permission: 'listJobs'
          },
          {
            title: 'Individual Jobs',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'Future Jobs',
                link: '/jobs/list/single/future',
                permission: 'listJobs'
              },
              {
                name: 'Past Jobs',
                link: '/jobs/list/single/past',
                permission: 'listJobs'
              },
              {
                name: 'Cancelled Jobs',
                link: '/jobs/list/single/cancelled',
                permission: 'listJobs'
              }
            ],
            permission: 'listJobs'
          },
          {
            title: 'Recurring Base',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'Active Recurring Base',
                link: '/jobs/list/recurring/active',
                permission: 'listJobs'
              },
              {
                name: 'Ended Recurring Base',
                link: '/jobs/list/recurring/ended',
                permission: 'listJobs'
              }
            ],
            permission: 'listJobs'
          },
        ]
      },
      {
        subgroup: [
          {
            title: 'Clients',
            icon: 'usergroup-add',
            menu: [
              {
                name: 'List Clients',
                link: '/clients',
                permission: 'listClients'
              }, {
                name: 'Add Client',
                link: '/clients/add',
                permission: 'createClient'
              }
            ],
            permission: 'listClients'
          },
          {
            title: 'Funders',
            icon: 'team',
            menu: [
              {
                name: 'List Funders',
                link: '/funders',
                permission: 'listFunders'
              }, {
                name: 'Add Funder',
                link: '/funders/add',
                permission: 'createFunder'
              }
            ],
            permission: 'listFunders'
          },
          {
            title: 'Feedbacks',
            icon: 'star',
            menu: [
              {
                name: 'List Feedbacks',
                link: '/feedbacks',
                permission: 'listFeedbacks',
              },
              {
                name: 'Add Feedback',
                link: '/feedbacks/add',
                permission: 'createFeedback',
              },
            ],
            permission: 'listFeedbacks',
          }
        ]
      },
      {
        subgroup: [
          {
            title: 'Employees',
            icon: 'user',
            menu: [
              {
                name: 'List Employees',
                link: '/employees',
                permission: 'listEmployees'
              }, {
                name: 'Add Employee',
                link: '/employees/add',
                permission: 'createEmployee'
              }
            ],
            permission: 'listEmployees'
          },
          {
            title: 'Employees By Map',
            icon: 'global',
            menu: [
              {
                name: 'Full Map',
                link: '/map',
                permission: 'listMap'
              }
            ],
            permission: 'listMap'
          },
          {
            title: 'Communication',
            icon: 'message',
            menu: [
              {
                name: 'Job Communication',
                link: '/communication'
              }
            ]
          },
          {
            title: 'Payroll',
            icon: 'dollar',
            menu: [
              {
                name: 'Payroll',
                link: '/payrolls',
                permission: 'listPayrolls'
              }
            ],
            permission: 'listPayrolls'
          }
        ],
        permission: 'listEmployees'
      }
    ]
  },
  {
    title: 'Section 2',
    groups: [
      {
        subgroup: [
          {
            title: 'Reports',
            icon: 'snippets',
            menu: [
              {
                name: 'Back-To-Back Shifts',
                link: '/reports/back-to-back-job',
                permission: 'readBackToBackReport'
              },
              {
                name: 'Clashed Shifts',
                link: '/reports/clash-job',
                permission: 'readClashReport'
              },
              {
                name: 'Client\'s Birthday',
                link: '/reports/client-birthday',
                permission: 'readClientBirthdayReport'
              },
              {
                name: 'Client\'s Budget Report',
                link: '/reports/client-budget',
                permission: 'readClientBudgetReport'
              },
              {
                name: 'Client\'s Care Plan Review',
                link: '/reports/care-plan-v2-review',
                permission: 'readClientCarePlanReviewReport'
              },
              {
                name: 'Client\'s Funding',
                link: '/reports/client-funding',
                permission: 'readClientFundReport'
              },
              {
                name: 'Client\'s Files',
                link: '/reports/client-files',
                permission: 'readClientFilesReport'
              },
              {
                name: 'Client\'s Leave',
                link: '/reports/client-leave',
                permission: 'readClientReport'
              },
              {
                name: 'Day Counts since Last Job',
                link: '/reports/last-job',
                permission: 'readDayCountReport'
              },
              {
                name: 'Employee\'s Birthday',
                link: '/reports/employee-birthday',
                permission: 'readEmployeeBirthdayReport'
              },
              {
                name: 'Employee\'s Files',
                link: '/reports/employee-files',
                permission: 'readEmployeeCertReport'
              },
              {
                name: 'Employee\'s Leave',
                link: '/reports/employee-leave',
                permission: 'readEmployeeReport'
              },
              {
                name: 'Employee\'s Required Files',
                link: '/reports/employee-missing-required-files',
                permission: 'readEmployeeMissingRequiredFileReport'
              },
              // {
              //   name: 'Funder\'s Agreement Expiry',
              //   link: '/reports/funder-agreement-expiry',
              //   permission: 'readFunderAgreementExpiryReport',
              // },
              {
                name: 'Feedback',
                link: '/reports/feedback',
                permission: 'readFeedbackReport'
              },
              {
                name: 'Funder\'s Files',
                link: '/reports/funder-files',
                permission: 'readFunderFilesReport'
              },
              {
                name: 'Job Cancellation',
                link: '/reports/job-cancellation',
                permission: 'readJobMultiActionReport'
              },
              {
                name: 'Job Timesheet Report',
                link: '/reports/job-timesheet-report',
                permission: 'readJobTimesheetReport'
              },
              {
                name: 'Multi Client Shifts',
                link: '/reports/multi-client',
                permission: 'readMultiClientReport'
              },
              {
                name: 'Public Holidays',
                link: '/reports/public-holiday',
                permission: 'readPublicHolidayReport'
              },
              {
                name: 'System User Action',
                link: '/reports/action',
                permission: 'readActionReport'
              },
              {
                name: 'Individual User Action',
                link: '/reports/action/me',
                // permission: 'readActionReport' // No permission required for individual user action
                status: 1
              },
              {
                name: 'Recipient Settings',
                link: '/reports/setting',
                permission: 'listRecipients'
              }
            ],
            permission: 'listReports'
          }
        ],
        permission: 'listEmployees'
      },
      {
        subgroup: [
          {
            title: 'System Users',
            icon: 'solution',
            menu: [
              {
                name: 'System Users',
                link: '/settings/admins',
                permission: 'listSystemUsers'
              },
              {
                name: 'User Roles',
                link: '/settings/admin-roles',
                permission: 'listUserRoles'
              },
              {
                name: 'System Modules',
                link: '/settings/modules',
                permission: 'listSystemModules'
              }
            ],
            permission: 'listSystemUsers'
          },
          {
            title: 'Logs',
            icon: 'profile',
            menu: [
              {
                name: 'Access Log',
                link: '/settings/access-log',
                permission: 'listSystemModules'
              },
              {
                name: 'Reminder Log',
                link: '/settings/reminder-log',
                permission: 'listSystemModules'
              }
            ],

            permission: 'listSystemModules'
          }
        ],
        permission: 'listSystemModules'
      },
      {
        title: 'Settings',
        icon: 'setting',
        menu: [
          // {
          //   name: 'Billing Rates',
          //   link: '/settings/billing-rates',
          //   permission: 'listBillingRates',
          //   status: 2
          // },
          {
            name: 'Contacts',
            link: '/settings/contacts',
            permission: 'listContacts'
          },
          {
            name: 'Feedback',
            link: '/settings/feedbacks',
            permission: 'listFeedbackSettings'
          },
          {
            name: 'File',
            link: '/settings/files',
            permission: 'listFiles'
          },
          {
            name: 'Funders',
            link: '/settings/funders',
            permission: 'listFvpFundingTypes'
          },
          // {
          //   name: 'Funding Types',
          //   link: '/settings/funding-types',
          //   permission: 'listFundingTypes',
          //   status: 2
          // },
          {
            name: 'Public Holidays',
            link: '/settings/holidays',
            permission: 'listPublicHolidays'
          },
          {
            name: 'Profile',
            link: '/settings/profile',
            permission: 'listProfile'
          },
          {
            name: 'Reasons',
            link: '/settings/reasons',
            permission: 'listCancellations'
          },
          // {
          //   name: 'Tax Codes',
          //   link: '/settings/tax-codes',
          //   permission: 'listTaxCodes',
          //   status: 2
          // },
          {
            name: 'Others',
            link: '/settings/others',
            permission: 'listOthers'
          }
        ],
        permission: 'listSettings'
      }
    ]
  }
]

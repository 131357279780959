import React from 'react'
import ReactDOM from 'react-dom'
import NotificationBox from './notification'

export default {
  success (title, message) {
    create('success', title, message)
  },
  error (title, message) {
    create('error', title, message)
  },
  warning (title, message) {
    create('warning', title, message)
  }
}

function create (type, title, message) {
  const div = document.createElement('div')
  document.body.appendChild(div)

  ReactDOM.render(<NotificationBox key={Math.random()} type={type} title={title} message={message} />, div)
  return true
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Loading extends Component {
  render () {
    const { blur, children, className, fullPage = false, loading } = this.props

    return fullPage
      ? <div className={formatter.toClassName(['wd-loading', className])}>
        { loading ? <div className='icon'><img src='/icon/loading.svg' alt='loading' /></div> : null }
        <div className={loading ? ('loading' + (blur ? ', loading-blur' : '')) : ''} >
          <div className='component'>{ children }</div>
        </div>
      </div>
      : <div className={formatter.toClassName(['wd-loading', className])}>
        <div className={loading ? ('loading' + (blur ? ', loading-blur' : '')) : ''} >
          <div className='component'>{ children }</div>
        </div>
      </div>
  }
}

Loading.propTypes = {
  className: PropTypes.string
}

export const FETCH_RECURRING_JOBS = 'FETCH_RECURRING_JOBS'
export const FETCHING_RECURRING_JOBS = 'FETCHING_RECURRING_JOBS'
export const RECURRING_JOBS_FETCHED = 'RECURRING_JOBS_FETCHED'

export const fetchRecurringJobs = (params) => {
  return Object.assign({
    type: FETCH_RECURRING_JOBS
  }, params)
}

export const fetchingRecurringJobs = (loading) => {
  return {
    type: FETCHING_RECURRING_JOBS,
    loading
  }
}

export const recurringJobsFetched = (jobs) => {
  return {
    type: RECURRING_JOBS_FETCHED,
    jobs
  }
}

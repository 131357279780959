import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'

import GeneralTab from './General'
import CustomIdentifier from './CustomIdentifier'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Profile'

// title should be always plural form and title_singular is for singular form
const TabList = [
  { type: 'payroll', title: 'Carer Skill Levels Required', title_singular: 'Carer Skill Level Required', PmsCreate: 'createClientDifficulty', PmsUpdate: 'updateClientDifficulty', PmsDelete: 'deleteClientDifficulty' },
  { type: 'gender', title: 'Genders', title_singular: 'Gender', PmsCreate: 'createGender', PmsUpdate: 'updateGender', PmsDelete: 'deleteGender' },
  { type: 'height', title: 'Heights', title_singular: 'Height', PmsCreate: 'createHeight', PmsUpdate: 'updateHeight', PmsDelete: 'deleteHeight' },
  { type: 'language', title: 'Languages', title_singular: 'Language', PmsCreate: 'createLanguage', PmsUpdate: 'updateLanguage', PmsDelete: 'deleteLanguage' },
  { type: 'relationship', title: 'Relationships', title_singular: 'Relationship', PmsCreate: 'createRelationship', PmsUpdate: 'updateRelationship', PmsDelete: 'deleteRelationship' },
  { type: 'skill', title: 'Skills', title_singular: 'Skill', PmsCreate: 'createSkill', PmsUpdate: 'updateSkill', PmsDelete: 'deleteSkill' },
  { type: 'visa', title: 'Visa Status', title_singular: 'Visa Status', PmsCreate: 'createVisaStatus', PmsUpdate: 'updateVisaStatus', PmsDelete: 'deleteVisaStatus' }
]

export class SettingProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addClientDifficulty: false,
      addGender: false,
      addHeight: false,
      addLanguage: false,
      addRelationship: false,
      addSkill: false,
      addVisaStatus: false,
      addCustomIdentifier: false,
      currentPage: 1,
      activeTab: '1',
      moduleKey: 'client',
      filter: {},
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    // const tab = (new URLSearchParams(window.location.search)).get("t")
    // this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  addCustomIdentifier = () => {
    this.setState({ addCustomIdentifier: true })
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  onModuleKeyChange = (key) => {
    this.setState({ moduleKey: key })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addClientDifficulty, addGender, addHeight, addLanguage, addRelationship, addSkill, addVisaStatus, addCustomIdentifier, activeTab, moduleKey } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
            { TabList.map((e, idx) => (
              <TabPane tab={`${e.title}`} key={idx + 1}>
                <GeneralTab settingInfo={e} />
              </TabPane>
            )) }

            <TabPane tab='Custom Identifier' key='8'>
              <Tabs defaultActiveKey='client' activeKey={moduleKey} onChange={this.onModuleKeyChange}>
                { /* client tab */ }
                <TabPane tab='Client' key='client'>
                  <div className='header-action'>
                    { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                      Add Custom Identifier
                    </div> : null }
                  </div>
                  <CustomIdentifier module='client' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                </TabPane>
                { /* employee tab */ }
                <TabPane tab='Employee' key='employee'>
                  <div className='header-action'>
                    { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                      Add Custom Identifier
                    </div> : null }
                  </div>
                  <CustomIdentifier module='employee' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                </TabPane>
                { /* funder tab */ }
                <TabPane tab='Funder' key='funder'>
                  <div className='header-action'>
                    { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                      Add Custom Identifier
                    </div> : null }
                  </div>
                  <CustomIdentifier module='funder' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingProfile))

import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/export/jobs'

export default {
  getViewJobList (type, values) {
    if (values.isSendEmail) {
      return request(`${url}/${type}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(values)
      })
    } else {
      return requestFile(`${url}/${type}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(values)
      })
    }
  },
}

import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_JOB_LIST,
  FETCHING_JOB_LIST,
  JOB_LIST_FETCHED
} from '../actions/jvpJob'
import jvpJobService from '../../services/jvp/job'

function * fetchJobList ({ loading, isForceReload = false, currentPage, pageSize, filter, filterType, sort, searchText }) {
  try {
    yield put({ type: FETCHING_JOB_LIST, loadingList: loading, isLoaded: !isForceReload })

    const jobs = yield jvpJobService.listViewMainByPage(filterType, currentPage, pageSize, filter, sort, searchText)

    yield put({ type: JOB_LIST_FETCHED, jobs, loadingList: false })
  } catch (e) {
    yield put({ type: JOB_LIST_FETCHED, loadingList: false })
    console.error(e)
  }
}

function * watchJvpJob () {
  yield takeEvery(FETCH_JOB_LIST, fetchJobList)
}

export default watchJvpJob

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingFileTypeService, settingFileCategoryService, settingGeneralService } from '../../../../services/setting'
import { setRefreshFileCategory } from '../../../../states/actions/setting/file'
import { authService } from '../../../../services'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'
import { validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

const settingTitle = 'Sub-Category'
const settingType = 'type'

export class SubCategory extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: [],
      currentPage: 1,
      filter: {},
      hasApplySkill: false,
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { id: null },
      showExpiryInput: false,
      skills: [],
      sort: {},
      total: 0
    }
    this.handleExpiryChange = this.handleExpiryChange.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    // console.log('Next Props', nextProps)
    if (nextProps.shouldRefreshFileCategory !== this.props.shouldRefreshFileCategory) {
      if (nextProps.shouldRefreshFileCategory) {
        this.fetchSettings(this.state.currentPage)
      }
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })
    this.fetchSkillSettings()
  }

  fetchSettings = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { classification } = this.props
      filter.sub_cat_classification = { condition: '=', value: classification }
      this.setState({ currentPage, loading: true })

      const settings = await settingFileTypeService.listSettingByPage(currentPage, pageSize, filter)

      const { list: categories } = await settingFileCategoryService.listByPage(1, 0, { active: true, classification })
      this.setState({ list: settings.list, loading: false, total: settings.total, categories })

      setTimeout(() => {
        this.props.setRefreshFileCategory(false)
      }, 200)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  fetchSkillSettings = async () => {
    const filter = {}
    filter.type = { condition: '=', value: 'skill' }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({ skills: settings.list })
  }

  hideModal = () => {
    const { form, onComplete } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {}, showExpiryInput: false })
    onComplete()
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form, classification } = this.props
    const { validateFields } = form
    const { selectedItem, currentPage } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        // values.type = settingType

        this.setState({ loadingForm: true })

        if (values.automate_skills) {
          if (!Array.isArray(values.automate_skills)) {
            values.automate_skills = [values.automate_skills]
          }
        }

        try {
          let response

          if (id) {
            response = await settingFileTypeService.save(id, values)
          } else {
            values.classification = classification
            values.code = values.name.toLowerCase()
            response = await settingFileTypeService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: currentPage })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, categoryId, name, code, active, hasMandatory, hasExpiry, expiryMonth, is_apply_skill, automate_skills, sub_cat_is_editable) {
    this.setState({
      selectedItem: { id, categoryId, name, code, active, hasMandatory, hasExpiry, expiryMonth, is_apply_skill, automate_skills, sub_cat_is_editable },
      hasApplySkill: is_apply_skill
    })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingFileTypeService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchSettings({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { categories, currentPage, list, loading, loadingForm, modal, selectedItem, total, skills, hasApplySkill } = this.state
    const { form, classification } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Category',
        width: 4,
        key: 'main_cat_name'
        // render: ({ file_category_id: categoryId }) => {
        //   const category = categories.filter(item => item.id === categoryId)
        //   return (
        //     validator.isNotEmptyArray(category)
        //       ? <div>{category[0].name}</div>
        //       : null
        //   )
        // }
      },
      {
        title: 'Sub-Category',
        width: 6,
        key: 'sub_cat_name'
      },
      {
        title: 'Required',
        width: 2,
        render: ({ sub_cat_has_mandatory: mandatory }) => <div style={{ fontSize: '11pt' }}>{ mandatory ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: 'Expiry',
        width: 2,
        render: ({ sub_cat_has_expiry: expiry }) => <div style={{ fontSize: '11pt' }}>{ expiry ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: 'Expiry Months',
        width: 2,
        render: ({ sub_cat_expiry_month: expiryMonth }) => <div style={{ }}>{ !validator.isEmptyString(expiryMonth) ? expiryMonth : '' }</div>
      },
      {
        title: 'Enable',
        width: 2,
        render: ({ sub_cat_active }) => <div style={{ fontSize: '11pt' }}>{ sub_cat_active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, main_cat_id: categoryId, sub_cat_code: code, sub_cat_name: name, sub_cat_active: active, sub_cat_has_mandatory: hasMandatory, sub_cat_has_expiry: hasExpiry, sub_cat_expiry_month: expiryMonth, sub_cat_is_apply_skill: isApplySkill, sub_cat_automate_skills: automateSkills, sub_cat_is_editable: isEditable }) => {
          return (
            this.hasAccess('updateSubCategory')
              ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(id, categoryId, name, code, active, hasMandatory, hasExpiry, expiryMonth, isApplySkill, automateSkills, isEditable )}>
                <Icon type='form' />
              </div>
              : null
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id, sub_cat_is_editable }) => this.hasAccess('deleteSubCategory') && sub_cat_is_editable
        ? <Popconfirm
            title='Confirm to delete this?'
            onConfirm={(e) => this.handleDelete(id)}
            okText='Yes'
            cancelText='No'
          >
            <Icon type='delete' />
        </Popconfirm>
        : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} sub-categories`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title={settingTitle}
          showModal={modal.show || this.props.onAdd}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <FormItem layout='vertical' label='Category'>
            {getFieldDecorator('file_category_id', {
              initialValue: selectedItem.categoryId || '',
              rules: [
                { required: true, message: 'Please select a category' }
              ]
            })(
              <Select
                style={{ width: '100%' }}
                placeholder='Please select a category'
                showSearch
                disabled={selectedItem.sub_cat_is_editable === false}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  categories.map((category, idx) => {
                    return <Option key={category.id} value={category.id}>{category.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <Form layout='vertical'>
            <FormItem label='Sub-Category'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Sub-Category must be between 2 and 128 characters' },
                  { max: 128, message: 'Sub-Category must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter sub-category' },
                  { whitespace: true, message: 'Please enter sub-category' }
                ]
              })(
                <Input disabled={selectedItem.sub_cat_is_editable === false} />
              )}
            </FormItem>
            { classification !== 'job'
              ? (<FormItem label='Required'>
                {getFieldDecorator('has_mandatory', {
                  initialValue: selectedItem.hasMandatory,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes' unCheckedChildren='No'
                  />
                )}
              </FormItem>
              )
              : null
            }
            <FormItem label='Has Expiry'>
              {getFieldDecorator('has_expiry', {
                initialValue: selectedItem.hasExpiry,
                valuePropName: 'checked'
              })(
                <Switch
                  onChange={this.handleExpiryChange}
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
              {getFieldDecorator('expiry_month', {
                initialValue: selectedItem.expiryMonth
                // initialValue: selectedItem.expiryMonth || 0,
                // rules: [
                //   { required: true, message: 'Please enter month expiry' },
                //   { whitespace: true, message: 'Please enter month expiry' }
                // ]
              })(
                <Input style={{ width: '30%', marginLeft: 20 }} suffix='months' />
              )}
            </FormItem>
            { classification === 'client' || classification === 'employee'
              ? (<FormItem label='Apply Skill'>
                {getFieldDecorator('is_apply_skill', {
                  initialValue: selectedItem.is_apply_skill,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={(e) => this.handleSkillChange(e)}
                    checkedChildren='Yes' unCheckedChildren='No'
                  />
                )}

                {getFieldDecorator('automate_skills', {
                  initialValue: Array.isArray(selectedItem.automate_skills) && selectedItem.automate_skills[0] ? selectedItem.automate_skills : null,
                  // rules: [
                  //   hasApplySkill ? { required: true, message: 'Please Select Skills' } : null
                  // ]
                })(
                  <Select
                    disabled={!hasApplySkill}
                    style={{ width: '70%', marginLeft: 20 }}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      skills.map((skill, idx) => {
                        return <Option key={`${skill.id}-${idx}`} value={skill.id}>{skill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>)
              : null }
            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: typeof selectedItem.active === 'boolean' ? selectedItem.active : true,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={selectedItem.sub_cat_is_editable === false}
                  checkedChildren='Enable'
                  unCheckedChildren='Disable'
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }

  handleExpiryChange (value) {
    // this.setState({ showExpiryInput: value })

    if (!value) {
      const { form } = this.props
      const { setFieldsValue } = form
      // console.log('set expiry_month null', !value)
      setTimeout(() => { setFieldsValue({ expiry_month: null }) }, 200)
    }
  }

  handleSkillChange = (value) => {
    this.setState({ hasApplySkill: value })

    if (!value) {
      const { form } = this.props
      const { setFieldsValue } = form

      setTimeout(() => { setFieldsValue({ automate_skills: null }) }, 200)
    }
  }
}

const mapDispatchToProps = {
  setRefreshFileCategory
}

const mapStateToProps = (state) => {
  return {
    ...state.File
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SubCategory))

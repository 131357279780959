import { REFRESH_FILE_CATEGORIES } from '../../actions/setting/file'

const INITIAL_STATE = {
  shouldRefreshFileCategory: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_FILE_CATEGORIES:
      return { ...state, shouldRefreshFileCategory: action.done }
    default:
      return state
  }
}

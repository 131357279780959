export const ADMIN_ROLES_FETCHED = 'ADMIN_ROLES_FETCHED'
export const ADMIN_ROLE_SET_PRISTINE = 'ADMIN_ROLE_SET_PRISTINE'

export const adminRolesFetched = (adminRoles) => {
  return {
    type: ADMIN_ROLES_FETCHED,
    adminRoles
  }
}

export const adminRoleSetPristine = (pristine) => {
  return {
    type: ADMIN_ROLE_SET_PRISTINE,
    pristine
  }
}

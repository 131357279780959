import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, jvpLeaveService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log } from '../../../util'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const { Item: FormItem } = Form

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const pageSize = 20

const PmsCreateClientLeave = 'createClientLeave'
const PmsUpdateClientLeave = 'updateClientLeave'
const PmsListClientLeave = 'listClientLeaves'
const PmsDeleteClientLeave = 'deleteClientLeave'

class ClientLeaveJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      currentPage: 1,
      filter: {},
      total: 0,
      isShowModal: false,
      isUFNExist: false, // the flag returned from API to indicate whether UFN is exist on all leaves
      isUFNTriggered: false,
      loading: false,
      loadingValidate: false,
      loadingUpdate: false,
      modalItem: {},
      jobImpactInfo: {}
    }
  }

  componentDidMount () {
    this.fetchLeaves()
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const { list, total, currentPage, isShowModal, isUFNTriggered, loading, loadingUpdate, loadingValidate, modalItem, jobImpactInfo } = this.state

    const columns = [
      {
        title: 'Memo',
        width: 9,
        key: 'memo'
      },
      {
        title: 'Start',
        width: 4,
        render: ({ start_date }) => formatter.toShortDate(start_date)
      },
      {
        title: 'Finish',
        width: 4,
        render: ({ end_date, is_ufn }) => is_ufn ? 'UFN' : formatter.toShortDate(end_date)
      },
      {
        title: 'Affected Jobs',
        width: 2,
        render: ({ id, impacted_jobs, total_jobs }) => parseInt(total_jobs) > 0
          ? <div className='action-buttons'>
            <Tooltip mouseLeaveDelay={0} title='Resolve'>
            <Link to={`/clients/${clientId}/leave/${id}`}>
              <div style={{ color: '#dd4444' }}>{impacted_jobs} Job{parseFloat(impacted_jobs) === 1 ? '' : 's'} <Icon type='issues-close' /></div>
            </Link>
            </Tooltip>
          </div>
          : null
      },
      {
        title: 'Last Updated On',
        width: 3,
        render: ({ updated_at }) => formatter.toStandardDate(updated_at)
      },
      {
        title: '',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'>
            <div onClick={() => this.showModal(item)}><Icon type='form' /></div>
          </Tooltip>
          { this.hasAccess(PmsDeleteClientLeave)
            ? <Tooltip mouseLeaveDelay={0} title='Delete'>
              <Popconfirm
                title='Confirm to delete this leave? All affected jobs will not be reinstated even after leave deleted.'
                onConfirm={() => this.handleDelete(item)}
                okText='Yes'
                cancelText='No'
              >
                <Icon style={{marginTop: '3px'}} type='delete' />
              </Popconfirm>
            </Tooltip>
            : null }
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-header'>
          { this.hasAccess(PmsCreateClientLeave)
            ? <div className='btn' onClick={() => this.showModal()}>
              Add Leave
            </div>
            : null }
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <Loading loading={loadingUpdate} blur>
              <List cols={columns} rows={list} />
            </Loading>
            <SideModal
              title='Leave'
              showModal={isShowModal}
              onClose={this.hideModal}
              buttons={[
                this.hasAccess(PmsCreateClientLeave) || this.hasAccess(PmsUpdateClientLeave)
                ? <Button key={`client-leave-save`} disabled={loadingUpdate} onClick={() => this.checkBeforeSave()}>Save</Button>
                : null
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Memo'>
                  {getFieldDecorator('memo', {
                    initialValue: modalItem.memo,
                    rules: [
                      { min: 2, message: 'Memo must be between 2 and 128 characters' },
                      { max: 128, message: 'Memo must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter memo' },
                      { whitespace: true, message: 'Please enter memo' }
                    ]
                  })(
                    <Input disabled={loadingUpdate} />
                  )}
                </FormItem>
                <FormItem label='Start Leave'>
                  {getFieldDecorator('start_date', {
                    initialValue: modalItem.start_date
                      ? moment(modalItem.start_date)
                      : null,
                    rules: [
                      { required: true, message: 'Please enter date' },
                      { validator: this.handleDateChange }
                    ]
                  })(
                    <DatePicker
                      format='DD/MM/YYYY'
                      disabled={loadingUpdate || loadingValidate}
                      defaultPickerValue={moment(new Date())}
                    />
                  )}
                </FormItem>

                <Row>
                  <FormItem label='End Leave'>
                    <Col lg={16}>
                      {getFieldDecorator('end_date', {
                        initialValue: isUFNTriggered
                          ? null
                          : modalItem.end_date
                            ? moment(modalItem.end_date)
                            : null,
                        rules: [
                          { validator: this.handleDateChange },
                          !isUFNTriggered
                            ? { required: true, message: 'Please enter date' }
                            : {}
                        ]
                      })(
                        <DatePicker
                          format='DD/MM/YYYY'
                          disabled={isUFNTriggered || loadingUpdate || loadingValidate}
                          defaultPickerValue={moment(new Date())}
                        />
                      )}
                    </Col>
                    <Col lg={8}>
                      {getFieldDecorator('is_ufn', {
                        initialValue: modalItem.is_ufn || false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          disabled={loadingUpdate || loadingValidate}
                          checkedChildren='UFN'
                          unCheckedChildren='Not UFN'
                          onChange={(e) => this.handleUFNClick(e)}
                        />
                      )}
                    </Col>
                  </FormItem>
                </Row>
                <Row>
                  { loadingValidate
                    ? (!loadingUpdate
                        ? <div style={{fontWeight: 'bold', color: 'red'}}>Getting affected jobs count...</div>
                        : null)
                    : jobImpactInfo.total_jobs !== null
                      ? <div style={{fontWeight: 'bold', color: 'red'}}>{parseFloat(jobImpactInfo.total_jobs) === 0 ? 'No' : `Total of ${jobImpactInfo.total_jobs}`} job{parseFloat(jobImpactInfo.total_jobs) === 1 ? ' is' : 's are'}  affected under the dates.<br />{parseFloat(jobImpactInfo.impacted_jobs === 0) ? 'No' : `${jobImpactInfo.impacted_jobs}`} job{parseFloat(jobImpactInfo.impacted_jobs) === 1 ? ' is' : 's are'} ready for the action.</div>
                      : null }
                </Row>
              </Form>
            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} leave${total === 1 ? '' : 's'}`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />
      </Loading>
    )
  }

  fetchLeaves = async (page = null) => {
    if (!this.hasAccess(PmsListClientLeave)) return

    const { clientId } = this.props
    const { currentPage } = this.state

    const pg = page ? page : currentPage

    this.setState({ loading: true }, async () => {
      const { list, total, is_ufn_exist = false } = await jvpLeaveService.getAll('client', clientId)

      this.setState({
        loading: false,
        list,
        total,
        isUFNExist: is_ufn_exist
      })
    })
  }

  changePage = async (currentPage) => {
    this.fetchLeaves(currentPage)
  }

  checkBeforeSave = () => {
    const { clientInfo = {}, form } = this.props
    const { list, modalItem } = this.state
    const { getFieldValue } = form
    let isExist = false

    for (let i = 0; i < list.length; i++) {
      const leave = list[i]

      if (modalItem && modalItem.id === leave.id) {
        isExist = false
      } else if (getFieldValue('is_ufn') === true && leave.is_ufn === true) {
        isExist = true
      }

      if (isExist) {
        Modal.error({
          title: 'Unable to add more than one leave UFN',
          content: `${clientInfo.first_name} ${clientInfo.last_name} already have existing leave UFN added.`
        })
        break
      }
    }

    if (!isExist) {
      this.handleSave()
    }
  }

  handleDateChange = (rule, value, callback) => {
    const { form } = this.props
    const { list, modalItem } = this.state
    const { getFieldValue } = form

    const field = rule.field
    const startDate = rule.field === 'start_date' ? value : getFieldValue('start_date')
    const endDate = rule.field === 'end_date' ? value : getFieldValue('end_date')

    const std = !startDate ? null : moment.isMoment(startDate) ? startDate.startOf('day').clone() : moment(startDate).startOf('day')
    const etd = !endDate ? null : moment.isMoment(endDate) ? endDate.endOf('day').clone() : moment(endDate).endOf('day')

    if (std !== null && etd !== null) {
      if (etd.isBefore(std)) {
        callback(new Error(`End Leave should not be earlier than Start Leave`))
      } else {
        let isBetween = false

        for (let i = 0; i < list.length; i++) {
          const leave = list[i]

          const lsd = moment(leave.start_date)
          const led = moment(leave.end_date)

          if (modalItem && modalItem.id === leave.id) {
            isBetween = false
          } else if (leave.is_ufn === true) {
            isBetween = std.isSameOrAfter(lsd) || etd.isSameOrAfter(lsd)
          } else {
            isBetween = std.isBetween(lsd, led)
          }

          if (isBetween === true) {
            callback(new Error(`Date is overlapping with ${`${formatter.toShortDate(leave.start_date)} - ${leave.is_ufn ? 'UFN' : formatter.toShortDate(leave.end_date)}`}`))
            break
          }
        }

        if (isBetween === false) {
          callback()
        }
      }
    } else {
      callback()
    }

    this.validateImpactedJobs(std, etd)
  }

  validateImpactedJobs = async (std, etd = null) => {
    const { clientId, form } = this.props

    if (std) {
      const body = {
        type: 'client',
        type_id: clientId,
        start_date: std,
        end_date: etd
      }

      this.setState({ loadingValidate: true })
      try {
        const r = await jvpLeaveService.validateImpactedJobs(body)

        if (r && r.ts) {
          this.setState({ jobImpactInfo: r })
        }
      } catch (e) {

      }
      this.setState({ loadingValidate: false })
    }
  }

  handleSave = async () => {
    const { clientId, clientInfo = {}, form, infoUpdate } = this.props
    const { validateFieldsAndScroll } = form
    const { modalItem, isUFNTriggered } = this.state

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (!values.end_date && !isUFNTriggered) {
          this.handleLeaveSaveFailedAddUp()
        } else {
          const isEdit = modalItem && !!modalItem.id
          this.setState({ loadingUpdate: true }, async () => {
            try {
              let r = null
              if (isEdit) {
                r = await jvpLeaveService.save(modalItem.id, values)
              } else {
                values.is_approved = true //is_approved is automatically true in client leave
                values.type = 'client'
                values.type_id = clientId

                r = await jvpLeaveService.add(values)
              }

              if (r && r.id) {
                // CR0011 - remove the UFN Alert because no more leave jobs triggered after leave created/saved, which is different with previous case
                // // logs are moved to backend
                // // return data will have new flag "is_ufn_triggered" which will indicate ufn triggered status
                // // if is_ufn false -> true, is_ufn_triggered = true
                // // if is_ufn true -> false, is_ufn_triggered = false
                // // if is_ufn no change, is_ufn_triggered = null
                // if (r.is_ufn_triggered === true) {
                //   this.handleLeaveSaveSuccessfulWithAddUFN()
                // } else if (r.is_ufn_triggered === false) {
                //   this.handleLeaveSaveSuccessfulWithRemoveUFN()
                // } else {
                //   this.handleLeaveSaveSuccessful()
                // }
                this.handleLeaveSaveSuccessful()

                this.fetchLeaves()
                this.hideModal()

                infoUpdate && infoUpdate()
              } else {
                this.handleLeaveSaveFailed()
              }
            } catch (e) {
              this.handleLeaveSaveFailed()
            }

            this.setState({ loadingUpdate: false })
          })
        }
      }
    })
  }

  async handleDelete (item) {
    const { clientId } = this.props

    this.setState({ loadingUpdate: true }, async () => {
      try {
        const r = await jvpLeaveService.remove(item.id)

        if (r && r.id) {
          this.handleLeaveDeleteSuccessful()
          this.fetchLeaves()
        } else {
          this.handleLeaveDeleteFailed()
        }
      } catch (e) {
        this.handleLeaveDeleteFailed()
      }
      this.setState({ loadingUpdate: false })
    })
  }

  handleUFNClick = (value) => {
    const { form } = this.props
    this.setState({ isUFNTriggered: value })
    form.setFieldsValue({ end_date: null })

    const startDate = form.getFieldValue('start_date')
    const std = !startDate ? null : moment.isMoment(startDate) ? startDate.startOf('day').clone() : moment(startDate).startOf('day')

    this.validateImpactedJobs(std, null)
  }

  hideModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({ isShowModal: false, modalItem: {}, isUFNTriggered: false, jobImpactInfo: {} })
  }

  showModal = (modalItem = {}) => {
    const isUFNTriggered = modalItem && modalItem.is_ufn !== undefined ? !!modalItem.is_ufn : false
    const jobTotalCount = modalItem && modalItem.total_jobs !== undefined ? modalItem.total_jobs : null
    const jobAffectedCount = modalItem && modalItem.impacted_jobs !== undefined ? modalItem.impacted_jobs : null
    this.setState({ isShowModal: true, modalItem, isUFNTriggered, jobImpactInfo: { impacted_jobs: jobAffectedCount, total_jobs: jobTotalCount } })
  }

  handleLeaveSaveSuccessful = () => {
    notify.success('Saved successfully', 'Leave saved successfully.')
  }

  handleLeaveSaveSuccessfulWithAddUFN = () => {
    notify.success('Saved successfully', 'Leave saved successfully. Please wait for a moment to process the on hold jobs.')
  }

  handleLeaveSaveSuccessfulWithRemoveUFN = () => {
    notify.success('Saved successfully', 'Leave saved successfully. Please wait for a moment to reinstate jobs.')
  }

  handleLeaveSaveFailedAddUp = () => {
    notify.error('Unable to save successfully', 'Please fill in all required fields.')
  }

  handleLeaveSaveFailed = () => {
    notify.error('Unable to save successfully', 'Unable to save Leave successfully. Please try again later.')
  }

  handleLeaveDeleteSuccessful = () => {
    notify.success('Deleted successfully', 'Leave deleted successfully.')
  }

  handleLeaveDeleteFailed = () => {
    notify.success('Unable to delete successfully', 'Unable to delete Leave successfully. Please try again later.')
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientLeaveJvp))

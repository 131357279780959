import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import { ExportType } from '../../../constants'
import {
  authService, feedbackActionService, feedbackService, settingFeedbackCategoryService, settingFeedbackStatusService,
  settingFeedbackSubCategoryService
} from '../../../services'
import { exportFile, formatter } from '../../../util'

import { Button, Checkbox, ControlLabel, FeedbackStatus, List, Loading, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const pageSize = 40
const { Option } = Select
const { RangePicker } = DatePicker

export class ClientFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      feedbackCategories: [],
      feedbackStatuses: [],
      feedbackSubCategories: [],
      filter: {},
      isCheckFeedback: true,
      isCheckMsgShow: false,
      isGenerating: false,
      isShowExportModal: false,
      list: [],
      loading: true,
      searching: false,
      searchText: '',
      sort: {},
      total: 0
    }
    this.handleSearch = debounce(this.handleSearch, 500)
  }

  componentDidMount() {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchFeedbacks({ currentPage, filter, loading, searchText, sort })
    this.fetchFeedbackCategories()
    this.fetchFeedbackStatuses()
    this.fetchFeedbackSubCategories()
  }

  render() {
    const {
      currentPage, feedbackCategories, feedbackStatuses, feedbackSubCategories, isCheckFeedback, isCheckMsgShow, isGenerating,
      isShowExportModal, list, loading, searching, total
    } = this.state
    const columns = [
      {
        title: 'Incident Date',
        width: 2,
        key: 'occurred_at',
        render: ({ occurred_at: occurredAt }) => formatter.toShortDate(occurredAt)
      },
      {
        title: 'Report Date',
        width: 2,
        key: 'reported_at',
        render: ({ reported_at: reportedAt }) => formatter.toShortDate(reportedAt)
      },
      {
        title: 'Category',
        width: 4,
        key: 'category_name',
        render: ({ category_name: categoryName, sub_category_name: subCategoryName }) => `${categoryName} (${subCategoryName})`
      },
      {
        title: 'Description',
        width: 6,
        key: 'description'
      },
      {
        title: 'Updated At',
        width: 3,
        key: 'updated_at',
        render: ({ updated_at: updatedAt }) => formatter.toStandardDate(updatedAt)
      },
      {
        title: 'Updated By',
        width: 3,
        key: 'updated_by_name'
      },
      {
        title: 'Status',
        width: 3,
        render: ({ status, status_colour: statusColour, status_name: statusName }) => status
          ? <FeedbackStatus colour={statusColour} label={statusName} />
          : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => (
          <div className='action-icon'>
            {this.hasAccess('readFeedback') ? (
              <Link to={`/feedbacks/${id}`}>
                <div>
                  <Tooltip mouseLeaveDelay={0} title='Manage feedback'>
                    <Icon type='form' />
                  </Tooltip>
                </div>
              </Link>
            ) : null}
          </div>
        )
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='feedback-header'>
          <Row gutter={8}>
            <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
              <ControlLabel>FID, Employee, Client, Description, Location</ControlLabel>

              <SearchInput placeholder='Search feedback' onChange={this.handleSearch} isSearching={searching} />
            </Col>

            <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
              <ControlLabel>Category</ControlLabel>

              <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeFeedbackCategory} style={{ width: '100%' }}>
                {feedbackCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
              </Select>
            </Col>

            <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
              <ControlLabel>Sub-Category</ControlLabel>

              <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeFeedbackSubCategory} style={{ width: '100%' }}>
                {feedbackSubCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
              </Select>
            </Col>

            <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
              <ControlLabel>Status</ControlLabel>

              <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeStatus} style={{ width: '100%' }}>
                {feedbackStatuses.map(({ id, name }) => <Option key={id}>{name}</Option>)}
              </Select>
            </Col>

            <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {this.hasAccess('listFeedbacks') ? (
                  <div className='btn' onClick={() => this.handleExportModal(true)}>
                    {isGenerating ? <img src='/icon/button-loading.svg' alt='loading' /> : 'Export'}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          <div style={{ height: 10 }} />

          <Row gutter={8}>
            <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
              <ControlLabel>Incident Date</ControlLabel>

              <RangePicker allowClear onChange={this.handleChangeOccurredAt} style={{ width: '100%' }} />
            </Col>

            <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
              <ControlLabel>Report Date</ControlLabel>

              <RangePicker allowClear onChange={this.handleChangeReportedAt} style={{ width: '100%' }} />
            </Col>
          </Row>
        </div>

        <div className='feedback-list'>
          <Skeleton loading={loading} active>

            <List cols={columns} rows={list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} feedback${total === 1 ? '' : 's'}`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        {isShowExportModal ? (
          <Modal
            width='450px'
            title='Select Feedback Export Items'
            visible={isShowExportModal}
            onCancel={() => this.handleExportModal(false)}
            footer={[
              <div>
                <Button key='close' ghost feedback={isGenerating} onClick={() => this.handleExportModal(false)}>Cancel</Button>
                <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
              </div>
            ]}
          >
            <Spin spinning={isGenerating} blur>
              <Form>
                <div className='inv-title'>Please select item(s) to export:</div>

                <span>
                  <Checkbox
                    checked={isCheckFeedback}
                    onClick={f => this.handleCheckboxClick(f, {isCheckFeedback: true})}
                  />
                  <span className='checkbox-text'>Feedback Export</span>
                </span>

                <div className='checkbox-warning-text'>{isCheckMsgShow ? `Please select at least one item.` : ''}</div>
              </Form>
            </Spin>
          </Modal>
        ) : null}
      </Loading>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFeedbacks({ currentPage, filter, searchText, sort })
  }

  export = async () => {
    const { filter, isCheckFeedback } = this.state
    const data = {
      export_feedback: isCheckFeedback,
      query: { filter: JSON.stringify(filter) }
    }

    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_FEEDBACK, data)

      setTimeout(() => {
        this.setState({ isGenerating: false, isShowExportModal: false })
      }, 7000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get feedback export successfully. Please try again later.')
      this.setState({ isGenerating: false, isShowExportModal: false })
    }
  }

  fetchFeedbacks = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { clientId } = this.props
      filter.client_id = clientId
      this.setState({ currentPage, loading }, async () => {
        const { list, total } = await feedbackService.listByPage(currentPage, pageSize, filter, sort, searchText)
        this.setState({ list, loading: false, searching: false, total })
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load feedbacks successfully. Please try again later.')
    }
  }

  fetchFeedbackCategories = async () => {
    settingFeedbackCategoryService.getAll().then((feedbackCategories) => {
      this.setState({ feedbackCategories: Array.isArray(feedbackCategories) ? feedbackCategories : [] })
    })
  }

  fetchFeedbackStatuses = async () => {
    settingFeedbackStatusService.getAll().then((feedbackStatuses) => {
      this.setState({ feedbackStatuses: Array.isArray(feedbackStatuses) ? feedbackStatuses : [] })
    })
  }

  fetchFeedbackSubCategories = async () => {
    settingFeedbackSubCategoryService.getAll().then((feedbackSubCategories) => {
      this.setState({ feedbackSubCategories: Array.isArray(feedbackSubCategories) ? feedbackSubCategories : [] })
    })
  }

  handleChangeFeedbackCategory = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.category_id = value
      } else {
        delete filter.category_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeFeedbackSubCategory = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.sub_category_id = value
      } else {
        delete filter.sub_category_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeFeedbackType = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.feedback_type_id = value
      } else {
        delete filter.feedback_type_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeOccurredAt = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (Array.isArray(value) && value.length > 1) {
        filter.occurred_at = {
          $and: [{ condition: '>=', value: Moment(value[0]).startOf('day') }, { condition: '<=', value: Moment(value[1]).endOf('day') }]
        }
      } else {
        delete filter.occurred_at
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeReportedAt = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (Array.isArray(value) && value.length > 1) {
        filter.reported_at = {
          $and: [{ condition: '>=', value: Moment(value[0]).startOf('day') }, { condition: '<=', value: Moment(value[1]).endOf('day') }]
        }
      } else {
        delete filter.reported_at
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeStatus = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.status_id = value
      } else {
        delete filter.status_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleExpand = (index) => () => {
    const { list } = this.state

    if (index < list.length) {
      list[index].is_expand = !list[index].is_expand
    }

    this.setState({ list }, () => {
      const { id } = list[index]
      feedbackActionService.listByPage(1, 0, { feedback_id: id }).then((response) => {
        if (response && Array.isArray(response.list)) {
          list[index].feedback_actions = response.list
          this.setState({ list })
        }
      })
    })
  }

  handleExportModal (isShowExportModal) {
    this.setState({ isShowExportModal })
  }

  handleSearch = (value) => {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true }, () => {
      value = value ? value.trim() : value

      if (value.indexOf(' ') >= 0) {
        const words = value.split(' ')

        if (Array.isArray(words)) {
          filter.$and = []

          for (let i = 0; i < words.length; i++) {
            filter.$and.push({
              $or: [
                { case_no: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_employee_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_employee_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { description: { condition: 'ilike', value: `%${words[i]}%` } },
                { location: { condition: 'ilike', value: `%${words[i]}%` } }
              ]
            })
          }
        }
      } else {
        if (Array.isArray(filter.$and)) {
          delete filter.$and
        }
      }

      this.setState({ currentPage: 1, searchText: value }, () => {
        this.fetchFeedbacks({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
      })
    })
  }

  preCheckExport () {
    const { isCheckMsgShow } = this.state

    if (!isCheckMsgShow) {
      this.export()
    }
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientFeedback)

import React, { Component } from 'react'
import { cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { communicationService, jobService, fvpJobCostService } from '../../../../services'
import { setRefreshCommLog } from '../../../../states/actions'
import { formatter, log, validator } from '../../../../util'
import moment from 'moment-timezone'

// UI
import { Loading, List, Panel, Button, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { jobLegacyURL, timezone } from '../../../../config'

const { confirm, error, info, warning } = Modal
const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane
const pageSize = 20

moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY hh:mmA'

const ColTitle = 10
const ColCostContent1 = 4
const ColCostContent2 = 5
const ColCostContent3 = 5
const ColPayrollContent1 = 6
const ColPayrollContent2 = 7
const ColContent = 14

const HOLIDAY_TEXT = 'Public Holiday'

export class JobCost extends Component {
  constructor (props) {
    super(props)
    this.state = {
      breakdown: '',
      costDetail: { billing_category_id: null, current_cost: {}, default_cost: {}, is_cancel: true },
      prevCostDetail: { billing_category_id: null, current_cost: {}, default_cost: {} },
      payrollDetail: { current_payroll: {}, current_payroll_ext: [], default_payroll: {}, default_payroll_ext: [] },
      prevPayrollDetail: { current_payroll: {}, current_payroll_ext: [], default_payroll: {}, default_payroll_ext: [] },
      isSameDay: true,
      job: {},
      jobId: null,
      loadingCost: false,
      loadingCostUpdate: false,
      loadingPayroll: false,
      loadingPayrollUpdate: false,
      employeeIdText: null,
      payrollText: null,
      tabActiveKey: '1'
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { breakdown = '', jobId = '', job = {} } = nextProps

    let isSameDay = prevState.isSameDay

    if (job && job.id) {
      const std = moment(job.job_start_date)
      const etd = moment(job.job_end_date)

      isSameDay = std.isSame(etd, 'day')
      job.cst_dt_text = formatter.toDurationTextWithDay(job.job_start_date, job.job_end_date)
    }

    return { ...prevState, breakdown, isSameDay, job, jobId, payrollText: job.payroll || null }
  }

  componentDidMount () {
    this.fetchCost()
    this.fetchPayroll()
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { breakdown, costDetail, payrollDetail, payrollText, jobId, job, isSameDay, loadingCost, loadingCostUpdate, loadingPayrollUpdate, tabActiveKey } = this.state
    const { current_cost: cfc, default_cost: dfc, d1_holiday_name: d1HolidayName, d2_holiday_name: d2HolidayName, is_emergency: isEmergency, fs_emergency: fsEmergency, fs_emergency_pay: fsEmergencyPay, fs_emergency_invoice: fsEmergencyInv, is_cancel: isCancel } = costDetail
    const { current_payroll: cfp, default_payroll: dfp } = payrollDetail

    const isCostJobSameDay = dfc.d2_start_day === 0 || dfc.d1_start_day === dfc.d2_start_day
    const isPayrollJobSameDay = dfp.d1_day !== undefined && dfp.d2_day !== undefined ? dfp.d1_day === dfp.d2_day : null

    const getEmgText = () => {
      let txt = ''

      if (isEmergency) {
        if (fsEmergency) {
          txt += `${txt ? ' / ' : ''}EMG`
        }

        if (fsEmergencyPay) {
          txt += `${txt ? ' / ' : ''}EMG Pay`
        }

        if (fsEmergencyInv) {
          txt += `${txt ? ' / ' : ''}EMG Invoice`
        }
      } else {
        txt = 'NO'
      }

      return txt
    }

    return (
      <Loading loading={loadingCostUpdate || loadingPayrollUpdate} blur>
        <Skeleton loading={loadingCost} active>
          { isCancel
            ? <div className='cst unavailable'>
              <Row>
                {'Unable to get costing info for Cancelled Jobs.'}
              </Row>
            </div>
            : <div className='cst'>
              <Panel
                title={`Job Summary`}
              >
                <Row>
                  {this.renderItem({title: 'Job Date', content: `${job.cst_dt_text} ${breakdown ? `(${breakdown})` : ''}${payrollText ? ` (${formatter.capitalize(payrollText)})` : ''}`}) }
                  {this.renderItem({title: 'Client', content: <div><a href={`/clients/${costDetail.client_id}`} rel='noopener noreferrer' target='_blank'>{`${costDetail.client_name} (${costDetail.client_acc_ref})`}</a></div>})}
                  {this.renderItem({title: 'Employee', content: <div><a href={`/employees/${costDetail.employee_id}`} rel='noopener noreferrer' target='_blank'>{`${costDetail.employee_name} (${costDetail.employee_acc_ref})`}</a></div>})}
                  {this.renderItem({title: 'Shift Type', content: `${costDetail.billing_category_name || ''}`}) }
                  {this.renderItem({title: 'Is Emergency Job?', content: `${getEmgText()}`, isHighlight: isEmergency}) }
                  {this.renderItem({title: 'Is Invoice Updated?', content: `${cfc.is_forced_update ? 'YES' : 'NO'}`, isHighlight: cfc.is_forced_update}) }
                  {this.renderItem({title: 'Is Payroll Updated?', content: `${dfp.job_id === undefined ? 'Pending' : !!cfp.id ? 'YES' : 'NO'}`, isHighlight: !!cfp.id}) }
                </Row>
              </Panel>
              <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)} >
                <TabPane tab={<div>Invoice</div>} key='1'>
                  <Row gutter={8}>
                    <Col lg={8}>
                    </Col>
                    <Col lg={16}>
                      <Row className='row-content'>
                        <div className='btn btn-ghost' onClick={() => this.onCostRefreshCheck(cfc.is_forced_update)}>
                          { cfc.is_forced_update ? 'Revert Invoice' : 'Refresh Invoice' }
                        </div>
                        <div className='btn' onClick={() => this.onCostUpdateCheck()}>
                          Update Invoice
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={12}>
                      <Panel
                        panelStyle={{backgroundColor: '#ddd'}}
                        title={`Original Invoice`}
                      >
                        { this.renderCostDaySection(costDetail, '1') }
                        { !isCostJobSameDay ? this.renderCostDaySection(costDetail, '2') : null }
                        { this.renderCostKmsSection(costDetail) }
                        { this.renderCostSubtotalSection(costDetail) }
                      </Panel>
                    </Col>
                    <Col lg={12}>
                      <Panel
                        title={`Current Invoice`}
                        subtitle={cfc.is_forced_update ? <div style={{color: 'red'}}>UPDATED</div> : null}
                      >
                        <Form key={`kcms`}>
                          { this.renderCostDaySection(costDetail, '1', true) }
                          { !isCostJobSameDay ? this.renderCostDaySection(costDetail, '2', true) : null }
                          { this.renderCostKmsSection(costDetail, true) }
                          { this.renderCostSubtotalSection(costDetail, true) }
                        </Form>
                      </Panel>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab={<div>Payroll</div>} key='2'>
                  <Row gutter={8}>
                    <Col lg={8}>
                    </Col>
                    <Col lg={16}>
                      <Row className='row-content'>
                        { cfp.id
                          ? <div className='btn btn-ghost' onClick={() => this.onPayrollRefreshCheck()}>
                            Revert Payroll
                          </div>
                          : null }
                        <div className='btn' onClick={() => this.onPayrollUpdateCheck()}>
                          Update Payroll
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={12}>
                      <Panel
                        panelStyle={{backgroundColor: '#ddd'}}
                        title={`Original Payroll`}
                      >
                        { this.renderPayrollDaySection(payrollDetail, '1') }
                        { isPayrollJobSameDay === false ? this.renderPayrollDaySection(payrollDetail, '2') : null }
                        { this.renderPayrollKmsSection(payrollDetail) }
                        { this.renderPayrollSummarySection(payrollDetail) }
                      </Panel>
                    </Col>
                    <Col lg={12}>
                      <Panel
                        title={`Current Payroll`}
                        subtitle={cfp.id ? <div style={{color: 'red'}}>UPDATED</div> : null}
                      >
                        <Form key={`kcps`}>
                          { this.renderPayrollDaySection(payrollDetail, '1', true) }
                          { isPayrollJobSameDay === false ? this.renderPayrollDaySection(payrollDetail, '2', true) : null }
                          { this.renderPayrollKmsSection(payrollDetail, true) }
                          { this.renderPayrollRmsSection(payrollDetail, true)}
                          { this.renderPayrollSummarySection(payrollDetail, true) }
                        </Form>
                      </Panel>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div> }
        </Skeleton>
      </Loading>
    )
  }

  /** Costing section */
  renderItem ({lg = 18, isHighlight = false, title, content, titleWidth = 6, contentWidth = 14}) {
    return (
      <Col lg={lg}>
        <Row className='row' gutter={24}>
          <Col lg={titleWidth}>
            <div className='title'>
              { title }:
            </div>
          </Col>
          <Col lg={contentWidth}>
            <div className={`content ${isHighlight ? 'highlight' : ''}`}>
              { content }
            </div>
          </Col>
        </Row>
      </Col>
    )
  }

  renderCostRow ({
    isTitle = false,
    isRowTitle = false,
    isInput = false,
    icons = [],
    title,
    content1,
    content2,
    content3,
    content1Key,
    content2Key,
    content3Key,
    content1OnChange,
    content2OnChange,
    content3OnChange
  }) {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { loadingCostUpdate } = this.state

    const renderTD = ({lg = ColCostContent1, content, contentKey, contentOnChange, isInputRender, isRowTitle}) => {
      return <Col lg={lg}>
        <div className={isRowTitle !== undefined ? (`row-title ${isRowTitle ? 'theme' : ''}`) : isTitle ? 'row-content-title' : 'row-content'}>
          { isInputRender && contentKey && validator.isFunction(contentOnChange)
            ? <FormItem>
              { getFieldDecorator(`${contentKey}`, {
                initialValue: content,
                rules: [
                  { validator: this.validateCostQtyChange },
                  { required: true, message: ' ' }
                ],
              })(
                <Input
                  onChange={contentOnChange}
                  disabled={loadingCostUpdate}
                />
              )}
            </FormItem>
            : content }
        </div>
      </Col>
    }
    return (
      <Row className='row' gutter={5}>
        { renderTD({
          lg: ColTitle,
          content: title,
          isInputRender: false,
          isRowTitle
        }) }
        { renderTD({
          lg: ColCostContent1,
          content: content1,
          contentKey: content1Key,
          contentOnChange: content1OnChange,
          isInputRender: isInput
        }) }
        { renderTD({
          lg: ColCostContent2,
          content: content2,
          contentKey: content2Key,
          contentOnChange: content2OnChange,
          isInputRender: isInput
        }) }
        { renderTD({
          lg: ColCostContent3,
          content: content3,
          contentKey: content3Key,
          contentOnChange: content3OnChange,
          isInputRender: isInput
        }) }
      </Row>
    )
  }

  renderCostRowSingleInput ({
    isTitle = false,
    isRowTitle = false,
    isInput = false,
    icons = [],
    title,
    content,
    contentKey,
    contentOnChange,
  }) {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { loadingCostUpdate } = this.state

    const renderTD = ({lg = ColCostContent1, content, contentKey, contentOnChange = () => {}, isInputRender, isRowTitle}) => {
      return <Col lg={lg}>
        <div className={isRowTitle !== undefined ? (`row-title ${isRowTitle ? 'theme' : ''}`) : isTitle ? 'row-content-title' : 'row-content-single'}>
          { isInputRender && contentKey && validator.isFunction(contentOnChange)
            ? <FormItem>
              { getFieldDecorator(`${contentKey}`, {
                initialValue: content
              })(
                <TextArea
                  rows={2}
                  onChange={contentOnChange}
                  disabled={loadingCostUpdate}
                />
              )}
            </FormItem>
            : content }
        </div>
      </Col>
    }
    return (
      <Row className='row row-single-input' gutter={5}>
        { renderTD({
          lg: ColTitle,
          content: title,
          isInputRender: false,
          isRowTitle
        }) }
        { renderTD({
          lg: ColContent,
          content: content,
          contentKey: contentKey,
          contentOnChange: contentOnChange,
          isInputRender: isInput
        }) }
      </Row>
    )
  }

  renderCostDaySection (dtc, day = '1', isCurrent = false) {
    const { default_cost: dcc, current_cost: cfc, d1_holiday_name: d1HolidayName, d2_holiday_name: d2HolidayName, is_emergency: isEmergency } = dtc

    const dfc = isCurrent ? cfc : dcc

    return (<div className='section'>
      { this.renderCostRow({
        isTitle: true,
        title: dfc[`d${day}_is_holiday`]
        ? <div className='title-flex-start highlight'>Day {day} <span>({dfc[`d${day}_holiday_name`]})</span></div>
        : <div className='title-flex-start'>Day {day}</div>,
        content1: 'Unit',
        content2: 'Rate',
        content3: 'Subtotal',
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'Normal Hours',
        content1: dfc[`d${day}n_unit`],
        content2: dfc[`d${day}n_rate`],
        content3: dfc[`d${day}n_subtotal`],
        content1Key: `d${day}n_unit`,
        content2Key: `d${day}n_rate`,
        content3Key: `d${day}n_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}n_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}n_rate`)
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'Normal Hours EMG Loading',
        content1: dfc[`d${day}nl_unit`],
        content2: dfc[`d${day}nl_rate`],
        content3: dfc[`d${day}nl_subtotal`],
        content1Key: `d${day}nl_unit`,
        content2Key: `d${day}nl_rate`,
        content3Key: `d${day}nl_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}nl_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}nl_rate`)
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'After Hours',
        content1: dfc[`d${day}a_unit`],
        content2: dfc[`d${day}a_rate`],
        content3: dfc[`d${day}a_subtotal`],
        content1Key: `d${day}a_unit`,
        content2Key: `d${day}a_rate`,
        content3Key: `d${day}a_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}a_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}a_rate`)
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'After Hours EMG Loading',
        content1: dfc[`d${day}al_unit`],
        content2: dfc[`d${day}al_rate`],
        content3: dfc[`d${day}al_subtotal`],
        content1Key: `d${day}al_unit`,
        content2Key: `d${day}al_rate`,
        content3Key: `d${day}al_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}al_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}al_rate`)
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'Sleepover',
        content1: dfc[`d${day}s_unit`],
        content2: dfc[`d${day}s_rate`],
        content3: dfc[`d${day}s_subtotal`],
        content1Key: `d${day}s_unit`,
        content2Key: `d${day}s_rate`,
        content3Key: `d${day}s_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}s_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}s_rate`)
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'Sleepover EMG Loading',
        content1: dfc[`d${day}sl_unit`],
        content2: dfc[`d${day}sl_rate`],
        content3: dfc[`d${day}sl_subtotal`],
        content1Key: `d${day}sl_unit`,
        content2Key: `d${day}sl_rate`,
        content3Key: `d${day}sl_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `d${day}sl_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `d${day}sl_rate`)
      }) }
      {/* { this.renderCostRow({
        isInput: isCurrent,
        title: `Day ${day} Adjustment`,
        content3: dfc[`d${day}_adj`],
        content3Key: `d${day}_adj`,
        content3OnChange: (v) => this.onChangeCostQty(v, `d${day}_adj`)
      }) } */}
      { this.renderCostRow({
        isTitle: true,
        isRowTitle: true,
        title: `Day ${day} Subtotal`,
        content3: dfc[`d${day}_subtotal`],
        content3Key: `d${day}_subtotal`,
      }) }
      { isCurrent
        ? this.renderCostRowSingleInput({
          isInput: isCurrent,
          title: `Day ${day} Adjustment Note`,
          content: dfc[`d${day}_adj_notes`],
          contentKey: `d${day}_adj_notes`,
          contentOnChange: (v) => this.onChangeCostValue(v, `d${day}_adj_notes`)
        })
        : null }
    </div>)
  }

  renderCostKmsSection (dtc, isCurrent = false) {
    const { default_cost: dcc, current_cost: cfc, d1_holiday_name: d1HolidayName, d2_holiday_name: d2HolidayName, is_emergency: isEmergency } = dtc

    const dfc = isCurrent ? cfc : dcc

    return (<div className='section'>
      { this.renderCostRow({
        isTitle: true,
        isRowTitle: true,
        title: 'KMS',
        content1: 'Unit',
        content2: 'Rate',
        content3: 'Subtotal',
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'KMS',
        content1: dfc[`kms_unit`],
        content2: dfc[`kms_rate`],
        content3: dfc[`kms_imt_subtotal`],
        content1Key: `kms_unit`,
        content2Key: `kms_rate`,
        content3Key: `kms_imt_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `kms_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `kms_rate`)
      }) }
      { isCurrent
        ? this.renderCostRowSingleInput({
          isInput: isCurrent,
          title: `KMS Adjustment Note`,
          content: dfc[`kms_adj_notes`],
          contentKey: `kms_adj_notes`,
          contentOnChange: (v) => this.onChangeCostValue(v, `kms_adj_notes`)
        })
        : null }

      <div className='row' />

      { isCurrent
        ? this.renderCostRow({
          isTitle: true,
          isRowTitle: true,
          title: 'Additional Travel',
          content1: 'Unit',
          content2: 'Rate',
          content3: 'Subtotal',
        })
      : null }
      { isCurrent
        ? this.renderCostRow({
          isInput: isCurrent,
          title: 'Travel',
          content1: dfc[`kms_extra_unit`],
          content2: dfc[`kms_extra_rate`],
          content3: dfc[`kms_extra_subtotal`],
          content1Key: `kms_extra_unit`,
          content2Key: `kms_extra_rate`,
          content3Key: `kms_extra_subtotal`,
          content2OnChange: (v) => this.onChangeCostQty(v, `kms_extra_rate`),
          content3OnChange: (v) => this.onChangeCostQty(v, `kms_extra_subtotal`)
        })
      : null }
      { isCurrent
        ? this.renderCostRowSingleInput({
          isInput: isCurrent,
          title: `Travel Note`,
          content: dfc[`kms_extra_adj_notes`],
          contentKey: `kms_extra_adj_notes`,
          contentOnChange: (v) => this.onChangeCostValue(v, `kms_extra_adj_notes`)
        })
        : null }

      <div className='row' />

      { this.renderCostRow({
        isTitle: true,
        isRowTitle: true,
        title: `KMS Subtotal`,
        content3: dfc[`kms_subtotal`],
      }) }
    </div>)
  }

  renderCostKmsExtraSection (dtc, isCurrent = false) {
    const { default_cost: dcc, current_cost: cfc, d1_holiday_name: d1HolidayName, d2_holiday_name: d2HolidayName, is_emergency: isEmergency } = dtc

    const dfc = isCurrent ? cfc : dcc

    return (<div className='section'>
      { this.renderCostRow({
        isTitle: true,
        isRowTitle: true,
        title: 'Additional Travel',
        content1: 'Unit',
        content2: 'Rate',
        content3: 'Subtotal',
      }) }
      { this.renderCostRow({
        isInput: isCurrent,
        title: 'Travel',
        content1: dfc[`kms_extra_unit`],
        content2: dfc[`kms_extra_rate`],
        content3: dfc[`kms_extra_subtotal`],
        content1Key: `kms_extra_unit`,
        content2Key: `kms_extra_rate`,
        content3Key: `kms_extra_subtotal`,
        content1OnChange: (v) => this.onChangeCostQty(v, `kms_extra_unit`),
        content2OnChange: (v) => this.onChangeCostQty(v, `kms_extra_rate`)
      }) }
      { isCurrent
        ? this.renderCostRowSingleInput({
          isInput: isCurrent,
          title: `Travel Note`,
          content: dfc[`kms_extra_adj_notes`],
          contentKey: `kms_extra_adj_notes`,
          contentOnChange: (v) => this.onChangeCostValue(v, `kms_extra_adj_notes`)
        })
        : null }
    </div>)
  }

  renderCostSubtotalSection (dtc, isCurrent = false) {
    const { default_cost: dcc, current_cost: cfc, d1_holiday_name: d1HolidayName, d2_holiday_name: d2HolidayName, is_emergency: isEmergency } = dtc

    const dfc = isCurrent ? cfc : dcc

    return (<div className='section end'>
      { this.renderCostRow({
        isTitle: true,
        isRowTitle: true,
        title: `Invoice Subtotal`,
        content3: this.toFixed(dfc[`subtotal`]),
      }) }
    </div>)
  }

  /**
   * onChangeCostQty: enter unit and rate to update subtotal
   * onChangeQtyCost: enter rate and subtotal to update unit
   */
  onChangeCostQty = (e, key) => {
    const { costDetail } = this.state
    const { current_cost: cfc } = costDetail
    const value = e.target.value

    if (key && value !== undefined) {
      const unitIndex = key.indexOf('_unit')
      const rateIndex = key.indexOf('_rate')
      const subtotalIndex = key.indexOf('_subtotal')
      const adjIndex = key.indexOf('_adj')

      // do for unit and rate update
      if (unitIndex > -1) {
        if (/^d1/.test(key) || /^d2/.test(key)) {
          const prefix = key.substring(0, unitIndex)
          cfc[`${prefix}_unit`] = value
          cfc[`${prefix}_subtotal`] = formatter.toDecimalV(parseFloat(cfc[`${prefix}_unit`]) * parseFloat(cfc[`${prefix}_rate`]))

          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms_extra/.test(key)) {
          cfc[`kms_extra_unit`] = value
          cfc[`kms_extra_subtotal`] = formatter.toDecimalV(parseFloat(cfc[`kms_extra_unit`]) * parseFloat(cfc[`kms_extra_rate`]))

          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms/.test(key)) {
          cfc[`kms_unit`] = value
          cfc[`kms_imt_subtotal`] = formatter.toDecimalV(parseFloat(cfc[`kms_unit`]) * parseFloat(cfc[`kms_rate`]))

          this.onUpdateCostSubtotal(cfc)
        }
      } else if (rateIndex > -1) {
        if (/^d1/.test(key) || /^d2/.test(key)) {
          const prefix = key.substring(0, rateIndex)
          cfc[`${prefix}_rate`] = value
          cfc[`${prefix}_subtotal`] = formatter.toDecimalV(parseFloat(cfc[`${prefix}_unit`]) * parseFloat(cfc[`${prefix}_rate`]))
          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms_extra/.test(key)) {
          cfc[`kms_extra_rate`] = value
          cfc[`kms_extra_unit`] = value === 0 ? 0 : formatter.toDecimalV(parseFloat(cfc[`kms_extra_subtotal`]) / parseFloat(cfc[`kms_extra_rate`]))
          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms/.test(key)) {
          cfc[`kms_rate`] = value
          cfc[`kms_imt_subtotal`] = formatter.toDecimalV(parseFloat(cfc[`kms_unit`]) * parseFloat(cfc[`kms_rate`]))

          this.onUpdateCostSubtotal(cfc)
        }
      } else if (subtotalIndex > -1) {
        // subtotal only works on kms_extra inputs. other fields remain unchanged
        if (/^kms_extra/.test(key)) {
          cfc[`kms_extra_subtotal`] = value
          cfc[`kms_extra_unit`] = parseFloat(cfc[`kms_extra_rate`]) === 0 ? 0 : formatter.toDecimalV(parseFloat(cfc[`kms_extra_subtotal`]) / parseFloat(cfc[`kms_extra_rate`]))

          this.onUpdateCostSubtotal(cfc)
        }
      } else if (adjIndex > -1) {
        if (/^d1/.test(key) || /^d2/.test(key)) {
          const prefix = key.substring(0, adjIndex)
          cfc[`${prefix}_adj`] = isNaN(parseFloat(value)) ? 0 : formatter.toDecimalV(parseFloat(value))

          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms/.test(key)) {
          cfc[`kms_adj`] = isNaN(parseFloat(value)) ? 0 : formatter.toDecimalV(parseFloat(value))

          this.onUpdateCostSubtotal(cfc)
        } else if (/^kms/.test(key)) {
          cfc[`kms_adj`] = isNaN(parseFloat(value)) ? 0 : formatter.toDecimalV(parseFloat(value))

          this.onUpdateCostSubtotal(cfc)
        }
      }
    }
  }

  onChangeCostValue = (e, key) => {
    const { costDetail } = this.state
    const { current_cost: cfc } = costDetail
    const value = e.target.value

    if (key && value !== undefined) {
      cfc[`${key}`] = value
    }
  }

  onUpdateCostSubtotal = (cfc) => {
    let sumD1 = 0
    let sumD2 = 0
    let sumKMS = 0

    /** remove cfc['d1_adj'], cfc['d2_adj'] and cfc['kms_adj'] based on request */
    sumD1 = parseFloat(cfc['d1n_subtotal']) + parseFloat(cfc['d1nl_subtotal']) + parseFloat(cfc['d1a_subtotal']) + parseFloat(cfc['d1al_subtotal']) + parseFloat(cfc['d1s_subtotal'])
    sumD2 = parseFloat(cfc['d2n_subtotal']) + parseFloat(cfc['d2nl_subtotal']) + parseFloat(cfc['d2a_subtotal']) + parseFloat(cfc['d2al_subtotal']) + parseFloat(cfc['d2s_subtotal'])

    // kms_imt_subtotal has now become the sum of kms_rate * kms_unit, and kms_subtotal become the sum of kms_imt_subtotal and kms_adj
    // update: remove kms_adj as per request so sumKMS will be equal to parseFloat(cfc['kms_imt_subtotal'])
    sumKMS = parseFloat(cfc['kms_imt_subtotal']) + parseFloat(cfc['kms_extra_subtotal'])
    const sum = sumD1 + sumD2 + sumKMS

    cfc['kms_subtotal'] = this.toFixed(sumKMS)
    cfc['d1_subtotal'] = this.toFixed(sumD1)
    cfc['d2_subtotal'] = this.toFixed(sumD2)
    cfc['subtotal'] = this.toFixed(sum)

    return cfc
  }

  validateCostQtyChange = (rule, value, callback) => {
    const { form } = this.props
    const field = rule.field
    if (value === null || value === undefined || value === '') {
      callback(new Error(` `))
    } else {
      const v = field && field.indexOf('_adj') > -1
        ? validator.isNumberTextNegative(value)
        : field && field === 'kms_extra_rate' // check kms_extra_rate and validate value is whether 0 if kms_extra_subtotal is not 0
          ? (parseFloat(form.getFieldValue('kms_extra_subtotal')) !== 0
            ? validator.isNumberText(value) && parseFloat(value) !== 0
            : validator.isNumberText(value))
          : validator.isNumberText(value)

      if (!v) {
        callback(new Error(` `))
      } else {
        callback()
      }
    }

    if (field) {
      if (field === 'kms_extra_subtotal') {
        form.validateFields(['kms_extra_rate'])
      }
    }
  }

  fetchCost = async () => {
    const { jobId } = this.props

    this.setState({ loadingCost: true })
    const dt = await fvpJobCostService.getCostingDetail(jobId)

    if (dt && dt.billing_category_id) {
      this.setState({ costDetail: dt, prevCostDetail: cloneDeep(dt), loadingCost: false })
    } else {
      this.setState({ loadingCost: false })
    }
  }

  onCostRefreshCheck = (isForceUpdated) => {
    const { loadingCost, loadingCostUpdate } = this.state
    const { refreshCost } = this

    if (loadingCost || loadingCostUpdate) return

    const title = isForceUpdated ? 'Proceed To Revert to Original Costing? ' : 'Proceed To Refresh Original Costing?'
    const content = isForceUpdated
      ? <div>
        Do you want to revert to the original costing for this job?<br/><br/>This job will be unlocked after reverting. <br/><span style={{fontWeight: '700', color: 'red'}}>All of your edited costing will be lost permanently after revert.</span>
      </div>
      : <div>
        <p>Do you want to refresh original costing for this job?</p>
      </div>

    confirm({
      title: title,
      content: content,
      onOk () {
        // eslint-disable-next-line no-lone-blocks
        refreshCost(isForceUpdated)
      },
      onCancel () {
      }
    })
  }

  refreshCost = async (isForceUpdated) => {
    const { jobId } = this.props
    const { costDetail } = this.state
    const { current_cost: cfc } = costDetail
    const actionType = isForceUpdated ? 'Revert Costing' : 'Refresh Costing'
    const actionText = isForceUpdated ? 'reverted' : 'refreshed'

    try {
      this.setState({ loadingCostUpdate: true })
      const dt = await fvpJobCostService.refreshCostingDetail(jobId, {})

      if (dt && dt.id) {
        log.updateJobCostingPayroll(jobId, `${actionType}`, `Costing has been ${actionText} and it is up to date.`)

        this.setState({ loadingCostUpdate: false }, () => {
          notify.success(`${actionType} Successfully`, `Costing is ${actionText} successfully`)
          this.fetchCost()
        })
      } else {
        notify.error(`Unable to ${actionType}`, `Unable to ${actionType.toLocaleLowerCase()}. Please try again later.`)
        this.setState({ loadingCostUpdate: false })
      }
    } catch (e) {
      notify.error(`Unable to ${actionType}`, `Unable to ${actionType.toLocaleLowerCase()}. Please try again later.`)
      this.setState({ loadingCostUpdate: false })
    }
  }

  onCostUpdateCheck = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { loadingCost, loadingCostUpdate } = this.state
    const { updateCost } = this

    if (loadingCost || loadingCostUpdate) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        confirm({
          title: 'Proceed To Update Costing?',
          content: (
            <div>
              <p>Do you want to update the costing for this job? This will affect the amount shown in rows of invoice extract.</p>
              <p>Even your costing update could be same with default costing, the job details will be locked after this update action. However, you could revert the costing to default costing after update.</p>
            </div>
          ),
          onOk () {
            // eslint-disable-next-line no-lone-blocks
            updateCost(values)
          },
          onCancel () {
          }
        })
      } else {
        error({
          title: <p style={{color: 'red'}}>Invalid Values</p>,
          content: (
            <div>
              <p style={{color: 'red'}}>Some of your values entered are invalid. Please update before proceed.</p>
            </div>
          )
        })
      }
    })
  }

  updateCost = async () => {
    const { jobId } = this.props
    const { costDetail, loadingCost, loadingCostUpdate, prevCostDetail } = this.state
    const { current_cost: cfc } = costDetail
    const { current_cost: pcfc } = prevCostDetail

    if (loadingCost || loadingCostUpdate) return

    this.setState({ loadingCostUpdate: true }, async () => {
      try {
        const dt = await fvpJobCostService.updateCostingDetail(jobId, cfc)

        if (dt && dt.id) {
          cfc.is_forced_update = dt.is_forced_update

          const prev = this.trimCostValue(pcfc)
          const curr = this.trimCostValue(cfc)

          const compare = log.generateItemChanges(prev, curr, undefined, [
            { key: 'd1_subtotal', label: 'Day 1 Subtotal' },
            { key: 'd1a_rate', label: 'Day 1 After Hours Rate' },
            { key: 'd1a_unit', label: 'Day 1 After Hours Unit' },
            { key: 'd1a_subtotal', label: 'Day 1 After Hours Subtotal' },
            { key: 'd1al_rate', label: 'Day 1 After Hours EMG Loading Rate' },
            { key: 'd1al_unit', label: 'Day 1 After Hours EMG Loading Unit' },
            { key: 'd1al_subtotal', label: 'Day 1 After Hours EMG Loading Subtotal' },
            { key: 'd1n_rate', label: 'Day 1 Normal Hours Rate' },
            { key: 'd1n_unit', label: 'Day 1 Normal Hours Unit' },
            { key: 'd1n_subtotal', label: 'Day 1 Normal Hours Subtotal' },
            { key: 'd1nl_rate', label: 'Day 1 Normal Hours EMG Loading Rate' },
            { key: 'd1nl_unit', label: 'Day 1 Normal Hours EMG Loading Unit' },
            { key: 'd1nl_subtotal', label: 'Day 1 Normal Hours EMG Loading Subtotal' },
            { key: 'd1s_rate', label: 'Day 1 Sleepover Rate' },
            { key: 'd1s_unit', label: 'Day 1 Sleepover Unit' },
            { key: 'd1s_subtotal', label: 'Day 1 Sleepover EMG Loading Subtotal' },
            { key: 'd1sl_rate', label: 'Day 1 Sleepover EMG Loading Rate' },
            { key: 'd1sl_unit', label: 'Day 1 Sleepover EMG Loading Unit' },
            { key: 'd1sl_subtotal', label: 'Day 1 Sleepover EMG Loading Subtotal' },
            { key: 'd1_adj', label: 'Day 1 Adjustment' },
            { key: 'd1_adj_notes', label: 'Day 1 Adjustment Notes' },

            { key: 'd2_subtotal', label: 'Day 2 Subtotal' },
            { key: 'd2a_rate', label: 'Day 2 After Hours Rate' },
            { key: 'd2a_unit', label: 'Day 2 After Hours Unit' },
            { key: 'd2a_subtotal', label: 'Day 2 After Hours Subtotal' },
            { key: 'd2al_rate', label: 'Day 2 After Hours EMG Loading Rate' },
            { key: 'd2al_unit', label: 'Day 2 After Hours EMG Loading Unit' },
            { key: 'd2al_subtotal', label: 'Day 2 After Hours EMG Loading Subtotal' },
            { key: 'd2n_rate', label: 'Day 2 Normal Hours Rate' },
            { key: 'd2n_unit', label: 'Day 2 Normal Hours Unit' },
            { key: 'd2n_subtotal', label: 'Day 2 Normal Hours Subtotal' },
            { key: 'd2nl_rate', label: 'Day 2 Normal Hours EMG Loading Rate' },
            { key: 'd2nl_unit', label: 'Day 2 Normal Hours EMG Loading Unit' },
            { key: 'd2nl_subtotal', label: 'Day 2 Normal Hours EMG Loading Subtotal' },
            { key: 'd2s_rate', label: 'Day 2 Sleepover Rate' },
            { key: 'd2s_unit', label: 'Day 2 Sleepover Unit' },
            { key: 'd2s_subtotal', label: 'Day 2 Sleepover EMG Loading Subtotal' },
            { key: 'd2sl_rate', label: 'Day 2 Sleepover EMG Loading Rate' },
            { key: 'd2sl_unit', label: 'Day 2 Sleepover EMG Loading Unit' },
            { key: 'd2sl_subtotal', label: 'Day 2 Sleepover EMG Loading Subtotal' },
            { key: 'd2_adj', label: 'Day 2 Adjustment' },
            { key: 'd2_adj_notes', label: 'Day 2 Adjustment Notes' },

            { key: 'kms_rate', label: 'KMS Rate' },
            { key: 'kms_unit', label: 'KMS Unit' },
            { key: 'kms_imt_subtotal', label: 'KMS Unit Subtotal' },
            { key: 'kms_subtotal', label: 'KMS Final Subtotal' },
            { key: 'kms_adj', label: 'KMS Adjustment' },
            { key: 'kms_adj_notes', label: 'KMS Adjustment Notes' },
            { key: 'kms_extra_rate', label: 'Travel Rate' },
            { key: 'kms_extra_unit', label: 'Travel Unit' },
            { key: 'kms_extra_subtotal', label: 'Travel Subtotal' },
            { key: 'kms_extra_adj_notes', label: 'Travel Notes' },
            { key: 'kms_subtotal', label: 'KMS Final Subtotal' },
            { key: 'subtotal', label: 'Costing Subtotal' },
            { key: 'is_forced_update', label: 'Costing Force Update' },
          ])

          log.updateJobCostingPayroll(jobId, 'Update Costing', compare)

          this.setState({ loadingCostUpdate: false }, () => {
            notify.success('Update Costing Successfully', 'New Costing is updated successfully')
            this.fetchCost()
          })
        } else {
          notify.error('Unable to Update Costing', 'Unable to update new costing. Please try again later')
          this.setState({ loadingCostUpdate: false })
        }
      } catch (e) {
        notify.error('Unable to Update Costing', 'Unable to update new costing. Please try again later')
        this.setState({ loadingCostUpdate: false })
      }
    })
  }

  trimCostValue (values) {
    const v2 = cloneDeep(values)
    if (validator.isNotEmptyObject(v2)) {
      for (let v in v2) {
        if (v.indexOf('_unit') > -1 || v.indexOf('_rate') > -1 || v.indexOf('subtotal') > -1 || v.indexOf('_adj') > -1) {
          if (v.indexOf('_adj_notes')) {
            v2[v] = v2[v] || null
          } else {
            v2[v] = parseFloat(v2[v])
          }
        } else if (v === 'is_forced_update') {

        } else {
          delete v2[v]
        }
      }
    }

    return v2
  }

  /** Payroll section */
  fetchPayroll = async () => {
    const { jobId } = this.props

    this.setState({ loadingPayroll: true })
    const dt = await fvpJobCostService.getPayrollDetail(jobId)

    if (dt && dt.default_payroll) {
      this.setState({ payrollDetail: dt, prevPayrollDetail: cloneDeep(dt), loadingPayroll: false })
    } else {
      this.setState({ loadingPayroll: false })
    }
  }

  renderPayrollDaySection (dtc, day = '1', isCurrent = false) {
    const { default_payroll: dfp, current_payroll: cfp } = dtc
    const { payrollText } = this.state

    const dfc = isCurrent ? cfp : dfp
    const isHoliday = !!dfc[`d${day}_is_holiday`]
    const holidayName = dfc[`d${day}_holiday_name`] || null
    const payroll = formatter.capitalize(payrollText)

    const d1DayText = isHoliday ? HOLIDAY_TEXT : dfc[`d${day}_day_text`]

    return (<div className='section'>
      { this.renderPayrollRow({
        isTitle: true,
        title: isHoliday
        ? <div className='title-flex-start highlight'>Day {day} <span>({holidayName})</span></div>
        : <div className='title-flex-start'>Day {day}</div>,
        content1: 'Unit',
        content2: 'Job Date',
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} ${payroll}`,
        content1: dfc[`d${day}nc_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}nc_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}nc_unit`),
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} EMG ${payroll}`,
        content1: dfc[`d${day}ne_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}ne_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}ne_unit`),
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} A/H ${payroll}`,
        content1: dfc[`d${day}ac_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}ac_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}ac_unit`),
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} EMG A/H ${payroll}`,
        content1: dfc[`d${day}ae_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}ae_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}ae_unit`),
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} S/O ${payroll}`,
        content1: dfc[`d${day}sc_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}sc_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}sc_unit`),
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: `${d1DayText} EMG S/O ${payroll}`,
        content1: dfc[`d${day}se_unit`],
        content2: dfc[`d${day}_date`],
        content1Key: `d${day}se_unit`,
        content2Key: `d${day}_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `d${day}se_unit`),
      }) }
      { isCurrent
        ? this.renderPayrollRowSingleInput({
          isInput: isCurrent,
          title: `${d1DayText} Adjustment Note`,
          content: dfc[`d${day}_notes`],
          contentKey: `d${day}_notes`,
          contentOnChange: (v) => this.onChangePayrollValue(v, `d${day}_notes`)
        })
        : null }
    </div>)
  }

  renderPayrollKmsSection (dtc, isCurrent = false) {
    const { default_payroll: dfp, current_payroll: cfp } = dtc

    const dfc = isCurrent ? cfp : dfp

    return (<div className='section'>
      { this.renderPayrollRow({
        isTitle: true,
        isRowTitle: true,
        title: 'KMS',
        content1: 'Unit',
        content2: 'Job Date',
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: 'KM Travel Allowance',
        content1: dfc[`kms_c_unit`],
        content2: dfc[`d1_date`],
        content1Key: `kms_c_unit`,
        content2Key: `d1_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `kms_c_unit`),
      }) }
      { isCurrent
        ? this.renderPayrollRowSingleInput({
          isInput: isCurrent,
          title: `KM Travel Allowance Adjustment Note`,
          content: dfc[`kms_notes`],
          contentKey: `kms_notes`,
          contentOnChange: (v) => this.onChangePayrollValue(v, `kms_notes`)
        })
        : null }
    </div>)
  }

  renderPayrollRmsSection (dtc, isCurrent = false) {
    const { default_payroll: dfp, current_payroll: cfp } = dtc

    const dfc = isCurrent ? cfp : dfp

    return (<div className='section'>
      { this.renderPayrollRow({
        isTitle: true,
        isRowTitle: true,
        title: 'Reimbursement',
        content1: 'Unit',
        content2: 'Job Date',
      }) }
      { this.renderPayrollRow({
        isInput: isCurrent,
        title: 'Reimbursement',
        content1: dfc[`rms_c_unit`],
        content2: dfc[`d1_date`],
        content1Key: `rms_c_unit`,
        content2Key: `d1_date`,
        content1OnChange: (v) => this.onChangePayrollQty(v, `rms_c_unit`),
      }) }
      { isCurrent
        ? this.renderPayrollRowSingleInput({
          isInput: isCurrent,
          title: `Reimbursement Note`,
          content: dfc[`rms_notes`],
          contentKey: `rms_notes`,
          contentOnChange: (v) => this.onChangePayrollValue(v, `rms_notes`)
        })
        : null }
    </div>)
  }

  renderPayrollSummarySection (dtc, isCurrent = false) {
    const { default_payroll_ext: dfp, current_payroll_ext: cfp } = dtc
    const { costDetail } = this.state
    const { current_cost: cfc } = costDetail

    const dfc = isCurrent ? cfp : dfp
    const empAccRef = cfc && cfc.employee_acc_ref || null

    if (validator.isArray(dfc)) {
      return <div className='section end'>
        { this.renderPayrollRow({
          isTitle: true,
          isRowTitle: true,
          title: `Summary ${empAccRef ? `(${empAccRef})`: ''}`,
          content1: 'Unit',
          content2: 'Job Date',
        }) }
        { dfc.map (d => {
          return this.renderPayrollRow({
            isTitle: true,
            isRowTitle: true,
            title: `${d.category}`,
            content1: `${d.unit}`,
            content2: `${d.jobDate}`,
          })
        })}
      </div>
    } else {
      return <div></div>
    }
  }

  renderPayrollRow ({
    isTitle = false,
    isRowTitle = false,
    isInput = false,
    icons = [],
    title,
    content1,
    content2,
    content1Key,
    content2Key,
    content1OnChange,
    content2OnChange
  }) {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { loadingPayrollUpdate } = this.state

    const renderTD = ({lg = ColPayrollContent1, content, contentKey, contentOnChange, isInputRender, isRowTitle}) => {
      return <Col lg={lg}>
        <div className={isRowTitle !== undefined ? (`row-title ${isRowTitle ? 'theme' : ''}`) : isTitle ? 'row-content-title' : 'row-content'}>
          { isInputRender && contentKey && validator.isFunction(contentOnChange)
            ? <FormItem>
              { getFieldDecorator(`${contentKey}`, {
                initialValue: content,
                rules: [
                  { validator: this.validatePayrollQtyChange },
                  { required: true, message: ' ' }
                ],
              })(
                <Input
                  onChange={contentOnChange}
                  disabled={loadingPayrollUpdate}
                />
              )}
            </FormItem>
            : content }
        </div>
      </Col>
    }
    return (
      <Row className='row' gutter={5}>
        { renderTD({
          lg: ColTitle,
          content: title,
          isInputRender: false,
          isRowTitle
        }) }
        { renderTD({
          lg: ColPayrollContent1,
          content: content1,
          contentKey: content1Key,
          contentOnChange: content1OnChange,
          isInputRender: isInput
        }) }
        { renderTD({
          lg: ColPayrollContent2,
          content: content2,
          contentKey: content2Key,
          contentOnChange: content2OnChange,
          isInputRender: isInput
        }) }
      </Row>
    )
  }

  renderPayrollRowSingleInput ({
    isTitle = false,
    isRowTitle = false,
    isInput = false,
    icons = [],
    title,
    content,
    contentKey,
    contentOnChange,
  }) {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { loadingPayrollUpdate } = this.state

    const renderTD = ({lg = ColCostContent1, content, contentKey, contentOnChange = () => {}, isInputRender, isRowTitle}) => {
      return <Col lg={lg}>
        <div className={isRowTitle !== undefined ? (`row-title ${isRowTitle ? 'theme' : ''}`) : isTitle ? 'row-content-title' : 'row-content-single'}>
          { isInputRender && contentKey && validator.isFunction(contentOnChange)
            ? <FormItem>
              { getFieldDecorator(`${contentKey}`, {
                initialValue: content
              })(
                <TextArea
                  rows={2}
                  onChange={contentOnChange}
                  disabled={loadingPayrollUpdate}
                />
              )}
            </FormItem>
            : content }
        </div>
      </Col>
    }
    return (
      <Row className='row row-single-input' gutter={5}>
        { renderTD({
          lg: ColTitle,
          content: title,
          isInputRender: false,
          isRowTitle
        }) }
        { renderTD({
          lg: ColContent,
          content: content,
          contentKey: contentKey,
          contentOnChange: contentOnChange,
          isInputRender: isInput
        }) }
      </Row>
    )
  }

  onChangePayrollQty = (e, key) => {
    const { payrollDetail } = this.state
    const { current_payroll: cfp } = payrollDetail
    const value = e.target.value

    if (key && value !== undefined) {
      const unitIndex = key.indexOf('_unit')

      // do for unit and rate update
      if (unitIndex > -1) {
        if (/^d1/.test(key) || /^d2/.test(key)) {
          const prefix = key.substring(0, unitIndex)
          cfp[`${prefix}_unit`] = value

          this.formatPayrollData(cfp)
        } else if (/^kms_c_unit/.test(key)) {
          cfp[`kms_c_unit`] = value

          this.formatPayrollData(cfp)
        } else if (/^rms_c_unit/.test(key)) {
          cfp[`rms_c_unit`] = value

          this.formatPayrollData(cfp)
        }
      }
    }
  }

  onChangePayrollValue = (e, key) => {
    const { payrollDetail } = this.state
    const { current_payroll: cfp } = payrollDetail
    const value = e.target.value

    if (key && value !== undefined) {
      cfp[`${key}`] = value
    }
  }

  formatPayrollData (p) {
    const { payrollDetail } = this.state

    const day1Text = p.d1_is_holiday === true ? HOLIDAY_TEXT : p.d1_day_text
    const day2Text = p.d1_is_holiday === true ? HOLIDAY_TEXT : p.d1_day_text
    const payrollText = formatter.capitalize(p.payroll)

    const payrollItems = []

    // Day 1
    if (p.d1nc_unit > 0) {
      payrollItems.push({
        category: `${day1Text} ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1nc_unit
      })
    }
    if (p.d1ne_unit > 0) {
      payrollItems.push({
        category: `${day1Text} EMG ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1ne_unit
      })
    }
    if (p.d1ac_unit > 0) {
      payrollItems.push({
        category: `${day1Text} A/H ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1ac_unit
      })
    }
    if (p.d1ae_unit > 0) {
      payrollItems.push({
        category: `${day1Text} EMG A/H ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1ae_unit
      })
    }
    if (p.d1sc_unit > 0) {
      payrollItems.push({
        category: `${day1Text} S/O ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1sc_unit
      })
    }
    if (p.d1se_unit > 0) {
      payrollItems.push({
        category: `${day1Text} EMG S/O ${payrollText}`,
        jobDate: p.d1_date,
        unit: p.d1se_unit
      })
    }
    // Day 2
    if (p.d2nc_unit > 0) {
      payrollItems.push({
        category: `${day2Text} ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2nc_unit
      })
    }
    if (p.d2ne_unit > 0) {
      payrollItems.push({
        category: `${day2Text} EMG ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2ne_unit
      })
    }
    if (p.d2ac_unit > 0) {
      payrollItems.push({
        category: `${day2Text} A/H ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2ac_unit
      })
    }
    if (p.d2ae_unit > 0) {
      payrollItems.push({
        category: `${day2Text} EMG A/H ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2ae_unit
      })
    }
    if (p.d2sc_unit > 0) {
      payrollItems.push({
        category: `${day2Text} S/O ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2sc_unit
      })
    }
    if (p.d2se_unit > 0) {
      payrollItems.push({
        category: `${day2Text} EMG S/O ${payrollText}`,
        jobDate: p.d2_date,
        unit: p.d2se_unit
      })
    }
    // KMS
    if (p.kms_c_unit > 0) {
      payrollItems.push({
        category: `KM Travel Allowance`,
        jobDate: p.d1_date,
        unit: p.kms_c_unit
      })
    }
    // Reimbursement
    if (p.rms_c_unit > 0) {
      payrollItems.push({
        category: `Reimbursement`,
        jobDate: p.d1_date,
        unit: p.rms_c_unit
      })
    }

    const newItems = this.payrollDataGroupBy(payrollItems)

    payrollDetail.current_payroll_ext = newItems

    this.setState({ payrollDetail })
  }

  validatePayrollQtyChange = (rule, value, callback) => {
    const field = rule.field
    if (value === null || value === undefined || value === '') {
      callback(new Error(` `))
    } else {
      const v = validator.isNumberText(value)
      if (!v) {
        callback(new Error(` `))
      } else {
        callback()
      }
    }
  }

  payrollDataGroupBy (payroll, key) {
    for (let i = 0; i < payroll.length; i++) {
      const pay = payroll[i]
      pay.category = pay.category.replace('Monday', 'Weekday').replace('Tuesday', 'Weekday').replace('Wednesday', 'Weekday').replace('Thursday', 'Weekday').replace('Friday', 'Weekday')
    }

    var helper = {}
    var result = payroll.reduce((r, o) => {
      var key = o.id + '-' + o.category
      if (!helper[key]) {
        helper[key] = Object.assign({}, o)
        r.push(helper[key])
      } else {
        helper[key].unit += o.unit
      }

      return r
    }, [])

    return result
  }

  onPayrollUpdateCheck = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { loadingPayroll, loadingPayrollUpdate } = this.state
    const { updatePayroll } = this

    if (loadingPayroll || loadingPayrollUpdate) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        confirm({
          title: 'Proceed To Update Payroll?',
          content: (
            <div>
              <p>Do you want to update the payroll for this job? This will affect the items enlisted payroll extract.</p>
              <p>Even your payroll update could be same with default payroll, the job details will be locked after this update action. However, you could revert the payroll to default payroll after update.</p>
            </div>
          ),
          onOk () {
            // eslint-disable-next-line no-lone-blocks
            updatePayroll(values)
          },
          onCancel () {
          }
        })
      } else {
        error({
          title: <p style={{color: 'red'}}>Invalid Values</p>,
          content: (
            <div>
              <p style={{color: 'red'}}>Some of your values entered are invalid. Please update before proceed.</p>
            </div>
          )
        })
      }
    })
  }

  updatePayroll = async (values) => {
    const { jobId } = this.props
    const { payrollDetail, prevPayrollDetail, payrollText } = this.state
    const { current_payroll: cfp } = payrollDetail
    const { current_payroll: pcfp } = prevPayrollDetail

    try {
      this.setState({ loadingPayrollUpdate: true })
      const dt = await fvpJobCostService.updatePayrollDetail(jobId, cfp)

      if (dt && dt.id) {
        const prev = this.trimPayrollValue(pcfp)
        const curr = this.trimPayrollValue(cfp)
        const day1Text = cfp.d1_is_holiday ? HOLIDAY_TEXT : formatter.capitalize(cfp.d1_day_text)
        const day2Text = cfp.d2_is_holiday ? HOLIDAY_TEXT : formatter.capitalize(cfp.d2_day_text)
        const compare = log.generateItemChanges(prev, curr, undefined, [
          { key: 'd1nc_unit', label: `Day 1 (${day1Text}) ${payrollText}`},
          { key: 'd1ne_unit', label: `Day 1 (${day1Text}) EMG ${payrollText}`},
          { key: 'd1ac_unit', label: `Day 1 (${day1Text}) A/H ${payrollText}`},
          { key: 'd1ae_unit', label: `Day 1 (${day1Text}) EMG A/H ${payrollText}`},
          { key: 'd1sc_unit', label: `Day 1 (${day1Text}) S/O ${payrollText}`},
          { key: 'd1se_unit', label: `Day 1 (${day1Text}) EMG S/O ${payrollText}`},
          { key: 'd1_notes', label: `Day 1 (${day1Text}) Adjustment Notes`},

          { key: 'd2nc_unit', label: `Day 2 (${day2Text}) ${payrollText}`},
          { key: 'd2ne_unit', label: `Day 2 (${day2Text}) EMG ${payrollText}`},
          { key: 'd2ac_unit', label: `Day 2 (${day2Text}) A/H ${payrollText}`},
          { key: 'd2ae_unit', label: `Day 2 (${day2Text}) EMG A/H ${payrollText}`},
          { key: 'd2sc_unit', label: `Day 2 (${day2Text}) S/O ${payrollText}`},
          { key: 'd2se_unit', label: `Day 2 (${day2Text}) EMG S/O ${payrollText}`},
          { key: 'd2_notes', label: `Day 2 (${day1Text}) Adjustment Notes`},

          { key: 'kms_c_unit', label: `KM Travel Allowance`},
          { key: 'kms_notes', label: `KM Travel Allowance Adjustment Notes`},

          { key: 'rms_c_unit', label: `Reimbursement`},
          { key: 'rms_notes', label: `Reimbursement Notes`},
        ])

        log.updateJobCostingPayroll(jobId, 'Update Payroll', compare)

        this.setState({ loadingPayrollUpdate: false }, () => {
          notify.success('Update Payroll Info Successfully', 'New Payroll Info is updated successfully')
          this.fetchPayroll()
        })
      } else {
        notify.error('Unable to Update Payroll Info', 'Unable to update new payroll info. Please try again later')
        this.setState({ loadingPayrollUpdate: false })
      }
    } catch (e) {
      notify.error('Unable to Update Payroll Info', 'Unable to update new payroll info. Please try again later')
      this.setState({ loadingPayrollUpdate: false })
    }
  }

  onPayrollRefreshCheck = () => {
    const { loadingPayroll, loadingPayrollUpdate } = this.state
    const { refreshPayroll } = this

    if (loadingPayroll || loadingPayrollUpdate) return

    const title = 'Proceed To Revert to Original Payroll?'
    const content = <div>
        Do you want to revert to the original payroll for this job?<br/><br/>This job will be unlocked after reverting. <br/><span style={{fontWeight: '700', color: 'red'}}>All of your edited payroll info will be lost permanently after revert.</span>
      </div>

    confirm({
      title: title,
      content: content,
      onOk () {
        // eslint-disable-next-line no-lone-blocks
        refreshPayroll()
      },
      onCancel () {
      }
    })
  }

  trimPayrollValue (values) {
    const v2 = cloneDeep(values)

    if (validator.isNotEmptyObject(v2)) {
      for (let v in v2) {
        if (v.indexOf('_unit') > -1 || v.indexOf('_notes') > -1) {
          if (v.indexOf('_notes') > -1) {
            v2[v] = v2[v] ? v2[v].trim() : null
          } else {
            v2[v] = parseFloat(v2[v])
          }
        } else {
          delete v2[v]
        }
      }
    }

    return v2
  }

  refreshPayroll = async () => {
    const { jobId } = this.props
    const { payrollDetail } = this.state
    const { current_payroll: cfp } = payrollDetail
    const actionType = 'Revert Payroll'
    const actionText = 'reverted'

    try {
      this.setState({ loadingPayrollUpdate: true })
      const dt = await fvpJobCostService.refreshPayrollDetail(jobId, {})

      if (dt && dt.id) {
        log.updateJobCostingPayroll(jobId, `${actionType}`, `Payroll has been ${actionText} and it is up to date.`)

        this.setState({ loadingPayrollUpdate: false }, () => {
          notify.success(`${actionType} Successfully`, `Payroll is ${actionText} successfully`)
          this.fetchPayroll()
        })
      } else {
        notify.error(`Unable to ${actionType}`, `Unable to ${actionType.toLocaleLowerCase()}. Please try again later.`)
        this.setState({ loadingPayrollUpdate: false })
      }
    } catch (e) {
      notify.error(`Unable to ${actionType}`, `Unable to ${actionType.toLocaleLowerCase()}. Please try again later.`)
      this.setState({ loadingPayrollUpdate: false })
    }
  }


  /** Misc section */
  toFixed (number, decimals = 2) {
    return formatter.toDecimalVS(number, decimals)
  }

  handleChangeTab (tabActiveKey) {
    this.setState({ tabActiveKey })
  }
}

const mapDispatchToProps = {
  setRefreshCommLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobCost))

export const YesNoOptions = Object.freeze([
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
])
export const YesNoOption = Object.freeze(Object.fromEntries(YesNoOptions.map((option) => [option.value, option])))
export const YesNoNaOptions = Object.freeze([
  ...YesNoOptions,
  { label: 'N/A', value: 'n/a' }
])
export const YesNoNaOption = Object.freeze(Object.fromEntries(YesNoNaOptions.map((option) => [option.value, option])))

export * from './address'
export * from './customer'
export * from './customIdentifier'
export * from './client'
export { default as DefaultDates } from './date'
export * from './session'
export * from './employee'
export * from './export'
export * from './feedback'
export * from './file'
export * from './jobs'
export * from './menu'
export * from './menu/payroll'
export * from './menu/client'
export * from './menu/client/page'
export * from './resource'
export * from './role'

export default [
  {
    name: 'Funder List',
    desc: 'Manage all customers from different channels',
    icon: 'team',
    link: '/funders',
    enable: true,
    permission: 'listFunders'
  }/* ,
  {
    name: 'Add Client',
    desc: 'Membership',
    icon: 'usergroup-add',
    link: '/clients/add'
  } */
]

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { authService, clientService, fvpClientBudgetService, fvpCategoriesService, fvpClientFundingService, fvpClientFundingPeriodService, fvpRatesService, fvpFunderService, funderService, settingGeneralService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log, trigger, uploader, validator } from '../../../util'

// UI
import { Button, ControlLabel, Loading, List, Panel, SideModal, Pager, FileTypeUpload } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Popover from 'antd/lib/popover'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Popconfirm from 'antd/lib/popconfirm'
import Upload from 'antd/lib/upload'

import { apiHostname } from '../../../config'
import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'

const { Item: FormItem } = Form
const Option = Select.Option
const { confirm } = Modal

const pageSize = 20

export class ClientBudget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      budgetList: [],
      fundingList: [],
      loading: false,
      loadingList: false,
      loadingForm: false,
      isBudgetModal: false,
      selectedBudgetItem: {}
    }
  }

  componentDidMount () {
    this.fetchBudget()
    this.fetchFunding()
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const { budgetList, fundingList, isBudgetModal, loading, loadingForm, loadingList, selectedBudgetItem } = this.state

    const isEdit = selectedBudgetItem && selectedBudgetItem.id

    return (<div>
      <Loading loading={loading} blur>
        <Panel
          title='Budget'
          subtitle={this.hasAccess('createClientBudget')
            ? <div className='btn' onClick={() => this.triggerBudgetModal(true)}>Add</div>
            : null}
        >
          <Spin spinning={loadingList}>
            <div className='client-budget-list'>
              { budgetList.map((item, index) => {
                const { active, start_date, end_date, funder_fullname, budget_value, period_day_interval, period_day_to_now, period_progress,
                  remaining_up_to_now, remaining_up_to_now_percentage, remaining_upcoming, remaining_upcoming_percentage,
                  subtotal_up_to_now, subtotal_up_to_now_job, subtotal_up_to_now_kms, subtotal_up_to_now_percentage, subtotal_up_to_now_job_percentage, subtotal_up_to_now_kms_percentage,
                  subtotal_upcoming, subtotal_upcoming_job, subtotal_upcoming_kms, subtotal_upcoming_percentage, subtotal_upcoming_job_percentage, subtotal_upcoming_kms_percentage,
                  subtotal_projected_job, subtotal_projected_job_percentage, subtotal_projected_upcoming_kms, subtotal_projected_upcoming_kms_percentage, subtotal_projected_kms, subtotal_projected_kms_percentage, remaining_projected, remaining_projected_percentage
                } = item
                const isBudgetActive = !!active
                const std = moment(start_date).format(dateFormat)
                const etd = moment(end_date).format(dateFormat)
                const isExpired = period_day_to_now > period_day_interval
                const isExpiring = parseFloat(period_progress) >= 80
                const isExceding = parseFloat(remaining_upcoming_percentage) <= 25
                const isOverProjected = remaining_projected_percentage <= 0 ? 2 : remaining_projected_percentage <= 10 ? 1 : 0

                return (
                  <div key={`bdlist${index}`} className={`client-budget-list-item ${isBudgetActive ? '' : 'client-budget-list-item-inactive'}`}>
                    <Row className={`row ${!isBudgetActive ? 'row-inactive' : isExpired ? 'row-expired' : ''}`}>
                      <Col lg={8}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Period</ControlLabel>
                        <div className='info'>
                          {`${std} - ${etd} (${isExpired ? `Expired` : `${period_day_to_now} day${period_day_to_now === 1 ? '' : 's'} / ${period_day_interval} day${period_day_interval === 1 ? '' : 's'}`})`}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Current Progress</ControlLabel>
                        <div className='info'>{period_progress} %</div>
                      </Col>
                      <Col lg={6}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Funder</ControlLabel>
                        <div className='info'>{funder_fullname}</div>
                      </Col>
                      <Col lg={5}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>ACTIVE</ControlLabel>
                        <div className='info'>{active ? 'Yes' : 'No'}</div>
                      </Col>
                      <Col lg={1}>
                        <div className='action-buttons' style={{ marginRight: '10px', marginTop: '15px' }}>
                          { this.hasAccess('updateClientBudget')
                            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                              <div onClick={() => this.triggerBudgetModal(true, item)}><Icon type='form' /></div>
                            </Tooltip>
                            : null }
                          { this.hasAccess('deleteClientBudget')
                            ? <Popconfirm
                              title='Confirm to delete this budget?'
                              onConfirm={() => this.handleDeleteBudget(item)}
                              okText='Yes'
                              cancelText='No'>
                              <Icon type='delete' style={{ marginTop: '2px' }} />
                            </Popconfirm>
                            : null }
                        </div>
                      </Col>
                    </Row>

                    <Row className='row-period'>
                      <Col lg={1} />
                      <Col lg={3}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Total Budget</ControlLabel>
                        <div className={`info-value`}>{formatter.toPrice(budget_value)}</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Current Job Spending</ControlLabel>
                        <div className={`info-value ${isExceding ? 'info-value-red' : ''}`}>{formatter.toPrice(subtotal_up_to_now_job)} ({subtotal_up_to_now_job_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Current KM Spending</ControlLabel>
                        <div className={`info-value ${isExceding ? 'info-value-red' : ''}`}>{formatter.toPrice(subtotal_up_to_now_kms)} ({subtotal_up_to_now_kms_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Upcoming Job Spending</ControlLabel>
                        <div className={`info-value ${isExceding ? 'info-value-red' : ''}`}>{formatter.toPrice(subtotal_upcoming)} ({subtotal_upcoming_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Projected KM Spending</ControlLabel>
                        <div className={`info-value ${isOverProjected === 2 ? 'info-value-red' : isOverProjected === 1 ? 'info-value-orange' : ''}`}>{formatter.toPrice(subtotal_projected_upcoming_kms)} ({subtotal_projected_upcoming_kms_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Upcoming Balance</ControlLabel>
                        <div className={`info-value ${isExceding ? 'info-value-red' : ''}`}>{formatter.toPrice(remaining_upcoming)} ({remaining_upcoming_percentage}%)</div>
                      </Col>
                    </Row>
                    <Row className='row-period'>
                      <Col lg={12} />
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Projected Total Job Spending</ControlLabel>
                        <div className={`info-value ${isOverProjected === 2 ? 'info-value-red' : isOverProjected === 1 ? 'info-value-orange' : ''}`}>{formatter.toPrice(subtotal_projected_job)} ({subtotal_projected_job_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Projected Total KM Spending</ControlLabel>
                        <div className={`info-value ${isOverProjected === 2 ? 'info-value-red' : isOverProjected === 1 ? 'info-value-orange' : ''}`}>{formatter.toPrice(subtotal_projected_kms)} ({subtotal_projected_kms_percentage}%)</div>
                      </Col>
                      <Col lg={4}>
                        <ControlLabel className={`${isBudgetActive ? '' : 'label-inactive'}`}>Projected Balance</ControlLabel>
                        <div className={`info-value ${isOverProjected === 2 ? 'info-value-red' : isOverProjected === 1 ? 'info-value-orange' : ''}`}>{formatter.toPrice(remaining_projected)} ({remaining_projected_percentage}%)</div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </div>
          </Spin>
        </Panel>

        <SideModal
          title='Budget'
          showModal={isBudgetModal}
          onClose={() => this.triggerBudgetModal(false)}
          buttons={[
            selectedBudgetItem.id && this.hasAccess('updateClientBudget')
            ? <Button key='savePeriod1' onClick={() => this.handleSaveBudget()} feedback={loadingForm}>Update</Button>
            : !selectedBudgetItem.id && this.hasAccess('createClientBudget')
              ? <Button key='savePeriod2' onClick={() => this.handleSaveBudget()} feedback={loadingForm}>Add</Button>
              : null
          ]}
        >
          { isBudgetModal
            ? <Form layout='vertical' key={`bgdtmdl`}>
              <FormItem label='Active'>
                {getFieldDecorator('active', {
                  initialValue: selectedBudgetItem.active !== undefined && selectedBudgetItem.active !== null ? selectedBudgetItem.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={loadingForm}
                  />
                )}
              </FormItem>
              <FormItem label='Funder'>
                {getFieldDecorator('funder_id', {
                  initialValue: selectedBudgetItem.funder_id || null,
                  rules: [
                    { required: true, message: 'Please select a funder' }
                  ]
                })(
                  <Select
                    showSearch
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    placeholder='Please select a funder'
                    filterOption={(input, option) => this.findFunders(input, option)}
                    onChange={this.handleFunderChange}
                    disabled={loadingForm || isEdit}
                  >
                    { fundingList.map((funder, idx) => (
                        <Option key={`fdxer${idx}`} value={funder.funder_id}>{funder.funder_fullname}</Option>
                      )) }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Budget Start'>
                {getFieldDecorator('start_date', {
                  initialValue: selectedBudgetItem.start_date ? (moment.isMoment(selectedBudgetItem.start_date) ? selectedBudgetItem.start_date : moment(selectedBudgetItem.start_date)) : null,
                  rules: [
                    { required: true, message: 'Please select a start date' },
                    { validator: this.validateDate }
                  ]
                })(
                  <DatePicker format='DD/MM/YYYY' disabled={loadingForm} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>

              <FormItem label='Budget End'>
                {getFieldDecorator('end_date', {
                  initialValue: selectedBudgetItem.end_date ? (moment.isMoment(selectedBudgetItem.end_date) ? selectedBudgetItem.end_date : moment(selectedBudgetItem.end_date)) : null,
                  rules: [
                    { required: true, message: 'Please select an end date' },
                    { validator: this.validateDate }
                  ]
                })(
                  <DatePicker format='DD/MM/YYYY' disabled={loadingForm} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>

              <FormItem label='Budget Amount'>
                {getFieldDecorator('budget_value', {
                  initialValue: selectedBudgetItem.budget_value || 0.0,
                  rules: [
                    { required: true, message: 'Please enter budget amount' },
                    { validator: this.validateAmount }
                  ]
                })(
                  <Input addonBefore={'$'} disabled={loadingForm} />
                )}
              </FormItem>

              <FormItem label='Note'>
                {getFieldDecorator('notes', {
                  initialValue: selectedBudgetItem.notes || null,
                })(
                  <Input disabled={loadingForm} />
                )}
              </FormItem>
            </Form>
            : null }
        </SideModal>
      </Loading>
    </div>
    )
  }

  fetchBudget = async () =>  {
    if (this.hasAccess('listClientBudgets')) {
      this.setState({ loading: true })
      const { clientId } = this.props

      const budget = await fvpClientBudgetService.listAll(clientId)

      if (budget && validator.isArray(budget)) {
        this.setState({ budgetList: budget, loading: false })
      }
    }
  }

  fetchFunding = async () =>  {
    if (this.hasAccess('listFvpClientFundingPeriod')) {
      const { clientId } = this.props

      const funding = await fvpClientBudgetService.listCurrentPeriod(clientId)

      if (funding && validator.isNotEmptyArray(funding)) {
        this.setState({ fundingList: funding })
      }
    }
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  validateAmount (rule, value, callback) {
    if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error('Please enter budget amount'))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error('Amount value must be valid'))
    } else {
      callback()
    }
  }

  validateDate = (rule, value, callback) => {
    const { form } = this.props
    if (rule.field === 'start_date') {
      const endDate = form.getFieldValue('end_date')

      if (endDate) {
        if (value > endDate) {
          callback(new Error('End date is before start date.'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    } else if (rule.field === 'end_date') {
      const startDate = form.getFieldValue('start_date')

      if (startDate) {
        if (value < startDate) {
          callback(new Error('End date is before start date.'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  handleFunderChange = (value) => {
    const { fundingList } = this.state
    const { form } = this.props
    const { getFieldValue, setFieldsValue } = form

    const f = fundingList.find(e => e.funder_id === value)
    if (f && f.id) {
      if (f.funder_id === 38 || f.funder_fullname === 'NDIS') {
        const std = f.start_date ? moment(f.start_date) : undefined
        const etd = f.end_date ? moment(f.end_date) : undefined
        setFieldsValue({ start_date: std, end_date: etd })
      } else {
        const std = getFieldValue('start_date')

        if (!std) setFieldsValue({ start_date: moment(new Date()) })
      }
    }
  }

  handleSaveBudget = async () => {
    const { clientId, clientName, form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        try {
          const { fundingList, selectedBudgetItem } = this.state
          this.setState({ loadingForm: true })

          const isEdit = selectedBudgetItem && selectedBudgetItem.id
          let r = null

          if (isEdit) {
            r = await fvpClientBudgetService.save(selectedBudgetItem.id, values)
          } else {
            values.client_id = clientId
            r = await fvpClientBudgetService.add(values)
          }

          if (r && r.id) {
            this.triggerBudgetModal(false)
            if (isEdit) {
              const funder = fundingList.find(e => e.funder_id === values.funder_id)

              let changeText = log.generateItemChanges(
                selectedBudgetItem,
                values,
                ['funder_id'],
                undefined
              )

              let changeTextAdd = ''
              if (selectedBudgetItem.funder_id !== values.funder_id && funder) {
                changeTextAdd += `Funder changes from "${selectedBudgetItem.funder_id ? `ID ${selectedBudgetItem.funder_id} - ` : ''}${selectedBudgetItem.funder_fullname}" to "${funder.funder_id ? `ID ${funder.funder_id} - ` : ''}${funder.funder_fullname}"`
              }

              if (changeTextAdd) {
                changeText += `${changeText ? `, ` : ''}${changeTextAdd}`
              }

              if (changeText) {
                log.updateClientBudget(clientId, changeText)
              }

              notify.success('Saved successfully', 'Client Budget updated successfully.')
            } else {
              const funder = fundingList.find(e => e.funder_id === values.funder_id)
              const startDate = formatter.toShortDate(values.start_date)
              const endDate = formatter.toShortDate(values.end_date)

              log.addClientBudget(clientId, `Budget under Funder "${funder.funder_fullname}"${funder.funder_id ? `, Funder ID ${funder.funder_id}` : ''}, starting from "${startDate}" to "${endDate}", Active: ${values.active}, Budget Value "${values.budget_value}" is added.`)

              notify.success('Saved successfully', 'Client Budget added successfully.')
            }

            this.props.setRefreshActivityLog(true)
            this.fetchBudget()
          } else {
            notify.error('Unable to save', 'Unable to save client budget. Please try again later.')
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          console.log('errror save', e)
          notify.error('Unable to save', 'Unable to save client budget. Please try again later.')
        }
      }
    })
  }

  handleDeleteBudget = async (item = {}) => {
    const { clientId } = this.props

    this.setState({ loadingList: true })
      try {
        const r = await fvpClientBudgetService.remove(item.id)

        if (r && r.id) {
          log.deleteClientBudget(clientId, `Funder "${item.funder_id ? `ID ${item.funder_id} - ` : ''}${item.funder_fullname}" is deleted.`)
          notify.success('Deleted successfully', 'Budget deleted successfully')
          this.fetchBudget()

          this.props.setRefreshActivityLog(true)
        } else {
          this.fetchBudget()
          notify.error('Unable to delete', 'Unable to delete funder successfully. Please try again later.')
        }
      } catch (e) {
        console.log('errror delete', e)
        notify.error('Unable to delete', 'Unable to delete funder successfully. Please try again later.')
      }
      this.setState({ loadingList: false })
  }

  triggerBudgetModal (isBudgetModal = false, item = {}) {
    const { fundingList } = this.state
    const { form } = this.props
    const { resetFields } = form

    if (isBudgetModal) {
      if (item.id) {

      } else {
        // Set NDIS as default funder if client funding list has funder of NDIS
        const f = fundingList.find(e => e.funder_id === 38 || e.funder_fullname === 'NDIS')
        if (f && f.id) {
          item.funder_id = 38
          if (f.start_date) item.start_date = moment(f.start_date)
          if (f.end_date) item.end_date = moment(f.end_date)
        }
      }
    }

    this.setState({ isBudgetModal, selectedBudgetItem: item }, () => {
      if (!isBudgetModal) {
        resetFields()
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientBudget))


export default {
  getDayNumber (dayNumber) {
    if (dayNumber === 1) {
      return 'First'
    } else if (dayNumber === 2) {
      return 'Second'
    } else if (dayNumber === 3) {
      return 'Third'
    } else if (dayNumber === 4) {
      return 'Fourth'
    } else {
      return dayNumber
    }
  },
  getOptionDate (optionDate) {
    if (optionDate === '01') {
      return '1st'
    } else if (optionDate === '02') {
      return '2nd'
    } else if (optionDate === '03') {
      return '3rd'
    } else if (optionDate === '21') {
      return '21st'
    } else if (optionDate === '22') {
      return '22nd'
    } else if (optionDate === '23') {
      return '23rd'
    } else if (optionDate === '31') {
      return '31st'
    } else {
      return `${optionDate}th`
    }
  },
  getQueryString (search) {
    const queries = {}
    let queryString = search.substr(1)
    const queryList = queryString.split('&')

    for (let i = 0; i < queryList.length; i++) {
      const query = queryList[i]
      const keyValues = query.split('=')
      queries[keyValues[0]] = keyValues[1]
    }

    return queries
  },
  getQueryStringSearchParams (search) {
    const newSearch = search.replace('?', '')

    const param = new URLSearchParams(newSearch)

    const result = {}
    for (const [key, value] of param) {
      result[key] = value
    }
    return result
  },
  getPath (path) {
    const { params = {} } = path

    return params
  }
}

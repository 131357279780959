import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import {
  FeedbackGenreOption, FeedbackGenreOptions, FeedbackReportedByOption, FeedbackReportedByOptions, FeedbackStatusOption,
  YesNoNaOption, YesNoNaOptions, YesNoOption, YesNoOptions
} from '../../../constants'
import {
  adminService, authService, clientService, employeeService, feedbackService, jvpJobService, settingFeedbackCategoryService,
  settingFeedbackSubCategoryService, settingFeedbackTypeService, settingOtherService
} from '../../../services'
import { fetchingFeedbacks, setRefreshActivityLog } from '../../../states/actions'
import { formatter, queryString, validator } from '../../../util'

// UI
import { FeedbackStatus, Loading, Page, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Action from '../Action'
import ActivityLog from '../ActivityLog'
import File from '../File'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { confirm } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 19 }
}
const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}
const TabInfo = Object.freeze({
  info: { id: '1', path: '/info' },
  actions: { id: '2', path: '/actions' },
  files: { id: '3', path: '/files' },
  logs: { id: '4', path: '/logs' },
})

export class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      admins: [],
      currentTab: this.getCurrentTabFromUrl(),
      feedbackCategories: [],
      feedbackSubCategories: [],
      feedbackTypes: [],
      genreClients: [],
      genreEmployees: [],
      genreFeedbacks: [],
      genreJobs: [],
      isReopened: false,
      item: {},
      loading: false,
      reportedByClients: [],
      reportedByEmployees: [],
      reportedByJobs: [],
      searchingGenreClients: false,
      searchingGenreEmployees: false,
      searchingGenreFeedbacks: false,
      searchingGenreJobs: false,
      searchingReportedByClients: false,
      searchingReportedByEmployees: false,
      searchingReportedByJobs: false,
      setting: {},
      shouldRefreshFeedbackActions: false,
      showSave: false,
      showEdit: true
    }
    this.handleSearchGenreClients = debounce(this.handleSearchClients('genreClients', 'searchingGenreClients'), 500)
    this.handleSearchGenreEmployees = debounce(this.handleSearchEmployees('genreEmployees', 'searchingGenreEmployees'), 500)
    this.handleSearchGenreFeedbacks = debounce(this.handleSearchFeedbacks('genreFeedbacks', 'searchingGenreFeedbacks'), 500)
    this.handleSearchGenreJobs = debounce(this.handleSearchJobs('genreJobs', 'searchingGenreJobs'), 500)
    this.handleSearchReportedByClients = debounce(this.handleSearchClients('reportedByClients', 'searchingReportedByClients'), 500)
    this.handleSearchReportedByEmployees = debounce(this.handleSearchEmployees('reportedByEmployees', 'searchingReportedByEmployees'), 500)
    this.handleSearchReportedByJobs = debounce(this.handleSearchJobs('reportedByJobs', 'searchingReportedByJobs'), 500)
  }

  componentDidMount() {
    this.checkCurrentUrl()
    this.fetchAdmins()
    this.fetchFeedbackCategories()
    this.fetchFeedbackSubCategories()
    this.fetchFeedbackTypes()

    if (this.isEdit()) {
      this.fetchFeedback()
    } else {
      this.initJob()
    }
  }

  render() {
    const { history, match } = this.props
    const { currentTab, isReopened, item, loading, shouldRefreshFeedbackActions, showSave, showEdit } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header
            title={
              !this.isEdit() ? 'Feedback (Add)' : loading
                ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit
                  ? `Feedback #${item.case_no} (View Only)` : showSave ? `Feedback #${item.case_no} (Edit Mode)` : 'Feedback'
            }
          >
            {this.isEdit() && !this.isCompleted() && this.hasAccess('deleteFeedback') ? showSave ? (
              <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
              </div>
            ) : null : null}

            {!loading && !isReopened && this.isEdit() && this.isCompleted() && this.hasAccess('updateCompletedFeedback') ? (
              <div className='btn' onClick={this.handleReopenButton}>
                Reopen
              </div>
            ) : null}

            {!loading && showEdit && this.isEdit() && !this.isCompleted() && this.hasAccess('updateFeedback') ? (
              <div className='btn' onClick={this.handleEditButton}>
                Edit
              </div>
            ) : null}

            {(
              (showSave && (isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback')))) ||
              (!this.isEdit() && this.hasAccess('createFeedback'))
            ) ? (
              <div className='btn' onClick={this.handleSave}>
                Save
              </div>
            ) : null}

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='feedback-panel'>
            {this.isEdit() ? (
              <div className='feedback-panel-header'>
                {loading ? (
                  <>
                    <Row>
                      <Col lg={2}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={2}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={4}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={4}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-skeleton' />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg={2}>
                        <div className='feedback-panel-header-label'>Incident Date</div>
                        <div className='feedback-panel-header-value'>{item.occurred_at ? formatter.toShortDate(item.occurred_at) : '-'}</div>
                      </Col>
                      <Col lg={2}>
                        <div className='feedback-panel-header-label'>Report Date</div>
                        <div className='feedback-panel-header-value'>{item.reported_at ? formatter.toShortDate(item.reported_at) : '-'}</div>
                      </Col>
                      <Col lg={4}>
                        <div className='feedback-panel-header-label'>Category</div>
                        <div className='feedback-panel-header-value'>{item.category_name || '-'} ({item.sub_category_name || '-'})</div>
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-label'>Employee</div>
                        <div className='feedback-panel-header-value'>
                          {item.employee_id
                            ? <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/employees/${item.employee_id}`}
                            >
                              {item.rb_employee_first_name} {item.rb_employee_last_name}
                            </Link>
                            : '-'}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-label'>Client</div>
                        <div className='feedback-panel-header-value'>
                          {item.client_id
                            ? <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/clients/${item.client_id}`}
                            >
                              {item.rb_client_first_name} {item.rb_client_last_name}
                            </Link>
                            : '-'}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-label'>Job</div>
                        <div className='feedback-panel-header-value'>
                          {item.job_id ? (
                            <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/jobs/single/${item.job_id}`}
                            >
                              Job #{item.job_id}
                            </Link>
                          ) : '-'}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className='feedback-panel-header-label'>Updated</div>
                        <div className='feedback-panel-header-value'>
                          <div className='subvalue'>{item.updated_at ? formatter.toStandardDate(item.updated_at) : '-'}</div>
                          <div className='feedback-panel-header-subvalue'>{item.updated_by_name || '-'}</div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className='feedback-panel-header-label'>Status</div>
                        <div className='feedback-panel-header-value'>{item.status
                          ? <FeedbackStatus colour={item.status_colour} label={item.status_name} />
                          : null}
                        </div>
                      </Col>
                    </Row>

                    {item.is_reportable === true ? (
                      <Row>
                        <Col lg={3}>
                          <div className='feedback-panel-header-value'>
                            <span className='feedback-reportable'>Reportable Incident</span>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className='feedback-panel-header-value'>
                            <span className='feedback-panel-header-label'>Date 1:&nbsp;</span>
                            <span className='feedback-panel-header-value'>
                              {item.reportable_date1 ? formatter.toShortDate(item.reportable_date1) : '-'}&nbsp;&nbsp;
                            </span>
                            <span>
                              {item.reportable_done1 === YesNoOption.yes.value
                                ? (
                                  <Tooltip mouseLeaveDelay={0} title="Done">
                                    <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span>
                                  </Tooltip>
                                )
                                : (isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback'))) ? (
                                  <Popconfirm
                                    title="Mark as Done?"
                                    onConfirm={this.handleMarkAsDone1}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip mouseLeaveDelay={0} title="Mark as Done?">
                                      <Icon style={{ color: '#666' }} type='check-circle' />
                                    </Tooltip>
                                  </Popconfirm>
                                ) : <Icon style={{ color: '#666' }} type='check-circle' />}
                            </span>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className='feedback-panel-header-value'>
                            <span className='feedback-panel-header-label'>Date 2:&nbsp;</span>
                            <span className='feedback-panel-header-value'>
                              {item.reportable_date2 ? formatter.toShortDate(item.reportable_date2) : '-'}&nbsp;&nbsp;
                            </span>
                            <span>
                              {item.reportable_done2 === YesNoOption.yes.value
                                ? (
                                  <Tooltip mouseLeaveDelay={0} title="Done">
                                    <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span>
                                  </Tooltip>
                                )
                                : (isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback'))) ? (
                                  <Popconfirm
                                    title="Mark as Done?"
                                    onConfirm={this.handleMarkAsDone2}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip mouseLeaveDelay={0} title="Mark as Done?">
                                      <Icon style={{ color: '#666' }} type='check-circle' />
                                    </Tooltip>
                                  </Popconfirm>
                                ) : <Icon style={{ color: '#666' }} type='check-circle' />}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            <div className='feedback-panel-body'>
              <Tabs activeKey={currentTab} onChange={this.changeCurrentTab}>
                <TabPane tab='Feedback Info' key={TabInfo.info.id}>
                  {this.infoTab()}
                </TabPane>

                {this.isEdit() ? (
                  <TabPane tab='Actions Taken' key={TabInfo.actions.id}>
                    <Action
                      actionId={match.params.aid}
                      feedback={item}
                      feedbackForm={this.props.form}
                      feedbackId={match.params.id}
                      history={this.props.history}
                      isReopened={isReopened}
                      onRefreshFeedbackActions={this.onRefreshFeedbackActions}
                      onUpdateFeedbackStatus={this.onUpdateFeedbackStatus}
                      onUpdateIsReopened={this.onUpdateIsReopened}
                      shouldRefreshFeedbackActions={shouldRefreshFeedbackActions}
                    />
                  </TabPane>
                ) : null}

                {this.isEdit() ? (
                  <TabPane tab='Files' key={TabInfo.files.id}>
                    <File
                      feedbackId={match.params.id}
                      history={this.props.history}
                      onRefreshFeedbackActions={this.onRefreshFeedbackActions}
                      shouldRefreshFeedbackActions={shouldRefreshFeedbackActions}
                    />
                  </TabPane>
                ) : null}

                {this.isEdit() ? (
                  <TabPane tab='Activity Log' key={TabInfo.logs.id}>
                    <ActivityLog feedbackId={match.params.id} />
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const {
      admins, feedbackCategories, feedbackSubCategories, item, loading, reportedByClients, reportedByEmployees,
      reportedByJobs, searchingReportedByClients, searchingReportedByEmployees, searchingReportedByJobs, setting
    } = this.state
    const { getFieldDecorator, getFieldValue } = form
    const genres = getFieldValue('genres')
    const hasAssessment = getFieldValue('has_assessment')
    const hasConsultation = getFieldValue('has_consultation')
    const hasHazardsImprovements = getFieldValue('has_hazards_improvements')
    const hasWitness = getFieldValue('has_witness')
    const isReportable = getFieldValue('is_reportable')
    const occurredAt = getFieldValue('occurred_at')
    const reportedBy = getFieldValue('reported_by')

    return (
      <Form>
        <Panel title='Feedback Details'>
          <Loading loading={loading} blur>
            <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Employee'>
                  {getFieldDecorator('employee_id', {
                    initialValue: item.employee_id ? item.employee_id.toString() : undefined,
                    rules: [
                      reportedBy === FeedbackReportedByOption.employee.value
                        ? { required: true, message: 'Please select an employee.' }
                        : { validator: this.checkClientEmployeeJob }
                    ]
                  })(
                    <Select
                      allowClear={!searchingReportedByEmployees}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      loading={searchingReportedByEmployees}
                      notFoundContent={null}
                      onSearch={this.handleSearchReportedByEmployees}
                      placeholder='Type to search employee'
                      showArrow={reportedByEmployees.length > 0}
                      showSearch
                    >
                      {reportedByEmployees.map(({ id, acc_ref, first_name, last_name }) => (
                        <Option key={id}>
                          <div>{first_name} {last_name}</div>

                          <div className='clientId'>{acc_ref}</div>
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Client'>
                  {getFieldDecorator('client_id', {
                    initialValue: item.client_id ? item.client_id.toString() : undefined,
                    rules: [
                      reportedBy === FeedbackReportedByOption.client.value
                        ? { required: true, message: 'Please select a client.' }
                        : { validator: this.checkClientEmployeeJob }
                    ]
                  })(
                    <Select
                      allowClear={!searchingReportedByClients}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      loading={searchingReportedByClients}
                      notFoundContent={null}
                      onSearch={this.handleSearchReportedByClients}
                      placeholder='Type to search client'
                      showArrow={reportedByClients.length > 0}
                      showSearch
                    >
                      {reportedByClients.map(({ id, acc_ref, first_name, last_name }) => (
                        <Option key={id}>
                          <div>{first_name} {last_name}</div>

                          <div className='clientId'>{acc_ref}</div>
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Job'>
                  {getFieldDecorator('job_id', {
                    initialValue: item.job_id ? item.job_id.toString() : undefined,
                    rules: [
                      { required: false, message: 'Please select a job.' },
                      { validator: this.checkClientEmployeeJob }
                    ]
                  })(
                    <Select
                      allowClear={!searchingReportedByJobs}
                      dropdownMatchSelectWidth={false}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      loading={searchingReportedByJobs}
                      notFoundContent={null}
                      onChange={this.changeJob}
                      onSearch={this.handleSearchReportedByJobs}
                      placeholder='Type to search job'
                      showArrow={reportedByJobs.length > 0}
                      showSearch
                    >
                      {reportedByJobs.map(({
                        id, c_first_name, c_last_name, is_sleepover_job, job_end_date, job_start_date, payroll, suburb
                      }) => (
                        <Option key={id}>
                          <div>
                            {formatter.toShortDate(job_start_date)}&nbsp;&nbsp;{formatter.toDay(job_start_date)}&nbsp;&nbsp;
                            {c_first_name} {c_last_name}&nbsp;&nbsp;<span style={{ textTransform: 'capitalize' }}>{payroll}</span>
                          </div>

                          <div className='clientId'>
                            {formatter.toShortTime(job_start_date)} - {formatter.toShortTime(job_end_date)}&nbsp;&nbsp;
                            {is_sleepover_job
                              ? '1 SO'
                              : `${Moment.duration(Moment(job_end_date).diff(Moment(job_start_date))).asHours()} hours`} &nbsp;&nbsp;
                            {suburb}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Reported By' onChange={this.changeReportedBy}>
                  {getFieldDecorator('reported_by', {
                    initialValue: item.reported_by,
                    rules: [
                      { required: true, message: 'Please select reported by.' }
                    ]
                  })(
                    <Radio.Group className='feedback-radio-group'>
                      {FeedbackReportedByOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                    </Radio.Group>
                  )}
                </FormItem>
              </Col>
            </Row>

            {reportedBy === FeedbackReportedByOption.others.value ? (
              <>
                <hr className='contact-separator' />

                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Name'>
                      {getFieldDecorator('reported_by_name', {
                        initialValue: item.reported_by_name,
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      })(
                        <Input placeholder='Name' />
                      )}
                    </FormItem>

                    <FormItem {...sideBySideFormItemLayout} label='Contact'>
                      {getFieldDecorator('reported_by_contact', {
                        initialValue: item.reported_by_contact,
                        rules: [
                          { min: 2, message: 'Contact must be between 2 and 128 characters' },
                          { max: 128, message: 'Contact must be between 2 and 128 characters' },
                          { requires: false, message: 'Please enter contact' },
                          { whitespace: true, message: 'Please enter contact' }
                        ]
                      })(
                        <Input placeholder='Contact' />
                      )}
                    </FormItem>
                  </Col>

                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Position'>
                      {getFieldDecorator('reported_by_position', {
                        initialValue: item.reported_by_position,
                        rules: [
                          { min: 2, message: 'Position must be between 2 and 128 characters' },
                          { max: 128, message: 'Position must be between 2 and 128 characters' },
                          { requires: false, message: 'Please enter position' },
                          { whitespace: true, message: 'Please enter position' }
                        ]
                      })(
                        <Input placeholder='Position' />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <hr className='contact-separator' />
              </>
            ) : null}

            <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Incident Date'>
                  {getFieldDecorator('occurred_at', {
                    initialValue: item.occurred_at ? Moment(item.occurred_at) : undefined,
                    rules: [
                      { required: true, message: 'Please select incident date.' }
                    ]
                  })(
                    <DatePicker
                      allowClear={false} disabledDate={this.disableOccurredAt} format={dateFormat} onChange={this.changeOccurredAt}
                    />
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Report Date'>
                  {getFieldDecorator('reported_at', {
                    initialValue: item.reported_at ? Moment(item.reported_at) : Moment().startOf('day'),
                    rules: [
                      { required: true, message: 'Please select report date.' },
                      { validator: this.checkReportedAt }
                    ]
                  })(
                    <DatePicker allowClear={false} disabledDate={this.disableReportedAt} format={dateFormat} />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Category'>
                  {getFieldDecorator('category_id', {
                    initialValue: item.category,
                    rules: [
                      { required: true, message: 'Please select a category.' }
                    ]
                  })(
                    <Select>
                      {feedbackCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Sub-Category'>
                  {getFieldDecorator('sub_category_id', {
                    initialValue: item.sub_category,
                    rules: [
                      { required: true, message: 'Please select a sub-category.' }
                    ]
                  })(
                    <Select>
                      {feedbackSubCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormItem {...formItemLayout} label='Location'>
                  {getFieldDecorator('location', {
                    initialValue: item.location,
                    rules: [
                      { min: 2, message: 'Location must be between 2 and 256 characters' },
                      { max: 128, message: 'Location must be between 2 and 256 characters' },
                      { required: true, message: 'Please enter location' },
                      { whitespace: true, message: 'Please enter location' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label='Description'>
                  {getFieldDecorator('description', {
                    initialValue: item.description,
                    rules: [
                      { required: true, message: 'Please enter description' },
                      { whitespace: true, message: 'Please enter description' }
                    ]
                  })(
                    <TextArea placeholder='Description & Impact on Person/s with Disability' />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormItem {...formItemLayout} label='Manager Notified'>
                  {getFieldDecorator('to_notify', {
                    initialValue: this.isEdit() ? item.to_notify : this.getDefaultToNotify(setting),
                    rules: [
                      { required: true, message: 'Please select at least one manager.' }
                    ]
                  })(
                    <Select mode="multiple">
                      {admins.map(({ email, name }) => <Option key={email}>{name} ({email})</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Loading>
        </Panel>

        {this.isEdit() ? (
          <>
            <Panel title='Feedback Involved'>
              <Loading loading={loading} blur>
                <Row gutter={12}>
                  <Col>
                    <FormItem {...formItemLayout} label='Feedback Involved'>
                      {getFieldDecorator('genres', {
                        initialValue: Array.isArray(item.genres) ? item.genres : undefined,
                        rules: [
                          { required: false, message: 'Please select feedback involved.' }
                        ]
                      })(
                        <Select mode="multiple" onChange={this.changeGenres}>
                          {FeedbackGenreOptions.map(({ label, value }) => <Option key={value}>{label}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                {Array.isArray(genres) && genres.length > 0 ? this.getGenreFormItems(genres) : null}
              </Loading>
            </Panel>

            <Panel title='Witnesses'>
              <Loading loading={loading} blur>
                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Has Witness'>
                      {getFieldDecorator('has_witness', {
                        initialValue: item.has_witness === true ? 'yes' : item.has_witness === false ? 'no' : undefined,
                        rules: [
                          { required: true, message: 'Please select has witness.' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                {hasWitness === YesNoOption.yes.value ? (
                  <>
                    <Row gutter={12}>
                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Name'>
                          {getFieldDecorator('witness_name1', {
                            initialValue: item.witness_name1,
                            rules: [
                              { min: 2, message: 'Name must be between 2 and 128 characters' },
                              { max: 128, message: 'Name must be between 2 and 128 characters' },
                              { required: true, message: 'Please enter name' },
                              { whitespace: true, message: 'Please enter name' }
                            ]
                          })(
                            <Input placeholder='Name of witness' />
                          )}
                        </FormItem>

                        <FormItem {...sideBySideFormItemLayout} label='Contact'>
                          {getFieldDecorator('witness_contact1', {
                            initialValue: item.witness_contact1,
                            rules: [
                              { min: 2, message: 'Contact must be between 2 and 128 characters' },
                              { max: 128, message: 'Contact must be between 2 and 128 characters' },
                              { requires: false, message: 'Please enter contact' },
                              { whitespace: true, message: 'Please enter contact' }
                            ]
                          })(
                            <Input placeholder='Contact of witness' />
                          )}
                        </FormItem>
                      </Col>

                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Position'>
                          {getFieldDecorator('witness_position1', {
                            initialValue: item.witness_position1,
                            rules: [
                              { min: 2, message: 'Position must be between 2 and 128 characters' },
                              { max: 128, message: 'Position must be between 2 and 128 characters' },
                              { requires: false, message: 'Please enter position' },
                              { whitespace: true, message: 'Please enter position' }
                            ]
                          })(
                            <Input placeholder='Position of witness' />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <hr className='contact-separator' />

                    <Row gutter={12}>
                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Name'>
                          {getFieldDecorator('witness_name2', {
                            initialValue: item.witness_name2,
                            rules: [
                              { min: 2, message: 'Name must be between 2 and 128 characters' },
                              { max: 128, message: 'Name must be between 2 and 128 characters' },
                              { required: false, message: 'Please enter name' },
                              { whitespace: true, message: 'Please enter name' }
                            ]
                          })(
                            <Input placeholder='Name of witness' />
                          )}
                        </FormItem>

                        <FormItem {...sideBySideFormItemLayout} label='Contact'>
                          {getFieldDecorator('witness_contact2', {
                            initialValue: item.witness_contact2,
                            rules: [
                              { min: 2, message: 'Contact must be between 2 and 128 characters' },
                              { max: 128, message: 'Contact must be between 2 and 128 characters' },
                              { requires: false, message: 'Please enter contact' },
                              { whitespace: true, message: 'Please enter contact' }
                            ]
                          })(
                            <Input placeholder='Contact of witness' />
                          )}
                        </FormItem>
                      </Col>

                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Position'>
                          {getFieldDecorator('witness_position2', {
                            initialValue: item.witness_position2,
                            rules: [
                              { min: 2, message: 'Position must be between 2 and 128 characters' },
                              { max: 128, message: 'Position must be between 2 and 128 characters' },
                              { requires: false, message: 'Please enter position' },
                              { whitespace: true, message: 'Please enter position' }
                            ]
                          })(
                            <Input placeholder='Position of witness' />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Loading>
            </Panel>

            <Panel title='Additional Info'>
              <Loading loading={loading} blur>
                <Row>
                  <Col>
                    <FormItem {...formItemLayout} label='Investigator Assigned'>
                      {getFieldDecorator('to_assign', {
                        initialValue: Array.isArray(item.to_assign) ? item.to_assign : undefined,
                        rules: [
                          { required: true, message: 'Please select at least one investigator.' }
                        ]
                      })(
                        <Select mode="multiple">
                          {admins.map(({ email, name }) => <Option key={email}>{name} ({email})</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Reportable Incident'>
                      {getFieldDecorator('is_reportable', {
                        initialValue: item.is_reportable === true ? 'yes' : item.is_reportable === false ? 'no' : undefined,
                        rules: [
                          { required: true, message: 'Please select reportable incident.' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                {isReportable === YesNoOption.yes.value ? (
                  <>
                    <Row gutter={12}>
                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Report Date 1'>
                          {getFieldDecorator('reportable_date1', {
                            initialValue: item.reportable_date1 ? Moment(item.reportable_date1) : Moment(occurredAt).add(1, 'day').startOf('day'),
                            rules: [
                              { required: true, message: 'Please select report date 1.' },
                              { validator: this.checkReportedAt }
                            ]
                          })(
                            <DatePicker allowClear={false} disabledDate={this.disableReportDate} format={dateFormat} />
                          )}
                        </FormItem>
                      </Col>

                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          label={(
                            <span>
                              <span>Mark as Done 1&nbsp;</span>

                              <Tooltip title='Marking as done will stop sending of email reminder'>
                                <Icon type="info-circle" />
                              </Tooltip>
                            </span>
                          )}
                        >
                          {getFieldDecorator('reportable_done1', {
                            initialValue: item.reportable_done1 || YesNoOption.no.value
                          })(
                            <Radio.Group className='feedback-radio-group'>
                              {YesNoOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                            </Radio.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col lg={12}>
                        <FormItem {...sideBySideFormItemLayout} label='Report Date 2'>
                          {getFieldDecorator('reportable_date2', {
                            initialValue: item.reportable_date2 ? Moment(item.reportable_date2) : Moment(occurredAt).add(5, 'day').startOf('day'),
                            rules: [
                              { required: true, message: 'Please select report date 2.' },
                              { validator: this.checkReportedAt }
                            ]
                          })(
                            <DatePicker allowClear={false} disabledDate={this.disableReportDate} format={dateFormat} />
                          )}
                        </FormItem>
                      </Col>

                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          label={(
                            <span>
                              <span>Mark as Done 2&nbsp;</span>

                              <Tooltip title='Marking as done will stop sending of email reminder'>
                                <Icon type="info-circle" />
                              </Tooltip>
                            </span>
                          )}
                        >
                          {getFieldDecorator('reportable_done2', {
                            initialValue: item.reportable_done2 || YesNoOption.no.value
                          })(
                            <Radio.Group className='feedback-radio-group'>
                              {YesNoOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                            </Radio.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Assessment of Incident'>
                      {getFieldDecorator('has_assessment', {
                        initialValue: item.has_assessment,
                        rules: [
                          { required: true, message: 'Please select assessment of incident' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoNaOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>

                    {hasAssessment === YesNoNaOption.yes.value ? (
                      <FormItem {...sideBySideFormItemLayout} label='Please Specify'>
                        {getFieldDecorator('assessment_incident', {
                          initialValue: item.assessment_incident,
                          rules: [
                            { min: 2, message: 'Assessment of incident must be between 2 and 128 characters' },
                            { max: 128, message: 'Assessment of incident must be between 2 and 128 characters' },
                            { whitespace: true, message: 'Please enter assessment of incident' }
                          ]
                        })(
                          <TextArea placeholder='Assessment of Incident' />
                        )}
                      </FormItem>
                    ) : null}
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Hazards & Improvements Identified'>
                      {getFieldDecorator('has_hazards_improvements', {
                        initialValue: item.has_hazards_improvements,
                        rules: [
                          { required: true, message: 'Please select hazards & improvements identified' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoNaOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>

                    {hasHazardsImprovements === YesNoNaOption.yes.value ? (
                      <FormItem {...sideBySideFormItemLayout} label='Please Specify'>
                        {getFieldDecorator('hazards_improvements', {
                          initialValue: item.hazards_improvements,
                          rules: [
                            { min: 2, message: 'Hazards & improvements identified must be between 2 and 128 characters' },
                            { max: 128, message: 'Hazards & improvements identified must be between 2 and 128 characters' },
                            { whitespace: true, message: 'Please enter hazards & improvements identified' }
                          ]
                        })(
                          <TextArea placeholder='Hazards & Improvements Identified' />
                        )}
                      </FormItem>
                    ) : null}
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Consultation'>
                      {getFieldDecorator('has_consultation', {
                        initialValue: item.has_consultation,
                        rules: [
                          { required: true, message: 'Please select consultation' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoNaOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>

                    {hasConsultation === YesNoNaOption.yes.value ? (
                      <FormItem {...sideBySideFormItemLayout} label='Please Specify'>
                        {getFieldDecorator('consultation', {
                          initialValue: item.consultation,
                          rules: [
                            { min: 2, message: 'Consultation must be between 2 and 128 characters' },
                            { max: 128, message: 'Consultation must be between 2 and 128 characters' },
                            { whitespace: true, message: 'Please enter consultation' }
                          ]
                        })(
                          <TextArea placeholder='Consultation' />
                        )}
                      </FormItem>
                    ) : null}
                  </Col>
                </Row>
              </Loading>
            </Panel>
          </>
        ) : null}
      </Form>
    )
  }

  changeCurrentTab = (value) => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    this.setState({ currentTab: value }, () => {
      if (value === TabInfo.actions.id) {
        window.history.replaceState(null, '', `/feedbacks/${id}${TabInfo.actions.path}`)
      } else if (value === TabInfo.files.id) {
        window.history.replaceState(null, '', `/feedbacks/${id}${TabInfo.files.path}`)
      } else if (value === TabInfo.logs.id) {
        window.history.replaceState(null, '', `/feedbacks/${id}${TabInfo.logs.path}`)
      } else {
        window.history.replaceState(null, '', `/feedbacks/${id}${TabInfo.info.path}`)
      }
    })
  }

  changeGenres = (value) => {
    // const { item, reportedByClients, reportedByEmployees, reportedByJobs } = this.state
    // const employeeGenreIdx = value.indexOf(FeedbackGenreOption.employee.value)
    // const clientGenreIdx = value.indexOf(FeedbackGenreOption.client.value)
    // const jobGenreIdx = value.indexOf(FeedbackGenreOption.job.value)

    // if (employeeGenreIdx > -1 && !item.genre_employee_id) {
    //   this.setState({ genreEmployees: [].concat(reportedByEmployees) })
    // }

    // if (clientGenreIdx > -1 && !item.genre_client_id) {
    //   this.setState({ genreClients: [].concat(reportedByClients) })
    // }

    // if (jobGenreIdx > -1 && !item.genre_job_id) {
    //   this.setState({ genreJobs: [].concat(reportedByJobs) })
    // }
  }

  changeJob = (value) => {
    const { form } = this.props
    const { reportedByJobs } = this.state
    const { setFieldsValue } = form

    if (value) {
      const job = reportedByJobs.find(({ id }) => id.toString() === value)

      if (job && job.id) {
        const { client_id: clientId, employee_id: employeeId, job_start_date: jobStartDate } = job
        setFieldsValue({ occurred_at: Moment(jobStartDate) })
        this.setState({ searchingReportedByClients: true, searchingReportedByEmployees: true }, () => {
          Promise.all([
            clientService.listByPage(1, 1, { id: clientId }),
            employeeService.listByPage(1, 1, { id: employeeId })
          ]).then(([clientResponse, employeeReponse]) => {
            this.setState({
              reportedByClients: clientResponse && Array.isArray(clientResponse.list) ? clientResponse.list : [],
              reportedByEmployees: employeeReponse && Array.isArray(employeeReponse.list) ? employeeReponse.list : [],
              searchingReportedByClients: false,
              searchingReportedByEmployees: false
            }, () => {
              setFieldsValue({ client_id: clientId.toString(), employee_id: employeeId.toString() })
            })
          })
        })
      }
    }
  }

  changeOccurredAt = () => {
    setTimeout(() => {
      const { form } = this.props
      const { getFieldValue, validateFieldsAndScroll } = form
      const isReportable = getFieldValue('is_reportable')
      let toValidate = ['reported_at']

      if (isReportable === YesNoOption.yes.value) {
        toValidate = toValidate.concat(['reportable_date1', 'reportable_date2'])
      }

      validateFieldsAndScroll(toValidate)
    }, 50)
  }

  changeReportedBy = ({ target }) => {
    const { value } = target
    const { form } = this.props
    const { getFieldValue, resetFields, validateFieldsAndScroll } = form

    if (value === FeedbackReportedByOption.client.value) {
      if (!getFieldValue('employee_id')) {
        resetFields(['employee_id'])
      }

      setTimeout(() => validateFieldsAndScroll(['client_id']), 100)
    } else if (value === FeedbackReportedByOption.employee.value) {
      if (!getFieldValue('client_id')) {
        resetFields(['client_id'])
      }

      setTimeout(() => validateFieldsAndScroll(['employee_id']), 100)
    } else if (value === FeedbackReportedByOption.others.value) {
      if (!getFieldValue('client_id')) {
        resetFields(['client_id'])
      }

      if (!getFieldValue('employee_id')) {
        resetFields(['employee_id'])
      }
    }
  }

  checkClientEmployeeJob = (rule, value, callback) => {
    if (!value || value.trim().length < 1) {
      const { form } = this.props
      const { client_id: clientId, employee_id: employeeId, job_id: jobId } = form.getFieldsValue(['client_id', 'employee_id', 'job_id'])

      if (!clientId && !employeeId && !jobId) {
        return callback(new Error('Please select at least a client, an employee or a job'))
      }
    }

    callback()
  }

  checkCurrentUrl = () => {
    const { match } = this.props
    const { params } = match || {}
    const { aid, type } = params

    if (this.isEdit() && !aid && (!type || !TabInfo[type] || !TabInfo[type].id)) {
      this.changeCurrentTab()
    }
  }

  checkEmail = (rule, value, callback) => {
    if (value && value.length > 1 && !validator.isEmail(value)) {
      return callback(new Error('E-mail is invalid'))
    }

    callback()
  }

  checkPhoneNo = (rule, value, callback) => {
    if (value && value.length > 1 && !validator.isPhoneNo(value)) {
      return callback(new Error('Phone number is invalid'))
    }

    callback()
  }

  checkReportedAt = (rule, value, callback) => {
    const { form } = this.props
    let occurredAt = form.getFieldValue('occurred_at')

    if (occurredAt && value) {
      occurredAt = Moment(occurredAt).startOf('day')

      if (Moment(value).startOf('day').isBefore(occurredAt)) {
        return callback(new Error('Report date must not be earlier than incident date'))
      }
    }

    callback()
  }

  disableOccurredAt = (date) => {
    return date && Moment(date).startOf('day') > Moment().startOf('day')
  }

  disableReportedAt = (date) => {
    const { form } = this.props
    const occurredAt = form.getFieldValue('occurred_at')

    if (occurredAt) {
      return date && (
        Moment(date).startOf('day') < Moment(occurredAt).startOf('day') ||
        Moment(date).startOf('day') > Moment().startOf('day')
      )
    }

    return date && (
      Moment(date).startOf('day') < Moment().subtract(1, 'month').endOf('day') ||
      Moment(date).startOf('day') > Moment().startOf('day')
    )
  }

  disableReportDate = (date) => {
    const { form } = this.props
    const occurredAt = form.getFieldValue('occurred_at')

    if (occurredAt) {
      return date && Moment(date).startOf('day') < Moment(occurredAt).startOf('day')
    }

    return false
  }

  fetchAdmins = async () => {
    const [adminResponse, settingReponse] = await Promise.all([
      adminService.listByPage(1, 0, { active: true }),
      settingOtherService.listByPage(1, 0)
    ])
    this.setState({
      admins: adminResponse && Array.isArray(adminResponse.list) ? adminResponse.list : [],
      setting: settingReponse && Array.isArray(settingReponse.list) ? settingReponse.list[0] || {} : {}
    })
  }

  fetchFeedback = async () => {
    try {
      this.setState({ loading: true }, async () => {
        const { form, match } = this.props
        const { params } = match
        const { id, aid } = params
        const { item } = await feedbackService.get(id)
        this.setState({ item }, async () => {
          await this.setFeedbackValues(form, item)
          this.setState({ loading: false, isReopened: false })
          this.setState({ currentTab: aid ? '2' : this.getCurrentTabFromUrl(), loading: false, isReopened: false })
        })
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load feedback successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchFeedbackCategories = () => {
    settingFeedbackCategoryService.getAll().then((feedbackCategories) => {
      this.setState({ feedbackCategories: Array.isArray(feedbackCategories) ? feedbackCategories : [] })
    })
  }

  fetchFeedbackSubCategories = () => {
    settingFeedbackSubCategoryService.getAll().then((feedbackSubCategories) => {
      this.setState({ feedbackSubCategories: Array.isArray(feedbackSubCategories) ? feedbackSubCategories : [] })
    })
  }

  fetchFeedbackTypes = () => {
    settingFeedbackTypeService.getAll().then((feedbackTypes) => {
      this.setState({ feedbackTypes: Array.isArray(feedbackTypes) ? feedbackTypes : [] })
    })
  }

  getCurrentTabFromUrl = () => {
    const { match } = this.props
    const { params } = match || {}
    const { type } = params || {}
    return (TabInfo[type] || TabInfo.info).id
  }

  getDefaultToNotify = (setting) => {
    const { feedback_added_email: fae } = setting
    const token = '|'

    if (typeof fae === 'string') {
      return fae.replace(/[;,]/g, token).split(token)
        .map((email) => typeof email === 'string' ? email.trim() : '')
        .filter((email) => typeof email === 'string' && email.length > 0)
    }

    return []
  }

  getGenreFormItems = (genres) => {
    const { form } = this.props
    const { genreFeedbacks, item, searchingGenreFeedbacks } = this.state
    const { getFieldDecorator } = form
    // const employeeGenreIdx = genres.indexOf(FeedbackGenreOption.employee.value)
    // const clientGenreIdx = genres.indexOf(FeedbackGenreOption.client.value)
    const peopleGenreIdx = genres.indexOf(FeedbackGenreOption.people.value)
    // const jobGenreIdx = genres.indexOf(FeedbackGenreOption.job.value)
    const feedbackGenreIdx = genres.indexOf(FeedbackGenreOption.feedback.value)
    const mattersGenreIdx = genres.indexOf(FeedbackGenreOption.matters.value)
    // const hasLeftCol = employeeGenreIdx + clientGenreIdx + peopleGenreIdx !== -3
    const hasLeftCol = peopleGenreIdx !== -1
    // const hasRightCol = jobGenreIdx + feedbackGenreIdx + mattersGenreIdx !== -3
    const hasRightCol = feedbackGenreIdx + mattersGenreIdx !== -2

    return (
      <Row gutter={12}>
        {hasLeftCol ? (
          <Col lg={12}>
            {/* {employeeGenreIdx > -1 ? (
              <FormItem {...sideBySideFormItemLayout} label='Employee'>
                {getFieldDecorator('genre_employee_id', {
                  initialValue: item.genre_employee_id
                    ? item.genre_employee_id.toString() : item.employee_id
                      ? item.employee_id.toString() : undefined,
                  rules: [
                    { required: true, message: 'Please select an employee.' }
                  ]
                })(
                  <Select
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    loading={searchingGenreEmployees}
                    notFoundContent={null}
                    onSearch={this.handleSearchGenreEmployees}
                    placeholder='Type to search employee'
                    showArrow={genreEmployees.length > 0}
                    showSearch
                  >
                    {genreEmployees.map(({ id, acc_ref, first_name, last_name }) => (
                      <Option key={id}>
                        <div>{first_name} {last_name}</div>

                        <div className='clientId'>{acc_ref}</div>
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            ) : null} */}

            {/* {clientGenreIdx > -1 ? (
              <FormItem {...sideBySideFormItemLayout} label='Client'>
                {getFieldDecorator('genre_client_id', {
                  initialValue: item.genre_client_id ? item.genre_client_id.toString() : item.client_id
                    ? item.client_id.toString() : undefined,
                  rules: [
                    { required: true, message: 'Please select a client.' }
                  ]
                })(
                  <Select
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    loading={searchingGenreClients}
                    notFoundContent={null}
                    onSearch={this.handleSearchGenreClients}
                    placeholder='Type to search client'
                    showArrow={genreClients.length > 0}
                    showSearch
                  >
                    {genreClients.map(({ id, acc_ref, first_name, last_name }) => (
                      <Option key={id}>
                        <div>{first_name} {last_name}</div>

                        <div className='clientId'>{acc_ref}</div>
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            ) : null} */}

            {peopleGenreIdx > -1 ? (
              <>
                <FormItem {...sideBySideFormItemLayout} label='Name'>
                  {getFieldDecorator('people_name', {
                    initialValue: item.people_name,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input placeholder='Name' />
                  )}
                </FormItem>

                <FormItem {...sideBySideFormItemLayout} label='Contact'>
                  {getFieldDecorator('people_contact', {
                    initialValue: item.people_contact,
                    rules: [
                      { min: 2, message: 'Contact must be between 2 and 128 characters' },
                      { max: 128, message: 'Contact must be between 2 and 128 characters' },
                      { requires: false, message: 'Please enter contact' },
                      { whitespace: true, message: 'Please enter contact' }
                    ]
                  })(
                    <Input placeholder='Contact' />
                  )}
                </FormItem>

                <FormItem {...sideBySideFormItemLayout} label='Position'>
                  {getFieldDecorator('people_position', {
                    initialValue: item.people_position,
                    rules: [
                      { min: 2, message: 'Position must be between 2 and 128 characters' },
                      { max: 128, message: 'Position must be between 2 and 128 characters' },
                      { requires: false, message: 'Please enter position' },
                      { whitespace: true, message: 'Please enter position' }
                    ]
                  })(
                    <Input placeholder='Position' />
                  )}
                </FormItem>
              </>
            ) : null}
          </Col>
        ) : null}

        {hasRightCol ? (
          <Col lg={12}>
            {/* {jobGenreIdx > -1 ? (
              <FormItem {...sideBySideFormItemLayout} label='Job'>
                {getFieldDecorator('genre_job_id', {
                  initialValue: item.genre_job_id ? item.genre_job_id.toString() : item.job_id
                    ? item.job_id.toString() : undefined,
                  rules: [
                    { required: true, message: 'Please select a job.' }
                  ]
                })(
                  <Select
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    loading={searchingGenreJobs}
                    notFoundContent={null}
                    onSearch={this.handleSearchGenreJobs}
                    placeholder='Type to search job'
                    showArrow={genreJobs.length > 0}
                    showSearch
                  >
                    {genreJobs.map(({
                      id, first_name, is_sleepover_job, job_end_date, job_start_date, last_name, payroll, so_sum_hour_day,
                      suburb
                    }) => (
                      <Option key={id}>
                        <div>
                          {formatter.toShortDate(job_start_date)}&nbsp;&nbsp;{formatter.toDay(job_start_date)}&nbsp;&nbsp;
                          {first_name} {last_name}&nbsp;&nbsp;<span style={{ textTransform: 'capitalize' }}>{payroll}</span>
                        </div>

                        <div className='clientId'>
                          {formatter.toShortTime(job_start_date)} - {formatter.toShortTime(job_end_date)}&nbsp;&nbsp;
                          {is_sleepover_job
                            ? formatter.toDecimalS(so_sum_hour_day)
                            : Moment.duration(Moment(job_end_date).diff(Moment(job_start_date))).asHours()} hours&nbsp;&nbsp;
                          {suburb}
                        </div>
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            ) : null} */}

            {feedbackGenreIdx > -1 ? (
              <FormItem {...sideBySideFormItemLayout} label='Feedback'>
                {getFieldDecorator('genre_feedback_id', {
                  initialValue: item.genre_feedback_id ? item.genre_feedback_id.toString() : undefined,
                  rules: [
                    { required: true, message: 'Please select a feedback.' }
                  ]
                })(
                  <Select
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    loading={searchingGenreFeedbacks}
                    notFoundContent={null}
                    onSearch={this.handleSearchGenreFeedbacks}
                    placeholder='Type to search feedback'
                    showArrow={genreFeedbacks.length > 0}
                    showSearch
                  >
                    {genreFeedbacks.map(({
                      id, case_no, category_name, client_id, employee_id, job_id, occurred_at, rb_client_first_name,
                      rb_client_last_name, rb_employee_first_name, rb_employee_last_name, reported_at, status_colour: statusClour,
                      status_name: statusName,
                      sub_category_name
                    }) => {
                      return (
                        <Option key={id}>
                          <div>
                            #{case_no}&nbsp;&nbsp;{formatter.toShortDate(occurred_at)}&nbsp;&nbsp;{formatter.toShortDate(reported_at)}
                          </div>

                          <div className='clientId'>
                            {category_name}&nbsp;&nbsp;{sub_category_name}&nbsp;&nbsp;<FeedbackStatus colour={statusClour} label={statusName} />
                          </div>

                          {client_id || employee_id || job_id ? (
                            <div className='clientId'>
                              {client_id ? <>{rb_client_first_name} {rb_client_last_name}&nbsp;&nbsp;</> : null}
                              {employee_id ? <>{rb_employee_first_name} {rb_employee_last_name}&nbsp;&nbsp;</> : null}
                              {job_id ? <>Job #{job_id}</> : null}
                            </div>
                          ) : null}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </FormItem>
            ) : null}

            {mattersGenreIdx > -1 ? (
              <FormItem {...sideBySideFormItemLayout} label='Other Matters'>
                {getFieldDecorator('matters_description', {
                  initialValue: item.matters_description,
                  rules: [
                    { min: 2, message: 'Other matters must be between 2 and 128 characters' },
                    { max: 128, message: 'Other matters must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter other matters' },
                    { whitespace: true, message: 'Please enter other matters' }
                  ]
                })(
                  <TextArea placeholder='Other matters' />
                )}
              </FormItem>
            ) : null}
          </Col>
        ) : null}
      </Row>
    )
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { loading } = this.state
    const { params } = match
    const { id } = params

    if (loading) {
      return
    }

    confirm({
      title: 'Are you sure you want to delete this feedback?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk() {
        try {
          const response = await feedbackService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Feedback deleted successfully.')
            history.replace('/feedbacks')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete feedback successfully. Please try again later.')
        }
      }
    })
  }

  handleEditButton = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    this.setState({ showSave: true, showEdit: false })
  }

  handleMarkAsDone1 = () => {
    const { form } = this.props
    form.setFieldsValue({ reportable_done1: YesNoOption.yes.value }, () => {
      this.handleSave()
    })
  }

  handleMarkAsDone2 = () => {
    const { form } = this.props
    form.setFieldsValue({ reportable_done2: YesNoOption.yes.value }, () => {
      this.handleSave()
    })
  }

  handleReopenButton = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    this.setState({ showSave: true, isReopened: true })
  }

  handleSave = () => {
    const { form } = this.props
    const { loading } = this.state

    if (loading) {
      return
    }

    const { validateFieldsAndScroll } = form
    validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        this.setState({ loading: true }, async () => {
          const { fetchingFeedbacks } = this.props
          const { isReopened, item } = this.state
          const { genres, has_consultation, has_witness, is_reportable, occurred_at, reported_at, reported_by } = values
          values.occurred_at = Moment(occurred_at).startOf('day')
          values.reported_at = Moment(reported_at).startOf('day')
          values.reopened = isReopened

          if (Array.isArray(genres) && genres.length > 0) {
            // const employeeGenreIdx = genres.indexOf(FeedbackGenreOption.employee.value)
            // const clientGenreIdx = genres.indexOf(FeedbackGenreOption.client.value)
            const peopleGenreIdx = genres.indexOf(FeedbackGenreOption.people.value)
            // const jobGenreIdx = genres.indexOf(FeedbackGenreOption.job.value)
            const feedbackGenreIdx = genres.indexOf(FeedbackGenreOption.feedback.value)
            const mattersGenreIdx = genres.indexOf(FeedbackGenreOption.matters.value)

            // if (employeeGenreIdx < 0) {
            //   values.genre_employee_id = null
            // }

            // if (clientGenreIdx < 0) {
            //   values.genre_client_id = null
            // }

            if (peopleGenreIdx < 0) {
              values.people_contact = null
              values.people_name = null
              values.people_position = null
            }

            // if (jobGenreIdx < 0) {
            //   values.genre_job_id = null
            // }

            if (feedbackGenreIdx < 0) {
              values.genre_feedback_id = null
            }

            if (mattersGenreIdx < 0) {
              values.matters_description = null
            }
          } else {
            values.genre_employee_id = null
            values.genre_client_id = null
            values.people_contact = null
            values.people_name = null
            values.people_position = null
            values.genre_job_id = null
            values.genre_feedback_id = null
            values.matters_description = null
          }

          if (
            reported_by === FeedbackReportedByOption.client ||
            reported_by === FeedbackReportedByOption.employee
          ) {
            values.reported_by_contact = null
            values.reported_by_name = null
            values.reported_by_position = null
          }

          if (has_consultation !== YesNoNaOption.yes.value) {
            values.consultation = null
          }

          if (has_consultation !== YesNoNaOption.yes.value) {
            values.consultation = null
          }

          if (has_consultation !== YesNoNaOption.yes.value) {
            values.consultation = null
          }

          if (has_witness !== YesNoOption.yes.value) {
            values.witness_contact1 = null
            values.witness_name1 = null
            values.witness_position1 = null
            values.witness_contact2 = null
            values.witness_name2 = null
            values.witness_position2 = null
          }

          if (is_reportable !== YesNoOption.yes.value) {
            values.reportable_date1 = null
            values.reportable_done1 = null
            values.reportable_date2 = null
            values.reportable_done2 = null
          }

          try {
            if (this.isEdit()) {
              const response = await feedbackService.save(item.id, values)
              this.setState({ item: { ...item, ...values }, loading: false }, () => {
                if (response.id) {
                  notify.success('Saved successfully', 'Feedback saved successfully.')
                  this.props.setRefreshActivityLog(true)
                  this.fetchFeedback()
                  fetchingFeedbacks(true)
                }
              })
            } else {
              const response = await feedbackService.add(values)
              this.setState({ loading: false }, () => {
                if (response.id) {
                  const { id } = response
                  item.case_no = `${id}`.padStart(8, '0')
                  this.setState({ item: { ...item, ...values, id } }, () => {
                    notify.success('Saved successfully', 'Feedback saved successfully.')
                    this.props.setRefreshActivityLog(true)
                    window.location.replace(`/feedbacks/${id}`)
                    fetchingFeedbacks(true)
                  })
                }
              })
            }
          } catch (e) {
            notify.error('Unable to save successfully', 'Unable to save feedback successfully. Please try again later.')
            this.setState({ loading: false })
          }
        })
      }
    })
  }

  handleSearchClients = (listKey, loadingKey) => (value) => {
    value = typeof value === 'string' ? value.trim() : ''

    if (value.length > 0) {
      this.setState({ [loadingKey]: true }, async () => {
        const filter = { $and: [] }
        const words = value.split(' ')

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }

        const response = await clientService.listByPage(1, 100, filter)
        this.setState({ [listKey]: response && Array.isArray(response.list) ? response.list : [], [loadingKey]: false })
      })
    }
  }

  handleSearchEmployees = (listKey, loadingKey) => (value) => {
    value = typeof value === 'string' ? value.trim() : ''

    if (value.length > 0) {
      this.setState({ [loadingKey]: true }, async () => {
        const filter = { $and: [] }
        const words = value.split(' ')

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }

        const response = await employeeService.listByPage(1, 100, filter)
        this.setState({ [listKey]: response && Array.isArray(response.list) ? response.list : [], [loadingKey]: false })
      })
    }
  }

  handleSearchFeedbacks = (listKey, loadingKey) => (value) => {
    value = typeof value === 'string' ? value.trim() : ''

    if (value.length > 0) {
      this.setState({ [loadingKey]: true }, async () => {
        const { match } = this.props
        const { params } = match
        const { id } = params
        const filter = { $and: [{ id: { condition: '<>', value: id } }] }
        const words = value.split(' ')

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { case_no: { condition: 'ilike', value: `%${words[i]}%` } },
              { rb_client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { rb_client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { rb_employee_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { rb_employee_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { category_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { sub_category_name: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }

        const response = await feedbackService.listByPage(1, 100, filter)
        this.setState({ [listKey]: response && Array.isArray(response.list) ? response.list : [], [loadingKey]: false })
      })
    }
  }

  handleSearchJobs = (listKey, loadingKey) => (value) => {
    value = typeof value === 'string' ? value.trim() : ''

    if (value.length > 0) {
      this.setState({ [loadingKey]: true }, async () => {
        const { form } = this.props
        const { getFieldValue } = form
        const clientId = getFieldValue('client_id')
        const employeeId = getFieldValue('employee_id')
        const filter = { $and: [{ job_start_date: { condition: '<=', value: Moment().endOf('day') } }] }
        const words = value.split(' ')

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { str_job_start_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { str_job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { c_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { c_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { c_preferred_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { c_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { c_suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { e_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { e_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { e_preferred_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { e_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }

        if (clientId) {
          filter.$and.push({ client_id: clientId })
        }

        if (employeeId) {
          filter.$and.push({ employee_id: employeeId })
        }

        const response = await jvpJobService.listDropdownByPage(1, 20, filter)
        this.setState({ [listKey]: response && Array.isArray(response.list) ? response.list : [], [loadingKey]: false })
      })
    }
  }

  initJob = async () => {
    const { form, history } = this.props
    const { location } = history
    const { search } = location
    const { jobId } = queryString.parse(search)

    if (jobId > 0) {
      const jobResponse = await jvpJobService.listDropdownByPage(1, 1, { id: jobId })
      const { list: jobList } = jobResponse || {}
      const hasJobs = Array.isArray(jobList) && jobList.length > 0
      this.setState({ reportedByJobs: hasJobs ? jobList : [] }, () => {
        form.setFieldsValue({ job_id: jobId }, () => {
          this.changeJob(jobId)
        })
      })
    }
  }

  isCompleted = () => {
    const { item } = this.state
    const { status } = item || {}
    return status === FeedbackStatusOption.completed.value
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  onRefreshFeedbackActions = () => {
    this.setState({ shouldRefreshFeedbackActions: false })
  }

  onUpdateFeedbackStatus = ({ code, colour, name }) => {
    const { item } = this.state
    item.status = code
    item.status_name = name
    item.status_colour = colour
    this.setState({ item })
  }

  onUpdateIsReopened = (isReopened) => {
    this.setState({ isReopened })
  }

  setFeedbackValues = async (form, item) => {
    const {
      genres, genre_feedback_id, category_id, sub_category_id, client_id, employee_id, job_id, occurred_at, reported_at,
      description, location, is_reportable, reportable_date1, reportable_done1, reportable_date2, reportable_done2,
      has_assessment, assessment_incident, has_hazards_improvements, hazards_improvements, has_consultation, consultation,
      has_witness, witness_name1, witness_contact1, witness_position1, witness_name2, witness_contact2, witness_position2,
      to_assign, to_notify
    } = item || {}
    const _genres = Array.isArray(genres) ? genres : undefined
    const categoryId = typeof category_id === 'number' ? category_id.toString() : undefined
    const subCategoryId = typeof sub_category_id === 'number' ? sub_category_id.toString() : undefined
    const clientId = typeof client_id === 'number' ? client_id.toString() : undefined
    const employeeId = typeof employee_id === 'number' ? employee_id.toString() : undefined
    const jobId = typeof job_id === 'number' ? job_id.toString() : undefined
    const occurredAt = Moment(occurred_at)
    const reportedAt = Moment(reported_at)
    const isReportable = is_reportable === true
      ? YesNoOption.yes.value : is_reportable === false
        ? YesNoOption.no.value : undefined
    const hasWitness = has_witness === true
      ? YesNoOption.yes.value : has_witness === false
        ? YesNoOption.no.value : undefined
    const toAssign = Array.isArray(to_assign) ? to_assign : undefined
    form.setFieldsValue({
      genres: _genres, category_id: categoryId, sub_category_id: subCategoryId, occurred_at: occurredAt, reported_at: reportedAt,
      description, location, is_reportable: isReportable, has_assessment, has_hazards_improvements, has_consultation,
      has_witness: hasWitness, to_assign: toAssign, to_notify
    }, () => {
      if (is_reportable) {
        const reportableDate1 = Moment(reportable_date1)
        const reportableDate2 = Moment(reportable_date2)
        form.setFieldsValue({
          reportable_date1: reportableDate1, reportable_done1, reportable_date2: reportableDate2, reportable_done2
        })
      }

      if (has_assessment === YesNoNaOption.yes.value) {
        form.setFieldsValue({ assessment_incident })
      }

      if (has_hazards_improvements === YesNoNaOption.yes.value) {
        form.setFieldsValue({ hazards_improvements })
      }

      if (has_consultation === YesNoNaOption.yes.value) {
        form.setFieldsValue({ consultation })
      }

      if (has_witness) {
        form.setFieldsValue({
          witness_name1, witness_contact1, witness_position1, witness_name2, witness_contact2, witness_position2
        })
      }
    })

    if (Array.isArray(genres) && genres.length > 0) {
      // const employeeGenreIdx = genres.indexOf(FeedbackGenreOption.employee.value)
      // const clientGenreIdx = genres.indexOf(FeedbackGenreOption.client.value)
      // const jobGenreIdx = genres.indexOf(FeedbackGenreOption.job.value)
      const feedbackGenreIdx = genres.indexOf(FeedbackGenreOption.feedback.value)

      // if (clientGenreIdx > -1 && genre_client_id > 0) {
      //   const response = await clientService.listByPage(1, 1, { id: genre_client_id })
      //   this.setState({ genreClients: response && Array.isArray(response.list) ? response.list : [] })
      // }

      // if (employeeGenreIdx > -1 && genre_employee_id > 0) {
      //   const response = await employeeService.listByPage(1, 1, { id: genre_employee_id })
      //   this.setState({ genreEmployees: response && Array.isArray(response.list) ? response.list : [] })
      // }

      if (feedbackGenreIdx > -1 && genre_feedback_id > 0) {
        const response = await feedbackService.listByPage(1, 1, { id: genre_feedback_id })
        this.setState({ genreFeedbacks: response && Array.isArray(response.list) ? response.list : [] })
      }

      // if (jobGenreIdx > -1 && genre_job_id > 0) {
      //   const response = await fvpJobsService.listByPage(1, 1, { id: genre_job_id })
      //   this.setState({ genreJobs: response && Array.isArray(response.list) ? response.list : [] })
      // }
    }

    if (clientId > 0) {
      const response = await clientService.listByPage(1, 1, { id: clientId })
      this.setState({ reportedByClients: response && Array.isArray(response.list) ? response.list : [] }, () => {
        form.setFieldsValue({ client_id: clientId })
      })
    }

    if (employeeId > 0) {
      const response = await employeeService.listByPage(1, 1, { id: employeeId })
      this.setState({
        reportedByEmployees: response && Array.isArray(response.list) ? response.list : []
      }, () => {
        form.setFieldsValue({ employee_id: employeeId })
      })
    }

    if (jobId > 0) {
      const response = await jvpJobService.listDropdownByPage(1, 1, { id: jobId })
      this.setState({ reportedByJobs: response && Array.isArray(response.list) ? response.list : [] }, () => {
        form.setFieldsValue({ job_id: jobId })
      })
    }
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchingFeedbacks,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Feedback }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Feedback))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jobService, reportService, settingHolidayService } from '../../../../services'
import { exportFile, formatter, log } from '../../../../util'
import { ExportType } from '../../../../constants'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { apiHostname } from '../../../../config'

// UI
import { Button, Loading, List, Page, Pager, SideModal } from '../../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const pageSize = 50
export class HolidayImpactedJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      holiday: {},
      jobs:  { list: [], total: 0 },
      jobsCount: 0,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false
    }
  }

  componentDidMount () {
    this.fetchDetail()
    this.fetchList()
  }

  fetchDetail = async () => {
    const id = this.getId()
    const { holiday, count } = await settingHolidayService.listImpactedJobCount(id)

    this.setState({ holiday, jobsCount: count })
  }

  fetchList = () => {
    const { currentPage, isLoaded, loading } = this.state
    this.fetchJobs({currentPage, isLoaded, loading})
  }

  fetchJobs = async ({ currentPage, isLoaded = true, loading }) => {
    const filter = {}
    const id = this.getId()
    const sorting = { job_start_date: -1, client: 1 }
    filter.id = { condition: '=', value: id }

    if (isLoaded) {
      this.setState({ loadingList: true })
    } else {
      this.setState({ loading: true })
    }

    const jobs = await reportService.getListByPageView(ExportType.REPORT.PH_JOB_LIST, id, currentPage, pageSize)

    this.setState({
      jobs,
      loading: false,
      loadingList: false,
      isLoaded: true
    }, () => this.toTop())
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  render () {
    const { jobs, isLoaded, loading, loadingExport, loadingList, currentPage, holiday, jobsCount } = this.state

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job, job_survey_feedback }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }}>SO</div> }
          { job_survey_feedback ? <Tooltip title='Job Feedbak'><div className='job-indicator' style={{ backgroundColor: '#f5a142' }}>F</div></Tooltip> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >F</div> }
        </div>
      },
      {
        title: 'Client',
        width: 5,
        // key: 'client'
        render: ({ client_id: clientId, client }) => <div><a href={`/clients/${clientId}`} rel='noopener noreferrer' target='_blank'>{client}</a></div>
      },
      {
        title: 'Day',
        width: 2,
        render: ({ job_start_date: startDate }) => startDate ? moment(startDate).format('dddd') : '-'
      },

      {
        title: 'Start Date',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortDate(job_start_date)
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortDate(job_end_date)
      },
      {
        title: 'Start Time',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortTime(job_start_date)
      },
      {
        title: 'End Time',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortTime(job_end_date)
      },
      {
        title: 'Status',
        width: 2,
        render: ({ job_id, job_leave_status, is_cancel }) => <div style={{ color: '#ccc', fontSize: '12pt' }}>
          { is_cancel ? <div><Icon type='check-circle' /> &nbsp;<Tooltip mouseLeaveDelay={0} title='Cancelled'><span style={{ color: '#ff5b5b' }}><Icon type='close-circle' theme='filled' /></span></Tooltip></div> : <div>
            <Tooltip mouseLeaveDelay={0} title='Keep?'>{ job_leave_status === 'approved' ? <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span> : <Icon type='check-circle' onClick={() => this.saveStatus(job_id, 'approved')} /> }</Tooltip> &nbsp;
            <Tooltip mouseLeaveDelay={0} title='To review?'>{ job_leave_status !== 'approved' ? <span style={{ color: '#f2b018' }}><Icon type='exclamation-circle' theme='filled' /></span> : <Icon type='exclamation-circle' onClick={() => this.saveStatus(job_id, 'review')} /> }</Tooltip></div> }
        </div>
      },
      {
        title: '',
        width: 1,
        render: ({ job_id }) => <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
          <Tooltip mouseLeaveDelay={0} title='View'> <a href={`/jobs/single/${job_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></Tooltip>
        </div>
      }
    ]

    return (
      <Page.Body className='impacted'>
        <Page.Left>
          <Page.Menu
            title='Public Holidays'
            backLink={`/reports/public-holiday`}
            panel={<div style={{ textAlign: 'center', padding: '20px 0' }}>
              <div className='employee'>{holiday && holiday.id ? `${holiday.name}` : ''}</div>
            </div>}
          />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={holiday && holiday.id
              ? `${jobsCount} Affected Job${Number(jobsCount) === 1 ? '' : 's'}: Public Holiday (${holiday.name}) On ${holiday.string_date}`
              : 'Public Holiday'}
          >
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
          </Page.Header>
          <Loading loading={loading} blur>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <List cols={columns} rows={jobs.list} />

                <Pager
                  size={pageSize}
                  total={jobs.total}
                  totalText={`Total ${jobs.total} job${jobs.total === 1 ? '' : 's'}`}
                  current={currentPage}
                  onChange={(e) => this.onChangePage(e)}
                  style={{ marginTop: '15px' }}
                />
              </Spin>
            </Skeleton>
          </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  handleExport = () => {
    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const id = this.getId()

      const r = await exportFile.fetchReportExport(ExportType.REPORT.PH_JOB_LIST, { id })
      this.setState({ loadingExport: false })
    }, 4000)
  }

  async saveStatus (id, value) {
    const { jobs } = this.state
    const result = await jobService.save(id, { job_leave_status: value })

    if (result.id) {
      // get new to action number
      this.fetchDetail()
      for (let i = 0; i < jobs.list.length; i++) {
        const job = jobs.list[i]
        if (job.job_id === id) {
          job.job_leave_status = value
          break
        }
      }
    }

    this.setState({ jobs })
  }

  isEdit = () => {
    const id = this.getId()
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

export default HolidayImpactedJob

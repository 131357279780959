import React, { Component } from 'react'
import { connect } from 'react-redux'
import { communicationService, jobService } from '../../../../services'
import { fetchingClients } from '../../../../states/actions'
import { formatter, log } from '../../../../util'
import moment from 'moment'

// UI
import { Loading, List, Pager, Button, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const pageSize = 20

export class JobCommunication extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      communication: {
        list: [],
        total: 0
      },
      currentPage: 0
    }
  }

  componentWillReceiveProps (nextProps) {
    const { onUpdateRefresh } = this.props
    if (nextProps.shouldRefreshComm !== this.props.shouldRefreshComm) {
      if (nextProps.shouldRefreshComm) {
        this.fetchCommunications()
        onUpdateRefresh(false)
      }
    }
  }

  componentDidMount () {
    this.fetchCommunications()
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { communication, currentPage, loading, modal } = this.state

    const commColumns = [
      {
        title: 'Date',
        width: 4,
        render: ({ created_at: createdAt }) => formatter.toStandardDate(createdAt)
      },
      {
        title: 'Event',
        width: 3,
        render: ({ event }) => <div style={{ textTransform: 'capitalize' }}>{event}</div>
      },
      {
        title: 'Subject',
        width: 8,
        key: 'subject'
      },
      {
        title: 'Sent?',
        width: 2,
        render: ({ sent_at: sentAt }) => sentAt ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: 'Ignored?',
        width: 2,
        render: ({ id, omitted }) => <div onClick={() => this.handleOmit(id, omitted)} style={{cursor: 'pointer'}}>{omitted ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Tooltip mouseLeaveDelay={0} title='No need to send'><Icon type='check-circle' /></Tooltip></div>}</div>
      },
      {
        title: 'Confirmed?',
        width: 2,
        render: ({ is_confirmed: isConfirmed }) => isConfirmed ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: 'Action',
        width: 2,
        render: ({ id, subject, body }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit email'><div onClick={() => this.handleEditEmail(id, subject, body)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Send'><div onClick={() => this.handleSendEmail(id)} style={{ cursor: 'pointer' }}><Icon type='mail' /></div></Tooltip>
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='communication-list'>
          <Skeleton loading={loading} active>
            <List cols={commColumns} rows={communication.list} />

            <Pager
              size={pageSize}
              total={communication.total}
              totalText={`Total ${communication.total} emails`}
              current={currentPage}
              onChange={(e) => this.onChangeCommunicationPage(e)}
              style={{ marginTop: '15px' }}
            />

            <SideModal
              title='Communication (V1)'
              showModal={modal.show}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave}>Save</Button>,
                <Button key='1' onClick={this.handleSaveSend}>Save & Send</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Subject'>
                  {getFieldDecorator('subject', {
                    initialValue: modal.item.subject,
                    rules: [
                      { min: 2, message: 'Subject must be between 2 and 128 characters' },
                      { max: 128, message: 'Subject must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter subject' },
                      { whitespace: true, message: 'Please enter subject' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
                <FormItem label='Body'>
                  {getFieldDecorator('body', {
                    initialValue: modal.item.body,
                    rules: [
                      { required: true, message: 'Please enter body' }
                    ]
                  })(
                    <TextArea rows={12} />
                  )}
                </FormItem>
              </Form>

            </SideModal>
          </Skeleton>
        </div>

      </Loading>
    )
  }

  fetchCommunications = async (currentPage = 1) => {
    const { jobId } = this.props

    this.setState({ loading: true })
    const filter = {}

    filter.module = { condition: '=', value: 'job' }
    filter.job_id = { condition: '=', value: jobId }

    const communication = await jobService.getEmails(currentPage, pageSize, filter)
    const unsentComm = await jobService.getUnsentEmailTotal(jobId)
    //console.log('Unsent No', unsentComm)
    // const { unsentTotal } = unsentComm

    this.setState({ communication: Object.assign(communication, currentPage), loading: false, currentPage, filter })
  }

  handleEditJobs = (id) => {
    const { history } = this.props
    history.replace(`/v1/jobs/single/${id}`)
  }

  handleEditEmail = (id, subject, body) => {
    this.showModal(subject, body)
    this.setState({ communicationId: id })
  }

  handleSendEmail = async (id) => {
    const { jobId, onUpdate } = this.props
    this.setState({ loading: true })
    const result = communicationService.send(id)

    if (result) {
      notify.success('Sent successfully', 'Email sent successfully')

      // email log
      const emailRes = await communicationService.get(id)
      const { item } = emailRes
      const { body } = item

      log.sendEmailSingleJob(jobId, body)

      const { communication } = this.state
      const comm = communication.list.find(item => item.id === id)
      comm.sent_at = moment()

      // get unsent
      const unsentComm = await jobService.getUnsentEmailTotal(jobId)
      const { unsentTotal } = unsentComm

      onUpdate(unsentTotal)

      this.setState({ communication })
    } else {
      notify.error('Unable to send successfully', 'Please try again later.')
    }
    this.setState({ loading: false })
    // window.location.reload()
  }

  handleSave = async () => {
    const { form } = this.props
    const { communicationId } = this.state
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const response = await communicationService.save(communicationId, values)

        if (response.id) {
          const { communication } = this.state
          const comm = communication.list.find(item => item.id === communicationId)
          comm.subject = values.subject
          comm.body = values.body

          this.setState({ communication })

          notify.success('Saved successfully', 'Email saved successfully')
          this.hideModal()
        } else {
          notify.error('Unable to save successfully', 'Unable to save Email successfully. Please try again later.')
        }
      }
    })
  }

  handleSaveSend = async () => {
    const { communicationId } = this.state

    await this.handleSave()

    setTimeout(async () => {
      await this.handleSendEmail(communicationId)
      window.location.reload()
    }, 1200)
  }

  handleOmit = async (id, omitted) => {
    const { jobId, onUpdate } = this.props
    const { communication } = this.state
    const response = await communicationService.save(id, {omitted : !omitted})

    if (response.id) {
      for (let i = 0; i < communication.list.length; i++) {
        if (communication.list[i].id === id) {
          communication.list[i].omitted = !omitted
          break
        }
      }
    }

    this.setState({ communication })

    // get unsent
    const unsentComm = await jobService.getUnsentEmailTotal(jobId)
    const { unsentTotal } = unsentComm

    onUpdate(unsentTotal)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { subject: null, body: null }
    modal.show = false
    this.setState({ modal, communicationId: null })
  }

  showModal = (subject, body) => {
    const { modal } = this.state
    modal.item = { subject, body }
    modal.show = true
    this.setState({ modal })
  }

  onChangeCommunicationPage = async (currentPage) => {
    const { communication } = this.state
    communication.currentPage = currentPage
    this.setState({ communication })
    this.fetchCommunications(currentPage)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobCommunication))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingHolidayService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Button, Page, Panel, SideModal } from '../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Public Holidays'
const settingType = 'gender'

export class SettingHoliday extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchHolidays({ currentPage })
  }

  fetchHolidays = async ({ currentPage = 1 }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingHolidayService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        values.type = settingType
        values.value = values.name.toLowerCase()
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingHolidayService.save(id, values)
          } else {
            values.image = item.image
            response = await settingHolidayService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingHolidayService.removeSet(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchHolidays({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='Public Holidays'>
            <Link to={`/settings/holidays/add`}>
              {
                this.hasAccess('createPublicHoliday') ? <div className='btn'>
                Add
                </div> : null
              }
            </Link>
          </Page.Header>

          <div className='setting-list'>
            <Skeleton loading={loading} active>
              <Row className='list-header'>
                <Col md={24}>Name</Col>
              </Row>
              {list.map((item) => {
                const { id, name } = item

                return (
                  <div className='list-item' key={id}>
                    <Row className='list-content'>
                      <Col md={22}>{ name }</Col>
                      { this.hasAccess('readPublicHoliday') && !this.hasAccess('updatePublicHoliday') ? <Col md={1}>
                        <Link to={`/settings/holidays/${id}`}><div><Icon type='eye' /></div></Link>
                      </Col>
                        : null }
                      <Col md={1}>
                        { this.hasAccess('updatePublicHoliday') ? <Link to={`/settings/holidays/${id}`}><div><Icon type='form' /></div></Link> : null }
                      </Col>
                      <Col md={1}>
                        {
                          this.hasAccess('deletePublicHoliday') ? <Popconfirm
                            title='Confirm to delete this?'
                            onConfirm={(e) => this.handleDelete(id)}
                            okText='Yes'
                            cancelText='No'
                          ><Icon type='delete' />
                          </Popconfirm> : null
                        }
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Skeleton>
          </div>

        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            Configure public holidays
          </Page.Panel>
        </Page.Right>

        <SideModal
          title={settingTitle}
          showModal={modal.show}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Name'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Name must be between 2 and 128 characters' },
                  { max: 128, message: 'Name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingHoliday))

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI
import Alert from 'antd/lib/alert'
import Icon from 'antd/lib/icon'

import './styles.css'

export default class List extends React.Component {
  constructor (props) {
    super(props)
    const { defaultSort = {} } = props
    this.state = {
      defaultSort,
      pristine: true,
      sorting: {},
      sortingText: {}
    }
  }

  render () {
    const { cols, dimmedIf, hideColumns, highlightIf, link, loading = true, onClick, rows, showEmptyMessage = true, headerTextColor = undefined } = this.props
    const { defaultSort, pristine, sorting } = this.state

    const actualLink = (row) => {
      let actualLink = link || ''

      if (actualLink.indexOf('<') >= 0) {
        const key = /<(.*?)>/g.exec(actualLink)
        actualLink = actualLink.replace(/<.*?>/, row[key[1]])
      }

      return actualLink
    }

    const highlight = (row) => {
      if (highlightIf && row[highlightIf.key] === highlightIf.value) {
        return true
      }
      return false
    }

    const dimmed = (row) => {
      if (dimmedIf) {
        if (dimmedIf.overwrite && dimmedIf.overwrite === true) {
          return true
        }
        if (dimmedIf.key && row[dimmedIf.key] === dimmedIf.value) {
          return true
        }
      }

      return false
    }

    const listRow = (row, index) => (
      <tr className={'wd-list-row' + (highlight(row) ? ' wd-list-row-highlight' : '')} key={index}>
        {cols.map((col, idx) => (
          <td className='wd-list-col' style={{...toTableWidth(col.width), color: dimmed(row) ? '#aaa' : undefined}} key={idx}>
            {col.render ? (
              col.render(row, index)
            ) : col.key ? (
              row[col.key]
            ) : ''}
          </td>
        ))}
      </tr>
    )

    const toTableWidth = (width) => {
      return { width: `${(100 * width / 24).toFixed(2)}%` }
    }

    return (
      <div className='wd-list-container'>
        <table className='wd-list-body'>
          <tbody>
            {!hideColumns ? (
              <tr className='wd-list-header'>
                {cols.map((col, idx) => {
                  const { key, onSort, onSortItem, title, width } = col
                  const sortKey = pristine ? defaultSort[key] : sorting[idx]

                  return (typeof onSort === 'function' || typeof onSortItem === 'function') ? (
                    <td key={idx} style={{...toTableWidth(width), color: headerTextColor}}>
                      <div
                        className='wd-list-header-sort'
                        onClick={(e) => onSortItem ? this.handleSortItem(e, idx, key, onSortItem) : onSort ? this.handleSort(e, idx, key, onSort) : null}
                      >
                        <span>{title}</span>

                        <span className='wd-list-header-caret'>
                          <Icon type='caret-up' style={{ color: sortKey === 1 ? 'var(--themeColorLite)' : 'var(--themeColorDark)' }} />

                          <Icon type='caret-down' style={{ color: sortKey === -1 ? 'var(--themeColorLite)' : 'var(--themeColorDark)' }} />
                        </span>
                      </div>
                    </td>
                  ) : (
                    <td key={idx} className='wd-list-header' style={{...toTableWidth(width), color: headerTextColor}}>{title}</td>
                  )
                })}
              </tr>
            ) : null}

            {showEmptyMessage && (!loading && rows.length < 1) ? (
              <Alert message='The list is empty. Try adding something into it.' showIcon type='info' />
            ) : null}

            {rows.map((row, idx) => (
              link || onClick ? (
                <Link to={link ? actualLink(row) : '#'} onClick={(e) => this.handleClick(e, row)}>
                  {listRow(row, idx)}
                </Link>
              ) : listRow(row, idx)
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  handleClick (e, row) {
    const { onClick } = this.props

    if (typeof onClick === 'function') {
      e.preventDefault()
      onClick(row)
    }
  }

  handleSort (e, idx, key, onSort) {
    if (typeof onSort === 'function') {
      const { defaultSort, pristine, sorting } = this.state
      let order = pristine ? defaultSort[key] : sorting[idx] || 0
      order = order === 1 ? -1 : order === -1 ? 0 : 1

      const newSort = Object.assign({}, { [idx]: order })
      this.setState({ pristine: false, sorting: newSort })
      onSort(key, order)
    }
  }

  handleSortItem (e, idx, key, onSortItem) {
    if (typeof onSortItem === 'function') {
      const { defaultSort, pristine, sorting, sortingText } = this.state

      if (Array.isArray(key)) {
        /**
         * if key is an array type, only one value with pure text is allowed.
         * if more than one pure text available in array, only take the first one and ignore the following
         * using flag "isKeyReady" to ignore all non-first pure text element
         * furthermore, it will not have multiple column sorting if key is in array
         */
        let newSort = {}
        let newSortText = {}
        let isKeyReady = false

        for (let i = 0; i < key.length; i++) {
          const k = key[i]

          // key value could be pure text or object with {key, sort} pattern for array type of key
          if (k && k.key) {
            // k object with {key, sort} handling, if k is in object {key, sort} form, the key will be in fixed order (value from k.sort)
            let order = k.sort || 0

            newSortText = Object.assign(newSortText, { [k.key]: order })
          } else {
            // k in pure text handling, ignore if any of pure text key is processed before
            if (!isKeyReady) {
              let order = pristine ? defaultSort[k] : sorting[idx] || 0
              order = order === 1 ? -1 : order === -1 ? 0 : 1

              newSort = Object.assign(newSort, { [idx]: order })
              newSortText = Object.assign(newSortText, { [k]: order })

              isKeyReady = true
            }
          }
        }
        this.setState({ pristine: false, sorting: newSort, sortingText: newSortText })
        onSortItem(newSortText)
      } else {
        let order = pristine ? defaultSort[key] : sorting[idx] || 0
        order = order === 1 ? -1 : order === -1 ? 0 : 1

        const newSort = Object.assign({}, sorting, { [idx]: order })
        const newSortText = Object.assign({}, sortingText, { [key]: order })
        this.setState({ pristine: false, sorting: newSort, sortingText: newSortText })
        onSortItem(newSortText)
      }
    }
  }
}

List.propTypes = {
  cols: PropTypes.array.isRequired,
  defaultSort: PropTypes.object,
  hideColumns: PropTypes.bool,
  highlight: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rows: PropTypes.array.isRequired,
  showEmptyMessage: PropTypes.bool
}

const isUrl = require('is-url')

const validator = {
  checkPassword (str) {
    return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/.test(str)
  },
  isCurrencyAmount (str) {
    return /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(str)
  },
  isDecimal (str) {
    return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str)
  },
  isDigit (str) {
    return /^\d+$/.test(str)
  },
  isEmail (str) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
  },
  isEmptyString (str) {
    return this.isString(str) && str.length < 1
  },
  isEmptyString2 (str) {
    return str === null || str === undefined || typeof str === 'string' && str.trim().length < 1
  },
  isImage (file) {
    return ['image/jpeg', 'image/png'].indexOf(file.type) > -1
  },
  isNotEmpty (obj) {
    return obj && obj.length > 0
  },
  isNotEmptyObject (value) {
    const isEmpty = (value) => {
      let name
      for (name in value) {
        if (value.hasOwnProperty(name)) {
          return true
        }

        return false
      }
    }
    return this.isObject(value) && isEmpty(value)
  },
  isArray (array) {
    if (array) {
      return array.constructor === Array
    }
    return false
  },
  isNotEmptyArray (arr) {
    return this.isArray(arr) && arr.length > 0
  },
  isNumberText (value) {
    return /^[0-9]*\.?[0-9]*$/.test(value)
  },
  isNumberTextNegative (value) {
    return /^[+-]?(?=\.?[0-9])[0-9]*\.?[0-9]*$/.test(value)
  },
  isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object
  },
  isPhoneNo (value) {
    return /^[0-9#*]{1}[0-9 #*]*[0-9#*]{1}$/.test(value)
  },
  isString (str) {
    return typeof str === 'string'
  },
  isUrl (str) {
    return isUrl(str)
  },
  isFunction (fx) {
    return fx && typeof fx === 'function'
  },
  numOfDecimalPoints (num, str) {
    const regex = new RegExp(`(\\.\\d{${num + 1},})$`)
    return !regex.test(str)
  }
}

export default validator

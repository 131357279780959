import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { formatter, validator } from '../../../util'
import { settingHolidayService, authService } from '../../../services'
import ReportMenu from '../../../constants/menu/report'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, List, Page, Pager, SearchInput, Button } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Switch from 'antd/lib/switch'
const { Item: FormItem } = Form
const { build, timezone } = require('../../../config')
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
moment.tz.setDefault(timezone)

const pageSize = 20

export class PublicHolidays extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterYear: moment().format('YYYY'),
      holidays: { list: [], total: 0 },
      holidayYears: [],
      isLoaded: false,
      loading: false,
      loadingList: false,
      loadingYear: false,
      searchText: '',
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.fetchYears()
  }

  render () {
    const { currentPage, filterYear, holidays, holidayYears, loading, loadingList, loadingYear, searchText } = this.state

    const holidayColumns = [
      {
        title: 'Name',
        width: 10,
        key: 'name'
      },
      {
        title: 'Date',
        width: 6,
        render: ({ date }) => moment(date).format('DD/MM/YYYY')
      },
      {
        title: 'Notes',
        width: 8,
        render: ({ id, impacted, total_jobs }) => total_jobs > 0 ? <Link to={`/holidays/${id}/job`}><div style={{ color: '#e5374e', cursor: 'pointer' }}><Icon type='issues-close' /> {impacted} affected job{Number(impacted) === 1 ? '' : 's'}. </div></Link> : ''
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Public Holidays`}>
          </Page.Header>

          <div>
            { !loadingYear
              ? <RadioGroup
                onChange={this.onChangeYear}
                defaultValue={filterYear}
                value={filterYear}
                style={{ marginLeft: 20 }}
              >
                { holidayYears.map((i, index) => (
                    <RadioButton key={`hyc${index}`} value={i.year}>{`${i.year}`}</RadioButton>
                  )) }
              </RadioGroup>
              : null }

            <div className='setting-list'>
              <Skeleton loading={loading} active>
                <Spin spinning={loadingList}>
                  <List cols={holidayColumns} rows={holidays.list} />

                  <Pager
                    size={pageSize}
                    total={holidays.total}
                    totalText={`Total ${holidays.total} record${holidays.total === 1 ? '' : 's'}`}
                    current={currentPage}
                    onChange={this.onChangeYear}
                    style={{ marginTop: '15px' }}
                  />
                </Spin>
              </Skeleton>
            </div>
          </div>
        </Page.Content>

        <Page.Right>
          <div className='wd-noti-panel-box'>
            <ControlLabel>Search: </ControlLabel>
            <SearchInput placeholder='' onChange={(v) => this.onSearchName(v)} />
          </div>
        </Page.Right>
      </Page.Body>
    )
  }

  fetchList = async () => {
    const { currentPage, isLoaded, searchText, filter, filterYear } = this.state
    this.fetchHolidays({ currentPage, isLoaded, searchText, filter, filterYear })
  }

  fetchHolidays = async ({ currentPage, isLoaded = true, searchText, filter, filterYear }) => {
    filter.$or = []
    filter.$and = []

    filter.$and.push({ year: { condition: '=', value: filterYear } })

    if (searchText !== '') {
      filter.$or.push({ string_date: { condition: 'ilike', value: `%${searchText}%` } })
      filter.$or.push({ name: { condition: 'ilike', value: `%${searchText}%` } })
    }

    if (isLoaded) {
      this.setState({ loadingList: true })
    } else {
      this.setState({ loading: true })
    }

    try {
      const holidays = await settingHolidayService.listItemByPage(currentPage, pageSize, filter)

      this.setState({
        holidays,
        filter,
        loading: false,
        loadingList: false,
        isLoaded: true
      })
    } catch (e) {
      notify.error('Unable to get Public Holiday Report', 'Please try again later.')
      this.setState({ loading: false, loadingList: false, isLoaded: false  })
    }
  }

  fetchYears = async () => {
    this.setState({ loadingYear: true, loading: true })
    const years = await settingHolidayService.listYears()
    const firstYear = validator.isNotEmptyArray(years) ? years[0].year : null

    this.setState({
      loadingYear: false,
      holidayYears: years,
      filterYear: firstYear ? firstYear : this.state.filterYear
    }, () => {
      this.fetchList()
    })
  }

  onChangeYear = (e) => {
    const year = e.target.value

    this.setState({
      filterYear: year,
      isLoaded: false
    }, () => this.fetchList())
  }

  onSearchName (value) {
    this.setState({
      searchText: value
    }, () => this.fetchList())
  }
}

export default Form.create()(PublicHolidays)

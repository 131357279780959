import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatter, localStorage } from '../../util'
import { fvpPayrollService } from '../../services'

// UI
import { List } from '../../components'
import notify from '../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'

const pageSize = 2000

export class PayrollPrintFvp extends Component {
  constructor (props) {
    super(props)
    const { payrolls: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: {},
      list,
      loading,
      sort: {},
      total
    }
  }

  componentDidMount () {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = { id }
    const { currentPage, loading, searchText, sort } = this.state
    this.fetchTimesheetSummaryFvp(id)
    this.fetchTimesheetsFvp({ currentPage, filter, loading, searchText, sort })
    this.setState({ filter, loading: true })
  }

  render () {
    const { payroll = {}, summary = {}, loading } = this.state
    const { date_from, date_to, employees = 0, clients = 0, jobs = 0, kms = 0, hours = 0, sleepovers = 0, getups = 0, approved = 0, review = 0, signed = 0 } = summary

    const columns = [
      this.isEmployeeView() ? {
        title: 'Employees',
        width: 5,
        render: ({ employee }) => <span style={{ fontWeight: 'bold' }}><Icon type='user' /> {employee}</span>
      } : {
        title: 'Clients',
        width: 5,
        render: ({ client }) => <span style={{ fontWeight: 'bold' }}><Icon type='user' /> {client}</span>
      },
      {
        title: 'Accounting Ref',
        width: 3,
        key: 'acc_ref'
      },
      {
        title: 'Jobs',
        width: 2,
        render: ({ jobs }) => <div style={{ color: '#1a63b0' }}>{jobs}</div>
      },
      {
        title: 'Approved',
        width: 2,
        render: ({ approved }) => <div style={{ color: approved > 0 ? '#4fbc85' : '#aaa' }}>{approved}</div>
      },
      {
        title: 'To Review / Signed',
        width: 3,
        render: ({ review, signed }) => <div>
          <span style={{ color: review > 0 ? '#f50' : '#aaa' }}>{review}</span> / <span style={{ color: signed > 0 ? '#e8419d' : '#aaa' }}>{signed}</span>
        </div>
      },
      {
        title: 'Hours',
        width: 1,
        key: 'hours'
      },
      {
        title: 'Sleepovers',
        width: 2,
        render: ({ sleepovers }) => sleepovers || 0
      },
      {
        title: 'Getups',
        width: 1,
        render: ({ getups }) => getups || 0
      },
      {
        title: 'KMs',
        width: 1,
        render: ({ kms }) => formatter.toDecimalS(kms) || 0
      },
    ]

    return (
      <div className='app'>
        <div className='payroll-print-fvp'>
          <Skeleton loading={loading} active>
            <div className='title'>Timesheet ( {formatter.toShortDate(date_from)} - {formatter.toShortDate(date_to)} )</div>
            <div className='page-summary' style={{ width: '99%' }}>
              <Row>
                {this.isEmployeeView() ? <Col sm={3} lg={3} className='col'>
                    <div className='number'>{employees}</div>
                    Employees
                  </Col> : <Col sm={3} lg={3} className='col'>
                    <div className='number'>{clients}</div>
                    Clients
                  </Col>}
                  <Col lg={3} className='col'>
                  <div className='number'>{jobs}</div>
                  Jobs
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{approved}</div>
                  Approved
                </Col>
                <Col lg={5} className='col'>
                  <div className='number'>{review} / {signed}</div>
                  To Review / Signed
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{hours}</div>
                  Hours
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{sleepovers || 0}</div>
                  Sleepovers
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{getups || 0}</div>
                  Getups
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{kms}</div>
                  KMs
                </Col>
              </Row>
            </div>
            <List cols={columns} rows={payroll.list || []} />
          </Skeleton>
        </div>
      </div>
    )
  }

  fetchTimesheetsFvp = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const payroll = await fvpPayrollService.listTimesheetByPage(currentPage, pageSize, filter, sort, searchText, this.getTimesheetView())
      this.setState({ payroll, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchTimesheetSummaryFvp = async (id) => {
    try {
      const summary = await fvpPayrollService.getTimesheetSummary(id, this.getTimesheetView())
      this.setState({ summary, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  getTimesheetView () {
    return localStorage.getItem('timesheetView') || 'employee'
  }

  isEmployeeView () {
    return this.getTimesheetView() === 'employee'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Payroll }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayrollPrintFvp)

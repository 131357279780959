import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientLeaveService, jobService, jobActionLogService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import { exportFile, formatter, log } from '../../../util'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { apiHostname } from '../../../config'
import { ExportType } from '../../../constants'

// UI
import { Loading, List, Page } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Col from 'antd/lib/col'
import notification from 'antd/lib/notification'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import job from '../../../services/job'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

notification.config({
  placement: 'topRight',
  top: 130,
  duration: 3
})

export class ClientImpactedJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      jobs: { list: [], total: 0 },
      loading: false,
      isCancelling: false,
      impacted: false
    }
  }

  componentDidMount () {
    this.fetchLeaveJobs()
    this.checkRef()
    this.fetchTotalJobs()
  }

  selectJob = (id) => {
    const { jobs } = this.state

    for (let i = 0; i < jobs.list.length; i++) {
      const job = jobs.list[i]
      if (job.job_id === id) {
        job.selected = job.selected ? !job.selected : true
        break
      }
    }

    this.setState({ jobs })
  }

  cancelJob = async () => {
    const { name: adminName } = authService.getCurrentUser()
    const { jobs, isCancelling } = this.state
    const selectedJobs = []

    if (isCancelling) return

    for (let i = 0; i < jobs.list.length; i++) {
      const job = jobs.list[i]
      if (job.selected) {
        selectedJobs.push(job)
      }
    }

    if (selectedJobs.length > 0) {
      this.setState({ isCancelling: true })
      for (let j = 0; j < selectedJobs.length; j++) {
        const job = selectedJobs[j]
        const values = {
          is_cancel: true,
          cancellation_time: moment(),
          cancellation_reason: job.memo,
          cancellation_type: 1,
          employee_id: job.employee_id,
          original_end_date: job.job_end_date,
          staff: adminName
        }

        // Generate & save log_multi_action
        const actions = {}
        actions.module = 'job'
        actions.module_id = job.job_id
        actions.action = 'job-cancel'
        actions.action_detail = `Job cancelled by client leave. Cancellation reason - Client - ${job.memo}`
        actions.previous_id = job.employee_id
        actions.current_id = job.employee_id
        actions.action_lvl1_text = `${job.memo} (leave memo)`
        const actionLog = await jobActionLogService.add(actions)

        const response = await jobService.save(job.job_id, values)
        if (response.id) {
          log.cancelJob(response.id, `Cancellation reason - Client - ${job.memo}`)
        }
      }
      // notify.success('Success', 'Job Cancelled.')
      this.setState({ isCancelling: false })
      this.fetchLeaveJobs()
      this.fetchTotalJobs()
    } else {
      notification['warning']({
        message: 'No job to cancel',
        description:
          'Please select at least a job to proceed'
      })
    }
  }

  render () {
    const { form, match } = this.props
    const { jobs, loading, isCancelling, impacted, impactedNumber } = this.state
    const { params } = match
    const { id } = params
    const info = jobs.list.length > 0 ? {
      firstName: jobs.list[0].first_name,
      lastName: jobs.list[0].last_name,
      gender: jobs.list[0].gender,
      leaveStart: jobs.list[0].leave_start,
      leaveEnd: jobs.list[0].leave_end,
      isUFN: jobs.list[0].until_further_notice
    } : {}

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency, cancellation_penalty }) => (
          <div className='indicator-list'> { emergency ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>E</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
            { cancellation_penalty > 0 ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
          </div>
        )
      },
      {
        title: 'Employee',
        width: 5,
        render: ({ employee_id: empId, employee }) => <div><a href={`/employees/${empId}`} rel='noopener noreferrer' target='_blank'>{employee}</a></div>
      },
      {
        title: 'Day',
        width: 2,
        render: ({ job_start_date: startDate }) => startDate ? moment(startDate).format('dddd') : '-'
      },

      {
        title: 'Start Date',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortDate(job_start_date)
      },

      {
        title: 'End Date',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortDate(job_end_date)
      },

      {
        title: 'Start Time',
        width: 2,
        render: ({ job_start_date }) => formatter.toShortTime(job_start_date)
      },

      {
        title: 'End Time',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortTime(job_end_date)
      },
      {
        title: 'Status',
        width: 2,
        render: ({ is_cancel }) => is_cancel ? <div style={{ color: '#ccc', fontSize: '12pt' }}>
          <Tooltip mouseLeaveDelay={0}  title='Cancelled'><span style={{ color: '#ff5b5b' }}><Icon type='close-circle' theme='filled' /></span></Tooltip>
        </div> : null
      },
      {
        title: '',
        width: 1,
        render: ({ job_id, selected, is_cancel }) => !is_cancel ? <div style={{ color: '#ccc', fontSize: '12pt', cursor: 'pointer' }}>
          <Tooltip mouseLeaveDelay={0} title='Cancel?'>
            <div onClick={() => this.selectJob(job_id)}>{ !selected ? <Icon type='check-square' /> : <span style={{ color: '#4fa3e2' }}><Icon type='check-square' theme='filled' /></span>}</div>
          </Tooltip>
        </div> : null
      },
      {
        title: '',
        width: 1,
        render: ({ job_id }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='View'> <a href={`/jobs/single/${job_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></Tooltip>
        </div>
      }
    ]

    return (
      <Page.Body className='impacted'>
        <Page.Left>
          <Page.Menu title='Client' backLink={impacted ? '/reports/client-leave' : `/clients/${id}/leaves`} panel={<div style={{ textAlign: 'center', padding: '20px 0' }}>
            <img alt='' src={process.env.PUBLIC_URL + '/img/' + (info.gender && info.gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' />
            <div className='employee'>
              {
                info.firstName
              }
              &nbsp;
              {
                info.lastName
              }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`${impactedNumber} Jobs To Action (Leave From ${formatter.toShortDate(info.leaveStart)} to ${info.isUFN ? 'UFN' : formatter.toShortDate(info.leaveEnd)})`}>
            <div className='btn' onClick={() => this.handleExport()}>
              Export
            </div>
            <div className='btn' onClick={() => this.cancelJob()}>
              { isCancelling ? <span style={{ marginRight: 5 }}><Icon type='sync' spin /></span> : null }Cancel Job
            </div>
          </Page.Header>
          <Loading loading={loading} blur>
            {/* <Page.Filter>
              <Row gutter={8}>
                <Col lg={20}>
                  <div className='page-title'>{impactedNumber} Jobs To Action (Leave From {formatter.toShortDate(info.leaveStart)} to {formatter.toShortDate(info.leaveEnd)})</div>
                </Col>
                <Col lg={4}>

                </Col>
              </Row>
            </Page.Filter> */}
            <Skeleton loading={loading} active>
              <List cols={columns} rows={jobs.list} />
            </Skeleton>
          </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchLeaveJobs = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    this.setState({ loading: true })
    const jobs = await clientLeaveService.listImpactedJobsByPage(1, 200, { id: lid })
    this.setState({ jobs, loading: false })
  }

  fetchTotalJobs = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params
    let filter = {}
    filter.is_cancel = { condition: '=', value: false }
    filter.id = { condition: '=', value: lid }
    const { total } = await clientLeaveService.listImpactedJobsByPage(1, 5, filter)
    this.setState({ impactedNumber: total })
  }

  handleExport = async () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    try {
      this.setState({ loading: true })
      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_CLIENT_JOB_TO_ACTION, {}, lid)

      setTimeout(() => {
        this.setState({ loading: false })
      }, 3000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get jobs to action export successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  checkRef () {
    const { location } = this.props
    if (location.search === '?notify') {
      // redirected from notification page
      this.setState({ impacted: true })
    } else {
      // redirected from client page
      console.log('From Client Page')
    }
  }

  isEdit = () => {
    const id = this.getId()
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientImpactedJob))

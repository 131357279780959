import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientService, carePlanService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment'

// UI
import { Button, FileUpload, Loading, List, Pager, SectionTitle, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Notification from 'antd/lib/notification'
import { formatter, log, uploader } from '../../../util'
import { diff } from 'json-diff'
import { apiHostname } from '../../../config'
import CarePlanFile from './file'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane

const Option = Select.Option
const pageSize = 20
const clientModule = 'client'

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

export class ClientCarePlan extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      item: {},
      loading: false,
      clientId: '',
      payrolls: [],
      languages: [],
      skills: [],
      carePlanVersions: { list: [] },
      version: 1,
      highlightFields: [],
      shouldRefreshFiles: false
    }
  }

  componentDidMount () {
    // console.log('CarePlan props', this.props)
    this.fetchCarePlan()
    this.fetchCarePlanVersion()
  }

  viewVersion = (version) => {
    const { carePlanVersions } = this.state
    const changeIds = []

    this.props.form.resetFields()

    const currentVersion = carePlanVersions.list.filter(item => item.version === version)
    this.setState({ version, carePlan: currentVersion[0] })

    if (version > 1) {
      const previousVersion = carePlanVersions.list.filter(item => item.version === version - 1)

      const changes = diff(currentVersion[0], previousVersion[0])

      for (const key of Object.keys(changes)) {
        const value = changes[key]

        if (value && value.hasOwnProperty('__new')) {
          // check if the value is date
          if (value.__old && value.__old.toString().indexOf('.000Z') > -1) {
            // skip if no changes on date
            if (!moment(value.__old).isSame(moment(value.__new))) {
              changeIds.push(key)
            }
          } else {
            if (value.__old && value.__new && (value.__old.toString() !== value.__new.toString())) {
              changeIds.push(key)
            } else if (!value.__old && value.__new) {
              changeIds.push(key)
            }
          }
        }
      }
    }

    this.setState({ highlightFields: changeIds })
  }

  shouldHighlight = (field) => {
    const { highlightFields } = this.state
    const result = highlightFields.find(item => item === field)
    return { backgroundColor: result ? '#fff5dd99' : '#fff' }
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const { carePlan = '', loading, carePlanVersions, version, shouldRefreshFiles } = this.state

    return (<Form>
      <Loading loading={loading} blur>
        { this.carePlanTab() }
        {/* <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
          <TabPane tab="Care Plan" key="1">
            { this.carePlanTab() }
          </TabPane>
          <TabPane tab="Files" key="2">
            <CarePlanFile clientId={clientId} history={this.props.history} onUpdateInfo={() => ''} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
          </TabPane>
        </Tabs> */}
      </Loading>
    </Form>)
  }

  carePlanTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { carePlan = '', loading, carePlanVersions, version } = this.state

    return <Row gutter={16}>
      <Col lg={20}>
        <Row>
          <Col className='button-row'>
            { carePlanVersions.total > 0
              ? <div className='btn btn-ghost' style={{ width: 180 }} onClick={this.handleDownload}>
              Download Care Plan
              </div> : null
            }
            {/* { version === carePlanVersions.list.length
              ? <div className='btn' onClick={this.handleSave}>
              Save Care Plan
              </div> : null
            } */}
          </Col>
        </Row>

        <SectionTitle title='Client Information' />

        <FormItem {...formItemLayout} label='About The Client' hasFeedback>
          {getFieldDecorator('client_about', {
            initialValue: carePlan.client_about || '',
            rules: [
              { required: true, message: 'Please enter About The Client' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_about')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Purpose Of Service' hasFeedback>
          {getFieldDecorator('client_purpose', {
            initialValue: carePlan.client_purpose || '',
            rules: [
              { required: true, message: 'Please enter Purpose Of Service' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_purpose')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label='Assistance With Communication' hasFeedback>
          {getFieldDecorator('client_assist_comm', {
            initialValue: carePlan.client_assist_comm || '',
            rules: [
              { required: true, message: 'Please enter Assistance With Communication' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_assist_comm')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label='Mobility' hasFeedback>
          {getFieldDecorator('client_mobility', {
            initialValue: carePlan.client_mobility || '',
            rules: [
              { required: true, message: 'Please enter Mobility' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_mobility')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label='Supervision & Safety' hasFeedback>
          {getFieldDecorator('client_supervisions', {
            initialValue: carePlan.client_supervisions || '',
            rules: [
              { required: true, message: 'Please enter Supervision & Safety' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_supervisions')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label='Fears/Dislike' hasFeedback>
          {getFieldDecorator('client_fears_dislike', {
            initialValue: carePlan.client_fears_dislike || '',
            rules: [
              { required: true, message: 'Please enter Fears/Dislike' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('client_fears_dislike')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label='Mobility Option'>
          {getFieldDecorator('client_mobility_option', {
            initialValue: carePlan.client_mobility_option || '',
            rules: [
              { required: true, message: 'Please select Mobility Option' }
            ]
          })(
            <Radio.Group style={this.shouldHighlight('client_mobility_option')}>
              <Radio value='independent'>Independent</Radio>
              <Radio value='partial'>Partial Assist</Radio>
              <Radio value='full'>Full Assist</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Sexual Identity'>
          {getFieldDecorator('client_sexual', {
            initialValue: carePlan.client_sexual || '',
            rules: [
              { required: true, message: 'Please enter Sexual Identity' }
            ]
          })(
            <Input style={this.shouldHighlight('client_sexual')} />
          )}
        </FormItem>

        <SectionTitle title='Medical Diagnosis' />

        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Diagnosis' hasFeedback>
              {getFieldDecorator('medical_diagnosis', {
                initialValue: carePlan.medical_diagnosis || '',
                rules: [
                  { required: true, message: 'Please enter Diagnosis' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('medical_diagnosis')} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Allergies' hasFeedback>
              {getFieldDecorator('medical_allergies', {
                initialValue: carePlan.medical_allergies || '',
                rules: [
                  { required: true, message: 'Please enter Allergies' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('medical_allergies')} />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Rehabilitation Activity' hasFeedback>
              {getFieldDecorator('medical_rehab_activity', {
                initialValue: carePlan.medical_rehab_activity || '',
                rules: [
                  { required: true, message: 'Please enter Rehabilitation Activity' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('medical_rehab_activity')} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Medication Assistance Supervision' hasFeedback>
              {getFieldDecorator('medical_medication_assist', {
                initialValue: carePlan.medical_medication_assist || '',
                rules: [
                  { required: true, message: 'Please enter Medication Assistance Supervision' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('medical_medication_assist')} />
              )}
            </FormItem>
          </Col>
        </Row>

        <SectionTitle title='Care Needs' />

        <FormItem {...formItemLayout} label='Bathing/Showering' hasFeedback>
          {getFieldDecorator('care_bathing_shower', {
            initialValue: carePlan.care_bathing_shower || '',
            rules: [
              { required: true, message: 'Please enter Bathing/Showering' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_bathing_shower')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Self Care & Personal Hygiene Option'>
          {getFieldDecorator('care_hygiene_option', {
            initialValue: carePlan.care_hygiene_option || '',
            rules: [
              { required: true, message: 'Please select Self Care & Personal Hygiene Option' }
            ]
          })(
            <Radio.Group style={this.shouldHighlight('care_hygiene_option')}>
              <Radio value='independent'>Independent</Radio>
              <Radio value='partial'>Partial Assist</Radio>
              <Radio value='full'>Full Assist</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Grooming' hasFeedback>
          {getFieldDecorator('care_grooming', {
            initialValue: carePlan.care_grooming || '',
            rules: [
              { required: true, message: 'Please enter Grooming' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_grooming')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Grooming Option'>
          {getFieldDecorator('care_grooming_option', {
            initialValue: carePlan.care_grooming_option || '',
            rules: [
              { required: true, message: 'Please select Grooming Option' }
            ]
          })(
            <Radio.Group style={this.shouldHighlight('care_grooming_option')}>
              <Radio value='independent'>Independent</Radio>
              <Radio value='partial'>Partial Assist</Radio>
              <Radio value='full'>Full Assist</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Dressing & Grooming' hasFeedback>
          {getFieldDecorator('care_dressing', {
            initialValue: carePlan.care_dressing || '',
            rules: [
              { required: true, message: 'Please enter Dressing & Grooming' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_dressing')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Dressing & Grooming Option'>
          {getFieldDecorator('care_dressing_option', {
            initialValue: carePlan.care_dressing_option || '',
            rules: [
              { required: true, message: 'Please select Dressing & Grooming Option' }
            ]
          })(
            <Radio.Group style={this.shouldHighlight('care_dressing_option')}>
              <Radio value='independent'>Independent</Radio>
              <Radio value='partial'>Partial Assist</Radio>
              <Radio value='full'>Full Assist</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Toileting' hasFeedback>
          {getFieldDecorator('care_toileting', {
            initialValue: carePlan.care_toileting || '',
            rules: [
              { required: true, message: 'Please enter Toileting' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_toileting')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Toileting Option'>
          {getFieldDecorator('care_toileting_option', {
            initialValue: carePlan.care_toileting_option || '',
            rules: [
              { required: true, message: 'Please select Toileting Option' }
            ]
          })(
            <Radio.Group style={this.shouldHighlight('care_toileting_option')}>
              <Radio value='independent'>Independent</Radio>
              <Radio value='partial'>Partial Assist</Radio>
              <Radio value='full'>Full Assist</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Cathether Care' hasFeedback>
          {getFieldDecorator('care_cathether', {
            initialValue: carePlan.care_cathether || '',
            rules: [
              { required: true, message: 'Please enter Cathether Care' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_cathether')} />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Aids & Equipment' hasFeedback>
          {getFieldDecorator('care_equipment', {
            initialValue: carePlan.care_equipment,
            rules: [
              { required: true, message: 'Please enter Aids & Equipment' }
            ]
          })(
            <TextArea rows={3} style={this.shouldHighlight('care_equipment')} />
          )}
        </FormItem>

        <SectionTitle title='Meals' />

        <Row>

          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Food Preparation & Meal Assistance' hasFeedback>
              {getFieldDecorator('meals_food_prep', {
                initialValue: carePlan.meals_food_prep || '',
                rules: [
                  { required: true, message: 'Please enter Food Preparation & Meal Assistance' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('meals_food_prep')} />
              )}
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Dietary Needs' hasFeedback>
              {getFieldDecorator('meals_dietary_needs', {
                initialValue: carePlan.meals_dietary_needs || '',
                rules: [
                  { required: true, message: 'Please enter Dietary Needs' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('meals_dietary_needs')} />
              )}
            </FormItem>
          </Col>

        </Row>

        <SectionTitle title='Social' />
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Social Interest' hasFeedback>
              {getFieldDecorator('social_interest', {
                initialValue: carePlan.social_interest || '',
                rules: [
                  { required: true, message: 'Please enter Social Interest' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('social_interest')} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Travel/Transportation' hasFeedback>
              {getFieldDecorator('social_travel', {
                initialValue: carePlan.social_travel || '',
                rules: [
                  { required: true, message: 'Please enter Travel/Transportation' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('social_travel')} />
              )}
            </FormItem>
          </Col>
        </Row>

        <SectionTitle title='House Situation' />
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Home Entry' hasFeedback>
              {getFieldDecorator('house_home_entry', {
                initialValue: carePlan.house_home_entry || '',
                rules: [
                  { required: true, message: 'Please enter Home Entry' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('house_home_entry')} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Household Tasks' hasFeedback>
              {getFieldDecorator('house_household_tasks', {
                initialValue: carePlan.house_household_tasks || '',
                rules: [
                  { required: true, message: 'Please enter Household Tasks' }
                ]
              })(
                <TextArea rows={3} style={this.shouldHighlight('house_household_tasks')} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          {/* <Col className='button-row'>
            <div className='btn' onClick={this.handleSave}>
              Save Care Plan
            </div>
          </Col> */}
        </Row>
      </Col>
      <Col lg={4}>
        { carePlanVersions.list.length > 0
          ? <div className='version-container'>

            <div className='version-title'>Previous Versions</div>
            {
              carePlanVersions.list.map((careplan, index) => {
                return <div key={index} className={`version ${version === careplan.version ? 'version-selected' : ''}`} onClick={() => { this.viewVersion(careplan.version) }}>
                  Version { careplan.version }
                  <div className='date'>{ formatter.toStandardDate(careplan.created_at) }</div>
                </div>
              })
            }
          </div> : null
        }
      </Col>
    </Row>
  }

  fetchClient = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await clientService.get(id)
      this.setState({ item, loading: false, clientId: id })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchCarePlan = async () => {
    const { clientId } = this.props
    const carePlan = await carePlanService.get(clientId)

    this.setState({ carePlan: carePlan.item, version: carePlan.item ? carePlan.item.version : 0 })
  }

  fetchCarePlanVersion = async () => {
    const { clientId } = this.props

    const filter = {}
    filter.client_id = { condition: '=', value: clientId }

    const carePlan = await carePlanService.listByPage(1, 0, filter)
    this.setState({ carePlanVersions: carePlan })

    // console.log('carePlanVersions', carePlan)

    setTimeout(() => {
      if (carePlan.list.length > 0) {
        this.viewVersion(carePlan.list[0].version)
      }
    }, 800)
  }

  handleDownload = async () => {
    const { clientId } = this.props

    const carePlan = await carePlanService.download(clientId)
    // window.location.href = `${apiHostname}/api/export/careplan/${clientId}`

    if (carePlan.url) {
      window.open(
        apiHostname + carePlan.url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  handleSave = () => {
    const { clientId, form } = this.props
    const { validateFieldsAndScroll } = form
    const { carePlan } = this.state

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        if (!carePlan) {
          values.version = 1
        } else {
          const { carePlan } = this.state

          const newVersion = carePlan.version + 1
          values.version = newVersion
        }

        values.client_id = clientId

        const response = await carePlanService.add(values)
        this.setState({ loading: false })

        if (response.id) {
          notify.success('Saved successfully', 'Care Plan saved successfully')
          this.fetchCarePlanVersion()
        } else {
          notify.error('Unable to save successfully', 'Unable to save Care Plan successfully. Please try again later.')
        }
      } else {
        Notification['error']({
          message: 'Incomplete Care Plan Information',
          description: 'Please fill up all the required fields to proceed.',
          top: 130
        })
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientCarePlan))

import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const urlFundManager = '/private/api/fvp/funders/fund-managers'

export default {
  addFunderManager (values) {
    return request(urlFundManager, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getFunderManager (funderId) {
    return request(`${urlFundManager}/${funderId}`)
  },
  removeFunderManager (funderId) {
    return request(`${urlFundManager}/${funderId}`, {
      method: 'DELETE',
      headers
    })
  },
  saveFunderManager (funderId, values) {
    return request(`${urlFundManager}/${funderId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listFunderManager (page, size, filter, sorting, text) {
    return request(`${urlFundManager}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

}

/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { isEqual } from 'lodash'
import { authService, clientFileService, clientService, funderService, logService, settingGeneralService, settingReasonService, taskService,
  settingCustomIdentifier, jobActionLogService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates, CountryList, ClientFileTypes, SkillNames } from '../../../constants'
import Moment from 'moment-timezone'
import { formatter, log, trigger, validator, extraValue } from '../../../util'

// UI
import { Loading, Page, Panel, SideModal, MandatoryList, CustomIdentifierList } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

import ActivityLog from '../ActivityLog'

import CarePlan from '../CarePlan'
import CarePlanV2 from '../CarePlanV2'
import Feedback from '../Feedback'
import File from '../File'
import JobsFvp from '../JobFvp'
import JobsJvp from '../JobJvp'
import Tasks from '../Task'
import Leave from '../Leave'
import LeaveJvp from '../LeaveJvp'
import ClientFunder from '../Funder'
import ClientFunding from '../Funding'
import ClientBudget from '../Budget'
import Blacklist from '../Blacklist'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const clientModule = 'client'
const ChildAgeLimit = 18

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/custom-identifier' },
  { tabId: 3, path: '/detailed-info' },
  { tabId: 4, path: '/jobs' },
  { tabId: 18, path: '/feedbacks' },
  { tabId: 6, path: '/careplan' },
  { tabId: 7, path: '/legacy-funding' },
  { tabId: 17, path: '/funding' },
  { tabId: 12, path: '/budget' },
  { tabId: 8, path: '/leaves' },
  { tabId: 9, path: '/carers' },
  { tabId: 10, path: '/files' },
  { tabId: 11, path: '/logs' }
]

export class Client extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      funders: [],
      tasks: [],
      genders: [],
      funding: [],
      item: {},
      loading: false,
      clientId: '',
      currentAge: 0,
      isChild: null,
      payrolls: [],
      languages: [],
      skills: [],
      logList: [],
      heights: [],
      relationships: [],
      reasons: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      showAuthorizeRep: false,
      showEdit: true,
      showSave: false,
      showReminderDate: false,
      shouldRefreshFiles: false,
      isDuplicateClient: false,
      duplicateClientInfo: {},
      isExitedToggleChange: false,
      settingsMandatory: [],
      uploadedMandatory: [],
      hasMissingMandatoryFiles: false,
      identifierSetting: [],
      clashClient: '',
      clashEmp: '',
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.handleDuplicateCheck = debounce(this.handleDuplicateCheck, 500)

    this.handleActiveChange = this.handleActiveChange.bind(this)
    this.handleExitedReason = this.handleExitedReason.bind(this)
  }

  componentDidMount () {
    this.checkUrl()
    if (this.isEdit()) {
      this.fetchClient()
      this.fetchLogs()
      this.fetchFiles()
    }

    this.fetchFunders()
    this.fetchReasons()
    this.fetchSettings()
    this.fetchTasks()
    this.fetchSettingFilesMandatory()
    this.fetchCustomIdentifierSetting()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  checkUrl = () => {
    // if the url is /:id only (but not /add), redirect to /:id/:type
    const { history, match, location } = this.props
    const { currentTab } = this.state
    const { params = {} } = match
    const { id, type = '' } = params

    if (!(id === 'add' || id === 'new')) {
      const tab = TabList.find(e => `${e.tabId}` === currentTab)

      if (!type) {
        if (location && location.pathname) {
          history.replace(`${location.pathname}${tab && tab.tabId ? `${tab.path}` : ''}`)
        }
      }
    }
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i].long_name
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i].long_name
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i].long_name
      }
    }

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { history, match } = this.props
    const { currentAge, currentTab, item, loading, showEdit, showSave, shouldRefreshFiles, clashClient, clashEmp } = this.state

    const isInfoTab = currentTab === '1' || currentTab === '3'

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Client (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.first_name} ${item.last_name} (View Only)` : showSave ? `${item.first_name} ${item.last_name} (Edit Mode)` : 'Client'}>

            { this.isEdit() && this.hasAccess('deleteClient') && isInfoTab
              ? showSave
                ? (<div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                    Delete
                  </div>)
                : null
              : null }

            { showEdit && this.isEdit() && this.hasAccess('updateClient')  && isInfoTab
              ? (<div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>)
              : null }

            { (showSave || !this.isEdit()) && isInfoTab
              ? (<div className='btn' onClick={this.checkBeforeSave}>
                Save
                </div>)
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='client-panel'>
            {this.isEdit()
              ? <div className='client-panel-header' style={(item && !item.active) ? { backgroundImage: 'linear-gradient(180deg,#00000040 -20%, #ffffff55 100%, white 140%)' } : {}}>
                {loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='client-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row className={`${(item && !item.active) ? 'list-item-dim' : ''}`}>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      {item.gender ? <img alt='' src={process.env.PUBLIC_URL + '/img/' + (item.gender && item.gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /> : null}
                    </Col>
                    <Col lg={5}>
                      <div className='client-panel-header-label'>Name</div>
                      <div className='client-panel-header-value'>{item.first_name} {item.last_name}</div>
                      <div className='client-panel-header-subvalue'>{item.acc_ref}</div>
                      { item.client_leave_id
                        ? <div style={{ color: '#ff0000' }}>
                          <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                          &nbsp; {`Leave ${formatter.toShortDate(item.client_leave_start_date)} - ${item.client_leave_is_ufn ? 'UFN' : formatter.toShortDate(item.client_leave_end_date)}`}
                        </div>
                        : null }
                    </Col>
                    <Col lg={4}>
                      <div className='client-panel-header-label'>Carer Skill Level Required</div>
                      <div className='client-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.payroll_category || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='client-panel-header-label'>Suburb</div>
                      <div className='client-panel-header-value'>{item.suburb}
                      {clashClient.length > 1 || clashEmp.length > 1
                        ? <span>
                            &nbsp;
                            <Tooltip
                              title={(
                                <div>
                                  {clashClient.length > 1 ? <div>Same address with client: {clashClient}</div> : ''}
                                  {clashEmp.length > 1 ? <div>Same address with employee: {clashEmp}</div> : ''}
                                </div>
                              )}
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0}
                            >
                              <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                            </Tooltip>
                          </span>
                        : null}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className='client-panel-header-label'>Age</div>
                      <div className='client-panel-header-value'>{currentAge} years old</div>
                    </Col>
                    </Row>}
                </div>
              : null}
            <div className='client-panel-body'>
              <Tabs
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={this.handleTabChange}
              >
                <TabPane tab='Client Info' key='1'>
                  {this.infoTab()}
                </TabPane>
                {this.isEdit()
                  ? <TabPane tab='Custom Identifier' key='2'>
                    {this.customIdenfierTab()}
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Detailed Info' key='3'>
                    {this.detailedInfoTab()}
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Jobs' key='4'>
                    <Jobs key={`jbrcc${currentTab}`} clientId={match.params.id} clientName={item.first_name} history={this.props.history} isClientActive={item.active} isClientExited={item.is_exited} isClientHasJobReminder={item.job_reminder} clientEmail={item.email} />
                  </TabPane>
                  : null} */}
                {/* {this.isEdit()
                  ? <TabPane tab='Jobs' key='4'>
                    <JobsFvp key={`jbscc${currentTab}`} clientId={match.params.id} clientName={item.first_name} history={this.props.history} isClientActive={item.active} isClientHasJobReminder={item.job_reminder} clientEmail={item.email} />
                  </TabPane>
                  : null} */}
                {this.isEdit()
                  ? <TabPane tab='Jobs' key='4'>
                    <JobsJvp key={`jbscc${currentTab}`} clientId={match.params.id} data={item} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Feedbacks' key='18'>
                    <Feedback key={`fcc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Tasks' key='5'>
                    <Tasks key={`tskcc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit()
                  ? <TabPane tab='Care Plan' key='6'>
                    <CarePlanV2 key={`crcpc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {/* {this.isEdit() && this.hasAccess('listLegacyClientFundingPeriod')
                  ? <TabPane tab='Funders' key='7' forceRender>
                    <ClientFunder key={`cfacc${currentTab}`} clientId={match.params.id} clientName={`${item.first_name} ${item.last_name}`} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit() && this.hasAccess('listFvpClientFundingPeriod')
                  ? <TabPane tab='Funding' key='17' forceRender>
                    <ClientFunding key={`cfvcc${currentTab}`} clientId={match.params.id} clientName={`${item.first_name} ${item.last_name}`} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit() && this.hasAccess('listClientBudgets')
                  ? <TabPane tab='Budget' key='12' forceRender>
                    <ClientBudget key={`cbvcc${currentTab}`} clientId={match.params.id} clientName={`${item.first_name} ${item.last_name}`} history={this.props.history} />
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Leave' key='8'>
                    <Leave key={`lfacc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit() && this.hasAccess('listClientLeaves')
                  ? <TabPane tab='Leave' key='8'>
                    <LeaveJvp key={`lfpcc${currentTab}`} clientId={match.params.id} clientInfo={item} history={this.props.history} infoUpdate={() => this.fetchClient(true)} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Previous Carers' key='9'>
                    <Blacklist key={`bcrcc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit() && this.hasAccess('listClientFile')
                  ? <TabPane tab='Files' key='10'>
                    <File key={`frscc${currentTab}`} clientId={match.params.id} history={this.props.history} onUpdateInfo={() => this.handleFilesUpdateAction()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Activity Log' key='11'>
                    <ActivityLog key={`actcc${currentTab}`} clientId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  handleTabChange = (index) => {
    const id = this.getId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/clients/${id}${tab.path}`)
    }
  }

  infoTab = () => {
    const { form } = this.props
    const { currentAge, isChild, isExitedToggleChange, showExitedToggle, showExitedReason, genders, item, languages, loading, payrolls, skills,
      showAuthorizeRep, heights, relationships, reasons, settingsMandatory, uploadedMandatory, identifierSetting } = this.state
    const { getFieldDecorator } = form

    return <Form>
      <Loading loading={loading} blur>
        <Panel title='Account'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleActiveChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    // disabled={item.is_exited}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12} />
            <Col lg={12}>
              {showExitedToggle && this.hasAccess('updateClientExited') ? <FormItem {...sideBySideFormItemLayout} label='Exited'>
                {getFieldDecorator('is_exited', {
                  initialValue: item.is_exited || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={() => !item.is_exited ? this.setState({ showExitedReason: true, isExitedToggleChange: true }) : null}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem> : null}
            </Col>
          </Row> */}
          {/* <Row>
            <Col lg={12} />
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Audit Consent Given'>
                {getFieldDecorator('audit_opt_in', {
                  initialValue: item.audit_opt_in,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row> */}
        </Panel>
        <Panel title='Information'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                {getFieldDecorator('first_name', {
                  initialValue: item.first_name,
                  rules: [
                    { min: 2, message: 'First Name must be between 2 and 128 characters' },
                    { max: 128, message: 'First Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                {getFieldDecorator('last_name', {
                  initialValue: item.last_name,
                  rules: [
                    { min: 2, message: 'Last Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Last Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter last name' },
                    { whitespace: true, message: 'Please enter last name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Name' hasFeedback>
                {getFieldDecorator('preferred_name', {
                  initialValue: item.preferred_name,
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                    {getFieldDecorator('dob', this.isEdit() && item.dob ? {
                      initialValue: Moment(item.dob)
                    } : {})(
                      <DatePicker format={dateFormat} onChange={this.handleDOBChange} defaultPickerValue={Moment(new Date())} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={5}>
                  <div className='client_age'>
                    {currentAge} years old
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                {getFieldDecorator('gender', {
                  initialValue: item.gender,
                  rules: [
                    { required: true, message: 'Please select gender.' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      genders.map((gender) => {
                        return <Option key={`sag${gender.value}`} value={`${gender.value}`}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Sexual Identity' hasFeedback>
                {getFieldDecorator('client_sexual', {
                  initialValue: item.client_sexual,
                  // rules: [
                  //   { whitespace: true, message: 'Please enter sexual identity.' },
                  //   { required: true, message: 'Please enter sexual identity.' }
                  // ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>-- Not Selected --</Option>
                    {
                      genders.map((gender) => {
                        return <Option key={`sic${gender.value}`} value={`${gender.value}`}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                {getFieldDecorator('mobile_phone', {
                  initialValue: item.mobile_phone,
                  rules: [
                    { min: 2, message: 'Mobile Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Mobile Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter mobile number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone', {
                  initialValue: item.phone,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Height' hasFeedback>
                {getFieldDecorator('height', {
                  initialValue: item.height,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      heights.map((height, idx) => {
                        return <Option key={`msph-${height.value}`} value={`${height.value}`}>{height.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>

            </Col>

          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Have Authorized Rep. ?'>
                {getFieldDecorator('authorize_rep', {
                  initialValue: item.authorize_rep || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleAuthorizeRepChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>

            </Col>
          </Row>
        </Panel>

        {
          showAuthorizeRep
          ? <div className='show-authorize-field'>
            <Panel title='Authorized Representative'>
              <Row>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Name'>
                    {getFieldDecorator('authorize_rep_name', {
                      initialValue: item.authorize_rep_name || ''
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Contact'>
                    {getFieldDecorator('authorize_rep_phone', {
                      initialValue: item.authorize_rep_phone,
                      rules: [
                        { min: 2, message: 'Contact must be between 2 and 128 characters' },
                        { max: 128, message: 'Contact must be between 2 and 128 characters' },
                        { whitespace: true, message: 'Please enter contact' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                    {getFieldDecorator('authorize_rep_email', {
                      initialValue: item.authorize_rep_email || ''
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                    {getFieldDecorator('authorize_rep_relationship', {
                      initialValue: item.authorize_rep_relationship
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {
                          relationships.map((relation, idx) => {
                            return <Option key={`mspr-${relation.value}`} value={relation.value}>{relation.name}</Option>
                          })
                        }
                      </Select>
                    )}
                  </FormItem>
                </Col>

              </Row>

            </Panel>
          </div>
          : null
        }

        <Panel title='Alert'>
          {/* <Row>
            <Col lg={12}>
              <div style={{ paddingLeft: 16, marginBottom: 25, fontSize: '11pt' }}><strong>Public</strong></div>
            </Col>
            <Col lg={12}>
              <div style={{ paddingLeft: 16, marginBottom: 25, fontSize: '11pt' }}><strong>Private</strong></div>
            </Col>
          </Row> */}
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                {getFieldDecorator('public_alert', {
                  initialValue: item.public_alert || 'SW to wear appropriate masks, gloves and protective eyewear at all times during shift.'
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Private Alert'>
                {getFieldDecorator('private_alert', {
                  initialValue: item.private_alert
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Do not leave the client alone'>
                {getFieldDecorator('not_leave_alone', {
                  initialValue: item.not_leave_alone,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

        </Panel>

        <Panel title='Service Requirements'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Carer Skill Level Required' hasFeedback>
                {getFieldDecorator('payroll_category', {
                  initialValue: item.payroll_category,
                  rules: [
                    { required: true, message: 'Please select Carer Skill Level Required.' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      payrolls.map((payroll) => {
                        return <Option key={`mspc-${payroll.value}`} value={payroll.value}>{payroll.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>

            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Skills Required' hasFeedback>
            {getFieldDecorator('preferred_skills', {
              initialValue: item.preferences && validator.isNotEmptyArray(item.preferences.skills) ? item.preferences.skills : [],
              rules: [
                { required: true, message: 'Please enter skills' }
              ]
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  skills.map((skill) => {
                    return <Option key={`mssk-${skill.setting_id}`} value={skill.setting_id} disabled={isChild === true && skill.reference === 'wwcc'}>{skill.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Preferred Languages' hasFeedback>
            {getFieldDecorator('preferred_languages', {
              // initialValue: Array.isArray(item.languages) && item.languages[0] !== null ? item.languages : []
              initialValue: item.preferences && validator.isNotEmptyArray(item.preferences.languages) ? item.preferences.languages : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  languages.map((language) => {
                    return <Option key={`mslk-${language.setting_id}`} value={language.setting_id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Gender' hasFeedback>
                {getFieldDecorator('preferred_gender', {
                  initialValue: item.preferred_gender
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      genders.map((gender) => {
                        return <Option key={`msfg-${gender.value}`} value={`${gender.value}`}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Private Notes' hasFeedback>
            {getFieldDecorator('private_notes', {
              initialValue: item.private_notes
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Panel>
        <Panel title='Address'>
          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address,
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address then select address on dropdown list' },
                { whitespace: true, message: 'Please enter address then select address on dropdown list' },
                { validator: this.checkAddressInput }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
            {getFieldDecorator('unit_building', {
              initialValue: item.unit_building,
              rules: [
                { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter unit/building info' }
              ]
            })(
              <Input placeholder='Please Enter Unit No/Building Name' />
            )}
          </FormItem>

          <Row style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>

            </Col>
          </Row>

          <Row style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states, idx) => (
                        <Option key={`msst-${idx}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode' hasFeedback>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Second Contact'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name'>
                {getFieldDecorator('second_contact_name_1', {
                  initialValue: item.second_contact_name_1,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('second_contact_phone_1', {
                  initialValue: item.second_contact_phone_1,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

          </Row>
          <Row>
            <Col lg={12}>
              {/* <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_1', {
                  initialValue: item.second_contact_relationship_1
                })(
                  <Input />
                )}
              </FormItem> */}

              <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_1', {
                  initialValue: item.second_contact_relationship_1,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      relationships.map((relation, idx) => {
                        return <Option key={`msrr1-${relation.value}`} value={relation.value}>{relation.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                {getFieldDecorator('second_contact_email_1', {
                  initialValue: item.second_contact_email_1
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <hr className='contact-separator' />
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name'>
                {getFieldDecorator('second_contact_name_2', {
                  initialValue: item.second_contact_name_2,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('second_contact_phone_2', {
                  initialValue: item.second_contact_phone_2,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/* <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_2', {
                  initialValue: item.second_contact_relationship_2
                })(
                  <Input />
                )}
              </FormItem> */}

              <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_2', {
                  initialValue: item.second_contact_relationship_2,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      relationships.map((relation, idx) => {
                        return <Option key={`msrr2-${relation.value}`} value={relation.value}>{relation.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                {getFieldDecorator('second_contact_email_2', {
                  initialValue: item.second_contact_email_2
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Required Files'>
          <div className='mandatory-files-panel'>
            <MandatoryList
              form={form}
              onSkillApply={(e) => this.handleSkillApply(e)}
              moduleType={'client'}
              moduleId={item.id}
              settingsMandatory={settingsMandatory}
              items={uploadedMandatory}
            />
          </div>
        </Panel>

      </Loading>
      <SideModal
        title='Inactive Reason'
        showModal={showExitedReason}
        onClose={() => this.handleCancelInactiveReason()}
        buttons={[
          <Button key='0' type='primary' onClick={() => this.handleExitedReason(false)}>Submit</Button>
        ]}
      >
        <FormItem label='Reason'>
          {getFieldDecorator('exited_reason', {
            rules: [
              isExitedToggleChange ? { required: true, message: 'Please select reason' } : {}
            ]
          })(
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                reasons.map((reason) => {
                  return <Option key={`msrc-${reason.name}`} value={reason.name}>{reason.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        <FormItem label='Note'>
          {getFieldDecorator('exited_reason_note', {
            rules: [
              // isExitedToggleChange ? { required: true, message: 'Please enter note' } : {}
            ]
          })(
            <TextArea rows={4} />
          )}
        </FormItem>
      </SideModal>
    </Form>
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { currentTab, identifierSetting } = this.state

    return <CustomIdentifierList
      key={`csrcc${currentTab}`}
      moduleType={'client'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  detailedInfoTab = () => {
    const { form } = this.props
    const { item, loading, languages } = this.state
    const { getFieldDecorator } = form

    return <Form>
      <Loading loading={loading} blur>
        <Panel title='Religious / Cultural Info'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Interpreter required'>
                {getFieldDecorator('detail_interpreter', {
                  valuePropName: 'checked',
                  initialValue: item.detail_interpreter || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Aboriginal or Torres Strait Islander'>
                {getFieldDecorator('detail_aboriginal', {
                  valuePropName: 'checked',
                  initialValue: item.detail_aboriginal || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Other Languages' hasFeedback>
            {getFieldDecorator('other_languages', {
              initialValue: item.preferences && validator.isNotEmptyArray(item.preferences.other_languages) ? item.preferences.other_languages : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  languages.map((language) => {
                    return <Option key={`cskl-${language.id}`} value={language.setting_id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Religious/Cultural Needs' hasFeedback>
            {getFieldDecorator('detail_cultural', {
              initialValue: item.detail_cultural
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Panel>

        <Panel title='Healthcare Info'>
          <FormItem {...formItemLayout} label='Health care Professionals' hasFeedback>
            {getFieldDecorator('other_healthcare', {
              initialValue: item.other_healthcare
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Medicare'>
                {getFieldDecorator('other_medicare', {
                  initialValue: item.other_medicare,
                  rules: [
                    { min: 2, message: 'Medicare must be between 2 and 128 characters' },
                    { max: 128, message: 'Medicare must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter Medicare' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Private Health Fund'>
                {getFieldDecorator('other_private_fund', {
                  initialValue: item.other_private_fund,
                  rules: [
                    { min: 2, message: 'Private Health Fund must be between 2 and 128 characters' },
                    { max: 128, message: 'Private Health Fund must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter Private Health Fund' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Pension Card'>
                {getFieldDecorator('other_pension_card', {
                  initialValue: item.other_pension_card,
                  rules: [
                    { min: 2, message: 'Pension card must be between 2 and 128 characters' },
                    { max: 128, message: 'Pension card must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter Pension card' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Health Care Card'>
                {getFieldDecorator('other_healthcare_card', {
                  initialValue: item.other_healthcare_card,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Ambulance No.'>
                {getFieldDecorator('other_ambulance', {
                  initialValue: item.other_ambulance,
                  rules: [
                    { min: 2, message: 'Ambulance No. must be between 2 and 128 characters' },
                    { max: 128, message: 'Ambulance No. must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter ambulance no.' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Hospital'>
                {getFieldDecorator('other_preferred_hospital', {
                  initialValue: item.other_preferred_hospital,
                  rules: [
                    { min: 2, message: 'Preferred Hospital must be between 2 and 128 characters' },
                    { max: 128, message: 'Preferred Hospital must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter Preferred Hospital' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Companion Card'>
                {getFieldDecorator('other_companion_card_bool', {
                  valuePropName: 'checked',
                  initialValue: item.other_companion_card_bool || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Disabled Parking'>
                {getFieldDecorator('other_disabled_parking', {
                  initialValue: item.other_disabled_parking
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

        </Panel>
        {/* <Panel title='Detailed Information'>
          <FormItem {...formItemLayout} label='Living arrangement of others' hasFeedback>
            {getFieldDecorator('detail_living_arrangement', {
              initialValue: item.detail_living_arrangement
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country of Birth'>
                {getFieldDecorator('detail_cob', {
                  initialValue: item.detail_cob,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Select
                    showSearch
                    placeholder='Please select a country'
                  >
                    {
                      CountryList.map((states, idx) => (
                        <Option key={idx} value={states.name}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Religion'>
                {getFieldDecorator('detail_religion', {
                  initialValue: item.detail_religion,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem {...formItemLayout} label='Cultural Needs' hasFeedback>
            {getFieldDecorator('detail_cultural', {
              initialValue: item.detail_cultural
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Interpreter required'>
                {getFieldDecorator('detail_interpreter', {
                  valuePropName: 'checked',
                  initialValue: item.detail_interpreter || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Aboriginal or Torres Strait Islander'>
                {getFieldDecorator('detail_aboriginal', {
                  valuePropName: 'checked',
                  initialValue: item.detail_aboriginal || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel> */}
        {/* <Panel title='Other Info'>
          <FormItem {...formItemLayout} label='Health care professionals' hasFeedback>
            {getFieldDecorator('other_healthcare', {
              initialValue: item.other_healthcare
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Medicare'>
                {getFieldDecorator('other_medicare', {
                  initialValue: item.other_medicare,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Private Health Fund'>
                {getFieldDecorator('other_private_fund', {
                  initialValue: item.other_private_fund,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Pension Card'>
                {getFieldDecorator('other_pension_card', {
                  initialValue: item.other_pension_card,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Health Care Card'>
                {getFieldDecorator('other_healthcare_card', {
                  initialValue: item.other_healthcare_card,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Companion Card'>
                {getFieldDecorator('other_companion_card', {
                  initialValue: item.other_companion_card,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Hospital'>
                {getFieldDecorator('other_preferred_hospital', {
                  initialValue: item.other_preferred_hospital,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Ambulance'>
                {getFieldDecorator('other_ambulance', {
                  initialValue: item.other_ambulance,
                  rules: [
                    { min: 2, message: 'Ambulance must be between 2 and 128 characters' },
                    { max: 128, message: 'Ambulance must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Disabled Parking'>
                {getFieldDecorator('other_disabled_parking', {
                  initialValue: item.other_disabled_parking
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel> */}
      </Loading>

    </Form>
  }

  checkAddressInput = (rule, value, callback) => {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    if (value && value.length > 0) {
      if (!getFieldValue('postcode') && !getFieldValue('state') && !getFieldValue('suburb')) {
        callback(new Error('Please enter address then select address on dropdown list'))
      }
    } else if (validator.isEmptyString(value)) {
      setFieldsValue({ postcode: null, state: '', suburb: null })
    }

    callback()
  }

  calculateAgeNum = (dob = null) => {
    const currYear = Moment()
    const diffYear = dob === null ? null : Moment.isMoment(dob) ? dob : Moment(dob)
    const ageNum = dob ? currYear.diff(diffYear, 'years') : null

    return { ageNum, isChild: ageNum === null ? null : ageNum < ChildAgeLimit}
  }

  handleAuthorizeRepChange = (e) => {
    if (e === false) {
      this.setState({ showAuthorizeRep: false })
    } else {
      this.setState({ showAuthorizeRep: true })
    }
  }

  handleDOBChange = (e) => {
    const { form } = this.props
    const { item, skills } = this.state
    const { ageNum, isChild } = this.calculateAgeNum(e)

    // MS0143 - revamp on dob detection method to fulfil WWCC new requirements
    // Get skill wwcc & preferred skills
    const wwccSkill = skills.find(e => e.reference === 'wwcc')
    const skillEntry = form.getFieldValue('preferred_skills')

    // Age 18 or above remove skill wwcc if added
    // Age under 18 add skill wwcc if have not added yet
    if (wwccSkill && wwccSkill.setting_id) {
      if (isChild) {
        // compulsory assign wwccSkill if it is underage
        if (skillEntry.indexOf(wwccSkill.setting_id) < 0) {
          skillEntry.push(wwccSkill.setting_id)
        }
      } else {
        const itemSkillPref = item && item.preferences && item.preferences.skills_list ? item.preferences.skills_list : []
        const itemSkillEntry = itemSkillPref.find(e => e.setting_id === wwccSkill.setting_id)
        const currentSkillEntry = skillEntry.find(e => e === wwccSkill.setting_id)

        let isShowPopup = false
        // check on current skill entry (entry from input field) first, if current skill entry contains of wwcc, procceed to next checking
        // review on item record skill entry, if there is wwcc, check its saved manual override status.
        // if it is a manual override, do not show any popup. if it is not manual override (assign by system and UI automatically), show the popup immediately and update the skill input fields accordingly.
        if (currentSkillEntry) {
          if (itemSkillEntry && itemSkillEntry.id) {
            if (itemSkillEntry.is_manual_override === true) {

            } else {
              isShowPopup = true
            }
          } else {
            isShowPopup = true
          }

          if (isShowPopup) {
            confirm({
              title: `REMOVE "Working with Children"`,
              content: 'Updated age is above 18. Do you want to REMOVE "Working with Children" skill?',
              okText: 'REMOVE',
              cancelText: 'Cancel',
              async onOk () {
                const idx = skillEntry.indexOf(wwccSkill.setting_id)

                if (idx > -1) {
                  skillEntry.splice(idx, 1)
                }
                form.setFieldsValue({ preferred_skills: skillEntry })
              }
            })
          }
        }
      }
    }

    form.setFieldsValue({ preferred_skills: skillEntry })
    this.setState({ currentAge: ageNum, isChild })
  }

  handleDuplicateCheck = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['first_name', 'last_name'],
      async (errors, values) => {
        if (!errors) {
          const res = await clientService.checkDuplicateClient(values)

          if (res.total > 0) {
            warning({
              title: 'Client already exists',
              content: (
                <div className='duplicate-warnings'>
                  {res.list.map((item, index) => {
                    const url = `/clients/${item.id}`
                    return (
                      <div key={index}><a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                                       </a>
                      </div>
                    )
                  })}
                </div>
              ),
              okText: 'OK',
              onOk () { }
            })

            this.setState({ isDuplicateClient: true, duplicateClientInfo: res })
          } else {
            this.setState({ isDuplicateClient: false, duplicateClientInfo: {} })
          }
        }
      })
  }

  fetchClient = async (isUpdate = false) => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params

      if (isUpdate) {
        this.setState({ loading: true })
      }

      const { item, clashClient, clashEmp } = await clientService.get(id)
      // get current age
      const { ageNum, isChild } = this.calculateAgeNum(item.dob)
      const defaultAuthorize = item.authorize_rep

      this.setState({
        item,
        loading: false,
        clientId: id,
        currentAge: ageNum,
        isChild,
        showAuthorizeRep: defaultAuthorize,
        showExitedToggle: !item.active,
        oldActiveStatus: item.active,
        clashClient,
        clashEmp
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchFunders = async () => {
    const funders = await funderService.listByPage(1, 0)
    this.setState({ funders: funders.list })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const clientId = id

    const filter = {}
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: clientModule }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'client' }

    const fileTypes = await clientFileService.listByPage(1, 0, filter)
    this.setState({ uploadedMandatory: fileTypes.list })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'client' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchTasks = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const taskRes = await taskService.get(id)
    this.setState({ tasks: taskRes.item })
  }

  fetchReasons = async () => {
    const filter = {}
    filter.code = { condition: '=', value: 'inactive-client' }

    const reasons = await settingReasonService.listItemsByPage(1, 0, filter)
    this.setState({ reasons: reasons.list })
  }

  fetchSettingFilesMandatory = async (currentPage = 1) => {
    const filter = {}
    this.setState({ loading: true })

    filter.sub_cat_active = { condition: '=', value: true }
    filter.sub_cat_has_mandatory = { condition: '=', value: true }
    filter.sub_cat_classification = { condition: '=', value: 'client' }

    const { list: documents } = await clientFileService.listDocumentByPage(currentPage, 0, filter)

    this.setState({ loading: false, settingsMandatory: documents })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'relationship' }
      ]
    }
    filter.active = { condition: '=', value: true }
    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const hg = this.generateListNullOption('height', settings)
    const rs = this.generateListNullOption('relationship', settings)
    // MS0143 - restructure skills and languages list to fulfil WWCC special requirements
    const lgs = this.generatePreferenceOption(settings.list.filter(item => item.type === 'language'), 'language')
    const sks = this.generatePreferenceOption(settings.list.filter(item => item.type === 'skill'), 'skill')

    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: lgs,
      skills: sks,
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding'),
      heights: hg,
      relationships: rs
    })
  }

  generateListNullOption (type, settings) {
    const vList = settings.list.filter(item => item.type === type)
    vList.unshift({ name: '-- Not Selected --', type: type, value: null })

    return vList
  }

  generatePreferenceOption (settings = [], type = '') {
    let list = []

    for (let i = 0; i < settings.length; i++) {
      const s = settings[i]
      list.push({
        setting_id: s.id,
        setting_type: type,
        name: s.name,
        reference: s.reference
      })
    }

    return list
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'client' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)
    this.setState({ identifierSetting: customFields.list })
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this client?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await clientService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Client deleted successfully.')
            history.replace('/clients')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete client successfully. Please try again later.')
        }
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleActiveChange (value) {
    this.setState({ newActiveStatus: value })

    if (!value) {
      this.setState({ showExitedReason: true })
    } else {
      this.setState({ showExitedReason: false })
    }

    // this.setState({ showExitedToggle: !value })

    // if (!value) {
    //   const { form } = this.props
    //   const { setFieldsValue } = form

    //   setTimeout(() => { setFieldsValue({ is_exited: false }) }, 200)
    // }
  }

  handleCancelInactiveReason () {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    setFieldsValue({ active: !getFieldValue('active') })

    this.setState({ showExitedReason: false, isExitedToggleChange: false })

  }

  handleExitedReason (value) {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['exited_reason'], async (errors, values) => {
      if (!errors) {
        this.setState({ showExitedReason: false })
      }
    })
  }

  handleSkillApply = (value) => {
    const { form } = this.props
    const skills = form.getFieldValue('preferred_skills')

    if (skills.includes(value[0]) === false) {
      skills.push(value[0])

      form.setFieldsValue({ preferred_skills: skills })
    }
  }

  handleFilesUpdateAction = () => {
    this.fetchClient()
    this.fetchFiles()
  }

  checkBeforeSave = (e) => {
    const { form } = this.props
    const { getFieldValue } = form
    const { isDuplicateClient, duplicateClientInfo } = this.state
    const { handleSave } = this
    let hasFileList = []

    const mandatory = getFieldValue('mandatory')
    this.setState({ hasMissingMandatoryFiles: false })

    // Check whether all mandatory categories have file uploaded; missing file uploaded disactive employee profile but still allow to save
    if (validator.isArray(mandatory)) {
      hasFileList = mandatory.filter(item => item.file_name && item.file_url)
    }
    const isShowClientDuplicatedAlert = isDuplicateClient && duplicateClientInfo.total > 0
    const isShowMandatoryAlert = validator.isArray(mandatory) && mandatory.length !== hasFileList.length

    if (isShowClientDuplicatedAlert || isShowMandatoryAlert) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The client will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            { isShowClientDuplicatedAlert
              ? <div className='duplicate-warnings'>
                <div style={{ fontSize: '14px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong> Client already exists</strong>
                </div>
                {duplicateClientInfo.list.map((item, index) => {
                  const url = `/clients/${item.id}`
                  return (
                    <div key={index}>
                      <a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                      </a>
                    </div>
                  )
                })}
                <br />
              </div>
              : null }

            { isShowMandatoryAlert
              ? <div className='duplicate-warnings'>
                {this.setState({ hasMissingMandatoryFiles: true })}
                <div style={{ fontSize: '14px', margin: '0px 0px 5px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong> Missing files in "Required Files". Client profile will be deactivate when proceed to save</strong>
                </div>
                <br />
              </div>
              : null }

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave(e) },
        onCancel () { }
      })
    } else {
      handleSave(e)
    }

  }

  handleSave = (e) => {
    e.preventDefault()
    const { loading, currentTab } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = []
    const tab = TabList.find(e => String(e.tabId) === String(currentTab))

    if (loading) {
      return
    }

    this.setState({ loading: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          const { fetchingClients } = this.props
          const { item, longitude, latitude, hasMissingMandatoryFiles, uploadedMandatory, oldActiveStatus, newActiveStatus, reasons } = this.state

          if (longitude && latitude) {
            values.longitude = longitude
            values.latitude = latitude
          }

          if (hasMissingMandatoryFiles) {
            values.active = false
          }

          // console.log('handle Save', values)

          if (values.exited_reason && values.exited_reason !== '') {
            extraLog.push(`Inactive Reason "${values.exited_reason}" ${values.exited_reason_note ? `, Inactive Note "${values.exited_reason_note}"` : ''}`)
          }

          if (values.height === 'null') {
            values.height = ''
          }

          if (values.second_contact_relationship_1 === 'null') {
            values.second_contact_relationship_1 = ''
            // extraLog.push(`Second Contact Relationship 1 from "${item.second_contact_relationship_1}" to ""`)
          }

          if (values.second_contact_relationship_2 === 'null') {
            values.second_contact_relationship_2 = ''
            // extraLog.push(`Second Contact Relationship 2 from "${item.second_contact_relationship_2}" to ""`)
          }

          delete values.exited_reason
          delete values.exited_reason_note

          try {
            if (this.isEdit()) {
              const response = await clientService.save(item.id, values)

              const newItem = Object.assign({}, item, values)

              const fileList = values.mandatory || []
              delete values.mandatory

              if (response.id) {
                // const logItemBefore = extraValue.getBeforeExtraValueLog(item)
                // const logItemAfter = extraValue.getAfterExtraValueLog(values)
                // delete item.extra_fields
                // delete values.extra_fields

                // Generate & save log_multi_action
                if (typeof oldActiveStatus === 'boolean' && typeof newActiveStatus === 'boolean' && (oldActiveStatus !== newActiveStatus)) {
                  const exReason = form.getFieldValue('exited_reason')
                  const exReasonNote = form.getFieldValue('exited_reason_note')
                  const reasonId = newActiveStatus === false ? reasons.find(e => e.name === exReason) : null
                  const actions = {}
                  actions.module = 'client'
                  actions.module_id = item.id
                  actions.action = 'client-status-change'
                  actions.action_detail = `client status change from "${oldActiveStatus}" to ${newActiveStatus}`
                  actions.previous_boolean = oldActiveStatus
                  actions.current_boolean = newActiveStatus
                  actions.action_lvl1_id = newActiveStatus === false ? reasonId.id : null
                  actions.action_lvl1_name = newActiveStatus === false ? exReason : null
                  actions.action_lvl1_text = newActiveStatus === false ? exReasonNote : null
                  const actionLog = await jobActionLogService.add(actions)
                }

                const settingLog = this.getSettingChanges(values, false)

                if (settingLog) {
                  extraLog.push(settingLog)
                }

                log.updateClient(response.id, item, values, [],
                  [
                    // { key: 'is_exited', label: 'Exited' },
                    { key: 'dob', label: 'D.O.B.' },
                    { key: 'payroll_category', label: 'Carer Skill Level Required' },
                    { key: 'not_leave_alone', label: 'Do Not Leave The Client Alone' },
                    { key: 'detail_cob', label: 'Detail C.O.B.' },
                    { key: 'client_sexual', label: 'Sexual Identity' },
                    { key: 'detail_cultural', label: 'Religious/Cultural Needs' },
                    { key: 'other_companion_card_bool', label: 'Other Companion Card' }
                  ],
                  extraLog.join(', ')
                )

                trigger.updateClient(
                  response.id,
                  `${values.first_name} ${values.last_name}`,
                  item,
                  values,
                  [
                    { key: 'active' },
                    { key: 'first_name' },
                    { key: 'last_name' },
                    { key: 'unit_building' },
                    { key: 'address' },
                    { key: 'mobile_phone', label: 'Mobile Number' },
                    { key: 'email' },
                    { key: 'gender' },
                    { key: 'dob', label: 'Date of Birth' },
                    { key: 'phone_number' },
                    { key: 'payroll_category', label: 'Carer Skill Level Required' }
                  ]
                )

                // prepare and log each mandatory files uploaded
                for (let i = 0; i < fileList.length; i++) {
                  const file = fileList[i]

                  if (file.file_name && file.file_url) {
                    let extraFileLog = []
                    let logItemBefore = {}

                    // get previously uploaded file, if have value return prepare log item
                    const item = uploadedMandatory.find(e => e.id === file.id)
                    if (item) {
                      logItemBefore.label = item.label || ''
                      logItemBefore.file_name = formatter.toStandardFileName(item.file_name) || ''
                      logItemBefore.active = item.active
                      logItemBefore.issuance_date = item.issuance_date
                      logItemBefore.expiry_date = item.expiry_date
                    }

                    let logItemAfter = {
                      label: file.label || '',
                      file_name: formatter.toStandardFileName(file.file_name) || '',
                      active: true,
                      issuance_date: file.issuance_date
                    }
                    if (file.expiry_date) {
                      logItemAfter.expiry_date = file.expiry_date
                    }
                    extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                    if (item) {
                      log.updateClientFile(response.id, logItemBefore, logItemAfter, [], extraFileLog.join())
                    } else {
                      log.addClientFile(response.id, logItemAfter, [], extraFileLog.join())
                    }
                  }
                }

                this.setState({ item: newItem })
                notify.success('Saved successfully', 'Client saved successfully.')
                fetchingClients(true)
                window.location.replace(`/clients/${item.id}${tab && tab.tabId ? `${tab.path}` : ''}`)
              }
            } else {
              const response = await clientService.add(values)

              const fileList = values.mandatory || []
              delete values.mandatory

              if (response.id && !response.errors) {
                const { id, acc_ref } = response
                this.setState({ item: { ...item, ...values, id, acc_ref } })

                log.addClient(id, `New client ${values.first_name} ${values.last_name}`)

                trigger.addClient(
                  id,
                  `${values.first_name} ${values.last_name}`,
                  values,
                  [
                    { key: 'active' },
                    { key: 'first_name' },
                    { key: 'last_name' },
                    { key: 'gender' },
                    { key: 'dob', label: 'Date of Birth' },
                    { key: 'mobile_phone', label: 'Mobile Number' },
                    { key: 'phone', label: 'Phone Number' },
                    { key: 'email' },
                    { key: 'payroll_category', label: 'Carer Skill Level Required' },
                    { key: 'height' },
                    { key: 'authorize_rep', label: 'Have Authorized Representative' },
                    { key: 'address' },
                    { key: 'unit_building', label: 'Unit/Building' }
                  ]
                )

                // prepare and log each mandatory files uploaded
                for (let i = 0; i < fileList.length; i++) {
                  const file = fileList[i]

                  if (file.file_name && file.file_url) {
                    let extraFileLog = []
                    let logItemAfter = {
                      label: file.label || '',
                      file_name: formatter.toStandardFileName(file.file_name) || '',
                      active: true,
                      issuance_date: file.issuance_date
                    }
                    if (file.expiry_date) {
                      logItemAfter.expiry_date = file.expiry_date
                    }
                    extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                    log.addClientFile(id, logItemAfter, [], extraFileLog.join())
                  }
                }

                // this.setState({ newAccRef: acc_ref })
                notify.success('Saved successfully', 'Client saved successfully.')
                // history.replace(`/clients/${id}`)
                window.location.replace(`/clients/${id}/info`)
                fetchingClients(true)
              } else {
                notify.error(response.errors[0].message)
              }
            }

            const { ageNum, isChild } = this.calculateAgeNum(values.dob)
            this.setState({ currentAge: ageNum, isChild })

            this.props.setRefreshActivityLog(true)
          } catch (e) {
            const { response } = e

            if (response) {
              const { valid, errors } = response
              if (!valid && Array.isArray(errors)) {
                notify.error('Error', errors[0].message)
              }
            } else {
              notify.error('Unable to save successfully', 'Unable to save client successfully. Please try again later.')
            }

          }
          this.setState({ loading: false })
          this.fetchLogs()
        }
      })
    })
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  replacer = (value) => {
    let str = value

    if (str) {
       // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')
    }

    return str
  }

  getSettingChanges (values, isNew = false) {
    let extra = ''

    extra += `${extra ? ', ' :''}${this.updateSettingChanges({values, key: 'language', keyName: 'languages', vKey: 'preferred_languages', type: 'language', name: 'Preferred Languages', isNew})}`
    extra += `${extra ? ', ' :''}${this.updateSettingChanges({values, key: 'skill', keyName: 'skills', vKey: 'preferred_skills', type: 'skill', name: 'Skills Required', isNew})}`
    extra += `${extra ? ', ' :''}${this.updateSettingChanges({values, key: 'other_language', keyName: 'other_languages', vKey: 'other_languages', type: 'language', name: 'Other Languages', isNew})}`

    return extra
  }

  updateSettingChanges ({values, key, keyName, vKey, type, name = '', isNew = false}) {
    const { item, settings } = this.state

    const prevValue = item[`${key}_ids`]
    const currValue = values[vKey]

    const sortFn = (a, b) => {
      return a > b ? 1 : -1
    }

    if (!isNew && validator.isArray(prevValue) && validator.isArray(currValue)) {
      prevValue.sort(sortFn)

      currValue.sort(sortFn)

      if (!isEqual(prevValue, currValue)) {
        let prevValueNameArr = item && item.preferences && item.preferences[`${keyName}_name`] ? item.preferences[`${keyName}_name`] : []

        prevValueNameArr = prevValueNameArr.filter(e => e !== null)

        let currValueNameArr = currValue.map(e => {
          const s = settings.find(f => f.type === type && parseInt(f.id) === parseInt(e))

          if (s && s.id) {
            return s.name
          } else {
            return null
          }
        })
        currValueNameArr = currValueNameArr.filter(e => e !== null)

        prevValueNameArr.sort(sortFn)
        currValueNameArr.sort(sortFn)

        return `${name ? name : formatter.capitalize(type)} change from "${prevValueNameArr.join(',')}" to "${currValueNameArr.join(',')}"`
      }
    } else if (isNew && validator.isArray(currValue)) {
      currValue.sort(sortFn)

      let currValueNameArr = currValue.map(e => {
        const s = settings.find(f => f.type === type && f.id === e)

        if (s && s.id) {
          return s.name
        } else {
          return null
        }
      })
      currValueNameArr = currValueNameArr.filter(e => e !== null)
      currValueNameArr.sort(sortFn)

      return `${name ? name : formatter.capitalize(type)} set as "${currValueNameArr.join(',')}"`
    }

    return ''
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Client))

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter } from '../../../util'

// UI
import { Button, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 50

export class ReportFunderAgreement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      endedList: { list: [], total: 0 },
      endingList: { list: [], total: 0 },
      currentPage: 1,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false
    }
  }

  componentDidMount () {
    this.fetchList()
  }

  render () {
    const { currentPage, loading, loadingExport, loadingList, endedList, endingList, total } = this.state

    const endedColumns = [
      {
        title: 'Funder',
        width: 9,
        render: ({ id, fullname }) => <a href={`/funders/${id}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
      },
      {
        title: 'Ended On',
        width: 4,
        render: ({ funder_end_date }) => formatter.toShortDate(funder_end_date)
      },

      {
        title: 'As of Today',
        width: 4,
        key: 'ip',
        render: ({ funder_end_date }) => `${formatter.getDaysAgo(formatter.expiringDuration(funder_end_date))}`
      }
    ]

    const endingColumns = [
      {
        title: 'Funder',
        width: 9,
        render: ({ id, fullname }) => <a href={`/funders/${id}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
      },
      {
        title: 'Ending On',
        width: 4,
        render: ({ funder_end_date }) => formatter.toShortDate(funder_end_date)
      },
      {
        title: 'As of Today',
        width: 4,
        key: 'ip',
        render: ({ funder_end_date }) => `${formatter.getDaysToGo(formatter.expiringDuration(funder_end_date))}`
      }
    ]


    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Funder Agreement Expiry`} >
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }

          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Funders with Ended Agreement `} <span className='report-tag'>{endedList.total}</span></div>
              <List cols={endedColumns} rows={endedList.list} />

              <div className='report-title'>{`Funders with Ending Agreement `} <span className='report-tag'>{endingList.total}</span></div>
              <List cols={endingColumns} rows={endingList.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, isLoaded, loading } = this.state
    this.fetchFunderAgmtExp({currentPage, isLoaded, loading})
  }

  async fetchFunderAgmtExp ({ currentPage, isLoaded = true, loading }) {
    if (this.hasAccess('readFunderAgreementExpiryReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const { ending, ended } = await reportService.getListByAll(ExportType.REPORT.FUNDER_AGMT_EXP)

        this.setState({
          endingList: ending,
          endedList: ended,
          loading: false,
          loadingList: false,
          isLoaded: true
        }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Funder Agreement Expiry Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readFunderAgreementExpiryReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.FUNDER_AGMT_EXP)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

export default ReportFunderAgreement

import React, { Component } from 'react'
import { connect } from 'react-redux'
import LogMenu from '../../../constants/menu/log'
import { reminderLogService } from '../../../services'
import { formatter } from '../../../util'
// UI
import { List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

export class SettingReminderLog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      loading: false,
      sort: { id: -1 }
    }
  }

  componentDidMount () {
    const { currentPage, filter, sort } = this.state
    this.fetchLogs({ currentPage, filter, sort })
  }

  render () {
    const { currentPage, list, loading, total } = this.state
    const columns = [
      {
        title: 'Date',
        width: 3,
        render: ({ created_at }) => formatter.toStandardLongDate(created_at)
      },

      {
        title: 'Type',
        width: 2,
        render: ({ type }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{ type === 'cert' ? 'Certificate' : type}</div>
      },

      {
        title: 'Employee',
        width: 4,
        render: ({ employee, email }) => <div>
          <div className='updated-name' style={{ textTransform: 'capitalize' }}>{employee}</div>
          <div style={{ fontStyle: 'italic' }}>{email}</div>
        </div>
      },

      {
        title: 'Email Content',
        width: 15,
        key: 'content',
        render: ({ type, content }) => type === 'roster' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <div>{content}</div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={LogMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Reminder Log' />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} reminder logs`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Skeleton>
          </div>
        </Page.Content>

      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchLogs({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchLogs = async ({ currentPage = 1, loading, filter, sort, searchText }) => {
    try {
      this.setState({ currentPage, loading: true })
      const log = await reminderLogService.listByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({ list: log.list, total: log.total, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load logs successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingReminderLog))

export const FETCH_JOBS = 'FETCH_JOBS'
export const FETCHING_JOBS = 'FETCHING_JOBS'
export const JOBS_FETCHED = 'JOBS_FETCHED'
export const FETCH_TOTAL_PENDING_JOBS = 'FETCH_TOTAL_PENDING_JOBS'
export const FETCHED_TOTAL_PENDING_JOBS = 'FETCHED_TOTAL_PENDING_JOBS'

export const fetchJobs = (params) => {
  return Object.assign({
    type: FETCH_JOBS
  }, params)
}

export const fetchingJobs = (loading) => {
  return {
    type: FETCHING_JOBS,
    loading
  }
}

export const jobsFetched = (jobs) => {
  return {
    type: JOBS_FETCHED,
    jobs
  }
}

export const fetchTotalPending = () => {
  return {
    type: FETCH_TOTAL_PENDING_JOBS
  }
}

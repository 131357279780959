import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { fetchJobList, fetchJobSummary } from '../../../../states/actions'
import { ExportType, JobExportType, JobJvpMenu, JobListType, JobFilterType } from '../../../../constants'
import { authService } from '../../../../services'
import { common, exportFile, formatter, validator } from '../../../../util'
import { jobSelectRangeMaxDay, timezone } from '../../../../config'
import moment from 'moment-timezone'
import AddJobModal from '../../AddJobModal'
import SingleJobView from '../View'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'

import '../../styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 20
const { RangePicker } = DatePicker

moment.tz.setDefault(timezone)

const getFilterObject = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  value = value ? value.trim() : value

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          filter.$and.push({
            $or: [
              { client_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { employee_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { funder_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_job_start_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { status_sleepover: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }

      if (value && value.trim() !== '') {
        filter.$and.push({
          $or: [
            { client_fullname: { condition: 'ilike', value: `%${value}%` } },
            { employee_fullname: { condition: 'ilike', value: `%${value}%` } },
            { funder_fullname: { condition: 'ilike', value: `%${value}%` } },
            { string_job_start_date: { condition: 'ilike', value: `%${value}%` } },
            { string_job_start_day: { condition: 'ilike', value: `%${value}%` } },
            { tasks: { condition: 'ilike', value: `%${value}%` } },
            { client_suburb: { condition: 'ilike', value: `%${value}%` } },
            { status_sleepover: { condition: 'ilike', value: `%${value}%` } }
          ]
        })
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

const redirectUrl = (props, query) => {
  const { history, location } = props
  const params = new URLSearchParams(query)

  history.replace({ pathname: location.pathname, search: params.toString() })
}

class JobList extends Component {
  constructor (props) {
    super(props)
    const { location } = this.props
    const { page = 1, q = '' } = common.getQueryStringSearchParams(location.search)
    const { key = undefined } = location

    this.state = {
      currentPage: page ? Number(page) : 1,
      exportType: null,
      filter: {},
      isAddJobModal: false,
      isLoaded: false,
      isSearching: false,
      jobFilterType: null,
      jobSummary: {},
      list: [],
      loadingExport: false,
      loadingList: false,
      loadingSummary: false,
      showExportDate: false,
      selectedFirstDate: null,
      searchText: q || '' ,
      total: 0,
      currentTS: Date.now(),
      pageKey: key,
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobList: { list, total }, jobSummary = {}, loadingList, loadingSummary, isLoaded, fetchJobList, fetchJobSummary, match, location } = nextProps
    const { type = JobFilterType.JOB_TYPE_ACTIVE } = common.getPath(match)
    const { page = 1, q } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterObject(prevState.filter, q)

    const currentPage = page ? Number(page) : prevState.currentPage

    if (prevState.jobFilterType !== null && prevState.jobFilterType !== type) {
      redirectUrl(nextProps, { page: 1, q: '' })
      fetchJobList({ loading: true, isForceReload: true, currentPage: currentPage, pageSize, filter, sort: {}, searchText: filter.$and ? '' : q ? q : prevState.searchText, filterType: type })
      fetchJobSummary({ loadingSummary: true })
    }

    const state = {
      ...prevState,
      currentPage,
      filter,
      isLoaded,
      isSearching: prevState.loadingList === true && loadingList === false ? false : prevState.isSearching,
      jobFilterType: type,
      jobSummary,
      list,
      loadingList,
      loadingSummary,
      searchText: q ? q : prevState.searchText,
      total
    }

    return state
  }

  componentDidMount () {
    this.fetchDataList()
    this.fetchJobSummary()
  }

  getTitle () {
    const { jobFilterType } = this.state

    if (jobFilterType === JobFilterType.JOB_TYPE_FUTURE) {
      return 'Future Jobs'
    } else if (jobFilterType === JobFilterType.JOB_TYPE_PAST) {
      return 'Past Jobs'
    } else if (jobFilterType === JobFilterType.JOB_TYPE_CANCELLED) {
      return 'Cancelled Jobs'
    } else if (jobFilterType === JobFilterType.JOB_TYPE_PENDING) {
      return 'Pending Jobs'
    }

    return 'Single Jobs'
  }

  onSearchName (value) {
    const { filter: prevFilter, sort } = this.state
    this.setState({ isSearching: true })

    const filter = getFilterObject(prevFilter, value)

    this.setState({ filter, searchText: value, currentPage: 1 }, () => {
      redirectUrl(this.props, { page: 1, q: value })
      this.fetchSingleJobs({ currentPage: 1, filter, loading: true, searchText: (filter.$and ? '' : value), sort })
    })
  }

  render () {
    const { history } = this.props
    const { currentPage, jobFilterType, jobSummary, list, total, searchText, loadingExport, loadingList, loadingSummary, showExportDate, selectedFirstDate, isAddJobModal, isLoaded, isSearching } = this.state

    const title = this.getTitle(jobFilterType)

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobJvpMenu} backLink='/' countData={jobSummary} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess('createJob')
              ? <div className='btn' onClick={() => this.showAddJobModal(true)}>
                Add Job
              </div>
              : null }
            { this.hasAccess('listJobs')
              ? <div className='btn btn-ghost' onClick={() => this.openDownloadJobList(JobExportType.EXPORT_JOB_COMPLETE)}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `Export (Full)` }
              </div>
              : null }
            { this.hasAccess('listJobs')
              ? <div className='btn btn-ghost' onClick={() => this.openDownloadJobList(JobExportType.EXPORT_JOB_FEEDBACK)}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `Export (Feedback)` }
              </div>
              : null }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput
                  placeholder='Search'
                  onChange={(v) => this.onSearchName(v)}
                  isSearching={isSearching}
                  value={searchText}
                />
              </Col>
              <Col className='jobs-flex-row' lg={16}>
                <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                  <RangePicker
                    format='YYYY-MM-DD'
                    open={showExportDate}
                    disabledDate={(date) => this.validateSelectRange(date)}
                    onCalendarChange={(d1) => this.onRangeChange(d1)}
                    onChange={(d1) => this.exportJobList(d1)}
                  />
                </div>
              </Col>
            </Row>
          </Page.Filter>

          <div className='jobs-jvp'>
            <Skeleton loading={loadingList && !isLoaded} active>
              <Spin spinning={loadingList && isLoaded}>
                { validator.isArray(list) && list.map((item) => {
                  return <SingleJobView key={`amcss-${item.id}`} item={item} listType={JobListType.JOB_LIST_SINGLE} />
                })}
              </Spin>
            </Skeleton>
          </div>

          { !(loadingList && !isLoaded) // inverse of Skeleton activate condition
            ? <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} job${total === 1 ? '' : 's'}`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
            : null }

          <AddJobModal
            visible={isAddJobModal}
            history={history}
            onClose={() => this.showAddJobModal(false)}
          />

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    redirectUrl(this.props, { page: currentPage, q: searchText })
    this.fetchSingleJobs({ currentPage, filter, searchText, sort })
  }

  fetchDataList = async () => {
    const { currentPage, searchText } = this.state
    this.fetchList({ page: currentPage, searchText }, true)
  }

  async fetchList ({page = 1, searchText}, isRedirect = true) {
    const { filter, loading, searchText: prevSearchText, sort } = this.state

    if (isRedirect) {
      redirectUrl(this.props, { page, q: searchText !== undefined ? searchText : prevSearchText || '' })
    }

    this.fetchSingleJobs({ loading, isForceReload: isRedirect, currentPage: page, filter, searchText: searchText !== undefined ? searchText : prevSearchText, sort })
  }

  fetchSingleJobs = async ({ loading = true, isForceReload = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchJobList } = this.props
      const { jobFilterType } = this.state
      fetchJobList({ loading, isForceReload, currentPage, pageSize, filter, sort, searchText, filterType: jobFilterType })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load single jobs successfully. Please try again later.')
    }
  }

  fetchJobSummary = async () => {
    const { fetchJobSummary } = this.props

    fetchJobSummary()
  }

  openDownloadJobList = (exportType = null) => {
    const { showExportDate, loadingExport } = this.state
    if (loadingExport) return

    this.setState({ showExportDate: !showExportDate, exportType })
  }

  onRangeChange = (d1) => {
    if (d1 && validator.isNotEmptyArray(d1) && d1[0]) {
      this.setState({ selectedFirstDate: d1[0] })
    } else {
      this.setState({ selectedFirstDate: null })
    }
  }

  validateSelectRange = (date) => {
    const { selectedFirstDate } = this.state
    return selectedFirstDate ? (selectedFirstDate.isAfter(date, 'day') || date.isAfter(selectedFirstDate.clone().add(jobSelectRangeMaxDay, 'days'), 'days')) : false
  }

  validateRangeMaxDay (startDate, endDate) {
    if (startDate && endDate) {
      // +1 because the diff range could be between max day and may day + 1 and it would trigger error if select the last day of range
      return formatter.toDurationDiff(startDate, endDate, 'day') <= (jobSelectRangeMaxDay + 1)
    }

    return false
  }

  async exportJobList (d1) {
    this.setState({ showExportDate: false, selectedFirstDate: null })

    if (validator.isNotEmptyArray(d1)) {
      const { exportType } = this.state
      const startDate = d1[0]
      const endDate = d1[1]

      if (this.validateRangeMaxDay(startDate, endDate)) {
        this.setState({ loadingExport: true })
        const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_LIST, {from: startDate, to: endDate, exportType})

        setTimeout(() => {
          this.setState({ loadingExport: false })
        }, 2000)
      } else {
        notify.error('Unable to Export Jobs', 'You max excced the limit of days count for duration. Please select the dates again.')
      }
    }
  }

  // add job modal
  showAddJobModal = (isAddJobModal) => {
    this.setState({ isAddJobModal })
  }


  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchJobList,
  fetchJobSummary
}

const mapStateToProps = (state) => {
  return { ...state.JvpJob, ...state.JvpJobSummary }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobList))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingFeedbackSubCategoryService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Pager, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Sub-Category'
const settingType = 'sub-category'

export class SettingFeedbackSubCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: {},
        show: false
      },
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount() {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })
  }

  fetchSettings = async ({ currentPage = 1, filter = {} }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingFeedbackSubCategoryService.listByPage(currentPage, pageSize, filter)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form, onComplete } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
    onComplete()
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem, currentPage } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingFeedbackSubCategoryService.save(id, values)
          } else {
            response = await settingFeedbackSubCategoryService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }
            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: currentPage })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit(id, name, description, active, is_editable) {
    this.setState({ selectedItem: { id, name, description, active, is_editable } })
    this.showModal()
  }

  async handleDelete(id) {
    const res = await settingFeedbackSubCategoryService.remove(id)

    if (res && !res.errors) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchSettings({ currentPage: 1 })
    }
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render() {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 6,
        key: 'name'
      },
      {
        title: 'Description',
        width: 10,
        key: 'description'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{active ? <Icon type='check-circle' theme='filled' /> : null}</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, name, description, active, is_editable }) => this.hasAccess('updateFeedbackSubCategory')
          ? (
            <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(id, name, description, active, is_editable)}>
              <Icon type='form' />
            </div>
          ) : null
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess('deleteFeedbackSubCategory') && is_editable
          ? (
            <Popconfirm
              title='Confirm to delete this?'
              onConfirm={(e) => this.handleDelete(id)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' />
            </Popconfirm>
          ) : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} categories`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title={settingTitle}
          showModal={modal.show || this.props.onAdd}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Name'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Name must be between 2 and 128 characters' },
                  { max: 128, message: 'Name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input disabled={selectedItem.is_editable === false} />
              )}
            </FormItem>

            <FormItem label='Description'>
              {getFieldDecorator('description', {
                initialValue: selectedItem.description,
                rules: [
                  { min: 2, message: 'Description must be between 2 and 128 characters' },
                  { max: 128, message: 'Description must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter description' },
                  { whitespace: true, message: 'Please enter description' }
                ]
              })(
                <Input />
              )}
            </FormItem>

            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: typeof selectedItem.active === 'boolean' ? selectedItem.active : true,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={selectedItem.is_editable === false}
                  checkedChildren='Enable'
                  unCheckedChildren='Disable'
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFeedbackSubCategory))

import { FETCHING_CLIENTS, CLIENTS_FETCHED } from '../actions/client'

const INITIAL_STATE = {
  clients: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CLIENTS:
      return handleFetchingClients(state, action)
    case CLIENTS_FETCHED:
      return handleClientsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingClients (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleClientsFetched (state, action) {
  const { clients, loading } = action
  return { ...state, clients, loading }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { authService, fvpRateTaxCodeService } from '../../../../services'

// UI
import { Button, Loading, Page, Pager, Panel, SideModal, List } from '../../../../components'
import { validator } from '../../../../util'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingType = 'tax_code'

export class SettingTaxCode extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchTaxCodes({ currentPage, loading: true })
  }

  fetchTaxCodes = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      if (this.hasAccess('listFvpFundingTaxCodes')) {
        this.setState({ currentPage, loading: loading })
        const settings = await fvpRateTaxCodeService.listByPage(currentPage, pageSize, { is_delete: false })
        this.setState({ list: settings.list, loading: false, total: settings.total })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal

        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await fvpRateTaxCodeService.save(id, values)
          } else {
            values.image = item.image
            response = await fvpRateTaxCodeService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', 'Tax code saved successfully.')
            this.fetchTaxCodes({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (item) {
    this.setState({ selectedItem: item })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await fvpRateTaxCodeService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchTaxCodes({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  validateAmount (rule, value, callback) {
    const name = rule.field === 'value' ? 'Value' : rule.field
    if (value === null || value === undefined || value === '') {
      callback(new Error(`Please enter ${name}.`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${name} must be valid.`))
    } else {
      callback()
    }
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 6,
        key: 'name'
      },
      {
        title: 'Code',
        width: 6,
        key: 'code'
      },
      {
        title: 'Value',
        width: 6,
        render: ({ value }) => <div>{value} %</div>
      },
      {
        title: 'Enable',
        width: 4,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: (item) => this.hasAccess('updateFvpFundingTaxCode') ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(item)}><Icon type='form' /></div> : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('deleteFvpFundingTaxCode') ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    return (
      <div>
        <Page.Header title='Tax Codes'>
          {
            this.hasAccess('createFvpFundingTaxCode')
            ? <div className='btn' onClick={() => this.showModal()}>
              Add
            </div>
            : null
          }
        </Page.Header>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} tax codes`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title='Tax Code'
          showModal={modal.show}
          onClose={this.hideModal}
          buttons={[
            this.hasAccess('updateFvpFundingTaxCode') || this.hasAccess('createFvpFundingTaxCode')
            ? <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
            : null
          ]}
        >
          <Loading loading={loadingForm} blur>
            <Form layout='vertical'>
              <FormItem label='Name'>
                {getFieldDecorator('name', {
                  initialValue: selectedItem.name || null,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label='Code'>
                {getFieldDecorator('code', {
                  initialValue: selectedItem.code || null,
                  rules: [
                    { min: 2, message: 'Code must be between 2 and 128 characters' },
                    { max: 128, message: 'Code must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter code' },
                    { whitespace: true, message: 'Please enter code' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label='Value'>
                {getFieldDecorator('value', {
                  initialValue: selectedItem.value || 0,
                  rules: [
                    { required: true, message: ' ' },
                    { validator: this.validateAmount }
                  ]
                })(
                  <Input addonAfter={'%'}/>
                )}
              </FormItem>
              <FormItem label=''>
                {getFieldDecorator('active', {
                  initialValue:selectedItem.active === undefined || selectedItem.active === null ? true : selectedItem.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Enable' unCheckedChildren='Disable'
                  />
                )}
              </FormItem>
            </Form>
          </Loading>
        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingTaxCode))

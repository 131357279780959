import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'

import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export default class DateTimePicker extends Component {
  constructor (props) {
    super(props)
    const { defaultValue, showDate = true, showTime = true, value } = this.props
    const moment = Moment
    const dateValue = value || defaultValue || moment().startOf('hour')
    const dateString = showDate && showTime ? dateValue.format('DD/MM/YYYY hh:mm A') : showDate ? dateValue.format('DD/MM/YYYY') : dateValue.format('hh:mm A')

    this.state = {
      isSet: false,
      showPicker: false,
      defaultDateValue: dateValue.clone(),
      dateValue: dateValue,
      dateValueString: dateString,
      dateTime: {
        day: parseInt(dateValue.format('DD')),
        month: parseInt(dateValue.format('MM')),
        year: parseInt(dateValue.format('YY')),
        hour: parseInt(dateValue.format('hh')),
        minute: parseInt(dateValue.format('mm')),
        ampm: dateValue.format('A')
      },
      isAM: true
    }
    this.changeDateValue = debounce(this.changeDateValue, 80)
  }

  static getDerivedStateFromProps (props, state) {
    const moment = Moment

    if (moment(props.value).isValid() && props.value.format('DD/MM/YYYY hh:mm A') !== state.defaultDateValue.format('DD/MM/YYYY hh:mm A')) {
      const { value: dateValue, showDate = true, showTime = true } = props
      const dateString = showDate && showTime ? dateValue.format('DD/MM/YYYY hh:mm A') : showDate ? dateValue.format('DD/MM/YYYY') : dateValue.format('hh:mm A')

      // console.log('new value', dateValue.format('DD/MM/YYYY hh:mm A'), state.dateValue.format('DD/MM/YYYY hh:mm A'))
      return {
        dateTime: {
          day: parseInt(dateValue.format('DD')),
          month: parseInt(dateValue.format('MM')),
          year: parseInt(dateValue.format('YY')),
          hour: parseInt(dateValue.format('hh')),
          minute: parseInt(dateValue.format('mm')),
          ampm: dateValue.format('A')
        },
        defaultDateValue: dateValue.clone(),
        dateValueString: dateString,
        dateValue: dateValue
      }
    }

    return null
  }

  componentDidMount () {
  }

  showPicker = () => {
    this.setState({ showPicker: true })
  }

  changeDateValue = (type, e, key) => {
    const { dateValue } = this.state
    const value = parseInt(e)

    if (type === 'day' && value > 0 && value <= 31) {
      dateValue.set('date', value)
    } else if (type === 'month' && value > 0 && value <= 12) {
      dateValue.set('month', value - 1)
    } else if (type === 'year' && value > 0 && value <= 30) {
      dateValue.set('year', 2000 + value)
    } else if (type === 'hour' && value > 0 && value <= 12) {
      dateValue.set('hour', value)
    } else if (type === 'minute' && value > 0 && value <= 60) {
      dateValue.set('minute', value)

      if (value > 6 || value === 0) {
        dateValue.set('minute', value % 15 === 0 || value === 0 ? value : 0)
      }
    }

    this.setState({ dateValue })
  }

  changeValue = (type, key) => {
    const { dateValue } = this.state

    // console.log('changeValue', type, key)

    if (key === 'Backspace') {
      if (type === 'day') {
        dateValue.set('date', 1)
      } else if (type === 'month') {
        dateValue.set('month', 0)
      } else if (type === 'year') {
        dateValue.set('year', 2000)
      } else if (type === 'hour') {
        dateValue.set('hour', 0)
      } else if (type === 'minute') {
        dateValue.set('minute', 0)
      }
    }

    this.setState({ dateValue })
  }

  changeDate = (type, increment) => {
    const { dateValue } = this.state
    let newDate = dateValue

    if (increment) {
      newDate = dateValue.add(1, type)
    } else {
      newDate = dateValue.add(-1, type)
    }

    this.setState({ dateValue: newDate })
  }

  changeTime = (type, increment, step = 1) => {
    const { dateValue } = this.state
    const { showDate, showTime } = this.props

    const oldDate = dateValue.clone()
    let newDate = dateValue

    if (increment) {
      newDate = dateValue.add(step, type)
    } else {
      newDate = dateValue.add(-step, type)
    }

    if (!showDate && showTime) {
      if (!oldDate.isSame(newDate, 'day')) {
        if (newDate.isAfter(oldDate)) {
          newDate = newDate.add(-1, 'day')
        } else if (newDate.isBefore(oldDate)) {
          newDate = newDate.add(1, 'day')
        }
      }
    }

    this.setState({ dateValue: newDate })
  }

  changeAPM = (type) => {
    const { dateValue, isAM } = this.state
    let newDate = dateValue

    if (isAM) {
      newDate = dateValue.add(12, 'hour')
    } else {
      newDate = dateValue.add(-12, 'hour')
    }

    this.setState({ dateValue: newDate, isAM: !isAM })
  }

  reset = () => {
    const { defaultDateValue } = this.state
    this.setState({ dateValue: defaultDateValue.clone() })
  }

  set = () => {
    const { dateValue } = this.state
    const { onChange, showDate = true, showTime = true } = this.props
    const dateString = showDate && showTime ? dateValue.format('DD/MM/YYYY hh:mm A') : showDate ? dateValue.format('DD/MM/YYYY') : dateValue.format('hh:mm A')
    this.setState({ dateValue, dateValueString: dateString, showPicker: false, isSet: true })

    // console.log('New Date', dateValue.format('DD/MM/YYYY hh:mm A'))

    if (onChange) {
      onChange(dateValue)
    }
  }

  closePicker = () => {
    const { defaultDateValue } = this.state
    this.setState({ dateValue: defaultDateValue.clone(), showPicker: false })
  }

  handleFocus = (event) => event.target.select()

  render () {
    const { defaultValue, disabled = false, placeholder = '', showDate = true, showTime = true, value } = this.props
    const { dateValue, dateValueString, isSet, showPicker } = this.state
    const showBoth = showDate & showTime

    // console.log(dateValueString)

    return (
      <div className='picker'>
        <Input className='inputField' readOnly disabled={disabled} placholder={placeholder} onClick={() => this.showPicker()} value={(value || defaultValue || isSet) ? dateValueString : ''} suffix={<Icon type='calendar' />} />
        <div className='container' style={{ display: showPicker ? '' : 'none' }}>
          <div className='header'>
            <div className='date-display'>{dateValue.format(showBoth ? 'dddd, MMM DD, YYYY hh:mm A' : showDate ? 'dddd, MMM DD, YYYY' : 'hh:mm A')}</div>
            <div className='action-icon' onClick={() => this.closePicker()}><Icon type='close-circle' theme='filled' /></div>
          </div>
          <div style={{zIndex: 30000, padding: '0px 8px'}}>
            <Row>
              <Col span={showBoth ? 11 : 24} style={{ display: showDate ? '' : 'none' }}>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('day', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('month', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('year', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
              </Col>
              <Col span={2} />
              <Col span={showBoth ? 11 : 24} style={{ display: showTime ? '' : 'none' }}>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><div className='control' onClick={() => this.changeTime('hour', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeTime('minute', true, 15)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeAPM()}><Icon type='plus-circle' theme='filled' /></div></Col>
              </Col>
            </Row>
            <Row className='value'>
              <Col span={showBoth ? 11 : 24} style={{ display: showDate ? '' : 'none' }}>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={('00' + dateValue.format('DD')).slice(-2)} onFocus={this.handleFocus} onClick={this.handleFocus} onChange={(e) => this.changeDateValue('day', e.target.value)} onKeyDown={e => this.changeValue('day', e.key)} /></Col>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={dateValue.format('MMM')} onFocus={this.handleFocus} onChange={(e) => this.changeDateValue('month', e.target.value)} readOnly /></Col>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={dateValue.format('YY')} onFocus={this.handleFocus} onChange={(e) => this.changeDateValue('year', e.target.value)} /></Col>
              </Col>
              <Col span={2} />
              <Col span={showBoth ? 11 : 24} style={{ display: showTime ? '' : 'none' }}>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={('00' + dateValue.format('hh')).slice(-2)} onFocus={this.handleFocus} onChange={(e) => this.changeDateValue('hour', e.target.value)} /></Col>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={String(dateValue.format('mm')).padStart(2, '0')} onFocus={this.handleFocus} onChange={(e) => this.changeDateValue('minute', e.target.value)} onKeyDown={e => this.changeValue('minute', e.key)} /></Col>
                <Col xs={8} lg={8} style={{ textAlign: 'center' }}><input type='text' className='label' value={dateValue.format('A')} readOnly /></Col>
              </Col>
            </Row>
            <Row style={{ marginTop: 2 }}>
              <Col span={showBoth ? 11 : 24} style={{ display: showDate ? '' : 'none' }}>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('day', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('month', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeDate('year', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
              </Col>
              <Col span={2} />
              <Col span={showBoth ? 11 : 24} style={{ display: showTime ? '' : 'none' }}>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeTime('hour', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeTime('minute', false, 15)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col xs={8} lg={8}><div className='control' onClick={() => this.changeAPM()}><Icon type='minus-circle' theme='filled' /></div></Col>
              </Col>
            </Row>
          </div>
          <div className='footer'>
            <div className='button' onClick={() => this.reset()}>Reset</div>
            <div className='button' onClick={() => this.set()}>Set Date</div>
          </div>
        </div>

      </div>
    )
  }
}

DateTimePicker.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func
}

export default [
  {
    title: 'Section 2',
    groups: [
      {
        subgroup: [
          {
            title: 'Reports',
            icon: 'snippets',
            menu: [
              {
                name: 'Back-To-Back Shifts',
                link: '/reports/back-to-back-job',
                permission: 'readBackToBackReport'
              },
              {
                name: 'Clashed Shifts',
                link: '/reports/clash-job',
                permission: 'readClashReport'
              },
              {
                name: 'Client\'s Birthday',
                link: '/reports/client-birthday',
                permission: 'readClientBirthdayReport'
              },
              {
                name: 'Client\'s Budget Report',
                link: '/reports/client-budget',
                permission: 'readClientBudgetReport'
              },
              {
                name: 'Client\'s Care Plan Review',
                link: '/reports/care-plan-v2-review',
                permission: 'readClientCarePlanReviewReport'
              },
              {
                name: 'Client\'s Funding',
                link: '/reports/client-funding',
                permission: 'readClientFundReport'
              },
              {
                name: 'Client\'s Files',
                link: '/reports/client-files',
                permission: 'readClientFilesReport'
              },
              {
                name: 'Client\'s Leave',
                link: '/reports/client-leave',
                permission: 'readClientReport'
              },
              {
                name: 'Day Counts since Last Job',
                link: '/reports/last-job',
                permission: 'readDayCountReport'
              },
              {
                name: 'Employee\'s Birthday',
                link: '/reports/employee-birthday',
                permission: 'readEmployeeBirthdayReport'
              },
              {
                name: 'Employee\'s Files',
                link: '/reports/employee-files',
                permission: 'readEmployeeCertReport'
              },
              {
                name: 'Employee\'s Leave',
                link: '/reports/employee-leave',
                permission: 'readEmployeeReport'
              },
              {
                name: 'Employee\'s Required Files',
                link: '/reports/employee-missing-required-files',
                permission: 'readEmployeeMissingRequiredFileReport'
              },
              // {
              //   name: 'Funder\'s Agreement Expiry',
              //   link: '/reports/funder-agreement-expiry',
              //   permission: 'readFunderAgreementExpiryReport'
              // },
              {
                name: 'Feedback',
                link: '/reports/feedback',
                permission: 'readFeedbackReport'
              },
              {
                name: 'Funder\'s Files',
                link: '/reports/funder-files',
                permission: 'readFunderFilesReport'
              },
              {
                name: 'Job Cancellation',
                link: '/reports/job-cancellation',
                permission: 'readJobMultiActionReport'
              },
              {
                name: 'Job Timesheet Report',
                link: '/reports/job-timesheet-report',
                permission: 'readJobTimesheetReport'
              },
              {
                name: 'Multi Client Shifts',
                link: '/reports/multi-client',
                permission: 'readMultiClientReport'
              },
              {
                name: 'Public Holidays',
                link: '/reports/public-holiday',
                permission: 'readPublicHolidayReport'
              },
              {
                name: 'System User Action',
                link: '/reports/action',
                permission: 'readActionReport'
              },
              {
                name: 'Individual User Action',
                link: '/reports/action/me',
                // permission: 'readActionReport' // No permission required for individual user action
                status: 1
              },
              {
                name: 'Recipient Settings',
                link: '/reports/setting',
                permission: 'listRecipients'
              }
            ],
            permission: 'listReports'
          }
        ],
      }
    ]
  }
]

/* global google */

import React, { Component } from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle } from 'react-google-maps'
import { compose, withProps, lifecycle, withState, withHandlers } from 'recompose'

import { common, formatter, log, validator } from '../../../util'
import { googleMapApi } from '../../../config'

const clientMarker = {
  url: process.env.PUBLIC_URL + '/img/home.png',
  anchor: new window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(40, 122)
}
const carerMarker = {
  url: process.env.PUBLIC_URL + '/img/placeholder2.png',
  anchor: new window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(40, 122)
}

const FlexiMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapApi}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `40vh` }} />,
    containerElement: <div style={{ height: `40vh` }} />,
    mapElement: <div style={{ height: `40vh` }} />
  }),
  lifecycle({
    componentDidMount () {
      const { clientCoords, radius, signoffCoords } = this.props
      const { distance, center, zoom } = formatter.updateMapDetails(clientCoords, signoffCoords, radius)
      this.setState({
        distance,
        mapCenter: center,
        mapZoom: zoom || this.props.zoom
      })
    },
    componentWillReceiveProps (nextProps) {
      const { clientCoords, radius, signoffCoords, mapCenter } = nextProps
      const { distance, center, zoom } = formatter.updateMapDetails(clientCoords, signoffCoords, radius)
      if (clientCoords && signoffCoords) {
        this.setState({
          flexMap: map => {
            if (map && !isNaN(distance)) {
              map.panTo({lat: center.latitude, lng: center.longitude})
            }
          },
          mapCenter: center,
          distance: distance,
          mapZoom: zoom
        })
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { clientCoords, radius, signoffCoords } = props
  return (
    <GoogleMap
      zoom={props.mapZoom || props.zoom}
      defaultCenter={{ lat: parseFloat(props.mapCenter.latitude), lng: parseFloat(props.mapCenter.longitude) }}
      ref={props.flexMap}
      origin={props.origin}
    >
      { clientCoords && !isNaN(clientCoords.latitude) && !isNaN(clientCoords.longitude)
        ? <Marker
          key={`clientmarker`}
          position={{ lat: clientCoords.latitude, lng: clientCoords.longitude }}
          icon={clientMarker}
          label={{
            text: `Client's location`,
            fontWeight: 'bold',
            fontSize: '13px',
            color: 'black'
          }}
        />
        : null }
      { signoffCoords && !isNaN(signoffCoords.latitude) && !isNaN(signoffCoords.longitude)
        ? <Marker
          key={`swmarker`}
          position={{ lat: signoffCoords.latitude, lng: signoffCoords.longitude }}
          icon={carerMarker}
          label={{
            text: `Signing location (accuracy: ${formatter.toDistance(radius)})`,
            fontWeight: 'bold',
            fontSize: '13px',
            color: 'black',
            backgroundColor: 'white'
          }}
        />
        : null }
      { signoffCoords && !isNaN(signoffCoords.latitude) && !isNaN(signoffCoords.longitude)
        ? <Circle
          key={`swcircle1`}
          center={{ lat: signoffCoords.latitude, lng: signoffCoords.longitude }}
          radius={parseInt(radius)}
          options={{
            fillColor: '#FF000055',
            strokeColor: '#FF0000AA'
          }}
        />
        : null }
    </GoogleMap>
  )
})

export default FlexiMap

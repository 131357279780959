import { FILE_MODULE_PERMISSIONS_FETCHED, FILE_MODULE_PERMISSION_SET_PRISTINE } from '../../actions/setting/fileModulePermission'

const INITIAL_STATE = {
  fileModulePermissions: { list: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FILE_MODULE_PERMISSIONS_FETCHED:
      return { ...state, ...handleFileModulePermissionsFetched(action) }
    case FILE_MODULE_PERMISSION_SET_PRISTINE:
      return { ...state, ...handleFileModulePermissionSetPristine(action) }
    default:
      return state
  }
}

function handleFileModulePermissionsFetched (action) {
  const { fileModulePermissions } = action
  return { fileModulePermissions, pristine: false }
}

function handleFileModulePermissionSetPristine (action) {
  const { pristine } = action
  return { pristine }
}

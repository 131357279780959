import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { authService, clientService, fvpCategoriesService, fvpClientFundingService, fvpClientFundingPeriodService, fvpRatesService, fvpFunderService, fvpFundManagerService, funderService, settingGeneralService } from '../../../services'
import { fetchingClients, setRefreshActivityLog, setRefreshFiles } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log, trigger, uploader, validator } from '../../../util'
import { DefaultDates } from '../../../constants'

// UI
import { Button, ControlLabel, Loading, List, Panel, SideModal, Pager, FileTypeUpload } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Popover from 'antd/lib/popover'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Popconfirm from 'antd/lib/popconfirm'
import Upload from 'antd/lib/upload'

import { apiHostname } from '../../../config'
import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'

const { Item: FormItem } = Form
const Option = Select.Option
const { confirm } = Modal

const pageSize = 20
const funderModule = 'client'

const MSG_NO_CATEGORIES_AVAILABLE = 'No available categories under the selected period. If period start date is selected, please check on your rate set configuration.'

export class ClientFunding extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      loadingList: false,
      loadingForm: false,
      loadingCategories: false,
      loadingManagers: false,
      categories: [],
      categoriesAllList: [],
      currentCategories: [],
      currentFundManagers: [],
      currentFunders: [],
      funders: [],
      fundManagers: [],
      funding: [],
      selectedFundingItem: {},
      selectedPeriodItem: {},
      isFundingModal: false,
      isPeriodModal: false,
      isNoKMRestriction: false,
      textNoAvailableCats: ''
    }
  }

  componentDidMount () {
    this.fetchFunding()
    this.fetchCategories()
    this.fetchFunders()
    this.fetchAllFundManagers()
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const {
      currentPage,
      loading,
      loadingList,
      loadingForm,
      loadingCategories,
      loadingManagers,
      currentCategories,
      currentFundManagers,
      currentFunders,
      categories,
      funders,
      funding,
      selectedFundingItem,
      selectedPeriodItem,
      isFundingModal,
      isPeriodModal,
      isNoKMRestriction,
      textNoAvailableCats
    } = this.state

    const columns = [
      {
        title: 'Start',
        width: 3,
        render: ({ start_date }) => formatter.toShortDate(start_date)
      },
      {
        title: 'End',
        width: 3,
        render: ({ end_date }) => formatter.toShortDate(end_date)
      },
      {
        title: 'Fund Manager',
        width: 5,
        key: 'fund_manager_name'
      },
      {
        title: 'Invoice Notes',
        width: 4,
        key: 'invoice_note'
      },
      {
        title: 'CM/SC',
        width: 4,
        render: (item) => <div>{item.cmsc_first_name || ''} &nbsp;
          {item.cmsc_first_name ? <Popover content={<div>{item.cmsc_org}<br />{item.cmsc_contact}<br />{item.cmsc_email}</div>} title={`${item.cmsc_first_name || ''}`}>
            <Icon type='info-circle' />
          </Popover> : null}
        </div>
      },
      {
        title: 'Max KM (per week)',
        width: 3,
        render: ({ km_max }) => km_max === null
          ? <span style={{ fontSize: 16 }}>∞</span>
          : parseFloat(km_max) === 0
            ? 'Not Allowed'
            : km_max
      },
      {
        title: 'Active',
        width: 1,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: (item) => (
          <div className='action-buttons'>
            { this.hasAccess('updateFvpClientFundingPeriod')
              ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                <div onClick={() => this.triggerPeriodModal(true, item)}>
                  <Icon type='form' />
                </div>
              </Tooltip>
              : null}
            {this.hasAccess('deleteFvpClientFundingPeriod')
              ? <Popconfirm
                  title='Confirm to delete this?'
                  onConfirm={() => this.handleDeletePeriod(item)}
                  okText='Yes'
                  cancelText='No'
                >
                <Icon type='delete' style={{ marginTop: '2px' }} />
                </Popconfirm>
              : null}
          </div>
        )
      }
    ]

    return (<div>
      <Loading loading={loading} blur>
        <Panel
          title='Funding'
          subtitle={this.hasAccess('createFvpClientFundingPeriod')
            ? <div className='btn' onClick={() => this.triggerFundingModal(true)}>Add</div>
            : null}
        >
          <Loading loading={loading} blur>
            <Spin spinning={loadingList}>
              <div className='client-funding-list'>
                { funding.map((item, index) => {
                  const isFundingActive = !!item.active
                  const isFunderActive = !!item.funder_active
                  const isActive = isFundingActive && isFunderActive
                  const isContactClient = !!item.contact_client_for_jobs
                  const isAllowAddPeriod = !!item.is_allow_add_period

                  return (
                    <div key={`flist${index}`} className={`client-funding-list-item ${isActive ? '' : 'client-funding-list-item-inactive'}`}>
                      <Row className={`row ${isActive ? '' : 'row-inactive'}`}>
                        <Col lg={8}>
                          <ControlLabel className={`${isActive ? '' : 'label-inactive'}`}>Funder</ControlLabel>
                          <div className='info'>
                            <a className={`${isActive ? '' : 'label-inactive'}`} href={`/funders/${item.funder_id}`} rel='noopener noreferrer' target='_blank'>
                              {item.funder_fullname}
                            </a>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <ControlLabel className={`${isActive ? '' : 'label-inactive'}`}>ACTIVE</ControlLabel>
                          <div className='info'>{isActive ? 'Yes' : !isFunderActive ? 'No (Funder Inactive)' : 'No'}</div>
                        </Col>
                        <Col lg={10}>
                          <ControlLabel className={`${isActive ? '' : 'label-inactive'}`}>Contact Client For Jobs?</ControlLabel>
                          <div className='info'>{isContactClient ? 'Yes' : 'No'}</div>
                        </Col>
                        <Col lg={1}>
                          <div className='action-buttons' style={{ marginRight: '10px', marginTop: '15px' }}>
                            { this.hasAccess('updateFvpClientFundingPeriod')
                              ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                                <div onClick={() => this.triggerFundingModal(true, item)}><Icon type='form' /></div>
                              </Tooltip>
                              : null }
                            { this.hasAccess('deleteFvpClientFundingPeriod')
                              ? <Popconfirm
                                title='Confirm to delete this funder?'
                                onConfirm={() => this.handleDeleteFunding(item)}
                                okText='Yes'
                                cancelText='No'>
                                <Icon type='delete' style={{ marginTop: '2px' }} />
                              </Popconfirm>
                              : null }
                          </div>
                        </Col>
                      </Row>

                      <Row className='row-period'>
                        <Col className={'label'}>
                          Funding Period
                          { this.hasAccess('createFvpClientFundingPeriod') && isAllowAddPeriod
                            ? <span
                              onClick={() => this.triggerPeriodModal(true, { funder_id: item.funder_id, funding_id: item.id, is_fund_manager: item.is_fund_manager })}
                              style={{ cursor: 'pointer', marginLeft: '12px' }}
                              >
                                <Icon type='plus-circle' theme='filled' style={{ fontSize: '10pt' }} />
                              </span>
                            : null }
                        </Col>
                        {validator.isNotEmptyArray(item.funding_period)
                          ? <List
                            headerTextColor={isFundingActive ? undefined : '#888'}
                            cols={columns}
                            rows={item.funding_period}
                            dimmedIf={{key: 'active', value: false, overwrite: !isFundingActive}}
                          />
                          : <div className='div-text'>No Funding Period available.</div>}
                      </Row>
                    </div>
                  )
                })}
              </div>
            </Spin>
          </Loading>
        </Panel>

        <Pager
          size={pageSize}
          total={funding.length}
          totalText={`Total ${funding.length} fundings`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        <SideModal
          title='Funder'
          showModal={isFundingModal}
          onClose={() => this.triggerFundingModal(false)}
          buttons={[
            selectedFundingItem.id && this.hasAccess('updateFvpClientFundingPeriod')
            ? <Button key='saveFunding1' onClick={() => this.handleSaveFunding()} feedback={loadingForm}>Save</Button>
            : !selectedFundingItem.id && this.hasAccess('createFvpClientFundingPeriod')
              ? <Button key='saveFunding2' onClick={() => this.handleSaveFunding()} feedback={loadingForm}>Save</Button>
              : null
          ]}
        >
          { isFundingModal
            ? <Form layout='vertical'>
              <FormItem label='Active'>
                {getFieldDecorator('active', {
                  initialValue: selectedFundingItem.active !== undefined && selectedFundingItem.active !== null ? selectedFundingItem.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={loadingForm}
                  />
                )}
              </FormItem>
              <FormItem label='Funder'>
                {getFieldDecorator('funder_id', {
                  initialValue: selectedFundingItem.funder_id || null,
                  rules: [
                    { required: true, message: 'Please select a funder' }
                  ]
                })(
                  <Select
                    showSearch
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    placeholder='Please select a funder'
                    filterOption={(input, option) => this.findFunders(input, option)}
                    // onChange={this.handleFunderChange}
                    disabled={loadingForm || selectedFundingItem.id}
                  >
                    {
                      selectedFundingItem && selectedFundingItem.id
                      ? funders.map((funder, idx) => (
                        <Option key={`fder${idx}`} value={funder.id}>{funder.fullname}</Option>
                      ))
                      : currentFunders.map((funder, idx) => (
                        <Option key={`fder${idx}`} value={funder.id}>{funder.fullname}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
              <FormItem label='Contact Client directly for Jobs?'>
                {getFieldDecorator('contact_client_for_jobs', {
                  initialValue: selectedFundingItem.contact_client_for_jobs !== undefined && selectedFundingItem.contact_client_for_jobs !== null ? selectedFundingItem.contact_client_for_jobs : false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={loadingForm}
                  />
                )}
              </FormItem>
            </Form>
            : null }
        </SideModal>

        <SideModal
          title='Funding Period'
          showModal={isPeriodModal}
          onClose={() => this.triggerPeriodModal(false)}
          buttons={[
            <Button key='savePeriod' onClick={() => this.handleSavePeriod()} feedback={loadingForm}>Save</Button>
          ]}
        >
          { isPeriodModal
            ? <Form layout='vertical'>
              <FormItem label='Start'>
                {getFieldDecorator('start_date', {
                  initialValue: selectedPeriodItem.start_date ? (moment.isMoment(selectedPeriodItem.start_date) ? selectedPeriodItem.start_date : moment(selectedPeriodItem.start_date)) : null,
                  rules: [
                    { required: true, message: 'Please select a start date' },
                    { validator: this.handleDateChange }
                  ]
                })(
                  <DatePicker format='DD/MM/YYYY' disabled={loadingForm} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>

              <FormItem label='End'>
                {getFieldDecorator('end_date', {
                  initialValue: selectedPeriodItem.end_date ? (moment.isMoment(selectedPeriodItem.end_date) ? selectedPeriodItem.end_date : moment(selectedPeriodItem.end_date)) : null,
                  rules: [
                    { required: true, message: 'Please select an end date' },
                    { validator: this.handleDateChange }
                  ]
                })(
                  <DatePicker format='DD/MM/YYYY' disabled={loadingForm} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>

              <FormItem
                label='Is Funder Period'
                style={{display: 'none'}}
              >
                {getFieldDecorator('is_default_funder_period', {
                  initialValue: selectedPeriodItem.is_default_funder_period,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={loadingForm}
                  />
                )}
              </FormItem>

              <FormItem label='Invoice Notes'>
                {getFieldDecorator('invoice_note', {
                  initialValue: selectedPeriodItem.invoice_note || null,
                  rules: [
                    { min: 2, message: 'Invoice notes must be between 2 and 128 characters' },
                    { max: 128, message: 'Invoice notes must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter invoice notes' }
                  ]
                })(
                  <Input disabled={loadingForm} />
                )}
              </FormItem>

              {/* <Spin spinning={loadingManagers}>
                <FormItem label='Fund Manager'>
                  {getFieldDecorator('fund_manager_id', {
                    initialValue: validator.isNotEmptyArray(currentFundManagers) ? (selectedPeriodItem.fund_manager_id || null) : null,
                    rules: [
                      validator.isNotEmptyArray(currentFundManagers)
                      ? { required: true, message: 'Please select a fund manager' }
                      : {}
                    ]
                  })(
                    <Select
                      placeholder='Please select a fund manager'
                      showSearch
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      disabled={!validator.isNotEmptyArray(currentFundManagers) || loadingForm}
                    >
                      {
                        currentFundManagers.map((fm) => (
                          <Option key={`fmls${fm.fund_manager_id}`} value={parseInt(fm.fund_manager_id)}>{fm.funder_manager_fullname}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>
              </Spin> */}

              <Spin spinning={loadingManagers}>
                <FormItem label='Fund Manager'>
                  {getFieldDecorator('fund_manager_id', {
                    initialValue: selectedPeriodItem.fund_manager_id,
                    rules: [
                      selectedPeriodItem.is_fund_manager === true
                      ? { required: true, message: 'Please select a fund manager' }
                      : {}
                    ]
                  })(
                    <Select
                      placeholder='Please select a fund manager'
                      showSearch
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      disabled={!selectedPeriodItem.is_fund_manager || loadingForm}
                    >
                      {
                        currentFundManagers.map((fm) => (
                          <Option key={`fmls${fm.id}`} value={fm.id} disabled={!fm.active} >{fm.fullname}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>
              </Spin>

              <Spin spinning={loadingCategories}>
                <FormItem label='Preferred Billing Categories'>
                  {getFieldDecorator('category', {
                    initialValue: validator.isArray(selectedPeriodItem.category) ? selectedPeriodItem.category : [],
                    rules: [
                      { required: true, message: 'Please select at least one preferred billing category' }
                    ]
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      placeholder='Select billing categories'
                      filterOption={(input, option) => this.findCategories(input, option)}
                      disabled={currentCategories.length === 0 || loadingForm}
                    >
                      {
                        currentCategories.map((cat) => {
                          return <Option key={`${cat.category_id}`} value={cat.category_id} disabled={cat.is_sleepover}>{cat.category_name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Spin>

              { textNoAvailableCats && !loadingCategories
                ? <div className='client-funding-warning'>{textNoAvailableCats}</div>
                : null }

              <FormItem label='CM/SC' style={{margin: '0px'}}>
                {getFieldDecorator('cmsc_first_name', {
                  initialValue: selectedPeriodItem.cmsc_first_name || null,
                  rules: [
                    { min: 1 },
                    { max: 128 },
                    { whitespace: true }
                  ]
                })(
                  <Input placeholder='Name' disabled={loadingForm} />
                )}
              </FormItem>
              <FormItem style={{margin: '0px'}}>
                {getFieldDecorator('cmsc_org', {
                  initialValue: selectedPeriodItem.cmsc_org || null,
                  rules: [
                    { min: 1, message: 'CM/SC org must be between 1 and 128 characters' },
                    { max: 128, message: 'CM/SC org must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter CM/SC org' }
                  ]
                })(
                  <Input placeholder='Organization' style={{ margin: '0px' }} disabled={loadingForm} />
                )}
              </FormItem>
              <FormItem style={{margin: '0px'}}>
                {getFieldDecorator('cmsc_contact', {
                  initialValue: selectedPeriodItem.cmsc_contact || null,
                  rules: [
                    { min: 1, message: 'CM/SC contact must be between 1 and 128 characters' },
                    { max: 128, message: 'CM/SC contact must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter CM/SC contact' }
                  ]
                })(
                  <Input placeholder='Contact' style={{ margin: '0px' }} disabled={loadingForm} />
                )}
              </FormItem>
              <FormItem style={{margin: '0px'}}>
                {getFieldDecorator('cmsc_email', {
                  initialValue: selectedPeriodItem.cmsc_email || null,
                  rules: [
                    { min: 1, message: 'CM/SC email must be between 1 and 128 characters' },
                    { max: 128, message: 'CM/SC email must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter CM/SC email' }
                  ]
                })(
                  <Input placeholder='Email' style={{ margin: '0px' }} disabled={loadingForm} />
                )}
              </FormItem>

              <div style={{height: '24px'}}></div>

              <Row>
                <Col sm={12}>
                  <FormItem
                    label='Max KM (per week)'
                  >
                    {getFieldDecorator('km_max', {
                      initialValue: selectedPeriodItem.km_max === undefined ? null : selectedPeriodItem.km_max,
                      rules: [
                        { required: !isNoKMRestriction, message: 'Please enter max km' }
                      ]
                    })(
                      <Input disabled={isNoKMRestriction || loadingForm} />
                    )}
                  </FormItem>
                </Col>
                <Col offset={1} sm={11}>
                  <FormItem label='No Restriction'>
                    {getFieldDecorator('km_restrict', {
                      initialValue: selectedPeriodItem.km_restrict || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={this.handleNoKMRestriction}
                        disabled={loadingForm}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            : null }
        </SideModal>
      </Loading>
    </div>)
  }

  fetchCategories = async () => {
    const filter = {}
    filter.active = { condition: '=', value: true }
    filter.is_delete = { condition: '=', value: false }

    const categories = await fvpCategoriesService.listByPage(1, 0, filter)

    if (categories && validator.isNotEmptyArray(categories.list)) {
      this.setState({ categoriesAllList: categories.list })
    }
  }

  fetchFunders = async () => {
    const { clientId } = this.props

    const funders1 = await fvpClientFundingService.listClientAvailableFunders(clientId)
    const funders2 = await funderService.listByPage(1, 0, { active: true })

    this.setState({
      currentFunders: funders1 && validator.isNotEmptyArray(funders1) ? funders1 : [],
      funders: funders2 && validator.isNotEmptyArray(funders2.list) ? funders2.list : [],
    })
  }

  fetchFunding = async () =>  {
    if (this.hasAccess('listFvpClientFundingPeriod')) {
      this.setState({ loading: true })
      const { clientId } = this.props

      const funding = await fvpClientFundingService.listAll(clientId)

      if (funding && validator.isNotEmptyArray(funding)) {
        this.setState({ funding, currentPage: 1, loading: false })
      } else {
        this.setState({ currentPage: 1, loading: false })
      }
    }
  }

  fetchAllFundManagers = async () => {
    this.setState({ loadingManagers: true })

    const fm = await fvpFundManagerService.getAll()

    if (fm && validator.isNotEmptyArray(fm)) {
      this.setState({ fundManagers: fm, loadingManagers: false })
    } else {
      this.setState({ fundManagers: [], loadingManagers: false })
    }
  }

  // fetchFundManagers = async (funderId) => {
  //   this.setState({ loadingManagers: true })
  //   const mgrs = await fvpFunderService.getFunderManager(funderId)

  //   if (mgrs && validator.isArray(mgrs)) {
  //     this.setState({ currentFundManagers: mgrs, loadingManagers: false })
  //   } else {
  //     this.setState({ loadingManagers: false })
  //   }
  // }

  fetchFundManagers = async (fmId) => {
    const { fundManagers } = this.state

    const fms = fundManagers.filter(e => {
      if (e.id === fmId) return true
      else return e.active === true
    })

    this.setState({ currentFundManagers: fms })
  }

  fetchBillingCategories = async (funderId, cb = () => {}) => {
    this.setState({ loadingCategories: true })
    const categories = await fvpRatesService.listCategoriesByFunderId(funderId)

    if (categories && validator.isArray(categories)) {
      this.setState({ categories: categories, loadingCategories: false }, cb)
    } else {
      this.setState({ loadingCategories: false })
    }
  }

  triggerFundingModal (isFundingModal = false, item = {}) {
    const { form } = this.props
    const { resetFields } = form

    this.setState({ isFundingModal, selectedFundingItem: item }, () => {
      if (!isFundingModal) {
        resetFields()
      }
      // } else if (item.funder_id) {
      //   this.fetchBillingCategories(item.funder_id)
      // }
    })
  }

  changePage (e) {
    this.setState({ currentPage: e })
  }

  findCategories = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleSaveFunding = async () => {
    const { clientId, clientName, form } = this.props
    const { validateFields } = form
    const { isEdit, funders, currentCategories, selectedFundingItem } = this.state

    validateFields(['active', 'funder_id', 'contact_client_for_jobs'], async (errors, values) => {
      if (!errors) {
        try {
          this.setState({ loadingForm: true })

          const isEdit = selectedFundingItem && selectedFundingItem.id
          let r = null

          if (isEdit) {
            r = await fvpClientFundingService.save(selectedFundingItem.id, values)
          } else {
            values.client_id = clientId
            r = await fvpClientFundingService.add(values)
          }

          if (r && r.id) {
            this.triggerFundingModal(false)

            if (isEdit) {
              const funder = funders.find(e => e.id === values.funder_id)

              const changeHead = `Funding under Funder "${funder.fullname}" `

              let changeText = log.generateItemChanges(
                selectedFundingItem,
                values,
                ['client_first_name', 'client_id', 'client_last_name', 'created_at', 'funder_fullname', 'funder_id', 'funding_period'],
                undefined
              )

              let changeTextAdd = ''
              if (selectedFundingItem.funder_id !== values.funder_id && funder) {
                changeTextAdd += `Funder changes from "${selectedFundingItem.funder_id ? `ID ${selectedFundingItem.funder_id} - ` : ''}${selectedFundingItem.funder_fullname}" to "${funder.id ? `ID ${funder.id} - ` : ''}${funder.fullname}"`
              }

              if (changeTextAdd) {
                changeText += `${changeText ? `, ` : ''}${changeTextAdd}`
              }

              const finalChangeText = changeHead + changeText

              if (changeText) {
                log.updateClientFunding(clientId, finalChangeText)
              }

              notify.success('Saved successfully', 'Client Funding updated successfully.')
            } else {
              const funder = funders.find(e => e.id === values.funder_id)

              log.addClientFunding(clientId, `Funding under Funder "${funder.fullname}"${funder.id ? `, Funder ID ${funder.id}` : ''}, Active: ${values.active}, Contact Client For Jobs: ${values.contact_client_for_jobs} is added.`)

              notify.success('Saved successfully', 'Client Funding added successfully.')
            }

            this.fetchFunding()
          } else {
            notify.error('Unable to save', 'Unable to save client funding. Please try again later.')
          }
          this.setState({ loadingForm: false })
        } catch (e) {
          notify.error('Unable to save', 'Unable to save client funding. Please try again later.')
        }
      }
    })
  }

  // handleFunderChange = async (value) => {
  //   const { form } = this.props
  //   const { setFieldsValue } = form
  //   const that = this
  //   const { selectedFundingItem } = this.state

  //   if (selectedFundingItem && selectedFundingItem.id) {
  //     confirm({
  //       title: 'Change Funder?',
  //       content: 'This action will reset selected billing categories. Continue?',
  //       async onOk () {
  //         that.updateFunderChange(value)
  //       },
  //       onCancel () {
  //         setFieldsValue({'funder_id': selectedFundingItem.funder_id})
  //       }
  //     })
  //   } else {
  //     this.updateFunderChange(value)
  //   }
  // }

  // updateFunderChange = (value) => {
  //   const { form } = this.props
  //   const { setFieldsValue } = form

  //   this.fetchBillingCategories(value, () => {
  //     const { currentCategories = [] } = this.state
  //     const SOcategories = currentCategories.filter(e => e.is_sleepover === true)
  //     const SOcatIds = SOcategories.map(e => e.category_id)

  //     setFieldsValue({'category': SOcatIds})
  //   })
  // }

  handleDeleteFunding = async (item = {}) => {
    const { clientId } = this.props

    if (validator.isNotEmptyArray(item.funding_period)) {
      notify.error('Unable to delete', 'All funding period(s) have to removed first')
    } else if (item.id) {
      this.setState({ loadingList: true })
      try {
        const res = await fvpClientFundingService.remove(item.id)

        if (res && res.id) {
          log.deleteClientFunding(clientId, `Funder "${item.funder_id ? `ID ${item.funder_id} - ` : ''}${item.funder_fullname}" is deleted.`)
          notify.success('Deleted successfully', 'Funder deleted successfully')
          this.fetchFunding()

          this.props.setRefreshActivityLog(true)
        } else {
          notify.error('Unable to delete', 'Unable to delete funder successfully. Please try again later.')
        }
      } catch (e) {
        notify.error('Unable to delete', 'Unable to delete funder successfully. Please try again later.')
      }
      this.setState({ loadingList: false })
    } else {
      notify.error('Unable to delete', 'No funder is available')
    }
  }

  triggerPeriodModal (isPeriodModal = false, item = {}) {
    const { form } = this.props
    const { getFieldValue, resetFields, setFieldsValue } = form

    item = this.setPeriodDate(item)

    this.setState({
      isPeriodModal,
      selectedPeriodItem: item,
      isNoKMRestriction: !!item.km_restrict,
      currentFundManagers: [],
      currentCategories: []
    }, () => {
      if (!isPeriodModal) {
        resetFields()
      } else if (item.funder_id) {
        this.fetchBillingCategories(item.funder_id, () => {
          const sd1 = getFieldValue('start_date')
          const ed1 = getFieldValue('end_date')

          const date = this.getFilterCategoriesDate(sd1, ed1)
          this.filterCategories(date)
        })
        this.fetchFundManagers(item.fund_manager_id)
      }
    })
  }

  setPeriodDate (item) {
    if (item.start_date && item.end_date) {
      return item
    }

    const { funding } = this.state

    if (item.funding_id) {
      const f = funding.find(e => e.id === item.funding_id)

      if (f && f.id) {
        if (validator.isNotEmptyArray(f.funding_period)) {
          const periodList = cloneDeep(f.funding_period)

          periodList.sort((a, b) => a.start_date > b.start_date ? -1 : 1)

          const startDate = moment(periodList[0].end_date).add(1, 'day').startOf('day')
          item.start_date = startDate.clone()
          // item.end_date = startDate.clone().add(1, 'year').subtract(1, 'day').endOf('day')
          item.end_date = null
        } else {
          item.start_date = moment(DefaultDates.DATE_DEFAULT_START_DATE)
          item.end_date = moment(DefaultDates.DATE_DEFAULT_END_DATE)
          item.is_default_funder_period = false
        }
      }
    }

    return item
  }

  handleDeletePeriod = async (item = {}) => {
    const { clientId } = this.props
    const { funding } = this.state

    if (item.id) {
      this.setState({ loadingList: true })
      try {
        const r = await fvpClientFundingPeriodService.remove(item.id)

        if (r && r.id) {
          const fund = funding.find(e => e.funder_id === item.funder_id)
          const startDate = moment.isMoment(item.start_date) ? item.start_date : moment(item.start_date)
          const endDate = moment.isMoment(item.end_date) ? item.start_date : moment(item.end_date)

          log.deleteClientFundingPeriod(clientId, `Funding Period ${fund ? `under ${fund.funder_fullname} ` : ''}for ${startDate.format(dateFormat)}-${endDate.format(dateFormat)} managed by ${item.fund_manager_name} is deleted.`)

          notify.success('Deleted successfully', 'Funding Period deleted successfully')
          this.fetchFunding()

          this.props.setRefreshActivityLog(true)
        } else {
          notify.error('Unable to delete', 'Unable to delete funding period successfully. Please try again later.')
        }
      } catch (e) {
        notify.error('Unable to delete', 'Unable to delete funding period successfully. Please try again later.')
      }
      this.setState({ loadingList: false })
    } else {
      notify.error('Unable to delete', 'No period is available')
    }
  }

  handleSavePeriod = async () => {
    const { clientId, clientName, form } = this.props
    const { validateFields } = form
    const { isEdit, categoriesAllList, currentCategories, currentFundManagers, funding, fundManagers, selectedPeriodItem, funders } = this.state

    validateFields(['start_date', 'end_date', 'is_default_funder_period', 'fund_manager_id', 'cmsc_first_name', 'cmsc_last_name', 'cmsc_org', 'cmsc_contact', 'cmsc_email', 'km_max', 'km_restrict', 'invoice_note', 'category'], async (errors, values) => {
      if (!errors) {
        try {
          this.setState({ loadingForm: true })

          const isEdit = selectedPeriodItem && selectedPeriodItem.id
          let r = null

          if (this.validateDateInfinity(values.start_date, 'start')) {
            values.start_date = DefaultDates.DATE_DEFAULT_START_DATE
          }

          if (this.validateDateInfinity(values.end_date, 'end')) {
            values.end_date = DefaultDates.DATE_DEFAULT_END_DATE
          }

          if (values.km_restrict === true) {
            values.km_max = null
          }

          if (isEdit) {
            r = await fvpClientFundingPeriodService.save(selectedPeriodItem.id, values)
          } else {
            values.funding_id = selectedPeriodItem.funding_id
            r = await fvpClientFundingPeriodService.add(values)
          }

          if (r && r.id) {
            this.triggerPeriodModal(false)

            const fund = funding.find(e => e.funder_id === selectedPeriodItem.funder_id)

            if (isEdit) {
              // prevCat refers to categoriesAllList in order to get the correct cat name for previous records
              const prevCat = validator.isNotEmptyArray(selectedPeriodItem.category) ? categoriesAllList.slice().filter(e => selectedPeriodItem.category.findIndex(f => f === e.id) > -1) : null
              const currCat = validator.isNotEmptyArray(values.category) ? currentCategories.slice().filter(e =>  values.category.findIndex(f => f === e.category_id) > -1) : null

              const prevCatText = prevCat ? prevCat.map(e => e.name).join(', ') : ''
              const currCatText = currCat ? currCat.map(e => e.category_name).join(', ') : ''

              const changeHead = `Funding Period${fund ? ` under ${fund.funder_fullname} ` : ''}${selectedPeriodItem && selectedPeriodItem.invoice_note ? ` (Invoice Note: ${selectedPeriodItem.invoice_note})` : ''} - `

              let changeText = log.generateItemChanges(
                selectedPeriodItem,
                values,
                ['fund_manager_id', 'fund_manager_name', 'funder_id', 'funder_fullname', 'funding_id', 'is_default_funder_period', 'invoice_note', 'category'],
                [
                  { key: 'cmsc_first_name', label: 'CM/SC First Name' },
                  { key: 'cmsc_last_name', label: 'CM/SC Last Name' },
                  { key: 'cmsc_org', label: 'CM/SC Org' },
                  { key: 'cmsc_contact', label: 'CM/SC Contact' },
                  { key: 'cmsc_email', label: 'CM/SC Email' },
                  { key: 'km_restrict', label: 'KM No Restriction' },
                  { key: 'km_max', label: 'Max KM (per week)' }
                ]
              )

              let changeTextAdd = ''
              if (selectedPeriodItem.fund_manager_id !== values.fund_manager_id) {
                const prevFM = validator.isNotEmptyArray(fundManagers) && selectedPeriodItem.fund_manager_id ? fundManagers.find(e => e.id === selectedPeriodItem.fund_manager_id) : null
                const currFM = validator.isNotEmptyArray(fundManagers) && values.fund_manager_id ? fundManagers.find(e => e.id === values.fund_manager_id) : null

                changeTextAdd += `Fund Manager from "${prevFM.fullname || ''}" to "${currFM.fullname || ''}"`
              }

              if (prevCatText !== currCatText) {
                changeTextAdd += `Preferred Category changes from "${prevCatText}" to "${currCatText}"`
              }

              if (changeTextAdd) {
                changeText += `${changeText ? `, ` : ''}${changeTextAdd}`
              }

              // finalChangeText appeneds change header before changeText, but to decide whether the log is recorded or not, it still depends on value of changeText
              const finalChangeText = changeHead + changeText

              if (changeText) {
                log.updateClientFundingPeriod(clientId, finalChangeText)
                trigger.updateClientFundingPeriod(clientId, clientName, finalChangeText)
              }

              notify.success('Saved successfully', 'Client Funding Period updated successfully.')
            } else {
              const startDate = moment.isMoment(values.start_date) ? values.start_date : moment(values.start_date)
              const endDate = moment.isMoment(values.end_date) ? values.end_date : moment(values.end_date)
              const fundManager = values.fund_manager_id ? currentFundManagers.find(e => e.fund_manager_id === values.fund_manager_id) : null
              const category = validator.isNotEmptyArray(values.category) ? currentCategories.filter(e => values.category.findIndex(f => f === e.category_id) > -1) : null
              let catText = category ? category.map(e => e.category_name).join(', ') : ''

              const newLogText = `${fundManager ? `managed by ${fundManager.funder_manager_fullname}` : ''};`
                + `${values.cmsc_first_name ? ` CM/SC Name '${values.cmsc_first_name}';` : ''}`
                + `${values.cmsc_org ? ` CM/SC Organisation '${values.cmsc_first_name}';` : ''}`
                + `${values.cmsc_contact ? ` CM/SC Contact '${values.cmsc_contact}';` : ''}`
                + `${values.cmsc_email ? ` CM/SC Email '${values.cmsc_email}';` : ''}`
                + `${values.km_restrict ? ' No KM restriction' : ` Max KM restriction '${values.km_max} KM'`};`
                + `${values.invoice_note ? ` Invoice Note: "${values.invoice_note}"; ` : ''}`
                + ` Billing Categories: ${catText};`

              const changeText = `Funding Period${fund ? ` under ${fund.funder_fullname}` : ''} for ${startDate.format(dateFormat)}-${endDate.format(dateFormat)} ${newLogText} is added.`

              log.addClientFundingPeriod(clientId, changeText)

              trigger.updateClientFundingPeriod(clientId, clientName, changeText)

              notify.success('Saved successfully', 'Client Funding Period added successfully.')
            }

            this.fetchFunding()
          } else {
            notify.error('Unable to save', 'Unable to save client funding period. Check Period Date Setting and try again later.')
          }
        } catch (e) {
          notify.error('Unable to save', 'Unable to save client funding period. Check Period Date Setting and try again later.')
        }

        this.setState({ loadingForm: false })
      }
    })
  }

  handleDateChange = (rule, value, callback) => {
    const { form } = this.props

    const overlapping = this.isDateOverlapping(value)
    const startend = this.isDatePeriod(value, rule.field)
    this.isDateDefaultFunder(value, rule.field)

    const sd1 = rule.field === 'start_date' ? value.clone() : form.getFieldValue('start_date')
    const ed1 = rule.field === 'end_date' ? value.clone() : form.getFieldValue('end_date')

    const date = this.getFilterCategoriesDate(sd1, ed1)
    this.filterCategories(date)

    if (overlapping.result) {
      try {
        throw new Error(`Date is overlapping with ${overlapping.invoiceNotes ? `${overlapping.invoiceNotes} `: ''}${overlapping.period}`)
      } catch (err) {
        callback(err)
      }
    } else if (startend.isAfter) {
      try {
        throw new Error(`Period End Date must be after Start Date.`)
      } catch (err) {
        callback(err)
      }
    } else {
      callback()
    }
  }

  isDateOverlapping (value) {
    const { funding, selectedPeriodItem } = this.state
    const period = funding.find(e => e.id === selectedPeriodItem.funding_id)

    if (period && period.id) {
      if (validator.isNotEmptyArray(period.funding_period)) {
        for (let i = 0; i < period.funding_period.length; i++) {
          const p = period.funding_period[i]

          if (p.id && p.active) {
            const isBetween = moment(value).isBetween(p.start_date, p.end_date)

            // exclude the current editing period
            if (isBetween && p.id !== selectedPeriodItem.id) {
              return { result: true, period: `${moment(p.start_date).format('DD/MM/YYYY')} - ${moment(p.end_date).format('DD/MM/YYYY')}`, invoiceNotes: `${p.invoice_note || ''}` }
            }
          }
        }
      }
    }
    return { result: false }
  }

  isDatePeriod (value, field) {
    const { form } = this.props
    let isAfter = false

    if (field) {
      const fields = form.getFieldsValue()
      if (field === 'start_date') {
        const endDate = fields['end_date']

        if (endDate) {
          isAfter = moment(value).isAfter(endDate)
        }
      } else if (field === 'end_date') {
        const startDate = fields['start_date']

        if (startDate) {
          isAfter = moment(startDate).isAfter(value)
        }
      }
    }

    return { isAfter }
  }

  isDateDefaultFunder (value, field) {
    /**
     * must check the period is whether the funder default period (i.e. service agreement date) and assign the flag correctly
     * becos the categories fetched is affected by current rate set period.
     * general funder default period is 100% out of any available rate set period (year 1111 - 9999), must apply the flag in order to load the categories correctly
     */
    const { form } = this.props
    const { selectedPeriodItem } = this.state
    let isAfter = false

    const fsd = moment(selectedPeriodItem.funder_start_date)
    const fed = moment(selectedPeriodItem.funder_end_date)

    if (field) {
      const fields = form.getFieldsValue()
      if (field === 'start_date') {
        const endDate = fields['end_date']

        if (endDate) {
          if (fsd.format(dateFormat) === value.format(dateFormat) && fed.format(dateFormat) === endDate.format(dateFormat)) {
            form.setFieldsValue({'is_default_funder_period': true})
          } else {
            form.setFieldsValue({'is_default_funder_period': false})
          }
        }
      } else if (field === 'end_date') {
        const startDate = fields['start_date']

        if (startDate) {
          if (fsd.format(dateFormat) === startDate.format(dateFormat) && fed.format(dateFormat) === value.format(dateFormat)) {
            form.setFieldsValue({'is_default_funder_period': true})
          } else {
            form.setFieldsValue({'is_default_funder_period': false})
          }
        }
      }
    }
  }

  handleNoKMRestriction = (e) => {
    const { form } = this.props

    if (e) {
      form.setFieldsValue({ km_max: null })
    }

    this.setState({ isNoKMRestriction: e })
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterCategories = (startDate) => {
    const { form } = this.props
    const { getFieldValue, setFieldsValue } = form
    const { categories = [] } = this.state

    const currentCats = categories.slice().filter(e => {
      const std = moment(e.start_date)
      const etd = moment(e.end_date)

      return startDate.isSameOrAfter(std) && startDate.isSameOrBefore(etd)
    })

    const SOcategories = currentCats.slice().filter(e => {
      return e.is_sleepover === true
    })

    const selectedCategories = getFieldValue('category')

    const SOcatIds = SOcategories.map(e => e.category_id)

    if (validator.isNotEmptyArray(selectedCategories)) {
      // find if SOcatIds is inside the current selected categories in dropdown.
      // if available, keep the entries as it is. else, replace with all available SO categories

      const isAvailable = selectedCategories.filter(e => {
        return SOcatIds.findIndex(f => parseInt(f) === parseInt(e)) > -1
      })

      if (!validator.isNotEmptyArray(isAvailable)) {
        setFieldsValue({'category': SOcatIds})
      }
    } else {
      setFieldsValue({'category': SOcatIds})
    }

    let textNoAvailableCats = ''

    if (!validator.isNotEmptyArray(SOcatIds)) {
      textNoAvailableCats = MSG_NO_CATEGORIES_AVAILABLE
    }

    this.setState({ currentCategories: currentCats, textNoAvailableCats })
  }

  getFilterCategoriesDate (sd1, ed1) {
    const isInfinitySD = this.validateDateInfinity(sd1, 'start')
    const isInfinityED = this.validateDateInfinity(ed1, 'end')
    let date = moment.isMoment(sd1) ? sd1.clone() : moment(sd1)

    if (isInfinitySD && isInfinityED) {
      date = moment(new Date())
    } else if (isInfinitySD) {
      date = moment.isMoment(ed1) ? ed1.clone() : moment(ed1)
    } else if (isInfinityED) {
      date = moment.isMoment(sd1) ? sd1.clone() : moment(sd1)
    }

    return date
  }

  validateDateInfinity (date = new Date(), type = 'start') {
    const d = moment.isMoment(date) ? date.clone() : moment(date)
    const dy = d.format('YYYY')

    if (type === 'start') {
      return dy === '1111'
    } else if (type === 'end') {
      return dy === '9999'
    }

    return false
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog,
  setRefreshFiles
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientFunding))

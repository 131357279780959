import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchPendingEmployeeJobs } from '../../../states/actions/pendingEmployeeJob'
import { fetchTotalPending } from '../../../states/actions'
import { JobMenu } from '../../../constants'
import { clientService, employeeService, funderService, settingBillingRateService, authService } from '../../../services'
import formatter from '../../../util/formatter'
import moment from 'moment'
import AddJobModal from '../AddJobModal'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'
import { jobLegacyURL } from '../../../config'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 20

export class PendingIndividual extends Component {
  constructor (props) {
    super(props)
    const { jobs: { list, total }, loading } = props
    this.state = {
      currentStep: 0,
      jobIds: [],
      billingCategory: [],
      currentPage: 1,
      clients: [],
      employees: [],
      funders: [],
      filter: {},
      list,
      loading,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      sort: {},
      suburbs: [],
      spinLoading: false,
      total,
      disableFunder: true,
      jobType: ''
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobs: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const currUrl = this.props.match.url

    const { currentPage, filter, loading, searchText, sort } = this.state
    if (currUrl === `${jobLegacyURL}/pending-individual`) {
      this.fetchPendingEmployeeJobs({ currentPage, filter, loading, searchText, sort, jobType: 'single' })
      this.setState({ jobType: 'single' })
    } else {
      this.fetchPendingEmployeeJobs({ currentPage, filter, loading, searchText, sort, jobType: 'recurring' })
      this.setState({ jobType: 'recurring' })
    }

    this.fetchClients()
    this.fetchEmployees()

    this.props.fetchTotalPending()
  }

  /** Search by date currently only search using job_start_date */
  /* onSearchName (value) {
    const { currentPage, filter, loading, sort, jobType } = this.state
    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$or = []

        for (let i = 0; i < words.length; i++) {
          filter.$or.push({ first_name: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ last_name: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ suburb: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ job_start_day: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ tasks: { condition: 'ilike', value: `%${words[i]}%` } })
          filter.$or.push({ string_start_date: { condition: 'ilike', value: `%${words[i]}%` } })
        }
        console.log('filters:', filter)
      }
    } else {
      if (Array.isArray(filter.$or)) {
        delete filter.$or
      }
    }
    this.fetchPendingEmployeeJobs({ currentPage, filter, loading, searchText: (filter.$or ? '' : value), sort, jobType })
    this.setState({ searchText: value })
  } */

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, loading, sort, jobType } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
        console.log('filters:', filter)
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchPendingEmployeeJobs({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort, jobType })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectSuburb (value) {
    const { currentPage, filter, loading, searchText, sort, jobType } = this.state
    filter.location = value

    if (value === '') {
      delete filter.location
    }

    this.fetchPendingEmployeeJobs({ currentPage, filter, loading, searchText, sort, jobType })
    this.setState({ filter })
  }

  onSelectDates (dates) {
    console.log(dates)
    const { currentPage, filter, loading, searchText, sort, jobType } = this.state

    if (dates.length === 0) {
      delete filter['$date']
    } else {
      filter['$date'] = {
        fromDate: {
          $gte: dates[0],
          $lte: dates[1]
        }
      }
    }

    this.fetchPendingEmployeeJobs({ currentPage, filter, loading, searchText, sort, jobType })
    this.setState({ filter })
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  handleNext = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep === 0 ? 1 : 0 })
  }

  handleCancel = () => {
    this.setState({ showJobModal: false })
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]} ${option.props.children[3].props.children}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findEmployees = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]} ${option.props.children[3].props.children}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onSelectClient = async (value, option) => {
    this.setState({ spinLoading: true })
    const funders = await clientService.getClientFunders(value)
    console.log('funders', funders)
    this.setState({ selectedClient: `${option.props.children[0]} ${option.props.children[2]}`, funders: funders, spinLoading: false, disableFunder: false })
  }

  onSelectEmployee = (value, option) => {
    // console.log(value, `${option.props.children[0]} ${option.props.children[2]}`)
    this.setState({ selectedEmployee: `${option.props.children[0]} ${option.props.children[2]}` })
  }

  onClickRecurring = () => {
    this.setState({ showEndDate: false })
  }

  onClickSingle = () => {
    this.setState({ showEndDate: true })
  }

  onChangeDate = (e) => {
    const dateInfo = e
    const dayIn = dateInfo.format('dddd')
    this.setState({ selectedDay: dayIn })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, showJobModal, total, searching, jobType } = this.state
    const { totalPending } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobMenu} backLink='/' countData={totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={jobType === 'recurring' ? 'Pending Recurring Jobs (V1)' : 'Pending Individual Jobs (V1)'}>
            {
              this.hasAccess('createJob') ? <div className='btn' onClick={() => this.addJob()}>
              Add Job
              </div>
                : null
            }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Client, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              {/* <Col lg={5}>
                <ControlLabel>Suburb</ControlLabel>
                <Select showSearch allowClear
                  style={{ width: '100%' }}
                  placeholder='Suburb'
                  optionFilterProp='children'
                  notFoundContent='Not found'
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => this.onSelectSuburb(e)} >
                  { suburbs.map((d, index) => <Option key={d.value}>{d.name}</Option>) }
                </Select>
              </Col> */}
            </Row>
          </Page.Filter>

          <div className='jobs'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, base_job_id, job_start_date, job_end_date, first_name, last_name, emp_firstname,
                  emp_lastname, suburb, tasks, fund_fullname, emergency, emergency_invoice, emergency_pay, client_id, employee_id, funder_id,
                  first_job_date, last_job_date, interval, job_start_time, job_end_time, cancellation_penalty, sleepover,
                  client_leave_id, client_leave_start, client_leave_end, employee_leave_id, employee_leave_start, employee_leave_end, holiday_date, holiday_name } = item

                return (
                  <div className={`list-item ${holiday_date ? 'list-item-highlight' : ''}`} key={id}>
                    { emergency_invoice && emergency_pay
                      ? <div className='list-item-emergency' style={{ borderTop: '16px solid #ff526ebb', borderLeft: '16px solid #ff526ebb' }}><span>EE</span></div>
                      : emergency_invoice
                        ? <div className='list-item-emergency'><span>EI</span></div>
                        : emergency_pay
                          ? <div className='list-item-emergency' style={{ borderTop: '16px solid #ea3471bb', borderLeft: '16px solid #ea3471bb' }}><span>EP</span></div>
                          : null
                    }
                    { cancellation_penalty ? <div className='list-item-late'><span>LC</span></div> : null }
                    { base_job_id > 0 ? <div className='list-item-recur'><span>R</span></div> : null }

                    {
                      jobType === 'single'

                        ? <div className='single-job-listing'>
                          <Row>
                            <Col lg={22}>
                              <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={5}>
                                      <div className='subtitle'>Client</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { client_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={6}>
                                      <div className='subtitle'>Employee</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'>{emp_firstname} {emp_lastname} { employee_id ? <a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a> : '' } </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={5}>
                                      <div className='subtitle'>Funder</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'>{fund_fullname} <a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: 15 }}>
                                <Col md={8}>
                                  <div className='detail'><Icon type='calendar' /> { formatter.toShortDate(job_start_date)} - { formatter.toShortDate(job_end_date)} { holiday_date ? <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } <span className='time-sum'>{moment(job_start_date).format('dddd')}</span></div>
                                  {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {moment(created_at).fromNow()}</div> */}
                                </Col>
                                <Col md={8}>
                                  <div className='detail'><Icon type='clock-circle' /> { formatter.toShortTime(job_start_date)} - { formatter.toShortTime(job_end_date)} <span className='time-sum'>{formatter.toDecimal(moment.duration(moment(job_end_date).diff(moment(job_start_date))).asHours())} hours</span></div>
                                </Col>
                                <Col md={6}>
                                  <div className='detail'><Icon type='environment' /> {suburb}</div>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: 5 }}>
                                <Col>
                                  <div className='detail-normal'>{ tasks }</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className='action-icon'>
                              <Link to={`${jobLegacyURL}/pending-individual/${id}`}>

                                { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                                  <Tooltip mouseLeaveDelay={0} title='Manage job'>
                                    <Icon type='form' />
                                  </Tooltip>
                                </div> : null }

                              </Link>
                            </Col>
                          </Row>
                        </div>

                        : <div className='recurring-job-listing'>
                          <Row>
                            <Col lg={22}>
                              <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={5}>
                                      <div className='subtitle'>Client</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'>{first_name} {last_name} <a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={6}>
                                      <div className='subtitle'>Employee</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'>{emp_firstname} {emp_lastname} <a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col lg={5}>
                                      <div className='subtitle'>Funder</div>
                                    </Col>
                                    <Col lg={14}>
                                      <div className='name'>{fund_fullname} <a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'><Icon type='eye' /></a></div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: 15 }}>
                                <Col md={8}>
                                  <div className='detail'><Icon type='calendar' /> { formatter.toShortDate(first_job_date)} - { last_job_date !== null ? formatter.toShortDate(last_job_date) : '∞'} <span className='time-sum'>{formatter.capitalize(interval + 'ly')}</span>{ interval === 'weekly' || 'fortnight' ? <span className='time-sum'>{moment(first_job_date).format('dddd')}</span> : null }</div>
                                  {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {moment(created_at).fromNow()}</div> */}
                                </Col>
                                <Col md={8}>
                                  <div className='detail'><Icon type='clock-circle' />
                                    { formatter.toShortTime(job_start_time)} - { formatter.toShortTime(job_end_time)}
                                    { sleepover ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(moment.duration(moment(job_end_time).diff(moment(job_start_time))).asHours())} hours</span> }
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className='detail'><Icon type='environment' /> {suburb}</div>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: 5 }}>
                                <Col>
                                  <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : null }</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className='action-icon'>
                              <Link to={`/v1/jobs/pending-recurring/${id}`}>

                                { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                                  <Tooltip mouseLeaveDelay={0} title='Manage job'>
                                    <Icon type='form' />
                                  </Tooltip>
                                </div> : null }

                              </Link>
                            </Col>
                          </Row>
                        </div>
                    }

                  </div>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <AddJobModal visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, jobType } = this.state
    this.fetchPendingEmployeeJobs({ currentPage, filter, searchText, sort, jobType })
  }

  fetchPendingEmployeeJobs = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, jobType }) => {
    try {
      if (Array.isArray(filter.$and) && filter.$and.length) {
        searchText = ''
      }
      const { fetchPendingEmployeeJobs } = this.props
      this.setState({ currentPage })
      fetchPendingEmployeeJobs({ loading, currentPage, pageSize, filter, sort, searchText, jobType })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
    }
  }

  fetchClients = async () => {
    try {
      const clients = await clientService.listByPage(1, 100)
      this.setState({ clients: clients.list })
      // console.log('listclie', clients)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }

  fetchEmployees = async () => {
    try {
      const employees = await employeeService.listByPage(1, 100)
      this.setState({ employees: employees.list })
      // console.log('emplist', employees)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  handleFunderChange = async (value) => {
    console.log(`I will change to ${value}`)

    const { item } = await funderService.get(value)
    const funderRateSet = item.rate_set
    console.log('rateset', funderRateSet)
    const billRateInfo = await settingBillingRateService.get(funderRateSet)
    console.log('yay', billRateInfo)
    const billRateCategory = billRateInfo.categories
    this.setState({ billingCategory: billRateCategory, billingRateId: item.rate_set, billRateInfo })
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history } = this.props
        const clientId = values.client_id
        const funderId = values.funder_id
        this.setState({ loading: true })

        history.replace(`/v1/jobs/new/client/${clientId}/funder/${funderId}`)
        this.setState({ loading: false })
      }
    })
  }
}

const mapDispatchToProps = {
  fetchPendingEmployeeJobs,
  fetchTotalPending
}

const mapStateToProps = (state) => {
  return { ...state.PendingEmployeeJob, ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PendingIndividual))

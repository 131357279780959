export default [
  {
    name: 'Jobs',
    icon: 'thunderbolt',
    link: '/jobs/list/single/future',
    permission: 'listJobs'
  },
  {
    name: 'Clients',
    icon: 'usergroup-add',
    link: '/clients',
    permission: 'listClients'
  },
  {
    name: 'Funders',
    icon: 'team',
    link: '/funders',
    permission: 'listFunders'
  },
  {
    name: 'Employees',
    icon: 'user',
    link: '/employees',
    permission: 'listEmployees'
  },
  {
    name: 'Full Map',
    icon: 'global',
    link: '/map',
    permission: 'listMap'
  },
  {
    name: 'Payroll',
    icon: 'dollar',
    link: '/payrolls',
    permission: 'listPayrolls'
  },
  {
    name: 'Settings',
    icon: 'setting',
    link: '/settings',
    permission: 'listSettings'
  }
]

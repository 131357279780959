import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
// const url = '/api/report'
// const urlMulti = '/api/report-multi'
const urlp = '/private/api/report'

export default {
  getListByDate (type, from, to, user) {
    return request(`${urlp}/${type}/period/${from}/${to}${user ? `/${user}` : ''}`)
  },
  getListByDateViewPage (type, view, from, to, page, size) {
    return request(`${urlp}/${type}/period-page/${view}/${from}/${to}/${page}/${size}`)
  },
  getListByPage (type, page, size, filter = {}) {
    return request(`${urlp}/${type}/list/${page}/${size}${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  getListByPageView (type, view, page, size) {
    return request(`${urlp}/${type}/list/${view}/${page}/${size}`)
  },
  getListByAll (type, view) {
    return request(`${urlp}/${type}/all${view ? `/${view}` : ''}`)
  },
  exportReport (type, value) {
    return requestFile(`${urlp}/${type}/export`, {
      method: 'POST',
      headers,
      body: JSON.stringify(value)
    })
  },
//   // ---------
//   get (type) {
//     return request(`${url}/${type}`)
//   },
//   getByDate (action, from, to) {
//     return request(`${url}/${action}/${from}/${to}`)
//   },
//   getActionJobs (page, size, filter, sorting, text) {
//     return request(`${url}/action/jobs/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
//   },
//   getClashJobs (page, size) {
//     return request(`${url}/job-clash/page/${page}/size/${size}`)
//   },
//   getClashJobsImproved () {
//     return request(`${url}/job-clash/improved`)
//   },
//   getBackToBackJobs (page, size) {
//     return request(`${url}/job-back-to-back/page/${page}/size/${size}`)
//   },
//   // getMultiClientRecurringJob (page, size, filter, sorting, text) {
//   //   return request(`${url}/multiclient/basejob/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
//   // },
//   // getMultiClientSingleJob (page, size, filter, sorting, text) {
//   //   return request(`${url}/multiclient/job/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
//   // }
//   getMultiClientRecurringJob (from, to) {
//     return request(`${url}/multiclient/basejob/${from}/${to}`)
//   },
//   getMultiClientSingleJob (from, to) {
//     return request(`${url}/multiclient/job/${from}/${to}`)
//   },
//   getMultiByDate (action, from, to) {
//     return request(`${urlMulti}/${action}/${from}/${to}`)
//   },
}

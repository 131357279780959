import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchClients } from '../../../states/actions/client'
import { ClientMenu, ExportType } from '../../../constants'
import { apiHostname } from '../../../config'
import { exportFile, formatter, queryString } from '../../../util'
import ClientServices from '../../../services/client'

// UI
import { Button, Checkbox, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'

const pageSize = 40
const Option = Select.Option

export class Client extends Component {
  constructor (props) {
    super(props)
    const { clients: { list, total }, loading } = props
    this.state = {
      currentPage: 1,
      filter: { active: true },
      isGenerating: false,
      isShowExportModal: false,
      isCheckProfile: true,
      isCheckFile: true,
      isCheckCarePlan: true,
      isCheckMsgShow: false,
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.handleSelectStatus = this.handleSelectStatus.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clients: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, loading, searchText, sort })
  }

  onSearchName (value) {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true })

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { email: { condition: 'ilike', value: `%${words[i]}%` } },
              { mobile_phone: { condition: 'ilike', value: `%${words[i]}%` } },
              { phone: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_email_1: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_name_1: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_phone_1: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_email_2: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_name_2: { condition: 'ilike', value: `%${words[i]}%` } },
              { second_contact_phone_2: { condition: 'ilike', value: `%${words[i]}%` } },
              { authorize_rep_email: { condition: 'ilike', value: `%${words[i]}%` } },
              { authorize_rep_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { authorize_rep_phone: { condition: 'ilike', value: `%${words[i]}%` } },
              { address_text: { condition: 'ilike', value: `%${words[i]}%` } },
              { postcode_text: { condition: 'ilike', value: `%${words[i]}%` } },
              { preferred_name: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (value && value.length > 0) {
        filter.$and = []
        const words = value
        filter.$and.push({
          $or: [
            { first_name: { condition: 'ilike', value: `%${words}%` } },
            { last_name: { condition: 'ilike', value: `%${words}%` } },
            { acc_ref: { condition: 'ilike', value: `%${words}%` } },
            { suburb: { condition: 'ilike', value: `%${words}%` } },
            { email: { condition: 'ilike', value: `%${words}%` } },
            { mobile_phone: { condition: 'ilike', value: `%${words}%` } },
            { phone: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_email_1: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_name_1: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_phone_1: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_email_2: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_name_2: { condition: 'ilike', value: `%${words}%` } },
            { second_contact_phone_2: { condition: 'ilike', value: `%${words}%` } },
            { authorize_rep_email: { condition: 'ilike', value: `%${words}%` } },
            { authorize_rep_name: { condition: 'ilike', value: `%${words}%` } },
            { authorize_rep_phone: { condition: 'ilike', value: `%${words}%` } },
            { address_text: { condition: 'ilike', value: `%${words}%` } },
            { postcode_text: { condition: 'ilike', value: `%${words}%` } },
            { preferred_name: { condition: 'ilike', value: `%${words}%` } },
          ]
        })
      } else if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClients({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  handleCheckboxClick (e, { isCheckProfile, isCheckFile, isCheckCarePlan }) {
    const check = e.target.checked
    this.setState({
      isCheckProfile: isCheckProfile === undefined ? this.state.isCheckProfile : check,
      isCheckFile: isCheckFile === undefined ? this.state.isCheckFile : check,
      isCheckCarePlan: isCheckCarePlan === undefined ? this.state.isCheckCarePlan : check,
    }, () => {
      const { isCheckProfile, isCheckFile, isCheckCarePlan } = this.state
      if (!(isCheckProfile || isCheckFile || isCheckCarePlan)) {
        this.setState({ isCheckMsgShow: true })
      } else {
        this.setState({ isCheckMsgShow: false })
      }
    })
  }

  handleExportModal (isShowExportModal) {
    this.setState({ isShowExportModal })
  }

  // handleSelectStatus (value) {
  //   const { currentPage, filter, searchText, sort } = this.state

  //   if (value === 'active' || value === 'inactive') {
  //     filter.active = (value === 'active')
  //     filter.is_exited = false
  //   } else if (value === 'exited') {
  //     filter.active = false
  //     filter.is_exited = true
  //   }

  //   this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
  //   this.setState({ filter })
  // }

  handleSelectStatus (value) {
    const { currentPage, filter, searchText, sort } = this.state

    if (value === 'active') {
      filter.active = (value === 'active')
      filter.is_exited = false
    } else if (value === 'inactive') {
      filter.active = false
      // filter.is_exited = true
    }

    this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  preCheckExport () {
    const { isCheckMsgShow } = this.state

    if (!isCheckMsgShow) {
      this.export()
    }
  }

  async export () {
    const { isCheckProfile, isCheckFile, isCheckCarePlan } = this.state
    const data = { export_profile: isCheckProfile, export_file: isCheckFile, export_care_plan: isCheckCarePlan }

    try {
      this.setState({ isGenerating: true })
      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_CLIENT, data)
      setTimeout(() => {
        this.setState({ isGenerating: false, isShowExportModal: false })
      }, 10000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get participant export successfully. Please try again later.')
      this.setState({ isGenerating: false, isShowExportModal: false })
    }
  }

  render () {
    const {
      currentPage, isGenerating, isShowExportModal, isCheckProfile, isCheckFile, isCheckCarePlan, isCheckMsgShow, list, total,
      searching
    } = this.state
    const { loading } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ClientMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Clients'>
            <Link to='/clients/add'>
              { this.hasAccess('createClient')
                ? <div className='btn'>Add</div>
                : null }
            </Link>
            { this.hasAccess('listClients')
              ? <div className='btn' onClick={() => this.handleExportModal(true)}>
                { isGenerating
                  ? <img src='/icon/button-loading.svg' alt='loading' />
                  : 'Export'}
              </div>
              : null }
            {/* { this.hasAccess('listClients')
              ? <div className='btn' onClick={() => this.export()}>
                { isGenerating
                  ? <img src='/icon/button-loading.svg' alt='loading' />
                  : 'Export'}
              </div>
              : null } */}
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col sm={24} md={24} lg={16} xl={14} xxl={12}>
                <ControlLabel>Name, Acc Ref, Address, Email, Phone, Secondary Contact, Auth Rep, Preferred Name</ControlLabel>
                <SearchInput placeholder='Search clients' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Status</ControlLabel>
                <Select defaultValue='active' style={{ width: 120 }} onChange={this.handleSelectStatus}>
                  <Option value='active'>Active</Option>
                  <Option value='inactive'>Inactive</Option>
                  {/* <Option value='exited'>Exited</Option> */}
                </Select>
              </Col>
            </Row>
          </Page.Filter>

          <div className='clients'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, active, first_name: firstName, last_name: lastName, gender, suburb, acc_ref: clientId, leave_id: leaveId, leave_start_date: leaveStart, leave_end_date: leaveEnd, leave_is_ufn: isUFN } = item

                return (
                  <Link to={`/clients/${id}/info`} key={id}>
                    <div className={`list-item ${active ? '' : 'list-item-dim'}`}>
                      <Row className='list-content'>
                        <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/' + (gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /></Col>

                        <Col md={18}>
                          {/* <div className='name'>{firstName} {lastName} { leaveId ? <Tooltip title={`Leave ${formatter.toShortDate(leaveStart)} - ${formatter.toShortDate(leaveEnd)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null }</div> */}
                          <div className='name'>{firstName} {lastName}</div>
                          { leaveId ? <div className='leave'><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(leaveStart)} - ${isUFN ? `UFN` : formatter.toShortDate(leaveEnd)}`}</div> : null }
                          <div style={{ fontSize: '8pt' }}>{clientId}</div>
                          <div style={{ marginTop: '3px', fontSize: '8pt', color: '#888' }}>{suburb}</div>
                        </Col>

                      </Row>
                    </div>
                  </Link>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} clients`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          { isShowExportModal
            ? <Modal
              width='450px'
              title='Select Client Export Items'
              visible={isShowExportModal}
              onCancel={() => this.handleExportModal(false)}
              footer={[
                <div>
                  <Button key='close' ghost feedback={isGenerating} onClick={() => this.handleExportModal(false)}>Cancel</Button>
                  <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
                </div>
              ]}
            >
              <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>

                  <span>
                    <Checkbox
                      checked={isCheckProfile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckProfile: true })}
                    />
                    <span className='checkbox-text'>Client Profile Export</span>
                  </span>

                  <span>
                    <Checkbox
                      checked={isCheckFile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckFile: true })}
                    />
                    <span className='checkbox-text'>Client Files Export</span>
                  </span>

                  <span>
                    <Checkbox
                      checked={isCheckCarePlan}
                      onClick={f => this.handleCheckboxClick(f, { isCheckCarePlan: true })}
                    />
                    <span className='checkbox-text'>Client Care Plan Export</span>
                  </span>

                  <div className='checkbox-warning-text'>{isCheckMsgShow ? `Please select at least one item.` : ''}</div>
                </Form>
              </Spin>
            </Modal>
            : null }
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, searchText, sort })
  }

  fetchClients = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchClients } = this.props
      this.setState({ currentPage })
      fetchClients({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Client)

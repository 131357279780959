import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'

import Categories from './Categories'
import FundManager from './FundManager'
import FundingType from './FundingType'
import RateSet from './RateSet'
import TaxCodes from './TaxCodes'

const TabList = [
  { tabId: 1, path: '/funding-type' },
  { tabId: 2, path: '/rate-set' },
  { tabId: 3, path: '/categories' },
  { tabId: 4, path: '/tax-codes' },
  { tabId: 5, path: '/fund-manager' },
]

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

// const settingTitle = 'Profile'
// const settingType = 'gender'

export class SettingFunder extends Component {
  constructor (props) {
    super(props)
    const { type } = props.match.params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      activeTab: tab && tab.tabId ? String(tab.tabId) : '1'
    }
  }

  componentDidMount () {
    const { type } = this.props.match.params

    if (type) {
      const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      this.setState({ activeTab: cTab})
    } else {
      this.props.history.replace(`/settings/funders${TabList[0].path}`)
    }
  }

  onTabChange = (key) => {
    const tab = TabList.find(e => e.tabId === parseInt(key))
    const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
    const cPath = tab && tab.path ? tab.path : ''
    if (cTab) {
      this.setState({ activeTab: cTab })
      this.props.history.replace(`/settings/funders${cPath}`)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addClientDifficulty, addGender, addHeight, addLanguage, addRelationship, addSkill, addVisaStatus, addCustomIdentifier, activeTab, moduleKey } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={'Funder'} />

          <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
            { this.hasAccess('listFvpFundingTypes')
              ? <TabPane tab='Funding Types' key='1'>
                <FundingType />
              </TabPane>
              : null }

            { this.hasAccess('listFvpFundingTaxCodes')
              ? <TabPane tab='Tax Codes' key='4'>
                <TaxCodes />
              </TabPane>
              : null }

            { this.hasAccess('listFvpFundingCategories')
              ? <TabPane tab='Categories' key='3'>
                <Categories />
              </TabPane>
              : null }

            { this.hasAccess('listFvpFundingRateSets')
              ? <TabPane tab='Rate Sets' key='2'>
                <RateSet />
              </TabPane>
              : null }

            { this.hasAccess('listFvpFundingFundManagers')
              ? <TabPane tab='Fund Manager' key='5'>
                <FundManager />
              </TabPane>
              : null }
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFunder))

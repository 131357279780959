import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_USERS, FETCHING_USERS, USERS_FETCHED } from '../actions/user'
import userService from '../../services/user'

function * fetchUsers ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_USERS, loading })
    const users = yield userService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: USERS_FETCHED, loading: false, users })
  } catch (e) {
    yield put({ type: FETCHING_USERS, loading: false })
    console.error(e)
  }
}

function * watchUser () {
  yield takeEvery(FETCH_USERS, fetchUsers)
}

export default watchUser

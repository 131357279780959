import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_PAST_JOBS, FETCHING_PAST_JOBS, PAST_JOBS_FETCHED } from '../actions/pastJob'
import jobService from '../../services/job'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_PAST_JOBS, loading })
    const jobs = yield jobService.getPastJob(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: PAST_JOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAST_JOBS, loading: false })
    console.error(e)
  }
}

function * watchPastJobs () {
  yield takeEvery(FETCH_PAST_JOBS, fetchJobs)
}

export default watchPastJobs

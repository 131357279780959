import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter, validator } from '../../../util'
import { timezone } from '../../../config'
import moment from 'moment-timezone'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { Form } from 'antd';
moment.tz.setDefault(timezone)

const { RangePicker } = DatePicker

const pageSize = 50
const dateFormat = 'YYYY-MM-DD'

export class JobCancellationReport extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dateFrom: moment().add(-6, 'day'),
      dateTo: moment(),
      list: [],
      currentPage: 1,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      total: 0,
    }
  }

  componentDidMount () {
    this.fetchList()
  }

  render () {
    const { currentPage, dateFrom, dateTo, loading, loadingExport, loadingList, list, total } = this.state
    const { form } = this.props

    const columns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ module, job_start_date, recurring_start_date, job_start_time }) => module === 'basejob' ? `${formatter.toShortDate(recurring_start_date)} ${formatter.toShortTime(job_start_time)}` : formatter.toStandardDate(job_start_date)
      },
      {
        title: 'Job End',
        width: 2,
        render: ({ module, job_end_date, recurring_end_date, job_end_time }) => module === 'basejob' ? `${formatter.toShortDate(recurring_end_date)} ${formatter.toShortTime(job_end_time)}` : formatter.toStandardDate(job_end_date)
      },
      {
        title: 'Client',
        width: 2,
        render: ({ client_name }) => client_name
      },
      {
        title: 'Employee',
        width: 2,
        render: ({ previous_emp_name, current_emp_name, action }) => action === 'job-employee-change' ? previous_emp_name : current_emp_name
      },
      {
        title: 'Action',
        width: 2,
        render: ({ action }) => this.generateAction(action)
      },
      {
        title: 'Date/Time',
        width: 2,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
      {
        title: 'By',
        width: 2,
        render: ({ created_by }) => created_by
      },
      {
        title: 'Reason Type',
        width: 2,
        render: ({ action_lvl1_name }) => action_lvl1_name
      },
      {
        title: 'Reason',
        width: 2,
        render: ({ action_lvl2_name }) => action_lvl2_name
      },
      {
        title: 'Other Reason',
        width: 2,
        render: ({ action_lvl1_other }) => action_lvl1_other
      },
      {
        title: 'Notes/Memo',
        width: 2,
        render: ({ action_lvl1_text }) => action_lvl1_text
      },
      {
        title: '',
        width: 1,
        render: ({ module, module_id }) => <div>
          <Tooltip mouseLeaveDelay={0} title='View'> <Link to={`${
            module === 'employee' ? `/employees/${module_id}`
              : module === 'client' ? `/clients/${module_id}`
                : module === 'job' ? `/jobs/single/${module_id}`
                  : module === 'basejob' ? `/jobs/recurring/${module_id}`
                    : ``
          }`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Job Cancellation Report'>
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
          </Page.Header>

          <div className='admin-list'>
            <ControlLabel>Date Range</ControlLabel>
            <RangePicker
              disabled={loading || loadingList}
              format="DD/MM/YYYY"
              onChange={(d1, d2) => this.onUpdateRange(d1, d2)}
              defaultValue={[dateFrom, dateTo]}
            />

            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <List cols={columns} rows={list} />
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, dateFrom, dateTo, isLoaded, loading } = this.state
    this.fetchJobCancellation({currentPage, dateFrom, dateTo, isLoaded, loading})
  }

  async fetchJobCancellation ({ currentPage, dateFrom, dateTo, isLoaded = true, loading }) {
    if (this.hasAccess('readJobMultiActionReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const from = dateFrom.format(dateFormat)
        const to = dateTo.format(dateFormat)
        const { list, total } = await reportService.getListByDate(ExportType.REPORT.JOB_CANCELLATION, from, to)
        this.setState({ list, loading: false, loadingList: false, isLoaded: true, total }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Job Cancellation Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  onUpdateRange (d1, d2) {
    if (validator.isNotEmptyArray(d1)) {
      this.setState({ dateFrom: d1[0], dateTo: d1[1], isLoaded: false }, () => {
        this.fetchList()
      })
    }
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readJobMultiActionReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const { dateFrom, dateTo } = this.state
      const from = dateFrom.format(dateFormat)
      const to = dateTo.format(dateFormat)

      const r = await exportFile.fetchReportExport(ExportType.REPORT.JOB_CANCELLATION, { from, to })
      this.setState({ loadingExport: false })
    }, 4000)
  }

  generateAction (action) {
    if (action === 'job-cancel-ufn' ) {
      return 'Cancel (UFN)'
    } else if (action === 'job-uncancel-ufn') {
      return 'Uncancel (UFN)'
    } else if (action === 'job-cancel') {
      return 'Cancel'
    } else if (action === 'job-uncancel') {
      return 'Uncancel'
    } else if (action === 'job-employee-change') {
      return 'Cancel (Employee Change)'
    } else return action
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobCancellationReport))

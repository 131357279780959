import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment-timezone'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import App from './containers/App'
import ForgotPassword from './containers/ForgotPassword'
import Login from './containers/Login'
import PrintFvp from './containers/PrintFvp'
import reducers from './states/reducers'
import root from './states/sagas'
import authService from './services/auth'

import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'moment/locale/en-gb';  // important!

moment.locale('en-gb');  // important!

moment.tz.setDefault('Australia/Melbourne')

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/login' component={Login} />
        <Route path='/print/:module/:id' component={PrintFvp} />
        <Route path='/' render={() => (isLoggedIn() ? <ConfigProvider locale={en_GB}><App /></ConfigProvider> : <Redirect to='/login' />)} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

function isLoggedIn () {
  return authService.isSignedIn()
}

import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/notification'

export default {
  send (values) {
    return request(url + '/changes', {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}

import moment from 'moment-timezone'
import numeral from 'numeral'
import * as geolib from 'geolib'

moment.tz.setDefault('Australia/Melbourne')

const formatter = {
  capitalize (str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.split(' ')
      return words.map((word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1).toUpperCase())).join(' ')
    }

    return ''
  },
  round (number) {
    if (typeof number === 'number') {
      return Math.round(number * 100) / 100
    }

    return 0
  },
  toClassName (arr) {
    if (arr && arr.length > 0) {
      return arr.filter((str) => typeof str === 'string' && str).join(' ')
    }

    return ''
  },
  toMoment (str) {
    if (str) {
      return moment(str)
    }

    return moment(new Date())
  },
  toDate (str, format) {
    if ((typeof str === 'string' || str) && typeof format === 'string') {
      return moment(str).format(format)
    } else if (moment.isMoment(str)) {
      return str.format(format)
    }

    return ''
  },
  toNumber (number) {
    const num = numeral(number)
    return num.value()
  },
  toDecimal (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.00')}`
    } else if (!isNaN(parseFloat(number))) {
      return `${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toDecimalS (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.[00]')}`
    } else if (!isNaN(parseFloat(number))) {
      return `${numeral(number).format('0,0.[00]')}`
    }

    return 0
  },
  toDecimalV (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0.[00]')}`
    } else if (!isNaN(parseFloat(number))) {
      return `${numeral(number).format('0.[00]')}`
    }

    return 0
  },
  toDecimalVS (number, decimals = 2) {
    let format = '0.'
    for (let i = 0; i < decimals; i++) {
      format += '0'
    }

    if (typeof number === 'number' || typeof number === 'string') {
      return validateNumeral(number, format)
    }

    return number
  },
  toPercentage (value, decimal = 1) {
    return value ? Number(Math.round(value + `e${decimal}`) + `e-${decimal}`) + '%' : '0%'
  },
  toPercentageFloat (value, decimalFormat = '[0]') {
    if (typeof value === 'number' || typeof value === 'string') {
      return `${validateNumeral(value, `0.${decimalFormat}`)}%`
    }

    return value
  },
  toPrice (number, currency = '$') {
    if (typeof number === 'number' || typeof number === 'string') {
      const isNegative = parseFloat(number) < 0
      const str = `${currency} ${numeral(number).format('0,0.00')}`
      if (isNegative) {
        return `- ${str.replace('-', '')}`
      } else {
        return str
      }
    }

    return `${currency} 0.00`
  },
  toDBDate (str) {
    return this.toDate(str, 'YYYYMMDD')
  },
  toShortDate (str) {
    return this.toDate(str, 'DD/MM/YYYY')
  },
  toDay (str) {
    return this.toDate(str, 'dddd')
  },
  toStandardDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm A')
  },
  toStandardLongDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm:ss A')
  },
  toShortTime (str) {
    return this.toDate(str, 'hh:mm A')
  },
  toUnix () {
    return moment(new Date()).unix()
  },
  toIcon (mediaType, defaultIcon) {
    if (typeof mediaType === 'string') {
      if (mediaType.startsWith('audio')) {
        return '/icon/audio.svg'
      } else if (mediaType.startsWith('video')) {
        return '/icon/video.svg'
      } else {
        switch (mediaType) {
          case 'application/pdf':
            return '/icon/pdf.svg'
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          case 'application/vnd.ms-word.document.macroEnabled.12':
          case 'application/vnd.ms-word.template.macroEnabled.12':
            return '/icon/doc.svg'
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          case 'application/vnd.ms-excel.template.macroEnabled.12':
          case 'application/vnd.ms-excel.addin.macroEnabled.12':
          case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            return '/icon/xls.svg'
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
            return '/icon/ppt.svg'
          case 'image/jpeg':
            return '/icon/jpg.svg'
          case 'image/png':
            return '/icon/png.svg'
          default:
            return '/icon/file.svg'
        }
      }
    }

    return defaultIcon || '/icon/resource.svg'
  },
  duration (date) {
    const duration = moment.duration(moment().endOf('days').diff(moment(date).endOf('days')))
    return duration.asDays().toFixed(0)
  },
  expiringDuration (date) {
    const duration = moment.duration(moment(date).endOf('days').diff(moment().endOf('days')))
    return duration.asDays().toFixed(0)
  },
  toErrorMessage (r) {
    if (Array.isArray(r) && r.length > 0) {
      return r.map(({message}) => message).join('\n')
    } else if (r && r.errors && Array.isArray(r.errors) && r.errors.length > 0) {
      return r.errors.map(({message}) => message).join('\n')
    }
    return ''
  },
  toStandardFileName (str) {
    if (typeof str === 'string') {
      const fileNameIndex = str.lastIndexOf('_')
      const fileExtIndex = str.lastIndexOf('.')
      const name = decodeURI(str.substring(0, fileNameIndex))
      const extName = str.substring(fileExtIndex)
      return name + extName
    }

    return ''
  },
  toShortenText (str, range = 30) {
    let length = str.length
    let index = str.indexOf(' ', range)
    index = index === -1 ? length : index
    return length <= range ? str : str.substring(0, index) + (index === length ? '' : '...')
  },
  toHtmlLineBreak (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
  },
  toDistance (radius = 0) {
    let r1 = parseFloat(radius)

    if (r1 > 1000) {
      r1 = r1 / 1000
      return `${this.toDecimalS(r1)} km`
    } else {
      return `${this.toDecimalS(r1)} m`
    }
  },
  updateMapDetails (clientCoords, signoffCoords, radius = 0) {
    let d = 0
    let c = {latitude: -37.8829427, longitude: 145.1599169}
    let dis
    let ctr = {latitude: NaN, longitude: NaN}
    let zm
    if (clientCoords && signoffCoords) {
      dis = geolib.getDistance(clientCoords, signoffCoords)
      ctr = geolib.getCenter([
        clientCoords, signoffCoords
      ])
      zm = isNaN(dis)
        ? 14
        : (dis > 1000000)
          ? 2
          : (dis > 100000)
            ? 7
            : (dis > 50000)
              ? 8
              : (dis > 10000)
                ? 10
                : (dis > 1000 || parseInt(radius) > 500)
                  ? 12
                  : parseInt(radius) > 100
                    ? 15.5
                    : 16
    }

    return { distance: isNaN(dis) ? d : (dis || d), center: isNaN(ctr.latitude) || isNaN(ctr.latitude) ? c : ctr, zoom: zm }
  },
  updateJobTimeDiff (jobEndDate, signoffDate) {
    const d2 = moment.isMoment(jobEndDate) ? jobEndDate : moment(jobEndDate)
    const now = moment.isMoment(signoffDate) ? signoffDate : moment(signoffDate)

    return d2.diff(now, 'minutes')
  },
  toTimeCountText (minutes, afterText = '', beforeText = '', isBefore = null) {
    // minutes counted by job end time - signoff time
    // so if minus => after job end time, plus => before job end time
    let day = 0
    let hour = 0
    let min = 0

    const fMinutes = Math.abs(minutes)
    min = fMinutes % 60
    hour = Math.floor(fMinutes / 60)
    if (hour > 24) hour = hour % 24
    day = Math.floor(fMinutes / 1440)

    const prefixText = (!afterText && !beforeText) ? '' : minutes === 0 && isBefore !== null ? (isBefore ? beforeText : afterText) : minutes > 0 ? beforeText : afterText

    return `${minutes === null ? 'No minutes value available.' : minutes === 0 ? `Less than 1 minute${prefixText ? ` ${prefixText}` : ''}` : `${day > 0 ? `${day} Day${day === 1 ? '' : 's'} ` : ''}${hour > 0 ? `${hour} Hour${hour === 1 ? '' : 's'} ` : ''}${min > 0 ? `${min} Min${min === 1 ? '' : 's'} ` : ''}${prefixText}`}`
  },
  toDurationDiff (date1, date2, unit = 'hours') {
    const d1 = moment.isMoment(date1) ? date1 : moment(date1)
    const d2 = moment.isMoment(date2) ? date2 : moment(date2)

    const diff = moment.duration(d1.diff(d2))

    if (unit === 'millisecond' || unit === 'milliseconds') {
      return Math.abs(diff.asMilliseconds())
    } else if (unit === 'second' || unit === 'seconds') {
      return Math.abs(diff.asSeconds())
    } else if (unit === 'minute' || unit === 'minutes') {
      return Math.abs(diff.asMinutes())
    } else if (unit === 'hour' || unit === 'hours') {
      return Math.abs(diff.asHours())
    } else if (unit === 'day' || unit === 'days') {
      return Math.abs(diff.asDays())
    }

    return null
  },
  toDurationText (date1, date2, timeFormat = 'hh:mm A', dateFormat = 'DD/MM/YYYY') {
    const d1 = moment.isMoment(date1) ? date1 : moment(date1)
    const d2 = moment.isMoment(date2) ? date2 : moment(date2)

    const isSameDay = d1.isSame(d2, 'day')
    const diffDay = d1.diff(d2, 'day')

    const d1Text = `${d1.format(dateFormat)} ${d1.format(timeFormat)}`
    const d2Text = isSameDay ? `${d2.format(timeFormat)}` : `${d2.format(dateFormat)} ${d2.format(timeFormat)}`

    if (d1.isValid() && d2.isValid()) {
      return `${d1Text} - ${d2Text}`
    } else if (d1.isValid() && !d2.isValid()) {
      return `from ${d1Text}`
    } else if (!d1.isValid() && d2.isValid()) {
      return `until ${d2Text}`
    } else {
      return ''
    }
  },
  toDurationTextFormat (date1, date2, timeFormat = 'hh:mm A') {
    const d1 = moment.isMoment(date1) ? date1 : moment(date1)
    const d2 = moment.isMoment(date2) ? date2 : moment(date2)

    const d1Text = `${d1.format(timeFormat)}`
    const d2Text = `${d2.format(timeFormat)}`

    if (d1.isValid() && d2.isValid()) {
      return `${d1Text} - ${d2Text}`
    } else if (d1.isValid() && !d2.isValid()) {
      return `from ${d1Text}`
    } else if (!d1.isValid() && d2.isValid()) {
      return `until ${d2Text}`
    } else {
      return ''
    }
  },
  toDurationTextWithDay (date1, date2, timeFormat = 'hh:mm A', dateFormat = 'DD/MM/YYYY', dayFormat = 'dddd') {
    const d1 = moment.isMoment(date1) ? date1 : moment(date1)
    const d2 = moment.isMoment(date2) ? date2 : moment(date2)

    const isSameDay = d1.isSame(d2, 'day')
    const diffDay = d1.diff(d2, 'day')

    const d1Text = `${d1.format(dateFormat)} (${d1.format(dayFormat)}) ${d1.format(timeFormat)}`
    const d2Text = isSameDay ? `${d2.format(timeFormat)}` : `${d2.format(dateFormat)} (${d2.format(dayFormat)}) ${d2.format(timeFormat)}`

    if (d1.isValid() && d2.isValid()) {
      return `${d1Text} - ${d2Text}`
    } else if (d1.isValid() && !d2.isValid()) {
      return `from ${d1Text}`
    } else if (!d1.isValid() && d2.isValid()) {
      return `until ${d2Text}`
    } else {
      return ''
    }
  },
  roundKMS (k) {
    let kms = parseFloat(k)
    let val = k
    if (!isNaN(kms)) {
      let r1 = kms * 100
      let r2 = r1 % 100

      if (r2 === 0) {
        val = r1 / 100
      } else if (r2 <= 50) {
        const balance = 50 - r2
        val = (r1 + balance) / 100
      } else {
        const balance = 100 - r2
        val = (r1 + balance) / 100
      }
    } else {
      console.log('round kms error', k)
      val = 0
    }

    return val
  },
  getDaysAgo (day = 0) {
    return humanizeDuration(day, 'ago')
  },
  getDaysToGo (day = 0) {
    return humanizeDuration(day)
  },
  humanizeDuration
}

/**
 * numeral.js has a critical issue which the number input will generate NaN if it is decimal exponential form (< 0.000001 i.e. 1e-7)
 * so need to validate the numeral result and fix the number if it generates NaN
 */
function validateNumeral (value, format = '0.00') {
  try {
    const a = numeral(value).format('0.0000')

    if (isNaN(a)) {
      // round to 6 decimals
      const b = parseFloat(Math.round(value * 100000) / 100000)
      return numeral(b).format(format)
    } else {
      return numeral(value).format(format)
    }
  } catch (e) {
    console.log('numeral validate error', e)
    return value
  }
}

/**
 * to humanize duration e.g. 3 days ago, 5 days to go
 * @param {number} day
 * @param {string} label
 * @returns string
 */
function humanizeDuration (day = 0, label = 'to go') {
  const d = parseFloat(day)
  const l = label && label.trim().length > 0 ? ` ${label}` : ''
  if (d === 0) {
    return 'Today'
  } else if (d > 0) {
    return `${d} day${d === 1 ? '' : 's'}${l}`
  } else {
    const day2 = Math.abs(d)
    return `${day2} day${d === -1 ? '' : 's'}${l}`
  }
}

export default formatter

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter } from '../../../util'
import { apiHostname } from '../../../config'

// UI
import { Button, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
const pageSize = 50

export class ReportBackToBackJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      currentPage: 1,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      total: 0,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchList()
    this.fetchOtherSettings()
  }

  render () {
    const { currentPage, loading, loadingExport, loadingList, list, total, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }
    const columns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ job_start_date }) => formatter.toStandardDate(job_start_date)
      },

      {
        title: 'Job End',
        width: 2,
        render: ({ job_end_date }) => formatter.toStandardDate(job_end_date)
      },

      {
        title: 'Employee',
        width: 3,
        render: ({ employee_id: empId, employee_fullname }) => <div><a href={`/employees/${empId}`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
      },

      {
        title: 'Client',
        width: 3,
        render: ({ client_id: clientId, client_fullname }) => <div><a href={`/clients/${clientId}`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
      },

      {
        title: 'Tasks',
        width: 6,
        render: ({ tasks }) => <div style={{ fontSize: 10 }}>{tasks}</div>
      },

      {
        title: 'Funder',
        width: 2,
        render: ({ funder_id: funderId, funder_fullname }) => <div><a href={`/funders/${funderId}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
      },

      {
        title: '',
        width: 1,
        render: ({ id }) => <div>
          <Tooltip mouseLeaveDelay={0} title='View'> <Link to={`/jobs/single/${id}`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Back-To-Back Shift`} >
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }

          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('back_to_back_email', {
                          initialValue: settings.back_to_back_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div className='report-title'>{`Back-To-Back Shifts `} <span className='report-tag'>{total}</span></div>
                <List cols={columns} rows={list} />
                <Pager
                  size={pageSize}
                  total={total}
                  totalText={`Total ${total} shifts`}
                  current={currentPage}
                  onChange={(e) => this.onChangePage(e)}
                  style={{ marginTop: '0px' }}
                />
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, isLoaded, loading } = this.state
    this.fetchBacktoBack({currentPage, isLoaded, loading})
  }

  async fetchBacktoBack ({ currentPage, isLoaded = true, loading }) {
    if (this.hasAccess('readBackToBackReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const { list, total } = await reportService.getListByPage(ExportType.REPORT.BACK_TO_BACK, currentPage, pageSize)
        this.setState({ list, loading: false, loadingList: false, isLoaded: true, total }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Back To Back Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readBackToBackReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      try {
        const r = await exportFile.fetchReportExport(ExportType.REPORT.BACK_TO_BACK)
        this.setState({ loadingExport: false })
      } catch (e) {
        notify.error('Unable to get Back to Back Report', 'Unable to export the report. Please try again later')
        this.setState({ loadingExport: false })
      }
    }, 1000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportBackToBackJob))

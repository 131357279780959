export const FETCH_USERS = 'FETCH_USERS'
export const FETCHING_USERS = 'FETCHING_USERS'
export const USERS_FETCHED = 'USERS_FETCHED'

export const fetchUsers = (params) => {
  return Object.assign({
    type: FETCH_USERS
  }, params)
}

export const fetchingUsers = (loading) => {
  return {
    type: FETCHING_USERS,
    loading
  }
}

export const usersFetched = (users) => {
  return {
    type: USERS_FETCHED,
    users
  }
}

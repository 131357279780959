import { FETCHING_JOBS, JOBS_FETCHED, FETCHED_TOTAL_PENDING_JOBS } from '../actions/job'

const INITIAL_STATE = {
  jobList: { list: [], total: 0 },
  totalPending: { individual: 0, recurring: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_JOBS:
      return handleFetchingJobs(state, action)
    case JOBS_FETCHED:
      return handleJobsFetched(state, action)
    case FETCHED_TOTAL_PENDING_JOBS:
      return { ...state, totalPending: action.total, loading: false }
    default:
      return state
  }
}

function handleFetchingJobs (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleJobsFetched (state, action) {
  const { jobs, loading } = action
  return { ...state, jobList: jobs, loading }
}

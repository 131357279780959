import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uploader, validator, formatter, log } from '../../util'
import { authService, settingCustomValue } from '../../services'
import { setRefreshActivityLog } from '../../states/actions'

// UI
import { Loading, Panel } from '../../components'
import notify from '../Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input

export class CustomIdentifierList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      items: [],
      total: 0
    }
  }

  componentDidMount () {
    this.fetchCustomValues()
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const sideBySideFormToggleItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 16 },
      wrapperCol: { sm: 14, md: 14, lg: 6 }
    }

    const sideBySideFormNoteItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 7 },
      wrapperCol: { sm: 14, md: 14, lg: 15 }
    }

    const { form, moduleType, moduleId, identifierSetting } = this.props
    const { loading, items } = this.state
    const { getFieldDecorator, getFieldValue } = form
    return (<div>
      <Loading loading={loading} blur>
        <Panel title='Custom Identifier' subtitle={identifierSetting.length > 0 ? <div className='btn' onClick={() => this.handleSave()}>Save Custom Identifier</div> : null}>
          <Form>
            {identifierSetting.map((identifier, idx) => {
              const _item = items.filter(a => a.identifier_id === identifier.id)
              const item = _item[0] || {}

              return (
                <div key={`identifier-${idx}`}>
                  {identifier.input_type === 'text' ? (
                    <Row>
                      <FormItem {...formItemLayout} label={identifier.label}>
                        {getFieldDecorator(`extra_fields[${idx}].value`, {
                          initialValue: item.value_text ? item.value_text : '',
                          rules: [
                            { whitespace: true, message: `Please enter ${identifier.label}` }
                          ]
                        })(
                          <Input />
                        )}
                      </FormItem>
                      {identifier.is_include_notes ? (
                        <FormItem {...formItemLayout} label={`${identifier.label} Notes`}>
                          {getFieldDecorator(`extra_fields[${idx}].notes`, {
                            initialValue: item.value_notes ? item.value_notes : ''
                          })(
                            <TextArea rows={1} />
                          )}
                        </FormItem>
                      )
                        : null
                      }
                    </Row>
                  )
                    : identifier.input_type === 'text-area' ? (
                      <Row>
                        <FormItem {...formItemLayout} label={identifier.label}>
                          {getFieldDecorator(`extra_fields[${idx}].value`, {
                            initialValue: item.value_text ? item.value_text : '',
                            rules: [
                              { whitespace: true, message: `Please enter ${identifier.label}` }
                            ]
                          })(
                            <TextArea rows={1} />
                          )}
                        </FormItem>
                        {identifier.is_include_notes ? (
                          <FormItem {...formItemLayout} label={`${identifier.label} Notes`}>
                            {getFieldDecorator(`extra_fields[${idx}].notes`, {
                              initialValue: item.value_notes ? item.value_notes : ''
                            })(
                              <TextArea rows={1} />
                            )}
                          </FormItem>
                        )
                          : null
                        }
                      </Row>
                    )
                      : identifier.input_type === 'toggle' ? (
                        <Row>
                          <Col lg={6}>
                            <FormItem {...sideBySideFormToggleItemLayout} label={identifier.label}>
                              {getFieldDecorator(`extra_fields[${idx}].value`, {
                                initialValue: item.value_boolean ? item.value_boolean : false,
                                valuePropName: 'checked'
                              })(
                                <Switch
                                  checkedChildren='Yes'
                                  unCheckedChildren='No'
                                />
                              )}
                            </FormItem>
                          </Col>
                          {identifier.is_include_notes ? (
                            <Col lg={18}>
                              <FormItem {...sideBySideFormNoteItemLayout} label={``}>
                                {getFieldDecorator(`extra_fields[${idx}].notes`, {
                                  initialValue: item.value_notes ? item.value_notes : ''
                                })(
                                  <TextArea rows={1} disabled={!getFieldValue(`extra_fields[${idx}].value`)} />
                                )}
                              </FormItem>
                            </Col>
                          )
                            : null
                          }
                        </Row>
                      )
                        : null }

                  <div style={{ display: 'none' }}>
                    <FormItem {...formItemLayout} label={`Value ID`}>
                      {getFieldDecorator(`extra_fields[${idx}].id`, {
                        initialValue: item.id ? item.id : null
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Identifier ID`}>
                      {getFieldDecorator(`extra_fields[${idx}].identifier_id`, {
                        initialValue: identifier.id ? identifier.id : null
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Input Type`}>
                      {getFieldDecorator(`extra_fields[${idx}].input_type`, {
                        initialValue: identifier.input_type ? identifier.input_type : ''
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Code`}>
                      {getFieldDecorator(`extra_fields[${idx}].code`, {
                        initialValue: identifier.code ? identifier.code : ''
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Allow Log`}>
                      {getFieldDecorator(`extra_fields[${idx}].is_allow_log`, {
                        initialValue: identifier.is_allow_log ? identifier.is_allow_log : false
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Include Notes`}>
                      {getFieldDecorator(`extra_fields[${idx}].is_include_notes`, {
                        initialValue: identifier.is_include_notes ? identifier.is_include_notes : false
                      })(
                        <Input readOnly />
                      )}
                    </FormItem>
                  </div>
                </div>
              )
            })}
          </Form>
        </Panel>
      </Loading>
    </div>
    )
  }

  fetchCustomValues = async () => {
    this.setState({ loading: true })
    const { moduleType, moduleId } = this.props
    const filter = { }

    filter.module = { condition: '=', value: moduleType }
    filter.module_id = { condition: '=', value: moduleId }
    filter.active = { condition: '=', value: true }

    const { list: items, total } = await settingCustomValue.listByPage(1, 0, filter)

    this.setState({ items, total, loading: false })
  }

  handleSave = () => {
    const { form, moduleType, moduleId } = this.props
    const { items } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })
        const sValues = []
        let changes = ''

        try {
          for (let i = 0; i < values.extra_fields.length; i++) {
            let item = {}
            let logValueAfter = {}
            let c = ''
            const _item = values.extra_fields[i]

            // assigning custom value to be save
            item.id = _item.id
            item.identifier_id = _item.identifier_id
            item.module_id = moduleId
            if (_item.input_type === 'text') {
              item.value_text = _item.value.trim()
              item.value_boolean = null
              logValueAfter[`${_item.code}`] = item.value_text
            } else if (_item.input_type === 'text-area') {
              item.value_text = _item.value.trim()
              item.value_boolean = null
              logValueAfter[`${_item.code}`] = item.value_text
            } else if (_item.input_type === 'toggle') {
              item.value_boolean = _item.value
              item.value_text = null
              logValueAfter[`${_item.code}`] = item.value_boolean
            }

            if (_item.is_include_notes) {
              item.value_notes = _item.notes.replace(/,/g, '').trim()
              logValueAfter[`${_item.code}_notes`] = item.value_notes || ''
            } else {
              item.value_notes = null
              logValueAfter[`${_item.code}_notes`] = ''
            }

            let logValueBefore = {}
            const logItemBefore = items[i]

            if (logItemBefore) {
              logValueBefore[`${logItemBefore.code}`] = logItemBefore.input_type === 'toggle' ? logItemBefore.value_boolean : logItemBefore.value_text
              logValueBefore[`${logItemBefore.code}_notes`] = logItemBefore.value_notes || ''
            }

            // Generate activity log
            if (item.id) {
              c = log.generateItemChanges(logValueBefore, logValueAfter)
            } else {
              // To prevent empty value & empty notes log generated
              if (item.value_boolean) {
                c = log.generateNewItemChanges(logValueAfter)
              }
            }

            sValues.push(item)
            changes += changes ? `, ${c}` : c
          }
          const response = await settingCustomValue.add(sValues)
          log.updateCustomIdentifier(moduleId, moduleType, changes)

          if (response && response.id) {
            notify.success('Saved successfully', `Custom identifier saved successfully.`)
            this.fetchCustomValues()
          } else {
            notify.error('Unable to save successfully', `Unable to save custom identifier successfully. Please try again later.`)
          }

          setTimeout(() => {
            this.props.setRefreshActivityLog(true)
          }, 1000)
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save custom identifier successfully. Please try again later.`)
        }

        this.setState({ loading: false })
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CustomIdentifierList))

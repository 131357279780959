export const FETCH_PENDING_EMPLOYEE_JOB = 'FETCH_PENDING_EMPLOYEE_JOB'
export const FETCHING_PENDING_EMPLOYEE_JOBS = 'FETCHING_PENDING_EMPLOYEE_JOBS'
export const PENDING_EMPLOYEE_JOBS_FETCHED = 'PENDING_EMPLOYEE_JOBS_FETCHED'

export const fetchPendingEmployeeJobs = (params) => {
  return Object.assign({
    type: FETCH_PENDING_EMPLOYEE_JOB
  }, params)
}

export const fetchingPendingEmployeeJobs = (loading) => {
  return {
    type: FETCHING_PENDING_EMPLOYEE_JOBS,
    loading
  }
}

export const pendingEmployeeJobsFetched = (jobs) => {
  return {
    type: PENDING_EMPLOYEE_JOBS_FETCHED,
    jobs
  }
}

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_JOBS, FETCHING_JOBS, JOBS_FETCHED, FETCH_TOTAL_PENDING_JOBS, FETCHED_TOTAL_PENDING_JOBS } from '../actions/job'
import jobService from '../../services/job'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_JOBS, loading })
    const jobs = yield jobService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: JOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_JOBS, loading: false })
    console.error(e)
  }
}

function * fetchTotalPendingJobs () {
  try {
    const total = yield jobService.getTotalPending()
    yield put({ type: FETCHED_TOTAL_PENDING_JOBS, total, loading: false })
  } catch (e) {
    yield put({ type: FETCHED_TOTAL_PENDING_JOBS, loading: false })
    console.error(e)
  }
}

function * watchJob () {
  yield takeEvery(FETCH_JOBS, fetchJobs)
  yield takeEvery(FETCH_TOTAL_PENDING_JOBS, fetchTotalPendingJobs)
}

export default watchJob

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { debounce, cloneDeep } from 'lodash'
import { fetchJobRecurringList, fetchJobSummary } from '../../../../states/actions'
import { JobJvpMenu, JobFilterType, JobListType } from '../../../../constants'
import {
  clientService,
  employeeService,
  employeeFileService,
  funderService,
  fvpClientFundingService,
  fvpRatesService,
  jvpJobFileService,
  jvpJobRecurringService,
  jvpJobService,
  authService,
  settingFileCategoryService,
  settingCancellationService,
  settingFileTypeService,
  settingGeneralService,
  settingHolidayService,
  settingOtherService
} from '../../../../services'
import { common, formatter, log, validator } from '../../../../util'
import { jobURL, timezone } from '../../../../config'
import moment from 'moment-timezone'

// UI
import { DateTimePicker, List, Loading, Page, Panel, Pager, ControlLabel, SearchInput, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
import SingleJobView from '../../Single/View'
import ActivityLog from '../../ActivityLog'
import AddFileModal from '../../AddFileModal'
import Communication from '../../Communication'
import File from '../../File'
import DurationBreakdown from '../../../../util/durationFvp'

import '../../styles.css'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'
import Checkbox from 'antd/lib/checkbox'
import TimePicker from 'antd/lib/time-picker'

const pageSize = 10
const { Item: FormItem } = Form
const { confirm, error, info, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const { Option, OptGroup } = Select

const TIMEOUT = 150

moment.tz.setDefault(timezone)

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/comm' },
  { tabId: 3, path: '/files' },
  { tabId: 4, path: '/logs' }
]

const dbFormat = 'YYYY-MM-DD HH:mm:ss'
const dateFormat = 'DD/MM/YYYY'
const dateFormatComplete = 'DD/MM/YYYY, dddd'
const PmsCreateJob = 'createJob'
const PmsUpdateJob = 'updateJob'
const JobTriggerAlertHours = 2
const JobSleepoverDetectionMinutes = 240

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}
const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const specialFormItemLayoutToggle = {
  labelCol: { sm: 6, md: 6, lg: 0 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

class JvpRecurringJobPage extends Component {
  constructor (props) {
    super(props)
    const { match, location } = props
    const { key = undefined } = location
    const { params = {} } = match
    const { tab = '' } = params
    const selectedTab = TabList.find(e => e.path === tab || e.path === `/${tab}`)

    this.state = {
      // ids
      clientId: null,
      employeeId: null,
      funderId: null,
      // info
      clientInfo: {},
      employeeInfo: {},
      funderInfo: {},
      prevFunderInfo: {},
      fileInfo: {},
      rateSetInfo: {},
      clientList: [],
      employeeList: [],
      employeePrevList: [],
      funderList: [],
      funderPeriodList: [],
      billingCategoryList: [],
      fileList: [],
      // info misc
      clientCheckInfo: {},
      clientPrivateAlert: '',
      clientPublicAlert: '',
      clientLanguages: [],
      clientLanguagesName: [],
      clientSkills: [],
      clientSkillsName: [],
      employeeExpiredFiles: [],
      employeeJobHoursInfo: {},
      employeePrivateAlert: '',
      employeePublicAlert: '',
      employeeLanguages: [],
      employeeSkills: [],
      // job item
      jobDurationText: null,
      item: {},
      itemOri: {},
      // holidayInfo,
      isHoliday: false,
      holidayInfo: {},
      // leave info,
      clientLeaveInfo: {},
      employeeLeaveInfo: {},
      isClientLeave: false,
      isEmployeeLeave: false,
      // mismatch info
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: [],
      // clashed info
      clashedClients: null,
      clashedEmployees: null,
      // employee change reason info
      employeeChangeReasonId: null,
      employeeChangeReasonName: null,
      employeeChangeReasonTypeId: null,
      employeeChangeReasonTypeName: null,
      employeeChangeOtherReason: null,
      employeeChangeNote: null,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false,
      // files related
      fileMainCategoryList: [],
      fileSubCategoryList: [],
      // conflict job lists
      conflictJobList: [],
      conflictJobListClient: [],
      conflictJobListEmp: [],
      conflictJobErrorMsg: null,
      // loading flags
      loading: false,
      loadingSave: false,
      loadingJobList: false,
      loadingAffectedJobList: false,
      loadingCheckClient: false,
      loadingFileCats: false,
      // normal flags
      isCustomSO: false,
      isDisableCategoriesSelect: false,
      isEmployeePending: false,
      isExtSleepover: false,
      isRecurringJobNeverEnd: false,
      isRecurringJobHasStartDate: false,
      isShowSave: false,
      isShowSleepoverAlert: false,
      isRemoveJobsForSameDay: null, // if recurring start date & end date is same date, a popup will trigger to ask whether the single job at the start date will be removed as well, if the start date is before now.
      // single jobs related
      singleJobAllList: [],
      singleJobList: [],
      singleJobListTotal: 0,
      singleJobListType: JobFilterType.JOB_TYPE_FUTURE_ALL,
      singleJobListPage: 1,
      // affected jobs related
      affectedJobList: [],
      affectedJobErrorMsg: null,
      // setting lists
      settingsAll: [],
      settingsPayroll: [],
      settingsOthers: {},
      settingsCancelList: [],
      settingsCancelListReasonAll: [],
      settingsCancelListCurrentReason: [],
      // modal related
      isShowEmployeeReasonModal: false,
      isShowFunderModal: false,
      // add file modal related
      isShowAddFileModal: false,
      modalFileInfo: {},
      // UI
      isLogRefresh: false,
      currentTab: selectedTab && selectedTab.tabId ? String(selectedTab.tabId) : '1',
      prevUnsentCommTotal: null,
      unsentCommTotal: 0,
      pageKey: key,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.tab !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, pageKey: location.key }
  }

  componentDidMount () {
    this.checkUrl()
    this.initialize()
  }

  checkUrl = () => {
    // if the url is /:id only (but not /add), redirect to /:id/:tab,
    const { history, match, location } = this.props
    const { currentTab } = this.state
    const { params = {} } = match
    const { id, tab = '' } = params

    if (!(id === 'add' || id === 'new')) {
      const t = TabList.find(e => `${e.tabId}` === currentTab)

      if (!tab) {
        if (location && location.pathname) {
          history.replace(`${location.pathname}${t && t.tabId ? `${t.path}` : ''}`)
        }
      }
    }
  }

  render () {
    const { history, match, form } = this.props
    const { getFieldDecorator } = form
    const {
      clashedClients,
      clashedEmployees,
      clientInfo,
      funderInfo,
      clientPrivateAlert,
      clientPublicAlert,
      employeeChangeNote,
      employeeChangeOtherReason,
      employeeChangeReasonId,
      employeeChangeReasonTypeId,
      fileMainCategoryList,
      fileSubCategoryList,
      funderList,
      isEmployeeChangeReasonOther,
      isLogRefresh,
      isShowAddFileModal,
      isShowFunderModal,
      isShowEmployeeReasonModal,
      isShowSave,
      item,
      loading,
      modalFileInfo,
      settingsCancelList,
      settingsCancelListCurrentReason,
      currentTab,
      unsentCommTotal,
    } = this.state

    const jobId = this.getJobId()
    const modeText = !this.isEdit() ? '' : isShowSave ? `(Edit Mode)` : `(View Mode)`
    const isInfoTab = currentTab === '1'

    const title = `${this.isEdit() ? '' : `New `}Recurring Job ${clientInfo && clientInfo.id ? `For ${clientInfo.first_name} ${clientInfo.last_name} ` : ''}${modeText}`

    const isShowClientPrivateAlert = clientPrivateAlert && clientPrivateAlert.length > 0
    const isShowClientPublicAlert = clientPublicAlert && clientPublicAlert.length > 0

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={`${title}`}>
            {!isShowSave && this.isEdit() && this.hasAccess(PmsUpdateJob) && isInfoTab
              ? <div className='btn' onClick={this.handleEditButton}>Edit</div>
              : null}

            {((isShowSave && this.hasAccess(PmsUpdateJob)) || (!this.isEdit() && this.hasAccess(PmsCreateJob))) && isInfoTab
              ? <div className='btn' onClick={() => this.checkBeforeSave()}>
                {loading ? <Icon type='loading' style={{ fontSize: 13 }} /> : 'Save'}
              </div>
              : null}

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          {isShowClientPrivateAlert || isShowClientPublicAlert
            ? <div>
              <Alert
                message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                      `${clientPublicAlert ? `${clientPublicAlert}<br />` : ''}` +
                      `${clientPrivateAlert ? `${clientPrivateAlert}<br />` : ''}`
                  }} />
                }
                type='warning'
                showIcon
              />
              <br />
            </div>
            : null}

          {clashedClients || clashedEmployees
            ? <div>
              <Alert
                message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Address</span><br />` +
                      `${clashedClients ? `Same address with client: ${clashedClients}<br />` : ''}` +
                      `${clashedEmployees ? `Same address with employee: ${clashedEmployees}<br />` : ''}`
                  }} />
                }
                type='warning'
                showIcon
              /><br />
            </div>
            : null}

          {this.isEdit()
            ? <div className='job-remark-div' style={{ backgroundColor: '#ea2a2acc' }}><Icon type='exclamation-circle' /> You are editing Base Job. Any changes you make will affect future jobs.</div>
            : null}

          {funderInfo && (funderInfo.id === null)
            ? <div className='job-remark-div' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' /> The client does not have active funding period so unable to show the funder properly.</div>
            : null}

          <Tabs defaultActiveKey={currentTab} activeKey={currentTab} onChange={this.handleTabChange}>
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              {this.renderInfoTab()}
            </TabPane>

            {this.isEdit()
              ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2'>
                <Communication key={`cmsrc${currentTab}`} jobId={jobId} moduleType={'base_job'} onUpdate={(value) => this.handleCommUpdate(value)} />
              </TabPane>
              : null}

            {this.isEdit()
              ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
                <File key={`fcsrc${currentTab}`} jobId={jobId} moduleType={'basejob'} history={history} onRefreshPage={() => this.refreshPage()} />
              </TabPane>
              : null}

            {this.isEdit()
              ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='4'>
                <ActivityLog key={`actrc${currentTab}`} jobId={jobId} moduleType={'basejob'} isLogRefresh={isLogRefresh} onUpdateRefresh={() => this.updateRefreshLogComplete()} />
              </TabPane>
              : null}
          </Tabs>
        </Page.Content>

        {/* --------------------------------------FUNDER MODAL START---------------------------------------- */}
        <Modal
          width={600}
          title='Change Funder'
          visible={isShowFunderModal}
          onOk={this.handleFunderSubmit}
          onCancel={this.handleFunderCancel}
          footer={[
            <Row>
              <Button key='change-funder-confirm' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>
              <Button key='change-funder-cancel' onClick={this.handleFunderCancel}>Cancel</Button>

            </Row>

          ]}
        >
          {isShowFunderModal
            ? <div>
              <FormItem {...formItemLayout} label='Funder' hasFeedback>
                {getFieldDecorator('funder_id', {
                  initialValue: funderInfo.id
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Funders'
                    showSearch
                    filterOption={this.findOption}>
                    {funderList.map((funder, idx) => (<Option key={`fusx-${idx}`} value={funder.funder_id}>
                      {funder.funder_fullname}
                    </Option>))}
                  </Select>
                )}
              </FormItem>
            </div>
            : null}
        </Modal>
        {/* --------------------------------------FUNDER MODAL END---------------------------------------- */}

        {/* --------------------------------------CHANGE EMPLOYEE MODAL START ---------------------------------------- */}
        <SideModal
          title='You Have Changed Employee'
          showModal={isShowEmployeeReasonModal}
          onClose={() => this.triggerEmployeeChangeModal(false)}
          buttons={[
            <Button key={`empsump`} type='primary' onClick={() => this.handleSubmitEmployeeChangeReason()}>Submit</Button>
          ]}
        >
          {isShowEmployeeReasonModal
            ? <div>
              <FormItem label='Reason Type'>
                {getFieldDecorator('employee_change_reason_type_id', {
                  initialValue: employeeChangeReasonTypeId,
                  rules: [
                    { required: true, message: 'Please select change type' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleEmployeeCancelTypeChange}>
                    {settingsCancelList.map((items, idx) => {
                      return <Option key={`cts${idx}`} value={items.id}>{items.name}</Option>
                    })}
                  </Select>
                )}
              </FormItem>

              <FormItem label='Reason To Change'>
                {getFieldDecorator('employee_change_reason_id', {
                  initialValue: employeeChangeReasonId,
                  rules: [
                    { required: true, message: 'Please select reason' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleEmployeeChangeReasonChange}>
                    {settingsCancelListCurrentReason.map((items, idx) => {
                      return <Option key={`ctsl-${idx}`} value={items.id}>{items.name}</Option>
                    })}
                  </Select>
                )}
              </FormItem>

              {isEmployeeChangeReasonOther
                ? <FormItem label='Other Reason To Change'>
                  {getFieldDecorator('employee_change_other_reason', {
                    initialValue: employeeChangeOtherReason,
                  })(
                    <TextArea row={2} />
                  )}
                </FormItem>
                : null}

              <FormItem label='Notes (Optional)'>
                {getFieldDecorator('employee_change_note', {
                  initialValue: employeeChangeNote,
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>
            </div>
            : null}
        </SideModal>
        {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

        {/* --------------------------------------ADD FILE MODAL START---------------------------------------- */}
        <AddFileModal
          jobId={'add'}
          module={'basejob'}
          key={`basejob_addfile_new`}
          item={modalFileInfo}
          categoriesList={fileMainCategoryList}
          subCategoriesList={fileSubCategoryList}
          onClose={() => this.handleAddFileModal(false)}
          onSetFile={(values) => this.updateFileAdded(values)}
          visible={isShowAddFileModal}
        />
        {/* --------------------------------------ADD FILE MODAL END---------------------------------------- */}
      </Page.Body>
    )
  }

  renderInfoTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      affectedJobList,
      affectedJobErrorMsg,
      billingCategoryList,
      clientInfo,
      clientLeaveInfo,
      clientPrivateAlert,
      clientPublicAlert,
      clientLanguagesName,
      clientSkillsName,
      clashedClients,
      clashedEmployees,
      conflictJobList,
      conflictJobListClient,
      conflictJobListEmp,
      conflictJobErrorMsg,
      employeeExpiredFiles,
      // employeeChangeNote,
      // employeeChangeOtherReason,
      // employeeChangeReason,
      // employeeChangeReasonType,
      employeeId,
      employeeInfo,
      employeeLeaveInfo,
      employeeJobHoursInfo,
      employeePrivateAlert,
      employeePublicAlert,
      employeeLanguages,
      employeeSkills,
      employeeList,
      employeePrevList,
      fileList,
      fileMainCategoryList,
      fileSubCategoryList,
      funderInfo,
      // funderList,
      holidayInfo,
      item,
      isClientLeave,
      isEmployeeLeave,
      isCustomSO,
      isDisableCategoriesSelect,
      isEmployeePending,
      // isEmployeeChangeReasonOther,
      isExtSleepover,
      isHoliday,
      isMismatchLanguage,
      isMismatchSkill,
      isRecurringJobNeverEnd,
      isRecurringJobHasStartDate,
      isRemoveJobsForSameDay,
      // isShowAddFileModal,
      // isShowEmployeeReasonModal,
      // isShowFunderModal,
      isShowSleepoverAlert,
      jobDurationText,
      loading,
      loadingSave,
      loadingJobList,
      mismatchedLanguageList,
      mismatchedSkillList,
      // modalFileInfo,
      settingsPayroll,
      // settingsCancelList,
      // settingsCancelListCurrentReason,
      // settingsCancelListReasonAll,
      singleJobList,
      singleJobListType,
      singleJobListPage,
      singleJobListTotal
    } = this.state

    const billingCategoryListPreferred = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === true) : []
    const billingCategoryListOther = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === false) : []

    const rsd = form.getFieldValue('recurring_start_date')
    const isSleepoverToggleEnabled = !!rsd && funderInfo && funderInfo.id && validator.isNotEmptyArray(billingCategoryList) && billingCategoryList.findIndex(e => e.is_sleepover === true) > -1
    const isSleepoverListEnabled = !!rsd && rsd < moment(new Date()) && funderInfo && funderInfo.id && isSleepoverToggleEnabled

    const currentRecurringStartDate = form.getFieldValue('recurring_start_date')
    const currentRecurringStartDateText = currentRecurringStartDate
      ? (moment.isMoment(currentRecurringStartDate)
        ? currentRecurringStartDate.format(dateFormatComplete)
        : moment(currentRecurringStartDate).format(dateFormatComplete))
      : null
    const durationPanelTitle = `Recurring Job Schedule ${currentRecurringStartDateText ? `- Starting from ${currentRecurringStartDateText}` : ''}`

    const isNoEmployeeAlert = this.isEdit() && isEmployeePending

    const isShowEmployeePrivateAlert = employeePrivateAlert && employeePrivateAlert.length > 0
    const isShowEmployeePublicAlert = employeePublicAlert && employeePublicAlert.length > 0

    const isShowEmpMaxHoursAlert = employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = fileMainCategoryList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = fileSubCategoryList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'>
            <div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}>
              <Icon type='form' />
            </div>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleFileDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (
      <div className='jobs-jvp'>
        <Loading loading={loading || loadingSave} blur>
          {/* { isRemoveJobsForSameDay !== null && this.isEdit()
            ? <Row gutter={24}>
              <Col lg={21} />
              <Col lg={3}>
                <div style={{marginBottom: '20px'}}>
                  <Switch
                    checked={isRemoveJobsForSameDay || false}
                    checkedChildren='Remove All Jobs'
                    unCheckedChildren='Keep first job'
                    onChange={(e) => this.handleRecurringSameDayChange(e)}
                  />
                </div>
              </Col>
            </Row>
            : null } */}
          <Row className='row-info' gutter={24}>
            <Col className='row-info-col' md={12} lg={12}>
              <div className='info'>
                {!(clientInfo && clientInfo.id)
                  ? <div className='panel-empty'>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </div>
                  : <div className='panel-info'>
                    <div className='space-between'>
                      <span className='space-between'>
                        <span className='name'>
                          <a href={`/clients/${clientInfo.id}/info`} rel='noopener noreferrer' target='_blank'>
                            {clientInfo.first_name} {clientInfo.last_name}
                          </a>
                        </span>
                        <span className='accref'>{clientInfo.acc_ref}</span>
                      </span>
                    </div>
                    {clientInfo.leave_id
                      ? <span className='leave-info'><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                        &nbsp;{`Leave ${formatter.toShortDate(clientInfo.leave_start_date)} - ${clientInfo.leave_is_ufn ? 'UFN' : formatter.toShortDate(clientInfo.leave_end_date)}`}
                      </span>
                      : null}
                    <div className='detail'>
                      {clientInfo.phone
                        ? <div className='detail-item'>
                          <Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                          {clientInfo.phone}
                        </div>
                        : null}
                      {clientInfo.address
                        ? <div className='detail-item'>
                          <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                          {clientInfo.unit_building ? `${clientInfo.unit_building},` : ''}
                          {clientInfo.address}
                        </div>
                        : null}
                    </div>
                    <div className='detail' style={{ marginTop: 15 }}>
                      <b>Preference</b>
                      {clientInfo.preferred_gender
                        ? <div className='detail-item-span'>
                          <Icon type='user-add' style={{ color: '#ed6d1e' }} />&nbsp;
                          {formatter.capitalize(clientInfo.preferred_gender)}
                        </div>
                        : null}

                      <div className='detail-item'>
                        {validator.isNotEmptyArray(clientLanguagesName)
                          ? <div className='detail-item-span'>
                            <Icon type='font-size' style={{ color: '#ed6d1e' }} />&nbsp;
                            {clientLanguagesName.join(', ')}
                          </div>
                          : null}
                      </div>
                    </div>
                    <div className='detail' style={{ marginTop: 5 }}>
                      <b>Skills</b>
                      <div className='detail-item'>
                        {validator.isNotEmptyArray(clientSkillsName)
                          ? <div className='detail-item-span'>
                            <Icon type='check-circle' style={{ color: '#ed6d1e' }} />&nbsp;
                            {clientSkillsName.join(', ')}
                          </div>
                          : null}
                      </div>
                    </div>
                  </div>}
              </div>
            </Col>
            <Col className='row-info-col' md={12} lg={12}>
              <div className='info' style={{ backgroundColor: funderInfo && funderInfo.isInvalidFunder ? 'red' : undefined }}>
                {!(funderInfo && funderInfo.id)
                  ? <div lg={24} className='panel-empty'>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </div>
                  : <div className='panel-info'>
                    <div className='space-between'>
                      <span className='space-between'>
                        {funderInfo.fullname
                          ? <span className='name'>
                            <a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>
                              {funderInfo.fullname}
                            </a>
                          </span>
                          : <span className='name warning'>
                            <Icon type='exclamation-circle' /> Invalid Funder. Please select a new one.
                          </span>}
                        <span className='accref'>{funderInfo.acc_ref}</span>
                      </span>
                      <span className='act-button' onClick={() => this.triggerFunderModal(true)}>
                        <Icon type='form' />
                      </span>
                    </div>

                    <div className='detail'>
                      {funderInfo.phone_number
                        ? <div className='detail-item'>
                          <Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                          {funderInfo.phone_number}
                        </div>
                        : null}
                      {funderInfo.address
                        ? <div className='detail-item'>
                          <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                          {funderInfo.unit_building ? `${funderInfo.unit_building},` : ''}
                          {funderInfo.address}
                        </div>
                        : null}
                    </div>

                    {funderInfo.isInvalidFunder
                      ? <div>
                        <span className='name warning' style={{ marginTop: '15px' }}>
                          <Icon type='exclamation-circle' /> Invalid Funder. Please select a new one.
                        </span>
                      </div>
                      : null}
                  </div>}
              </div>
            </Col>
          </Row>

          <Panel
            className='info'
            title={`${durationPanelTitle}`}
            subtitle={<Row>
              <Col lg={24}>
                <div className='duration-text'>
                  {jobDurationText
                    ? <span>
                      <Icon type='clock-circle' />&nbsp;&nbsp;
                      {jobDurationText}
                    </span>
                    : null}
                </div>
              </Col>
            </Row>}
          >
            {validator.isNotEmptyArray(conflictJobListClient)
              ? <div style={{ marginBottom: '20px' }}>
                {conflictJobListClient.map((c) => {
                  const {
                    id,
                    job_start_date: jobStartDate,
                    job_end_date: jobEndDate,
                    employee_id: eid,
                    employee_fullname: empName,
                    client_id: cid,
                    client_fullname: clientName,
                    funder_fullName: fundName
                  } = c

                  const clientText = `that is serving ${clientName}`
                  return (
                    <div key={`cmfs-${id}`} style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                      <span className='name' style={{ color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}>
                        <Icon type='exclamation-circle' />
                        <a href={`${jobURL}/single/${id}/info`} target='_blank' style={{ color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold' }}>
                          &nbsp;A job {clientName} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}
                        </a> is conflicting.
                      </span>
                    </div>
                  )
                })}
              </div>
              : null}

            <Row>
              {isClientLeave && clientLeaveInfo
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {formatter.toShortDate(clientLeaveInfo.leave_start_date)} to {clientLeaveInfo.leave_is_ufn ? 'UFN' : formatter.toShortDate(clientLeaveInfo.leave_end_date)}</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null}

              {isHoliday && holidayInfo
                ? <div className='alert-block'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {`${holidayInfo.name} (${formatter.toShortDate(holidayInfo.date)})`}</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null}
              {isShowSleepoverAlert
                ? <div className='alert-block'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null}
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Recurring Start Date'>
                  {getFieldDecorator('recurring_start_date', {
                    initialValue: this.isEdit() ? moment(item.recurring_start_date) : null,
                    rules: [
                      { required: true, message: 'Please enter Recurring Start date' }
                    ]
                  })(
                    <DateTimePicker
                      showTime={false}
                      onChange={(date) => this.handleStartDateChange(date)}
                      disabled={this.isEdit()}
                    />
                  )}
                </FormItem>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={19}>
                    {isRecurringJobNeverEnd
                      ? <FormItem key={`rlsica`} {...sideBySideExtraFormItemLayout} label='Recurring End Date'>
                        {getFieldDecorator('recurring_end_date', {
                          initialValue: null
                        })(
                          <DateTimePicker
                            showTime={false}
                            onChange={(date) => this.handleEndDateChange(date)}
                            disabled
                          />
                        )}
                      </FormItem>
                      : <FormItem key={`rlsppa`} {...sideBySideExtraFormItemLayout} label='Recurring End Date'>
                        {getFieldDecorator('recurring_end_date', {
                          initialValue: this.isEdit() ? (item.recurring_end_date ? moment(item.recurring_end_date) : null) : null,
                          rules: [
                            { required: true, message: 'Please enter Recurring End date' },
                            { validator: this.validateEndDate }
                          ]
                        })(
                          <DateTimePicker
                            showTime={false}
                            onChange={this.handleEndDateChange}
                            disabled={(this.isEdit() ? false : !isRecurringJobHasStartDate)}
                          />
                        )}
                      </FormItem>}
                  </Col>
                  <Col lg={5}>
                    <FormItem {...specialFormItemLayoutToggle}>
                      {getFieldDecorator('is_recurring_never_end', {
                        initialValue: item.is_recurring_never_end || false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='Never&nbsp;End'
                          unCheckedChildren='Never&nbsp;End'
                          onChange={(e) => this.handleRecurringJobNeverEndChange(e)}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Job Start Time'>
                  {getFieldDecorator('job_start_date', {
                    initialValue: this.isEdit() ? moment(item.job_start_date) : null,
                    rules: [
                      { required: true, message: 'Please enter Job Start time' }
                    ]
                  })(
                    <DateTimePicker
                      showTime
                      showDate={false}
                      onChange={(time) => this.handleStartTimeChange(time)}
                      disabled={this.isEdit() ? false : !isRecurringJobHasStartDate}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Job End Time'>
                  {getFieldDecorator('job_end_date', {
                    initialValue: this.isEdit() ? moment(item.job_end_date) : null,
                    rules: [
                      { required: true, message: 'Please enter Job End time' }
                    ]
                  })(
                    <DateTimePicker
                      showTime
                      showDate={false}
                      onChange={(time) => this.handleEndTimeChange(time)}
                      disabled={this.isEdit() ? false : !isRecurringJobHasStartDate}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Frequency'>
                  {getFieldDecorator('interval', {
                    initialValue: item.interval || null,
                    rules: [
                      { required: true, message: 'Please select Frequency' }
                    ]
                  })(
                    <Select
                      disabled={this.isEdit()}
                      style={{ width: 150 }}
                      onChange={(value) => this.handleIntervalChange(value)}
                      showSearch
                      filterOption={this.findOption}>
                      <Option value='week'>Weekly</Option>
                      <Option value='fortnight'>Fortnightly</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel
            className='info'
            title='Shift Work'
            type={isNoEmployeeAlert ? 'warn' : ''}
          >
            <Row gutter={16} style={{ marginBottom: '15px' }}>
              <Col lg={24}>
                <div style={{ marginBottom: 10 }}>
                  { /** MS0021 pt2 : list employee conflicted job  */}
                  {validator.isNotEmptyArray(conflictJobListEmp)
                    ? <div style={{ marginBottom: '20px' }}>
                      {conflictJobListEmp.map((c) => {
                        const {
                          id,
                          job_start_date: jobStartDate,
                          job_end_date: jobEndDate,
                          employee_id: eid,
                          employee_fullname: empName,
                          client_id: cid,
                          client_fullname: clientName,
                          funder_fullName: fundName
                        } = c

                        const employeeText = `served by ${empName}`

                        return (
                          <Alert
                            key={`emfs-${id}`}
                            banner
                            message={
                              <div>
                                <a href={`${jobURL}/single/${id}/info`} target='_blank' className='error-block-text-dark'>A job {employeeText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.
                              </div>
                            }
                            type='error'
                            showIcon
                          />
                        )
                      })}
                    </div>
                    : null}

                  {isShowEmployeePrivateAlert || isShowEmployeePublicAlert
                    ? <div className='alert-block'>
                      <Alert
                        message={
                          <div>
                            <span style={{ fontWeight: 'bold' }}>{`Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`}</span>
                            {employeePublicAlert.length > 0
                              ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlert }} />
                              : null}
                            {employeePrivateAlert.length > 0
                              ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlert }} />
                              : null}
                          </div>
                        }
                        type='warning'
                        showIcon
                      />
                    </div>
                    : null}

                  {/* { isEmployeeLeave
                  ? <div className='alert-block'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                  : null } */}

                  {isShowEmpMaxHoursAlert
                    ? <div className='alert-block'>
                      <Alert
                        banner
                        type='error'
                        showIcon
                        message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${employeeInfo.first_name} ${employeeInfo.last_name} already has `}<span className='error-msg'>{employeeJobHoursInfo.employee_total_job_hours}</span>{` total job hours this week, including this job will be `}<span className='error-msg'>{formatter.toDecimalS(employeeJobHoursInfo.employee_new_total_job_hours)}</span>{` total job hours`}{employeeJobHoursInfo.is_employee_over_hour ? `, EXCEEDING` : '.'}{employeeJobHoursInfo.is_employee_has_max_hours ? <span className={employeeJobHoursInfo.is_employee_over_hour ? 'error-msg' : 'bold-msg'}>{` Max ${employeeJobHoursInfo.employee_max_hours} hours.`}</span> : ''}</div>}
                      />
                    </div>
                    : null}

                  {isMismatchLanguage
                    ? <div className='alert-block'>
                      <Alert
                        message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${mismatchedLanguageList.map(e => e.setting_name).join(', ')},
                        but ${employeeInfo.first_name} ${employeeInfo.last_name} does not. `} </div>}
                        type='error'
                        banner
                        showIcon
                      />
                    </div>
                    : null}

                  {isMismatchSkill
                    ? <div className='alert-block'>
                      <Alert
                        message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}  does not possess following skill(s): ${mismatchedSkillList.map(e => e.setting_name).join(', ')}`} </div>}
                        type='error'
                        banner
                        showIcon
                      />
                    </div>
                    : null}

                  {validator.isNotEmptyArray(employeeExpiredFiles)
                    ? (employeeExpiredFiles.map((file, idx) => {
                      return (
                        <div key={`epfs-${idx}`} className='alert-block'>
                          <Alert
                            message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                            type='error'
                            banner
                            showIcon
                          />
                        </div>
                      )
                    }))
                    : null}
                </div>
              </Col>
            </Row>

            <Form layout='vertical'>
              <Row gutter={16}>
                <Col lg={8}>
                  <FormItem label={<span>Shift Type
                    <Switch
                      disabled={!isSleepoverToggleEnabled}
                      checkedChildren='Sleepover'
                      unCheckedChildren='Sleepover?'
                      style={{ marginLeft: 10 }}
                      checked={isExtSleepover}
                      onChange={(value) => this.handleSleepover(value, false, true)}
                    />
                  </span>}>
                    {getFieldDecorator('category_id', {
                      initialValue: item.category_id || null,
                      rules: [
                        { required: true, message: 'Please Select Shift Type' }
                      ]
                    })(
                      <Select
                        onSelect={(v, o) => this.handleBillingSelectCategory(o)}
                        disabled={isExtSleepover || isDisableCategoriesSelect}
                        showSearch
                        filterOption={(input, option) =>
                          this.filterBillingCategory(input, option)
                        }>
                        {validator.isNotEmptyArray(billingCategoryListPreferred)
                          ? <OptGroup label={<div className='label-title'>----- PREFERRED -----</div>}>
                            {billingCategoryListPreferred.map((bill, idx) => {
                              return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsa-${idx}`} value={bill.id}>{bill.name}</Option> : null
                            })}
                          </OptGroup>
                          : null}
                        {validator.isNotEmptyArray(billingCategoryListOther)
                          ? <OptGroup label={<div className='label-title'>{validator.isNotEmptyArray(billingCategoryListPreferred) ? <p></p> : null}<p>----- OTHER -----</p></div>}>
                            {billingCategoryListOther.map((bill, idx) => {
                              return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsb-${idx}`} value={bill.id}>{bill.name}</Option> : null
                            })}
                          </OptGroup>
                          : null}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={8} style={{ marginTop: '8.5px' }}>
                  <FormItem label='Carer Skill Level Required'>
                    {getFieldDecorator('payroll', this.isEdit()
                      ? {
                        initialValue: item.payroll,
                        rules: [
                          { required: true, message: 'Please Select Carer Skill Level' }
                        ]
                      }
                      : {
                        initialValue: clientInfo.payroll_category,
                        rules: [
                          { required: true, message: 'Please Select Carer Skill Level' }
                        ]
                      })
                      (<Select
                        onChange={this.handlePayrollChange}
                        showSearch
                        filterOption={this.findOption}>
                        {settingsPayroll.map((pay) => {
                          return <Option key={pay.value}>{pay.name}</Option>
                        })}
                      </Select>
                      )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label={
                    <span>Employee <Switch
                      checkedChildren='Pending'
                      unCheckedChildren='Pending'
                      style={{ marginLeft: 10 }}
                      checked={isEmployeePending}
                      onChange={this.handleEmployeePending}
                    /></span>
                  } hasFeedback>
                    {getFieldDecorator('employee_id', {
                      initialValue: item.employee_id,
                      rules: [
                        !isEmployeePending ? { required: true, message: 'Please select an employee OR toggle Employee Pending' } : {}
                      ]
                    })(
                      <Select showSearch
                        style={{ width: '100%' }}
                        placeholder='Employee'
                        optionFilterProp='children'
                        notFoundContent='Not found'
                        filterOption={(input, option) => this.filterEmployees(input, option)}
                        onChange={this.handleEmployeeChange} disabled={isEmployeePending}>
                        {employeeList.map((items, idx) => {
                          return <Option key={`empls-${idx}`} value={items.id}>{items.first_name} {items.last_name} {items.leave_id ? <span className='job-option-leave-block'><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start_date)} - ${formatter.toShortDate(items.leave_end_date)}`}</span> : null}</Option>
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={8}>
                  <FormItem label='Tasks' hasFeedback>
                    {getFieldDecorator('tasks', {
                      initialValue: this.replacer(item.tasks),
                      rules: [
                        { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter tasks' }
                      ]
                    })(
                      <TextArea row={4} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label='Notes'>
                    {getFieldDecorator('notes', {
                      initialValue: this.replacer(item.notes),
                    })(
                      <TextArea row={4} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label='Max KM'>
                    {getFieldDecorator('job_kms', {
                      initialValue: this.isEdit() ? item.job_kms : 0,
                      rules: [
                        { validator: this.checkKMSInput }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Panel>

          {/* { isExtSleepover
          ? <Panel title='Sleepover'>
            <Form className='getup-panel'>
              <Row>
                <Col lg={24}>
                  <Row className='getup'>
                    <Col offset={4} xs={{ offset: 1 }} lg={22}>
                      <Row>
                        <Col xs={24} lg={3}><div className='getup-label-2'>Custom S/O</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_start_time', {
                              initialValue: item.custom_so_start_time ? moment(item.custom_so_start_time) : rateSetInfo.so_hours_start ? moment(rateSetInfo.so_hours_start) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={2} lg={1}><div className='getup-label-2'>to</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_end_time', {
                              initialValue: item.custom_so_end_time ? moment(item.custom_so_end_time) : rateSetInfo.so_hours_end ? moment(rateSetInfo.so_hours_end) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={9} lg={9}>
                          <FormItem>
                            {getFieldDecorator('is_custom_so_time', {
                              initialValue: isCustomSO || false
                            })(
                              <Switch
                                checkedChildren='Enabled Custom SO Time'
                                unCheckedChildren='Enable Custom SO Time?'
                                style={{ marginLeft: 10 }}
                                checked={isCustomSO}
                                onChange={this.handleSleepoverCustomSO}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Panel>
          : null } */}

          {validator.isNotEmptyArray(employeePrevList)
            ? <Panel title='Previous Carers'>
              <Row>
                <Col lg={24}>
                  {employeePrevList.map((employee) => (
                    <span key={`revemfs-${employee.id}`} className='employee-list-block' style={{ color: '#242b49' }}><a href={`/employees/${employee.id}/info`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.first_name} {employee.last_name}</a></span>
                  ))}
                </Col>
              </Row>
            </Panel>
            : null}

          {!this.isEdit()
            ? <Panel
              title='Files'
              subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
            >
              <List cols={fileColumns} rows={fileList} />
            </Panel>
            : null}

          {this.isEdit()
            ? <Panel title='Effective Date of Changes'>
              <Row>
                <Col lg={24}>
                  <div className='specific-date'>
                    <p><Icon type='exclamation-circle' /> To apply these updates after a specific date, add that date below.</p>
                    <Row>
                      <Col lg={12}>
                        <FormItem label='Effective From' {...shortFormItemLayout}>
                          {getFieldDecorator('effective_date', {
                            initialValue: null,
                            rules: [
                              { required: true, message: 'Please enter date' }
                            ]
                          })(
                            <DatePicker
                              format={dateFormat}
                              showTime={false}
                              defaultPickerValue={moment(new Date())}
                              onChange={this.handleEffectiveDateChange}
                              disabledDate={(value) => moment(new Date()).isAfter(value, 'day')}
                            />
                            // <DateTimePicker showTime={false} onChange={this.handleEffectiveDateChange} />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                  {/* <div className='apply-all-jobs'>
                    <Checkbox checked={isAllCurrentJobChecked} onChange={this.handleApplyAllAffectedJobs}>Apply these changes to all future jobs, even if they have been updated with different details to the base jobs. </Checkbox>
                    {loadingAffectedJobList ? <Icon type='loading' style={{ marginLeft: '8px', fontSize: '15pt' }} /> : null}
                  </div> */}

                  { /** -------------------------------------------------------------------- */}
                  {validator.isNotEmptyArray(affectedJobList)
                    ? <div className='info' style={{ marginTop: '8pt' }}>
                      {affectedJobList.map((c) => {
                        const { id, job_start_date: jobStartDate } = c

                        return (
                          <div key={`afcs-${id}`} className='error-block'>
                            <span className='error-block-text-light'>
                              <Icon type='exclamation-circle' />
                              <a href={`${jobURL}/single/${id}/info`} target='_blank' className='error-block-text-dark'>
                                &nbsp;&nbsp;The job on {formatter.toStandardLongDate(jobStartDate)}
                              </a> is individually updated before.
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    : null}
                </Col>
              </Row>
            </Panel>
            : null}

          {this.isEdit()
            ? <Panel title='All Recurring Single Jobs'>
              <Radio.Group onChange={(e) => this.filterAllJobList(e)} defaultValue={singleJobListType} style={{ marginLeft: 20 }}>
                <Radio.Button value={JobFilterType.JOB_TYPE_FUTURE_ALL}>Future</Radio.Button>
                <Radio.Button value={JobFilterType.JOB_TYPE_PAST_ALL}>Past</Radio.Button>
              </Radio.Group>

              <div style={{ marginTop: '20px' }}>
                <Skeleton loading={loadingJobList} active>
                  <div className='jobs-jvp'>
                    {validator.isNotEmptyArray(singleJobList)
                      ? singleJobList.map((item) => {
                        return <SingleJobView key={`jslc-${item.id}`} item={item} listType={JobListType.JOB_LIST_RECURRING} filterType={singleJobListType} />
                      })
                      : <div className='space-between'>
                        <img src={`${process.env.PUBLIC_URL}/img/table-nodata.png`} style={{ width: '200px', margin: '10px auto' }} />
                      </div>}
                  </div>
                </Skeleton>
              </div>
              <Pager
                size={pageSize}
                total={singleJobListTotal ? parseInt(singleJobListTotal) : 0}
                totalText={`Total ${singleJobListTotal} job${parseInt(singleJobListTotal) === 1 ? '' : 's'}`}
                current={singleJobListPage}
                onChange={(e) => this.handleSingleJobListPageChange(e)}
                style={{ marginTop: '15px' }}
              />
            </Panel>
            : null}
        </Loading>
      </div>
    )
  }

  /**
   * Section 1: Job Related Fetching
   */
  initialize = async () => {
    const { singleJobListType, singleJobListPage } = this.state
    const isEdit = this.isEdit()
    const jobId = this.getJobId()
    let clientId = null
    let funderId = null
    let employeeId = null

    this.fetchSettings()
    this.fetchSettingsCancellation()

    if (isEdit) {
      const item = await this.fetchRecurringJob()

      if (item && item.id) {
        clientId = item.client_id
        funderId = item.funder_id
        employeeId = item.employee_id
        this.renderDuration(item)
      }

      this.fetchSingleJobList(singleJobListType, 1)
      this.fetchUnsentComm(item.id)
      this.handleJobAffectedChecking(true, null, clientId)
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)
      clientId = client
      funderId = funder
    }

    this.setState({ clientId, employeeId, funderId }, () => {
      this.fetchClient(clientId, employeeId)
      this.fetchFunders(clientId, funderId, true)
      this.fetchEmployees(clientId, employeeId)
      this.fetchFileCategories()

      this.fetchPreviousEmployees(clientId)
    })
  }

  fetchRecurringJob = async () => {
    if (!this.isEdit()) return

    try {
      this.setState({ loading: true })
      const item = await jvpJobRecurringService.get(this.getJobId())
      const isCustomSO = !!item.is_custom_so_time
      const isEmployeePending = !item.employee_id && item.is_employee_pending === true
      const isExtSleepover = !!item.is_sleepover_job
      const isRecurringJobNeverEnd = !!item.is_recurring_never_end
      const isRecurringJobHasStartDate = !!item.recurring_start_date

      this.setState({
        item,
        itemOri: cloneDeep(item),
        isCustomSO,
        isEmployeePending,
        isRecurringJobHasStartDate,
        isRecurringJobNeverEnd,
        isExtSleepover,
        isRemoveJobsForSameDay: null,
        loading: false,
        clientId: item.client_id,
        employeeId: item.employee_id || null,
        funderId: item.funder_id
      }, () => {
        this.checkCommQuery()
      })

      return item
    } catch (e) {
      this.showFetchJobError()
      this.setState({ loading: false })
    }

    return null
  }

  fetchClient = async (clientId = null, employeeId = null) => {
    try {
      const cinf = await clientService.get(clientId)

      if (cinf && cinf.item && cinf.item.id) {
        const clientInfo = cinf.item
        const privateAlert = this.getAlertMsgHtml(clientInfo, 'private_alert')
        const publicAlert = this.getAlertMsgHtml(clientInfo, 'public_alert')
        const clientLanguages = clientInfo.preferences.languages || []
        const clientLanguagesName = clientInfo.preferences.languages_name || []
        const clientSkills = clientInfo.preferences.skills || []
        const clientSkillsName = clientInfo.preferences.skills_name || []
        const clashedClients = cinf.clashClient || null
        const clashedEmployees = cinf.clashEmp || null

        this.setState({
          clientInfo,
          clientPrivateAlert: privateAlert,
          clientPublicAlert: publicAlert,
          clientLanguages,
          clientLanguagesName,
          clientSkills,
          clientSkillsName,
          clashedClients,
          clashedEmployees
        }, () => {
          this.checkClient(clientId)
          // employeeId && this.checkEmployee(employeeId) // done in fetchEmployees()
          this.checkConflictJobs()
        })
      }
    } catch (e) {
      console.log('fetch client', e)
      this.showFetchClientError()
    }
  }

  fetchFunders = async (clientId, funderId, isUpdateFunderList = false) => {
    const { form, history } = this.props
    let funders = []
    let funderInfo = { id: null }
    let funderPeriods = []

    if (isUpdateFunderList) {
      funders = await fvpClientFundingService.listClientFunders(clientId)
    } else {
      funders = this.state.funderList
    }

    if (validator.isNotEmptyArray(funders)) {
      if (funderId) {
        const f = funders.find(e => e.funder_id === parseInt(funderId))

        if (f && f.id) {
          const info = await funderService.get(funderId)
          if (info && info.item && info.item.id) {
            funderInfo = info.item
          }
          const periods = await fvpClientFundingService.listClientFundingPeriod(clientId, funderId)

          if (validator.isNotEmptyArray(periods)) {
            funderPeriods = periods
          }
        } else {
          this.showInvalidFunderNotification()

          if (funderId) {
            const info = await funderService.get(funderId)
            if (info && info.item && info.item.id) {
              funderInfo = info.item
              funderInfo.isInvalidFunder = true
            }
          }
        }
      }
    }

    this.setState({
      funderInfo: Object.assign({}, funderInfo),
      prevFunderInfo: this.state.prevFunderInfo && this.state.prevFunderInfo.id ? this.state.prevFunderInfo : Object.assign({}, funderInfo),
      funderList: funders,
      funderPeriodList: funderPeriods
    }, () => {
      const { item } = this.state
      if (item && item.id) {
        this.fetchRateSetInfo(moment(new Date()))
        this.checkCategoriesAndUpdate(moment(new Date()))
      }
    })

    if (funderInfo && funderInfo.id && !validator.isNotEmptyArray(funderPeriods)) {
      this.alertUnassignedTime()
    }
  }

  fetchEmployees = async (clientId, employeeId) => {
    try {
      const emps = await clientService.getClientEmployees(clientId)
      this.setState({ employeeList: emps }, () => {
        setTimeout(() => {
          this.checkEmployee(employeeId, false)
        }, 1000)
      })
    } catch (e) {
      this.showFetchEmployeeError()
    }
  }

  fetchPreviousEmployees = async (clientId) => {
    try {
      const emps = await clientService.getClientPrevEmployeesActive(clientId)

      if (validator.isNotEmptyArray(emps)) {
        this.setState({ employeePrevList: emps })
      }
    } catch (e) {
      this.showFetchPrevEmployeeError()
    }
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loadingFileCats: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        fileMainCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        fileSubCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loadingFileCats: false
      })
    } catch (e) {
      this.showFetchFileCatsError()
    }
  }

  fetchRateSetInfo = async (jobStartDateTime) => {
    const { funderInfo } = this.state

    if (!funderInfo.isInvalidFunder) {
      try {
        const body = {
          date: jobStartDateTime,
          rate_id: funderInfo.fvp_rate_id
        }

        const r = await fvpRatesService.getDetails(body)
        if (r && r.id) {
          this.setState({ rateSetInfo: r })
        } else {
          this.setState({ rateSetInfo: {} })
        }
      } catch (e) {
        this.setState({ rateSetInfo: {} })
      }
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)


    this.setState({
      settingsAll: settings.list,
      settingsPayroll: settings.list.filter(item => item.type === 'payroll'),
      settingsOthers: settingOthers.item
    })
  }

  fetchSettingsCancellation = async () => {
    const settingsCancel = await settingCancellationService.getAllCancellations()
    const settingsCancelReason = await settingCancellationService.getAllCancellationReasons()

    this.setState({
      settingsCancelList: settingsCancel,
      settingsCancelListReasonAll: settingsCancelReason,
      settingsCancelListCurrentReason: []
    })
  }

  fetchSingleJobList = async (filterParam, startPage = null) => {
    const { singleJobListPage } = this.state
    const filter = {}

    this.setState({ loadingJobList: true })
    const page = startPage || singleJobListPage

    filter.base_job_id = { condition: '=', value: this.getJobId() }

    try {
      const { list, total } = await jvpJobRecurringService.listSingleJobsByPage(filterParam, page, pageSize, filter)

      this.setState({
        singleJobListType: filterParam,
        singleJobListPage: page,
        singleJobList: list,
        singleJobListTotal: total,
        loadingJobList: false
      }, async () => {
        const { list: singleJobAllList } = await jvpJobRecurringService.listSingleJobsByPage(JobFilterType.JOB_TYPE_ALL, 1, 0, filter)
        this.setState({ singleJobAllList })
      })
    } catch (e) {
      this.showLoadSingleJobErrror()
      this.setState({ loadingJobList: false, singleJobList: [], singleJobAllList: [] })
    }
  }

  fetchUnsentComm = async (jobId) => {
    if (!jobId) return
    const { prevUnsentCommTotal } = this.state
    const { unsent_total } = await jvpJobRecurringService.checkUnsentComm(jobId)

    // if it is redirected fron new job, prevUnsentCommTotal should be null, so set the unsentCommTotal and go to checkEmail
    // else if load from id, just update both values; or if after edit save, just update unsentCommTotal and prevUnsentCommTotal will be updated at checkEmail().
    if (prevUnsentCommTotal !== null) {
      this.setState({ unsentCommTotal: unsent_total }, () => {
        this.checkComm()
      })
    } else {
      this.setState({ unsentCommTotal: unsent_total, prevUnsentCommTotal: unsent_total })
    }
  }

  async refreshPage () {
    const { singleJobListType } = this.state
    this.fetchRecurringJob()
    const jobId = this.getJobId()
    await this.fetchUnsentComm(jobId)

    setTimeout(() => {
      this.checkComm()
    }, 600)

    this.fetchSettingsCancellation()
    this.fetchSingleJobList(singleJobListType)
    this.checkConflictJobs()

    // this.props.setRefreshActivityLog(true)
    // this.props.setRefreshCommLog(true)
  }

  /**
   * Section 2: Check Functions
   */

  checkCategoriesAndUpdate = (jobStartDateTime) => {
    const { form } = this.props
    const { isExtSleepover, funderPeriodList, isDisableCategoriesSelect: isl } = this.state
    let billingCategoryList = []
    let isDisableCategoriesSelect = isl

    if (jobStartDateTime) {
      /**
       * Update on 20220624: funderPeriodList will add new periods which has no period ID but having funder_id and valid category list.
       * this list is to indicate the admin select a job date that beyond of current client's plan but rate set did include those categories which are open to select
       * when this plan is selected, all categories will be in Other list, but no locking on shift type select.
       *
       * however, for recurring job, the jobStartDateTime will always follow current date. so unless current date does not have the list,
       *  or else the patterns as in single jobs will not happen.
       */

      const jsdt = moment.isMoment(jobStartDateTime) ? jobStartDateTime : moment(jobStartDateTime)
      const pd = funderPeriodList.find(e => {
        const std = moment(e.start_date)
        const etd = moment(e.end_date)
        return std.isBefore(jsdt) && etd.isAfter(jsdt)
      })

      const jsdt2 = moment(new Date())
      const pd2 = funderPeriodList.find(e => {
        const std = moment(e.start_date)
        const etd = moment(e.end_date)
        return std.isBefore(jsdt2) && etd.isAfter(jsdt2)
      })

      // check on pd.id (valid client funding) or pd.funder_id (category list out of client's funding periods) to enter the sequence
      if (pd && (pd.id || pd.funder_id) && validator.isNotEmptyArray(pd.category)) {
        billingCategoryList = pd.category
        isDisableCategoriesSelect = false
        if (!pd.id) this.showNoFundingPeriodNotification()
      } else if (pd2 && (pd2.id || pd2.funder_id) && validator.isNotEmptyArray(pd2.category)) {
        billingCategoryList = pd2.category
        isDisableCategoriesSelect = false
        this.showNoFundingPeriodNotification()
      } else {
        isDisableCategoriesSelect = true
        this.showNoFundingPeriodNotification()
      }

    } else {
      form.setFieldsValue({ category_id: undefined })
    }

    this.setState({
      isExtSleepover: jobStartDateTime ? isExtSleepover : false,
      billingCategoryList,
      isDisableCategoriesSelect
    }, () => {
      this.fetchRateSetInfo(jobStartDateTime)
    })
  }

  checkClient = async (clientId = null, employeeId = null, isUpdate = false) => {
    const { form } = this.props

    if (!clientId) return

    const rsd = form.getFieldValue('recurring_start_date') || null
    const red = form.getFieldValue('recurring_end_date') || null
    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')
    const isrec = form.getFieldValue('is_recurring_never_end') || false

    const jsdt = this.isEdit() ? moment(new Date()) : rsd ? moment(rsd) : null
    const jedt = isrec && red ? moment(red) : null

    const jst = jsd ? moment(jsd) : null
    const jet = jed ? moment(jed) : null

    // if (moment.isMoment(jsdt) && jsdt.isValid()) {
    //   const body = {
    //     recurring_start_date: rsd,
    //     recurring_end_date: red,
    //     job_start_date: jst,
    //     job_end_date: jet,
    //     is_recurring_never_end: isrec,
    //     client_id: clientId,
    //     employee_id: employeeId
    //   }

    //   this.setState({ loadingCheckClient: true })

    //   const info = await jvpJobRecurringService.validateRecurring(body)

    //   if (info && info.ts) {
    //     this.setState({ loadingCheckClient: false, clientCheckInfo: info })
    //   }
    // }


    this.checkClientLeave(clientId)
    this.checkHoliday(rsd)

    this.checkSleepover(jst, jet, isUpdate)
  }

  checkClientLeave = async (clientId) => {
    const { form } = this.props

    if (!clientId) return

    const rsd = form.getFieldValue('recurring_start_date') || null
    const red = form.getFieldValue('recurring_end_date') || null
    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')
    const isrec = form.getFieldValue('is_recurring_never_end') || false

    const rsdt = rsd ? moment(rsd) : null
    const redt = isrec && red ? moment(red) : null

    const body = {
      recurring_start_date: rsdt ? rsdt.format(dbFormat) : rsdt,
      recurring_end_date: redt ? redt.format(dbFormat) : redt,
      is_recurring_never_end: isrec,
      client_id: clientId
    }

    const info = await jvpJobRecurringService.validateClientLeave(body)

    if (info && info.ts) {
      const isClientLeave = !!info.leave_id
      this.setState({
        clientLeaveInfo: info,
        isClientLeave
      })
    }
  }

  checkEmployee = async (employeeId = null, isEmployeeChange = false) => {
    const { form } = this.props
    const { clientId } = this.state

    if (!employeeId) {
      this.setState({
        employeeInfo: {},
        employeePrivateAlert: '',
        employeePublicAlert: '',
        employeeLanguages: [],
        employeeSkills: []
      })

      return
    }

    const rsd = form.getFieldValue('recurring_start_date') || null
    const red = form.getFieldValue('recurring_end_date') || null
    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')
    const isrec = form.getFieldValue('is_recurring_never_end') || false

    const jsdt = this.isEdit() ? moment(new Date()) : rsd ? moment(rsd) : null
    const jedt = isrec && red ? moment(red) : null

    const jst = jsd ? moment(jsd) : null
    const jet = jed ? moment(jed) : null

    const { item: empInfo } = await employeeService.get(employeeId)

    const privateAlert = this.getAlertMsgHtml(empInfo, 'private_alert')
    const publicAlert = this.getAlertMsgHtml(empInfo, 'public_alert')
    const employeeLanguages = empInfo.preferences.languages || []
    const employeeSkills = empInfo.preferences.skills || []

    this.setState({
      employeeId: empInfo.id,
      employeeInfo: empInfo,
      employeePrivateAlert: privateAlert,
      employeePublicAlert: publicAlert,
      employeeLanguages,
      employeeSkills
    }, async () => {
      // alert check
      const isShowEmployeePrivateAlert = privateAlert.length > 0
      const isShowEmployeePublicAlert = publicAlert.length > 0

      // max hour check
      const employeeJobHoursInfo = await this.checkEmployeeWeeklyMaxHour(employeeId)
      const isShowEmpMaxHoursAlert = employeeJobHoursInfo.is_employee_has_max_hours !== undefined && employeeJobHoursInfo.is_employee_has_max_hours

      // skill mismatch check
      const mismatchList = this.checkSkillsMismatch()
      const isShowMismatchAlert = mismatchList.isMismatchLanguage || mismatchList.isMismatchSkill

      // leave check
      const leaveInfo = await this.checkEmployeeLeave(employeeId)
      const isShowLeaveAlert = leaveInfo && leaveInfo.ts && leaveInfo.leave_id

      const isInfoTab = this.state.currentTab === '1'

      if (isInfoTab &&
        // ((isEmployeeChange && (isShowEmployeePrivateAlert || isShowEmployeePublicAlert)) || isShowEmpMaxHoursAlert || isShowLeaveAlert || isShowMismatchAlert)) {
        isEmployeeChange) {
        const maxHourAlert = isShowEmpMaxHoursAlert
          ? `<br />
            <span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.employee_max_hours} hours</span>
            <span style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.employee_total_job_hours || 0} hours</span></span>
            <div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.employee_new_total_job_hours} hour${employeeJobHoursInfo.employee_new_total_job_hours === 1 ? '' : 's'}${employeeJobHoursInfo.is_employee_over_hour ? ` EXCEEDING` : ''}</span></div>
          `
          : ''

        const mismatchAlert = isShowMismatchAlert
          ? `<br /><div style="color: #f5222d; font-weight: bold;">
              Some mismatches are available.
              ${mismatchList.isMismatchLanguage ? `<br />Mismatch Languages: ${mismatchList.mismatchedLanguageList.map(e => e.setting_name).join(', ')}` : ''}
              ${mismatchList.isMismatchSkill ? `<br />Mismatch Skills: ${mismatchList.mismatchedSkillList.map(e => e.setting_name).join(', ')}` : ''}
            </div>`
          : ''

        const leaveAlert = isShowLeaveAlert
          ? `<br /><span style="color: #f5222d; font-weight: bold;">
            Leave is available. It starts from ${formatter.toShortDate(leaveInfo.leave_start_date)} to ${formatter.toShortDate(leaveInfo.leave_end_date)}.
            </span>`
          : ''

        warning({
          title: `Employee: ${empInfo.first_name} ${empInfo.last_name}`,
          content: (
            <div>
              {isShowEmployeePublicAlert
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null}
              {isShowEmployeePrivateAlert
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null}
              {isShowEmpMaxHoursAlert
                ? <div dangerouslySetInnerHTML={{ __html: maxHourAlert }} />
                : null}
              {isShowMismatchAlert
                ? <div dangerouslySetInnerHTML={{ __html: mismatchAlert }} />
                : null}
              {isShowLeaveAlert
                ? <div dangerouslySetInnerHTML={{ __html: leaveAlert }} />
                : null}
            </div>
          ),
          okText: 'OK',
          onOk () { },
        })
      }

      this.checkConflictJobs()
      this.checkEmployeeFilesExpiry(employeeId, rsd, red)
    })
  }

  checkEmployeeFilesExpiry = async (employeeId, rsd, red) => {
    const filter = {}
    const employeeExpiredFiles = []

    if (employeeId && rsd) {
      const recurringStartDate = !rsd ? null : moment.isMoment(rsd) ? rsd.clone() : moment(rsd)
      const recurringEndDate = !red ? moment(new Date()).add(90, 'days') : moment.isMoment(red) ? red.clone() : moment(red)

      filter.has_expiry = { condition: '=', value: 'true' }
      filter.active = { condition: '=', value: 'true' }
      filter.module = { condition: '=', value: 'employee' }
      filter.module_id = { condition: '=', value: employeeId }

      const list = await employeeFileService.listByPage(1, 0, filter)

      if (list && validator.isNotEmptyArray(list.list) && recurringStartDate) {
        const files = list.list
        for (let i = 0; i < files.length; i++) {
          const file = files[i]

          if (file.expiry_date) {
            if ((recurringStartDate && recurringStartDate.isAfter(file.expiry_date)) || (recurringEndDate && recurringEndDate.isAfter(file.expiry_date))) {
              employeeExpiredFiles.push(file)
            }
          }
        }
      }

      this.setState({ employeeExpiredFiles })
    }
  }

  checkEmployeeLeave = async (employeeId) => {
    const { form } = this.props

    const rsd = form.getFieldValue('recurring_start_date') || null
    const red = form.getFieldValue('recurring_end_date') || null
    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')
    const isrec = form.getFieldValue('is_recurring_never_end') || false

    const rsdt = this.isEdit() ? moment(new Date()) : rsd ? moment(rsd) : null
    const redt = isrec && red ? moment(red) : null

    if (!employeeId) return {}

    const body = {
      recurring_start_date: rsdt,
      recurring_end_date: redt,
      is_recurring_never_end: isrec,
      employee_id: employeeId
    }

    const info = await jvpJobRecurringService.validateEmployeeLeave(body)

    if (info && info.ts) {
      const isEmployeeLeave = !!info.leave_id
      this.setState({
        employeeLeaveInfo: info,
        isEmployeeLeave
      })
    } else {
      this.setState({
        employeeLeaveInfo: {},
        isEmployeeLeave: false
      })
    }

    return info
  }

  checkEmployeeWeeklyMaxHour = async (employeeId = null) => {
    const { form } = this.props

    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')

    if (!employeeId || !jsd || !jed) return

    const body = {
      job_start_date: jsd,
      job_end_date: jed,
      type: 'recurring',
      type_id: this.isEdit() ? this.getJobId() : null,
      employee_id: employeeId
    }

    const info = await jvpJobService.validateEmployeeMaxHour(body)

    if (info && info.ts) {
      this.setState({ employeeJobHoursInfo: info })

      return info
    }

    return {}
  }

  checkConflictJobs = () => {
    setTimeout(async () => {
      const { clientId = null, item } = this.state
      const { form } = this.props

      const isRecurringJobNeverEnd = form.getFieldValue('is_recurring_never_end')
      const sd = form.getFieldValue('recurring_start_date')
      const ed = form.getFieldValue('recurring_end_date')
      const jst = form.getFieldValue('job_start_date')
      const jet = form.getFieldValue('job_end_date')
      const jobBaseId = this.getJobId()

      const firstDate = sd && moment.isMoment(sd) && sd.isValid() ? sd.format('YYYY-MM-DD') : sd
      const lastDate = ed && moment.isMoment(ed) && ed.isValid() ? ed.format('YYYY-MM-DD') : ed

      const jobStartTime = jst && moment.isMoment(jst) && jst.isValid() ? jst.format('HH:mm:ss') : jst
      const jobEndTime = jet && moment.isMoment(jet) && jet.isValid() ? jet.format('HH:mm:ss') : jet
      const frequency = form.getFieldValue('interval')
      const employeeId = form.getFieldValue('employee_id') || ''

      if (clientId && firstDate && jobStartTime && jobEndTime && frequency) {
        const body = {
          clientId: clientId,
          employeeId: employeeId,
          startDate: firstDate,
          endDate: lastDate,
          startTime: jobStartTime,
          endTime: jobEndTime,
          frequency,
          jobBaseId: jobBaseId === 'add' ? '' : jobBaseId
        }

        const r = await jvpJobRecurringService.checkRecurringConflictJobs(body)

        if (r && validator.isNotEmptyArray(r.job_list)) {
          const conflictJobList = r.job_list || []
          const conflictJobListClient = conflictJobList.filter(e => parseInt(e.client_id) === parseInt(clientId))
          const conflictJobListEmp = employeeId ? conflictJobList.filter(e => parseInt(e.employee_id) === parseInt(employeeId)) : []
          this.setState({
            conflictJobList,
            conflictJobListClient,
            conflictJobListEmp,
            conflictJobErrorMsg: null
          })
        } else {
          this.setState({
            onflictJobList: [],
            conflictJobListClient: [],
            conflictJobListEmp: [],
            conflictJobErrorMsg: 'Unable to validate conflict jobs.'
          })
        }
      }
    }, TIMEOUT)
  }

  checkComm = (isForcedTrigger = false) => {
    const that = this
    const { prevUnsentCommTotal, unsentCommTotal } = this.state

    if ((prevUnsentCommTotal === null && unsentCommTotal > 0) ||
      (prevUnsentCommTotal !== null && unsentCommTotal > 0 && prevUnsentCommTotal < unsentCommTotal) ||
      isForcedTrigger === true) {
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.handleTabChange('2')
        },
        onCancel () {

        }
      })

      // update on prevUnsentCommTotal after confirm pop up appears
      // set the prevUnsentCommTotal only when isForcedTrigger is false. If didnt check, the popup will show two times becos of prevUnsentCommTotal available
      if (!isForcedTrigger) {
        this.setState({ prevUnsentCommTotal: unsentCommTotal })
      }
    }
  }

  checkCommQuery = () => {
    const { history, location } = this.props
    if (location && location.search) {
      const queries = common.getQueryStringSearchParams(location.search)

      if (queries && queries['comm'] && queries['comm'] === 'true') {
        this.checkComm(true)
        if (location && location.pathname) {
          history.replace(`${location.pathname}`)
        }
      }
    }
  }

  checkHoliday = async (startDate) => {
    const rsd = !startDate ? null : moment.isMoment(startDate) ? startDate : moment(startDate)

    if (rsd) {
      const date = rsd.format(dbFormat)
      const holiday = await settingHolidayService.getTodayHoliday(date)

      if (holiday && validator.isNotEmptyArray(holiday.list)) {
        this.setState({ isHoliday: true, holidayInfo: holiday.list[0] })
        return true
      } else {
        this.setState({ isHoliday: false, holidayInfo: null })
        return false
      }
    }

    return false
  }

  checkJobLessThan2Hrs = (jobStartDate, jobEndDate) => {
    const differentHours = formatter.toDurationDiff(jobStartDate, jobEndDate, 'hours')

    if (parseInt(differentHours) < JobTriggerAlertHours) {
      warning({
        title: `This job's duration is less than ${JobTriggerAlertHours} hours.`
      })
    }
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSkillsMismatch = () => {
    const { clientInfo, employeeId, employeeInfo } = this.state

    let info = {
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: []
    }

    if (!employeeId) {
      // empty action
    } else {
      let mismatchedLanguageList = []
      let mismatchedSkillList = []

      const clientLanguages = clientInfo.preferences.languages_list || []
      const clientSkills = clientInfo.preferences.skills_list || []
      const empLanguages = employeeInfo.preferences.languages_list || []
      const empSkills = employeeInfo.preferences.skills_list || []

      if (validator.isNotEmptyArray(clientLanguages)) {
        mismatchedLanguageList = cloneDeep(clientLanguages).filter(e => empLanguages.findIndex(f => f.setting_id === e.setting_id) < 0)
      }

      if (validator.isNotEmptyArray(clientSkills)) {
        mismatchedSkillList = cloneDeep(clientSkills).filter(e => empSkills.findIndex(f => f.setting_id === e.setting_id) < 0)
      }

      info = {
        isMismatchLanguage: !!validator.isNotEmptyArray(mismatchedLanguageList),
        isMismatchSkill: !!validator.isNotEmptyArray(mismatchedSkillList),
        mismatchedLanguageList,
        mismatchedSkillList
      }
    }

    this.setState({
      isMismatchLanguage: info.isMismatchLanguage,
      isMismatchSkill: info.isMismatchSkill,
      mismatchedLanguageList: info.mismatchedLanguageList,
      mismatchedSkillList: info.mismatchedSkillList
    })

    return info
  }

  checkSingleJobsDelete = async () => {
    const { item, singleJobAllList } = this.state
    const { form } = this.props

    const startDate = form.getFieldValue('recurring_start_date')
    const endDate = form.getFieldValue('recurring_end_date')
    const rsd = !startDate ? null : moment.isMoment(startDate) ? startDate : moment(startDate)
    const red = !endDate ? null : moment.isMoment(endDate) ? endDate : moment(endDate)
    const pred = !item.recurring_end_date ? null : moment(item.recurring_end_date)
    const isNeverEnd = form.getFieldValue('is_recurring_never_end')

    let affectedJobs = []

    if (this.isEdit()) {
      /**
       * case 1: previous item is never end = true, about to update is never end = false and with a new recurring end date
       * case 2: current is never end = false, previously has a recurring and date and now a new recurring end date is before the old one
       * case 3: current is never end = false, previously no recurring end date and now has a new recrring end date
       * */
      if (item.is_recurring_never_end === false && isNeverEnd === true && red) {
        affectedJobs = singleJobAllList.filter(item => moment(item.job_end_date).isAfter(red))
      } else if (isNeverEnd === false && pred && red.isBefore(pred)) {
        affectedJobs = singleJobAllList.filter(item => moment(item.job_end_date).isAfter(red))
      } else if (isNeverEnd === false && !pred && red) {
        affectedJobs = singleJobAllList.filter(item => moment(item.job_end_date).isAfter(red))
      }
    }

    return affectedJobs
  }

  checkSleepover = (start, end, isUpdate) => {
    const { isExtSleepover, rateSetInfo } = this.state
    const format = 'YYYY-MM-DD HH:mm:ss'

    // only process if rateSetInfo is available, because need to get AH Hours
    if (rateSetInfo && rateSetInfo.id && isUpdate) {
      const std = !start ? null : moment.isMoment(start) ? start : moment(start)
      const etd = !end ? null : moment.isMoment(end) ? end : moment(end)

      if (std && etd) {
        const isSameDay = std.isSame(etd, 'day')
        const durations = moment.duration(Math.abs(end.diff(start))).asMinutes()

        // get after hours moment obj
        const saah = rateSetInfo.after_hours_start ? moment(rateSetInfo.after_hours_start) : null
        const eaah = rateSetInfo.after_hours_end ? moment(rateSetInfo.after_hours_end) : null

        // set valid AH hours based on the setting. for AH end time, if the start&end dates are same day, the AH end time shall be on the next day.
        const sah = saah ? std.clone().set('hours', saah.get('hours')).set('minutes', saah.get('minutes')) : null
        const eah = eaah ? (isSameDay ? etd.clone().add(1, 'day').set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes')) : etd.clone().set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes'))) : null

        // get the 12:00AM moment object of the day, if same day, then it should be end of the day (next day 12.00AM), else it would be end date's start of the day.
        const eth = isSameDay ? etd.clone().endOf('day').add(1, 'second') : etd.clone().startOf('day')

        let ovlDuration = 0

        if (sah && eah) {
          // case for same day:
          // if job end time is before start of AH: no possibilities for auto sleepover detection since it is out of AH duration
          // if job end time is after start of AH: check on job start time is whether before or after start of AH.
          // if before, get the duration between start of AH and job end time
          // if after, get the duration between job start time and job end time (i.e. job duration is within the period of AH but before the day ends)
          if (isSameDay) {
            if (!etd.isBefore(sah)) {
              if (std.isBefore(sah)) {
                ovlDuration = moment.duration(Math.abs(etd.diff(sah))).asMinutes()
              } else {
                ovlDuration = moment.duration(Math.abs(etd.diff(std))).asMinutes()
              }
            }
          } else {
            // case for diff day:
            // for day 1, check job start time is before/after start of AH
            // if before, get the duration between start of AH and end of day
            // if after, get the duration bewteen job start date and end of day
            // for day 2, check job end time is before/after end of AH
            // if before, get the duration between start of AH and end of day
            // if after, get the duration bewteen job start date and end of day
            let d1c = 0
            let d2c = 0
            if (std.isBefore(sah)) {
              d1c = moment.duration(Math.abs(sah.diff(eth))).asMinutes()
            } else {
              d1c = moment.duration(Math.abs(std.diff(eth))).asMinutes()
            }

            if (etd.isAfter(eah)) {
              d2c = moment.duration(Math.abs(eth.diff(eah))).asMinutes()
            } else {
              d2c = moment.duration(Math.abs(eth.diff(etd))).asMinutes()
            }

            ovlDuration = d1c + d2c
          }
        }

        if (durations > JobSleepoverDetectionMinutes && ovlDuration > JobSleepoverDetectionMinutes) {
          if (std.isBefore(etd)) {
            this.handleSleepover(isUpdate ? true : isExtSleepover, true)
          }
        } else {
          this.handleSleepover(false, false, isUpdate)
        }
      }
    }
  }

  findOption = (input, option) => {
    const pvd = `${option.props.children}`
    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  /**
   * Section 3: UI change handling
   */
  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Funding Period Not Defined!',
      content: <div><p>Please assign a funding period for this funder before proceeding.</p> <p>Click <b>Go to Client Page</b> to be redirected to the client page or <b>Continue</b> to choose another Funder.</p></div>,
      okText: 'Go To Client Page',
      cancelText: 'Continue',
      onOk () {
        history.replace(`/clients/${clientId}/info`)
      },
      onCancel () {
      }
    })
  }

  getAlertMsgHtml = (info = {}, key) => {
    if (validator.isNotEmptyObject(info) && info[key] !== undefined) {
      const alert = info[key] !== null && !validator.isEmptyString(info[key]) ? info[key] : ''
      let alertHtml = alert.replace(/(?:\r\n|\r|\n)/g, '<br />')

      if (alertHtml) {
        if (key === 'public_alert') {
          alertHtml = `<strong><ins>${alertHtml}</ins></strong>`
        } else if (key === 'private_alert') {
          alertHtml = `<i>${alertHtml}</i>`
        }
      }

      return alertHtml
    }

    return ''
  }

  handleEditButton = () => {
    this.setState({ isShowSave: true })
  }

  handleTabChange = (index) => {
    const id = this.getJobId()
    const tab = TabList.find(e => e.tabId === parseInt(index))

    this.setState({ currentTab: index })
    if (tab && tab.tabId) {
      this.props.history.replace(`${jobURL}/recurring/${id}${tab.path}`)
      if (tab.tabId === 1) {
        this.fetchRecurringJob()
      }
    }
  }

  filterAllJobList (e) {
    this.setState({ singleJobListPage: 1 })
    this.fetchSingleJobList(e.target.value, 1)
  }

  filterBillingCategory = (input, option) => {
    const { children } = option.props

    if (option.type.isSelectOptGroup) {
      return children.includes(child => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterEmployees = (input, option) => {
    const emp = `${option.props.children[0]} ${option.props.children[2]}`
    return emp.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  resetEmployeeDetails = (callback = () => { }) => {
    this.setState({
      employeeInfo: {},
      employeeExpiredFiles: [],
      employeeJobHoursInfo: {},
      employeePrivateAlert: '',
      employeePublicAlert: '',
      employeeLanguages: [],
      employeeSkills: [],
      isEmployeeOnLeave: false,
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: []
    }, callback && callback())
  }

  handleEmployeeChange = async (value) => {
    this.resetEmployeeDetails(() => {
      this.checkEmployee(value, true)
      this.checkConflictJobs()
    })
  }

  handleEmployeePending = (value) => {
    const { form } = this.props
    this.setState({ isEmployeePending: value })

    if (value) {
      this.resetEmployeeDetails()
      form.setFieldsValue({ employee_id: null })
    } else {
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleStartDateChange = (std) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state

    const etd = form.getFieldValue('recurring_end_date') || null
    const startDate = moment.isMoment(std) ? std.clone() : moment(std)
    const endDate = !etd ? null : moment.isMoment(etd) ? etd.clone() : moment(etd)
    const hourStart = moment(new Date()).startOf('hour').hour()

    const jobStartDate = startDate.clone().set('hour', hourStart).set('minute', 0)
    const jobEndDate = jobStartDate.clone().add(2, 'hour')

    const newJob = { job_start_date: jobStartDate, job_end_date: jobEndDate }
    form.setFieldsValue(newJob)

    this.renderDuration(newJob)

    setTimeout(() => {
      /**
       * BREAKDOWN UPDATE: if THE detail breakdown is activated, activate the this.renderDuration(newJob) below and remove the one at above
       */
      // this.renderDuration(newJob)
      this.checkClient(clientId, employeeId, true)
      this.checkEmployeeWeeklyMaxHour(employeeId)
    }, TIMEOUT)

    this.checkCategoriesAndUpdate(moment(new Date()))
    this.checkEmployeeFilesExpiry(employeeId, startDate, endDate)

    this.setState({ isRecurringJobHasStartDate: true })
  }

  handleEndDateChange = (etd) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state
    const that = this

    const std = form.getFieldValue('recurring_start_date') || null
    const startDate = !std ? null : moment.isMoment(std) ? std.clone() : moment(std)
    const endDate = moment.isMoment(etd) ? etd.clone() : moment(etd)
    const now = moment(new Date())

    const continueFunction = () => {
      setTimeout(() => {
        this.checkClient(clientId, employeeId)
        this.checkEmployeeWeeklyMaxHour(employeeId)
        this.checkConflictJobs()
      }, TIMEOUT)

      this.checkEmployeeFilesExpiry(employeeId, startDate, endDate)
    }
    let isRemoveJobsForSameDay = false

    // function to check if startDate same day with endDate, toggle a special switch to enable to remove all single jobs
    if (this.isEdit() && startDate && endDate && startDate.isSame(endDate, 'date') && now.isSameOrBefore(startDate, 'date')) {
      // console.log('handle end date change 3')
      // warning({
      //   title: 'All Single Jobs Will Be Removed!',
      //   content: `You are about to remove all single jobs under the series. To cancel the action, set the recurring end date with a different date or toggle Never End.`,
      //   okText: 'OK',
      //   onOk () {
      //     that.setState({ isRemoveJobsForSameDay: true })
      //     continueFunction()
      //   }
      // })
      isRemoveJobsForSameDay = true
    }

    this.setState({ isRemoveJobsForSameDay })
    continueFunction()
  }

  validateEndDate = (rule, value, callback) => {
    const { form } = this.props

    const rsd = form.getFieldValue('recurring_start_date')
    const red = moment.isMoment(value) ? value.clone() : moment(value).clone()
    const now = moment(new Date())

    if (red.isBefore(rsd)) {
      callback(new Error('Recurring End Date could not be earlier than Recurring Start Date.'))
    } else if (red.isBefore(now, 'date')) {
      callback(new Error('Recurring End Date could not be earlier than today.'))
    } else {
      callback()
    }
  }

  handleStartTimeChange = (jsd) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state

    const std = form.getFieldValue('recurring_start_date') || null
    const startDate = !std ? null : moment.isMoment(std) ? std.clone() : moment(std)

    const jed = form.getFieldValue('job_end_date')
    const jobStartDate = moment.isMoment(jsd) ? jsd.clone() : moment(jsd)
    let jobEndDate = null

    jobStartDate.set('second', 0).set('millisecond', 0)
    if (startDate) {
      jobStartDate.set('year', startDate.year()).set('month', startDate.month()).set('date', startDate.date())
    }

    if (!jed) {
      // if no job end date, assign one by default
      jobEndDate = jobStartDate.clone().add(2, 'hour')
      form.setFieldsValue({ job_end_date: jobEndDate })
    } else {
      jobEndDate = moment.isMoment(jed) ? jed.clone() : moment(jed)
    }

    form.setFieldsValue({ job_start_date: jobStartDate })

    // no need renderDuration because handleJobTimeValues will do so after checking job start date and job end date
    this.handleJobTimeValues(jobStartDate, jobEndDate)

    setTimeout(() => {
      this.checkClient(clientId, employeeId)
      this.checkEmployeeWeeklyMaxHour(employeeId)
    }, TIMEOUT)

    // console.log('job start date change', jobStartDate, jobEndDate)
    this.checkConflictJobs()
  }

  handleEndTimeChange = (jed) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state

    const std = form.getFieldValue('recurring_start_date') || null
    const startDate = !std ? null : moment.isMoment(std) ? std.clone() : moment(std)

    const jsd = form.getFieldValue('job_start_date')
    const jobStartDate = !jsd ? null : moment.isMoment(jsd) ? jsd.clone() : moment(jsd)

    const jobEndDate = moment.isMoment(jed) ? jed.clone() : moment(jed)

    jobEndDate.set('second', 0).set('millisecond', 0)
    if (startDate) {
      jobEndDate.set('year', startDate.year()).set('month', startDate.month()).set('date', startDate.date())
    }

    form.setFieldsValue({ job_end_date: jobEndDate })

    // no need renderDuration because handleJobTimeValues will do so after checking job start date and job end date
    this.handleJobTimeValues(jobStartDate, jobEndDate)
    this.checkJobLessThan2Hrs(jobStartDate, jobEndDate)

    setTimeout(() => {
      this.checkClient(clientId, employeeId)
      this.checkEmployeeWeeklyMaxHour(employeeId)
    }, TIMEOUT)

    // console.log('job start date change', jobStartDate, jobEndDate)

    this.checkConflictJobs()
  }

  handleJobTimeValues = (jsd, jed) => {
    // if the job start date is > job end date, then need to add job end date with 1 day so it will be extended to next day
    // but if job start date is < job end date, switch back the job end date to be same day with recurring start date
    const { form } = this.props
    // jsd and jed must be moment object
    const jobStartDate = moment.isMoment(jsd) ? jsd.clone() : moment(jsd)
    const jobEndDate = moment.isMoment(jed) ? jed.clone() : moment(jed)

    if (jobStartDate.isAfter(jobEndDate)) {
      jobEndDate.add(1, 'day')
      form.setFieldsValue({ job_end_date: jobEndDate })
    } else {
      const std = form.getFieldValue('recurring_start_date') || null
      const startDate = !std ? null : moment.isMoment(std) ? std.clone() : moment(std)
      jobEndDate.set('year', startDate.year()).set('month', startDate.month()).set('date', startDate.date())
    }

    this.checkJobLessThan2Hrs(jobStartDate, jobEndDate)
    this.renderDuration({ job_start_date: jobStartDate, job_end_date: jobEndDate })
  }

  handleRecurringSameDayChange = (value) => {
    const { form } = this.props
    this.setState({ isRemoveJobsForSameDay: value })
  }

  handleRecurringJobNeverEndChange = (value) => {
    const { form } = this.props
    this.setState({ isRecurringJobNeverEnd: value, isRemoveJobsForSameDay: false })

    form.setFieldsValue({ recurring_end_date: null })
    this.checkConflictJobs()
  }

  handleIntervalChange = () => {
    this.checkConflictJobs()
  }

  handleSleepover = (value, isShowSleepoverAlert = false, isUpdate = false) => {
    const { form } = this.props
    const { billingCategoryList } = this.state
    const that = this

    if (isShowSleepoverAlert) {
      confirm({
        title: 'Is This A Sleepover Job?',
        content: `This job has more than 4 After Hours.`,
        okText: 'Yes',
        cancelText: 'Not Sleepover',
        onOk () {
          const ct = billingCategoryList.find(e => e.is_sleepover === true)
          if (ct && ct.id) {
            form.setFieldsValue({ category_id: ct.id })

            that.setState({ isExtSleepover: true, isShowSleepoverAlert })
          }
        },
        onCancel () {
          form.setFieldsValue({ category_id: undefined })
          that.setState({ isExtSleepover: false, isShowSleepoverAlert })
        }
      })
    } else {
      if (value === false) {
        if (isUpdate) {
          form.setFieldsValue({ category_id: undefined })
        }
      } else {
        const ct = billingCategoryList.find(e => e.is_sleepover === true)
        if (ct && ct.id) {
          form.setFieldsValue({ category_id: ct.id })
        }
      }

      this.setState({ isExtSleepover: value, isShowSleepoverAlert })
    }

    setTimeout(() => {
      this.renderDuration({ job_start_date: form.getFieldValue('job_start_date'), job_end_date: form.getFieldValue('job_end_date') })
      // this.checkSleepoverMismatch()
    }, 100)
  }

  handleSleepoverCustomSO = (value) => {
    const { item, rateSetInfo } = this.state
    const { form } = this.props
    if (value) {
      if (item.custom_so_start_time) {
        form.setFieldsValue({ custom_so_start_time: moment(item.custom_so_start_time) })
      }

      if (item.custom_so_end_time) {
        form.setFieldsValue({ custom_so_end_time: moment(item.custom_so_end_time) })
      }
    } else {
      if (rateSetInfo && rateSetInfo.id) {
        form.setFieldsValue({ custom_so_start_time: moment(rateSetInfo.so_hours_start), custom_so_end_time: moment(rateSetInfo.so_hours_end) })
      }
    }

    this.setState({ isCustomSO: value })
  }

  handleBillingSelectCategory (option) {

  }

  handleEffectiveDateChange = (e) => {
    const date = moment.isMoment(e) ? e.clone().startOf('day') : e
    this.handleJobAffectedChecking(true, e)
  }

  handleJobAffectedChecking = async (isAppliedAll, dateFrom, cId = null) => {
    if (this.isEdit()) {
      if (isAppliedAll) {
        this.setState({ loadingAffectedJobList: true })
        const clientId = cId || this.state.clientId
        const jobBaseId = this.getJobId()
        const body = {
          client_id: clientId,
          base_job_id: jobBaseId,
          effective_date: dateFrom
        }

        const r = await jvpJobRecurringService.checkAffectedSingleJobs(body)

        this.setState({
          affectedJobList: r && validator.isNotEmptyArray(r.job_list) ? r.job_list : [],
          affectedJobErrorMsg: r && validator.isNotEmptyArray(r.job_list) ? null : 'Unable to look for affected jobs.',
          loadingAffectedJobList: false
        })
      } else {
        this.setState({
          affectedJobList: [],
          affectedJobErrorMsg: null,
          loadingAffectedJobList: false
        })
      }
    }
  }

  handlePayrollChange = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleSingleJobListPageChange = async (currentPage) => {
    const { singleJobListType } = this.state
    this.fetchSingleJobList(singleJobListType, currentPage)
  }

  /** BREAKDOWN UPDATE: the original renderDuration function with simple info for recurring jobs **/
  renderDuration = (item) => {
    const { isExtSleepover } = this.state
    const periodText = formatter.toDurationTextFormat(item.job_start_date, item.job_end_date)
    const durationText = formatter.toDurationDiff(item.job_start_date, item.job_end_date)
    this.setState({ jobDurationText: isExtSleepover ? `1 Unit S/O` : `${periodText} (${durationText} Hour${durationText === 1 ? '' : 's'})` })
  }

  /**
   * BREAKDOWN UPDATE:
   * pending features which show the actual breakdown (with A/H calculation) based on navigated date
   * the function will find the nearest job date from today and render breakdown info similar with single jobs instead of normal hours count and this function will ignore holiday setting so it will purely show the breakdown based on day of week
   * */
  // renderDuration = async (item) => {
  //   const { form } = this.props
  //   const { getFieldValue } = form
  //   const { isExtSleepover, funderId } = this.state

  //   const rsd = item.recurring_start_date ? moment(item.recurring_start_date) : getFieldValue('recurring_start_date') ? moment(getFieldValue('recurring_start_date')) : null
  //   const jsd = getFieldValue('job_start_date') || null
  //   const jed = getFieldValue('job_end_date') || null

  //   if (rsd && jsd && jed) {
  //     const today = moment(new Date()).endOf('day')
  //     const dayDiff = Math.abs(today.diff(rsd, 'day'))
  //     const genRemainder = dayDiff % 7
  //     const countDayToNextJob = genRemainder === 0 ? 0 : 7 - genRemainder

  //     const nextJobDate = today.clone().add(countDayToNextJob, 'day')
  //     const jsdh = jsd.clone().get('hours')
  //     const jsdm = jsd.clone().get('minutes')
  //     const jedh = jed.clone().get('hours')
  //     const jedm = jed.clone().get('minutes')

  //     const startDate = nextJobDate.clone().set('hours', jsdh).set('minutes', jsdm).set('seconds', 0).set('milliseconds', 0)
  //     const endDate = nextJobDate.clone().set('hours', jedh).set('minutes', jedm).set('seconds', 0).set('milliseconds', 0)

  //     if (endDate.isBefore(startDate)) {
  //       endDate.add(1, 'day')
  //     }

  //     const breakdown = new DurationBreakdown(item.funder_id || funderId, false, false, isExtSleepover)
  //     const durationBreakdown = await breakdown.get(startDate, endDate)

  //     if (durationBreakdown) {
  //       this.setState({ jobDurationText: durationBreakdown.breakdown})
  //     } else {
  //       this.setState({ jobDurationText: 'Invalid Job Time'})
  //     }
  //   }
  // }

  // comm related
  handleCommUpdate = (value) => {
    this.setState({ unsentCommTotal: value })
  }

  // funder modal related
  triggerFunderModal = (isShowFunderModal) => {
    this.setState({ isShowFunderModal })
  }

  handleFunderSubmit = async () => {
    const { clientId, funderId: currentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['funder_id'], async (errors, values) => {
      if (currentFunderId !== parseInt(values.funder_id)) {
        this.setState({ funderInfo: {}, funderId: null, isShowFunderModal: false })
        form.setFieldsValue({ category_id: undefined })
        this.fetchFunders(clientId, values.funder_id)
      }
    })
  }

  handleFunderCancel = async () => {
    const { form } = this.props
    form.setFieldsValue({ funder_id: null })
    this.setState({ isShowFunderModal: false })
  }

  // employee change modal related
  triggerEmployeeChangeModal = (isShowEmployeeReasonModal) => {
    const { form } = this.props
    form.setFieldsValue({
      employee_change_reason_type_id: null,
      employee_change_reason_id: null,
      employee_change_other_reason: null,
      employee_change_note: null
    })

    this.setState({
      isShowEmployeeReasonModal,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false,
      settingsCancelListCurrentReason: []
    })
  }

  handleEmployeeCancelTypeChange = async (id) => {
    const { settingsCancelListReasonAll } = this.state

    const selectedReasons = settingsCancelListReasonAll.filter(e => e.cancellation_id === id)
    this.setState({
      settingsCancelListCurrentReason: selectedReasons
    })
  }

  handleEmployeeChangeReasonChange = async (id) => {
    const { settingsCancelListCurrentReason } = this.state
    let isEmployeeChangeReasonOther = false

    const rs = settingsCancelListCurrentReason.find(e => e.id === id)
    if (rs && rs.id) {
      if (rs.name.trim().toLowerCase() === 'other') {
        isEmployeeChangeReasonOther = true
      }
    }

    this.setState({ isEmployeeChangeReasonOther })
  }

  handleSubmitEmployeeChangeReason = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { settingsCancelList, settingsCancelListCurrentReason } = this.state
    const that = this

    validateFieldsAndScroll(['employee_change_reason_id', 'employee_change_other_reason', 'employee_change_note', 'employee_change_reason_type_id'],
      async (errors, values) => {
        if (!errors) {
          const rst = settingsCancelList.find(e => e.id === values.employee_change_reason_type_id)
          const rsc = settingsCancelListCurrentReason.find(e => e.id === values.employee_change_reason_id)
          this.setState({
            isShowEmployeeReasonModal: false,
            employeeChangeReasonId: values.employee_change_reason_id,
            employeeChangeReasonName: rsc && rsc.id ? rsc.name : null,
            employeeChangeReasonTypeId: values.employee_change_reason_type_id,
            employeeChangeReasonTypeName: rst && rst.id ? rst.name : null,
            employeeChangeOtherReason: values.employee_change_other_reason,
            employeeChangeNote: values.employee_change_note,
            isEmployeeChangeDone: true
          })
          // Proceed to checkBeforeSave
          setTimeout(() => {
            this.checkBeforeSave()
          }, TIMEOUT)
        }
      })
  }

  resetEmployeeChangeValues = () => {
    const { form } = this.props
    this.setState({
      employeeChangeReasonId: null,
      employeeChangeReasonName: null,
      employeeChangeReasonTypeId: null,
      employeeChangeReasonTypeName: null,
      employeeChangeOtherReason: null,
      employeeChangeNote: null,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false
    }, () => {
      form.setFieldsValue({
        employee_change_reason_type_id: null,
        employee_change_reason_id: null,
        employee_change_other_reason: null,
        employee_change_note: null
      })
    })
  }

  // file modal related
  handleAddFileModal = (isShowAddFileModal, info = {}) => {
    this.setState({ isShowAddFileModal, modalFileInfo: info })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  /**
   * Section 4: Save related features
   */
  checkBeforeSave = async () => {
    const { form } = this.props
    const { getFieldValue, validateFieldsAndScroll } = form
    const { onSaveJob, triggerEmployeeChangeModal } = this
    const {
      // loading flag
      loading,
      loadingSave,
      // info
      clientInfo,
      employeeInfo,
      funderInfo,
      item,
      // job list
      affectedJobList,
      conflictJobList,
      // files
      employeeExpiredFiles,
      // leave flag
      isClientLeave,
      isEmployeeLeave,
      // skill
      isMismatchSkill,
      mismatchedSkillList,
      // private alert
      clientPrivateAlert,
      clientPublicAlert,
      employeePrivateAlert,
      employeePublicAlert,
      // emp job hours
      employeeJobHoursInfo,
      // clashed info
      clashedClients,
      clashedEmployees,
      // leave info
      clientLeaveInfo,
      employeeLeaveInfo,
      // employee change info
      isShowEmployeeReasonModal,
      isEmployeeChangeDone,
      // etc
      isRemoveJobsForSameDay,
    } = this.state

    const isShowClientPrivateAlert = clientPrivateAlert && clientPrivateAlert.length > 0
    const isShowClientPublicAlert = clientPublicAlert && clientPublicAlert.length > 0
    const isShowEmployeePrivateAlert = employeePrivateAlert && employeePrivateAlert.length > 0
    const isShowEmployeePublicAlert = employeePublicAlert && employeePublicAlert.length > 0
    const isAnyJobAffected = validator.isNotEmptyArray(affectedJobList)
    const isAnyJobConflicted = validator.isNotEmptyArray(conflictJobList)
    const isFileExpired = validator.isNotEmptyArray(employeeExpiredFiles)
    const isShowEmpMaxHoursAlert = employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour

    if (loading || loadingSave || isShowEmployeeReasonModal) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (this.isEdit() && item.employee_id && item.employee_id !== values.employee_id && !isEmployeeChangeDone) {
          triggerEmployeeChangeModal(true)
          return
        }

        const isNeverEnd = getFieldValue('is_recurring_never_end')
        const startDate = getFieldValue('recurring_start_date')
        const endDate = getFieldValue('recurring_end_date')
        const rsd = !startDate ? null : moment.isMoment(startDate) ? startDate : moment(startDate)
        const red = !endDate ? null : moment.isMoment(endDate) ? endDate : moment(endDate)

        if (!isNeverEnd && red && red.isBefore(rsd)) {
          this.showDateNotification()
          return
        }

        if (funderInfo && funderInfo.isInvalidFunder) {
          this.showInvalidFunderNotification()
          return
        }

        const affectedDeletedJobs = this.checkSingleJobsDelete()
        const isAffectedDeletedJobs = validator.isNotEmptyArray(affectedDeletedJobs)

        if (isShowClientPrivateAlert || isShowEmployeePrivateAlert || isFileExpired ||
          isClientLeave || isMismatchSkill || isAnyJobAffected || isAnyJobConflicted || isAffectedDeletedJobs ||
          isRemoveJobsForSameDay) {
          confirm({
            title: 'Proceed To Save?',
            content: (
              <div className='alert-block'>
                <p>The job will be {this.isEdit() ? 'updated' : 'created'} with following issue(s):</p>
                <br />

                {/** Recurring Job Same Day */}
                {isRemoveJobsForSameDay
                  ? <div style={{ color: '#FF0000', fontSize: '15pt' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '15pt' }} />
                      &nbsp;<strong>You are about to remove all single jobs!</strong>
                    </p>
                    <p>Deleted jobs will not recover.</p>
                  </div>
                  : null}

                {/* Private Alert */}
                {isShowClientPrivateAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${`${clientPrivateAlert}`}`
                      }} />
                    </p>
                  </div>
                  : null}

                {isShowEmployeePrivateAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}</strong><br />${employeePrivateAlert}`
                      }} />
                    </p>
                  </div>
                  : null}

                {/* clashed address detail */}
                {clashedClients || clashedEmployees
                  ? <div>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      &nbsp;<span dangerouslySetInnerHTML={{
                        __html: ` <strong>Address</strong><br />` +
                          `${clashedClients ? `Same address with client: ${clashedClients}<br />` : ''}` +
                          `${clashedEmployees ? `Same address with employee: ${clashedEmployees}<br />` : ''}`
                      }} />
                    </p>
                  </div>
                  : null}

                {/* Files Expiry */}
                {isFileExpired
                  ? (<div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                      &nbsp;<strong>Expired Files:</strong>
                    </p>
                    <li>
                      <ul>{employeeExpiredFiles.map((file, idx) => { return (<li key={`expns${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                    </li>
                    <br />
                  </div>)
                  : null}

                {/** Employee job max hour warning */}
                {isShowEmpMaxHoursAlert
                  ? <div style={{ color: '#FF0000' }}>
                    <p style={{ fontSize: '14px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                      &nbsp;<span style={{ fontWeight: 'bold' }}>Max Hours</span>
                      <div>
                        {`${employeeInfo.first_name} ${employeeInfo.last_name} already has `}<span className='error-msg'>{employeeJobHoursInfo.employee_total_job_hours}</span>{` total job hours this week, including this job will be `}<span className='error-msg'>{formatter.toDecimalS(employeeJobHoursInfo.employee_new_total_job_hours)}</span>{` total job hours`}{employeeJobHoursInfo.is_employee_over_hour ? `, EXCEEDING` : '.'}{employeeJobHoursInfo.employee_max_hours ? <span className={employeeJobHoursInfo.is_employee_over_hour ? 'error-msg' : 'bold-msg'}>{` Max ${employeeJobHoursInfo.employee_max_hours} hours.`}</span> : ''}
                      </div>
                    </p>
                  </div>
                  : null}

                {/* Client On Leave */}
                {isClientLeave
                  ? <div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>Client On Leave</strong>
                    </p>
                    <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {formatter.toShortDate(clientLeaveInfo.leave_start_date)} to {clientLeaveInfo.leave_is_ufn ? `UFN` : formatter.toShortDate(clientLeaveInfo.leave_end_date)}</p>
                    <br />
                  </div>
                  : null}

                {/* Employee On Leave */}
                {isEmployeeLeave
                  ? <div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} />
                      &nbsp;<strong>Employee On Leave</strong>
                    </p>
                    <p>{employeeInfo.first_name} {employeeInfo.last_name} is on leave from {formatter.toShortDate(employeeLeaveInfo.leave_start_date)} to {formatter.toShortDate(employeeLeaveInfo.leave_end_date)}</p>
                    <br />
                  </div>
                  : null}

                {/* Skill Mismatch */}
                {isMismatchSkill
                  ? <div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>{employeeInfo.first_name} {employeeInfo.last_name} does not possess following skill(s):</strong>
                    </p>
                    <ul>
                      {mismatchedSkillList.map((skills, index) => (
                        <li key={`mskl-${index}`}>{skills.setting_name}</li>
                      ))}
                    </ul>
                    <br />
                  </div>
                  : null}

                {/* Conflicted Jobs exists */}
                {isAnyJobConflicted
                  ? <div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>Jobs are conflicting</strong>
                    </p>
                    <p>{conflictJobList.length} job{conflictJobList.length === 1 ? '' : 's'} in total are conflicting with Base's series. Please check those jobs before you continue.</p>
                    <br />
                  </div>
                  : null}

                {/* Individual Edited Jobs exists */}
                {isAnyJobAffected
                  ? <div style={{ color: '#FF0000' }}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>Jobs are different from base </strong>
                    </p>
                    <p>{affectedJobList.length} job{affectedJobList.length === 1 ? '' : 's'} that have been individually updated before are different from Recurring Base. Please check those Jobs and apply update individually.</p>
                    <br />
                  </div>
                  : null}

                {/* Warning Recurring Single Jobs to be remove */}
                {isAffectedDeletedJobs
                  ? <div style={{ color: '#FF0000' }}>
                    <p style={{ fontSize: '15px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>(Remove Job) Recurring single job(s) below are unable to revert after save:</strong>
                    </p>
                    <ul>
                      {affectedDeletedJobs.map((job, index) => (
                        <li key={`jtdlc-${index}`} style={{ color: 'red' }}>{`${formatter.toStandardDate(job.job_start_date)} - ${formatter.toShortTime(job.job_end_date)} (ID: ${job.id})`}</li>
                      ))}
                    </ul>
                    <br />
                  </div>
                  : null}

                <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>

              </div>
            ),
            okText: 'OK',
            cancelText: 'Cancel',
            onOk () {
              // eslint-disable-next-line no-lone-blocks
              onSaveJob(values)
            },
            onCancel () {
            }
          })
        } else {
          onSaveJob(values)
        }
      }
    })
  }

  onSaveJob = async (values) => {
    const { form, history } = this.props
    const {
      loading,
      loadingSave,
      isShowEmployeeReasonModal,
      employeeChangeReasonId,
      employeeChangeReasonName,
      employeeChangeNote,
      employeeChangeOtherReason,
      employeeChangeReasonTypeId,
      employeeChangeReasonTypeName,
      isEmployeeChangeDone,
      isEmployeePending,
      clientId,
      funderId,
      funderInfo,
      fileList,
      item,
      isCustomSO,
      isExtSleepover,
      isRecurringJobNeverEnd,
      isRemoveJobsForSameDay,
      rateSetInfo
    } = this.state
    const { setFieldsValue } = form

    if (loading || loadingSave || isShowEmployeeReasonModal) return

    this.setState({ loadingSave: true })

    values.client_id = clientId
    values.funder_id = funderInfo.id || item.funder_id
    values.is_recurring_never_end = isRecurringJobNeverEnd

    values.is_employee_pending = isEmployeePending
    if (isEmployeePending) {
      values.employee_id = null
    }

    if (values.tasks && values.tasks.length > 0) {
      values.tasks = this.replacer(values.tasks).trim()
      setFieldsValue({ tasks: values.tasks })
    }

    if (values.notes && values.notes.length > 0) {
      values.notes = this.replacer(values.notes).trim()
      setFieldsValue({ notes: values.notes })
    }

    if (values.job_kms === '') {
      values.job_kms = null
    }

    // fvp sleepover
    values.is_sleepover_job = isExtSleepover
    values.is_custom_so_time = isCustomSO
    values.sleepover_type = isExtSleepover ? 'sleepover' : null

    if (!isCustomSO) {
      values.custom_so_start_time = null
      values.custom_so_end_time = null
    }

    // employee change reason
    if (employeeChangeReasonId && employeeChangeReasonTypeId) {
      values.employee_change_reasons = {
        is_employee_change: true,
        employee_change_reason_id: employeeChangeReasonId,
        employee_change_reason_name: employeeChangeReasonName,
        employee_change_reason_type_id: employeeChangeReasonTypeId,
        employee_change_reason_type_name: employeeChangeReasonTypeName,
        employee_change_note: employeeChangeNote,
        employee_change_other_reason: employeeChangeOtherReason,
      }
    }

    if (isRemoveJobsForSameDay !== null && isRemoveJobsForSameDay === true) {
      values.is_recurring_cancel = true
    }

    try {
      let r = null
      if (this.isEdit()) {
        const jobId = this.getJobId()
        r = await jvpJobRecurringService.save(jobId, values)
      } else {
        r = await jvpJobRecurringService.add(values)
      }

      if (r && r.id) {
        this.showSaveJobSuccess()

        // Log / Trigger / Single Job update log / Action Log moved to backend

        if (!this.isEdit()) {
          const newId = r.id

          if (validator.isNotEmptyArray(fileList)) {
            await this.onSaveUploadFiles(r.id)
          }

          const redirect = () => {
            const commCheckQuery = r && r.is_comm_created && r.is_comm_created === true ? `?comm=true` : ''
            window.location.replace(`${jobURL}/recurring/${r.id}/info${commCheckQuery}`)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }

          redirect()
        } else {
          this.resetEmployeeChangeValues()
          this.refreshPage()
        }

        // setFieldsValue({ effective_date: null })
        this.updateRefreshLogTrigger()
      } else {
        this.showSaveJobError()
      }
    } catch (e) {
      console.log('on save job', e)
      this.showSaveJobError()
    }

    this.setState({ loadingSave: false })
  }

  onSaveUploadFiles = async (jobId) => {
    const { fileList, fileMainCategoryList, fileSubCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = fileMainCategoryList.find(e => e.id === file.main_category_id)
      const subcat = fileSubCategoryList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jvpJobFileService.add(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addBaseJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  /**
   * Section 5: Miscellaneous
   */
  updateRefreshLogTrigger = () => {
    this.setState({ isLogRefresh: true })
  }

  updateRefreshLogComplete = () => {
    this.setState({ isLogRefresh: false })
  }

  showFetchClientError = () => {
    notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later.')
  }

  showFetchEmployeeError = () => {
    notify.error('Unable to load successfully, Unable to load employees successfully. Please try again later.')
  }

  showFetchPrevEmployeeError = () => {
    notify.error('Unable to load successfully, Unable to load previous employee list successfully. Please try again later')
  }

  showFetchFileCatsError = () => {
    notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
  }

  showFetchJobError = () => {
    notify.error('Unable to load successfully', 'Unable to load base job successfully. Please try again later.')
  }

  showSaveJobSuccess = () => {
    notify.success('Saved successfully', 'Base Job saved successfully.')
  }

  showSaveJobError = () => {
    notify.error('Unable to save successfully', 'Unable to save base job successfully. Please try again later.')
  }

  showLoadSingleJobErrror = () => {
    notify.error('Unable to load successfully, Unable to load single jobs list successfully. Please try again later')
  }

  showInvalidFunderNotification = () => {
    error({
      title: `Invalid Funder`,
      content: <div><p>The funder is not set for this client.</p> <p>Please go to Client Page to update funder or update this job's funder in order to proceed to edit and save.</p></div>
    })
  }

  showNoFundingPeriodNotification = () => {
    warning({
      title: `Client has no Funding Period`,
      content: `The client does not have any available funding period.`
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Recurring Date Error',
      description: 'End Date should not be earlier than Start Date',
      top: 130
    })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  replacer = (value) => {
    if (value) {
      let str = value
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return null
  }
}

const mapDispatchToProps = {
  fetchJobRecurringList,
  fetchJobSummary
}

const mapStateToProps = (state) => {
  return { ...state.JvpJobRecurring, ...state.JvpJobSummary }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JvpRecurringJobPage))

export const FETCH_JOB_SUMMARY = 'FETCH_JOB_SUMMARY'
export const FETCHING_JOB_SUMMARY = 'FETCHING_JOB_SUMMARY'
export const JOB_SUMMARY_FETCHED = 'JOB_SUMMARY_FETCHED'

export const fetchJobSummary = (params) => {
  return Object.assign({
    type: FETCH_JOB_SUMMARY
  }, params)
}

export const fetchingJobSummary = (loading) => {
  return {
    type: FETCHING_JOB_SUMMARY,
    loading
  }
}

export const jobSummaryFetched = (jobs) => {
  return {
    type: JOB_SUMMARY_FETCHED,
    jobs
  }
}

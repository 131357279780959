import {
  clientService,
  exportContactService,
  exportJobService,
  exportViewService,
  exportPayrollService,
  funderService,
  fvpClientBudgetService,
  jobTimesheetService,
  employeeService,
  reportService
} from '../services'
import {
  JobExportType,
  ExportType
} from '../constants'
import formatter from './formatter'

const exportFunction = {
  async fetchExport (type, data = {}, typeId) {
    let filename = ''
    let r = null
    let isFileDownload = true

    if (type === ExportType.PAYROLL.INVOICE) {
      filename = `invoice-${data.date}-${Date.now()}.csv`
      r = await exportPayrollService.getPayrollExport(ExportType.PAYROLL.INVOICE, data)
    }

    if (type === ExportType.PAYROLL.NDIS_PAYMENT) {
      filename = `ndis-payment-${data.date}-${Date.now()}.csv`
      r = await exportPayrollService.getPayrollExport(ExportType.PAYROLL.NDIS_PAYMENT, data)
    }

    if (type === ExportType.PAYROLL.PAYROLL) {
      filename = `payroll-${data.date}-${Date.now()}.csv`
      r = await exportPayrollService.getPayrollExport(ExportType.PAYROLL.PAYROLL, data)
    }

    if (type === ExportType.GENERAL.EXPORT_CLIENT) {
      filename = `clients-data-${Date.now()}.zip`
      r = await exportViewService.getViewExport(ExportType.GENERAL.EXPORT_CLIENT, data)
    }

    if (type === ExportType.GENERAL.EXPORT_EMPLOYEE) {
      filename = `employees-data-${Date.now()}.zip`
      r = await exportViewService.getViewExport(ExportType.GENERAL.EXPORT_EMPLOYEE, data)
    }

    if (type === ExportType.GENERAL.EXPORT_FEEDBACK) {
      filename = `feedbacks-data-${Date.now()}.zip`
      r = await exportViewService.getViewExport(ExportType.GENERAL.EXPORT_FEEDBACK, data)
    }

    if (type === ExportType.GENERAL.EXPORT_FUNDER) {
      filename = `funders-data-${Date.now()}.zip`
      r = await exportViewService.getViewExport(ExportType.GENERAL.EXPORT_FUNDER, data)
    }

    if (type === ExportType.GENERAL.EXPORT_CLIENT_BUDGET) {
      filename = `client-budget-export-${Date.now()}.csv`
      r = await fvpClientBudgetService.exportClientBudget(data)
    }

    if (type === ExportType.GENERAL.EXPORT_JOB_TS) {
      filename = `job-timesheet-export-${Date.now()}.csv`
      r = await jobTimesheetService.exportTimesheetReport(data)
    }

    if (type === ExportType.CONTACT.MICROSIP_CSV) {
      filename = `export-contact-microsip-${Date.now()}.csv`
      r = await exportContactService.getMicrosipCsv()
    }

    if (type === ExportType.CONTACT.MICROSIP_XML) {
      filename = `export-contact-microsip-${Date.now()}.xml`
      r = await exportContactService.getMicrosipXML()
    }

    if (type === ExportType.CONTACT.ACROBITS_CSV) {
      filename = `export-contact-acrobits-${Date.now()}.csv`
      r = await exportContactService.getAcrobitsCsv()
    }

    if (type === ExportType.CONTACT.ACROBITS_JSON) {
      filename = `export-contact-acrobits-${Date.now()}.json`
      r = await exportContactService.getAcrobitsJSON()
    }

    if (type === ExportType.CONTACT.GENERAL_CSV) {
      filename = `export-contact-general-${Date.now()}.csv`
      r = await exportContactService.getGeneralCsv()
    }

    if (type === ExportType.CONTACT.GENERAL_XML) {
      filename = `export-contact-general-${Date.now()}.xml`
      r = await exportContactService.getGeneralXML()
    }

    if (type === ExportType.CONTACT.GENERAL_V2_CSV) {
      filename = `export-contact-simple-${Date.now()}.csv`
      r = await exportContactService.getSimpleCsv()
    }

    if (type === ExportType.CONTACT.GENERAL_V2_ZIP_CSV) {
      filename = `export-contact-simple-${Date.now()}.zip`
      r = await exportContactService.getSimpleZipCsv()
    }

    if (type === ExportType.CONTACT.GENERAL_V2_ZIP_EMAIL) {
      r = await exportContactService.getSimpleZipEmail()
    }

    if (type === ExportType.CONTACT.GENERAL_V2_XML) {
      filename = `export-contact-simple-${Date.now()}.xml`
      r = await exportContactService.getSimpleXML()
    }

    if (r && filename) {
      return downloadFile(r, filename, isFileDownload)
    } else {
      return r
    }
  },
  async fetchListExport (type, data = {}) {
    let filename = ''
    let r = null
    let isFileDownload = !data.isSendEmail

    if (type === ExportType.GENERAL.JOB_CLIENT_LIST) {
      const d1 = formatter.toDBDate(data.from)
      const d2 = formatter.toDBDate(data.to)

      filename = `${ExportType.GENERAL.JOB_CLIENT_LIST}${data.exportType && data.exportType === JobExportType.EXPORT_JOB_FEEDBACK ? `-feedback` : ''}-${d1}-${d2}-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.JOB_CLIENT_LIST, data)
    }

    if (type === ExportType.GENERAL.JOB_EMPLOYEE_LIST) {
      const d1 = formatter.toDBDate(data.from)
      const d2 = formatter.toDBDate(data.to)

      filename = `${ExportType.GENERAL.JOB_EMPLOYEE_LIST}${data.exportType && data.exportType === JobExportType.EXPORT_JOB_FEEDBACK ? `-feedback` : ''}-${d1}-${d2}-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.JOB_EMPLOYEE_LIST, data)
    }

    if (type === ExportType.GENERAL.JOB_FUNDER_LIST) {
      const d1 = formatter.toDBDate(data.from)
      const d2 = formatter.toDBDate(data.to)

      filename = `${ExportType.GENERAL.JOB_FUNDER_LIST}${data.exportType && data.exportType === JobExportType.EXPORT_JOB_FEEDBACK ? `-feedback` : ''}-${d1}-${d2}-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.JOB_FUNDER_LIST, data)
    }

    if (type === ExportType.GENERAL.JOB_LIST) {
      const d1 = formatter.toDBDate(data.from)
      const d2 = formatter.toDBDate(data.to)

      filename = `${ExportType.GENERAL.JOB_LIST}${data.exportType && data.exportType === JobExportType.EXPORT_JOB_FEEDBACK ? `-feedback` : ''}-${d1}-${d2}-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.JOB_LIST, data)
    }

    if (type === ExportType.GENERAL.EXPORT_CLIENT_JOB_TO_ACTION) {
      filename = `client-job-to-action-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.EXPORT_CLIENT_JOB_TO_ACTION, data)
    }

    if (type === ExportType.GENERAL.EXPORT_EMPLOYEE_JOB_TO_ACTION) {
      filename = `employee-job-to-action-${Date.now()}.csv`
      r = await exportJobService.getViewJobList(ExportType.GENERAL.EXPORT_EMPLOYEE_JOB_TO_ACTION, data)
    }

    if (r) {
      if (isFileDownload) {
        return downloadFile(r, filename, isFileDownload)
      } else {
        return r
      }
    }
  },
  async fetchReportExport (type, data = {}) {
    let filename = ''
    let r = null
    let isFileDownload = true

    if (type === ExportType.REPORT.BACK_TO_BACK) {
      filename = `back-to-back-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.BACK_TO_BACK, data)
    }

    if (type === ExportType.REPORT.CLASHED_SHIFTS_V2) {
      filename = `clashed-shifts-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.CLASHED_SHIFTS_V2, data)
    }

    if (type === ExportType.REPORT.CLIENT_BDAY) {
      filename = `client-birthday-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_BDAY, data)
    }

    if (type === ExportType.REPORT.CLIENT_CAREPLAN_V2) {
      filename = `client-careplan-v2-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_CAREPLAN_V2, data)
    }

    if (type === ExportType.REPORT.CLIENT_CAREPLAN_ALL) {
      filename = `client-careplan-all-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_CAREPLAN_ALL, data)
    }

    if (type === ExportType.REPORT.CLIENT_FILE) {
      filename = `client-files-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_FILE, data)
    }

    if (type === ExportType.REPORT.CLIENT_FUNDING) {
      filename = `client-funding-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_FUNDING, data)
    }

    if (type === ExportType.REPORT.CLIENT_LEAVE) {
      filename = `client-leaves-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.CLIENT_LEAVE, data)
    }

    if (type === ExportType.REPORT.LAST_JOB_DAY_COUNT) {
      filename = `day-counts-since-last-job-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.LAST_JOB_DAY_COUNT, data)
    }

    if (type === ExportType.REPORT.EMPLOYEE_BDAY) {
      filename = `employee-birthday-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.EMPLOYEE_BDAY, data)
    }

    if (type === ExportType.REPORT.EMPLOYEE_FILE) {
      filename = `employee-files-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.EMPLOYEE_FILE, data)
    }

    if (type === ExportType.REPORT.EMPLOYEE_LEAVE) {
      filename = `employee-leaves-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.EMPLOYEE_LEAVE, data)
    }

    if (type === ExportType.REPORT.EMPLOYEE_MISSING_REQUIRED_FILE) {
      filename = `employee-required-files-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.EMPLOYEE_MISSING_REQUIRED_FILE, data)
    }

    if (type === ExportType.REPORT.FEEDBACK) {
      filename = `feedback-report-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.FEEDBACK, data)
    }

    if (type === ExportType.REPORT.FUNDER_AGMT_EXP) {
      filename = `funder-agreement-expiry-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.FUNDER_AGMT_EXP, data)
    }

    if (type === ExportType.REPORT.FUNDER_FILE) {
      filename = `funder-files-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.FUNDER_FILE, data)
    }

    if (type === ExportType.REPORT.JOB_CANCELLATION) {
      filename = `job-cancellation-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.JOB_CANCELLATION, data)
    }

    if (type === ExportType.REPORT.JOB_TIMESHEET) {
      filename = `job-timesheet-report-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.JOB_TIMESHEET, data)
    }

    if (type === ExportType.REPORT.MULTI_CLIENT) {
      filename = `multi-client-${Date.now()}.zip`
      r = await reportService.exportReport(ExportType.REPORT.MULTI_CLIENT, data)
    }

    if (type === ExportType.REPORT.PH_JOB_LIST) {
      filename = `holiday-jta-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.PH_JOB_LIST, data)
    }

    if (type === ExportType.REPORT.USER_ACTION) {
      filename = `system-user-action-${Date.now()}.csv`
      r = await reportService.exportReport(ExportType.REPORT.USER_ACTION, data)
    }

    if (r) {
      return downloadFile(r, filename, isFileDownload)
    }
  }
}

async function downloadFile (r, filename, isFileDownload = true) {
  if (r) {
    const blob = await r.blob()
    if (blob.type === 'application/json' && isFileDownload === false) {
      try {
        const response = await blob.text()
        return JSON.parse(response)
      } catch (e) {
        return e
      }
    } else {
      const href = window.URL.createObjectURL(blob)
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}

export default exportFunction

export const FETCH_CANCELLED_JOBS = 'FETCH_CANCELLED_JOBS'
export const FETCHING_CANCELLED_JOBS = 'FETCHING_CANCELLED_JOBS'
export const CANCELLED_JOBS_FETCHED = 'CANCELLED_JOBS_FETCHED'

export const fetchCancelledJobs = (params) => {
  return Object.assign({
    type: FETCH_CANCELLED_JOBS
  }, params)
}

export const fetchingCancelledJobs = (loading) => {
  return {
    type: FETCHING_CANCELLED_JOBS,
    loading
  }
}

export const cancelledJobsFetched = (jobs) => {
  return {
    type: CANCELLED_JOBS_FETCHED,
    jobs
  }
}

export default [
  {
    name: 'Back-to-Back Shifts',
    desc: '',
    icon: 'block',
    link: '/reports/back-to-back-job',
    permission: 'readBackToBackReport',
    enable: true
  },
  {
    name: 'Clashed Shifts',
    desc: '',
    icon: 'switcher',
    link: '/reports/clash-job',
    permission: 'readClashReport',
    enable: true
  },
  {
    name: 'Client\'s Birthday',
    desc: 'View client birthday report',
    icon: 'gift',
    link: '/reports/client-birthday',
    permission: 'readClientBirthdayReport',
    enable: true
  },
  {
    name: 'Client\'s Budget Report',
    desc: 'View client budget report',
    icon: 'line-chart',
    link: '/reports/client-budget',
    permission: 'readClientBudgetReport',
    enable: true
  },
  {
    name: 'Client\'s Care Plan Review',
    desc: 'View client care plan review',
    icon: 'profile',
    link: '/reports/care-plan-v2-review',
    permission: 'readClientCarePlanReviewReport',
    enable: true
  },
  {
    name: 'Client\'s Funding',
    desc: 'Manage products details, price, stock, and etc',
    icon: 'dollar',
    link: '/reports/client-funding',
    permission: 'readClientFundReport',
    enable: true
  },
  {
    name: 'Client\'s Files',
    desc: 'Manage products details, price, stock, and etc',
    icon: 'safety',
    link: '/reports/client-files',
    permission: 'readClientFilesReport',
    enable: true
  },
  {
    name: 'Client\'s Leave',
    desc: 'Manage bundle products',
    icon: 'calendar',
    link: '/reports/client-leave',
    permission: 'readClientReport',
    enable: true
  },
  {
    name: 'Day Counts since Last Job',
    desc: 'Manage employees and clients\' day counts since last job',
    icon: 'calendar',
    link: '/reports/last-job',
    permission: 'readDayCountReport',
    enable: true
  },
  {
    name: 'Employee\'s Birthday',
    desc: 'View employee birthday report',
    icon: 'gift',
    link: '/reports/employee-birthday',
    permission: 'readEmployeeBirthdayReport',
    enable: true
  },
  {
    name: 'Employee\'s Files',
    desc: 'Manage products details, price, stock, and etc',
    icon: 'safety',
    link: '/reports/employee-files',
    permission: 'readEmployeeCertReport',
    enable: true
  },
  {
    name: 'Employee\'s Leave',
    desc: 'Manage bundle products',
    icon: 'calendar',
    link: '/reports/employee-leave',
    permission: 'readEmployeeReport',
    enable: true
  },
  {
    name: 'Employee\'s Required Files',
    desc: 'Manage bundle products',
    icon: 'safety',
    link: '/reports/employee-missing-required-files',
    permission: 'readEmployeeMissingRequiredFileReport',
    enable: true
  },
  // {
  //   name: 'Funder\'s Agreement Expiry',
  //   desc: 'Manage products details, price, stock, and etc',
  //   icon: 'safety',
  //   link: '/reports/funder-agreement-expiry',
  //   permission: 'readFunderAgreementExpiryReport',
  //   enable: true
  // },
  {
    name: 'Feedback',
    desc: '',
    icon: 'safety',
    link: '/reports/feedback',
    permission: 'readFeedbackReport',
    enable: true
  },
  {
    name: 'Funder\'s Files',
    desc: 'Manage products details, price, stock, and etc',
    icon: 'safety',
    link: '/reports/funder-files',
    permission: 'readFunderFilesReport',
    enable: true
  },
  {
    name: 'Job Cancellation',
    desc: 'Manage Cancellation status of the jobs',
    icon: 'thunderbolt',
    link: '/reports/job-cancellation',
    permission: 'readJobMultiActionReport',
    enable: true
  },
  {
    name: 'Job Timesheet Report',
    desc: 'Manage Timesheet Signing Status of the jobs',
    icon: 'clock-circle',
    link: '/reports/job-timesheet-report',
    permission: 'readJobTimesheetReport',
    enable: true
  },
  {
    name: 'Multi Client Shifts',
    desc: '',
    icon: 'thunderbolt',
    link: '/reports/multi-client',
    permission: 'readMultiClientReport',
    enable: true
  },
  {
    name: 'Public Holidays',
    desc: '',
    icon: 'calendar',
    link: '/reports/public-holiday',
    permission: 'readPublicHolidayReport',
    enable: true
  },
  {
    name: 'System User Action',
    desc: 'System user action report',
    icon: 'fund',
    permission: 'readActionReport',
    link: '/reports/action'
  },
  {
    name: 'Individual User Action',
    desc: 'individual user action report',
    icon: 'stock',
    // permission: 'readActionReport',
    link: '/reports/action/me'
  },
  {
    name: 'Recipient Settings',
    desc: '',
    icon: 'setting',
    link: '/reports/setting',
    permission: 'listRecipients',
    enable: true
  }
]

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jvpJobService, reportService, settingHolidayService } from '../../../../services'
import { common, exportFile, formatter, validator } from '../../../../util'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { jobURL, timezone } from '../../../../config'
import { ExportType } from '../../../../constants'

// UI
import { Loading, List, Page } from '../../../../components'
import notify from '../../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

moment.tz.setDefault(timezone)

const view = 'Holiday'

class HolidayImpactedJobJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      holidayInfo: {},
      count: null,
      list: [],
      total: 0,
      isNotify: null,
      loading: false,
      loadingInfo: false,
      loadingSave: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    if (prevState.isNotify === null) {
      let isNotify = prevState.isNotify

      if (location && location.search) {
        const queries = common.getQueryStringSearchParams(location.search)

        if (queries && queries['notify'] !== undefined) {
          isNotify = true
        } else {
          isNotify = false
        }
      }

      return { ...prevState, isNotify }
    }

    return { ...prevState }
  }

  componentDidMount () {
    this.fetchHolidayInfo()
    this.fetchHolidayJobs()
  }

  render () {
    const { form } = this.props
    const { isNotify, list, holidayInfo, count, loading, loadingInfo, loadingSave } = this.state

    const title = `${count === null ? '' : `${count} Affected Job${Number(count) === 1 ? '' : 's'}: `}Public Holiday ${holidayInfo && holidayInfo.id ? `(${holidayInfo.name}) On ${holidayInfo.string_date}` : ''}`

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >SO</div> }
        </div>
      },
      {
        title: 'Client',
        width: 4.5,
        render: ({ is_frontend_unhide, job_id, client_fullname, client_id, client_leave_id, client_leave_start_date, client_leave_end_date, client_active, client_leave_is_ufn }) => {
          const isClientLeave = !!client_leave_id
          const clientLeaveText = isClientLeave ? `Leave ${formatter.toShortDate(client_leave_start_date)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end_date)}` : null
          const isHide = client_leave_id && !is_frontend_unhide

          return isClientLeave
            ? (<div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                <a className={`${!client_active || isHide ? 'client-label-dim' : ''}`} href={`/clients/${client_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>
                <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />&nbsp;{`Leave ${formatter.toShortDate(client_leave_start_date)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end_date)}`}</div>
              </div>)
            : (<div>
                <a className={`${!client_active || isHide ? 'client-label-dim' : ''}`} href={`/clients/${client_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>
              </div>)
        }
      },
      {
        title: 'Employee',
        width: 4.5,
        render: ({ client_leave_id, is_frontend_unhide, employee_active, employee_id, employee_fullname, employee_leave_id, employee_leave_start_date, employee_leave_end_date }) => {
          const isEmployeeLeave = !!employee_leave_id
          const employeeLeaveText = isEmployeeLeave ? `Leave ${formatter.toShortDate(employee_leave_start_date)} - ${formatter.toShortDate(employee_leave_end_date)}` : null
          const isHide = client_leave_id && !is_frontend_unhide

          return isEmployeeLeave
            ? (<div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                <a className={`${!employee_active || isHide ? 'emp-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
                <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />&nbsp;{employeeLeaveText}</div>
              </div>)
            : (<div>
                <a className={`${!employee_active || isHide ? 'emp-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
              </div>)
        }
      },
      {
        title: 'Day',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, string_job_start_day }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.capitalize(string_job_start_day)}</div>
        }
      },
      {
        title: 'Start Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_start_date)}</div>
        }
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_end_date)}</div>
        }
      },
      {
        title: 'Start Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_start_date)}</div>
        }
      },
      {
        title: 'End Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_end_date)}</div>
        }
      },
      {
        title: 'Job ID',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, job_id }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{job_id}</div>
        }
      },
      {
        title: 'Status',
        width: 2,
        render: ({ job_id, status_leave, is_cancel }) => {
          const isCancel = !!is_cancel
          const isKeeped = !!(status_leave === 'approved')

          return (
            <div style={{ color: '#ccc', fontSize: '12pt' }}>
              { isCancel
                ? <div>
                  <Tooltip mouseLeaveDelay={0} title='Cancelled'>
                    <span style={{ color: '#ff5b5b' }}>
                      <Icon type='close-circle' theme='filled' />
                    </span>
                  </Tooltip>
                </div>
                : isKeeped
                  ? <div>
                    <Tooltip mouseLeaveDelay={0} title='Kept'>
                      <span style={{ color: '#4fbc85' }}>
                        <Icon type='check-circle' theme='filled' />
                      </span>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='To Review?'>
                      <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.updateJob(job_id, 'review')}>
                        <Icon type='exclamation-circle' theme='filled' />
                      </span>
                    </Tooltip>
                  </div>
                  : <div>
                    <Tooltip mouseLeaveDelay={0} title='To Keep?'>
                      <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.updateJob(job_id, 'approved')}>
                        <Icon type='check-circle' theme='filled' />
                      </span>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='To Review'>
                      <span style={{ color: '#f2b018' }}>
                        <Icon type='exclamation-circle' theme='filled' />
                      </span>
                    </Tooltip>
                  </div>}
            </div>
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ job_id }) => (
          <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
            <Tooltip mouseLeaveDelay={0} title='View'>
              <a href={`${jobURL}/single/${job_id}/info`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
          </div>
        )
      }
    ]

    return (
      <Page.Body className='holiday-impacted'>
        {/* <Page.Left>
          <Page.Menu
            title='Public Holidays'
            backLink={'/reports/public-holiday'}
            panel={<div style={{ textAlign: 'center', padding: '20px 0' }}
          >
            {holidayInfo && holidayInfo.id ? `${holidayInfo.name}` : ''}
          </div>} />
        </Page.Left> */}
        <Page.Content nomenu>
          <Page.Header title={title}>

              <Link to='/reports/public-holiday'><div className='btn'>Back to Public Holidays</div></Link>

            <div className='btn' onClick={() => this.handleExport()}>
              Export
            </div>
          </Page.Header>
            <Loading loading={loadingSave} blur>
              <Skeleton loading={loading} active>
                <List cols={columns} rows={list} />
              </Skeleton>
            </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchHolidayInfo = async () => {
    const hid = this.getHolidayId()

    this.setState({ loadingInfo: true })

    const { holiday, count } = await settingHolidayService.listImpactedJobCount(hid)

    this.setState({ holidayInfo: holiday && holiday.id ? holiday : {}, count: count || 0, loadingInfo: false })
  }

  fetchHolidayJobs = async () => {
    const hid = this.getHolidayId()

    this.setState({ loading: true })

    const { list, total, count } = await reportService.getListByPageView(ExportType.REPORT.PH_JOB_LIST, hid, 1, 0)

    this.setState({
      list: validator.isArray(list) ? list : [],
      total: total || 0,
      count: count || 0,
      loading: false
    })
  }

  updateJob = async (id, value) => {
    const body = {
      id: id,
      status_leave: value,
      view
    }

    this.setState({ loadingSave: true }, async () => {
      const { list } = this.state
      try {
        const r = await jvpJobService.saveList(body)
        console.log('r', r)
        if (r && (r.id || r.ts)) {
          // this.showUpdateJobSuccess()
          await this.fetchHolidayInfo()
          const jobIndex = list.findIndex(e => e.job_id === id)

          if (jobIndex > -1) {
            list[jobIndex].status_leave = value
          }
        } else {
          this.showUpdateJobError()
        }
      } catch (e) {
        this.showUpdateJobError()
      }

      this.setState({ loadingSave: false, list })
    })
  }

  showUpdateJobSuccess = () => {
    notify.success('Updated successfully', 'Job status updated successfully.')
  }

  showUpdateJobError = () => {
    notify.error('Unable to Update', 'Unable to update the job. Please try again later.')
  }

  showExportJobsError = () => {
    notify.error('Unable to export', 'Unable to get jobs to action export successfully. Please try again later.')
  }

  handleExport = async () => {
    const { loading, loadingSave } = this.state
    if (loading || loadingSave) return

    const id = this.getHolidayId()

    try {
      this.setState({ loadingSave: true })
      const r = await exportFile.fetchReportExport(ExportType.REPORT.PH_JOB_LIST, { id })

      setTimeout(() => {
        this.setState({ loadingSave: false })
      }, 3000)
    } catch (e) {
      this.showExportJobsError()
      this.setState({ loadingSave: false })
    }
  }

  getHolidayId = () => {
    const { match } = this.props
    const { params } = match
    const { hid } = params

    return hid
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(HolidayImpactedJobJvp))

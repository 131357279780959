import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter, validator } from '../../../util'
import { timezone } from '../../../config'
import moment from 'moment-timezone'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
moment.tz.setDefault(timezone)

const { RangePicker } = DatePicker

const pageSize = 50
const dateFormat = 'YYYY-MM-DD'

export class ReportMultiClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dateFrom: moment(),
      dateTo: moment(),
      singleList: { list: [], total: 0 },
      recurringList: { list: [], total: 0 },
      currentPage: 1,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  render () {
    const { currentPage, dateFrom, dateTo, isLoaded, loading, loadingExport, loadingList, singleList, recurringList, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    const recurringColumns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ recurring_start_date, job_start_date }) => <div>{`${formatter.toShortDate(recurring_start_date)} ${formatter.toShortTime(job_start_date)}`}</div>
      },

      {
        title: 'Job End',
        width: 2,
        render: ({ recurring_end_date, job_end_date }) => recurring_end_date
          ? <div>{`${formatter.toShortDate(recurring_end_date)} ${formatter.toShortTime(job_end_date)}`}</div>
          : <div>{formatter.toShortTime(job_end_date)}</div>
      },

      {
        title: 'Client',
        width: 3,
        render: ({ client_id: clientId, client_fullname }) => <div><a href={`/clients/${clientId}`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
      },

      {
        title: 'Funder',
        width: 2,
        render: ({ funder_id: funderId, funder_fullname }) => <div><a href={`/funders/${funderId}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
      },

      {
        title: 'Shift Type',
        width: 3,
        render: ({ category_name }) => <div>{category_name}</div>
      },

      {
        title: 'Carer Skill Level Req',
        width: 3,
        render: ({ payroll_name }) => <div>{formatter.capitalize(payroll_name || '')}</div>
      },

      {
        title: 'Employee',
        width: 3,
        render: ({ employee_id: empId, employee_fullname }) => <div><a href={`/employees/${empId}`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
      },

      {
        title: '',
        width: 1,
        render: ({ id }) => <div>
          <a href={`/jobs/recurring/${id}`} rel='noopener noreferrer' target='_blank'>
            <Tooltip mouseLeaveDelay={0} title='View'>
            <Icon type='eye' />
            </Tooltip>
          </a>
        </div>
      }
    ]

    const singleColumns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ job_start_date }) => formatter.toStandardDate(job_start_date)
      },

      {
        title: 'Job End',
        width: 2,
        render: ({ job_end_date }) => formatter.toStandardDate(job_end_date)
      },

      {
        title: 'Client',
        width: 3,
        render: ({ client_id: clientId, client_fullname }) => <div><a href={`/clients/${clientId}`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
      },

      {
        title: 'Funder',
        width: 2,
        render: ({ funder_id: funderId, funder_fullname }) => <div><a href={`/funders/${funderId}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
      },

      {
        title: 'Shift Type',
        width: 3,
        render: ({ category_name }) => <div>{category_name}</div>
      },

      {
        title: 'Carer Skill Level Req',
        width: 3,
        render: ({ payroll_name }) => <div>{formatter.capitalize(payroll_name || '')}</div>
      },

      {
        title: 'Employee',
        width: 3,
        render: ({ employee_id: empId, employee_fullname }) => <div><a href={`/employees/${empId}`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
      },

      {
        title: '',
        width: 1,
        render: ({ id }) => <div>
          <a href={`/jobs/single/${id}`} rel='noopener noreferrer' target='_blank'>
            <Tooltip mouseLeaveDelay={0} title='View'>
            <Icon type='eye' />
            </Tooltip>
          </a>
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Multi Client Shifts'>
            { !loading && isLoaded
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }
          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('multi_client_email', {
                          initialValue: settings.multi_client_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form>:null
              : null }   
          </div>

          <div className='admin-list'>
            <ControlLabel>Date Range</ControlLabel>
            <RangePicker
              disabled={loading || loadingList}
              format="DD/MM/YYYY"
              onChange={(d1, d2) => this.onUpdateRange(d1, d2)}
            />

            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { isLoaded
                  ? <div>
                    <div className='report-title'>{`Recurring Bases `} <span className='report-tag'>{recurringList.total}</span></div>
                    <List cols={recurringColumns} rows={recurringList.list} />
                    <div className='report-title'>{`Single Jobs `} <span className='report-tag'>{singleList.total}</span></div>
                    <List cols={singleColumns} rows={singleList.list} />
                  </div>
                  : null }
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, dateFrom, dateTo, isLoaded, loading } = this.state
    this.fetchMultiClient({currentPage, dateFrom, dateTo, isLoaded, loading})
  }

  async fetchMultiClient ({ currentPage, dateFrom, dateTo, isLoaded = true, loading }) {
    if (this.hasAccess('readMultiClientReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const from = dateFrom.format(dateFormat)
        const to = dateTo.format(dateFormat)
        const { single, recurring } = await reportService.getListByDate(ExportType.REPORT.MULTI_CLIENT, from, to)

        this.setState({
          singleList: single,
          recurringList: recurring,
          loading: false,
          loadingList: false,
          isLoaded: true
        }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Multi Client Shifts Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  onUpdateRange (d1, d2) {
    if (validator.isNotEmptyArray(d1)) {
      this.setState({ dateFrom: d1[0], dateTo: d1[1], isLoaded: false }, () => {
        this.fetchList()
      })
    }
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readMultiClientReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const { dateFrom, dateTo } = this.state
      const from = dateFrom.format(dateFormat)
      const to = dateTo.format(dateFormat)

      const r = await exportFile.fetchReportExport(ExportType.REPORT.MULTI_CLIENT, { from, to })
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportMultiClient))

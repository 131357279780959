import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { Link } from 'react-router-dom'
import {
  authService,
  baseJobService,
  clientService,
  clientLeaveService,
  employeeService,
  employeeFileService,
  employeeLeaveService,
  jobService,
  jobActionLogService,
  settingFileCategoryService,
  settingFileTypeService,
  funderService,
  fvpCategoriesService,
  fvpClientFundingService,
  fvpRatesService,
  logService,
  settingOtherService,
  settingBillingRateService,
  settingCancellationService,
  settingGeneralService,
  settingHolidayService
} from '../../../services'

import { fetchingJobs } from '../../../states/actions/job'
import { fetchTotalPending, setRefreshActivityLog, setRefreshCommLog } from '../../../states/actions'
import { JobFvpMenu } from '../../../constants'
import { common, formatter, log, validator } from '../../../util'
import { jobLegacyURL } from '../../../config'

import AddFileModal from './AddFileModal'
import Communication from './Communication'
import File from './File'

// UI
import { List, Loading, Page, Panel, DateTimePicker, Pager, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'
import Checkbox from 'antd/lib/checkbox'
import TimePicker from 'antd/lib/time-picker'

import './styles.css'
import ActivityLog from './ActivityLog'

const { Item: FormItem } = Form
const { confirm, error, info, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const { Option, OptGroup } = Select
const momentWeek = extendMoment(Moment)
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const sqlFormat = 'YYYY-MM-DDTHH:mm:ss+00'

const LIST_FILTER_FUTURE = 'future'
const LIST_FILTER_PREVIOUS = 'previous'

const pageSize = 10

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}
const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const specialFormItemLayoutToggle = {
  labelCol: { sm: 6, md: 6, lg: 0 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // ids
      clientId: null,
      employeeId: null,
      funderId: null,
      // info
      clientInfo: {},
      clientLanguages: [],
      clientSkills: [],
      clientLanguagesName: [],
      clientSkillsName: [],
      currentEmpInfo: {},
      currentFunderInfo: {},
      empLanguages: [],
      empSkills: [],
      empLanguagesName: [],
      empSkillsName: [],
      employeeJobHoursInfo: {},
      fileInfo: {},
      fileList: [],
      funderInfo: {},
      prevEmpInfo: {},
      prevFunderInfo: {},
      rateSetInfo: {},
      clashClient: '',
      clashEmp: '',
      // job item
      item: {},
      itemOri: {},
      jobDayNumber: null,
      jobNewEndTime: null,
      jobOptionDate: null,
      // job related flags
      isAllCurrentJobChecked: false,
      isCustomSO: false,
      isDisableCategoriesSelect: false,
      isEmergency: false,
      isEmployeePending: false,
      isExtSleepover: false,
      isJobHasStartDate: false,
      isJobNeverEnd: false,
      isShowCancelOtherField: false,
      isShowClientPrivateAlert: false,
      isShowClientPublicAlert: false,
      isShowEmployeePrivateAlert: false,
      isShowEmployeePublicAlert: false,
      isShowSleepoverAlert: false,
      // listings
      billingCategoryList: [],
      cancelReasonList: [],
      cancelTypeList: [],
      employeeAllList: [],
      funderList: [],
      funderPeriodList: [],
      previousEmployeeList: [],
      // conflict job lists
      conflictJobList: [],
      conflictJobListClient: [],
      conflictJobListEmp: [],
      conflictJobErrorMsg: null,
      // client leave info
      isClientOnLeave: false,
      clientLeaveInfo: null,
      // client conflict info
      isShowClientConflictAlert: false,
      clientConflictInfo: null,
      // employee change reason
      changeEmployeeReason: null,
      changeEmployeeReasonType: null,
      changeEmployeeOtherReason: null,
      changeEmployeeNote: null,
      // employee leave info
      isEmployeeOnLeave: false,
      employeeLeaveInfo: null,
      // employee job hours info
      employeeJobHoursInfo: {},
      // holiday info
      isHoliday: false,
      holidayInfo: null,
      // preferences mismatch lists
      isMatchLanguage: true,
      isMatchSkill: true,
      mismatchedLanguageList: [],
      mismatchedSkillList: [],
      // loading flags
      loading: false,
      loadingJob: false,
      loadingAffectedJobList: false,
      // modal flags
      showAddFileModal: false,
      showCancelModal: false,
      showCancelConfirmModal: false,
      showEmergencyModal: false,
      showEmployeeReasonModal: false,
      showFunderModal: false,
      // single job list
      allSingleJobList: [],
      singleJobList: [],
      singleJobListTotal: 0,
      singleJobListType: LIST_FILTER_FUTURE,
      singleJobListPage: 1,
      // affected job list
      affectedJobList: [],
      affectedJobErrorMsg: null,
      // files
      employeeExpiredFiles: [],
      mainFileCategoryList: [],
      subFileCategoryList: [],
      // setting lists
      settingsAll: [],
      settingsPayroll: [],
      settingsLanguage: [],
      settingsSkill: [],
      settingsGender: [],
      settingsFunding: [],
      settingsOthers: {},
      // UI
      isShowSave: false,
      tabActiveKey: '1',
      textDuration: null,
      prevUnsentCommTotal: null,
      unsentCommTotal: 0
    }
    // this.filterJob = this.filterJob.bind(this)
  }

  async componentDidMount () {
    this.initialize()
  }

  render () {
    const { history, match, form } = this.props
    const {
      clientInfo,
      funderInfo,
      isShowClientPrivateAlert,
      isShowClientPublicAlert,
      isShowSave,
      item,
      loading,
      tabActiveKey,
      unsentCommTotal,
      clashClient,
      clashEmp
    } = this.state

    const jobId = this.getJobId()

    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const modeText = isShowSave ? `(Edit Mode)` : `(View Mode)`

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Jobs' menu={JobFvpMenu} countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`${this.isEdit() ? `` : `New `}Recurring Job ${clientInfo && clientInfo.id ? `For ${clientInfo.first_name} ${clientInfo.last_name}` : ''} ${modeText}`}>
            { !isShowSave && this.isEdit() && this.hasAccess('updateJob')
              ? <div className='btn' onClick={this.handleEditButton}>Edit</div>
              : null }

            { (isShowSave && this.hasAccess('updateJob')) || (!this.isEdit() && this.hasAccess('createJob'))
              ? <div className='btn' onClick={() => this.checkBeforeSave()}>{ loading ? <Icon type='loading' style={{ fontSize: 13 }} /> : 'Save' }</div>
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          { isShowClientPrivateAlert || isShowClientPublicAlert
            ? <div>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                    `${clientPublicAlertMsg ? `${clientPublicAlertMsg}<br />` : ''}` +
                    `${clientPrivateAlertMsg ? `${clientPrivateAlertMsg}<br />` : ''}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null }

          { clashClient || clashEmp
            ? <div>
              <Alert
                message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Address</span><br />` +
                    `${clashClient ? `Same address with client: ${clashClient}<br />` : ''}` +
                    `${clashEmp ? `Same address with employee: ${clashEmp}<br />` : ''}`
                  }} />
                }
                type='warning'
                showIcon
              /><br />
            </div>
            : null }

          { this.isEdit()
            ? <div className='job-remark' style={{ backgroundColor: '#ea2a2acc' }}><Icon type='exclamation-circle' /> You are editing Base Job. Any changes you make will affect future jobs.</div>
            : null }

          { item.emergency
            ? <div className='job-remark' style={{ backgroundColor: '#ff5b5bbb' }}><Icon type='exclamation-circle' /> This is Emergency job</div>
            : null }

          { item.cancellation_penalty > 0
            ? <div className='job-remark' style={{ backgroundColor: '#b17bcd' }}><Icon type='info-circle' /> This job has Late Cancellation charge ({this.getChargeHours()} hours)</div>
            : null }

          {/* { item.billing_rate_id !== null && this.isEdit()
            ? <div className='job-remark' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' /> This recurring job is still having previous funder rate configuration. Please save the base job to apply the patch.</div>
            : null } */}

          { funderInfo && funderInfo.id === null
            ? <div className='job-remark' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' /> The client does not have active funding period so unable to show the funder properly.</div>
            : null }

          <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)}>
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.infoTab() }
            </TabPane>

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2' forceRender>
                <Communication jobId={jobId} onUpdate={(value) => this.handleBadgeCount(value)} />
              </TabPane>
              : null }

            {/** CR0011 - disable certain tab for legacy job **/}
            {/* { this.isEdit()
              ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
                <File jobId={jobId} history={history} onRefreshPage={() => this.refreshPage()} />
              </TabPane>
              : null } */}

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='4'>
                <ActivityLog jobId={jobId} />
              </TabPane>
              : null }
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      affectedJobList,
      affectedJobErrorMsg,
      billingCategoryList,
      cancelReasonList,
      cancelTypeList,
      clientInfo,
      clientConflictInfo,
      clientLeaveInfo,
      clientLanguages,
      clientSkills,
      clientLanguagesName,
      clientSkillsName,
      conflictJobListClient,
      conflictJobListEmp,
      conflictJobList,
      conflictJobErrorMsg,
      currentEmpInfo,
      employeeAllList,
      employeeExpiredFiles,
      employeeJobHoursInfo,
      fileInfo,
      fileList,
      funderInfo,
      funderList,
      holidayInfo,
      isAllCurrentJobChecked,
      isClientOnLeave,
      isCustomSO,
      isEmployeeOnLeave,
      isEmployeePending,
      isDisableCategoriesSelect,
      isExtSleepover,
      isHoliday,
      isJobNeverEnd,
      isJobHasStartDate,
      isMatchLanguage,
      isMatchSkill,
      isShowCancelOtherField,
      isShowClientConflictAlert,
      isShowClientPrivateAlert,
      isShowClientPublicAlert,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert,
      isShowSleepoverAlert,
      jobDayNumber,
      jobOptionDate,
      item,
      loading,
      loadingAffectedJobList,
      loadingJob,
      mainFileCategoryList,
      mismatchedLanguageList,
      mismatchedSkillList,
      previousEmployeeList,
      rateSetInfo,
      settingsPayroll,
      singleJobList,
      singleJobListPage,
      singleJobListTotal,
      singleJobListType,
      showAddFileModal,
      showEmployeeReasonModal,
      showFunderModal,
      subFileCategoryList,
      textDuration
    } = this.state

    const billingCategoryListPreferred = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === true) : []
    const billingCategoryListOther = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === false) : []

    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isSleepoverToggleEnabled = !!form.getFieldValue('first_job_date') && funderInfo && funderInfo.id && validator.isNotEmptyArray(billingCategoryList) && billingCategoryList.findIndex(e => e.is_sleepover === true) > -1
    const isSleepoverListEnabled = !!form.getFieldValue('first_job_date') && form.getFieldValue('first_job_date') < Moment(new Date()) && funderInfo && funderInfo.id && form.getFieldValue('first_job_date') && isSleepoverToggleEnabled

    if (currentEmpInfo && currentEmpInfo.private_alert) {
      const pAlert = currentEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.public_alert) {
      const uAlert = currentEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = mainFileCategoryList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subFileCategoryList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleFileDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <Row gutter={16}>
          <Col lg={12}>
            <Panel>
              { !(clientInfo && clientInfo.id)
                ? <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </Col>
                </Row>
                : <Row>
                  <Col style={{ minHeight: 105 }}>
                    <span className='personnel-name'><a href={`/clients/${clientInfo.id}`} rel='noopener noreferrer' target='_blank'>{clientInfo.first_name } { clientInfo.last_name }</a></span>
                    <span className='personnel-accref'>{ clientInfo.acc_ref }</span>
                    { clientInfo.leave_id ? <span style={{ color: '#ff0000', fontSize: '9.5pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${clientInfo.until_further_notice ? 'UFN' : formatter.toShortDate(clientInfo.leave_end)}`}</span> : null }
                    <div className='personnel-details'>
                      { clientInfo.phone ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { clientInfo.phone }</div> : null }
                      <div style={{ fontSize: 12, marginRight: 10 }}>{ clientInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' } {clientInfo.address }</div>
                    </div>
                    <div className='personnel-details' style={{ marginTop: 15 }}>
                      <b>Preference</b>
                      <div style={{ fontSize: 12, marginRight: 10, marginLeft: 10, textTransform: 'capitalize' }}>{ clientInfo.preferred_gender ? <span style={{ color: '#ed6d1e' }}><Icon type='user-add' /></span> : null } { clientInfo.preferred_gender }</div>

                      <div style={{ fontSize: 12, marginRight: 10 }}>{ validator.isNotEmptyArray(clientLanguagesName) ? <span style={{ color: '#ed6d1e' }}><Icon type='font-size' /></span> : null } {
                        clientLanguagesName.map((language, idx) => (
                          <span key={idx} className='preferrence-item'>{language}{idx === clientLanguagesName.length - 1 ? '' : ','}</span>
                        ))
                      }</div>
                    </div>
                    <div className='personnel-sub-details'>
                      <b>Skills</b>
                      <div style={{ fontSize: 12, marginLeft: 10 }}>{ validator.isNotEmptyArray(clientSkillsName) ? <Icon type='check-circle' theme='twoTone' twoToneColor='#ed6d1e' /> : null } {
                        clientSkillsName.map((skill, idx) => (
                          <span key={idx} className='preferrence-item'>{skill}{idx === clientSkillsName.length - 1 ? '' : ','}</span>
                        ))
                      }</div>
                    </div>
                  </Col>
                </Row> }
            </Panel>
          </Col>
          <Col lg={12}>
            <Panel panelStyle={{ backgroundColor: funderInfo && funderInfo.isInvalidFunder ? 'red' : undefined }}>
              { !(funderInfo && funderInfo.id)
                ? <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </Col>
                </Row>
                : <Row>
                  <Col lg={24} style={{ height: 105 }}>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          { funderInfo.fullname
                            ? <span className='personnel-name'><a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>{ funderInfo.fullname }</a></span>
                            : <span className='personnel-name' style={{ color: '#ff0000' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>}
                          { funderInfo.acc_ref ? <span className='personnel-accref'>{ funderInfo.acc_ref }</span> : null }
                        </div>
                        <span className='changeFunder'
                          style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => this.triggerFunderModal(true)}><Icon type='form' style={{fontSize: '12pt'}} /></span>
                      </div>

                      <div className='personnel-details'>
                        { funderInfo.phone_number ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { funderInfo.phone_number }</div> : null }
                        <div style={{ fontSize: 12 }}>{ funderInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { funderInfo.address }</div>
                      </div>

                      { funderInfo.isInvalidFunder
                        ? <div>
                          <span className='personnel-sub-details' style={{ marginTop: 15 }}>
                          <span className='personnel-name' style={{ color: '#FFF' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>
                          </span>
                        </div>
                        : null }
                    </div>
                  </Col>
                </Row> }
            </Panel>
          </Col>
        </Row>

        <Panel
          title={`Recurring Job Schedule ${form.getFieldValue('first_job_date') ? `- Starting from ${Moment(form.getFieldValue('first_job_date')).format('DD/MM/YYYY')}, ${Moment(form.getFieldValue('first_job_date')).format('dddd')}` : ''}`}
          subtitle={<Row>
            <Col lg={24}>
              <div style={{ fontSize: 14 }}>
                { item.frequency ? <Icon type='clock-circle' /> : null } { textDuration }
                { item.week_month === 'months'
                  ? <span>, { item.frequency_number ? <Icon type='sync' /> : null } For { item.frequency_number } months ( {item.frequency} weeks)</span>
                  : null }
              </div>
            </Col>
          </Row>}>

          { validator.isNotEmptyArray(conflictJobListClient)
            ? <div style={{marginBottom: '20px'}}>
            { conflictJobListClient.map((c) => {
                const {
                  id,
                  job_start_date: jobStartDate,
                  job_end_date: jobEndDate,
                  employee_id: eid,
                  emp_firstname: empFirstName,
                  emp_lastname: empLastName,
                  client_id: cid,
                  first_name: clientFirstName,
                  last_name: clientLastName,
                  fund_fullName: fundName
                } = c
                const clientName = `${clientFirstName} ${clientLastName}`

                const clientText = `that is serving ${clientName}`
                return (
                  <div key={`cmfs-${id}`} style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                    <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`${jobLegacyURL}/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>A job {clientText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.</span>
                  </div>
                )
              })
            }
            </div>
            : null }

          <Row>
            <Col lg={24}>
              { isClientOnLeave && clientLeaveInfo
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }
              { isShowClientConflictAlert && clientConflictInfo
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))} <a href={`${jobLegacyURL}/single/${clientConflictInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }
              { isHoliday && holidayInfo
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {`${holidayInfo.name} (${Moment(holidayInfo.date).format('DD/MM/YYYY')})`}</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }
              { isShowSleepoverAlert
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Recurring Start Date'>
                {getFieldDecorator('first_job_date', {
                  initialValue: this.isEdit() ? Moment(item.first_job_date) : null,
                  rules: [
                    { required: true, message: 'Please enter first shift date' }
                  ]
                })(
                  <DateTimePicker showTime={false} onChange={this.handleStartDateChange} disabled={this.isEdit()} />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  { isJobNeverEnd
                    ? <FormItem key={`rlsica`} {...sideBySideExtraFormItemLayout} label='Recurring End Date'>
                      {getFieldDecorator('last_job_date', {
                        initialValue: null
                      })(
                        <DateTimePicker showTime={false} onChange={this.handleEndDateChange} disabled />
                      )}
                    </FormItem>
                    : <FormItem key={`rlsppa`} {...sideBySideExtraFormItemLayout} label='Recurring End Date'>
                      {getFieldDecorator('last_job_date', {
                        initialValue: this.isEdit() ? (Moment(item.last_job_date) || null) : null,
                        rules: [
                          { required: true, message: 'Please enter last shift date' }
                        ]
                      })(
                        <DateTimePicker showTime={false} onChange={this.handleEndDateChange} disabled={(this.isEdit() ? false : !isJobHasStartDate)} />
                      )}
                    </FormItem> }
                </Col>
                <Col lg={5}>
                  <FormItem {...specialFormItemLayoutToggle}>
                    {getFieldDecorator('is_never_end', {
                      initialValue: item.is_never_end || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Never&nbsp;End'
                        unCheckedChildren='Never&nbsp;End'
                        onChange={(e) => this.handleJobNeverEndClick(e)}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Job Start Time'>
                {getFieldDecorator('job_start_time', {
                  initialValue: this.isEdit() ? Moment(item.job_start_time) : null,
                  rules: [
                    { required: true, message: 'Please enter job start time' }
                  ]
                })(
                  <DateTimePicker showTime showDate={false} onChange={this.handleStartTimeChange} disabled={this.isEdit() ? false : !isJobHasStartDate} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Job End Time'>
                {getFieldDecorator('job_end_time', {
                  initialValue: this.isEdit() ? Moment(item.job_end_time) : null,
                  rules: [
                    { required: true, message: 'Please enter job end time' }
                  ]
                })(
                  <DateTimePicker showTime showDate={false} onChange={this.handleEndTimeChange} disabled={this.isEdit() ? false : !isJobHasStartDate} />
                )}
              </FormItem>

            </Col>

          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...shortFormItemLayout} label='Frequency'>
                {getFieldDecorator('interval', {
                  initialValue: item.interval,
                  rules: [
                    { required: true, message: 'Please select interval' }
                  ]
                })(
                  <Select
                    disabled={this.isEdit()}
                    style={{ width: 150 }}
                    onChange={(value) => this.handleIntervalClick(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {/* <Option value='daily'>Daily</Option> */}
                    <Option value='week'>Weekly</Option>
                    <Option value='fortnight'>Fortnightly</Option>
                    {/* <Option value='monthly'>Monthly</Option> */}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Shift Work' type={item.employee_id === null ? 'warn' : ''}>
          <Row gutter={16}>
            <Col lg={24}>
              <div style={{ marginBottom: 10 }}>
                { /** MS0021 pt2 : list employee conflicted job  */}
                { validator.isNotEmptyArray(conflictJobListEmp)
                  ? <div style={{marginBottom: '20px'}}>
                    { conflictJobListEmp.map((c) => {
                        const { id, job_start_date: jobStartDate, job_end_date: jobEndDate, employee_id: eid, emp_firstname: empFirstName, emp_lastname: empLastName, client_id: cid, first_name: clientFirstName, last_name: clientLastName, fund_fullName: fundName } = c

                        const empName = `${empFirstName} ${empLastName}`
                        // console.log('array', isClient, isEmployee, clientName, empName)

                        const employeeText = `served by ${empName}`
                        return (
                          <div key={`emfs-${id}`} style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                            <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`${jobLegacyURL}/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>A job {employeeText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.</span>
                          </div>
                        )
                      }) }
                    </div>
                    : null }

                { isShowEmployeePrivateAlert || isShowEmployeePublicAlert
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div>
                          <span style={{ fontWeight: 'bold' }}>{`Employee: ${currentEmpInfo.first_name} ${currentEmpInfo.last_name}`}</span>
                          { employeePublicAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                            : null }
                          { employeePrivateAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                            : null }
                        </div>
                      }
                      type='warning'
                      showIcon
                    />
                  </div>
                  : null }

              { isEmployeeOnLeave
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              {/* Employee max hour warning */}
              { !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>}
                    type='error'
                    showIcon
                  />
                </div>
                : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>}
                      type='info'
                      showIcon
                    />
                  </div>
                  : null }

              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div className='alert-info'>
                  <Alert
                    banner
                    message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                    `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                    }} />}
                    type='error'
                    showIcon
                  />
                </div>
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                      }} />}
                      type='info'
                      showIcon
                    />
                  </div>
                  : null }

              { !isMatchLanguage
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${mismatchedLanguageList.map(e => e).join(', ')},
                      but ${currentEmpInfo.first_name} ${currentEmpInfo.last_name} does not. `} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { !isMatchSkill
                ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}  does not possess following skill(s): ${mismatchedSkillList.map(e => e).join(', ')}`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { validator.isNotEmptyArray(employeeExpiredFiles)
                  ? (employeeExpiredFiles.map((file, idx) => {
                    return (
                      <div key={`expired file ${idx}`} className='alert-info'>
                        <Alert
                          message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                          type='error'
                          banner
                          showIcon
                        />
                      </div>
                    )
                  }))
                  : null }
              </div>
            </Col>
          </Row>

          <Form layout='vertical'>
            <Row gutter={16}>
              <Col lg={8}>
                <FormItem label={<span>Shift Type
                  <Switch
                    disabled={!isSleepoverToggleEnabled}
                    checkedChildren='Sleepover'
                    unCheckedChildren='Sleepover?'
                    style={{ marginLeft: 10 }}
                    checked={isExtSleepover}
                    onChange={(value) => this.handleSleepover(value, false, true)}
                  />
                </span>}>
                  {getFieldDecorator('billing_category_id', {
                    initialValue: item.billing_category_id,
                    rules: [
                      { required: true, message: 'Please Select Billing Rate' }
                    ]
                  })(
                    <Select
                      onSelect={(v, o) => this.handleBillingSelectCategory(o)}
                      disabled={isExtSleepover || isDisableCategoriesSelect}
                      showSearch
                      filterOption={(input, option) =>
                        this.filterBillingCategory(input, option)
                      }>
                        { validator.isNotEmptyArray(billingCategoryListPreferred)
                          ? <OptGroup label={<div className='label-title'>----- PREFERRED -----</div>}>
                            { billingCategoryListPreferred.map((bill, idx) => {
                              return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsa-${idx}`} value={bill.id}>{bill.name}</Option> : null
                            }) }
                          </OptGroup>
                          : null }
                        { validator.isNotEmptyArray(billingCategoryListOther)
                          ? <OptGroup label={<div className='label-title'>{validator.isNotEmptyArray(billingCategoryListPreferred) ? <p></p> : null}<p>----- OTHER -----</p></div>}>
                            { billingCategoryListOther.map((bill, idx) => {
                              return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsb-${idx}`} value={bill.id}>{bill.name}</Option> : null
                            }) }
                          </OptGroup>
                          : null }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={8} style={{ marginTop: '8.5px' }}>
                <FormItem label='Carer Skill Level Required'>
                  { getFieldDecorator('payroll', this.isEdit()
                    ? { initialValue: item.payroll,
                        rules: [
                          { required: true, message: 'Please Select Carer Skill Level' }
                        ] }
                    : { initialValue: clientInfo.payroll_category,
                        rules: [
                          { required: true, message: 'Please Select Carer Skill Level' }
                        ]})
                    (<Select
                      onChange={this.handleChangePayroll}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      { settingsPayroll.map((pay) => {
                          return <Option key={pay.value}>{pay.name}</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem label={
                  <span>Employee <Switch
                    checkedChildren='Pending'
                    unCheckedChildren='Pending'
                    style={{ marginLeft: 10 }}
                    checked={isEmployeePending}
                    onChange={this.handlePendingEmployee}
                  /></span>
                } hasFeedback>
                  {getFieldDecorator('employee_id', {
                    initialValue: item.employee_id,
                    rules: [
                      !isEmployeePending ? { required: true, message: 'Please select an employee OR turn on Pending' } : {}
                    ]
                  })(
                    <Select showSearch
                      style={{ width: '100%' }}
                      placeholder='Employee'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      filterOption={(input, option) => this.filterEmployees(input, option)}
                      onChange={this.handleEmployeeChange} disabled={isEmployeePending}>
                      { employeeAllList.map((items, idx) => {
                          return <Option key={`empls-${idx}`} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <span style={{ color: '#ff0000', fontSize: '9pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start)} - ${formatter.toShortDate(items.leave_end)}`}</span> : null }</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>
              </Col>

            </Row>

            <Row gutter={16}>
              <Col lg={8}>
                <FormItem label='Tasks' hasFeedback>
                  {getFieldDecorator('tasks', {
                    initialValue: this.replacer(item.tasks),
                    rules: [
                      { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter tasks' }
                    ]
                  })(
                    <TextArea row={4} />
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem label='Notes'>
                  {getFieldDecorator('notes', {
                    initialValue: this.replacer(item.notes),
                  })(
                    <TextArea row={4} />
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem label='Max KM'>
                  {getFieldDecorator('job_kms', {
                    initialValue: this.isEdit() ? item.job_kms : 0,
                    rules: [
                      { validator: this.checkKMSInput }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={8}>

              </Col>
            </Row>
          </Form>
        </Panel>

        {/* { isExtSleepover
          ? <Panel title='Sleepover'>
            <Form className='getup-panel'>
              <Row>
                <Col lg={24}>
                  <Row className='getup'>
                    <Col offset={4} xs={{ offset: 1 }} lg={22}>
                      <Row>
                        <Col xs={24} lg={3}><div className='getup-label-2'>Custom S/O</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_start_time', {
                              initialValue: item.custom_so_start_time ? Moment(item.custom_so_start_time) : rateSetInfo.so_hours_start ? Moment(rateSetInfo.so_hours_start) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={2} lg={1}><div className='getup-label-2'>to</div></Col>
                        <Col xs={7} lg={3}>
                          <FormItem>
                            {getFieldDecorator('custom_so_end_time', {
                              initialValue: item.custom_so_end_time ? Moment(item.custom_so_end_time) : rateSetInfo.so_hours_end ? Moment(rateSetInfo.so_hours_end) : null
                            })(
                              <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={9} lg={9}>
                          <FormItem>
                            {getFieldDecorator('is_custom_so_time', {
                              initialValue: isCustomSO || false
                            })(
                              <Switch
                                checkedChildren='Enabled Custom SO Time'
                                unCheckedChildren='Enable Custom SO Time?'
                                style={{ marginLeft: 10 }}
                                checked={isCustomSO}
                                onChange={this.handleSleepoverCustomSO}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Panel>
          : null } */}

        { validator.isNotEmptyArray(previousEmployeeList)
          ? <Panel title='Previous Carers'>
            <Row>
              <Col lg={24}>
                { previousEmployeeList.map((employee) => (
                    <span key={`revemfs-${employee.employee_id}`} className='employeeList' style={{ color: '#242b49' }}><a href={`/employees/${employee.employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</a></span>
                  )) }
              </Col>
            </Row>
          </Panel>
          : null }

        { !this.isEdit()
          ? <Panel
              title='Files'
              subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
            >
              <List cols={fileColumns} rows={fileList} />
          </Panel>
          : null }

        { this.isEdit()
          ? <Panel title='Effective Date of Changes'>
            <Row>
              <Col lg={24}>
                <div className='specific-date'>
                  <p><Icon type='exclamation-circle' /> To apply these updates after a specific date, add that date below.</p>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Effective From' {...shortFormItemLayout}>
                        {getFieldDecorator('from_date', {
                          initialValue: null,
                          rules: [
                            { required: true, message: 'Please enter date' }
                          ]
                        })(
                          <DateTimePicker showTime={false} onChange={this.handleEffectiveDateChange} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
                {/* <div className='apply-all-jobs'>
                  <Checkbox checked={isAllCurrentJobChecked} onChange={this.handleApplyAllAffectedJobs}>Apply these changes to all future jobs, even if they have been updated with different details to the base jobs. </Checkbox>
                  {loadingAffectedJobList ? <Icon type='loading' style={{ marginLeft: '8px', fontSize: '15pt' }} /> : null}
                </div> */}

                { /** -------------------------------------------------------------------- */}
                { validator.isNotEmptyArray(affectedJobList)
                  ? <div style={{marginTop: '8pt'}}>
                    { affectedJobList.map((c) => {
                      const { id, job_start_date: jobStartDate } = c

                      return (
                        <div key={`afcs-${id}`} style={{ backgroundColor: '#ff000022', padding: '2pt 10pt 2pt 10pt' }}>
                          <span className='personnel-name' style={{color: '#ff0000', fontSize: '9.5pt', fontStyle: 'regular' }}><Icon type='exclamation-circle' /> <a href={`${jobLegacyURL}/single/${id}`} target='_blank' style={{color: '#cc0000', fontSize: '9.5pt', fontStyle: 'bold'}}>The job on {formatter.toStandardLongDate(jobStartDate)}</a> is individually updated before.</span>
                        </div>
                      )
                    })}
                  </div>
                  : null }
              </Col>
            </Row>
          </Panel>
          : null }

        { this.isEdit()
          ? <Panel title='All Recurring Jobs'>
            <Radio.Group onChange={(e) => this.filterAllJobList(e)} defaultValue={singleJobListType} style={{ marginLeft: 20 }}>
              <Radio.Button value='future'>Future</Radio.Button>
              <Radio.Button value='previous'>Past</Radio.Button>
            </Radio.Group>

            <div className='jobs'>
              <Skeleton loading={loadingJob} active>
                { validator.isNotEmptyArray(singleJobList)
                  ? singleJobList.map((item) => {
                    const {
                      id,
                      job_start_date,
                      job_end_date,
                      first_name,
                      last_name,
                      emp_firstname,
                      sleepover,
                      emp_lastname,
                      suburb,
                      tasks,
                      fund_fullname,
                      is_delete: jobDelete,
                      is_cancel: jobCancel,
                      client_id,
                      employee_id,
                      funder_id,
                      client_leave_id,
                      client_leave_start,
                      client_leave_end,
                      employee_leave_id,
                      employee_leave_start,
                      employee_leave_end,
                      holiday_date,
                      holiday_name,
                      client_leave_until_further_notice,
                      is_sleepover_job
                    } = item

                    const isUpdated = this.handleCheckJobAffectedStatus(item)

                    return (
                      <div className={`list-item ${isUpdated === 1 ? 'list-item-warning' : holiday_date ? 'list-item-highlight' : ''}`} key={`amcs-${id}`}>
                        <Row>
                          <Col lg={1}>
                            { !jobCancel && !jobDelete
                              ? <div className='normalIcon'>
                                <Icon type='check-circle' />
                              </div>
                              : !jobCancel && jobDelete
                                ? <div className='cancelIcon'>
                                  <Icon type='delete' theme='twoTone' twoToneColor='#17202A' />
                                </div>
                                : jobCancel && !jobDelete
                                  ? <div className='cancelIcon'>
                                    <Icon type='close-circle' theme='twoTone' twoToneColor='#eb2f96' />
                                  </div>
                                  : <div className='cancelIcon'>
                                    <Icon type='close-circle' theme='twoTone' twoToneColor='#eb2f96' />
                                    <Icon type='delete' theme='twoTone' twoToneColor='#17202A' />
                                  </div> }
                          </Col>
                          <Col lg={22}>
                            <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                              <Col lg={8}>
                                <Row>
                                  <Col lg={5}>
                                    <div className='subtitle'>Client</div>
                                  </Col>
                                  <Col lg={14}>
                                    <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
                                      { client_leave_id ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(client_leave_start)} - ${client_leave_until_further_notice ? 'UFN' : formatter.toShortDate(client_leave_end)}`}</div> : null }
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={8}>
                                <Row>
                                  <Col lg={6}>
                                    <div className='subtitle'>Employee</div>
                                  </Col>
                                  <Col lg={14}>
                                    <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a></div>
                                    { employee_leave_id ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div> : null }
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={8}>
                                <Row>
                                  <Col lg={5}>
                                    <div className='subtitle'>Funder</div>
                                  </Col>
                                  <Col lg={14}>
                                    <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a></div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                              <Col md={8}>
                                <div className='detail'><Icon type='calendar' /> { formatter.toShortDate(job_start_date)} - { formatter.toShortDate(job_end_date)} { holiday_date ? <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' /></Tooltip> : null } <span className='time-sum'>{Moment(job_start_date).format('dddd')}</span></div>
                              </Col>
                              <Col md={8}>
                                <div className='detail'><Icon type='clock-circle' /> { formatter.toShortTime(job_start_date)} - { formatter.toShortTime(job_end_date)} { is_sleepover_job ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(Moment.duration(Moment(job_end_date).diff(Moment(job_start_date))).asHours())} hours</span> }</div>
                              </Col>
                              <Col md={6}>
                                <div className='detail'><Icon type='environment' /> {suburb}</div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 5 }}>
                              <Col>
                                <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : tasks }</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={1} className='action-icon'>
                            <Link to={`${jobLegacyURL}/single/${id}`}>
                              { this.hasAccess('readJob') || this.hasAccess('updateJob')
                                ? <div style={{ color: '#D66E00' }}>
                                  <Tooltip mouseLeaveDelay={0} title='Manage job'>
                                    <Icon type='form' />
                                  </Tooltip>
                                </div>
                                : null }
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    )
                  })
                  : <img src={`${process.env.PUBLIC_URL}/img/table-nodata.png`} style={{ width: '200px', margin: '10px auto' }} />}
              </Skeleton>
            </div>
            <Pager
              size={pageSize}
              total={singleJobListTotal ? parseInt(singleJobListTotal) : 0}
              totalText={`Total ${singleJobListTotal} jobs`}
              current={singleJobListPage}
              onChange={(e) => this.handleJobListPageChange(e)}
              style={{ marginTop: '15px' }}
            />
          </Panel>
          : null }

        {/* --------------------------------------FUNDER MODAL START---------------------------------------- */}
        <Modal
          width={600}
          title='Change Funder'
          visible={showFunderModal}
          onOk={this.handleFunderSubmit}
          onCancel={this.handleFunderCancel}
          footer={[
            <Row>
              <Button key='ok' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>
              <Button key='cancel' onClick={this.handleFunderCancel}>Cancel</Button>
            </Row>
          ]}
        >
          { showFunderModal
            ? <div>
                <FormItem {...formItemLayout} label='Funder' hasFeedback>
                {getFieldDecorator('funder_id', {
                  initialValue: funderInfo.id
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Funders'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    { funderList.map((funder, idx) => (<Option key={`fusx-${idx}`} value={funder.funder_id}>
                        {funder.funder_fullname}
                      </Option> )) }
                  </Select>
                )}
              </FormItem>
            </div>
            : null }
        </Modal>
        {/* --------------------------------------FUNDER MODAL END---------------------------------------- */}

        {/* --------------------------------------CHANGE EMPLOYEE MODAL START---------------------------------------- */}
        <SideModal
          title='You Have Changed Employee'
          showModal={showEmployeeReasonModal}
          onClose={() => this.setState({showEmployeeReasonModal: false})}
          buttons={[
            <Button key='0' type='primary' onClick={() => this.handleEmployeeChangeReason()}>Submit</Button>
          ]}
        >
          { showEmployeeReasonModal
            ? <div>
              <FormItem label='Reason Type'>
                {getFieldDecorator('change_employee_reason_type', {
                  rules: [
                    { required: true, message: 'Please select change type' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleEmployeeCancelTypeChange}>
                    { cancelTypeList.map((items, idx) => {
                        return <Option key={`cts${idx}`} value={items.id}>{items.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>
              <FormItem label='Reason To Change'>
                {getFieldDecorator('change_employee_reason', {
                  initialValue: item.cancellation_reason,
                  rules: [
                    { required: true, message: 'Please select reason' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleEmployeeCancelReasonChange}>
                    { cancelReasonList.map((items, idx) => {
                        return <Option key={`ctsl-${idx}`} value={items.name}>{items.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>
              { isShowCancelOtherField
                ? <FormItem label='Other Reason To Change'>
                  {getFieldDecorator('change_employee_other_reason', {
                  })(
                    <TextArea row={2} />
                  )}
                </FormItem>
                : null }
              <FormItem label='Notes (Optional)'>
                {getFieldDecorator('change_employee_note', {
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>
            </div>
            : null }
        </SideModal>
        {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

        {/* --------------------------------------ADD FILE MODAL START---------------------------------------- */}
        <AddFileModal
          jobId={'add'}
          key={`addfilemodaljob_new`}
          item={fileInfo}
          categoriesList={mainFileCategoryList}
          subCategoriesList={subFileCategoryList}
          onClose={() => this.handleAddFileModal(false)}
          onSetFile={(values) => this.updateFileAdded(values)}
          visible={showAddFileModal}
        />

        {/* --------------------------------------ADD FILE MODAL END---------------------------------------- */}

      </Loading>
    )
  }

  initialize = async () => {
    const { singleJobListType, singleJobListPage } = this.state
    let clientId = null
    let funderId = null
    let employeeId = null

    this.fetchSettings()

    if (this.isEdit()) {
      const item = await this.fetchRecurringJob()
      const jobId = this.getJobId()

      if (item) {
        clientId = item.client_id
        funderId = item.funder_id
        employeeId = item.employee_id
        this.showDuration(Moment(item.job_start_time), Moment(item.job_end_time))
      }

      this.fetchCancel()
      this.fetchSingleJobList(singleJobListType, singleJobListPage)
      // this.fetchLogs()
      this.fetchUnsentEmail(jobId)

      this.handleJobAffectedChecking(true, undefined, clientId)
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)
      clientId = client
      funderId = funder
    }

    this.fetchFunders(clientId, funderId, true)
    this.fetchClient(clientId, employeeId)
    this.fetchAllEmployees(clientId, employeeId)
    this.fetchFileCategories()

    this.fetchPreviousEmployees(clientId)
    this.props.fetchTotalPending()

    this.setState({ clientId, funderId, employeeId })
  }

  /*********************************************************************************
    Keep all refactored/clean codes below here
    Please arrange by alphabet A-Z
  *********************************************************************************/

  /** ========== SECTION 1: fetch functions ========== */
  fetchAllEmployees = async (clientId, employeeId) => {
    try {
      const employeeAllList = await clientService.getClientEmployees(clientId)
      this.setState({ employeeAllList })

      setTimeout(() => {
        this.cacheEmployee(employeeId)
      }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchCancel = async () => {
    const cancelType = await settingCancellationService.getAll()
    this.setState({ cancelTypeList: cancelType.list || [] })
  }

  fetchClient = async (clientId, empId) => {
    try {
      const clientInfoRes = await clientService.get(clientId)
      const clientInfo = clientInfoRes.item
      const clientLanguages = clientInfo.preferences.languages || []
      const clientSkills = clientInfo.preferences.skills || []
      const clientLanguagesName = clientInfo.preferences.languages_name || []
      const clientSkillsName = clientInfo.preferences.skills_name || []
      const isShowClientPrivateAlert = clientInfo.private_alert !== null && !validator.isEmptyString(clientInfo.private_alert)
      const isShowClientPublicAlert = clientInfo.public_alert !== null && !validator.isEmptyString(clientInfo.public_alert)
      const clashClient = clientInfoRes.clashClient
      const clashEmp = clientInfoRes.clashEmp

      this.setState({
        clientInfo,
        clientLanguages,
        clientSkills,
        clientLanguagesName,
        clientSkillsName,
        isShowClientPrivateAlert,
        isShowClientPublicAlert,
        clashClient,
        clashEmp
      })


      setTimeout(() => {
        this.checkClient(clientId)
      }, 1000)

      if (empId) {
        setTimeout(() => {
          this.checkEmployee(empId)
        }, 1000)
      }

      setTimeout(() => {
        this.checkConflictJobs()
      }, 2000)
    } catch (e) {
      console.log(e)
      notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later')
    }
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        mainFileCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subFileCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loading: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId, funderId, isUpdateFunderList = false) => {
    const { form, history } = this.props
    let clientFunderSettingLegacyInfo = {}
    let funderInfo = { id: null }
    let funderPeriods = []
    let legacyFunders = []
    let funders = []

    if (isUpdateFunderList) {
      funders = await fvpClientFundingService.listClientFunders(clientId)
    } else {
      funders = this.state.funderList
    }

    if (validator.isNotEmptyArray(funders)) {
      if (funderId) {
        const f = funders.find(e => e.funder_id === parseInt(funderId))

        if (f && f.id) {
          const info = await funderService.get(funderId)
          if (info && info.item && info.item.id) {
            funderInfo = info.item
          }
          const periods = await fvpClientFundingService.listClientFundingPeriod(clientId, funderId)

          if (validator.isNotEmptyArray(periods)) {
            funderPeriods = periods
          }
        } else {
          this.showInvalidFunderNotification()

          if (funderId) {
            const info = await funderService.get(funderId)
            if (info && info.item && info.item.id) {
              funderInfo = info.item
              funderInfo.isInvalidFunder = true
            }
          }

          // confirm({
          //   icon: (<Icon type='close-circle' style={{color: 'red'}} />),
          //   title: `Invalid Funder`,
          //   content: (
          //     <div>
          //       The funder is not set for this client. Please press on <b>Go To Client Page</b> to add the funder or <b>Cancel</b> to back to the listing.
          //     </div>
          //   ),
          //   okText: 'Go To Client Page',
          //   cancelText: 'Cancel',
          //   onOk () {
          //     history.replace(`/clients/${clientId}`)
          //   },
          //   onCancel () {
          //     history.replace(`${jobLegacyURL}/recurring`)
          //   }
          // })
        }
      }
    }

    this.setState({
      currentFunderInfo: Object.assign({}, funderInfo),
      prevFunderInfo: this.state.prevFunderInfo && this.state.prevFunderInfo.id ? this.state.prevFunderInfo : Object.assign({}, funderInfo),
      funderList: funders,
      funderId,
      funderInfo,
      funderPeriodList: funderPeriods
    }, () => {
      const { item } = this.state
      if (item && item.id) {
        this.fetchRateSetInfo(Moment(new Date()))
        this.checkCategoriesAndUpdate(Moment(new Date()))
      }
    })

    if (!validator.isNotEmptyArray(funders) && clientFunderSettingLegacyInfo && clientFunderSettingLegacyInfo.end_date === null) {
      this.alertUnassignedTime()
    } else if (funderInfo && funderInfo.id && !validator.isNotEmptyArray(funderPeriods)) {
      this.alertUnassignedTime()
    }
  }

  fetchPreviousEmployees = async (clientId) => {
    try {
      const previousEmployeeList = await clientService.getClientPrevEmployeesActive(clientId)

      if (validator.isNotEmptyArray(previousEmployeeList)) {
        this.setState({ previousEmployeeList })
      }
    } catch (e) {
      notify.error('Unable to load successfully, Unable to load previous employee list successfully. Please try again later')
    }
  }

  // ======= fvp functions start =======
  fetchRateSetInfo = async (jobStartDateTime) => {
    const { funderInfo } = this.state

    if (!funderInfo.isInvalidFunder) {
      try {
        const body = {
          date: jobStartDateTime,
          rate_id: funderInfo.fvp_rate_id
        }

        const r = await fvpRatesService.getDetails(body)
        if (r && r.id) {
          this.setState({ rateSetInfo: r })
        } else {
          this.setState({ rateSetInfo: {} })
        }
      } catch (e) {
        this.setState({ rateSetInfo: {} })
      }
    }
  }

  fetchRecurringJob = async () => {
    try {
      this.setState({ loading: true })
      const { item } = await baseJobService.get(this.getJobId())

      const isCustomSO = !!item.is_custom_so_time
      const isEmergency = !!item.emergency
      const isEmployeePending = !item.employee_id
      const isExtSleepover = !!item.is_sleepover_job
      const isJobNeverEnd = !!item.is_never_end

      this.setState({
        item,
        itemOri: cloneDeep(item),
        isCustomSO,
        isEmergency,
        isEmployeePending,
        isJobNeverEnd,
        isExtSleepover,
        loading: false,
        clientId: item.client_id,
        clientFunderLegacyId: item.client_funder_id,
        employeeId: item.employee_id,
        funderId: item.funder_id
      })

      return item
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load base job successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchSingleJobList = async (filterParam, startPage = null) => {
    const { singleJobListPage } = this.state
    const currentDate = new Date()
    const filter = {}

    this.setState({ loadingJob: true })
    const page = startPage || singleJobListPage

    if (filterParam === LIST_FILTER_FUTURE) {
      filter.job_start_date = { condition: '>=', value: currentDate }
    } else if (filterParam === LIST_FILTER_PREVIOUS) {
      filter.job_start_date = { condition: '<=', value: currentDate }
    } else {
      filter.job_start_date = { condition: '>=', value: currentDate }
    }

    filter.base_job_id = { condition: '=', value: this.getJobId() }

    try {
      let allList = []
      let list = []
      let total = 0
      if (filterParam === LIST_FILTER_PREVIOUS) {
        const { list: sjl, total: t } = await baseJobService.getJobListDesc(page, pageSize, filter)
        list = sjl
        total = t
      } else {
        const { list: sjl, total: t } = await baseJobService.getJobList(page, pageSize, filter)
        list = sjl
        total = t
      }

      this.setState({
        singleJobListType: filterParam,
        singleJobListPage: page,
        singleJobList: list,
        singleJobListTotal: total,
        loadingJob: false
      }, async () => {
        const { list: allSingleJobList } = await baseJobService.getJobList(1, 0, filter)
        this.setState({ allSingleJobList })
      })

    } catch (e) {
      notify.error('Unable to load successfully, Unable to load single jobs list successfully. Please try again later')
      this.setState({ loadingJob: false })
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)

    this.setState({
      settingsAll: settings.list,
      settingsPayroll: settings.list.filter(item => item.type === 'payroll'),
      settingsLanguage: settings.list.filter(item => item.type === 'language'),
      settingsSkill: settings.list.filter(item => item.type === 'skill'),
      settingsGender: settings.list.filter(item => item.type === 'gender'),
      settingsFunding: settings.list.filter(item => item.type === 'funding'),
      settingsOthers: settingOthers.item
    })
  }

  fetchUnsentEmail = async (jobId, isRedirectedFromNewJob = false) => {
    const { unsentTotal } = await jobService.getUnsentEmailTotalRecurring(jobId)

    // if it is redirected fron new job, prevUnsentCommTotal should be null, so set the unsentCommTotal and go to checkEmail
    // else if load from id, just update both values; or if after edit save, just update unsentCommTotal and prevUnsentCommTotal will be updated at checkEmail().
    if (this.state.prevUnsentCommTotal !== null) {
      this.setState({ unsentCommTotal: unsentTotal })
    } else {
      if (isRedirectedFromNewJob) {
        this.setState({ unsentCommTotal: unsentTotal }, () => {
          this.checkEmail()
        })
      } else {
        this.setState({ unsentCommTotal: unsentTotal, prevUnsentCommTotal: unsentTotal })
      }
    }
  }

  /** ========== SECTION 2: check functions ========== */
  /** check before save function */
  checkBeforeSave = () => {
    const {
      affectedJobList,
      clientConflictInfo,
      clientInfo,
      clientLeaveInfo,
      conflictJobList,
      currentEmpInfo,
      employeeExpiredFiles,
      employeeJobHoursInfo,
      funderInfo,
      isClientOnLeave,
      isEmployeeOnLeave,
      isExtSleepover,
      isMatchSkill,
      isShowClientConflictAlert,
      isShowClientPrivateAlert,
      isShowClientPublicAlert,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert,
      item,
      loading,
      mismatchedSkillList,
      clashClient,
      clashEmp
    } = this.state

    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isAnyJobConflicted = validator.isNotEmptyArray(conflictJobList)
    const isAnyJobAffected = validator.isNotEmptyArray(affectedJobList)
    const isFileExpired = validator.isNotEmptyArray(employeeExpiredFiles)
    const { form } = this.props
    const { onSaveJob } = this

    const isShowJobWarning = this.checkSingleJobsDelete()

    if (!form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date') &&
    (form.getFieldValue('last_job_date') < form.getFieldValue('first_job_date'))) {
      this.showDateNotification()
      return
    }

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    if (loading) return

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.private_alert) {
      const pAlert = currentEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (currentEmpInfo && currentEmpInfo.public_alert) {
      const uAlert = currentEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (isShowClientPrivateAlert || isShowEmployeePrivateAlert || isShowClientPublicAlert || isShowEmployeePublicAlert ||
      isClientOnLeave || !isMatchSkill || isAnyJobConflicted || isAnyJobAffected) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>
            <br />

            {/* Private Alert */}
            { isShowEmployeePrivateAlert || isShowEmployeePublicAlert
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <span style={{ fontWeight: 'bold' }}>{` Employee: ${currentEmpInfo.first_name} ${currentEmpInfo.last_name}`}</span>
                  { employeePublicAlertMsg.length > 0
                    ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                    : null }
                  { employeePrivateAlertMsg.length > 0
                    ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                    : null }
                </p>
              </div>
              : null }

            {/* clashed address detail */}
            { clashClient || clashEmp
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Address</strong><br />` +
                          `${clashClient ? `Same address with client: ${clashClient}<br />` : ''}` +
                          `${clashEmp ? `Same address with employee: ${clashEmp}<br />` : ''}`
                      }} />
                  </p>
                </div>
                : null }

            {/* Files Expiry */}
            { isFileExpired
              ? (<div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Expired Files:</strong></p>
                <li>
                  <ul>{employeeExpiredFiles.map((file, idx) => { return (<li key={`expired file warnings ${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                </li>
                <br />
              </div> )
              : null }

            {/** Create job max hour warning */}
            { !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                  <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                  <div> {`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>
                </p>
              </div>
              : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                    <div>{`${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>
                  </p>
                </div>
                : null }

            {/** Update job max hour warning */}
            { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                  <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                    `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                  }} />
                </p>
              </div>
              : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                      `${currentEmpInfo.first_name} ${currentEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                    }} />
                  </p>
                </div>
                : null }

            {/* Client On Leave */}
            { isClientOnLeave
              ? <div>
                <div><p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div>
              : null }

            {/* Employee On Leave */}
            { isEmployeeOnLeave
              ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{currentEmpInfo.first_name} {currentEmpInfo.last_name} is on leave from {Moment(currentEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(currentEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div>
              : null }

            {/* Skill Mismatch */}
            { isMatchSkill
              ? null
              : <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>{currentEmpInfo.first_name} {currentEmpInfo.last_name} does not possess following skill(s):</strong></p>
                <ul>
                  { mismatchedSkillList.map((skills, index) => (
                      <li key={`mskl-${index}`}>{skills}</li>
                    )) }
                </ul>
                <br />
              </div> }

            {/* Conflicted Jobs exists */}
            { isAnyJobConflicted
              ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Jobs are conflicting </strong></p>
                <p>{conflictJobList.length} job{conflictJobList.length === 1 ? '' : 's'} in total are conflicting with Base's series. Please check those jobs before you continue.</p>
                <br />
              </div>
              : null }

            {/* Individual Edited Jobs exists */}
            { isAnyJobAffected
              ? <div>
                <p style={{ fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>Jobs are different from base </strong></p>
                <p>{affectedJobList.length} job{affectedJobList.length === 1 ? '' : 's'} that have been individually updated before are different from Recurring Base. Please check those Jobs and apply update individually.</p>
                <br />
              </div>
              : null }

            {/* Warning Recurring Single Jobs to be remove */}
            { validator.isNotEmptyArray(isShowJobWarning)
              ? <div>
                <p style={{ color: 'red', fontSize: '15px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '13pt' }} /> <strong>(Remove Job) Recurring single job(s) below are unable to revert after save:</strong></p>
                <ul>
                  { isShowJobWarning.map((job, index) => (
                      <li key={`jtdlc-${index}`} style={{ color: 'red' }}>{`${formatter.toStandardDate(job.job_start_date)} - ${formatter.toShortTime(job.job_end_date)} (ID: ${job.id})`}</li>
                    )) }
                </ul>
                <br />
              </div>
              : null }

            <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          onSaveJob()
        },
        onCancel () {
        }
      })
    } else {
      onSaveJob()
    }
  }

  checkCategoriesAndUpdate = (jobStartDateTime) => {
    const { form } = this.props
    const { isExtSleepover, funderPeriodList, isDisableCategoriesSelect: isl } = this.state
    let billingCategoryList = []
    let isDisableCategoriesSelect = isl

    if (jobStartDateTime) {
      /**
       * Update on 20220624: funderPeriodList will add new periods which has no period ID but having funder_id and valid category list.
       * this list is to indicate the admin select a job date that beyond of current client's plan but rate set did include those categories which are open to select
       * when this plan is selected, all categories will be in Other list, but no locking on shift type select.
       *
       * however, for recurring job, the jobStartDateTime will always follow current date. so unless current date does not have the list,
       *  or else the patterns as in single jobs will not happen.
       */

      const jsdt = Moment.isMoment(jobStartDateTime) ? jobStartDateTime : Moment(jobStartDateTime)
      const pd = funderPeriodList.find(e => {
        const std = Moment(e.start_date)
        const etd = Moment(e.end_date)
        return std.isBefore(jsdt) && etd.isAfter(jsdt)
      })

      const jsdt2 = Moment(new Date())
      const pd2 = funderPeriodList.find(e => {
        const std = Moment(e.start_date)
        const etd = Moment(e.end_date)
        return std.isBefore(jsdt2) && etd.isAfter(jsdt2)
      })

      // check on pd.id (valid client funding) or pd.funder_id (category list out of client's funding periods) to enter the sequence
      if (pd && (pd.id || pd.funder_id) && validator.isNotEmptyArray(pd.category)) {
        billingCategoryList = pd.category
        isDisableCategoriesSelect = false
        if (!pd.id) this.showNoFundingPeriodNotification()
      } else if (pd2 && (pd2.id || pd2.funder_id) && validator.isNotEmptyArray(pd2.category)) {
        billingCategoryList = pd2.category
        isDisableCategoriesSelect = false
        this.showNoFundingPeriodNotification()
      } else {
        isDisableCategoriesSelect = true
        this.showNoFundingPeriodNotification()
      }

    } else {
      form.setFieldsValue({ billing_category_id: undefined })
    }

    this.setState({
      isExtSleepover: jobStartDateTime ? isExtSleepover : false,
      billingCategoryList,
      isDisableCategoriesSelect
    }, () => {
      this.fetchRateSetInfo(jobStartDateTime)
    })
  }

  checkClient = (clientId, start, end, isUpdate = false) => {
    const { form } = this.props

    const jobStartDateTime = start || Moment(form.getFieldValue('first_job_date'))
    const jobEndDateTime = end || Moment(form.getFieldValue('last_job_date'))

    const jobStartTime = start || Moment(form.getFieldValue('job_start_time'))
    const jobEndTime = end || Moment(form.getFieldValue('job_end_time'))

    if (jobStartDateTime.isValid() && jobEndDateTime.isValid()) {
      // 1. Check if client is onleave
      this.checkClientLeave(clientId, jobStartDateTime, jobEndDateTime)

      // 2. Check if it has client conflict shift
      // this.checkClientJobConflict(clientId, jobStartDateTime, jobEndDateTime)

      // 3. Check if it is public holiday
      this.checkHoliday(jobStartDateTime)
    }

    // 4. Check if it is sleepover
    this.checkSleepover(jobStartTime, jobEndTime, isUpdate)
  }

  checkClientLeave = async (clientId, start, end) => {
    const std = !start  // step 1: check whether is empty date
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(start) // step 2: check whether start is a moment object
        ? Moment(start).utc().format(sqlFormat)
        : start.isValid() // step 3: check whether start has a valid date
          ? start.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)
    const etd = !end
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(end)
        ? Moment(end).utc().format(sqlFormat)
        : end.isValid()
          ? end.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)

    const allLeaves = await clientLeaveService.getByStartEnd(clientId, std, etd)

    if (allLeaves && validator.isNotEmptyArray(allLeaves.item)) {
      this.setState({ isClientOnLeave: true, clientLeaveInfo: allLeaves.item[0] })
    } else {
      this.setState({ isClientOnLeave: false, clientLeaveInfo: null })
    }
  }

  checkConflictJobs = async () => {
    setTimeout(async () => {
      const { clientId = null } = this.state
      const { form } = this.props

      const isJobNeverEnd = form.getFieldValue('is_never_end')
      const fd = form.getFieldValue('first_job_date')
      const ld = form.getFieldValue('last_job_date')
      const ed = form.getFieldValue('last_job_date')
      const jst = form.getFieldValue('job_start_time')
      const jet = form.getFieldValue('job_end_time')
      const jobBaseId = this.getJobId()

      const firstDate = fd && fd.isValid() ? fd.format('YYYY-MM-DD') : fd
      const lastDate = ld && ld.isValid() ? ld.format('YYYY-MM-DD') : ld

      const jobStartTime = jst && jst.isValid() ? jst.format('HH:mm:ss') : jst
      const jobEndTime = jet && jet.isValid() ? jet.format('HH:mm:ss') : jet
      const frequency = form.getFieldValue('interval')
      const employeeId = form.getFieldValue('employee_id') || ''

      if (clientId && firstDate && jobStartTime && jobEndTime && frequency) {
        const body = {
          clientId: clientId,
          employeeId: employeeId,
          startDate: firstDate,
          endDate: lastDate,
          startTime: jobStartTime,
          endTime: jobEndTime,
          frequency,
          jobBaseId: jobBaseId === 'add' ? '' : jobBaseId
        }

        const r = await jobService.getRecurringConflictJobs(body)
        // console.log('conflict jobs', r)

        if (r && validator.isNotEmptyArray(r.job_list)) {
          const conflictJobList = r.job_list || []
          const conflictJobListClient = conflictJobList.filter(e => parseInt(e.client_id) === parseInt(clientId))
          const conflictJobListEmp = employeeId ? conflictJobList.filter(e => parseInt(e.employee_id) === parseInt(employeeId)) : []
          this.setState({
            conflictJobList,
            conflictJobListClient,
            conflictJobListEmp,
            conflictJobErrorMsg: null
          })
        } else {
          this.setState({
            onflictJobList: [],
            conflictJobListClient: [],
            conflictJobListEmp: [],
            conflictJobErrorMsg: 'Unable to validate conflict jobs.'
          })
        }
      }
    }, 500)
  }

  checkEmployee = async (empId, changeEmployee = false) => {
    const { form } = this.props
    const formStartDate = Moment(form.getFieldValue('job_start_date'))
    const formEndDate = Moment(form.getFieldValue('job_end_date'))
    let employeeJobHoursInfo = {}

    if (typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null) && (Moment(formEndDate).isValid() && formEndDate !== null)) {
      employeeJobHoursInfo = await this.checkEmployeeWeeklyMaxHour(empId, formStartDate, formEndDate)
    }

    const employeeInfoRes = await employeeService.get(empId)
    const employeeInfo = employeeInfoRes.item
    const empLanguages = employeeInfo.preferences.languages || []
    const empSkills = employeeInfo.preferences.skills || []
    const empLanguagesName = employeeInfo.preferences.languages_name || []
    const empSkillsName = employeeInfo.preferences.skills_name || []
    const isShowEmployeePrivateAlert = employeeInfo.private_alert !== null && !validator.isEmptyString(employeeInfo.private_alert)
    const isShowEmployeePublicAlert = employeeInfo.public_alert !== null && !validator.isEmptyString(employeeInfo.public_alert)

    setTimeout(() => {
      // Show popup alert if change new employee & employee have private alert or public alert
      if ((changeEmployee && isShowEmployeePrivateAlert) || (changeEmployee && isShowEmployeePublicAlert) || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)) {
        const pAlert = employeeInfo.private_alert || ''
        const uAlert = employeeInfo.public_alert || ''
        const privateAlert = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        const publicAlert = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        warning({
          title: `Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`,
          content: (
            <div>
              { publicAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null }
              { privateAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null }
              { !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpHasMaxHour
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
            </div>
          ),
          okText: 'OK',
          onOk () { },
        })
      }
    }, 400)

    this.setState({
      currentEmpInfo: employeeInfo,
      empLanguages,
      empSkills,
      empLanguagesName,
      empSkillsName,
      isShowEmployeePrivateAlert,
      isShowEmployeePublicAlert
    })

    const { clientLanguagesName, clientSkillsName } = this.state

    // check skills and languages mismatch between client and employee
    let mismatchedLanguageList = []
    let mismatchedSkillList = []

    if (validator.isNotEmptyArray(clientLanguagesName)) {
      mismatchedLanguageList = cloneDeep(clientLanguagesName).filter(e => empLanguagesName.findIndex(f => f === e) < 0)
    }

    if (validator.isNotEmptyArray(clientSkillsName)) {
      mismatchedSkillList = cloneDeep(clientSkillsName).filter(e => empSkillsName.findIndex(f => f === e) < 0)
    }

    this.setState({
      isMatchLanguage: !validator.isNotEmptyArray(mismatchedLanguageList),
      isMatchSkill: !validator.isNotEmptyArray(mismatchedSkillList),
      mismatchedLanguageList,
      mismatchedSkillList
    })

    // 1. Check schedule conflict
    // this.checkEmployeeJobConflict(empId, formStartDate, formEndDate)

    // 2. Check if employee is on leave on the selected date
    this.checkEmployeeLeave(empId, formStartDate, formEndDate)

    // 3. Check employee files expiry
    if (validator.isDigit(empId)) {
      this.checkEmployeeFilesExpiry(empId, form.getFieldValue('first_job_date'), form.getFieldValue('last_job_date'))
    }
  }

  checkEmployeeFilesExpiry = async (empId, jobStartDate, jobEndDate) => {
    const filter = {}
    const employeeExpiredFiles = []

    filter.has_expiry = { condition: '=', value: 'true' }
    filter.active = { condition: '=', value: 'true' }
    filter.module = { condition: '=', value: 'employee' }
    filter.module_id = { condition: '=', value: empId }

    const list = await employeeFileService.listByPage(1, 0, filter)

    if (list && validator.isNotEmptyArray(list.list)) {
      const files = list.list
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.expiry_date) {
          if ((jobStartDate && jobStartDate.isAfter(file.expiry_date)) || (jobEndDate && jobEndDate.isAfter(file.expiry_date))) {
            employeeExpiredFiles.push(file)
          }
        }
      }
    }

    this.setState({ employeeExpiredFiles })
  }

  checkEmployeeLeave = async (empId, start, end) => {
    const std = !start  // step 1: check whether is empty date
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(start) // step 2: check whether start is a moment object
        ? Moment(start).utc().format(sqlFormat)
        : start.isValid() // step 3: check whether start has a valid date
          ? start.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)
    const etd = !end
      ? Moment(new Date()).format(sqlFormat)
      : !Moment.isMoment(end)
        ? Moment(end).utc().format(sqlFormat)
        : end.isValid()
          ? end.clone().utc().format(sqlFormat)
          : Moment(new Date()).format(sqlFormat)

    const allLeaves = await employeeLeaveService.getByStartEnd(empId, start, end)

    if (allLeaves && validator.isNotEmptyArray(allLeaves.item)) {
      this.setState({ isEmployeeOnLeave: true, employeeLeaveInfo: allLeaves.item[0] })
      // this.showEmployeeLeaveConfirm()
    } else {
      this.setState({ isEmployeeOnLeave: false, employeeLeaveInfo: {} })
    }
  }

  checkEmployeeWeeklyMaxHour = async (employeeId, start, end) => {
    let employeeJobHoursInfo = {}
    if (end > start) {
      let body = {}
      const startingWeek = Moment(start).startOf('week')
      const endingWeek = Moment(start).endOf('week')
      const s = Moment(startingWeek).startOf('day')
      const e = Moment(endingWeek).endOf('day')
      const durationFromNewJob = Moment.duration(Math.abs(start.diff(end)))
      const differentHours = durationFromNewJob.asHours()
      const jobId = this.getJobId()

      body.empId = employeeId
      body.start = s
      body.end = e
      body.newJobHours = differentHours
      body.baseJobId = jobId === 'add' ? null : jobId
      const r = await jobService.getEmployeeTotalMaxHour(body)

      if (r && r.isEmpHasMaxHour !== undefined) {
        employeeJobHoursInfo = r
        this.setState({ employeeJobHoursInfo: r })
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    } else {
      this.setState({ employeeJobHoursInfo: {} })
    }

    return employeeJobHoursInfo
  }

  checkEmail = () => {
    const that = this
    const { prevUnsentCommTotal, unsentCommTotal } = this.state

    if ((prevUnsentCommTotal === null && unsentCommTotal > 0) ||
      (prevUnsentCommTotal !== null && unsentCommTotal > 0 && prevUnsentCommTotal < unsentCommTotal)) {
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.setState({ tabActiveKey: '2' })
        },
        onCancel () {

        }
      })

      // update on prevUnsentCommTotal after confirm pop up appears
      this.setState({ prevUnsentCommTotal: unsentCommTotal })
    }
  }

  checkHoliday = async (date) => {
    const newDate = Moment.isMoment(date) ? date.format('YYYY-MM-DD') : Moment(new Date()).format('YYYY-MM-DD')
    const holiday = await settingHolidayService.getTodayHoliday(newDate)

    if (holiday && validator.isNotEmptyArray(holiday.list)) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list[0] })
      return true
    } else {
      this.setState({ isHoliday: false, holidayInfo: null })
      return false
    }

    return false
  }

  checkJobLessThan2Hrs = (startTime, endTime) => {
    const differentHours = Moment.duration(Math.abs(Moment(startTime).diff(endTime))).asHours()

    if (differentHours < 2) {
      warning({
        title: 'This job\'s duration is less than 2 hours.'
      })
    }
  }

  checkJobUpdateStatus = (job) => {
    const { is_updated: isUpdated } = job
    const { type } = this.state

    if (type !== LIST_FILTER_PREVIOUS) {
      if (isUpdated) {
        return 1
      }
    }

    return 0
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSingleJobsDelete = () => {
    const { item, allSingleJobList } = this.state
    const { form } = this.props
    let show = null

    if (this.isEdit()) {
      if (item.is_never_end && !form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date')) {
        const delJobs = allSingleJobList.filter(item => Moment(item.job_end_date).isAfter(form.getFieldValue('last_job_date')))
        return delJobs
      } else if (!form.getFieldValue('is_never_end') && form.getFieldValue('last_job_date') &&
        (form.getFieldValue('last_job_date').isBefore(Moment(item.last_job_date)))) {
        const delJobs = allSingleJobList.filter(item => Moment(item.job_end_date).isAfter(form.getFieldValue('last_job_date')))
        return delJobs
      }
    }

    return show
  }

  checkSleepover = (start, end, isUpdate) => {
    const { isExtSleepover, rateSetInfo } = this.state
    const format = 'YYYY-MM-DD HH:mm:ss'

    // only process if rateSetInfo is available, because need to get AH Hours
    if (rateSetInfo && rateSetInfo.id && isUpdate) {
      const std = Moment.isMoment(start) ? start : Moment(start)
      const etd = Moment.isMoment(end) ? end : Moment(end)

      const isSameDay = std.isSame(etd, 'day')
      const durations = Moment.duration(Math.abs(end.diff(start))).asMinutes()

      // get after hours moment obj
      const saah = rateSetInfo.after_hours_start ? Moment(rateSetInfo.after_hours_start) : null
      const eaah = rateSetInfo.after_hours_end ? Moment(rateSetInfo.after_hours_end) : null

      // set valid AH hours based on the setting. for AH end time, if the start&end dates are same day, the AH end time shall be on the next day.
      const sah = saah ? std.clone().set('hours', saah.get('hours')).set('minutes', saah.get('minutes')) : null
      const eah = eaah ? (isSameDay ? etd.clone().add(1, 'day').set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes')) : etd.clone().set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes'))) : null

      // get the 12:00AM moment object of the day, if same day, then it should be end of the day (next day 12.00AM), else it would be end date's start of the day.
      const eth = isSameDay ? etd.clone().endOf('day').add(1, 'second') : etd.clone().startOf('day')

      let ovlDuration = 0

      if (sah && eah) {
        // case for same day:
        // if job end time is before start of AH: no possibilities for auto sleepover detection since it is out of AH duration
        // if job end time is after start of AH: check on job start time is whether before or after start of AH.
        // if before, get the duration between start of AH and job end time
        // if after, get the duration between job start time and job end time (i.e. job duration is within the period of AH but before the day ends)
        if (isSameDay) {
          if (!etd.isBefore(sah)) {
            if (std.isBefore(sah)) {
              ovlDuration = Moment.duration(Math.abs(etd.diff(sah))).asMinutes()
            } else {
              ovlDuration = Moment.duration(Math.abs(etd.diff(std))).asMinutes()
            }
          }
        } else {
          // case for diff day:
          // for day 1, check job start time is before/after start of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          // for day 2, check job end time is before/after end of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          let d1c = 0
          let d2c = 0
          if (std.isBefore(sah)) {
            d1c = Moment.duration(Math.abs(sah.diff(eth))).asMinutes()
          } else {
            d1c = Moment.duration(Math.abs(std.diff(eth))).asMinutes()
          }

          if (etd.isAfter(eah)) {
            d2c = Moment.duration(Math.abs(eth.diff(eah))).asMinutes()
          } else {
            d2c = Moment.duration(Math.abs(eth.diff(etd))).asMinutes()
          }

          ovlDuration = d1c + d2c
        }
      }

      if (durations > 240 && ovlDuration > 240) {
        if (std.isBefore(etd)) {
          this.handleSleepover(isUpdate ? true : isExtSleepover, true)
        }
      } else {
        this.handleSleepover(false, false, isUpdate)
      }
    }
  }

  /** ========== SECTION 3: SAVE Related Controls and functions ========== */
  onSaveJob = async (isRedirected = false) => {
    const { form, history } = this.props
    const { clientInfo, changeEmployeeReason, changeEmployeeReasonType, changeEmployeeOtherReason, changeEmployeeNote, fileList, isCustomSO, employeeId, cancelTypeList, cancelReasonList } = this.state
    const { validateFieldsAndScroll, setFieldsValue } = form

    // console.log('validate save')
    validateFieldsAndScroll(async (errors, values) => {
      // console.log('validate fields', errors, values)
      if (!errors) {
        const {
          clientId,
          currentEmpInfo,
          currentFunderInfo,
          funderInfo,
          item,
          itemOri,
          isCustomSO,
          isEmployeePending,
          isExtSleepover,
          isJobNeverEnd,
          jobDayNumber,
          jobNewEndTime,
          loading,
          prevEmpInfo,
          prevFunderInfo,
          rateSetInfo
        } = this.state
        const jobBaseId = this.getJobId()

        if (itemOri.employee_id && itemOri.employee_id !== values.employee_id) {
          if (!changeEmployeeReason){
            this.setState({ showEmployeeReasonModal: true })
            return
          }
        }

        if (loading && !isRedirected) return

        this.setState({ loading: true })

        if (jobNewEndTime) {
          values.job_end_time = jobNewEndTime
        }

        const isStartOnSameDay = Moment(values.first_job_date).isSame(values.job_start_time, 'day')

        if (!isStartOnSameDay) {
          const date = values.first_job_date.clone()
          const time = values.job_start_time
          values.job_start_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 })
        }

        const isEndOnSameDay = Moment(values.first_job_date).isSame(values.job_end_time, 'day')

        if (!isEndOnSameDay) {
          const date = values.first_job_date.clone()
          const time = values.job_end_time

          if (values.job_end_time.format('a') === 'am' && values.job_start_time.format('a') === 'pm') {
            values.job_end_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 }).add('day', 1)
          } else {
            values.job_end_time = date.set({ hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 })
          }
        }

        const startTime = values.job_start_time.seconds(0).milliseconds(0)
        const endTime = values.job_end_time.seconds(0).milliseconds(0)
        const durations = Moment.duration(Math.abs(endTime.diff(startTime)))
        const diffHours = durations.asHours()

        values.client_id = clientId
        values.funder_id = funderInfo.id || item.funder_id
        values.client_funder_id = rateSetInfo.id // updated with fvp rate set id but still no actual use for fvp
        values.job_hours = Math.round(diffHours * 100) / 100
        values.billing_rate_id = null // fvp update to configure all jobs saved under v2 empty the billing_rate_id to separate with legacy case
        values.job_start_day = Moment(values.first_job_date).format('dddd')
        values.is_never_end = isJobNeverEnd

        // Replace and remove if tasks has special character, tab or extra blank spaces
        if (values.tasks && values.tasks.length > 0) {
          values.tasks = this.replacer(values.tasks).trim()
          setFieldsValue({ tasks: values.tasks })
        }

        // Replace and remove if notes has special character, tab or extra blank spaces
        if (values.notes && values.notes.length > 0) {
          values.notes = this.replacer(values.notes).trim()
          setFieldsValue({ notes: values.notes })
        }

        // Check if job is not emergency then clear invoice and pay
        if (!values.emergency) {
          values.emergency_invoice = false
          values.emergency_pay = false
        }

        // Check if job is monthly job that got indicator
        if (values.month_setting) {
          values.day_number = jobDayNumber
        } else {
          delete values.month_setting
        }

        // Remove job_kms if job_kms empty string
        if (values.job_kms === '') {
          values.job_kms = null
        }

        // fvp sleepover
        values.is_sleepover_job = isExtSleepover
        values.is_custom_so_time = isCustomSO
        values.sleepover_type = isExtSleepover ? 'sleepover' : null

        if (!isCustomSO) {
          values.custom_so_start_time = null
          values.custom_so_end_time = null
        }

        // check if job is never ending
        const intervalVal = values.interval

        const jobEndDate = Moment(values.last_job_date).endOf('day')
        const legacyStart = values.cache_start_time
        const legacyEnd = values.cache_end_time

        // generate frequency
        if (legacyStart && legacyEnd) {
          const startDate = Moment(legacyStart)

          const endDate = Moment(startDate).add(3, 'months')
          const weekNum = endDate.diff(legacyStart, 'week')
          const dayNum = endDate.diff(legacyStart, 'days')
          if (isJobNeverEnd) {
            values.is_never_end = true
            if (intervalVal === 'monthly') {
              values.frequency = 3
              const lastDate = Moment(legacyStart).add(2, 'months')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'week') {
              values.frequency = weekNum
              const lastDate = Moment(legacyStart).add((weekNum - 1), 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'fortnight') {
              values.frequency = 6
              const lastDate = Moment(legacyStart).add(10, 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else {
              values.frequency = dayNum
              const lastDate = Moment(legacyStart).add((dayNum - 1), 'days')
              values.recurring_end = Moment(lastDate).startOf('day')
            }

            values.last_job_date = null
          } else {
            const limitJobEndDate = Moment(legacyStart).add(3, 'months')

            if (Moment(legacyEnd).isAfter(Moment(limitJobEndDate))) {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'weeks')
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                // console.log('lastdate', Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks'))
                values.frequency = totalFreq
                values.recurring_end = Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(limitJobEndDate).diff(legacyStart, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(limitJobEndDate).startOf('day')
              }
            } else {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'weeks')
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(legacyStart).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(legacyStart).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(jobEndDate).diff(legacyStart, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(jobEndDate).startOf('day')
              }
            }
          }
        } else {
          const startDate = Moment(form.getFieldValue('first_job_date')).startOf('day')
          const endDate = Moment(startDate).add(3, 'months')
          const weekNum = endDate.diff(startDate, 'week')
          const dayNum = endDate.diff(startDate, 'days')
          if (isJobNeverEnd) {
            values.is_never_end = true
            if (intervalVal === 'monthly') {
              values.frequency = 3
              const lastDate = Moment(startDate).add(2, 'months')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else if (intervalVal === 'week') {
              values.frequency = weekNum + 1
              const lastDate = Moment(startDate).add((weekNum), 'weeks')
              values.recurring_end = Moment(lastDate).add(1, 'day').startOf('day')
            } else if (intervalVal === 'fortnight') {
              values.frequency = 6
              const lastDate = Moment(startDate).add(10, 'weeks')
              values.recurring_end = Moment(lastDate).startOf('day')
            } else {
              values.frequency = dayNum
              const lastDate = Moment(startDate).add((dayNum - 1), 'days')
              values.recurring_end = Moment(lastDate).startOf('day')
            }

            values.last_job_date = null
          } else {
            let limitJobEndDate = Moment(startDate).add(3, 'months')

            if (limitJobEndDate < Moment()) {
              limitJobEndDate = Moment().add(3, 'months')
            }

            if (Moment(jobEndDate).isAfter(Moment(limitJobEndDate))) {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'weeks')
                // console.log(`I will calculate from ${limitJobEndDate} to ${startDate}`)
                // console.log('Number Of Week:', startEnd)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(startDate).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(limitJobEndDate).diff(startDate, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(limitJobEndDate).startOf('day')
              }
            } else {
              if (intervalVal === 'monthly') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'months')
                // console.log('Number Of Months:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(startDate).add(startEnd, 'months')
              } else if (intervalVal === 'week') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'weeks')
                // console.log('Number Of Week:', startEnd)
                // console.log(`I will calculate from ${jobEndDate} to ${startDate}`)
                values.frequency = startEnd + 1
                values.recurring_end = Moment(startDate).add(startEnd, 'weeks')
              } else if (intervalVal === 'fortnight') {
                const startEnd = Moment(jobEndDate).diff(startDate, 'weeks')
                const fortnightWeek = (startEnd / 2)
                const totalFreq = fortnightWeek % 1 !== 0 ? Math.ceil(fortnightWeek) : fortnightWeek + 1
                // console.log('Fortnight Freq: ', totalFreq)
                values.frequency = totalFreq
                values.recurring_end = Moment(startDate).add((totalFreq - 1) * 2, 'weeks')
              } else {
                const startEnd = Moment(jobEndDate).diff(startDate, 'days')
                // console.log('Number Of Day:', startEnd)
                values.frequency = startEnd
                values.recurring_end = Moment(jobEndDate).startOf('day')
              }
            }
          }
        }
        // Check if recurring date is changed to greater Date.
        const initialRecurringEnd = Moment(item.recurring_end)
        const newRecurringEnd = Moment(form.getFieldValue('last_job_date')).isValid() ? form.getFieldValue('last_job_date').endOf('day') : ''

        if (Moment(newRecurringEnd).isAfter(initialRecurringEnd)) {
          values.is_extended = true
        } else {
          values.is_extended = false
        }

        try {
          const { isAllCurrentJobChecked, singleJobListType } = this.state
          const checkFromDateField = form.getFieldValue('from_date')

          if (this.isEdit()) {
            let response

            if (checkFromDateField !== null) {
              const fromDatePrep = values.from_date
              const fromDate = fromDatePrep.toISOString()
              delete values.from_date

              if (isAllCurrentJobChecked) {
                response = await baseJobService.saveFromUpdated(jobBaseId, values, fromDate)
              } else {
                response = await baseJobService.saveFrom(jobBaseId, values, fromDate)
              }
            } else {
              const fromDatePrep = Moment()
              const fromDate = fromDatePrep.toISOString()
              delete values.from_date

              if (isAllCurrentJobChecked) {
                response = await baseJobService.saveFromUpdated(jobBaseId, values, fromDate)
              } else {
                response = await baseJobService.saveFrom(jobBaseId, values, fromDate)
              }
            }

            if (response.id) {
              const { id } = response
              notify.success('Saved successfully', 'Job saved successfully.')

              if (changeEmployeeReason !== null && changeEmployeeReasonType !== null) {
                const newEmployeeId = form.getFieldValue('employee_id')
                const oldEmployeeName = await this.getEmployeeName(employeeId)
                const newEmployeeName = await this.getEmployeeName(newEmployeeId)
                const reasonType = cancelTypeList.find(e => e.id === changeEmployeeReasonType)
                const reasonId = cancelReasonList.find(e => e.name === changeEmployeeReason)
                // Generate & save log_multi_action
                const actions = {}

                actions.module = 'basejob'
                actions.module_id = id
                actions.action = 'job-employee-change'
                actions.action_detail = `job change employee from "${oldEmployeeName}" to "${newEmployeeName}"`
                actions.previous_id = employeeId
                actions.current_id = newEmployeeId
                actions.action_lvl1_id = changeEmployeeReasonType
                actions.action_lvl1_name = reasonType.name
                actions.action_lvl1_other = changeEmployeeOtherReason
                actions.action_lvl1_text = changeEmployeeNote
                actions.action_lvl2_id = reasonId.id
                actions.action_lvl2_name = changeEmployeeReason

                const actionLog = await jobActionLogService.add(actions)

                const actions2 = {}
                actions2.module = 'basejob'
                actions2.module_id = id
                actions2.action = 'job-cancel-by-employee-change'
                actions2.previous_id = employeeId
                actions2.current_id = newEmployeeId
                const actionLog2 = await jobActionLogService.add(actions2)
              }

              let extraLogs = []

              if (validator.isNotEmptyArray(response.logJobsInfo)) {
                let logParams = []

                logParams.push(`Recurring End Date has been updated to ${formatter.toShortDate(values.last_job_date)} ${formatter.toShortTime(values.job_end_time)}. The following jobs are added/removed:<br />`)

                for (let x = 0; x < response.logJobsInfo.length; x++) {
                  const job = response.logJobsInfo[x]
                  logParams.push(`- ${job.isRemoveJob ? 'Removed' : 'Added'} ${formatter.toShortDate(job.job_start_date)} ${formatter.toShortTime(job.job_start_date)} - ${formatter.toShortTime(job.job_end_date)} (ID: ${job.job_id})<br />`)
                }

                log.updateBaseJobExtra(response.id, 'update', logParams.join(''))
              }

              if (prevEmpInfo.id && prevEmpInfo.id !== values.employee_id) {
                const newEmployeeName = await this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from "${prevEmpInfo.id ? `ID ${prevEmpInfo.id} - ` : ''}${prevEmpInfo.name}" to "${values.employee_id ? `ID ${values.employee_id} - ` : ''}${newEmployeeName}"`)

                this.cacheEmployee(values.employee_id)

                log.updateBaseJobExtra(response.id, 'employee change reason', `Employee changed to "${newEmployeeName}" with reason: ${changeEmployeeReasonType} - ${changeEmployeeReason} ${changeEmployeeOtherReason ? ` - ${changeEmployeeOtherReason}` : ''} ${changeEmployeeNote ? ` (${changeEmployeeNote})` : ''}`)
              } else if (!prevEmpInfo.id && values.employee_id) {
                const newEmployeeName = await this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from Pending to "${values.employee_id ? `ID ${values.employee_id} - ` : ''}${newEmployeeName}"`)

                this.cacheEmployee(values.employee_id)
              }

              if (values.funder_id !== itemOri.funder_id) {
                extraLogs.push(`Funder changed from "${itemOri.funder_id ? `ID ${itemOri.funder_id} - ` : ''}${prevFunderInfo.fullname}" to "${values.funder_id ? `ID ${values.funder_id} - ` : ''}${funderInfo.fullname}"`)

                this.cacheFunder(values.funder_id)
              }

              if (values.billing_category_id !== itemOri.billing_category_id) {
                const oldName = await this.getBillingCategoryName(itemOri.billing_category_id)
                const newName = await this.getBillingCategoryName(values.billing_category_id)

                if (oldName || newName) {
                  extraLogs.push(`Shift Type changed from "${oldName}" to "${newName}"`)
                }
              }

              if (values.payroll !== itemOri.payroll) {
                const oldPayroll = this.getPayrollName(itemOri.payroll)
                const newPayroll = this.getPayrollName(values.payroll)

                if (oldPayroll || newPayroll) {
                  extraLogs.push(`Carer Skill Level changed from "${oldPayroll}" to "${newPayroll}"`)
                }
              }

              await log.updateBaseJob(
                id,
                item,
                values,
                ['recurring_end', 'is_extended', 'employee_id', 'frequency', 'funder_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'sleepover', 'sleepover_category_id', 'client_funder_id', 'is_sleepover_job', 'custom_so_start_time', 'custom_so_end_time', 'is_custom_so_time', 'sleepover_type', 'is_extending_job'],
                extraLogs.join(),
                [
                  { key: 'job_kms', label: 'Max KM' },
                  { key: 'kms', label: 'Recorded KM' }
                ]
              )

              this.refreshPage()

              this.setState({
                changeEmployeeReason: null,
                changeEmployeeReasonType: null,
                changeEmployeeOtherReason: null,
                changeEmployeeNote: null
              })
            }
          } else {
            const response = await baseJobService.add(values)

            if (response.id) {
              const { id } = response

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList)) {
                await this.onSaveUploadFiles(response.id)
              }
              // ------ append file end -------

              let extraLogs = []

              extraLogs.push(`Client to "${clientInfo.first_name} ${clientInfo.last_name}"`)

              if (values.employee_id) {
                const newEmployeeName = await this.getEmployeeName(values.employee_id)
                extraLogs.push(` Employee to "${newEmployeeName}"`)
              }

              if (values.funder_id !== undefined) {
                extraLogs.push(` Funder to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== undefined) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(` Shift Type to "${name}"`)
              }

              if (values.payroll !== undefined) {
                const newPayroll = await this.getPayrollName(values.payroll)
                extraLogs.push(` Carer Skill Level to "${newPayroll}"`)
              }

              if (values.interval !== undefined) {
                extraLogs.push(` Frequency to "${values.interval}"`)
              }

              log.addBaseJob(
                id,
                values,
                ['is_extended', 'is_updated', 'interval', 'job_hours', 'employee_id', 'frequency', 'funder_id', 'client_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'sleepover', 'job_type', 'original_end_date', 'client_funder_id'],
                extraLogs.join(),
                [
                  { key: 'job_kms', label: 'Max KM' },
                  { key: 'kms', label: 'Recorded KM' }
                ]
              )

              notify.success('Saved successfully', 'Job saved successfully.')
              history.replace(`${jobLegacyURL}/recurring/${id}`)

              this.cacheEmployee(values.employee_id)

              this.refreshPage()
            }
          }
        } catch (e) {
          console.log(e)
          notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.props.setRefreshCommLog(true)
      } else {
        this.showErrorNotification('error')
      }
    })
  }

  onSaveUploadFiles = async (jobId) => {
    const { fileList, mainFileCategoryList, subFileCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = mainFileCategoryList.find(e => e.id === file.main_category_id)
      const subcat = subFileCategoryList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jobService.addFile(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addBaseJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  /** ========== SECTION 4: modal functions ========== */
  getChargeHours = () => {
    const { item, settingOthers = {} } = this.state

    if (item.cancellation_penalty > 0) {
      return item.job_hours > settingOthers.cancellation_charge_hour ? settingOthers.cancellation_charge_hour : item.job_hours
    }
    return 0
  }

  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  triggerFunderModal = (showFunderModal) => {
    this.setState({ showFunderModal })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  /** ========== SECTION 5: UI Control functions ========== */
  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Funding Period Not Defined!',
      content: <div><p>Please assign a funding period for this funder before proceeding.</p> <p>Click <b>Go to Client Page</b> to be redirected to the client page or <b>Continue</b> to choose another Funder.</p></div>,
      okText: 'Go To Client Page',
      cancelText: 'Continue',
      onOk () {
        history.replace(`/clients/${clientId}`)
      },
      onCancel () {
      }
    })
  }

  filterAllJobList (e) {
    this.setState({ currentPage: 1 })
    this.fetchSingleJobList(e.target.value, 1)
  }

  handleApplyAllAffectedJobs = (value) => {
    const { form } = this.props
    const applyAll = value.target.checked

    if (applyAll) {
      this.setState({ allJobChecked: true })
    } else {
      this.setState({ allJobChecked: false })
    }

    this.handleJobAffectedChecking(applyAll)
  }

  handleBadgeCount = (value) => {
    this.setState({ unsentCommTotal: value })
    this.props.setRefreshActivityLog(true)
  }

  handleBillingSelectCategory (option) {
    /**TODO: fvp revamp */
    // this.setState({ showGetupHours: option.props.children.indexOf('Sleepover') > -1 })
  }

  handleChangePayroll = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleChangeTab (tabActiveKey) {
    this.setState({ tabActiveKey })
  }

  handleCheckJobAffectedStatus = (job) => {
    const { is_updated: isUpdated } = job
    const { singleJobListType } = this.state

    if (singleJobListType !== 'previous') {
      if (isUpdated) {
        return 1
      }
    }

    return 0
  }

  handleEditButton = () => {
    this.setState({ isShowSave: true })
  }

  handleEffectiveDateChange = (e) => {
    this.setState({ isAllCurrentJobChecked: false })
    const date = Moment.isMoment(e) ? e.clone().format('YYYY-MM-DD') : e
    this.handleJobAffectedChecking(true, e)
  }

  handleEmployeeCancelReasonChange = async (value) => {
    if (value === 'Other') {
      this.setState({ isShowCancelOtherField: true })
    } else {
      this.setState({ isShowCancelOtherField: false })
    }
  }

  handleEmployeeCancelTypeChange = async (id, value) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReasonList: cancelReason.list || [], changeEmployeeReasonType: value.props.children })
  }

  handleEmployeeChange = async (value) => {
    this.checkEmployee(value, true)
    this.checkConflictJobs()
  }

  handleEmployeeChangeReason = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll([ 'change_employee_reason', 'change_employee_other_reason', 'change_employee_note', 'change_employee_reason_type' ],
      async (errors, values) => {
        if (!errors) {
          this.setState({
            showEmployeeReasonModal: false,
            changeEmployeeReason: values.change_employee_reason,
            changeEmployeeReasonType: values.change_employee_reason_type,
            changeEmployeeOtherReason: values.change_employee_other_reason,
            changeEmployeeNote: values.change_employee_note
          })
          this.setState({ loading: true })

          that.resetChangeEmployeeForm()
          //Proceed to save
          setTimeout(() => {
            this.onSaveJob(true)
          }, 500)
        }
      })
  }

  handleStartTimeChange = async (date) => {
    const { clientId } = this.state
    const { form } = this.props

    const startTime = Moment(date)
    const firstDate = form.getFieldValue('first_job_date')
    const endTime = form.getFieldValue('job_end_time')
    const newEndTime = Moment(startTime).add(2, 'hours')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    // Update job end time
    if (!endTime) {
      form.setFieldsValue({ job_end_time: newEndTime })
    }

    form.setFieldsValue({ job_start_time: firstDate.set({ hour: startTime.get('hour'), minute: startTime.get('minute'), second: 0, millisecond: 0 }) })

    this.showDuration(startTime, endTime || newEndTime)

    this.checkClient(clientId, startTime, endTime || newEndTime, true)

    if (!this.isEdit() && typeof selectedEmployeeId === 'number' && firstDate !== null && Moment(firstDate).isValid()) {
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstDate, startTime, endTime || newEndTime)
    }

    this.checkJobLessThan2Hrs(startTime, endTime || newEndTime)

    this.checkConflictJobs()
  }

  handleEndTimeChange = async (time) => {
    const { clientId } = this.state
    const { form } = this.props

    const firstDate = form.getFieldValue('first_job_date')
    const jobStartTime = Moment(form.getFieldValue('job_start_time'))
    const jobEndTime = Moment(time)
    const selectedEmployeeId = form.getFieldValue('employee_id')
    let duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()

    this.checkJobLessThan2Hrs(jobStartTime, jobEndTime)

    // Time correction
    if (duration <= 0) {
      jobEndTime.add('day', 1)
      duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()
      form.setFieldsValue({ job_end_time: jobEndTime })
      this.setState({ jobNewEndTime: jobEndTime })
    } else if (jobStartTime.format('YYYY-MM-DD') !== jobEndTime.format('YYYY-MM-DD')) {
      if (duration > 24) {
        jobEndTime.add('day', -1)
        duration = Moment.duration(Moment(jobEndTime).diff(jobStartTime)).asHours()
        form.setFieldsValue({ job_end_time: jobEndTime })
        this.setState({ jobNewEndTime: jobEndTime })
      }
    }

    this.showDuration(jobStartTime, jobEndTime)
    this.checkClient(clientId, jobStartTime, jobEndTime, true)

    if (!this.isEdit() && typeof selectedEmployeeId === 'number' && firstDate !== null && Moment(firstDate).isValid()) {
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstDate, jobStartTime, jobEndTime)
    }

    this.checkConflictJobs()
  }

  handleFunderSubmit = async () => {
    const { clientId, funderId: currentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['funder_id'], async (errors, values) => {
      if (currentFunderId !== parseInt(values.funder_id)) {
        this.setState({ funderInfo: {}, funderId: null, showFunderModal: false })
        form.setFieldsValue({ billing_category_id: undefined })
        this.fetchFunders(clientId, values.funder_id)
      }
    })
  }

  handleFunderCancel = async () => {
    this.setState({ showFunderModal: false })
  }

  handleIntervalClick = (value) => {
    const { item } = this.state
    const { form } = this.props
    const isNever = item.is_never_end

    if (isNever) {
      form.setFieldsValue({
        is_never_end: false
      })
    }

    // if (value === 'monthly') {
    //   this.setState({ willShowMonthOption: true })
    // } else {
    //   this.setState({ willShowMonthOption: false })
    // }

    this.checkConflictJobs()
  }

  handleJobAffectedChecking = async (applyAll, dateFrom, cId = null) => {
    if (this.isEdit()) {
      if (applyAll) {
        this.setState({ loadingAffectedJobList: true })
        const clientId = cId || this.state.clientId
        const jobBaseId = this.getJobId()
        const body = {
          clientId,
          jobBaseId,
          dateFrom
        }

        const r = await jobService.getAffectedUpdateJobs(body)

        this.setState({
          affectedJobList: r && validator.isNotEmptyArray(r.job_list) ? r.job_list : [],
          affectedJobErrorMsg: r && validator.isNotEmptyArray(r.job_list) ? null : 'Unable to look for affected jobs.',
          loadingAffectedJobList: false
        })
      } else {
        this.setState({
          affectedJobList: [],
          affectedJobErrorMsg: null,
          loadingAffectedJobList: false
        })
      }
    }
  }

  handleJobListPageChange = async (currentPage) => {
    const { singleJobListType } = this.state
    this.fetchSingleJobList(singleJobListType, currentPage)
  }

  handleJobNeverEndClick = (value) => {
    const { form } = this.props
    // const intervalVal = form.getFieldValue('interval')

    // if (value) {
    //   if (intervalVal === 'monthly') {
    //     this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: false, willShowOnlyMonth: true, isJobNeverEnd: true })
    //   } else {
    //     this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: false, willShowOnlyMonth: false, isJobNeverEnd: true })
    //   }
    // } else {
    //   if (intervalVal === 'monthly') {
    //     this.setState({ willShowFreqAndMonth: true, willShowOnlyFreq: false, willShowOnlyMonth: false, isJobNeverEnd: false })
    //   } else {
    //     this.setState({ willShowFreqAndMonth: false, willShowOnlyFreq: true, willShowOnlyMonth: false, isJobNeverEnd: false })
    //   }
    // }

    this.setState({ isJobNeverEnd: value })

    form.setFieldsValue({ last_job_date: null })
    this.checkConflictJobs()
  }


  handleStartDateChange = async (date) => {
    const { form } = this.props
    const { clientId } = this.state
    const firstJobDate = Moment(date)
    const selectedEmployeeId = form.getFieldValue('employee_id')
    const hourValueStart = Moment().startOf('hour').hour()
    const jobOptionDate = firstJobDate.format('DD')
    this.setState({ jobOptionDate, isJobHasStartDate: true })

    const jobStartTime = firstJobDate.clone().hour(hourValueStart).minute(0)
    const jobEndTime = jobStartTime.clone().add(2, 'hour')
    form.setFieldsValue({ job_start_time: jobStartTime })
    form.setFieldsValue({ job_end_time: jobEndTime })

    this.showDuration(jobStartTime, jobEndTime)
    this.checkClient(clientId, jobStartTime, jobEndTime)

    /** TODO: fvp update */
    // this.checkFunderAvailability(jobStartTime)
    this.checkCategoriesAndUpdate(Moment(new Date()))

    // get day

    const chosenDayNumber = firstJobDate.day()
    const chosenDate = firstJobDate.format('DD-MM-YYYY')

    const firstDayOfMonth = Moment(firstJobDate).startOf('month')
    const firstDayOfMonthNum = Moment(firstJobDate).startOf('month').day()

    if (chosenDayNumber < firstDayOfMonthNum) {
      const checkWeek = firstJobDate.format('w')
      const startDateWeek = Moment(firstDayOfMonth).format('w')
      const currentWeek = parseInt(checkWeek - startDateWeek + 1, 10)
      const jobDayNumber = parseInt(currentWeek - 1, 10)
      // console.log('week', checkWeek, startDateWeek, currentWeek, firstJobDate)
      this.setState({ jobDayNumber })
    } else {
      const numberLeftInWeek = 6 - firstDayOfMonthNum
      const endDateForFirstWeek = firstDayOfMonth.add(numberLeftInWeek, 'days').format('DD-MM-YYYY')
      if (chosenDate > endDateForFirstWeek) {
        const checkWeek = firstJobDate.format('w')
        const startDateWeek = Moment(firstDayOfMonth).format('w')
        const currentWeek = checkWeek - startDateWeek + 1
        this.setState({ jobDayNumber: currentWeek })
      }
    }

    if (typeof selectedEmployeeId === 'number' && firstJobDate !== null && Moment(firstJobDate).isValid()) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, firstJobDate)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstJobDate, jobStartTime, jobEndTime)
    }

    this.checkConflictJobs()
  }

  handleEndDateChange = async (date) => {
    const { form } = this.props
    const firstJobDate = form.getFieldValue('first_job_date')
    const jobStartTime = form.getFieldValue('job_start_time')
    const jobEndTime = form.getFieldValue('job_end_time')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    if (typeof selectedEmployeeId === 'number' && firstJobDate !== null && Moment(firstJobDate).isValid()) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, firstJobDate, date)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, firstJobDate, jobStartTime, jobEndTime)
    }
  }

  handlePendingEmployee = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({
        isEmployeePending: true,
        isEmployeeOnLeave: false,
        isEmployeeJobConflict: false,
        isMatchLanguage: true,
        isMatchSkill: true,
        employeeExpiredFiles: [],
        employeeJobHoursInfo: {}
      })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ isEmployeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleSleepover = (value, isShowSleepoverAlert = false, isUpdate = false) => {
    const { form } = this.props
    const { billingCategoryList } = this.state
    const that = this

    if (isShowSleepoverAlert) {
      confirm({
        title: 'Is This A Sleepover Job?',
        content: `This job has more than 4 After Hours.`,
        okText: 'Yes',
        cancelText: 'Not Sleepover',
        onOk () {
          const ct = billingCategoryList.find(e => e.is_sleepover === true)
          if (ct && ct.id) {
            form.setFieldsValue({ billing_category_id: ct.id })

            that.setState({ isExtSleepover: true, isShowSleepoverAlert })
          }
        },
        onCancel () {
          form.setFieldsValue({ billing_category_id: undefined })
          that.setState({ isExtSleepover: false,  isShowSleepoverAlert })
        }
      })
    } else {
      if (value === false) {
        if (isUpdate) {
          form.setFieldsValue({ billing_category_id: undefined })
        }
      } else {
        const ct = billingCategoryList.find(e => e.is_sleepover === true)
        if (ct && ct.id) {
          form.setFieldsValue({ billing_category_id: ct.id })
        }
      }

      this.setState({ isExtSleepover: value, isShowSleepoverAlert })
    }

    setTimeout(() => {
      this.showDuration(form.getFieldValue('job_start_time'), form.getFieldValue('job_end_time'))
      // this.checkSleepoverMismatch()
    }, 100)
  }

  handleSleepoverCustomSO = (value) => {
    const { item, rateSetInfo } = this.state
    const { form } = this.props
    if (value) {
      if (item.custom_so_start_time) {
        form.setFieldsValue({custom_so_start_time: Moment(item.custom_so_start_time)})
      }

      if (item.custom_so_end_time) {
        form.setFieldsValue({custom_so_end_time: Moment(item.custom_so_end_time)})
      }
    } else {
      if (rateSetInfo && rateSetInfo.id) {
        form.setFieldsValue({custom_so_start_time: Moment(rateSetInfo.so_hours_start), custom_so_end_time: Moment(rateSetInfo.so_hours_end) })
      }
    }

    this.setState({ isCustomSO: value })
  }

  resetChangeEmployeeForm = async () => {
    const { form } = this.props
    this.setState({ showEmployeeReasonModal: false, isShowCancelOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({
        change_employee_reason: undefined,
        change_employee_reason_type: undefined,
        change_employee_other_reason: undefined,
        change_employee_note: undefined
      })
    }, 1000)
  }

  showDuration = (start, end) => {
    const { isExtSleepover } = this.state
    const duration = Moment.duration(Math.abs(Moment(end).diff(start))).asHours()
    this.setState({ textDuration: isExtSleepover ? `1 Unit S/O` :`${start.format('hh:mm A')} - ${end.format('hh:mm A')} (${duration} hours)` })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Recurring Date Error',
      description: 'End Date should not be earlier than Start Date',
      top: 130
    })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showInvalidFunderNotification = () => {
    error({
      title: `Invalid Funder`,
      content: <div><p>The funder is not set for this client.</p> <p>Please go to Client Page to update funder or update this job's funder in order to proceed to edit and save.</p></div>
    })
  }

  showNoFundingPeriodNotification = () => {
    warning({
      title: `Client has no Funding Period`,
      content: `The client does not have any available funding period.`
    })
  }

  /** ========== SECTION 6: Misc functions ========== */
  cacheEmployee = async (employeeId) => {
    if (employeeId) {
      const emp = await this.getEmployee(employeeId)
      this.setState({ prevEmpInfo: Object.assign({}, emp), currentEmpInfo: Object.assign({}, emp), employeeId })
    }
  }

  cacheFunder = (funderId) => {
    if (funderId) {
      const funder = this.getFunder(funderId)
      this.setState({ prevFunderInfo: Object.assign({}, funder), currentFunderInfo: Object.assign({}, funder), funderId })
    }
  }

  filterEmployees = (input, option) => {
    const emp = `${option.props.children[0]} ${option.props.children[2]}`
    return emp.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterBillingCategory = (input, option) => {
    const { children } = option.props

    if (option.type.isSelectOptGroup) {
      return children.includes(child => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getBillingCategoryName = async (catId) => {
    const { billingCategoryList } = this.state
    const cat = billingCategoryList.find(e => e.id === parseInt(catId)) || {}
    if (cat && cat.id) {
      return `${cat.name}`
    } else {
      const cat2 = await fvpCategoriesService.get(catId)

      if (cat2 && cat2.item && cat2.item.id) {
        return `${cat2.item.name}`
      }
    }

    return ''
  }

  getEmployee = async (empId) => {
    const { employeeAllList } = this.state
    const emp = employeeAllList.find(e => e.id === parseInt(empId)) || {}

    if (emp && emp.id) {
      return emp
    } else {
      const emp2 = await employeeService.get(empId)
      if (emp2 && emp2.id) {
        return emp2
      }
    }
    return null
  }

  getEmployeeName = async (empId) => {
    const emp = await this.getEmployee(empId)
    return emp && emp.id ? `${emp.first_name} ${emp.last_name}` : ''
  }

  getFunder = (funderId) => {
    const { funderList } = this.state
    const funder = funderList.find(e => e.id === parseInt(funderId)) || {}
    return funder
  }

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  getPayrollName = (pId) => {
    const { settingsPayroll } = this.state
    const p = settingsPayroll.find(e => parseInt(e.value) === parseInt(pId)) || {}
    return p && p.id ? `${p.name}` : ''
  }

  async refreshPage (isRedirectedFromNewJob = false) {
    const { singleJobListType } = this.state
    this.fetchRecurringJob()
    const jobId = this.getJobId()
    await this.fetchUnsentEmail(jobId, isRedirectedFromNewJob)

    setTimeout(() => {
      this.checkEmail()
    }, 600)

    this.fetchCancel()
    this.fetchSingleJobList(singleJobListType)

    this.props.setRefreshActivityLog(true)
    this.props.setRefreshCommLog(true)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }

  replacer = (value) => {
    if (value) {
      let str = value
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return null
  }
}

const mapDispatchToProps = {
  fetchingJobs,
  fetchTotalPending,
  setRefreshActivityLog,
  setRefreshCommLog
}

const mapStateToProps = (state) => {
  return { ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Job))

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_PENDING_EMPLOYEE_JOB, FETCHING_PENDING_EMPLOYEE_JOBS, PENDING_EMPLOYEE_JOBS_FETCHED } from '../actions/pendingEmployeeJob'
import jobService from '../../services/job'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText, jobType }) {
  try {
    yield put({ type: FETCHING_PENDING_EMPLOYEE_JOBS, loading })
    if (jobType === 'recurring') {
      const jobs = yield baseJobService.getPendingEmployeeJob(currentPage, pageSize, filter, sort, searchText)
      yield put({ type: PENDING_EMPLOYEE_JOBS_FETCHED, jobs, loading: false })
    } else {
      const jobs = yield jobService.getPendingEmployeeJob(currentPage, pageSize, filter, sort, searchText)
      yield put({ type: PENDING_EMPLOYEE_JOBS_FETCHED, jobs, loading: false })
    }
  } catch (e) {
    yield put({ type: FETCHING_PENDING_EMPLOYEE_JOBS, loading: false })
    console.error(e)
  }
}

function * watchPendingEmployeeJobs () {
  yield takeEvery(FETCH_PENDING_EMPLOYEE_JOB, fetchJobs)
}

export default watchPendingEmployeeJobs

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService, funderService, settingOtherService } from '../../../services'
import { exportFile, formatter, validator } from '../../../util'

// UI
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

import { Button, List, Page } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

export class ReportClientCarePlan extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expiredList: { list: [], total: 0 },
      expiringList: { list: [], total: 0 },
      funderList: [],
      loading: false,
      loadingExport: false,
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchFunder()
    this.fetchOtherSettings()
    this.fetchList()
  }

  render () {
    const { expiredList, expiringList, funderList, loading, loadingExport, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }
    const expiredColumns = [
      {
        title: 'Client',
        width: 5,
        render: ({ client_id: id, client }) => <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{client}</a>
      },
      {
        title: 'Funder(s)',
        width: 10,
        render: ({ funders, funder_ids }) => <span>{funders.map((f, index) => <span><a href={`/funders/${funder_ids[index]}`} rel='noopener noreferrer' target='_blank'>{f}{index === funders.length - 1 ? '' : ', '}</a></span>)}</span>
      },
      {
        title: 'Review Date',
        width: 3,
        render: ({ review_report_date }) => <div>{formatter.toShortDate(review_report_date)}</div>
      },
      {
        title: 'As of Today',
        width: 3,
        render: ({ review_date_expiry }) => <div style={{ textAlign: '' }}>{`${formatter.getDaysAgo(review_date_expiry)}`}</div>
      }
    ]

    const expiringColumns = [
      {
        title: 'Client',
        width: 5,
        render: ({ client_id: id, client }) => <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{client}</a>
      },
      {
        title: 'Funder(s)',
        width: 10,
        render: ({ funders, funder_ids }) => <span>{funders.map((f, index) => <span><a href={`/funders/${funder_ids[index]}`} rel='noopener noreferrer' target='_blank'>{f}{index === funders.length - 1 ? '' : ', '}</a></span>)}</span>
      },
      {
        title: 'Review Date',
        width: 3,
        render: ({ review_report_date }) => <div>{formatter.toShortDate(review_report_date)}</div>
      },
      {
        title: 'As of Today',
        width: 3,
        render: ({ review_date_expiry }) => <div style={{ textAlign: '' }}>{`${formatter.getDaysToGo(review_date_expiry)}`}</div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Client's Care Plan Review`} >
            {!loading
              ? <Button ghost key={'export-all'} feedback={loadingExport} onClick={() => this.handleExportAll()}>
                Export All
              </Button>
              : null}
            {!loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null}
            {!loading
              ? this.hasAccess('listRecipients')
                ? this.hasAccess('updateRecipient') ?
                  <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                    Save
                  </Button> : null : null
              : null}
          </Page.Header>
          <div className='admin-list'>
            {!loading
              ? this.hasAccess('listRecipients')
                ? <Form {...longFormItemLayout}>
                  <FormItem label="Weekly Email Recipients">
                    {getFieldDecorator('client_careplan_email', {
                      initialValue: settings.client_careplan_email,
                      rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                    })
                      (<Input />)
                    }
                  </FormItem>
                  <FormItem label='Funders'>
                    {getFieldDecorator(`client_careplan_funder_ids`, {
                      initialValue: settings.client_careplan_funder_ids || undefined
                    })(
                      <Select
                        mode='multiple'
                        showSearch
                        placeholder='Select Funder'
                        optionFilterProp='children'
                        notFoundContent='No funders available'
                        filterOption={(input, option) => this.findFunder(input, option)}
                        onChange={(id) => this.onSelectFunder(id)}
                      >
                        {funderList.map((itm, idx) => <Option key={`pts${idx}`} value={itm.id}>{itm.fullname}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Form> : null
              : null}
          </div>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Review OVERDUE `} <span className='report-tag'>{expiredList.total}</span></div>
              <List cols={expiredColumns} rows={expiredList.list} />

              <div className='report-title'>{`Review due soon `} <span className='report-tag'>{expiringList.total}</span></div>
              <List cols={expiringColumns} rows={expiringList.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    this.fetchClientCarePlanReport()
  }

  async fetchClientCarePlanReport () {
    if (this.hasAccess('readClientCarePlanReviewReport')) {
      try {
        this.setState({ loading: true })
        const { expiring, expired } = await reportService.getListByAll(ExportType.REPORT.CLIENT_CAREPLAN_V2)
        this.setState({
          expiringList: expiring,
          expiredList: expired,
          loading: false
        }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Client Care Plan Report', 'Please try again later.')
        this.setState({ loading: false })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      const settings = await settingOtherService.listByPage(1, 0)
      this.setState({ settings: settings.list[0] })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  onSelectFunder = (id) => {
    const { funderList } = this.state
    const p = funderList.find(e => e.id === id)
    if (p) {
      this.setState({ funderInfo: p })
    }
  }

  findFunder = (input, option) => {
    const pvd = `${option.props.children}`
    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  fetchFunder = async () => {
    try {
      const funders = await funderService.listAllFunders()
      this.setState({ funderList: validator.isNotEmptyArray(funders) ? funders : [] })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder list successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            this.fetchOtherSettings()
            this.fetchList()
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  handleExport = () => {
    const { loadingExport, loading } = this.state

    if (loadingExport || loading || !this.hasAccess('readClientCarePlanReviewReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.CLIENT_CAREPLAN_V2)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  handleExportAll = () => {
    const { loadingExport, loading } = this.state

    if (loadingExport || loading || !this.hasAccess('readClientCarePlanReviewReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.CLIENT_CAREPLAN_ALL)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if (window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportClientCarePlan))

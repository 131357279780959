import React, { Component } from 'react'
import { connect } from 'react-redux'
// import debounce from 'lodash.debounce'
import UserMenu from '../../../constants/menu/user'
import { adminRoleService, modulePermissionService, settingFileModulePermissionService } from '../../../services'
import { adminRolesFetched } from '../../../states/actions/adminRole'
import { fileModulePermissionsFetched } from '../../../states/actions/setting/fileModulePermission'
import { localStorage, sessionStorage } from '../../../util'

// UI
import { Button, FullModal, Page, Pager, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Popconfirm from 'antd/lib/popconfirm'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import './styles.css'

const TabPane = Tabs.TabPane
const filterKey = 'adminRole.filter'
const pageKey = 'adminRole.page'
const sortKey = 'adminRole.sort'
const pageSize = 20
const { Item: FormItem } = Form
const { TextArea } = Input

export class SettingAdminRole extends Component {
  constructor (props) {
    super(props)
    const { adminRoles: { list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      activeTab: '1',
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      modal: {
        item: { module_permissions: [] },
        show: false
      },
      searchText: '',
      sort: { name: 1 }, //sessionStorage.getObject(sortKey),
      total
    }
    // this.findAdminRoles = debounce(this.findAdminRoles, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { adminRoles: { list, total } } = nextProps
    const { fileModulePermissions: { list: fileModulePermissions, total: fileModulePermissionTotal } } = nextProps

    return { ...prevState, list, total }
  }

  componentDidMount () {
    const { currentPage, filter, sort } = this.state
    this.fetchAdminRoles({ currentPage, filter, sort })
    this.fetchFilePermission()
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  render () {
    const { form } = this.props
    const { activeTab, currentPage, list, loading, loadingForm, modal, total } = this.state
    const { getFieldDecorator } = form
    const { item: modalItem, show: modalShow } = modal
    const { module_permissions: modulePermissions = [] } = modalItem
    let lastModuleId = 0

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={UserMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='User Roles'>
            <div className='btn' onClick={this.handleAdd}>
              Add
            </div>
          </Page.Header>

          <div className='admin-role-list'>
            <Skeleton loading={loading} active>
              <Row className='list-header'>
                <Col md={6}>Name</Col>

                <Col md={14}>Description</Col>

                <Col md={2}>Enable</Col>
              </Row>

              {list.map((item) => {
                const { id, name, description, active } = item

                return (
                  <div className='list-item' key={id}>
                    <Row className='list-content'>
                      <Col md={6}>{name}</Col>

                      <Col md={14}>{description}</Col>

                      <Col md={2} style={{ fontSize: '11pt' }}>
                        {active ? <Icon type='check-circle' theme='filled' /> : null}
                      </Col>

                      <Col md={1}>
                        <div onClick={this.handleEdit(item)}><Icon type='form' /></div>
                      </Col>

                      <Col md={1}>
                        {id > 1 ? (
                          <Popconfirm
                            title='Confirm to delete this?'
                            onConfirm={this.handleDelete(id)}
                            okText='Yes'
                            cancelText='No'
                          >
                            <Icon type='delete' />
                          </Popconfirm>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                )
              })}

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} user roles`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Skeleton>
          </div>
        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            Manage user roles and permissions
          </Page.Panel>
        </Page.Right>

        <FullModal
          title='User Role'
          showModal={modalShow}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <Row gutter={16}>
              <Col lg={6}>
                <FormItem hasFeedback label='Name'>
                  {getFieldDecorator('name', {
                    initialValue: modalItem.name,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 127 characters' },
                      { max: 127, message: 'Name must be between 2 and 127 characters' },
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>

              <Col lg={15}>
                <FormItem hasFeedback label='Description'>
                  {getFieldDecorator('description', {
                    initialValue: modalItem.description,
                    rules: [
                      { min: 2, message: 'Description must be between 2 and 255 characters' },
                      { max: 255, message: 'Description must be between 2 and 255 characters' },
                      { required: true, message: 'Please enter description' },
                      { whitespace: true, message: 'Please enter description' }
                    ]
                  })(
                    <TextArea autosize={{ minRows: 1, maxRows: 2 }} />
                  )}
                </FormItem>
              </Col>

              <Col lg={3}>
                <FormItem className='wd-hidden-label' label='.'>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Panel className='module-panel' title='Permissions'>
              <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
                <TabPane tab='Module' key='1'>
                  {modulePermissions.map((permission, idx) => {
                    const { id, can_perform: canPerform, module_id: mid, module_name: mName, module_permission_description: description, module_permission_id: mpId, module_permission_name: name } = permission
                    const prevModuleId = lastModuleId
                    lastModuleId = mid
                    return (
                      <div key={`module-${id}`} >
                        {prevModuleId !== lastModuleId ? (
                          <div style={{ marginBottom: 8, marginTop: 24, fontWeight: 'bold', textTransform: 'uppercase' }}>{mName}</div>
                        ) : null}

                        <Row gutter={16} style={{ marginBottom: 8 }}>
                          {getFieldDecorator(`module_permissions[${idx}].id`, {
                            initialValue: id
                          })(
                            <Input type='hidden' />
                          )}

                          {getFieldDecorator(`module_permissions[${idx}].module_permission_id`, {
                            initialValue: mpId
                          })(
                            <Input type='hidden' />
                          )}

                          <Col lg={6}>
                            <span className='ant-form-text'>{name}</span>
                          </Col>

                          <Col lg={15}>
                            <span className='ant-form-text'>{description}</span>
                          </Col>

                          <Col lg={3}>

                            {getFieldDecorator(`module_permissions[${idx}].can_perform`, {
                              initialValue: typeof canPerform === 'boolean' ? canPerform : false,
                              valuePropName: 'checked'
                            })(
                              <Switch
                                checkedChildren='Allow'
                                unCheckedChildren='Deny'
                                tabIndex={idx + 1}
                              />
                            )}

                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                </TabPane>

                {/* <TabPane tab='File' key='2'>

                </TabPane> */}
              </Tabs>

            </Panel>
          </Form>
        </FullModal>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchAdminRoles({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchAdminRoles = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { adminRolesFetched, pristine } = this.props
      this.setState({ currentPage, loading: loading || pristine })
      const response = await adminRoleService.listByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({ loading: false })
      adminRolesFetched(response)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load user roles successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchFilePermission = async () => {
    try {
      const { fileModulePermissionsFetched, pristine } = this.props
      this.setState({ loading: pristine })
      const response = await settingFileModulePermissionService.list()
      this.setState({ loading: false })
      fileModulePermissionsFetched(response)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file permissions successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { modal, total } = this.state
        const { item } = modal
        const { id } = item
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await adminRoleService.save(id, values)
          } else {
            response = await adminRoleService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', 'User role saved successfully.')
            this.hideModal()
            this.fetchAdminRoles({ loading: true, currentPage: Math.ceil((total + 1) / pageSize), sort:{ name: 1 } })

            if (Array.isArray(response.permissions)) {
              const { permissions } = response
              localStorage.setObject('permissions', permissions)
            }
          } else {
            notify.error('Unable to save successfully', 'Unable to save user role successfully. Please try again later.')
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save user role successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleAdd = async () => {
    const modulePermissions = await modulePermissionService.list()
    this.showModal({
      module_permissions: modulePermissions.map((permission) => {
        const { id, description, name, module_id: moduleId, module_name: moduleName } = permission
        return {
          id,
          module_id: moduleId,
          module_name: moduleName,
          module_permission_id: id,
          module_permission_name: name,
          module_permission_description: description,
          can_perform: false
        }
      })
    })
  }

  handleDelete = (id) => async () => {
    const response = await adminRoleService.remove(id)

    if (response) {
      const { currentPage, filter, list, sort } = this.state
      this.fetchAdminRoles({ loading: true, currentPage: list.length > 1 ? currentPage : (currentPage - 1 || 1), filter, sort: { name: 1 } })
      message.success('Deleted successfully', 'User role deleted successfully')
    }
  }

  handleEdit = (item) => async () => {
    const module = await adminRoleService.get(item.id) || { item }
    this.showModal(module.item)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.item = {}
    modal.show = false
    this.setState({ modal })
    resetFields()
  }

  showModal = (item) => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.item = {}
    this.setState({ modal })
    resetFields()
    modal.item = item
    modal.show = true
    this.setState({ modal })
  }
}

const mapDispatchToProps = {
  adminRolesFetched,
  fileModulePermissionsFetched
}

const mapStateToProps = (state) => {
  return { ...state.AdminRole, ...state.FileModule }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingAdminRole))

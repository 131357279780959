export const FETCH_JOB_RECURRING_LIST = 'FETCH_JOB_RECURRING_LIST'
export const FETCHING_JOB_RECURRING_LIST = 'FETCHING_JOB_RECURRING_LIST'
export const JOB_RECURRING_LIST_FETCHED = 'JOB_RECURRING_LIST_FETCHED'

export const fetchJobRecurringList = (params) => {
  return Object.assign({
    type: FETCH_JOB_RECURRING_LIST
  }, params)
}

export const fetchingJobRecurringList = (loading) => {
  return {
    type: FETCHING_JOB_RECURRING_LIST,
    loading
  }
}

export const jobRecurringListFetched = (jobs) => {
  return {
    type: JOB_RECURRING_LIST_FETCHED,
    jobs
  }
}

import { FETCHING_EMPLOYEES, EMPLOYEES_FETCHED,
  FETCHING_MANDATORY_FILES, MANDATORY_FILES_FETCHED } from '../actions/employee'

const INITIAL_STATE = {
  employees: { list: [], total: 0 },
  saveMandatoryList: [],
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_EMPLOYEES:
      return handleFetchingEmployees(state, action)
    case EMPLOYEES_FETCHED:
      return handleEmployeesFetched(state, action)
    case FETCHING_MANDATORY_FILES:
      return handleFetchingMandatoryFiles(state, action)
    case MANDATORY_FILES_FETCHED:
      return handleMandatoryFilesFetched(state, action)
    default:
      return state
  }
}

function handleFetchingEmployees (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleEmployeesFetched (state, action) {
  const { employees, loading } = action
  return { ...state, employees, loading }
}

function handleFetchingMandatoryFiles (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleMandatoryFilesFetched (state, action) {
  const { saveMandatoryList, loading } = action
  return { ...state, saveMandatoryList, loading }
}

export const FETCH_FUNDERS = 'FETCH_FUNDERS'
export const FETCHING_FUNDERS = 'FETCHING_FUNDERS'
export const FUNDERS_FETCHED = 'FUNDERS_FETCHED'

export const fetchFunders = (params) => {
  return Object.assign({
    type: FETCH_FUNDERS
  }, params)
}

export const fetchingFunders = (loading) => {
  return {
    type: FETCHING_FUNDERS,
    loading
  }
}

export const fundersFetched = (funders) => {
  return {
    type: FUNDERS_FETCHED,
    funders
  }
}

export default [
  {
    name: 'Access Log',
    desc: 'View user access log',
    icon: 'login',
    link: '/settings/access-log',
    enable: true,
    permission: 'listUserRoles'
  },
  {
    name: 'Reminder Log',
    desc: 'View user access log',
    icon: 'mail',
    link: '/settings/reminder-log',
    enable: true,
    permission: 'listUserRoles'
  }
]

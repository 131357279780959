import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'

import './styles.css'

export default class WDInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { fieldDecorator, form, formItem = {}, input = {}, readOnly = false } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    formItem.className = formatter.toClassName(['wd-input', formItem.className])

    return (
      <Form.Item hasFeedback={!readOnly} {...formItem}>
        {readOnly ? (
          <span className='ant-form-text'>{options.initialValue}</span>
        ) : (
          getFieldDecorator(id, options)(<Input {...input} />)
        )}
      </Form.Item>
    )
  }
}

WDInput.propTypes = {
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  input: PropTypes.object,
  readOnly: PropTypes.bool
}

import { all, fork } from 'redux-saga/effects'

import watchCancelledJobs from './cancelledJob'
import watchCancelledRecurringJobs from './cancelledRecurringJob'
import watchClient from './client'
import watchEndedBaseJobs from './endedBaseJob'
import watchFeedback from './feedback'
import watchFunder from './funder'
import watchFutureJobs from './futureJob'
import watchEmployee from './employee'
import watchJob from './job'
import watchJvpJob from './jvpJob'
import watchJvpJobRecurring from './jvpJobRecurring'
import watchJvpJobSummary from './jvpJobSummary'
import watchPastJobs from './pastJob'
import watchPendingEmployeeJobs from './pendingEmployeeJob'
import watchPendingEmployeeBaseJobs from './pendingEmployeeBaseJob'
import watchRecurringJob from './recurringJob'
import watchRecurringJobList from './recurringJobList'
import watchDashboard from './dashboard'
import watchPayrollFvp from './payrollFvp'
import watchSettingStore from './setting/store'
import watchUser from './user'

export default function* root() {
  yield all([
    fork(watchCancelledJobs),
    fork(watchCancelledRecurringJobs),
    fork(watchClient),
    fork(watchDashboard),
    fork(watchEndedBaseJobs),
    fork(watchFeedback),
    fork(watchFutureJobs),
    fork(watchFunder),
    fork(watchEmployee),
    fork(watchJvpJob),
    fork(watchJvpJobRecurring),
    fork(watchJvpJobSummary),
    fork(watchJob),
    fork(watchPastJobs),
    fork(watchRecurringJob),
    fork(watchRecurringJobList),
    fork(watchPayrollFvp),
    fork(watchSettingStore),
    fork(watchUser),
    fork(watchPendingEmployeeJobs),
    fork(watchPendingEmployeeBaseJobs)
  ])
}

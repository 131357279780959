import React, { Component } from 'react'
import { connect } from 'react-redux'
import { questionnaireService } from '../../../../services'
import { setRefreshActivityLog } from '../../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log, validator } from '../../../../util'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager } from '../../../../components'
import notify from '../../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 20
const { Item: FormItem } = Form

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

export class Questionnaire extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      questions: { list: [], total: 0 },
    }
  }

  componentDidMount () {
    this.fetchList()
  }

  fetchList = async(page) => {
    const { currentPage } = this.state
    this.setState({loading: true})
    const list = await questionnaireService.listByPage(page || currentPage, pageSize)

    let questions = { list: [], total: 0 }
    if (list && validator.isNotEmptyArray(list.list)) {
      questions = list
    }
    this.setState({ questions, loading: false})
  }

  render () {
    const { currentPage, loading, questions } = this.state

    const quesColumns = [
      {
        title: 'Title',
        width: 5,
        key: 'header'
      },
      {
        title: 'Type',
        width: 4,
        render: ({ type }) => {
          const newtype = formatter.capitalize(type ? type.replace(/-/g, ' ') : type)
          return (
            <div>{newtype}</div>
          )
        }
      },
      {
        title: 'Reply Method',
        width: 4,
        render: ({ reply_type }) => formatter.capitalize(reply_type)
      },
      {
        title: 'Frequency',
        width: 4,
        render: ({ type, frequency, time }) => {
          if (frequency === 'covid-emergency') {
            return `Everyday ${formatter.toShortTime(time)} when Job Available`
          } else if (frequency === 'per-id') {
            if (type && type.indexOf('job-update') > -1) {
              return `Per Job`
            } else {
              return `Per ID`
            }
          } else {
            return formatter.capitalize(frequency)
          }
        }
      },
      {
        title: 'Active',
        width: 2,
        render: ({ active }) => active ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: ({ module, job_id }) => <div className='action-buttons'>
          {/* <Tooltip mouseLeaveDelay={0} title='Edit Questionnaire'><Link to={module === 'job' ? `/jobs/single/${job_id}` : `/jobs/recurring/${job_id}`}><Icon type='form' /></Link></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='View Replies'><Link to={module === 'job' ? `/jobs/single/${job_id}` : `/jobs/recurring/${job_id}`}><Icon type='unordered-list' /></Link></Tooltip> */}
          <Tooltip mouseLeaveDelay={0} title='Edit Questionnaire'><Icon type='form' /></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='View Replies'><Icon type='unordered-list' /></Tooltip>
        </div>
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={quesColumns} rows={questions.list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={questions.total}
          totalText={`Total ${questions.total} records`}
          current={currentPage}
          onChange={(page) => this.fetchList(page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Questionnaire))

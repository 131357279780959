import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/export/views'

export default {
  getViewExport (type, values) {
    return requestFile(`${url}/${type}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
}

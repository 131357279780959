import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter } from '../../../util'

// UI
import { Button, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { Form } from 'antd';
const pageSize = 50

export class ReportEmployeeMissingRequiredFiles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      missingList: { list: [], total: 0 },
      currentPage: 1,
      isLoaded: false,
      loading: false,
      loadingExport: false,
    }
  }

  componentDidMount () {
    this.fetchList()
  }

  render () {
    const { currentPage, loading, loadingExport, missingList, total} = this.state

    const leaveColumns = [
      {
        title: 'Employee',
        width: 5,
        render: ({ id: employeeId, employee }) => <a href={`/employees/${employeeId}`} rel='noopener noreferrer' target='_blank'>{employee}</a>
      },
      {
        title: 'Missing Required Files',
        width: 8,
        render: ({ missing_required_files: files }) => <div>{files ? files : ''}</div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Employee's Required Files`} >
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Employee's Required Files`} <span className='report-tag'>{missingList.total}</span></div>
              <List cols={leaveColumns} rows={missingList.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, isLoaded, loading } = this.state
    this.fetchEmpMissingReqFile({currentPage, isLoaded, loading})
  }

  async fetchEmpMissingReqFile ({ currentPage, isLoaded = true, loading }) {
    if (this.hasAccess('readEmployeeMissingRequiredFileReport')) {
      if (isLoaded) {
      } else {
        this.setState({ loading: true })
      }

      try {
        const { details } = await reportService.getListByAll(ExportType.REPORT.EMPLOYEE_MISSING_REQUIRED_FILE)

        this.setState({
          missingList: details,
          loading: false,
          isLoaded: true
        }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Employee Required File Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readEmployeeMissingRequiredFileReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.EMPLOYEE_MISSING_REQUIRED_FILE)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportEmployeeMissingRequiredFiles))

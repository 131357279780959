/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { isEqual, cloneDeep } from 'lodash'
import {
  authService, communicationService, employeeFileService, employeeService,
  jobService, logService, settingGeneralService, settingOtherService,
  settingReasonService, settingCustomIdentifier, jobActionLogService, clientService, settingFileCategoryService, settingFileTypeService,
  requiredFileOverrideService
} from '../../../services'
import formatter from '../../../util/formatter'
import { fetchingEmployees, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates, FileTypeList, FileTypes, SkillNames } from '../../../constants'
import Moment from 'moment-timezone'
import moment from 'moment'
import { accRef, log, trigger, validator, uploader, extraValue } from '../../../util'

// UI
import { Loading, List, MandatoryList, Page, Panel, SideModal, CustomIdentifierList } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import DatePicker from 'antd/lib/date-picker'
import Popconfirm from 'antd/lib/popconfirm'
import Upload from 'antd/lib/upload'

import AddFileModal from './AddFileModal'

import ActivityLog from '../ActivityLog'
import Feedback from '../Feedback'
import File from '../File'
import Leave from '../Leave'
import LeaveJvp from '../LeaveJvp'
import Communication from '../Communication'
import JobFvp from '../JobFvp'
import JobJvp from '../JobJvp'
import Document from '../Document'

import './styles.css'
import { apiHostname } from '../../../config'

const { Item: FormItem } = Form
const { confirm, error, warning } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option
const ButtonGroup = Button.Group
const { RangePicker } = DatePicker

const dateFormat = 'DD/MM/YYYY'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const AppEnableType = {
  CREATE: 'create',
  RESEND: 'resend',
  RESET: 'reset'
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/custom-identifier' },
  { tabId: 3, path: '/jobs' },
  { tabId: 9, path: '/feedbacks' },
  { tabId: 4, path: '/leaves' },
  { tabId: 5, path: '/comms' },
  { tabId: 6, path: '/prev-clients' },
  { tabId: 7, path: '/files' },
  { tabId: 8, path: '/logs' }
]

const TEXT_NOT_SELECTED = '-- Not Selected --'

const pageSize = 20
const employeeModule = 'employee'
export class Employee extends Component {
  constructor(props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      currentPage: 1,
      total: 0,
      item: {},
      funding: [],
      empId: '',
      genders: [],
      logList: [],
      loginStatus: { is_app_allow: false },
      policeExpiryDate: '',
      firstAidDate: '',
      wwcExpiry: '',
      dwesExpiry: '',
      prevClient: [],
      loading: false,
      loadingPrevClient: false,
      currentAge: 0,
      communication: {
        list: [],
        total: 0,
        currentPage: 0
      },
      newAccRef: '',
      jobList: [],
      empAge: 0,
      showEdit: true,
      showSave: false,
      languages: [],
      skills: [],
      heights: [],
      visa: [],
      relationships: [],
      reasons: [],
      shouldRefreshFiles: false,
      isDuplicateEmployee: false,
      duplicateEmployeeInfo: {},
      showResignedToggle: false,
      showResignedReason: false,
      isResignedToggleChange: false,
      settingsMandatory: [],
      uploadedMandatory: [],
      hasMissingMandatoryFiles: false,
      identifierSetting: [],
      clashClient: '',
      clashEmp: '',
      fileList: [],
      fileListBeforeEdit: [],
      mainFileCategoryList: [],
      subFileCategoryList: [],
      fileInfo: {},
      showAddFileModal: false,
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.filterJob = this.filterJob.bind(this)
    this.handleDuplicateCheck = debounce(this.handleDuplicateCheck, 500)
    this.handleActiveChange = this.handleActiveChange.bind(this)
    this.handleResignedReason = this.handleResignedReason.bind(this)
  }

  componentDidMount() {
    this.checkUrl()
    const { currentPage } = this.state
    if (this.isEdit()) {
      this.fetchEmployee()
      this.fetchEmployeeLoginStatus()
      this.fetchJob('all', currentPage)
      this.fetchLogs()
      this.fetchCommunications()
      this.fetchFiles()
      this.fetchReasons()
    }
    this.fetchSettings()
    // this.fetchSettingFilesMandatory()
    this.fetchCustomIdentifierSetting()
    this.fetchFileCategories()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  checkUrl = () => {
    // if the url is /:id only (but not /add), redirect to /:id/:type
    const { history, match, location } = this.props
    const { currentTab } = this.state
    const { id, params = {} } = match
    const { type = '' } = params

    if (!(id === 'add' || id === 'new')) {
      const tab = TabList.find(e => `${e.tabId}` === currentTab)

      if (!type) {
        if (location && location.pathname) {
          history.replace(`${location.pathname}${tab && tab.tabId ? `${tab.path}` : ''}`)
        }
      }
    }
  }

  async generateAccRef() {
    const number = await accRef.employee()
    this.setState({ newAccRef: number })
  }

  filterJob(e) {
    this.setState({ currentPage: 1 })
    this.fetchJob(e.target.value, 1)
  }

  handlePlaceChanged() {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i].long_name
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i].long_name
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i].long_name
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }

  async handleFluDateChange(value) {
    const { form } = this.props
    const { skills } = this.state

    const { item } = await settingOtherService.get(1)

    const fluVacMonth = item.cert_flu_vaccination
    const expiryDate = Moment(value).add(fluVacMonth, 'months')
    const expiryDateDiff = Moment().startOf('day').diff(expiryDate, 'days')

    const fluArr = skills.filter((skill) => skill.name === SkillNames.IMMUNISATION)
    const fluId = fluArr[0].id
    const skillField = form.getFieldValue('skills')

    // Haven't expired add skill immunisation if not added yet
    // Expired remove skill immunisation if added
    if (expiryDateDiff < 0) {
      if (skillField.indexOf(fluId) < 0) {
        skillField.push(fluId)
      }
    } else {
      if (skillField.indexOf(fluId) >= 0) {
        const removeIndex = skillField.indexOf(fluId)
        skillField.splice(removeIndex, 1)
      }
    }

    form.setFieldsValue({ flu_vaccination_expiry: (value === null ? null : expiryDate), skills: skillField })
  }

  handleActiveChange(value) {
    this.setState({ newActiveStatus: value })

    if (!value) {
      this.setState({ showResignedReason: true })
    } else {
      this.setState({ showResignedReason: false })
    }
    // this.setState({ showResignedToggle: !value })

    // if (!value) {
    //   const { form } = this.props
    //   const { setFieldsValue } = form

    //   setTimeout(() => { setFieldsValue({ is_resigned: false }) }, 200)
    // }
  }

  async downloadCalendar() {
    const { match } = this.props
    const { params } = match
    const { id } = params

    window.location.href = `${apiHostname}/api/calendar/${id}`
  }

  render() {
    const { history, match } = this.props
    const { currentTab, item, loading, empId, loginStatus, newAccRef, currentAge, showEdit, showSave, shouldRefreshFiles, showResignedToggle, clashClient, clashEmp } = this.state

    const isInfoTab = currentTab === '1'

    const loginCond = (!loginStatus || !loginStatus.login_id)
      ? AppEnableType.CREATE
      : (loginStatus.login_id && loginStatus.is_reset_password
        ? AppEnableType.RESEND
        : AppEnableType.RESET)

    const loginText = loginCond === AppEnableType.RESEND
      ? 'Resend App Login Invitation'
      : loginCond === AppEnableType.CREATE
        ? 'Enable App Login'
        : loginCond === AppEnableType.RESET
          ? 'Reset Password'
          : ''

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Employee (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.first_name} ${item.last_name} (View Only)` : showSave ? `${item.first_name} ${item.last_name} (Edit Mode)` : 'Employee'}>

            {this.hasAccess('updateEmployee') && isInfoTab
              ? (<div className='btn btn-ghost' onClick={() => this.handleEnableAppLogin(loginCond)} style={{ marginRight: 5 }}>
                {loginText}
              </div>)
              : null}

            {this.isEdit() && this.hasAccess('deleteEmployee') && isInfoTab
              ? showSave
                ? (<div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                  Delete
                </div>)
                : null
              : null}
            {showEdit && this.isEdit() && this.hasAccess('updateEmployee') && isInfoTab
              ? (<div className='btn' onClick={this.handleEditButton}>
                Edit
              </div>)
              : null}
            {(showSave || !this.isEdit()) && isInfoTab
              ? (<div className='btn' onClick={this.checkBeforeSave}>Save</div>)
              : null}
            <div className='btn' onClick={loading ? null : history.goBack}>Back</div>
          </Page.Header>

          <div className='employee-panel'>
            {this.isEdit()
              ? <div className={`employee-panel-header ${(item && !item.active) ? 'employee-resigned-panel-header' : ''} `}>
                {loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='employee-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row className={`${(item && !item.active) ? 'list-item-dim' : ''}`}>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/' + (item.gender && item.gender.toLowerCase() === 'male' ? 'man.png' : 'woman.png')} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='employee-panel-header-label'>Name</div>
                      <div className='employee-panel-header-value'>{item.first_name}&nbsp;{item.last_name}</div>
                      <div className='employee-panel-header-subvalue'>{item.acc_ref}</div>
                      {item.employee_leave_id
                        ? <div style={{ color: '#ff0000' }}>
                          <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                          &nbsp; {`Leave ${formatter.toShortDate(item.employee_leave_start_date)} - ${formatter.toShortDate(item.employee_leave_end_date)}`}</div>
                        : null}
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Phone Number</div>
                      <div className='employee-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.mobile_number || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Suburb</div>
                      <div className='employee-panel-header-value'>{item.suburb}
                        {clashClient.length > 1 || clashEmp.length > 1
                          ? <span>
                            &nbsp;
                            <Tooltip
                              title={(
                                <div>
                                  {clashClient.length > 1 ? <div>Same address with client: {clashClient}</div> : ''}
                                  {clashEmp.length > 1 ? <div>Same address with employee: {clashEmp}</div> : ''}
                                </div>
                              )}
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0}
                            >
                              <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                            </Tooltip>
                          </span>
                          : null}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Age</div>
                      <div className='employee-panel-header-value'>{currentAge} years old</div>
                    </Col>
                  </Row>}
              </div> : null}
            <div className='employee-panel-body'>
              <Tabs
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={this.handleTabChange}
              >
                <TabPane tab='Information' key='1'>
                  {this.infoTab()}
                </TabPane>
                {this.isEdit()
                  ? <TabPane tab='Custom Identifier' key='2'>
                    {this.customIdenfierTab()}
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Jobs' key='3'>
                    <Job key={`jbrem${currentTab}`} empId={match.params.id} data={item} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit()
                  ? <TabPane tab='Jobs' key='3'>
                    <JobJvp key={`jbsem${currentTab}`} employeeId={match.params.id} data={item} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Feedbacks' key='9'>
                    <Feedback key={`fem${currentTab}`} employeeId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Leave' key='4'>
                    <Leave key={`lfaem${currentTab}`} empId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit() && this.hasAccess('listEmployeeLeaves')
                  ? <TabPane tab='Leave' key='4'>
                    <LeaveJvp key={`lfpem${currentTab}`} employeeId={match.params.id} employeeInfo={item} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Communications' key='5'>
                    <Communication key={`cmrem${currentTab}`} empId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Previous Clients' key='6'>
                    {this.clientTab()}
                  </TabPane>
                  : null}
                {this.isEdit() && this.hasAccess('listEmployeeFile')
                  ? <TabPane tab='Files' key='7'>
                    <File key={`frsem${currentTab}`} empId={match.params.id} history={this.props.history} onUpdateInfo={() => this.handleFilesUpdateAction()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Activity Log' key='8'>
                    <ActivityLog key={`actem${currentTab}`} empId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  handleTabChange = (index) => {
    const id = this.getId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({ currentTab: index })

    if (tab && tab.tabId) {
      this.props.history.replace(`/employees/${id}${tab.path}`)
    }
  }

  infoTab = () => {
    const { form } = this.props

    const {
      genders, item, loginStatus, languages, loading, skills, currentAge, heights, visa, showResignedReason, isResignedToggleChange,
      driverLicense, dwes, firstAid, policeCheck, vehicleRegistration, vehicleInsurance, wwcc, loadingFile, fluVaccination, relationships, showResignedToggle,
      reasons, settingsMandatory, uploadedMandatory, identifierSetting, fileList, mainFileCategoryList, subFileCategoryList, fileInfo, showAddFileModal
    } = this.state

    const isShowAppLoginToggle = loginStatus.is_app_allow || (loginStatus.login_id !== undefined && loginStatus.login_id)

    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    const sideBySideFirstFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 12 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 11 }
    }

    const sideBySideExtraFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 10 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const vehicleLeftSideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 10 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const vehicleRightSideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 14 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = mainFileCategoryList.find(e => e.id === main_category_id)
          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subFileCategoryList.find(e => e.id === sub_category_id)
          return <div>{subCat ? subCat.name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Enabled',
        width: 3,
        render: (item) => <div>
          {item.active ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <span style={{ color: '#ccc' }}><Icon type='check-circle' /></span>}
          {!item.id
            ? <span style={{ paddingLeft: 5 }}>
              {getFieldDecorator(`is_override[${item.seq}]`, {
                initialValue: item.isOverride,
                valuePropName: 'checked'
              })(
                <Switch
                  onChange={(e) => this.handleRequiredFileOverride(e, item)}
                  checkedChildren='N/A'
                  unCheckedChildren='N/A'
                />
              )}
            </span>
            : null}
        </div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          {!item.id
            ? <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
            : null}
          {/* {this.hasAccess(`readEmployeeFile`) && item && item.file_url && item.file_name && this.isEdit()
            ? <Tooltip
              mouseLeaveDelay={0}
              title={`Download ${formatter.toStandardFileName(item.file_name)}`}
            >
              <div onClick={this.handleDownloadFile(item.file_url)} style={{ cursor: 'pointer' }}>
                <Icon type='file-text' /></div>
            </Tooltip>
            : null} */}
        </div>
      }
    ]

    return <Form>
      <Loading loading={loading} blur>
        <Panel title='Account'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleActiveChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  // disabled={item.is_resigned}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {isShowAppLoginToggle
                ? <FormItem {...sideBySideFormItemLayout} label='Enable App Login'>
                  {getFieldDecorator('is_app_allow', {
                    initialValue: loginStatus.is_app_allow || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem> : null}
            </Col>
            {/* <Col lg={12}>
              {showResignedToggle && this.hasAccess('updateEmployeeResigned') ? <FormItem {...sideBySideFormItemLayout} label='Resigned'>
                {getFieldDecorator('is_resigned', {
                  initialValue: item.is_resigned || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={() => !item.is_resigned ? this.setState({ showResignedReason: true, isResignedToggleChange: true }) : null}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem> : null}
            </Col> */}
          </Row>
        </Panel>
        <Panel title='Employee Information'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                {getFieldDecorator('first_name', {
                  initialValue: item.first_name || '',
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                {getFieldDecorator('last_name', {
                  initialValue: item.last_name || '',
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Name' hasFeedback>
                {getFieldDecorator('preferred_name', {
                  initialValue: item.preferred_name || null,
                  rules: [
                    { min: 2, message: 'Preferred Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Preferred Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter preferred name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                    {getFieldDecorator('dob', this.isEdit() && item.dob ? {
                      initialValue: Moment(item.dob),
                      rules: [
                        { required: true, message: 'Please enter dob' }
                      ]
                    } : {
                      rules: [
                        { required: true, message: 'Please enter dob' }
                      ]
                    })(
                      <DatePicker format={dateFormat} onChange={this.handleDOBChange} defaultPickerValue={Moment(new Date())} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={5}>
                  <div className='employee_age'>
                    {currentAge} years old
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                {getFieldDecorator('gender', {
                  initialValue: item.gender || null,
                  rules: [
                    { required: true, message: 'Please select gender' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      genders.map((gender, idx) => {
                        return <Option key={`sag${gender.value}`} value={gender.value}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <FormItem {...sideBySideFormItemLayout} label='Sexual Identity' hasFeedback>
                  {getFieldDecorator('sexual_identity', {
                    initialValue: item.sexual_identity,
                    rules: [
                      // { required: true, message: 'Please select sexual identity' }
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        genders.map((gender, idx) => {
                          return <Option key={`sic${gender.value}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                {getFieldDecorator('mobile_number', {
                  initialValue: item.mobile_number || '',
                  rules: [
                    { required: true, message: 'Please enter Mobile Phone Number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter email' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Height' hasFeedback>
                {getFieldDecorator('height', {
                  initialValue: item.height || null,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      heights.map((height, idx) => {
                        return <Option key={height.value} value={height.value}>{height.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address then select address on dropdown list' },
                { whitespace: true, message: 'Please enter address then select address on dropdown list' },
                { validator: this.checkAddressInput }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
            {getFieldDecorator('unit_building', {
              initialValue: item.unit_building,
              rules: [
                { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter unit/building info' }
              ]
            })(
              <Input placeholder='Please Enter Unit No/Building Name' />
            )}
          </FormItem>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Weekly Max Hour' hasFeedback>
                {getFieldDecorator('weekly_max_hrs', {
                  initialValue: item.weekly_max_hrs || null,
                  rules: [
                    { validator: this.checkDecimalInput }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...formItemLayout} label='Visa Status' hasFeedback>
                {getFieldDecorator('visa_status', {
                  initialValue: item.visa_status,
                  rules: [
                    // { required: true, message: 'Please select visa status' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      visa.map((visa, idx) => {
                        return <Option key={visa.value} value={visa.value}>{visa.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states, index) => (
                        <Option key={index} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

        </Panel>

        <Panel title='Alert'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                {getFieldDecorator('public_alert', {
                  initialValue: item.public_alert
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Private Alert'>
                {getFieldDecorator('private_alert', {
                  initialValue: item.private_alert
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Employee Ability'>

          <FormItem {...formItemLayout} label='Language Other Than English' hasFeedback>
            {getFieldDecorator('languages', {
              initialValue: item.preferences && validator.isNotEmptyArray(item.preferences.languages) ? item.preferences.languages : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  languages.map((language, index) => {
                    return <Option key={index} value={language.id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Skills' hasFeedback>
            {getFieldDecorator('skills', {
              rules: [
                { required: true, message: 'Please Select Skills' }
              ],
              initialValue: item.preferences && validator.isNotEmptyArray(item.preferences.skills) ? item.preferences.skills : [],
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  skills.map((skill, index) => {
                    return <Option
                      key={index}
                      value={skill.id}
                    // disabled={skill.name === SkillNames.IMMUNISATION || skill.name === SkillNames.WWCC}
                    >
                      {skill.name}
                    </Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

        </Panel>

        <Panel title='Secondary Contact'>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name'>
                {getFieldDecorator('second_contact_name', {
                  initialValue: item.second_contact_name || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              {/* <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('relationship', {
                  initialValue: item.relationship || ''
                })(
                  <Input />
                )}
              </FormItem> */}

              <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('relationship', {
                  initialValue: item.relationship || null,
                  rules: [
                    // { required: true, message: 'Please select relationship' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      relationships.map((relation, idx) => {
                        return <Option key={relation.value} value={relation.value}>{relation.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('second_contact_number', {
                  initialValue: item.second_contact_number || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                {getFieldDecorator('second_contact_email', {
                  initialValue: item.second_contact_email || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

          </Row>
        </Panel>

        {/* <Panel title='Mandatory Files'>
          <div className='mandatory-files-panel'>
            <MandatoryList
              form={form}
              onSkillApply={(e) => this.handleSkillApply(e)}
              moduleType={'employee'}
              moduleId={item.id}
              settingsMandatory={settingsMandatory}
              items={uploadedMandatory}
            />
          </div>
        </Panel> */}

        <Panel
          title='Required Files'
        >
          <List cols={fileColumns} rows={fileList} />
        </Panel>

        { /** File Modal */}
        <AddFileModal
          jobId={'add'}
          key={`addfilemodaljob_new`}
          item={fileInfo}
          categoriesList={mainFileCategoryList}
          subCategoriesList={subFileCategoryList}
          onClose={() => this.handleAddFileModal(false)}
          onSetFile={(values) => this.updateFileAdded(values)}
          visible={showAddFileModal}
        />

        {/* <Panel title='Custom Identifiers'>
          <CustomIdentifierList
            form={form}
            moduleType={'employee'}
            moduleId={item.id}
            identifierSetting={identifierSetting}
            items={item.extra_fields || []}
          />
        </Panel> */}

      </Loading>

      <SideModal
        title='Inactive Reason'
        showModal={showResignedReason}
        onClose={() => this.handleCancelInactiveReason()}
        buttons={[
          <Button key='0' type='primary' onClick={() => this.handleResignedReason()}>Submit</Button>
        ]}
      >

        <FormItem label='Reason'>
          {getFieldDecorator('resigned_reason', {
            rules: [
              isResignedToggleChange ? { required: true, message: 'Please select reason' } : {}
            ]
          })(
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                reasons.map((reason) => {
                  return <Option key={`mspc-${reason.name}`} value={reason.name}>{reason.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        <FormItem label='Note'>
          {getFieldDecorator('resigned_reason_note', {
            rules: [
              // isResignedToggleChange ? { required: true, message: 'Please enter note' } : {}
            ]
          })(
            <TextArea rows={4} />
          )}
        </FormItem>

      </SideModal>

    </Form>
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { currentTab, identifierSetting } = this.state

    return <CustomIdentifierList
      key={`csrem${currentTab}`}
      moduleType={'employee'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  clientTab = () => {
    const { currentTab, loadingPrevClient, prevClient } = this.state

    const columns = [
      {
        title: 'Name',
        width: 8,
        render: ({
          id,
          first_name,
          last_name,
          leave_start_date,
          leave_end_date,
          leave_id,
          leave_is_ufn
        }) => (
          <div style={{ textTransform: 'capitalize' }}>
            <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>
              {first_name} {last_name}
            </a>
            {leave_id
              ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                &nbsp;{`Leave ${formatter.toShortDate(leave_start_date)} - ${leave_is_ufn ? 'UFN' : formatter.toShortDate(leave_end_date)}`}
              </div>
              : null}
          </div>
        )
      },
      {
        title: 'Accounting Ref',
        width: 8,
        key: 'acc_ref'
      },
      {
        title: '',
        width: 8
      }
    ]
    return (
      <div key={`cmpem${currentTab}`} className='task-list'>
        <Skeleton loading={loadingPrevClient} active>
          <List cols={columns} rows={prevClient} />
        </Skeleton>
      </div>
    )
  }

  checkAddressInput = (rule, value, callback) => {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    if (value && value.length > 0) {
      if (!getFieldValue('postcode') && !getFieldValue('state') && !getFieldValue('suburb')) {
        callback(new Error('Please enter address then select address on dropdown list'))
      }
    } else if (validator.isEmptyString(value)) {
      setFieldsValue({ postcode: null, state: '', suburb: null })
    }

    callback()
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  checkDecimalInput = (rule, value, callback) => {
    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkBeforeSave = () => {
    const { form } = this.props
    const { getFieldValue } = form
    const { loading, isDuplicateEmployee, duplicateEmployeeInfo, fileList } = this.state
    const { handleSave } = this
    let hasFileList = []
    let hasRequiredFileWarning

    if (loading) return

    // const mandatory = getFieldValue('mandatory')
    this.setState({ hasMissingMandatoryFiles: false })

    // Check whether all mandatory categories have file uploaded; missing file uploaded disactive employee profile but still allow to save
    if (validator.isArray(fileList)) {
      hasFileList = fileList.filter(item => item.file_name && item.file_url)
      hasRequiredFileWarning = fileList.find(item => item.isRequiredFile)
    }
    const isShowEmployeeDuplicatedAlert = isDuplicateEmployee && duplicateEmployeeInfo.total > 0

    if (isShowEmployeeDuplicatedAlert || validator.isObject(hasRequiredFileWarning)) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The employee will be {this.isEdit() ? 'updated' : 'created'} with following issue(s):</p>

            {isShowEmployeeDuplicatedAlert
              ? <div className='duplicate-warnings'>
                <div style={{ fontSize: '14px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong> Employee may already exist</strong>
                </div>
                {duplicateEmployeeInfo.list.map((item, index) => {
                  const url = `/employees/${item.id}`
                  return (
                    <div key={index}>
                      <a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                      </a>
                    </div>
                  )
                })}
                <br />
              </div>
              : null}

            {validator.isObject(hasRequiredFileWarning)
              ? <div className='duplicate-warnings'>
                {this.setState({ hasMissingMandatoryFiles: true })}
                <div style={{ fontSize: '14px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong> There are missing files in "Required Files". Are you sure you want to proceed to save?</strong>
                </div>
                <br />
              </div>
              : null}

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk() { handleSave() },
        onCancel() { }
      })
    } else {
      handleSave()
    }

  }

  fetchEmployee = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true, loadingPrevClient: true })

      const { item, clashClient, clashEmp } = await employeeService.get(id)
      // get current age
      const empDate = Moment(item.dob)
      const currYear = Moment()
      const ageNum = currYear.diff(empDate, 'years')

      this.setState({ item, loading: false, currentAge: ageNum, showResignedToggle: !item.active, oldActiveStatus: item.active, clashClient, clashEmp }, async () => {
        const prevClient = await employeeService.getEmployeePrevClient(id)

        this.setState({ prevClient, loadingPrevClient: false })
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Employee successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchEmployeeLoginStatus = async () => {
    const { match } = this.props
    const { params } = match
    const { id: empId } = params

    const r = await employeeService.getEmployeeLoginStats(empId)
    this.setState({ loginStatus: r })
  }

  fetchJob = async (filterParam, startPage = null) => {
    this.setState({ loading: true })
    const { match } = this.props
    const { params } = match
    const { id } = params
    const empId = id
    const { currentPage } = this.state
    const filter = {}
    const sorting = { job_start_date: -1 }
    const nowDate = Moment()

    const page = startPage || currentPage

    if (filterParam === 'future') {
      filter.employee_id = { condition: '=', value: empId }
      filter.job_start_date = { condition: '>=', value: nowDate }
      sorting.job_start_date = 1
      filter.is_cancel = false
    } else if (filterParam === 'previous') {
      filter.employee_id = { condition: '=', value: empId }
      filter.job_start_date = { condition: '<=', value: nowDate }
      filter.is_cancel = false
    } else if (filterParam === 'single') {
      filter.employee_id = { condition: '=', value: empId }
      filter.base_job_id = { condition: '=', value: '0' }
    } else if (filterParam === 'cancel') {
      filter.employee_id = { condition: '=', value: empId }
      filter.is_cancel = true
    } else {
      filter.employee_id = { condition: '=', value: empId }
    }

    const { list: jobList, total } = await jobService.listByPage(page, pageSize, filter, sorting)
    this.setState({ jobList, type: filterParam, total, loading: false, currentPage: page, filter, sorting })
  }

  fetchCommunications = async (currentPage = 1) => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    this.setState({ loading: true })
    const filter = {}

    filter.employee_id = { condition: '=', value: id }

    const communication = await jobService.getEmails(currentPage, pageSize, filter)
    // console.log('Communications', communication.list)
    this.setState({ communication: Object.assign(communication, currentPage), loading: false, currentPage, filter })
  }

  fetchSettingFilesMandatory = async (currentPage = 1) => {
    const filter = {}
    this.setState({ loading: true })

    filter.sub_cat_active = { condition: '=', value: true }
    filter.sub_cat_has_mandatory = { condition: '=', value: true }
    filter.sub_cat_classification = { condition: '=', value: 'employee' }

    const { list: documents } = await employeeFileService.listDocumentByPage(currentPage, 0, filter)

    this.setState({ loading: false, settingsMandatory: documents })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: employeeModule }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'employee' }

    const fileTypes = await employeeFileService.getEmployeeFilelistByPage(1, 0, filter)

    this.setState({ uploadedMandatory: fileTypes.list, fileList: cloneDeep(fileTypes.list), fileListBeforeEdit: cloneDeep(fileTypes.list) })
  }

  // fetchFiles = async () => {
  //   const { match } = this.props
  //   const { params } = match
  //   const { id } = params

  //   const filter = {}
  //   filter.type = {
  //     $or: [
  //       { condition: '=', value: FileTypes.DRIVER_LICENSE },
  //       { condition: '=', value: FileTypes.DWES },
  //       { condition: '=', value: FileTypes.FIRST_AID },
  //       { condition: '=', value: FileTypes.FLU_VACCINATION },
  //       { condition: '=', value: FileTypes.POLICE_CHECK },
  //       { condition: '=', value: FileTypes.VEHICLE_INSURANCE },
  //       { condition: '=', value: FileTypes.VEHICLE_REGISTRATION },
  //       { condition: '=', value: FileTypes.WWCC }
  //     ]
  //   }
  //   filter.module_id = { condition: '=', value: id }
  //   filter.module = { condition: '=', value: employeeModule }
  //   filter.active = { condition: '=', value: true }

  //   const fileTypes = await employeeFileService.listByPage(1, 0, filter)

  //   const driver_license = fileTypes.list.filter(item => item.type === FileTypes.DRIVER_LICENSE)
  //   const dwes = fileTypes.list.filter(item => item.type === FileTypes.DWES)
  //   const first_aid = fileTypes.list.filter(item => item.type === FileTypes.FIRST_AID)
  //   const flu_vaccination = fileTypes.list.filter(item => item.type === FileTypes.FLU_VACCINATION)
  //   const police_check = fileTypes.list.filter(item => item.type === FileTypes.POLICE_CHECK)
  //   const vehicle_insurance = fileTypes.list.filter(item => item.type === FileTypes.VEHICLE_INSURANCE)
  //   const vehicle_registration = fileTypes.list.filter(item => item.type === FileTypes.VEHICLE_REGISTRATION)
  //   const wwcc = fileTypes.list.filter(item => item.type === FileTypes.WWCC)
  //   this.setState({
  //     driverLicense: driver_license[0],
  //     dwes: dwes[0],
  //     firstAid: first_aid[0],
  //     fluVaccination: flu_vaccination[0],
  //     policeCheck: police_check[0],
  //     vehicleInsurance: vehicle_insurance[0],
  //     vehicleRegistration: vehicle_registration[0],
  //     wwcc: wwcc[0]
  //   })
  // }

  fetchFileCategories = async () => {
    try {
      const { fileList } = this.state
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'employee' })
      const subCats = await settingFileTypeService.listSubsByPage(1, 0, { active: true, classification: 'employee', has_mandatory: true })

      if (!this.isEdit()) {
        for (let i = 0; i < subCats.list.length; i++) {
          const data = subCats.list[i]

          fileList.push({
            seq: String(i),
            main_category_id: data.file_category_id,
            sub_category_id: data.id,
            isRequiredFile: true,
            isOverride: false
          })
        }

        this.setState({
          mainFileCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
          subFileCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
          fileList,
          fileListBeforeEdit: cloneDeep(fileList),
          loading: false
        })
      } else {
        this.setState({
          mainFileCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
          subFileCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
          loading: false
        })
      }

    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  fetchReasons = async () => {
    const filter = {}
    filter.code = { condition: '=', value: 'inactive-employee' }

    const reasons = await settingReasonService.listItemsByPage(1, 0, filter)
    this.setState({ reasons: reasons.list })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'visa' },
        { condition: '=', value: 'relationship' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const hg = this.generateListNullOption('height', settings)
    const vs = this.generateListNullOption('visa', settings)
    const rs = this.generateListNullOption('relationship', settings)

    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: settings.list.filter(item => item.type === 'language'),
      skills: settings.list.filter(item => item.type === 'skill'),
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding'),
      heights: hg,
      visa: vs,
      relationships: rs
    })
  }

  generateListNullOption(type, settings) {
    const vList = settings.list.filter(item => item.type === type)
    vList.unshift({ id: null, name: TEXT_NOT_SELECTED, type: type, value: null })

    return vList
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'employee' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)
    this.setState({ identifierSetting: customFields.list })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'employee' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList, subFileCategoryList } = this.state

    const fAdd = subFileCategoryList.filter(e => e.id === values.sub_category_id)
    if (validator.isNotEmptyArray(fAdd)) {
      if (fAdd[0].is_apply_skill && validator.isNotEmptyArray(fAdd[0].automate_skills)) {
        this.handleSkillApply(fAdd[0].automate_skills)
      }
    }


    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item && item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  handleDuplicateCheck = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['last_name', 'dob'],
      async (errors, values) => {
        if (!errors) {
          values.dob = Moment(values.dob).format('YYYY-MM-DD')

          const res = await employeeService.checkDuplicateEmployee(values)

          if (res.total > 0) {
            warning({
              title: 'Employee may already exist',
              content: (
                <div className='duplicate-warnings'>
                  {res.list.map((item, index) => {
                    const url = `/employees/${item.id}`
                    return (
                      <div key={index}><a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                      </a>
                      </div>
                    )
                  })}
                </div>
              ),
              okText: 'OK',
              onOk() { }
            })

            this.setState({ isDuplicateEmployee: true, duplicateEmployeeInfo: res })
          } else {
            this.setState({ isDuplicateEmployee: false, duplicateEmployeeInfo: {} })
          }
        }
      })
  }

  handleDelete = () => {
    const { fetchingEmployees, history, match } = this.props
    const { loading } = this.state
    const { params } = match
    const { id } = params

    if (loading) return

    confirm({
      title: 'Are you sure you want to delete this employee?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk() {
        try {
          const response = await employeeService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Employee deleted successfully.')
            history.replace('/employees')
            fetchingEmployees(true)
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete Employees successfully. Please try again later.')
        }
      }
    })
  }

  handleCancelInactiveReason() {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    setFieldsValue({ active: !getFieldValue('active') })

    this.setState({ showResignedReason: false, isResignedToggleChange: false })
  }

  handleResignedReason(value) {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['resigned_reason'], async (errors, values) => {
      if (!errors) {
        this.setState({ showResignedReason: false })
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleDOBChange = (e) => {
    const currYear = Moment()
    const ageNum = currYear.diff(e, 'years')

    this.setState({ currentAge: ageNum })

    if (!this.isEdit()) {
      this.handleDuplicateCheck()
    }
  }

  handleDownloadFile = (url) => (e) => {
    if (url) {
      window.location.href = url
    } else {
      error({
        title: 'Error',
        content: (
          <div>No file has been uploaded!</div>
        ),
        okText: 'OK',
        onOk() { }
      })
    }
  }

  handleFilesUpdateAction = () => {
    this.fetchEmployee()
    this.fetchFiles()
  }

  // async handleDeleteFile (id, info) {
  //   const { match } = this.props
  //   const { params } = match
  //   const { id: empId } = params
  //   this.setState({ loading: true })

  //   const response = await employeeFileService.remove(id)
  //   this.setState({ loading: false })

  //   if (response.id) {
  //     log.deleteEmployeeFile(empId, `Delete file ${info.label}`)
  //     this.fetchFiles()
  //     this.setState({ shouldRefreshFiles: true })
  //     notify.success('Deleted successfully', 'File deleted successfully')
  //     this.props.setRefreshActivityLog(true)
  //   }
  // }

  handleEditJobs = (id) => {
    const { history } = this.props
    history.replace(`/jobs/single/${id}`)
  }

  handleEditEmail = (id) => {
    console.log('Will Edit Email', id)
  }

  handleSendEmail = async (id) => {
    const { item } = this.state
    const emailData = await jobService.getEmailsById(id)
    const empEmail = item.email
    const emailSubject = emailData.item.subject
    const emailBody = emailData.item.body

    const emailParam = {
      to: empEmail,
      subject: emailSubject,
      content: emailBody
    }

    const sendEmail = communicationService.sendEmail(emailParam)
  }

  handleSkillApply = (value) => {
    const { form } = this.props
    const skills = form.getFieldValue('skills')

    if (skills.includes(value[0]) === false) {
      skills.push(value[0])

      form.setFieldsValue({ skills })
    }
  }

  handleRequiredFileOverride = async (value, item) => {
    const { fileList } = this.state

    const index = fileList.indexOf(item)
    fileList[index].isRequiredFile = !value
    fileList[index].isOverride = value

    this.setState({ fileList })
  }

  getSettingChanges(values, isNew = false) {
    let extra = ''

    extra += `${extra ? ', ' : ''}${this.updateSettingChanges({ values, key: 'languages', type: 'language', name: 'Languages', isNew })}`
    extra += `${extra ? ', ' : ''}${this.updateSettingChanges({ values, key: 'skills', type: 'skill', name: 'Skills', isNew })}`

    return extra
  }

  updateSettingChanges({ values, key, type, name = '', isNew = false }) {
    const { item, settings } = this.state

    const prevValue = item[`${type}_ids`]
    const currValue = values[key]

    const sortFn = (a, b) => {
      return a > b ? 1 : -1
    }


    if (!isNew && validator.isArray(prevValue) && validator.isArray(currValue)) {
      prevValue.sort(sortFn)

      currValue.sort(sortFn)

      if (!isEqual(prevValue, currValue)) {
        let prevValueNameArr = prevValue.map(e => {
          const s = settings.find(f => f.type === type && parseInt(f.id) === parseInt(e))

          if (s && s.id) {
            return s.name
          } else {
            return null
          }
        })
        prevValueNameArr = prevValueNameArr.filter(e => e !== null)

        let currValueNameArr = currValue.map(e => {
          const s = settings.find(f => f.type === type && parseInt(f.id) === parseInt(e))

          if (s && s.id) {
            return s.name
          } else {
            return null
          }
        })
        currValueNameArr = currValueNameArr.filter(e => e !== null)

        prevValueNameArr.sort(sortFn)
        currValueNameArr.sort(sortFn)

        return `${name ? name : formatter.capitalize(type)} change from "${prevValueNameArr.join(',')}" to "${currValueNameArr.join(',')}"`
      }
    } else if (isNew && validator.isArray(currValue)) {
      currValue.sort((a, b) => {
        return a > b ? 1 : -1
      })

      let currValueNameArr = currValue.map(e => {
        const s = settings.find(f => f.type === type && f.id === e)

        if (s && s.id) {
          return s.name
        } else {
          return null
        }
      })
      currValueNameArr = currValueNameArr.filter(e => e !== null)
      currValueNameArr.sort(sortFn)

      return `${name ? name : formatter.capitalize(type)} set as "${currValueNameArr.join(',')}"`
    }

    return ''
  }

  changePage = async (currentPage) => {
    this.setState({ loading: true })

    const { type } = this.state

    this.fetchJob(type, currentPage)
  }

  onChangeCommunicationPage = async (currentPage) => {
    const { communication } = this.state
    communication.currentPage = currentPage
    this.setState({ communication })
    this.fetchCommunications(currentPage)
  }

  handleEnableAppLogin = (type) => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const that = this
    const { loading } = this.state

    if (loading) return

    const title = type === AppEnableType.CREATE
      ? 'Are you sure you want to enable app login for this employee?'
      : type === AppEnableType.RESEND
        ? 'Are you sure you want to resend app login invitation?'
        : type === AppEnableType.RESET
          ? 'Are you sure you want to reset employee\'s app login credentials?'
          : ''
    const successTitle = type === AppEnableType.CREATE
      ? 'App Login enabled successfully'
      : type === AppEnableType.RESEND
        ? 'Invitation resend successfully'
        : type === AppEnableType.RESET
          ? 'App Login Credential reset successfully'
          : ''

    const successContent = type === AppEnableType.CREATE
      ? 'App login for the employee is enabled successfully.'
      : type === AppEnableType.RESEND
        ? 'Invitation has resent successfully.'
        : type === AppEnableType.RESET
          ? 'App login credential is reset successfully.'
          : ''

    const failedTitle = type === AppEnableType.CREATE
      ? 'Unable to enable App Login'
      : type === AppEnableType.RESEND
        ? 'Unable to resend invitation'
        : type === AppEnableType.RESET
          ? 'Unable to reset login credential'
          : ''

    const failedContent = type === AppEnableType.CREATE
      ? 'Unable to enable App Login. Please try again later.'
      : type === AppEnableType.RESEND
        ? 'Unable to resend invitation. Please try again later.'
        : type === AppEnableType.RESET
          ? 'Unable to reset login credential. Please try again later.'
          : ''

    confirm({
      title: title,
      content: 'Press Ok to continue, Cancel to return',
      async onOk() {
        try {
          const response = await employeeService.updateEmployeeLoginStats(id, { is_app_allow: true, is_first_time: type === AppEnableType.CREATE || type === AppEnableType.RESEND })
          if (response && response.id) {
            notify.success(successTitle, successContent)
            that.setState({ loginStatus: response })
          } else {
            notify.error(failedTitle, failedContent)
          }
        } catch (e) {
          notify.error(failedTitle, failedContent)
        }
      }
    })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingEmployees } = this.props
        const { item, loading, longitude, latitude, hasMissingMandatoryFiles, uploadedMandatory, oldActiveStatus, newActiveStatus, reasons, fileList } = this.state
        let extraLog = []

        if (loading) return

        this.setState({ loading: true })

        values.dob = values.dob.format('YYYY-MM-DD')
        values.weekly_max_hrs = values.weekly_max_hrs || null

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        // if (hasMissingMandatoryFiles) {
        //   values.active = false
        // }


        if (values.resigned_reason && values.resigned_reason !== '') {
          extraLog.push(`Inactive Reason "${values.resigned_reason}" ${values.resigned_reason_note ? `, Inactive Note "${values.resigned_reason_note}"` : ''}`)
        }

        delete values.resigned_reason
        delete values.resigned_reason_note
        delete values.is_override

        try {
          if (this.isEdit()) {
            // ------ append file required override start ------
            if (validator.isNotEmptyArray(fileList)) {
              await this.onSaveFilesRequiredOverride(item.id)
            }
            // ------ append file required override end ------

            // ------ append file start -------
            if (validator.isNotEmptyArray(fileList) && fileList.find(e => e.file_name && e.file_url)) {
              await this.onSaveUploadFiles(item.id)
            }
            // ------ append file end -------
            const response = await employeeService.save(item.id, values)

            const newItem = Object.assign({}, item, values)
            // const fileList = values.mandatory || []
            delete values.mandatory

            if (response && response.id) {
              // Generate & save log_multi_action
              if (typeof oldActiveStatus === 'boolean' && typeof newActiveStatus === 'boolean' && (oldActiveStatus !== newActiveStatus)) {
                const exReason = form.getFieldValue('resigned_reason')
                const exReasonNote = form.getFieldValue('resigned_reason_note')
                const reasonId = newActiveStatus === false ? reasons.find(e => e.name === exReason) : null
                const actions = {}
                actions.module = 'employee'
                actions.module_id = item.id
                actions.action = 'employee-status-change'
                actions.action_detail = `employee status change from "${oldActiveStatus}" to ${newActiveStatus}`
                actions.previous_boolean = oldActiveStatus
                actions.current_boolean = newActiveStatus
                actions.action_lvl1_id = newActiveStatus === false ? reasonId.id : null
                actions.action_lvl1_name = newActiveStatus === false ? exReason : null
                actions.action_lvl1_text = newActiveStatus === false ? exReasonNote : null
                const actionLog = await jobActionLogService.add(actions)
              }

              const settingLog = this.getSettingChanges(values, false)

              if (settingLog) {
                extraLog.push(settingLog)
              }

              log.updateEmployee(response.id, item, values, [],
                [
                  // { key: 'is_resigned', label: 'Resigned' },
                  { key: 'dob', label: 'D.O.B.' },
                  { key: 'work_with_child_date', label: 'WWCC Date' },
                  { key: 'work_with_child_expiry', label: 'WWCC Expiry' }
                ],
                extraLog.join(', ')
              )

              trigger.updateEmployee(
                response.id,
                `${values.first_name} ${values.last_name}`,
                item,
                values,
                [
                  { key: 'active' },
                  { key: 'first_name' },
                  { key: 'last_name' },
                  { key: 'unit_building' },
                  { key: 'address' },
                  { key: 'mobile_number' },
                  { key: 'email' },
                  { key: 'gender' },
                  { key: 'dob', label: 'Date of Birth' },
                  { key: 'phone_number' },
                  { key: 'visa_status' }
                ]
              )

              this.setState({ item: newItem, loading: false })
              notify.success('Saved successfully', 'Employees saved successfully.')
              fetchingEmployees(true)
              window.location.replace(`/employees/${item.id}`)
            } else {
              notify.error('Saved failed', 'Unable to save employee detail. Please try again later.')
              this.setState({ loading: false })
            }
          } else {
            const response = await employeeService.add(values)

            if (response.id && !response.errors) {
              const { id, acc_ref } = response

              log.addEmployee(id, `New employee ${values.first_name} ${values.last_name}`)

              trigger.addEmployee(
                id,
                `${values.first_name} ${values.last_name}`,
                values,
                [
                  { key: 'active' },
                  { key: 'first_name' },
                  { key: 'last_name' },
                  { key: 'gender' },
                  { key: 'dob', label: 'Date of Birth' },
                  { key: 'mobile_phone', label: 'Mobile Number' },
                  { key: 'phone_number', label: 'Phone Number' },
                  { key: 'email' },
                  { key: 'second_email', label: 'Secondary Email' },
                  { key: 'visa_status' },
                  { key: 'height' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' }
                ]
              )

              // ------ append file required override start ------
              if (validator.isNotEmptyArray(fileList)) {
                await this.onSaveFilesRequiredOverride(response.id)
              }
              // ------ append file required override end ------

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList) && fileList.find(e => e.file_name && e.file_url)) {
                await this.onSaveUploadFiles(response.id)
              }
              // ------ append file end -------

              this.setState({ loading: false })
              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Employees saved successfully.')
              window.location.replace(`/employees/${id}`)
              fetchingEmployees(true)
            } else {
              const errMsg = response && validator.isNotEmptyArray(response.errors) && response.errors[0].message ? response.errors[0].message : 'Unable to save employee detail. Please try again later.'
              notify.error('Saved failed', errMsg)
              this.setState({ loading: false })
            }
          }

          const empDate = Moment(values.dob)
          const currYear = Moment()
          const ageNum = currYear.diff(empDate, 'years')
          this.setState({ currentAge: ageNum })

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          const { response } = e

          if (response) {
            const { valid, errors } = response
            if (!valid && Array.isArray(errors)) {
              notify.error('Error', errors[0].message)
            }
          } else {
            notify.error('Unable to save successfully', 'Unable to save employees successfully. Please try again later.')
          }

          this.setState({ loading: false })
        }
        this.fetchLogs()
      }
    })
  }

  onSaveUploadFiles = async (empId) => {
    const { fileList, mainFileCategoryList, subFileCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = empId
      // console.log(`upload file ${i}`, file)

      if (file.file_name && file.file_url && !file.id) {
        const cat = mainFileCategoryList.find(e => e.id === file.main_category_id)
        const subcat = subFileCategoryList.find(e => e.id === file.sub_category_id)

        const response = await employeeFileService.add(file)

        if (response && response.id) {
          // ------ remove file required override start ------
          let values = {}
          values.module = 'employee'
          values.module_id = empId
          values.main_category_id = file.main_category_id
          values.sub_category_id = file.sub_category_id
          await requiredFileOverrideService.remove(values)
          // ------ remove file required override end ------

          let logItemAfter = {
            label: file.label || '',
            file_name: formatter.toStandardFileName(file.file_name),
            active: file.active,
            issuance_date: file.issuance_date
          }

          if (subcat && (subcat.has_expiry && file.expiry_date)) {
            logItemAfter.expiry_date = file.expiry_date || ''
          }

          const extraLog = []
          extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

          log.addEmployeeFile(empId, logItemAfter, [], extraLog.join())
        }
      }

    }
  }

  onSaveFilesRequiredOverride = async (empId) => {
    const { fileList, fileListBeforeEdit, mainFileCategoryList, subFileCategoryList } = this.state

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      let values = {}

      if ((file && file.isOverride) || (file && file.isOverride === false)) {

        if (fileListBeforeEdit[i].isOverride !== file.isOverride) {
          const mainCat = mainFileCategoryList.find(e => e.id === file.main_category_id)
          const subCat = subFileCategoryList.find(e => e.id === file.sub_category_id)
          values.module = 'employee'
          values.module_id = empId
          values.main_category_id = file.main_category_id
          values.sub_category_id = file.sub_category_id
          values.active = file.isOverride
          const response = await requiredFileOverrideService.add(values)

          if (response && response.id) {

            if (response.isNewAdded) {
              log.setEmployeeFileToggle(empId, `Set ${mainCat ? mainCat.name : ''} - ${subCat ? subCat.name : ''} toggle N/A to "${file.isOverride}"`)
            } else {
              log.setEmployeeFileToggle(empId, `Set ${mainCat ? mainCat.name : ''} - ${subCat ? subCat.name : ''} toggle N/A from "${!file.isOverride}" to "${file.isOverride}" `)
            }
          }
        }
      }
    }
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingEmployees,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Employee))

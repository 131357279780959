import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jobService, baseJobService, fvpJobsService, clientService, authService } from '../../../../services'
import formatter from '../../../../util/formatter'
import { fetchingClients } from '../../../../states/actions/client'
import Moment from 'moment-timezone'
import { log, validator } from '../../../../util'
import { apiHostname } from '../../../../config'

// UI
import { JobViewRecurring, Loading, List, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import AddJobModal from '../AddJobModal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker

export class ClientJobsRecurring extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: 'recurring',
      searchText: '',
      total: 0,
      jobList: [],
      loading: false,
      clientId: '',
      showJobModal: false,
      showReminderDate: false,
      showExportDate: false
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.refreshJob()
  }

  filterJob (e) {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchClientJob({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClientJob({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, loading, jobList, total, showJobModal, showReminderDate, showExportDate } = this.state
    const { clientId, form, isClientActive, isClientHasJobReminder, clientEmail } = this.props

    const { getFieldDecorator } = form

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              <div className='btn' onClick={() => this.refreshJob()}>
                Refresh
              </div>
              { this.hasAccess('createJob') && isClientActive
                ? <div className='btn' onClick={() => this.addJob()}>
                  Add Job
                </div>
                : null }
            </Col>
          </Row>

        </div>

        <Radio.Group onChange={this.filterJob} defaultValue='recurring' style={{ marginLeft: 20 }}>
          <Radio.Button value='recurring'>Active</Radio.Button>
          <Radio.Button value='ended'>Ended</Radio.Button>
        </Radio.Group>

        <div className='jobs jobs-client-list'>
          <Skeleton loading={loading} active>
            {jobList.map((item) => {
              return (
                <JobViewRecurring
                  item={item}
                />
              )
            })}
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} jobs`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        {isClientActive
          ? <AddJobModal clientId={clientId} visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} type={'recurring'} />
          : null }

      </Loading>
    )
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  refreshJob = async () => {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchClientJob({ currentPage, filter, loading, searchText, sort, filterParam: filterParam })
  }

  fetchClientJob = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    let sorting = {}
    const { clientId } = this.props
    const nowDate = Moment().startOf('day')

    const page = startPage || currentPage
    let jobList = []
    let total = 0

    if (filterParam === 'recurring') {
      sorting = { job_start_date: -1 }
      filter.client_id = { condition: '=', value: clientId }
      const list1 = await baseJobService.listByPage(page, pageSize, filter, sorting, searchText)

      jobList = list1 && validator.isNotEmptyArray(list1.list) ? list1.list : []
      total = list1 && list1.total !== undefined ? list1.total : 0
    } else if (filterParam === 'ended') {
      sorting = { id: -1 }
      filter.client_id = { condition: '=', value: clientId }
      const list2 = await baseJobService.getEndedJob(page, pageSize, filter, sorting, searchText)

      jobList = list2 && validator.isNotEmptyArray(list2.list) ? list2.list : []
      total = list2 && list2.total !== undefined ? list2.total : 0
    }

    this.setState({ jobList, type: filterParam, total, loading: false, currentPage: page, filter, sorting, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, type } = this.state
    this.fetchClientJob({ currentPage, filter, searchText, sort, filterParam: type })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientJobsRecurring))

export const FETCH_PAYROLLS_FVP = 'FETCH_PAYROLLS_FVP'
export const FETCHING_PAYROLLS_FVP = 'FETCHING_PAYROLLS_FVP'
export const PAYROLLS_FETCHED_FVP = 'PAYROLLS_FETCHED_FVP'

export const FETCH_PAYROLL_TIMESHEETS_FVP = 'FETCH_PAYROLL_TIMESHEETS_FVP'
export const FETCHING_PAYROLL_TIMESHEETS_FVP = 'FETCHING_PAYROLL_TIMESHEETS_FVP'
export const PAYROLL_TIMESHEETS_FETCHED_FVP = 'PAYROLL_TIMESHEETS_FETCHED_FVP'

export const FETCH_PAYROLL_TIMESHEET_SUM_FVP = 'FETCH_PAYROLL_TIMESHEET_SUM_FVP'
export const FETCHING_PAYROLL_TIMESHEET_SUM_FVP = 'FETCHING_PAYROLL_TIMESHEET_SUM_FVP'
export const PAYROLL_TIMESHEET_SUM_FETCHED_FVP = 'PAYROLL_TIMESHEET_SUM_FETCHED_FVP'

export const fetchPayrollsFvp = (params) => {
  return Object.assign({
    type: FETCH_PAYROLLS_FVP
  }, params)
}

export const fetchingPayrollsFvp = (loading) => {
  return {
    type: FETCHING_PAYROLLS_FVP,
    loading
  }
}

export const payrollsFetchedFvp = (payrolls) => {
  return {
    type: PAYROLLS_FETCHED_FVP,
    payrolls
  }
}

export const fetchTimesheetsFvp = (params) => {
  return Object.assign({
    type: FETCH_PAYROLL_TIMESHEETS_FVP
  }, params)
}

export const fetchTimesheetSummaryFvp = (params) => {
  return Object.assign({
    type: FETCH_PAYROLL_TIMESHEET_SUM_FVP
  }, params)
}

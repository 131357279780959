import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/funders'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getClient (id) {
    return request(`${url}/${id}/clients`)
  },
  getRateset (id) {
    return request(`${url}/${id}/rateset`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listClientByPage (page, size, filter, sorting, text) {
    return request(`${url}/client/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getPhones () {
    return request(`${url}/phones`)
  },
  getFunderIds () {
    return request(`${url}/ids`)
  },
  getClientFunderSetting (id) {
    return request(`${url}/clientfunder/${id}`)
  },
  checkDuplicate (values) {
    return request(`${url}/check/duplicate`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listAllFunders () {
    return request(`${url}/list/all`)
  }
}

import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/clients'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getCustom (values) {
    return request(`${url}/info`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateFunder (id, values) {
    return request(`${url}/${id}/funder`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listDetailsByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageAddress (page, size, filter, sorting, text) {
    return request(`${url}/map/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageActive (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}/active${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageFunder (page, size, filter, sorting, text) {
    return request(`${url}/funder/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getSuburbs () {
    return request(`${url}/suburbs`)
  },
  getClientIds () {
    return request(`${url}/ids`)
  },
  getClientEmployees (id) {
    return request(`${url}/${id}/employees/active`)
  },
  getClientEmployeesCustom (values) {
    return request(`${url}/employees/active`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getClientPrevEmployeesActive (id) {
    return request(`${url}/${id}/employees/prev/active`)
  },
  getClientFunders (id) {
    return request(`${url}/${id}/funders`)
  },
  getClientFundersInfo (id, fid) {
    return request(`${url}/${id}/funder/${fid}`)
  },
  getClientFundersByDate (id, startD, endD) {
    return request(`${url}/${id}/funder/startD/${startD}`)
  },
  getClientFunderSetting (id) {
    return request(`${url}/${id}/funders/setting`)
  },
  addClientFunderSetting (clientId, values) {
    return request(`${url}/${clientId}/funders/setting`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveClientFunderSetting (clientId, id, values) {
    return request(`${url}/${clientId}/funders/setting/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeClientFunderSetting (clientId, id) {
    return request(`${url}/${clientId}/funders/setting/${id}`, {
      method: 'DELETE'
    })
  },
  exportJobList (id, from, to) {
    return request(`${url}/${id}/jobs/${from}/${to}`)
  },
  checkDuplicateClient (values) {
    return request(`${url}/check/duplicate${queryString.stringify(values)}`)
  },
  exportClient (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getClashedAddress (type, id) {
    return request(`${url}/type/${type}/${id}/clashed-address`)
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../services/auth'
import { localStorage, sessionStorage, validator } from '../../util'

// UI
import { Spin } from '../../components'
import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './styles.css'

const FormItem = Form.Item

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      alert: {
        message: 'Incorrect username and/or password.',
        show: false,
        type: 'error'
      },
      resetPassword: false,
      loading: false,
      tokenExpired: false,
      tokenRevoked: false,
      unauthorized: false
    }
  }

  componentDidMount () {
    const redirected = localStorage.getItem('redirected') === 'true'

    if (!redirected) {
      const rememberMe = localStorage.getItem('rememberMe') === 'true'
      const tokenExpired = sessionStorage.getItem('tokenExpired') === 'true'
      const tokenRevoked = sessionStorage.getItem('tokenRevoked') === 'true'
      const unauthorized = sessionStorage.getItem('unauthorized') === 'true'

      if (tokenExpired || tokenRevoked || unauthorized || !rememberMe) {
        localStorage.clear()
        authService.signOut()
      }

      if (!(tokenExpired || tokenRevoked || unauthorized)) {
        sessionStorage.clear()
      } else {
        this.setState({ tokenExpired, tokenRevoked, unauthorized })
      }

      localStorage.setItem('redirected', true)
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { alert, resetPassword, loading, tokenExpired, tokenRevoked, unauthorized } = this.state

    return (
      <div className='login'>
        <div className='login-box'>
          <Row>
            <Col xs={0} sm={0} lg={14}>
              <div className='login-left-side'>
                <img alt='' src={process.env.PUBLIC_URL + '/img/login/event.svg'} className='login-pic' />
              </div>
            </Col>

            <Col sm={24} lg={10} className='box'>
              <div className='center'>
                <img className='logo' alt='Flexi Support' src={process.env.PUBLIC_URL + '/img/logo-flexi.png'} />
              </div>

              <div className='center'>
                {tokenExpired ? (
                  <Alert
                    className='alert'
                    message='Your session has expired. Please sign in again.'
                    type='error'
                    showIcon
                  />
                ) : null}

                {tokenRevoked ? (
                  <Alert
                    className='alert'
                    message='Your session is invalid. Please sign in again.'
                    type='error'
                    showIcon
                  />
                ) : null}

                {unauthorized ? (
                  <Alert
                    className='alert'
                    message='You must sign in to use this application.'
                    type='error'
                    showIcon
                  />
                ) : null}

                {alert.show ? (
                  <Alert
                    className='alert'
                    message={alert.message}
                    type={alert.type}
                    showIcon
                  />
                ) : null}
              </div>

              <Spin loading={loading} blur>
                <div className='container'>
                  <Form className='form'>
                    <div style={resetPassword ? { display: 'none' } : {}}>
                      <FormItem>
                        {getFieldDecorator('username', {
                          rules: [{ required: true, message: 'Please enter your username' }]
                        })(
                          <Input
                            autoFocus
                            onKeyPress={this.handleSignIn}
                            placeholder='Username'
                            prefix={<Icon type='user' />}
                            readOnly={resetPassword || loading}
                          />
                        )}
                      </FormItem>

                      <FormItem>
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Please enter your password' }]
                        })(
                          <Input
                            onKeyPress={this.handleSignIn}
                            placeholder='Password'
                            prefix={<Icon type='lock' />}
                            readOnly={resetPassword || loading}
                            type='password'
                          />
                        )}
                      </FormItem>

                      <FormItem>
                        {getFieldDecorator('rememberMe', {
                          initialValue: false,
                          valuePropName: 'checked'
                        })(
                          <Checkbox>Remember Me</Checkbox>
                        )}
                      </FormItem>
                    </div>

                    {resetPassword ? (
                      <div>
                        <FormItem label='Enter your new password' />

                        <FormItem>
                          {getFieldDecorator('newPassword', {
                            rules: [
                              { required: true, message: 'Please enter your new password' },
                              { validator: this.checkPassword }
                            ]
                          })(
                            <Input
                              onKeyPress={this.handleSignIn}
                              placeholder='New Password'
                              prefix={<Icon type='lock' />}
                              readOnly={loading}
                              type='password'
                            />
                          )}
                        </FormItem>

                        <FormItem>
                          {getFieldDecorator('confirmPassword', {
                            rules: [
                              { required: true, message: 'Please re-enter your new password to confirm' },
                              { validator: this.checkConfirmPassword }
                            ]
                          })(
                            <Input
                              onKeyPress={this.handleSignIn}
                              placeholder='Confirm New Password'
                              prefix={<Icon type='lock' />}
                              readOnly={loading}
                              type='password'
                            />
                          )}
                        </FormItem>

                        <FormItem>
                          <Button className='button' disabled={loading} onClick={this.handleSignIn} type='primary'>
                        Confirm
                          </Button>
                        </FormItem>
                      </div>
                    ) : (
                      <FormItem>
                        <Button className='button' disabled={loading} onClick={this.handleSignIn} type='primary'>
                      Sign In
                        </Button>

                        <div className='center' style={{marginTop: '25px'}}>
                          <Link to='/forgot-password' onClick={this.hideAlert}>Forgot password</Link>
                        </div>
                      </FormItem>
                    )}
                  </Form>
                </div>
              </Spin>
            </Col>
          </Row>
        </div>
        <div className='copyright'>
          <div className='footer'>
            Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2020 Pumpkin IT. All rights reserved.
          </div>
        </div>
      </div>
    )
  }

  checkPassword = (rule, value, callback) => {
    const { form } = this.props
    const { validateFields } = form

    if (value && value.length > 0) {
      if (validator.checkPassword(value)) {
        callback(new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter'))
      }

      if (value.length < 8) {
        callback(new Error('Please enter at least 8 characters'))
      }
    }

    if (value) {
      validateFields(['confirmPassword'], { force: true })
    }

    callback()
  }

  checkConfirmPassword = (rule, value, callback) => {
    const { form } = this.props
    const { getFieldValue } = form
    const password = getFieldValue('newPassword') || ''

    if (value && value.length > 0) {
      if (password.length < 1 && validator.checkPassword(value)) {
        callback(new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter'))
      }

      if (password.length < 1 && value.length < 8) {
        callback(new Error('Please enter at least 8 characters'))
      }
    }

    if (value && value !== password) {
      callback(new Error('Your confirm password does not match new password'))
    } else {
      callback()
    }
  }

  handleSignIn = (e) => {
    if (this.isEnterKey(e) || this.isMouseClick(e)) {
      const { form, history } = this.props
      const { alert } = this.state
      const { validateFields } = form
      alert.show = false
      sessionStorage.removeItem('tokenExpired')
      sessionStorage.removeItem('tokenRevoked')
      sessionStorage.removeItem('unauthorized')
      this.setState({ alert, tokenExpired: false, tokenRevoked: false, unauthorized: false })
      validateFields(async (errors, values) => {
        if (!errors) {
          const { username, password, newPassword, rememberMe } = values
          this.setState({ loading: true })

          try {
            const response = await authService.signIn(username, password, newPassword, rememberMe)
            const { permissions, resetPassword, token, user, role } = response

            if (resetPassword) {
              this.setState({ resetPassword: true, loading: false })
            } else {
              user.role = role
              localStorage.setItem('redirected', false)
              localStorage.setItem('rememberMe', rememberMe)
              localStorage.setItem('token', token)
              localStorage.setObject('permissions', permissions)
              localStorage.setObject('user', user)
              sessionStorage.removeItem('tokenExpired')
              sessionStorage.removeItem('tokenRevoked')
              sessionStorage.removeItem('unauthorized')
              this.setState({ loading: false })
              history.replace('/')
            }
          } catch (e) {
            const { response } = e

            if (response) {
              const { errors } = response
              alert.message = errors[0].message
            }

            alert.show = true
            this.setState({ alert, loading: false })
          }
        }
      })
    }
  }

  hideAlert = () => {
    const { alert } = this.state
    alert.show = false
    this.setState({ alert })
  }

  isEnterKey = (e) => {
    return e && e.key === 'Enter'
  }

  isMouseClick = (e) => {
    return e && e.key === undefined && e.currentTarget.tagName.toLowerCase() === 'button'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Auth }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Login))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { settingOtherService } from '../../../services/setting'
import moment from 'moment'
import { authService } from '../../../services'

// UI
import { DateTimePicker, Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import { validator } from '../../../util'

import './styles.css'
import notify from '../../../components/Notification'

const { Item: FormItem } = Form

const settingTitle = 'Settings'
const settingType = 'Public Holiday'

export class SettingOther extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)
      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  validateDigits = (rule, value, callback) => {
    if (!validator.isDecimal(value)) {
      callback(new Error('Please enter valid decimal value'))
    }

    callback()
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        /*  console.log(values.date1, values.date2)

        delete values.date1
        delete values.date2 */

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { loading, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Other Settings'>
            {
              this.hasAccess('updateOther') ? <div className='btn' onClick={() => this.handleSave()}>
              Save
              </div> : null
            }
          </Page.Header>

          <div className='setting-list'>
            {/* <Row>
              <Col lg={12}>
                <FormItem label='Date 1' {...formItemLayout}>
                  {getFieldDecorator('date1', {
                    initialValue: '',
                    rules: [
                      { required: true, message: 'Please enter hours of advance notice' }
                    ]
                  })(
                    <DateTimePicker onChange={(date) => console.log(date)} />
                  )}
                </FormItem>

              </Col>
              <Col lg={12}>
                <FormItem label='Date 2' {...formItemLayout}>
                  {getFieldDecorator('date2', {
                    initialValue: moment('2019-08-08'),
                    rules: [
                      { required: true, message: 'Please enter hours of advance notice' }
                    ]
                  })(
                    <DateTimePicker onChange={(date) => console.log(date)} />
                  )}
                </FormItem>
              </Col>
            </Row> */}
            <Skeleton loading={loading} active>
              <Form>
                <Panel title='Emergency Policy'>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Advance Notice (Client)' {...formItemLayout}>
                        {getFieldDecorator('emergency_notice', {
                          initialValue: settings.emergency_notice,
                          rules: [
                            { required: true, message: 'Please enter hours of advance notice (client).' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='hours' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Max Hours Charge' {...formItemLayout}>
                        {getFieldDecorator('emergency_charge_hour', {
                          initialValue: settings.emergency_charge_hour,
                          rules: [
                            { required: true, message: 'Please enter charge hours.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='hours' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Advance Notice (Employee)' {...formItemLayout}>
                        {getFieldDecorator('emergency_notice_emp', {
                          initialValue: settings.emergency_notice_emp,
                          rules: [
                            { required: true, message: 'Please enter hours of advance notice (employee).' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='hours' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
                <Panel title='Job Cancellation Policy'>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Advance Notice' {...formItemLayout}>
                        {getFieldDecorator('cancellation_notice', {
                          initialValue: settings.cancellation_notice,
                          rules: [
                            { required: true, message: 'Please enter hours of advance notice.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='hours' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Max Penalty Hours' {...formItemLayout}>
                        {getFieldDecorator('cancellation_charge_hour', {
                          initialValue: settings.cancellation_charge_hour,
                          rules: [
                            { required: true, message: 'Please enter charge hours.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='hours' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
                {/* <Panel title='Certificate Expiry Period'>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Police Check Length' {...formItemLayout}>
                        {getFieldDecorator('cert_police_check', {
                          initialValue: settings.cert_police_check,
                          rules: [
                            { required: true, message: 'Please enter Police Check Length' }
                          ]
                        })(
                          <Input suffix='months' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='First Aid Length' {...formItemLayout}>
                        {getFieldDecorator('cert_first_aid', {
                          initialValue: settings.cert_first_aid,
                          rules: [
                            { required: true, message: 'Please enter First Aid Length' }
                          ]
                        })(
                          <Input suffix='months' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='WWCC Length' {...formItemLayout}>
                        {getFieldDecorator('cert_wwc', {
                          initialValue: settings.cert_wwc,
                          rules: [
                            { required: true, message: 'Please enter WWC Length' }
                          ]
                        })(
                          <Input suffix='months' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='DWES Length' {...formItemLayout}>
                        {getFieldDecorator('cert_dwes', {
                          initialValue: settings.cert_dwes,
                          rules: [
                            { required: true, message: 'Please enter DWES Length' }
                          ]
                        })(
                          <Input suffix='months' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Flu Vaccination Length' {...formItemLayout}>
                        {getFieldDecorator('cert_flu_vaccination', {
                          initialValue: settings.cert_flu_vaccination,
                          rules: [
                            { required: true, message: 'Please enter flu vaccination length' }
                          ]
                        })(
                          <Input suffix='months' />
                        )}
                      </FormItem>
                    </Col>

                  </Row>
                </Panel> */}
                <Panel title='Accounting Ref'>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Client' {...formItemLayout}>
                        {getFieldDecorator('client_acc_ref', {
                          initialValue: settings.client_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter client acc ref' }
                          ]
                        })(
                          <Input prefix='R' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Employee' {...formItemLayout}>
                        {getFieldDecorator('employee_acc_ref', {
                          initialValue: settings.employee_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter employee acc ref' }
                          ]
                        })(
                          <Input prefix='E' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Funder' {...formItemLayout}>
                        {getFieldDecorator('funder_acc_ref', {
                          initialValue: settings.funder_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter funder acc ref' }
                          ]
                        })(
                          <Input prefix='F' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>

                <Panel title='Timesheet Settings'>
                  <Row>
                    <Col lg={12}>
                      <FormItem
                        label='Location Radius'
                        extra='The threshold meter that identify whether submit location is within the range of client place. Set to 0 to disable (always inside location).'
                        {...formItemLayout}
                      >
                        {getFieldDecorator('app_signoff_loc_radius', {
                          initialValue: settings.app_signoff_loc_radius,
                          rules: [
                            { required: true, message: 'Please enter location radius.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='meter(s)' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem
                        label='In-time Submit Timeframe'
                        extra='The timeframe in minutes that identify whether the timesheet submit time is within the timeframe of job end date. Set to 0 to disable (always sign on time).'
                        {...formItemLayout}
                      >
                        {getFieldDecorator('app_signoff_intime_submit_timeframe', {
                          initialValue: settings.app_signoff_intime_submit_timeframe,
                          rules: [
                            { required: true, message: 'Please enter in-time submit timeframe.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input prefix='+/-' suffix='min(s)' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem
                        label='Submit Enabled Timeframe'
                        extra='The timeframe in minutes that enable timesheet submit button and allow SW to submit timesheet. Any values will make the submit button visible only after job start date. Set to 0 to disable. (submit button always visible even before job start date)'
                        {...formItemLayout}
                      >
                        {getFieldDecorator('app_signoff_timeframe', {
                          initialValue: settings.app_signoff_timeframe,
                          rules: [
                            { required: true, message: 'Please enter timesheet enabled timeframe.' },
                            { validator: this.validateDigits }
                          ]
                        })(
                          <Input suffix='min(s) after job end time' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
              </Form>

            </Skeleton>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingOther))

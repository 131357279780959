import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, employeeService, reportService } from '../../../services'
import { exportFile, formatter, validator } from '../../../util'
import { build } from '../../../config'

// UI
import { Button, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { settingOtherService } from '../../../services/setting'
import { Input } from 'antd';
const { Item: FormItem } = Form
const Option = Select.Option

const pageSize = 30
const jobUrl = build === 'prod' ? 'https://flexi-support.pumpkinit.com.au/jobs/single/' : 'https://roster-stg.pumpkinit.com.au/jobs/single/'
const baseJobUrl = build === 'prod' ? 'https://flexi-support.pumpkinit.com.au/jobs/recurring/' : 'https://roster-stg.pumpkinit.com.au/jobs/recurring/'

export class ReportClashJobV2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      loadingEmployee: false,
      loadingExport: false,
      loadingList: false,
      isLoaded: false,
      isCompleteLoading: false,
      list: { list: [], total: 0 },
      currentPage: 1,
      employeeList: [],
      filter: {},
      settings: {},
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchList({ currentPage })
    this.fetchEmployeeList()
    this.fetchOtherSettings()
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { employeeList, list, loading, loadingExport, loadingList, currentPage, settings } = this.state
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    const columns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ job_start_date }) => formatter.toStandardDate(job_start_date)
      },

      {
        title: 'Job End',
        width: 2,
        render: ({ job_end_date }) => formatter.toStandardDate(job_end_date)
      },

      {
        title: 'Employee',
        width: 3,
        render: ({ employee_id, employee_fullname }) => <div><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
      },

      {
        title: 'Client',
        width: 3,
        render: ({ client_id, client_fullname }) => <div><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
      },

      {
        title: 'Tasks',
        width: 6,
        render: ({ tasks }) => <div style={{ fontSize: 10 }}>{tasks}</div>
      },

      {
        title: 'Funder',
        width: 2,
        render: ({ funder_id, funder_fullname }) => <div><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
      },

      {
        title: 'Job/Base Job',
        width: 2,
        render: ({ id, base_job_id }) => <div className='action-buttons-s'>
          { id
            ? <Tooltip mouseLeaveDelay={0} title='View Job'>
              <a href={`/jobs/single/${id}`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
            : null }
          { base_job_id
            ? <Tooltip mouseLeaveDelay={0} title='View Base Job'>
              <a href={`/jobs/recurring/${base_job_id}`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
            : null }
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Clashed Shifts`}>
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }
          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('clash_back_email', {
                          initialValue: settings.clash_back_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <div className='admin-list'>
            <Row gutter={8}>
              <Col lg={10}>
                <FormItem style={{marginLeft: '10px'}}>
                  {getFieldDecorator('filter_employee_id', {
                    initialValue: undefined
                  })(
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%'}}
                      placeholder='Select Employee'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      filterOption={(input, option) => this.findEmployees(input, option)}
                      onChange={(e) => this.handleChangeEmployee(e)}
                      disabled={loadingList}
                      loading={loadingList}
                    >
                      { employeeList.map((emp, idx) => {
                          return <Option key={`emp${idx}`} value={emp.id}>
                            <span>{emp.first_name} {emp.last_name}</span>
                          </Option>
                        }) }
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Skeleton loading={loading}>
              <Spin spinning={loadingList}>
                <div className='report-title'>{`Clashed Shifts `} <span className='report-tag'>{list.total}</span></div>
                <List cols={columns} rows={list.list} />
                <Pager
                  size={pageSize}
                  total={list.total}
                  totalText={`Total ${list.total} shifts`}
                  current={currentPage}
                  onChange={(e) => this.onChangePage(e)}
                  style={{ marginTop: '0px' }}
                />
              </Spin>
            </Skeleton>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  async fetchList ({ currentPage }) {
    if (this.hasAccess('readClashReport')) {
      const { filter } = this.state

      // if (isLoaded) {
      //   this.setState({ loadingList: true })
      // } else {
      //   this.setState({ loading: true })
      // }

      this.setState({ loading: true, loadingList: true })

      try {
        const list = await reportService.getListByPage(ExportType.REPORT.CLASHED_SHIFTS_V2, currentPage, pageSize, filter)

        if (list && validator.isArray(list.list)) {
          this.setState({ list, loading: false, loadingList: false, isLoaded: true })
        } else {
          notify.error('Unable to get Clashed Shift Report', 'Please try again later.')
          this.setState({ loading: false, loadingList: false, isLoaded: true })
        }
      } catch (e) {
        notify.error('Unable to get Clashed Shift Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }

      this.toTop()
    }
  }

  async fetchEmployeeList () {
    this.setState({ loadingEmployee: true })

    const employeeList = await employeeService.getAllEmployeeActive()

    if (validator.isNotEmptyArray(employeeList)) {
      this.setState({ employeeList, loadingEmployee: false })
    } else {
      this.setState({ loadingEmployee: false })
    }
  }

  findEmployees = (input, option) => {
    const empArr = option.props.children.props.children
    const e = `${empArr[0]} ${empArr[2]}`
    return e.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleChangeEmployee = async (empId = null) => {
    const { filter } = this.state

    if (empId) {
      filter.employee_id = empId
    } else {
      delete filter.employee_id
    }

    this.setState({ filter, currentPage: 1, isLoaded: false }, () => {
      this.fetchList({ currentPage: 1 })
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readClashReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      try {
        const r = await exportFile.fetchReportExport(ExportType.REPORT.CLASHED_SHIFTS_V2)
        this.setState({ loadingExport: false })
      } catch (e) {
        notify.error('Unable to get Clashed Shift Report', 'Unable to export the report. Please try again later')
        this.setState({ loadingExport: false })
      }
    }, 1000)
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  onChangePage = async (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchList({ currentPage: page })
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

export default (Form.create()(ReportClashJobV2))

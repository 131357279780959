import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jvpJobService, jvpLeaveService } from '../../../services'
import { fetchEmployees } from '../../../states/actions/employee'
import { common, exportFile, formatter, validator } from '../../../util'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { jobURL, timezone } from '../../../config'
import { ExportType } from '../../../constants'

// UI
import { Loading, List, Page } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

moment.tz.setDefault(timezone)

const view = 'Employee Leave'

class EmployeeImpactedJobJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      leaveInfo: {},
      list: [],
      total: 0,
      isNotify: null,
      jobCheckList: [],
      loading: false,
      loadingInfo: false,
      loadingSave: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    if (prevState.isNotify === null) {
      let isNotify = prevState.isNotify

      if (location && location.search) {
        const queries = common.getQueryStringSearchParams(location.search)

        if (queries && queries['notify'] !== undefined) {
          isNotify = true
        } else {
          isNotify = false
        }
      }

      return { ...prevState, isNotify }
    }

    return { ...prevState }
  }

  componentDidMount () {
    this.fetchLeaveInfo()
    this.fetchLeaveJobs()
  }

  render () {
    const { form } = this.props
    const { isNotify, list, leaveInfo, loading, loadingInfo, loadingSave } = this.state


    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >SO</div> }
        </div>
      },
      {
        title: 'Client',
        width: 5,
        render: ({ is_frontend_unhide, client_fullname, client_id, client_leave_id, client_leave_start_date, client_leave_end_date, client_active, client_leave_is_ufn }) => {
          const isClientLeave = !!client_leave_id
          const clientLeaveText = isClientLeave ? `Leave ${formatter.toShortDate(client_leave_start_date)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end_date)}` : null
          const isHide = client_leave_id && !is_frontend_unhide

          return isClientLeave
            ? (<div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                <a className={`${!client_active || isHide ? 'client-label-dim' : ''}`} href={`/clients/${client_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>
                <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />&nbsp;{`Leave ${formatter.toShortDate(client_leave_start_date)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end_date)}`}</div>
              </div>)
            : (<div>
                <a className={`${!client_active || isHide ? 'client-label-dim' : ''}`} href={`/clients/${client_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>
              </div>)
        }
      },
      {
        title: 'Day',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, string_job_start_day }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.capitalize(string_job_start_day)}</div>
        }
      },
      {
        title: 'Start Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_start_date)}</div>
        }
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortDate(job_end_date)}</div>
        }
      },
      {
        title: 'Start Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_start_date)}</div>
        }
      },
      {
        title: 'End Time',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => {
          const isHide = (client_leave_id && !is_frontend_unhide)
          return <div className={`${isHide ? 'client-label-dim-unhover' : ''}`}>{formatter.toShortTime(job_end_date)}</div>
        }
      },
      {
        title: 'Status',
        width: 2,
        render: ({ id, status_leave, is_cancel }) => {
          const isCancel = !!is_cancel
          const isKeeped = !!(status_leave === 'approved')

          return (
            <div style={{ color: '#ccc', fontSize: '12pt' }}>
              { isCancel
                ? <div>
                  <Tooltip mouseLeaveDelay={0} title='Cancelled'>
                    <span style={{ color: '#ff5b5b' }}>
                      <Icon type='close-circle' theme='filled' />
                    </span>
                  </Tooltip>
                </div>
                : isKeeped
                  ? <div>
                    <Tooltip mouseLeaveDelay={0} title='Kept'>
                      <span style={{ color: '#4fbc85' }}>
                        <Icon type='check-circle' theme='filled' />
                      </span>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='To Review?'>
                      <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.updateJob(id, 'review')}>
                        <Icon type='exclamation-circle' theme='filled' />
                      </span>
                    </Tooltip>
                  </div>
                  : <div>
                    <Tooltip mouseLeaveDelay={0} title='To Keep?'>
                      <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.updateJob(id, 'approved')}>
                        <Icon type='check-circle' theme='filled' />
                      </span>
                    </Tooltip>
                    &nbsp;&nbsp;<Tooltip mouseLeaveDelay={0} title='To Review'>
                      <span style={{ color: '#f2b018' }}>
                        <Icon type='exclamation-circle' theme='filled' />
                      </span>
                    </Tooltip>
                  </div>}
            </div>
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => (
          <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
            <Tooltip mouseLeaveDelay={0} title='View'>
              <a href={`${jobURL}/single/${id}/info`} rel='noopener noreferrer' target='_blank'>
                <Icon type='eye' />
              </a>
            </Tooltip>
          </div>
        )
      }
    ]

    return (
      <Page.Body className='employee-impacted'>
        <Page.Left>
          <Page.Menu
            title='Employee'
            backLink={loadingInfo ? null : isNotify ? '/reports/employee-leave' : `/employees/${leaveInfo.member_id}/leaves`}
            panel={<div style={{ textAlign: 'center', padding: '20px 0' }}
          >
            <img alt='' src={process.env.PUBLIC_URL + '/img/' + (leaveInfo.member_gender && leaveInfo.member_gender.toLowerCase() === 'male' ? 'man.png' : 'woman.png')} className='avatar' />
            <div className='employee'>
              { leaveInfo.member_fullname }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`Jobs Affected Under the Leave ${loadingInfo ? '' : `(From ${formatter.toShortDate(leaveInfo.start_date)} to ${formatter.toShortDate(leaveInfo.end_date)})`}`}>
            <div className='btn' onClick={() => this.handleExport()}>
              Export
            </div>
          </Page.Header>
            <Loading loading={loadingSave} blur>
              <Skeleton loading={loading} active>
                <List cols={columns} rows={list} />
              </Skeleton>
            </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchLeaveInfo = async () => {
    const leaveId = this.getLeaveId()
    const typeId = this.getEmployeeId()

    this.setState({ loadingInfo: true })

    const info = await jvpLeaveService.getInfo(leaveId, 'employee', typeId)

    this.setState({ leaveInfo: info && info.id ? info : {}, loadingInfo: false })
  }

  fetchLeaveJobs = async () => {
    const leaveId = this.getLeaveId()
    const typeId = this.getEmployeeId()

    this.setState({ loading: true })

    const jobs = await jvpLeaveService.listImpactedJobs(leaveId, 'employee', typeId)

    this.setState({ list: validator.isArray(jobs) ? jobs : [], total: validator.isArray(jobs) ? jobs.length : 0, loading: false })
  }

  updateJob = async (id, value) => {
    const body = {
      id: id,
      status_leave: value,
      view
    }

    this.setState({ loadingSave: true }, async () => {
      const { list } = this.state
      try {
        const r = await jvpJobService.saveList(body)

        if (r && (r.id || r.ts)) {
          // this.showUpdateJobSuccess()
          const jobIndex = list.findIndex(e => e.id === id)

          if (jobIndex > -1) {
            list[jobIndex].status_leave = value
          }
        } else {
          this.showUpdateJobError()
        }
      } catch (e) {
        this.showUpdateJobError()
      }

      this.setState({ loadingSave: false, list })
    })
  }

  showUpdateJobSuccess = () => {
    notify.success('Updated successfully', 'Job status updated successfully.')
  }

  showUpdateJobError = () => {
    notify.error('Unable to Update', 'Unable to update the job. Please try again later.')
  }

  showExportJobsError = () => {
    notify.error('Unable to export', 'Unable to get affected jobs export successfully. Please try again later.')
  }

  handleExport = async () => {
    const { loading, loadingSave } = this.state
    if (loading || loadingSave) return

    const leaveId = this.getLeaveId()
    const typeId = this.getEmployeeId()

    try {
      this.setState({ loadingSave: true })
      const r = await exportFile.fetchListExport(ExportType.GENERAL.EXPORT_EMPLOYEE_JOB_TO_ACTION, { leave_id: leaveId, employee_id: typeId })

      setTimeout(() => {
        this.setState({ loadingSave: false })
      }, 3000)
    } catch (e) {
      this.showExportJobsError()
      this.setState({ loadingSave: false })
    }
  }

  getLeaveId = () => {
    const { match } = this.props
    const { params } = match
    const { lid } = params

    return lid
  }

  getEmployeeId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    return id
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeImpactedJobJvp))

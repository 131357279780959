export const FETCH_PAST_JOBS = 'FETCH_PAST_JOBS'
export const FETCHING_PAST_JOBS = 'FETCHING_PAST_JOBS'
export const PAST_JOBS_FETCHED = 'PAST_JOBS_FETCHED'

export const fetchPastJobs = (params) => {
  return Object.assign({
    type: FETCH_PAST_JOBS
  }, params)
}

export const fetchingPastJobs = (loading) => {
  return {
    type: FETCHING_PAST_JOBS,
    loading
  }
}

export const pastJobsFetched = (jobs) => {
  return {
    type: PAST_JOBS_FETCHED,
    jobs
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fvpFundManagerService, fvpFunderService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import Moment from 'moment-timezone'
import { formatter, log, validator } from '../../../util'

// UI
import { Button, Loading, List, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FunderFundManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      list: [],
      fundManagerList: [],
      loading: false,
      loadingForm: false,
      selectedManagers: [],
      showFundManagerModal: false,
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchFundManager(currentPage)
    this.fetchFundManagerList()
  }

  render () {
    const { form } = this.props
    const { currentPage, loading, loadingForm, list, total, showFundManagerModal, fundManagerList, selectedManagers } = this.state
    const { getFieldDecorator } = form

    const listColumns = [
      {
        title: 'Name',
        width: 18,
        render: ({ funder_manager_fullname }) => <div className='name-label'>{funder_manager_fullname}</div>
      },
      {
        title: 'Created At',
        width: 4,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      }
    ]

    return (
      <div>
        <Loading loading={loading} blur>
          <div className='task-header'>
            <div className='btn' onClick={() => this.showModal()}>
              Add Manager
            </div>
          </div>

          <div className='task-list'>
            <Skeleton loading={loading} active>

              <List cols={listColumns} rows={list} />

            </Skeleton>
          </div>

          <Pager
            size={total}
            total={total}
            totalText={`Total ${total} fund managers`}
            current={currentPage}
            onChange={(e) => this.changePage(e)}
            style={{ marginTop: '15px' }}
          />
        </Loading>

        <Modal
          title="Update Fund Manager"
          style={{ top: 20 }}
          visible={showFundManagerModal}
          maskClosable={false}
          confirmLoading={loadingForm}
          // onOk={() => this.handleSaveFundManager()}
          onCancel={() => this.closeFundManagerModal()}
          footer={[
            <Button key='close' feedback={loadingForm} ghost onClick={() => this.closeFundManagerModal()}>Cancel</Button>,
            <Button key='save' feedback={loadingForm} onClick={() => this.handleSaveFundManager()}>Update</Button>,
          ]}
        >
          <Form layout='vertical'>
            { showFundManagerModal
              ? <FormItem label='Fund Manager'>
                {getFieldDecorator('selected_fund_manager', {
                  initialValue: selectedManagers,
                  rules: [
                    { required: true, message: 'Please select a fund manager' }
                  ]
                })(
                  <Select placeholder='Please select one/multiple fund manager(s)'
                    mode='multiple'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      fundManagerList.map((manager, idx) => {
                        return <Option key={`fm${idx}`} value={manager.id}>{manager.fullname}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
              : null }
          </Form>
        </Modal>
      </div>
    )
  }

  fetchFundManager = async (startPage = null) => {
    this.setState({ loading: true })
    const { funderId, form } = this.props
    const { currentPage } = this.state

    const list = await fvpFunderService.getFunderManager(funderId)

    if (validator.isNotEmptyArray(list)) {
      const selectedManagers = list.reduce((a, value) => (a.push(parseInt(value.fund_manager_id)), a), [])

      form.setFieldsValue({selected_fund_manager: selectedManagers})
      this.setState({ list, total: list.length, loading: false, selectedManagers })
    } else {
      this.setState({ list: [], total: 0, loading: false, selectedManagers: [] })
    }
  }

  fetchFundManagerList = async () => {
    const filter = {}
    filter.active = { condition: '=', value: true }

    const m = await fvpFundManagerService.listByPage(1, 0, filter)

    if (m && validator.isNotEmptyArray(m.list)) {
      this.setState({ fundManagerList: m.list })
    }
  }

  changePage = async (currentPage) => {
    this.fetchFundManager(currentPage)
  }

  handleSaveFundManager = async () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['selected_fund_manager'], async (errors, values) => {
      if (!errors) {
        try {
          const { form, funderId } = this.props
          const { fundManagerList } = this.state
          const { getFieldValue } = form

          this.setState({ loadingForm: true })
          const selectIds = getFieldValue('selected_fund_manager')

          const data = {
            selectIds
          }

          const r = await fvpFunderService.saveFunderManager(funderId, data)

          if (r && r.id) {
            if (r.changeText) {
              log.updateFunderFundManager(funderId, r.changeText)
            }

            await this.fetchFundManager()

            notify.success('Saved successfully', 'Fund Manager saved successfully.')

            this.props.setRefreshActivityLog(true)
            this.props.onUpdate()
            this.setState({ loadingForm: false, showFundManagerModal: false })
          } else {
            notify.error('Unable to save successfully', 'Unable to save fund manager successfully. Please try again later.')
            this.setState({ loadingForm: false})
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save fund manager successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  showModal = () => {
    const { showFundManagerModal } = this.state
    this.setState({ showFundManagerModal: !showFundManagerModal })
  }

  closeFundManagerModal = () => {
    const { form } = this.props
    const { setFieldsValue } = form

    this.setState({ showFundManagerModal: false })
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FunderFundManager))

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_RECURRING_JOBS, FETCHING_RECURRING_JOBS, RECURRING_JOBS_FETCHED } from '../actions/recurringJob'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText, jobStatus }) {
  try {
    yield put({ type: FETCHING_RECURRING_JOBS, loading })
    if (jobStatus === 'active') {
      const jobs = yield baseJobService.listByPage(currentPage, pageSize, filter, sort, searchText, jobStatus)
      yield put({ type: RECURRING_JOBS_FETCHED, jobs, loading: false })
    } else {
      const jobs = yield baseJobService.getEndedJob(currentPage, pageSize, filter, sort, searchText, jobStatus)
      yield put({ type: RECURRING_JOBS_FETCHED, jobs, loading: false })
    }
  } catch (e) {
    yield put({ type: FETCHING_RECURRING_JOBS, loading: false })
    console.error(e)
  }
}

function * watchRecurringJob () {
  yield takeEvery(FETCH_RECURRING_JOBS, fetchJobs)
}

export default watchRecurringJob

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jobService, fvpJobsService, clientService, authService } from '../../../services'
import formatter from '../../../util/formatter'
import { fetchingClients } from '../../../states/actions/client'
import Moment from 'moment-timezone'
import { log } from '../../../util'
import { apiHostname } from '../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import IndividualListPage from './IndividualPage'
import RecurringListPage from './RecurringPage'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker
const TabPane = Tabs.TabPane

export class ClientJobs extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { clientId, clientName, form, isClientActive, isClientHasJobReminder, clientEmail } = this.props

    return (
      <div>
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
          <TabPane tab="Single Jobs" key="1">
            <IndividualListPage
              clientId={clientId}
              clientName={clientName}
              history={this.props.history}
              isClientActive={isClientActive}
              isClientHasJobReminder={isClientHasJobReminder}
              clientEmail={clientEmail}
            />
          </TabPane>
          <TabPane tab="Recurring Base" key="2">
            <RecurringListPage
              clientId={clientId}
              clientName={clientName}
              history={this.props.history}
              isClientActive={isClientActive}
              isClientHasJobReminder={isClientHasJobReminder}
              clientEmail={clientEmail}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientJobs))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import FeedbackCategory from './Category'
import FeedbackStatus from './Status'
import FeedbackSubCategory from './SubCategory'
// import FeedbackType from './Type'
import './styles.css'

const TabPane = Tabs.TabPane
const settingTitle = 'Feedback'

export class SettingFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '1',
      addCategory: false,
      addStatus: false,
      addSubCategory: false,
      // addType: false,
      currentPage: 1,
    }
  }

  addCategory = () => {
    this.setState({ addCategory: true })
  }

  addStatus = () => {
    this.setState({ addStatus: true })
  }

  addSubCategory = () => {
    this.setState({ addSubCategory: true })
  }

  // addType = () => {
  //   this.setState({ addType: true })
  // }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render() {
    // const { activeTab, addCategory, addStatus, addSubCategory, addType } = this.state
    const { activeTab, addCategory, addStatus, addSubCategory } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
            <TabPane tab='Categories' key='1'>
              <div className='header-action'>
                {this.hasAccess('createFeedbackCategory') ? <div className='btn' onClick={() => this.addCategory()}>
                  Add Category
                </div> : null}
              </div>

              <FeedbackCategory onAdd={addCategory} onComplete={() => this.setState({ addCategory: false })} />
            </TabPane>

            <TabPane tab='Sub-Categories' key='2'>
              <div className='header-action'>
                {this.hasAccess('createFeedbackSubCategory') ? <div className='btn' onClick={() => this.addSubCategory()}>
                  Add Sub-Category
                </div> : null}
              </div>

              <FeedbackSubCategory onAdd={addSubCategory} onComplete={() => this.setState({ addSubCategory: false })} />
            </TabPane>

            <TabPane tab='Statuses' key='3'>
              <div className='header-action'>
                {this.hasAccess('createFeedbackStatus') ? <div className='btn' onClick={() => this.addStatus()}>
                  Add Status
                </div> : null}
              </div>

              <FeedbackStatus onAdd={addStatus} onComplete={() => this.setState({ addStatus: false })} />
            </TabPane>

            {/* <TabPane tab='Types' key='4'>
              <div className='header-action'>
                {this.hasAccess('createFeedbackType') ? <div className='btn' onClick={() => this.addType()}>
                  Add Type
                </div> : null}
              </div>

              <FeedbackType onAdd={addType} onComplete={() => this.setState({ addType: false })} />
            </TabPane> */}
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFeedback))

import qs from 'qs'

export default {
  parse (str) {
    if (str) {
      return qs.parse(str.substring(1))
    }

    return {}
  },
  stringify (obj) {
    if (obj) {
      return qs.stringify(obj, { addQueryPrefix: true })
    }

    return obj
  }
}

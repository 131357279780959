
import React, { Component } from 'react'
import { taskService } from '../../../services'
import { log } from '../../../util'
import { setRefreshActivityLog } from '../../../states/actions'
import { connect } from 'react-redux'

// UI
import { Loading, List, Button, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const pageSize = 10

export class ClientTask extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      item: {},
      tasks: [],
      loading: false,
      clientId: '',
      modal: {
        item: { },
        show: false,
        isEdit: false
      }
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchTasks(currentPage)
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { tasks, loading, modal, currentPage, total } = this.state
    const { item } = modal

    const columns = [
      {
        title: 'Day Time',
        width: 8,
        key: 'day_time'
      },
      {
        title: 'Details',
        width: 12,
        key: 'details'
      },
      {
        title: 'Action',
        width: 2,
        render: ({ id, day_time, details }) => <div className='action-buttons'>
          {/* <Tooltip mouseLeaveDelay={0}  title='Edit'> <div onClick={() => this.handleEditTask(id, day_time, details)}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0}  title='Delete'>
            <Popconfirm
              title='Confirm to delete this?'
              onConfirm={() => this.handleDelete(id, day_time, details)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip> */}
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        {/* <div className='task-header'>
          <div className='btn' onClick={() => this.showModal()}>
            Add Task
          </div>
        </div> */}

        <div className='task-list'>
          <Skeleton loading={loading} active>

            <List cols={columns} rows={tasks} />

            <SideModal
              title='Tasks'
              showModal={modal.show}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Day Time'>
                  {getFieldDecorator('day_time', {
                    initialValue: item.day_time,
                    rules: [
                      { min: 2, message: 'Day Time must be between 2 and 128 characters' },
                      { max: 128, message: 'Day Time must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter day time' },
                      { whitespace: true, message: 'Please enter day time' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
                <FormItem label='Detail'>
                  {getFieldDecorator('details', {
                    initialValue: item.details,
                    rules: [
                      { min: 2, message: 'Detail must be more than 2 characters' },
                      { required: true, message: 'Please enter detail' },
                      { whitespace: true, message: 'Please enter detail' }
                    ]
                  })(
                    <TextArea rows={7} />
                  )}
                </FormItem>

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} tasks`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchTasks = async (startPage = null) => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.client_id = { condition: '=', value: clientId }

    const { list: tasks, total } = await taskService.listByPage(page, pageSize, filter)
    this.setState({ tasks, total, loading: false, currentPage: page, filter })
  }

  changePage = async (currentPage) => {
    this.fetchTasks(currentPage)
  }

  handleEditTask = (id, dayTime, details, status) => {
    const { modal } = this.state
    modal.isEdit = true
    modal.item = {
      id,
      day_time: dayTime,
      details
    }
    this.setState({ taskId: id, modal })
    this.showModal()
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { modal } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        const { clientId } = this.props
        values.client_id = clientId

        if (!modal.isEdit) {
          const response = await taskService.add(values)
          this.setState({ loading: false })

          if (response.id) {
            log.addClientTask(clientId, `'${values.details}' on '${values.day_time}'`)
            notify.success('Saved successfully', 'Tasks saved successfully')
            this.fetchTasks()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', 'Unable to save Tasks successfully. Please try again later.')
          }
        } else {
          const { taskId } = this.state
          const response = await taskService.save(taskId, values)
          this.setState({ loading: false })

          if (response.id) {
            log.updateClientTask(clientId, modal.item, values)
            notify.success('Saved successfully', 'Tasks saved successfully')
            this.fetchTasks()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', 'Unable to save Tasks successfully. Please try again later.')
          }
        }

        this.props.setRefreshActivityLog(true)
      }
    })
  }

  handleDelete = async (taskId, dayTime, details) => {
    const { clientId } = this.props
    const res = await taskService.remove(taskId)

    if (res) {
      log.deleteClientTask(clientId, `'${details}' on '${dayTime}'`)
      notify.success('Task Deleted', 'Tasks deleted successfully')
      this.fetchTasks()
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete Tasks successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { resetFields } = form
    const { modal } = this.state

    resetFields()
    modal.item = { }
    modal.isEdit = false
    modal.show = false
    this.setState({ modal })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientTask))

import { FETCHING_FEEDBACKS, FEEDBACKS_FETCHED, REFRESH_FEEDBACK_ACTIONS } from '../actions/feedback'

const INITIAL_STATE = {
  feedbacks: { list: [], total: 0 },
  loading: true,
  shouldRefreshFeedbackActions: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_FEEDBACKS:
      return handleFetchingFeedbacks(state, action)
    case FEEDBACKS_FETCHED:
      return handleFeedbacksFetched(state, action)
    case REFRESH_FEEDBACK_ACTIONS:
      return handleRefreshFeedbackActions(state, action)
    default:
      return state
  }
}

function handleFetchingFeedbacks(state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleFeedbacksFetched(state, action) {
  const { feedbacks, loading } = action
  return { ...state, feedbacks, loading }
}

function handleRefreshFeedbackActions(state, action) {
  const { done } = action
  return { ...state, shouldRefreshFeedbackActions: done }
}

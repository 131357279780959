import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jobService, fvpJobsService, employeeService, authService } from '../../../../services'
import { ExportType } from '../../../../constants'
import { fetchingClients } from '../../../../states/actions/client'
import Moment from 'moment-timezone'
import { exportFile, formatter, log, validator } from '../../../../util'
import { apiHostname } from '../../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker

export class EmployeeJobsIndividual extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: 'future',
      searchText: '',
      total: 0,
      jobList: [],
      loading: false,
      loadingExport: false,
      employeeId: '',
      showReminderDate: false,
      showExportDate: false
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.refreshJob()
  }

  filterJob (e) {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchEmployeeJobs({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          const w = words[i]

          if (w) {
            filter.$and.push({
              $or: [
                { first_name: { condition: 'ilike', value: `%${w}%` } },
                { last_name: { condition: 'ilike', value: `%${w}%` } },
                { emp_firstname: { condition: 'ilike', value: `%${w}%` } },
                { emp_lastname: { condition: 'ilike', value: `%${w}%` } },
                { fund_fullname: { condition: 'ilike', value: `%${w}%` } },
                { suburb: { condition: 'ilike', value: `%${w}%` } },
                { job_start_day: { condition: 'ilike', value: `%${w}%` } },
                { tasks: { condition: 'ilike', value: `%${w}%` } },
                { string_start_date: { condition: 'ilike', value: `%${w}%` } }
              ]
            })
          }
        }

        if (value && value.trim() !== '') {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${value}%` } },
              { last_name: { condition: 'ilike', value: `%${value}%` } },
              { client_name: { condition: 'ilike', value: `%${value}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${value}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${value}%` } },
              { emp_name: { condition: 'ilike', value: `%${value}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${value}%` } },
              { suburb: { condition: 'ilike', value: `%${value}%` } },
              { job_start_day: { condition: 'ilike', value: `%${value}%` } },
              { tasks: { condition: 'ilike', value: `%${value}%` } },
              { string_start_date: { condition: 'ilike', value: `%${value}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchEmployeeJobs({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  sendJobList(d1, d2) {
    const { employeeId, data } = this.props
    this.setState({ showReminderDate: false })

    if (validator.isNotEmptyArray(d1)) {
      confirm({
        title: `Send ${data.first_name} a reminder for jobs from ${formatter.toShortDate(d1[0])} to ${formatter.toShortDate(d1[1])}?`,
        content: 'Press Ok to continue, Cancel to return',
        async onOk () {
          try {
            const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_EMPLOYEE_LIST, {id: employeeId, from: d1[0], to: d1[1], isSendEmail: true})

            if (r && r.id) {
              notify.success('Send successfully', 'Jobs sent successfully.')
            }
          } catch (e) {
            console.log('emp send job error', e)
            notify.error('Unable to send successfully', 'Unable to send reminder successfully. Please try again later.')
          }
        }
      })
    }
  }

  async exportJobList (d1, d2) {
    const { employeeId } = this.props
    const { filterParam } = this.state
    this.setState({ showExportDate: false })

    if (validator.isNotEmptyArray(d1)) {
      this.setState({ loadingExport: true })
      const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_EMPLOYEE_LIST, {id: employeeId, from: d1[0], to: d1[1], isCancelJob: filterParam === 'cancel' ? true : false})

      setTimeout(() => {
        this.setState({ loadingExport: false })
      }, 2000)
    }
  }

  handleJobReminder = async (value) => {
    const { employeeId } = this.props
    const values = { job_reminder: value }
    const response = await employeeService.save(employeeId, values)

    if (response.id) {
      log.updateEmployeeExtra(response.id, `Job Reminder from "${!value}" to "${value}"`)
      notify.success('Updated successfully', 'Job reminder updated successfully.')
    }
  }

  render () {
    const { form, data } = this.props
    const { currentPage, loading, loadingExport, jobList, showReminderDate, showExportDate, total } = this.state

    const { getFieldDecorator } = form

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 15 },
      wrapperCol: { sm: 14, md: 14, lg: 9 }
    }

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job, job_survey_feedback }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >SO</div> }
          { job_survey_feedback ? <Tooltip title='Job Feedback'><div className='job-indicator' style={{ backgroundColor: '#f5a142' }}>F</div></Tooltip> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >F</div> }
        </div>
      },
      {
        title: 'Date',
        width: 2,
        render: ({ job_start_date, holiday_date, holiday_name }) => holiday_date ? <div>{formatter.toShortDate(job_start_date)} <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> </div> : formatter.toShortDate(job_start_date)
      },
      {
        title: 'Day',
        width: 2,
        render: ({ job_start_date }) => formatter.toDay(job_start_date)
      },
      {
        title: 'Clients',
        width: 4,
        render: ({ first_name, last_name, client_id, client_leave_id, client_leave_start, client_leave_end, client_active, client_leave_until_further_notice: isUFN }) => client_leave_id ? (
          <div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
            <a className={`${!client_active ? 'client-label-dim' : ''}`} href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
            <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(client_leave_start)} - ${isUFN ? 'UFN' : formatter.toShortDate(client_leave_end)}`}</div>
          </div>
        ) : (<div>
              <a className={`${!client_active ? 'client-label-dim' : ''}`} href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
          </div>
        )
      },
      {
        title: 'Carer Skill Level Required',
        width: 2,
        render: ({ payroll }) => <div style={{ textTransform: 'capitalize' }}>{payroll}</div>
      },
      {
        title: 'Start',
        width: 2,
        render: ({ job_start_date }) => formatter.toShortTime(job_start_date)
      },
      {
        title: 'End',
        width: 2,
        render: ({ job_end_date }) => formatter.toShortTime(job_end_date)
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ is_sleepover_job, job_start_date, job_end_date, so_sum_hour_day, jcs_id, jcs_is_forced_update, jps_id }) => {
          const isCostingUpdated = (jcs_id && jcs_is_forced_update) || !!jps_id
          const costingMsg = (jcs_id && jcs_is_forced_update) && !!jps_id
            ? `Costing - Invoice / Payroll updated`
            : (jcs_id && jcs_is_forced_update)
              ? `Costing - Invoice updated`
              : !!jps_id
                ? `Costing - Payroll updated`
                : ''

          return (<span>
            {is_sleepover_job ? formatter.toDecimalS(so_sum_hour_day) : Moment.duration(Moment(job_end_date).diff(Moment(job_start_date))).asHours()}&nbsp;
            { isCostingUpdated
              ? (<Tooltip
                title={<div>{costingMsg}</div>}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
              >
                <Icon style={{fontSize: '11pt'}} type='dollar' theme='twoTone' twoToneColor='#d60b29' />
              </Tooltip>)
              : null }
          </span>)
        }
      },
      {
        title: 'Location',
        width: 2,
        // key: 'suburb'
        render: ({ suburb, clashed_client_names, clashed_employee_names }) =>
          <div>{suburb}
            {clashed_client_names || clashed_employee_names
              ? <span>
                  &nbsp;
                  <Tooltip
                    title={(
                      <div>
                        {clashed_client_names ? <div>Same address with client: {clashed_client_names}</div> : ''}
                        {clashed_employee_names ? <div>Same address with employee: {clashed_employee_names}</div> : ''}
                      </div>
                    )}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                  >
                    <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                  </Tooltip>
                </span>
              : null}
          </div>
      },
      {
        title: 'Funder',
        width: 4,
        render: ({ cf_id, fund_fullname, funder_id, contact_client_for_jobs }) => <div style={{ textTransform: 'capitalize' }}><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a> {!cf_id ? <div style={{ color: '#ff0000', fontSize: '9pt', textTransform: 'none' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Outside of client's funding period`}</div> : null }{contact_client_for_jobs ? <Tooltip title='Contact client for jobs' mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='phone' style={{ color: '#1890ffbb'}} /></Tooltip> : null}</div>
      },
      {
        title: 'Action',
        width: 1,
        render: ({ id }) => <div className='action-buttons'><Link to={`/jobs/single/${id}`}><Tooltip mouseLeaveDelay={0} title='Manage Job'> <Icon type='form' style={{ color: 'orange' }} /></Tooltip></Link>
        </div>
      }
    ]
    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              <div className='btn' onClick={() => this.refreshJob()}>
                Refresh
              </div>
              <div className='btn btn-ghost' onClick={() => this.openSendJobList()}>
                Send Job List
              </div>
              <div className='btn btn-ghost'  onClick={() => this.openDownloadJobList()}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `Download Job List` }
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col lg={14} />
            <Col lg={10}>
              <div style={{ display: showReminderDate ? 'inline' : 'none' }}>
                <RangePicker
                  format='YYYY-MM-DD'
                  open={showReminderDate}
                  onChange={(d1, d2) => this.sendJobList(d1, d2)}
                  renderExtraFooter={() => <div style={{fontWeight: 'bold'}}>SEND JOB LIST</div>}
                />
              </div>
              <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                <RangePicker
                  format='YYYY-MM-DD'
                  open={showExportDate}
                  onChange={(d1, d2) => this.exportJobList(d1, d2)}
                  renderExtraFooter={() => <div style={{fontWeight: 'bold'}}>DOWNLOAD JOB LIST</div>}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Row gutter={8}>
          <Col lg={8}>
            <Radio.Group onChange={this.filterJob} defaultValue='future' style={{ marginLeft: 20 }}>
              <Radio.Button value='future'>Future</Radio.Button>
              <Radio.Button value='previous'>Past</Radio.Button>
              <Radio.Button value='cancel'>Cancelled</Radio.Button>
            </Radio.Group>
          </Col>
          <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end', paddingRight: 20}}>
            <FormItem {...sideBySideFormItemLayout} label='Job Reminder'>
              {getFieldDecorator('job_reminder', {
                initialValue: data.job_reminder || false,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={this.handleJobReminder}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={jobList} />
          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} jobs`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />
      </Loading>
    )
  }

  refreshJob = async () => {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchEmployeeJobs({ currentPage, filter, loading, searchText, sort, filterParam: filterParam })
  }

  fetchEmployeeJobs = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { employeeId } = this.props
    const sorting = { job_start_date: -1 }
    const nowDate = Moment().startOf('day')

    const page = startPage || currentPage

    if (filterParam === 'future') {
      filter.employee_id = { condition: '=', value: employeeId }
      filter.job_start_date = { condition: '>=', value: nowDate }
      filter.is_cancel = false
      sorting.job_start_date = 1
    } else if (filterParam === 'previous') {
      filter.employee_id = { condition: '=', value: employeeId }
      filter.job_start_date = { condition: '<', value: nowDate }
      filter.is_cancel = false
    } else if (filterParam === 'cancel') {
      delete filter.job_start_date
      filter.employee_id = { condition: '=', value: employeeId }
      filter.is_cancel = true
    } else {
      filter.employee_id = { condition: '=', value: employeeId }
      filter.job_start_date = { condition: '>=', value: nowDate }
      filter.is_cancel = false
      sorting.job_start_date = 1
    }

    const { list: jobList, total } = await fvpJobsService.listByPage(page, pageSize, filter, sorting, searchText)

    this.setState({ jobList, type: filterParam, total, loading: false, currentPage: page, filter, sorting, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, type } = this.state
    this.fetchEmployeeJobs({ currentPage, filter, searchText, sort, filterParam: type })
  }

  openDownloadJobList = () => {
    const { showExportDate, loadingExport } = this.state
    if (loadingExport) return

    this.setState({ showExportDate: !showExportDate, showReminderDate: false })
  }

  openSendJobList = () => {
    const { showReminderDate } = this.state

    this.setState({ showReminderDate: !showReminderDate, showExportDate: false })
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeJobsIndividual))

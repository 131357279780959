import React, { Component } from 'react'
import moment from 'moment-timezone'
import { authService, jobService } from '../../../../services'
import { formatter, log, validator } from '../../../../util'
import { cloneDeep } from 'lodash'

import { Button, FileUpload, Loading, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../../config'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'
const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const UploadMsgWrongFormat = 'Wrong format of file selected.'
const UploadMsgNoFile = 'No file selected.'

export class AddFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jobId: '',
      currentSubCatList: [],
      currentSubCat: {},
      fileList: [],
      fileUploadedList: {},
      item: {},
      itemPrev: {},
      isEdit: false,
      isShowFileRule: false,
      loading: false,
      spinLoading: false,
      visible: false,
      uploadErrorMsg: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobId = '', item = {}, visible } = nextProps
    const isEdit = !!item.id
    const itemPrev = cloneDeep(item)

    if (visible === true && prevState.visible === false) {
      return { ...prevState, jobId, item, itemPrev, isEdit, visible, currentSubCatList: [], currentSubCat: {} }
    } else {
      return { ...prevState, jobId, item, itemPrev, isEdit, visible }
    }
  }

  isNewJob = () => {
    const { jobId } = this.props
    return jobId === '' || jobId === 'new' || jobId === 'add'
  }

  handleSubmit = () => {
    const { jobId, form, onUpdate, categoriesList = [], subCategoriesList = [], onSetFile = () => {} } = this.props
    const { isEdit = false, fileUploadedList } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const isNewJob = this.isNewJob()

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (!isEdit && !fileUploadedList.file_url) {
          this.setState({ uploadErrorMsg: UploadMsgNoFile })
          return
        } else {
          const adminUser = await authService.getCurrentUser() || {}

          if (fileUploadedList.uid) {
            values.file_name = fileUploadedList.file_name
            values.file_url = fileUploadedList.file_url
          }

          // console.log('add modal values', values)

          this.setState({ loading: true })

          let r = null
          if (isNewJob) {
            values.module = 'job'
            onSetFile(values)
            this.fileClear()
            resetFields()
          }

          this.setState({ loading: false })
        }
      } else if (!fileUploadedList.uid) {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  handleMainCatChange = (id) => {
    const { form, subCategoriesList } = this.props
    const { setFieldsValue } = form
    const list = subCategoriesList.filter(e => e.file_category_id === id)
    this.setState({ currentSubCatList: list, currentSubCat: {} })
    setFieldsValue({ sub_category_id: undefined })
  }

  handleSubCatChange = (id) => {
    const { currentSubCatList } = this.state
    const cat = currentSubCatList.find(e => e.id === id)
    if (cat) {
      this.setState({ currentSubCat: cat })
    }
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ spinLoading: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCat } = this.state

    if (value) {
      if (currentSubCat.has_expiry && currentSubCat.expiry_month) {
        const expiryMonth = parseInt(currentSubCat.expiry_month)
        const expiryDate = moment(value).add(expiryMonth, 'months')
        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ spinLoading: false })
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({ fileList: newFileList, fileUploadedList: {} })
    }
  }

  fileClear = () => {
    this.setState({ fileList: [], fileUploadedList: {} })
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const lastIndex = r.fileUrl.lastIndexOf('/')
        const fileName = decodeURI(r.fileUrl.substring(lastIndex + 1))
        const data = {
          file_name: fileName || '',
          file_url: r.fileUrl,
          uid: uid
        }

        this.setState({ fileUploadedList: data, isShowFileRule: false })
      }
    }
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  render () {
    const { visible, categoriesList, subCategoriesList, form, onClose, jobId } = this.props
    const { currentSubCatList, fileList, isEdit, item, loading, spinLoading, currentSubCat, uploadErrorMsg, isShowFileRule } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit File' : 'Add File'

    return (
      <SideModal
        key={`jobaddfile_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          this.fileClear()
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Main Category'>
                {getFieldDecorator('main_category_id', {
                  initialValue: item.main_category_id || '',
                  rules: [
                    { required: true, message: 'Please select main category' }
                  ]
                })(
                  <Select placeholder='Please select main category'
                    onChange={(mainId) => this.handleMainCatChange(mainId)}
                    disabled={isEdit}>
                    {
                      categoriesList.map((main) => (
                        <Option key={main.name} value={main.id}>{main.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Sub Category'>
                {getFieldDecorator('sub_category_id', {
                  initialValue: item.sub_category_id || '',
                  rules: [
                    { required: true, message: 'Please select sub category' }
                  ]
                })(
                  <Select placeholder='Please select sub category'
                    onChange={(subId) => this.handleSubCatChange(subId)}
                    disabled={currentSubCatList.length < 1 || isEdit}>
                    {isEdit || item.seq
                      ? subCategoriesList.map((sub) => (
                        <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                      ))
                      : currentSubCatList.map((sub) => (
                        <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Issuance Date'>
                {getFieldDecorator('issuance_date', {
                  rules: [
                    { required: true, message: 'Please enter issuance date' }
                  ],
                  initialValue: item.issuance_date ? formatter.toMoment(item.issuance_date) : null
                })(
                  <DatePicker onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>

              {currentSubCat.has_expiry || item.expiry_date
                ? <FormItem label='Expiry Date'>
                  {getFieldDecorator('expiry_date', item.expiry_date ? {
                    rules: [
                      { required: true, message: 'Please enter expiry date' }
                    ],
                    initialValue: item.expiry_date ? formatter.toMoment(item.expiry_date) : null
                  } : {
                    rules: [
                      { required: true, message: 'Please enter expiry_date' }
                    ]
                  })(
                    <DatePicker format={dateFormat} defaultPickerValue={moment(new Date())} />
                  )}
                </FormItem>
                : null }

              <FormItem label='Label'>
                {getFieldDecorator('label', {
                  initialValue: item.label,
                  rules: [
                    { min: 2, message: 'Label must be between 2 and 128 characters' },
                    { max: 128, message: 'Label must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter label' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem label=''>
                {getFieldDecorator('active', {
                  initialValue: typeof item.active === 'boolean' ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Enable'
                    unCheckedChildren='Disable'
                  />
                )}
              </FormItem>

              <FileUpload
                file={item.file_url}
                fileName={item.file_name}
                loading={spinLoading}
                readOnly={false}
                showError={!!isShowFileRule}
                upload={{
                  method: 'POST',
                  action: `${apiHostname}/private/api/jobs/files/upload/files`,
                  data: { id: item.id, moduleId: jobId },
                  name: 'file',
                  onRemove: this.fileRemove,
                  onChange: this.fileChange,
                  beforeUpload: this.checkFile,
                  headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                  multiple: false
                }}
              />

              { isShowFileRule ? <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>Please upload file</div> : null }
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileModal)

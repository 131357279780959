import React, { Component } from 'react'
import { connect } from 'react-redux'
import { funderService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import Moment from 'moment-timezone'
import { formatter } from '../../../util'

// UI
import { Loading, List, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FunderClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: 'active',
      total: 0,
      clientList: [],
      loading: false,
      clientId: ''
    }
  }

  componentDidMount () {
    const { currentPage, filterParam } = this.state
    this.fetchClient({currentPage, filterParam})
  }

  render () {
    const { currentPage, filterParam, loading, clientList, total } = this.state

    const clientColumns = [

      {
        title: 'Name',
        width: 8,
        render: ({ first_name, last_name, client_id }) => <a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'><div className='name-label'>{first_name} {last_name}</div></a>
      },

      {
        title: 'Carer Skill Level Required',
        width: 6,
        render: ({ payroll_category }) => <div className='name-label' style={{ textTransform: 'capitalize' }}>{payroll_category}</div>
      },

      {
        title: 'Invoice Notes',
        width: 6,
        key: 'invoice_note'
      },

      {
        title: 'Created At',
        width: 4,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      }
    ]

    return (
      <Loading loading={loading} blur>
        <Radio.Group onChange={(e) => this.filterClient(e)} defaultValue='active' style={{ marginLeft: '20px', marginTop: '10px', marginBottom: '10px' }}>
          <Radio.Button value='active'>Active</Radio.Button>
          <Radio.Button value='inactive'>Inactive</Radio.Button>
          {/* <Radio.Button value='exited'>Exited</Radio.Button> */}
        </Radio.Group>

        <div className='task-list'>
          <Skeleton loading={loading} active>

            <List cols={clientColumns} rows={clientList} />

          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} clients`}
          current={currentPage}
          onChange={(e) => this.changePage({currentPage: e, filterParam})}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  // fetchClient = async ({currentPage: page, filterParam, loading}) => {
  //   this.setState({ loading: true })
  //   const { funderId } = this.props
  //   const filter = { }

  //   filter.funder_id = { condition: '=', value: funderId }

  //   if (filterParam === 'active') {
  //     filter.client_active = { condition: '=', value: true }
  //   } else if (filterParam === 'inactive') {
  //     filter.client_active = { condition: '=', value: false }
  //     filter.client_is_exited = { condition: '=', value: false }
  //   } else if (filterParam === 'exited') {
  //     filter.client_active = { condition: '=', value: false }
  //     filter.client_is_exited = { condition: '=', value: true }
  //   }

  //   const { list: clientList, total } = await funderService.listClientByPage(page, pageSize, filter)

  //   this.setState({ clientList, total, loading: false, currentPage: page, filter })
  // }

  fetchClient = async ({currentPage: page, filterParam, loading}) => {
    this.setState({ loading: true })
    const { funderId } = this.props
    const filter = { }

    filter.funder_id = { condition: '=', value: funderId }

    if (filterParam === 'active') {
      filter.client_active = { condition: '=', value: true }
    } else if (filterParam === 'inactive') {
      filter.client_active = { condition: '=', value: false }
    }

    const { list: clientList, total } = await funderService.listClientByPage(page, pageSize, filter)

    this.setState({ clientList, total, loading: false, currentPage: page, filter })
  }

  filterClient (e) {
    const { filter, loading } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchClient({ currentPage: 1, filter, loading, filterParam: filterValue })
  }

  changePage = async (currentPage) => {
    this.fetchClient(currentPage)
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FunderClient))


import ClientList from './containers/Client/List'
import ClientPage from './containers/Client/Page'
import ClientLeaveJob from './containers/Client/ImpactedJob'
import ClientLeaveJobJvp from './containers/Client/ImpactedJobJvp'
import Communication from './containers/Communication'
import Dashboard from './containers/Dashboard'
import EmployeeList from './containers/Employee/List'
import EmployeePage from './containers/Employee/Page'
import EmployeeLeaveJob from './containers/Employee/ImpactedJob'
import EmployeeLeaveJobJvp from './containers/Employee/ImpactedJobJvp'
import FunderList from './containers/Funder/List'
import FunderPage from './containers/Funder/Page'
import Map from './containers/Map'
import Notification from './containers/Notification'

// Feedbacks
import FeedbackList from './containers/Feedback/List'
import FeedbackPage from './containers/Feedback/Page'
import SettingFeedback from './containers/Setting/Feedback'

// Job
import IndividualList from './containers/Job/Individual'
import JobPage from './containers/Job/IndividualPage'
import PendingList from './containers/Job/Pending'
import RecurringJobPage from './containers/Job/RecurringPage'
import RecurringBaseList from './containers/Job/RecurringBase'

// JobFvp
import IndividualListV2 from './containers/JobFvp/Individual'
import JobPageV2 from './containers/JobFvp/IndividualPage'
import PendingListV2 from './containers/JobFvp/Pending'
import RecurringJobPageV2 from './containers/JobFvp/RecurringPage'
import RecurringBaseListV2 from './containers/JobFvp/RecurringBase'

// JobJvp
import JobSingleListV3 from './containers/JobJvp/Single/List'
import JobSinglePageV3 from './containers/JobJvp/Single/Page'
import JobRecurringListV3 from './containers/JobJvp/Recurring/List'
import JobRecurringPageV3 from './containers/JobJvp/Recurring/Page'

import Helpdesk from './containers/Helpdesk'
import HelpdeskPage from './containers/Helpdesk/Page'

// PayrollFvp
import PayrollMainV2 from './containers/PayrollFvp/Main'
import PayrollListV2 from './containers/PayrollFvp/List'
import PayrollIndividualV2 from './containers/PayrollFvp/Individual'

// Report
import ReportAction from './containers/Report/Action'
import ReportActionJob from './containers/Report/Action/job'
import ReportBackToBackJob from './containers/Report/BackToBack'
// import ReportClashJob from './containers/Report/Clash'
import ReportClashJobV2 from './containers/Report/ClashV2'
import ReportClientBirthday from './containers/Report/Client/birthday'
import ReportClientBudget from './containers/Report/Client/budget'
import ReportClientCarePlan from './containers/Report/Client/careplan'
import ReportClientFunding from './containers/Report/Client/funding'
import ReportClientFiles from './containers/Report/Client/files'
import ReportClientLeave from './containers/Report/Client/leave'
import ReportDayCount from './containers/Report/DayCount'
import ReportEmployeeBirthday from './containers/Report/Employee/birthday'
import ReportEmployeeFiles from './containers/Report/Employee/files'
import ReportEmployeeLeave from './containers/Report/Employee/leave'
import ReportEmployeeMissingRequiredFiles from './containers/Report/Employee/missingrequiredfile'
import ReportFeedback from './containers/Report/Feedback'
import ReportFunderAgreementExpiry from './containers/Report/Funder/agreementexpiry'
import ReportFunderFiles from './containers/Report/Funder/files'
import ReportJobCancellation from './containers/Report/Job/cancellation'
import ReportJobTimesheet from './containers/Report/Job/timesheet'
import ReportMultiClientJob from './containers/Report/MultiClient'
import ReportPublicHoliday from './containers/Report/PublicHoliday'
import ReportSetting from './containers/Report/Setting'

// Settings
import Setting from './containers/Setting'
import SettingAdmin from './containers/Setting/Admin'
import SettingAdminRole from './containers/Setting/AdminRole'
import SettingAccessLog from './containers/Setting/AccessLog'
import SettingBillingRates from './containers/Setting/BillingRates'
import SettingBillingRatesSet from './containers/Setting/BillingRates/Set/set'
import SettingCancellationPage from './containers/Setting/Reason/Cancellation/Page'
import SettingResignedExitedPage from './containers/Setting/Reason/ResignedExited/Page'
import SettingContacts from './containers/Setting/Contacts'
import SettingFile from './containers/Setting/File'
import SettingFunders from './containers/Setting/Funder'
import SettingFundersFMPage from './containers/Setting/Funder/FundManager/Page'
import SettingFundersRSPage from './containers/Setting/Funder/RateSet/Page'
import SettingFundingType from './containers/Setting/FundingType'
import SettingHoliday from './containers/Setting/Holiday'
import SettingHolidayPage from './containers/Setting/Holiday/Page'
import SettingHolidayImpactedJob from './containers/Setting/Holiday/ImpactedJob'
import SettingHolidayImpactedJobJvp from './containers/Setting/Holiday/ImpactedJobJvp'
import SettingModule from './containers/Setting/Module'
import SettingProfile from './containers/Setting/Profile'
import SettingReason from './containers/Setting/Reason'
import SettingReminderLog from './containers/Setting/ReminderLog'
import SettingTaxcode from './containers/Setting/TaxCode'
import SettingOther from './containers/Setting/Other'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Dashboard',
    path: '/'
  },
  {
    component: Setting,
    exact: true,
    icon: 'admin.svg',
    name: 'Admins',
    path: '/settings'
  },
  {
    component: ClientList,
    name: 'Client',
    path: '/clients',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Client',
    path: '/clients/:id',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Client',
    path: '/clients/:id/:type',
    exact: true
  },
  {
    component: ClientLeaveJobJvp,
    name: 'Client',
    path: '/clients/:id/leave/:lid',
    exact: true
  },
  {
    component: EmployeeList,
    name: 'Employee',
    path: '/employees',
    exact: true
  },
  {
    component: EmployeePage,
    name: 'Employee',
    path: '/employees/:id',
    exact: true
  },
  {
    component: EmployeePage,
    name: 'Employee',
    path: '/employees/:id/:type',
    exact: true
  },
  {
    component: EmployeeLeaveJobJvp,
    name: 'Employee',
    path: '/employees/:id/leave/:lid',
    exact: true
  },
  {
    component: FunderList,
    name: 'Funder',
    path: '/funders',
    exact: true
  },
  {
    component: FeedbackList,
    name: 'Feedback',
    path: '/feedbacks',
    exact: true
  },
  {
    component: FeedbackPage,
    name: 'Feedback',
    path: '/feedbacks/:id',
    exact: true
  },
  {
    component: FeedbackPage,
    name: 'Feedback',
    path: '/feedbacks/:id/:type',
    exact: true
  },
  {
    component: FeedbackPage,
    name: 'Feedback',
    path: '/feedbacks/:id/actions/:aid',
    exact: true
  },
  {
    component: FunderPage,
    name: 'Funder',
    path: '/funders/:id',
    exact: true
  },
  {
    component: FunderPage,
    name: 'Funder',
    path: '/funders/:id/:type',
    exact: true
  },
  // Job
  {
    component: RecurringBaseList,
    name: 'Job',
    path: '/v1/jobs/recurring',
    exact: true
  },
  {
    component: IndividualList,
    name: 'Job',
    path: '/v1/jobs/cancelled',
    exact: true
  },
  {
    component: IndividualList,
    name: 'Job',
    path: '/v1/jobs/future',
    exact: true
  },
  {
    component: IndividualList,
    name: 'Job',
    path: '/v1/jobs/past',
    exact: true
  },
  {
    component: RecurringBaseList,
    name: 'Job',
    path: '/v1/jobs/recurring-ended',
    exact: true
  },
  {
    component: PendingList,
    name: 'Job',
    path: '/v1/jobs/pending-individual',
    exact: true
  },
  {
    component: PendingList,
    name: 'Job',
    path: '/v1/jobs/pending-recurring',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/single/:id',
    exact: true
  },
  {
    component: RecurringJobPage,
    name: 'Job',
    path: '/v1/jobs/recurring/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/conflict/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/cancelled/:id',
    exact: true
  },
  {
    component: RecurringJobPage,
    name: 'Job',
    path: '/v1/jobs/recurring-ended/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/future/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/past/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/v1/jobs/pending-individual/:id',
    exact: true
  },
  {
    component: RecurringJobPage,
    name: 'Job',
    path: '/v1/jobs/pending-recurring/:id',
    exact: true
  },
  // JobFvp
  {
    component: RecurringBaseListV2,
    name: 'Job',
    path: '/v2/jobs/recurring',
    exact: true
  },
  {
    component: IndividualListV2,
    name: 'Job',
    path: '/v2/jobs/cancelled',
    exact: true
  },
  {
    component: IndividualListV2,
    name: 'Job',
    path: '/v2/jobs/future',
    exact: true
  },
  {
    component: IndividualListV2,
    name: 'Job',
    path: '/v2/jobs/past',
    exact: true
  },
  {
    component: RecurringBaseListV2,
    name: 'Job',
    path: '/v2/jobs/recurring-ended',
    exact: true
  },

  {
    component: PendingListV2,
    name: 'Job',
    path: '/v2/jobs/pending-individual',
    exact: true
  },
  {
    component: PendingListV2,
    name: 'Job',
    path: '/v2/jobs/pending-recurring',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/single/:id',
    exact: true
  },
  {
    component: RecurringJobPageV2,
    name: 'Job',
    path: '/v2/jobs/recurring/:id',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/conflict/:id',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/cancelled/:id',
    exact: true
  },
  {
    component: RecurringJobPageV2,
    name: 'Job',
    path: '/v2/jobs/recurring-ended/:id',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/future/:id',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/past/:id',
    exact: true
  },
  {
    component: JobPageV2,
    name: 'Job',
    path: '/v2/jobs/pending-individual/:id',
    exact: true
  },
  {
    component: RecurringJobPageV2,
    name: 'Job',
    path: '/v2/jobs/pending-recurring/:id',
    exact: true
  },
  // JobJvp
  {
    component: JobRecurringListV3,
    name: 'Job',
    path: '/jobs/list/recurring/:type',
    exact: true
  },
  {
    component: JobRecurringPageV3,
    name: 'Job',
    path: '/jobs/recurring/:id/:tab',
    exact: true
  },
  {
    component: JobRecurringPageV3,
    name: 'Job',
    path: '/jobs/recurring/:id',
    exact: true
  },
  {
    component: JobSingleListV3,
    name: 'Job',
    path: '/jobs/list/single/:type',
    exact: true
  },
  {
    component: JobSinglePageV3,
    name: 'Job',
    path: '/jobs/single/:id',
    exact: true
  },
  {
    component: JobSinglePageV3,
    name: 'Job',
    path: '/jobs/single/:id/:tab',
    exact: true
  },
  // Others
  {
    component: Map,
    name: 'Map',
    path: '/map',
    exact: true
  },
  // {
  //   component: Notification,
  //   name: 'Notification',
  //   path: '/notification',
  //   exact: true
  // },
  {
    component: Communication,
    name: 'Communication',
    path: '/communication',
    exact: true
  },
  {
    component: Helpdesk,
    name: 'Communication',
    path: '/helpdesk',
    exact: true
  },
  {
    component: HelpdeskPage,
    name: 'Communication',
    path: '/helpdesk/:id',
    exact: true
  },
  // PayrollFvp
  {
    component: PayrollMainV2,
    name: 'Payroll',
    path: '/payrolls',
    exact: true
  },
  {
    component: PayrollListV2,
    name: 'Payroll',
    path: '/payrolls/:id',
    exact: true
  },
  {
    component: PayrollIndividualV2,
    name: 'Payroll',
    path: '/payrolls/:pid/:view',
    exact: true
  },
  {
    component: PayrollIndividualV2,
    name: 'Payroll',
    path: '/payrolls/:pid/:view/:id',
    exact: true
  },
  {
    component: SettingModule,
    name: 'Setting System Module',
    path: '/settings/modules',
    exact: true
  },
  {
    component: SettingAdmin,
    name: 'Setting System User',
    path: '/settings/admins',
    exact: true
  },
  {
    component: SettingAdminRole,
    name: 'Setting User Role',
    path: '/settings/admin-roles',
    exact: true
  },
  {
    component: SettingAccessLog,
    name: 'User Access Log',
    path: '/settings/access-log',
    exact: true
  },
  {
    component: SettingReminderLog,
    name: 'Reminder Log',
    path: '/settings/reminder-log',
    exact: true
  },
  {
    component: SettingContacts,
    name: 'Setting Contacts',
    path: '/settings/contacts',
    exact: true
  },
  {
    component: SettingFeedback,
    name: 'Setting Feedback',
    path: '/settings/feedbacks',
    exact: true
  },
  {
    component: SettingFile,
    name: 'Setting File',
    path: '/settings/files',
    exact: true
  },
  {
    component: SettingHoliday,
    name: 'Setting Holidays',
    path: '/settings/holidays',
    exact: true
  },
  {
    component: SettingHolidayPage,
    name: 'Setting Holidays',
    path: '/settings/holidays/:id',
    exact: true
  },
  {
    component: SettingHolidayImpactedJobJvp,
    name: 'Setting Holidays',
    path: '/holidays/:hid/job',
    exact: true
  },
  {
    component: SettingBillingRates,
    name: 'Setting Billing Rates',
    path: '/settings/billing-rates',
    exact: true
  },
  {
    component: SettingBillingRatesSet,
    name: 'Setting Billing Rates',
    path: '/settings/billing-rates/:id',
    exact: true
  },
  {
    component: SettingFundingType,
    name: 'Setting Funding Type',
    path: '/settings/funding-types',
    exact: true
  },
  {
    component: SettingFunders,
    name: 'Setting Funder',
    path: '/settings/funders',
    exact: true
  },
  {
    component: SettingFunders,
    name: 'Setting Funder',
    path: '/settings/funders/:type',
    exact: true
  },
  {
    component: SettingFundersFMPage,
    name: 'Setting Fund Manager',
    path: '/settings/funders/fund-manager/:id',
    exact: true
  },
  {
    component: SettingFundersRSPage,
    name: 'Setting Rate Set',
    path: '/settings/funders/rate-set/:id',
    exact: true
  },
  {
    component: SettingOther,
    name: 'Setting Other',
    path: '/settings/others',
    exact: true
  },
  {
    component: SettingCancellationPage,
    name: 'Setting Cancellation',
    path: '/settings/cancellations/:id',
    exact: true
  },
  {
    component: SettingTaxcode,
    name: 'Setting Tax Code',
    path: '/settings/tax-codes',
    exact: true
  },
  {
    component: SettingProfile,
    name: 'Setting Profile',
    path: '/settings/profile',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons',
    exact: true
  },
  {
    component: SettingResignedExitedPage,
    name: 'Setting Resigned/Exited',
    path: '/settings/resigned-or-exited/:id',
    exact: true
  },
  {
    component: ReportAction,
    name: 'System User Action Report',
    path: '/reports/action',
    exact: true
  },
  {
    component: ReportClientBirthday,
    name: 'Client Birthday Report',
    path: '/reports/client-birthday',
    exact: true
  },
  {
    component: ReportClientBudget,
    name: 'Client Budget Report',
    path: '/reports/client-budget',
    exact: true
  },
  {
    component: ReportClientFunding,
    name: 'Client Funding',
    path: '/reports/client-funding',
    exact: true
  },
  {
    component: ReportClientFiles,
    name: 'Client Files',
    path: '/reports/client-files',
    exact: true
  },
  {
    component: ReportClientLeave,
    name: 'Client Leave Report',
    path: '/reports/client-leave',
    exact: true
  },
  {
    component: ReportEmployeeBirthday,
    name: 'Employee Birthday Report',
    path: '/reports/employee-birthday',
    exact: true
  },
  {
    component: ReportEmployeeFiles,
    name: 'Employee Files',
    path: '/reports/employee-files',
    exact: true
  },
  {
    component: ReportEmployeeLeave,
    name: 'Employee Leave Report',
    path: '/reports/employee-leave',
    exact: true
  },
  {
    component: ReportEmployeeMissingRequiredFiles,
    name: 'Employee Missing Required Files Report',
    path: '/reports/employee-missing-required-files',
    exact: true
  },
  {
    component: ReportSetting,
    name: 'Report Settings',
    path: '/reports/setting',
    exact: true
  },
  {
    component: ReportActionJob,
    name: 'System User Action Report',
    path: '/reports/action/:usercode',
    exact: true
  },
  {
    component: ReportActionJob,
    name: 'System User Action Report',
    path: '/reports/action/:user/:from/:to',
    exact: true
  },
  {
    component: ReportClashJobV2,
    name: 'Clash & Back-to-Back Report',
    path: '/reports/clash-job',
    exact: true
  },
  {
    component: ReportDayCount,
    name: 'Day Counts Report',
    path: '/reports/last-job',
    exact: true
  },
  {
    component: ReportBackToBackJob,
    name: 'Back-to-Back Report',
    path: '/reports/back-to-back-job',
    exact: true
  },
  {
    component: ReportPublicHoliday,
    name: 'Public Holidays',
    path: '/reports/public-holiday',
    exact: true
  },
  {
    component: ReportMultiClientJob,
    name: 'Multi Client Report',
    path: '/reports/multi-client',
    exact: true
  },
  {
    component: ReportClientCarePlan,
    name: 'Care Plan Review',
    path: '/reports/care-plan-v2-review',
    exact: true
  },
  {
    component: ReportFeedback,
    name: 'Feedback Report',
    path: '/reports/feedback',
    exact: true
  },
  {
    component: ReportFunderFiles,
    name: 'Funder Files',
    path: '/reports/funder-files',
    exact: true
  },
  {
    component: ReportFunderAgreementExpiry,
    name: 'Funder Agreement Expiry',
    path: '/reports/funder-agreement-expiry',
    exact: true
  },
  {
    component: ReportJobCancellation,
    name: 'Job Cancellation Report',
    path: '/reports/job-cancellation'
  },
  {
    component: ReportJobTimesheet,
    name: 'Job Timesheet Report',
    path: '/reports/job-timesheet-report'
  }
]

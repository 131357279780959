import React, { Component } from 'react'

// UI
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Popconfirm from 'antd/lib/popconfirm'

import './styles.css'

export class SettingBillingRateRow extends Component {
  constructor (props) {
    super(props)
  }

  onChangeValue (day, value) {
    const { item } = this.props

    this.props.onChange(item.id, day, value)
    return value
  }

  onChangeLabel (day, value) {
    const { item } = this.props

    this.props.onChangeLabel(item.id, day, value)
    return value
  }

  render () {
    const { form, item, onDelete, setId } = this.props
    const { getFieldDecorator } = form
    const { canDelete = true, is_sleepover: isSleepover = false } = item

    const rateProps = (field, value = '', index) => (getFieldDecorator(`${field}-${setId}-${item.id}-${index}`, {
      getValueFromEvent: (e) => this.onChangeValue(field, e.target.value),
      initialValue: value
    })(
      <Input key={field} className='billing-rate-set-field' placeholder='0.00' />
    ))

    const labelProps = (field, value = '', index) => (getFieldDecorator(`${field}-label-${setId}-${item.id}-${index}`, {
      getValueFromEvent: (e) => this.onChangeLabel(field, e.target.value),
      initialValue: value
    })(
      <Input className='billing-rate-set-field' placeholder='Label' />
    ))

    const normalHours = item.rates.filter(r => r.day.indexOf('n') > -1).sort((x, y) => {
      if (x.day < y.day) return -1
      if (x.day > y.day) return 1
      return 0
    })
    const afterHours = item.rates.filter(r => r.day.indexOf('a') > -1).sort((x, y) => {
      if (x.day < y.day) return -1
      if (x.day > y.day) return 1
      return 0
    })

    return (
      <div style={{ margin: '20px 0' }}>
        <Row gutter={10}>
          <Col lg={4}>
            <div className='billing-rate-category-name'>
              { canDelete && !isSleepover
                ? <Popconfirm
                  title='Confirm to remove this?'
                  onConfirm={(e) => onDelete(item.id)}
                  okText='Yes'
                  cancelText='No'
                  placement='left'
                >
                  <div className='billing-rate-remove-row'><Icon type='delete' /></div>
                </Popconfirm>
                : null }
              { item.name }
            </div>
          </Col>
          <Col lg={20}>
            <Row gutter={10} style={{ fontSize: '8.5pt', fontWeight: 'bold' }}>
              <Col lg={3}>
                Mon
              </Col>
              <Col lg={3}>
                Tue
              </Col>
              <Col lg={3}>
                Wed
              </Col>
              <Col lg={3}>
                Thu
              </Col>
              <Col lg={3}>
                Fri
              </Col>
              <Col lg={3}>
                Sat
              </Col>
              <Col lg={3}>
                Sun
              </Col>
              <Col lg={3}>
                Holiday
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={4} style={{ height: '30px', position: 'relative' }}>
            <div className='billing-rate-hour'>
              Normal Hours
            </div>
          </Col>
          <Col lg={20}>
            <Row gutter={4}>
              {
                normalHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {rateProps(rate.day, rate.value, index)}
                  </Col>
                })
              }
            </Row>
            <Row gutter={4} style={{ marginTop: '3px' }}>
              {
                normalHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {labelProps(rate.day, rate.label, index)}
                  </Col>
                })
              }
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <Col lg={4} />
          <Col lg={20}>
            <Row gutter={4} style={{ fontSize: '8.5pt', fontWeight: 'bold' }}>
              <Col lg={3}>
                Mon
              </Col>
              <Col lg={3}>
                Tue
              </Col>
              <Col lg={3}>
                Wed
              </Col>
              <Col lg={3}>
                Thu
              </Col>
              <Col lg={3}>
                Fri
              </Col>
              <Col lg={3}>
                Sat
              </Col>
              <Col lg={3}>
                Sun
              </Col>
              <Col lg={3}>
                Holiday
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col lg={4} style={{ height: '30px', position: 'relative' }}>
            <div className='billing-rate-hour'>
              After Hours
            </div>
          </Col>
          <Col lg={20}>
            <Row gutter={4}>
              {
                afterHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {rateProps(rate.day, rate.value, index)}
                  </Col>
                })
              }
            </Row>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: '3px' }}>
          <Col lg={4}>

          </Col>
          <Col lg={20}>
            <Row gutter={4}>
              {
                afterHours.map((rate, index) => {
                  return <Col lg={3} key={index}>
                    {labelProps(rate.day, rate.label, index)}
                  </Col>
                })
              }
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (Form.create()(SettingBillingRateRow))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { fvpFundManagerService } from '../../../../services'
import { authService } from '../../../../services'

// UI
import { Page, Pager, List } from '../../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 30
const { Item: FormItem } = Form


export class SettingFundManagerList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      searchText: '',
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchFundManagers({ loading: true, currentPage })
  }

  fetchFundManagers = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      if (this.hasAccess('listFvpFundingFundManagers')) {
        this.setState({ currentPage, loading: loading })
        const settings = await fvpFundManagerService.listByPage(currentPage, pageSize, filter, sort, searchText)
        this.setState({ list: settings.list, loading: false, total: settings.total })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load plan manager successfully. Please try again later.')
    }
  }

  async handleDelete (id) {
    this.setState({ loading: true })
    const res = await fvpFundManagerService.remove(id)

    if (res) {
      await this.fetchFundManagers({ currentPage: 1 })
      notify.success('Deleted successfully', `Plan manager deleted successfully`)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 16,
        key: 'fullname'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('updateFvpFundingFundManager')
          ? <Link to={`/settings/funders/fund-manager/${id}`}><div><Icon type='form' /></div></Link>
          : null
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess('deleteFvpFundingFundManager') && is_editable
        ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm>
        : null
      }
    ]

    return (
      <div>
        <Page.Header title='Fund Manager'>
          {
            this.hasAccess('createFvpFundingFundManager')
              ? <Link to={`/settings/funders/fund-manager/add`}>
                <div className='btn'>
                  Add Fund Manager
                </div>
              </Link>
              : null
          }
        </Page.Header>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} fund managers`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFundManagers({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFundManagerList))

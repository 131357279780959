import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { authService, clientService, fvpClientFundingService } from '../../../../services'
import { formatter, validator } from '../../../../util'
import { JobFilterType, JobListType } from '../../../../constants'
import { jobURL, timezone } from '../../../../config'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import '../../styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const dateFormat = 'DD/MM/YYYY'
const timeFormat = 'hh:mm A'
const JobRecurringpageURL = `${jobURL}/${JobListType.JOB_LIST_RECURRING}`

moment.tz.setDefault(timezone)

class RecurringJobView extends Component {
  render () {
    const { item, listType } = this.props
    const {
      id,
      client_id,
      funder_id,
      employee_id,
      is_employee_pending,
      tasks,
      recurring_start_date,
      recurring_end_date,
      is_recurring_never_end,
      job_start_date,
      job_end_date,
      interval,
      is_sleepover_job,
      string_recurring_start_day,
      client_fullname,
      client_suburb,
      employee_fullname,
      funder_fullname,
      client_leave_id,
      client_leave_start_date,
      client_leave_end_date,
      client_leave_is_ufn,
      employee_leave_id,
      employee_leave_start_date,
      employee_leave_end_date,
      acvd_client_names,
      acvd_employee_names
    } = item

    const isNeverEnd = is_recurring_never_end === true && !recurring_end_date
    const dateText = `${formatter.toShortDate(recurring_start_date)} - ${isNeverEnd ? `∞` : recurring_end_date ? formatter.toShortDate(recurring_end_date) : 'Unknown'}`
    const timeText = `${formatter.toShortTime(job_start_date)} - ${formatter.toShortTime(job_end_date)}`
    const duration = formatter.toDurationDiff(job_start_date, job_end_date)
    const durationText = is_sleepover_job ? `Sleepover` : `${formatter.toDecimal(duration)} hour${duration === 1 ? '' : 's'}`

    const isClientLeave = !!client_leave_id
    const clientLeaveText = isClientLeave ? `Leave ${formatter.toShortDate(client_leave_start_date)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end_date)}` : null

    const isEmployeeLeave = !!employee_leave_id
    const employeeLeaveText = isEmployeeLeave ? `Leave ${formatter.toShortDate(employee_leave_start_date)} - ${formatter.toShortDate(employee_leave_end_date)}` : null

    const isClashedMembers = acvd_client_names || acvd_employee_names
    const clashMembersDiv = isClashedMembers
      ? <div>
        { acvd_client_names ? <div>Same address with client: {acvd_client_names}</div> : null }
        { acvd_employee_names ? <div>Same address with client: {acvd_employee_names}</div> : null }
      </div>
      : null

    return (
      <div className='list-item' key={`rcrj-${id}`}>
        <Row>
          <Col lg={22}>
            <Row className='bottom-border'>
              <Col lg={8}>
                <Row>
                  <Col lg={5}>
                    <div className='subtitle'>Client</div>
                  </Col>
                  <Col lg={14}>
                    <div className='name'>
                      <a href={`/clients/${client_id}/info`} rel='noopener noreferrer' target='_blank'>
                        {client_fullname}
                      </a>
                    </div>
                    { clientLeaveText
                      ? <div className='warning-text'>
                         <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                        &nbsp;{ clientLeaveText }
                      </div>
                      : null }
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={6}>
                    <div className='subtitle'>Employee</div>
                  </Col>
                  <Col lg={14}>
                    <div className='name'>
                      { employee_id
                        ? <a href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>
                          {employee_fullname}
                        </a>
                        : <div className='disabled'>Unassigned</div> }
                      { employeeLeaveText
                        ? <div className='warning-text'>
                          <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                          &nbsp;{ employeeLeaveText }
                        </div>
                        : null }
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={5}>
                    <div className='subtitle'>Funder</div>
                  </Col>
                  <Col lg={14}>
                    <div className='name'>
                      <a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>
                        {funder_fullname}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col md={8}>
                <div className='detail'>
                  <Icon type='calendar' />
                  &nbsp;&nbsp;{ dateText }
                  <span className='indicator'>{formatter.capitalize(interval + 'ly')}</span>
                  <span className='indicator'>{formatter.capitalize(string_recurring_start_day)}</span>
                </div>
              </Col>
              <Col md={8}>
                <div className='detail'>
                  <Icon type='clock-circle' />
                  &nbsp;&nbsp;{ timeText }
                  <span className='indicator'>{durationText}</span>
                </div>
              </Col>
              <Col md={6}>
                <div className='detail'>
                  <Icon type='environment' />
                  &nbsp;&nbsp;{client_suburb}&nbsp;
                  { clashMembersDiv
                    ? <span className='marker'>
                      &nbsp;
                      <Tooltip
                        title={(
                          clashMembersDiv
                        )}
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                      >
                        <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                      </Tooltip>
                    </span>
                    : null }
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col>
                <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : tasks }</div>
              </Col>
            </Row>
          </Col>
          <Col lg={2}>
          { this.hasAccess('readJob') || this.hasAccess('updateJob')
              ? <Link to={`${JobRecurringpageURL}/${id}/info`}>
                <div className='edit-button'>
                  <Tooltip mouseLeaveDelay={0} title='Manage job'>
                    <Icon type='form' />
                  </Tooltip>
                </div>
              </Link>
            : null }
          </Col>
        </Row>
      </div>
    )
  }

  hasAccess = (accessLevel) => {
    return authService.hasAccess(accessLevel)
  }
}

export default RecurringJobView

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientService, funderService, settingGeneralService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log, trigger } from '../../../util'

// UI
import { Button, Loading, List, Panel, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Popconfirm from 'antd/lib/popconfirm'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const Option = Select.Option

const pageSize = 20

const KMRules = [
  {
    name: 'Bill in same invoice',
    value: 'none'
  },
  {
    name: 'Convert to hours',
    value: 'convert'
  },
  {
    name: 'Bill KM separately to Client',
    value: 'self'
  }
]

export class ClientFunder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      fundingItem: [],
      loading: false,
      item: [],
      clientId: '',
      clientInfo: '',
      payrolls: [],
      languages: [],
      skills: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { id: null },
      taxCodes: [],
      currentPage: 1,
      total: 0,
      noKMRestriction: false
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchFundingSetting(currentPage)
    this.fetchFunders()
  }

  showFundingSetting () {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  hideFundingSetting () {
    const { form } = this.props
    const { modal } = this.state
    form.resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleNoKMRestriction = (noRestriction) => {
    const { setFieldsValue } = this.props.form
    this.setState({ noKMRestriction: noRestriction })
    if (noRestriction) {
      setFieldsValue({ max_km: '' })
    }
  }

  findKMInvoice = (rule) => {
    const kmRule = KMRules.filter((item) => item.value === rule)
    return kmRule.length > 0 ? kmRule[0].name : ''
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { funders, fundingItem, loading, loadingForm, modal, noKMRestriction, selectedItem, startDate, endDate, currentPage, total } = this.state

    const columns = [
      {
        title: 'Start',
        width: 2,
        render: ({ start_date }) => formatter.toShortDate(start_date)
      },
      {
        title: 'End',
        width: 2,
        render: ({ end_date }) => formatter.toShortDate(end_date)
      },
      {
        title: 'Funder',
        width: 3,
        render: ({ funder_id, funder_name }) => <div style={{ textTransform: 'capitalize', fontWeight: 'bold' }}><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{funder_name}</a></div>
      },
      {
        title: 'Fund Manager',
        width: 3,
        key: 'manager'
      },
      {
        title: 'Invoice Notes',
        width: 4,
        key: 'invoice_note'
      },
      {
        title: 'CM/SC',
        width: 4,
        key: 'cmsc'
      },
      {
        title: 'Max KM (per week)',
        width: 3,
        key: 'max_km',
        render: ({ max_km }) => max_km !== null && max_km >= 0 ? max_km : <span style={{fontSize: 16}}>∞</span>
      },
      {
        title: 'KM Invoice',
        width: 2,
        key: 'km_rules',
        render: ({ km_rules }) => this.findKMInvoice(km_rules)
      },

      {
        title: '',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { this.hasAccess('updateLegacyClientFundingPeriod')
            ? <Tooltip mouseLeaveDelay={0}  title='Edit'>
              <div onClick={() => this.handleEdit(item)}><Icon type='form' /></div>
            </Tooltip>
            : null }
          { this.hasAccess('deleteLegacyClientFundingPeriod')
            ? <Popconfirm
              title='Confirm to delete this?'
              onConfirm={() => this.handleDelete(item.setting_id, item.funder_name, item.start_date, item.end_date)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
            : null }
        </div>
      }
    ]

    return (<div>
      <Loading loading={loading} blur>
        <Panel title='Funder & Funding Period' subtitle={this.hasAccess('createLegacyClientFundingPeriod')
          ? <div className='btn' onClick={() => this.showFundingSetting()}>Add</div>
          : null }>
          <div className='client-funder-list'>
            <List cols={columns} rows={fundingItem} />
          </div>
        </Panel>
      </Loading>
      <Pager
        size={pageSize}
        total={total}
        totalText={`Total ${total} funders`}
        current={currentPage}
        onChange={(e) => this.changePage(e)}
        style={{ marginTop: '15px' }}
      />

      <SideModal
        title='Funding Settings'
        showModal={modal.show}
        onClose={() => this.hideFundingSetting()}
        buttons={[
          selectedItem.id && this.hasAccess('updateLegacyClientFundingPeriod')
          ? <Button key='saveFunder1' onClick={() => this.handleSave()} feedback={loadingForm}>Save</Button>
          : !selectedItem.id && this.hasAccess('createLegacyClientFundingPeriod')
            ? <Button key='saveFunder2' onClick={() => this.handleSave()} feedback={loadingForm}>Save</Button>
            : null
        ]}
      >
        <Form layout='vertical'>
          <FormItem label='Funder'>
            {getFieldDecorator('funder_id', {
              initialValue: selectedItem.funder_id,
              rules: [
                { required: true, message: 'Please select a funder' }
              ]
            })(
              <Select
                showSearch
                optionFilterProp='children'
                notFoundContent='Not found'
                placeholder='Please select a funder'
                filterOption={(input, option) => this.findFunders(input, option)}
                onChange={this.handleFunderChange}>
                {
                  funders.map((funder, idx) => (
                    <Option key={idx} value={funder.id}>{funder.fullname}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>
          <FormItem label='Start' validateStatus={startDate && startDate.validateStatus} help={startDate && startDate.errorMsg}>
            {getFieldDecorator('start_date', {
              initialValue: selectedItem.start_date ? moment(selectedItem.start_date) : null,
              rules: [
                { required: true, message: 'Please select a start date' },
                { validator: this.handleDateChange }
              ]
            })(
              <DatePicker format={'DD/MM/YYYY'} onChange={(value) => this.setState({ startValue: value })} defaultPickerValue={moment(new Date())} />
            )}
          </FormItem>
          <FormItem label='End' validateStatus={endDate && endDate.validateStatus} help={endDate && endDate.errorMsg}>
            {getFieldDecorator('end_date', {
              initialValue: selectedItem.end_date ? moment(selectedItem.end_date).endOf('day') : null,
              rules: [
                { required: true, message: 'Please select an end date' },
                { validator: this.handleDateChange }
              ]
            })(
              <DatePicker format={'DD/MM/YYYY'} disabledDate={this.disabledStartDate} defaultPickerValue={moment(new Date())} />
            )}
          </FormItem>
          <FormItem label='Fund Manager'>
            {getFieldDecorator('manager', {
              initialValue: selectedItem.manager,
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter name' },
                { whitespace: true, message: 'Please enter name' }
              ]
            })(
              <Input />
            )}
          </FormItem>
          <FormItem label='Invoice Notes'>
            {getFieldDecorator('invoice_note', {
              initialValue: selectedItem.invoice_note,
              rules: [
                { min: 2, message: 'Invoice notes must be between 2 and 128 characters' },
                { max: 128, message: 'Invoice notes must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter invoice notes' }
              ]
            })(
              <Input />
            )}
          </FormItem>
          {/* <FormItem label='Funding ID'>
            {getFieldDecorator('funding_id', {
              initialValue: selectedItem.funding_id,
              rules: [
                { min: 2, message: 'code must be between 2 and 128 characters' },
                { max: 128, message: 'Code must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter code' }
              ]
            })(
              <Input />
            )}
          </FormItem>*/}
          <FormItem label='CM/SC'>
            {getFieldDecorator('cmsc', {
              initialValue: selectedItem.cmsc,
              rules: [
                { min: 1, message: 'CM/SC must be between 1 and 128 characters' },
                { max: 128, message: 'CM/SC must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter CM/SC' }
              ]
            })(
              <Input />
            )}
          </FormItem>
          <Row>
            <Col sm={12}>
              <FormItem label='Max KM (per week)'>
                {getFieldDecorator('max_km', {
                  initialValue: selectedItem.max_km,
                  rules: [
                    { required: !noKMRestriction, message: 'Please enter max km (per week)' }
                  ]
                })(
                  <Input disabled={noKMRestriction} />
                )}
              </FormItem>
            </Col>
            <Col offset={1} sm={11}>
              <FormItem label='No Restriction'>
                {getFieldDecorator('no_km_restriction', {
                  initialValue: selectedItem.no_km_restriction,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleNoKMRestriction}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem label='KM Invoice'>
            {getFieldDecorator('km_rules', {
              initialValue: selectedItem.km_rules,
              rules: [
                { required: true, message: 'Please selct a KM invoice' }
              ]
            })(
              <Select placeholder='Please select a km invoice'>
                {
                  KMRules.map((rule, idx) => (
                    <Option key={idx} value={rule.value}>{rule.name}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>
        </Form>

      </SideModal>
    </div>)
  }

  fetchFunder = async () => {
    try {
      const { clientId } = this.props

      this.setState({ loading: true })
      const item = await clientService.getClientFunders(clientId)
      this.setState({ item, loading: false, clientId: clientId })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.' + e)
      this.setState({ loading: false })
    }
  }

  fetchFunders = async () => {
    const funders = await funderService.listByPage(1, 0, { active: true })
    this.setState({ funders: funders.list })
  }

  handleFunderChange = async (funderId) => {
    const { form } = this.props

    // get funder info
    const { item } = await funderService.get(funderId)

    // get funder start and end date
    const startDate = item.funder_start_date
    const endDate = item.funder_end_date

    // get funder funding type
    const fundType = item.funding_type

    // append to start and end
    if (fundType !== 'A002') {
      form.setFieldsValue({ start_date: moment(startDate).startOf('day'), end_date: moment(endDate).endOf('day') })
    } else {
      form.setFieldsValue({ start_date: null, end_date: null })
    }
  }

  fetchFundingSetting = async (startPage = null) => {
    if (this.hasAccess('listLegacyClientFundingPeriod')) {
      this.setState({ loading: true })
      const { clientId } = this.props
      const { currentPage } = this.state
      const filter = { }

      const page = startPage || currentPage

      filter.client_id = { condition: '=', value: clientId }

      const { list: fundingItem, total } = await clientService.listByPageFunder(page, pageSize, filter)

      this.setState({ fundingItem, total, loading: false, currentPage: page, filter })
    }
  }

  changePage = async (currentPage) => {
    this.fetchFundingSetting(currentPage)
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'tax_code' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      taxCodes: settings.list
    })
  }

  handleEdit (item) {
    const { funders } = this.state
    const currentFunder = funders.find((f) => f.id === item.funder_id)
    this.setState({ currentFunder, selectedItem: item, startValue: item.start_date, noKMRestriction: item.no_km_restriction })
    this.showFundingSetting()
  }

  async handleDelete (id, funderName, startDate, endDate) {
    const { clientId } = this.props
    const res = await clientService.removeClientFunderSetting(clientId, id)

    if (res) {
      log.deleteClientFunder(clientId, `${funderName} from ${formatter.toShortDate(startDate)} to ${formatter.toShortDate(endDate)}`)
      notify.success('Deleted successfully', `Funding deleted successfully`)
      this.fetchFundingSetting()

      this.props.setRefreshActivityLog(true)
    }
  }

  handleDateChange = (rule, value, callback) => {
    const overlapping = this.isDateOverlapping(value)
    if (overlapping.result) {
      try {
        throw new Error(`Date is overlapping with ${overlapping.period}`)
      } catch (err) {
        callback(err)
      }
    } else {
      console.log('no overlapp')
      callback()
    }
  }

  isDateOverlapping = (value, isStart = true) => {
    const { getFieldValue } = this.props.form
    const { fundingItem, selectedItem } = this.state
    const funderId = getFieldValue('funder_id')

    const { setting_id: id } = selectedItem

    const sameFunder = fundingItem.filter(funding => funding.setting_id !== id && funding.funder_id === funderId)

    if (sameFunder && sameFunder.length > 0) {
      for (let i = 0; i < sameFunder.length; i++) {
        const funding = sameFunder[i]
        const isBetween = moment(value).isBetween(funding.start_date, funding.end_date)

        if (isBetween) {
          return { result: true, period: `${moment(funding.start_date).format('DD/MM/YYYY')} - ${moment(funding.end_date).format('DD/MM/YYYY')}` }
        }
      }
    }

    return { result: false }
  }

  disabledStartDate = endValue => {
    const { startValue } = this.state
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  };

  handleSave = () => {
    const { clientId, clientName, form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { setting_id: id } = selectedItem
        const { currentFunder, funders, modal } = this.state
        const { item } = modal
        this.setState({ loadingForm: true })
        delete values.funders
        values.client_id = clientId

        if (values.start_date.format('YYYY-MM-DD') === '1111-01-01' && values.end_date.format('YYYY-MM-DD') === '9999-09-09') {
          values.start_date = values.start_date.format('YYYY-MM-DD')
          values.end_date = values.end_date.format('YYYY-MM-DD')
        } else {
          values.start_date = moment(values.start_date).startOf('day')
          values.end_date = moment(values.end_date).endOf('day')
        }

        // values.start_date = values.start_date.format('YYYY-MM-DD')
        // values.end_date = values.end_date.format('YYYY-MM-DD')
        //values.start_date = moment(values.start_date).startOf('day')
        //values.end_date = moment(values.end_date).endOf('day')
        values.max_km = values.max_km !== '' ? values.max_km : null

        try {
          let response

          if (id) {
            let extraLogs = []

            if (values.funder_id !== selectedItem.funder_id) {
              const funderInfo = funders.find((f) => f.id === values.funder_id)
              extraLogs.push(`Funder changed from "${currentFunder.fullname}" to "${funderInfo.fullname}"`)
            }

            if (values.km_rules !== selectedItem.km_rules) {
              const kmOld = KMRules.find((r) => r.value === selectedItem.km_rules)
              const kmNew = KMRules.find((r) => r.value === values.km_rules)
              extraLogs.push(`KM Invoice changed from "${kmOld.name}" to "${kmNew.name}"`)
            }

            response = await clientService.saveClientFunderSetting(clientId, id, values)
            log.updateClientFunder(clientId, selectedItem, values, ['funder_id', 'km_rules'], extraLogs.join())
            trigger.updateClientFunder(
              clientId,
              `${clientName}: ${currentFunder.fullname}`,
              selectedItem,
              values,
              [
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'max_km' },
                { key: 'cmsc', label: 'CM/SC' },
                { key: 'invoice_note' },
                { key: 'no_km_restriction', label: 'No Restriction' },
                { key: 'manager' }
              ],
              extraLogs.join()
            )
          } else {
            const funder = funders.filter((item) => item.id === values.funder_id)
            const funderName = funder && funder.length > 0 ? funder[0].fullname : ''
            const kmRule = KMRules.filter((item) => item.value === values.km_rules)
            const kmRuleName = kmRule && kmRule.length > 0 ? kmRule[0].name : ''
            let extraContent = ''

            extraContent += `Funder: ${funderName}<br />`
            extraContent += `KM Invoice: ${kmRuleName}<br />`

            response = await clientService.addClientFunderSetting(clientId, values)
            log.addClientFunder(clientId, `${funderName} from ${formatter.toShortDate(values.start_date)} to ${formatter.toShortDate(values.end_date)}`)

            // trigger.addClientFunder(
            //   clientId,
            //   `${clientName}: ${funderName}`
            // )
            trigger.addClientFunder(
              clientId,
              `${clientName}: ${funderName}`,
              funderName,
              values,
              [
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'max_km' },
                { key: 'cmsc', label: 'CM/SC' },
                { key: 'invoice_note' },
                { key: 'no_km_restriction', label: 'No Restriction' },
                { key: 'manager' }
              ],
              extraContent
            )
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `Funding settings saved successfully.`)
            this.fetchFundingSetting()
            this.hideFundingSetting()
          } else {
            notify.error('Unable to save successfully', `Unable to save funding settings successfully. Please try again later.`)
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save funding settings successfully. Please try again later. ${e}`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientFunder))

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HelpdeskMenu } from '../../constants'
import { connect } from 'react-redux'
import { formatter, validator } from '../../util'
import { jobService, communicationService } from '../../services'
import moment from 'moment'
import debounce from 'lodash.debounce'

// UI
import { Button, ControlLabel, List, Page, Pager, SearchInput } from '../../components'

import './styles.css'

import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Switch from 'antd/lib/switch'

const TabPane = Tabs.TabPane
const pageSize = 30
const Option = Select.Option

/**
 * mock data
 */

const mockData = [
  {
    id: 1,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 63912,
    recipient: 'employee',
    recipient_id: 832,
    recipient_name: 'Su Su',
    employee_id: 832,
    employee_name: 'Su Su',
    client_id: 802, // join from job
    client_name: 'Anderson', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-04 03:19:42',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_update',
    subject: 'Your job for Anderson',
    body: 'Hi Su Su, Your job for Anderson (High) ON Mon 08/06/2020 11:00 AM is CANCELLED. Thanks, Anson, Flexi Support',
    created_by: 'Anson',
    created_by_id: 34,
    is_pending_available: true
  },
  {
    id: 2,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46952,
    recipient: 'client',
    recipient_id: 856, // client id
    recipient_name: 'Jefferson',
    employee_id: 202,
    employee_name: 'Sabrina',
    client_id: 856, // join from job
    client_name: 'Jefferson', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-12 09:22:42',
    bound: 'inbound',
    channel_used: 'sms',
    type: 'complaint',
    subject: 'Issues with worker',
    body: 'I would like to make the complain on my worker.',
    created_by: 'Jenny',
    created_by_id: 36,
    is_pending_available: true
  },
  {
    id: 3,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46874,
    recipient: 'employee',
    recipient_id: 832,
    recipient_name: 'Cui',
    employee_id: 832,
    employee_name: 'Cui',
    client_id: 954, // join from job
    client_name: 'Vil (Vilnis)  Dilderats', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-07 15:30:20',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_create',
    subject: 'Your shift for Diidarets',
    body: 'Hi Cui: Your job for Vil (Vilnis)  Dilderats (Standard) When: Tue 09/06/2020 09:00 AM - 11:00 AM At: Scope Australia SRS, 97 Lusher Rd, Croydon Task: Respite and community access. E.g. taking for lunch, going for a walk, going to the shops, playing games and general company. Reply OK to confirm. Thanks, Ayla, Flexi Support',
    created_by: 'system',
    created_by_id: 0,
    is_pending_available: false
  },
  {
    id: 4,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 90344,
    recipient: 'employee',
    recipient_id: 503,
    recipient_name: 'Fiona (Shao Fang)',
    employee_id: 503,
    employee_name: 'Fiona (Shao Fang)',
    client_id: 201, // join from job
    client_name: 'Bi Ying Wu', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-08 19:44:23',
    bound: 'outbound',
    channel_used: 'system',
    type: 'job_cancel',
    subject: 'Your job for Bi Ying  Wu',
    body: 'Hi Fiona (Shao Fang), Your job for Bi Ying  Wu  (Standard) ON Thu 04/06/2020 01:00 PM is CANCELLED Please write 3 hour(s) and “Late Cancellation” on your timesheet Please note all services have been put on HOLD until further notice Thanks, Saliha, Flexi Support',
    created_by: 'Saliha',
    created_by_id: 46,
    is_pending_available: true
  },
  {
    id: 5,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 46262,
    recipient: 'individual',
    recipient_id: 0,
    recipient_name: 'Alicia Bosch',
    employee_id: 599,
    employee_name: 'Mariam',
    client_id: 355, // join from job
    client_name: 'Bosch', // join from job
    recipient_additional_info: 'Daughter',
    recipient_additional_email: 'jasminebosch@gmail.com',
    recipient_additional_phone_no: '0428 092 207',
    created_at: '2020-06-12 11:55:10',
    bound: 'inbound',
    channel_used: 'call',
    type: 'incident',
    subject: 'Incident happened during serving',
    body: 'Washing machine is damaged while serving',
    created_by: 'Saliha',
    created_by_id: 46,
    is_pending_available: true
  },
  {
    id: 6,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 64624,
    recipient: 'client',
    recipient_id: 251,
    recipient_name: 'Caren',
    employee_id: 515,
    employee_name: 'Sarah',
    client_id: 251, // join from job
    client_name: 'Caren', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-15 01:11:32',
    bound: 'outbound',
    channel_used: 'call',
    type: 'client_contact',
    subject: 'Sarah is sick',
    body: 'Inform Caren that Sarah is sick.',
    created_by: 'Winnie',
    created_by_id: 37,
    is_pending_available: false,
    actions: [
      {
        id: 1,
        created_at: '2020-06-15 01:11:32',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Inform Caren that Sarah is sick.',
        is_follow_up: true
      },
      {
        id: 2,
        created_at: '2020-06-15 13:04:22',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Caren is being informed. The job is cancelled',
        is_follow_up: false
      }
    ]
  },
  {
    id: 7,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 88212,
    recipient: 'individual',
    recipient_id: 0,
    recipient_name: 'Arven Moses',
    employee_id: 708,
    employee_name: 'Yi Heng',
    client_id: 331, // join from job
    client_name: 'Moses', // join from job
    recipient_additional_info: 'Dad',
    recipient_additional_email: '',
    recipient_additional_phone_no: '0412 783 210',
    created_at: '2020-06-16 17:30:21',
    bound: 'inbound',
    channel_used: 'letter',
    type: 'feedback_negative',
    subject: 'Bad atitude to dad',
    body: 'Complain employee giving bad attitude to client',
    created_by: 'Tiffany',
    created_by_id: 38,
    is_pending_available: true,
    actions: [
      {
        id: 3,
        created_at: '2020-06-16 17:30:21',
        created_by: 'Tiffany',
        created_by_id: 38,
        description: 'Complain employee giving bad attitude to client',
        is_follow_up: true
      },
      {
        id: 4,
        created_at: '2020-06-16 20:33:34',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Have been talked to Yi Heng and the case is not as described as Moses.',
        is_follow_up: true
      },
      {
        id: 5,
        created_at: '2020-06-17 07:05:34',
        created_by: 'Winnie',
        created_by_id: 37,
        description: 'Confirmed with Moses and the cases confirmed. Will further investigate',
        is_follow_up: true
      }
    ]
  },
  {
    id: 8,
    co_id: 1,
    target: 'private',
    module: 'job',
    module_id: 90271,
    recipient: 'client',
    recipient_id: 390,
    recipient_name: 'Johnny',
    employee_id: 762,
    employee_name: 'Ashton',
    client_id: 390, // join from job
    client_name: 'Johnny', // join from job
    recipient_additional_info: '',
    recipient_additional_email: '',
    recipient_additional_phone_no: '',
    created_at: '2020-06-17 02:22:14',
    bound: 'inbound',
    channel_used: 'email',
    type: 'feedback_positive',
    subject: 'Help alot on client',
    body: 'Good help',
    created_by: 'Janice',
    created_by_id: 27,
    is_pending_available: false
  }
]

const mockJobs = [
  {
    acc_ref: "R00245",
    base_job_id: 9844,
    billing_category_id: 9,
    billing_rate_id: 21,
    cancellation_reason: null,
    cancellation_time: null,
    charge_hours: null,
    client_id: 245,
    client_leave_end: null,
    client_leave_id: null,
    client_leave_start: null,
    created_at: "2020-04-20T16:00:09.056Z",
    day_number: null,
    emergency: false,
    emergency_invoice: false,
    emergency_pay: false,
    emp_accref: "E00523",
    emp_firstname: "Vivienne (Ying)",
    emp_gender: "female",
    emp_id: 513,
    emp_lastname: "Shaw",
    emp_suburb: "Glen Waverley",
    employee_email: "vivienne.shawying@gmail.com",
    employee_id: 513,
    employee_leave_end: null,
    employee_leave_id: null,
    employee_leave_start: null,
    first_name: "Karen",
    fund_fullname: "NDIS",
    funder_id: 38,
    gender: "female",
    get_up_hours: null,
    holiday_date: null,
    holiday_name: null,
    id: 55408,
    is_after_hours_job: null,
    is_cancel: false,
    is_delete: false,
    job_after_hours: null,
    job_after_hours_weekend: null,
    job_end_date: "2020-07-19T22:15:00.000Z",
    job_hours: 14,
    job_normal_hours: null,
    job_normal_hours_weekend: null,
    job_start_date: "2020-07-19T20:15:00.000Z",
    job_start_day: "Monday",
    job_type: "single",
    kms: 0,
    last_name: "Hiam",
    month_setting: null,
    notes: "null",
    payroll: "high",
    payroll_category: "high",
    sleepover: 0,
    status: "pending",
    string_end_date: "20/07/2020",
    string_start_date: "20/07/2020",
    suburb: "Clayton",
    tasks: "Assist client with showering and personal grooming , changing pads, toileting and use of hoist",
    updated_at: "2020-04-20T16:00:09.056Z"
  }
]

const typeList = [
  { name: 'Job Create', value: 'job_create' },
  { name: 'Job Update', value: 'job_update' },
  { name: 'Job Cancel', value: 'job_cancel' },
  { name: 'Complaint', value: 'complaint' },
  { name: 'Client Contact', value: 'client_contact' },
  { name: 'Negative Feedback', value: 'feedback_negative' },
  { name: 'Positive Feedback', value: 'feedback_positive' },
  { name: 'Incident', value: 'incident' }
]

const contactTypeList = [
  { name: 'Employee', value: 'employee' },
  { name: 'Client', value: 'client' },
  { name: 'Others', value: 'individual' }
]

class Helpdesk extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPageConfirmation: 1,
      currentPageUnsent: 1,
      loading: false,
      confirmation: { list: [], total: 0 },
      unsent: { list: [], total: 0 },
      searchText: '',
      tabKey: '1',
      filterConfirmationTab: {},
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.initialLists()
  }

  render () {
    const { tabKey } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={HelpdeskMenu} backLink='/' />
        </Page.Left>
        <Page.Content full>
          <Page.Header icon={<Icon type='customer-service' />} title='Communication'>
            <Link to={`/helpdesk/add`}>
              <div className='btn'>
                Add Ticket
              </div>
            </Link>
          </Page.Header>
          {
            this.confirmationTab()
          }
        </Page.Content>
      </Page.Body>
    )
  }

  confirmationTab = () => {
    const { currentPageConfirmation, confirmation, loading } = this.state

    const getTypeWord = (type, list) => {
      const filterType = list.find(e => e.value === type)
      return filterType ? filterType.name : type
    }

    const getContactName = (recipientName, recipient, info) => (
      <div>{`${recipientName}${info ? ` - ${info}` : ''}`}</div>
    )

    const confirmationColumns = [
      {
        title: 'Entered Date',
        width: 4,
        render: ({ created_at: createdAt }) => formatter.toStandardDate(createdAt)
      },
      {
        title: 'Type',
        width: 3,
        render: ({ type }) => getTypeWord(type, typeList)
      },
      {
        title: 'Subject',
        width: 5,
        key: 'subject'
      },
      {
        title: 'Contact Type',
        width: 3,
        render: ({ recipient }) => getTypeWord(recipient, contactTypeList)
      },
      {
        title: 'Contact Person',
        width: 4,
        render: ({ recipient_name: recipientName, recipient, recipient_additional_info: info }) => getContactName(recipientName, recipient, info)
      },
      {
        title: 'Status',
        width: 2,
        render: ({ is_pending_available: isPending }) => !isPending ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => <div className='action-buttons'><Link to={`/helpdesk/${id}`}><Tooltip mouseLeaveDelay={0} title='Edit Ticket'> <Icon type='form' style={{ color: 'orange' }} /></Tooltip></Link>
        </div>

      }
    ]

    const onSelectMsgType = () => {

    }

    return (
      <div>
        <Page.Filter>
          <Row gutter={8}>
            <Col lg={6}>
              <ControlLabel>Message Type</ControlLabel>
              <Select defaultValue='' allowClear style={{ width: 200 }} onChange={onSelectMsgType}>
                { typeList.map(p => (
                  <Option key={p.value} value={p.value}>{p.name}</Option>
                ))}
              </Select>
            </Col>
            <Col lg={6}>
              <ControlLabel>Contact Type</ControlLabel>
              <Select defaultValue='' allowClear style={{ width: 200 }} onChange={onSelectMsgType}>
                { contactTypeList.map(p => (
                  <Option key={p.value} value={p.value}>{p.name}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Page.Filter>
        <div className='helpdesk-list'>
          <Skeleton loading={loading} active>
            <List cols={confirmationColumns} rows={confirmation.list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={confirmation.total}
          totalText={`Total ${confirmation.total} records`}
          current={currentPageConfirmation}
          onChange={(page) => this.refreshList('confirmation', page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }


  initialLists = async () => {
    const { currentPageConfirmation, currentPageUnsent } = this.state
    this.setState({ loading: true })

    // const unsent = await communicationService.listUnsentByPage(currentPageUnsent, pageSize, {})

    // const filter = {}
    // filter.should_confirm = { condition: '=', value: 'true' }
    // const confirmation = await jobService.getEmails(currentPageConfirmation, pageSize, filter)

    const confirmation = { list: mockData.slice(), total: mockData.length }

    // this.setState({ loading: false, confirmation: confirmation, unsent: unsent })
    this.setState({ loading: false, confirmation: confirmation })
  }

  fetchConfirmation = async (searchText, currentPage = 1, filter = this.state.filterConfirmationTab, sort = {}) => {
    this.setState({ loading: true })

    filter.should_confirm = { condition: '=', value: 'true' }
    const confirmation = await jobService.getEmails(currentPage, pageSize, filter, sort, searchText)

    this.setState({ confirmation, loading: false, currentPageConfirmation: currentPage, searchText })
  }

  refreshList = async (filterParam, currentPage) => {
    this.setState({ loading: true })

    // Confirmation search filters
    if (filterParam === 'confirmation') {
      const { filterConfirmationTab, searchText } = this.state
      const filter = {}

      if (!Array.isArray(filter.$and)) {
        filter.$and = []
      }

      filter.$and.push(filterConfirmationTab)
      filter.should_confirm = { condition: '=', value: 'true' }

      const confirmation = await jobService.getEmails(currentPage, pageSize, filter, {}, searchText)
      return this.setState({ confirmation, loading: false, currentPageConfirmation: currentPage })
    }

    // Unsent
    if (filterParam === 'unsent') {
      const { searchText } = this.state
      this.fetchUnsent(searchText, currentPage)
    }
  }

  onSearchName (value) {
    const { tabKey } = this.state

    if (tabKey === '1') {
      this.fetchConfirmation(value)
    } else if (tabKey === '2') {
      this.fetchUnsent(value)
    } else {
      console.log('Search Failed.')
    }

    this.setState({searchText: value})
  }

  onTabClick = (key) => {
    this.setState({ tabKey: key })
  }
}

export default Form.create()(Helpdesk)

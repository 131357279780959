/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { authService, funderService, settingGeneralService, settingBillingRateService, logService,
  settingCustomIdentifier, settingCustomValue, funderFileService, fvpRatesService, fvpFunderService, fvpFundManagerService, fvpRateTaxCodeService } from '../../../services'
import { fetchingFunders, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import { accRef, formatter, log, trigger, validator, extraValue } from '../../../util'
import Moment from 'moment-timezone'

// UI
import { Button, Loading, List, Page, Panel, CustomIdentifierList, MandatoryList } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import ActivityLog from '../ActivityLog'
import ClientList from '../Client'
import Feedback from '../Feedback'
import File from '../File'
import FundManager from '../FundManager'
import JobFvp from '../JobFvp'
import JobJvp from '../JobJvp'

import './styles.css'

const { confirm, warning } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const ABNDuplicatedMsg = 'Duplicate record found.'

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 19 }
}
const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/custom-identifier' },
  { tabId: 3, path: '/clients' },
  { tabId: 4, path: '/jobs' },
  { tabId: 7, path: '/feedbacks' },
  { tabId: 5, path: '/files' },
  { tabId: 6, path: '/logs' }
]

export class Funder extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      item: {},
      funding: [],
      billingRate: [],
      fvpBillingRate: [],
      clientList: [],
      fundManagerList: [],
      logList: [],
      loading: false,
      loadingForm: false,
      showSave: false,
      showEdit: true,
      taxCodes: [],
      fvpTaxCodes: [],
      showFundManager: false,
      showFundManagerModal: false,
      showFundingDate: false,
      identifierSetting: [],
      shouldRefreshFiles: false,
      settingsMandatory: [],
      uploadedMandatory: [],
      hasMissingMandatoryFiles: false,
      duplicatedABNInfo: [],
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.debounceValidateABNDuplicate = debounce(this.debounceValidateABNDuplicate, 1000)
  }

  componentDidMount () {
    this.checkUrl()
    if (this.isEdit()) {
      this.fetchFunder()
      this.fetchLogs()
      this.fetchFiles()
    }
    this.fetchBillingRate()
    this.fetchSettings()
    this.fetchSettingFilesMandatory()
    this.fetchCustomIdentifierSetting()
    this.fetchFundManager()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  checkUrl = () => {
    // if the url is /:id only (but not /add), redirect to /:id/:type
    const { history, match, location } = this.props
    const { currentTab } = this.state
    const { id, params = {} } = match
    const { type = '' } = params

    if (!(id === 'add' || id === 'new')) {
      const tab = TabList.find(e => `${e.tabId}` === currentTab)

      if (!type) {
        if (location && location.pathname) {
          history.replace(`${location.pathname}${tab && tab.tabId ? `${tab.path}` : ''}`)
        }
      }
    }
  }

  async generateAccRef () {
    const number = await accRef.funder()
    this.setState({ newAccRef: number })
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i].long_name
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i].long_name
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i].long_name
      }
    }

    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { currentTab, item, loading, showSave, showEdit, showFundManager, shouldRefreshFiles } = this.state

    const isInfoTab = currentTab === '1'

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Funder (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Funder'}>
            { this.isEdit() && this.hasAccess('deleteFunder') && isInfoTab
              ? (showSave
                ? (<div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                  Delete
                </div>)
                : null)
              : null }
            { showEdit && this.isEdit() && this.hasAccess('updateFunder') && isInfoTab
              ? (<div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>)
              : null }
            { (showSave || !this.isEdit()) && isInfoTab
              ? (<div className='btn' onClick={this.checkBeforeSave}>
                Save
                </div>)
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            {this.isEdit()
              ? <div className='funder-panel-header'>
                {loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{item.acc_ref}</div></div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Phone Number</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.phone_number || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Suburb</div>
                      <div className='funder-panel-header-value'>{item.suburb}</div>
                    </Col>
                    <Col lg={4} />
                  </Row>}
              </div> : null}
            <div className='funder-panel-body'>
              <Tabs
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={this.handleTabChange}
              >
                <TabPane tab='Funder Info' key='1'>
                  {this.infoTab()}
                </TabPane>
                {/* {this.isEdit() && showFundManager
                  ? <TabPane tab='Fund Manager' key='6'>
                    <FundManager funderId={match.params.id} history={this.props.history} onUpdate={() => this.fetchFunderFundManager()} />
                  </TabPane>
                  : null } */}
                {this.isEdit()
                  ? <TabPane tab='Custom Identifier' key={TabList[1].tabId}>
                    {this.customIdenfierTab()}
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Clients' key={TabList[2].tabId}>
                    <ClientList key={`clnfd${currentTab}`} funderId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {/* {this.isEdit()
                  ? <TabPane tab='Jobs' key='7'>
                    <JobFvp funderId={match.params.id} data={item} history={this.props.history} />
                  </TabPane>
                  : null} */}
                {this.isEdit()
                  ? <TabPane tab='Jobs' key={TabList[3].tabId}>
                    <JobJvp key={`jbsfd${currentTab}`} funderId={match.params.id} data={item} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Feedbacks' key={TabList[4].tabId}>
                    <Feedback key={`ff${currentTab}`} funderId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
                {this.isEdit() && this.hasAccess('listFunderFile')
                  ? <TabPane tab='Files' key={TabList[5].tabId}>
                    <File funderId={match.params.id} history={this.props.history} onUpdateInfo={() => this.handleFilesUpdateAction()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                  </TabPane>
                  : null}
                {this.isEdit()
                  ? <TabPane tab='Activity Log' key={TabList[6].tabId}>
                    <ActivityLog funderId={match.params.id} history={this.props.history} />
                  </TabPane>
                  : null}
              </Tabs>
            </div>
          </div>


        </Page.Content>
      </Page.Body>
    )
  }

  handleTabChange = (index) => {
    const id = this.getId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/funders/${id}${tab.path}`)
    }
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, funding, fundManagerList, item, loading, loadingForm, billingRate, fvpBillingRate, fvpTaxCodes, taxCodes, showFundingDate, showFundManager, showFundManagerModal, settingsMandatory, uploadedMandatory } = this.state
    const { getFieldDecorator, getFieldValue } = form
    const isEnableFundingPeriod = getFieldValue('funding_type') !== 'A002'

    return <Form>
      <Panel title='Funding Settings'>
        <Loading loading={loading} blur>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Funding Type'>
                {getFieldDecorator('funding_type', {
                  initialValue: item.funding_type || '',
                  rules: [
                    { required: true, message: 'Please select a funding type.' }
                  ]
                })(
                  <Select
                    onChange={this.changeFundingType} placeholder='Please select a Funding Type'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      funding.map((states) => (
                        <Option key={states.value} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              {/* <FormItem {...sideBySideFormItemLayout} label='Rate Set'>
                {getFieldDecorator('rate_set', {
                  initialValue: item.rate_set || '',
                  rules: [
                    { required: true, message: 'Please select a rate set' }
                  ]
                })(
                  <Select
                    placeholder='Please select a Rate Set'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      billingRate.map((bill, idx) => {
                        return <Option key={`bill-${bill.id}`} value={bill.id}>{bill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem> */}
              <FormItem {...sideBySideFormItemLayout} label='Rate Set'>
                {getFieldDecorator('fvp_rate_id', {
                  initialValue: item.fvp_rate_id || '',
                  rules: [
                    { required: true, message: 'Please select a rate set' }
                  ]
                })(
                  <Select
                    placeholder='Please select a Rate Set'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    disabled={!!item.fvp_rate_id}
                  >
                    {
                      fvpBillingRate.map((bill, idx) => {
                        return <Option key={`bill-${bill.id}`} value={bill.id}>{bill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Output Prefix'>
                {getFieldDecorator('output_prefix', {
                  initialValue: item.output_prefix || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          {/** MS0130 - FUNDER>INFO: SA Dates */}
          {/* { showFundingDate
            ? <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Agreement Start Date'>
                  {getFieldDecorator('funder_start_date',
                    item.funder_start_date ? { initialValue: Moment(item.funder_start_date) || null } : {}

                  )(
                    <DatePicker format={dateFormat} />
                  )}

                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Agreement End Date'>
                  {getFieldDecorator('funder_end_date',
                    item.funder_end_date ? { initialValue: Moment(item.funder_end_date) || null } : {}
                  )(
                    <DatePicker format={dateFormat} />
                  )}

                </FormItem>
              </Col>
              </Row>
            : null } */}

          <Row gutter={12}>
            {/* <Col lg={12}> */}
              {/* `<FormItem {...sideBySideFormItemLayout} label='Tax Code'>
                {getFieldDecorator('tax_code', {
                  initialValue: item.tax_code || '',
                  rules: [
                    { required: true, message: 'Please select a tax code' }
                  ]
                })(`
                  <Select
                    placeholder='Please select a tax code'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      taxCodes.map((tax, idx) => {
                        return <Option key={`tax-${tax.id}`} value={tax.id}>{tax.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>` */}
              {/* <FormItem {...sideBySideFormItemLayout} label='Tax Code'>
                {getFieldDecorator('fvp_tax_code_id', {
                  initialValue: item.fvp_tax_code_id || '',
                  rules: [
                    { required: true, message: 'Please select a tax code' }
                  ]
                })(
                  <Select
                    placeholder='Please select a tax code'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      fvpTaxCodes.map((tax, idx) => {
                        return <Option key={`tax-${tax.id}`} value={tax.id}>{tax.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem> */}
            {/* </Col> */}
            <Col lg={12}>
              { this.isEdit()
                ? <FormItem {...sideBySideFormItemLayout} label='Fund Manager'>
                    {getFieldDecorator('is_fund_manager', {
                      initialValue: item.is_fund_manager || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        // onChange={this.handleFundManager()}
                      />
                    )}
                  </FormItem>
                : null }
            </Col>
          </Row>
        </Loading>
      </Panel>

      {/* <Panel title='Default Funding Period for Client'>
        <Loading loading={loading} blur>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem
                {...sideBySideFormItemLayout}
                label='Is Funding Period Everlasting?'
                extra={`If select "YES", the funding period for client would be everlasting and only one period available. Else, the client would have multiple periods for this funder. This input is disabled if Funding Type is "NDIS Funding".`}
              >
                {getFieldDecorator('is_default_funder_period', {
                  initialValue: showFundingDate || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    disabled={!isEnableFundingPeriod}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleDefaultFundingPeriod}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          { isEnableFundingPeriod
          ? <div>
            <Row gutter={12}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Default Period Start Date'>
                  {getFieldDecorator('funder_start_date',
                    item.funder_start_date ? { initialValue: Moment(item.funder_start_date) || null } : {}

                  )(
                    <DatePicker disabled={!showFundingDate} format={dateFormat} />
                  )}

                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Default Period End Date'>
                  {getFieldDecorator('funder_end_date',
                    item.funder_end_date ? { initialValue: Moment(item.funder_end_date) || null } : {}
                  )(
                    <DatePicker disabled={!showFundingDate} format={dateFormat} />
                  )}

                </FormItem>
              </Col>
            </Row>
          </div>
          : null }

          <Row gutter={12}>
            <Col lg={12}>
              { this.isEdit()
                ? <FormItem {...sideBySideFormItemLayout} label='Fund Manager'>
                    {getFieldDecorator('funding_manager', {
                      initialValue: showFundManager || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={this.handleFundManager()}
                      />
                    )}
                  </FormItem>
                : null }
            </Col>
          </Row>
        </Loading>
      </Panel> */}

      <Panel title='Funder Information'>
        <Loading loading={loading} blur>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' extra='Enter ABN without spacing'>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || '',
                  rules: [
                    { required: true, message: ' ' },
                    { validator: this.validateABN }
                  ]
                })(
                  <Input onChange={(e) => this.validateABNDuplicate(e)} />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Name' hasFeedback>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || '',
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address then select address on dropdown list' },
                { whitespace: true, message: 'Please enter address then select address on dropdown list' },
                { validator: this.checkAddressInput }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={states.value} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Agreement Contact Person'>
                {getFieldDecorator('contact_person', {
                  initialValue: item.contact_person
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              {/* <FormItem {...sideBySideFormItemLayout} label='Fax' hasFeedback>
                {getFieldDecorator('fax', {
                  initialValue: item.fax || '',
                  rules: [
                    { min: 2, message: 'Fax Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Fax Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter fax number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem> */}
              <FormItem {...sideBySideFormItemLayout} label='Agreement Contact Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number,
                  rules: [
                    { min: 2, message: 'Agreement Contact Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Agreement Contact Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Agreement Email' hasFeedback>
              {getFieldDecorator('email', {
                initialValue: item.email,
                rules: [
                  // {
                  //   type: 'email',
                  //   message: 'Please provide a valid Email'
                  // },
                  // { min: 2, message: 'Email must be between 2 and 128 characters' },
                  // { max: 128, message: 'Email must be between 2 and 128 characters' },
                  // { whitespace: true, message: 'Please enter email' }
                  // { required: true, message: 'Please enter agreement email' },
                ]
              })(
                  <Input />
              )}
              {/* <div className='text-form-explain'>For multiple email address, please use comma "," as delimiter.</div> */}
            </FormItem>
          </Row>



          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Rostering Contact Person' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>

              <FormItem {...sideBySideFormItemLayout} label='Rostering Contact Number' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number
                })(
                  <Input />
                )}
              </FormItem>

            </Col>
          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Rostering Email' hasFeedback>
              {getFieldDecorator('after_contact_email', {
                initialValue: item.after_contact_email,
                rules: [
                ]
              })(
                  <Input />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Accounts Contact Person' hasFeedback>
                {getFieldDecorator('account_contact_name', {
                  initialValue: item.account_contact_name
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>

              <FormItem {...sideBySideFormItemLayout} label='Accounts Contact Number' hasFeedback>
                {getFieldDecorator('account_contact_number', {
                  initialValue: item.account_contact_number
                })(
                  <Input />
                )}
              </FormItem>

            </Col>
          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Accounts Email' hasFeedback>
              {getFieldDecorator('account_contact_email', {
                initialValue: item.account_contact_email,
                rules: [
                ]
              })(
                  <Input />
              )}
            </FormItem>
          </Row>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Loading>
      </Panel>

      <Panel title='Required Files'>
        <div className='mandatory-files-panel'>
          <MandatoryList
            form={form}
            moduleType={'funder'}
            moduleId={item.id}
            settingsMandatory={settingsMandatory}
            items={uploadedMandatory}
          />
        </div>
      </Panel>

      {/* <Panel title='Custom Identifiers'>
        <CustomIdentifierList
          form={form}
          moduleType={'funder'}
          moduleId={item.id}
          identifierSetting={identifierSetting}
          items={item.extra_fields || []}
        />
      </Panel> */}

      <Modal
        title="Add Fund Manager"
        style={{ top: 20 }}
        visible={showFundManagerModal}
        maskClosable={false}
        confirmLoading={loadingForm}
        // onOk={() => this.handleSaveFundManager()}
        onCancel={() => this.closeFundManagerModal()}
        footer={[
          <Button key='close' feedback={loadingForm} ghost onClick={() => this.closeFundManagerModal()}>Cancel</Button>,
          <Button key='save' feedback={loadingForm} onClick={() => this.handleSaveFundManager()}>Add</Button>,
        ]}
      >
        <Form layout='vertical'>
          { showFundManagerModal
            ? <FormItem label='Fund Manager'>
              {getFieldDecorator('selected_fund_manager', {
                rules: [
                  { required: true, message: 'Please select a fund manager' }
                ]
              })(
                <Select placeholder='Please select one/multiple fund manager(s)'
                  mode='multiple'
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    fundManagerList.map((manager, idx) => {
                      return <Option key={`fm${idx}`} value={manager.id}>{manager.fullname}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            : null }
        </Form>
      </Modal>
    </Form>
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { currentTab, identifierSetting } = this.state

    return <CustomIdentifierList
      key={`csrfd${currentTab}`}
      moduleType={'funder'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  checkAddressInput = (rule, value, callback) => {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    if (value && value.length > 0) {
      if (!getFieldValue('postcode') && !getFieldValue('state') && !getFieldValue('suburb')) {
        callback(new Error('Please enter address then select address on dropdown list'))
      }
    } else if (validator.isEmptyString(value)) {
      setFieldsValue({ postcode: null, state: '', suburb: null })
    }

    callback()
  }

  fetchFunder = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await funderService.get(id)
      this.setState({
        item,
        loading: false,
        showFundingDate: item && item.funder_start_date && item.funder_end_date,
        showFundManager: item && validator.isNotEmptyArray(item.funding_manager)
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchBillingRate = async () => {
    const billRate = await settingBillingRateService.listByPage(1, 0)
    const fvpBillRate = await fvpRatesService.listByPage(1, 0)

    let b1 = []
    let b2 = []

    if (billRate && validator.isNotEmptyArray(billRate.list)) {
      b1 = billRate.list
      this.setState({ billingRate: billRate.list })
    }

    if (fvpBillRate && validator.isNotEmptyArray(fvpBillRate.list)) {
      b2 = fvpBillRate.list
    }

    this.setState({
      billingRate: b1,
      fvpBillingRate: b2
    })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'funding' },
        { condition: '=', value: 'tax_code' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const taxCodes = await fvpRateTaxCodeService.listAll()

    if (settings && validator.isNotEmptyArray(settings.list)) {
      this.setState({
        settings: settings.list,
        funding: settings.list.filter(item => item.type === 'funding'),
        taxCodes: settings.list.filter(item => item.type === 'tax_code')
      })
    }

    if (validator.isNotEmptyArray(taxCodes)) {
      this.setState({
        fvpTaxCodes: taxCodes
      })
    }
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'funder' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)

    if (customFields && validator.isNotEmptyArray(customFields.list)) {
      this.setState({ identifierSetting: customFields.list })
    }
  }

  fetchFundManager = async () => {
    const filter = {}
    filter.active = { condition: '=', value: true }

    const m = await fvpFundManagerService.listByPage(1, 0, filter)

    if (m && validator.isNotEmptyArray(m.list)) {
      this.setState({ fundManagerList: m.list })
    }
  }

  fetchFunderFundManager = async () => {
    const { form } = this.props
    const { setFieldsValue } = form
    const id = this.getId()
    const m = await fvpFunderService.getFunderManager(id)

    if (m && validator.isArray(m)) {
      const showFundManager = validator.isNotEmptyArray(m)
      this.setState({ item: {...this.state.item, funding_manager: m}, showFundManager })
      setFieldsValue({ funding_manager: showFundManager })
    }
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'funder' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchSettingFilesMandatory = async (currentPage = 1) => {
    const filter = {}
    this.setState({ loading: true })

    filter.sub_cat_active = { condition: '=', value: true }
    filter.sub_cat_has_mandatory = { condition: '=', value: true }
    filter.sub_cat_classification = { condition: '=', value: 'funder' }

    const { list: documents } = await funderFileService.listDocumentByPage(currentPage, 0, filter)

    this.setState({ loading: false, settingsMandatory: documents })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: 'funder' }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'funder' }

    const fileTypes = await funderFileService.listByPage(1, 0, filter)
    this.setState({ uploadedMandatory: fileTypes.list })
  }

  changeFundingType = (value) => {
    if (value === 'A002') {
      this.handleDefaultFundingPeriod(false, true)
    } else {
      this.handleDefaultFundingPeriod(true)
    }
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this funder?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await funderService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Funder deleted successfully.')
            history.replace('/funders')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete funder successfully. Please try again later.')
        }
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleFilesUpdateAction = () => {
    this.fetchFiles()
  }

  handleDefaultFundingPeriod = (value, isForceUpdateDefault = false) => {
    const { form } = this.props
    this.setState({ showFundingDate: value })
    if (value) {
      form.setFieldsValue({
        'funder_start_date': Moment('1111-01-01 00:00:00+00'),
        'funder_end_date': Moment('9999-09-09 00:00:00+00')
      })
    } else {
      form.setFieldsValue({
        'funder_start_date': undefined,
        'funder_end_date': undefined
      })
    }

    if (isForceUpdateDefault) {
      form.setFieldsValue({
        'is_default_funder_period': false
      })
    }
  }

  handleFundManager = () => async (value) => {
    const { form } = this.props
    const { setFieldsValue } = form
    const { item, showFundManager, showFundManagerModal } = this.state
    const { deleteFundManager } = this

    if (value === false && showFundManager === true) {
      confirm({
        title: 'Are you sure you want to delete all fund managers?',
        content: 'Press Ok to continue, Cancel to return',
        async onOk () {
          await deleteFundManager(value)
        },
        onCancel () {
          setFieldsValue({ funding_manager: true })
        }
      })
    } else {
      this.setState({ showFundManagerModal: value })
    }
  }

  deleteFundManager = async (value) => {
    const { form } = this.props
    const { setFieldsValue } = form
    const { item, showFundManager, showFundManagerModal } = this.state

    try {
      const id = this.getId()
      this.setState({ loading: true })
      const r = await fvpFunderService.removeFunderManager(id)

      if (r && r.id) {
        const { item } = this.state
        const fundManager = item.funding_manager

        let list = []

        if (validator.isArray(fundManager)) {
          for (let i = 0; i < fundManager.length; i++) {
            const itm = fundManager[i]
            if (itm && itm.funder_manager_fullname) {
              list.push(itm.funder_manager_fullname)
            }
          }
          log.removeFunderFundManager(id, list.join(', '))
        }

        await this.fetchFunderFundManager()

        notify.success('Deleted successfully', 'Fund manager deleted successfully.')
        setFieldsValue({ selected_fund_manager: undefined, funding_manager: false })
        this.setState({ loading: false, showFundManager: false, showFundManagerModal: false })

        this.props.setRefreshActivityLog(true)
      }
    } catch (e) {
      notify.error('Unable to delete successfully', 'Unable to delete fund manager successfully. Please try again later.')
      setFieldsValue({ funding_manager: true })
      this.setState({ loading: false })
    }
  }

  checkBeforeSave = () => {
    const { form } = this.props
    const { isABNDuplicated, duplicatedABNInfo } = this.state
    const { getFieldValue } = form
    const { handleSave } = this
    let hasFileList = []

    const mandatory = getFieldValue('mandatory')
    this.setState({ hasMissingMandatoryFiles: false })

    // Check whether all mandatory categories have file uploaded; missing file uploaded disactive employee profile but still allow to save
    if (validator.isArray(mandatory)) {
      hasFileList = mandatory.filter(item => item.file_name && item.file_url)
    }
    const isShowABNDuplicatedAlert = isABNDuplicated && duplicatedABNInfo.length > 0
    const isShowMandatoryAlert = validator.isArray(mandatory) && mandatory.length !== hasFileList.length

    if (isShowMandatoryAlert || isShowABNDuplicatedAlert) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The funder will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            { isShowABNDuplicatedAlert
              ? <div className='duplicate-warnings'>
                <div style={{ fontSize: '14px', margin: '0px 0px 5px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong style={{color: 'rgb(238, 27, 27)'}}> Funder with Duplicated ABN Number</strong>
                </div>
                {duplicatedABNInfo.map((item, index) => {
                  const url = `/funders/${item.id}`
                  return (
                    <div key={`cbs-dup-${index}`}>
                      <a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.fullname} - Ref: ${item.acc_ref}`}
                      </a>
                    </div>
                  )
                })}
                <br />
              </div>
              : null }

            { isShowMandatoryAlert
              ? <div className='duplicate-warnings'>
                {this.setState({ hasMissingMandatoryFiles: true })}
                <div style={{ fontSize: '14px', margin: '0px 0px 5px' }}>
                  <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                  <strong> Missing files in "Required Files". Funder profile will be deactivate when proceed to save</strong>
                </div>
                <br />
              </div>
              : null }

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave() },
        onCancel () { }
      })
    } else {
      handleSave()
    }

  }

  handleSave = () => {
    const { form } = this.props
    const { getFieldValue } = form
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingFunders } = this.props
        const { item, hasMissingMandatoryFiles, uploadedMandatory } = this.state
        this.setState({ loading: true })

        values.fullname = values.fullname.trim().replace(/\s\s+/g, ' ')
        values.email = values.email ? values.email.trim().toLowerCase().replace(/[!&\/\\#()$^`~%'":*?<>{}]/g, '') : values.email
        values.after_contact_email = values.after_contact_email ? values.after_contact_email.trim().toLowerCase().replace(/[!&\/\\#()$^`~%'":*?<>{}]/g, '') : values.after_contact_email
        values.account_contact_email = values.account_contact_email ? values.account_contact_email.trim().toLowerCase().replace(/[!&\/\\#()$^`~%'":*?<>{}]/g, '') : values.account_contact_email
        delete values.is_default_funder_period

        // if (values.funder_start_date) {
        //   values.funder_start_date = values.funder_start_date.format('YYYY-MM-DD')
        // }
        // if (values.funder_end_date) {
        //   values.funder_end_date = values.funder_end_date.format('YYYY-MM-DD')
        // }

        if (hasMissingMandatoryFiles) {
          values.active = false
        }

        // delete values.funding_manager

        try {
          if (this.isEdit()) {
            const response = await funderService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            const fileList = values.mandatory || []
            delete values.mandatory

            if (response.id) {
              const extraLogs = []
              // const logItemBefore = extraValue.getBeforeExtraValueLog(item)
              // const logItemAfter = extraValue.getAfterExtraValueLog(values)
              // delete item.extra_fields
              // delete values.extra_fields

              // if (values.rate_set !== item.rate_set) {
              //   const oldRate = await this.getRateSetName(item.rate_set)
              //   const newRate = await this.getRateSetName(values.rate_set)
              //   extraLogs.push(`Legacy Rate Set changed from "${oldRate}" to "${newRate}"`)
              // }

              if (values.fvp_rate_id !== item.fvp_rate_id) {
                const oldRate = await this.getRateSetName(item.fvp_rate_id)
                const newRate = await this.getRateSetName(values.fvp_rate_id)
                extraLogs.push(`Rate Set changed from "${oldRate}" to "${newRate}"`)
              }

              log.updateFunder(
                response.id,
                item,
                values,
                ['rate_set', 'fvp_rate_id', 'fvp_tax_code_id'],
                [
                  { key: 'is_fund_manager', label: 'Fund Manager' },
                  { key: 'contact_person', label: 'Agreement Contact Person' },
                  { key: 'phone_number', label: 'Agreement Contact Number' },
                  { key: 'email', label: 'Agreement Email' },
                  { key: 'after_contact_name', label: 'Rostering Contact Person' },
                  { key: 'after_contact_number', label: 'Rostering Contact Number' },
                  { key: 'after_contact_email', label: 'Rostering Email' },
                  { key: 'account_contact_name', label: 'Accounts Contact Person' },
                  { key: 'account_contact_number', label: 'Accounts Contact Number' },
                  { key: 'account_contact_email', label: 'Accounts Email' }
                ],
                extraLogs.join()
              )

              trigger.updateFunder(
                response.id,
                values.fullname,
                item,
                values,
                [
                  { key: 'abn' },
                  { key: 'active' },
                  { key: 'fullname', label: 'Name' },
                  { key: 'phone_number', label: 'Agreement Contact Number' },
                  { key: 'email', label: 'Agreement Email' },
                  // { key: 'fax' },
                  { key: 'contact_person', label: 'Agreement Contact Person' },
                  { key: 'after_contact_name', label: 'Rostering Contact Person' },
                  { key: 'after_contact_number', label: 'Rostering Contact Number' },
                  { key: 'after_contact_email', label: 'Rostering Email' },
                  { key: 'account_contact_name', label: 'Accounts Contact Person' },
                  { key: 'account_contact_number', label: 'Accounts Contact Number' },
                  { key: 'account_contact_email', label: 'Accounts Email' },
                  { key: 'address' },
                  { key: 'is_fund_manager', label: 'Fund Manager'},
                  { key: 'unit_building', label: 'Unit/Building' }
                  // { key: 'funder_start_date', label: 'Default Period Start Date' },
                  // { key: 'funder_end_date', label: 'Default Period End Date' }
                ],
                extraLogs.join()
              )

              // prepare and log each mandatory files uploaded
              for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]

                if (file.file_name && file.file_url) {
                  let extraFileLog = []
                  let logItemBefore = {}

                  // get previously uploaded file, if have value return prepare log item
                  const item = uploadedMandatory.find(e => e.id === file.id)
                  if (item) {
                    logItemBefore.label = item.label || ''
                    logItemBefore.file_name = formatter.toStandardFileName(item.file_name) || ''
                    logItemBefore.active = item.active
                    logItemBefore.issuance_date = item.issuance_date
                    logItemBefore.expiry_date = item.expiry_date
                  }

                  let logItemAfter = {
                    label: file.label || '',
                    file_name: formatter.toStandardFileName(file.file_name) || '',
                    active: true,
                    issuance_date: file.issuance_date
                  }
                  if (file.expiry_date) {
                    logItemAfter.expiry_date = file.expiry_date
                  }
                  extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                  if (item) {
                    log.updateFunderFile(response.id, logItemBefore, logItemAfter, [], extraFileLog.join())
                  } else {
                    log.addFunderFile(response.id, logItemAfter, [], extraFileLog.join())
                  }
                }
              }

              notify.success('Saved successfully', 'Funder saved successfully.')
              this.fetchFunder()
              fetchingFunders(true)
            }
          } else {
            const response = await funderService.add(values)
            this.setState({ loading: false })

            const fileList = values.mandatory || []
            delete values.mandatory

            if (response.id) {
              const { id, acc_ref } = response
              log.addFunder(response.id, `New funder ${values.fullname}`)

              trigger.addFunder(
                id,
                values.fullname,
                values,
                [
                  { key: 'abn' },
                  { key: 'active' },
                  { key: 'fullname', label: 'Name' },
                  { key: 'phone_number', label: 'Agreement Contact Number' },
                  { key: 'email', label: 'Agreement Email' },
                  // { key: 'fax' },
                  { key: 'contact_person', label: 'Agreement Contact Person' },
                  { key: 'after_contact_name', label: 'Rostering Contact Person' },
                  { key: 'after_contact_number', label: 'Rostering Contact Number' },
                  { key: 'after_contact_email', label: 'Rostering Email' },
                  { key: 'account_contact_name', label: 'Accounts Contact Person' },
                  { key: 'account_contact_number', label: 'Accounts Contact Number' },
                  { key: 'account_contact_email', label: 'Accounts Email' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' },
                  // { key: 'funder_start_date', label: 'Default Period Start Date' },
                  // { key: 'funder_end_date', label: 'Default Period End Date' },
                ]
              )

              // prepare and log each mandatory files uploaded
              for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]

                if (file.file_name && file.file_url) {
                  let extraFileLog = []
                  let logItemAfter = {
                    label: file.label || '',
                    file_name: formatter.toStandardFileName(file.file_name) || '',
                    active: true,
                    issuance_date: file.issuance_date
                  }
                  if (file.expiry_date) {
                    logItemAfter.expiry_date = file.expiry_date
                  }
                  extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                  log.addClientFile(id, logItemAfter, [], extraFileLog.join())
                }
              }

              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Funder saved successfully.')
              // history.replace(`/funders/${id}`)
              window.location.replace(`/funders/${id}`)
              fetchingFunders(true)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save funder successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.fetchLogs()
      }
    })
  }

  handleSaveFundManager = async () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['selected_fund_manager'], async (errors, values) => {
      if (!errors) {
        try {
          const { form } = this.props
          const { fundManagerList } = this.state
          const { getFieldValue } = form
          const id = this.getId()

          this.setState({ loadingForm: true })
          const selectIds = getFieldValue('selected_fund_manager')

          const data = {
            id,
            selectIds
          }

          const r = await fvpFunderService.addFunderManager(data)

          if (r && r.id) {
            let list = []
            for (let i = 0; i < selectIds.length; i++) {
              const itm = fundManagerList.find(e => e.id === parseInt(selectIds[i]))
              // the list getting from fund manager list, so the name will be with fullname
              // if for existed fund manager list,it will be funder_manager_fullname
              if (itm && itm.fullname) {
                list.push(itm.fullname)
              }
            }

            log.addFunderFundManager(id, list.join(','))
            await this.fetchFunderFundManager()

            notify.success('Saved successfully', 'Fund Manager saved successfully.')

            this.props.setRefreshActivityLog(true)
            this.setState({ loadingForm: false, showFundManagerModal: false })
          } else {
            notify.error('Unable to save successfully', 'Unable to save fund manager successfully. Please try again later.')
            this.setState({ loadingForm: false})
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save fund manager successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  validateABN = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
        callback(new Error('Please enter ABN'))
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      callback()
    }
  }

  validateABNDuplicate = (e) => {
    e.persist()
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value
    this.debounceValidateABNDuplicate(id, value)
  }

  debounceValidateABNDuplicate = async (id, value) => {
    const { form } = this.props
    const data = {
      id,
      abn: value
    }


    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ abn: { value: value } })
      this.setState({ isABNDuplicated: false })

      const r = await funderService.checkDuplicate(data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ isABNDuplicated: true, duplicatedABNInfo: r.data })
        const w = window

        warning({
          title: <div style={{color: 'rgb(238, 27, 27)'}}>Funder with Duplicated ABN Number</div>,
          content: (
            <div className='duplicate-warnings'>
              {r.data.map((item, index) => {
                    const url = `/funders/${item.id}`
                    return (
                      <div key={`dup-${index}`}>
                        <a href={url} rel='noopener noreferrer' target='_blank'>
                          {`${item.fullname} - Ref: ${item.acc_ref}`}
                        </a>
                      </div>
                    )
                  })}
            </div>
          ),
          okText: 'OK',
          onOk () {
            // w.open(`/funders/${r.ref_id}`)
          }
        })

        // set the field value with errors
        // form.setFields({ abn: { value: value, errors: [{ message: ABNDuplicatedMsg }] } })
      } else {
        this.setState({ isABNDuplicated: false })
      }
    }
  }

  async getRateSetName (id) {
    const { billingRate } = this.state
    const rate = await billingRate.filter(item => item.id === id)

    return rate.length > 0 ? rate[0].name : ''
  }

  closeFundManagerModal = () => {
    const { form } = this.props
    const { setFieldsValue } = form

    this.setState({ showFundManagerModal: false })
    setFieldsValue({ funding_manager: false })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingFunders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Funder))

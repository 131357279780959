import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

export default class List extends React.Component {
  constructor (props) {
    super(props)
    const { defaultSort = {} } = props
    this.state = {
      defaultSort,
      pristine: true,
      sorting: {}
    }
  }

  render () {
    const { cols, emptyMessage, hideColumns, highlightIf, link, loading = true, onClick, rows, showEmptyMessage = true, subcols, subrows } = this.props
    const { defaultSort, pristine, sorting } = this.state

    const actualLink = (row) => {
      let actualLink = link || ''

      if (actualLink.indexOf('<') >= 0) {
        const key = /<(.*?)>/g.exec(actualLink)
        actualLink = actualLink.replace(/<.*?>/, row[key[1]])
      }

      return actualLink
    }

    const highlight = (row) => {
      if (highlightIf && row[highlightIf.key] === highlightIf.value) {
        return true
      }

      return false
    }

    const listRow = (row) => (
      <Row gutter={8} type='flex' justify='space-around' align='middle'>
        {cols.map((col, idx) => (
          <Col span={col.width} className='wd-list-div-col' key={idx} style={{ justifyContent: col.align || 'flex-start' }}>
            <div >
              {col.render ? (
                col.render(row)
              ) : col.key ? (
                row[col.key]
              ) : ''}
            </div>
          </Col>
        ))}
        {
          subrows
            ? row[subrows] && row[subrows].map((subrow, index) => {
              // console.log('subrows', row, subcols)
              return subrow && subcols && subcols.map((subcol, i) => {
                return <Col key={i} span={subcol.width} className='wd-list-div-col' style={{ justifyContent: subcol.align || 'flex-start' }}>
                  <div style={{ paddingLeft: i === 0 ? '15px' : '0px' }}>
                    {subcol.render && subcol.subkey && row[subcol.subkey] ? (
                      subcol.render(row[subcol.subkey][index], index)
                    ) : subcol.subkey && row[subcol.subkey] ? (
                      row[subcol.subkey][index]
                    ) : subcol.render
                      ? subcol.render(row, index)
                      : null }
                  </div>
                </Col>
              })
            })
            : null
        }
      </Row>
    )

    return (
      <div className='wd-list-div-container'>
        <ul className='wd-list-div-body'>
          {!hideColumns ? (
            <li className='wd-list-div-header'>
              <Row gutter={8}>
                {cols.map((col, idx) => {
                  const { key, onSort, title, width } = col
                  const sortKey = pristine ? defaultSort[key] : sorting[idx]

                  return typeof onSort === 'function' ? (
                    <Col key={idx} span={width}>
                      <div className='wd-list-div-header-sort' onClick={(e) => this.handleSort(e, idx, key, onSort)}>
                        <span>{title}</span>

                        <span className='wd-list-div-header-caret'>
                          <span className='icon icon-sort-asc' style={{ color: sortKey === 1 ? 'var(--themeColorAlt)' : 'var(--themeColorDark)' }} />

                          <span className='icon icon-sort-desc' style={{ color: sortKey === -1 ? 'var(--themeColorAlt)' : 'var(--themeColorDark)' }} />
                        </span>
                      </div>
                    </Col>
                  ) : (
                    <Col key={idx} span={width}>{title}</Col>
                  )
                })}
              </Row>
            </li>
          ) : null}

          {showEmptyMessage && (!loading && rows.length < 1) ? (
            <Alert message={emptyMessage || 'The list is empty. Try adding something into it.'} showIcon type='info' />
          ) : null}

          {rows.map((row, idx) => (
            <li className={'wd-list-div-row' + (highlight(row) ? ' wd-list-div-row-highlight' : '')} key={idx}>
              {link || onClick ? (
                <Link to={link ? actualLink(row) : '#'} onClick={(e) => this.handleClick(e, row)}>
                  {listRow(row)}
                </Link>
              ) : listRow(row)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  handleClick (e, row) {
    const { onClick } = this.props

    if (typeof onClick === 'function') {
      e.preventDefault()
      onClick(row)
    }
  }

  handleSort (e, idx, key, onSort) {
    if (typeof onSort === 'function') {
      const { defaultSort, pristine, sorting } = this.state
      let order = pristine ? defaultSort[key] : sorting[idx] || 0
      order = order === 1 ? -1 : order === -1 ? 0 : 1
      this.setState({ pristine: false, sorting: { [idx]: order } })
      onSort(key, order)
    }
  }
}

List.propTypes = {
  align: PropTypes.string,
  cols: PropTypes.array.isRequired,
  defaultSort: PropTypes.object,
  emptyMessage: PropTypes.string,
  hideColumns: PropTypes.bool,
  highlight: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rows: PropTypes.array.isRequired,
  showEmptyMessage: PropTypes.bool
}

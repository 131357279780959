/* global google */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatter, validator } from '../../util'
import { compose, withProps, lifecycle, withState, withHandlers } from 'recompose'
import { googleMapApi } from '../../config'
import { settingGeneralService, clientService, employeeService } from '../../services'
import * as geolib from 'geolib'

// UI
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle, Polyline } from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import { Page } from '../../components'

import './styles.css'

import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Spin from 'antd/lib/spin'

// const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer')
const FormItem = Form.Item
const TabPane = Tabs.TabPane

const Option = Select.Option
const clientMarker = {
  url: process.env.PUBLIC_URL + '/img/home.png',
  anchor: new window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(23, 82)
}
const carerMarker = {
  url: process.env.PUBLIC_URL + '/img/placeholder2.png',
  anchor: new window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(23, 82)
}

const circleColorList = [
  { color: '#FFA4B5', fillOpacity: 0.2 },
  { color: '#FF819D', fillOpacity: 0.2 },
  { color: '#FE7659', fillOpacity: 0.2 },
  { color: '#DF5735', fillOpacity: 0.2 },
  { color: '#FF2D00', fillOpacity: 0.1 }
]

const defaultCenter = { lat: -37.8829427, lng: 145.1599169 }

const radiusLabelStyle = (color) => ({
  textAlign: 'center',
  width:'80px',
  color: color,
  backgroundColor: '#00000000',
  fontSize: '18px',
  fontWeight: '400',
  padding: '8px'
})

const FlexiMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapApi}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillReceiveProps () {
      const refs = {}
      this.setState({
        flexMap: map => {
          if (!map || this.props.origin === true) { return }
          const { zoomToMarkers: idx, zoomToCoord, selectedType, selectedClient, clients, carers } = this.props
          // console.log('map clients', clients)
          // console.log('map carers', carers)
          // console.log('map selected client', selectedClient)

          let coord = null
          let child = []

          if (selectedType === 'client') {
            if (idx && map.props.children[0].length !== 0) {
              let coord = {}
              const { clients } = this.props
              child = clients.filter(item => item.id === idx)
              if (child.length > 0) {
                const latitude = child[0].latitude
                const longitude = child[0].longitude
                if (latitude !== null && longitude !== null) {
                  coord = { lat: formatter.toNumber(latitude), lng: formatter.toNumber(longitude) }
                }
              }
            }
          } else {
            if (selectedClient.length > 0) {
              child = selectedClient.filter(item => item.id === idx)
            } else if (idx && this.props.clients.length !== 0) {
              const { clients } = this.props
              child = clients.filter(item => item.id === idx)
            }
            if (child.length > 0) {
              const latitude = child[0].latitude
              const longitude = child[0].longitude
              if (latitude !== null && longitude !== null) {
                coord = { lat: formatter.toNumber(latitude), lng: formatter.toNumber(longitude) }
              }
            }
          }

          if (zoomToCoord || this.props.zoomToCoord) {
            const zoc = zoomToCoord || this.props.zoomToCoord
            if (zoc.latitude !== null && zoc.longitude !== null) {
              coord = { lat: parseFloat(zoc.latitude), lng: parseFloat(zoc.longitude) }
            }
          }

          if (coord) {
            map.panTo(coord)
          }
          this.props.offOrigin()
        }
      })
    }
  }),
  withState('isOpen', 'updateIsOpen', null),
  withHandlers({
    onToggleOpen: ({ updateIsOpen }) => (key, type) => {
      updateIsOpen(`${type}${key}`)
    },
    onToggleOff: ({ updateIsOpen }) => () => {
      updateIsOpen(null)
    },
    onRegionChangeComplete: (ki) => {
      // let boundingBox = this.getBoundingBox(screenCoordinate)
      // this.setState({screenCoordinate, boundingBox})
    },
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
      // const centerCoord = markerClusterer.getCenter()
      // console.log(`Current clicked markers length: ${clickedMarkers.length}`)
      // console.log(clickedMarkers)
      // console.log('centerCoord', centerCoord)
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log('MAP PROPS ', props)
  if (props.zoomToMarkers) {
    if (props.selectedType === 'client') {
      props.onToggleOpen(props.zoomToMarkers, 'Client')
    } else if (props.selectedType === 'carer') {
      props.onToggleOpen(props.zoomToMarkers, 'Client')
    }
  }

  const zoomBound = props.zoomToCoord && props.zoomToCoord.latitude && props.zoomToCoord.longitude ? getBound({ latitude: formatter.toNumber(props.zoomToCoord.latitude), longitude: formatter.toNumber(props.zoomToCoord.longitude) }) : null

  return (
    <GoogleMap
      zoom={props.zoom}
      defaultCenter={Object.assign({}, defaultCenter)}
      ref={props.flexMap}
      origin={props.origin}
      // onDragEnd={(e) => props.onBoundsChanged(e)}
    >
      {/* <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={60}
        minimumClusterSize={1000}
        styles={[
          { marginTop: '20px' }
        ]}
      > */}
      { props.clients.map((client, idx) => {
        const bound = props.isMarkerShown && props.currentTab === 'carer' && client.latitude && client.longitude ? getBound({ latitude: formatter.toNumber(client.latitude), longitude: formatter.toNumber(client.longitude) }) : null

        return (
          props.isMarkerShown && client.latitude && client.longitude
          ? <div key={`client${idx}`}>
            <Marker
              key={`client${idx}`}
              position={{ lat: formatter.toNumber(client.latitude), lng: formatter.toNumber(client.longitude) }}
              onClick={() => props.onToggleOpen(client.id, 'Client')}
              icon={clientMarker}
            >
              { props.isOpen === `Client${client.id}` && <InfoWindow onCloseClick={props.onToggleOpen}>
                <div>
                  <div className='map-link'><a href={`/clients/${client.id}`} rel='noopener noreferrer' target='_blank'>{`Client - ${client.first_name} ${client.last_name}`}</a></div>
                  { client.leave_id
                    ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                      <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                      &nbsp;{`Leave ${formatter.toShortDate(client.leave_start_date)} - ${client.leave_is_ufn ? 'UFN' : formatter.toShortDate(client.leave_end_date)}`}</div> : null }
                  <div className='map-address'>{client.address}</div>
                  { client.public_alert || client.private_alert || client.private_notes ? (
                    <div className='map-info' style={{ marginTop: 7 }}>
                      <Alert
                        message={
                          <div dangerouslySetInnerHTML={{
                            __html: `
                            ${client.public_alert && client.public_alert != null ? `${formatter.toHtmlLineBreak(client.public_alert)}<br />` : ''}
                            ${client.private_alert && client.private_alert != null ? `${formatter.toHtmlLineBreak(client.private_alert)}<br />` : ''}
                            ${client.private_notes && client.private_notes != null ? `${formatter.toHtmlLineBreak(client.private_notes)}<br />` : ''}`
                          }} />
                        }
                        type='warning'
                        showIcon
                      />
                    </div>
                  ) : null }
                </div>
              </InfoWindow>}
            </Marker>
            { props.currentTab === 'carer' && circleColorList.map((e, idx) => (
              <div key={`ccr${idx}`}>
                <MarkerWithLabel
                  position={{ lat: formatter.toNumber(client.latitude) - (circleColorList.length - idx) * 0.01787, lng: formatter.toNumber(client.longitude) }}
                  labelAnchor={new google.maps.Point(40, 40)}
                  labelStyle={radiusLabelStyle(e.color)}
                  icon={' '}
                >
                  <div>{`${2*(circleColorList.length - idx)} km`}</div>
                </MarkerWithLabel>
                <Circle
                  center={{ lat: formatter.toNumber(client.latitude), lng: formatter.toNumber(client.longitude) }}
                  options={{
                    strokeColor: e.color,
                    strokeWeight: 3,
                    fillColor: '#000000',
                      fillOpacity: 0
                    // fillColor: e.color,
                    // fillOpacity: e.fillOpacity
                  }}
                  radius={2000 * (circleColorList.length - idx)}
                  key={`ccr${idx}`}
                  >
                  <div style={{fontSize: '30px'}}>2km</div>
                </Circle>
              </div>
            ))}
            </div>
            : null
        )
      })
      }
      {/* </MarkerClusterer> */}

      {
        props.carers
          ? (
          // <MarkerClusterer
          //   onClick={props.onMarkerClustererClick}
          //   averageCenter
          //   enableRetinaIcons
          //   gridSize={60}
          //   minimumClusterSize={1000}
          // >
            props.carers.map((carer, idx) => {
              const bound = props.isMarkerShown && props.currentTab === 'client' && carer.latitude && carer.longitude ? getBound({ latitude: formatter.toNumber(carer.latitude), longitude: formatter.toNumber(carer.longitude) }) : null

              return (
                props.isMarkerShown && carer.latitude && carer.longitude
                ? <div key={`carer${idx}`}>
                    <Marker
                      key={`carer${idx}`}
                      position={{ lat: formatter.toNumber(carer.latitude), lng: formatter.toNumber(carer.longitude) }}
                      onClick={() => props.onToggleOpen(carer.id, 'Carer')}
                      icon={carerMarker}
                      // label={{
                      //   text: `${carer.first_name} ${carer.last_name} ${ carer.leave_id ? `(Leave ${formatter.toShortDate(carer.leave_start)} - ${formatter.toShortDate(carer.leave_end)})` : ''}`,
                      //   fontWeight: 'bold',
                      //   fontSize: '11px',
                      //   color: 'black'
                      // }}
                    >
                      { props.isOpen === `Carer${carer.id}` && <InfoWindow onCloseClick={props.onToggleOpen}>
                        <div>
                          <div className='map-link'><a href={`/employees/${carer.id}`} rel='noopener noreferrer' target='_blank'>{`Carer - ${carer.first_name} ${carer.last_name}`}</a> </div>
                          { carer.leave_id
                            ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
                              <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                              &nbsp;{`Leave ${formatter.toShortDate(carer.leave_start_date)} - ${formatter.toShortDate(carer.leave_end_date)}`}</div> : null}
                          <div className='map-address'>{carer.address}</div>
                          { carer.public_alert || carer.private_alert || carer.notes ? (
                            <div className='map-info' style={{ marginTop: 7 }}>
                              <Alert
                                message={
                                  <div dangerouslySetInnerHTML={{
                                    __html: `
                                    ${carer.public_alert && carer.public_alert != null ? `${formatter.toHtmlLineBreak(carer.public_alert)}<br />` : ''}
                                    ${carer.private_alert && carer.private_alert != null ? `${formatter.toHtmlLineBreak(carer.private_alert)}<br />` : ''}
                                    ${carer.notes && carer.notes != null ? `${formatter.toHtmlLineBreak(carer.notes)}<br />` : ''}`
                                  }} />
                                }
                                type='warning'
                                showIcon
                              />
                            </div>
                          ) : null }
                        </div>
                      </InfoWindow>}
                    </Marker>
                    { props.currentTab === 'client' && circleColorList.map((e, idx) => (
                      <div key={`cvr${idx}`}>
                        <MarkerWithLabel
                          position={{ lat: formatter.toNumber(carer.latitude) - (circleColorList.length - idx) * 0.01787, lng: formatter.toNumber(carer.longitude) }}
                          labelAnchor={new google.maps.Point(40, 40)}
                          labelStyle={radiusLabelStyle(e.color)}
                          icon={' '}
                        >
                          <div>{`${2*(circleColorList.length - idx)} km`}</div>
                        </MarkerWithLabel>
                        <Circle
                          center={{ lat: formatter.toNumber(carer.latitude), lng: formatter.toNumber(carer.longitude) }}
                          options={{
                            strokeColor: e.color,
                            strokeWeight: 3,
                            fillColor: '#000000',
                            fillOpacity: 0
                            // fillColor: e.color,
                            // fillOpacity: e.fillOpacity
                          }}
                          radius={2000 * (circleColorList.length - idx)}
                          key={`cvr${idx}`}
                          >
                          <div style={{fontSize: '30px'}}>3km</div>
                        </Circle>
                      </div>
                    ))}
                </div>
                : null
              )
            })
          // </MarkerClusterer>
          ) : null
      }

      {
        props.zoomToCoord
          ? <div>
            <Marker
              key={`addressMarker`}
              position={{ lat: parseFloat(props.zoomToCoord.latitude), lng: parseFloat(props.zoomToCoord.longitude) }}
            />
            { zoomBound && circleColorList.map((e, idx) => (
              <div key={`car${idx}`}>
                <MarkerWithLabel
                  position={{ lat: formatter.toNumber(props.zoomToCoord.latitude) - (circleColorList.length - idx) * 0.01787, lng: formatter.toNumber(props.zoomToCoord.longitude) }}
                  labelAnchor={new google.maps.Point(40, 40)}
                  labelStyle={radiusLabelStyle(e.color)}
                  icon={' '}
                >
                  <div>{`${2*(circleColorList.length - idx)} km`}</div>
                </MarkerWithLabel>
                <Circle
                  center={{ lat: formatter.toNumber(props.zoomToCoord.latitude), lng: formatter.toNumber(props.zoomToCoord.longitude) }}
                  options={{
                    strokeColor: e.color,
                    strokeWeight: 3,
                    fillColor: '#000000',
                    fillOpacity: 0
                    // fillColor: e.color,
                    // fillOpacity: e.fillOpacity
                  }}
                  radius={2000 * (circleColorList.length - idx)}
                  key={`car${idx}`}
                />
              </div>
              ))}
            </div>
          : null
      }
    </GoogleMap>
  )
})

const getBound = (center, length = 7000) => {
  const bound = geolib.getBoundsOfDistance(center, length)
  let newArr = []
  if (validator.isNotEmptyArray(bound)) {
    for(let i = 0; i < bound.length; i++) {
      const b = bound[i]
      newArr.push({lat: b.latitude, lng: b.longitude})
    }
  }
  return newArr
}

export class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clients: [],
      clientsFilter: [],
      employees: [],
      genders: [],
      languages: [],
      skills: [],
      zoomLevel: 11,
      origin: false,
      boundaryBox: {
        westLng: 0,
        southLat: 0,
        eastLng: 0,
        northLat: 0
      },
      inputLanguages: [],
      inputSkills: [],
      inputGender: '',
      inputAddress: '',
      selectedType: 'carer',
      selectedClient: [],
      spinLoading: false
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const { selectedType } = this.state
    const place = this.googleAddress.getPlace()

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())

    this.setState({
      inputAddress: place.geometry ? { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() } : this.state.inputAddress,
      zoomLevel: 15,
      zoomToMarkers: null,
      origin: false
    })
    this.props.form.setFieldsValue({
      address: place.formatted_address,
      client: undefined,
      employee: undefined
    })
  }

  onRegionChangeComplete = (screenCoordinate) => {
    // let boundingBox = this.getBoundingBox(screenCoordinate)
    // this.setState({screenCoordinate, boundingBox})
  }

  handleOffOrigin () {
    // console.log('Off Origin')
    this.setState({ zoomToMarkers: null, origin: true })
  }

  handleOnOrigin () {
    // console.log('On Origin')
    this.setState({ origin: false })
  }

  // To clear all states and form field values
  handleClearAll () {
    const { setFieldsValue } = this.props.form
    this.setState({
      zoomLevel: 11,
      zoomToMarkers: null,
      inputGender: '',
      inputLanguages: [],
      inputSkills: [],
      selectedClient: []
    }, () => {
      setFieldsValue({
        client: undefined,
        employee: undefined,
        gender: undefined,
        languages: undefined,
        skills: undefined
      })

      this.fetchTypeList([], [], [])
    })
  }

  // To reset map zoom level and address field
  handleClearAddress () {
    const { setFieldsValue } = this.props.form

    this.setState({
      zoomLevel: 11,
      inputAddress: ''
    })
    setFieldsValue({
      address: ''
    })
  }

  selectClientCarer = (value, option) => {
    const { setFieldsValue } = this.props.form
    const { selectedType } = this.state

    setFieldsValue({
      address: ''
    })

    if (!value) {
      this.setState({ zoomToMarkers: Object.assign({}, defaultCenter),
        zoomLevel: 11,
        inputAddress: '',
        selectedClient: []
      })
    } else {
      this.setState({ zoomToMarkers: value,
        zoomLevel: 15,
        inputAddress: ''
      })
      this.handleSelectedGetInfo(value, selectedType)
    }
  }

  handleSelectedGetInfo = async (id, type) => {
    const { setFieldsValue } = this.props.form
    const filter = {}
    const selectedClient = []
    // console.log('type', type, ' ; ', id)

    const { item } = await clientService.get(id)

    if (item) {
      selectedClient.push(item)

      filter.gender = item.preferred_gender || undefined

      if (item.language_ids && validator.isArray(item.language_ids)) {
        filter.languages = item.language_ids
      } else {
        filter.languages = undefined
      }

      if (item.skill_ids && validator.isArray(item.skill_ids)) {
        filter.skills = item.skill_ids
      } else {
        filter.skills = undefined
      }


      this.setState({
        inputGender: item ? item.preferred_gender : '',
        inputLanguages: filter.languages || [],
        inputSkills: filter.skills || [],
        selectedClient,
        selectedType: type,
        origin: false
      }, () => {
        setFieldsValue({
          gender: filter.gender ? filter.gender : undefined,
          languages: validator.isArray(filter.languages) ? filter.languages : undefined,
          skills: validator.isArray(filter.skills) ? filter.skills : undefined,
        })
      })
    }

    this.fetchTypeList(filter.languages, filter.skills, filter.gender)
  }

  // Tab changed need to clear all form fields and set opposite type
  handleTabChange = async (type) => {
    const { setFieldsValue } = this.props.form
    this.setState({ spinLoading: true })

    const filter = {}
    filter.active = { condition: '=', value: true }

    if (type === 'client') {
      const { list } = await employeeService.listByPageAddress(1, 0, { active: filter.active })

      this.setState({ employees: list })
    } else {
      const { list } = await clientService.listByPageAddress(1, 0, { active: filter.active })

      this.setState({ clients: list })
    }

    this.setState({
      inputGender: '',
      inputLanguages: [],
      inputSkills: [],
      spinLoading: false,
      selectedType: (type === 'carer' ? 'client' : 'carer'),
      selectedClient: [],
      zoomLevel: 11
    })

    setFieldsValue({
      client: undefined,
      employee: undefined,
      gender: undefined,
      languages: undefined,
      skills: undefined
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { genders, languages, skills, clients, clientsFilter, employees, selectedType, selectedClient, spinLoading } = this.state
    // console.log('clients', clients)
    // console.log('employees', employees)
    // console.log('inputs', 'L:', this.state.inputLanguages, 'S:', this.state.inputSkills, 'G:', this.state.inputGender)

    const carerTab = () => {
      return (
        <Spin spinning={spinLoading}>
          <Form className='form'>
            <Row gutter={24}>
              <Col lg={2}>
                <div className='map-control-label'>Filter by </div>
                <div className='btn map' style={{ margin: '7px 0' }} onClick={e => this.handleClearAll()}>Clear</div>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Client</div>
                <FormItem>
                  {getFieldDecorator('client')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      placeholder='Client'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      onChange={(value, option) => this.selectClientCarer(value, option)}
                      filterOption={(input, option) => this.findClients(input, option)}>
                      { clientsFilter
                        ? clientsFilter.map((client, idx) => {
                          return <Option key={`cfil${client.id}`} value={client.id}>{`${client.first_name} ${client.last_name}`} { client.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start_date)} - ${client.leave_is_ufn ? 'UFN' : formatter.toShortDate(client.leave_end_date)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null} </Option>
                        })
                        : null }
                    </Select>)}
                </FormItem>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Preferred Gender</div>
                <FormItem>
                  {getFieldDecorator('gender')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      onChange={(value, option) => this.filterGender(value, option)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        genders.map((gender, idx) => {
                          return <Option key={`gender-${gender.value}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={7}>
                <div className='map-control-label'>Required Skills</div>
                <FormItem>
                  {getFieldDecorator('skills', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterSkills(value, option, selectedType)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        skills.map((skill, idx) => {
                          return <Option key={`skill-${skill.id}`} value={skill.id}>{skill.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={7}>
                <div className='map-control-label'>Preferred Languages</div>
                <FormItem>
                  {getFieldDecorator('languages', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterLanguages(value, option)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        languages.map((lang, idx) => {
                          return <Option key={`lang-${lang.id}`} value={lang.id}>{lang.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      )
    }

    const clientTab = () => {
      return (
        <Spin spinning={spinLoading}>
          <Form className='form'>
            <Row gutter={24}>
              <Col lg={2}>
                <div className='btn map' style={{ margin: '25px 0' }} onClick={e => this.handleClearAll()}>Clear</div>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Client</div>
                <FormItem>
                  {getFieldDecorator('client')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      placeholder='Client'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      onChange={(value, option) => this.selectClientCarer(value, option)}
                      filterOption={(input, option) => this.findClients(input, option)}>
                      {
                        clientsFilter
                          ? clientsFilter.map((client, idx) => {
                            return <Option key={`clit${client.id}`} value={client.id}>{`${client.first_name} ${client.last_name}`} { client.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start_date)} - ${client.leave_is_ufn ? 'UFN' : formatter.toShortDate(client.leave_end_date)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null} </Option>
                          }) : null
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Preferred Gender</div>
                <FormItem>
                  {getFieldDecorator('gender')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      onChange={(value, option) => this.filterGender(value, option)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        genders.map((gender, idx) => {
                          return <Option key={`gend${gender.value}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={7}>
                <div className='map-control-label'>Required Skills</div>
                <FormItem>
                  {getFieldDecorator('skills', {
                    // initialValue: ['10', '12'],
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterSkills(value, option, selectedType)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        skills.map((skill, idx) => {
                          return <Option key={`skl${skill.id}`} value={skill.id}>{skill.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={7}>
                <div className='map-control-label'>Preferred Languages</div>
                <FormItem>
                  {getFieldDecorator('languages', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterLanguages(value, option)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        languages.map((lang, idx) => {
                          return <Option key={`languag-${lang.id}`} value={lang.id}>{lang.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      )
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header icon={<Icon type='global' />} title='Full Map' />

          <div className='map-header'>

            <div className='map-panel-tabs'>
              <Tabs defaultActiveKey='1' size='default' onChange={() => this.handleTabChange(selectedType)}>
                <TabPane tab='Find Carer' key='1'>
                  { carerTab() }
                </TabPane>
                <TabPane tab='List Client' key='2'>
                  { clientTab() }
                </TabPane>
              </Tabs>
            </div>

          </div>

          <div className='map-header'>
            <Form className='form'>
              <Row gutter={24}>
                <Col lg={2}>
                  <div className='map-control-label'>Filter by </div>
                  <div className='btn map' style={{ margin: '7px 0' }} onClick={e => this.handleClearAddress()}>Clear</div>
                </Col>
                <Col lg={22}>
                  <div className='map-control-label'>Address</div>
                  <FormItem>
                    {getFieldDecorator('address', {
                      initialValue: this.state.inputAddress
                    })(
                      <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>

          <div className='map-container'>
            <FlexiMap
              isMarkerShown
              currentTab={selectedType}
              clients={selectedType === 'carer' ? selectedClient : clients}
              carers={selectedType === 'carer' ? employees : []}
              zoom={this.state.zoomLevel}
              zoomToMarkers={this.state.zoomToMarkers}
              zoomToCoord={this.state.inputAddress}
              origin={this.state.origin}
              offOrigin={() => this.handleOffOrigin()}
              onOrigin={() => this.handleOnOrigin()}
              selectedClient={selectedClient}
              selectedType={selectedType}
            />
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const { list } = await clientService.listByPageAddress(1, 0, { active: filter.active })
    const { list: employeeList } = await employeeService.listByPageAddress(1, 0, { active: filter.active })

    this.setState({
      clients: list,
      clientsFilter: list,
      employees: employeeList,
      genders: settings.list.filter(item => item.type === 'gender'),
      languages: settings.list.filter(item => item.type === 'language'),
      skills: settings.list.filter(item => item.type === 'skill'),
      origin: false
    })
  }

  fetchTypeList = async (languages, skills, gender) => {
    const { inputLanguages, inputSkills, selectedType, selectedClient } = this.state
    let filter = {}

    if (validator.isNotEmptyArray(skills)) {
      filter.skill_ids = { condition: '@>', value: skills }
    } else {
      delete filter.skill_ids
    }

    if (validator.isNotEmptyArray(languages)) {
      filter.language_ids = { condition: '@>', value: languages }
    } else {
      delete filter.language_ids
    }

    if (gender && gender.length > 2) {
      filter.gender = { condition: '=', value: gender }
    } else {
      delete filter.gender
    }

    filter.active = { condition: '=', value: true }

    // console.log('filters', filter)
    // console.log('skillsInput', inputSkills, typeof inputSkills, inputSkills, inputSkills.length)
    // console.log('languagesInput', inputLanguages, typeof inputLanguages, inputLanguages, inputLanguages.length)

    if (selectedType === 'client') {
      const { list } = await clientService.listByPageAddress(1, 0, filter)
      this.setState({ clients: list })
    } else {
      const { list: clientList } = await clientService.listByPageAddress(1, 0, filter)
      const { list } = await employeeService.listByPageAddress(1, 0, filter, undefined, undefined, { client_id: selectedClient.map(({ id }) => id) })
      this.setState({ employees: list, clients: clientList })
    }
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`

    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findCarers = (input, option) => {
    const carer = option.props.children

    return carer.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterGender = (input) => {
    const { inputLanguages, inputSkills } = this.state
    let gender = ''
    if (input !== undefined) {
      gender = input.toLowerCase()
    }
    // console.log('filterGender', input)
    this.setState({ inputGender: gender }, () => {
      this.fetchTypeList(
        inputLanguages,
        inputSkills,
        gender
      )
    })
  }

  filterLanguages = (input = []) => {
    const { inputSkills, inputGender } = this.state
    const languages = input.slice()

    this.setState({ inputLanguages: languages }, () => {
      this.fetchTypeList(
        languages,
        inputSkills,
        inputGender
      )
    })
  }

  filterSkills = (input, type) => {
    const { inputLanguages, inputGender } = this.state
    const skills = input.slice()

    this.setState({ inputSkills: skills }, () => {
      this.fetchTypeList(
        inputLanguages,
        skills,
        inputGender
      )
    })
  }
}

export default Form.create()(Map)

import { FETCHING_CANCELLED_RECURRING_JOBS, CANCELLED_RECURRING_JOBS_FETCHED } from '../actions/cancelledRecurringJob'

const INITIAL_STATE = {
  jobs: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CANCELLED_RECURRING_JOBS:
      return handleFetchingJobs(state, action)
    case CANCELLED_RECURRING_JOBS_FETCHED:
      return handleJobsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingJobs (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleJobsFetched (state, action) {
  const { jobs, loading } = action
  return { ...state, jobs, loading }
}

const storage = window.localStorage

export default {
  clear () {
    return storage.clear()
  },
  getItem (key) {
    return storage.getItem(key)
  },
  getObject (key, defaultValue) {
    defaultValue = defaultValue || {}
    try {
      const item = storage.getItem(key)
      return JSON.parse(item) || defaultValue
    } catch (e) {
      return defaultValue
    }
  },
  removeItem (key) {
    return storage.removeItem(key)
  },
  removeObject (key) {
    return storage.removeItem(key)
  },
  setItem (key, value) {
    return storage.setItem(key, value)
  },
  setObject (key, value) {
    return storage.setItem(key, JSON.stringify(value))
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientFileService } from '../../../services'
import { fetchingClients, setRefreshActivityLog, setRefreshFiles } from '../../../states/actions'
import { ClientFileTypes, ClientFileTypeList } from '../../../constants'

// UI
import { Button, FileUpload, Loading, List, Pager, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import { formatter, log, uploader } from '../../../util'
import { apiHostname } from '../../../config'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 20
const clientModule = 'client'

export class ClientCarePlanFiles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      loadingFile: false,
      loadingForm: false,
      isShowFileRule: false,
      fileList: [],
      fileTotal: 0,
      modal: {
        edit: false,
        item: {},
        show: false
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    const { onRefreshFiles } = this.props
    if (nextProps.shouldRefreshFiles !== this.props.shouldRefreshFiles) {
      if (nextProps.shouldRefreshFiles) {
        this.fetchFiles()
        onRefreshFiles(false)
      }
    }
  }

  componentDidMount () {
    this.fetchFiles()
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const { currentPage, loading, loadingFile, loadingForm, fileList, fileTotal, modal, isShowFileRule } = this.state
    const { edit, item: modalItem, show: modalShow } = modal

    const columns = [

      {
        title: 'Label',
        width: 6,
        key: 'label'
      },

      {
        title: 'Type',
        width: 4,
        render: ({ type }) => {
          const typeLabel = ClientFileTypeList.filter(item => item.value === type)
          return <div>{typeLabel[0].name}</div>
        }
      },

      {
        title: 'Uploaded on',
        width: 4,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },

      {
        title: 'Uploaded By',
        width: 4,
        render: ({ created_by }) => <div>{created_by}</div>
      },

      {
        title: 'Enabled',
        width: 2,
        render: ({ active }) => active ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <div style={{ color: '#ccc' }}><Icon type='check-circle' /></div>
      },

      {
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {this.hasAccess('createClientFile') ? (
            <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.showModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          ) : null}
          <Tooltip mouseLeaveDelay={0} title={`Download ${item.file_name}`}><div onClick={this.handleDownloadFile(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div></Tooltip>
          {this.hasAccess('deleteClientFile') ? (
            <div>
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Confirm to delete ${item.label}?`}
                  onConfirm={() => this.handleDeleteFile(item.id, item)}
                  okText='Yes'
                  cancelText='No'
                ><Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            </div>
          ) : null}
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='file-header'>
          {this.hasAccess('createClientFile') ? (
            <div className='btn' onClick={() => this.showModal(false)}>
              Add File
            </div>
          ) : null}
        </div>

        <div className='file-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={fileList} />

            <SideModal
              title='File'
              showModal={modalShow}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSaveFile} feedback={loadingForm}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Type'>
                  {getFieldDecorator('type', {
                    initialValue: modalItem.type || ClientFileTypes.CARE_PLAN,
                    rules: [
                      { required: true, message: 'Please select type' }
                    ]
                  })(
                    <Select
                      placeholder='Please select a type'
                      disabled={edit}>
                      {
                        <Option key='care_plan' value='care_plan'>Care Plan</Option>
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: modalItem.label,
                    rules: [
                      { min: 2, message: 'Label must be between 2 and 128 characters' },
                      { max: 128, message: 'Label must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FileUpload
                  file={modalItem.file_url}
                  fileName={modalItem.file_name}
                  loading={loadingFile}
                  readOnly={false}
                  showError={isShowFileRule}
                  upload={{
                    action: `${apiHostname}/private/api/clients/files/upload/files`,
                    beforeUpload: this.checkFile,
                    data: { id: modalItem.id, moduleId: clientId },
                    disabled: loading,
                    headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                    name: 'file',
                    onChange: this.handleUploadFile,
                    showUploadList: false
                  }}
                />

                {isShowFileRule
                  ? <div style={{ marginTop: '5px', color: 'red' }}>Please upload file</div>
                  : null }

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={fileTotal}
          totalText={`Total ${fileTotal} files`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchFiles = async (currentPage = 1) => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const filter = { }

    filter.module = { condition: '=', value: 'client' }
    filter.module_id = { condition: '=', value: clientId }
    filter.type = { condition: '=', value: ClientFileTypes.CARE_PLAN }

    const { list, total } = await clientFileService.listByPage(currentPage, pageSize, filter)
    this.setState({ fileList: list, fileTotal: total, loading: false, currentPage, filter })

    setTimeout(() => {
      this.props.setRefreshFiles(false)
    }, 200)
  }

  changePage = async (currentPage) => {
    this.fetchFiles(currentPage)
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  handleUploadFile = async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loadingForm: true })
    }

    if (status === 'done') {
      const { modal } = this.state
      const { item } = modal
      const { fileUrl } = response
      const lastIndex = fileUrl.lastIndexOf('/')
      const fileName = fileUrl.substring(lastIndex + 1)
      item.file_url = fileUrl
      item.file_name = fileName
      this.setState({ modal, loadingFile: false, loadingForm: false, isShowFileRule: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { modal } = this.state
        const { item } = modal
        const { UploadType } = uploader
        const response = await uploader.upload(item.id, info, token, UploadType.FILE)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = fileUrl.lastIndexOf('/')
          const fileName = fileUrl.substring(lastIndex + 1)
          item.file_name = fileName
          item.file_url = fileUrl
          this.setState({ modal })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  handleDownloadFile = (url) => (e) => {
    window.location.href = url
  }

  handleSaveFile = () => {
    const { form, clientId, onUpdateInfo } = this.props
    const { modal } = this.state
    const { edit, item } = modal
    const { file_url: fileUrl, file_name: fileName } = item
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors && fileUrl) {
        this.setState({ loadingForm: true, isShowFileRule: false })
        const user = authService.getCurrentUser()
        const { id: userId, name: userName } = authService.getCurrentUser()

        values.module = clientModule
        values.module_id = clientId
        values.created_by = userName
        values.created_by_id = userId
        values.file_url = fileUrl
        values.file_name = fileName
        // console.log('saving', values)
        // console.log('item', item)
        try {
          if (edit) {
            const response = await clientFileService.save(item.id, values)
            modal.item = { ...item, ...values }
            this.setState({ loadingForm: false, modal })

            if (response.id) {
              log.updateClientFile(clientId, item, values)
              const { currentPage } = this.state
              this.fetchFiles(currentPage)
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              this.props.setRefreshFiles(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          } else {
            const response = await clientFileService.add(values)
            this.setState({ loadingForm: false })

            if (response.id) {
              const { currentPage } = this.state
              const { id } = response
              modal.item = { ...item, ...values, id }
              log.addClientFile(clientId, `New file ${values.label}`)
              this.fetchFiles(currentPage)
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              this.props.setRefreshFiles(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save file successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }

      } else {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  async handleDeleteFile (id, info) {
    const { clientId, onUpdateInfo } = this.props
    const { currentPage } = this.state
    this.setState({ loading: true })

    const response = await clientFileService.remove(id)
    this.setState({ loading: false })

    if (response.id) {
      log.deleteClientFile(clientId, `Delete file ${info.label}`)
      notify.success('Deleted successfully', 'File deleted successfully')
      this.fetchFiles(currentPage)
      onUpdateInfo()
      this.props.setRefreshActivityLog(true)
      this.props.setRefreshFiles(true)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({ modal, isShowFileRule: false })
  }

  showModal = (isEdit, modalItem) => {
    const { modal } = this.state
    modal.edit = isEdit || false
    modal.item = modalItem || {}
    modal.show = true
    this.setState({ modal })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog,
  setRefreshFiles
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientCarePlanFiles))

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import formatter from '../../util/formatter'

import './styles.css'

export default class FeedbackStatus extends Component {
  render() {
    const { className, colour, label } = this.props
    const _colour = colour || '#d9d9d9'
    const _label = label || 'Unknown'

    return (
      <div className={formatter.toClassName(['wd-feedback-status', className])} style={{ backgroundColor: _colour }}>{_label}</div>
    )
  }
}

FeedbackStatus.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

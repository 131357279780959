import { FETCHING_FUNDERS, FUNDERS_FETCHED } from '../actions/funder'

const INITIAL_STATE = {
  funders: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_FUNDERS:
      return handleFetchingFunders(state, action)
    case FUNDERS_FETCHED:
      return handleFundersFetched(state, action)
    default:
      return state
  }
}

function handleFetchingFunders (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleFundersFetched (state, action) {
  const { funders, loading } = action
  return { ...state, funders, loading }
}

import { ADMINS_FETCHED, ADMIN_SET_PRISTINE } from '../actions/admin'

const INITIAL_STATE = {
  admins: { list: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMINS_FETCHED:
      return { ...state, ...handleAdminsFetched(action) }
    case ADMIN_SET_PRISTINE:
      return { ...state, ...handleAdminSetPristine(action) }
    default:
      return state
  }
}

function handleAdminsFetched (action) {
  const { admins } = action
  return { admins, pristine: false }
}

function handleAdminSetPristine (action) {
  const { pristine } = action
  return { pristine }
}

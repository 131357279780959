import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Icon from 'antd/lib/icon'

import './styles.css'

export default class CategoryMenu extends Component {
  render () {
    const { menu } = this.props

    return (
      <ul className='category-menu'>
        {
          menu.map((m, i) => {
            const category = (
              <li key={i} className={'menu' + (m.enable ? '' : ' menu-disabled')}>
                {m.icon ? (
                  <div className='category-menu-icon'><Icon type={m.icon} /></div>
                ) : (
                  <i className='iconfont icon-02 icon' />
                )}

                <div>
                  <div className='title'>{m.name}</div>
                  <div className='desc'>{m.desc}</div>
                </div>
              </li>
            )

            return (
              m.enable ? <Link to={m.link} key={i}>{category}</Link> : category
            )
          })
        }
      </ul>
    )
  }
}

CategoryMenu.propTypes = {
  menu: PropTypes.array.isRequired
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchPayrollsFvp } from '../../../states/actions'
import { exportFile, formatter, queryString } from '../../../util'
import { fvpPayrollService } from '../../../services'
import moment from 'moment-timezone'
import { ExportType, PayrollFvpMenu } from '../../../constants'

// UI
import { List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'

import { apiHostname, timezone } from '../../../config'
import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

moment.tz.setDefault(timezone)

const pageSize = 15
const { confirm } = Modal

export class Payroll extends Component {
  constructor (props) {
    super(props)
    const { payrolls: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: {},
      isGenerating: false,
      list,
      loading,
      searchText: '',
      showRangePicker: false,
      sort: {},
      suburbs: [],
      total,
      value: []
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { payrolls: { list, total }, loading } = nextProps
    return { ...prevState, list, loading, total }
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchPayrollsFvp({ currentPage, filter, loading: true, searchText, sort })
  }

  onSearchPeriod (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.fetchPayrollsFvp({ currentPage, filter, loading: true, searchText: value, sort })
    this.setState({ searchText: value })
  }

  async generatePayroll (e) {
    const endDate = e.endOf('day').format('YYYY-MM-DD hh:mm')
    const startDate = e.add(-13, 'day').startOf('day').format('YYYY-MM-DD hh:mm')
    this.setState({ loading: true, showRangePicker: false })
    const result = await fvpPayrollService.generate(startDate, endDate)
    if (result.id) {
      notify.success('Payroll Generated', `Payroll (${startDate} - ${endDate}) have been generated.`)
      const { currentPage, filter, loading, searchText, sort } = this.state
      this.fetchPayrollsFvp({ currentPage, filter, loading: true, searchText, sort })
    }
    this.setState({ loading: false })
  }

  async downloadInvoice (id, jobs, done, dateTo) {
    const filter = { payroll_id: id, date: formatter.toDBDate(dateTo) }
    const that = this

    if (jobs !== done) {
      confirm({
        title: `There are ${jobs} shifts. Proceed to download?`,
        content: 'Press Ok to download invoice',
        onOk () {
          that.generatingFile(ExportType.PAYROLL.INVOICE, filter)
        }
      })
    } else {
      this.generatingFile(ExportType.PAYROLL.INVOICE, filter)
    }
  }

  async downloadNDISPayment (id, ndisPaymentCount, dateTo) {
    const filter = { payroll_id: id, date: formatter.toDBDate(dateTo) }
    const that = this

    if (ndisPaymentCount > 0) {
      confirm({
        title: `There are ${ndisPaymentCount} shifts for NDIS payment. Proceed to download?`,
        content: 'Press Ok to download NDIS payment',
        onOk () {
          that.generatingFile(ExportType.PAYROLL.NDIS_PAYMENT, filter)
        }
      })
    } else {
      notify.error('Unable to download NDIS payment', 'No shift is eligible to be enlisted for NDIS payment.')
    }
  }

  async downloadPayroll (id, jobs, done, dateTo) {
    const filter = { payroll_id: id, status: 'approved', date: formatter.toDBDate(dateTo) }
    const that = this

    const jobCount = parseFloat(jobs)
    const remaining = jobCount - parseFloat(done)

    if (remaining < jobCount) {
      confirm({
        title: `There are ${remaining} pending out of ${jobCount} shifts. Proceed to download?`,
        content: 'Press Ok to download payroll',
        onOk () {
          that.generatingFile(ExportType.PAYROLL.PAYROLL, filter)
        }
      })
    } else {
      notify.error('Unable to download payroll', 'No shift under the selected period is approved.')
    }
  }

  async generatingFile (type, filter) {
    this.setState({ isGenerating: true })

    try {
      const r = await exportFile.fetchExport(type, filter)
      // window.location.href = `${apiHostname}/api/export/payroll-fvp/${type}${queryString.stringify({ filter: JSON.stringify(filter) })}`
      setTimeout(() => {
        this.setState({ isGenerating: false })
      }, 4000)
    } catch (e) {
      notify.error(`Unable to download ${type}`, 'Some error occured while try to download the document.')
      this.setState({ isGenerating: false })
    }
  }

  disabledDate (current) {
    // Can not select days other than monday
    return current.weekday() !== 0
  }

  render () {
    const { currentPage, isGenerating, list, showRangePicker, total, value } = this.state
    const { loading } = this.props

    const columns = [
      {
        title: 'Payroll Period',
        width: 4,
        render: ({ date_from: dateFrom, date_to: dateTo }) => <div className={'list-period '}><Icon type='calendar' /> {formatter.toShortDate(dateFrom)} - {formatter.toShortDate(dateTo)}</div>
      },
      {
        title: 'Clients',
        width: 1,
        render: ({ clients }) => clients || 0
      },
      {
        title: 'Employees',
        width: 1,
        render: ({ employees }) => employees || 0
      },
      {
        title: 'Jobs',
        width: 1,
        render: ({ jobs }) => jobs || 0
      },
      {
        title: 'Approved',
        width: 1,
        render: ({ approved }) => <div style={{ color: parseFloat(approved) > 0 ? '#2ec77a' : '#aaa' }}>{approved
      || 0}</div>
      },
      {
        title: 'To Review / Signed',
        width: 2,
        render: ({ review, signed }) => <div>
          <span style={{ color: parseFloat(review) > 0 ? '#f50' : '#aaa' }}>{review}</span> / <span style={{ color: parseFloat(signed) > 0 ? '#e8419d' : '#aaa' }}>{signed}</span>
        </div>
      },
      {
        title: 'Rejected',
        width: 1,
        render: ({ rejected }) => <div style={{ color: parseFloat(rejected) > 0 ? '#ff0000' : '#aaa' }}>{rejected || 0}</div>
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ hours }) => formatter.toDecimalS(hours) || 0
      },
      {
        title: 'S/O',
        width: 1,
        render: ({ sleepovers }) => sleepovers || 0
      },
      {
        title: 'Getups',
        width: 1,
        render: ({ getups }) => getups || 0
      },
      {
        title: 'KMS',
        width: 1,
        render: ({ kms }) => formatter.toDecimalS(kms) || 0
      },
      {
        title: '',
        width: 8,
        render: ({ id, date_to, jobs, approved, ndis_payment_count }) => (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Link to={`/payrolls/${id}`} className='list-button-fvp'><div><Icon type='eye' /> TS</div></Link>
          <div onClick={() => this.downloadPayroll(id, jobs, approved, date_to)} className='list-button-fvp'><Icon type='download' /> Payroll</div>
          <div onClick={() => this.downloadInvoice(id, jobs, approved, date_to)} className='list-button-fvp'><Icon type='download' /> Invoice</div>
          <div onClick={() => this.downloadNDISPayment(id, ndis_payment_count, date_to)} className='list-button-fvp'><Icon type='download' /> NDIS Payment</div>
        </div>)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={PayrollFvpMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Payrolls'>
            {/* <div style={{ display: showRangePicker ? '' : 'none' }}><DatePicker onChange={(e) => this.generatePayroll(e)} open={showRangePicker} disabledDate={this.disabledDate} /></div>
            <div className='btn' onClick={() => this.setState({ showRangePicker: true })}>Generate Payroll</div> */}
          </Page.Header>

          <Skeleton loading={loading} active>
            <Page.Filter>
              <Row gutter={8}>
                <Col lg={8}>
                  {/* <ControlLabel>Search</ControlLabel>
                  <SearchInput placeholder='Payroll Date' onChange={(v) => this.onSearchName(v)} /> */}
                  <DatePicker.RangePicker
                    value={value}
                    disabled={loading}
                    mode={['month', 'month']}
                    placeholder={['Start month', 'End month']}
                    format='YYYY-MM'
                    onPanelChange={this.handlePanelChange}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col offset={15} lg={1}>
                  { isGenerating ? <Icon type='loading' style={{ marginTop: '6px', fontSize: '15pt' }} /> : null }
                </Col>
              </Row>
            </Page.Filter>

            <div className='payroll'>
              <Skeleton loading={loading} active avatar>
                <List cols={columns} rows={list} />
              </Skeleton>
            </div>

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} payrolls`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </Page.Content>
      </Page.Body>
    )
  }

  handlePanelChange = (value, mode) => {
    const { filter } = this.state
    const startAt = value[0].startOf('month')
    const endAt = value[1].endOf('month')
    this.setState({
      value
      // mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    })

    if (value[0] !== null && value[1] !== null) {
      filter.date_from = { condition: '>=', value: startAt }
      filter.date_to = { condition: '<=', value: endAt }
    }

    this.fetchPayrollsFvp({ loading: true, filter })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchPayrollsFvp({ currentPage, filter, searchText, sort })
  }

  fetchPayrollsFvp = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchPayrollsFvp } = this.props
      this.setState({ currentPage })
      fetchPayrollsFvp({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchPayrollsFvp
}

const mapStateToProps = (state) => {
  return { ...state.PayrollFvp }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payroll)

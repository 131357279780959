import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { authService, settingGeneralService } from '../../../../services'

// UI
import { Button, Loading, Page, Pager, Panel, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingType = 'funding'

export class SettingFundingType extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage, loading: true })
  }

  fetchSettings = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      if (this.hasAccess('listFvpFundingTypes')) {
        this.setState({ currentPage, loading: loading })
        const settings = await settingGeneralService.listByPage(currentPage, pageSize, { type: settingType })
        this.setState({ list: settings.list, loading: false, total: settings.total })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        values.type = settingType
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingGeneralService.save(id, values)
          } else {
            values.image = item.image
            response = await settingGeneralService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', 'Funding type saved successfully.')
            this.fetchSettings({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingGeneralService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchSettings({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 8,
        key: 'name'
      },
      {
        title: 'Code',
        width: 8,
        key: 'value'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, name, value, active }) => this.hasAccess('updateFvpFundingType') ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(id, name, value, active)}><Icon type='form' /></div> : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('deleteFvpFundingType') ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    return (
      <div>
        <Page.Header title='Funding Types'>
          {
            this.hasAccess('createFvpFundingType')
              ? <div className='btn' onClick={() => this.showModal()}>
                Add
              </div>
              : null
          }
        </Page.Header>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} funding types`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title='Funding Type'
          showModal={modal.show}
          onClose={this.hideModal}
          buttons={[
            this.hasAccess('updateFvpFundingType') || this.hasAccess('createFvpFundingType')
            ? <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
            : null
          ]}
        >
          <Loading loading={loadingForm} blur>
            <Form layout='vertical'>
              <FormItem label='Name'>
                {getFieldDecorator('name', {
                  initialValue: selectedItem.name || null,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label='Code'>
                {getFieldDecorator('value', {
                  initialValue: selectedItem.value || null,
                  rules: [
                    { min: 2, message: 'code must be between 2 and 128 characters' },
                    { max: 128, message: 'Code must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter code' },
                    { whitespace: true, message: 'Please enter code' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label=''>
                {getFieldDecorator('active', {
                  initialValue: selectedItem.active === undefined || selectedItem.active === null ? true : selectedItem.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Enable' unCheckedChildren='Disable'
                  />
                )}
              </FormItem>
            </Form>
          </Loading>
        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFundingType))

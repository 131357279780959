import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import { formatter } from '../../../util'

// UI
import { Loading, List, Pager } from '../../../components'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

export class FeedbackActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      list: [],
      loading: false,
      total: 0
    }
  }

  componentDidMount() {
    const { currentPage } = this.state
    this.fetchLogs(currentPage)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldRefreshActivityLog !== this.props.shouldRefreshActivityLog) {
      if (nextProps.shouldRefreshActivityLog) {
        this.fetchLogs(this.state.currentPage)
      }
    }
  }

  render() {
    const { currentPage, list, loading, total } = this.state
    const logColumns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },

      {
        title: 'Updated By',
        width: 4,
        render: ({ updated_by }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{updated_by}</div>
      },

      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
      },

      {
        title: 'Changes',
        width: 12,
        key: 'changes'
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={logColumns} rows={list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} logs`}
          current={currentPage}
          onChange={this.changePage}
          style={{ marginTop: '15px' }}
        />
      </Loading>
    )
  }

  changePage = (currentPage) => {
    this.fetchLogs(currentPage)
  }

  fetchLogs = (startPage) => {
    this.setState({ loading: true }, async () => {
      const { feedbackId } = this.props
      const { currentPage } = this.state
      const filter = {}
      const page = startPage || currentPage
      filter.type = { condition: '=', value: 'feedback' }
      filter.type_id = { condition: '=', value: feedbackId }
      const { list, total } = await logService.listByPage(page, pageSize, filter)
      this.setState({ currentPage: page, filter, list, loading: false, total }, () => {
        this.props.setRefreshActivityLog(false)
      })
    })
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeedbackActivity))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Loading, List, Pager } from '../../../components'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import '../styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

export class JobActivity extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      logList: [],
      loading: false,
      jobId: ''
    }
    this.filterLogs = this.filterLogs.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.isLogRefresh !== this.props.isLogRefresh) {
      if (nextProps.isLogRefresh === true) {
        this.fetchLogs(1)
      }
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchLogs(currentPage)
  }

  filterLogs (e) {
    this.setState({ currentPage: 1 })
    this.fetchLogs(e.target.value, 1)
  }

  render () {
    const { currentPage, loading, logList, total } = this.state

    const logColumns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY hh:mm A')
      },

      {
        title: 'Updated By',
        width: 4,
        render: ({ updated_by }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{updated_by}</div>
      },

      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
      },

      {
        title: 'Changes',
        width: 12,
        render: ({ changes }) => <div dangerouslySetInnerHTML={{ __html: changes && changes.replace(/\n/g, '<br />') }}></div>
      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={logColumns} rows={logList} />

          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} logs`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchLogs = async (startPage = null) => {
    this.setState({ loading: true })
    const { jobId, moduleType, onUpdateRefresh } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.type = { condition: '=', value: moduleType }
    filter.type_id = { condition: '=', value: jobId }

    const { list: logList, total } = await logService.listByPage(page, pageSize, filter)

    this.setState({ logList, total, loading: false, currentPage: page, filter })

    setTimeout(() => {
      onUpdateRefresh && onUpdateRefresh()
    }, 200)
  }

  changePage = async (currentPage) => {
    this.fetchLogs(currentPage)
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.JvpJob, ...state.JvpJobRecurring, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JobActivity))

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class FullModal extends Component {
  render () {
    const { buttons, children, onClose, showModal, title } = this.props

    return (
      <div className={'wd-full-modal' + (showModal ? ' wd-full-modal-show' : ' wd-full-modal-hide')}>
        <div className='header'><div>{title}</div><div className='close' onClick={onClose}>&#x2715;</div></div>
        <div className='content'>
          { children }
        </div>
        <div className='footer'>
          { buttons || null }
        </div>
      </div>
    )
  }
}

FullModal.propTypes = {
  title: PropTypes.string
}

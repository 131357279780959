export default [
  {
    name: 'Clients',
    desc: 'Manage all customers from different channels',
    icon: 'team',
    link: '/clients',
    enable: true,
    permission: 'listClients'
  },
  {
    name: 'Add Client',
    desc: 'Membership',
    icon: 'usergroup-add',
    link: '/clients/add',
    permission: 'createClient'
  }
]

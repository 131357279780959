export default [
  {
    name: 'Pending Jobs (V1)',
    desc: 'List future jobs',
    icon: 'exclamation-circle',
    permission: 'listJobs',
    submenu: [
      {
        name: 'Individual',
        link: '/v1/jobs/pending-individual',
        count: 'individual'
      },
      {
        name: 'Recurring Base',
        link: '/v1/jobs/pending-recurring',
        count: 'recurring'
      }
    ]
  },
  {
    name: 'Individual Jobs (V1)',
    desc: 'List future jobs',
    icon: 'right-circle',
    permission: 'listJobs',
    submenu: [
      {
        name: 'Future Jobs',
        link: '/v1/jobs/future'
      },
      {
        name: 'Past Jobs',
        link: '/v1/jobs/past'
      },
      {
        name: 'Cancelled Jobs',
        link: '/v1/jobs/cancelled'
      }
    ]
  },
  {
    name: 'Recurring Base (V1)',
    desc: 'List future jobs',
    icon: 'sync',
    permission: 'listJobs',
    submenu: [
      {
        name: 'Active Recurring Base',
        link: '/v1/jobs/recurring'
      },
      {
        name: 'Ended Recurring Base',
        link: '/v1/jobs/recurring-ended'
      }
    ]
  }
]

import React, { Component } from 'react'
import { connect } from 'react-redux'
// import debounce from 'lodash.debounce'
import UserMenu from '../../../constants/menu/user'
import moduleService from '../../../services/module'
import { modulesFetched } from '../../../states/actions/module'
import { sessionStorage } from '../../../util'

// UI
import { Button, FullModal, Page, Pager, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const filterKey = 'module.filter'
const pageKey = 'module.page'
const sortKey = 'module.sort'
const pageSize = 20
const { Item: FormItem } = Form
const { TextArea } = Input

export class SettingModule extends Component {
  constructor (props) {
    super(props)
    const { modules: { list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      modal: {
        item: { module_permissions: [] },
        show: false
      },
      searchText: '',
      sort: { name: 1 }, //sessionStorage.getObject(sortKey),
      total
    }
    // this.findModules = debounce(this.findModules, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { modules: { list, total } } = nextProps
    return { ...prevState, list, total }
  }

  componentDidMount () {
    const { currentPage, filter, sort } = this.state
    this.fetchModules({ currentPage, filter, sort })
  }

  render () {
    const { form } = this.props
    const { currentPage, list, loading, loadingForm, modal, total } = this.state
    const { getFieldDecorator } = form
    const { item: modalItem, show: modalShow } = modal
    const { module_permissions: modulePermissions = [] } = modalItem

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={UserMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='Modules' />

          <div className='module-list'>
            <Skeleton loading={loading} active>
              <Row className='list-header'>
                <Col md={8}>Name</Col>

                <Col md={15}>Description</Col>
              </Row>

              {list.map((item) => {
                const { id, name, description } = item

                return (
                  <div className='list-item' key={id}>
                    <Row className='list-content'>
                      <Col md={8}>{name}</Col>

                      <Col md={15}>{description}</Col>

                      <Col md={1}>
                        <div onClick={this.handleEdit(item)}><Icon type='form' /></div>
                      </Col>
                    </Row>
                  </div>
                )
              })}

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} modules`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Skeleton>
          </div>
        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            Manage module descriptions
          </Page.Panel>
        </Page.Right>

        <FullModal
          title='Module'
          showModal={modalShow}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <Row gutter={16}>
              <Col lg={6}>
                <FormItem label='Name'>
                  {getFieldDecorator('name', {
                    initialValue: modalItem.name,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 127 characters' },
                      { max: 127, message: 'Name must be between 2 and 127 characters' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input readOnly />
                  )}
                </FormItem>
              </Col>

              <Col lg={18}>
                <FormItem hasFeedback label='Description'>
                  {getFieldDecorator('description', {
                    initialValue: modalItem.description,
                    rules: [
                      { min: 2, message: 'Description must be between 2 and 255 characters' },
                      { max: 255, message: 'Description must be between 2 and 255 characters' },
                      { whitespace: true, message: 'Description cannot contain only spaces' }
                    ]
                  })(
                    <TextArea
                      autosize={{ minRows: 1, maxRows: 2 }}
                      tabIndex={1}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Panel className='module-panel' title='Permissions'>
              {modulePermissions.map((permission, idx) => {
                const { id, name, description } = permission

                return (
                  <Row gutter={16} key={id}>
                    {getFieldDecorator(`module_permissions[${idx}].id`, {
                      initialValue: id
                    })(
                      <Input type='hidden' />
                    )}

                    <Col lg={6}>
                      <FormItem label='Name'>
                        {getFieldDecorator(`module_permissions[${idx}].name`, {
                          initialValue: name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 127 characters' },
                            { max: 127, message: 'Name must be between 2 and 127 characters' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        })(
                          <Input readOnly />
                        )}
                      </FormItem>
                    </Col>

                    <Col lg={18}>
                      <FormItem hasFeedback label='Description'>
                        {getFieldDecorator(`module_permissions[${idx}].description`, {
                          initialValue: description,
                          rules: [
                            { min: 2, message: 'Description must be between 2 and 255 characters' },
                            { max: 255, message: 'Description must be between 2 and 255 characters' },
                            { whitespace: true, message: 'Description cannot contain only spaces' }
                          ]
                        })(
                          <TextArea
                            autosize={{ minRows: 1, maxRows: 2 }}
                            tabIndex={idx + 1}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                )
              })}
            </Panel>
          </Form>
        </FullModal>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchModules({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchModules = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { modulesFetched, pristine } = this.props
      sessionStorage.setObject(filterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      sessionStorage.setObject(sortKey, sort)
      this.setState({ currentPage, loading: loading || pristine })
      const response = await moduleService.listByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({ loading: false })
      modulesFetched(response)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load modules successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findModules = (text) => {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchModules({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { modal, total } = this.state
        const { description, module_permissions: modulePermissions } = values
        const { item } = modal
        const { id } = item
        this.setState({ loadingForm: true })
        values.description = description || null
        values.module_permissions = modulePermissions.map((permission) => {
          const { description } = permission
          permission.description = description || null
          return permission
        })

        try {
          let response

          if (id) {
            response = await moduleService.save(id, values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', 'Module saved successfully.')
            this.hideModal()
            this.fetchModules({ loading: true, currentPage: Math.ceil((total + 1) / pageSize) })
          } else {
            notify.error('Unable to save successfully', 'Unable to save module successfully. Please try again later.')
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save module successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit = (item) => async () => {
    const module = await moduleService.get(item.id) || { item }
    this.showModal(module.item)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.item = {}
    modal.show = false
    this.setState({ modal })
    resetFields()
  }

  showModal = (item) => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.item = {}
    this.setState({ modal })
    resetFields()
    modal.item = item
    modal.show = true
    this.setState({ modal })
  }
}

const mapDispatchToProps = {
  modulesFetched
}

const mapStateToProps = (state) => {
  return { ...state.Module }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingModule))

import React, { Component } from 'react'
import { clientService, fvpClientFundingService } from '../../../../services'
import { formatter } from '../../../../util'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

export class AddJobModal extends Component {
  state = {
    clients: [],
    currentStep: 0,
    funders: [],
    spinLoading: false,
    disableFunder: true,
    type: 'single'
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { type } = nextProps
    if (type) {
      const state = { ...prevState, type }
      return state
    }

    return prevState
  }

  componentDidMount () {
    const { clientId } = this.props
    if (clientId) {
      this.fetchFunders(clientId)
    }

    this.fetchClients()
  }

  fetchClients = async () => {
    try {
      const clients = await clientService.listByPageActive(1, 0, {}, { first_name: 'asc' })
      this.setState({ clients: clients.list })
    } catch (e) {
      // notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId) => {
    try {
      const funders = await fvpClientFundingService.listClientFunders(clientId)
      this.setState({ funders, disableFunder: false })
    } catch (e) {

    }
  }

  onSelectClient = async (value, option) => {
    const { form } = this.props
    this.setState({ spinLoading: true })
    const funders = await fvpClientFundingService.listClientFunders(value)
    this.setState({ funders, spinLoading: false, disableFunder: false })
    form.setFieldsValue({ funder_id: null })
  }

  handleNext = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep === 0 ? 1 : 0 })
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history } = this.props
        const clientId = values.client_id
        const funderId = values.funder_id
        const jobType = values.job_type
        this.setState({ loading: true })

        if (jobType === 'single') {
          history.replace(`/jobs/single/add?client=${clientId}&funder=${funderId}`)
        } else {
          history.replace(`/jobs/recurring/add?client=${clientId}&funder=${funderId}`)
        }

        this.setState({ loading: false })
      }
    })
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]} ${option.props.children[3].props.children}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children[0]} ${option.props.children[1]} ${option.props.children[1].props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  render () {
    const { form, visible, onClose, clientId } = this.props
    const { clients, currentStep, funders, spinLoading, type, disableFunder } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return (
      <Modal visible={visible}
        width={700}
        title='New Job'
        onOk={this.handleNext}
        onCancel={onClose}
        footer={[
          <Button key='ok' type='primary' onClick={this.handleSubmit}>Next</Button>
        ]}
      >
        <Steps size='small' current={currentStep}>
          <Step title='Select Client & Funder' />
          <Step title='Click Next to continue' />
        </Steps>
        <div style={{ marginTop: '20px', borderTop: '1px solid #eee', paddingTop: '30px' }}>
          <Form>
            <div style={{ display: currentStep === 0 ? 'inline' : 'none' }}>
              <FormItem {...formItemLayout} label='Job Type'>
                {getFieldDecorator('job_type', {
                  initialValue: type
                })(
                  <RadioGroup buttonStyle='solid' onChange={this.handleTypeChange}>
                    <RadioButton value='single'>One&#8209;off</RadioButton>
                    <RadioButton value='recurring'>Recurring</RadioButton>
                  </RadioGroup>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='Client' hasFeedback>

                {getFieldDecorator('client_id', clientId ? {
                  initialValue: parseInt(clientId),
                  rules: [
                    { required: true, message: 'Please select a client' }
                  ]
                } : {
                  rules: [
                    { required: true, message: 'Please select a client' }
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Client'
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    filterOption={(input, option) => this.findClients(input, option)}
                    onChange={(e, c) => this.onSelectClient(e, c)} >
                    {
                      clients.map((client, idx) => {
                        return <Option key={idx} value={client.id}>
                          {client.first_name} {client.last_name} { client.leave_id ? <span style={{ color: '#ff1100', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(client.leave_start)} - ${client.until_further_notice ? 'UFN' : formatter.toShortDate(client.leave_end)}`}</span> : null}
                          <div className='clientId'>{client.acc_ref}</div></Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>

              <Spin spinning={spinLoading}>
                <FormItem {...formItemLayout} label='Funder' hasFeedback>
                  {getFieldDecorator('funder_id', {
                    rules: [
                      { required: true, message: 'Please select a funder' }
                    ]
                  })(
                    <Select showSearch
                      style={{ width: '100%' }}
                      placeholder='Funders'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      disabled={disableFunder}
                      filterOption={(input, option) => this.findFunders(input, option)}>
                      {
                        funders.map((funder) => (<Option key={funder.funder_id} value={funder.funder_id}>
                          {funder.funder_fullname}
                          </Option>))
                      }
                    </Select>
                  )}
                </FormItem>
              </Spin>

            </div>

          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddJobModal)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import LogMenu from '../../../constants/menu/log'
import { accessLogService } from '../../../services'
import { formatter } from '../../../util'
// UI
import { List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

export class SettingAccessLog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      loading: false
    }
  }

  componentDidMount () {
    const { currentPage, filter, sort } = this.state
    this.fetchLogs({ currentPage, filter, sort })
  }

  render () {
    const { currentPage, list, loading, total } = this.state
    const columns = [
      {
        title: 'Date',
        width: 6,
        render: ({ created_at }) => formatter.toStandardLongDate(created_at)
      },

      {
        title: 'Name',
        width: 5,
        render: ({ user_name }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{user_name}</div>
      },

      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
      },

      {
        title: 'IP Address',
        width: 8,
        key: 'ip'
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={LogMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='System Users Access Log' />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} access logs`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Skeleton>
          </div>
        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            View system users access log
          </Page.Panel>
        </Page.Right>

      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchLogs({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchLogs = async ({ currentPage = 1, loading, filter, sort, searchText }) => {
    try {
      this.setState({ currentPage, loading: true })
      const log = await accessLogService.listByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({ list: log.list, total: log.total, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load logs successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingAccessLog))

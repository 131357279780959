import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/basejobs'
const fileUrl = '/private/api/basejobs/files'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getJobList (page, size, filter, sorting, text) {
    return request(`${url}/jobs/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getJobListDesc (page, size, filter, sorting, text) {
    return request(`${url}/jobs/page/${page}/size/${size}/desc${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getByEmployee (empId) {
    return request(`${url}/empId/${empId}`)
  },
  getByEmployeeAndDate (empId, date) {
    return request(`${url}/empId/${empId}/date/${date}`)
  },

  getPendingEmployeeJob (page, size, filter, sorting, text) {
    return request(`${url}/noemp/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFrom (id, values, fromDate) {
    return request(`${url}/${id}/from/${fromDate}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFromUpdated (id, values, fromDate) {
    return request(`${url}/${id}/from/${fromDate}/edited`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getCancelledJob (page, size, filter, sorting, text) {
    return request(`${url}/cancel/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getEndedJob (page, size, filter, sorting, text) {
    return request(`${url}/ended/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getSuburbs () {
    return request(`${url}/suburbs`)
  },
  getClientIds () {
    return request(`${url}/ids`)
  },

  addFile (values) {
    return request(fileUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFile (id, values) {
    return request(`${fileUrl}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listFileByPage (page, size, filter, sorting, text) {
    return request(`${fileUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listFileDocumentByPage (page, size, filter, sorting, text) {
    return request(`${fileUrl}/document/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getFileDocumentMainCategory () {
    return request(`${fileUrl}/document/main-category`)
  },
  removeFile (id) {
    return request(`${fileUrl}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  async getEmployeeTotalMaxHour (values) {
    return request(`${url}/list/employee/maxhrs/post`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatter, validator } from '../../util'
import { settingGeneralService, settingHolidayService, clientService, employeeService, jobService } from '../../services'
import moment from 'moment'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../components'

import './styles.css'

import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Switch from 'antd/lib/switch'

const TabPane = Tabs.TabPane
const pageSize = 30

{/**
  * CR0011 - job revamp
  * this page is deprecated and no more in use
*/}
export class Notification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPageCarer: 1,
      currentPageClient: 1,
      currentPageHoliday: 1,
      loading: false,
      clients: { list: [], total: 0 },
      employees: { list: [], total: 0 },
      holidaysAll: {},
      holidays: { list: [], total: 0 },
      holidayYears: [],
      searchText: '',
      tabKey: '1',
      isFindBirthday: false,
      isFindCertificate: false,
      isFindCertExpired: false,
      isFindLeave: true,
      isFindCarePlan: false,
      isFindNDIS: false,
      isFindFunderEnding: false,
      isFindFunder: false,
      filter: {},
      filterHolidayTab: {},
      filterYear: moment().format('YYYY'),
      showFilter: false,
      loadedByInitial: false
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.initialLists()
  }

  render () {
    const { currentPageCarer, currentPageClient, currentPageHoliday, loading, clients, employees, holidays, tabKey,
      isFindBirthday, isFindCarePlan, isFindCertificate, isFindLeave, isFindFunderEnding, isFindCertExpired, isFindFunder } = this.state
    // console.log('isFindBirthday', isFindBirthday, 'isFindCarePlan', isFindCarePlan, 'isFindCertificate', isFindCertificate, 'isFindFunderEnding', isFindFunderEnding, 'isFindCertExpired', isFindCertExpired)

    return (
      <Page.Body>
        <Page.Content full>
          <Page.Header icon={<Icon type='exclamation-circle' />} title='Notifications' />
          <Tabs
            defaultActiveKey='1'
            onTabClick={this.onTabClick}
          >
            <TabPane tab='Employee' key='1'>
              { this.carerTab() }
            </TabPane>

            <TabPane tab='Client' key='2'>
              { this.clientTab() }
            </TabPane>

            <TabPane tab='Public Holidays' key='3'>
              { this.holidayTab() }
            </TabPane>

          </Tabs>

        </Page.Content>
        <Page.Right>
          <div className='wd-noti-panel-box'>
            <ControlLabel>Search: </ControlLabel>
            <SearchInput placeholder='' onChange={(v) => this.onSearchName(v)} />
            { /** Search Filter Toggle Switchs */ }
            {
              tabKey === '1' || tabKey === '2'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Leave</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      defaultChecked
                      onChange={(leave) => (tabKey === '2'
                        ? this.refreshList('carer', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, leave)
                        : this.refreshList('client', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, leave, isFindFunderEnding, isFindFunder)
                      )}
                    />
                  </div>)
                : null
            }

            {
              tabKey === '1'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Certificates</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(certExpiring) => this.refreshList('carer', 1, isFindBirthday, isFindCarePlan, certExpiring, isFindCertExpired, isFindLeave)}
                    />
                  </div>)
                : null
            }

            {
              tabKey === '2'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Client Funder</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(funderEnding) => this.refreshList('client', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, funderEnding, isFindFunder)}
                    />
                  </div>)
                : null
            }

            {
              tabKey === '1' || tabKey === '2'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Birthdays</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(birthday) => (tabKey === '1'
                        ? this.refreshList('carer', 1, birthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave)
                        : this.refreshList('client', 1, birthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder)
                      )}
                    />
                  </div>)
                : null
            }

            {/* {
              tabKey === '2'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Certificates Expired</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(certExpired) => this.refreshList('carer', 1, isFindBirthday, isFindCarePlan, isFindCertificate, certExpired, isFindLeave)}
                    />
                  </div>)
                : null
            } */}

            {
              tabKey === '2'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Care Plan Reassessment</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(carePlan) => this.refreshList('client', 1, isFindBirthday, carePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder)}
                    />
                  </div>)
                : null
            }



            {/* {
              tabKey === '3'
                ? (
                  <div style={{ marginTop: '16px' }}>
                    <ControlLabel>Funder Ended</ControlLabel>
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(funderEnded) => this.refreshList('client', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, funderEnded)}
                    />
                  </div>)
                : null
            } */}

          </div>
        </Page.Right>
      </Page.Body>
    )
  }

  carerTab = () => {
    const { currentPageCarer, employees, loading,
      isFindBirthday, isFindCarePlan, isFindCertificate, isFindLeave, isFindCertExpired, showFilter } = this.state

    const employeeColumns = [
      {
        title: 'Employee',
        width: 4,
        render: ({ fullname, id, leave_id, leave_start, leave_end }) => (leave_id
          ? <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{fullname} <Tooltip title={`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></a>
          : <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
        )
      },
      {
        title: 'Type',
        width: 8,
        key: 'type'
      },
      {
        title: 'Date',
        width: 4,
        render: ({ date }) => moment(date).format('DD/MM/YYYY')
      },
      {
        title: 'Notes',
        width: 8,
        render: ({ id, leave, notes, total_jobs }) => (leave > 0 && total_jobs !== '0') ? <Link to={`/employees/${id}/leave/${leave}?notify`}><div style={{ color: '#e5374e' }}><Icon type='issues-close' /> {notes}</div></Link> : (leave > 0 ? '' : notes)
      }
    ]

    return (
      <div>

        {
          showFilter ? <div className='expiry-filter'>
            <Radio.Group
              onChange={this.handleCertFilter}
              defaultValue='expiring' style={{ marginLeft: 20 }}>
              {/* <Radio.Button value='all'>All</Radio.Button> */}
              <Radio.Button value='expiring'>Expiring</Radio.Button>
              <Radio.Button value='expired'>Expired</Radio.Button>
            </Radio.Group>
          </div>

            : null
        }

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={employeeColumns} rows={employees.list} />
          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={employees.total}
          totalText={`Total ${employees.total} records`}
          current={currentPageCarer}
          onChange={(page) => this.refreshList('carer', page, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }

  clientTab = () => {
    const { currentPageClient, clients, loading,
      isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder, showFunderFilter } = this.state

    const clientColumns = [
      {
        title: 'Client',
        width: 4,
        render: ({ fullname, id, leave_id, leave_start, leave_end }) => (leave_id
          ? <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{fullname} <Tooltip title={`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></a>
          : <a href={`/clients/${id}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
        )
      },
      {
        title: 'Type',
        width: 8,
        key: 'type'
      },
      {
        title: 'Date',
        width: 4,
        render: ({ date }) => moment(date).format('DD/MM/YYYY')
      },
      {
        title: 'Notes',
        width: 8,
        render: ({ id, leave, notes, total_jobs }) => (leave > 0 && total_jobs !== '0') ? <Link to={`/clients/${id}/leave/${leave}?notify`}><div style={{ color: '#e5374e' }}><Icon type='issues-close' /> {notes}</div></Link> : (leave > 0 ? '' : notes)
      }
    ]

    return (

      <div>

        {
          showFunderFilter ? <div className='expiry-filter'>
            <Radio.Group
              onChange={this.handleFunderFilter}
              defaultValue='expiring' style={{ marginLeft: 20 }}>
              {/* <Radio.Button value='all'>All</Radio.Button> */}
              <Radio.Button value='expiring'>Ending</Radio.Button>
              <Radio.Button value='expired'>Ended</Radio.Button>
            </Radio.Group>
          </div>

            : null
        }
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={clientColumns} rows={clients.list} />
          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={clients.total}
          totalText={`Total ${clients.total} records`}
          current={currentPageClient}
          onChange={(page) => this.refreshList('client', page, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }

  holidayTab = () => {
    const { currentPageHoliday, holidays, loading, holidayYears } = this.state
    const thisYear = parseInt(moment().format('YYYY'))

    const holidayColumns = [
      {
        title: 'Name',
        width: 10,
        key: 'name'
      },
      {
        title: 'Date',
        width: 6,
        render: ({ date }) => moment(date).format('DD/MM/YYYY')
      },
      {
        title: 'Notes',
        width: 8,
        render: ({ id, impacted, total_jobs }) => total_jobs > 0 ? <Link to={`/holidays/${id}/job`}><div style={{ color: '#e5374e', cursor: 'pointer' }}><Icon type='issues-close' /> {impacted} job{Number(impacted) === 1 ? '' : 's'}. </div></Link> : ''
      }
    ]

    return (
      <div>
        <Radio.Group
          onChange={this.handleHolidaysYear}
          defaultValue={thisYear} style={{ marginLeft: 20 }}>
            {
              holidayYears.map((i, index) => (
              <Radio.Button key={index} value={i.year}>{i.year}</Radio.Button>
              ))
            }
        </Radio.Group>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={holidayColumns} rows={holidays.list} />
          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={holidays.total}
          totalText={`Total ${holidays.total} records`}
          current={currentPageHoliday}
          onChange={(page) => this.refreshList('holiday', page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }

  initialLists = async () => {
    const { currentPageClient, currentPageCarer } = this.state
    const filterLeave = {}
    filterLeave.type = { condition: 'ilike', value: '%leave%' }
    this.setState({ loading: true })

    const clients = await clientService.listByPageNotification(currentPageClient, pageSize, filterLeave)
    const employees = await employeeService.listByPageNotification(currentPageCarer, pageSize, filterLeave)

    const filter = {}
    filter.year = { condition: '=', value: moment().format('YYYY') }
    const holidays = await settingHolidayService.listItemByPage(1, 0, filter)
    const holidayYears = await settingHolidayService.listYears()

    this.setState({
      loading: false,
      clients: clients,
      employees: employees,
      holidays,
      holidayYears,
      filterHolidayTab: filter,
      loadedByInitial: true
    })
  }

  fetchCarer = async (searchText, currentPage = 1, filter = this.state.filter, sort = {}) => {
    this.setState({ loading: true })
    // const tempFilter = {}
    // tempFilter.$or = []
    // tempFilter.$and = []
    // if (searchText !== '') {
    //   tempFilter.$or.push({ key: 'string_date', condition: 'ilike', value: `%${searchText}%` })
    //   tempFilter.$or.push({ key: 'fullname', condition: 'ilike', value: `%${searchText}%` })
    //   tempFilter.$or.push({ key: 'notes', condition: 'ilike', value: `%${searchText}%` })
    //   tempFilter.$or.push({ key: 'type', condition: 'ilike', value: `%${searchText}%` })
    // }
    // if (Array.isArray(filter.$or)) {
    //   tempFilter.$or = [...filter.$or, ...tempFilter.$or]
    // }
    // console.log('tempFilter', tempFilter)
    const employees = await employeeService.listByPageNotification(currentPage, pageSize, filter, sort, searchText)
    this.setState({ employees, loading: false, currentPageCarer: currentPage, searchText })
  }

  fetchClient = async (searchText, currentPage = 1, filter = this.state.filter, sort = {}) => {
    this.setState({ loading: true })

    const clients = await clientService.listByPageNotification(currentPage, pageSize, filter, sort, searchText)

    this.setState({ clients, loading: false, currentPageClient: currentPage, searchText })
  }

  fetchHoliday = async (searchText, currentPage = 1, year = this.state.filterYear, sort = {}) => {
    const filter = { }

    if (!Array.isArray(filter.$or)) {
      filter.$or = []
    }

    if (!Array.isArray(filter.$and)) {
      filter.$and = []
    }

    filter.$and.push({ year: { condition: '=', value: year } })

    if (searchText !== '') {
      filter.$or.push({ string_date: { condition: 'ilike', value: `%${searchText}%` } })
      filter.$or.push({ name: { condition: 'ilike', value: `%${searchText}%` } })
    }

    this.setState({ loading: true })
    const holidays = await settingHolidayService.listItemByPage(currentPage, pageSize, filter, sort)
    this.setState({ holidays, loading: false, currentPageHoliday: currentPage, searchText })
  }

  handleCertFilter = (e) => {
    const { isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave } = this.state
    const filterParam = e.target.value

    if (filterParam === 'expiring') {
      this.setState({ isFindCertExpired: false, isFindCertificate: true })
      this.refreshList('carer', 1, isFindBirthday, isFindCarePlan, isFindCertExpired, isFindCertificate, isFindLeave)
    } else {
      this.setState({ isFindCertExpired: true, isFindCertificate: false })
      this.refreshList('carer', 1, isFindBirthday, isFindCarePlan, isFindCertExpired, isFindCertificate, isFindLeave)
    }
  }

  handleFunderFilter = (e) => {
    const { isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder } = this.state
    const filterParam = e.target.value

    if (filterParam === 'expiring') {
      this.refreshList('client', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, true, false)
    } else {
      this.refreshList('client', 1, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, false, true)
    }
  }

  handleHolidaysYear = (e) => {
    const year = e.target.value
    const { searchText } = this.state
    const filter = {}
    filter.year = { condition: '=', value: year }

    this.setState({ filterHolidayTab: filter})
    this.fetchHoliday(searchText, 1, year)
  }

  refreshList = async (filterParam, currentPage, isFindBirthday, isFindCarePlan, isFindCertificate, isFindCertExpired, isFindLeave, isFindFunderEnding, isFindFunder) => {
    this.setState({ loading: true })
    const { loadedByInitial } = this.state

    // Carer search filters
    if (filterParam === 'carer') {
      const { searchText } = this.state
      const filter = {}

      if (!Array.isArray(filter.$or)) {
        filter.$or = []
      }

      if (isFindLeave) {
        const paramOr = { type: { condition: 'ilike', value: '%leave%' } }
        filter.$or.push(paramOr)
        this.setState({ showFilter: false })
      }

      if (isFindBirthday) {
        const paramOr = { type: { condition: 'ilike', value: '%birthday%' } }
        filter.$or.push(paramOr)
        this.setState({ showFilter: false })
      }
      if (isFindCertificate) {
        const paramOr = { type: { condition: 'ilike', value: '%expiry%' } }
        filter.$or.push(paramOr)
        this.setState({ showFilter: true })
      }

      if (isFindCertExpired) {
        const paramOr = { type: { condition: 'ilike', value: '%expired%' } }
        filter.$or.push(paramOr)
        this.setState({ showFilter: true })
      }

      if (!isFindCertificate && !isFindCertExpired) {
        this.setState({ showFilter: false })
      }

      if (!isFindLeave) {
        this.setState({ loadedByInitial: false })
      }

      const employees = await employeeService.listByPageNotification(currentPage, pageSize, filter, {})

      return this.setState({ employees, loading: false, currentPageCarer: currentPage, filter, isFindBirthday, isFindCertificate, isFindCertExpired, isFindLeave })
      // return this.setState({ employees, loading: false, currentPageCarer: currentPage, isFindBirthday, isFindCertificate, isFindLeave })
    }

    // Client search filters
    if (filterParam === 'client') {
      const { searchText } = this.state
      const filter = {}

      if (!Array.isArray(filter.$or)) {
        filter.$or = []
      }

      // if (loadedByInitial) {
      //   const paramOr = { type: { condition: 'ilike', value: '%leave%' } }
      //   console.log(paramOr)
      //   filter.$or.push(paramOr)
      //   this.setState({ showFilter: false })
      // }

      if (isFindBirthday) {
        const paramOr = { type: { condition: 'ilike', value: '%birthday%' } }
        filter.$or.push(paramOr)
        this.setState({ showFunderFilter: false })
      }
      if (isFindLeave) {
        const paramOr = { type: { condition: 'ilike', value: '%leave%' } }
        filter.$or.push(paramOr)
        this.setState({ showFunderFilter: false })
      }
      if (isFindCarePlan) {
        const paramOr = { type: { condition: 'ilike', value: '%care plan%' } }
        filter.$or.push(paramOr)
        this.setState({ showFunderFilter: false })
      }
      if (isFindFunderEnding) {
        const paramOr = { type: { condition: 'ilike', value: '%ending%' } }
        filter.$or.push(paramOr)
        this.setState({ showFunderFilter: true })
      }

      if (isFindFunder) {
        const paramOr = { type: { condition: 'ilike', value: '%ended%' } }
        filter.$or.push(paramOr)
        this.setState({ showFunderFilter: true })
      }

      if (!isFindFunder && !isFindFunderEnding) {
        this.setState({ showFunderFilter: false })
      }

      if (!isFindLeave) {
        this.setState({ loadedByInitial: false })
      }

      const clients = await clientService.listByPageNotification(currentPage, pageSize, filter, {}, searchText)

      return this.setState({ clients, loading: false, currentPageClient: currentPage, filter, isFindBirthday, isFindCarePlan, isFindLeave, isFindFunderEnding, isFindFunder })
    }

    // Public Holidays search filters
    if (filterParam === 'holiday') {
      const { filterHolidayTab, searchText } = this.state
      const filter = {}

      if (!Array.isArray(filter.$or)) {
        filter.$or = []
      }

      if (!Array.isArray(filter.$and)) {
        filter.$and = []
      }

      filter.$and.push(filterHolidayTab)
      //filter.$and.push({ date: { condition: '>', value: moment().toISOString() } })
      if (searchText !== '') {
        filter.$or.push({ string_date: { condition: 'ilike', value: `%${searchText}%` } })
        filter.$or.push({ name: { condition: 'ilike', value: `%${searchText}%` } })
      }
      this.setState({ loading: true })

      const holidays = await settingHolidayService.listItemByPage(currentPage, pageSize, filter, {})
      return this.setState({ holidays, loading: false, currentPageHoliday: currentPage })
    }
  }

  onSearchName (value) {
    const { tabKey } = this.state

    if (tabKey === '1') {
      this.fetchCarer(value)
    } else if (tabKey === '2') {
      this.fetchClient(value)
    } else if (tabKey === '3') {
      this.fetchHoliday(value)
    } else {
      console.log('Search Failed.')
    }

    this.setState({searchText: value})
  }

  onTabClick = (key) => {
    this.setState({ tabKey: key })
  }
}

export default Form.create()(Notification)

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_CANCELLED_RECURRING_JOBS, FETCHING_CANCELLED_RECURRING_JOBS, CANCELLED_RECURRING_JOBS_FETCHED } from '../actions/cancelledRecurringJob'
import baseJobService from '../../services/baseJob'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_CANCELLED_RECURRING_JOBS, loading })
    const jobs = yield baseJobService.getCancelledJob(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: CANCELLED_RECURRING_JOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_CANCELLED_RECURRING_JOBS, loading: false })
    console.error(e)
  }
}

function * watchCancelledRecurringJobs () {
  yield takeEvery(FETCH_CANCELLED_RECURRING_JOBS, fetchJobs)
}

export default watchCancelledRecurringJobs

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { communicationService, jobService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import { formatter, log } from '../../../util'
import moment from 'moment'

// UI
import { Loading, List, Pager, Button, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const pageSize = 20

export class EmployeeCommunication extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      modal: {
        item: { link: {} },
        show: false
      },
      currentPage: 1,
      communication: {
        list: [],
        total: 0,
        currentPage: 1
      }
    }
  }

  componentDidMount () {
    this.fetchCommunications()
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { communication, currentPage, loading, modal } = this.state

    const commColumns = [
      {
        title: 'Date',
        width: 3,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
      {
        title: 'Clients',
        width: 3,
        render: ({ client_id, first_name, last_name }) => <div style={{ textTransform: 'capitalize' }}><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a></div>
      },
      {
        title: 'Event',
        width: 3,
        render: ({ event }) => <div style={{ textTransform: 'capitalize' }}>{event}</div>
      },
      {
        title: 'Job Time',
        width: 3,
        render: ({ job_start_date }) => formatter.toStandardDate(job_start_date)
      },
      {
        title: 'Subject',
        width: 7,
        key: 'subject'
      },
      {
        title: 'Sent?',
        width: 3,
        render: ({ sent_at }) => sent_at ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: 'Action',
        width: 2,
        render: ({ id, job_id, subject, body }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0}  title='Edit job'><div onClick={() => this.handleEditJobs(job_id)} style={{ cursor: 'pointer' }}><Icon type='thunderbolt' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0}  title='Edit email'><div onClick={() => this.handleEditEmail(id, subject, body)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0}  title='Send'><div onClick={() => this.handleSendEmail(id)} style={{ cursor: 'pointer' }}><Icon type='mail' /></div></Tooltip>
        </div>
      }
    ]

    return (
      <div className='communication-list'>
        <Skeleton loading={loading} active>
          <List cols={commColumns} rows={communication.list} />

          <Pager
            size={pageSize}
            total={communication.total}
            totalText={`Total ${communication.total} emails`}
            current={currentPage}
            onChange={(e) => this.onChangeCommunicationPage(e)}
            style={{ marginTop: '15px' }}
          />

          <SideModal
            title='Communication'
            showModal={modal.show}
            onClose={this.hideModal}
            buttons={[
              <Button key='0' onClick={this.handleSave}>Save</Button>,
              <Button key='1' onClick={this.handleSaveSend}>Save & Send</Button>
            ]}
          >
            <Form layout='vertical'>
              <FormItem label='Subject'>
                {getFieldDecorator('subject', {
                  initialValue: modal.item.subject,
                  rules: [
                    { min: 2, message: 'Subject must be between 2 and 128 characters' },
                    { max: 128, message: 'Subject must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter subject' },
                    { whitespace: true, message: 'Please enter subject' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label='Body'>
                {getFieldDecorator('body', {
                  initialValue: modal.item.body,
                  rules: [
                    { required: true, message: 'Please enter body' }
                  ]
                })(
                  <TextArea rows={12} />
                )}
              </FormItem>
            </Form>

          </SideModal>
        </Skeleton>
      </div>
    )
  }

  fetchCommunications = async (currentPage = 1) => {
    const { empId } = this.props

    this.setState({ loading: true })
    const filter = {}
    filter.employee_id = { condition: '=', value: empId }

    const communication = await jobService.getEmails(currentPage, pageSize, filter)
    this.setState({ communication: Object.assign(communication, currentPage), loading: false, currentPage, filter })
  }

  handleEditJobs = (id) => {
    const { history } = this.props
    history.replace(`/jobs/single/${id}`)
  }

  handleEditEmail = (id, subject, body) => {
    this.showModal(subject, body)
    this.setState({ communicationId: id })
  }

  handleSendEmail = async (id) => {
    this.setState({ loading: true })
    const result = communicationService.send(id)

    if (result) {
      notify.success('Sent successfully', 'Email sent successfully')

      const { communication } = this.state
      const comm = communication.list.find(item => item.id === id)
      comm.sent_at = moment()

      this.setState({communication})
    } else {
      notify.error('Unable to send successfully', 'Please try again later.')
    }
    this.setState({ loading: false })
  }

  handleSave = async () => {
    const { form } = this.props
    const { communicationId } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const response = await communicationService.save(communicationId, values)

        if (response.id) {
          const { communication } = this.state
          const comm = communication.list.find(item => item.id === communicationId)
          comm.subject = values.subject
          comm.body = values.body

          this.setState({communication})

          notify.success('Saved successfully', 'Email saved successfully')
          this.hideModal()
        } else {
          notify.error('Unable to save successfully', 'Unable to save Email successfully. Please try again later.')
        }
      }
    })
  }

  handleSaveSend = async () => {
    const { communicationId } = this.state

    await this.handleSave()

    setTimeout(() => {
      this.handleSendEmail(communicationId)
    }, 1200)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { subject: null, body: null }
    modal.show = false
    this.setState({ modal, communicationId: null })
  }

  showModal = (subject, body) => {
    const { modal } = this.state
    modal.item = { subject, body }
    modal.show = true
    this.setState({ modal })
  }

  onChangeCommunicationPage = async (currentPage) => {
    const { communication } = this.state
    communication.currentPage = currentPage
    this.setState({ communication, currentPage })
    this.fetchCommunications(currentPage)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  handleSendEmail = async (id) => {
    const { item } = this.state
    const emailData = await jobService.getEmailsById(id)
    const empEmail = item.email
    const emailSubject = emailData.item.subject
    const emailBody = emailData.item.body

    const emailParam = {
      'to': empEmail,
      'subject': emailSubject,
      'content': emailBody
    }

    const sendEmail = communicationService.sendEmail(emailParam)
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeCommunication))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientService, carePlanService, carePlanV2Service, settingFileCategoryService, settingFileTypeService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Button, DateTimePicker, FileUpload, Loading, List, Pager, SectionTitle, SideModal } from '../../../components'
import { ClientAccomondation } from '../../../constants'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Checkbox from 'antd/lib/checkbox'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import Notification from 'antd/lib/notification'
import { formatter, log, validator, uploader } from '../../../util'
import { diff } from 'json-diff'
import { apiHostname } from '../../../config'
import CarePlanFile from './file'
import AddFileModal from './AddFileModal'
import CarePlan from '../CarePlan'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane

const Option = Select.Option
const pageSize = 20
const clientModule = 'client'
const dateFormat = 'DD/MM/YYYY'
moment.tz.setDefault('Australia/Melbourne')

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const formItemLayoutCustom = (wrapper = 12, label = 6) => {
  return {
    labelCol: { sm: label, md: label, lg: label },
    wrapperCol: { sm: wrapper, md: wrapper, lg: wrapper }
  }
}

const formItemSelectLayout = {
  labelCol: { sm: 14, md: 14, lg: 17 },
  wrapperCol: { sm: 6, md: 6, lg: 6 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout2 = {
  labelCol: { sm: 9, md: 9, lg: 9 },
  wrapperCol: { sm: 15, md: 15, lg: 15 }
}

const DefaultAllergyItem = {
  hinfo_dm_allergy_name: '',
  hinfo_dm_allergy_effects: ''
}

const DefaultRiskItem = {
  risk_type: '',
  risk_name: '',
  risk_trigger: '',
  risk_mgnt_strategy: ''
}

const DefaultCPItem = {
  review_date: moment().add(1, 'year'),
  adl_bath_type: 'independent',
  adl_bath_type_desc: 'NA',
  adl_conti_products: 'NA',
  adl_conti_with_faeces: 0,
  adl_conti_with_urine: 0,
  adl_dietary_req: 'NA',
  adl_dressing_type: 'independent',
  adl_dressing_type_desc: 'NA',
  adl_grooming_type: 'independent',
  adl_grooming_type_desc: 'NA',
  adl_is_bowel: 0,
  adl_is_bsp: 0,
  adl_is_catheter: 0,
  adl_is_med_assist_prompt: 0,
  adl_mealtime_assist_type: 'independent',
  adl_mealtime_assist_type_desc: 'NA',
  adl_med_assist_prompt_desc: 'NA',
  adl_sleep_routine: 'NA',
  adl_toileting: 'NA',
  emp_allergies: 0,
  emp_assist_in_normal_emergency: 0,
  emp_assist_in_normal_emergency_desc: 'NA',
  emp_assist_med: 0,
  emp_asthma: 0,
  emp_epilepsy: 0,
  emp_natural_disaster: 0,
  emp_other: 0,
  hinfo_dm_conditions: 'NA',
  lar_accomondation: 'own-family-home',
  lar_cctv_installed_desc: 'NA',
  lar_home_entry: 'NA',
  lar_is_cctv_installed: 0,
  lar_is_equipment_commu: null,
  lar_is_equipment_mobility: null,
  lar_is_flu_vac: 0,
  lar_is_limited_commu: null,
  lar_is_limited_mobility: null,
  lar_is_live_alone: 0,
  lar_is_rcv_support: null,
  lar_is_regular_f2f: null,
  lar_living_arrangement: 'NA',
  purpose_of_service: 'NA',
  sinfo_communication: 'verbal',
  sinfo_communication_desc: 'NA',
  sinfo_mobility: 'independent',
  sinfo_mobility_desc: 'NA',
  sinfo_social_interests: 'NA',
  sinfo_travels_select: ['public-transport', 'taxi'],
  sinfo_vehicle_size_req: 'NA'
}

export class ClientCarePlanV2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      item: {},
      loading: false,
      clientId: '',
      mainCats: [],
      subCats: [],
      currentSubCats: [],
      // carePlan: {},
      carePlan: {},
      carePlanVersions: { list: [] },
      version: 0,
      highlightFields: [],
      shouldRefreshFiles: false,
      listHealthAllergy: [],
      listRiskAnalysis: [],
      listFile: [],
      listFileErrMsg: [],
      listPastHealthAllergy: [],
      listPastRiskAnalysis: [],
      listPastFile: [],
      isShowFileModal: false,
      modalFileItem: {},
      // inputs flags
      isLiveAlone: null,
      isCCTVInstalled: null,
      isEmpAllergies: null,
      isEmpEpilepsy: null,
      isEmpAssistMed: null,
      isEmpNaturalDisaster: null,
      isEmpAsthma: null,
      isEmpOther: null,
      isRequireAssist: null,
      isCommunication: null,
      isMobility: null,
      isBathing: null,
      isGrooming: null,
      isDressing: null,
      isMealtimeAssist: null,
      isMedicAssist: null,
      isContinence: null
    }
  }

  componentDidMount () {
    // console.log('CarePlan props', this.props)
    this.fetchCarePlan()
    this.fetchCarePlanVersion()
    this.fetchFileCats()
  }

  viewVersion = (version) => {
    const { carePlanVersions } = this.state
    const changeIds = []

    this.props.form.resetFields()

    const currentVersion = carePlanVersions.list.filter(item => item.version === version)

    if (validator.isNotEmptyArray(currentVersion)) {
      const cp = currentVersion[0]

      const listPastHealthAllergy = validator.isNotEmptyArray(cp.list_health_allergy) ? cp.list_health_allergy : []
      const listPastRiskAnalysis = validator.isNotEmptyArray(cp.list_risk_analysis) ? cp.list_risk_analysis : []
      const listPastFile = validator.isNotEmptyArray(cp.list_files) ? cp.list_files : []

      this.setState({
        version,
        carePlan: cp,
        listPastHealthAllergy,
        listPastRiskAnalysis,
        listPastFile
      })
    }


    if (version > 1) {
      const previousVersion = carePlanVersions.list.filter(item => item.version === version - 1)

      const changes = diff(currentVersion[0], previousVersion[0])
      // console.log('changes 1', changes)

      for (const key of Object.keys(changes)) {
        const value = changes[key]
        // console.log('changes 2', key, value)

        if (value && value.hasOwnProperty('__new')) {
          // check if the value is date
          if (value.__old && (value.__old.toString().indexOf('.000Z') > -1 || value.__old.toString().indexOf('.999Z') > -1)) {
            // skip if no changes on date
            if (!moment(value.__old).isSame(moment(value.__new))) {
              changeIds.push(key)
            } else if (value.__new === null) {
              changeIds.push(key)
            }
          } else {
            if (value.__old && value.__new && (value.__old.toString() !== value.__new.toString())) {
              changeIds.push(key)
            } else if (!value.__old && value.__new) {
              changeIds.push(key)
            } else if (value.__old && !value.__new) {
              changeIds.push(key)
            }
          }
        } else if (value !== null && value !== undefined && key && key.indexOf('__deleted') > -1) {
          const newKey = key.replace('__deleted', '')
          changeIds.push(newKey)
        }
      }
    }
    this.setState({ highlightFields: changeIds })
  }

  shouldHighlight = (field, isPadding = true, isPaddingRight = false) => {
    const { highlightFields } = this.state
    const result = highlightFields.find(item => item === field)
    return {
      backgroundColor: result ? '#fff5ddAA' : '#fff',
      paddingTop: isPadding ? '8px' : '0px',
      paddingBottom: isPadding ? '8px' : '0px',
      paddingRight: isPaddingRight ? '15px' : '0px'
      // padding: isPadding ? '8px 0px' : '0px 0px'
    }
  }

  getYNRowWide = (field, title, isMandatory = false) => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { carePlan } = this.state

    return (
      <div className='row-care-plan' key={`input-rr-${field}`}>
        <div style={{width: '16%'}}></div>
        <div style={{width: '50%'}} className={`title ${!isMandatory ? 'disabled' : ''}`}><span><span className='alert-text'>{isMandatory ? '* ' : ''}</span>{title}</span></div>
        <div style={{width: '21%'}}>
          <FormItem>
            {getFieldDecorator(field, {
              initialValue: carePlan[field],
              rules: [
                { required: isMandatory, message: 'Please select.' }
              ]
            })(
              <Radio.Group disabled={!isMandatory} name={field} style={this.shouldHighlight(field)}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
                <Radio value={2}>Undisclosed</Radio>
              </Radio.Group>
            )}
          </FormItem>
        </div>
        <div style={{width: '8%'}} className='alert-text'>
          {/* Please Select */}
        </div>
        <div style={{width: '5%'}}></div>
      </div>
    )
  }

  getYNRowHalf = (field, title, isMandatory = false) => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { carePlan } = this.state

    return (
      <div className='row-care-plan' key={`input-rr-${field}`}>
        <div style={{width: '40%', textAlign: 'right'}} className={`title ${!isMandatory ? 'disabled' : ''}`}><span className='label-text'><span className='alert-text'>{isMandatory ? '* ' : ''}</span>{title}</span></div>
        <div style={{width: '26%'}}>
          <FormItem>
            {getFieldDecorator(field, {
              initialValue: carePlan[field],
              rules: [
                { required: isMandatory, message: 'Please select.' }
              ]
            })(
              <Radio.Group disabled={!isMandatory} name={field} style={this.shouldHighlight(field)}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
                <Radio value={2}>Undisclosed</Radio>
              </Radio.Group>
            )}
          </FormItem>
        </div>
        <div style={{width: '18%'}} className='alert-text'>
          {/* Please Select */}
        </div>
      </div>
    )
  }

  getUploader = (isCurrentVersion, isFlag, field, type) => {
    const { carePlan, listFile, listFileErrMsg, listPastFile, mainCats, subCats } = this.state
    const isButtonEnable = validator.isNotEmptyArray(mainCats) && validator.isNotEmptyArray(subCats)
    const f = isCurrentVersion ? listFile.find(e => e.type === type) : listPastFile.find(e => e.type === type)
    const err = isCurrentVersion ? listFileErrMsg.find(e => e === type) : null

    return (
      <div className='row-care-plan-left' style={{marginTop: '6px'}}>
        { isCurrentVersion
          ? <div
            className={`file-name-icon ${isFlag && isButtonEnable ? '' : 'disabled'}`}
            onClick={isFlag && isButtonEnable ? () => this.showAddFileModal(true, type) : undefined}
          >
            <Icon type='upload' />
          </div>
          : null }
        <div>
          { !f
            ? (err && isCurrentVersion
              ? <div className='file-name-text-alert'>Please upload file.</div>
              : null)
            : f.id === null
              ? <div className='file-name-text-alert'>
                The file is removed.
              </div>
              : <div className='row-care-plan-left' style={this.shouldHighlight(`${field}_file_id`, false)}>
                <div className='file-name-text-alert'>
                  {f.id === undefined
                    ? 'New!'
                    : <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(f.file_name)}`}>
                      <div onClick={this.handleDownloadFile(f.file_url)} className='file-name-icon'>
                        <Icon type='file-text' />
                      </div>
                    </Tooltip>
                  }</div>
                <div className='file-name-label'>{f.label}</div>
                <div className='file-name-text'>[{f.file_name}]</div>
              </div>}
        </div>
      </div>
    )

  }

  render () {
    const { form, clientId, history } = this.props
    const { getFieldDecorator } = form
    const { carePlan = '', loading, carePlanVersions, version, shouldRefreshFiles } = this.state

    return (<Form>
      <Loading loading={loading} blur>

        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
          <TabPane tab="Care Plan" key="1">
            { this.carePlanTab() }
          </TabPane>
          <TabPane tab="Legacy" key="2">
            <CarePlan clientId={clientId} history={history} />
          </TabPane>
          {this.hasAccess('readClientFile')
          ? <TabPane tab='Files' key='10'>
              <CarePlanFile
                clientId={clientId}
                history={history}
                // onUpdateInfo={() => this.handleFilesUpdateAction()}
                onUpdateInfo={() => {}}
                onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })}
                shouldRefreshFiles={shouldRefreshFiles}
              />
            </TabPane>
          : null}
          {/* <TabPane tab="Files" key="2">
            <CarePlanFile clientId={clientId} history={this.props.history} onUpdateInfo={() => ''} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
          </TabPane> */}
        </Tabs>
      </Loading>
    </Form>)
  }

  validateMultiBoolEntry (value) {
    return validator.isDigit(value)
  }

  validateInputs = () => {
    const { form } = this.props
    const values = form.getFieldsValue()

    if (values['lar_is_live_alone'] !== undefined) {
      this.updateInputFlag({target: {value: values['lar_is_live_alone']}}, 'isLiveAlone')
    }

    if (values['lar_is_cctv_installed'] !== undefined) {
      this.updateInputFlag({target: {value: values['lar_is_cctv_installed']}}, 'isCCTVInstalled', 'lar_cctv_installed_desc')
    }

    if (values['emp_allergies'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_allergies']}}, 'isEmpAllergies')
    }

    if (values['emp_epilepsy'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_epilepsy']}}, 'isEmpEpilepsy')
    }

    if (values['emp_assist_med'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_assist_med']}}, 'isEmpAssistMed')
    }

    if (values['emp_natural_disaster'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_natural_disaster']}}, 'isEmpNaturalDisaster')
    }

    if (values['emp_asthma'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_asthma']}}, 'isEmpAsthma')
    }

    if (values['emp_other'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_other']}}, 'isEmpOther')
    }

    if (values['emp_assist_in_normal_emergency'] !== undefined) {
      this.updateInputFlag({target: {value: values['emp_assist_in_normal_emergency']}}, 'isRequireAssist', 'emp_assist_in_normal_emergency_desc')
    }

    if (values['sinfo_communication'] !== undefined) {
      this.updateInputFlag({target: {value: values['sinfo_communication']}}, 'isCommunication', 'sinfo_communication_desc')
    }

    if (values['sinfo_mobility'] !== undefined) {
      this.updateInputFlag({target: {value: values['sinfo_mobility']}}, 'isMobility', 'sinfo_mobility_desc')
    }

    if (values['adl_bath_type'] !== undefined) {
      this.updateInputFlag({target: {value: values['adl_bath_type']}}, 'isBathing', 'adl_bath_type_desc')
    }

    if (values['adl_grooming_type'] !== undefined) {
      this.updateInputFlag({target: {value: values['adl_grooming_type']}}, 'isGrooming', 'adl_grooming_type_desc')
    }

    if (values['adl_dressing_type'] !== undefined) {
      this.updateInputFlag({target: {value: values['adl_dressing_type']}}, 'isDressing', 'adl_dressing_type_desc')
    }

    if (values['adl_mealtime_assist_type'] !== undefined) {
      this.updateInputFlag({target: {value: values['adl_mealtime_assist_type']}}, 'isMealtimeAssist', 'adl_mealtime_assist_type_desc')
    }

    if (values['adl_is_med_assist_prompt'] !== undefined) {
      this.updateInputFlag({target: {value: values['adl_is_med_assist_prompt']}}, 'isMedicAssist', 'adl_med_assist_prompt_desc')
    }

    if (values['adl_conti_with_urine'] !== undefined) {
      this.updateContinence({target: {value: values['adl_conti_with_urine']}}, 'adl_conti_with_urine')
    }

    // console.log('validate inputs', values)
  }

  updateInputFlag (e, field = '', inputField = '', isInverse = false) {
    const { form } = this.props
    const { validateFields } = form
    const value = e.target.value
    let finalVal = null
    if (field) {
      if (typeof value === 'boolean') {
        finalVal = isInverse ? !value : value
        // this.setState({[field]: isInverse ? !value : value})
      } else if (this.validateMultiBoolEntry(value)) {
        const bool = parseInt(value) === 1
        finalVal = isInverse ? !bool : bool
      } else if (value === 'verbal' || value === 'independent' || value === 'unknown') {
        finalVal = isInverse ? false : true
      } else {
        finalVal = isInverse ? true : false
      }

      this.setState({[field]: finalVal}, () => {
        if (inputField) {
          validateFields([inputField])
        }
      })
    }
  }

  updateContinence (e, field) {
    const value = e.target.value
    const { form } = this.props
    const { getFieldValue } = form
    const cField = field === 'adl_conti_with_urine'
      ? getFieldValue('adl_conti_with_faeces')
      : field === 'adl_conti_with_faeces'
        ? getFieldValue('adl_conti_with_urine')
        : null
    let cValue = false

    if (cField !== null) {
      if (cField === false && value === false) {
        cValue = true
      } else if (this.validateMultiBoolEntry(cField) && this.validateMultiBoolEntry(value)) {
        if (parseInt(cField) !== 1 && parseInt(value) !== 1) {
          cValue = true
        }
      }
    }

    this.setState({ isContinence: cValue })
  }

  carePlanTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      carePlan = {},
      loading,
      carePlanVersions,
      version,
      listHealthAllergy,
      listRiskAnalysis,
      listPastHealthAllergy,
      listPastRiskAnalysis,
      listFile,
      listPastFile,
      listFileErrMsg,
      mainCats,
      subCats,
      currentSubCats,
      isShowFileModal,
      modalFileItem,
      // flags
      isLiveAlone,
      isCCTVInstalled,
      isEmpAllergies,
      isEmpEpilepsy,
      isEmpAssistMed,
      isEmpNaturalDisaster,
      isEmpAsthma,
      isEmpOther,
      isRequireAssist,
      isCommunication,
      isMobility,
      isBathing,
      isGrooming,
      isDressing,
      isMealtimeAssist,
      isMedicAssist,
      isContinence
    } = this.state

    const id = this.getId()
    const isCurrentVersion = !loading && version === carePlanVersions.list.length

    return <Row gutter={16}>
      <Col lg={20}>
        <Row>
          <Col className='button-row'>
            { !loading && carePlanVersions.total > 0
              ? <div className='btn btn-ghost' style={{ width: 180 }} onClick={this.handleDownload}>
              Download Care Plan
              </div> : null
            }
            { !loading && isCurrentVersion
              ? <div className='btn' onClick={this.handleSave}>
              Save Care Plan
              </div> : null
            }
          </Col>
        </Row>

        {/** ----- review date ------- */}
        <SectionTitle title='Review Date' />
        <FormItem {...formItemLayout} label='Review Date' hasFeedback>
          {getFieldDecorator('review_date', {
            initialValue: carePlan.review_date ? moment(carePlan.review_date) : moment().add(1, 'year'),
            rules: [
              { required: true, message: 'Please enter Plan Review Date' }
            ]
          })(
            <DatePicker
              format={dateFormat}
              defaultPickerValue={moment(new Date())}
            />
          )}
        </FormItem>

        {/* <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Current Version Review Date'>
              <span style={{...this.shouldHighlight('review_date'), lineHeight: '38px'}}>{carePlan.review_date ? moment(carePlan.review_date).format(dateFormat) : 'No review date available.'}</span>
            </FormItem>
          </Col>
        </Row> */}

        {/** ----- service goals ------- */}
        <SectionTitle title='Service Goals' />

        <FormItem {...formItemLayout} label='Purpose Of Service' hasFeedback>
          {getFieldDecorator('purpose_of_service', {
            initialValue: carePlan.purpose_of_service || '',
            rules: [
              { required: true, message: 'Please enter Purpose Of Service' }
            ]
          })(
            <TextArea
              rows={3}
              placeholder={'What goals would the client like to achieve through receiving this service?'}
              style={this.shouldHighlight('purpose_of_service')}
            />
          )}
        </FormItem>

        {/** ----- living arrangemenet ------- */}
        <SectionTitle title='Living Arrangement' />

        <FormItem {...formItemLayout} label='Lives Alone'>
          {getFieldDecorator('lar_is_live_alone', {
            initialValue: carePlan.lar_is_live_alone,
            rules: [
              { required: true, message: 'Please select Lives Alone' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isLiveAlone')}
              name='lar_is_live_alone'
              style={this.shouldHighlight('lar_is_live_alone')}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
              <Radio value={2}>Undisclosed</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Acommondation' hasFeedback>
              {getFieldDecorator('lar_accomondation', {
                initialValue: carePlan.lar_accomondation,
                rules: [
                  { required: true, message: 'Please select Accommondation.' }
                ]
              })(
                <Select
                  style={this.shouldHighlight('lar_accomondation')}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    ClientAccomondation.map((acc) => {
                      return <Option value={acc.value} key={acc.value}>{acc.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Flu Vac required?'>
              {getFieldDecorator('lar_is_flu_vac', {
                initialValue: carePlan.lar_is_flu_vac,
                rules: [
                  { required: true, message: 'Please select Flu Vac' }
                ]
              })(
                <Radio.Group name='lar_is_flu_vac' style={this.shouldHighlight('lar_is_flu_vac')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayout} label='Living Arrangement' hasFeedback>
          {getFieldDecorator('lar_living_arrangement', {
            initialValue: carePlan.lar_living_arrangement,
            rules: [
              { required: true, message: 'Please enter Living Arrangement.' }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'Details of current living arrangements, e.g. who lives with client'}
              style={this.shouldHighlight('lar_living_arrangement')}
            />
          )}
        </FormItem>

        { this.getYNRowWide('lar_is_rcv_support', 'Receives support from other support workers or providers?', isLiveAlone) }
        { this.getYNRowWide('lar_is_regular_f2f', 'Has regular face-to-face assistance/support from family, friends or community members?', isLiveAlone) }
        { this.getYNRowWide('lar_is_limited_mobility', 'Has limited or no physical mobility without assistance?', isLiveAlone) }
        { this.getYNRowWide('lar_is_equipment_mobility', 'Uses equipment to enable/facilitate physical mobility?', isLiveAlone) }
        { this.getYNRowWide('lar_is_limited_commu', 'Has limited or no communication ability without assistance?', isLiveAlone) }
        { this.getYNRowWide('lar_is_equipment_commu', 'Uses equipment to enable/facilitate communication or the use of a phone/device?', isLiveAlone) }

        <div style={{marginBottom: '24px'}}></div>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Home Entry' hasFeedback>
              {getFieldDecorator('lar_home_entry', {
                initialValue: carePlan.lar_home_entry,
                rules: [
                  { required: true, message: 'Please enter Home Entry.' }
                ]
              })(
                <TextArea
                  rows={4}
                  style={this.shouldHighlight('lar_home_entry')}
                  placeholder={'Details such Key Code, only enter via back door, etc.'}
                />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='CCTV Installed?' style={{marginBottom: '5px'}}>
              {getFieldDecorator('lar_is_cctv_installed', {
                initialValue: carePlan.lar_is_cctv_installed,
                rules: [
                  { required: true, message: 'Please select CCTV Installed' }
                ]
              })(
                <Radio.Group onChange={(e) => this.updateInputFlag(e, 'isCCTVInstalled', 'lar_cctv_installed_desc')} name='lar_is_cctv_installed' style={this.shouldHighlight('lar_is_cctv_installed')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
            <Row>
              <Col lg={12}>

              </Col>
              <Col lg={12}>
                <FormItem hasFeedback>
                  {getFieldDecorator('lar_cctv_installed_desc', {
                    initialValue: carePlan.lar_cctv_installed_desc,
                    rules: [
                      { required: isCCTVInstalled, message: 'Please enter CCTV description' }
                    ]
                  })(
                    <TextArea
                      disabled={!isCCTVInstalled}
                      rows={4}
                      style={this.shouldHighlight('lar_cctv_installed_desc')}
                      placeholder={'If Yes, please provide more info (how is it used, where is it located, are the recordings kept)'}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        {/** ----- health info ------- */}
        <SectionTitle title='Health Info' />

        <FormItem {...formItemLayout} label='Diagnosis/Medical conditions' hasFeedback>
          {getFieldDecorator('hinfo_dm_conditions', {
            initialValue: carePlan.hinfo_dm_conditions,
            rules: [
              { required: true, message: 'Please enter Diagnosis/Medical conditions' }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'Include relevant medical / surgical history, disability.'}
              style={this.shouldHighlight('hinfo_dm_conditions')}
            />
          )}
        </FormItem>

        <FormItem {...formItemLayoutCustom(12)} label='Medications Assist/Prompt' style={{marginBottom: '5px'}}>
          {getFieldDecorator('hinfo_is_med_assist_prompt', {
            initialValue: carePlan.hinfo_is_med_assist_prompt,
            rules: [
              { required: true, message: 'Please select Medications Assist/Prompt' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isMedicAssist', 'hinfo_med_assist_prompt_desc')}
              name='hinfo_is_med_assist_prompt'
              style={this.shouldHighlight('hinfo_is_med_assist_prompt')}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
              <Radio value={2}>Undisclosed</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('hinfo_med_assist_prompt_desc', {
                initialValue: carePlan.hinfo_med_assist_prompt_desc,
                rules: [
                  { required: isMedicAssist, message: 'Please enter Medications Assist/Prompt description.' }
                ]
              })(
                <TextArea
                  rows={4}
                  disabled={!isMedicAssist}
                  style={this.shouldHighlight(`hinfo_med_assist_prompt_desc`)}
                  placeholder={'If Yes, please provide more information/describe process, e.g. – fed through Peg etc.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <div className='row-custom-title'>
          <div>Details of allergies of medications, foods, wound dressings etc. and their effects.</div>
          { isCurrentVersion
            ? <div className='btn' style={{marginBottom: '0px'}} onClick={() => this.updateNewAllergy()}>Add Allergy</div>
            : null }
        </div>

        { isCurrentVersion
          ? listHealthAllergy.map((e, idx) => {
          return (
            <Row key={`hal${idx}`}>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label={`Allergy ${idx + 1}`} hasFeedback>
                  {getFieldDecorator(`hinfo_dm_allergy_name${idx}`, {
                    initialValue: e.hinfo_dm_allergy_name || null,
                    rules: [
                      { required: true, message: 'Please enter Allergy Name.' }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={this.shouldHighlight(`hinfo_dm_allergy_name${idx}`)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={11}>
                <FormItem {...sideBySideFormItemLayout} label={`Effects ${idx + 1}`} hasFeedback>
                  {getFieldDecorator(`hinfo_dm_allergy_effects${idx}`, {
                    initialValue: e.hinfo_dm_allergy_effects || null,
                    rules: [
                      { required: true, message: 'Please enter Allergy Effects.' }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={this.shouldHighlight(`hinfo_dm_allergy_effects${idx}`)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={1}>
                <Tooltip mouseLeaveDelay={0} title='Delete Allergy'>
                  <Popconfirm
                    title={`Are you sure you want to delete this allergy?`}
                    onConfirm={() => this.updateNewAllergy(idx)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Icon type='delete' style={{ marginLeft: '15px' }} />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          )})
          : listPastHealthAllergy.map((e, idx) => {
            return (
              <Row key={`halp${idx}`}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label={`Allergy ${idx + 1}`} hasFeedback>
                    {getFieldDecorator(`hinfo_dm_allergy_name${idx}`, {
                      initialValue: e.hinfo_dm_allergy_name || null,
                      rules: [
                        { required: true, message: 'Please enter Allergy Name.' }
                      ]
                    })(
                      <TextArea
                        rows={2}
                        style={this.shouldHighlight(`hinfo_dm_allergy_name${idx}`)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={11}>
                  <FormItem {...sideBySideFormItemLayout} label={`Effects ${idx + 1}`} hasFeedback>
                    {getFieldDecorator(`hinfo_dm_allergy_effects${idx}`, {
                      initialValue: e.hinfo_dm_allergy_effects || null,
                      rules: [
                        { required: true, message: 'Please enter Allergy Effects.' }
                      ]
                    })(
                      <TextArea
                        rows={2}
                        style={this.shouldHighlight(`hinfo_dm_allergy_effects${idx}`)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={1}>
                  <Tooltip mouseLeaveDelay={0} title='Delete Allergy'>
                    <Popconfirm
                      title={`Are you sure you want to delete this allergy?`}
                      onConfirm={() => this.updateNewAllergy(idx)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Icon type='delete' style={{ marginLeft: '15px' }} />
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            )})
          }

        {/** ----- EMP ------- */}
        <SectionTitle title='Emergency Management Plans' />
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Allergies EMP' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_allergies', {
                initialValue: carePlan.emp_allergies,
                rules: [
                  { required: true, message: 'Please select Allergies EMP' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpAllergies')}
                  name='emp_allergies'
                  style={this.shouldHighlight('emp_allergies')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpAllergies, 'emp_allergies', 'emp_allergies')}
          </Col>
        </Row>
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Epilepsy EMP' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_epilepsy', {
                initialValue: carePlan.emp_epilepsy,
                rules: [
                  { required: true, message: 'Please select Epilepsy EMP' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpEpilepsy')}
                  name='emp_epilepsy'
                  style={this.shouldHighlight('emp_epilepsy')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpEpilepsy, 'emp_epilepsy', 'emp_epilepsy')}
          </Col>
        </Row>
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Assist with med EMP' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_assist_med', {
                initialValue: carePlan.emp_assist_med,
                rules: [
                  { required: true, message: 'Please select Assist with med EMP' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpAssistMed')}
                  name='emp_assist_med'
                  style={this.shouldHighlight('emp_assist_med')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpAssistMed, 'emp_assist_med', 'emp_assist_med')}
          </Col>
        </Row>
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Natural Disaster EMP' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_natural_disaster', {
                initialValue: carePlan.emp_natural_disaster,
                rules: [
                  { required: true, message: 'Please select Natural Disaster EMP' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpNaturalDisaster')}
                  name='emp_natural_disaster'
                  style={this.shouldHighlight('emp_natural_disaster')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpNaturalDisaster, 'emp_natural_disaster', 'emp_natural_disaster')}
          </Col>
        </Row>
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Asthma EMP' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_asthma', {
                initialValue: carePlan.emp_asthma,
                rules: [
                  { required: true, message: 'Please select Asthma EMP' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpAsthma')}
                  name='emp_asthma'
                  style={this.shouldHighlight('emp_asthma')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpAsthma, 'emp_asthma', 'emp_asthma')}
          </Col>
        </Row>
        <Row>
          <Col lg={11}>
            <FormItem {...formItemLayoutCustom(7, 17)} label='Other' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_other', {
                initialValue: carePlan.emp_other,
                rules: [
                  { required: true, message: 'Please select Other' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isEmpOther')}
                  name='emp_other'
                  style={this.shouldHighlight('emp_other')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={13}>
            { this.getUploader(isCurrentVersion, isEmpOther, 'emp_other', 'emp_other')}
          </Col>
        </Row>
        <Row>
          <Col lg={17}>
            <FormItem {...formItemLayoutCustom(13,11)} label='Requires assistance in a natural emergency?' style={{marginBottom: '5px'}}>
              {getFieldDecorator('emp_assist_in_normal_emergency', {
                initialValue: carePlan.emp_assist_in_normal_emergency,
                rules: [
                  { required: true, message: 'Please select Requires assistance' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateInputFlag(e, 'isRequireAssist', 'emp_assist_in_normal_emergency_desc')}
                  name='emp_assist_in_normal_emergency'
                  style={this.shouldHighlight('emp_assist_in_normal_emergency')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col lg={7}>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16} >
            <FormItem hasFeedback>
              {getFieldDecorator('emp_assist_in_normal_emergency_desc', {
                initialValue: carePlan.emp_assist_in_normal_emergency_desc,
                rules: [
                  { required: isRequireAssist, message: 'Please enter Require Assistance description.' }
                ]
              })(
                <TextArea
                  disabled={!isRequireAssist}
                  rows={4}
                  style={this.shouldHighlight(`emp_assist_in_normal_emergency_desc`)}
                  placeholder={'Details such as flood zone, bushfire zone, etc.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        {/** ----- Supports Info ------- */}
        <SectionTitle title='Supports Info' />
        <FormItem {...formItemLayoutCustom(12)} label='Communication' style={{marginBottom: '5px'}}>
          {getFieldDecorator('sinfo_communication', {
            initialValue: carePlan.sinfo_communication || null,
            rules: [
              { required: true, message: 'Please select Communication' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isCommunication', 'sinfo_communication_desc')}
              name='sinfo_communication'
              style={this.shouldHighlight('sinfo_communication')}>
              <Radio className='vertical-radio' value={'verbal'}>Verbal/Can self-communicate</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partially verbal /can self-communicate (uses communication cards, single words)</Radio>
              <Radio className='vertical-radio' value={'non-verbal'}>Non-verbal (no ability to communicate, no comprehension)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('sinfo_communication_desc', {
                initialValue: carePlan.sinfo_communication_desc,
                rules: [
                  { required: !isCommunication, message: 'Please enter Require Assistance description.' }
                ]
              })(
                <TextArea
                  disabled={isCommunication}
                  rows={4}
                  style={this.shouldHighlight(`sinfo_communication_desc`)}
                  placeholder={'Assistance details such as support needed with hearing, comprehension, and vision. Consider speech and written communication, aides used – compic, pecs, Auslan, languages other than English, so on.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayoutCustom(9)} label='Mobility' style={{marginBottom: '5px'}}>
          {getFieldDecorator('sinfo_mobility', {
            initialValue: carePlan.sinfo_mobility || null,
            rules: [
              { required: true, message: 'Please select Mobility' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isMobility', 'sinfo_mobility_desc')}
              name='sinfo_mobility'
              style={this.shouldHighlight('sinfo_mobility')}>
              <Radio className='vertical-radio' value={'independent'}>Independent</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partial assistance (falls risk, keep steady, assisted transfer)</Radio>
              <Radio className='vertical-radio' value={'full'}>Full assistance (wheelchair, hoist etc)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('sinfo_mobility_desc', {
                initialValue: carePlan.sinfo_mobility_desc,
                rules: [
                  { required: !isMobility, message: 'Please enter Mobility description.' }
                ]
              })(
                <TextArea
                  disabled={isMobility}
                  rows={4}
                  style={this.shouldHighlight(`sinfo_mobility_desc`)}
                  placeholder={'Assistance details such as consider aids, falls, transfers – bed/chair, hoisting required, 1 or 2-person assistance, wheelchair type , so on.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayout} label='Usually travels in' style={{marginBottom: '5px'}}>
          {getFieldDecorator('sinfo_travels_select', {
            initialValue: carePlan.sinfo_travels_select || null,
            rules: [
              { required: true, message: 'Please select travel method' }
            ]
          })(
            <Checkbox.Group name='sinfo_travels_select' style={this.shouldHighlight('sinfo_travels_select')}>
              <Checkbox value={'public-transport'}>Public Transport</Checkbox>
              <Checkbox value={'taxi'}>Taxi</Checkbox>
              <Checkbox value={'sc-car'}>Support Worker's car</Checkbox>
              <Checkbox value={'own-car'}>Own car</Checkbox>
            </Checkbox.Group>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Vehicle size/requirement' hasFeedback>
          {getFieldDecorator('sinfo_vehicle_size_req', {
            initialValue: carePlan.sinfo_vehicle_size_req,
            rules: [
              { required: true, message: 'Please enter Vehicle size/requirement.' }
            ]
          })(
            <TextArea
              rows={4}
              style={this.shouldHighlight('sinfo_vehicle_size_req')}
            />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Social Interests' hasFeedback>
          {getFieldDecorator('sinfo_social_interests', {
            initialValue: carePlan.sinfo_social_interests,
            rules: [
              { required: true, message: 'Please enter Social Interests.' }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'Include hobbies, regular activities, occupation.'}
              style={this.shouldHighlight('sinfo_social_interests')}
            />
          )}
        </FormItem>

        {/** ----- Assisted Daily Living ------- */}
        <SectionTitle title='Assisted Daily Living' />
        <FormItem {...formItemLayoutCustom(13)} label='Bathing/Showering' style={{marginBottom: '5px'}}>
          {getFieldDecorator('adl_bath_type', {
            initialValue: carePlan.adl_bath_type || null,
            rules: [
              { required: true, message: 'Please select Bathing/Showering' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isBathing', 'adl_bath_type_desc')}
              name='adl_bath_type'
              style={this.shouldHighlight('adl_bath_type')}>
              <Radio className='vertical-radio' value={'independent'}>Independent</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partial assistance (assist with hair, back, legs etc., prompting required, falls risk etc.)</Radio>
              <Radio className='vertical-radio' value={'full'}>Full assistance (cannot bathe/shower independently)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('adl_bath_type_desc', {
                initialValue: carePlan.adl_bath_type_desc,
                rules: [
                  { required: !isBathing, message: 'Please enter Bathing/Showering description.' }
                ]
              })(
                <TextArea
                  rows={4}
                  disabled={isBathing}
                  style={this.shouldHighlight(`adl_bath_type_desc`)}
                  placeholder={'Assistance details such as consider aids, falls, transfers – shower/bath, hoisting required, 1 or 2-person assistance, wheelchair type, so on.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayoutCustom(13)} label='Grooming' style={{marginBottom: '5px'}}>
          {getFieldDecorator('adl_grooming_type', {
            initialValue: carePlan.adl_grooming_type || null,
            rules: [
              { required: true, message: 'Please select Grooming' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isGrooming', 'adl_grooming_type_desc')}
              name='adl_grooming_type'
              style={this.shouldHighlight('adl_grooming_type')}>
              <Radio className='vertical-radio' value={'independent'}>Independent</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partial assistance (assist with hair, shave etc., prompting required, falls risk etc.) </Radio>
              <Radio className='vertical-radio' value={'full'}>Full assistance (cannot groom independently)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('adl_grooming_type_desc', {
                initialValue: carePlan.adl_grooming_type_desc,
                rules: [
                  { required: !isGrooming, message: 'Please enter Grooming description.' }
                ]
              })(
                <TextArea
                  rows={4}
                  disabled={isGrooming}
                  style={this.shouldHighlight(`adl_grooming_type_desc`)}
                  placeholder={'Assistance details such as oral hygiene, hair care, make up, shaving, menstrual care, so on.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayoutCustom(13)} label='Dressing' style={{marginBottom: '5px'}}>
          {getFieldDecorator('adl_dressing_type', {
            initialValue: carePlan.adl_dressing_type || null,
            rules: [
              { required: true, message: 'Please select Dressing' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isDressing', 'adl_dressing_type_desc')}
              name='adl_dressing_type'
              style={this.shouldHighlight('adl_dressing_type')}>
              <Radio className='vertical-radio' value={'independent'}>Independent</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partial assistance (assist with buttons, laces, pants etc., prompting required, falls risk etc.)</Radio>
              <Radio className='vertical-radio' value={'full'}>Full assistance (cannot dress independently)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('adl_dressing_type_desc', {
                initialValue: carePlan.adl_dressing_type_desc,
                rules: [
                  { required: !isDressing, message: 'Please enter Dressing description.' }
                ]
              })(
                <TextArea
                  rows={4}
                  disabled={isDressing}
                  style={this.shouldHighlight(`adl_dressing_type_desc`)}
                  placeholder={'Assistance details such as consider aids, falls, transfers – shower/bath, hoisting required, 1 or 2-person assistance, wheelchair type, so on.'}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Behaviour Support Plan (BSP)' style={{marginBottom: '5px'}}>
              {getFieldDecorator('adl_is_bsp', {
                initialValue: carePlan.adl_is_bsp,
                rules: [
                  { required: true, message: 'Please select BSP' }
                ]
              })(
                <Radio.Group name='adl_is_bsp' style={this.shouldHighlight('adl_is_bsp')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Catheter' style={{marginBottom: '5px'}}>
              {getFieldDecorator('adl_is_catheter', {
                initialValue: carePlan.adl_is_catheter,
                rules: [
                  { required: true, message: 'Please select Catheter' }
                ]
              })(
                <Radio.Group name='adl_is_catheter' style={this.shouldHighlight('adl_is_catheter')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Bowel Care' style={{marginBottom: '5px'}}>
              {getFieldDecorator('adl_is_bowel', {
                initialValue: carePlan.adl_is_bowel,
                rules: [
                  { required: true, message: 'Please select Bowel' }
                ]
              })(
                <Radio.Group name='adl_is_bowel' style={this.shouldHighlight('adl_is_bowel')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Continence with urine' style={{marginBottom: '5px'}}>
              {getFieldDecorator('adl_conti_with_urine', {
                initialValue: carePlan.adl_conti_with_urine,
                rules: [
                  { required: true, message: 'Please select Continence with urine' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateContinence(e, 'adl_conti_with_urine')}
                  name='adl_conti_with_urine'
                  style={this.shouldHighlight('adl_conti_with_urine')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Continence with faeces' style={{marginBottom: '5px'}}>
              {getFieldDecorator('adl_conti_with_faeces', {
                initialValue: carePlan.adl_conti_with_faeces,
                rules: [
                  { required: true, message: 'Please select Continence with faeces' }
                ]
              })(
                <Radio.Group
                  onChange={(e) => this.updateContinence(e, 'adl_conti_with_faeces')}
                  name='adl_conti_with_faeces'
                  style={this.shouldHighlight('adl_conti_with_faeces')}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                  <Radio value={2}>Undisclosed</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayout} label='Continence products' hasFeedback>
          {getFieldDecorator('adl_conti_products', {
            initialValue: carePlan.adl_conti_products,
            rules: [
              { required: !isContinence, message: 'Please enter Continence products.' }
            ]
          })(
            <TextArea
              rows={4}
              disabled={isContinence}
              style={this.shouldHighlight('adl_conti_products')}
            />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Toileting' hasFeedback>
          {getFieldDecorator('adl_toileting', {
            initialValue: carePlan.adl_toileting,
            rules: [
              { required: true, message: 'Please enter Toileting.' }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'Assistance details such as consider aids – bedpan, commode, incontinence pads, falls, transfers, hoisting required, 1 or 2-person assistance, wheelchair type, so on.'}
              style={this.shouldHighlight('adl_toileting')}
            />
          )}
        </FormItem>

        <FormItem {...formItemLayoutCustom(9)} label='Mealtime Assistance' style={{marginBottom: '5px'}}>
          {getFieldDecorator('adl_mealtime_assist_type', {
            initialValue: carePlan.adl_mealtime_assist_type || null,
            rules: [
              { required: true, message: 'Please select Mealtime Assistance' }
            ]
          })(
            <Radio.Group
              onChange={(e) => this.updateInputFlag(e, 'isMealtimeAssist', 'adl_mealtime_assist_type_desc')}
              name='adl_mealtime_assist_type'
              style={this.shouldHighlight('adl_mealtime_assist_type')}>
              <Radio className='vertical-radio' value={'independent'}>Independent</Radio>
              <Radio className='vertical-radio' value={'partially'}>Partial assistance (set up, cutting foods, opening containers)</Radio>
              <Radio className='vertical-radio' value={'full'}>Full assist (unable to eat independently, has difficulty swallowing)</Radio>
              <Radio className='vertical-radio' value={'unknown'}>Undisclosed　　　　　　　　　　　　　　　　　　</Radio>
            </Radio.Group>
          )}
        </FormItem>

        <Row>
          <Col sm={6} md={6} lg={6}/>
          <Col sm={16} md={16} lg={16}>
            <FormItem hasFeedback>
              {getFieldDecorator('adl_mealtime_assist_type_desc', {
                initialValue: carePlan.adl_mealtime_assist_type_desc,
                rules: [
                  { required: !isMealtimeAssist, message: 'Please enter Mealtime Assistance description.' }
                ]
              })(
                <TextArea
                  rows={4}
                  disabled={isMealtimeAssist}
                  style={this.shouldHighlight(`adl_mealtime_assist_type_desc`)}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem {...formItemLayout} label='Dietary Requirements' hasFeedback>
          {getFieldDecorator('adl_dietary_req', {
            initialValue: carePlan.adl_dietary_req,
            rules: [
              { required: true, message: 'Please enter Dietary Requirements.' }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'Include special diets, meal size, food and drinks preferences, cultural considerations, so on.'}
              style={this.shouldHighlight('adl_dietary_req')}
            />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label='Sleep Routine' hasFeedback>
          {getFieldDecorator('adl_sleep_routine', {
            initialValue: carePlan.adl_sleep_routine,
            rules: [
              { required: true, message: 'Please enter Sleep Routine.' }
            ]
          })(
            <TextArea
              rows={4}
              style={this.shouldHighlight('adl_sleep_routine')}
            />
          )}
        </FormItem>

        {/** ----- Risk Analysis ------- */}
        <SectionTitle title='Risk Analysis' />

        <div style={{margin: '0px 5%'}}>
          Add any known risk that requires management. Risk examples as below but not limited to:
          <ul style={{paddingBottom: '0px'}}>
            <li><strong>Physical – </strong>biting, punching, verbal abuse, spitting, yelling, inappropriate touching, falls risk etc.</li>
            <li><strong>Environmental – </strong>confined spaces, crowds, loud noises, bright lights etc.</li>
          </ul>
          Also rate the risk level (defined below) and provide a strategy for managing the risk.
          <ul>
            <li><strong>Low Risk – </strong>may happen 1 to 2 times a week (may not happen during our services)</li>
            <li><strong>Medium Risk – </strong>may happen 3 to 4 times a week (may happen during our services)</li>
            <li><strong>High Risk – </strong>may happen 5 or more times a week (most likely will happen during our services)</li>
          </ul>
        </div>

        <div className='row-custom-title'>
          <div> </div>
          { isCurrentVersion
            ? <div className='btn' style={{marginBottom: '0px'}} onClick={() => this.updateNewRisk()}>Add Risk</div>
            : null }
        </div>

        {/* <List cols={riskCols} rows={listRiskAnalysis} /> */}

        { isCurrentVersion
          ? listRiskAnalysis.map((e, idx) => {
          return (
            <div key={`risk${idx}`} style={{marginLeft: '3%'}}>
            <Row gutter={6}>
              <Col lg={1}>
                <div>{idx + 1}</div>
              </Col>
              <Col lg={6}>
                <FormItem {...formItemLayoutCustom(15, 7)} label={`Risk`} hasFeedback>
                  {getFieldDecorator(`risk_name${idx}`, {
                    initialValue: e.risk_name || null,
                    rules: [
                      { required: true, message: 'Please enter Risk Name.' }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={this.shouldHighlight(`risk_name${idx}`)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={6}>
                <FormItem {...formItemLayoutCustom(15, 7)} label={`Trigger(s)`} hasFeedback>
                  {getFieldDecorator(`risk_trigger${idx}`, {
                    initialValue: e.risk_trigger || null,
                    rules: [
                      { required: true, message: 'Please enter Trigger(s).' }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={this.shouldHighlight(`risk_trigger${idx}`)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={10}>
                <FormItem {...formItemLayoutCustom(13, 10)} label={`Management Strategy`} hasFeedback>
                  {getFieldDecorator(`risk_mgnt_strategy${idx}`, {
                    initialValue: e.risk_mgnt_strategy || null,
                    rules: [
                      { required: true, message: 'Please enter Management Strategy.' }
                    ]
                  })(
                    <TextArea
                      rows={2}
                      style={this.shouldHighlight(`risk_mgnt_strategy${idx}`)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={1}>
                <Tooltip mouseLeaveDelay={0} title='Delete Risk'>
                  <Popconfirm
                    title={`Are you sure you want to delete this risk?`}
                    onConfirm={() => this.updateNewRisk(idx)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Icon type='delete' style={{ marginLeft: '15px' }} />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col lg={5} />
              <Col lg={19}>
                <FormItem hasFeedback style={{marginBottom: '18px'}}>
                  {getFieldDecorator(`risk_type${idx}`, {
                    initialValue: carePlan[`risk_type${idx}`] || '',
                    rules: [
                      { required: true, message: 'Please select Type' }
                    ]
                  })(
                      <Radio.Group style={{margin: '0px', padding: '0px'}} name={`risk_type${idx}`} style={this.shouldHighlight(`risk_type${idx}`, true, true)}>
                        <Radio value={'low'}>Low Risk</Radio>
                        <Radio value={'medium'}>Medium Risk</Radio>
                        <Radio value={'high'}>High Risk</Radio>
                      </Radio.Group>
                  )}
                </FormItem>
              </Col>
            </Row>
            </div>
          )})
          : listPastRiskAnalysis.map((e, idx) => {
            return (
              <div key={`riskp${idx}`} style={{marginLeft: '3%'}}>
              <Row gutter={6}>
                <Col lg={1}>
                  <div>{idx + 1}</div>
                </Col>
                <Col lg={6}>
                  <FormItem {...formItemLayoutCustom(15, 7)} label={`Risk`} hasFeedback>
                    {getFieldDecorator(`risk_name${idx}`, {
                      initialValue: e.risk_name || null,
                      rules: [
                        { required: true, message: 'Please enter Risk Name.' }
                      ]
                    })(
                      <TextArea
                        rows={2}
                        style={this.shouldHighlight(`risk_name${idx}`)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={6}>
                  <FormItem {...formItemLayoutCustom(15, 7)} label={`Trigger(s)`} hasFeedback>
                    {getFieldDecorator(`risk_trigger${idx}`, {
                      initialValue: e.risk_trigger || null,
                      rules: [
                        { required: true, message: 'Please enter Trigger(s).' }
                      ]
                    })(
                      <TextArea
                        rows={2}
                        style={this.shouldHighlight(`risk_trigger${idx}`)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={10}>
                  <FormItem {...formItemLayoutCustom(13, 10)} label={`Management Strategy`} hasFeedback>
                    {getFieldDecorator(`risk_mgnt_strategy${idx}`, {
                      initialValue: e.risk_mgnt_strategy || null,
                      rules: [
                        { required: true, message: 'Please enter Management Strategy.' }
                      ]
                    })(
                      <TextArea
                        rows={2}
                        style={this.shouldHighlight(`risk_mgnt_strategy${idx}`)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={1}>

                </Col>
              </Row>
              <Row gutter={6}>
                <Col lg={5} />
                <Col lg={19}>
                  <FormItem hasFeedback style={{marginBottom: '18px'}}>
                    {getFieldDecorator(`risk_type${idx}`, {
                      initialValue: carePlan[`risk_type${idx}`] || '',
                      rules: [
                        { required: true, message: 'Please select Type' }
                      ]
                    })(
                        <Radio.Group name={`risk_type${idx}`} style={this.shouldHighlight(`risk_type${idx}`, true, true)}>
                          <Radio value={'low'}>Low Risk</Radio>
                          <Radio value={'medium'}>Medium Risk</Radio>
                          <Radio value={'high'}>High Risk</Radio>
                        </Radio.Group>
                    )}
                  </FormItem>
                </Col>
              </Row>
              </div>
            )})
        }
      </Col>
      <Col lg={4}>
        { carePlanVersions.list.length > 0
          ? <div className='version-container'>

            <div className='version-title'>Previous Versions</div>
            {
              carePlanVersions.list.map((careplan, index) => {
                return <div key={`cpvr${index}`} className={`version ${version === careplan.version ? 'version-selected' : ''}`} onClick={() => { this.viewVersion(careplan.version) }}>
                  Version { careplan.version }
                  <div className='date'>{ formatter.toStandardDate(careplan.created_at) }</div>
                  <div className='name'>{ careplan.created_by_name }</div>
                </div>
              })
            }
          </div> : null
        }
      </Col>

      <AddFileModal
        clientId={id}
        key={`addfilemodal${modalFileItem && modalFileItem.id ? modalFileItem.id : '_new'}`}
        item={modalFileItem}
        categoriesList={mainCats}
        subCategoriesList={subCats}
        onClose={() => this.showAddFileModal(false)}
        onSetFile={(values) => this.updateFile(values)}
        visible={isShowFileModal}
      />
    </Row>
  }

  fetchCarePlan = async () => {
    const { clientId } = this.props
    this.setState({ loading: true })

    try {
      const cp = await carePlanV2Service.get(clientId)

      if (cp && cp.id) {
        const listHealthAllergy = validator.isNotEmptyArray(cp.list_health_allergy) ? cp.list_health_allergy : []
        const listRiskAnalysis = validator.isNotEmptyArray(cp.list_risk_analysis) ? cp.list_risk_analysis : []
        const listFile = validator.isNotEmptyArray(cp.list_files) ? cp.list_files : []

        this.setState({
          carePlan: cp,
          version: cp.version || 0,
          listHealthAllergy,
          listRiskAnalysis,
          listFile
        }, () => {
          setTimeout(() => {
            this.validateInputs()
            this.setState({ loading: false })
          }, 500)
        })
      } else {
        this.setState({ loading: false })
      }
    } catch (e) {
      this.setState({ loading: false })
    }

  }

  fetchCarePlanVersion = async () => {
    const { clientId } = this.props

    const filter = {}
    filter.client_id = { condition: '=', value: clientId }

    const carePlan = await carePlanV2Service.listByPage(1, 0, filter)

    if (carePlan && carePlan.list && validator.isNotEmptyArray(carePlan.list)) {
      this.setState({ carePlanVersions: carePlan })

      setTimeout(() => {
        if (carePlan.list.length > 0) {
          this.viewVersion(carePlan.list[0].version)
        }
      }, 800)
    }
  }

  fetchFileCats = async () => {
    try {
      let filter1 = {}
      filter1.active = { condition: '=', value: true }
      filter1.classification = { condition: '=', value: 'client' }
      filter1.name = { condition: 'ilike', value: '%care plan%'}

      const mainCategories = await settingFileCategoryService.listByPage(1, 0, filter1)

      if (mainCategories && validator.isNotEmptyArray(mainCategories.list)) {
        const cat = mainCategories.list[0]
        let filter2 = {}

        if (cat && cat.id) {
          filter2.active = { condition: '=', value: true }
          filter2.file_category_id = { condition: '=', value: cat.id }

          const subCategories = await settingFileTypeService.listByPage(1, 0, filter2)

          if (subCategories && validator.isNotEmptyArray(subCategories.list)) {
            this.setState({mainCats: mainCategories.list, subCats: subCategories.list})
          }
        }
      }
    } catch (e) {

    }
  }

  handleDownload = async () => {
    const { clientId } = this.props

    const carePlan = await carePlanV2Service.download(clientId)
    // window.location.href = `${apiHostname}/api/export/careplan/${clientId}`

    if (carePlan.url) {
      window.open(
        apiHostname + carePlan.url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  handleDownloadFile = (url) => (e) => {
    window.location.href = url
  }

  validateFiles = (values) => {
    const { listFile, listFileErrMsg, subCats } = this.state
    let status = true
    const cats = subCats.map(e => e.code)
    for (let i = 0; i < cats.length; i++) {
      const cat = cats[i]
      const file = listFile.find(e => e.type === cat)
      const errIdx = listFileErrMsg.findIndex(e => e === cat)
      if (values[cat] === true && !file) {
        status = false
        if (errIdx < 0) {
          listFileErrMsg.push(cat)
        }
      } else {
        if (errIdx > -1) {
          listFileErrMsg.splice(errIdx, 1)
        }
      }
    }
    this.setState({listFileErrMsg})
    return status
  }

  restructureValues = (values) => {
    const { listHealthAllergy, listRiskAnalysis, listFile } = this.state
    let listH = []
    let listR = []
    for (let i = 0; i < listHealthAllergy.length; i++) {
      let item = {}
      item['hinfo_dm_allergy_name'] = values[`hinfo_dm_allergy_name${i}`]
      item['hinfo_dm_allergy_effects'] = values[`hinfo_dm_allergy_effects${i}`]

      delete values[`hinfo_dm_allergy_name${i}`]
      delete values[`hinfo_dm_allergy_effects${i}`]

      listH.push(item)
    }

    for (let i = 0; i < listRiskAnalysis.length; i++) {
      let item = {}

      item['risk_type'] = values[`risk_type${i}`]
      item['risk_name'] = values[`risk_name${i}`]
      item['risk_trigger'] = values[`risk_trigger${i}`]
      item['risk_mgnt_strategy'] = values[`risk_mgnt_strategy${i}`]

      delete values[`risk_type${i}`]
      delete values[`risk_name${i}`]
      delete values[`risk_trigger${i}`]
      delete values[`risk_mgnt_strategy${i}`]

      listR.push(item)
    }

    values.list_health_allergy = listH
    values.list_risk_analysis = listR
    values.list_files = listFile

    return values
  }

  handleSave = () => {
    const { clientId, form } = this.props
    const { validateFieldsAndScroll } = form
    const { carePlan, listFile } = this.state

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const isAllFiles = this.validateFiles(values)

        if (!isAllFiles) {
          Notification['error']({
            message: 'Incomplete Care Plan Information',
            description: 'Please upload files required.',
            top: 130
          })

          return
        }

        values = this.restructureValues(values)

        this.setState({ loading: true })

        let newVersion = 1
        if (!carePlan || !carePlan.id) {
          values.version = newVersion
        } else {
          newVersion = carePlan.version + 1
          values.version = newVersion
        }

        values.client_id = clientId

        const currentUser = authService.getCurrentUser()
        const { id: userId, name } = currentUser

        values.created_by = userId
        values.created_by_name = name

        try {
          const r = await carePlanV2Service.add(values)
          this.setState({ loading: false, shouldRefreshFiles: true })

          if (r && r.id) {
            notify.success('Saved successfully', 'Care Plan saved successfully')

            log.updateClientText(clientId, 'care plan', `New version of care plan (version ${newVersion}) is created.`)

            this.fetchCarePlanVersion()
            this.fetchCarePlan()
          } else {
            if (r && r.errors) {
              notify.error('Unable to save successfully', formatter.toErrorMessage(r.errors))
            } else {
              notify.error('Unable to save successfully', 'Unable to save Care Plan successfully. Please try again later.')
            }
          }
        } catch (e) {
          console.log('e', e.message)
          this.setState({ loading: false })
          notify.error('Unable to save successfully', 'Soemthing wrong when try to save Care Plan successfully. Please try again later.')
        }

      } else {
        Notification['error']({
          message: 'Incomplete Care Plan Information',
          description: 'Please fill up all the required fields to proceed.',
          top: 130
        })
      }
    })
  }

  updateNewAllergy = (index = null) => {
    const { listHealthAllergy } = this.state
    if (index === null) {
      listHealthAllergy.push(Object.assign({}, DefaultAllergyItem))
    } else if (index > -1 && listHealthAllergy.length > index) {
      listHealthAllergy.splice(index, 1)
    }

    this.setState({listHealthAllergy})
  }

  updateNewRisk = (index = null) => {
    const { listRiskAnalysis } = this.state
    if (index === null) {
      listRiskAnalysis.push(Object.assign({}, DefaultRiskItem))
    } else if (index > -1 && listRiskAnalysis.length > index) {
      listRiskAnalysis.splice(index, 1)
    }

    this.setState({listRiskAnalysis})
  }

  showAddFileModal = (isShowFileModal, type) => {
    const { mainCats, subCats, listFile } = this.state

    const currentSubCats = subCats.filter(e => e.code === type)
    const file = listFile.find(e => e.type === type) || {}

    if (isShowFileModal) {
      const mainCatId = validator.isNotEmptyArray(mainCats) ? mainCats[0].id : null
      const subCatId = validator.isNotEmptyArray(currentSubCats) ? currentSubCats[0].id : null
      this.setState({
        isShowFileModal,
        modalFileItem: { ...file, type, main_category_id: mainCatId, sub_category_id: subCatId },
        currentSubCats
      })
    } else {
      this.setState({
        isShowFileModal,
        modalFileItem: {},
        currentSubCats: []
      })
    }
  }

  updateFile = (values) => {
    const { form } = this.props

    this.showAddFileModal(false)
    const { listFile, listFileErrMsg } = this.state
    const idx = listFile.findIndex(e => e.type === values.type)
    const currentFlag = form.getFieldValue(values.type)

    if (idx > -1) {
      listFile.splice(idx, 1, values)

      if (currentFlag === true) {
        listFileErrMsg.push(values.type)
      }
    } else {
      listFile.push(values)

      const errIdx = listFileErrMsg.findIndex(e => e === values.type)
      if (errIdx > -1) {
        listFileErrMsg.splice(errIdx, 1)
      }
    }
    this.setState({ listFile, listFileErrMsg })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { clientId } = this.props
    return clientId !== 'add'
  }

  getId = () => {
    const { clientId } = this.props
    return clientId
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientCarePlanV2))

import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_PAYROLLS_FVP,
  FETCHING_PAYROLLS_FVP,
  PAYROLLS_FETCHED_FVP,
  FETCH_PAYROLL_TIMESHEETS_FVP,
  FETCHING_PAYROLL_TIMESHEETS_FVP,
  PAYROLL_TIMESHEETS_FETCHED_FVP,
  FETCH_PAYROLL_TIMESHEET_SUM_FVP,
  FETCHING_PAYROLL_TIMESHEET_SUM_FVP,
  PAYROLL_TIMESHEET_SUM_FETCHED_FVP
} from '../actions/payrollFvp'
import payrollService from '../../services/fvp/payroll'

function * fetchPayrollsFvp ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_PAYROLLS_FVP, loading })
    const payrolls = yield payrollService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: PAYROLLS_FETCHED_FVP, payrolls, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLLS_FVP, loading: false })
    console.error(e)
  }
}

function * fetchTimesheetsFvp ({ loading, currentPage, pageSize, filter, sort, searchText, view }) {
  try {
    yield put({ type: FETCHING_PAYROLL_TIMESHEETS_FVP, loading })
    const timesheets = yield payrollService.listTimesheetByPage(currentPage, pageSize, filter, sort, searchText, view)
    yield put({ type: PAYROLL_TIMESHEETS_FETCHED_FVP, timesheets, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLL_TIMESHEETS_FVP, loading: false })
    console.error(e)
  }
}

function * fetchTimesheetSummaryFvp ({ id, view }) {
  try {
    yield put({ type: FETCHING_PAYROLL_TIMESHEET_SUM_FVP, loading: true })
    const timesheetSummary = yield payrollService.getTimesheetSummary(id, view)
    yield put({ type: PAYROLL_TIMESHEET_SUM_FETCHED_FVP, timesheetSummary, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLL_TIMESHEET_SUM_FVP, loading: false })
    console.error(e)
  }
}

function * watchPayrollFvp () {
  yield takeEvery(FETCH_PAYROLLS_FVP, fetchPayrollsFvp)
  yield takeEvery(FETCH_PAYROLL_TIMESHEETS_FVP, fetchTimesheetsFvp)
  yield takeEvery(FETCH_PAYROLL_TIMESHEET_SUM_FVP, fetchTimesheetSummaryFvp)
}

export default watchPayrollFvp

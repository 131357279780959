export const FETCH_ENDED_BASEJOBS = 'FETCH_ENDED_BASEJOBS'
export const FETCHING_ENDED_BASEJOBS = 'FETCHING_ENDED_BASEJOBS'
export const ENDED_BASEJOBS_FETCHED = 'ENDED_BASEJOBS_FETCHED'

export const fetchEndedBaseJobs = (params) => {
  return Object.assign({
    type: FETCH_ENDED_BASEJOBS
  }, params)
}

export const fetchingEndedBaseJobs = (loading) => {
  return {
    type: FETCHING_ENDED_BASEJOBS,
    loading
  }
}

export const endedBaseJobsFetched = (jobs) => {
  return {
    type: ENDED_BASEJOBS_FETCHED,
    jobs
  }
}

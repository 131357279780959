import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/employees'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getCustom (values) {
    return request(`${url}/info`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getEmployeeLoginStats (id) {
    return request(`${url}/${id}/login-stats`)
  },
  updateEmployeeLoginStats (id, values) {
    return request(`${url}/${id}/login-stats`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getEmployeePrevClient (id) {
    return request(`${url}/${id}/client/prev`)
  },
  getAllEmployeeActive () {
    return request(`${url}/all/active`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageAddress (page, size, filter, sorting, text, subFilter) {
    return request(`${url}/map/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text, subFilter: JSON.stringify(subFilter) })}`)
  },
  getSuburbs () {
    return request(`${url}/suburbs`)
  },
  getEmployeeIds () {
    return request(`${url}/ids`)
  },
  checkDuplicateEmployee (values) {
    return request(`${url}/check/duplicate${queryString.stringify(values)}`)
  },
  exportEmployee (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}

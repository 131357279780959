import { FETCHING_PAYROLLS_FVP, FETCHING_PAYROLL_TIMESHEETS_FVP, PAYROLLS_FETCHED_FVP, PAYROLL_TIMESHEETS_FETCHED_FVP, PAYROLL_TIMESHEET_SUM_FETCHED_FVP } from '../actions/payrollFvp'

const INITIAL_STATE = {
  loading: false,
  payrolls: { list: [], total: 0 },
  timesheets: { list: [], total: 0 },
  timesheetSummary: { jobs: 0, kms: 0, sleepovers: 0, hours: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_PAYROLLS_FVP:
    case FETCHING_PAYROLL_TIMESHEETS_FVP:
      return handleFetchingPayrolls(state, action)
    case PAYROLLS_FETCHED_FVP:
      return handlePayrollsFetched(state, action)
    case PAYROLL_TIMESHEETS_FETCHED_FVP:
      return handleTimesheetsFetched(state, action)
    case PAYROLL_TIMESHEET_SUM_FETCHED_FVP:
      return handleTimesheetSummaryFetched(state, action)
    default:
      return state
  }
}

function handleFetchingPayrolls (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handlePayrollsFetched (state, action) {
  const { payrolls, loading } = action
  return { ...state, payrolls, loading }
}

function handleTimesheetsFetched (state, action) {
  const { timesheets, loading } = action
  return { ...state, timesheets, loading }
}

function handleTimesheetSummaryFetched (state, action) {
  const { timesheetSummary, loading } = action
  return { ...state, timesheetSummary, loading }
}

export default [
  {
    name: 'System Modules',
    desc: 'Manage module descriptions',
    icon: 'profile',
    link: '/settings/modules',
    enable: true,
    permission: 'listSystemModules'
  },
  {
    name: 'System Users',
    desc: 'Manage system users',
    icon: 'team',
    link: '/settings/admins',
    enable: true,
    permission: 'listSystemUsers'
  },
  {
    name: 'User Roles',
    desc: 'Manage user roles and permissions',
    icon: 'solution',
    link: '/settings/admin-roles',
    enable: true,
    permission: 'listUserRoles'
  }
]

import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import BillingRateRow from './row'

import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import DatePicker from 'antd/lib/date-picker'
import TimePicker from 'antd/lib/time-picker'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import message from 'antd/lib/message'

import { validator } from '../../../../../util'
import { Button } from '../../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const Option = Select.Option

function Subset (props) {
  const { categories, data: set = {}, form, index, subsets = [] } = props
  const { getFieldDecorator, getFieldsValue } = form
  const [visible, setVisible] = useState(index === 0)
  const [showError, setShowError] = useState(false)

  const selectCategories = categories.filter(e => validator.isNotEmptyArray(set.categories) ? set.categories.findIndex(f => f.id === e.id) === -1 : true)

  useEffect(() => {
    if (props.validate) {
      validateForm()
    }
  }, [props.validate])

  const formItemLayout = {
    labelCol: { sm: 6, md: 6, lg: 7 },
    wrapperCol: { sm: 14, md: 14, lg: 16 }
  }

  const formItemTimeLayout = {
    labelCol: { sm: 6, md: 6, lg: 12 },
    wrapperCol: { sm: 14, md: 14, lg: 10 }
  }

  function handleDuplicate (set) {
    props.onDuplicate(set)
  }

  function setBillingRate (category, day, value, isLabel = false) {
    let isExist = false
    const billingCategory = set.categories.filter(c => c.id === category)

    for (let i = 0; i < billingCategory[0].rates.length; i++) {
      const rate = billingCategory[0].rates[i]
      if (rate.day === day) {
        if (isLabel) {
          rate.label = value
        } else {
          rate.value = parseFloat(value)
        }
        isExist = true
        break
      }
    }

    if (!isExist) {
      set.categories.push({ day, value: !isLabel ? value : null, label: isLabel ? value : '' })
    }

    props.onUpdate(set)
  }

  function removeBillingRate (category) {
    const billingCategory = set.categories.filter(c => c.id !== category)
    set.categories = billingCategory

    props.onUpdate(set)
  }

  function addCategory (option) {
    const rates = []
    for (let j = 0; j < 8; j++) {
      // populate normal hours
      rates.push({category_id: option.key, day: `n${j + 1}`, value: 0, label: null})
      // populate after hours
      rates.push({category_id: option.key, day: `a${j + 1}`, value: 0, label: null})
    }

    if (!validator.isArray(set.categories)) {
      set.categories = []
    }

    set.categories.push({ id: option.key, name: option.label, rates })
    props.onUpdate(set)
  }

  function updateSetInfo (value) {
    setTimeout(() => {
      const fields = getFieldsValue()
      set.name = fields['name'] || null
      set.active = fields['active'] || null
      set.start_date = fields['start_date'] ? moment(fields['start_date']).startOf('day') : null
      set.end_date = fields['end_date'] ? moment(fields['end_date']).endOf('day') : null
      set.normal_hours_start = fields['normal_hours_start'] || null
      set.normal_hours_end = fields['normal_hours_end'] || null
      set.after_hours_start = fields['after_hours_start'] || null
      set.after_hours_end = fields['after_hours_end'] || null
      set.km_rate = fields['km_rate'] || null
      set.km_label = fields['km_label'] || null
      set.emg_rate = fields['emg_rate'] || null
      set.so_hours_start = fields['so_hours_start'] || null
      set.so_hours_end = fields['so_hours_end'] || null
      set.so_free_getup = fields['so_free_getup'] || null

      props.onUpdate(set)
    }, 500)

    return value
  }

  // function handleDuplicate() {
  //   const newSet = {...set}

  //   const startDate = moment(set.start_date).startOf('day')
  //   const endDate = moment(set.end_date).endOf('day')
  //   const duration = moment.duration(endDate.diff(startDate))
  //   const durationDays = duration.asDays()

  //   newSet.id = null
  //   newSet.name = newSet.name + ' - Duplicate'
  //   newSet.start_date = moment(set.end_date).add(1, 'day').startOf('day')
  //   newSet.end_date = moment(set.end_date).add(durationDays + 1, 'day').endOf('day')
  //   props.onDuplicate(newSet)
  // }

  function handleDelete (id) {
    props.onDelete(id)
  }

  function toggle () {
    setShowError(false)
    setVisible(!visible)
  }

  function validateForm () {
    const { form } = props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (errors) {
        setShowError(true)
        props.onError(false)
      }
    })
  }

  function validateAmount (rule, value, callback) {
    const name = rule.field === 'emg_rate' ? 'Emergency Rate' : rule.field === 'km_rate' ? 'KM Billing Rate' : rule.field === 'so_free_getup' ? 'S/O Free Get Up' : rule.field
    if (value === null || value === undefined || value === '') {
      callback(new Error(`Please enter ${name}.`))
    } else if (rule.field !== 'so_free_getup' && parseFloat(value) === 0) {
      callback(new Error(`Please enter ${name}.`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${name} must be valid.`))
    } else if (rule.field === 'emg_rate' && parseFloat(value) < 1) {
      callback(new Error(`${name} cannot be lesser than 1.`))
    } else {
      callback()
    }
  }

  function handleDateChange (rule, value, callback) {
    const overlapping = isDateOverlapping(value)
    const startend = isDatePeriod(value, rule.field)
    if (overlapping.result) {
      try {
        throw new Error(`Date is overlapping with ${overlapping.period}`)
      } catch (err) {
        callback(err)
      }
    } else if (startend.isAfter) {
      try {
        throw new Error(`Period End Date must be after Start Date.`)
      } catch (err) {
        callback(err)
      }
    } else {
      callback()
    }
  }

  function isDateOverlapping (value) {
    for (let i = 0; i < subsets.length; i++) {
      const subset = subsets[i]

      if (subset.id !== set.id) {
        const isBetween = moment(value).isBetween(subset.start_date, subset.end_date)

        if (isBetween) {
          return { result: true, period: `${moment(subset.start_date).format('DD/MM/YYYY')} - ${moment(subset.end_date).format('DD/MM/YYYY')}` }
        }
      }
    }

    return { result: false }
  }

  function isDatePeriod (value, field) {
    let isAfter = false

    if (field) {
      const fields = getFieldsValue()
      if (field === 'start_date') {
        const endDate = fields['end_date']

        if (endDate) {
          isAfter = moment(value).isAfter(endDate)
        }
      } else if (field === 'end_date') {
        const startDate = fields['start_date']

        if (startDate) {
          isAfter = moment(startDate).isAfter(value)
        }
      }
    }

    return { isAfter }
  }

  return (
    <div key={`rsk${index}`} className='billing-rate-info'>
      <Form>
        <Row>
          <Col onClick={() => toggle()} lg={20} style={{ cursor: 'pointer', fontWeight: 'bold', paddingLeft: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            { set ? set.name : '' }
            <Tooltip title={visible ? 'Collapse' : 'Expand'}>
              <div style={{ fontSize: '12pt', marginLeft: '10px' }}><Icon type={visible ? 'up-circle' : 'down-circle'} /></div>
            </Tooltip>
            { showError ? <div style={{ fontSize: '10pt', color: 'red', marginLeft: '10px' }}>Required fields remaining.</div> : null }
          </Col>
          <Col lg={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            { set.id
              ? <Button onClick={() => handleDuplicate(set)}>
                Duplicate
              </Button>
              : null
            }
            {<Popconfirm
              title='Confirm to remove this?'
              onConfirm={() => handleDelete(set.id)}
              okText='Yes'
              cancelText='No'
              placement='left'
            >
              <div className='billing-set-remove-row'><Icon type='delete' theme='filled' /></div>
            </Popconfirm>
            }
          </Col>
        </Row>

        { visible
          ? <div className='billing-rate-info-separate' />
          : null }

        <div style={{ display: visible ? 'inline' : 'none' }}>
          <Row style={{ marginTop: '20px' }}>
            <Col lg={10}>
              <FormItem label='Set Name' {...formItemLayout}>
                {getFieldDecorator('name', {
                  initialValue: set.name || '',
                  getValueFromEvent: (e) => updateSetInfo(e.target.value),
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={2}>
              <FormItem label='' {...formItemLayout}>
                {getFieldDecorator('active', {
                  initialValue: set.active === undefined ? false : set.active,
                  valuePropName: 'checked',
                  getValueFromEvent: (e) => updateSetInfo(e),
                })(
                  <Switch
                    checkedChildren='Enable' unCheckedChildren='Disable'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6}>
              <FormItem label='Start Date' {...formItemTimeLayout}>
                {getFieldDecorator('start_date', {
                  initialValue: set.start_date ? moment(set.start_date) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select start date' },
                    { validator: handleDateChange }
                  ]
                })(
                  <DatePicker format={'DD/MM/YYYY'} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='End Date' {...formItemTimeLayout}>
                {getFieldDecorator('end_date', {
                  initialValue: set.end_date ? moment(set.end_date) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select end date' },
                    { validator: handleDateChange }
                  ]
                })(
                  <DatePicker format={'DD/MM/YYYY'} defaultPickerValue={moment(new Date())} />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='Emergency Rate' {...formItemTimeLayout}>
                {getFieldDecorator('emg_rate', {
                  initialValue: set.emg_rate,
                  getValueFromEvent: (e) => updateSetInfo(e.target.value),
                  rules: [
                    { required: true, message: ' ' },
                    { validator: validateAmount }
                  ]
                })(
                  <Input addonBefore='✕' placeholder='0.00' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='KM Billing Rate' {...formItemTimeLayout}>
                {getFieldDecorator('km_rate', {
                  initialValue: set.km_rate,
                  getValueFromEvent: (e) => updateSetInfo(e.target.value),
                  rules: [
                    { required: true, message: ' ' },
                    { validator: validateAmount }
                  ]
                })(
                  <Input addonBefore='$' placeholder='0.00' />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6}>
              <FormItem label='Normal Hours Start' {...formItemTimeLayout}>
                {getFieldDecorator('normal_hours_start', {
                  initialValue: set.normal_hours_start ? moment(set.normal_hours_start).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select normal hours start.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='Normal Hours End' {...formItemTimeLayout}>
                {getFieldDecorator('normal_hours_end', {
                  initialValue: set.normal_hours_end ? moment(set.normal_hours_end).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select normal hours end.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='After Hours Start' {...formItemTimeLayout}>
                {getFieldDecorator('after_hours_start', {
                  initialValue: set.after_hours_start ? moment(set.after_hours_start).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select after hours start.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='After Hours End' {...formItemTimeLayout}>
                {getFieldDecorator('after_hours_end', {
                  initialValue: set.after_hours_end ? moment(set.after_hours_end).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select after hours end.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6}>
              <FormItem label='Sleepover Hours Start' {...formItemTimeLayout}>
                {getFieldDecorator('so_hours_start', {
                  initialValue: set.so_hours_start ? moment(set.so_hours_start).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select sleepover hours start.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='Sleepover Hours End' {...formItemTimeLayout}>
                {getFieldDecorator('so_hours_end', {
                  initialValue: set.so_hours_end ? moment(set.so_hours_end).seconds(0).milliseconds(0) : null,
                  getValueFromEvent: (e) => updateSetInfo(e),
                  rules: [
                    { required: true, message: 'Please select sleepover hours end.' },
                  ]
                })(
                  <TimePicker use12Hours format='h:mm A' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='Sleepover Free Get Up' {...formItemTimeLayout}>
                {getFieldDecorator('so_free_getup', {
                  initialValue: set.so_free_getup,
                  getValueFromEvent: (e) => updateSetInfo(e.target.value),
                  rules: [
                    { required: true, message: ' ' },
                    { validator: validateAmount }
                  ]
                })(
                  <Input addonAfter='hrs' placeholder='0.0' />
                )}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem label='KM Label' {...formItemTimeLayout}>
                {getFieldDecorator('km_label', {
                  initialValue: set.km_label,
                  getValueFromEvent: (e) => updateSetInfo(e.target.value),
                  rules: [
                    { required: true, message: 'Please enter km label.' },
                    { whitespace: true, message: 'Please enter km label.' }
                  ]
                })(
                  <Input placeholder='km' />
                )}
              </FormItem>
            </Col>
          </Row>

          <div className='billing-rate-info-separate' />

          <div className='billing-rate-info-category'>
            <Row style={{ fontSize: 13, color: '#777' }} gutter={10}>
              <Col lg={3}>
                Category
                <Popover
                  content={<Select
                    labelInValue style={{ width: 250 }}
                    onChange={(e) => addCategory(e)}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    { selectCategories.map((cat, idx) => {
                      return <Option key={idx} value={cat.id}>{ cat.name }</Option>
                    }) }
                  </Select>}
                  title='Add Category'
                  placement='right'
                >
                  <span className='billing-rate-add-category'> <Icon type='plus-circle' theme='filled' /></span>
                </Popover>
              </Col>
            </Row>

            <div className='billing-rate-set-row-list'>
              { validator.isNotEmptyArray(set.categories)
                ? set.categories.map((cat, index) => (
                  <BillingRateRow
                    key={index}
                    setId={set.id}
                    item={cat}
                    canDelete={false}
                    onChange={(category, day, value) => setBillingRate(category, day, value)}
                    onChangeLabel={(category, day, value) => setBillingRate(category, day, value, true)}
                    onDelete={(category) => removeBillingRate(category)} />
                ))
                : null
              }
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(Subset)

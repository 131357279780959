export const FETCH_STORES = 'FETCH_STORES'
export const FETCHING_STORES = 'FETCHING_STORES'
export const STORES_FETCHED = 'STORES_FETCHED'

export const fetchStores = (params) => {
  return Object.assign({
    type: FETCH_STORES
  }, params)
}

export const fetchingStores = (loading) => {
  return {
    type: FETCHING_STORES,
    loading
  }
}

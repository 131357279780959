import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchRecurringJobs } from '../../../states/actions/recurringJob'
import { fetchTotalPending } from '../../../states/actions'
import { JobMenu } from '../../../constants'
import { clientService, employeeService, funderService, settingBillingRateService, authService } from '../../../services'
import formatter from '../../../util/formatter'
import moment from 'moment'
import AddJobModal from '../AddJobModal'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import Radio from 'antd/lib/radio'

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

export class RecurringBaseActive extends Component {
  constructor (props) {
    super(props)
    const { jobs: { list, total }, loading } = props
    this.state = {
      currentStep: 0,
      jobIds: [],
      billingCategory: [],
      currentPage: 1,
      clients: [],
      employees: [],
      funders: [],
      filter: {},
      list,
      loading,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      sort: {},
      suburbs: [],
      spinLoading: false,
      total,
      disableFunder: true,
      jobStatus: ''
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobs: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const currUrl = this.props.match.url
    const { currentPage, filter, loading, searchText, sort } = this.state
    if (currUrl === '/v1/jobs/recurring-ended') {
      this.fetchRecurringJobs({ currentPage, filter, loading, searchText, sort, jobStatus: 'ended' })
      this.setState({ jobStatus: 'ended' })
    } else {
      this.fetchRecurringJobs({ currentPage, filter, loading, searchText, sort, jobStatus: 'active' })
      this.setState({ jobStatus: 'active' })
    }

    this.fetchClients()
    this.fetchEmployees()
    this.props.fetchTotalPending()
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, loading, sort, jobStatus } = this.state
    this.setState({ searching: true })
    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchRecurringJobs({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort, jobStatus })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectSuburb (value) {
    const { currentPage, filter, loading, searchText, sort, jobStatus } = this.state
    filter.location = value

    if (value === '') {
      delete filter.location
    }

    this.fetchRecurringJobs({ currentPage, filter, loading, searchText, sort, jobStatus })
    this.setState({ filter })
  }

  onSelectDates (dates) {
    console.log(dates)
    const { currentPage, filter, loading, searchText, sort, jobStatus } = this.state

    if (dates.length === 0) {
      delete filter['$date']
    } else {
      filter['$date'] = {
        fromDate: {
          $gte: dates[0],
          $lte: dates[1]
        }
      }
    }

    this.fetchRecurringJobs({ currentPage, filter, loading, searchText, sort, jobStatus })
    this.setState({ filter })
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  handleNext = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep === 0 ? 1 : 0 })
  }

  handleCancel = () => {
    this.setState({ showJobModal: false })
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]} ${option.props.children[3].props.children}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findEmployees = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]} ${option.props.children[3].props.children}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onSelectClient = async (value, option) => {
    this.setState({ spinLoading: true })
    const funders = await clientService.getClientFunders(value)
    this.setState({ selectedClient: `${option.props.children[0]} ${option.props.children[2]}`, funders: funders, spinLoading: false, disableFunder: false })
  }

  onSelectEmployee = (value, option) => {
    // console.log(value, `${option.props.children[0]} ${option.props.children[2]}`)
    this.setState({ selectedEmployee: `${option.props.children[0]} ${option.props.children[2]}` })
  }

  onClickRecurring = () => {
    this.setState({ showEndDate: false })
  }

  onClickSingle = () => {
    this.setState({ showEndDate: true })
  }

  onChangeDate = (e) => {
    const dateInfo = e
    const dayIn = dateInfo.format('dddd')
    this.setState({ selectedDay: dayIn })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, showJobModal, searching, total, jobStatus } = this.state
    const { totalPending } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobMenu} backLink='/' countData={totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={jobStatus === 'active' ? 'Active Recurring Jobs (V1)' : 'Ended Recurring Jobs (V1)'}>
            {
              this.hasAccess('createJob') ? <div className='btn' onClick={() => this.addJob()}>
              Add Job
              </div>
                : null
            }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
            </Row>
          </Page.Filter>

          <div className='jobs'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, client_id, employee_id, job_start_date, job_end_date, first_name, last_name, emp_firstname,
                  emp_lastname, suburb, tasks, fund_fullname, funder_id, emergency, interval, job_start_time, job_end_time,
                  first_job_date, last_job_date, frequency, sleepover, leave_id, leave_start, leave_end, employee_leave_id,
                  employee_leave_start, employee_leave_end, client_leave_until_further_notice } = item

                return (
                  <div className='list-item' key={id} style={emergency ? { borderLeft: `2px solid #ff5b5b88` } : null}>
                    <Row>
                      <Col lg={22}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Client</div>
                              </Col>
                              <Col lg={14}>
                                {/* <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                                <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a></div>
                                { leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(leave_start)} - ${client_leave_until_further_notice ? 'UFN' : formatter.toShortDate(leave_end)}`}</div> : null }
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Employee</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a></div>
                                { employee_leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div> : null }
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Funder</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a></div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='calendar' /> { formatter.toShortDate(first_job_date)} - { last_job_date !== null ? formatter.toShortDate(last_job_date) : '∞'}
                              <span className='time-sum'>{formatter.capitalize(interval + 'ly')}</span>
                              { interval === 'weekly' || 'fortnight' ? <span className='time-sum'> {moment(first_job_date).format('dddd')}</span> : null }
                            </div>
                            {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {moment(created_at).fromNow()}</div> */}
                          </Col>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='clock-circle' /> { formatter.toShortTime(job_start_time)} - { formatter.toShortTime(job_end_time)}
                              { sleepover ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(moment.duration(moment(job_end_time).diff(moment(job_start_time))).asHours())} hours</span> }
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='detail'><Icon type='environment' /> {suburb}</div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                          <Col>
                            <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : tasks }</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2} className='action-icon'>
                        <Link to={`/v1/jobs/recurring/${id}`}>

                          { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                            <Tooltip mouseLeaveDelay={0} title='Manage job'>
                              <Icon type='form' />
                            </Tooltip>
                          </div> : null }

                        </Link>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <AddJobModal visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, jobStatus } = this.state
    this.fetchRecurringJobs({ currentPage, filter, searchText, sort, jobStatus })
  }

  fetchRecurringJobs = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, jobStatus }) => {
    try {
      if (Array.isArray(filter.$and) && filter.$and.length) {
        searchText = ''
      }
      const { fetchRecurringJobs } = this.props
      this.setState({ currentPage })
      fetchRecurringJobs({ loading, currentPage, pageSize, filter, sort, searchText, jobStatus })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
    }
  }

  fetchClients = async () => {
    try {
      const clients = await clientService.listByPageActive(1, 100)
      this.setState({ clients: clients.list })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }

  fetchEmployees = async () => {
    try {
      const employees = await employeeService.listByPage(1, 100)
      this.setState({ employees: employees.list })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  handleFunderChange = async (value) => {
    const { item } = await funderService.get(value)
    const funderRateSet = item.rate_set
    const billRateInfo = await settingBillingRateService.get(funderRateSet)
    const billRateCategory = billRateInfo.categories
    this.setState({ billingCategory: billRateCategory, billingRateId: item.rate_set, billRateInfo })
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history } = this.props
        const clientId = values.client_id
        const funderId = values.funder_id
        const jobType = values.job_type
        this.setState({ loading: true })

        if (jobType === 'single') {
          history.replace(`/v1/jobs/new/client/${clientId}/funder/${funderId}`)
        } else {
          history.replace(`/v1/jobs/new/client/${clientId}/funder/${funderId}/recurring`)
        }

        this.setState({ loading: false })
      }
    })
  }
}

const mapDispatchToProps = {
  fetchRecurringJobs,
  fetchTotalPending
}

const mapStateToProps = (state) => {
  return { ...state.RecurringJob, ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(RecurringBaseActive))

const ExportType = {
  CONTACT: {
    MICROSIP_CSV: 'export-contact-microsip-csv',
    MICROSIP_XML: 'export-contact-microsip-xml',
    ACROBITS_CSV: 'export-contact-acrobits-csv',
    ACROBITS_JSON: 'export-contact-acrobits-json',
    GENERAL_CSV: 'export-contact-general-csv',
    GENERAL_XML: 'export-contact-general-xml',
    GENERAL_V2_CSV: 'export-contact-general-v2-csv',
    GENERAL_V2_ZIP_CSV: 'export-contact-general-v2-zip-csv',
    GENERAL_V2_ZIP_EMAIL: 'export-contact-general-v2-zip-email',
    GENERAL_V2_XML: 'export-contact-general-v2-xml'
  },
  GENERAL: {
    EXPORT_CLIENT: 'client-export',
    EXPORT_CLIENT_BUDGET: 'client-budget-export',
    EXPORT_CLIENT_JOB_TO_ACTION: 'client-leave-jobs',
    EXPORT_EMPLOYEE: 'employee-export',
    EXPORT_EMPLOYEE_JOB_TO_ACTION: 'employee-leave-jobs',
    EXPORT_FEEDBACK: 'feedback-export',
    EXPORT_FUNDER: 'funder-export',
    EXPORT_JOB_TS: 'job-timesheet-export',
    JOB_CLIENT_LIST: 'client-jobs',
    JOB_EMPLOYEE_LIST: 'employee-jobs',
    JOB_FUNDER_LIST: 'funder-jobs',
    JOB_LIST: 'list-jobs'
  },
  PAYROLL: {
    INVOICE: 'invoice',
    NDIS_PAYMENT: 'ndis-payment',
    PAYROLL: 'payroll'
  },
  REPORT: {
    BACK_TO_BACK: 'back-to-back',
    CLASHED_SHIFTS: 'clashed-shifts',
    CLASHED_SHIFTS_V2: `clashed-shifts/v2`,
    CLIENT_BDAY: 'client-bday',
    CLIENT_BUDGET: 'client-budget',
    CLIENT_CAREPLAN_V2: 'client-care-plan-v2',
    CLIENT_CAREPLAN_ALL: 'client-care-plan-all',
    CLIENT_FUNDING: 'client-funding',
    CLIENT_FILE: 'client-files',
    CLIENT_LEAVE: 'client-leave',
    LAST_JOB_DAY_COUNT: 'last-job',
    EMPLOYEE_BDAY: 'employee-bday',
    EMPLOYEE_FILE: 'employee-files',
    EMPLOYEE_LEAVE: 'employee-leave',
    EMPLOYEE_MISSING_REQUIRED_FILE: 'employee-missing-required-files',
    FEEDBACK: 'feedback',
    FUNDER_AGMT_EXP: 'funder-agmt-exp',
    FUNDER_FILE: 'funder-files',
    JOB_CANCELLATION: 'job-cancellation',
    JOB_TIMESHEET: 'job-timesheet',
    MULTI_CLIENT: 'multi-client',
    PH_JOB_LIST: 'holiday-job-list',
    USER_ACTION: 'user-action'
  }
}

export default ExportType

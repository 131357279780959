import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import UserMenu from '../../../constants/menu/user'
import { adminService, adminRoleService } from '../../../services'
import { adminsFetched } from '../../../states/actions/admin'
import { localStorage, sessionStorage, validator } from '../../../util'
import { Link } from 'react-router-dom'

// UI
import { Button, Page, Pager, SideModal, List } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import message from 'antd/lib/message'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'

import './styles.css'

const filterKey = 'admin.filter'
const pageKey = 'admin.page'
const sortKey = 'admin.sort'
const pageSize = 20
const { Item: FormItem } = Form
const { TextArea } = Input
const { Group: RadioGroup } = Radio
const { Option } = Select

export class SettingAdmin extends Component {
  constructor (props) {
    super(props)
    const { admins: { list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      modal: {
        edit: false,
        item: {},
        show: false
      },
      roles: [],
      roleValidateStatus: undefined,
      searchText: '',
      sort: sessionStorage.getObject(sortKey),
      total
    }
    this.checkEmailExists = debounce(this.checkEmailExists, 500)
    // this.findAdmins = debounce(this.findAdmins, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { admins: { list, total } } = nextProps
    return { ...prevState, list, total }
  }

  componentDidMount () {
    const { currentPage, filter, sort } = this.state
    this.fetchAdmins({ currentPage, filter, sort })
  }

  render () {
    const { form } = this.props
    const { currentPage, list, loading, loadingForm, modal, roles, roleValidateStatus, total } = this.state
    const { getFieldDecorator, getFieldValue } = form
    const { edit: editModal, item: modalItem, show: modalShow } = modal

    const columns = [
      {
        title: 'Name',
        width: 6,
        key: 'name'
      },
      {
        title: 'E-mail',
        width: 9,
        key: 'email'
      },
      {
        title: 'Role',
        width: 5,
        key: 'role_name'
      },
      {
        title: 'Enable',
        width: 2,
        render: ({ active }) => active ? <Icon type='check-circle' theme='filled' /> : null
      },
      {
        title: '',
        width: 1,
        render: (item) => <div style={{ cursor: 'pointer' }} onClick={this.handleEdit(item)}><Icon type='form' /></div>
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => <Popconfirm
          title='Confirm to delete this?'
          onConfirm={this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        >
          <Icon type='delete' />
        </Popconfirm>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={UserMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='System Users'>
            <div className='btn' onClick={this.handleAdd}>
              Add
            </div>
          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} system users`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Skeleton>
          </div>
        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            Manage system users
          </Page.Panel>
        </Page.Right>

        <SideModal
          title='System User'
          showModal={modalShow}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem hasFeedback label='Name'>
              {getFieldDecorator('name', {
                initialValue: modalItem.name,
                rules: [
                  { min: 2, message: 'Name must be between 2 and 127 characters' },
                  { max: 127, message: 'Name must be between 2 and 127 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input tabIndex={1} />
              )}
            </FormItem>

            <FormItem hasFeedback label='E-mail'>
              {getFieldDecorator('email', {
                initialValue: modalItem.email,
                rules: [
                  { required: true, message: 'Please enter e-mail' },
                  { whitespace: true, message: 'Please enter e-mail' },
                  { validator: this.checkEmail }
                ]
              })(
                <Input tabIndex={2} />
              )}
            </FormItem>

            <FormItem label='Role' validateStatus={roleValidateStatus}>
              {getFieldDecorator('role_id', {
                initialValue: modalItem.role_id || (roles[0] ? roles[0].id : undefined),
                rules: [
                  { required: true, message: 'Please select role' }
                ]
              })(
                <Select
                  filterOption={false}
                  onSearch={this.findRoles}
                  placeholder='Select role'
                  showSearch
                >
                  {roles.map(({ id, name, description }) => (
                    <Option key={id} value={id}>
                      <div>{name}</div>

                      <div className='admin-role-select-description'>{description}</div>
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem hasFeedback label='Remark'>
              {getFieldDecorator('remark', {
                initialValue: modalItem.remark,
                rules: [
                  { min: 2, message: 'Remark must be between 2 and 255 characters' },
                  { max: 255, message: 'Remark must be between 2 and 255 characters' },
                  { whitespace: true, message: 'Remark cannot contain only spaces' }
                ]
              })(
                <TextArea
                  autosize={{ minRows: 5, maxRows: 5 }}
                  tabIndex={3}
                />
              )}
            </FormItem>

            <Row>
              <Col span={12}>
                <FormItem>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                      tabIndex={4}
                    />
                  )}
                </FormItem>
              </Col>

              {editModal ? (
                <Col span={12}>
                  <FormItem>
                    {getFieldDecorator('change_password', {
                      initialValue: false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Change Password'
                        unCheckedChildren='Keep Password'
                        tabIndex={5}
                      />
                    )}
                  </FormItem>
                </Col>
              ) : null}
            </Row>

            {getFieldValue('change_password') === true ? (
              <div>
                <FormItem hasFeedback label='Password'>
                  {getFieldDecorator('password', {
                    initialValue: modalItem.password,
                    rules: [
                      { validator: this.checkPassword }
                    ]
                  })(
                    <Input
                      type='password'
                      tabIndex={6}
                    />
                  )}
                </FormItem>

                <FormItem hasFeedback label='Confirm Password'>
                  {getFieldDecorator('confirm_password', {
                    initialValue: modalItem.confirm_password,
                    rules: [
                      { validator: this.checkConfirmPassword }
                    ]
                  })(
                    <Input
                      type='password'
                      tabIndex={7}
                    />
                  )}
                </FormItem>
              </div>
            ) : null}

            {editModal ? null : (
              <FormItem label='First Time Login'>
                {getFieldDecorator('first_time_login', {
                  initialValue: 'email'
                })(
                  <RadioGroup className='admin-first-time-radio-group'>
                    <div className='radio'>
                      <Radio value='email' />

                      <div className='value'>Send auto-genereated password via e-mail</div>
                    </div>

                    <div className='radio'>
                      <Radio value='custom' />

                      <div className='value'>
                        <div>Enter custom password</div>

                        {getFieldValue('first_time_login') === 'custom' ? (
                          <div className='input-password'>
                            <FormItem hasFeedback label='Password'>
                              {getFieldDecorator('password', {
                                initialValue: modalItem.password,
                                rules: [
                                  { required: true, message: 'Please enter password' },
                                  { validator: this.checkPassword }
                                ]
                              })(
                                <Input
                                  type='password'
                                  tabIndex={8}
                                />
                              )}
                            </FormItem>

                            <FormItem hasFeedback label='Confirm Password'>
                              {getFieldDecorator('confirm_password', {
                                initialValue: modalItem.confirm_password,
                                rules: [
                                  { required: true, message: 'Please enter confirm password' },
                                  { validator: this.checkConfirmPassword }
                                ]
                              })(
                                <Input
                                  type='password'
                                  tabIndex={9}
                                />
                              )}
                            </FormItem>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </RadioGroup>
                )}
              </FormItem>
            )}
          </Form>
        </SideModal>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchAdmins({ loading: true, currentPage, filter, sort, searchText })
  }

  checkEmail = (rule, value, callback) => {
    if (value && value.length > 0 && !validator.isEmail(value)) {
      callback(new Error('E-mail is invalid'))
    }

    this.checkEmailExists(rule, value, callback)
  }

  checkEmailExists = (rule, value, callback) => {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { modal } = this.state
        const { email } = modal.item
        const response = await adminService.checkEmail(value)

        if (response.exists !== false && email !== value) {
          callback(new Error('E-mail already exists'))
        }
      }

      callback()
    })
  }

  checkPassword = (rule, value, callback) => {
    const { validateFields } = this.props.form

    if (value && value.length > 0) {
      if (validator.checkPassword(value)) {
        callback(new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter'))
      }

      if (value.length < 8) {
        callback(new Error('Please enter at least 8 characters'))
      }
    }

    if (value) {
      validateFields(['confirm_password'], { force: true })
    }

    callback()
  }

  checkConfirmPassword = (rule, value, callback) => {
    const { getFieldValue } = this.props.form
    const password = getFieldValue('password') || ''

    if (value && value.length > 0) {
      if (password.length < 1 && validator.checkPassword(value)) {
        callback(new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter'))
      }

      if (password.length < 1 && value.length < 8) {
        callback(new Error('Please enter at least 8 characters'))
      }
    }

    if (value && value !== password) {
      callback(new Error('Your confirm password does not match password'))
    } else {
      callback()
    }
  }

  fetchAdmins = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { adminsFetched, pristine } = this.props
      sessionStorage.setObject(filterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      sessionStorage.setObject(sortKey, sort)
      this.setState({ currentPage, loading: loading || pristine })
      const response = await adminService.listByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({ loading: false })
      adminsFetched(response)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load system users successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findAdmins = (text) => {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchAdmins({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  findRoles = async (value) => {
    try {
      this.setState({ roleValidateStatus: 'validating' })
      adminRoleService
        .findByName(100, value || '')
        .then((roles) => {
          this.setState({ roles, roleValidateStatus: undefined })
        })
    } catch (e) {
      console.error(e)
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { modal, total } = this.state
        const { remark } = values
        const { item } = modal
        const { id } = item
        this.setState({ loadingForm: true })
        values.remark = remark || null

        try {
          let response

          if (id) {
            response = await adminService.save(id, values)
          } else {
            values.image = item.image
            response = await adminService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', 'System user saved successfully.')
            this.hideModal()
            this.fetchAdmins({ loading: true, currentPage: 1 })

            if (Array.isArray(response.permissions)) {
              const { permissions } = response
              localStorage.setObject('permissions', permissions)
            }
          } else {
            notify.error('Unable to save successfully', 'Unable to save system user successfully. Please try again later.')
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save system user successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleAdd = () => {
    this.findRoles()
    this.showModal({}, false)
  }

  handleDelete = (id) => async () => {
    const checkActivity = await adminService.checkActivityLog(id)

    if (checkActivity.exists) {
      Modal.warning({
        title: 'Error! ',
        content: 'This User Cannot Be Deleted. Activity Log Already Recorded For This User.'
      })
    } else {
      const response = await adminService.remove(id)

      if (response) {
        const { currentPage, filter, list, sort } = this.state
        this.fetchAdmins({ loading: true, currentPage: list.length > 1 ? currentPage : (currentPage - 1 || 1), filter, sort })
        message.success('Deleted successfully', 'System user deleted successfully')
      }
    }
  }

  handleEdit = (item) => () => {
    this.findRoles()
    this.showModal(item, true)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({ modal })
    resetFields()
  }

  showModal = (item, edit) => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    modal.item = {}
    this.setState({ modal })
    resetFields()
    modal.edit = edit
    modal.item = item
    modal.show = true
    this.setState({ modal })
  }
}

const mapDispatchToProps = {
  adminsFetched
}

const mapStateToProps = (state) => {
  return { ...state.Admin }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingAdmin))

import moment from 'moment'
import formatter from './formatter'
import { cloneDeep } from 'lodash'

const timezone = 'Australia/Melbourne'
// moment.tz.setDefault(timezone)

/**
 * this class is used for calculate the breakdown to know is there any extended job which falls outside of S/O hours
 * able to know if there is any required additional tasks required to add into extended work list (getup list)
 */
export default class SOExtendPeriods {
  constructor ({soHourStart, soHourEnd}) {
    this.soHourStart = soHourStart ? moment(moment(soHourStart).format('HH:mm'), 'HH:mm') : null
    this.soHourEnd = soHourEnd ? moment(moment(soHourEnd).format('HH:mm'), 'HH:mm') : null
    this.breakdownList = []
    this.breakdownText = ''
    this.isSoSameDay = isDateOnSameDay(this.soHourStart, this.soHourEnd)
  }

  updateValue ({soHourStart, soHourEnd}) {
    this.soHourStart = soHourStart ? moment(soHourStart).format('HH:mm') : this.soHourStart
    this.soHourEnd = soHourEnd ? moment(soHourEnd).format('HH:mm') : this.soHourEnd
    this.isSoSameDay = isDateOnSameDay(this.soHourStart, this.soHourEnd)
  }

  getBreakdown (jobStartDate, jobEndDate) {
    const jsd = moment.isMoment(jobStartDate) ? jobStartDate : moment(jobStartDate)
    const esd = moment.isMoment(jobEndDate) ? jobEndDate : moment(jobEndDate)

    const breakdowns = []

    const jsd1 = jsd.clone().format('YYYY-MM-DD')
    const esd1 = esd.clone().format('YYYY-MM-DD')
    const soStart = moment.tz(`${jsd1} ${this.soHourStart}`, timezone)
    const soEnd = this.isSoSameDay ? moment.tz(`${jsd1} ${this.soHourEnd}`, timezone) : moment.tz(`${esd1} ${this.soHourEnd}`, timezone)

    if (jsd.isBefore(soStart)) {
      breakdowns.push({
        start: jsd.clone(),
        end: soStart.clone(),
        isSameDay: jsd.isSame(soStart, 'day')
      })
    }

    if (esd.isAfter(soEnd)) {
      breakdowns.push({
        start: soEnd.clone(),
        end: esd.clone(),
        isSameDay: jsd.isSame(soStart, 'day')
      })
    }

    this.breakdownList = cloneDeep(breakdowns)
    this.breakdownText = output(breakdowns)
  }
}

function output (breakdowns = []) {
  let txt = ''

  for (let i = 0; i < breakdowns.length; i++) {
    const v = breakdowns[i]
    const st = moment.isMoment(v.start) ? v.start.format('DD/MM/YYYY HH:mmA') : ''
    const et = moment.isMoment(v.end) ? v.end.format(v.isSameDay ? 'HH:mmA' : 'DD/MM/YYYY HH:mmA') : ''

    txt += `${txt ? '; ' : ''}${st} - ${et}`
  }

  return txt
}

function isDateOnSameDay (start, end) {
  if (start && end) {
    const s = moment(start)
    const e = moment(end)

    const isSameHalf = isTimeOnSameHalf(s, e)

    if (isSameHalf) {
      return s.isSameOrBefore(e)
    } else {
      return false
    }
  }

  return undefined
}

function isTimeOnSameHalf (start, end) {
  const s = moment(start).format('a')
  const e = moment(end).format('a')

  return s === e
}

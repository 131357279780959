export const FETCH_FUTURE_JOBS = 'FETCH_FUTURE_JOBS'
export const FETCHING_FUTURE_JOBS = 'FETCHING_FUTURE_JOBS'
export const FUTURE_JOBS_FETCHED = 'FUTURE_JOBS_FETCHED'

export const fetchFutureJobs = (params) => {
  return Object.assign({
    type: FETCH_FUTURE_JOBS
  }, params)
}

export const fetchingFutureJobs = (loading) => {
  return {
    type: FETCHING_FUTURE_JOBS,
    loading
  }
}

export const futureJobsFetched = (jobs) => {
  return {
    type: FUTURE_JOBS_FETCHED,
    jobs
  }
}

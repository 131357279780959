import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { exportFile, formatter } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { connect } from 'react-redux'
import { settingOtherService } from '../../../services/setting'
import { Form, Input } from 'antd';
const { Item: FormItem } = Form
const VIEW_CLIENT = 'clients'
const VIEW_EMPLOYEE = 'employees'
const pageSize = 30

export class ReportDayCount extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      currentPage: 1,
      currentView: VIEW_CLIENT,
      isLoaded: false,
      loading: false,
      loadingExport: false,
      loadingList: false,
      total: 0,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchList()
    this.fetchOtherSettings()
  }

  render () {
    const { currentPage, currentView, loading, loadingExport, loadingList, list, total, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }

    const columns = [
      {
        title: formatter.capitalize(currentView),
        width: 7,
        render: ({ id, name }) => <a href={`/${currentView}/${id}`} rel='noopener noreferrer' target='_blank'>{name}</a>
      },
      {
        title: 'Active Status',
        width: 3,
        key: 'active_status'
      },
      {
        title: 'Acc Ref',
        width: 5,
        key: 'acc_ref'
      },
      {
        title: 'Last Job Date',
        width: 6,
        render: ({ job_start_date: jsd }) => <div>{formatter.toStandardDate(jsd)}</div>
      },
      {
        title: 'Days Count',
        width: 3,
        render: ({ past_day: pd }) => <div>{ pd === -1 ? 'No past jobs' : pd === 0 ? '< 24 hours' : pd }</div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Day Counts since Last Job`} >
            { !loading
              ? <Button key={'export'} feedback={loadingExport} onClick={() => this.handleExport()}>
                Export
              </Button>
              : null }
            { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }

          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('last_job_count_email', {
                          initialValue: settings.last_job_count_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group defaultValue={currentView} onChange={(v) => this.onChangeView(v)}>
                  <Radio.Button value={VIEW_CLIENT}> Client </Radio.Button>
                  <Radio.Button value={VIEW_EMPLOYEE}> Employee </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div className='admin-list' style={{marginTop: '30px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { currentView === VIEW_CLIENT
                  ? <div>
                    <div className='report-title'>{`Clients' Job Day Counts`} <span className='report-tag'>{total}</span></div>
                    <List cols={columns} rows={list} />
                  </div>
                  : currentView === VIEW_EMPLOYEE
                    ? <div>
                      <div className='report-title'>{`Employees' Job Day Counts`} <span className='report-tag'>{total}</span></div>
                      <List cols={columns} rows={list} />
                    </div>
                    : null }
                  <Pager
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} jobs`}
                    current={currentPage}
                    onChange={(e) => this.onChangePage(e)}
                    style={{ marginTop: '0px' }}
                  />
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchList () {
    const { currentPage, currentView, isLoaded, loading } = this.state
    this.fetchDayCount({currentPage, currentView, isLoaded, loading})
  }

  async fetchDayCount ({ currentPage, currentView, isLoaded = true, loading }) {
    if (this.hasAccess('readDayCountReport')) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const { list, total } = await reportService.getListByPageView(ExportType.REPORT.LAST_JOB_DAY_COUNT, currentView, currentPage, pageSize)

        this.setState({
          list,
          total,
          loading: false,
          loadingList: false,
          isLoaded: true
        }, () => this.toTop())
      } catch (e) {
        notify.error('Unable to get Day Count since Last Job Report', 'Please try again later.')
        this.setState({ loading: false, loadingList: false, isLoaded: false  })
      }
    }
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  onChangePage (page) {
    this.setState({ currentPage: page }, () => {
      this.fetchList()
    })
  }

  onChangeView (e) {
    this.setState({
      currentView: e.target.value,
      currentPage: 1,
      isLoaded: false
    }, () => {
      this.fetchList()
    })
  }

  handleExport = () => {
    const { loadingExport, loading, loadingList } = this.state

    if (loadingExport || loading || loadingList || !this.hasAccess('readDayCountReport')) return

    this.setState({ loadingExport: true })

    setTimeout(async () => {
      const r = await exportFile.fetchReportExport(ExportType.REPORT.LAST_JOB_DAY_COUNT)
      this.setState({ loadingExport: false })
    }, 4000)
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportDayCount))

import { request, queryString } from '../util'

const url = '/private/api/reminder-log'

export default {
  findByName (size, text, filter) {
    return request(`${url}/find/name/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  }
}

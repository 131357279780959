export const MODULES_FETCHED = 'MODULES_FETCHED'
export const MODULE_SET_PRISTINE = 'MODULE_SET_PRISTINE'

export const modulesFetched = (modules) => {
  return {
    type: MODULES_FETCHED,
    modules
  }
}

export const moduleSetPristine = (pristine) => {
  return {
    type: MODULE_SET_PRISTINE,
    pristine
  }
}

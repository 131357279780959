/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clientLeaveService, employeeService, employeeLeaveService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import { formatter, log } from '../../../util'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

export class EmployeeLeave extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      item: {},
      dayTime: '',
      taskDetail: '',
      leaves: [],
      loading: false,
      clientId: '',
      empInfo: '',
      startDay: '',
      endDay: '',
      memoInfo: '',
      payrolls: [],
      languages: [],
      skills: [],
      modal: {
        item: { link: {} },
        show: false
      },
      editLeave: {
        isEdit: false
      },
      modalShow: false,
      currentPage: 1,
      total: 0,
      selectedItem: ''
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchLeave(currentPage)
    this.fetchEmployee()
  }

  render () {
    const { form, empId, history } = this.props
    const { getFieldDecorator } = form
    const { leaves, loading, modal, memoInfo, startDay, endDay, statusInfo, currentPage, total, selectedItem } = this.state

    const columns = [

      {
        title: 'Memo',
        width: 9,
        key: 'memo'
      },

      {
        title: 'Start',
        width: 3,
        render: ({ leave_start }) => formatter.toShortDate(leave_start)
      },

      {
        title: 'Finish',
        width: 3,
        render: ({ leave_end }) => formatter.toShortDate(leave_end)
      },

      {
        title: 'Approved',
        width: 2,
        render: ({ approved }) => approved ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <div style={{ color: '#ccc' }}><Icon type='check-circle' /></div>
      },

      {
        title: 'Jobs To Action',
        width: 2,
        render: ({ id, impacted, total_jobs }) => parseInt(total_jobs) > 0 ? <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Resolve'> <Link to={`/employees/${empId}/leave/${id}`}><div style={{ color: '#dd4444' }}>{impacted} Jobs <Icon type='issues-close' /></div></Link></Tooltip>
        </div> : null
      },

      {
        title: 'Last Updated On',
        width: 3,
        render: ({ updated_at }) => formatter.toStandardDate(updated_at)
      },

      {
        title: '',
        width: 2,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'> <div onClick={() => this.handleEditLeave(item)}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title='Confirm to delete this?'
              onConfirm={() => this.handleDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-header'>
          <div className='btn' onClick={() => this.showModal()}>
            Add Leave
          </div>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={leaves} />

            <SideModal
              title='Leave'
              showModal={modal.show}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Memo'>
                  {getFieldDecorator('memo', {
                    initialValue: selectedItem.memo,
                    rules: [
                      { min: 2, message: 'Memo must be between 2 and 128 characters' },
                      { max: 128, message: 'Memo must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter memo' },
                      { whitespace: true, message: 'Please enter memo' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
                <FormItem label='Start Leave'>
                  {getFieldDecorator('leave_start', {
                    initialValue: moment(selectedItem.leave_start).isValid() ? moment(selectedItem.leave_start) : moment(),
                    rules: [
                      { required: true, message: 'Please enter date' },
                      { validator: this.handleDateChange() }
                    ]
                  })(
                    <DatePicker format='DD/MM/YYYY' defaultPickerValue={moment(new Date())} />
                  )}
                </FormItem>

                <FormItem label='End Leave'>
                  {getFieldDecorator('leave_end', {
                    initialValue: moment(selectedItem.leave_end).isValid() ? moment(selectedItem.leave_end) : moment(),
                    rules: [
                      { required: true, message: 'Please enter date' },
                      { validator: this.handleDateChange() },
                    ]
                  })(
                    <DatePicker format='DD/MM/YYYY' defaultPickerValue={moment(new Date())} />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('approved', {
                    initialValue: selectedItem.approved,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Approved' unCheckedChildren='Not Approved' defaultChecked
                    />
                  )}
                </FormItem>

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} leaves`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchEmployee = async () => {
    try {
      const { empId } = this.props
      this.setState({ loading: true })
      const { item } = await employeeService.get(empId)
      const empInfo = item
      this.setState({ empInfo, loading: false, empId: empId })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchLeave = async (startPage = null) => {
    this.setState({ loading: true })
    const { empId } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.employee_id = { condition: '=', value: empId }

    const { list: leaves, total } = await employeeLeaveService.listByPage(page, pageSize, filter)

    this.setState({ leaves, total, loading: false, currentPage: page, filter })
  }

  changePage = async (currentPage) => {
    this.fetchLeave(currentPage)
  }

  handleDateChange = () => (rule, value, callback) => {
    const { form } = this.props
    const { leaves, selectedItem } = this.state
    const { getFieldValue } = form

    const startDate = getFieldValue('leave_start')
    const endDate = getFieldValue('leave_end')

    if (startDate !== null && endDate !== null) {
      if (endDate < startDate) {
        try {
          throw new Error(`End Leave should not be earlier than Start Leave`)
        } catch (err) {
          callback(err)
        }
      }

      for (let i = 0; i < leaves.length; i++) {
        const leave = leaves[i]
        let isBetween = false

        if (selectedItem && selectedItem.id === leave.id) {
          isBetween = false
        } else {
          isBetween = moment(startDate).isBetween(leave.leave_start, leave.leave_end)
        }

        if (isBetween) {
          try {
            throw new Error(`Date is overlapping with ${`${formatter.toShortDate(leave.leave_start)} - ${formatter.toShortDate(leave.leave_end)}`}`)
          } catch (err) {
            callback(err)
          }
        }
      }
    }

    callback()
  }

  handleEditLeave = (item) => {
    const { editLeave } = this.state
    this.showModal()
    editLeave.isEdit = true
    // console.log('Will edit', id, dayTime, taskDetail)
    this.setState({ editLeave, selectedItem: item })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { editLeave, selectedItem, empInfo } = this.state

    if (!editLeave.isEdit) {
      validateFields(async (errors, values) => {
        if (!errors) {
          const { empId } = this.props

          this.setState({ loading: true })
          // console.log('PARAMS', values)
          values.employee_id = empId
          values.leave_start = moment(values.leave_start).startOf('day')
          values.leave_end = moment(values.leave_end).endOf('day')

          if (values.approved) {
            values.approved = true
          } else {
            values.approved = false
          }

          const response = await employeeLeaveService.add(values)
          this.setState({ loading: false })

          if (response.id) {
            log.addEmployeeLeave(empId,
              `Leaves Start "${formatter.toShortDate(values.leave_start)}", Leave End "${formatter.toShortDate(values.leave_end)}",
              Memo "${values.memo}"
              `)
            notify.success('Saved successfully', 'Leave created successfully')
            this.fetchLeave()
            this.hideModal()
            this.props.setRefreshActivityLog(true)
          } else {
            notify.error('Unable to save successfully', 'Unable to save Leave successfully. Please try again later.')
          }
        }
      })
    } else {
      validateFields(async (errors, values) => {
        if (!errors) {
          const { empId } = this.props

          this.setState({ loading: true })
          // const newVersion = carePlan.version + 1
          // console.log('PARAM', values)

          // console.log('Is Editing, no add, add version, taskID:', taskId)

          if (values.approved) {
            values.approved = true
          } else {
            values.approved = false
          }
          values.employee_id = empId
          values.leave_start = moment(values.leave_start).startOf('day')
          values.leave_end = moment(values.leave_end).endOf('day')
          const leaveId = selectedItem.id
          const response = await employeeLeaveService.save(leaveId, values)
          this.setState({ loading: false })

          if (response.id) {
            log.updateEmployeeLeave(empId, selectedItem, values, ['employee_id'])
            notify.success('Saved successfully', 'Leave saved successfully')
            this.fetchLeave()
            this.hideModal()
            this.props.setRefreshActivityLog(true)
          } else {
            notify.error('Unable to save successfully', 'Unable to save Leave successfully. Please try again later.')
          }
        }
      })
    }
  }

  async handleDelete (item) {
    const { empId } = this.props
    const { empInfo } = this.state
    const res = await employeeLeaveService.remove(item.id)

    if (res) {
      log.deleteEmployeeLeave(empId, `Leave Start "${formatter.toShortDate(item.leave_start)}", Leave End "${formatter.toShortDate(item.leave_end)}",
      Memo "${item.memo}"
      `)
      notify.success('Leave Deleted', 'Leave deleted successfully')
      this.fetchLeave()
      this.props.setRefreshActivityLog(true)
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal, editLeave } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {}, editLeave, memoInfo: null, startDay: null, endDay: null, statusInfo: null, leaveId: null })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeLeave))

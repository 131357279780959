export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const FETCHING_CLIENTS = 'FETCHING_CLIENTS'
export const CLIENTS_FETCHED = 'CLIENTS_FETCHED'

export const fetchClients = (params) => {
  return Object.assign({
    type: FETCH_CLIENTS
  }, params)
}

export const fetchingClients = (loading) => {
  return {
    type: FETCHING_CLIENTS,
    loading
  }
}

export const clientsFetched = (clients) => {
  return {
    type: CLIENTS_FETCHED,
    clients
  }
}

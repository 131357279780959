import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clientService, clientLeaveService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log } from '../../../util'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const { Item: FormItem } = Form

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const pageSize = 20

export class ClientLeave extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: [],
      leaves: [],
      loading: false,
      clientId: '',
      clientInfo: '',
      modal: {
        item: { link: {} },
        show: false
      },
      editLeave: {
        isEdit: false
      },
      modalShow: false,
      currentPage: 1,
      total: 0,
      selectedItem: { id: null },
      isUFN: false
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchClient()
    // console.log('state', this.props.match)
    this.fetchLeave(currentPage)
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const { leaves, loading, modal, selectedItem, currentPage, total, isUFN } = this.state

    const columns = [

      {
        title: 'Memo',
        width: 9,
        key: 'memo'
      },

      {
        title: 'Start',
        width: 4,
        render: ({ leave_start }) => formatter.toShortDate(leave_start)
      },

      {
        title: 'Finish',
        width: 4,
        render: ({ leave_end, until_further_notice }) => until_further_notice ? 'UFN' : formatter.toShortDate(leave_end)
      },

      {
        title: 'Jobs To Action',
        width: 2,
        render: ({ id, impacted, total_jobs }) => parseInt(total_jobs) > 0 ? <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0}  title='Resolve'> <Link to={`/clients/${clientId}/leave/${id}`}><div style={{ color: '#dd4444' }}>{impacted} Jobs <Icon type='issues-close' /></div></Link></Tooltip>
        </div> : null
      },

      {
        title: 'Last Updated On',
        width: 3,
        render: ({ updated_at }) => formatter.toStandardDate(updated_at)
      },

      {
        title: '',
        width: 2,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0}  title='Edit'> <div onClick={() => this.handleEditLeave(item)}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0}  title='Delete'>
            <Popconfirm
              title='Confirm to delete this?'
              onConfirm={() => this.handleDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-header'>
          <div className='btn' onClick={() => this.showModal()}>
            Add Leave
          </div>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>

            <List cols={columns} rows={leaves} />

            <SideModal
              title='Leave'
              showModal={modal.show}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={() => this.checkBeforeSave()}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Memo'>
                  {getFieldDecorator('memo', {
                    initialValue: selectedItem.memo,
                    rules: [
                      { min: 2, message: 'Memo must be between 2 and 128 characters' },
                      { max: 128, message: 'Memo must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter memo' },
                      { whitespace: true, message: 'Please enter memo' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
                <FormItem label='Start Leave'>
                  {getFieldDecorator('leave_start', {
                    initialValue: selectedItem.leave_start ? moment.isMoment(selectedItem.leave_start) ? selectedItem.leave_start : moment(selectedItem.leave_start) : null,
                    rules: [
                      { required: true, message: 'Please enter date' },
                      { validator: this.handleDateChange() }
                    ]
                  })(
                    <DatePicker format='DD/MM/YYYY' defaultPickerValue={moment(new Date())} />
                  )}
                </FormItem>

                <Row>
                  <FormItem label='End Leave'>
                    <Col lg={16}>
                      {getFieldDecorator('leave_end', {
                        initialValue: !isUFN ? selectedItem.leave_start ? (moment.isMoment(selectedItem.leave_end) ? selectedItem.leave_end : moment(selectedItem.leave_end)) : null : null,
                        rules: [
                          { validator: this.handleDateChange() },
                          !isUFN
                            ? { required: true, message: 'Please enter date' }
                            : {}
                        ]
                      })(
                        <DatePicker format='DD/MM/YYYY' disabled={isUFN} defaultPickerValue={moment(new Date())} />
                      )}
                    </Col>
                    <Col lg={8}>
                      {getFieldDecorator('until_further_notice', {
                        initialValue: selectedItem.until_further_notice || false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='UFN'
                          unCheckedChildren='UFN'
                          onChange={(e) => this.handleUFNClick(e)}
                        />
                      )}
                    </Col>
                  </FormItem>
                </Row>

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} leaves`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchClient = async () => {
    try {
      const { clientId } = this.props
      this.setState({ loading: true })
      const { item } = await clientService.get(clientId)
      const clientInfo = item
      this.setState({ clientInfo, loading: false, clientId: clientId })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchLeave = async (startPage = null) => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.client_id = { condition: '=', value: clientId }

    const { list: leaves, total } = await clientLeaveService.listByPage(page, pageSize, filter)

    this.setState({ leaves, total, loading: false, currentPage: page, filter })
  }

  changePage = async (currentPage) => {
    this.fetchLeave(currentPage)
  }

  checkBeforeSave = () => {
    const { form } = this.props
    const { clientInfo, leaves, selectedItem } = this.state
    const { getFieldValue } = form
    let isExist = false

    for (let i = 0; i < leaves.length; i++) {
      const leave = leaves[i]

      if (selectedItem && selectedItem.id === leave.id) {
        isExist = false
      } else if (getFieldValue('until_further_notice') === true && leave.until_further_notice === true) {
        isExist = true
      }

      if (isExist) {
        Modal.error({
          title: 'Unable to add more than one leave UFN',
          content: `${clientInfo.first_name} ${clientInfo.last_name} already have existing leave UFN added.`
        })
        break
      }
    }

    if (!isExist) {
      this.handleSave()
    }
  }

  handleDateChange = () => (rule, value, callback) => {
    const { form } = this.props
    const { leaves, selectedItem } = this.state
    const { getFieldValue } = form

    const startDate = getFieldValue('leave_start')
    const endDate = getFieldValue('leave_end')

    if (startDate !== null && endDate !== null) {
      if (endDate < startDate) {
        try {
          throw new Error(`End Leave should not be earlier than Start Leave`)
        } catch (err) {
          callback(err)
        }
      }

      for (let i = 0; i < leaves.length; i++) {
        const leave = leaves[i]
        let isBetween = false

        if (selectedItem && selectedItem.id === leave.id) {
          isBetween = false
        } else if (leave.until_further_notice === true) {
          isBetween = moment(startDate) >= moment(leave.leave_start) || moment(endDate) >= moment(leave.leave_start)
        } else {
          isBetween = moment(startDate).isBetween(leave.leave_start, leave.leave_end)
        }

        if (isBetween) {
          try {
            throw new Error(`Date is overlapping with ${`${formatter.toShortDate(leave.leave_start)} - ${leave.until_further_notice ? 'UFN' : formatter.toShortDate(leave.leave_end)}`}`)
          } catch (err) {
            callback(err)
          }
        }
      }
    }

    callback()
  }

  handleEditLeave = (item) => {
    const { editLeave } = this.state
    this.showModal()
    editLeave.isEdit = true

    this.setState({ editLeave, selectedItem: item, isUFN: item.until_further_notice })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { editLeave, clientInfo, selectedItem, isUFN } = this.state

    if (!editLeave.isEdit) {
      validateFields(async (errors, values) => {
        if (!errors) {
          if (!moment(values.leave_end).isValid() && !isUFN) {
            notify.error('Unable to save successfully', 'Please fill in all required fields.')
          } else {
            const { clientId } = this.props

            this.setState({ loading: true })
            values.client_id = clientId
            values.leave_start = moment(values.leave_start).startOf('day')
            values.leave_end = moment(values.leave_end).endOf('day')

            if (values.approved) {
              values.approved = true
            } else {
              values.approved = false
            }

            const response = await clientLeaveService.add(values)

            if (response.id) {
              if (isUFN) {
                await clientLeaveService.updateJobsUFN({ leaveId: response.id })
              }
              this.setState({ loading: false })
              log.addClientLeave(clientId,
                `Leave Start "${formatter.toShortDate(values.leave_start)}", Leave End "${formatter.toShortDate(values.leave_end)}",
                Memo "${values.memo}", Until Further Notice "${values.until_further_notice}"
                `)
              notify.success('Saved successfully', 'Leave created successfully')
              this.fetchLeave()
              this.hideModal()
              this.props.setRefreshActivityLog(true)
            } else {
              notify.error('Unable to save successfully', 'Unable to save Leave successfully. Please try again later.')
            }
          }
        }
      })
    } else {
      validateFields(async (errors, values) => {
        if (!errors) {
          if (!moment(values.leave_end).isValid() && !isUFN) {
            notify.error('Unable to save successfully', 'Please fill in all required fields.')
          } else {
            const { clientId } = this.props

            this.setState({ loading: true })

            // console.log('PARAM', values)

            if (values.approved) {
              values.approved = true
            } else {
              values.approved = false
            }
            values.client_id = clientId
            values.leave_start = moment(values.leave_start).startOf('day')
            values.leave_end = moment(values.leave_end).endOf('day')
            const leaveId = selectedItem.id

            if (!isUFN && selectedItem.until_further_notice) { // Uncancel jobs
              await clientLeaveService.updateJobsUndoUFN({ leaveId: leaveId, leaveStart: values.leave_start, leaveEnd: values.leave_end })
            }

            const response = await clientLeaveService.save(leaveId, values)

            if (response.id) {
              if (isUFN) { // Cancel jobs
                await clientLeaveService.updateJobsUFN({ leaveId: leaveId })
                Message.success('Leave Saved successfully. Please wait for a moment to process the on hold jobs', 10)
              }
              if (!isUFN && selectedItem.until_further_notice) { // Uncancel jobs
                Message.success('Leave Saved successfully. Please wait for a moment to reinstate jobs', 10)
              }
              this.setState({ loading: false, item: { ...values } })
              log.updateClientLeave(clientId, selectedItem, values, isUFN ? ['client_id', 'leave_end'] : ['client_id'])
              notify.success('Saved successfully', 'Leave saved successfully')
              this.fetchLeave()
              this.hideModal()
              this.props.setRefreshActivityLog(true)
            } else {
              notify.error('Unable to save successfully', 'Unable to save Leave successfully. Please try again later.')
            }
          }
        }
      })
    }
  }

  async handleDelete (item) {
    const { clientId } = this.props
    const { clientInfo } = this.state
    const res = await clientLeaveService.remove(item.id)

    if (res) {
      // log.deleteClientLeave(clientId, `Delete leave for ${clientInfo.first_name} ${clientInfo.last_name} from ${formatter.toShortDate(startDate)} to ${isUFN ? 'Until Further Notice' : formatter.toShortDate(endDate)}`)
      log.deleteClientLeave(clientId, `Leave Start "${formatter.toShortDate(item.leave_start)}", Leave End "${formatter.toShortDate(item.leave_end)}",
      Memo "${item.memo}", Until Further Notice "${item.until_further_notice}"
      `)
      notify.success('Leave Deleted', 'Leave deleted successfully')
      this.fetchLeave()
    }
  }

  handleUFNClick = (value) => {
    const { form } = this.props

    this.setState({ isUFN: value })

    form.setFieldsValue({ leave_end: null })
  }

  hideModal = () => {
    const { form } = this.props
    const { modal, editLeave } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    editLeave.isEdit = false
    this.setState({ modal, selectedItem: {}, editLeave, isUFN: false })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientLeave))

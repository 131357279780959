export default {
  JOB_TYPE_PENDING: 'pending',
  JOB_TYPE_ACTIVE: 'active',
  JOB_TYPE_ENDED: 'ended',
  JOB_TYPE_FUTURE: 'future',
  JOB_TYPE_FUTURE_ALL: 'future-all',
  JOB_TYPE_PAST: 'past',
  JOB_TYPE_PAST_ALL: 'past-all',
  JOB_TYPE_CANCELLED: 'cancelled',
  JOB_TYPE_ALL: 'all',
}

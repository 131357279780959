import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/jobs'
const fileUrl = '/private/api/jobs/files'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },

  getDetail (id) {
    return request(`${url}/detail/${id}`)
  },

  getByEmployee (empId) {
    return request(`${url}/empId/${empId}`)
  },

  getByClient (clientId) {
    return request(`${url}/clientId/${clientId}`)
  },

  getByClientAndStart (clientId, start) {
    return request(`${url}/clientId/${clientId}/startD/${start}`)
  },

  getByClientAndEnd (clientId, end) {
    return request(`${url}/clientId/${clientId}/endD/${end}`)
  },

  getByEmpAndStart (empId, start) {
    return request(`${url}/empId/${empId}/startD/${start}`)
  },

  getByEmpAndEnd (empId, end) {
    return request(`${url}/empId/${empId}/endD/${end}`)
  },

  // CS
  getEmployeeConflictJobs (jobId, empId, start, end) {
    return request(`${url}/${jobId}/employee/${empId}/${start}/${end}`)
  },

  // CS
  getClientConflictJobs (jobId, empId, start, end) {
    return request(`${url}/${jobId}/client/${empId}/${start}/${end}`)
  },

  getByClientFuture (clientId) {
    return request(`${url}/clientId/${clientId}/future`)
  },

  getByEmployeeAndDate (empId, date) {
    return request(`${url}/empId/${empId}/date/${date}`)
  },

  getByEmployeeAndStartEnd (empId, start, end) {
    return request(`${url}/empId/${empId}/startD/${start}/endD/${end}`)
  },

  getClientJob (clientId, page, size, filter, sorting, text) {
    return request(`${url}/client/${clientId}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getCancelledJob (page, size, filter, sorting, text) {
    return request(`${url}/cancel/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getFutureJob (page, size, filter, sorting, text) {
    return request(`${url}/future/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getPastJob (page, size, filter, sorting, text) {
    return request(`${url}/past/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getPendingEmployeeJob (page, size, filter, sorting, text) {
    return request(`${url}/noemp/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getEmails (page, size, filter, sorting, text) {
    return request(`${url}/emails/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },

  getEmailsById (id) {
    return request(`${url}/emails/${id}`)
  },

  getUnsentEmailTotal (id) {
    return request(`${url}/emails/unsent/${id}`)
  },

  getUnsentEmailTotalRecurring (id) {
    return request(`${url}/emails/unsent/recurring/${id}`)
  },

  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },

  removeSingleJobs (id) {
    return request(`${url}/${id}/basejobs`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveQuick (id, values) {
    return request(`${url}/${id}/quick`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getSuburbs () {
    return request(`${url}/suburbs`)
  },
  getClientIds () {
    return request(`${url}/ids`)
  },
  getTotalPending () {
    return request(`${url}/pending/total`)
  },
  // MS0021 recurring enhancement
  getRecurringConflictJobs (values) {
    return request(`${url}/recurring/conflict`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAffectedUpdateJobs (values) {
    return request(`${url}/recurring/affected`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },

  addFile (values) {
    return request(fileUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFile (id, values) {
    return request(`${fileUrl}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listFileByPage (page, size, filter, sorting, text) {
    return request(`${fileUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listFileDocumentByPage (page, size, filter, sorting, text) {
    return request(`${fileUrl}/document/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getFileDocumentMainCategory () {
    return request(`${fileUrl}/document/main-category`)
  },
  removeFile (id) {
    return request(`${fileUrl}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  async getEmployeeTotalMaxHour (values) {
    return request(`${url}/list/employee/maxhrs/post`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, fvpRatesService } from '../../../../services'
import moment from 'moment'
import { Link } from 'react-router-dom'

// UI
import { Button, FullModal, Page, Pager, SectionTitle, List, ListDiv } from '../../../../components'
import { formatter } from '../../../../util'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import TimePicker from 'antd/lib/time-picker'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Billing Rate Set'
const settingType = 'billing rate set'

export class SettingRateSet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billingRates: [],
      categories: [],
      categoryRates: [],
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchRateSets({ loading: true, currentPage })
  }

  fetchRateSets = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      if (this.hasAccess('listFvpFundingRateSets')) {
        this.setState({ currentPage, loading: loading })
        const settings = await fvpRatesService.listByPage(currentPage, pageSize, filter, sort, searchText)

        this.setState({ list: settings.list, loading: false, total: settings.total })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Rate Sets successfully. Please try again later.')
    }
  }

  async handleDelete (id) {
    this.setState({ loading: true })
    const res = await fvpRatesService.remove(id)

    if (res) {
      await this.fetchRateSets({ currentPage: 1 })
      notify.success('Deleted successfully', `Rate set deleted successfully`)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, total } = this.state

    const columns = [
      {
        title: 'Name',
        width: 18,
        key: 'name',
        render: ({ name }) => <div className={`rs-title`}>{ name }</div>
      },
      {
        title: 'Enable',
        width: 4,
        render: ({ id, active }) => (
          <div style={{ fontSize: '11pt' }}>
            { active
              ? <Icon type='check-circle' theme='filled' />
              : null }
            { this.hasAccess('readFvpFundingRateSet') && !this.hasAccess('updateFvpFundingRateSet')
              ? <Col md={1}>
                  <Link to={`/settings/funders/rate-set/${id}`}><div><Icon type='eye' /></div></Link>
                </Col>
              : null }
          </div>
        )
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('updateFvpFundingRateSet') ? <Link to={`/settings/funders/rate-set/${id}`}><div><Icon type='form' /></div></Link> : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('deleteFvpFundingRateSet') ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    const subcolumns = [
      {
        title: 'Name',
        width: 9,
        subkey: 'rate_set_name',
        render: (name, index) => {
          return <div className={`${index === 0 ? 'rs-first' : 'rs-norm'}`}>{name}</div>
        }
      },
      {
        title: 'Period',
        width: 6,
        render: (data, index) => {
          const d1 = data['rate_set_start_date'][index]
          const d2 = data['rate_set_end_date'][index]
          return <div className={`${index === 0 ? 'rs-first' : 'rs-norm'}`}>{formatter.toShortDate(d1)} - {formatter.toShortDate(d2)}</div>
        }
      },
      {
        title: '',
        width: 3,
      },
      {
        title: 'Enable',
        width: 4,
        subkey: 'rate_set_active',
        render: (active) => <div style={{ fontSize: '11pt', width: '30px' }}>{ active ? <Icon type='check-circle' /> : null }</div>
      },
      {
        title: '',
        width: 2,
      }
    ]

    return (
      <div>
        <Page.Header title='Rate Set'>
          {
            this.hasAccess('createFvpFundingRateSet')
              ? <Link to={`/settings/funders/rate-set/add`}>
                <div className='btn'>
                  Add Rate Set
                </div>
              </Link>
              : null
          }
        </Page.Header>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <ListDiv cols={columns} rows={list} subcols={subcolumns} subrows='rate_set_name' />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} rate set`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
              />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchRateSets({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSet))

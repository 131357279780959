import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_FUNDERS, FETCHING_FUNDERS, FUNDERS_FETCHED } from '../actions/funder'
import funderService from '../../services/funder'

function * fetchFunders ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_FUNDERS, loading })
    const funders = yield funderService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: FUNDERS_FETCHED, funders, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_FUNDERS, loading: false })
    console.error(e)
  }
}

function * watchFunder () {
  yield takeEvery(FETCH_FUNDERS, fetchFunders)
}

export default watchFunder

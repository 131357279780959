/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import Communication from './Communication'
import File from './File'
import AddFileModal from './AddFileModal'

import { authService, jobService, employeeService, clientService,
  funderService, logService, settingGeneralService, settingBillingRateService,
  settingHolidayService, employeeLeaveService, settingOtherService,
  settingCancellationService, clientLeaveService, communicationService, employeeFileService,
  settingFileCategoryService, settingFileTypeService } from '../../../services'

import { fetchingJobs, fetchTotalPending } from '../../../states/actions/job'
import { setRefreshCommLog } from '../../../states/actions'
import { JobMenu } from '../../../constants'
import { common, formatter, log, validator } from '../../../util'
import DurationBreakdown from '../../../util/duration'
import { googleMapApi } from '../../../config'

// S1.5
// import GetUpTask from './getup'

// UI
import { DateTimePicker, Loading, List, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'

import './styles.css'
import SignoffMap from './map'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const SleepOver = 'Sleepover'

export class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientId: '',
      clientInfo: '',
      funders: [],
      employees: [],
      payrolls: [],
      currentEmployee: { id: '', name: '' },
      currentFunder: { id: '', name: '' },
      currentBillingCategory: { name: '' },
      isRecurring: false,
      billingRate: [],
      billingRateId: null,
      isEmergencyTime: false,
      isHoliday: false,
      isTimeConflict: false,
      isOnLeave: false,
      isMatchLanguage: true,
      isMatchSkill: true,
      billingCategory: [],
      emergencyField: false,
      selectedEmpInfo: '',
      item: { client: {}, employee: {} },
      modalShow: false,
      loading: false,
      showGetupHours: false,
      dayNumber: '',
      showCancelAlert: false,
      clientLanguage: [],
      clientSkill: [],
      prevEmployee: [],
      logList: [],
      showSleepoverAlert: false,
      employeePending: false,
      cancelType: [],
      cancelReason: [],
      showOtherField: false,
      getupList: [],
      isSleepover: false,
      funderInfo: {},
      funderDetail: '',
      showFunderAlert: false,
      clientLeaveInfo: '',
      clientIsOnLeave: false,
      clientConflictInfo: '',
      showClientConflictAlert: false,
      showFunderModal: false,
      isOvernightSleepover: false,
      notAssignedTime: false,
      currentTotalHours: 0,
      employeeId: '',
      durationBreakdown: '',
      conflictJobInfo: '',
      showCancelModal: false,
      showCancelConfirmModal: false,
      showEmergencyModal: false,
      settingOthers: {},
      showEdit: true,
      showSave: false,
      clientFunderId: 0,
      isCertificateExpired: false,
      isLicenseExpired: false,
      isVehicleRegistrationExpired: false,
      isVehicleInsuranceExpired: false,
      isDwesExpired: false,
      isPoliceExpired: false,
      isFirstAidExpired: false,
      isWWCCExpired: false,
      unsentCommTotal: 0,
      tabActiveKey: '1',
      showEmployeeReasonModal: false,
      changeEmployeeReason: '',
      changeEmployeeReasonType: '',
      changeEmployeeOtherReason: '',
      changeEmployeeNote: '',
      isHandlingLateCancel: false,
      isHandlingCancel: false,
      shouldRefreshComm: false,
      showClientPublicAlert: false,
      showClientPrivateAlert: false,
      showEmployeePublicAlert: false,
      showEmployeePrivateAlert: false,
      // signoff
      isSignoff: false,
      showSignoffModal: false,
      expiredFiles: [],
      shouldRefreshFiles: false,
      categoriesList: [],
      subCategoriesList: [],
      fileList: [],
      fileInfo: {},
      showAddFileModal: false,
      employeeJobHoursInfo: {},
      isLastJobSeries: false
    }
  }

  async componentDidMount () {
    let clientId = ''
    let funderId = ''
    let fundingId = ''
    let employeeId

    this.fetchSettings()

    if (this.isEdit()) {
      const item = await this.fetchJob()
      const jobId = this.getJobId()

      if (item) {
        clientId = item.client_id
        funderId = item.funder_id
        fundingId = item.client_funder_id
        employeeId = item.employee_id

        this.showBreakdown(Moment(item.job_start_date), Moment(item.job_end_date))
      }

      this.fetchCancel()
      this.fetchLogs()
      this.fetchUnsentEmail(jobId)
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)

      clientId = client
      fundingId = funder
    }

    this.fetchClient(clientId, employeeId)
    this.fetchAllEmployees(clientId, employeeId)
    this.fetchFunders(clientId, fundingId)
    this.fetchFileCategories()

    this.fetchPreviousEmployee(clientId)
    this.props.fetchTotalPending()

    this.setState({ clientId, fundingId, funderId, employeeId })
  }

  handleStartDateChange = async (date) => {
    const { form } = this.props
    const { clientId, item: job } = this.state
    const jobHours = job.job_hours || 1

    const jobStartDateTime = date
    const jobEndDateTime = form.getFieldValue('job_end_date')
    const newJobEndDateTime = Moment(jobStartDateTime).add(jobHours, 'hours')
    const durationsHours = jobEndDateTime ? jobEndDateTime.diff(jobStartDateTime, 'hours') : newJobEndDateTime.diff(jobStartDateTime, 'hours')
    const selectedEmployeeId = form.getFieldValue('employee_id')

    this.setState({ currentTotalHours: durationsHours })

    // Update job end time to field
    if (!jobEndDateTime) {
      form.setFieldsValue({ job_end_date: newJobEndDateTime })
    }

    // Display the shift time breakdown
    this.showBreakdown(jobStartDateTime, jobEndDateTime || newJobEndDateTime)

    this.checkEmergencyJob(jobStartDateTime)

    this.checkFunderAvailability(jobStartDateTime)

    this.checkClient(clientId, jobStartDateTime, jobEndDateTime || newJobEndDateTime)

    if (typeof selectedEmployeeId === 'number' && jobStartDateTime !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jobStartDateTime, jobEndDateTime || newJobEndDateTime)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, jobStartDateTime, jobEndDateTime || newJobEndDateTime)
    }
  }

  handleEndDateChange = async (date) => {
    const { form } = this.props
    const { clientId } = this.state
    const startDateTime = form.getFieldValue('job_start_date')

    const jobStartDateTime = Moment(startDateTime)
    const jobEndDateTime = date
    const selectedEmployeeId = form.getFieldValue('employee_id')

    const durationsHours = jobEndDateTime.diff(jobStartDateTime, 'hours')

    this.setState({ currentTotalHours: durationsHours })

    // Display the shift time breakdown
    this.showBreakdown(jobStartDateTime, jobEndDateTime)

    // this.checkEmergencyJob(jobStartDateTime)

    this.checkClient(clientId, jobStartDateTime, jobEndDateTime)

    if (typeof selectedEmployeeId === 'number' && jobStartDateTime !== null && jobEndDateTime !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jobStartDateTime, jobEndDateTime)
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId, jobStartDateTime, jobEndDateTime)
    }
  }

  handleBadgeCount = (value) => {
    this.setState({ unsentCommTotal: value })
    this.fetchLogs()
  }

  replacer = (value) => {
    let str = value
    // Replace special characters
    str = str.replace(/[•·]/g, '-')

    // Remove extra blank space or tab
    str = str.replace(/([ ]{2,})|(\t)/g, ' ')

    return str
  }

  render () {
    const { history, match, form } = this.props
    const { getFieldDecorator } = form
    const { clientInfo, item, isRecurring, showEdit, showSave, unsentCommTotal, tabActiveKey,
      showCancelConfirmModal, isHandlingLateCancel, isHandlingCancel, settingOthers, showEmergencyModal, showEmployeeReasonModal,
      showFunderModal, showCancelModal, cancelType, showOtherField, funders, cancelReason, shouldRefreshComm,
      showClientPrivateAlert, showClientPublicAlert, isSignoff, showSignoffModal, shouldRefreshFiles } = this.state
    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 17 }
    }

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Jobs' menu={JobMenu} countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>

          <Page.Header title={
            !this.isEdit() ? `New Job ${clientInfo ? `For ${clientInfo.first_name} ${clientInfo.last_name}` : ''} (V1)`
              : item.is_cancel && item.base_job_id && !item.is_delete
                ? 'Single Job (Recurring Job) (Cancelled) (V1)'
                : !item.is_cancel && item.base_job_id && !item.is_delete
                  ? 'Single Job (Recurring Job) (V1)'
                  : item.is_cancel && !item.base_job_id && !isRecurring && !item.is_delete
                    ? 'Single Job (Cancelled) (V1)'
                    : !item.is_cancel && !item.base_job_id && !isRecurring && !item.is_delete
                      ? 'Single Job (V1)'
                      : ''
          }>

            {
              this.isEdit() && item.base_job_id ? (
                <Link to={`/v1/jobs/recurring/${item.base_job_id}`}>
                  <div className='btn btn-ghost'>
                    { !item.is_cancel ? 'Edit Base Job' : 'Show Base Job' }
                  </div>
                </Link>
              ) : null
            }

            {
              showSave && this.isEdit() && !item.is_cancel && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.showCancelModal} style={{ marginRight: 15 }}>Cancel Job</div>
              ) : null
            }
            {
              showSave && this.isEdit() && item.is_cancel && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.handleUncancel} style={{ marginRight: 15 }}>Uncancel Job</div>
              ) : null
            }

            {
              showEdit && this.isEdit() && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.handleEditButton}>Edit</div>
              ) : null
            }
            {
              (showSave && (this.hasAccess('updateJob')) && !item.is_cancel) || (!this.isEdit() && this.hasAccess('createJob')) ? (
                <div className='btn' onClick={this.checkBeforeSave}>Save</div>
              ) : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>


          {/* { showClientPrivateAlert && clientInfo.private_alert && showClientPublicAlert && clientInfo.public_alert
            ? <div>
                <Alert message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPublicAlertMsg}<br />${clientPrivateAlertMsg}`
                  }} />
                } type='warning' showIcon /><br />
              </div>
            : showClientPublicAlert && clientInfo.public_alert
              ? <div>
                <Alert message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPublicAlertMsg}`
                  }} />
                } type='warning' showIcon /><br />
              </div>
              : showClientPrivateAlert && clientInfo.private_alert
                ? <div>
                  <Alert message={
                    <div dangerouslySetInnerHTML={{
                      __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPrivateAlertMsg}`
                    }} />
                  } type='warning' showIcon /><br />
                </div>
                : null } */}
          { showClientPrivateAlert || showClientPublicAlert
            ? <div>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                    `${clientPublicAlertMsg ? `${clientPublicAlertMsg}<br />` : ''}` +
                    `${clientPrivateAlertMsg ? `${clientPrivateAlertMsg}<br />` : ''}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null }

          { item.emergency_pay && item.emergency_invoice
            ? <div className='job-remark' style={{ backgroundColor: '#ff526ebb' }}><Icon type='exclamation-circle' /> This is Emergency job</div>
            : item.emergency_pay
              ? <div className='job-remark' style={{ backgroundColor: '#ea3471bb' }}><Icon type='exclamation-circle' /> This is Emergency job with emergency pay</div>
              : item.emergency_invoice
                ? <div className='job-remark' style={{ backgroundColor: '#ff5b5bbb' }}><Icon type='exclamation-circle' /> This is Emergency job with emergency invoice</div>
                : null }

          { item.cancellation_penalty > 0
            ? <div className='job-remark' style={{ backgroundColor: '#b17bcd' }}><Icon type='info-circle' /> This job has Late Cancellation {this.getChargeHours()} hours charge (original {this.getOriginalHours()} hours).</div>
            : null }

          { item.base_job_id > 0
            ? <div className='job-remark' style={{ backgroundColor: '#1890ffbb' }}><Icon type='info-circle' /> This is Recurring job</div>
            : null }

          <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)} >
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.infoTab() }
            </TabPane>

            { this.isEdit() ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2' forceRender>
              <Communication jobId={match.params.id} onUpdate={(value) => this.handleBadgeCount(value)} onUpdateRefresh={() => this.setState({ shouldRefreshComm: false })} shouldRefreshComm={shouldRefreshComm} />
            </TabPane> : null }

            { this.isEdit() ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
              <File jobId={match.params.id} history={this.props.history} onUpdateInfo={() => this.handleFilesUpdateAction()} onRefreshPage={() => this.refreshPage()} />
            </TabPane> : null }

            { this.isEdit() ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='4'>
              { this.logTab() }
            </TabPane> : null }

            { this.isEdit() && isSignoff ? <TabPane tab={<div><Icon type='edit' /> Timesheet </div>} key='5'>
              { this.signOffTab() }
            </TabPane> : null }
          </Tabs>

          {/* --------------------------------------CANCEL CONFIRM MODAL START---------------------------------------- */}
          <Modal
            width={550}
            title='Are you sure you want to cancel this job?'
            visible={showCancelConfirmModal}
            onOk={this.handleLateCancel}
            onCancel={() => this.setState({ showCancelConfirmModal: false })}
            footer={[
              <Button key='1' type='primary' loading={isHandlingLateCancel} onClick={() => this.handleLateCancel(true)}>Late Cancel</Button>,
              <Button key='2' loading={isHandlingCancel} onClick={() => this.handleLateCancel()}>Normal Cancel</Button>
            ]}
          >
            <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ fontSize: 50, color: 'orange', marginRight: '20px' }}>
                <Icon type='question-circle' />
              </div>

              <div>You are cancelling this job within {settingOthers.cancellation_notice} hours of start time making it a Late Cancellation.<br /><br />
            Please confirm if Late Cancellation charges should be applied.<br /><br />
                <div>Late Charge: <b>{this.getChargeHours()} hours</b></div>
              </div>

            </div>
          </Modal>
          {/* --------------------------------------CANCEL CONFIRM MODAL END---------------------------------------- */}

          {/* --------------------------------------EMG CONFIRM MODAL START---------------------------------------- */}
          <Modal
            width={550}
            title='Do you want to change Employee?'
            visible={showEmergencyModal}
            onOk={this.handleEmployeeEmergency}
            onCancel={() => this.handleEmployeeCancel()}
            footer={[
              <Button key='1' type='primary' onClick={() => this.handleEmployeeEmergency(true)}>Emergency</Button>,
              <Button key='2' onClick={() => this.handleEmployeeEmergency()}>Not Emergency</Button>
            ]}
          >
            <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 50, color: 'orange', marginRight: '20px' }}>
                <Icon type='question-circle' />
              </div>
              <div>Changing the employee now will incur emergency charge. Are you sure to proceed?</div>
            </div>
          </Modal>
          {/* --------------------------------------EMG CONFIRM MODAL END---------------------------------------- */}

          {/* --------------------------------------CHANGE FUNDER MODAL START---------------------------------------- */}
          <Modal
            width={600}
            title='Change Funder'
            visible={showFunderModal}
            onOk={this.handleFunderSubmit}
            onCancel={this.handleFunderCancel}
            footer={[
              <Button key='ok' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>,
              <Button key='cancel' onClick={this.handleFunderCancel}>Cancel</Button>
            ]}
          >
            <FormItem {...formItemLayout} label='Funder' hasFeedback>
              {getFieldDecorator('client_funder_id', {
                initialValue: item.client_funder_id
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder='Funders'>
                  {
                    funders.map((funder, idx) => (<Option key={idx} value={funder.id}>
                      {funder.funder_name}
                      <div className='clientId'>{funder.manager} ({formatter.toShortDate(funder.start_date)} - {formatter.toShortDate(funder.end_date)})</div></Option>))
                  }
                </Select>
              )}
            </FormItem>
          </Modal>
          {/* --------------------------------------CHANGE FUNDER MODAL END---------------------------------------- */}

          <SideModal
            title='Cancel Job'
            showModal={showCancelModal}
            onClose={this.hideCancelModal}
            buttons={[
              <Button key='0' type='primary' onClick={this.handleCancelJob}>Cancel Job</Button>
            ]}
          >

            <FormItem label='Cancellation Type'>
              {getFieldDecorator('cancellation_type', {
                rules: [
                  { required: true, message: 'Please select cancellation type' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelTypeChange}>
                  {
                    cancelType.map((items, idx) => {
                      return <Option key={idx} value={items.id}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Cancellation Reason'>
              {getFieldDecorator('cancellation_reason', {
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                  {
                    cancelReason.map((items, idx) => {
                      return <Option key={idx} value={items.name}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            {
              showOtherField

                ? (
                  <FormItem label='Other Reason For Cancellation'>
                    {getFieldDecorator('cancellation_other_reason', {
                    })(
                      <TextArea row={2} />
                    )}
                  </FormItem>
                )

                : null
            }
            <FormItem label='Notes (Optional)'>
              {getFieldDecorator('cancellation_note', {
              })(
                <TextArea rows={2} />
              )}
            </FormItem>

          </SideModal>
          {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

          <SideModal
            title='You Have Changed Employee'
            showModal={showEmployeeReasonModal}
            onClose={() => this.setState({showEmployeeReasonModal: false})}
            buttons={[
              <Button key='0' type='primary' onClick={() => this.handleEmployeeChangeReason()}>Submit</Button>
            ]}
          >
            <FormItem label='Reason Type'>
              {getFieldDecorator('change_employee_reason_type', {
                rules: [
                  { required: true, message: 'Please select reason type' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleEmployeeReasonTypeChange}>
                  {
                    cancelType.map((items, idx) => {
                      return <Option key={idx} value={items.id}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Reason To Change'>
              {getFieldDecorator('change_employee_reason', {
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                  {
                    cancelReason.map((items, idx) => {
                      return <Option key={idx} value={items.name}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            {
              showOtherField

                ? (
                  <FormItem label='Other Reason To Change'>
                    {getFieldDecorator('change_employee_other_reason', {
                    })(
                      <TextArea row={2} />
                    )}
                  </FormItem>
                )

                : null
            }
            <FormItem label='Notes (Optional)'>
              {getFieldDecorator('change_employee_note', {
              })(
                <TextArea rows={2} />
              )}
            </FormItem>

          </SideModal>

          {/* --------------------------------------SHOW SIGNOFF START---------------------------------------- */}
          <Modal
            width={'30vw'}
            title='Signing Signature'
            visible={showSignoffModal}
            onOk={() => this.setState({showSignoffModal: false})}
            onCancel={() => this.setState({showSignoffModal: false})}
            footer={[
              <Button key='ok' type='primary' onClick={() => this.setState({showSignoffModal: false})}>OK</Button>
            ]}
          >
            { item.signoff_image ? <img style={{width: '20vw'}} src={item.signoff_image.file_url} /> : <div>No image available.</div> }
          </Modal>
          {/* --------------------------------------SHOW SIGNOFF END---------------------------------------- */}

        </Page.Content>
      </Page.Body>

    )
  }

  infoTab = () => {
    const { form } = this.props
    const { employees, funders, item, loading, clientInfo, payrolls, isHoliday, holidayInfo = [], billingCategory,
      isRecurring, emergencyField, knownLanguage = [], missingSkill = [], isMatchLanguage, isMatchSkill, isTimeConflict,
      isOnLeave, selectedEmpInfo, clientLanguage, prevEmployee, clientSkill, showSleepoverAlert, employeePending,
      cancelType, cancelReason, showOtherField, clientLeaveInfo, clientIsOnLeave, clientConflictInfo, showClientConflictAlert,
      showCancelModal, showCancelConfirmModal, showEmergencyModal, settingOthers, funderInfo, funderDetail, showFunderAlert, showFunderModal,
      durationBreakdown, conflictJobInfo, isLicenseExpired, isDwesExpired, isPoliceExpired, isFirstAidExpired, isVehicleInsuranceExpired,
      isVehicleRegistrationExpired, isWWCCExpired, showEmployeeReasonModal, isHandlingLateCancel, isHandlingCancel, showEmployeePrivateAlert,
      showEmployeePublicAlert, expiredFiles, categoriesList, subCategoriesList, fileList, fileInfo, showAddFileModal, employeeJobHoursInfo,
      isLastJobSeries } = this.state
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    let itemTasks = item.tasks || ''
    let itemNotes = item.notes || ''

    // Stage 1.5
    // const { getupList, showGetupHours, isSleepover, isOvernightSleepover } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 17 }
    }
    const shortFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }
    const threeeFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 14 },
      wrapperCol: { sm: 14, md: 14, lg: 8 }
    }

    if (selectedEmpInfo && selectedEmpInfo.private_alert) {
      const pAlert = selectedEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.public_alert) {
      const uAlert = selectedEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const fileColumns = [

      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = categoriesList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },

      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subCategoriesList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.name : ''}</div>
        }
      },

      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },

      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },

      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },

      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleDeleteFile(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return <Loading loading={loading} blur>

      <Row gutter={16}>
        <Col lg={12}>
          <Panel>
            { !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col style={{ height: 105 }}>
                  {/* <span className='client-name'>{clientInfo.first_name } { clientInfo.last_name } { clientInfo.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${formatter.toShortDate(clientInfo.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </span> */}
                  <span className='personnel-name'><a href={`/clients/${clientInfo.id}`} rel='noopener noreferrer' target='_blank'>{clientInfo.first_name } { clientInfo.last_name }</a></span>
                  <span className='personnel-accref'>{ clientInfo.acc_ref }</span>
                  { clientInfo.leave_id ? <span style={{ color: '#ff0000', fontSize: '9.5pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(clientInfo.leave_start)} - ${clientInfo.until_further_notice ? 'UFN' : formatter.toShortDate(clientInfo.leave_end)}`}</span> : null }
                  <div className='personnel-details'>
                    { clientInfo.phone ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { clientInfo.phone }</div> : null }
                    <div style={{ fontSize: 12, marginRight: 10 }}>{ clientInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' } {clientInfo.address }</div>
                  </div>
                  <div className='personnel-details' style={{ marginTop: 15 }}>
                    <b>Preference</b>
                    <div style={{ fontSize: 12, marginRight: 10, marginLeft: 10, textTransform: 'capitalize' }}>{ clientInfo.preferred_gender ? <span style={{ color: '#ed6d1e' }}><Icon type='user-add' /></span> : null } { clientInfo.preferred_gender }</div>

                    <div style={{ fontSize: 12, marginRight: 10 }}>{ clientLanguage ? <span style={{ color: '#ed6d1e' }}><Icon type='font-size' /></span> : null } {
                      clientLanguage.map((language, idx) => (
                        <span key={idx} className='preferrence-item'>{language}{idx === clientLanguage.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                  <div className='personnel-sub-details'>
                    <b>Skills</b>
                    <div style={{ fontSize: 12, marginLeft: 10 }}>{ clientSkill ? <Icon type='check-circle' theme='twoTone' twoToneColor='#ed6d1e' /> : null } {
                      clientSkill.map((skill, idx) => (
                        <span key={idx} className='preferrence-item'>{skill}{idx === clientSkill.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                </Col>
              </Row>
            }
          </Panel>
        </Col>
        <Col lg={12}>
          <Panel>
            { !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col lg={24} style={{ height: 105 }}>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        { funderInfo.fullname
                          ? <span className='personnel-name'><a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>{ funderInfo.fullname }</a></span>
                          : <span className='personnel-name' style={{ color: '#ff0000' }}><Icon type='exclamation-circle' /> Invalid Funder. Please select a new one</span>}
                        { funderInfo.acc_ref ? <span className='personnel-accref'>{ funderInfo.acc_ref }</span> : null }
                      </div>
                      <span className='changeFunder'
                        style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                        onClick={this.handleChangeFunder}><Icon type='form' style={{fontSize: '12pt'}} /></span>
                    </div>

                    <div className='personnel-details'>
                      { funderInfo.phone_number ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { funderInfo.phone_number }</div> : null }
                      <div style={{ fontSize: 12 }}>{ funderInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { funderInfo.address }</div>
                    </div>

                    { funderDetail ? <div><div className='personnel-sub-details' style={{ marginTop: 15 }}>
                      <b>Fund Period</b>
                      <div style={{ fontSize: 12, marginLeft: 10 }}>
                        <span style={{ color: '#ed6d1e' }}><Icon type='calendar' /></span> { funderDetail ? Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' - ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') : '' }
                      </div>
                    </div>
                    <div className='personnel-sub-details' style={{ marginTop: 5 }}>
                      <b>Fund Manager</b>
                      <div style={{ fontSize: 12, marginLeft: 10 }}>
                        <span style={{ color: '#ed6d1e' }}><Icon type='user' /></span> { funderDetail && funderDetail.manager !== '' ? funderDetail.manager : '-' }
                      </div>
                    </div></div> : null
                    }
                  </div>
                </Col>
              </Row>
            }
          </Panel>
        </Col>
      </Row>

      <Panel title={`Job Schedule ${this.getScheduleDay()}`} subtitle={<Row>
        <Col lg={24}>
          <div style={{ fontSize: 14 }}>
            <span>{ item.frequency ? <Icon type='clock-circle' /> : null } { durationBreakdown }</span>
            {
              item.week_month === 'months'

                ? <span>, { item.frequency_number ? <Icon type='sync' /> : null } For { item.frequency_number } months ( {item.frequency} weeks)</span>
                : isRecurring
                  ? <span>, { item.frequency ? <Icon type='sync' /> : null } For { item.frequency } weeks</span>
                  : ''
            }
          </div>
        </Col>
      </Row>}>

        <Row>
          {/* Start Alert */}
          <Col lg={24}>
            {
              clientIsOnLeave ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {
              showClientConflictAlert ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Client Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}<a href={`/v1/jobs/single/${clientConflictInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {
              form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date') ? <div className='alert-container'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Job Time Error</span> End Time should not be earlier than Start Time </div>}
                  type='error'
                  banner
                  showIcon
                /></div> : null
            }
            {
              showFunderAlert ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Funder Not Available</span> { funderDetail.funder_name } not available for selected date</div>}
                  type='error'
                  showIcon
                />
              </div> : null
            }
            {
              isHoliday ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {`${holidayInfo.name} (${Moment(holidayInfo.date).format('DD/MM/YYYY')})`}</div>}
                  type='info'
                  showIcon
                />
              </div> : null
            }
            {
              showSleepoverAlert ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                  type='info'
                  showIcon
                />
              </div> : null
            }

          </Col>
          {/* End Alert */}
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Start Time'>
              {getFieldDecorator('job_start_date', {
                initialValue: this.isEdit() && item.job_start_date ? Moment(item.job_start_date) : null,
                rules: [
                  { required: true, message: 'Please enter job start time' }
                ]
              })(
                <DateTimePicker onChange={this.handleStartDateChange} disabled={isLastJobSeries} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='End Time'>
              {getFieldDecorator('job_end_date', {
                initialValue: this.isEdit() && item.job_end_date ? Moment(item.job_end_date) : null,
                rules: [
                  { required: true, message: 'Please enter job end time' }
                ]
              })(
                <DateTimePicker onChange={this.handleEndDateChange} disabled={isLastJobSeries} />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col lg={7}>
            <FormItem {...threeeFormItemLayout} label='Emergency Job'>
              {getFieldDecorator('emergency', {
                initialValue: item.emergency,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={this.handleEmergencyClick}
                />
              )}
            </FormItem>
          </Col>
          <Col lg={16}>
            {
              emergencyField

                ? <div className='additionalEmergency'>
                  <Row>
                    <Col lg={12}>
                      <FormItem {...threeeFormItemLayout} label='Emergency Pay'>
                        {getFieldDecorator('emergency_pay', {
                          initialValue: item.emergency_pay,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            onChange={this.handleEmergencyPayClick}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem {...threeeFormItemLayout} label='Emergency Invoice'>
                        {getFieldDecorator('emergency_invoice', {
                          initialValue: item.emergency_invoice,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            onChange={this.handleEmergencyInvoiceClick}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
                : null
            }
          </Col>
        </Row>
        <Row style={{ display: item.is_cancel ? '' : 'none' }}>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Late Cancellation?'>
              {getFieldDecorator('cancellation_penalty', {
                initialValue: item.cancellation_penalty || false,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={this.handleLateCancellation}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        {/* Stage 1.5
          isSleepover || showSleepoverAlert

            ? <Row>
              <Col lg={16}>
                <FormItem {...longFormItemLayout} label='Overnight'>
                  {getFieldDecorator('overnight_type', {
                    initialValue: item.overnight_type || 'sleepover'
                  })(
                    <RadioGroup buttonStyle='solid' onChange={this.handleOvernightChange}>
                      <RadioButton value='sleepover'>Sleepover (Inactive)</RadioButton>
                      <RadioButton value='nightowl'>Night Owl Shift (Active)</RadioButton>
                    </RadioGroup>
                  )}
                </FormItem>
              </Col>
            </Row>

            : null
                  */}

        {/*  isOvernightSleepover
          ? <div>

            <Row>
              <Col lg={24}>
                <Row className='getup'>
                  <Col offset={4} xs={{ offset: 2 }} lg={18}>
                    <Row>
                      <Col xs={24} lg={3}><div className='getup-label-2'>Custom S/O</div></Col>
                      <Col xs={7} lg={4}>
                        <FormItem>
                          {getFieldDecorator('sleepover_start_hours', {
                            initialValue: item.sleepover_start_hours ? Moment(item.sleepover_start_hours).seconds(0).milliseconds(0) : billRateInfo && billRateInfo.after_hours_start ? Moment(billRateInfo.after_hours_start).seconds(0).milliseconds(0) : Moment()
                          })(
                            <TimePicker use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={2} lg={1}><div className='getup-label-2'>to</div></Col>
                      <Col xs={7} lg={4}>
                        <FormItem>
                          {getFieldDecorator('sleepover_end_hours', {
                            initialValue: item.sleepover_end_hours ? Moment(item.sleepover_end_hours).seconds(0).milliseconds(0) : billRateInfo && billRateInfo.after_hours_end ? Moment(billRateInfo.after_hours_end).seconds(0).milliseconds(0) : Moment()
                          })(
                            <TimePicker use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={10}>
                        <div className='getup-label-2'>Hours outside S/O will be active hours</div>
                      </Col>
                    </Row>
                    {
                      getupList && getupList.map((g, index) => {
                        return <GetUpTask key={index} item={g} index={index + 1} onChange={this.handleGetupChange} onDelete={this.handleGetupDelete} />
                      })
                    }
                    {
                      Moment().isAfter(item.job_end_date)
                        ? <div className='btn' style={{ width: '200px', marginTop: '10px' }} onClick={() => this.handleGetupAdd()}>Add Get Up Hours</div>
                        : null
                    }
                  </Col>
                </Row>
              </Col>
            </Row></div>

          : null  */}

      </Panel>

      <Panel title='Shift Work' type={item.employee_id === null ? 'warn' : ''}>
        <Row gutter={16}>
          {/* Start Alert */}

          <Col lg={24}>
            <div style={{ marginBottom: 10 }}>
              {/* {
                showEmployeePrivateAlert && selectedEmpInfo.private_alert && showEmployeePublicAlert && selectedEmpInfo.public_alert
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div dangerouslySetInnerHTML={{
                          __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePublicAlertMsg}<br />${employeePrivateAlertMsg}`
                        }} />}
                      type='warning'
                      showIcon
                    />
                  </div>
                  : showEmployeePublicAlert && selectedEmpInfo.public_alert
                    ? <div className='alert-info'>
                      <Alert
                        message={
                          <div dangerouslySetInnerHTML={{
                            __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePublicAlertMsg}`
                          }} />}
                        type='warning'
                        showIcon
                      />
                    </div>
                    : showEmployeePrivateAlert && selectedEmpInfo.private_alert
                      ? <div className='alert-info'>
                        <Alert
                          message={
                            <div dangerouslySetInnerHTML={{
                              __html: `<span style="font-weight: bold;">Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</span><br />${employeePrivateAlertMsg}`
                            }} />}
                          type='warning'
                          showIcon
                        />
                      </div>
                      : null
              } */}
              {
                showEmployeePrivateAlert || showEmployeePublicAlert /* || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) */
                  ? <div className='alert-info'>
                    <Alert
                      message={
                        <div>
                          <span style={{ fontWeight: 'bold' }}>{`Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}`}</span>
                          {employeePublicAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                            : null
                          }
                          {employeePrivateAlertMsg.length > 0
                            ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                            : null
                          }
                          {/* {!this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                            ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                            `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week, including this job will be <span style="color: #f5222d; font-weight: bold;">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                            }} />
                            : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                              ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                              `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week, including this job will be <span style="font-weight: bold">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours this week.`
                              }} />
                              : null
                          }
                          {this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                            ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                            `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                            }} />
                            : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                              ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                              `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                              }} />
                              : null
                          } */}
                        </div>
                      }
                      type='warning'
                      showIcon
                    />
                  </div>
                  : null
              }
              {
                isOnLeave ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }

              {/* Employee max hour warning */}
              {
                !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>}
                      type='error'
                      showIcon
                    />
                  </div>
                  : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>}
                      type='info'
                      showIcon
                    />
                  </div> : null
              }
              {
                this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                  ? <div className='alert-info'>
                    <Alert
                      banner
                      message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />}
                      type='error'
                      showIcon
                    />
                  </div>
                  : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                    ? <div className='alert-info'>
                      <Alert
                        banner
                        message={<div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                        }} />}
                        type='info'
                        showIcon
                      />
                    </div>
                    : null
              }

              {
                isTimeConflict ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Schedule Conflict</span> {`${conflictJobInfo.emp_firstname} ${conflictJobInfo.emp_lastname}  has conflict shift with ${conflictJobInfo.first_name} ${conflictJobInfo.last_name}
                      at ${formatter.toShortDate(conflictJobInfo.job_start_date)}, ${formatter.toShortTime(conflictJobInfo.job_start_date)} - ${formatter.toShortTime(conflictJobInfo.job_end_date)}`} <a href={`/v1/jobs/single/${conflictJobInfo.id}`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                !isMatchLanguage ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${knownLanguage},
                      but ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} does not. `} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                !isMatchSkill ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}  does not possess following skill(s): ${missingSkill}`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                validator.isNotEmptyArray(expiredFiles)
                  ? (expiredFiles.map((file, idx) => {
                    return (
                      <div key={`expired file ${idx}`} className='alert-info'>
                        <Alert
                          message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                          type='error'
                          banner
                          showIcon
                        />
                      </div>
                    )
                  }))
                  : null
              }
            </div>
          </Col>
          {/* End Alert */}
        </Row>
        <Form layout='vertical'>
          <Row gutter={16}>
            <Col lg={8} style={{ marginTop: '8.5px' }}>
              <FormItem label='Shift Type'>
                {getFieldDecorator('billing_category_id', {
                  initialValue: item.billing_category_id,
                  rules: [
                    { required: true, message: 'Please Select Billing Rate' }
                  ]
                })(
                  <Select onSelect={(v, o) => this.onSelectBillingRate(o)}>
                    {
                      billingCategory && billingCategory.map((bill, idx) => {
                        return <Option key={idx} value={bill.id}>{bill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8} style={{ marginTop: '8.5px' }}>
              <FormItem label='Carer Skill Level Required'>
                {getFieldDecorator('payroll', this.isEdit() ? {
                  initialValue: item.payroll,
                  rules: [
                    { required: true, message: 'Please Select Payroll Category' }
                  ]
                }
                  : {
                    initialValue: clientInfo.payroll_category,
                    rules: [
                      { required: true, message: 'Please Select Payroll Category' }
                    ]
                  })(
                    <Select onChange={this.handleChangePayroll}>
                    {
                      payrolls.map((pay) => {
                        return <Option key={pay.value}>{pay.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label={
                <span>Employee <Switch
                  checkedChildren='Pending'
                  unCheckedChildren='Pending'
                  style={{ marginLeft: 10 }}
                  checked={employeePending}
                  onChange={this.handlePendingEmployee}
                /></span>
              } hasFeedback>
                {getFieldDecorator('employee_id', {
                  initialValue: item.employee_id,
                  rules: [
                    !employeePending ? { required: true, message: 'Please select an employee OR turn on Pending' } : {}
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Employee'
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    filterOption={(input, option) => this.findEmployees(input, option)}
                    onChange={this.handleEmployeeChange} disabled={employeePending}>
                    {
                      employees.map((items, idx) => {
                        return <Option key={idx} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <span style={{ color: '#ff0000', fontSize: '9pt', marginLeft: '4px' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start)} - ${formatter.toShortDate(items.leave_end)}`}</span> : null }</Option>
                      })
                    }

                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>
            <Col lg={8}>
              <FormItem label='Tasks' hasFeedback>
                {getFieldDecorator('tasks', {
                  initialValue: this.replacer(itemTasks),
                  rules: [
                    { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter tasks' }
                  ]
                })(
                  <TextArea row={4} />
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Notes'>
                {getFieldDecorator('notes', {
                  initialValue: this.replacer(itemNotes),
                })(
                  <TextArea row={4} />
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Max KM'>
                {getFieldDecorator('job_kms', {
                  initialValue: item.job_kms,
                  rules: [
                    { validator: this.checkKMSInput }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={8}>
              <FormItem label='Recorded KM'>
                {getFieldDecorator('kms', {
                  initialValue: item.kms,
                  rules: [
                    { validator: this.checkKMSInput }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          {/* Stage 1.5
            showGetupHours
              ? <Row className='subsection'><Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Get Up'>
                  {getFieldDecorator('get_up_hours', {
                    initialValue: item.get_up_hours || 0,
                    rules: [
                      { required: true, message: 'Please Enter Get Up Hours' }
                    ]
                  })(
                    <Input suffix='hours' />
                  )}
                </FormItem>
              </Col><Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Billing Rate For Get Up'>
                  {getFieldDecorator('get_up_billing_category_id', {
                    initialValue: item.get_up_billing_category_id,
                    rules: [
                      { required: true, message: 'Please Select Billing Rate ' }
                    ]
                  })(
                    <Select>
                      {
                        billingCategory && billingCategory.map((bill, idx) => {
                          return <Option key={idx} value={bill.id}>{bill.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col></Row> : null
          */}
        </Form>
      </Panel>

      { prevEmployee.length > 0
        ? <Panel title='Previous Carers'>
          <Row>
            <Col lg={24}>
              {
                prevEmployee.map((employee, index) => (
                  // <Link key={index} to={`/employees/${employee.employee_id}`} key={employee.employee_id}>
                  //   <span className='employeeList' style={{ color: '#242b49' }}><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</span>
                  // </Link>
                  <span className='employeeList' style={{ color: '#242b49' }}><a href={`/employees/${employee.employee_id}`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.emp_first_name} {employee.emp_last_name}</a></span>
                ))
              }
            </Col>
          </Row>
        </Panel> : null}

      { !this.isEdit()
        ? <Panel
            title='Files'
            subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
          >
            <List cols={fileColumns} rows={fileList} />
        </Panel>
        : null }

      { /** File Modal */}
      <AddFileModal
        jobId={'add'}
        key={`addfilemodaljob_new`}
        item={fileInfo}
        categoriesList={categoriesList}
        subCategoriesList={subCategoriesList}
        onClose={() => this.handleAddFileModal(false)}
        onSetFile={(values) => this.updateFileAdded(values)}
        visible={showAddFileModal}
      />

    </Loading>
  }

  logTab = () => {
    const { loading, logList } = this.state
    const columns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at: createdAt }) => Moment(createdAt).format('DD/MM/YYYY hh:mm A')
      },
      {
        title: 'Updated By',
        width: 4,
        render: ({ updated_by: updatedBy }) => <div style={{ textTransform: 'capitalize' }}>{updatedBy}</div>
      },
      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div style={{ textTransform: 'uppercase' }}>{action}</div>
      },
      {
        title: 'Changes',
        width: 12,
        key: 'changes'
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={logList} />
          </Skeleton>
        </div>
      </Loading>
    )
  }

  // -------------------------------------------------
  // ------------------------------------------------
  signOffTab = () => {
    const { item = {}, loading, clientInfo = {} } = this.state
    const {
      signoff_image: signoffImage = null,
      signed_time: signedTime,
      signed_submit_time: submitTime,
      is_signed_late: isSignedLate,
      is_outside_loc: isOutsideLoc,
      is_outside_acc: isOutsideAcc,
      signed_accuracy: accuracy,
      signed_distance: distance,
      signed_location: location,
      signed_client_location: clientLocation,
      job_start_date: jsd,
      job_end_date: jed,
      signed_job_start_date: sjsd,
      signed_job_end_date: sjed,
      job_end_time_diff: endTimeDiff,
      kms,
      job_kms: jobKms
    } = item

    const cLatitude = clientLocation && clientLocation.latitude ? clientLocation.latitude : clientInfo && clientInfo.latitude ? clientInfo.latitude : null
    const cLongitude = clientLocation && clientLocation.longitude ? clientLocation.longitude : clientInfo && clientInfo.longitude ? clientInfo.longitude : null

    const jobStartDate = sjsd === null ? jsd : sjsd
    const jobEndDate = sjed === null ? jed :sjed
    const afterText = 'After Job End Date'
    const beforeText = 'Before Job End Date'
    const diffMinSignedTime = endTimeDiff === null ? formatter.updateJobTimeDiff(jobEndDate, signedTime) : endTimeDiff
    const diffMinSignedTimeText = formatter.toTimeCountText(diffMinSignedTime, afterText, beforeText, (jobEndDate > signedTime))

    const signedAfterText = 'After Signing Time'
    const signedBeforeText = 'Before Signing Time'
    const diffSignedMin = formatter.updateJobTimeDiff(signedTime, submitTime)
    const diffSignedMinText = formatter.toTimeCountText(diffSignedMin, signedAfterText, signedBeforeText, (signedTime > submitTime))

    const clientCoords = {latitude: parseFloat(cLatitude), longitude: parseFloat(cLongitude)}
    const signoffCoords = location && location.latitude && location.longitude ? {latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude)} : null

    const jobKmsText = (jobKms === null || jobKms === '' || jobKms === undefined) ? 'No Max KM' : (!parseFloat(jobKms)) ? 'KMS Not Allowed' : `${jobKms} KM Allowed`
    const isOutsiteKMS = !(jobKms === null || jobKms === '' || jobKms === undefined) && ((!parseFloat(jobKms) && parseFloat(kms) > 0) || (parseFloat(jobKms) > 0 && parseFloat(kms) > 0 && parseFloat(jobKms) < parseFloat(kms)))

    const { zoom } = formatter.updateMapDetails(clientCoords, signoffCoords, parseInt(accuracy))

    return (
      <Loading loading={loading} blur>
        <div style={{ display: 'flex' }}>
          { signoffImage ? <img style={{width: '15vw', border: '1px solid #ccc', borderRadius: '15px'}} src={signoffImage.file_url} /> : <div>No image available.</div> }

          <div style={{ width: '55vw', marginLeft: '20px' }}>
            <Panel title={`Timesheet Details`}>
              <Row gutter={16}>
                <div className='personnel-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Recorded KM (Max KM)</b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      <span>
                        {`${kms}`}{` (${jobKmsText})`}
                      </span>
                      { isOutsiteKMS
                        ? <span className='personnel-indicator'>Outside KMS</span>
                        : null }
                    </div>
                  </Col>
                </div>

                <div className='personnel-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Job Date Time </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>{
                      <span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toDurationText(jobStartDate, jobEndDate)}`}
                    </div>
                  </Col>
                </div>

                <div className='personnel-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Signed on </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      {<span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toStandardLongDate(signedTime)}`}
                      <strong>{` (${diffMinSignedTimeText})`}</strong>
                      { isSignedLate
                        ? <span className='personnel-indicator'>Outside Endtime</span>
                        : null }
                    </div>
                  </Col>
                </div>

                <div className='personnel-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Received on </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      { <span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toStandardLongDate(submitTime)}` }
                      <strong>{` (${diffSignedMinText})`}</strong>
                    </div>
                  </Col>
                </div>

                {/* signoffImage
                  ? <div className='personnel-details' style={{ marginTop: 3 }}>
                    <Col lg={6}>
                      <b>Signing Signature</b>
                    </Col>
                    <Col lg={17}>
                      <span className='personnel-button' onClick={() => this.setState({showSignoffModal: true})}>View</span>
                    </Col>
                  </div>
                : null */}

                <div className='personnel-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Signing Location</b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12, }}>
                      <strong>
                        {`${formatter.toDistance(distance)} from client's location (accuracy ${formatter.toDistance(accuracy)})`}
                      </strong>
                      { isOutsideLoc || isOutsideAcc
                        ? <span className='personnel-indicator'>Outside Location</span>
                        : null }
                    </div>
                  </Col>
                </div>
              </Row>
            </Panel>

            <SignoffMap
              isMarkerShown
              zoom={zoom}
              radius={accuracy || 1}
              clientCoords={{latitude: parseFloat(cLatitude), longitude: parseFloat(cLongitude)}}
              signoffCoords={location && location.latitude && location.longitude ? {latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude)} : null}
            />
          </div>
        </div>


      </Loading>
    )

  }

  /*********************************************************************************
    Keep all refactored/clean codes below here
    Please arrange by alphabet A-Z
  *********************************************************************************/

  // 1. Fetching data ////////////////////////////////////////////////////////////////////////////////////

  fetchAllEmployees = async (clientId, employeeId) => {
    try {
      const employeeActive = await clientService.getClientEmployees(clientId)
      this.setState({ employees: employeeActive })

      setTimeout(() => {
        this.cacheEmployeeName(employeeId)
      }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchBillingRate = async (funderId) => {
    const funderInfo = await funderService.get(funderId)
    const funderInfoRes = funderInfo.item
    const funderRateSet = funderInfoRes ? funderInfoRes.rate_set : null
    const billRateInfo = await settingBillingRateService.get(funderRateSet)
    const billRateCategory = billRateInfo.categories

    this.setState({ billingCategory: billRateCategory, billingRateId: funderRateSet, billRateInfo })

    setTimeout(() => {
      this.cacaheBillingCategory()
      this.shouldShowGetupHours()
    }, 500)
  }

  fetchCancel = async () => {
    const cancelType = await settingCancellationService.getAll()
    this.setState({ cancelType: cancelType.list })
  }

  getCancelTypeName = (id) => {
    const { cancelType } = this.state
    const type = cancelType.filter((item) => item.id === id)
    return type ? type[0].name : ''
  }

  fetchClient = async (clientId, empId) => {
    try {
      const clientInfoRes = await clientService.get(clientId)
      const clientInfo = clientInfoRes.item
      const clientLanguages = clientInfo.languages
      const clientSkills = clientInfo.skills
      const clientHasPrivateAlert = clientInfo.private_alert !== null && !validator.isEmptyString(clientInfo.private_alert)
      const clientHasPublicAlert = clientInfo.public_alert !== null && !validator.isEmptyString(clientInfo.public_alert)

      // Get skills info
      if (clientSkills && clientSkills.length > 0 && clientSkills[0] !== null) {
        let preferredSkill = []
        const skills = await Promise.all(clientSkills.map(item => settingGeneralService.get(item)))

        for (var j = 0; j < skills.length; j++) {
          const item = skills[j].item.name
          preferredSkill.push(item)
        }

        this.setState({ clientSkill: preferredSkill })
      }

      // Get languages info
      if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
        let preferredLanguage = []
        const languages = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))

        for (var i = 0; i < languages.length; i++) {
          const item = languages[i].item.name
          preferredLanguage.push(item)
        }

        this.setState({ clientLanguage: preferredLanguage })
      }

      this.setState({ clientInfo, showClientPrivateAlert: clientHasPrivateAlert, showClientPublicAlert: clientHasPublicAlert })

      setTimeout(() => {
        this.checkClient(clientId)
      }, 1000)

      if (empId) {
        setTimeout(() => {
          this.checkEmployee(empId)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later')
    }
  }

  fetchEmployees = async (empId) => {
    try {
      const employeeInfoRes = await employeeService.get(empId)
      const employees = await employeeService.listByPage(1, 0)
      this.setState({ employeeInfo: employeeInfoRes.item, employeeId: employeeInfoRes.item.id }) //employees: employees.list,

      setTimeout(() => {
        this.checkEmployee(empId)
      }, 1500)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId, funderSettingId) => {
    const funders = await clientService.getClientFunders(clientId)
    const clientFunderSetting = await funderService.getClientFunderSetting(funderSettingId)
    const funderId = clientFunderSetting ? clientFunderSetting.funder_id : null

    if (funderId) {
      const funderInfo = await funderService.get(funderId)
      this.setState({
        funderInfo: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
        currentFunder: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
      })
    }

    this.setState({ funderId, funders, funderDetail: clientFunderSetting, clientFunderId: funderSettingId })

    const fundClient = await clientService.getClientFundersInfo(clientId, funderId)

    if (fundClient.item && fundClient.item.end_date === null) {
      this.setState({ notAssignedTime: true })
      this.alertUnassignedTime()
    } else {
      this.setState({ notAssignedTime: false })
    }

    const startDate = this.props.form.getFieldValue('job_start_date')
    this.checkFunderAvailability(startDate)
    this.fetchBillingRate(funderId)
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        categoriesList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subCategoriesList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loading: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  fetchJob = async () => {
    try {
      this.setState({ loading: true })

      const jobId = this.getJobId()
      const { item } = await jobService.get(jobId)

      if (!item.job_start_date) {
        item.job_start_date = Moment().startOf('hour')
      }

      if (!item.job_end_date) {
        item.job_end_date = Moment(item.job_start_date).startOf('hour').add(1, 'hour')
      }

      if (item.signed_location) {
        try {
          const loc = JSON.parse(item.signed_location)
          item.signed_location = loc
        } catch (e) {
          item.signed_location = ''
        }
      }

      if (item.signed_client_location) {
        try {
          const loc = JSON.parse(item.signed_client_location)
          item.signed_client_location = loc
        } catch (e) {
          item.signed_client_location = ''
        }
      }

      const isEmergency = item.emergency
      const isOvernightSleepover = (item.overnight_type === 'sleepover')
      const isSleepover = isOvernightSleepover
      const isSignoff = !!item.signed_timesheet_id
      const isLastJobSeries = item.is_last_job_series

      this.setState({
        item,
        loading: false,
        billingRateId: item.billing_rate_id,
        emergencyField: isEmergency,
        isSleepover,
        isOvernightSleepover,
        getupList: item.get_up,
        currentTotalHours: item.job_hours,
        clientId: item.client_id,
        isSignoff,
        isLastJobSeries
      })

      if (item.pending_employee) {
        this.setState({ employeePending: true })
      }

      this.setState({ loading: false })

      return item
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load job successfully. Please try again later.')
    }

    return null
  }

  fetchUnsentEmail = async (jobId) => {
    const { unsentTotal } = await jobService.getUnsentEmailTotal(jobId)
    this.setState({ unsentCommTotal: unsentTotal })
  }

  fetchLogs = async () => {
    const jobId = this.getJobId()

    const filter = {
      type: 'job',
      type_id: jobId
    }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchPreviousEmployee = async (clientId) => {
    try {
      const employeeList = await clientService.getClientPrevEmployeesActive(clientId)
      this.setState({ prevEmployee: employeeList })
    } catch (e) {
      notify.error('Unable to load successfully, Unable to load previous employee list successfully. Please try again later')
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)

    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: settings.list.filter(item => item.type === 'language'),
      skills: settings.list.filter(item => item.type === 'skill'),
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding'),
      settingOthers: settingOthers.item
    })
  }

  findEmployees = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  // 2. Logic ///////////////////////////////////////////////////////////////////////////////////////////

  checkBeforeSave = () => {
    const { isDwesExpired, isPoliceExpired, isLicenseExpired, isFirstAidExpired, isVehicleInsuranceExpired, isVehicleRegistrationExpired, isWWCCExpired, isCertificateExpired,
      showFunderAlert, clientIsOnLeave, showClientConflictAlert, isOnLeave, isTimeConflict, isMatchSkill, missingSkill, clientInfo, selectedEmpInfo,
      clientLeaveInfo, clientConflictInfo, conflictJobInfo, funderDetail, showClientPrivateAlert, showEmployeePrivateAlert, showClientPublicAlert, showEmployeePublicAlert,
      expiredFiles, employeeJobHoursInfo } = this.state
    let clientPrivateAlertMsg = ''
    let clientPublicAlertMsg = ''
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    const isFileExpired = validator.isNotEmptyArray(expiredFiles)


    const { form } = this.props
    const { handleSave } = this

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (clientInfo && clientInfo.public_alert) {
      const uAlert = clientInfo.public_alert
      clientPublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.private_alert) {
      const pAlert = selectedEmpInfo.private_alert
      employeePrivateAlertMsg = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (selectedEmpInfo && selectedEmpInfo.public_alert) {
      const uAlert = selectedEmpInfo.public_alert
      employeePublicAlertMsg = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    if (form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date')) {
      this.showDateNotification()
      return
    }


    if (showClientPrivateAlert || showEmployeePrivateAlert || showClientPublicAlert || showEmployeePublicAlert ||
      isFileExpired || showFunderAlert || clientIsOnLeave || showClientConflictAlert ||
      isOnLeave || isTimeConflict || !isMatchSkill) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            {/* Private Alert */}

            {
              showClientPrivateAlert && showClientPublicAlert
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{
                      __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}<br />${clientPrivateAlertMsg}`
                    }} />
                  </p>
                </div>
                : showClientPublicAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPublicAlertMsg}`
                      }} />
                    </p>
                  </div>
                  : showClientPrivateAlert
                    ? <div>
                      <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                        <span dangerouslySetInnerHTML={{
                          __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPrivateAlertMsg}`
                        }} />
                      </p>
                    </div>
                    : null
            }
            {
              // showEmployeePrivateAlert && showEmployeePublicAlert
              //   ? <div>
              //     <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //       <span dangerouslySetInnerHTML={{
              //         __html: ` <strong>Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</strong><br />${employeePublicAlertMsg}<br />${employeePrivateAlertMsg}`
              //       }} />
              //     </p>
              //   </div>
              //   : showEmployeePublicAlert
              //     ? <div>
              //       <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //         <span dangerouslySetInnerHTML={{
              //           __html: ` <strong>Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</strong><br />${employeePublicAlertMsg}`
              //         }} />
              //       </p>
              //     </div>
              //     : showEmployeePrivateAlert
              //       ? <div>
              //         <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              //           <span dangerouslySetInnerHTML={{
              //             __html: ` <strong>Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}</strong><br />${employeePrivateAlertMsg}`
              //           }} />
              //         </p>
              //       </div>
              //       : null
            }
            {
              showEmployeePrivateAlert || showEmployeePublicAlert /* || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) */
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}>{` Employee: ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}`}</span>
                    {employeePublicAlertMsg.length > 0
                      ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlertMsg }} />
                      : null
                    }
                    {employeePrivateAlertMsg.length > 0
                      ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlertMsg }} />
                      : null
                    }
                    {/* {!this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                      ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week, including this job will be <span style="color: #f5222d; font-weight: bold;">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />
                      : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                        ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week, including this job will be <span style="font-weight: bold">${formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span> total job hours this week.`
                        }} />
                        : null
                    }
                    {this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                      ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                      }} />
                      : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                        ? <div dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;">Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                        }} />
                        : null
                    } */}
                  </p>
                </div>
                : null
            }

            {/* certificate */}

            {
              isFileExpired ? (
                <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Expired Files:</strong></p>
                  <li>
                    <ul>{expiredFiles.map((file, idx) => { return (<li key={`expired file warnings ${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                  </li>
                  <br />
                </div>
              ) : null
            }

            {/* Funder */}

            {
              showFunderAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Fund Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>
                <br /></div> : null
            }

            {/* Employee Max Hour Warning */}

            {// Create job max hour warning
              !this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour && employeeJobHoursInfo.isEmpOverHour
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                    <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                    <div> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has `}{employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs}</span> : <span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>}{` total job hours this week, including this job will be `}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours, EXCEEDING`}<span style={{ color: '#f5222d', fontWeight: 'bold' }}>{` max ${employeeJobHoursInfo.weekly_max_hrs} hours`}</span>.</div>
                  </p>
                </div>
                : (!this.isEdit() && employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                      <span style={{ fontWeight: 'bold' }}> Max Hours </span>
                      <div>{`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has `}<span style={{ fontWeight: 'bold' }}>{employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>{` total job hours this week, including this job will be `}<span style={{ fontWeight: 'bold' }}>{formatter.toDecimalS(employeeJobHoursInfo.emp_total_job_hrs + employeeJobHoursInfo.new_job_hrs)}</span>{` total job hours this week.`}</div>
                    </p>
                  </div>
                  : null
            }

            {// Edit job max hour warning
              this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                    <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                      `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} already has ${employeeJobHoursInfo.emp_total_job_hrs && employeeJobHoursInfo.emp_total_job_hrs >= employeeJobHoursInfo.weekly_max_hrs ? `<span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs}</span>` : `<span style="font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span>`} total job hours this week including this job, EXCEEDING<span style="color: #f5222d; font-weight: bold;"> max ${employeeJobHoursInfo.weekly_max_hrs} hours</span>.`
                    }} />
                  </p>
                </div>
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#1890ff', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{ __html: `<span style="font-weight: bold;"> Max Hours </span>` +
                        `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} (max ${employeeJobHoursInfo.weekly_max_hrs} hours) already has <span style="font-weight: bold">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0}</span> total job hours this week including this job.`
                      }} />
                    </p>
                  </div>
                  : null
            }

            {/* Client On Leave */}

            {
              clientIsOnLeave ? <div>
                <div><p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Client Shift Conflict */}

            {
              showClientConflictAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client Has Another Shift</strong></p>
                <p> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}</p>
                <br />
              </div> : null
            }

            {/* Employee On Leave */}

            {
              isOnLeave ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} is on leave from {Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Employee Shift Conflict */}

            {
              isTimeConflict ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee Has Another Shift</strong></p>
                <p> {selectedEmpInfo.first_name} {selectedEmpInfo.last_name} has shift on {Moment(conflictJobInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(conflictJobInfo.job_start_date))} to {formatter.toShortTime(Moment(conflictJobInfo.job_end_date))}</p>
                <br />
              </div> : null
            }

            {/* Skill Mismatch */}

            {
              isMatchSkill ? null
                : <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess following skill(s):</strong></p>
                  <ul>
                    {
                      missingSkill.map((skills, index) => (
                        <li key={index}>{skills}</li>
                      ))
                    }
                  </ul>
                  <br />
                </div>
            }

            <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          handleSave()
        },
        onCancel () {
        }
      })
    } else {
      handleSave()
    }
  }

  checkEmail = () => {
    const that = this
    const { unsentCommTotal } = this.state

    if (unsentCommTotal > 0) {
      that.setState({ shouldRefreshComm: true })
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.setState({ tabActiveKey: '2' })
        },
        onCancel () {

        }
      })
    } else {}
  }

  checkClient = (clientId, start, end) => {
    const { form } = this.props

    const jobStartDateTime = start || Moment(form.getFieldValue('job_start_date'))
    const jobEndDateTime = end || Moment(form.getFieldValue('job_end_date'))

    if (jobStartDateTime.isValid() && jobEndDateTime.isValid()) {
      // 1. Check if client is onleave
      this.checkClientLeave(clientId, jobStartDateTime, jobEndDateTime)

      // 2. Check if it has client conflict shift
      this.checkClientJobConflict(clientId, jobStartDateTime, jobEndDateTime)

      // 3. Check if it is public holiday
      this.checkHoliday(jobStartDateTime)

      // 4. Check if it is sleepover
      this.checkSleepover(jobStartDateTime, jobEndDateTime)

      // if (!this.isEdit()) {
      //   setTimeout(() => {
      //     this.showPreWarning()
      //   }, 2000)
      // }
    }
  }

  // Refactor: Get client conflict jobs
  checkClientJobConflict = async (client, start, end) => {
    const jobId = this.getJobId()
    const conflictJobs = await jobService.getClientConflictJobs(jobId, client, start.format(), end.format())

    if (conflictJobs.items.length > 0) {
      this.setState({ showClientConflictAlert: true, clientConflictInfo: conflictJobs.items[0] })
      // this.showClientConflictConfirm()
    } else {
      this.setState({ showClientConflictAlert: false })
    }

    // Further check for employee's conflict job if employee is set
    const { form } = this.props
    const employeeId = form.getFieldValue('employee_id')

    if (employeeId) {
      this.checkEmployeeJobConflict(employeeId, start, end)
    }
  }

  checkClientLeave = async (clientId, start, end) => {
    const { item: allLeaves } = await clientLeaveService.getByStartEnd(clientId, start.format(), end.format())

    if (allLeaves.length > 0) {
      this.setState({ clientIsOnLeave: true, clientLeaveInfo: allLeaves[0] })
      // this.showClientLeaveConfirm()
    } else {
      this.setState({ clientIsOnLeave: false })
    }
  }

  checkEmployeeWeeklyMaxHour = async (employeeId, start, end) => {
    if (end > start) {
      let body = {}
      const startingWeek = Moment(start).startOf('week')
      const endingWeek = Moment(start).endOf('week')
      const s = Moment(startingWeek).startOf('day')
      const e = Moment(endingWeek).endOf('day')
      const durationFromNewJob = Moment.duration(Math.abs(start.diff(end)))
      const differentHours = durationFromNewJob.asHours()
      const jobId = this.getJobId()
      // console.log('sss', s.format())
      // console.log('eee', e.format())
      // console.log('differentHours', differentHours)
      body.empId = employeeId
      body.start = s
      body.end = e
      body.newJobHours = differentHours
      body.jobId = jobId === 'add' ? null : jobId
      const r = await jobService.getEmployeeTotalMaxHour(body)

      if (r && r.isEmpHasMaxHour !== undefined) {
        this.setState({ employeeJobHoursInfo: r })
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    } else {
      this.setState({ employeeJobHoursInfo: {} })
    }
  }

  // Refactor:  Get employee conflict jobs
  checkEmployeeJobConflict = async (employee, start, end) => {
    const jobId = this.getJobId()
    const conflictJobs = await jobService.getEmployeeConflictJobs(jobId, employee, start.format(), end.format())

    if (conflictJobs.items.length > 0) {
      this.setState({ isTimeConflict: true, conflictJobInfo: conflictJobs.items[0] })
      // this.showEmployeeConflictConfirm()
    } else {
      this.setState({ isTimeConflict: false })
    }
  }

  checkEmployeeCertificate = async (employee, jobStartDate, jobEndDate) => {
    let { isLicenseExpired: license, isDwesExpired: dwes, isFirstAidExpired: firstAid, isPoliceExpired: police,
      isVehicleRegistrationExpired: vehicleRegistration, isVehicleInsuranceExpired: vehicleInsurance, isWWCCExpired: wwcc } = this.state


    const { item: employeeInfo } = await employeeService.get(employee)
    const { driver_license_expiry: licenseExpiry, dwes_expiry: dwesExpiry, first_aid_expiry: firstAidExpiry, police_expire_date: policeCheck,
      vehicle_registration_expiry: vRegistrationExpiry, vehicle_insurance_expiry: vInsuranceExpiry, work_with_child_expiry: wwccExpiry } = employeeInfo

    // 1. Driver Licence

    if (jobStartDate.isAfter(licenseExpiry) || jobEndDate.isAfter(licenseExpiry)) {
      license = true
    } else {
      license = false
    }

    // 2. DWES

    if (jobStartDate.isAfter(dwesExpiry) || jobEndDate.isAfter(dwesExpiry)) {
      dwes = true
    } else {
      dwes = false
    }

    // 3. First Aid

    if (jobStartDate.isAfter(firstAidExpiry) || jobEndDate.isAfter(firstAidExpiry)) {
      firstAid = true
    } else {
      firstAid = false
    }

    // 4. Police Check

    if (jobStartDate.isAfter(policeCheck) || jobEndDate.isAfter(policeCheck)) {
      police = true
    } else {
      police = false
    }

    // 5. Vehicle Registration

    if (jobStartDate.isAfter(vRegistrationExpiry) || jobEndDate.isAfter(vRegistrationExpiry)) {
      vehicleRegistration = true
    } else {
      vehicleRegistration = false
    }

    // 6. Vechicle Insurance

    if (jobStartDate.isAfter(vInsuranceExpiry) || jobEndDate.isAfter(vInsuranceExpiry)) {
      vehicleInsurance = true
    } else {
      vehicleInsurance = false
    }

    // 7. WWCC

    if (jobStartDate.isAfter(wwccExpiry) || jobEndDate.isAfter(wwccExpiry)) {
      wwcc = true
    } else {
      wwcc = false
    }

    this.setState({
      isLicenseExpired: license,
      isDwesExpired: dwes,
      isFirstAidExpired: firstAid,
      isPoliceExpired: police,
      isVehicleRegistrationExpired: vehicleRegistration,
      isVehicleInsuranceExpired: vehicleInsurance,
      isWWCCExpired: wwcc,
      isCertificateExpired: license || dwes || firstAid || police || vehicleRegistration || vehicleInsurance || wwcc
    })

    // // 5. Trigger alert

    // if (jobStartDate.isAfter(licenseExpiry) || jobEndDate.isAfter(licenseExpiry) || jobStartDate.isAfter(dwesExpiry) || jobEndDate.isAfter(dwesExpiry) ||
    //     jobStartDate.isAfter(firstAidExpiry) || jobEndDate.isAfter(firstAidExpiry) || jobStartDate.isAfter(policeCheck) || jobEndDate.isAfter(policeCheck)) {
    //   this.showEmployeeCertConfirm(employee)
    // }
  }

  checkEmployeeFilesExpiry = async (empId, jobStartDate, jobEndDate) => {
    const filter = {}
    const expiredFiles = []

    filter.has_expiry = { condition: '=', value: 'true' }
    filter.active = { condition: '=', value: 'true' }
    filter.module = { condition: '=', value: 'employee' }
    filter.module_id = { condition: '=', value: empId }

    const { list: files } = await employeeFileService.listByPage(1, 0, filter)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.expiry_date) {
        if (jobStartDate.isAfter(file.expiry_date) || jobEndDate.isAfter(file.expiry_date)) {
          expiredFiles.push(file)
        }
      }
    }

    this.setState({ expiredFiles })
  }

  checkEmergencyJob = async (date) => {
    const { settingOthers } = this.state
    const emergencyDuration = settingOthers.emergency_notice
    const emergencyEmpDuration = settingOthers.emergency_notice_emp
    const durationFromNow = Moment.duration(Math.abs(date.diff(Moment())))
    const differentHours = durationFromNow.asHours()
    const isPastDate = Moment().isAfter(date)

    if ((differentHours >= 0 && differentHours <= emergencyDuration) || isPastDate) {
      this.setState({ isEmergencyTime: true })

      if (emergencyEmpDuration) {
        this.showEmergencyConfirm({
          emgPay: differentHours >= 0 && differentHours <= emergencyEmpDuration,
          emgInv: differentHours >= 0 && differentHours <= emergencyDuration,
          emgHours: emergencyDuration
        })
      } else {
        this.showEmergencyConfirm({
          emgPay: true,
          emgInv: true,
          emgHours: emergencyDuration
        })
      }
    } else {
      this.setState({ isEmergencyTime: false })
    }
  }

  checkEmployee = async (empId, changeEmployee = false) => {
    const { clientInfo } = this.state
    const { form } = this.props
    const formStartDate = Moment(form.getFieldValue('job_start_date'))
    const formEndDate = Moment(form.getFieldValue('job_end_date'))
    let employeeJobHoursInfo = {}

    if (typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null) && (Moment(formEndDate).isValid() && formEndDate !== null)) {
      if (formEndDate > formStartDate) {
        let body = {}
        const startingWeek = Moment(formStartDate).startOf('week')
        const endingWeek = Moment(formStartDate).endOf('week')
        const s = Moment(startingWeek).startOf('day')
        const e = Moment(endingWeek).endOf('day')
        const durationFromNewJob = Moment.duration(Math.abs(formStartDate.diff(formEndDate)))
        const differentHours = durationFromNewJob.asHours()
        const jobId = this.getJobId()
        // console.log('sss', s.format())
        // console.log('eee', e.format())
        // console.log('differentHours', differentHours)
        body.empId = empId
        body.start = s
        body.end = e
        body.newJobHours = differentHours
        body.jobId = jobId === 'add' ? null : jobId
        const r = await jobService.getEmployeeTotalMaxHour(body)

        if (r && r.isEmpHasMaxHour !== undefined) {
          employeeJobHoursInfo = r
          this.setState({ employeeJobHoursInfo: r })
        } else {
          this.setState({ employeeJobHoursInfo: {} })
        }
      } else {
        this.setState({ employeeJobHoursInfo: {} })
      }
    }

    const employeeInfoRes = await employeeService.get(empId)
    const employeeInfo = employeeInfoRes.item
    const employeeHasPrivateAlert = employeeInfo.private_alert !== null && !validator.isEmptyString(employeeInfo.private_alert)
    const employeeHasPublicAlert = employeeInfo.public_alert !== null && !validator.isEmptyString(employeeInfo.public_alert)
    const clientLanguages = clientInfo.languages
    const clientSkills = clientInfo.skills

    setTimeout(() => {
      // Show popup alert if change new employee & employee have private alert or public alert
      if ((changeEmployee && employeeHasPrivateAlert) || (changeEmployee && employeeHasPublicAlert) || (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)) {
        const pAlert = employeeInfo.private_alert || ''
        const uAlert = employeeInfo.public_alert || ''
        const privateAlert = pAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        const publicAlert = uAlert.replace(/(?:\r\n|\r|\n)/g, '<br />')
        warning({
          title: `Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`,
          content: (
            <div>
              { publicAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null }
              { privateAlert.length > 0
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null }
              { !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : !this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpHasMaxHour
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
              { this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour) && employeeJobHoursInfo.isEmpOverHour
                ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                `<div style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</span></div>` +
                `<div style="font-weight: bold;">New Job Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.new_job_hrs} hours (EXCEEDING)</span></div>`
                }} />
                : this.isEdit() && (employeeJobHoursInfo.isEmpHasMaxHour !== undefined && employeeJobHoursInfo.isEmpHasMaxHour)
                  ? <div dangerouslySetInnerHTML={{ __html: `<br /><span style="font-weight: bold;">Max Hours: ${employeeJobHoursInfo.weekly_max_hrs} hours</span>` +
                  `<div style="font-weight: bold;">Current Total Hours: ${employeeJobHoursInfo.emp_total_job_hrs ? employeeJobHoursInfo.emp_total_job_hrs : 0} hours</div>` +
                  `<div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.new_job_hrs} hours</span></div>`
                  }} />
                  : null
              }
            </div>
          ),
          okText: 'OK',
          onOk () { },
        })
      }
    }, 400)

    this.setState({
      selectedEmpInfo: employeeInfo,
      showEmployeePrivateAlert: employeeHasPrivateAlert,
      showEmployeePublicAlert: employeeHasPublicAlert
    })

    if (clientSkills && clientSkills.length > 0 && clientSkills[0] !== null) {
      const finalSkills = []
      const employeeSkills = employeeInfo.skills

      const diffSkills = clientSkills.filter(x => !employeeSkills.includes(x))
      const isMatchSkill = !(diffSkills.length > 0)

      const skills = await Promise.all(diffSkills.map(item => settingGeneralService.get(item)))

      skills.map(skill => (
        finalSkills.push(skill.item.name)
      ))

      this.setState({ missingSkill: finalSkills, isMatchSkill })
      // this.showEmployeeSkillConfirm()
    }

    if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
      const languages = []
      const finalLang = []
      const employeeLanguage = employeeInfo.languages

      clientLanguages.forEach((e1) => employeeLanguage.forEach((e2) => {
        if (e1 === e2) {
          languages.push(e2)
        }
      }))

      const languageNames = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))
      languageNames.map(lang => (
        finalLang.push(lang.item.name)
      ))

      this.setState({ knownLanguage: finalLang, isMatchLanguage: languages.length > 0 })
    }

    // 1. Check schedule conflict
    this.checkEmployeeJobConflict(empId, formStartDate, formEndDate)

    // 2. Check if employee is on leave on the selected date
    this.checkEmployeeLeave(empId, formStartDate, formEndDate)

    // 3. Check employee files expiry
    if (typeof empId === 'number') {
      this.checkEmployeeFilesExpiry(empId, formStartDate, formEndDate)
    }

    // 4. Check employee weekly max hours
    // if (!this.isEdit() && typeof empId === 'number' && (Moment(formStartDate).isValid() && formStartDate !== null) && (Moment(formEndDate).isValid() && formEndDate !== null)) {
    //   this.checkEmployeeWeeklyMaxHour(empId, formStartDate, formEndDate)
    // }
  }

  checkEmployeeLeave = async (empId, start, end) => {
    const allLeaves = await employeeLeaveService.getByStartEnd(empId, start, end)
    const allLeavesCheck = allLeaves.item

    if (allLeavesCheck.length > 0) {
      this.setState({ isOnLeave: true })
      // this.showEmployeeLeaveConfirm()
    } else {
      this.setState({ isOnLeave: false })
    }
  }

  checkFunderAvailability = (jobStartDate) => {
    const { funderDetail } = this.state

    if (funderDetail) {
      const funderStartDate = Moment(funderDetail.start_date)
      const funderEndDate = Moment(funderDetail.end_date)

      if (jobStartDate) {
        if (jobStartDate.isBetween(funderStartDate, funderEndDate)) {
          this.setState({ showFunderAlert: false })
        } else {
          this.setState({ showFunderAlert: true })
          // this.showFunderUnavailableConfirm()
        }
      }
    }
  }

  checkHoliday = async (date) => {
    const holiday = await settingHolidayService.getTodayHoliday(date)

    if (holiday.list && holiday.list.length > 0) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list[0] })
      return true
    } else {
      this.setState({ isHoliday: false })
      return false
    }

    return false
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSleepover = (start, end) => {
    const isSameDay = Moment(start).isSame(end, 'day')
    const durations = Moment.duration(Math.abs(end.diff(start)))
    const diffHours = durations.asHours()

    if (diffHours > 4) {
      /*   const { billRateInfo } = this.state
      const afterStart = billRateInfo.after_hours_start
      const afterEnd = billRateInfo.after_hours_end
      const normalStart = billRateInfo.normal_hours_start
      const normalEnd = billRateInfo.normal_hours_end

      const afterStartTime = Moment(Moment(afterStart).format('hh:mm a'), 'hh:mm a')
      const afterEndTime = Moment(Moment(afterEnd).format('hh:mm a'), 'hh:mm a')
      const normalStartTime = Moment(Moment(normalStart).format('hh:mm a'), 'hh:mm a')
      const normalEndTime = Moment(Moment(normalEnd).format('hh:mm a'), 'hh:mm a')
*/
      // Need to enhance further
      if (isSameDay) {
        // Same day
        const isStartInAM = start.format('A') === 'AM'
        const isEndInPM = end.format('A') === 'PM'

        if (isStartInAM && !isEndInPM) {
          const { billRateInfo } = this.state

          if (billRateInfo) {
            const midnightStart = Moment(Moment().startOf('day').format('hh:mm a'), 'hh:mm a')
            const afterEndTime = Moment(Moment(billRateInfo.after_hours_end).format('hh:mm a'), 'hh:mm a')
            const startTime = Moment(Moment(start).format('hh:mm a'), 'hh:mm a')
            const endTime = Moment(Moment(end).format('hh:mm a'), 'hh:mm a')

            // console.log(midnightStart, afterEndTime, startTime, endTime, startTime.isBetween(midnightStart, afterEndTime), endTime.isBetween(midnightStart, afterEndTime), endTime.isSame(afterEndTime))

            if ((startTime.isBetween(midnightStart, afterEndTime) || startTime.isSame(midnightStart)) && (endTime.isBetween(midnightStart, afterEndTime) || endTime.isSame(afterEndTime))) {
              this.setState({ isSleepover: true, showSleepoverAlert: true })
            }
          }

          // console.log(afterStartTime, afterEndTime)
        } else {
          this.setState({ isSleepover: false, showSleepoverAlert: false })
        }
      } else {
        // Different Day
        const isStartInPM = start.format('A') === 'PM'
        const isEndInAM = end.format('A') === 'AM'

        if (isStartInPM && isEndInAM) {
          this.setState({ isSleepover: true, showSleepoverAlert: true })
        } else {
          this.setState({ isSleepover: false, showSleepoverAlert: false })
        }
      }
    } else {
      this.setState({ isSleepover: false, showSleepoverAlert: false })
    }

    // this.setState({ isSleepover: false, showSleepoverAlert: false })
  }

  cacheEmployeeName = (employeeId) => {
    if (employeeId) {
      const employeeName = this.getEmployeeName(employeeId)
      this.setState({
        currentEmployee: {
          name: employeeName,
          id: employeeId
        }
      })
    }
  }

  cacaheBillingCategory = () => {
    const { item, currentBillingCategory } = this.state
    if (item.billing_category_id && currentBillingCategory.name === '') {
      const name = this.getBillingCategoryName(item.billing_category_id)
      this.setState({
        currentBillingCategory: {
          name
        }
      })
    }
  }

  getEmployeeName = (employeeId) => {
    const { employees } = this.state
    const employee = employees.filter(item => item.id === employeeId)
    return employee.length > 0 ? `${employee[0].first_name} ${employee[0].last_name}` : ''
  }

  getBillingCategoryName = (billingId) => {
    const { billingCategory } = this.state
    const category = billingCategory.filter(item => item.id === billingId)
    return category.length > 0 ? category[0].name : ''
  }

  getPayrollCategoryName = (payrollValue) => {
    const { payrolls } = this.state
    const payroll = payrolls.filter(item => item.value === payrollValue)
    return payroll.length > 0 ? payroll[0].name : ''
  }

  getChargeHours = () => {
    const { settingOthers = {} } = this.state
    const { form } = this.props
    const startDateTime = Moment(form.getFieldValue('job_start_date'))
    const endDateTime = Moment(form.getFieldValue('job_end_date'))

    const startDate = startDateTime.seconds(0).milliseconds(0)
    const endDate = endDateTime.seconds(0).milliseconds(0)
    const durations = Moment.duration(Math.abs(endDate.diff(startDate)))
    const diffHours = durations.asHours()

    var job_hours = Math.round(diffHours * 100) / 100

    return job_hours > settingOthers.cancellation_charge_hour ? settingOthers.cancellation_charge_hour : job_hours
  }

  getOriginalHours = () => {
    const { item } = this.state

    const start = Moment(item.job_start_date)
    const end = item.original_end_date ? Moment(item.original_end_date) : Moment(item.job_end_date)
    const durations = Moment.duration(Math.abs(start.diff(end)))
    const diffHours = durations.asHours()

    return diffHours
  }

  getScheduleDay = () => {
    let jobStartDate
    if (this.isEdit()) {
      const { item } = this.state
      jobStartDate = item.job_start_date
    } else {
      const { form } = this.props
      jobStartDate = form.getFieldValue('job_start_date')
    }
    return jobStartDate && Moment(jobStartDate).isValid() ? `on ${Moment(jobStartDate).format('DD/MM/YYYY')}, ${Moment(jobStartDate).format('dddd')}` : ''
  }

  showBreakdown = async (start, end) => {
    const { item, funderId } = this.state
    const isHoliday = await this.checkHoliday(start)
    const isNextHoliday = await this.checkHoliday(end)
    const breakdown = new DurationBreakdown(item.funder_id || funderId, isHoliday, isNextHoliday)
    const durationBreakdown = await breakdown.get(start, end)

    this.setState({ durationBreakdown })
  }

  // 3. UI Control ///////////////////////////////////////////////////////////////////////////////////////////

  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Funding Period Not Defined!',
      content: 'Please assign a funding period for this funder before proceeding. Click Ok to be redirected to the client page or Cancel to choose another Funder',
      onOk () {
        history.replace(`/clients/${clientId}`)
      },
      onCancel () {
        // this.setState({ showFunderModal: true })
      }
    })
  }

  disabledDate = (value) => {
    const form = this.props.form
    return value < form.getFieldValue('job_start_date')
  }

  disabledTime = (value) => {
    const form = this.props.form
    return value < form.getFieldValue('job_start_date')
  }

  handleCancelJob = async () => {
    const { form } = this.props
    const { employeeId, funderInfo, settingOthers, clientFunderId, item } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const that = this

    validateFieldsAndScroll(['job_start_date', 'cancellation_reason', 'cancellation_type', 'cancellation_note', 'cancellation_other_reason'], async (errors, values) => {
      const valuesStart = Moment(values.job_start_date)
      const nowTime = Moment()

      const durations = Moment.duration(Math.abs(valuesStart.diff(nowTime)))
      const diffHours = durations.asHours()
      const cancelParam = settingOthers.cancellation_notice
      const isPastDate = Moment().isAfter(valuesStart)
      const { name: adminName } = authService.getCurrentUser()

      if (!errors) {
        if (diffHours < cancelParam || isPastDate) {
          // Late cancellation wiht option to Normal Cancel
          this.setState({ showCancelAlert: true, showCancelConfirmModal: true })
        } else {
          // Normal cancellation
          values.is_cancel = true
          values.cancellation_time = Moment()
          values.cancellation_penalty = false
          values.funder_id = funderInfo.id
          values.client_funder_id = clientFunderId
          values.employee_id = employeeId || null
          values.staff = adminName
          values.original_end_date = item.job_end_date

          item.original_end_date = item.job_end_date

          this.setState({ showCancelAlert: false, item })

          confirm({
            title: 'Are you sure you want to cancel this job?',
            content: 'Press Ok to continue, No to return',
            okText: 'Yes',
            cancelText: 'No',
            async onOk () {
              const response = await jobService.save(jobId, values)
              if (response.id) {
                log.cancelJob(response.id, `Reason: ${that.getCancelTypeName(values.cancellation_type)} - ${values.cancellation_reason} ${values.cancellation_other_reason ? ` - ${values.cancellation_other_reason}` : ''} ${values.cancellation_note ? ` (${values.cancellation_note})` : ''}`)
                notify.success('Success', 'Job Cancelled.')

                that.resetCancelForm()
                that.refreshPage()
                /* this.fetchJob()

                await this.fetchUnsentEmail(jobId)

                setTimeout(() => {
                  this.checkEmail()
                }, 1000) */
               /*  setTimeout(() => {
                  window.location.reload()
                }, 1000) */
              }
            }
          })
        }
      }
    })
  }

  handleEmployeeChange = async (value) => {
    const { form } = this.props
    const { settingOthers } = this.state
    const formStartDate = Moment(form.getFieldValue('job_start_date'))

    // Check if it is emergency
    const emergencyDuration = settingOthers.emergency_notice
    const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
    const differentHours = durationFromNow.asHours()
    const isPastDate = Moment().isAfter(formStartDate)

    this.checkEmployee(value, true)

    if (!this.isEdit()) {
      return
    }

    if ((differentHours >= 0 && differentHours <= emergencyDuration) || isPastDate) {
      setTimeout(() => {
        this.setState({ emergencyField: true, showEmergencyModal: true, newEmployeeId: value })
      }, 1000)
    }
  }

  handleEmployeeChangeReason = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll([ 'change_employee_reason', 'change_employee_other_reason', 'change_employee_note' ],
      async (errors, values) => {
        if (!errors) {
          this.setState({
            showEmployeeReasonModal: false,
            changeEmployeeReason: values.change_employee_reason,
            changeEmployeeOtherReason: values.change_employee_other_reason,
            changeEmployeeNote: values.change_employee_note
          })
          this.setState({ loading: true })

          that.handleChangeEmployee()
          //Proceed to save
          setTimeout(() => {
            this.handleSave()
          }, 500)
        }
      })
  }

  handleLateCancel = async (isLate = false) => {
    const { form, fetchingJobs } = this.props
    const { clientFunderId, employeeId, funderInfo, item } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const { name: adminName } = authService.getCurrentUser()

    validateFieldsAndScroll(['cancellation_reason', 'cancellation_type', 'cancellation_other_reason', 'cancellation_note', 'job_end_date'],
      async (errors, values) => {
        values.is_cancel = true
        values.cancellation_time = Moment()
        values.funder_id = funderInfo.id
        values.client_funder_id = clientFunderId
        values.employee_id = employeeId || null
        values.staff = adminName
        values.original_end_date = item.job_end_date

        if (isLate) {
          this.setState({ isHandlingLateCancel: true })

          const initialNotes = values.notes
          const remarkStr = '(LC)'
          const finalStr = `${initialNotes} ${remarkStr}`
          const currStartTime = form.getFieldValue('job_start_date')
          const newEndTime = Moment(currStartTime).add(this.getChargeHours(), 'hours')

          values.job_end_date = newEndTime
          values.original_end_date = item.job_end_date

          values.notes = finalStr
          values.cancellation_penalty = true
          values.job_hours = this.getChargeHours()
        } else {
          this.setState({ isHandlingCancel: true })
        }

        const response = await jobService.save(jobId, values)

        if (response.id) {
          log.cancelJob(response.id, `${isLate ? 'Late Cancellation.' : ''} Reason: ${this.getCancelTypeName(values.cancellation_type)} - ${values.cancellation_reason} ${values.cancellation_other_reason ? ` - ${values.cancellation_other_reason}` : ''} ${values.cancellation_note ? ` (${values.cancellation_note})` : ''}`)
          notify.success('Success', 'Job Cancelled.')

          this.setState({ showCancelConfirmModal: false, showCancelModal: false, isHandlingCancel: false, isHandlingLateCancel: false })

          this.resetCancelForm()
          this.refreshPage()
        }
      }
    )
  }

  handleUncancel = async () => {
    const { form } = this.props
    const { funderInfo, clientFunderId, item } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const { name: adminName } = authService.getCurrentUser()
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      values.is_cancel = false
      values.cancellation_reason = null
      values.cancellation_time = null
      values.cancellation_other_reason = null
      values.cancellation_type = null
      values.cancellation_penalty = false
      values.funder_id = funderInfo.id
      values.client_funder_id = clientFunderId
      values.staff = adminName
      values.job_end_date = item.original_end_date

      delete values.change_employee_reason
      delete values.change_employee_reason_type
      delete values.change_employee_note

      confirm({
        title: 'Are you sure to uncancel this job?',
        content: 'Click Yes to uncancel, No to leave the job cancelled.',
        okText: 'Yes',
        cancelText: 'No',
        async onOk () {
          values.notes = ''

          const response = await jobService.save(jobId, values)
          if (response.id) {
            notify.success('Success', 'Job Uncancelled.')
            log.uncancelJob(response.id, 'Job is uncancelled')
            /* setTimeout(() => {
              window.location.reload()
            }, 1000) */
            // fetchingJobs(true)
            that.refreshPage()
          }
        }
      })
    })
  }

  handleEmployeeReasonTypeChange = async (id, value) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReason: cancelReason.list, changeEmployeeReasonType: value.props.children })
  }

  handleCancelTypeChange = async (id) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReason: cancelReason.list })
  }

  handleCancelReasonChange = async (value) => {
    if (value === 'Other') {
      this.setState({ showOtherField: true })
    } else {
      this.setState({ showOtherField: false })
    }
  }

  handleChangeTab (tabNumber) {
    this.setState({ tabActiveKey: tabNumber })
  }

  handlePendingEmployee = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({ employeePending: true, isOnLeave: false, isTimeConflict: false, isMatchLanguage: true, isMatchSkill: true, expiredFiles: [], employeeJobHoursInfo: {} })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ employeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleEmergencyPayClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Pay?',
        content: 'Not Paying Employee Emergency Rate?',
        onOk () {
          form.setFieldsValue({ emergency_pay: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_pay: true })
        }
      })
    }
  }

  handleEmployeeEmergency = async (isEmergency = false) => {
    const { form } = this.props
    const { employeeId, newEmployeeId, settingOthers } = this.state

    if (isEmergency) {
      const isEmergency = form.getFieldValue('emergency')
      const isInvoiceEmergency = form.getFieldValue('emergency_invoice')
      const formStartDate = Moment(form.getFieldValue('job_start_date'))
      const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
      const differentHours = durationFromNow.asHours()
      const isPastDate = Moment().isAfter(formStartDate)
      const emergencyEmpDuration = settingOthers.emergency_notice_emp

      form.setFieldsValue({ emergency: true, emergency_pay: ((emergencyEmpDuration ? differentHours >= 0 && differentHours <= emergencyEmpDuration : true) || isPastDate), emergency_invoice: !isEmergency ? false : (isInvoiceEmergency || false), employee_id: newEmployeeId })

      this.setState({ employeeId: newEmployeeId })
      this.checkEmployee(newEmployeeId)
    } else {
      form.setFieldsValue({ newEmployeeId: employeeId, emergency_pay: false })
      // this.setState({ emergencyField: false })
    }

    this.setState({ showEmergencyModal: false })
  }

  handleEmployeeCancel = async () => {
    const { form } = this.props
    const { employeeId } = this.state
    form.setFieldsValue({ employee_id: employeeId })
    this.checkEmployee(employeeId)
    this.setState({ showEmergencyModal: false })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleEmergencyYes = ({ emgPay = false, emgInv = false }) => {
    const { form } = this.props
    const { settingOthers } = this.state
    form.setFieldsValue({ emergency: true })
    this.setState({ emergencyField: true, isEmergencyTime: false })

    setTimeout(() => {
      form.setFieldsValue({ emergency_pay: emgPay, emergency_invoice: emgInv })
    }, 200)
  }

  handleEmergencyNo = () => {
    const { form } = this.props
    form.setFieldsValue({ emergency: false, emergency_pay: false, emergency_invoice: false })
    this.setState({ emergencyField: false, isEmergencyTime: false })
  }

  handleEmergencyClick = (value) => {
    const { form } = this.props
    const { settingOthers } = this.state
    const formStartDate = Moment(form.getFieldValue('job_start_date'))

    this.setState({ emergencyField: !!value })

    if (value === false) {
      setTimeout(() => {
        form.setFieldsValue({ emergency_pay: value, emergency_invoice: value })
      }, 200)
    } else {
      // Check if is emergency pay or emergency invoice
      const emergencyDuration = settingOthers.emergency_notice
      const emergencyEmpDuration = settingOthers.emergency_notice_emp
      const durationFromNow = Moment.duration(Math.abs(formStartDate.diff(Moment())))
      const differentHours = durationFromNow.asHours()

      setTimeout(() => {
        if (emergencyEmpDuration) {
          form.setFieldsValue({
            emergency_pay: differentHours >= 0 && differentHours <= emergencyEmpDuration,
            emergency_invoice: differentHours >= 0 && differentHours <= emergencyDuration
          })
        } else {
          form.setFieldsValue({ emergency_pay: value, emergency_invoice: value
          })
        }
      }, 200)
    }
  }

  handleChangeFunder = () => {
    this.setState({ showFunderModal: true })
  }

  handleChangePayroll = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleEmergencyInvoiceClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Invoice?',
        content: 'Not Charging Client Emergency Loading?',
        onOk () {
          form.setFieldsValue({ emergency_invoice: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_invoice: true })
        }
      })
    }
  }

  handleLateCancellation = async(value) => {
    const { item } = this.state
    const { form } = this.props
    const that = this

    confirm({
      title: 'Late Cancelllation',
      content: `${value ? 'Add' : 'Remove'} late cancellation charge?`,
      async onOk () {
        const params = { cancellation_penalty: value }

        if (value) {
          params.job_end_date = Moment(item.job_start_date).add(that.getChargeHours(), 'hours')

          if (!item.original_end_date) {
            params.original_end_date = item.job_end_date
          }
        } else {
          params.job_end_date = item.original_end_date
        }

        const res = await jobService.saveQuick(item.id, params)

        form.setFieldsValue({ cancellation_penalty: value, job_end_date: Moment(params.job_end_date) })
        that.showBreakdown(Moment(item.job_start_date), Moment(params.job_end_date))
        item.cancellation_penalty = value

        that.setState({ item })

        if (res) {
          notify.success('Updated successfully', 'Job updated successfully.')
          log.updateJobExtra(res.id, 'update', `Late cancellation from '${!value}' to '${value}'`)
        }

        that.refreshPage()
      },
      onCancel () {
        form.setFieldsValue({ cancellation_penalty: !value })
      }
    })

  }

  handleFunderSubmit = async () => {
    const { clientId, funderId: currrentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['client_funder_id'], async (errors, values) => {
      const funderSettingId = values.client_funder_id
      const clientFunderSetting = await funderService.getClientFunderSetting(funderSettingId)
      const funderId = clientFunderSetting.funder_id
      const funders = await funderService.get(funderId)
      const fundClient = await clientService.getClientFundersInfo(clientId, funderId)
      const funderList = await clientService.getClientFunders(clientId)

      if (currrentFunderId !== funderId) {
        this.fetchBillingRate(funderId)
        form.setFieldsValue({ billing_category_id: null })
      }

      this.setState({ funderInfo: funders.item, funderDetail: clientFunderSetting, funderList, showFunderModal: false, funderId, clientFunderId: funderSettingId })

      if (fundClient.item.end_date === null) {
        this.setState({ notAssignedTime: true })
        this.alertUnassignedTime()
      } else {
        this.setState({ notAssignedTime: false })
      }

      const startDate = form.getFieldValue('job_start_date')
      this.checkFunderAvailability(startDate)
    })
  }

  handleFunderCancel = async () => {
    this.setState({ showFunderModal: false })
  }

  resetCancelForm = () => {
    const { form } = this.props
    this.setState({ showCancelModal: false, showOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({ cancellation_reason: '', cancellation_type: '', cancellation_other_reason: '', cancellation_note: '' })
    }, 1000)
  }

  handleChangeEmployee= async () => {
    const { form } = this.props
    this.setState({ showEmployeeReasonModal: false, showOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({ change_employee_reason: '', change_employee_reason_type: '', change_employee_other_reason: '', change_employee_note: '' })
    }, 1000)
  }

  /** Add new File - File Upload Modal */
  handleAddFileModal = (showAddFileModal, info = {}) => {
    // console.log('handleAddFileModal', info)
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  handleDeleteFile = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  uploadFiles = async (jobId) => {
    const { fileList, categoriesList, subCategoriesList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = categoriesList.find(e => e.id === file.main_category_id)
      const subcat = subCategoriesList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jobService.addFile(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  handleSave = () => {
    const { form, fetchingJobs, history } = this.props
    const { clientInfo, changeEmployeeReason, changeEmployeeReasonType, changeEmployeeOtherReason, changeEmployeeNote, fileList } = this.state
    const { validateFieldsAndScroll, setFieldsValue } = form

    const { name: adminName } = authService.getCurrentUser()

    validateFieldsAndScroll([ 'job_start_date', 'job_end_date', 'billing_category_id', 'payroll',
      'employee_id', 'tasks', 'notes', 'emergency', 'emergency_pay', 'emergency_invoice', 'cancellation_penalty',
      'kms', 'job_kms' ],
    async (errors, values) => {
      if (!errors) {

        // Stage 1.5
        // const { showGetupHours, getupList } = this.state
        const { billingRateId, item, dayNumber, clientFunderId, funderInfo, clientId, employeePending, currentBillingCategory, currentEmployee, currentFunder } = this.state

        // Check if employee has changed
        if (currentEmployee.id !== '' && currentEmployee.id !== values.employee_id) {
          if (changeEmployeeReason === '' ){
            this.setState({ showEmployeeReasonModal: true })
            return
          }
        }

        this.setState({ loading: true })

        // calculate Job Hours
        const startDate = values.job_start_date.seconds(0).milliseconds(0)
        const endDate = values.job_end_date.seconds(0).milliseconds(0)
        const durations = Moment.duration(Math.abs(endDate.diff(startDate)))
        const diffHours = durations.asHours()

        values.job_hours = Math.round(diffHours * 100) / 100
        values.billing_rate_id = billingRateId
        values.job_start_day = Moment(startDate).format('dddd')

        values.client_id = clientId
        values.funder_id = funderInfo.id
        values.client_funder_id = clientFunderId

        values.pending_employee = employeePending

        values.staff = adminName

        // round up recorded kms
        const newKms = formatter.roundKMS(values.kms)
        values.kms = newKms
        setFieldsValue({ kms: newKms })

        // Replace and remove if tasks has special character, tab or extra blank spaces
        if (values.tasks.length > 0) {
          values.tasks = this.replacer(values.tasks).trim()
          setFieldsValue({ tasks: values.tasks })
        }

        // Replace and remove if notes has special character, tab or extra blank spaces
        if (values.notes.length > 0) {
          values.notes = this.replacer(values.notes).trim()
          setFieldsValue({ notes: values.notes })
        }

        // Check if part of this job is sleepover
        if (values.billing_category_id === 4) {
          values.sleepover = 1
          // Remove this once Stage 1.5 is up
          values.sleepover_category_id = values.billing_category_id
          values.job_hours = 0
        } else {
          values.sleepover = 0
          values.sleepover_category_id = null
        }

        // Check if job is not emergency then clear invoice and pay
        if (!values.emergency) {
          values.emergency_invoice = false
          values.emergency_pay = false
        }

        // Check if job is monthly job that got indicator
        if (values.month_setting) {
          values.day_number = dayNumber
        } else {
          delete values.month_setting
        }

        // Stage 1.5 overnight type
        /* if (values.overnight_type === 'sleepover') {
          const billCategory = billingCategory.find((item) => item.name === 'Sleepover')
          // console.log('billCategory', billCategory)
          values.get_up = getupList
          values.sleepover_category_id = billCategory.id
        } else if (values.overnight_type === 'nightowl') {
          values.sleepover_category_id = null
        } */

        delete values.cancellation_time

        if (item.base_job_id !== null) {
          values.is_updated = true
        }

        // Remove job_kms if job_kms empty string
        if (values.job_kms === '') {
          values.job_kms = null
        }

        // Remove kms if kms empty string
        if (values.kms === '') {
          values.kms = null
        }

        try {
          if (this.isEdit()) {
            values.original_end_date = values.job_end_date

            const response = await jobService.save(item.id, values)

            if (response.id) {
              let extraLogs = []

              if (currentEmployee.id !== '' && currentEmployee.id !== values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from "${currentEmployee.name}" to "${newEmployeeName}"`)

                this.setState({
                  currentEmployee: {
                    name: newEmployeeName,
                    id: values.employee_id
                  }
                })

                log.updateJobExtra(response.id, 'employee change reason', `Employee changed to "${newEmployeeName}" with reason: ${changeEmployeeReasonType} - ${changeEmployeeReason} ${changeEmployeeOtherReason ? ` - ${changeEmployeeOtherReason}` : ''} ${changeEmployeeNote ? ` (${changeEmployeeNote})` : ''}`)

              } else if (currentEmployee.id === '' && values.employee_id !== '') {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from Pending to "${newEmployeeName}"`)

                this.setState({
                  currentEmployee: {
                    name: newEmployeeName,
                    id: values.employee_id
                  }
                })
              }

              if (values.funder_id !== item.funder_id) {
                extraLogs.push(`Funder changed from "${currentFunder.fullname}" to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== item.billing_category_id) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(`Shift Type changed from "${currentBillingCategory.name}" to "${name}"`)
              }

              if (values.payroll !== item.payroll) {
                const oldPayroll = await this.getPayrollCategoryName(item.payroll)
                const newPayroll = await this.getPayrollCategoryName(values.payroll)
                extraLogs.push(`Carer Skill Level changed from "${oldPayroll}" to "${newPayroll}"`)
              }

              log.updateJob(response.id, item, values, ['is_updated', 'job_hours', 'employee_id', 'funder_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'client_funder_id'], extraLogs.join())
              notify.success('Saved successfully', 'Job saved successfully.')

              this.refreshPage()
            }

            this.setState({ item: { ...item, ...values }, loading: false, changeEmployeeReason: '', changeEmployeeReasonType: '', changeEmployeeOtherReason: '', changeEmployeeNote: '' })
          } else {
            values.original_end_date = values.job_end_date
            values.job_type = 'single'
            const response = await jobService.add(values)
            this.setState({ loading: false })

            if (response.id) {
              const { id } = response
              this.setState({ item: { ...item, ...values, id } })

              let extraLogs = []

              extraLogs.push(`Client to "${clientInfo.first_name} ${clientInfo.last_name}"`)

              if (values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(` Employee to "${newEmployeeName}"`)
              }

              if (values.funder_id !== undefined) {
                extraLogs.push(` Funder to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== undefined) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(` Shift Type to "${name}"`)
              }

              if (values.payroll !== undefined) {
                const newPayroll = await this.getPayrollCategoryName(values.payroll)
                extraLogs.push(` Carer Skill Level to "${newPayroll}"`)
              }

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList)) {
                await this.uploadFiles(response.id)
              }
              // ------ append file end -------

              log.addJob(id, values, ['is_updated', 'job_hours', 'employee_id', 'funder_id', 'client_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'sleepover', 'job_type', 'original_end_date', 'client_funder_id'], extraLogs.join())
              notify.success('Saved successfully', 'Job saved successfully.')
              history.replace(`/v1/jobs/single/${id}`)

              this.cacheEmployeeName(values.employee_id)

              this.refreshPage()
            }
          }

          this.props.setRefreshCommLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
          this.setState({ loading: false })
        }
      } else {
        this.showErrorNotification('error')
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideCancelModal = () => {
    this.setState({ showCancelModal: false })
  }

  onSelectBillingRate (option) {
    this.setState({ showGetupHours: option.props.children.indexOf('Sleepover') > -1 })
  }

  async refreshPage () {
    this.fetchJob()
    const jobId = this.getJobId()
    await this.fetchUnsentEmail(jobId)

    setTimeout(() => {
      this.checkEmail()
    }, 600)

    setTimeout(() => {
      this.fetchLogs()
    }, 1000)
  }

  async sendEmail (jobId) {
    // console.log('Will Send Email for:', jobId)
    // fetch email to be sent

    const filter = {}
    filter.job_id = { condition: '=', value: jobId }
    const communication = await jobService.getEmails(1, 5, filter)
    const emailToSend = communication.list[0]
    const emailId = emailToSend.id

    // send email

    const result = communicationService.send(emailId)

    if (result) {
      notify.success('Sent successfully', 'Email sent successfully')
      window.location.reload()
    } else {
      notify.error('Unable to send successfully', 'Please try again later.')
      window.location.reload()
    }
  }

  showCancelModal = () => {
    this.setState({ showCancelModal: true })
  }

  showClientLeaveConfirm = () => {
    const { clientLeaveInfo, clientInfo } = this.state
    warning({
      title: 'Client On Leave',
      content: `${clientInfo.first_name} ${clientInfo.last_name} is on leave from ${Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to ${clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}`
    })
  }

  showClientConflictConfirm = () => {
    const { clientInfo, clientConflictInfo } = this.state
    warning({
      title: 'Client Has Another Shift',
      content: `${clientInfo.first_name} ${clientInfo.last_name} has shift on ${Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} ${formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to ${formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}`
    })
  }

  showEmployeeConflictConfirm = () => {
    const { selectedEmpInfo, conflictJobInfo } = this.state

    warning({
      title: 'Employee Has Another Shift',
      content: `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} has shift on ${Moment(conflictJobInfo.job_start_date).format('DD/MM/YYYY')} ${formatter.toShortTime(Moment(conflictJobInfo.job_start_date))} to ${formatter.toShortTime(Moment(conflictJobInfo.job_end_date))}`
    })
  }

  showEmployeeLeaveConfirm = () => {
    const { selectedEmpInfo } = this.state
    warning({
      title: 'Employee On Leave',
      content: `${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} is on leave from ${Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to ${Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}`
    })
  }

  showEmployeeSkillConfirm = () => {
    const { isMatchSkill, missingSkill, selectedEmpInfo } = this.state

    if (!isMatchSkill) {
      warning({
        title: 'Skill Mismatch!',
        content: (<div>
          <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess following skill(s):</p>
          <ul>
            {
              missingSkill.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))
            }
          </ul>

        </div>)
      })
    }
  }

  showEmergencyConfirm = ({ emgPay, emgInv, emgHours }) => {
    const { handleEmergencyNo, handleEmergencyYes } = this

    confirm({
      title: 'Emergency Job?',
      content: `Shift Start In Less Than ${emgHours} Hours`,
      okText: 'Yes',
      cancelText: 'Not EMG',
      onOk () {
        handleEmergencyYes({ emgPay, emgInv })
      },
      onCancel () {
        handleEmergencyNo()
      }
    })
  }

  showPreWarning = () => {
    const { isDwesExpired, isPoliceExpired, isLicenseExpired, isFirstAidExpired, isCertificateExpired, showFunderAlert, clientIsOnLeave,
      showClientConflictAlert, isOnLeave, isTimeConflict, isMatchSkill, missingSkill, clientInfo, selectedEmpInfo, clientLeaveInfo,
      clientConflictInfo, conflictJobInfo, funderDetail } = this.state

    if (isDwesExpired || isPoliceExpired || isLicenseExpired || isFirstAidExpired || showFunderAlert || clientIsOnLeave ||
      showClientConflictAlert || isOnLeave || isTimeConflict || !isMatchSkill) {
      warning({
        title: (<strong>{this.isEdit() ? 'Warning' : 'Proceed To Add?'}</strong>),
        content: (
          <div>
            <p>{ this.isEdit() ? 'This job has followings issue(s)' : 'This job will be created with following issue(s)' }:</p>

            {/* certificate */}

            {
              isCertificateExpired ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Expired Certificate:</strong></p>
                <li>
                  <ul>
                    {isDwesExpired ? <li>DWES Expired</li> : null}
                    {isPoliceExpired ? <li>Police Check Expired</li> : null}
                    {isLicenseExpired ? <li>Driver License Expired</li> : null}
                    {isFirstAidExpired ? <li>First Aid Certificate Expired</li> : null}

                  </ul>
                </li>
                <br />
              </div> : null
            }

            {/* Funder */}

            {
              showFunderAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Fund Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>

                <br /></div> : null
            }

            {/* Client On Leave */}

            {
              clientIsOnLeave ? <div>
                <div><p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {clientLeaveInfo.until_further_notice ? 'UFN' : Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>

                <br />
              </div> : null
            }

            {/* Client Shift Conflict */}

            {
              showClientConflictAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Client Has Another Shift</strong></p>
                <p> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}</p>
                <br />
              </div> : null
            }

            {/* Employee On Leave */}

            {
              isOnLeave ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} is on leave from {Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            }

            {/* Employee Shift Conflict */}

            {
              isTimeConflict ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee Has Another Shift</strong></p>
                <p> {selectedEmpInfo.first_name} {selectedEmpInfo.last_name} has shift on {Moment(conflictJobInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(conflictJobInfo.job_start_date))} to {formatter.toShortTime(Moment(conflictJobInfo.job_end_date))}</p>
                <br />
              </div> : null
            }

            {/* Skill Mismatch */}

            {
              isMatchSkill ? null
                : <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} does not possess following skill(s):</strong></p>
                  <ul>
                    {
                      missingSkill.map((skills, index) => (
                        <li key={index}>{skills}</li>
                      ))
                    }
                  </ul>
                  <br />
                </div>
            }

            {/* <mark><strong>Click OK to go back and made changes or proceed to save.</strong></mark> */}

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks

        },
        onCancel () {
        }
      })
    }
  }

  showFunderUnavailableConfirm = () => {
    warning({
      title: 'Funder Not Available.',
      content: 'This funder is not available on the selected date.'

    })
  }

  showEmployeeCertConfirm = async (employee) => {
    const { item: employeeInfo } = await employeeService.get(employee)
    const { isDwesExpired, isFirstAidExpired, isLicenseExpired, isPoliceExpired } = this.state
    warning({
      title: `Expired Certificate!`,
      content: (<div>
        <p>{employeeInfo.first_name} {employeeInfo.last_name} got one or more expired certificate:</p>
        <ul>
          {isDwesExpired ? <li>DWES Expired</li> : null}
          {isPoliceExpired ? <li>Police Check Expired</li> : null}
          {isLicenseExpired ? <li>Driver License Expired</li> : null}
          {isFirstAidExpired ? <li>First Aid Certificate Expired</li> : null}
        </ul>

      </div>)

    })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Job Time Error',
      description: 'End Time should not be earlier than Start Time',
      top: 130
    })
  }

  // Stage 1.5 - Get up --------------------------------------

  handleGetupChange = (id, type, value, index) => {
    const { getupList } = this.state
    const item = getupList.find((item) => item.id === id)

    if (item) {
      item[type] = value

      // console.log(type, value)

      if (type === 'start_time' || type === 'end_time') {
        // console.log('Date', Moment(item.start_time).format('YYYY-MM-DD hh:mma'), Moment(item.end_time).format('YYYY-MM-DD hh:mma'))

        const startDate = Moment(item.start_time)
        const endDate = Moment(item.end_time)
        const durations = Moment.duration(Math.abs(startDate.diff(endDate)))
        const diffHours = durations.asHours()

        item.hour_d1 = 0
        item.hour_d2 = 0

        const timeStart = Moment(startDate.format('hh:mm:ss a'), 'hh:mm:ss a')
        const timeEnd = Moment(endDate.format('hh:mm:ss a'), 'hh:mm:ss a')

        let hour = Math.round(diffHours * 100) / 100
        let netHour = hour

        // console.log(diffHours, 'hour', hour, 'netHour', netHour)

        if (startDate.format('a') === 'pm' && endDate.format('a') === 'am') {
          hour = 24 - hour
          netHour = hour
        }

        // console.log('hour', hour, 'netHour', netHour)

        if (index === 0) {
          netHour = hour - 0.5
          // console.log('index', 0, hour, netHour)
        }

        if (netHour > 0) {
          if (netHour < 0.5) {
            if (timeEnd.format('a') === 'pm') {
              // console.log('all first day')
              item.hour_d1 = 0.5
            } else if (timeStart.format('a') === 'am') {
              // console.log('all second day')
              item.hour_d2 = 0.5
            } else {
              // console.log('start on first day and end on second day')
              item.hour_d1 = 0.25
              item.hour_d2 = 0.25
            }
          } else {
            if (timeEnd.format('a') === 'pm') {
              // console.log('all first day')
              item.hour_d1 = Math.ceil(netHour / 0.5) * 0.5
            } else if (timeStart.format('a') === 'am') {
              // console.log('all second day')
              item.hour_d2 = Math.ceil(netHour / 0.5) * 0.5
            } else {
              // console.log('start on first day and end on second day')

              const endOfStartD = startDate.clone().endOf('day')
              const startOfEndD = endDate.clone().startOf('day')

              const firstDuration = Moment.duration(Math.abs(endOfStartD.diff(startDate)))
              const secondDuration = Moment.duration(Math.abs(endDate.diff(startOfEndD)))

              const firstDay = formatter.round(firstDuration.asHours())
              const secondDay = formatter.round(secondDuration.asHours())

              if (firstDay + secondDay > 0.5) {
                const secondDayPortion = 0.5 - firstDay
                const secondDayBalance = formatter.round(secondDay - secondDayPortion)

                item.hour_d1 = index === 0 ? firstDay - 0.5 : firstDay
                item.hour_d2 = formatter.round(secondDayPortion + (Math.ceil(secondDayBalance / 0.5) * 0.5))

                // console.log(secondDay, secondDayPortion, secondDayBalance, item.hour_d2)
              } else if (firstDay + secondDay < 0.5) {
                item.hour_d1 = firstDay
                item.hour_d2 = 0.5 - firstDay
              } else {
                item.hour_d1 = firstDay
                item.hour_d2 = secondDay
              }

              // console.log(startDate.format('hh:mm:ss a'), endOfStartD.format('hh:mm:ss a'), firstDuration, secondDuration, firstDay, secondDay)
            }
            // item.hour = Math.ceil(netHour / 0.5) * 0.5
          }
        }
      }

      // console.log(getupList)

      this.setState({ getupList })
    }
  }

  // Stage 1.5
  handleGetupDelete = (id) => {
    const { getupList } = this.state

    const items = getupList.filter((item) => item.id !== id)

    this.setState({ getupList: items })
  }

  // Stage 1.5
  handleGetupAdd = () => {
    const { getupList } = this.state

    getupList.push({ id: `${getupList.length + 1}-temp`, notes: '', hour_d1: 0, hour_d2: 0 })

    this.setState({ getupList })
  }

  handleOvernightChange = (value) => {
    const type = value.target.value
    if (type === 'sleepover') {
      this.setState({ isOvernightSleepover: true })
    } else {
      this.setState({ isOvernightSleepover: false })
    }
  }

  shouldShowGetupHours () {
    const { billingCategory, item } = this.state

    if (billingCategory && item) {
      for (let i = 0; i < billingCategory.length; i++) {
        if (billingCategory[i].id === item.billing_category_id) {
          if (billingCategory[i].name.indexOf(SleepOver) > -1) {
            this.setState({ showGetupHours: true })
          }
          break
        }
      }
    }
  }

  // 4. General --------------

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingJobs,
  fetchTotalPending,
  setRefreshCommLog
}

const mapStateToProps = (state) => {
  return { ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Job))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, jobService } from '../../../services'
import { formatter, validator, log } from '../../../util'
import { Panel } from '../../../components'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import SignoffMap from './map'
import notify from '../../../components/Notification'
import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const { TextArea } = Input
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const MODE_VIEW = 'view'
const MODE_MODAL = 'modal'
const MODE_MODAL_W_DELETE = 'modal-delete'

class TimesheetTemplateJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientInfo: {},
      info: {},
      loading: false,
      mode: MODE_VIEW,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientInfo, info = {}, loading, mode, visible } = nextProps

    // compatible with the old field name in Fvp Payroll
    // the field names from FVP Payroll and JVP Job list are different. The latter one has "job_jts" appended at prefix
    let newInfo = {}

    if (validator.isObject(info)) {
      newInfo = {
        signedoffId: info.job_jts_id || info.signed_timesheet_id || null,
        signoffImageUrl: info.job_jts_image_file_url || info.file_url || null,
        signedTime: info.job_jts_signed_time || info.signed_time || null,
        submitTime: info.job_jts_signed_submit_time || info.signed_submit_time || null,
        isSignedLate: info.job_jts_is_signed_late || info.is_signed_late || null,
        isOutsideLoc: info.job_jts_is_outside_loc || info.is_outside_loc || null,
        isOutsideAcc: info.job_jts_is_outside_acc || info.is_outside_acc || null,
        isOutsiteKMSData: info.job_jts_is_outside_kms || info.is_outside_kms || null,
        accuracy: info.job_jts_signed_accuracy || info.signed_accuracy || null,
        distance: info.job_jts_signed_distance || info.signed_distance || null,
        location: info.job_jts_signed_location || info.signed_location || null,
        clientLocation: info.job_jts_signed_client_location || info.signed_client_location || {},
        jsd: info.job_jts_job_start_date || info.job_start_date || null,
        jed: info.job_jts_job_end_date || info.job_end_date || null,
        sjsd: info.job_jts_signed_job_start_date || info.job_start_date || null,
        sjed: info.job_jts_signed_job_end_date || info.job_end_date || null,
        endTimeDiff: info.job_jts_job_end_time_diff || info.job_end_time_diff || null,
        kms: info.kms || 0,
        jobKms: getJobKms(info)
      }
    }


    return { ...prevState, clientInfo, info: newInfo, loading, mode, visible }
  }

  /** Timesheet delete check function */
  checkBeforeDeleteTimesheet = () => {
    const { onDeleteTimesheet = () => {} } = this.props

    confirm({
      title: 'Are you sure you want to delete this timesheet?',
      content: <div><p>Press Ok to continue, No to return</p><p><strong>This action CANNOT BE UNDONE!</strong></p></div>,
      okText: 'Yes',
      cancelText: 'No',
      onOk () {
        onDeleteTimesheet()
      },
      onCancel () {

      }
    })
  }

  // saveJobFeedback = () => {
  //   const { form, onSavedJob = () => {} } = this.props
  //   const { info } = this.state
  //   const { validateFieldsAndScroll } = form
  //   let changes
  //   let response

  //   validateFieldsAndScroll(async (errors, values) => {
  //     if (!errors) {
  //       if (values.job_feedback_text) {
  //         values.job_feedback_text = this.replacer(values.job_feedback_text)
  //       }

  //       try {
  //         if (info.is_job_feedback !== values.is_job_feedback) {
  //           changes = `Job Feedback change from "${!values.is_job_feedback}" to "${values.is_job_feedback}"${values.job_feedback_text ? ` with notes: ${values.job_feedback_text}` : ''}`
  //         } else if (!validator.isEmptyString(values.job_feedback_text) && info.job_feedback_text !== values.job_feedback_text) {
  //           changes = `Job Feedback Notes from "${info.job_feedback_text}" to "${values.job_feedback_text}"`
  //         }

  //         response = await jobService.save(info.id, values)

  //         if (response.id) {
  //           onSavedJob()
  //           if (changes) {
  //             log.updateJobFeedbackTimesheet(info.id, changes)
  //           }
  //           notify.success('Saved successfully', 'Job Feedback saved successfully.')
  //         }
  //       } catch (e) {
  //         notify.error('Unable to save successfully', 'Unable to save funder successfully. Please try again later.')
  //       }
  //     }
  //   })
  // }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }

    const { onCloseSignoffModal = () => {}, onDeleteTimesheet, onSavedJob, form } = this.props
    const { clientInfo, info, loading, mode, visible } = this.state
    const { getFieldDecorator, getFieldValue } = form

    const {
      signedoffId,
      signoffImageUrl,
      signedTime,
      submitTime,
      isSignedLate,
      isOutsideLoc,
      isOutsideAcc,
      isOutsiteKMSData,
      accuracy,
      distance,
      location,
      clientLocation = {},
      jsd,
      jed,
      sjsd,
      sjed,
      endTimeDiff,
      kms,
      jobKms
    } = info

    const cLatitude = clientLocation && clientLocation.latitude ? clientLocation.latitude : clientInfo && clientInfo.latitude ? clientInfo.latitude : null
    const cLongitude = clientLocation && clientLocation.longitude ? clientLocation.longitude : clientInfo && clientInfo.longitude ? clientInfo.longitude : null

    const jobStartDate = sjsd === null || sjsd === undefined ? jsd : sjsd
    const jobEndDate = sjed === null || sjed === undefined ? jed : sjed
    const afterText = 'After Job End Date'
    const beforeText = 'Before Job End Date'
    const diffMinSignedTime = endTimeDiff === null || endTimeDiff === undefined ? formatter.updateJobTimeDiff(jobEndDate, signedTime) : endTimeDiff
    const diffMinSignedTimeText = formatter.toTimeCountText(diffMinSignedTime, afterText, beforeText, (jobEndDate > signedTime))

    const signedAfterText = 'After Signing Time'
    const signedBeforeText = 'Before Signing Time'
    const diffSignedMin = formatter.updateJobTimeDiff(signedTime, submitTime)
    const diffSignedMinText = formatter.toTimeCountText(diffSignedMin, signedAfterText, signedBeforeText, (signedTime > submitTime))
    const clientCoords = {latitude: parseFloat(cLatitude), longitude: parseFloat(cLongitude)}
    const signoffCoords = location && location.latitude && location.longitude ? {latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude)} : null

    const jobKmsText = (jobKms === null || jobKms === '' || jobKms === undefined) ? 'Unlimited KM' : (!parseFloat(jobKms)) ? 'KMS Not Allowed' : `${jobKms} KM Allowed`
    const isOutsiteKMS = !(jobKms === null || jobKms === '' || jobKms === undefined) && ((!parseFloat(jobKms) && parseFloat(kms) > 0) || (parseFloat(jobKms) > 0 && parseFloat(kms) > 0 && parseFloat(jobKms) < parseFloat(kms)))

    const { zoom } = formatter.updateMapDetails(clientCoords, signoffCoords, parseInt(accuracy))
    const image = signoffImageUrl
    const isModal = mode === MODE_MODAL || mode === MODE_MODAL_W_DELETE
    const isDeleteFunction = validator.isFunction(onDeleteTimesheet)
    const isSaveJobFunction = validator.isFunction(onSavedJob)

    const renderInfo = () => {
      return (
        signedoffId
        ? <div className='jobs-jvp-ts'>
          { image
            ? <img className='ts-panel' src={image} />
            : <div>No image available.</div> }

          <div className='info-panel'>
            <Panel
              title={isModal ? '' : `Timesheet Details`}
              subtitle={(
                <div className='subtitle1'>
                  {this.hasAccess('deleteJobTimesheet') && mode !== MODE_MODAL && isDeleteFunction
                    ? <div className='btn' onClick={this.checkBeforeDeleteTimesheet}>Delete Timesheet</div>
                    : null}

                  {/* {this.hasAccess('deleteJobTimesheet') && mode !== MODE_MODAL && isSaveJobFunction
                    ? <div className='btn btn-ghost' onClick={this.saveJobFeedback}>Save Job Feedback</div>
                    : null} */}
                </div>
              )}
            >
              <Row gutter={16}>
                <div className='ts-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Recorded KM (Max KM)</b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      <span>
                        {`${kms}`}{` (${jobKmsText})`}
                      </span>
                      { isOutsiteKMS
                        ? <div><span className='ts-indicator'>Outside KMS</span></div>
                        : null }
                    </div>
                  </Col>
                </div>

                <div className='ts-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Job Date Time </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      {<span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toDurationText(jobStartDate, jobEndDate)}`}
                    </div>
                  </Col>
                </div>

                <div className='ts-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Signed on </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      {<span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toStandardLongDate(signedTime)}`}
                      <strong>{` (${diffMinSignedTimeText})`}</strong>
                      { isSignedLate
                        ? <div><span className='ts-indicator'>Outside Endtime</span></div>
                        : null }
                    </div>
                  </Col>
                </div>

                <div className='ts-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Received on </b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      { <span style={{ color: '#ed6d1e' }}><Icon type='clock-circle' /></span>}
                      {` ${formatter.toStandardLongDate(submitTime)}` }
                      <strong>{` (${diffSignedMinText})`}</strong>
                    </div>
                  </Col>
                </div>

                {/* signoffImage
                  ? <div className='ts-details' style={{ marginTop: 3 }}>
                    <Col lg={6}>
                      <b>Signing Signature</b>
                    </Col>
                    <Col lg={17}>
                      <span className='ts-button' onClick={() => this.setState({showSignoffModal: true})}>View</span>
                    </Col>
                  </div>
                : null */}

                <div className='ts-details' style={{ marginTop: 3 }}>
                  <Col lg={6}>
                    <b>Signing Location</b>
                  </Col>
                  <Col lg={17}>
                    <div style={{ fontSize: 12, marginRight: 12 }}>
                      <strong>
                        {`${formatter.toDistance(distance)} from client's location (accuracy ${formatter.toDistance(accuracy)})`}
                      </strong>
                      { isOutsideLoc || isOutsideAcc
                        ? <div><span className='ts-indicator'>Outside Location</span></div>
                        : null }
                    </div>
                  </Col>
                </div>

                {/* <Form>
                  <div className='ts-details' style={{ marginTop: 3 }}>
                    <Col lg={6}>
                      <b>Job Feedback</b>
                    </Col>
                    <Col lg={2}>
                      <div style={{ fontSize: 12, marginRight: 12 }}>
                        <FormItem  label={``}>
                          {getFieldDecorator('is_job_feedback', {
                            initialValue: jobFeedback ? jobFeedback : false,
                            valuePropName: 'checked'
                          })(
                            <Switch
                              onChange={this.handleFeedbackChange}
                              checkedChildren='Yes'
                              unCheckedChildren='No'
                              disabled={mode !== 'view'}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={15}>
                      <FormItem label={``}>
                        {getFieldDecorator(`job_feedback_text`, {
                          initialValue: this.replacer(jobFeedbackText)
                        })(
                          <TextArea rows={1} disabled={!getFieldValue(`is_job_feedback`)} readOnly={mode !== 'view'} />
                        )}
                      </FormItem>
                    </Col>
                  </div>
                </Form> */}
              </Row>
            </Panel>

            <SignoffMap
              isMarkerShown
              zoom={zoom}
              radius={accuracy || 1}
              clientCoords={{latitude: parseFloat(cLatitude), longitude: parseFloat(cLongitude)}}
              signoffCoords={location && location.latitude && location.longitude ? {latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude)} : null}
            />
          </div>
        </div>
        : info.id
          ? <div className='message-box-ts'>
            <div className='alert-ts'>The job is not signed.</div>
          </div>
          : loading
            ? <div className='message-box-ts'>
              <div className='alert-ts'>Loading...</div>
            </div>
            : <div className='message-box-ts'>
              <div className='alert-ts'>No job info.</div>
            </div>
      )
    }

    return (
      isModal
        ? <Modal
          width={'75vw'}
          title='Timesheet Details'
          visible={visible}
          onOk={onCloseSignoffModal}
          onCancel={onCloseSignoffModal}
          footer={[
            <Button key='ok' type='primary' onClick={onCloseSignoffModal}>OK</Button>
          ]}>
          { renderInfo() }
        </Modal>
        : mode === MODE_VIEW
          ? <div>
            { renderInfo() }
          </div>
          : <div />
    )
  }

  handleFeedbackChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form

    if (!value) {
      setFieldsValue({ job_feedback_text: null })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  replacer = (value) => {
    if (value) {
      let str = value
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return null
  }
}

function getJobKms (info) {
  if (info) {
    const { job_jts_job_kms: jtsJobKms, job_kms: jobKms } = info

    if (jtsJobKms !== null && jtsJobKms !== '' && !isNaN(jtsJobKms)) {
      return parseFloat(jtsJobKms)
    } else if (jobKms !== null && jobKms !== '' && !isNaN(jobKms)) {
      return parseFloat(jobKms)
    }

    return null
  }

  return 0
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state }
}

export default Form.create()(TimesheetTemplateJvp)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clientService, employeeBlacklistService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import { formatter, log } from '../../../util'

// UI
import { Button, SideModal, Loading, List, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'


const { Item: FormItem } = Form
const { TextArea } = Input

export class ClientBlacklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      clientId: '',
      modalShow: false,
      prevEmployee: [],
      blacklistEmp: [],
      employee: { id: null, name: '' }
    }
  }

  componentDidMount () {
    this.fetchPreviousCarers()
    this.fetchBlacklistCarers()
  }

  render () {
    const { form } = this.props
    const { loading, prevEmployee, blacklistEmp, modalShow } = this.state
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 9,
        render: ({ first_name, last_name, leave_id, leave_start_date, leave_end_date, id }) => leave_id ? <div><a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(leave_start_date)} - ${formatter.toShortDate(leave_end_date)}`}</div></div>
          : <a href={`/employees/${id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
      },
      {
        title: 'Accounting Ref',
        width: 13,
        key: 'acc_ref'
      },
      {
        title: 'Action',
        width: 2,
        render: ({ id, first_name, last_name }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Blacklist'>
            <Popconfirm
              title='Confirm to blacklist this?'
              onConfirm={() => this.showModal(id, `${first_name} ${last_name}`)}
              okText='Yes'
              cancelText='No'
            ><Icon type='close-circle' theme='filled' style={{ fontSize: 15}} />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    const columnsWhitelist = [
      {
        title: 'Name',
        width: 9,
        render: ({ first_name, last_name, employee_id, leave_id, leave_start, leave_end }) => leave_id ? <div><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`}</div></div>
          : <a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
      },
      {
        title: 'Accounting Ref',
        width: 4,
        key: 'acc_ref'
      },
      {
        title: 'Reason',
        width: 9,
        key: 'reason'
      },
      {
        title: 'Action',
        width: 2,
        render: ({ id, first_name, last_name }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Whitelist'>
            <Popconfirm
              title='Confirm to whitelist this?'
              onConfirm={() => this.handleWhitelist(id, `${first_name} ${last_name}`)}
              okText='Yes'
              cancelText='No'
            ><Icon type='check-circle' theme='filled' style={{ fontSize: 15}} />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <Panel title='Previous Carers' subtitle={<div />}>
          <div className='task-list'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={prevEmployee} />
            </Skeleton>
          </div>
        </Panel>

        <Panel title='Blacklisted Carers' subtitle={<div />}>
          <div className='task-list'>
            <Skeleton loading={loading} active>
              <List cols={columnsWhitelist} rows={blacklistEmp} />
            </Skeleton>
          </div>
        </Panel>

        <SideModal
              title='Blacklist Carer'
              showModal={modalShow}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={() => this.handleBlacklist()}>Blacklist</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Reason'>
                  {getFieldDecorator('reason', {
                    rules: [
                      { min: 2, message: 'Reason must be between 2 and 128 characters' },
                      { max: 128, message: 'Reason must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter reason' },
                      { whitespace: true, message: 'Please enter reason' }
                    ]
                  })(
                    <TextArea rows={3} />
                  )}
                </FormItem>
              </Form>
            </SideModal>
      </Loading>
    )
  }

  fetchPreviousCarers = async () => {
    try {
      const { clientId } = this.props
      this.setState({ loading: true })
      const prevEmployee = await clientService.getClientPrevEmployeesActive(clientId)

      this.setState({ loading: false, clientId, prevEmployee })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchBlacklistCarers = async () => {
    try {
      const { clientId } = this.props
      this.setState({ loading: true })
      const { item } = await employeeBlacklistService.get(clientId)
      this.setState({ loading: false, blacklistEmp: item })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleBlacklist = async () => {
    const { clientId, form } = this.props
    const { employee } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        values.client_id = clientId
        values.employee_id = employee.id

        const res = await employeeBlacklistService.add(values)

        if (res) {
          this.hideModal()
          notify.success('Carer Blacklisted', 'Carer Blacklisted successfully')
          log.addBlacklist(clientId, `Add "${employee.name}", Reason "${values.reason}"`)
          this.fetchPreviousCarers()
          this.fetchBlacklistCarers()
        } else {
          notify.error('Unable to blacklist successfully', 'Unable to blacklist carer successfully. Please try again later.')
        }
      }
    })
  }

  handleWhitelist = async (id, name) => {
    const { clientId } = this.props
    try {
      this.setState({ loading: true })
      const response = await employeeBlacklistService.remove(id)

      if (response.id) {
        notify.success('Whitelisted successfully', 'Carer Whitelisted successfully.')
        log.deleteBlacklist(clientId, 'Remove ' + name)
        this.fetchPreviousCarers()
        this.fetchBlacklistCarers()
      }
    } catch (e) {
      notify.error('Unable to whitelist successfully', 'Unable to whitelist carer successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({ modalShow: false })
  }

  showModal = (id, name) => {
    this.setState({ modalShow: true, employee: { id, name } })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientBlacklist))

export default [
  {
    name: 'Feedback List',
    desc: 'Manage all feedbacks from different channels',
    icon: 'feedback',
    link: '/feedbacks',
    enable: true,
    permission: 'listFeedbacks'
  }
]

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { apiHostname } from '../../../config'
import { authService, employeeFileService, settingFileCategoryService, settingFileTypeService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import { FileTypes, FileTypeList } from '../../../constants'
import { formatter, log, uploader, validator } from '../../../util'
import Moment from 'moment-timezone'
import moment from 'moment'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager, FileUpload } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 100
const employeeModule = 'employee'

const dateFormat = 'DD/MM/YYYY'
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class EmployeeDocument extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      docMainCategories: [],
      loading: false,
      loadingFile: false,
      loadingForm: false,
      isShowFileRule: false,
      isShowSubCategory: false,
      list: [],
      filteredSubCategories: [],
      mainCategories: [],
      subCategories: [],
      total: 0,
      modal: {
        edit: false,
        item: {},
        show: false
      },
      currentFileName: '',
      currentSubCategory: {}
    }
  }

  componentWillReceiveProps (nextProps) {
    const { onRefreshFiles } = this.props
    // if (nextProps.shouldRefreshFiles !== this.props.shouldRefreshFiles) {
      if (nextProps.shouldRefreshFiles) {
        this.fetchFiles()
        onRefreshFiles(false)
      }
    // }
  }

  componentDidMount () {
    this.fetchFiles()
    this.fetchMainCategories()
    this.fetchSubCategories()
  }

  render () {
    const { form, empId, history } = this.props
    const { getFieldDecorator } = form
    const { currentPage, loading, loadingFile, loadingForm, list, total, modal, isShowFileRule,
      mainCategories, subCategories, filteredSubCategories, currentSubCategory, docMainCategories } = this.state
    const { edit, item: modalItem, show: modalShow } = modal

    const columns = [

      {
        title: 'Main Category',
        width: 4,
        render: ({ file_category_id: mainId }) => {
          const main = mainCategories.filter(item => item.id === mainId)
          if (validator.isNotEmptyArray(main)) {
            return <div>{main[0].name}</div>
          } else return ''
        }
      },

      {
        title: 'Sub Category',
        width: 4,
        render: ({ id: subId }) => {
          const sub = subCategories.filter(item => item.id === subId)
          if (validator.isNotEmptyArray(sub)) {
            return <div>{sub[0].name}</div>
          } else return ''
        }
      },

      {
        title: 'Label',
        width: 4,
        key: 'name'
      },

      {
        title: 'Issuance Date',
        width: 4,
        render: ({ issuance_date }) => formatter.toStandardDate(issuance_date)
      },

      {
        title: 'Expiry Date',
        width: 4,
        render: ({ expiry_date }) => formatter.toStandardDate(expiry_date)
      },

      {
        title: 'Enabled',
        width: 2,
        render: ({ active }) => active ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <div style={{ color: '#ccc' }}><Icon type='check-circle' /></div>
      },

      {
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {this.hasAccess('createEmployeeFile') ? (
            <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.showModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          ) : null}
          {/* <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.file_name)}`}><div onClick={this.handleDownload(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div></Tooltip> */}
          {this.hasAccess('deleteEmployeeFile') ? (
            <div>
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Confirm to delete ${item.label}?`}
                  onConfirm={() => this.handleDelete(item.id, item)}
                  okText='Yes'
                  cancelText='No'
                ><Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            </div>
          ) : null}

        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-header'>
          {this.hasAccess('createEmployeeFile') ? (
            <div className='btn' onClick={() => this.showModal(false)}>
              Add File
            </div>
          ) : null}
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            {/* <List cols={columns} rows={list} /> */}
            <div className='document-list'>
              {
                docMainCategories.map((item, idx) => {
                  return (
                    <div className='main-category' key={idx}>{item.file_main_category}</div>
                  )
                })
              }
            </div>

            <SideModal
              title='File'
              showModal={modalShow}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Main Category'>
                  {getFieldDecorator('main_category_id', {
                    initialValue: modalItem.main_category_id || '',
                    rules: [
                      { required: true, message: 'Please select main category' }
                    ]
                  })(
                    <Select placeholder='Please select main category'
                      onChange={(mainId) => this.handleMainCategory(mainId)}
                      disabled={edit}>
                      {
                        mainCategories.map((main) => (
                          <Option key={main.name} value={main.id}>{main.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Sub Category'>
                  {getFieldDecorator('sub_category_id', {
                    initialValue: modalItem.sub_category_id || '',
                    rules: [
                      { required: true, message: 'Please select sub category' }
                    ]
                  })(
                    <Select placeholder='Please select sub category'
                      onChange={(subId) => this.handleSubCategory(subId)}
                      disabled={edit}>
                      {edit
                        ? subCategories.map((sub) => (
                          <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                        ))
                        : filteredSubCategories.map((sub) => (
                          <Option key={sub.name} value={sub.id}>{sub.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                {/* <FormItem label='Type'>
                  {getFieldDecorator('type', {
                    initialValue: modalItem.type || '',
                    rules: [
                      { required: true, message: 'Please select type' },
                    ]
                  })(
                    <Select placeholder='Please select a type'
                      onChange={(type) => this.handleFileType(type)}
                      disabled={edit}>
                      {
                        FileTypeList.map((types) => (
                          <Option key={types.value} value={types.value}>{types.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem> */}

                <FormItem label='Issuance Date'>
                  {getFieldDecorator('issuance_date', modalItem.issuance_date ? {
                    rules: [
                      { required: true, message: 'Please enter issuance date' }
                    ],
                    initialValue: Moment(modalItem.issuance_date).isValid() ? Moment(modalItem.issuance_date) : null
                  } : {
                    rules: [
                      { required: true, message: 'Please enter issuance date' }
                    ],
                    initialValue: null
                  })(
                    <DatePicker onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} />
                  )}
                </FormItem>

                {currentSubCategory.has_expiry || modalItem.expiry_date
                  ? <FormItem label='Expiry Date'>
                    {getFieldDecorator('expiry_date', modalItem.expiry_date ? {
                      rules: [
                        { required: true, message: 'Please enter expiry date' }
                      ],
                      initialValue: Moment(modalItem.expiry_date).isValid() ? Moment(modalItem.expiry_date) : null
                    } : {
                      rules: [
                        { required: true, message: 'Please enter expiry_date' }
                      ]
                    })(
                      <DatePicker /* onChange={(e) => this.handleFirstAidDateChange(e)} */ format={dateFormat} />
                    )}
                  </FormItem>
                  : null}

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: modalItem.label,
                    rules: [
                      { min: 2, message: 'Label must be between 2 and 128 characters' },
                      { max: 128, message: 'Label must be between 2 and 128 characters' },
                      // { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FormItem label=''>
                  <FileUpload
                    file={modalItem.file_url}
                    fileName={modalItem.file_name}
                    loading={loadingFile}
                    readOnly={false}
                    showError={isShowFileRule}
                    upload={{
                      action: `${apiHostname}/private/api/employees/files/upload/files`,
                      beforeUpload: this.checkFile,
                      data: { id: modalItem.id, moduleId: empId },
                      disabled: loading,
                      headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                      name: 'file',
                      onChange: this.handleUploadFile,
                      showUploadList: false
                    }}
                  />

                  {isShowFileRule
                    ? <div style={{ marginTop: '5px', color: 'red' }}>Please upload file</div>
                    : null }
                </FormItem>

                {edit
                  ? (
                    <FormItem label='Uploaded By'>
                      {modalItem.created_by}
                    </FormItem>)
                  : null}

                {edit
                  ? (
                    <FormItem label='Uploaded On'>
                      {formatter.toStandardDate(modalItem.created_at)}
                    </FormItem>
                  )
                  : null}

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} files`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  changePage = async (currentPage) => {
    this.fetchFiles(currentPage)
  }

  fetchFiles = async (currentPage = 1) => {
    this.setState({ loading: true })
    const { empId } = this.props
    const filter = { }

    // filter.has_mandatory = { condition: '=', value: 'true' }

    const { list, total } = await employeeFileService.listDocumentByPage(currentPage, pageSize, filter)
    const docMainCategories = await employeeFileService.getDocumentMainCategory()

    this.setState({ list, total, loading: false, currentPage, filter, docMainCategories })
  }

  fetchMainCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })
      const mainCategories = await settingFileCategoryService.listByPage(1, 0, filter)

      this.setState({ mainCategories: mainCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load main categories successfully. Please try again later.')
    }
  }

  fetchSubCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })
      const subCategories = await settingFileTypeService.listByPage(1, 0, filter)

      this.setState({ subCategories: subCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load sub categories successfully. Please try again later.')
    }
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  handleFileType = (type) => {
    const { modal } = this.state
    const { item } = modal
    item.type = type
    this.setState({ modal })
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ loadingForm: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCategory } = this.state

    if (value) {
      if (currentSubCategory.has_expiry && currentSubCategory.expiry_month) {
        const expiryMonth = parseInt(currentSubCategory.expiry_month)
        const expiryDate = moment(value).add(expiryMonth, 'months')
        // console.log('expiry Month', expiryMonth)

        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ loadingForm: false })
  }

  handleMainCategory = (mainId) => {
    this.setState({ loadingForm: true })
    const { subCategories } = this.state
    const subs = subCategories.filter(item => mainId === item.file_category_id)
    this.setState({ loadingForm: false, isShowSubCategory: true, filteredSubCategories: subs })
  }

  handleSubCategory = (subId) => {
    this.setState({ loadingForm: true })
    const { subCategories } = this.state
    const selectedSubCat = subCategories.find(item => subId === item.id)

    this.setState({ loadingForm: false, currentSubCategory: selectedSubCat })
  }

  handleUploadFile = async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loadingForm: true })
    }

    if (status === 'done') {
      const { modal } = this.state
      const { edit, item } = modal
      const { fileUrl } = response
      const lastIndex = fileUrl.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

      if (edit) {
        const currentFileName = formatter.toStandardFileName(item.file_name)
        this.setState({ currentFileName })
      }

      item.file_url = fileUrl
      item.file_name = fileName
      this.setState({ modal, loadingFile: false, loadingForm: false, isShowFileRule: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { modal } = this.state
        const { edit, item } = modal
        const { UploadType } = uploader
        const response = await uploader.upload(item.id, info, token, UploadType.FILE)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = fileUrl.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

          if (edit) {
            const currentFileName = formatter.toStandardFileName(item.file_name)
            this.setState({ currentFileName })
          }

          item.file_name = fileName
          item.file_url = fileUrl
          this.setState({ modal })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleSave = () => {
    const { form, empId, onUpdateInfo } = this.props
    const { modal, currentSubCategory } = this.state
    const { edit, item } = modal
    const { file_url: fileUrl, file_name: fileName } = item
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors && fileUrl) {
        this.setState({ loadingForm: true, isShowFileRule: false })
        // const fileType = FileTypeList.filter(item => item.value === values.type)

        values.module = employeeModule
        values.module_id = empId
        values.file_url = fileUrl
        values.file_name = fileName
        values.type = currentSubCategory.name

        try {
          if (edit) {
            const response = await employeeFileService.save(item.id, values)
            modal.item = { ...item, ...values }
            this.setState({ loadingForm: false, modal })

            if (response.id) {
              const { currentFileName } = this.state
              const newFileName = formatter.toStandardFileName(fileName)
              const extraLog = []

              extraLog.push(`${currentSubCategory.name} ${values.label}`)

              if (currentFileName !== '' && currentFileName !== newFileName) {
                extraLog.push(` File changed from "${currentFileName}" to "${newFileName}"`)

                this.setState({ currentFileName: '' })
              }

              log.updateEmployeeFile(empId, item, values, [], extraLog.join())
              const { currentPage } = this.state
              this.fetchFiles(currentPage)
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          } else {
            const response = await employeeFileService.add(values)
            this.setState({ loadingForm: false })

            if (response.id) {
              const { currentPage } = this.state
              const { id } = response
              modal.item = { ...item, ...values, id }
              log.addEmployeeFile(empId, `New ${currentSubCategory.name} ${values.label}`)
              this.fetchFiles(currentPage)
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save file successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }

      } else {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  async handleDelete (id, info) {
    const { empId, onUpdateInfo } = this.props
    const { currentPage } = this.state
    this.setState({ loading: true })

    const response = await employeeFileService.remove(id)
    this.setState({ loading: false })

    if (response.id) {
      log.deleteEmployeeFile(empId, `Delete file ${info.label}`)
      notify.success('Deleted successfully', 'File deleted successfully')
      this.fetchFiles(currentPage)
      onUpdateInfo()
      this.props.setRefreshActivityLog(true)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields, setFieldsValue } = form
    resetFields()
    setFieldsValue({ issuance_date: null })
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({
      modal,
      isShowFileRule: false,
      isShowSubCategory: false,
      filteredSubCategories: [],
      currentSubCategory: {}
    })
  }

  showModal = (isEdit, modalItem) => {
    const { modal } = this.state
    modal.edit = isEdit || false
    modal.item = modalItem || {}
    modal.show = true
    this.setState({ modal })
  }

}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeDocument))

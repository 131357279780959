export const FILE_MODULE_PERMISSIONS_FETCHED = 'FILE_MODULE_PERMISSIONS_FETCHED'
export const FILE_MODULE_PERMISSION_SET_PRISTINE = 'FILE_MODULE_PERMISSION_SET_PRISTINE'

export const fileModulePermissionsFetched = (fileModulePermissions) => {
  return {
    type: FILE_MODULE_PERMISSIONS_FETCHED,
    fileModulePermissions
  }
}

export const fileModulePermissionSetPristine = (pristine) => {
  return {
    type: FILE_MODULE_PERMISSION_SET_PRISTINE,
    pristine
  }
}

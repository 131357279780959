import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_EMPLOYEES, FETCHING_EMPLOYEES, EMPLOYEES_FETCHED } from '../actions/employee'
import employeeService from '../../services/employee'

function * fetchEmployees ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_EMPLOYEES, loading })
    const employees = yield employeeService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: EMPLOYEES_FETCHED, employees, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_EMPLOYEES, loading: false })
    console.error(e)
  }
}

function * watchEmployee () {
  yield takeEvery(FETCH_EMPLOYEES, fetchEmployees)
}

export default watchEmployee

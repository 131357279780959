import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_FUTURE_JOBS, FETCHING_FUTURE_JOBS, FUTURE_JOBS_FETCHED } from '../actions/futureJob'
import jobService from '../../services/job'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText, jobStatus }) {
  try {
    yield put({ type: FETCHING_FUTURE_JOBS, loading })
    if (jobStatus === 'future') {
      const jobs = yield jobService.getFutureJob(currentPage, pageSize, filter, sort, searchText, jobStatus)
      yield put({ type: FUTURE_JOBS_FETCHED, jobs, loading: false })
    } else if (jobStatus === 'past') {
      const jobs = yield jobService.getPastJob(currentPage, pageSize, filter, sort, searchText, jobStatus)
      yield put({ type: FUTURE_JOBS_FETCHED, jobs, loading: false })
    } else {
      const jobs = yield jobService.getCancelledJob(currentPage, pageSize, filter, sort, searchText, jobStatus)
      yield put({ type: FUTURE_JOBS_FETCHED, jobs, loading: false })
    }
  } catch (e) {
    yield put({ type: FETCHING_FUTURE_JOBS, loading: false })
    console.error(e)
  }
}

function * watchFutureJobs () {
  yield takeEvery(FETCH_FUTURE_JOBS, fetchJobs)
}

export default watchFutureJobs

import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_FEEDBACKS, FETCHING_FEEDBACKS, FEEDBACKS_FETCHED } from '../actions/feedback'
import feedbackService from '../../services/feedback'

function * fetchFeedbacks ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_FEEDBACKS, loading })
    const feedbacks = yield feedbackService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: FEEDBACKS_FETCHED, feedbacks, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_FEEDBACKS, loading: false })
    console.error(e)
  }
}

function * watchFeedback () {
  yield takeEvery(FETCH_FEEDBACKS, fetchFeedbacks)
}

export default watchFeedback

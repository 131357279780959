import { FETCHING_USERS, USERS_FETCHED } from '../actions/user'

const INITIAL_STATE = {
  loading: true,
  users: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_USERS:
      return handleFetchingUsers(state, action)
    case USERS_FETCHED:
      return handleUsersFetched(state, action)
    default:
      return state
  }
}

function handleFetchingUsers (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleUsersFetched (state, action) {
  const { loading, users } = action
  return { ...state, loading, users }
}

import {
  FETCHING_JOB_SUMMARY,
  JOB_SUMMARY_FETCHED
} from '../actions/jvpJobSummary'

const INITIAL_STATE = {
  jobSummary: { individual_pending: 0, recurring_pending: 0 },
  loadingSummary: true,
  isSummaryLoaded: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_JOB_SUMMARY:
      return handleFetchingJobSummary(state, action)
    case JOB_SUMMARY_FETCHED:
      return handleJobSummaryFetched(state, action)
    default:
      return state
  }
}

function handleFetchingJobSummary (state, action) {
  const { loadingSummary, isSummaryLoaded } = action
  return { ...state, loadingSummary, isSummaryLoaded: isSummaryLoaded === null || isSummaryLoaded === undefined ? state.isSummaryLoaded : isSummaryLoaded }
}

function handleJobSummaryFetched (state, action) {
  const { summary, loadingSummary } = action
  return { ...state, jobSummary: summary, loadingSummary, isSummaryLoaded: true }
}


import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment-timezone'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'

import { authService } from '../../../services'
import { formatter } from '../../../util'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export default class RecurringJobView extends Component {
  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { children, item } = this.props

    const { id, client_id, employee_id, job_start_date, job_end_date, first_name, last_name, emp_firstname,
      emp_lastname, suburb, tasks, fund_fullname, funder_id, emergency, interval, job_start_time, job_end_time,
      first_job_date, last_job_date, frequency, sleepover, leave_id, leave_start, leave_end, employee_leave_id,
      employee_leave_start, employee_leave_end, client_leave_until_further_notice, is_sleepover_job, clashed_client_names, clashed_employee_names } = item

    return (
      <div className='recur-jobs-list-item' key={`rcrj-${id}`} style={emergency ? { borderLeft: `2px solid #ff5b5b88` } : null}>
        <Row>
          <Col lg={22}>
            <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
              <Col lg={8}>
                <Row>
                  <Col lg={5}>
                    <div className='subtitle'>Client</div>
                  </Col>
                  <Col lg={14}>
                    {/* <div className='recur-jobs-name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div> */}
                    <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a></div>
                    { leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(leave_start)} - ${client_leave_until_further_notice ? 'UFN' : formatter.toShortDate(leave_end)}`}</div> : null }
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={6}>
                    <div className='subtitle'>Employee</div>
                  </Col>
                  <Col lg={14}>
                    <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a></div>
                    { employee_leave_id ? <div className='name' style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`}</div> : null }
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={5}>
                    <div className='subtitle'>Funder</div>
                  </Col>
                  <Col lg={14}>
                    <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a></div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col md={8}>
                <div className='detail'>
                  <Icon type='calendar' /> { formatter.toShortDate(first_job_date)} - { last_job_date !== null ? formatter.toShortDate(last_job_date) : '∞'}
                  <span className='time-sum'>{formatter.capitalize(interval + 'ly')}</span>
                  { interval === 'weekly' || 'fortnight' ? <span className='time-sum'> {Moment(first_job_date).format('dddd')}</span> : null }
                </div>
                {/* <div style={{ fontSize: '7.5pt', color: '#999', marginTop: 6 }}>Created {Moment(created_at).fromNow()}</div> */}
              </Col>
              <Col md={8}>
                <div className='detail'>
                  <Icon type='clock-circle' /> { formatter.toShortTime(job_start_time)} - { formatter.toShortTime(job_end_time)}
                  { is_sleepover_job ? <span className='time-sum'>Sleepover</span> : <span className='time-sum'>{formatter.toDecimal(Moment.duration(Moment(job_end_time).diff(Moment(job_start_time))).asHours())} hours</span> }
                </div>
              </Col>
              <Col md={6}>
                <div className='detail'><Icon type='environment' /> {suburb}
                { clashed_client_names || clashed_employee_names
                  ? <span>
                      &nbsp;
                      <Tooltip
                        title={(
                          <div>
                            {clashed_client_names ? <div>Same address with client: {clashed_client_names}</div> : ''}
                            {clashed_employee_names ? <div>Same address with employee: {clashed_employee_names}</div> : ''}
                          </div>
                        )}
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                      >
                        <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                      </Tooltip>
                  </span>
                  : null }
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col>
                <div className='detail-normal'>{ tasks ? formatter.toShortenText(tasks, 350) : tasks }</div>
              </Col>
            </Row>
          </Col>
          <Col lg={2} className='action-icon'>
            <Link to={`/jobs/recurring/${id}`}>
              { this.hasAccess('readJob') || this.hasAccess('updateJob')
                ? <div style={{ color: '#D66E00' }}>
                  <Tooltip mouseLeaveDelay={0} title='Manage job'>
                    <Icon type='form' />
                  </Tooltip>
                </div>
                : null }
            </Link>
          </Col>
        </Row>
      </div>
    )
  }
}


import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import { apiHostname } from '../../../config'
import { FeedbackStatusOption, YesNoOption, YesNoOptions } from '../../../constants'
import { authService, feedbackActionService, settingFeedbackStatusService } from '../../../services'
import { setRefreshActivityLog, setRefreshFeedbackActions } from '../../../states/actions'
import { formatter, uploader } from '../../../util'

// UI
import { Button, ControlLabel, FeedbackStatus, FileUpload, List, Loading, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const { Option } = Select
const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class FeedbackAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFileName: '',
      currentPage: 1,
      feedbackStatuses: [],
      filter: {},
      list: [],
      loading: false,
      loadingFile: false,
      loadingForm: false,
      modal: {
        edit: false,
        item: {},
        show: false
      },
      searchText: '',
      searching: false,
      sort: {},
      total: 0
    }
    this.handleSearch = debounce(this.handleSearch, 500)
  }

  componentDidMount() {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
    this.fetchFeedbackStatuses()
  }

  componentWillReceiveProps(nextProps) {
    const { onRefreshFeedbackActions } = this.props
    const { currentPage, filter, searchText, sort } = this.state

    if (nextProps.shouldRefreshFeedbackActions !== this.props.shouldRefreshFeedbackActions) {
      if (nextProps.shouldRefreshFeedbackActions) {
        this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
        onRefreshFeedbackActions(false)
      }
    }
  }

  render() {
    const { feedbackId, form, isReopened } = this.props
    const { currentPage, feedbackStatuses, loading, loadingFile, loadingForm, list, modal, searching, total } = this.state
    const { getFieldDecorator, getFieldValue } = form
    const { edit: modalEdit, item: modalItem, show: modalShow } = modal
    const columns = [
      {
        key: 'description',
        title: 'Actions Taken',
        width: 11
      },
      {
        key: 'created_at',
        title: 'Added',
        width: 3,
        render: ({ created_at: createdAt, created_by_name: createdByName }) => (
          <>
            <div>{formatter.toStandardDate(createdAt)}</div>

            <div className='subtitle'>{createdByName}</div>
          </>
        )
      },
      {
        key: 'updated_at',
        title: 'Updated',
        width: 3,
        render: ({ updated_at: updatedAt, updated_by_name: updatedByName }) => (
          <>
            <div>{updatedAt ? formatter.toStandardDate(updatedAt) : ''}</div>

            <div className='subtitle'>{updatedByName}</div>
          </>
        )
      },
      {
        key: 'status',
        title: 'Status',
        width: 3,
        render: ({ status, status_colour: statusColour, status_name: statusName }) => status
          ? <FeedbackStatus colour={statusColour} label={statusName} />
          : null
      },
      {
        key: 'due_at',
        title: 'Due Date',
        width: 2,
        render: ({ due_at: dueAt }) => dueAt ? formatter.toShortDate(dueAt) : ''
      },
      {
        key: 'action',
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback')) ? (
            <div>
              {item.done === YesNoOption.yes.value ? (
                <Tooltip mouseLeaveDelay={0} title="Done">
                  <span style={{ color: '#4fbc85' }}>
                    <Icon type="check-circle" theme="filled" />
                  </span>
                </Tooltip>
              ) : (
                <Popconfirm
                  title="Mark as Done?"
                  onConfirm={this.handleMarkAsDone(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip mouseLeaveDelay={0} title="Mark as Done?">
                    <Icon type="check-circle" />
                  </Tooltip>
                </Popconfirm>
              )}
            </div>
          ) : null}

          {isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback')) ? (
            <Tooltip mouseLeaveDelay={0} title='Edit'>
              <div onClick={this.showModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div>
            </Tooltip>
          ) : null}

          {this.hasAccess('readFeedback') && item.file_url ? (
            <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.file_name)}`}>
              <div onClick={this.handleDownload(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div>
            </Tooltip>
          ) : null}

          {!this.isCompleted() && this.hasAccess('deleteFeedback') ? (
            <div>
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
                  onConfirm={this.handleDelete(item.id, item)}
                  okText='Yes'
                  cancelText='No'
                ><Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            </div>
          ) : null}
        </div >
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Actions Taken</ControlLabel>

              <SearchInput placeholder='Search actions taken' onChange={this.handleSearch} isSearching={searching} />
            </Col>

            <Col lg={16} style={{ display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end' }}>
              <div className='task-header'>
                {isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback')) ? (
                  <div className='btn' onClick={this.showModal(false)}>
                    Add Action
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Modal
              key='feedback-action-modal'
              width={'60vw'}
              title={`${modalEdit ? 'Update' : 'Add'} Action Taken`}
              visible={modalShow}
              onCancel={this.hideModal}
              footer={[
                <Button key='close' ghost disabled={loadingForm} onClick={this.hideModal}>Close</Button>,
                isReopened || (!this.isCompleted() && this.hasAccess('updateFeedback'))
                  ? <Button key='submit' disabled={loadingForm} onClick={this.handleSave}>Submit</Button>
                  : null
              ]}
            >
              <Spin spinning={loadingForm}>
                <Form>
                  <FormItem {...sideBySideFormItemLayout} label='Actions Taken'>
                    {getFieldDecorator('description', {
                      initialValue: modalItem.description,
                      rules: [
                        { required: true, message: 'Please enter actions taken' },
                        { whitespace: true, message: 'Please enter actions taken' }
                      ]
                    })(
                      <TextArea rows={{ minRows: 5 }} placeholder='Actions Taken' />
                    )}
                  </FormItem>

                  <FormItem {...sideBySideFormItemLayout} label='Feedback Status Update'>
                    {getFieldDecorator('status_id', {
                      initialValue: modalItem.status_id ? modalItem.status_id.toString() : undefined,
                      rules: [
                        { required: true, message: 'Please select feedback status update.' }
                      ]
                    })(
                      <Select>
                        {feedbackStatuses.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...sideBySideFormItemLayout} label='Due Date'>
                    {getFieldDecorator('due_at', {
                      initialValue: modalItem.due_at ? Moment(modalItem.due_at) : undefined
                    })(
                      <DatePicker allowClear={false} disabledDate={this.disableDueDate} format={dateFormat} />
                    )}
                  </FormItem>

                  {modalEdit ? (
                    <FormItem
                      {...sideBySideFormItemLayout}
                      label={(
                        <span>
                          <span>Mark as Done&nbsp;</span>

                          <Tooltip title="Marking as done will stop sending of email reminder">
                            <Icon type="info-circle" />
                          </Tooltip>
                        </span>
                      )}
                    >
                      {getFieldDecorator('done', {
                        initialValue: modalItem.done,
                        rules: [
                          { required: getFieldValue('due_at'), message: 'Please select mark as done.' }
                        ]
                      })(
                        <Radio.Group className='feedback-radio-group'>
                          {YesNoOptions.map(({ label, value }) => <Radio key={value} value={value}>{label}</Radio>)}
                        </Radio.Group>
                      )}
                    </FormItem>
                  ) : null}

                  <FormItem {...sideBySideFormItemLayout} colon={false} label=' '>
                    <Row>
                      <Col lg={12}>
                        <FileUpload
                          file={modalItem.file_url}
                          fileName={modalItem.file_name}
                          loading={loadingFile}
                          readOnly={false}
                          upload={{
                            action: `${apiHostname}/private/api/feedback-actions/upload/files`,
                            beforeUpload: this.checkFile,
                            data: { id: modalItem.id, moduleId: feedbackId },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'file',
                            onChange: this.handleUploadFile,
                            showUploadList: false
                          }}
                        />
                      </Col>
                    </Row>
                  </FormItem>
                </Form>
              </Spin>
            </Modal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} actions taken`}
          current={currentPage}
          onChange={this.changePage}
          style={{ marginTop: '15px' }}
        />
      </Loading>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  disableDueDate = (date) => {
    const { feedbackForm } = this.props
    const { getFieldValue } = feedbackForm
    const reportedAt = getFieldValue('reported_at')

    if (reportedAt) {
      return date && Moment(date).startOf('day') < Moment(reportedAt).startOf('day')
    }

    return false
  }

  fetchFeedbackActions = ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    this.setState({ loading: true }, () => {
      const { feedbackId, actionId } = this.props
      filter.feedback_id = feedbackId
      feedbackActionService.listByPage(currentPage, pageSize, filter, sort, searchText).then(({ list, total }) => {
        this.setState({ currentPage, filter, list, loading: false, searching: false, total }, () => {
          if (actionId) {
            feedbackActionService.get(actionId).then(({ item }) => {
              this.showModal(true, item)()
            })
          }
        })
      })
    })
  }

  fetchFeedbackStatuses = async () => {
    settingFeedbackStatusService.getAll().then((feedbackStatuses) => {
      this.setState({ feedbackStatuses: Array.isArray(feedbackStatuses) ? feedbackStatuses : [] })
    })
  }

  handleDelete = (id, info) => () => {
    const { feedbackId } = this.props
    const { currentPage, filter, searchText, sort } = this.state
    this.setState({ loading: true }, async () => {
      try {
        const response = await feedbackActionService.remove(id)
        this.setState({ loading: false }, () => {
          if (response.id) {
            notify.success('Deleted successfully', 'Action takens deleted successfully')
            this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
            this.props.setRefreshActivityLog(true)
            window.history.replaceState(null, '', `/feedbacks/${feedbackId}/actions`)
          }
        })
      } catch (e) {
        notify.error('Unable to delete successfully', 'Unable to delete actions taken successfully. Please try again later.')
        this.setState({ loading: false })
      }
    })
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleMarkAsDone = (item) => () => {
    const { feedbackId } = this.props
    const { currentPage, filter, searchText, sort } = this.state
    this.setState({ loading: true }, async () => {
      try {
        const response = await feedbackActionService.markAsDone(item.id, {
          feedback_id: feedbackId, done: YesNoOption.yes.value
        })
        this.setState({ loading: false }, () => {
          if (response.id) {
            this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
            this.props.setRefreshActivityLog(true)
          }
        })
      } catch (e) {
        notify.error('Unable to mark as done successfully', 'Unable to mark actions taken as done successfully. Please try again later.')
        this.setState({ loading: false })
      }
    })
  }

  handleSave = () => {
    const { feedbackId, form, isReopened } = this.props
    const { validateFields } = form
    validateFields((errors, values) => {
      if (!errors) {
        this.setState({ loadingForm: true }, async () => {
          const { currentPage, feedbackStatuses, filter, modal, searchText, sort } = this.state
          const { edit, item } = modal
          const { file_url: fileUrl, file_name: fileName } = item
          values.feedback_id = feedbackId
          values.file_url = fileUrl
          values.file_name = fileName
          values.reopened = isReopened

          try {
            if (edit) {
              const response = await feedbackActionService.save(item.id, values)
              this.setState({ loadingForm: false }, () => {
                if (response.id) {
                  const status = feedbackStatuses.find(({ id }) => id.toString() === values.status_id)
                  notify.success('Saved successfully', 'Actions taken saved successfully.')
                  this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
                  this.hideModal()
                  this.props.setRefreshActivityLog(true)
                  this.props.onUpdateFeedbackStatus(status || {})
                  this.props.onUpdateIsReopened(false)
                }
              })
            } else {
              const response = await feedbackActionService.add(values)
              this.setState({ loadingForm: false }, () => {
                if (response.id) {
                  const status = feedbackStatuses.find(({ id }) => id.toString() === values.status_id)
                  notify.success('Saved successfully', 'Actions taken saved successfully.')
                  this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
                  this.hideModal()
                  this.props.setRefreshActivityLog(true)
                  this.props.onUpdateFeedbackStatus(status || {})
                  this.props.onUpdateIsReopened(false)
                }
              })
            }
          } catch (e) {
            notify.error('Unable to save successfully', 'Unable to save actions taken successfully. Please try again later.')
            this.setState({ loadingForm: false })
          }
        })
      }
    })
  }

  handleSearch = (value) => {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true }, () => {
      value = value ? value.trim() : value

      if (value.indexOf(' ') >= 0) {
        const words = value.split(' ')

        if (Array.isArray(words)) {
          filter.$and = []

          for (let i = 0; i < words.length; i++) {
            filter.$and.push({
              $or: [
                { description: { condition: 'ilike', value: `%${words[i]}%` } }
              ]
            })
          }
        }
      } else {
        if (Array.isArray(filter.$and)) {
          delete filter.$and
        }
      }

      this.setState({ currentPage: 1, searchText: value }, () => {
        this.fetchFeedbackActions({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
      })
    })
  }

  handleUploadFile = async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loadingForm: true })
    }

    if (status === 'done') {
      const { modal } = this.state
      const { edit, item } = modal
      const { fileUrl } = response
      const lastIndex = fileUrl.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

      if (edit) {
        const currentFileName = formatter.toStandardFileName(item.file_name)
        this.setState({ currentFileName })
      }

      item.file_url = fileUrl
      item.file_name = fileName
      this.setState({ modal, loadingFile: false, loadingForm: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { modal } = this.state
        const { edit, item } = modal
        const { UploadType } = uploader
        const response = await uploader.upload(item.id, info, token, UploadType.FILE)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = fileUrl.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

          if (edit) {
            const currentFileName = formatter.toStandardFileName(item.file_name)
            this.setState({ currentFileName })
          }

          item.file_name = fileName
          item.file_url = fileUrl
          this.setState({ modal })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  hideModal = () => {
    const { feedbackId, form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({ modal }, () => {
      window.history.replaceState(null, '', `/feedbacks/${feedbackId}/actions`)
    })
  }

  isCompleted = () => {
    const { feedback } = this.props
    const { status } = feedback
    return status === FeedbackStatusOption.completed.value
  }

  showModal = (isEdit, modalItem) => () => {
    const { actionId, feedbackId } = this.props
    const { modal } = this.state
    modal.edit = isEdit || false
    modal.item = modalItem || {}
    modal.show = true
    this.setState({ modal }, () => {
      window.history.replaceState(null, '', `/feedbacks/${feedbackId}/actions/${isEdit ? actionId || modalItem.id : 'add'}`)
    })
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog,
  setRefreshFeedbackActions
}

const mapStateToProps = (state) => {
  return { ...state.Feedback, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeedbackAction))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../constants/menu/setting'

// UI
import { Page, Panel } from '../../components'
import CategoryMenu from '../../components/CategoryMenu'

import './styles.css'

export class Setting extends Component {
  render () {
    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title='Settings' />

          <CategoryMenu menu={SettingMenu} />
        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
          Manage settings
          </Page.Panel>
        </Page.Right>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setting)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Button from '../Button'
import Spin from '../Spin'
import Icon from 'antd/lib/icon'
import Upload from 'antd/lib/upload'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

export default class FileTypeUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { className, file, loading = false, readOnly = false, upload } = this.props
    upload.className = formatter.toClassName(['wd-filetypeupload-uploader', upload.className, readOnly ? 'disabled' : ''])
    upload.disabled = readOnly || upload.disabled

    return (
      <div className={formatter.toClassName(['wd-filetypeupload', className])}>
        <Upload {...upload}>
          <Spin loading={loading} blur>
            <div>
              {loading ? null : (
                <div className='wd-filetypeupload-trigger'>
                  <Tooltip mouseLeaveDelay={0} title='Upload'>
                    <div className='wd-filetypeupload-btn' style={{ cursor: 'pointer' }}><Icon className='wd-filetypeupload-icon' type='upload' /></div>
                  </Tooltip>
                </div>
              )}

            </div>
          </Spin>
        </Upload>
      </div>
    )
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }
}

FileTypeUpload.propTypes = {
  className: PropTypes.string,
  // img: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  upload: PropTypes.object.isRequired
}

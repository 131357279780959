import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchFunders } from '../../../states/actions/funder'
import { ExportType, FunderMenu } from '../../../constants'
import { apiHostname } from '../../../config'
import { exportFile, queryString } from '../../../util'

// UI
import { Button, Checkbox, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 40
const Option = Select.Option

export class Funder extends Component {
  constructor (props) {
    super(props)
    const { funders: { list, total }, loading } = props
    this.state = {
      currentPage: 1,
      filter: { active: true },
      isGenerating: false,
      isShowExportModal: false,
      isCheckProfile: true,
      isCheckFile: true,
      isCheckMsgShow: false,
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.handleSelectStatus = this.handleSelectStatus.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { funders: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchFunders({ currentPage, filter, loading, searchText, sort })
  }

  onSearchName (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.setState({ searching: true })

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { abn: { condition: 'ilike', value: `%${words[i]}%` } },
              { formatted_abn: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchFunders({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  handleCheckboxClick (e, { isCheckProfile, isCheckFile}) {
    const check = e.target.checked
    this.setState({
      isCheckProfile: isCheckProfile === undefined ? this.state.isCheckProfile : check,
      isCheckFile: isCheckFile === undefined ? this.state.isCheckFile : check,
    }, () => {
      const { isCheckProfile, isCheckFile } = this.state
      if (!(isCheckProfile || isCheckFile)) {
        this.setState({ isCheckMsgShow: true })
      } else {
        this.setState({ isCheckMsgShow: false })
      }
    })
  }

  handleExportModal (isShowExportModal) {
    this.setState({ isShowExportModal })
  }

  handleSelectStatus (value) {
    const { currentPage, filter, searchText, sort } = this.state
    filter.active = (value === 'active')

    this.fetchFunders({ currentPage, filter, loading: true, searchText, sort })
    this.setState({ filter })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  preCheckExport () {
    const { isCheckMsgShow } = this.state

    if (!isCheckMsgShow) {
      this.export()
    }
  }

  async export () {
    const { filter, isCheckFile, isCheckProfile } = this.state

    const data = {
      export_file: isCheckFile,
      export_profile: isCheckProfile,
    }

    try {
      this.setState({ isGenerating: true })
      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_FUNDER, data)

      setTimeout(() => {
        this.setState({ isGenerating: false, isShowExportModal: false })
      }, 400)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get funder export successfully. Please try again later.')
      this.setState({ isGenerating: false, isShowExportModal: false })
    }
  }

  render () {
    const { currentPage, isGenerating, isShowExportModal, isCheckFile, isCheckProfile, isCheckMsgShow, list, loading, searching, total } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={FunderMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Funders'>
            <Link to='/funders/add'>
              { this.hasAccess('createFunder')
                ? <div className='btn'>
                  Add
                </div>
                : null }
            </Link>
            { this.hasAccess('listFunders')
              ? <div className='btn' onClick={() => this.handleExportModal(true)}>
                { isGenerating
                  ? <img src='/icon/button-loading.svg' alt='loading' />
                  : 'Export'}
              </div>
              : null }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
                <ControlLabel>Name, Acc Ref, ABN, Suburb</ControlLabel>
                <SearchInput placeholder='Search funders' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Status</ControlLabel>
                <Select defaultValue='active' style={{ width: 120 }} onChange={this.handleSelectStatus}>
                  <Option value='active'>Active</Option>
                  <Option value='inactive'>Inactive</Option>
                </Select>
              </Col>
            </Row>
          </Page.Filter>

          <div className='funders'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, acc_ref, fullname, phone_number, suburb, active, abn, formatted_abn } = item

                return (
                  <Link to={`/funders/${id}`} key={id}>
                    <div className={`list-item ${active ? '' : 'list-item-dim'}`}>
                      <Row className='list-content'>
                        <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' /></Col>

                        <Col md={18}>
                          <div className='name'>{fullname}</div>
                          <div style={{ fontSize: '8pt' }}>{acc_ref}</div>
                          <div style={{ fontSize: '8pt' }}>{formatted_abn}</div>
                          <div style={{ fontSize: '8pt' }}>{suburb}</div>
                        </Col>

                      </Row>
                    </div>
                  </Link>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} funders`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          { isShowExportModal
            ? <Modal
              width='450px'
              title='Select Funder Export Items'
              visible={isShowExportModal}
              onCancel={() => this.handleExportModal(false)}
              footer={[
                <div>
                  <Button key='close' ghost feedback={isGenerating} onClick={() => this.handleExportModal(false)}>Cancel</Button>
                  <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
                </div>
              ]}
            >
              <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>

                  <span>
                    <Checkbox
                      checked={isCheckProfile}
                      onClick={f => this.handleCheckboxClick(f, {isCheckProfile: true})}
                    />
                    <span className='checkbox-text'>Funder Profile Export</span>
                  </span>

                  <span>
                    <Checkbox
                      checked={isCheckFile}
                      onClick={f => this.handleCheckboxClick(f, {isCheckFile: true})}
                    />
                    <span className='checkbox-text'>Funder Files Export</span>
                  </span>

                  <div className='checkbox-warning-text'>{isCheckMsgShow ? `Please select at least one item.` : ''}</div>
                </Form>
              </Spin>
            </Modal>
            : null }
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFunders({ currentPage, filter, searchText, sort })
  }

  fetchFunders = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchFunders } = this.props
      this.setState({ currentPage })

      fetchFunders({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funders successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchFunders
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Funder)

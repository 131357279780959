import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import { authService, feedbackActionService } from '../../../services'
import { setRefreshActivityLog, setRefreshFeedbackActions } from '../../../states/actions'
import { formatter } from '../../../util'

// UI
import { ControlLabel, List, Loading, Pager, SearchInput } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FeedbackActionFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      loading: false,
      searchText: '',
      searching: false,
      sort: {},
      total: 0
    }
    this.handleSearch = debounce(this.handleSearch, 500)
  }

  componentDidMount() {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
  }

  componentWillReceiveProps(nextProps) {
    const { onRefreshFeedbackActions } = this.props
    const { currentPage, filter, searchText, sort } = this.state

    if (nextProps.shouldRefreshFeedbackActionFiles !== this.props.shouldRefreshFeedbackActionFiles) {
      if (nextProps.shouldRefreshFeedbackActionFiles) {
        this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
        onRefreshFeedbackActions(false)
      }
    }
  }

  render() {
    const { currentPage, loading, list, searching, total } = this.state
    const columns = [
      {
        key: 'description',
        title: 'Label',
        width: 9,
        render: ({ description, file_name }) => {
          return (
            <>
              <div>{description}</div>

              <div className='subtitle'>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </>
          )
        }
      },
      {
        key: 'created_at',
        title: 'Added At',
        width: 3,
        render: ({ created_at: createdAt }) => formatter.toStandardDate(createdAt)
      },
      {
        key: 'created_by_name',
        title: 'Added By',
        width: 4
      },
      {
        key: 'updated_at',
        title: 'Updated At',
        width: 3,
        render: ({ updated_at: updatedAt }) => updatedAt ? formatter.toStandardDate(updatedAt) : ''
      },
      {
        key: 'updated_by_name',
        title: 'Updated By',
        width: 4
      },
      {
        key: 'action',
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {this.hasAccess('readFeedback') && item.file_url ? (
            <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.file_name)}`}>
              <div onClick={this.handleDownload(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div>
            </Tooltip>
          ) : null}
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Label</ControlLabel>

              <SearchInput placeholder='Search file' onChange={this.handleSearch} isSearching={searching} />
            </Col>
          </Row>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} files`}
          current={currentPage}
          onChange={this.changePage}
          style={{ marginTop: '15px' }}
        />
      </Loading>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFeedbackActions({ currentPage, filter, searchText, sort })
  }

  fetchFeedbackActions = ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    this.setState({ loading: true }, () => {
      const { feedbackId } = this.props
      filter.feedback_id = feedbackId
      filter.file_url = { condition: 'is not null' }
      feedbackActionService.listByPage(currentPage, pageSize, filter, sort, searchText).then(({ list, total }) => {
        this.setState({ currentPage, filter, list, loading: false, searching: false, total })
      })
    })
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleSearch = (value) => {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true }, () => {
      value = value ? value.trim() : value

      if (value.indexOf(' ') >= 0) {
        const words = value.split(' ')

        if (Array.isArray(words)) {
          filter.$and = []

          for (let i = 0; i < words.length; i++) {
            filter.$and.push({
              $or: [
                { description: { condition: 'ilike', value: `%${words[i]}%` } },
                { file_name: { condition: 'ilike', value: `%${words[i]}%` } }
              ]
            })
          }
        }
      } else {
        if (Array.isArray(filter.$and)) {
          delete filter.$and
        }
      }

      this.setState({ currentPage: 1, searchText: value }, () => {
        this.fetchFeedbackActions({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
      })
    })
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog,
  setRefreshFeedbackActions
}

const mapStateToProps = (state) => {
  return { ...state.Feedback, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeedbackActionFile))

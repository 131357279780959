export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const CUSTOMERS_FETCHED = 'CUSTOMERS_FETCHED'
export const CUSTOMERS_FETCHING = 'CUSTOMERS_FETCHING'
export const CUSTOMERS_FAILED = 'CUSTOMERS_FAILED'

export const fetchCustomers = (params) => {
  const { loading, page, size, filter, sorting, text } = params || {}
  return {
    type: FETCH_CUSTOMERS,
    loading,
    page,
    size,
    filter,
    sorting,
    text
  }
}

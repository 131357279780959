import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/jvp/jobs'

export default {
  add (values) {
    return request(`${url}/info`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/info/${id}`)
  },
  save (id, values) {
    return request(`${url}/info/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveCancel (id, values) {
    return request(`${url}/cancel/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveList (values) {
    return request(`${url}/list-update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  savePayroll (values) {
    return request(`${url}/payroll-update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  validateClientLeave (values) {
    return request(`${url}/validate/leave/client`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  validateEmployeeLeave (values) {
    return request(`${url}/validate/leave/employee`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) { //
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listViewByPage (view, page, size, filter, sorting, text) {  //
    return request(`${url}/list/${view}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listViewMainByPage (view, page, size, filter, sorting, text) {  //
    return request(`${url}/list/${view}/main/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listDropdownByPage (page, size, filter, sorting, text) { //
    return request(`${url}/list-dropdown/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getSummary () { //
    return request(`${url}/summary`)
  },
  checkUnsentComm (id) {
    return request(`${url}/check/comm-unsent/${id}`)
  },
  checkSingleConflictJobs (values) {
    return request(`${url}/check/conflict`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  validateEmployeeMaxHour (values) {
    return request(`${url}/validate/max-hour`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
